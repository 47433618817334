

export const CHART_COLORS = {
   "COMMAN": {
      "OTB_PRIMARY_L": "rgb(2, 119, 189)",
      "OTB_PRIMARY_R": "rgb(2, 119, 189)",

      "OTB_SDLY_L": "rgb(2, 119, 189)",
      "OTB_SDLY_R": "rgb(2, 119, 189)",

      "PICKUP_PRIOR_L": "rgb(2, 119, 189)",
      "OTB_PRIMARY_R": "rgb(2, 119, 189)"
   },
   //Format Tile + Identifier + Measure + Axis(l/r)
   "OTB_PRIMARY_OCC_L": "rgb(129, 212, 250)"
};
export const chartColors = {
   "Occ OTB TODAY": "rgb(129, 212, 250)",
   "Occ OTB PRIOR": "#2CA1D4",
   "Occ OTB SDLY": "rgb(41, 182, 246)",
   "Occ OTB SDLY PRIOR": "#3366ff",
   "Occ OTB SDLY ACTUAL": "rgb(2, 119, 189)",

   "ADR OTB TODAY": "rgb(255, 87, 34)",
   "ADR OTB PRIOR": "rgb(255, 193, 7)",
   "ADR OTB SDLY": "#FF9800",
   "ADR OTB SDLY ACTUAL": "#FFC107",
   "ADR OTB SDLY PRIOR": "#cc0066",

   "ADR PACE": "rgb(255, 87, 34)",

   "RevPAR OTB TODAY": "rgb(255, 87, 34)",
   "RevPAR OTB PRIOR": "#8f1727",
   "RevPAR OTB SDLY": "#FF9800",
   "RevPAR OTB SDLY ACTUAL": "#FFC107",
   "RevPAR OTB SDLY PRIOR": "#cc0066",

   "SellRate OTB TODAY": "rgb(255, 87, 34)",
   "SellRate OTB PRIOR": "#8f1727",
   "SellRate OTB SDLY": "#FF9800",
   "SellRate OTB SDLY ACTUAL": "#FFC107",
   "SellRate OTB SDLY PRIOR": "#cc0066",

   "Revenue OTB TODAY": "rgb(129, 212, 250)",
   "Revenue OTB PRIOR": "#8f1727",
   "Revenue OTB SDLY": "#FF9800",
   "Revenue OTB SDLY ACTUAL": "#FFC107",
   "Revenue OTB SDLY PRIOR": "#cc0066",

   "Occ Market TODAY": "rgb(129, 212, 250)",
   "Occ Market PRIOR": "#2CA1D4",
   "Occ Market SDLY": "rgb(41, 182, 246)",
   "Occ Market SDLY PRIOR": "#3366ff",
   "Occ Market SDLY ACTUAL": "rgb(2, 119, 189)",

   "ADR Market TODAY": "rgb(255, 87, 34)",
   "ADR Market PRIOR": "#8f1727",
   "ADR Market SDLY": "#FF9800",
   "ADR Market SDLY ACTUAL": "#FFC107",
   "ADR Market SDLY PRIOR": "#cc0066",

   //PACE
   "PACE": "#6f9fc8",
   "SDLY PACE": "#8c9022",
   "COMPARISON PACE": "#8d5627",

   "PRICING_RECOMENDED_SELLRATE_R": "#81D4FA",
   "PRICING_TODAY_SELLRATE_R": "grey"
   //We can override colors for specific measures as well like this measureName + SeriesName
   // "SellRate PACE": "#fd7800",
   // "SellRate SDLY PACE": "#7f3c00",
   // "SellRate COMPARISON PACE": "#7800fd",
}


export const headerColors = {
   // "OTB TODAY": "#81D4FA",
   // "OTB SDLY": "#0F7AA8",
   // "OTB SDLY ACTUAL": "#03248f",
   // "OTB PRIOR": "#2CA1D4",
   // "OTB SDLY PRIOR": "#3366ff",

   "OTB TODAY": "rgb(129, 212, 250)",
   "OTB PRIOR": "rgb(2, 119, 189)",
   "OTB SDLY": "rgb(41, 182, 246)",
   "OTB SDLY PRIOR": "#3366ff",
   "OTB SDLY ACTUAL": "rgb(2, 119, 189)",
   "ADR OTB TODAY": "blue",

   "PACE": "#3176B0",
   "SDLY PACE": "#afb42b",
   "COMPARISON PACE": "#b06b31",

   //Market tile

   "Market Pace": "#3176B0",
   "Market Comparison Pace": "#b06b31",
   "Market TODAY": "rgb(129, 212, 250)",
   "Market PROJECTED": "rgb(2, 119, 189)",
   //For Compset
   "COMPSET MEDIAN": "#333333",
   "compset_colors": [
      'SteelBlue', 'silver', 'green', 'orange', 'yellow', 'violet', 'gold', 'SlateBlue'
   ],
   "bi_sensitivity_colors": [
      'SteelBlue', 'silver', 'green', 'orange', 'yellow', 'violet','Aquamarine','SpringGreen',
       'gold', 'SlateBlue', 'lightblue','GreenYellow', 'LightCoral', 'Fuchsia','Aqua','PeachPuff'
   ]
}

export const leftMeasureColors = {
   //Booking Curve
   "PACE_L": "#3176B0",
   "SDLY PACE": "#afb42b",
   "COMPARISON PACE": "#b06b31",

   "PACE_PRIMARY_L": "#3176B0",
   "PACE_PRIMARY_PACE_L": "#3176B0",  
   "PACE_PRIMARY_L_DBA": "#3176B0",
   // "PACE_PACESDLY_L": "#afb42b",
   "PACE_PRIMARY_PACE_L": "#04046f",
   "PACE_COMPARISON_L": "#d0a683",
   "PACE_COMPARISON_L_DBA": "#d0a683",

   "Occ Market Today": "rgb(129, 212, 250)",


   //OTB left
   "OTB_PRIMARY_L": "rgb(129, 212, 250)",
   "OTB_PRIMARY_OTB_L": "rgb(129, 212, 250)",
   "OTB_PRIOR_L": "#2CA1D4",
   "OTB_SDLY_L": "rgb(41, 182, 246)",
   "OTB_SDLY_PRIOR_L": "#3366ff",
   "OTB_SDLY_ACTUAL_L": "rgb(2, 119, 189)",
   "OTB_V2_MULTI_SEGMENT_PRIMARY_L": "rgb(129, 212, 250)",
   "OTB_V2_MULTI_SEGMENT_SDLY_L":"rgb(41, 182, 246)",
   "OTB_V2_MULTI_SEGMENT_SDLY_ACTUAL_L":"rgb(2, 119, 189)",

   //OTB left Segmented
   "SEGMENT_TBAR": "#6A1B9A",
   "SEGMENT_CAIR": "#3949AB",
   "SEGMENT_CCOR": "#c97ff5",
   "SEGMENT_COMP": "#2f0c45",
   "SEGMENT_COTH": "#3366ff",
   "SEGMENT_GASC": "#81D4FA",
   "SEGMENT_GCOR": "#29B6F6",
   "SEGMENT_GGOV": "#8E24AA",
   "SEGMENT_GOTH": "#CE93D8",
   "SEGMENT_GSMF": "#AB47BC",
   "SEGMENT_GTRT": "#6A1B9A",
   "SEGMENT_HUSE": "#edd4fc",
   "SEGMENT_Other": "#0277BD",
   "SEGMENT_PSBY": "rgb(2, 119, 189)",
   "SEGMENT_TCON": "#8E24AA",
   "SEGMENT_TDIS": "#3949AB",
   "SEGMENT_TFIT": "#5C6BC0",
   "SEGMENT_TGOV": "#CE93D8",
   "SEGMENT_TNOP": "#9FA8DA",
   "SEGMENT_TOPA": "#0277BD",
   "SEGMENT_TOTH": "rgb(2, 119, 189)",
   "SEGMENT_TPKG": "#283593",
   "SEGMENT_TPRC": "#AB47BC",
   "SEGMENT_TPRM": "rgb(2, 119, 189)",
   "SEGMENT_CONTRACT": "#283593",
   //indexed based colors for segments
   "SEGMENT_0": "#6A1B9A",
   "SEGMENT_1": "#3949AB",
   "SEGMENT_2": "#c97ff5",
   "SEGMENT_3": "#2f0c45",
   "SEGMENT_3": "#3366ff",
   "SEGMENT_4": "#81D4FA",
   "SEGMENT_5": "#979704",
   "SEGMENT_6": "#CE93D8",
   "SEGMENT_7": "#8E24AA",
   "SEGMENT_8": "#AB47BC",
   "SEGMENT_9": "#6A1B9A",
   "SEGMENT_10": "#edd4fc",
   "SEGMENT_Other": "#62aef9",
   "SEGMENT_11": "#4e8bc7",
   "SEGMENT_12": "#8E24AA",
   "SEGMENT_13": "#3949AB",
   "SEGMENT_14": "#5C6BC0",
   "SEGMENT_15": "#CE93D8",
   "SEGMENT_16": "#731da7",
   "SEGMENT_17": "#0277BD",
   "SEGMENT_18": "rgb(2, 119, 189)",
   "SEGMENT_19": "#283593",
   "SEGMENT_20": "#AB47BC",
   "SEGMENT_21": "rgb(2, 119, 189)",
   "SEGMENT_22": "#283593",
   "SEGMENT_23": "#6A1B9A",
   "SEGMENT_24": "#3949AB",
   "SEGMENT_25": "#c97ff5",

   //PICKUP left
   "PICKUP_PRIMARY_L": "rgb(129, 212, 250)",
   "PICKUP_PICKUP_L": "rgb(2, 119, 189)",
   "PICKUP_SDLY_L": "rgb(41, 182, 246)",
   "PICKUP_SDLY_PICKUP_L": "#3366ff",
   "PICKUP_SDLY_ACTUAL_L": "rgb(159, 168, 218)",
   "PICKUP_PICKUP_DIFF_L": "#029cfb",
   "PICKUP_SDLY_PICKUP_DIFF_L": "#016db0",

   //Forecast left
   "FORECAST_PRIMARY_L": "#4f9dcf",
   "FORECAST_PRIMARY_FORECAST_L": "#4f9dcf",

   //Market left 
   "MARKET_PRIMARY_L": "#A6B4F7",
   "MARKET_PRIMARY_MARKET_L": "#A6B4F7",
   "MARKET_PRIMARY_MARKET_L_DBA":  "#b03176",
   "MARKET_SDLY_L": "#01415E",
   "MARKET_SDLY_MARKET_L": "#01415E",
   "MARKET_SDLY_ACTUAL_L": "#B040F3",
   "MARKET_SDLY_ACTUAL_MARKET_L": "#B040F3",
   "MARKET_MARKET_FORECAST_L": "#7F0428",

   "MARKET_PRIMARY_L_DBA": "#3176B0",
   "MARKET_SDLY_L_DBA": "#afb42b",
   // "MARKET_SDLY_ACTUALS_L_DBA": "#b06b31",
   // "MARKET_MARKET_FORECAST_L_DBA": "rgb(2, 119, 189)",
};
export const rightMeasureColors = {
   "Occ OTB TODAY": "rgb(129, 212, 250)",
   "Occ OTB PRIOR": "#2CA1D4",
   "Occ OTB SDLY": "rgb(41, 182, 246)",
   "Occ OTB SDLY PRIOR": "#3366ff",
   "Occ OTB SDLY ACTUAL": "rgb(2, 119, 189)",

   "OTB_PRIMARY_OTB_R": "#f53072",
   // primary_otb_adr"
   "ADR OTB TODAY": "rgb(255, 87, 34)",
   "ADR OTB PRIOR": "rgb(255, 193, 7)",
   "ADR OTB SDLY": "#FF9800",
   "ADR OTB SDLY ACTUAL": "#FFC107",
   "ADR OTB SDLY PRIOR": "#cc0066",

   "RevPAR OTB TODAY": "rgb(255, 87, 34)",
   "RevPAR OTB PRIOR": "#8f1727",
   "RevPAR OTB SDLY": "#FF9800",
   "RevPAR OTB SDLY ACTUAL": "#FFC107",
   "RevPAR OTB SDLY PRIOR": "#cc0066",

   "SellRate OTB TODAY": "rgb(255, 87, 34)",
   "SellRate OTB PRIOR": "#8f1727",
   "SellRate OTB SDLY": "#FF9800",
   "SellRate OTB SDLY ACTUAL": "#FFC107",
   "SellRate OTB SDLY PRIOR": "#cc0066",

   "Revenue OTB TODAY": "rgb(129, 212, 250)",
   "Revenue OTB PRIOR": "#8f1727",
   "Revenue OTB SDLY": "#FF9800",
   "Revenue OTB SDLY ACTUAL": "#FFC107",
   "Revenue OTB SDLY PRIOR": "#cc0066",

   "Occ Market TODAY": "rgb(129, 212, 250)",
   "Occ Market PRIOR": "#2CA1D4",
   "Occ Market SDLY": "rgb(41, 182, 246)",
   "Occ Market SDLY PRIOR": "#3366ff",
   "Occ Market SDLY ACTUAL": "rgb(2, 119, 189)",

   "ADR Market TODAY": "rgb(255, 87, 34)",
   "ADR Market PRIOR": "#8f1727",
   "ADR Market SDLY": "#FF9800",
   "ADR Market SDLY ACTUAL": "#FFC107",
   "ADR Market SDLY PRIOR": "#cc0066",

   //OTB Right
   "OTB_PRIMARY_R": "rgb(255, 87, 34)",
   "OTB_PRIOR_R": "#2CA1D4",
   "OTB_SDLY_R": "rgb(255, 152, 0)",
   "OTB_SDLY_PRIOR_R": "#3366ff",
   "OTB_SDLY_ACTUAL_R": "rgb(255, 193, 7)",

   //Multi Segment 
   "OTB_V2_MULTI_SEGMENT_PRIMARY_R": "rgb(255, 87, 34)",
   "OTB_V2_MULTI_SEGMENT_SDLY_R":"rgb(255, 152, 0)",
   "OTB_V2_MULTI_SEGMENT_SDLY_ACTUAL_R":"rgb(255, 193, 7)",
   //PICKUP Right
   "PICKUP_PRIMARY_R": "rgb(255, 87, 34)",
   "PICKUP_PICKUP_R": "rgb(255, 152, 0)",
   "PICKUP_SDLY_R": "rgb(255, 193, 7)",
   "PICKUP_SDLY_PICKUP_R": "rgb(255, 235, 59)",
   "PICKUP_SDLY_ACTUAL_R": "rgb(205, 220, 57)",
   "PICKUP_PICKUP_DIFF_R": "#a814b0",
   "PICKUP_SDLY_PICKUP_DIFF_R": "#f233fb",

   //PACE
   "PACE_PRIMARY_R": "#e45106",
   "PACE_PRIMARY_PACE_R": "#046f6f",
   "PACE_COMPARISON_R": "#8c9022",

   //Forecast
   "FORECAST_PRIMARY_FORECAST_R": "rgb(160, 31, 74)",

   //We can override colors for specific measures as well like this measureName + SeriesName
   // "SellRate PACE": "#fd7800",
   // "SellRate SDLY PACE": "#7f3c00",
   // "SellRate COMPARISON PACE": "#7800fd",

   //Compset
   "COMPSET MEDIAN": "#FFEB3B",

   //Market right
   "MARKET_PRIMARY_R": "#4F598A",
   "MARKET_PRIMARY_MARKET_R": "#4F598A",
   "MARKET_SDLY_R": "#D47ED4",
   "MARKET_SDLY_MARKET_R": "#D47ED4",
   "MARKET_SDLY_ACTUAL_R": "#F380A2",
   "MARKET_SDLY_ACTUAL_MARKET_R": "#F380A2",
   "MARKET_MARKET_FORECAST_R": "#A23354",

   //Forecast right
   "FORECAST_PRIMARY_R": "#a01f4a",
   // "FORECAST_PRIMARY_R": "#956cf5",

   //Market sell rates right
   "MARKET_SELL_RATES_3_STAR_R": "rgb(255, 87, 34)",
   "MARKET_SELL_RATES_4_STAR_R": "rgb(255, 152, 0)",
   "MARKET_SELL_RATES_5_STAR_R": "#2CA1D4",
};