
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import ConnectingAirportsSharpIcon from '@mui/icons-material/ConnectingAirportsSharp';
import FlightIcon from '@mui/icons-material/Flight';
import { Button, Box, Modal, FormControl, Select, Typography, Container, Grid } from '@mui/material'
import Chip from '@mui/material/Chip';
import { Tooltip } from '@mui/material';

const AirportCodes = () => {
    const controls = useSelector(state => state.app.current_state.app_state.workspace_controls);
    const { app_state } = useSelector(
        (state) => state.app.current_state
    );
    return (
        <>

            {app_state.workspace_controls.airport_codes && app_state.workspace_controls.airport_codes.length > 0 && (
                <Box sx={{ position: "absolute",top: 0 }}>
                    <Box sx={{ display: 'block', fontSize: '75%', fontWeight: 'bold', minHeight: .6,mt:.7,pl:2 }}>Airport Codes</Box>

                    {app_state.workspace_controls.airport_codes.map((item, index) => (
                        <Tooltip title={item} arrow followCursor>
                            <Chip icon={<FlightIcon />} size="small" color="primary" label={item} sx={{ mt:.9,ml:1 }} />
                        </Tooltip>
                    )
                    )}
                </Box>

            )}
        </>
    )
}

export default AirportCodes