import React, { useEffect, useRef, useState } from "react";
import moment from "moment";
import { UPDATE_STATE } from "../../actions/types";
import { useSelector, useDispatch } from "react-redux";
import { Grid, Box, CircularProgress, Fade, Tooltip } from "@mui/material/";
import { useNavigate } from "react-router-dom";
import { logout } from "../../actions/auth";
import { API_REGISTRY, api_status, promises } from "../../app/config";
import { sdly, getPermissions } from "../../app/util";
//Global custom theme configuration
import theme from "../../theme";
import icons from "../../components/Icons/styles.css";
import "./style.css";
import { getData, updateAppState } from "../../actions/appstate";
import {
    FlipCameraIosOutlined as FlipCameraIosOutlinedIcon,
    MergeSharp as MergeSharpIcon,
    CallMergeSharp as CallMergeSharpIcon
} from '@mui/icons-material';

import {
    processKpiData,
    processOTBKpiData,
    useDataSummary,
} from "./kpiService";
import dataService from "../../services/data.service";
const color = theme.palette;

const Tile = (props) => {
    const {
        id,
        label,
        labelSize,
        iconClass,
        iconSize,
        ml,
        mb,
        p,
        pt,
        pb,
        class_name,
        mergeable,
    } = props.tile;
    let { variant, compatibility } = props;
    const { app_state, ui_state } = useSelector(
        (state) => state.app.current_state
    );
    // debugger;
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const [otbKpiData, setOtbKpiData] = useState({ otb: {}, forecast: {} });
    const [compState, setCompState] = useState({ tileClass: "" });

    const { auth } = useSelector((state) => state);
    const { profile } = auth;
    const { permissions, index } = getPermissions(profile.property_details, app_state.property_id);

    //let otbData = useDataSummary("OSR", id);

    const {
        property_id,
        primary_stay_range,
        as_of_date,
        kpi_load,
        workspace_controls,
        data_load,
    } = app_state;
    const { active_tiles } = ui_state;

    // useEffect(() => {
    //   let isSubscribed = true;
    //   if (id === "otb" && otbData.loaded) {
    //     otbKpiData.otb = otbData.data;
    //     setOtbKpiData({ ...otbKpiData, otb: otbData.data });
    //   }
    //   return () => (isSubscribed = false);
    // }, [otbData.loaded]);

    const getTileClass = () => {
        let className = "";
        let p = props;
        if (active_tiles.includes(id)) {
            className += " active";
        }
        if (mergeable) {
            className += " mergeable";
        }
        return className;
    };

    const onDragStartHandler = (meta) => {
        return (event) => {
            // event.preventDefault();
            event.dataTransfer.setData("tileDetails", JSON.stringify(meta));
            // debugger;
        }
    };

    const drawerTile = (
        <Box sx={{ height: "100%" }}>
            <Box
                data-id={id}
                sx={{
                    pl: 1,
                    pt: "8px",
                    pb: "8px",
                    mb: 0,
                    background:
                        props.activeTile == id ? color.blue.main : color.dark.main,
                    color: color.white.main,
                    width: "100%",
                }}
            >
                <Box

                    display={"flex"}
                    className="mytile"
                    sx={{
                        cursor: "pointer",
                        height: "100%",
                        textAlign: "center",
                        fontSize: labelSize,
                        textTransform: "uppercase",
                        width: "100%",
                        height: "100%",
                        padding: 0,
                    }}
                >
                    <div draggable="true">
                        <Box
                            sx={{
                                fontSize: "30px",
                                color:
                                    props.activeTile == id ? color.white.main : color.blue.main,
                            }}
                        >
                            <Box
                                className={iconClass}
                                sx={{ width: "35px", height: "30px", backgroundSize: "contain" }}
                            ></Box>
                        </Box>
                        <Box sx={{ pl: 2, pt: 1, fontSize: 14 }}>{label}</Box>
                    </div>
                </Box>
            </Box>
        </Box>
    );

    // if budget is not enable or uploaded or segmented view in on then unable to merge 
    compatibility = (props.tile.id === "budget" && (permissions.budget === 0 || app_state.workspace_controls.segmented_view)) ? false : compatibility ;
    if(['pricing', 'restrictions', 'flights', 'hotel_summary'].includes(props.tile.id)){
        compatibility = false;
    }
    const simpleTile = (
        <Box sx={{ height: "100%", position: 'relative' }} className={class_name + " tile-wrap"}>
            {mergeable && compatibility && <Tooltip title="Ready for Intellimerge"><CallMergeSharpIcon className="ready-intellimerge-icon" color="blue" fontSize="18" sx={{ opacity: 0.5, position: 'absolute', right: 3, top: 3 }} /></Tooltip>}
            {mergeable && !compatibility && <Tooltip title="incompatible for Intellimerge"><CallMergeSharpIcon className="incompatible-intellimerge-icon" color="red" fontSize="18" sx={{ opacity: 0.5, position: 'absolute', right: 3, top: 3 }} /></Tooltip>}
            {mergeable && compatibility && ui_state.active_tiles.length > 1 && ui_state.active_tiles.includes(id) && <Tooltip title="Intellimerged"><MergeSharpIcon className="intellimerged-icon" fontSize="10" sx={{ opacity: 1, position: 'absolute' }} /></Tooltip>}
            {/* {mergeable && <Tooltip title="Ready for Intellimerge"><MergeSharpIcon color="blue" fontSize="10" sx={{opacity:0.7,position:'absolute', right:3, top:3}} /></Tooltip>} */}
            <Box
                draggable={mergeable && compatibility && !ui_state.active_tiles.includes(id)}
                onDragStart={onDragStartHandler(props.tile)}
                data-id={id}
                className={compState.tileClass}
                sx={{
                    ml: ml,
                    mb: mb,
                    background: color.dark.main,
                    // props.activeTile == id ? color.blue.main : color.dark.main,
                    //minHeight: `calc(100% - ${pb*8}px)`,
                    p: p,
                    pt: pt,
                    pb: pb,
                    color: color.white.main,
                    borderRadius: 1,
                }}
            >
                <Box
                    className="active-filter"
                    sx={{
                        cursor: "pointer",
                        textAlign: "center",
                        fontSize: labelSize,
                        textTransform: "uppercase",
                        width: "100%",
                        padding: 0,
                    }}
                >
                    <Box
                        sx={{
                            clear: "both",
                            color:
                                props.activeTile == id ? color.white.main : color.blue.main,
                            minHeight: `calc(100% - ${mb})`,
                        }}
                        className="icon-wrapper"
                    >
                        <span className={iconClass}></span>
                        {/* {(id === "hotel_summary" && <span><FlipCameraIosOutlinedIcon sx={{ position: "absolute", top: -21, right: -6 }} /> </span>)} */}
                    </Box>
                    <Box className="tile-label">{label}</Box>
                </Box>
            </Box>
        </Box>
    );
    const otbDataDisplay = (
        <>
            {" "}
            <Grid item xs={2}>
                <div className="main-number">{otbKpiData.otb.occAvg}%</div>
                <div className="secondry-number">{otbKpiData.otb.occYOY}% YOY</div>
            </Grid>
            <Grid item xs={2}>
                <div className="main-number">${otbKpiData.otb.revenue}</div>
                <div className="secondry-number">{otbKpiData.otb.revenueYOY}% YOY</div>
            </Grid>
            <Grid item xs={2}>
                <div className="main-number">${otbKpiData.otb.adrAvg}</div>
                <div className="secondry-number">{otbKpiData.otb.adrYOY}% YOY</div>
            </Grid>
            <Grid item xs={2}>
                <div className="main-number">${otbKpiData.otb.revparAvg}</div>
                <div className="secondry-number">{otbKpiData.otb.revparYOY}% YOY</div>
            </Grid>
        </>
    );
    const forecastDataDisplay = (
        <>
            {" "}
            <Grid item xs={2}>
                <div className="main-number">{otbKpiData.forecast.occAvg} %</div>
                <div className="secondry-number">
                    {otbKpiData.forecast.occYOYSdly} % / {otbKpiData.forecast.occYOY} %
                </div>
            </Grid>
            <Grid item xs={2}>
                <div className="main-number">${otbKpiData.forecast.revenue}</div>
                <div className="secondry-number">
                    {otbKpiData.forecast.revenueYOYSdly} % /{" "}
                    {otbKpiData.forecast.revenueYOY} %
                </div>
            </Grid>
            <Grid item xs={2}>
                <div className="main-number">${otbKpiData.forecast.adrAvg}</div>
                <div className="secondry-number">
                    {otbKpiData.forecast.adrYOYSdly} % / {otbKpiData.forecast.adrYOY} %
                </div>
            </Grid>
            <Grid item xs={2}>
                <div className="main-number">${otbKpiData.forecast.revparAvg}</div>
                <div className="secondry-number">
                    {otbKpiData.forecast.revenueYOYSdly} % /{" "}
                    {otbKpiData.forecast.revenueYOY} %
                </div>
            </Grid>
        </>
    );
    const otbTile = (
        <Box className={class_name + " tile-wrap"} sx={{ position: 'relative' }}>
            {mergeable && compatibility && <Tooltip title="Ready for Intellimerge"><CallMergeSharpIcon className="ready-intellimerge-icon" color="blue" fontSize="18" sx={{ opacity: 0.5, position: 'absolute', right: 3, top: 3 }} /></Tooltip>}
            {mergeable && !compatibility && <Tooltip title="incompatible for Intellimerge"><CallMergeSharpIcon className="incompatible-intellimerge-icon" color="red" fontSize="20" sx={{ opacity: 0.5, position: 'absolute', right: 2, top: 2 }} /></Tooltip>}
            {mergeable && compatibility && ui_state.active_tiles.length > 1 && ui_state.active_tiles.includes(id) && <Tooltip title="Intellimerged"><MergeSharpIcon className="intellimerged-icon" fontSize="10" sx={{ opacity: 1, position: 'absolute'}} /></Tooltip>}
            <Box
                draggable={mergeable && compatibility && !ui_state.active_tiles.includes(id)}
                onDragStart={onDragStartHandler(props.tile)}
                className={compState.tileClass}
                data-id={id}
                sx={{
                    background: color.dark.main,
                    // props.activeTile == id ? color.blue.main : color.dark.main,
                    ml: ml,
                    mb: mb,
                    p: p,
                    pt: pt,
                    pb: pb,
                    color: color.white.main,
                    borderRadius: 1,
                    height: `calc(100% - ${pb * 8}px)`,
                }}
            >


                <Box
                    className="otb-container-main"
                    sx={{
                        cursor: "pointer",
                        textAlign: "center",
                        fontSize: labelSize,
                        width: "100%",
                        height: "100%",
                        padding: 0,
                        position: "relative",
                        color: "blue.Tertiary"
                    }}

                >
                    <Box className="container-pt-vh">
                        <Grid
                            container
                            justifyContent="space-between"
                            className="tile-title-row"
                        >
                            <Grid
                                item
                                xs={1}
                                sx={{ textAlign: "left", color: "grey.grey1" }}
                            ></Grid>
                            <Grid item xs={2} className="otb-fs">
                                Occupancy
                            </Grid>
                            <Grid item xs={2} className="otb-fs">
                                Revenue
                            </Grid>
                            <Grid item xs={2} className="otb-fs">
                                Avg. Daily Rate
                            </Grid>
                            <Grid item xs={2} className="otb-fs">
                                RevPAR
                            </Grid>
                        </Grid>
                        <Grid container justifyContent="space-between">
                            <OtbTileSummary
                                date_range={app_state.primary_stay_range}
                                as_of_date={app_state.as_of_date}
                            />
                        </Grid>

                        {/* <img
              src={`assets/img/otb-tile-ph.png`}
              alt={`OTB`}
              width="97%" 
              loading="lazy" style={{maxHeight:'75.5px', marginTop:'30px'}}
            /> */}
                    </Box>
                    <Box className="container-pt-vh" sx={{ pb: 0.6, width: '100%', color: 'white.main' }}>
                        <OtbTileForecastSummary
                            date_range={app_state.primary_stay_range}
                            as_of_date={app_state.as_of_date}
                        />
                        {/* <img
              src={`assets/img/otb-tile-ph.png`}
              alt={`OTB`}
              width="97%" 
              loading="lazy" style={{maxHeight:'75.5px', marginTop:'30px'}}
            /> */}
                    </Box>
                </Box>
            </Box>
        </Box>
    );

    if (variant == "drawer") {
        return drawerTile;
    }
    if (!variant && id === "otb") {
        return otbTile;
    } else {
        return simpleTile;
    }
};
export default Tile;

function OtbTileSummary(props) {
    const { app_state, ui_state } = useSelector(
        (state) => state.app.current_state
    );
    const dispatch = useDispatch();
    const [calculated, setCalculated] = useState({ status: "idle", data: {} });

    //let otbData = useDataSummary("OSR", 'otb');
    let { date_from, date_until } = app_state.primary_stay_range;
    // const { as_of_date } = app_state;
    const { as_of_date, data_status, kpi_load, data_load } = app_state;


    useEffect(() => {
        let isSubscribed = true;
        // debugger;
        if (!kpi_load && app_state.kpi_status['OSR'] === 'error') {
            //setCalculated({ status: "loaded", data: {} });
        }
        if (app_state.kpi_status['OSR'] === 'loading') {
            setCalculated({ status: "loading", data: {} });
        }
        if (app_state.kpi_status['OSR'] === 'loaded') {

            processKpiData("OSR", app_state.primary_stay_range, '')
                .then((kpiData) => {
                    // debugger;
                    if (kpiData && kpiData.loaded) {
                        setCalculated({ status: "loaded", data: kpiData.data });
                    }

                });
        }
        return () => (isSubscribed = false);
    }, [app_state.kpi_status['OSR']]);


    let dataDisplay = (
        <>
            <Grid item xs={2} className='otb-fs'>
                <div className="main-number otb-number-fs">
                    <strong>{calculated.data.occAvg}%</strong>
                    <span className="secondry-number otb-number-yoy">
                        {calculated.data.occYOY > 0 ? (
                            <span>
                                {calculated.data.occYOY}% YOY
                                <span class="up-arrow"> <i class="ph ph-arrow-up"></i></span>
                            </span>
                        ) : (
                            <span>
                                {calculated.data.occYOY}% YOY
                                <span class="down-arrow"> <i class="ph ph-arrow-down"></i></span>
                            </span>
                        )}
                    </span>
                </div>
            </Grid>

            <Grid item xs={2}>
                <div className="main-number otb-number-fs">
                    <strong>{ui_state.currency_symbol}{calculated.data.revenue}</strong>
                    <span className="secondry-number otb-number-yoy">
                        {calculated.data.revenueYOY > 0 ? (
                            <span>
                                {calculated.data.revenueYOY}% YOY
                                <span class="up-arrow"> <i class="ph ph-arrow-up"></i></span>
                            </span>
                        ) : (
                            <span>
                                {calculated.data.revenueYOY}% YOY
                                <span class="down-arrow"> <i class="ph ph-arrow-down"></i></span>
                            </span>
                        )}
                    </span>
                </div>
            </Grid>

            <Grid item xs={2}>
                <div className="main-number otb-number-fs">
                    <strong>{ui_state.currency_symbol}{calculated.data.adrAvg}</strong>
                    <span className="secondry-number otb-number-yoy">
                        {calculated.data.adrYOY > 0 ? (
                            <span>
                                {calculated.data.adrYOY}% YOY
                                <span class="up-arrow"> <i class="ph ph-arrow-up"></i></span>
                            </span>
                        ) : (
                            <span>
                                {calculated.data.adrYOY}% YOY
                                <span class="down-arrow"> <i class="ph ph-arrow-down"></i></span>
                            </span>
                        )}
                    </span>
                </div>
            </Grid>

            <Grid item xs={2}>
                <div className="main-number otb-number-fs">
                    <strong>{ui_state.currency_symbol}{calculated.data.revparAvg}</strong>
                    <span className="secondry-number otb-number-yoy">
                        {calculated.data.revparYOY > 0 ? (
                            <span>
                                {calculated.data.revparYOY}% YOY
                                <span class="up-arrow"> <i class="ph ph-arrow-up"></i></span>
                            </span>
                        ) : (
                            <span>
                                {calculated.data.revparYOY}% YOY
                                <span class="down-arrow"> <i class="ph ph-arrow-down"></i></span>
                            </span>
                        )}
                    </span>
                </div>
            </Grid>
        </>
    );
    return (
        <Grid
            container
            justifyContent="space-between"
            className="tile-main-number-row"
        >
            <Grid
                item
                xs={1}
                sx={{ textAlign: "left", color: "dark.main" }}
                className="number-title"
            >
                <div className="otb-tile-row otb-fs" style={{ paddingLeft: "2px" }}>
                    OTB
                </div>
            </Grid>

            {app_state.kpi_status['OSR'] === 'loaded' && calculated.status === "loaded" && dataDisplay}



            {app_state.kpi_status['OSR'] === 'loading' && calculated.status !== "loaded" && (
                <Grid item xs={10}>
                    <Fade in={true} timeout={500}>
                        <Box className="otb-loader">loading...</Box>
                    </Fade>

                </Grid>
            )}
        </Grid>
    );
}

function OtbTileForecastSummary(props) {
    const { app_state, ui_state } = useSelector(
        (state) => state.app.current_state
    );
    const { auth } = useSelector((state) => state);
    const { profile } = auth;
    const { permissions, index } = getPermissions(profile.property_details, app_state.property_id);
    // debugger;
    const dispatch = useDispatch();
    const [calculated, setCalculated] = useState({ status: "idle", data: {} });
    const [otbData, setOtbData] = useState(null);
    //let forecastData = useDataSummary("OSRContrainedForecast");
    const { kpi_load, as_of_date, property_id, primary_stay_range, data_status, data_load } = app_state;
    const { left_measure, right_measure, table_view, workspace_view, showspinner } = ui_state;
    let { date_from, date_until } = primary_stay_range;
    if (moment(date_from).diff(moment()) < 0) {
        date_from = moment().format("YYYY-MM-DD");
    }

    useEffect(() => {
        let isSubscribed = true;
        if (isSubscribed) {
            if (!kpi_load && app_state.kpi_status['OSRContrainedForecast'] === 'error') {
                setCalculated({ status: "error", data: {} });
            }
            if (app_state.kpi_status['OSRContrainedForecast'] === 'loading') {
                setCalculated({ status: "loading", data: {} });
            }
            if (app_state.kpi_status['OSRContrainedForecast'] === 'loaded' && app_state.kpi_status['OSR'] === 'loaded') {
                processKpiData("OSR", app_state.primary_stay_range, '')
                    .then((otbDataTmp) => {
                        // setOtbData(otbDataTmp)
                        // for forecast //
                        processKpiData("OSRContrainedForecast", app_state.primary_stay_range, otbDataTmp)
                            .then((kpiData) => {
                                // console.log("KPIDATA =>", kpiData);
                                if (!kpiData) {
                                    setCalculated({ status: "notfound", data: {} });
                                }
                                if (kpiData && kpiData.data) {
                                    setCalculated({ status: "loaded", data: kpiData.data });
                                }
                            });
                        // end forecast
                    });

            }
        }

        return () => (isSubscribed = false);
    }, [app_state.kpi_status['OSRContrainedForecast'], app_state.kpi_status['OSR']]);

    // console.log('state in Tiles: ', app_state,ui_state);

    let dataDisplay = (
        <>
            <Grid item xs={2}>
                <div className="main-number otb-number-fs">
                    <strong>{calculated.data.occAvg}%</strong>
                    <span className="secondry-number otb-number-yoy">
                        {calculated.data.occYOYSdly}% / {calculated.data.occYOY}%
                    </span>
                </div>
            </Grid>
            <Grid item xs={2}>
                <div className="main-number otb-number-fs">
                    <strong>{ui_state.currency_symbol}{calculated.data.revenue}</strong>
                    <span className="secondry-number otb-number-yoy">
                        {calculated.data.revenueYOYSdly}% / {calculated.data.revenueYOY}%
                    </span>
                </div>

            </Grid>
            <Grid item xs={2}>
                <div className="main-number otb-number-fs">
                    <strong>{ui_state.currency_symbol}{calculated.data.adrAvg}</strong>
                    <span className="secondry-number otb-number-yoy">
                        {calculated.data.adrYOYSdly}% / {calculated.data.adrYOY}%
                    </span>
                </div>
            </Grid>
            <Grid item xs={2}>
                <div className="main-number otb-number-fs">
                    <strong>{ui_state.currency_symbol}{calculated.data.revparAvg}</strong>
                    <span className="secondry-number otb-number-yoy">
                        {calculated.data.revparYOYSdly}% / {calculated.data.revparYOY}%
                    </span>
                </div>

            </Grid>
        </>
    );
    return (
        <Grid
            container
            justifyContent="space-between"
            className="tile-main-number-row"
        >
            <Grid
                item
                xs={1}
                sx={{ textAlign: "left", color: "dark.main" }}
                className="number-title"
            >
                <div className="otb-tile-row otb-fs" style={{ paddingLeft: "2px" }}>
                    FORECAST
                    
                    <small style={{display: 'block'}}>VS. SDLY/OTB</small>
                </div>
            </Grid>
            {permissions && permissions.hfc == 1 && <>
                {calculated.status === "loading" && (
                    <Grid item xs={10}>
                        <Box className="otb-loader">loading...</Box>
                    </Grid>
                )}
                {calculated.status === "error" && (
                    <Grid item xs={10}>
                        <Box className="otb-loader">Please wait, Calculating the data ... </Box>
                    </Grid>
                )}

                {calculated.status === "notfound" && (
                    <Grid item xs={10}>
                        <Box className="otb-loader">Forecast data not available! </Box>
                    </Grid>
                )}
                {calculated.status === "loaded" && calculated.data && dataDisplay}
            </>}

            {permissions && permissions.hfc == 0 && <>
                <Grid item xs={10}>
                    <Box className="otb-loader">Forecast data not available! </Box>
                </Grid>
            </>}


        </Grid>
    );
}

