
import { Box, Select, FormControl, MenuItem, Tooltip } from '@mui/material'
import { useState } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { updateAppState } from '../../../../actions/appstate';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import Chip from '@mui/material/Chip';
import Grid from '@mui/material/Grid';
import moment from 'moment';

const MultiSegmentPicker = () => {
    const controls = useSelector(state => state.app.current_state.app_state.workspace_controls);
    const [segmentType, setSegmentType] = useState(controls.segmentType);
    const dispatch = useDispatch();

    const state = useSelector(appState => appState);
    const { auth, app } = useSelector(state => state);
    const { profile } = auth;
    const { app_state, ui_state } = useSelector(
        (state) => state.app.current_state
    );

    const handleChange = (type, value) => {
        controls.segmentType = value;
        controls.segmented_view = (value === "hotel") ? false : true;
       
        // controls.sdYear = moment().subtract(1, 'year').format("YYYY");
        dispatch(updateAppState(type,
            { app_state: { workspace_controls: controls, data_load: true }, ui_state: { showspinner: true} }));
    }


    return (
        <>
            <Box id="tabletype" sx={{ position: "relative", paddingLeft: 0, backgroundColor: 'blue', height: 1 / 2 }}>
                <Box sx={{ display: 'block', fontSize: '75%', fontWeight: 'bold' }}>Data Level </Box>
                <FormControl size="small" sx={{ minWidth: "100%", borderRadius: "16px" }}>
                    <Select className='tabular-data-filter' fullWidth value={segmentType} sx={{ height: "27px", mt: "5px" }} onChange={(e) => {
                        setSegmentType(e.target.value);
                        handleChange("segmentType", e.target.value);
                    }}>

                        <MenuItem value="hotel" className='tabular-data-filter'> Hotel</MenuItem>
                        <MenuItem value="top_market_segment" className='tabular-data-filter'> Top Segments </MenuItem>
                        <MenuItem value="market_segment" className='tabular-data-filter'> Segments </MenuItem>
                        {/* {(app_state.workspace_controls.rate_plan === 1 &&
                            <MenuItem value="rate_plan" className='tabular-data-filter'> Rate Plan</MenuItem>
                        )} */}
                        {<MenuItem value="rate_plan" className='tabular-data-filter'> Rate Plan</MenuItem>}
                        {/* {(app_state.workspace_controls.room_type === 1 && 
                            <>*/}
                            <MenuItem value="room_class" className='tabular-data-filter'> Room Class </MenuItem>
                            <MenuItem value="room_type" className='tabular-data-filter'> Room Type </MenuItem>
                            {/* </>
                        )} */}
                    </Select>
                </FormControl>
            </Box>
        </>
    )
}

export default MultiSegmentPicker