import zIndex from "@mui/material/styles/zIndex";
import React from "react";

function Loading(props) {
  let leftStyle = (props.leftStyle) ? props.leftStyle : "37%";
  return (
    <div
      style={{
        textAlign: "center",
        width: "100%",
        height: "80%",
        position: "relative",
      }}
    >
      <div
        style={{
          textAlign: "center",
          position: "absolute",
          zIndex: 999,
          width: "300px",
          top: "30%",
          left: leftStyle,
          fontSize: 14,
        }}
      >
        <img
          src={`../../assets/img/chartLoading.svg`}
          alt={`Loading...`}
          loading="lazy"
          style={{ width: 70 }}
        />
        <div style={{ position: "relative", top: -20, color: "#afafaf" }}>
          {props.showText}
        </div>
      </div>
    </div>
  );
}

export default Loading;
