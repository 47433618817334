import {
   round, capitalizeFirstLetter, deepCopy, currencyFormat,
   getFromState, displayFormat, truncate, isEmptyObj, getSystemSettings, safeDivide, convertToPercent
} from "../../app/util";
import { TILES, CHART_OPTIONS } from "../../app/config";
import { chartColors, headerColors, leftMeasureColors, rightMeasureColors } from "./chart-options-config";
import moment from "moment";
import { breakpoints } from "@mui/system";
import WorkspaceControls from "../Workspace/WorkspaceControls";
import { getRestrictionsDisplay } from "./functions";
let dayOfWeekGlobal;
export function getOptions(displayData,
   { date_from, date_until, sdlyOn, compareTo, property_id, property_details, dayOfWeek,
      clearComparision, lv1_segGrouping, active_api, resource_id, scopeType,
      active_tiles, right_measure, left_measure, currency, segmentType,
      currency_symbol, segmented_view, pickUpType, segments, total_capacity }) {
   dayOfWeekGlobal = dayOfWeek;
   // debugger;   
   let total_market_capacity = 0;
   // if(active_tiles.includes('market')){
   //    let sorted = displayData.sort((a, b) => b.MarketTotal.primary.Rooms - a.MarketTotal.primary.Rooms); 
   //    total_market_capacity = sorted[0].MarketTotal.primary.Rooms;
   // } 
   //series is the set od data points for a line/column, initial measures will check the measures input to create the data sets
   let firstTile = active_tiles[0];
   const { chartConfig, data_sources } = TILES[firstTile];
   const compset_data_sources = TILES['compset'].data_sources;
   const series = [];

   // Setting up default measures
   let intialMeasures = [left_measure, right_measure];

   if (chartConfig.defaultMeasures) {
      //intialMeasures = [chartConfig.defaultMeasures[0], chartConfig.defaultMeasures[1]];
   }

   //setting identifiers to make chart dynamic for each tile
   // let identifiers = JSON.parse(JSON.stringify(chartConfig.identifiers));
   let identifiers = [];
   let segmentedIdentifiers = [{ name: '' }];
   let seriesLabel = capitalizeFirstLetter(firstTile);
   if (firstTile === "otb_v2_multi_segment" && segmentType === "hotel") {
      segmented_view = false;
      chartConfig.controlSupport.segmented_view = false;
   }
   if (firstTile === "otb_v2_multi_segment" && segmentType !== "hotel") {
      segmented_view = true;
      chartConfig.controlSupport.segmented_view = true;
   }
   if (segmented_view && chartConfig.controlSupport.segmented_view) {
      if (displayData[0].segmented) {

         segmentedIdentifiers = displayData[0].order;

      }
      console.log(segmentedIdentifiers)
      //identifiers = Object.keys(segments.map);
   }


   //As no pre defined identifiers is there for compset and it depends on compset names in API response
   //So need ot generate dynamically from response data
   // debugger
   if (active_tiles.length > 1) {
      active_tiles.map((tile) => {
         let extra = generateIdentifiers({ tile, data_id: active_api, displayData, resource_id });
         identifiers = [...identifiers, ...extra];
      });
      // debugger;  
   }
   //Handle single tile case
   else {
      active_tiles.map((tile) => {
         let extra = generateIdentifiers({ tile, data_id: active_api, displayData, resource_id, pickUpType });
         identifiers = [...identifiers, ...extra];
      });
   }
   // debugger; 
   //setting name is important for colors

   if (firstTile === 'otb' || firstTile === 'pickup') {
      seriesLabel = 'OTB'
   }

   const category = active_api + seriesLabel;
   let setYAxis = true
   const yAxisArr = [];

   // debugger;
   if (chartConfig.controlSupport && chartConfig.controlSupport.sdlyOn && sdlyOn === true) {
      identifiers = [];
      chartConfig.sdlyIdentifiers.map((identifier) => {
         if (!identifiers.includes(identifier)) {
            identifiers.push(identifier);
         }
      });
   }
   // Merging identifiers for pace
   if (firstTile === 'pace') {
      if (clearComparision) {
         identifiers = chartConfig.compareToIdentifiers;
      } else {
         identifiers = chartConfig.identifiers
      }

      // debugger;
   }
   if (firstTile === 'bi_sensitivity_analysis') {
      if (clearComparision) {
         identifiers = chartConfig.compareToIdentifiers;
      } else {
         identifiers = chartConfig.identifiers
      }
      let numIden = 0;
      displayData.forEach(item => {
         let numkeys = Object.keys(item[chartConfig.mainkey]).length;
         if (numkeys > numIden) {
            numIden = numkeys;
            identifiers = Object.keys(item[chartConfig.mainkey]);
         }
      })
      console.log("identifiers", identifiers);
      // debugger;
   }
   if (active_tiles.includes('market')) {
      // debugger
      if (scopeType === 'Dba') {
         identifiers = chartConfig['dba_identifiers'];
         if (sdlyOn) {
            identifiers = chartConfig['sdly_dba_identifiers'];
         }
      } else {
         if (sdlyOn) {
            identifiers = chartConfig['sdlyIdentifiers'];
         }
      }
      // debugger;
   }
   //mapping through each identifier, which should have 2 subset measures
   //therefore series should have 2 * n-identifiers length
   let s = segments;
   let seriesGroup = [];
   let measureL = intialMeasures[0];
   let measureR = intialMeasures[1];
   let pricingTodaySeries = [];
   let colorIndexCorrection = 0;
   // if(measureL === null && segmented_view){
   //    intialMeasures[0] = 'none';
   // }
   segmentedIdentifiers.map((orderdItem, segmentIndex) => {
      let segmentName = orderdItem.name;
      identifiers.map((identifier, identifierIndex) => {
         let seriesName = "";
         // debugger 
         //only setting y-axis in first iteration of identifiers
         intialMeasures.map((measure, measureIndex) => {
            // debugger;
            if (measure) {
               let seriesName = setSeriesName({ identifier, seriesLabel, tile: firstTile, scopeType, measure, segmented_view, segmentName });
               const seriesConfig = generateChartSeries({ data: displayData, tile: firstTile, identifier, identifierIndex, measure, measureR, measureIndex, seriesLabel, segmented_view, segmentName, chartConfig });
               seriesConfig.colorKey = setChartSeriesColorKey({ identifier, identifierIndex, measure, measureIndex, seriesName, resource_id, tile: firstTile, });
               seriesConfig.mainCKey = setChartSeriesColorKey({ isMainColor: true, identifier, identifierIndex, measure, measureIndex, seriesName, resource_id, tile: firstTile, });
               // seriesConfig.id = segmented_view ? segmentName + "-" + seriesName + '_' + identifier + measureIndex : seriesName + '_' + identifier + measureIndex;
               seriesConfig.currency = { code: currency, symbol: currency_symbol };
               seriesConfig.category = category;
               seriesConfig.seriesName = seriesLabel + " " + identifier;
               seriesConfig.mainMeasure = measure === 'Occ' ? measure + "%" : measure;
               seriesConfig.marker = { enabled: false };
               // seriesConfig.id = seriesLabel + " " + identifier;
               // seriesConfig.name = seriesLabel + " " + identifier + " " + measure;
               // debugger;
               //Grouping series for segmented view only
               if (segmented_view && chartConfig.controlSupport.segmented_view && segmentName !== "total") {
                  if (!seriesGroup.includes(segmentName)) {
                     seriesGroup.push(segmentName);
                     seriesConfig.id = segmentName;
                  } else {
                     seriesConfig.linkedTo = segmentName;
                     delete seriesConfig.id;
                  }
               }

               if (measureIndex === 0) {
                  //handle negative values
                  // seriesConfig.negativeColor = "#c10101";
                  seriesConfig.zIndex = 1;
                  seriesConfig.type = 'column';
                  // console.log(chartConfig);
                  // debugger;
                  if (chartConfig.chartOptions && chartConfig.chartOptions.xAxisType === "Dba") {
                     seriesConfig.type = 'areaspline';
                  }

                  //For segmented view
                  if (segmented_view) {
                     // debugger; 
                     seriesConfig.mainCKey = "SEGMENT_" + segmentIndex;
                     // seriesConfig.stacking = "normal";
                     //seriesConfig.legendLabel = seriesName;
                  }
                  //Setting colors for left measures
                  let lmc = leftMeasureColors[seriesConfig.mainCKey];
                  if (lmc) {
                     seriesConfig.color = lmc;
                     seriesConfig.negativeColor = lmc;
                  }

               }
               if (measureIndex === 1) {
                  seriesConfig.yAxis = "secondaryRightYAxis" + measure;
                  seriesConfig.type = 'spline';
                  seriesConfig.zIndex = 2;

                  //Setting colors for right measures
                  // debugger;  
                  let rmc = rightMeasureColors[seriesConfig.mainCKey];
                  if (rmc) {
                     seriesConfig["color"] = rmc;
                     seriesConfig.negativeColor = rmc;
                  }

               }


               //code for setting y-axis
               if (setYAxis) {
                  const yAxisConfig = deepCopy(CHART_OPTIONS.default.yAxis);
                  if (measureIndex == 0) {
                     let threshold = null;
                     if (measureL === 'Rooms' && !['market_sell_rate', 'market', 'compset'].includes(active_tiles[0])) {
                        threshold = total_capacity;
                        yAxisConfig["max"] = threshold;
                     }
                     if (measureL === 'Occ') {
                        threshold = 100;
                        yAxisConfig["max"] = threshold + 10;
                     }
                     yAxisConfig["id"] = "primaryYAxis" + measure;

                     yAxisConfig["plotLines"] = [
                        {
                           "value": threshold,
                           "color": "black",
                           "dashStyle": "shortdash",
                           "width": 2,
                           "label": {
                              "text": ""
                           },

                        }
                     ]
                  } else {
                     let threshold = null;
                     if (measureR === 'Rooms' && !['market_sell_rate', 'market', 'compset'].includes(active_tiles[0])) {
                        threshold = total_capacity;
                        yAxisConfig["max"] = threshold;
                     }
                     if (measureR === 'Occ') {
                        threshold = 100;
                     }
                     yAxisConfig["id"] = "secondaryRightYAxis" + measure;
                     //yAxisConfig["gridLineColor"] = "transparent";
                     yAxisConfig["opposite"] = true;
                     // yAxisConfig["max"] = threshold;
                     yAxisConfig["min"] = -(threshold);
                     if (active_tiles[0] === 'bi_sensitivity_analysis') {
                        yAxisConfig["min"] = 0;
                     }
                     yAxisConfig["plotLines"] = [
                        {
                           "value": threshold,
                           "color": "lightblue",
                           "dashStyle": "shortdash",
                           "width": 1,
                           "label": {
                              "text": ""
                           },

                        }
                     ]
                     if (!active_tiles.includes('pickup')) {
                        yAxisConfig.min = 0;
                     }

                     yAxisConfig["labels"] = {
                        format: '{value:.0f}'
                     };

                     setYAxis = false;
                  }
                  yAxisArr.push(yAxisConfig);
               }

               //setting data from identifier and measure into data to graph
               if (displayData.length) {
                  // debugger; 
                  let inputData = deepCopy([...displayData]);
                  if (segmented_view && displayData[0].index && displayData[0].segmented) {
                     let tmp = displayData.filter((di) => di.segmented[segmentName] ? true : false);
                     tmp = tmp.length ? tmp : [];
                     inputData = deepCopy(tmp);
                  }
                  let chartData = generateChartData(inputData, { chartConfig, identifier, measure, measureR, data_id: active_api, segmentName });
                  seriesConfig.data = chartData;
               }

               //seriesConfig["data"].sort((a,b) => a.x - b.x)
               // seriesConfig['marker'] = { enabled: false };
               // seriesConfig.visible = true;
               // seriesConfig.showInLegend = true;

               //Setting colors for the chart curves/columns
               let seriesConfigNew = { ...seriesConfig };
               //if(chartConfig[])

               // debugger;  
               if (measureIndex === 0) {
                  //lmc =  left measure color
                  // let lmc = headerColors[seriesName];
                  //seriesConfigNew.color = lmc;
                  //Take chart options for xAxis from chartConfig if exists
                  if (chartConfig.chartOptions && chartConfig.chartOptions.xAxis) {
                     // debugger;
                     let areaColor = leftMeasureColors[seriesConfigNew.mainCKey];
                     let copyChartOptions = deepCopy(chartConfig.chartOptions.xAxis);
                    
                     if (active_tiles.includes('market') && scopeType === 'Dba') {
                        seriesConfigNew = { ...seriesConfig, ...copyChartOptions };
                        areaColor = leftMeasureColors[seriesConfigNew.mainCKey + '_DBA'];
                     }
                     // debugger
                     if (active_tiles.includes('pace') && scopeType !== 'Date') {
                        seriesConfigNew = { ...seriesConfig, ...copyChartOptions };
                        // seriesConfigNew.type = 'area';
                     }

                     if (areaColor) {
                        seriesConfigNew.color = areaColor;
                     }

                     if (active_tiles[0] === "bi_sensitivity_analysis") {
                        let colorIndex = colorIndexCorrection;
                        colorIndexCorrection += 1;
                        if (headerColors.bi_sensitivity_colors.length < colorIndexCorrection) {
                           colorIndex = 0
                           colorIndexCorrection = 0;
                        }
                        areaColor = headerColors.bi_sensitivity_colors[colorIndex + 1]
                        areaColor = "#3176B5";
                        seriesConfigNew.fillColor={};
                        seriesConfigNew.fillColor.linearGradient={ x1: 0, x2: 0, y1: 0, y2: 1 }
                        seriesConfigNew.fillColor.stops = [
                           [0, areaColor],
                           [1, window.Highcharts.Color(areaColor).setOpacity(0).get('rgba')]
                        ];
                        seriesConfigNew.fillOpacity = 0.3
                     }
                     // debugger;
                     // seriesConfigNew.fillColor.linearGradient = [0, 0, 0, 200];
                     if (seriesConfigNew.fillColor) {
                        seriesConfigNew.fillColor.linearGradient={ x1: 0, x2: 0, y1: 0, y2: 1 }
                        seriesConfigNew.fillColor.stops = [
                           [0, areaColor],
                           [1, window.Highcharts.Color(areaColor).setOpacity(0).get('rgba')]
                        ];
                     }
                     
                  }
               }

               if (measureIndex === 1) {
                  //rmc =  right measure color
                  // let serConfName = seriesConfig[];
                  // let rmc = rightMeasureColors[seriesConfigNew.mainCKey];
                  //seriesConfigNew.color = rmc;
                  if (chartConfig.chartOptions && chartConfig.chartOptions.yAxis) {
                     let copyChartOptions = deepCopy(chartConfig.chartOptions.yAxis);
                     seriesConfigNew = { ...seriesConfig, ...copyChartOptions };
                  }
               }
               // debugger
               if (active_tiles[0] === "pricing" && chartColors[seriesConfigNew.colorKey]) {
                  seriesConfigNew.color = chartColors[seriesConfigNew.colorKey];
               }
               if (active_tiles.includes('pricing')) {
                  if (identifier === "Recomended") {
                     //console.log(pricingTodaySeries);
                     seriesConfigNew.data = seriesConfigNew.data.map(seriesData => {
                        if (seriesData.x) {
                           let time = moment(seriesData.x).format("YYYY-MM-DD");
                           //seriesData.y=0;
                           displayData.forEach(d => {
                              if (d.index.date === time && !isEmptyObj(d.sets.Restrictions)) {
                                 //seriesData.y = d.sets.Today.SellRate;
                                 seriesData.marker = {
                                    symbol: 'url(/assets/icons/register.png)',
                                    width: 20,
                                    height: 20
                                 }
                                 seriesData.Restrictions = d.sets.Restrictions;
                              }
                           })
                        }

                        return seriesData
                     })
                     seriesConfigNew.marker = {
                        "symbol": "circle",
                        "enabled": true,
                        "fillColor": "white",
                        "lineWidth": 3,
                        "radius": 4,
                        "lineColor": null
                     }
                     console.log(seriesConfigNew.data);
                  }
               }
               if (identifier === "Restrictions") {
                  seriesConfigNew.data = seriesConfigNew.data.map(seriesData => {
                     if (seriesData.x) {
                        let time = moment(seriesData.x).format("YYYY-MM-DD");
                        displayData.forEach(d => {
                           if (d.index.date === time && !isEmptyObj(d.sets.Restrictions)) {
                              seriesData.y = 0;
                              seriesData.Restrictions = d.sets.Restrictions;
                           }
                        })
                     }

                     return seriesData
                  })
               }

               if (active_tiles.includes('compset')) {
                  let allowedData = compset_data_sources;
                  seriesConfigNew.legendLabel = seriesConfigNew.name;
                  // debugger;
                  if (allowedData.includes(active_api)) {
                     seriesConfigNew.visible = false;
                     seriesConfigNew.color = headerColors.compset_colors[identifierIndex + 1]
                     if (seriesConfigNew.name === chartConfig.resource.name) {
                        seriesConfigNew.visible = true;
                        seriesConfigNew.color = headerColors.compset_colors[0]
                     }
                     if (identifier === 'median') {
                        seriesConfigNew.visible = true;
                        seriesConfigNew.color = headerColors[seriesConfigNew.name.toUpperCase()];
                        seriesConfigNew.marker = {
                           "symbol": "circle",
                           "enabled": true,
                           "fillColor": "white",
                           "lineWidth": 3,
                           "radius": 4,
                           "lineColor": null
                        }
                     }
                  }
               }
               if (active_tiles[0] === "bi_sensitivity_analysis" && measureIndex == 1) {
                  let colorIndex = colorIndexCorrection;
                  colorIndexCorrection += 1;
                  if (headerColors.bi_sensitivity_colors.length < colorIndexCorrection) {
                     colorIndex = 0
                     colorIndexCorrection = 0;
                  }
                  seriesConfigNew.color = headerColors.bi_sensitivity_colors[colorIndex + 1]

               }
               if (segmented_view && chartConfig.controlSupport.segmented_view) {
                  // debugger;
                  if (segmentName === 'total' && measureL !== null) {

                     seriesConfigNew.visible = measureIndex ? true : false;
                     seriesConfigNew.showInLegend = measureIndex ? true : false;

                     if (!measureL) {
                        // seriesConfigNew.visible = false;
                        // seriesConfigNew.showInLegend = false;
                     }

                     series.push(seriesConfigNew);
                  } else if (segmentName === 'total' && measureIndex == 1 && measureL === null) {
                     seriesConfigNew.visible = true;
                     seriesConfigNew.showInLegend = true;
                     series.push(seriesConfigNew);
                  } else {
                     seriesConfigNew.visible = measureIndex ? false : true;
                     seriesConfigNew.showInLegend = measureIndex ? false : true;

                     let exists = series.filter(ser => ser.segmentName === segmentName);
                     exists = exists.length ? exists[0] : null;
                     if (exists) {
                        // exists.visible = false;
                        // exists.showInLegend = false;
                        // seriesConfigNew.visible = false ;
                        seriesConfigNew.showInLegend = false;
                     }

                     series.push(seriesConfigNew);
                  }
               } else {
                  if (chartConfig.xAxisType === "Dba" &&
                     seriesConfigNew.yAxis.includes("primaryYAxis")) {
                     seriesConfigNew.type = 'areaspline';
                     
                     if( active_tiles[0]!=="bi_sensitivity_analysis"){
                        seriesConfigNew.fillColor = {};
                        seriesConfigNew.fillColor.linearGradient = [0, 0, 0, 350];
                        seriesConfigNew.fillColor.stops = [
                           [0, seriesConfigNew.color],
                           [1, window.Highcharts.Color(seriesConfigNew.color).setOpacity(0).get('rgba')]
                        ];
                     } else {
                        seriesConfigNew.color="#3176B0"
                        seriesConfigNew.fillColor.linearGradient={ x1: 0, x2: 0, y1: 0, y2: 1 }
                        seriesConfigNew.fillColor.stops = [
                           [0, 
                              "rgba(49,118,176,0.9)"],
                           [1, 
                              "rgba(49,118,176,0)"]
                        ];
                        seriesConfigNew.fillOpacity = 0.3
                     }
                    
                  }

                  series.push(seriesConfigNew);
               }
               //series.push(seriesConfigNew);
            }
         })
      });
   });

   if (chartConfig && !chartConfig.chartOptions) {
      chartConfig.chartOptions = {};
   }
   let xAxisMax = '';
   let xAxisMin = '';

   let xAxis_reverse = false;
   let xAxisType = 'datetime';

   // debugger;
   if (series.length && series[0].data && series[0].data.length) {
      // debugger;
      let lastI = series[0].data.length - 1;
      let min = series[0].data[0].x;
      let max = series[0].data[lastI].x;
      xAxisMax = Number(max);
      xAxisMin = Number(min);
   }
   // debugger; 
   if (chartConfig.xAxisType === "Dba") {
      xAxis_reverse = true;
      xAxisType = 'number';
   }

   if (!chartConfig.controlSupport) {
      chartConfig.controlSupport = {};
   }
   let support = chartConfig.controlSupport;

   if (support && support.scopeType && scopeType === 'Dba') {
      xAxis_reverse = true;
      xAxisType = 'number';
   }
   const chartOptions = deepCopy(CHART_OPTIONS.default.option);
   chartOptions.legend = { ...chartOptions.legend, ...chartConfig.chartOptions.legend };

   chartOptions.xAxis[0].min = xAxisMin;
   chartOptions.xAxis[0].max = xAxisMax;
   chartOptions.xAxis[0].type = xAxisType;
   chartOptions.xAxis[0].reversed = xAxis_reverse;

   if (scopeType === 'Date') {
      chartOptions.xAxis[0].labels = { format: '{value:%e %b %y}' };
   }
   if (scopeType === 'Date' && chartConfig.xAxisType !== "Dba" && series.length && series[0].data.length > 40) {
      chartOptions.xAxis[0].labels = { format: '{value:%e %b %y}' }
   } else if (scopeType === 'Date' && chartConfig.xAxisType !== "Dba" && series.length && series[0].data.length < 40) {
      chartOptions.xAxis[0].labels = { format: '{value:%e %b}' }
   }
   if (active_tiles[0] === 'bi_sensitivity_analysis') {

      //chartOptions.xAxis[0].categories = [];
      chartOptions.xAxis[0].labels.format = "{text}";
      // chartOptions.xAxis[0].min -= (50 + chartOptions.xAxis[0].min % 50);
      chartOptions.xAxis[0].min= undefined;
      chartOptions.xAxis[0].max= undefined;
   
      //chartOptions.plotOptions["areaspline"]={fillOpacity: 0.3}
      //chartOptions.xAxis[0].labels.step = 50;

   }
   // chartOptions.xAxis[0].formatter = function () {
   //    return window.Highcharts.dateFormat('%e %b %Y ', this.value);
   // };
   chartOptions.yAxis = yAxisArr;
   chartOptions.series = series;
   // debugger;
   chartOptions.formatter = function () {
      // debugger; 
      let xScaleValue = this.value;

      if (chartConfig.controlSupport && chartConfig.controlSupport.scopeType && scopeType === 'Dba') {
         return Number(this.value);
      }
      if (chartConfig.controlSupport && chartConfig.controlSupport.scopeType && scopeType === 'Date') {
         xScaleValue = moment(this.value).startOf('day').format("MMM D");
         return xScaleValue;
      }

      if (scopeType === 'Dba' && chartConfig.xAxisType === "Dba") {
         return Number(this.value);
      }

      //return new moment(this.value).format('MMM D');
      if (!chartConfig.xAxisType) {
         xScaleValue = new moment(this.value).startOf('day').format('MMM D');
         return xScaleValue;
      }

      //let t = window.Highcharts.dateFormat('%a %d %b ', time);
      return xScaleValue;
   }
   // chartOptions.tooltip.width = 200*identifiers.length + 'px';
   // debugger;
   chartOptions.tooltip.formatter = function () {
      //  debugger;
      let axisTitles = {
         SellRate: "Sell Rate",
      };
      let timestamp = this.x / 1000;
      let tooltipTitle = '';
      let currCurrency = {};
      let tooltipJson = {
         rows: [],
         head: [],
         subHead: []
      };

      tooltipTitle = moment.unix(timestamp).format("ddd, MMM D, YYYY");
      if (chartConfig.controlSupport && chartConfig.controlSupport.scopeType && scopeType === 'Dba') {
         tooltipTitle = 'DBA: ' + this.x;
      }
      if (chartConfig.xAxisType === 'Dba') {
         tooltipTitle = 'DBA: ' + this.x;
      }

      let shared_points = this.points;
      if (active_tiles[0] === 'bi_sensitivity_analysis') {
         if (measureL !== null) {
            shared_points = shared_points.filter(function (v, i) {
               // check the index is even
               return i % 2 == 1;
            });

         }
      }
      let options = shared_points[0].series.userOptions;
      //first find all headers needed to put on tooltip
      let tooltipClass = 'default tile-' + options.tile;
      tooltipJson.tile = options.tile;
      tooltipJson.rows.push({ title: tooltipTitle, type: 'main' });
      tooltipJson.rows.push({ title: active_tiles.includes('compset') ? "Compset" : "", type: 'axis', y1: measureL, y2: axisTitles[measureR] || measureR });
      if (segmented_view && chartConfig.controlSupport.segmented_view) {
         tooltipJson.rows.push({ title: "Segments", type: 'identifiers', y1: [], y2: [] });
         tooltipJson.segments = [];
         tooltipClass = 'segmented tile-' + options.tile + ' identifiers-count-' + identifiers.length;
      }
      if (sdlyOn && chartConfig.controlSupport.sdlyOn) {
         tooltipClass += ' sdly-on';
      }
      tooltipJson.tooltipClass = 'tile-' + options.tile.toLowerCase();
      let s = '<div class="chart-tooltip-wrapper">';
      s += '<div class="' + tooltipClass + '">';

      tooltipJson.identifiers = [];
      let colTitles = {
         OTB_PRIMARY: "CURRENT",
         OTB_SDLY: "SDLY",
         OTB_SDLY_ACTUAL: "LY <br />ACTUALS",
         OTB_SDLY_ACTUAL_OTB: "LY <br />ACTUALS",
         OTB_PICKUP: "OTB <br />PRIOR",
         OTB_SDLY_PICKUP: "SDLY <br />PRIOR",

         OTB_PICKUP_PRIMARY: "OTB CURRENT",
         OTB_PICKUP_SDLY: "OTB SDLY",
         OTB_PICKUP_SDLY_ACTUAL: "SDLY ACTUAL",
         OTB_PICKUP_PICKUP: "OTB <br />PRIOR",
         OTB_PICKUP_SDLY_PICKUP: "SDLY PRIOR",

         OTB_PICKUP_DIFF: "OTB PICKUP",
         OTB_SDLY_PICKUP_DIFF: "SDLY PICKUP",

         PACE_PRIMARY: "PACE",
         PACE_COMPARISON: "PACE <br />COMPARISON",
         // PACE_PACESDLY: "Pace SDLY",
         // PACE_PACECOMPARETO: "Pace <br />Comparison",

         MARKETARIMARKET_PRIMARY: "MARKET <br />CURRENT",
         // MARKETSDLYDBAMARKET_PRIMARY: "MARKET <br />CURRENT",
         MARKETDBAMARKET_PRIMARY: "MARKET <br />CURRENT",
         MARKETDBAMARKET_SDLY: "MARKET <br />SDLY",
         MARKETARIMARKET_SDLY: "MARKET <br />SDLY",
         MARKETSDLYDBAMARKET_SDLY: "MARKET <br />SDLY",
         MARKETARIMARKET_SDLY_ACTUAL: "MARKET LY <br />ACTUALS",
         MARKET_SDLY_ACTUAL_MARKET: "MARKET LY <br />ACTUALS",
         MARKETARIMARKET_SDLY_ACTUALS: "MARKET LY <br />ACTUALS",
         MARKETARIMARKET_MARKET_FORECAST: "MARKET <br />PROJECTED",

         MARKET_SELL_RATES_3_STAR: "3 STAR",
         MARKET_SELL_RATES_4_STAR: "4 STAR",
         MARKET_SELL_RATES_5_STAR: "5 STAR",

         FORECAST_PRIMARY: "Forecast",
         OTB_V2_MULTI_SEGMENT_PRIMARY: "Current",
         OTB_V2_MULTI_SEGMENT_SDLY: "SDLY",
         OTB_V2_MULTI_SEGMENT_SDLY_ACTUAL: "SDLY Actual",

      };
      let subheaders = [];

      let segmentList = [];
      let totalRow = { type: "dataSegment", segment: 'total', color: {}, totalCalc: {} };

      shared_points.forEach((point, pointI) => {
         // debugger;
         let totalPoints = shared_points.length;
         let chartRef = point.series.chart;
         let color = point.color;
         let options = point.series.userOptions;
         let identifierKey = options.category + '_' + options.identifier;
         let SeriesMeasure = options.measureIndex ? measureR : measureL;
         currCurrency = options.currency;
         let cords = options.data.filter(val => val.x === this.x);
         cords = cords.length ? cords[0] : {};
         let y1 = point.y;
         let y2 = cords.y2;

         let yTotal = 0;
         if (active_tiles[0] === 'bi_sensitivity_analysis') {
            identifierKey = options.legendLabel;
            y1 = point.point.allKpis[measureR];
            if (measureL !== null) {
               y2 = point.point.allKpis[measureL];
            }
         }
         // debugger;
         console.log('identifierKey : ', identifierKey);
         if (!totalRow.totalCalc[identifierKey]) {
            totalRow.totalCalc[identifierKey] = { Occ: 0, Rooms: 0, ADR: 0, RevPar: 0, Revenue: 0, SellRate: 0 };
         }
         if (options.measureIndex === 0 && options.segmentName !== 'total' && cords.allKpis) {
            let total_capacity = cords.allKpis["Revenue"] / cords.allKpis["RevPAR"];
            totalRow.totalCalc[identifierKey]["Occ"] += cords.allKpis["Occ"] * 100;
            totalRow.totalCalc[identifierKey]["Revenue"] += round(cords.allKpis["Revenue"]);
            // totalRow.totalCalc["Revenue"] = totalRow.totalCalc["Revenue"]/100;
            totalRow.totalCalc[identifierKey]["Rooms"] += Number(cords.allKpis["Rooms"]);
            totalRow.totalCalc[identifierKey]["ADR"] = round(totalRow.totalCalc[identifierKey]["Revenue"] / totalRow.totalCalc[identifierKey]["Rooms"]);
            totalRow.totalCalc[identifierKey]["RevPAR"] = round(totalRow.totalCalc[identifierKey]["Revenue"] / total_capacity);
         }



         if (measureL === null && options.measureIndex === 1 && options.segmentName === 'total' && cords.allKpis) {
            let total_capacity = cords.allKpis["Revenue"] / cords.allKpis["RevPAR"];
            totalRow.totalCalc[identifierKey]["Occ"] = cords.allKpis["Occ"] * 100;
            totalRow.totalCalc[identifierKey]["Revenue"] = round(cords.allKpis["Revenue"]);
            // totalRow.totalCalc["Revenue"] = totalRow.totalCalc["Revenue"]/100;
            totalRow.totalCalc[identifierKey]["Rooms"] = Number(cords.allKpis["Rooms"]);
            totalRow.totalCalc[identifierKey]["ADR"] = Math.round(cords.allKpis["Revenue"] / cords.allKpis["Rooms"]);
            totalRow.totalCalc[identifierKey]["RevPAR"] = round(totalRow.totalCalc[identifierKey]["Revenue"] / total_capacity);
         }

         if (measureL !== null && options.measureIndex === 1 && options.segmentName === 'total') {
            // debugger
            totalRow.totalCalc[identifierKey]["ADR"] = round(totalRow.totalCalc[identifierKey]["Revenue"] / totalRow.totalCalc[identifierKey]["Rooms"]);
            // totalRow.totalCalc[identifierKey]["Revenue"] += round(cords.allKpis["Revenue"]);
            // totalRow.totalCalc[identifierKey]["RevPAR"] = round(totalRow.totalCalc[identifierKey]["Revenue"] / total_capacity);
            console.log('measureR : ', totalRow.totalCalc);
         }

         if (measureL === SeriesMeasure) {
            // debugger
            yTotal = point.total;
            yTotal = totalRow.totalCalc[identifierKey][SeriesMeasure];

            // console.log('measureR : ', SeriesMeasure, point, cords);
         }
         if (measureR === SeriesMeasure) {
            yTotal = y2;
            yTotal = totalRow.totalCalc[identifierKey][SeriesMeasure];
            // console.log('measureR : ', SeriesMeasure, totalRow.righMeasureTotal);
            // debugger
         }


         // debugger;
         totalRow[options.measure] = totalRow[options.measure] ? totalRow[options.measure] : {};
         totalRow[options.measure][identifierKey] = yTotal;
         totalRow.color[options.measure] = totalRow.color[options.measure] ? totalRow.color[options.measure] : {};
         totalRow.color[options.measure][identifierKey] = point.color;

         if (firstTile === "otb_v2_multi_segment") {
            totalRow[measureR] = {}//totalRow[options.measure] ? totalRow[options.measure] : {};
            totalRow.color[measureR] = {}
         }
         // debugger;
         if (segmented_view && chartConfig.controlSupport.segmented_view) {
            let rowItem = {
               type: 'dataSegment',
               title: colTitles[options.mapings.general] ? colTitles[options.mapings.general] : options.mapings.general.split("_").join(" "),
               segment: options.segmentName,
               identifier: identifierKey,
               color: {},
            };
            // debugger;
            let isExists = tooltipJson.rows.filter(row => row.segment === options.segmentName);
            if (!isExists.length) {
               // debugger;
               if (options.segmentName !== 'total') {
                  rowItem[measureL] = rowItem[measureL] ? rowItem[measureL] : {};
                  rowItem[measureR] = rowItem[measureR] ? rowItem[measureR] : {};
                  rowItem[measureL][identifierKey] = y1;
                  if (SeriesMeasure === measureL) {
                     rowItem[measureR][identifierKey] = y2;
                  }
                  // debugger
                  rowItem.color[measureR] = rowItem.color[measureR] ? rowItem.color[measureR] : {};
                  rowItem.color[measureR][identifierKey] = point.color;
                  rowItem.color[measureL] = rowItem.color[measureL] ? rowItem.color[measureL] : {};
                  rowItem.color[measureL][identifierKey] = point.color;
                  tooltipJson.rows.push(rowItem);
               }

            } else {
               // debugger;
               isExists = isExists[0];
               isExists[measureL] = isExists[measureL] ? isExists[measureL] : {};
               isExists[measureL][identifierKey] = isExists[measureL][identifierKey] ? isExists[measureL][identifierKey] : y1;
               isExists[measureR] = isExists[measureR] ? isExists[measureR] : {};
               isExists[measureR][identifierKey] = isExists[measureR][identifierKey] ? isExists[measureR][identifierKey] : y2;
               //Setting colors
               isExists.color = isExists.color ? isExists.color : {};
               isExists.color[SeriesMeasure] = isExists.color[SeriesMeasure] ? isExists.color[SeriesMeasure] : {};
               isExists.color[SeriesMeasure][identifierKey] = options.color;

               // }
            }

            if (!tooltipJson.identifiers.includes(identifierKey)) {
               tooltipJson.identifiers.push(identifierKey);
            }

         } else {
            let rowItem = {
               type: 'data',
               identifier: identifierKey,
               color: {},
               title: colTitles[options.mapings.general] ? colTitles[options.mapings.general] : options.mapings.general.split("_").join(" "),
            };

            rowItem.title = options.tooltipLabel;
            if (active_tiles[0] === 'bi_sensitivity_analysis') {

               if (dayOfWeek === 'mon,tue,wed,thu,fri,sat,sun'
                  || dayOfWeek === 'mon,tue,wed,thu' ||
                  dayOfWeek === 'fri,sat,sun') {
                  rowItem.title = options.tooltipLabel.split("_")[4] + " - " + options.tooltipLabel.split("_")[5]
               } else {
                  rowItem.title = options.tooltipLabel.split("_")[5]
               }

            }
            rowItem.color[measureL] = {};
            rowItem.color[measureR] = {};
            rowItem[measureL] = {}; rowItem[measureR] = {};
            let isExists = tooltipJson.rows.filter(row => row.identifier === identifierKey);
            if (identifierKey === "Pricing_Restrictions") {
               console.log(point);
               let time = moment(point.x).format("YYYY-MM-DD");
               let restrictions = {};
               displayData.forEach(d => {
                  if (d.index.date === time && !isEmptyObj(d.sets.Restrictions)) {
                     restrictions = d.sets.Restrictions;


                  }
               });
               rowItem["Restrictions"] = restrictions;
               rowItem.type = "restrictions"
            }
            // debugger;
            if (!isExists.length) {
               if (options.measureIndex) {
                  rowItem[measureR][identifierKey] = y1;
                  rowItem.color[measureR][identifierKey] = point.color;
               } else {
                  rowItem[measureL][identifierKey] = y1;
                  rowItem.color[measureL][identifierKey] = point.color;
               }
               if (active_tiles[0] === "bi_sensitivity_analysis") {
                  rowItem[measureR][identifierKey] = (measureR === 'Occ') ? convertToPercent(point.point.allKpis[measureR]) : point.point.allKpis[measureR];
                  rowItem.color[measureR][identifierKey] = point.color;
                  rowItem[measureL][identifierKey] = (measureL === 'Occ') ? convertToPercent(point.point.allKpis[measureL]) : point.point.allKpis[measureL];;
                  rowItem.color[measureL][identifierKey] = '#3176B5';
               }

               if (options.segmentName !== 'total') {
                  tooltipJson.rows.push(rowItem);
               }
            } else {
               isExists = isExists[0];
               if (options.measureIndex) {
                  isExists[measureR][identifierKey] = y1;
                  isExists.color[measureR][identifierKey] = point.color;
               } else {
                  isExists[measureL][identifierKey] = y1;
                  isExists.color[measureL][identifierKey] = point.color;
               }
            }
            // debugger
         }

         if (!tooltipJson.head.includes(options.measure)) {
            tooltipJson.head.push(options.measure);
         }
         let subHeadLable = options.tile + '_' + options.identifier;
         if (!tooltipJson.subHead.includes(subHeadLable)) {
            tooltipJson.subHead.push(subHeadLable);
         }
         //---

         let header = point.series.userOptions.tooltipLabel;
         if (!subheaders.includes(header)) {
            subheaders.push(header)
         }
      });
      if (segmented_view && chartConfig.controlSupport.segmented_view) {
         tooltipJson.rows.push(totalRow);
      }

      let allRows = tooltipJson.rows;
      // debugger;
      let segmentedTotal = allRows.filter(row => row.segment === 'total');
      segmentedTotal = segmentedTotal.length ? segmentedTotal[0] : null;
      // debugger;
      // let newIdentifierList = tooltipJson.identifiers.slice(0,tooltipJson.identifiers.length);
      // newIdentifierList = newIdentifierList.concat(tooltipJson.identifiers);
      // tooltipJson.identifiers = newIdentifierList;
      // debugger;
      let html = s;
      html += '<div class="rows grid-simple ' + tooltipJson.tooltipClass + '">';
      allRows.forEach((row, i) => {
         // debugger;
         let rowOrderId = i % 2 ? 'row-even' : 'row-odd';
         if (row.type === 'main') {
            html += '<div class="row-' + i + ' row-' + row.type + '">' +
               '<div class="tooltip-title">' + row.title + '</div>' +
               '</div>';
         }


         if (row.type === 'axis') {
            html += '<div class="row row-' + i + ' row-' + row.type + '">';
            // debugger;
            html += '<div class="measure col-one"><div class="axis-col-title">' + row.title + '</div></div>';
            if (measureL) {
               html += '<div class="axis-y1 col-two ' + row.y1 + '">' + row.y1 + '</div>';
            } else {
               html += '<div class="axis-y1 col-two ' + row.y1 + '">&nbsp;</div>';
            }
            if (measureR) {
               html += '<div class="axis-y2 col-three ' + row.y2 + '">' + row.y2 + '</div>';
            } else {
               html += '<div class="axis-y2 col-three ' + row.y2 + '"></div>';
            }
            html += '</div>';
         }


         if (row.type === 'identifiers') {
            if (measureL === null) {
               html += '<div class="row row-' + i + ' row-' + row.type + '">' +
                  '<div class="col-one"><div class="segment-names"></div></div>';
            }
            if (measureL) {
               html += '<div class="row row-' + i + ' row-' + row.type + '">' +
                  '<div class="col-one"><div class="segment-names">' + (row.title) + '</div></div>';
            }

            if (measureL) {
               html += '<div class="measure-y1 col-two">' +
                  '<div class="identifier-cols flex">';
               tooltipJson.identifiers.map((identifier, idx) => {
                  let labelKey = identifier.toUpperCase();
                  // debugger;
                  if (!colTitles[labelKey]) {
                     html += '<div class="col"><div class="identifier-label">' + identifier.split('_').join(" ") + '</div></div>';
                  } else {
                     html += '<div class="col"><div class="identifier-label">' + colTitles[labelKey] + '</div></div>';
                  }
               });
               html += '</div>' +
                  '</div>';
            }
            if (measureL === null) {
               html += '<div class="measure-y1 col-two">' +
                  '<div class="identifier-cols flex">';
               tooltipJson.identifiers.map((identifier, idx) => {
                  let labelKey = identifier.toUpperCase();
                  // debugger;
                  if (!colTitles[labelKey]) {
                     html += '<div class="col"><div class="identifier-label">' + identifier.split('_').join(" ") + '</div></div>';
                  } else {
                     html += '<div class="col"><div class="identifier-label">' + colTitles[labelKey] + '</div></div>';
                  }
               });
               html += '</div>' +
                  '</div>';
            }
            if (measureR) {
               html += '<div class="measure-y2 col-three">' +
                  '<div class="identifier-cols flex">';
               tooltipJson.identifiers.map((identifier, idx) => {
                  // debugger;
                  let labelKey = identifier.toUpperCase();
                  if (!colTitles[labelKey] && segmentedTotal.color[measureR]) {
                     html += '<div class="col"><div class="identifier-label"><span class="indicator" style="background-color:' + segmentedTotal.color[measureR][identifier] + '"></span>' + identifier.split('_').join(" ") + '</div></div>';
                  } else {
                     html += segmentedTotal.color[measureR] ? '<div class="col"><div class="identifier-label"><span class="indicator" style="background-color:' + segmentedTotal.color[measureR][identifier] + '"></span>' + colTitles[labelKey] + '</div></div>' : '<div class="col"><div class="identifier-label"><span class="indicator"></span>' + colTitles[labelKey] + '</div></div>';
                  }
               });
               html += '</div>' +
                  '</div>';
            }
            html += '</div>';
         }


         if (row.type === 'dataSegment') {
            let totalClass = '';
            if (row.segment === 'total') {
               totalClass = ' row-total';
               let valueSum = 0;
               let count = 0;
               let missingkey = '';

               Object.keys(row.color).forEach(cI => {
                  if (isEmptyObj(row.color[cI])) {
                     missingkey = cI;
                  }
               })
               if (active_tiles[0] === "otb_v2_multi_segment" && missingkey !== '') {
                  let identifierHash = {};
                  tooltipJson.identifiers.forEach(ident => {
                     identifierHash[ident] = { sum: 0, count: 0 }
                  })
                  tooltipJson.rows.forEach(item => {
                     if (item.type === "dataSegment" && item.segment !== "total") {
                        tooltipJson.identifiers.forEach(ident => {
                           if (item[missingkey][ident]) {
                              identifierHash[ident].sum += item[missingkey][ident];
                              identifierHash[ident].count++;
                           }
                        })

                     }
                  })
                  row.color[missingkey] = {};
                  tooltipJson.identifiers.forEach(ident => {
                     let avg = safeDivide(identifierHash[ident].sum, identifierHash[ident].count);
                     if (missingkey === "ADR") {
                        row.color[missingkey][ident] = avg;
                        row[missingkey][ident] = avg;
                     } else if (missingkey === "SellRate") {
                        row.color[missingkey][ident] = shared_points[0].y;
                        row[missingkey][ident] = shared_points[0].y;
                     } else {
                        row.color[missingkey][ident] = identifierHash[ident].sum;
                        row[missingkey][ident] = identifierHash[ident].sum;
                     }

                  })


               }


            }
            if (measureL) {
               html += '<div class="row data-row ' + rowOrderId + totalClass + ' ">';
               html += row.color[measureL] ? '<div class="col-one"><div class="segment-col"><span class="indicator" style="background-color:' + row.color[measureL][row.identifier] + '"></span>' + (row.segment) + '</div></div>' : '<div class="col-one"><div class="segment-col"><span class="indicator"></span>' + (row.segment) + '</div></div>';
               html += '<div class="measure-y1 col-two ' + measureL.toLocaleLowerCase() + '">' +
                  '<div class="flex">';
               tooltipJson.identifiers.map((identifier, idx) => {
                  let cellValue = row[measureL] && row[measureL][identifier] ? row[measureL][identifier].toFixed() : null;
                  cellValue = cellValue && measureL === 'Occ' ? displayFormat(cellValue, 'percent') : cellValue;
                  cellValue = cellValue && measureL !== 'Rooms' && measureL !== 'Occ' ? displayFormat(cellValue, 'currency', currCurrency) : cellValue;
                  html += '<div class="data-cell col ' + measureL + '_' + identifier + '">' + (cellValue ? cellValue : '-') + '</div>';
               });
               html += '</div>' +
                  '</div>';
            } else {
               html += '<div class="measure-y1 col-two ' + measureR + '">' +
                  '<div class="flex"> - </div></div>';
            }
            if (measureL === null) {
               html += '<div class="row data-row ' + rowOrderId + totalClass + ' ">';
               html += row.color[measureL] ? '<div class="col-one"><div class="segment-col"><span class="indicator" style="background-color:' + row.color[measureL][row.identifier] + '"></span>' + (row.segment) + '</div></div>' : '<div class="col-one"><div class="segment-col"><span class="indicator"></span>' + (row.segment) + '</div></div>';
               html += '<div class="measure-y1 col-two ' + measureL + '">' +
                  '<div class="flex">';
               tooltipJson.identifiers.map((identifier, idx) => {
                  let cellValue = null;
                  html += '<div class="data-cell col ' + measureL + '_' + identifier + '">' + (cellValue ? cellValue : '-') + '</div>';
               });
               html += '</div>' +
                  '</div>';
            }

            if (measureR && segmentedTotal.color[measureR]) {
               // debugger;  
               html += '<div class="measure-y2 col-three ' + measureR.toLocaleLowerCase() + '">' +
                  '<div class="flex">';
               if (active_tiles[0] === "otb_v2_multi_segment") {
                  tooltipJson.identifiers.map((identifier, idx) => {
                     let id = identifier.toLocaleLowerCase() + '_' + measureR.toLocaleLowerCase();
                     // debugger;
                     let exits = shared_points.filter((point, idx) => point.series.userOptions.mapings.specific.toLocaleLowerCase() === id);
                     
                     if (row[measureR] && exits.length > 0) {
                        let cellValue = row[measureR] && row[measureR][identifier] ? row[measureR][identifier].toFixed() : null;


                        if (!measureL) {
                           cellValue = !cellValue ? round(row.totalCalc[identifier][measureR]) : cellValue;
                        }

                        cellValue = cellValue && measureR === 'Occ' ? displayFormat(cellValue, 'percent') : cellValue;
                        cellValue = cellValue && measureR !== 'Rooms' && measureR !== 'Occ' ? displayFormat(cellValue, 'currency', currCurrency) : cellValue;
                        // debugger
                        html += '<div class="data-cell col ' + measureR + '_' + identifier + '">' + (cellValue ? cellValue : '-') + '</div>';
                     } else {
                        html += '<div class="data-cell col ' + measureR + '_' + identifier + '"> </div>';
                     }

                  });
               } else {

                  tooltipJson.identifiers.map((identifier, idx) => {
                     // debugger;
                     if (segmentedTotal.color[measureR][identifier]) {
                        let cellValue = row[measureR] && row[measureR][identifier] ? row[measureR][identifier].toFixed() : null;
                        cellValue = cellValue && measureR === 'Occ' ? displayFormat(cellValue, 'percent') : cellValue;
                        cellValue = cellValue && measureR !== 'Rooms' && measureR !== 'Occ' ? displayFormat(cellValue, 'currency', currCurrency) : cellValue;
                        html += '<div class="data-cell col ' + measureR + '_' + identifier + '">' + (cellValue ? cellValue : '-') + '</div>';
                     } else {
                        html += '<div class="data-cell col ' + measureR + '_' + identifier + '"> </div>';
                     }

                  });
               }

               html += '</div>' +
                  '</div>';
            } else {
               html += '<div class="measure-y2 col-three no-value ' + measureR + '">' +
                  '<div class="">  </div></div>';
            }
            html += '</div>';
         }
         if (row.type === 'data') {
            let totalClass = '';
            // debugger;
            // tooltipJson.identifiers.map((identifier, idx) => {
            let identifierCount = tooltipJson.identifiers.length;
            let identifier = row.identifier;
            let title = row.title;
            let labelKey = String(identifier).toUpperCase();
            let rowTitle = colTitles[labelKey] ? colTitles[labelKey] : labelKey.split("_").join(" ");

            // debugger;
            rowTitle = (tooltipJson.tile === 'compset' || tooltipJson.tile === 'bi_sensitivity_analysis') && title ? title : rowTitle;

            let measureLVal = row[measureL][identifier] ? row[measureL][identifier].toFixed() : ' - ';
            let measureRVal = row[measureR][identifier] ? row[measureR][identifier].toFixed() : ' - ';
            measureLVal = measureL === 'Occ' ? displayFormat(measureLVal, 'percent') : measureLVal;
            measureLVal = measureL !== 'Rooms' && measureL !== 'Occ' ? displayFormat(measureLVal, 'currency', currCurrency) : measureLVal;
            measureRVal = measureR === 'Occ' ? displayFormat(measureRVal, 'percent') : measureRVal;
            measureRVal = measureR !== 'Rooms' && measureR !== 'Occ' ? displayFormat(measureRVal, 'currency', currCurrency) : measureRVal;
            html += '<div class="row data-row ' + rowOrderId + totalClass + '">' +
               '<div class="col-one"><div class="row-title">' + truncate(rowTitle, 50) + '</div></div>';

            if (measureL) {
               html += '<div class="measure-y1 col-two ' + measureL.toLocaleLowerCase() + '">' +
                  '<div class="data-cell ' + measureL + '_' + identifier + '"><span class="indicator" style="background-color:' + row.color[measureL][identifier] + '"></span>' + measureLVal + '</div>' +
                  '</div>';
            } else {
               html += '<div class="measure-y2 col-two ' + measureR.toLocaleLowerCase() + '"></div>'
            }
            if (measureR) {
               // debugger
               html += '<div class="measure-y2 col-three ' + measureR.toLocaleLowerCase() + '">' +
                  '<div class="data-cell ' + measureR + '_' + identifier + '"><span class="indicator" style="background-color:' + row.color[measureR][identifier] + '"></span>' + measureRVal + '</div>' +
                  '</div>' +
                  '</div>';
            } else {
               html += '<div class="measure-y2 col-three">' +
                  '<div class="data-cell ' + measureR + '_' + identifier + '"></div>' +
                  '</div>' +
                  '</div>';
            }


            // });

         }
         if (row.type === 'restrictions') {
            let totalClass = '';
            let identifier = row.identifier;
            let labelKey = String(identifier).toUpperCase();
            let rowTitle = colTitles[labelKey] ? colTitles[labelKey] : labelKey.split("_").join(" ");
            // debugger;

            // let measureLVal = row[measureL][identifier] ? row[measureL][identifier].toFixed() : ' - ';
            // let measureRVal = row[measureR][identifier] ? row[measureR][identifier].toFixed() : ' - ';
            let measureRVal = getRestrictionsDisplay(row.Restrictions, true);
            html += '<div class="row data-row ' + rowOrderId + totalClass + '">' +
               '<div class=""><div class="row-title">' + truncate(rowTitle, 50) + '</div></div>';

            // if (measureL) {
            //    html += '<div class="measure-y1 col-two ' + measureL.toLocaleLowerCase() + '">' +
            //       '<div class="data-cell ' + measureL + '_' + identifier + '"><span class="indicator" style="background-color:' + row.color[measureL][identifier] + '"></span>' + measureLVal + '</div>' +
            //       '</div>';
            // } else {

            // }
            //html += '<div class="measure-y2 col-two ' + measureR.toLocaleLowerCase() + '"></div>'
            if (measureR) {
               // debugger
               html += '<div class="measure-y2 ' + measureR.toLocaleLowerCase() + '">' +
                  '<div class="data-cell ' + measureR + '_' + identifier + '">' + measureRVal + '</div>' +
                  '</div>' +
                  '</div>';
            } else {
               html += '<div class="measure-y2 col-three">' +
                  '<div class="data-cell ' + measureR + '_' + identifier + '"></div>' +
                  '</div>' +
                  '</div>';
            }


         }
      });
      html += '</div></div></div>';
      // console.log("tooltip html: " + 1 + 1 + ":" + html, tooltipJson);
      // debugger;
      return html;


      //go through all subheaders and create the html tag, then go through each point and then put them under
      subheaders.forEach((header, I) => {
         // debugger;
         let header_color = shared_points[I].color;
         let singleAxisTiles = ['compset', 'market_sell_rates'];
         if (!singleAxisTiles.includes(firstTile)) {
            header_color = shared_points[I * 2].color;
         }

         let header_html = '<div style="margin:5px 2px 0 2px;font-weight:bold;color:' + header_color + '">' + header.toUpperCase() + '</div>';
         s += header_html
         shared_points.forEach((point) => {
            let seriesMeasureName = point.series.userOptions.name;
            let seriesName = point.series.userOptions.seriesName.toUpperCase();
            let seriesColor = point.color;
            if (point.series.index % 2 === 0) {
               seriesColor = point.color;
               // seriesColor = headerColors[header];
            }

            // debugger;
            if (header == point.series.userOptions.tooltipLabel) {
               let mainMeasure = point.series.userOptions.mainMeasure;
               let displayVal = point.y.toFixed(0);
               if (mainMeasure !== 'Occ%' && mainMeasure !== 'Rooms') {
                  displayVal = currencyFormat(displayVal, point.series.userOptions.currency);
               }
               if (mainMeasure === 'Occ%') {
                  displayVal += '%';
               }
               let measure_element_html = '<div style="margin-left:5px;margin-right:5px;"><span style="font-size:17px; margin-right:2px;color:' + seriesColor + '">&#9679;</span> ' + point.series.userOptions.mainMeasure + " : <span class='tooltip-row-val'>" + displayVal + "</span></div>"
               s += measure_element_html
            }
         })
      })
      return s + '</div>';
   }
   //chartOptions.legend.labelFormat = '{color} {index} {symbol}';
   chartOptions.legend.labelFormatter = function () {
      // debugger;
      console.log('chartOptions.legend.labelFormatter', this);
      let legendLabel = this.userOptions.legendLabel;
      return legendLabel;
   }

   if (segmented_view) {

      chartOptions.legend = {
         ...chartOptions.legend,
         "enabled": true,
         "align": "right",
         "layout": "vertical",
         "verticalAlign": "top",
         "y": 10,
         "padding": 5,
         "useHTML": true,
         "shadow": false,
         "itemStyle": {
            "color": "#606060",
            "font-size": 10,
            "padding-bottom": "1vh"
         }
      }
   }
   console.log("generated chart JSON:", chartOptions);
   return chartOptions;
}

export function getMergedTilesOptions(displayData,
   { date_from, date_until, sdlyOn, compareTo, clearComparision, dba, active_api, resource_id, scopeType,
      active_tiles, right_measure, left_measure, currency, currency_symbol, segmented_view, pickUpType, segments, total_capacity }) {
   // debugger;
   //series is the set od data points for a line/column, initial measures will check the measures input to create the data sets
   let firstTile = active_tiles[0];
   let { chartConfig, data_sources } = TILES[firstTile];
   // const compset_data_sources = TILES['compset'].data_sources;
   const series = [];

   // Setting up default measures
   let intialMeasures = [left_measure, right_measure];
   if (chartConfig.defaultMeasures) {
      //intialMeasures = [chartConfig.defaultMeasures[0], chartConfig.defaultMeasures[1]];
   }
   active_tiles.map((tile, i) => {
      // debugger;  

   });

   //setting identifiers to make chart dynamic for each tile
   // let identifiers = JSON.parse(JSON.stringify(chartConfig.identifiers));
   let identifiers = [];
   let mergedIdentifiers = [];
   let segmentedIdentifiers = [{ name: '' }];


   //As no pre defined identifiers is there for compset and it depends on compset names in API response
   //So need ot generate dynamically from response data
   // debugger
   let seriesGroup = [];
   let measureL = intialMeasures[0];
   let measureR = intialMeasures[1];
   // const category = active_api + seriesLabel;
   let setYAxis = true
   const yAxisArr = [];
   if (active_tiles.length > 1) {
      // debugger; 
      const allMergedIdentifier = [];
      const repeatedIdentifier = [];
      //loop for generating chart options
      active_tiles.map((tile) => {
         let tileDisplayData = displayData[tile + '_aggregated'];
         let { chartConfig, data_sources } = TILES[tile];
         let seriesLabel = capitalizeFirstLetter(tile);
         let tileIdentifier = chartConfig.identifiers;
         // debugger;
         if (segmented_view && chartConfig.controlSupport.segmented_view) {
            if (tileDisplayData.length && tileDisplayData[0].segmented) {
               segmentedIdentifiers = tileDisplayData[0].order;
            }
         }
         if (tile === 'pickup' || tile === 'otb') {
            if (chartConfig.controlSupport && chartConfig.controlSupport.sdlyOn && sdlyOn) {
               let tmpIdentifier = chartConfig.sdlyIdentifiers;
               tileIdentifier = chartConfig.sdlyIdentifiers;
            }
         }
         // Merging identifiers for pace
         if (tile === 'pace') {
            if (clearComparision) {
               // tileIdentifier = ['primary_'+tile, 'comparison'];
               tileIdentifier = chartConfig.compareToIdentifiers;
            }
            // debugger;
         }

         if (tile === 'market_sell_rates') {
            let dataArray = displayData[tile + '_aggregated'];
            dataArray.map((item, i) => {
               for (let k in item.sets) {
                  if (measureR && !['SellRate'].includes(measureR)) {
                     displayData[tile + '_aggregated'][i]['sets'][k][measureR] = displayData[tile + '_aggregated'][i]['sets'][k]['SellRate'];
                  };
               }
            });
         }

         if (['compset'].includes(tile)) {
            let compsetData = displayData[tile + '_aggregated'];
            // debugger;
            compsetData.map((item, i) => {
               for (let k in item) {
                  if (measureR && !['SellRate'].includes(measureR)) {
                     compsetData[i][k][measureR] = compsetData[i][k]['SellRate'];
                  }
               }
            });
            tileIdentifier = generateIdentifiers({ tile, data_id: 'Compset', displayData: compsetData, resource_id, pickUpType });
            // intialMeasures = ['Occ', chartConfig.measures_list[0].value];
            // measureL = intialMeasures[0];
            // measureR = intialMeasures[1];
         }

         if (tile === 'market') {
            if (scopeType === 'Dba') {
               if (chartConfig.dba_identifiers) {
                  tileIdentifier = [...chartConfig.dba_identifiers];
               }
               // marketIdentifiers = chartConfig['dba_identifiers'];
               if (sdlyOn) {
                  let tmpIdentifier = chartConfig['sdly_dba_identifiers'];
                  tileIdentifier = [...tileIdentifier, ...tmpIdentifier];
               }
            } else {
               if (sdlyOn) {
                  let tmpIdentifier = chartConfig['sdlyIdentifiers'];
                  tileIdentifier = [...tmpIdentifier];
               }
            }
         }

         tileIdentifier.map((identifier) => {
            if (!allMergedIdentifier.includes(identifier)) {
               allMergedIdentifier.push(identifier);
            } else {
               allMergedIdentifier.push(identifier);
               if (!repeatedIdentifier.includes(identifier)) {
                  repeatedIdentifier.push(identifier);
               }
            }
         });

         const category = active_api + seriesLabel;
         // let setYAxis = true
         // const yAxisArr = [];
         // let s = segments;

         segmentedIdentifiers.map((orderdItem, segmentIndex) => {
            // debugger; 
            let segmentName = orderdItem.name;
            tileIdentifier.map((identifier_, identifierIndex) => {
               // let seriesName = "";
               /* 
                  This block of code is to resolve itelimerge issue in segmented view or
                  Any such scenario where identifiers are not unique, because if same identifier
                  is repeating again its giving issue and adding up values with older ones.
               */
               // uniqueIdentifier for making sure that identifiers would not repeat
               let identifier = identifier_;

               if (repeatedIdentifier.includes(identifier_)) {
                  identifier = identifier + "_" + tile;
                  let mainkey = chartConfig.mainkey;
                  // if(tile==='pace') debugger;
                  tileDisplayData.map((item) => {
                     // debugger;                     
                     if (item[mainkey]) {
                        //Duplicating data of the identifier
                        if (item[mainkey][identifier_]) {
                           item[mainkey][identifier] = deepCopy(item[mainkey][identifier_]);
                        }
                     }
                     if (item['segmented']) {
                        let segmentedKey = 'segmented';
                        for (let key in item[segmentedKey]) {
                           item[segmentedKey][key][identifier] = deepCopy(item[segmentedKey][key][identifier_]);
                        }
                     }
                  });
                  // debugger;
               }
               // --END of block

               // debugger   
               //only setting y-axis in first iteration of identifiers
               intialMeasures.map((measure, measureIndex) => {
                  // debugger;
                  if (measure) {
                     // if(tile==='otb') debugger;  
                     let seriesName = setSeriesName({ identifier, seriesLabel, tile: tile, scopeType, measure, segmented_view, segmentName });
                     const seriesConfig = generateChartSeries({ data: tileDisplayData, tile: tile, identifier, identifierIndex, measure, measureR, measureIndex, seriesLabel, segmented_view, segmentName, chartConfig });
                     seriesConfig.colorKey = setChartSeriesColorKey({ identifier, identifierIndex, measure, measureIndex, seriesName, resource_id, tile: tile, });
                     seriesConfig.mainCKey = setChartSeriesColorKey({ isMainColor: true, identifier, identifierIndex, measure, measureIndex, seriesName, resource_id, tile: tile, });
                     // seriesConfig.id = segmented_view ? segmentName + "-" + seriesName + '_' + identifier + measureIndex : seriesName + '_' + identifier + measureIndex;
                     seriesConfig.name = seriesConfig.name ? seriesConfig.name : seriesLabel + " " + identifier;
                     seriesConfig.currency = { code: currency, symbol: currency_symbol };
                     seriesConfig.category = category;
                     seriesConfig.seriesName = seriesLabel + " " + identifier;
                     seriesConfig.mainMeasure = measure === 'Occ' ? measure + "%" : measure;
                     seriesConfig.marker = { enabled: false };
                     // if(tile==='pace') debugger;


                     //Grouping series for segmented view only
                     // debugger;
                     if (segmented_view && chartConfig.controlSupport.segmented_view && segmentName !== "total") {
                        if (!seriesGroup.includes(segmentName)) {
                           seriesGroup.push(segmentName);
                           seriesConfig.id = segmentName;
                        } else {
                           // debugger
                           seriesConfig.linkedTo = segmentName;
                           delete seriesConfig.id;
                        }
                     }
                     // debugger;
                     if (measureIndex === 0) {
                        seriesConfig.zIndex = 1;
                        seriesConfig.type = 'column';

                        //For segmented view
                        if (segmented_view) {
                           // debugger; 
                           seriesConfig.mainCKey = "SEGMENT_" + segmentIndex;
                           // seriesConfig.stacking = "normal";
                           //seriesConfig.legendLabel = seriesName;
                        }
                        //Setting colors for left measures
                        console.log("LEFT Measure Color key", seriesConfig.mainCKey);
                        let lmc = leftMeasureColors[seriesConfig.mainCKey];
                        if (lmc) {
                           seriesConfig.color = lmc;
                           seriesConfig.negativeColor = lmc;
                        }

                     }
                     if (measureIndex === 1) {
                        seriesConfig.yAxis = "secondaryRightYAxis" + measure;
                        seriesConfig.type = 'spline';
                        seriesConfig.zIndex = 2;

                        //Setting colors for left measures
                        // debugger; 
                        console.log("Right Measure Color key", seriesConfig.mainCKey);
                        let rmc = rightMeasureColors[seriesConfig.mainCKey];
                        if (rmc) {
                           seriesConfig["color"] = rmc;
                           seriesConfig.negativeColor = rmc;
                        }

                     }


                     //code for setting y-axis
                     if (setYAxis) {
                        const yAxisConfig = deepCopy(CHART_OPTIONS.default.yAxis);
                        if (measureIndex == 0) {
                           let threshold = null;

                           if (measureL === 'Rooms' && !active_tiles.includes('market') && !active_tiles.includes('compset')) {
                              threshold = total_capacity;
                              yAxisConfig["max"] = threshold;
                           }
                           if (measureL === 'Occ') {
                              threshold = 100;
                              yAxisConfig["max"] = threshold + 10;
                           }
                           if (active_tiles.includes('market') || active_tiles.includes('compset')) {
                              threshold = null;
                              yAxisConfig["max"] = threshold;
                           }
                           yAxisConfig["id"] = "primaryYAxis" + measure;

                           yAxisConfig["plotLines"] = [
                              {
                                 "value": threshold,
                                 "color": "black",
                                 "dashStyle": "shortdash",
                                 "width": 2,
                                 "label": {
                                    "text": ""
                                 },

                              }
                           ]
                        } else {
                           let threshold = null;

                           if (measureR === 'Rooms' && !active_tiles.includes('market') && !active_tiles.includes('market_sell_rates') && !active_tiles.includes('compset')) {
                              threshold = total_capacity;
                              yAxisConfig["max"] = threshold;
                           }
                           if (measureR === 'Occ') {
                              threshold = 100;
                              yAxisConfig["max"] = threshold + 10;
                           }
                           yAxisConfig["id"] = "secondaryRightYAxis" + measure;;
                           //yAxisConfig["gridLineColor"] = "transparent";
                           yAxisConfig["opposite"] = true;
                           //For fixing curve according to scaling
                           // if(!active_tiles.includes('compset')){
                           //    yAxisConfig["max"] = threshold;
                           // }
                           // if(active_tiles.includes('market')){
                           //    yAxisConfig["max"] = threshold;
                           // }

                           yAxisConfig["plotLines"] = [
                              {
                                 "value": threshold,
                                 "color": "lightblue",
                                 "dashStyle": "shortdash",
                                 "width": 1,
                                 "label": {
                                    "text": ""
                                 },

                              }
                           ]
                           if (!active_tiles.includes('pickup')) {
                              yAxisConfig.min = 0;
                           }

                           yAxisConfig["labels"] = {
                              format: '{value:.0f}'
                           };
                           setYAxis = false;
                        }
                        yAxisArr.push(yAxisConfig);
                     }

                     //setting data from identifier and measure into data to graph
                     if (tileDisplayData.length) {
                        let inputData = deepCopy([...tileDisplayData]);
                        if (segmented_view && tileDisplayData[0].index && tileDisplayData[0].segmented) {
                           let tmp = tileDisplayData.filter((di) => di.segmented[segmentName] ? true : false);
                           tmp = tmp.length ? tmp : [];
                           inputData = deepCopy(tmp);
                        }
                        // debugger;

                        let chartData = generateChartData(inputData, { chartConfig, identifier, measure, measureR, data_id: active_api, segmentName });
                        seriesConfig.data = chartData;
                     }

                     //seriesConfig["data"].sort((a,b) => a.x - b.x)
                     // seriesConfig['marker'] = { enabled: false };
                     // seriesConfig.visible = true;
                     // seriesConfig.showInLegend = true;

                     //Setting colors for the chart curves/columns
                     let seriesConfigNew = { ...seriesConfig };
                     //if(chartConfig[])

                     // debugger;  
                     if (measureIndex === 0) {
                        //lmc =  left measure color
                        // let lmc = headerColors[seriesName];
                        //seriesConfigNew.color = lmc;
                        //Take chart options for xAxis from chartConfig if exists
                        if (chartConfig.chartOptions && chartConfig.chartOptions.xAxis) {
                           // debugger;
                           let areaColor = leftMeasureColors[seriesConfigNew.mainCKey];
                           let copyChartOptions = deepCopy(chartConfig.chartOptions.xAxis);

                           if (active_tiles.includes('market') && scopeType === 'Dba') {
                              // debugger
                              seriesConfigNew = { ...seriesConfig, ...copyChartOptions };
                              areaColor = leftMeasureColors[seriesConfigNew.mainCKey + '_DBA'];
                           }
                           // debugger
                           if (active_tiles.includes('pace') && scopeType === 'Dba' && !segmented_view) {
                              seriesConfigNew = { ...seriesConfig, ...copyChartOptions };
                              // seriesConfigNew.type = 'area';
                           }

                           if (areaColor && scopeType === 'Dba') {
                              seriesConfigNew.color = areaColor;

                              // debugger;
                              // seriesConfigNew.fillColor.linearGradient = [0, 0, 0, 200];
                              if (seriesConfigNew.fillColor) {
                                 seriesConfigNew.fillColor.linearGradient = [0, 0, 0, 350];
                                 seriesConfigNew.fillColor.stops = [
                                    [0, areaColor],
                                    [1, window.Highcharts.Color(areaColor).setOpacity(0).get('rgba')]
                                 ];

                              }
                           }
                        }
                     }

                     if (measureIndex === 1) {
                        if (chartConfig.chartOptions && chartConfig.chartOptions.yAxis) {
                           let copyChartOptions = deepCopy(chartConfig.chartOptions.yAxis);
                           seriesConfigNew = { ...seriesConfig, ...copyChartOptions };
                        }
                     }
                     if (tile === 'pricing') {
                        console.log(data_sources, identifier)
                     }
                     if (tile === 'compset') {
                        // debugger; 
                        let allowedData = data_sources;
                        seriesConfigNew.legendLabel = seriesConfigNew.name;
                        //if (allowedData.includes(active_api)) {
                        seriesConfigNew.visible = false;
                        seriesConfigNew.color = headerColors.compset_colors[identifierIndex + 1]
                        if (seriesConfigNew.name === chartConfig.resource.name) {
                           seriesConfigNew.visible = true;
                           seriesConfigNew.color = headerColors.compset_colors[0]
                        }
                        if (measureIndex === 0) {
                           // seriesConfigNew.visible = false;
                           seriesConfigNew.showInLegend = false;
                        }
                        if (identifier === 'median') {
                           seriesConfigNew.visible = true;
                           seriesConfigNew.color = headerColors[seriesConfigNew.name.toUpperCase()];
                           seriesConfigNew.marker = {
                              "symbol": "circle",
                              "enabled": true,
                              "fillColor": "white",
                              "lineWidth": 3,
                              "radius": 4,
                              "lineColor": null
                           }
                        }
                        //}
                     }
                     if (tile === 'market_sell_rates') {
                        if (measureIndex === 0) {
                           seriesConfigNew.showInLegend = false;
                        }
                     }
                     if (segmented_view && chartConfig.controlSupport.segmented_view) {
                        // debugger
                        if (segmentName === 'total' && measureIndex == 1 && measureL === null) {
                           // debugger;
                           seriesConfigNew.visible = true;
                           seriesConfigNew.showInLegend = true;
                           series.push(seriesConfigNew);


                        } else if (segmentName === 'total') {

                           if (measureIndex === 0) {
                              seriesConfigNew.visible = false;
                              seriesConfigNew.showInLegend = false;
                           } else {
                              seriesConfigNew.visible = true;
                              seriesConfigNew.showInLegend = true;
                           }

                           if (!measureL) {
                              seriesConfigNew.visible = false;
                              seriesConfigNew.showInLegend = false;
                           }

                           series.push(seriesConfigNew);

                        } else {

                           seriesConfigNew.visible = measureIndex ? false : true;
                           seriesConfigNew.showInLegend = measureIndex ? false : true;

                           let exists = series.filter(ser => ser.segmentName === segmentName);
                           exists = exists.length ? exists[0] : null;
                           if (exists) {
                              seriesConfigNew.showInLegend = false;
                           }

                           series.push(seriesConfigNew);
                        }
                     } else {
                        series.push(seriesConfigNew);
                     }
                     //series.push(seriesConfigNew);
                  }
               })
            });
         });
      });
   }
   // debugger; 
   let seriesLabel = capitalizeFirstLetter(firstTile);


   if (chartConfig && !chartConfig.chartOptions) {
      chartConfig.chartOptions = {};
   }

   let xAxisMax = '';
   let xAxisMin = '';

   let xAxis_reverse = false;
   let xAxisType = 'datetime';

   // debugger;
   if (series.length && series[0].data && series[0].data.length) {
      // debugger;
      let lastI = series[0].data.length - 1;
      let min = series[0].data[0].x;
      let max = series[0].data[lastI].x;
      xAxisMax = Number(max);
      xAxisMin = Number(min);
   }
   // debugger; 
   if (chartConfig.xAxisType === "Dba") {
      xAxis_reverse = true;
      xAxisType = 'number';
   }

   if (!chartConfig.controlSupport) {
      chartConfig.controlSupport = {};
   }
   let support = chartConfig.controlSupport;

   if (support && support.scopeType && scopeType === 'Dba') {
      xAxis_reverse = true;
      xAxisType = 'number';
   }
   const chartOptions = deepCopy(CHART_OPTIONS.default.option);
   chartOptions.legend = { ...chartOptions.legend, ...chartConfig.chartOptions.legend };

   chartOptions.xAxis[0].min = xAxisMin;
   chartOptions.xAxis[0].max = xAxisMax;
   chartOptions.xAxis[0].type = xAxisType;
   chartOptions.xAxis[0].reversed = xAxis_reverse;
   // debugger;
   if (scopeType === 'Date') {
      chartOptions.xAxis[0].labels = { format: '{value:%e %b %y}' };
   }
   if (scopeType === 'Date' && chartConfig.xAxisType !== "Dba" && series[0].data.length > 40) {
      chartOptions.xAxis[0].labels = { format: '{value:%e %b %y}' }
   } else if (scopeType === 'Date' && chartConfig.xAxisType !== "Dba" && series[0].data.length < 40) {
      chartOptions.xAxis[0].labels = { format: '{value:%e %b}' }
   }
   //make the legend to display in right side if necessary
   if (active_tiles.includes('compset') || (active_tiles.length > 2 && sdlyOn)) {
      chartOptions.legend = {
         "enabled": true,
         "align": "right",
         "layout": "vertical",
         "verticalAlign": "top",
         "y": 10,
         "padding": 5,
         "useHTML": true,
         "shadow": false,
         "itemStyle": {
            "color": "#606060",
            "font-size": ".7vw",
            "padding-bottom": ".5vw"
         }
      }
   }
   chartOptions.yAxis = yAxisArr;
   chartOptions.series = series;
   chartOptions.formatter = function () {
      // debugger;
      let xScaleValue = this.value;

      if (chartConfig.controlSupport && chartConfig.controlSupport.scopeType && scopeType === 'Dba') {
         return Number(this.value);
      }
      if (chartConfig.controlSupport && chartConfig.controlSupport.scopeType && scopeType === 'Date') {
         xScaleValue = moment(this.value).startOf('day').format("MMM D");
         return xScaleValue;
      }

      if (scopeType === 'Dba' && chartConfig.xAxisType === "Dba") {
         return Number(this.value);
      }

      //return new moment(this.value).format('MMM D');
      if (!chartConfig.xAxisType) {
         xScaleValue = new moment(this.value).startOf('day').format('MMM D');
         return xScaleValue;
      }

      //let t = window.Highcharts.dateFormat('%a %d %b ', time);
      return xScaleValue;
   }
   // chartOptions.tooltip.width = 200*identifiers.length + 'px';
   // debugger;
   chartOptions.tooltip.formatter = function () {
      //  debugger;
      let axisTitles = {
         SellRate: "Sell Rate",
      };
      let timestamp = this.x / 1000;
      let tooltipTitle = '';
      let currCurrency = {};
      let tooltipJson = {
         rows: [],
         head: [],
         subHead: []
      };

      tooltipTitle = moment.unix(timestamp).format("ddd, MMM D, YYYY");
      if (chartConfig.controlSupport && chartConfig.controlSupport.scopeType && scopeType === 'Dba') {
         tooltipTitle = 'DBA: ' + this.x;
      }
      if (chartConfig.xAxisType === 'Dba') {
         tooltipTitle = 'DBA: ' + this.x;
      }

      let shared_points = this.points;
      let options = shared_points[0].series.userOptions;
      //first find all headers needed to put on tooltip
      let tooltipClass = 'default tile-' + options.tile;
      tooltipJson.tile = options.tile;
      tooltipJson.rows.push({ title: tooltipTitle, type: 'main' });
      tooltipJson.rows.push({ title: active_tiles.includes('compset') ? "Compset" : "", type: 'axis', y1: measureL, y2: axisTitles[measureR] || measureR });
      if (segmented_view && chartConfig.controlSupport.segmented_view) {
         tooltipJson.rows.push({ title: "Segments", type: 'identifiers', y1: [], y2: [] });
         tooltipJson.segments = [];
         tooltipClass = 'segmented tile-count-' + active_tiles.length + ' tile-' + options.tile;
      }
      if (sdlyOn && chartConfig.controlSupport.sdlyOn) {
         tooltipClass += ' sdly-on';
      }
      if (scopeType && chartConfig.controlSupport.scopeType) {
         tooltipClass += ' scope-type-' + scopeType.toLowerCase();
      }
      // tooltipJson.tooltipClass = 'tile-' + options.tile.toLowerCase();

      let s = '<div class="chart-tooltip-wrapper">';


      tooltipJson.identifiers = [];
      let colTitles = {
         // OTB_PRIMARY: "TODAY",
         OTB_PRIMARY: "CURRENT",
         OTB_PRIMARY_OTB: "CURRENT",
         OTB_SDLY: "SDLY",
         OTB_SDLY_OTB: "SDLY",
         OTB_SDLY_ACTUAL: "LY <br />ACTUALS",
         OTB_SDLY_ACTUAL_OTB: "OTB LY <br />ACTUALS",
         OTB_PICKUP: "OTB <br />PRIOR",
         OTB_SDLY_PICKUP: "SDLY <br />PRIOR",

         OTB_PICKUP_PRIMARY: "OTB CURRENT",
         OTB_PICKUP_SDLY: "OTB SDLY",
         OTB_PICKUP_SDLY_ACTUAL: "LY <br />ACTUALS",
         OTB_PICKUP_PICKUP: "OTB <br />PRIOR",
         OTB_PICKUP_SDLY_PICKUP: "SDLY PRIOR",

         PICKUP_PICKUP_DIFF: "OTB <br />PICKUP",
         PICKUP_SDLY_PICKUP_DIFF: "SDLY <br />PICKUP",

         PACE_PRIMARY: "PACE",
         PACE_PRIMARY_PACE: "PACE",
         PACE_COMPARISON: "PACE <br />COMPARISON",
         PACE_COMPARISON_PACE: "PACE <br />COMPARISON",

         MARKET_PRIMARY: "MARKET <br />CURRENT",
         MARKET_PRIMARY_MARKET: "MARKET <br />CURRENT",
         MARKETARIMARKET_PRIMARY: "MARKET <br />CURRENT",
         MARKETDBAMARKET_PRIMARY: "MARKET <br />CURRENT",
         MARKETARIMARKET_SDLY: "MARKET <br />SDLY",
         MARKET_SDLY_MARKET: "MARKET <br />SDLY",
         MARKETARIMARKET_SDLY_ACTUALS: "MARKET LY <br />ACTUALS",
         MARKET_SDLY_ACTUAL_MARKET: "MARKET LY <br />ACTUALS",
         MARKET_SDLY_ACTUAL: "MARKET LY <br />ACTUALS",
         MARKETARIMARKET_MARKET_FORECAST: "MARKET <br />PROJECTED",
         MARKET_MARKET_FORECAST: "MARKET <br />PROJECTED",

         MARKET_SELL_RATES_3_STAR: "3 Star",
         MARKET_SELL_RATES_4_STAR: "4 Star",
         MARKET_SELL_RATES_5_STAR: "5 Star",

         FORECAST_PRIMARY: "FORECAST",
         FORECAST_PRIMARY_FORECAST: "FORECAST",

         COMPSET_MEDIAN: "COMPSET MEDIAN",
      };
      let subheaders = [];

      let segmentList = [];
      let totalRow = { type: "dataSegment", segment: 'total', color: {}, totalCalc: {} };
      shared_points.forEach((point, pointI) => {
         // debugger; 
         let totalPoints = shared_points.length;
         let chartRef = point.series.chart;
         let color = point.color;
         let options = point.series.userOptions;
         let tile = options.tile;
         tooltipJson.tile = options.tile;
         let identifierKey = options.category + '_' + options.identifier;
         let SeriesMeasure = options.measureIndex ? measureR : measureL;
         currCurrency = options.currency;
         let cords = options.data.filter(val => val.x === this.x);
         cords = cords.length ? cords[0] : {};
         let y1 = point.y;
         let y2 = cords.y2;
         let yTotal = 0;
         console.log('identifierKey : ', identifierKey);
         if (!totalRow.totalCalc[identifierKey]) {
            totalRow.totalCalc[identifierKey] = { Occ: 0, Rooms: 0, ADR: 0, RevPar: 0, Revenue: 0, SellRate: 0 };
         }

         if (options.measureIndex === 0) {
            totalRow.totalCalc[identifierKey]["Occ"] += cords.allKpis["Occ"] * 100;
            totalRow.totalCalc[identifierKey]["Revenue"] += round(cords.allKpis["Revenue"]);
            // totalRow.totalCalc["Revenue"] = totalRow.totalCalc["Revenue"]/100;
            totalRow.totalCalc[identifierKey]["Rooms"] += Number(cords.allKpis["Rooms"]);
         }
         if (measureL === null && options.measureIndex === 1 && options.segmentName === 'total' && cords.allKpis) {
            let total_capacity = cords.allKpis["Revenue"] / cords.allKpis["RevPAR"];
            totalRow.totalCalc[identifierKey]["Occ"] = cords.allKpis["Occ"] * 100;
            totalRow.totalCalc[identifierKey]["Revenue"] = round(cords.allKpis["Revenue"]);
            // totalRow.totalCalc["Revenue"] = totalRow.totalCalc["Revenue"]/100;
            totalRow.totalCalc[identifierKey]["Rooms"] = Number(cords.allKpis["Rooms"]);
            totalRow.totalCalc[identifierKey]["ADR"] = Math.round(cords.allKpis["Revenue"] / cords.allKpis["Rooms"]);
            totalRow.totalCalc[identifierKey]["RevPAR"] = round(totalRow.totalCalc[identifierKey]["Revenue"] / total_capacity);
         }

         if (options.measureIndex === 1) {
            // debugger
            totalRow.totalCalc[identifierKey]["ADR"] = round(totalRow.totalCalc[identifierKey]["Revenue"] / totalRow.totalCalc[identifierKey]["Rooms"]);
            totalRow.totalCalc[identifierKey]["RevPAR"] = round(totalRow.totalCalc[identifierKey]["Revenue"] / total_capacity);

         }

         if (measureL === SeriesMeasure) {
            yTotal = point.total;
            yTotal = totalRow.totalCalc[identifierKey][SeriesMeasure];
         }
         if (measureR === SeriesMeasure) {
            yTotal = y2;
            yTotal = totalRow.totalCalc[identifierKey][SeriesMeasure];
            // debugger
         }

         // debugger;
         //SeriesMeasure === measureL && point.total ? point.total : y1;

         //collect total values
         totalRow[options.measure] = totalRow[options.measure] ? totalRow[options.measure] : {};
         totalRow[options.measure][identifierKey] = yTotal;
         totalRow.color[options.measure] = totalRow.color[options.measure] ? totalRow.color[options.measure] : {};
         totalRow.color[options.measure][identifierKey] = point.color;

         // debugger;
         if (segmented_view && chartConfig.controlSupport.segmented_view) {

            let rowItem = {
               type: 'dataSegment',
               title: colTitles[options.mapings.general] ? colTitles[options.mapings.general] : options.mapings.general.split("_").join(" "),
               segment: options.segmentName,
               identifier: identifierKey,
               color: {},
               tile: tile
            };
            // debugger;
            let isExists = tooltipJson.rows.filter(row => row.segment === options.segmentName);
            if (!isExists.length) {
               // debugger;
               if (options.segmentName !== 'total') {
                  rowItem[measureL] = rowItem[measureL] ? rowItem[measureL] : {};
                  rowItem[measureR] = rowItem[measureR] ? rowItem[measureR] : {};
                  rowItem[measureL][identifierKey] = y1;
                  if (SeriesMeasure === measureL) {
                     rowItem[measureR][identifierKey] = y2;
                  }
                  // debugger
                  rowItem.color[measureR] = rowItem.color[measureR] ? rowItem.color[measureR] : {};
                  rowItem.color[measureR][identifierKey] = point.color;
                  rowItem.color[measureL] = rowItem.color[measureL] ? rowItem.color[measureL] : {};
                  rowItem.color[measureL][identifierKey] = point.color;
                  tooltipJson.rows.push(rowItem);
               }

            } else {
               // debugger;
               isExists = isExists[0];
               isExists[measureL] = isExists[measureL] ? isExists[measureL] : {};
               isExists[measureL][identifierKey] = isExists[measureL][identifierKey] ? isExists[measureL][identifierKey] : y1;
               isExists[measureR] = isExists[measureR] ? isExists[measureR] : {};
               isExists[measureR][identifierKey] = isExists[measureR][identifierKey] ? isExists[measureR][identifierKey] : y2;
               //Setting colors
               isExists.color = isExists.color ? isExists.color : {};
               isExists.color[SeriesMeasure] = isExists.color[SeriesMeasure] ? isExists.color[SeriesMeasure] : {};
               isExists.color[SeriesMeasure][identifierKey] = options.color;

               // }
            }

            if (!tooltipJson.identifiers.includes(identifierKey)) {
               tooltipJson.identifiers.push(identifierKey);
            }

         } else {
            let rowItem = {
               type: 'data',
               tile: tile,
               identifier: identifierKey,
               color: {},
               title: colTitles[options.mapings.general] ? colTitles[options.mapings.general] : options.mapings.general.split("_").join(" "),
            };

            rowItem.title = options.tooltipLabel;
            rowItem.color[measureL] = {};
            rowItem.color[measureR] = {};
            rowItem[measureL] = {}; rowItem[measureR] = {};
            let isExists = tooltipJson.rows.filter(row => row.identifier === identifierKey);
            // debugger;
            if (!isExists.length) {
               if (options.measureIndex) {
                  rowItem[measureR][identifierKey] = y1;
                  rowItem.color[measureR][identifierKey] = point.color;
               } else {
                  rowItem[measureL][identifierKey] = y1;
                  rowItem.color[measureL][identifierKey] = point.color;
               }
               if (options.segmentName !== 'total') {
                  tooltipJson.rows.push(rowItem);
               }
            } else {
               isExists = isExists[0];
               if (options.measureIndex) {
                  isExists[measureR][identifierKey] = y1;
                  isExists.color[measureR][identifierKey] = point.color;
               } else {
                  isExists[measureL][identifierKey] = y1;
                  isExists.color[measureL][identifierKey] = point.color;
               }
            }
         }

         if (!tooltipJson.head.includes(options.measure)) {
            tooltipJson.head.push(options.measure);
         }
         let subHeadLable = options.tile + '_' + options.identifier;
         if (!tooltipJson.subHead.includes(subHeadLable)) {
            tooltipJson.subHead.push(subHeadLable);
         }
         //---

         let header = point.series.userOptions.tooltipLabel;
         if (!subheaders.includes(header)) {
            subheaders.push(header)
         }
      });
      if (segmented_view && chartConfig.controlSupport.segmented_view) {
         tooltipJson.rows.push(totalRow);
      }

      let allRows = tooltipJson.rows;
      let segmentedTotal = allRows.filter(row => row.segment === 'total');
      segmentedTotal = segmentedTotal.length ? segmentedTotal[0] : null;
      // debugger;
      // let newIdentifierList = tooltipJson.identifiers.slice(0,tooltipJson.identifiers.length);
      // newIdentifierList = newIdentifierList.concat(tooltipJson.identifiers);
      // tooltipJson.identifiers = newIdentifierList;
      // debugger;
      let activeTileClass = 'tile-' + active_tiles.join(' tile-');
      s += '<div class="' + activeTileClass + ' ' + tooltipClass + ' identifiers-count-' + tooltipJson.identifiers.length + '">';
      let html = s;
      html += '<div class="rows grid-simple ' + tooltipJson.tooltipClass + '">';
      let dataRowHtml = '';
      let explainer = "";
      let explainerDetails = "";
      allRows.map((row, i) => {
         // debugger;
         let rowOrderId = i % 2 ? 'row-even' : 'row-odd';
         if (row.type === 'main') {
            html += '<div class="row-' + i + ' row-' + row.type + '">' +
               '<div class="tooltip-title">' + row.title + '</div>' +
               '</div>';
         }


         if (row.type === 'axis') {
            html += '<div class="row row-' + i + ' row-' + row.type + '">';
            // debugger;
            // html += '<div class="measure col-one"><div class="axis-col-title">' + row.title + '</div></div>';
            html += '<div class="measure col-one"><div class="axis-col-title"></div></div>';

            if (measureL) {
               html += '<div class="axis-y1 col-two ' + row.y1 + '">' + row.y1 + '</div>';
            } else {
               html += '<div class="axis-y1 col-two ' + row.y1 + '">&nbsp;</div>';
            }
            if (measureR) {
               html += '<div class="axis-y2 col-three ' + row.y2 + '">' + row.y2 + '</div>';
            } else {
               html += '<div class="axis-y2 col-three ' + row.y2 + '"></div>';
            }
            html += '</div>';
         }


         if (row.type === 'identifiers') {
            if (!measureL) {
               html += '<div class="row row-' + i + ' row-' + row.type + '">' +
                  '<div class="col-one"><div class="segment-names"></div></div>';
            } else {
               html += '<div class="row row-' + i + ' row-' + row.type + '">' +
                  '<div class="col-one"><div class="segment-names">' + (row.title) + '</div></div>';
            }

            if (!measureL) {
               html += '<div class="measure-y1 col-two">' +
                  '<div class="identifier-cols flex">';
               tooltipJson.identifiers.map((identifier, idx) => {
                  let labelKey = identifier.toUpperCase();
                  // debugger;
                  if (!colTitles[labelKey]) {
                     html += '<div class="col"><div class="identifier-label"></div></div>';
                  } else {
                     html += '<div class="col"><div class="identifier-label"></div></div>';
                  }
               });
               html += '</div>' +
                  '</div>';
            }
            if (measureL) {
               html += '<div class="measure-y1 col-two">' +
                  '<div class="identifier-cols flex">';
               tooltipJson.identifiers.map((identifier, idx) => {
                  let labelKey = identifier.toUpperCase();
                  // debugger;
                  if (!colTitles[labelKey]) {
                     html += '<div class="col"><div class="identifier-label">' + identifier.split('_').join(" ") + '</div></div>';
                  } else {
                     html += '<div class="col"><div class="identifier-label">' + colTitles[labelKey] + '</div></div>';
                  }
               });
               html += '</div>' +
                  '</div>';
            }
            if (measureR) {
               html += '<div class="measure-y2 col-three">' +
                  '<div class="identifier-cols flex">';
               tooltipJson.identifiers.map((identifier, idx) => {
                  // debugger;
                  let labelKey = identifier.toUpperCase();
                  if (!colTitles[labelKey] && segmentedTotal.color[measureR]) {
                     html += '<div class="col"><div class="identifier-label"><span class="indicator" style="background-color:' + segmentedTotal.color[measureR][identifier] + '"></span>' + identifier.split('_').join(" ") + '</div></div>';
                  } else if (segmentedTotal.color[measureR]) {
                     html += '<div class="col"><div class="identifier-label"><span class="indicator" style="background-color:' + segmentedTotal.color[measureR][identifier] + '"></span>' + colTitles[labelKey] + '</div></div>';
                  } else {
                     html += '<div class="col"><div class="identifier-label"><span class="indicator" ></span>' + colTitles[labelKey] + '</div></div>';

                  }
               });
               html += '</div>' +
                  '</div>';
            }
            html += '</div>';
         }
         // debugger

         if (row.type === 'dataSegment') {
            let totalClass = '';
            if (row.segment === 'total') {
               totalClass = ' row-total';
            }
            if (measureL) {
               dataRowHtml += '<div class="row data-row ' + rowOrderId + totalClass + ' ">' +
                  '<div class="col-one"><div class="segment-col"><span class="indicator" style="background-color:' + row.color[measureL][row.identifier] + '"></span>' + (row.segment) + '</div></div>' +
                  '<div class="measure-y1 col-two ' + measureL.toLocaleLowerCase() + '">' +
                  '<div class="flex">';
               tooltipJson.identifiers.map((identifier, idx) => {
                  let cellValue = row[measureL][identifier] ? row[measureL][identifier].toFixed() : null;
                  cellValue = cellValue && measureL === 'Occ' ? displayFormat(cellValue, 'percent') : cellValue;
                  cellValue = cellValue && measureL !== 'Rooms' && measureL !== 'Occ' ? displayFormat(cellValue, 'currency', currCurrency) : cellValue;
                  dataRowHtml += '<div class="data-cell col ' + measureL + '_' + identifier + '">' + (cellValue ? cellValue : '-') + '</div>';
               });
               dataRowHtml += '</div>' +
                  '</div>';
            } else {
               dataRowHtml += '<div class="measure-y1 col-two ' + measureR + '">' +
                  '<div class="flex"></div></div>';
            }
            if (measureL === null) {
               dataRowHtml += '<div class="row data-row ' + rowOrderId + totalClass + ' ">' +
                  '<div class="col-one"><div class="segment-col"><span class="indicator"></span>' + (row.segment) + '</div></div>' +
                  '<div class="measure-y1 col-two ' + measureL + '">' +
                  '<div class="flex">';

               tooltipJson.identifiers.map((identifier, idx) => {
                  let cellValue = '';
                  dataRowHtml += '<div class="data-cell col ' + measureL + '_' + identifier + '">' + cellValue + '</div>';
               });
               dataRowHtml += '</div>' +
                  '</div>';
            }

            if (measureR && segmentedTotal.color[measureR]) {
               dataRowHtml += '<div class="measure-y2 col-three ' + measureR.toLocaleLowerCase() + '">' +
                  '<div class="flex">';
               tooltipJson.identifiers.map((identifier, idx) => {
                  if (segmentedTotal.color[measureR][identifier]) {
                     let cellValue = row[measureR][identifier] ? row[measureR][identifier].toFixed() : null;
                     cellValue = cellValue && measureR === 'Occ' ? displayFormat(cellValue, 'percent') : cellValue;
                     cellValue = cellValue && measureR !== 'Rooms' && measureR !== 'Occ' ? displayFormat(cellValue, 'currency', currCurrency) : cellValue;
                     if (['compset', 'market_sell_rate'].includes(options.tile)) {

                     }
                     dataRowHtml += '<div class="data-cell col ' + measureR + '_' + identifier + '">' + (cellValue ? cellValue : '-') + '</div>';
                  } else {
                     dataRowHtml += '<div class="data-cell col ' + measureR + '_' + identifier + '"> </div>';
                  }
               });
               dataRowHtml += '</div>' +
                  '</div>';
            } else {
               dataRowHtml += '<div class="measure-y2 col-three ' + measureR + '">' +
                  '<div class="flex"></div></div>';
            }
            dataRowHtml += '</div>';
         }
         if (row.type === 'data') {
            let totalClass = '';
            // debugger;
            // tooltipJson.identifiers.map((identifier, idx) => {
            let identifierCount = tooltipJson.identifiers.length;
            let identifier = row.identifier;
            let title = row.title;
            let labelKey = String(identifier).toUpperCase();
            let rowTitle = colTitles[labelKey] ? colTitles[labelKey] : labelKey.split("_").join(" ");
            // debugger;
            rowTitle = row.tile === 'compset' && title ? title : rowTitle;

            if (['compset', 'market_sell_rates', 'pricing'].includes(row.tile) && measureR && !['SellRate'].includes(measureR)) {
               explainer = "<span class='explainer'>*</span>";
               explainerDetails = "<span class='explainer'>*</span> Sell Rate";
            } else {
               explainer = "";
               // explainerDetails = "";
            }

            let measureLVal = row[measureL][identifier] ? row[measureL][identifier].toFixed() : ' - ';
            let measureRVal = row[measureR][identifier] ? row[measureR][identifier].toFixed() : ' - ';
            measureLVal = measureL === 'Occ' ? displayFormat(measureLVal, 'percent') : measureLVal;
            measureLVal = measureL !== 'Rooms' && measureL !== 'Occ' ? displayFormat(measureLVal, 'currency', currCurrency) : measureLVal;

            // debugger;
            if (['compset', 'market_sell_rates', 'pricing'].includes(row.tile) && measureR && ['Occ', 'Rooms'].includes(measureR)) {
               measureRVal = measureR === 'Occ' ? measureRVal / 100 : measureRVal;
               measureRVal = displayFormat(measureRVal, 'currency', currCurrency) + explainer;
            } else {
               // if(measureR === 'Occ'){
               //    measureRVal = displayFormat(measureRVal, 'percent');
               // } else if(measureR !== 'Rooms'){
               //    measureRVal = displayFormat(measureRVal, 'currency', currCurrency) + explainer;
               // }
               measureRVal = measureR === 'Occ' ? displayFormat(measureRVal, 'percent') : measureRVal;
               measureRVal = measureR !== 'Rooms' && measureR !== 'Occ' ? displayFormat(measureRVal, 'currency', currCurrency) + explainer : measureRVal;
            }

            dataRowHtml += '<div class="row data-row ' + rowOrderId + totalClass + '">' +
               '<div class="col-one"><div class="row-title">' + truncate(rowTitle, 50) + '</div></div>';

            if (measureL) {
               dataRowHtml += '<div class="measure-y1 col-two ' + measureL.toLocaleLowerCase() + '">' +
                  '<div class="data-cell ' + measureL + '_' + identifier + '"><span class="indicator" style="background-color:' + row.color[measureL][identifier] + '"></span>' + measureLVal + '</div>' +
                  '</div>';
            } else {
               dataRowHtml += '<div class="measure-y2 col-two ' + measureR.toLocaleLowerCase() + '"></div>'
            }
            if (measureR) {
               // debugger
               dataRowHtml += '<div class="measure-y2 col-three ' + measureR.toLocaleLowerCase() + '">' +
                  '<div class="data-cell ' + measureR + '_' + identifier + '"><span class="indicator" style="background-color:' + row.color[measureR][identifier] + '"></span>' + measureRVal + '</div>' +
                  '</div>' +
                  '</div>';
            } else {
               dataRowHtml += '<div class="measure-y2 col-three">' +
                  '<div class="data-cell ' + measureR + '_' + identifier + '"></div>' +
                  '</div>' +
                  '</div>';
            }


            // });

         }
      });
      html += '<div class="tooltip-data-rows">' + dataRowHtml + '</div>';
      html += explainerDetails ? '<div class="tooltip-data-rows explainer-details">' + explainerDetails + '</div>' : '';
      html += '</div></div></div>';
      // console.log("tooltip html: " + 1 + 1 + ":" + html, tooltipJson);
      // debugger;
      return html;


      //go through all subheaders and create the html tag, then go through each point and then put them under
      subheaders.forEach((header, I) => {
         // debugger;
         let header_color = shared_points[I].color;
         let singleAxisTiles = ['compset', 'market_sell_rates'];
         if (!singleAxisTiles.includes(firstTile)) {
            header_color = shared_points[I * 2].color;
         }

         let header_html = '<div style="margin:5px 2px 0 2px;font-weight:bold;color:' + header_color + '">' + header.toUpperCase() + '</div>';
         s += header_html
         shared_points.forEach((point) => {
            let seriesMeasureName = point.series.userOptions.name;
            let seriesName = point.series.userOptions.seriesName.toUpperCase();
            let seriesColor = point.color;
            if (point.series.index % 2 === 0) {
               seriesColor = point.color;
               // seriesColor = headerColors[header];
            }

            // debugger;
            if (header == point.series.userOptions.tooltipLabel) {
               let mainMeasure = point.series.userOptions.mainMeasure;
               let displayVal = point.y.toFixed(0);
               if (mainMeasure !== 'Occ%' && mainMeasure !== 'Rooms') {
                  displayVal = currencyFormat(displayVal, point.series.userOptions.currency);
               }
               if (mainMeasure === 'Occ%') {
                  displayVal += '%';
               }
               let measure_element_html = '<div style="margin-left:5px;margin-right:5px;"><span style="font-size:17px; margin-right:2px;color:' + seriesColor + '">&#9679;</span> ' + point.series.userOptions.mainMeasure + " : <span class='tooltip-row-val'>" + displayVal + "</span></div>"
               s += measure_element_html
            }
         })
      })
      return s + '</div>';
   }
   //chartOptions.legend.labelFormat = '{color} {index} {symbol}';
   chartOptions.legend.labelFormatter = function () {
      // debugger;
      // console.log('chartOptions.legend.labelFormatter', this);
      // this.legendItem.styles.translate = "100px 20px";
      let legendLabel = '<span class="legend-label">' + this.userOptions.legendLabel + '</span>';
      return legendLabel;
   }

   if (segmented_view) {

      chartOptions.legend = {
         ...chartOptions.legend,
         "enabled": true,
         "align": "right",
         "layout": "vertical",
         "verticalAlign": "top",
         "y": 10,
         "padding": 5,
         "useHTML": true,
         "shadow": false,
         "itemStyle": {
            "color": "#606060",
            "font-size": 10,
            "padding-bottom": "1vh"
         }
      }
   }
   // console.log("generated chart JSON:", chartOptions);
   return chartOptions;
}

const generateChartData = (displayData, params) => {
   // debugger 
   const size = displayData.length;
   let { chartConfig, identifier, measure, measureL, measureR, segmentName, tile } = params;
   const { mainkey, controlSupport } = chartConfig;
   const newMainKey = segmentName !== '' ? segmentName : mainkey;
   let data = [];
   for (let index = 0; index < size; index++) {
      const dataItem = displayData[index];
      let dataObj = null;
      let xValue = 0;
      let yValue = 0;
      let y2Value = 0;
      let xKey = !dataItem['index'] ? 'Index' : 'index';
      //Handle dates
      if (dataItem[xKey] && dataItem[xKey].date) {
         let { date } = dataItem[xKey];
         date = size === 1 ? new moment(date).endOf('day') : date;
         xValue = Number(moment(date).format('x'));
      }
      //Handle dba
      // debugger
      if (dataItem[xKey] && dataItem[xKey].dba !== undefined) {
         const { dba } = dataItem[xKey];
         xValue = dba;
      }
      if (newMainKey) {
         dataObj = dataItem[newMainKey];
      }

      if (dataItem[identifier] && dataItem[identifier][measure]) {
         dataObj = dataItem;
      }
      //For segmented view
      // debugger;
      if (segmentName !== '' && dataItem.segmented && dataItem.segmented[newMainKey]) {
         dataObj = dataItem.segmented[newMainKey];
      }
      // debugger 
      if (dataObj && dataObj[identifier] && dataObj[identifier][measure]) {
         yValue = round(dataObj[identifier][measure]);
         y2Value = round(dataObj[identifier][measureR]);
         //Handle Occ differently
         if (measure === "Occ") {
            yValue = round(dataObj[identifier][measure] * 100);
         }
         if (measureR === "Occ") {
            y2Value = round(dataObj[identifier][measureR] * 100);
         }
      }
      let pointVal = { "x": xValue, "y": yValue > 0 || yValue < 0 ? yValue : null };
      pointVal.y2 = y2Value;
      pointVal.allKpis = dataObj && dataObj[identifier] ? dataObj[identifier] : {};
      // if(pointVal.y === null) debugger;
      data.push(pointVal);
   }
   // debugger;
   return data;
};

const setSeriesName = (params) => {
   const { identifier, seriesLabel, tile, segmented_view, measure, segmentName } = params;
   // debugger;
   //Will implemented through configuration
   const segmment = segmented_view ? segmentName : "";
   const key = tile + "_" + segmentName + "_" + identifier + "_" + measure;
   const defaultName = tile + " " + segmentName + "_" + identifier + "_" + measure;
   const seriesNameMap = {
      otb_primary_occ: "OTB Occ% CURRENT",
      otb_primary_adr: "OTB Occ% CURRENT",
      otb_primary_revenue: "OTB Occ% CURRENT",
      otb_primary_revpar: "OTB Occ% CURRENT",
      otb_primary_rooms: "OTB Occ% CURRENT",
      otb_primary_sellrate: "OTB Occ% CURRENT",
      otb_primary_occ: "OTB Occ% CURRENT",
      otb_primary_occ: "OTB Occ% CURRENT",
      otb_sdly_actual_otb: "OTB LY Actuals"
   };
   //--->
   let measure_ = measure === "Occ" ? measure + "%" : measure;
   //Series name for Compset is defining in diffrent way and is name of compset property
   switch (tile) {
      case "otb":
      case "pickup":
      case "forecast":
         if (segmented_view) {
            return segmentName;
         }
         break;
      case "pace":
         //For Pace/Booking curve
         if (identifier === 'comparison') {
            return "COMPARISON" + " " + seriesLabel.toUpperCase();
         }
         return seriesLabel.toUpperCase();
         break;

      case "compset":
         let spls = ['median', 'min', 'max'];
         if (!spls.includes(identifier)) {
            let tmp = identifier.split('|')[0];
            // debugger;
            return tmp.split('compset_')[1];

         }
         return seriesLabel + ' ' + identifier;
         break;
      ///----
      case "market":
         if (identifier === 'market_forecast') {
            return seriesLabel + " " + "Projected";
         }

         break;

      case "market_sell_rates":
         return identifier.split("_").join(" ").toUpperCase();
         break;

      //default: //do nothing
   }

   switch (identifier) {
      case "pickup":
         return seriesLabel + " " + measure_ + " " + "PRIOR";
         break;

      case "primary":
         // return seriesLabel + " " + "TODAY";
         return seriesLabel + " " + measure_ + " " + "CURRENT";
         break;

      case "sdly":
      case "sdly_actual":
      case "sdly_actuals":
         return (seriesLabel + " " + measure_ + " " + identifier.split('_').join(" ")).toUpperCase();
         break;

      case "sdly_pickup":
         return seriesLabel + " " + measure_ + " " + "SDLY PRIOR";
         break;

      default: return seriesLabel + " " + identifier;
   }
}

const setToolTipLabel = (params) => {
   const { identifier, measure, seriesLabel, tile, segmented_view } = params;
   // debugger; 
   let measure_ = measure === "Occ" ? measure + "%" : measure;
   //Series name for Compset is defining in diffrent way and is name of compset property
   switch (tile) {
      case "otb":
         if (segmented_view) {
            return identifier;
         }
         break;
      case "pickup":
         return "Pickup";
         break;
      case "pace":
         //For Pace/Booking curve
         if (identifier === 'comparison') {
            return "COMPARISON" + " " + seriesLabel.toUpperCase();
         }
         return seriesLabel.toUpperCase();
         break;

      case "compset":
         let spls = ['median', 'min', 'max'];
         if (!spls.includes(identifier)) {
            let tmp = identifier.split('|')[0];
            return tmp.split('compset_')[1];
         }
         return 'Compset ' + identifier;
         break;
      ///----
      case "market":
         if (identifier === 'market_forecast') {
            return seriesLabel + " " + "Projected";
         }

         break;

      case "market_sell_rates":
         return identifier.split("_").join(" ").toUpperCase();
         break;

      //default: //do nothing
   }

   switch (identifier) {
      case "pickup":
         return seriesLabel + " " + "PRIOR";
         break;

      case "pickup_diff":
         return "Pickup";
         break;

      case "primary":
         // return seriesLabel + " " + "TODAY";
         return seriesLabel + " " + "CURRENT";
         break;

      case "comparison":
         // return seriesLabel + " " + "TODAY";
         return seriesLabel + " " + "CURRENT";
         break;

      case "sdly":
      case "sdly_actual":
      case "sdly_actuals":
         return (seriesLabel + " " + identifier.split('_').join(" ")).toUpperCase();
         break;

      case "sdly_pickup":
         return seriesLabel + " " + "SDLY PRIOR";
         break;

      default: return seriesLabel + " " + identifier;
   }
}

const setLegendLabel = (params) => {
   const { identifier, measure, seriesLabel, tile, segmented_view, segmentName } = params;
   // debugger;
   //Will implemented through configuration
   const segmment = segmented_view ? segmentName : "";
   const key = (identifier + '_' + measure).toLowerCase();
   const defaultName = tile + " " + segmentName + "_" + identifier + "_" + measure;
   // debugger;
   const seriesNameMap = {
      primary_occ: "Occ% CURRENT",
      primary_otb_occ: "Occ% CURRENT",
      primary_otb_adr: "ADR CURRENT",
      primary_adr: "ADR CURRENT",
      primary_revenue: "Revenue CURRENT",
      primary_otb_revenue: "Revenue CURRENT",
      primary_revpar: "Revpar CURRENT",
      primary_otb_revpar: "Revpar CURRENT",
      primary_rooms: "Rooms CURRENT",
      primary_otb_rooms: "Rooms CURRENT",
      primary_sellrate: "SellRate CURRENT",
      primary_otb_sellrate: "SellRate CURRENT",

      pickup_occ: "Occ% Prior",
      pickup_adr: "ADR Prior",
      pickup_revenue: "Revenue Prior",
      pickup_revpar: "Revpar Prior",
      pickup_rooms: "Rooms Prior",
      pickup_sellrate: "SellRate Prior",

      pickup_diff_occ: "Occ% PICKUP",
      pickup_diff_adr: "ADR PICKUP",
      pickup_diff_revenue: "Revenue PICKUP",
      pickup_diff_revpar: "Revpar PICKUP",
      pickup_diff_rooms: "Rooms PICKUP",
      pickup_diff_sellrate: "SellRate PICKUP",

      sdly_pickup_diff_occ: "SDLY Occ% PICKUP",
      sdly_pickup_diff_adr: "SDLY ADR PICKUP",
      sdly_pickup_diff_revenue: "SDLY Revenue PICKUP",
      sdly_pickup_diff_revpar: "SDLY Revpar PICKUP",
      sdly_pickup_diff_rooms: "SDLY Rooms PICKUP",
      sdly_pickup_diff_sellrate: "SDLY SellRate PICKUP",

      pace_primary_occ: "Occ% PACE",
      pace_primary_pace_occ: "Occ% PACE",

      pace_primary_adr: "ADR PACE",
      pace_primary_pace_adr: "ADR PACE",

      pace_primary_rooms: "Rooms PACE",
      pace_primary_pace_rooms: "Rooms PACE",

      pace_primary_revenue: "Revenue PACE",
      pace_primary_pace_revenue: "Revenue PACE",

      pace_primary_revpar: "Revpar PACE",
      pace_primary_pace_revpar: "Revpar PACE",

      pace_primary_sellrate: "SellRate PACE",
      pace_primary_pace_sellrate: "SellRate PACE",

      pace_comparison_occ: "Occ% PACE Comparison",
      pace_comparison_adr: "ADR PACE Comparison",
      pace_comparison_rooms: "Rooms PACE Comparison",
      pace_comparison_revepar: "Revpar PACE Comparison",
      pace_comparison_revenue: "Revpar PACE Comparison",
      pace_comparison_sellrate: "SellRate PACE Comparison",

      sdly_pickup_occ: "SDLY Occ% Prior",
      sdly_pickup_adr: "SDLY ADR Prior",
      sdly_pickup_diff_adr: "SDLY ADR Prior",
      sdly_pickup_revenue: "SDLY Revenue Prior",
      sdly_pickup_revpar: "SDLY Revpar Prior",
      sdly_pickup_rooms: "SDLY Rooms Prior",
      sdly_pickup_sellrate: "SDLY SellRate Prior",

      sdly_actual_pickup_occ: "LY Actual Occ% Prior",
      sdly_actual_pickup_adr: "LY ADR Prior",
      sdly_actual_pickup_revenue: "LY Actual Revenue Prior",
      sdly_actual_pickup_revpar: "LY Actual Revpar Prior",
      sdly_actual_pickup_rooms: "LY Actual Rooms Prior",
      sdly_actual_pickup_sellrate: "LY Actual SellRate Prior",

      sdly_occ: "SDLY Occ%",
      sdly_otb_occ: "SDLY Occ%",
      sdly_adr: "SDLY ADR",
      sdly_otb_adr: "SDLY ADR",
      sdly_revenue: "SDLY Revenue",
      sdly_revpar: "SDLY Revpar",
      sdly_rooms: "SDLY Rooms",
      sdly_sellrate: "SDLY SellRate",

      sdly_actual_occ: "LY Actual Occ%",
      sdly_actual_adr: "LY Actual ADR",
      sdly_actual_revenue: "LY Actual Revenue",
      sdly_actual_revpar: "LY Actual Revpar",
      sdly_actual_rooms: "LY Actual Rooms",
      sdly_actual_sellrate: "LY Actual SellRate",

      primary_forecast_occ: "Forecast Occ%",
      forecast_primary_occ: "Forecast Occ%",
      forecast_primary_forecast_occ: "Forecast Occ%",

      primary_forecast_revenue: "Forecast Revenue",
      forecast_primary_revenue: "Forecast Revenue",
      forecast_primary_forecast_revenue: "Forecast Revenue",

      primary_forecast_revpar: "Forecast Revpar",
      forecast_primary_revpar: "Forecast Revpar",
      forecast_primary_forecast_revpar: "Forecast Revpar",

      primary_forecast_sellrate: "Forecast SellRate",
      forecast_primary_sellrate: "Forecast SellRate",
      forecast_primary_forecast_sellrate: "Forecast SellRate",

      primary_forecast_adr: "Forecast ADR",
      forecast_primary_forecast_adr: "Forecast ADR",
      forecast_primary_adr: "Forecast ADR",

      primary_forecast_rooms: "Forecast Rooms",
      forecast_primary_forecast_rooms: "Forecast Rooms",
      forecast_primary_rooms: "Forecast Rooms",

      forecast_primary_revenue: "Forecast Revenue",
      forecast_primary_revpar: "Forecast Revpar",
      forecast_primary_rooms: "Forecast Rooms",
      forecast_primary_sellrate: "Forecast SellRate",
      forecast_primary_forecast_sellrate: "Forecast SellRate",

      market_primary_occ: "Market Occ%",
      market_primary_market_occ: "Market Occ%",

      market_primary_adr: "Market ADR",
      market_primary_market_adr: "Market ADR",

      market_primary_revenue: "Market Revenue",
      market_primary_market_revenue: "Market Revenue",

      market_primary_revpar: "Market Revpar",
      market_primary_market_revpar: "Market Revpar",

      market_primary_rooms: "Market Rooms",
      market_primary_market_rooms: "Market Rooms",

      market_primary_sellrate: "Market SellRate",
      market_primary_market_sellrate: "Market SellRate",

      market_sdly_occ: "Market SDLY Occ%",
      market_sdly_market_occ: "Market SDLY Occ%",

      market_sdly_adr: "Market SDLY ADR",
      market_sdly_market_adr: "Market SDLY ADR",

      market_sdly_revenue: "Market SDLY Revenue",
      market_sdly_market_revenue: "Market SDLY Revenue",

      market_sdly_revpar: "Market SDLY Revpar",
      market_sdly_market_revpar: "Market SDLY Revpar",

      market_sdly_rooms: "Market SDLY Rooms",
      market_sdly_market_rooms: "Market SDLY Rooms",

      market_sdly_sellrate: "Market SDLY SellRate",
      market_sdly_market_sellrate: "Market SDLY SellRate",

      market_occ_sdly_actual: "Market Occ% LY Actuals",
      market_sdly_actual_occ: "Market Occ% LY Actuals",
      market_sdly_actual_market_occ: "Market Occ% LY Actuals",

      market_adr_sdly_actual: "Market ADR LY Actuals",
      market_sdly_actual_adr: "Market ADR LY Actuals",
      market_sdly_actual_market_adr: "Market ADR LY Actuals",

      market_revenue_sdly_actual: "Market Revenue LY Actuals",
      market_sdly_actual_revenue: "Market Revenue LY Actuals",
      market_sdly_actual_market_revenue: "Market Revenue LY Actuals",

      market_revpar_sdly_actual: "Market Revpar LY Actuals",
      market_sdly_actual_revpar: "Market Revpar LY Actuals",
      market_sdly_actual_market_revpar: "Market Revpar LY Actuals",

      market_rooms_sdly_actual: "Market Rooms LY Actuals",
      market_sdly_actual_rooms: "Market Rooms LY Actuals",
      market_sdly_actual_market_rooms: "Market Rooms LY Actuals",

      market_sellrate_sdly_actual: "Market SellRate LY Actuals",
      market_sdly_actual_sellrate: "Market SellRate LY Actuals",
      market_sdly_actual_market_sellrate: "Market SellRate LY Actuals",

   };
   let measure_ = measure === "Occ" ? measure + "%" : measure;
   let identifierlabel = ""
   //Series name for Compset is defining in diffrent way and is name of compset property
   switch (tile) {
      case "otb":
         // debugger; 
         if (segmented_view) {
            let label = seriesNameMap[key] ? seriesNameMap[key] : key.split("_").join(" ");
            return seriesNameMap[key] ? seriesNameMap[key] : key.split("_").join(" ");
            return (tile.toUpperCase() + ' ' + label);
         } else {
            let label = seriesNameMap[key] ? seriesNameMap[key] : key.split("_").join(" ");
            return seriesNameMap[key] ? seriesNameMap[key] : key.split("_").join(" ");
         }
         break;
      case "pickup":
         // if (segmented_view) {
         let label = seriesNameMap[key] ? seriesNameMap[key] : key.split("_").join(" ");
         return label;
         // }
         break;
      case "forecast":
         // debugger;
         if (segmented_view) {
            let label = seriesNameMap[key] ? seriesNameMap[key] : key.split("_").join(" ");
            return seriesNameMap[key] ? seriesNameMap["forecast_" + key] : key.split("_").join(" ");
            // return (tile.toUpperCase() + ' ' + label);
         } else {
            // let label = seriesNameMap[key] ? seriesNameMap[key] : key.split("_").join(" ");
            return seriesNameMap[key] ? seriesNameMap["forecast_" + key] : key.split("_").join(" ");
            return (tile.toUpperCase() + ' ' + label);
         }
         break;
      case "pace":
         //For Pace/Booking curve
         // debugger; 
         let mapKey = 'pace_' + key;
         return seriesNameMap[mapKey] ? seriesNameMap[mapKey] : mapKey.split("_").join(" ");
         break;

      case "compset":
         let spls = ['median', 'min', 'max'];
         if (!spls.includes(identifier)) {
            let tmp = identifier.split('|')[0];
            return tmp.split('compset_')[1];
         }
         return seriesLabel + ' ' + identifier;
         break;
      ///----
      case "market":
         // debugger;
         if (seriesNameMap['market_' + key]) {
            return seriesNameMap['market_' + key];
         }
         if (identifier === 'market_forecast') {
            return seriesLabel + " " + measure_ + " Projected";
         }
         // debugger;
         if (identifier === 'sdly_actual') {
            // return seriesLabel + " " + measure_ + " Projected";
            return seriesLabel + " " + measure_ + " LY Actuals";
         }

         break;
      case "otb_v2_multi_segment":
         return measure_ + " " + identifier.split("_").join(" ").toUpperCase();
      case "market_sell_rates":
         return identifier.split("_").join(" ").toUpperCase();
         break;
      case "bi_sensitivity_analysis":
         identifierlabel = identifier.split("_")[3];
         if (dayOfWeekGlobal === 'mon,tue,wed,thu,fri,sat,sun'
            || dayOfWeekGlobal === 'mon,tue,wed,thu' ||
            dayOfWeekGlobal === 'fri,sat,sun') {
            identifierlabel = identifier.split("_")[2] + " - " + identifier.split("_")[3]
         }
         return measure_ + " " + identifierlabel
      //default: //do nothing
   }

   switch (identifier) {
      case "pickup":
         return seriesLabel + " " + measure_ + " " + "PRIOR";
         break;

      case "primary":
         // return seriesLabel + " " + "TODAY";
         return seriesLabel + " " + measure_ + " " + "CURRENT";
         break;

      case "sdly":
      case "sdly_actual":
      case "sdly_actuals":
         return (seriesLabel + " " + measure_ + " " + identifier.split('_').join(" ")).toUpperCase();
         break;

      case "sdly_pickup":
         return seriesLabel + " " + measure_ + " " + "SDLY PRIOR";
         break;

      default: return seriesLabel + " " + identifier;
   }
}



const generateIdentifiers = (params) => {
   const { tile, data_id, displayData, resource_id, pickUpType } = params;
   // debugger;
   let { chartConfig, data_sources } = TILES[tile];
   let { identifiers } = deepCopy(chartConfig);
   if (tile === 'pickup' && pickUpType === 'Latest') {
      //identifiers = ['pickup_diff'];
   }
   if (tile === 'compset') {
      if (data_sources.includes(data_id)) {
         // intialMeasures[0] = '';  
         if (displayData && displayData.length > 0) {
            const toBeremove = ['Avg', 'Total', 'index', 'min', 'max', 'Min', 'Max', 'Median'];
            let allKeys = Object.keys(displayData[0]);

            toBeremove.map((key) => {
               let i = allKeys.indexOf(key);
               if (i > -1) allKeys.splice(i, 1);
            });
            // debugger; 
            // Set current property resource id to chartConfig only for compset tile
            // as in this tile we need to initially hide other series
            allKeys.map((val, i) => {
               let rId = Number(val.split('|')[1]);
               if (!isNaN(rId) && resource_id === rId) {
                  //shift current property name to top
                  allKeys.splice(i, 1);
                  allKeys.splice(1, 0, val);
                  let rName = val.split('|')[0].split('compset_')[1];
                  chartConfig.resource = { fullName: val, id: rId, name: rName };
               }
            })
            identifiers = [...identifiers, ...allKeys];
         }
      }
   }
   // debugger
   return identifiers;
}

const setChartSeriesColorKey = (params) => {
   //This will return like this: Tile_Identifier_measure_l/r
   const { tile, identifier, identifierIndex, measure, measureIndex, seriesName, resource_id, isMainColor } = params;
   let ckey = '';
   ckey = measureIndex ? (tile + '_' + identifier + "_" + measure + "_" + 'r') : (tile + '_' + identifier + "_" + measure + "_" + 'l');
   if (tile === 'compset') {
      let compset_rid = identifier.split('|')[1];
      ckey = measureIndex ? (tile + '_' + compset_rid + "_" + measure + "_" + 'r') : (tile + '_' + compset_rid + "_" + measure + "_" + 'l');
   }
   if (isMainColor) {
      ckey = measureIndex ? (tile + '_' + identifier + "_" + 'r') : (tile + '_' + identifier + "_" + 'l');
   }
   return ckey.toUpperCase().split(' ').join('_');
}

const generateChartSeries = (params) => {
   const { data, identifier, identifierIndex, measure, measureR, measureIndex, chartConfig, tile, seriesLabel, segmented_view, segmentName } = params;
   const { series } = deepCopy(CHART_OPTIONS.default);
   let seriesName = setSeriesName({ identifier, seriesLabel, tile, segmented_view, measure });
   const yAxis = ['primaryYAxis', 'secondaryRightYAxis'];
   series.name = seriesName;
   series.tooltipLabel = setToolTipLabel({ identifier, measure, seriesLabel, tile, segmented_view });
   series.legendLabel = setLegendLabel({ identifier, measure, seriesLabel, tile, segmented_view, segmentName });
   series.segmentName = segmentName;
   series.tile = tile;
   series.mapings = {
      column: (tile + '_' + measure).toUpperCase(),
      general: (tile + '_' + identifier).toUpperCase(),
      specific: (tile + '_' + identifier + '_' + measure).toUpperCase()
   }
   // series.colorKey = setChartSeriesColorKey({ identifier, identifierIndex, measure, measureIndex, seriesName, resource_id, tile });
   // series.mainCKey = setChartSeriesColorKey({ isMainColor: true, identifier, identifierIndex, measure, measureIndex, seriesName, resource_id, tile });
   //series.currency = { code: currency, symbol: currency_symbol };
   // debugger; 
   series.id = seriesName + '_' + measureIndex;

   series.segmentName = segmentName;
   series.identifier = identifier;
   series.identifierIndex = identifierIndex;
   series.measure = measure;
   series.measureIndex = measureIndex;
   series.zIndex = measureIndex + 1;
   series.yAxis = yAxis[measureIndex] + measure;

   //For segmented view only
   //series.id = segmented_view ? segmentName + '-' + identifier + '-' + measureIndex : series.id;
   series.id = segmented_view ? segmentName + '-' + tile + '-' + identifier + '-' + measureIndex : series.id;
   if (measureIndex === 0 && segmented_view && segmentName !== 'total') {
      series.stack = identifier;
      series.stacking = 'normal';
      series.subCategory = segmentName.split('-')[0];
      series.legendLabel = segmentName;
      // series.showInLegend = identifier !== 'primary' ? false : true;
   }
   if (segmented_view && measureIndex && segmentName === 'total') {
      //For segmented view
      // series.visible = true;
      // series.showInLegend = false;
      series.setLegendLabel = seriesLabel + ' ' + measure + ' ' + identifier;
   }

   series.data = generateChartData(data, { chartConfig, identifier, measure, measureR, segmented_view, segmentName, tile });
   return series;
}

