import { Box, Select, MenuItem } from '@mui/material'
import moment from 'moment';
import { useState } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { updateAppState } from '../../../../actions/appstate';
import { deepCopy, dateDiff } from '../../../../app/util';


//this value will need to be changed with state later


const ScopePicker = () => {
    const controls = useSelector(state => state.app.current_state.app_state.workspace_controls);
    const [scopeType, setScopeType] = useState(controls.scopeType)
    const { app_state, ui_state } = useSelector(
        (state) => state.app.current_state
    );
    const dispatch = useDispatch();

    const handleChange = (type, value) => {
        const updateTypes = [type];
        controls.scopeType = value;
        //setScopeType(value);
        let tempDateRange = {};
        tempDateRange.date_from = moment().startOf('month').format("YYYY-MM-DD");
        tempDateRange.date_until = moment().endOf('month').format("YYYY-MM-DD");
        // dispatch(updateAppState(updateTypes, {app_state: {workspace_controls: controls},ui_state:{showspinner:true}}));
        // dispatch(updateAppState(updateTypes, {
        //     app_state: {
        //         workspace_controls: controls,
        //         primary_stay_range: { ...app_state.primary_stay_range, ...tempDateRange },
        //         data_load: true
        //     },
        //     ui_state: { showspinner: true }
        // }));

        // //////// update range //////
        // debugger
        if (value === "Dba") {
            // debugger;
            let workspaceControls = {};
            if (ui_state.active_tiles.includes('market')) {
                workspaceControls.segmented_view = false;
                app_state.workspace_controls.segmented_view = false;
            }
            // let tempDateRange = {};
            // tempDateRange.date_from = moment().startOf('month').format("YYYY-MM-DD");
            // tempDateRange.date_until = moment().startOf('month').format("YYYY-MM-DD");
            let historicalRange = deepCopy(app_state.primary_stay_range);
            app_state.historicalRange = deepCopy(app_state.primary_stay_range);
            app_state.primary_stay_range.date_until = app_state.primary_stay_range.date_from;
            app_state.comp_stay_range.date_until = app_state.comp_stay_range.date_from;
            // let comp_as_of_date = new moment(app_state.comp_stay_range.comp_as_of_date).subtract(1, 'year').format('YYYY-MM-DD');
            let comp_date_from = new moment(app_state.primary_stay_range.date_from).subtract(1, 'year').format('YYYY-MM-DD');
            let comp_date_until = comp_date_from;
            let comp_stay_range = { ...app_state.comp_stay_range,  date_until: app_state.comp_stay_range.date_until }
            
            app_state.comp_stay_range.date_until = app_state.comp_stay_range.date_from;
            dispatch(updateAppState('stay_range', {
                app_state: {
                    ...app_state,
                    date_range_type: 'scopeTypeChanged_Dba',
                    //   as_of_date,
                    // comp_as_of_date,
                    //historicalRange: { ...historicalRange },
                    //primary_stay_range: { ...app_state.primary_stay_range, date_until: app_state.primary_stay_range.date_from },
                    //workspace_controls: { ...app_state.workspace_controls, ...workspaceControls },
                    //comp_stay_range: { ...comp_stay_range },
                    // data_load: true,
                    // kpi_load: true
                },
                ui_state: { ...ui_state, showspinner: true }
            }))
            // debugger;
        } else {
            // debugger           
            let daysDiff = dateDiff(app_state.historicalRange.date_until, app_state.historicalRange.date_from);;
            let fromDateLastYear = new moment(app_state.primary_stay_range.date_from).subtract(1, 'year').startOf('months').format("YYYY-MM-DD");
            let untilDateLastYear = new moment(fromDateLastYear).add(daysDiff, 'day').format("YYYY-MM-DD");
            let primaryAsOf = new moment(app_state.primary_stay_range.as_of_date);
            let sdlyAsOf = new moment(app_state.primary_stay_range.as_of_date).subtract(1, 'year')
                .isoWeek(primaryAsOf.isoWeek())
                .isoWeekday(primaryAsOf.isoWeekday());

            let cAsOfDate = moment(app_state.primary_stay_range.as_of_date).subtract(1, 'year').format("YYYY-MM-DD");
            let comp_stay_range = { date_from: fromDateLastYear, date_until: untilDateLastYear, comp_as_of_date: cAsOfDate }
            let date_until = new moment(app_state.comp_stay_range.date_from).add(daysDiff, 'day').format("YYYY-MM-DD");
            app_state.primary_stay_range.date_from = app_state.historicalRange.date_from;
            app_state.primary_stay_range.date_until = app_state.historicalRange.date_until;
            dispatch(updateAppState('stay_range', {
                app_state: {
                    ...app_state,
                    //   as_of_date,
                    // comp_as_of_date,
                    date_range_type: 'scopeTypeChanged_Date',
                    // primary_stay_range: { ...app_state.historicalRange },
                    comp_stay_range: { ...comp_stay_range, date_until:date_until },
                    workspace_controls: { ...app_state.workspace_controls, scopeType: 'Date'},
                    // data_load: true,
                    // kpi_load: true
                },
                ui_state: { ...ui_state, showspinner: true }
            }))
            // debugger;
        }

        // /////////////////

    }
    // debugger;
    return (
        <Box id="scope" sx={{ backgroundColor: 'blue', height: 1 / 2 }}>
            <Box sx={{ display: 'block', fontSize: '75%', fontWeight: 'bold' }}>SCOPE</Box>
            <Box>
                <Select fullWidth value={controls.scopeType}
                    sx={{ height: "27px", mt: "5px", fontSize: "13px" }}
                    onChange={(e) => { setScopeType(e.target.value); handleChange("scope_select", e.target.value); }} >
                    <MenuItem className='tabular-data-filter' value="Date"> Date </MenuItem>
                    <MenuItem className='tabular-data-filter' value="Dba"> DBA </MenuItem>
                </Select>
            </Box>
        </Box>
    )
}

export default ScopePicker