import { Box, Button, Badge } from '@mui/material'
import CloudDownloadIcon from '@mui/icons-material/CloudDownload';
import CloudUploadOutlinedIcon from '@mui/icons-material/CloudUploadOutlined';
import { useDispatch, useSelector } from 'react-redux';
import { updateAppState } from '../../../../actions/appstate';
import React, { useState, useEffect } from "react";
import SendPricingRate from "../../../Modal/SendPricingRate"
import { getPricingDataFormatted } from '../../../DynamicTable/tilesData/pricing';

const SendRatesButton = (props) => {
    const { app_state, ui_state } = useSelector(state => state.app.current_state);
    const {isRoomTypePricing} = app_state.workspace_controls;
    const [numRatesChanged, setNumRatesChanged] = useState(0);
    const [isOpen, setisOpen] = useState(false)
    const dispatch = useDispatch();
  
    useEffect(() => {
        let ratesChanged = 0;
        const pricingData = getPricingDataFormatted(app_state,true);
        if(isRoomTypePricing === false){
            Object.keys(app_state.pricing_override_rates).map(key => {
                if (app_state.pricing_override_rates[key].approved === true) {
                    ratesChanged += 1;
                }
            })
        } else {

            Object.keys(app_state.roomtype_pricing_overrides).map(stay_date => {
                let dayData = {};
                pricingData.map(item=>{
                    if(item.stay_date === stay_date){
                        dayData = item;
                    }
                });
                Object.keys(app_state.roomtype_pricing_overrides[stay_date]).map(room_code=>{
                    if (app_state.roomtype_pricing_overrides[stay_date][room_code].SellRate) {
                        // pricingData
                        if(dayData[room_code] && dayData[room_code].SellRate !== app_state.roomtype_pricing_overrides[stay_date][room_code].SellRate  ){
                            ratesChanged += 1;
                        }
                       
                    }
                    // ratesChanged += 1;
                })
                
            })
        }
        
        setNumRatesChanged(ratesChanged);
        //setisOpen(ui_state.pricingRateModalOpen)
        return () => {

        }
    }, [app_state.pricing_override_rates,app_state.roomtype_pricing_overrides, app_state.workspace_controls, ui_state.pricingRateModalOpen])
    const handleClick = () => {
        if(numRatesChanged>0){
            setisOpen(true)
            dispatch(updateAppState("sendRatesClick",
             { ui_state: { pricingRateModalOpen: true } }));
        }

    }
    return (
        <>
        <Badge component="div" classes={{ badge:'pulse'}}  badgeContent={numRatesChanged} color="primary" className='btn-ratepush' sx={{ mt: 0, mr: 0, position: 'relative' }}>
            <Button disabled={(numRatesChanged<1)} className=' PushRateButton' size="small" sx={{ m: "auto" }} onClick={handleClick}>
                <span>Update</span>
            </Button>
        </Badge>
        <SendPricingRate isOpen={isOpen}/>

        </>
    )

}


export default SendRatesButton