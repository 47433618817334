//React
import React, { useEffect, useState } from 'react';
//React Redux
import { useDispatch, useSelector } from 'react-redux';

//Highcharts
import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';
import { CHART_OPTIONS } from '../../app/config';

import { generatDailyFlashChartOptions } from './functions';

import {
    Grid
} from '@mui/material';

//Util functions
import { deepCopy, getTileById } from '../../app/util';

//Moment
import moment from "moment";
//APP's
import Spinner from '../Spinner';
import './dailyflash.css';

function DataChart(props) {
    // const chartOptions = deepCopy(CHART_OPTIONS.dailyFlash);
    const { app_state, ui_state } = useSelector(
        (state) => state.app.current_state
    );
    const { data } = props;
    const [optionOtbByMonth, setOptionOtbByMonth] = useState({});
    const [optionPickupByMonth, setOptionPickupByMonth] = useState({});
    const [showLoader, setShowLoader] = useState(true);

    useEffect(() => {
        let isSubscribed = true;
        if (isSubscribed) {
            if (data && data.length) {
                // debugger;
                if (showLoader) {
                    setShowLoader(false);
                }
                if (!showLoader) {
                    let tile = getTileById('daily_flash');
                    let { chartConfig } = tile;
                    let chartOptions = generatDailyFlashChartOptions(data, {
                        reportType: 'otb',
                        identifiers: ['primary', 'sdly', 'sdly_actual'],
                        measures: ['Occ', 'ADR'],
                        data_id: 'aggregates', xAxisKey: 'monthData',
                        ui_state
                    });
                    setOptionOtbByMonth(chartOptions);

                    let chartOptionsPickup = generatDailyFlashChartOptions(data, {
                        reportType: 'pickup',
                        identifiers: ['pickup'],
                        measures: ['Rooms', 'ADR'],
                        data_id: 'aggregates', xAxisKey: 'monthData',
                        ui_state
                    });
                    // debugger;

                    setOptionPickupByMonth(chartOptionsPickup);
                }
            }
        }


        return () => (isSubscribed = false)
    }, [data, showLoader]);

    return (
        <div className='chart-view data-table-container'>
            <Grid container spacing={1}>
                <Grid item xs={6}>
                    <div className='chart-box'>
                        <header className='chart-title'>ON THE BOOKS (BY MONTH)</header>
                        <div className='chart-container'>
                            {!showLoader && <HighchartsReact
                                highcharts={Highcharts}
                                options={optionOtbByMonth} />
                            }
                            {showLoader && <Spinner />}
                        </div>
                    </div>
                </Grid>
                <Grid item xs={6}>
                    <div className='chart-box'>
                        <header className='chart-title'>PICK-UP (BY MONTH)</header>
                        <div className='chart-container'>
                            {!showLoader && <HighchartsReact
                                highcharts={Highcharts}
                                options={optionPickupByMonth} />
                            }
                            {showLoader && <Spinner />}
                        </div>
                    </div>
                </Grid>
            </Grid>

        </div>
    )
}

export default DataChart
