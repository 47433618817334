
import { useState } from "react";
import { useDispatch, useSelector } from 'react-redux';
import { updateAppState } from '../../../../actions/appstate';
import { Box, Switch } from '@mui/material'
import { deepCopy } from '../../../../app/util';
import dataService from '../../../../services/data.service';
import moment from 'moment';

const SdlyButton = () => {
    const controls = useSelector(state => state.app.current_state.app_state.workspace_controls);
    const { app_state, ui_state } = useSelector(
        (state) => state.app.current_state
    );
    const [on, setOn] = useState(controls.sdlyOn ? true : false);
    // const controls = useSelector(state => state.app.current_state.app_state.workspace_controls);
    const dispatch = useDispatch();

    const handleChange = (type, value) => {

        let propertyDetails = deepCopy(dataService.getSeriesById("GetPropData").data);
        let sdYearsArr = propertyDetails.years;
        let rt_years = propertyDetails["rt-years"];
        let rp_years = propertyDetails["rp-years"];
        let default_ly = propertyDetails.default_ly;
    
        let lyYears = moment(app_state.as_of_date).subtract(1,'years').format('YYYY');
        controls.sdYear = lyYears;//(default_ly) ? default_ly : controls.sdYear;
        controls.sdYearsArr = (sdYearsArr.length > 0) ? sdYearsArr : controls.sdYearsArr;

        controls.rt_years = (rt_years.length > 0) ? rt_years : controls.sdYear;
        controls.rp_years = (rp_years.length > 0) ? rp_years : controls.sdYear;

        const updateTypes = [type];
        controls.sdlyOn = value;
        setOn(value);
        
        setTimeout(() => {
            dispatch(updateAppState(type, {
                app_state: {
                    ...app_state,
                    workspace_controls: { ...controls, sdlyOn: value },
                    data_load: true,
                    // kpi_load: true
                },
                ui_state: { ...ui_state, showspinner: true }
            }));
        }, 50);
        
    }


    return (
        <Box sx={{ }}>
            <Box className='filterElementText' sx={{pl:1}}>SDLY</Box>
            <Switch className='control-switch' checked={on} onChange={() => {
                handleChange("sdlyToggle", !controls.sdlyOn)
            }} />
            
            {/* <div class="checkbox-wrapper-51" checked={on} onChange={() => {
                handleChange("sdlyToggle", !controls.sdlyOn)
            }} >
                <input type="checkbox" id="cbx-51" />
                <label for="cbx-51" class="toggle">
                    <span>
                    <svg width="10px" height="10px" viewBox="0 0 10 10">
                        <path d="M5,1 L5,1 C2.790861,1 1,2.790861 1,5 L1,5 C1,7.209139 2.790861,9 5,9 L5,9 C7.209139,9 9,7.209139 9,5 L9,5 C9,2.790861 7.209139,1 5,1 L5,9 L5,1 Z"></path>
                    </svg>
                    </span>
                </label>
            </div> */}
        </Box>
    )


}

export default SdlyButton
