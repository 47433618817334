import { ExpandMore as ExpandMoreIcon } from "@mui/icons-material/";
import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { UPDATE_STATE } from "../../actions/types";

import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Divider,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Typography,
} from "@mui/material/";
import "./style.css";
import { color } from "highcharts";

function SettingsSideMenu(props) {
  const dispatch = useDispatch();
  const { app, auth } = useSelector((state) => state);
  const { user } = auth;
  const { user_login_type } = user;
  // let user_login_type = 2;
  const userTypeMap = {
    "0": "user",
    "1": "userAdmin",
    "2": "lodgiqAdmin",
    "3": "readOnly",
    "4": "qa",
    "5": "limited"
  };
  // const userType = userTypeMap[user_login_type];

  const { app_state, ui_state } = app.current_state;
  const active_settings_menu = ui_state.active_settings_menu;
  const { settingsActiveIndex } = ui_state;
  const [selectedIndex, setSelectedIndex] = useState(props.activeItemIndex);

  // debugger;
  if (ui_state.isFromHeader) {
    dispatch({
      type: UPDATE_STATE,
      payload: { ui_state: { isFromHeader: false } },
    });
    props.handleChange(ui_state.active_settings_menu)(null, true);
  }
  const handleListItemClick = (item) => (event, isExpanded) => {
    setSelectedIndex(item.index);
    dispatch({
      type: UPDATE_STATE,
      payload: {
        ui_state: {
          activeSettingsComponentName: item.component,
          settingsActiveIndex: item.index,
        },
      },
    });
  };

  return (
    <Box sx={{}}>
      {props.data.length === 1 && <Accordion
        component="div"
        className="settings-side-menu single-child"
        elevation={0}
        square={true}
        expanded={props.expanded}
        onChange={props.handleChange(props.menuId)}
        sx={{ backgroundColor: "#333", color: "#fff" }}
      >
        <AccordionSummary className="accordian-menu">
          <Box sx={{ width: "100%" }}>
            <nav>
              <List sx={{ maxHeight: "50vh", overflow: "auto", pb: 0 }}>
                {props.data.map((item, index) => {
                  // debugger; 
                  if (item.access && item.access && item.access.includes(user_login_type)) {
                    return (<div key={"menu-" + index} className="menu-item">
                      {/* <ListItem disablePadding> */}
                      <ListItemButton
                        className="settings-side-menu-item "
                        selected={settingsActiveIndex === item.index}
                        onClick={handleListItemClick(item)}
                        sx={{ p: 0, pl: 0 }}
                      >
                        <ListItemIcon sx={{ minWidth: 25 }}>
                          {item.icon}
                        </ListItemIcon>
                        <span className="menu-item-label">{item.label}</span>
                      </ListItemButton>
                      {/* </ListItem> */}
                    </div>)
                  } else {
                    return false;
                  }
                })}
              </List>
            </nav>
          </Box>
        </AccordionSummary>

      </Accordion>}
      {props.data.length > 1 && <Accordion
        className="settings-side-menu"
        square={true}
        expanded={props.expanded}
        onChange={props.handleChange(props.menuId)}
        sx={{ backgroundColor: "#333", color: "#fff" }}
      >
        <AccordionSummary className="accordian-menu" expandIcon={<ExpandMoreIcon />}>
          {props.menuIcon}
          <Typography sx={{ ml: 1, flexShrink: 0 }}>{props.title}</Typography>
        </AccordionSummary>
        <AccordionDetails sx={{ p: 0 }}>
          <Box sx={{ width: "100%" }}>
            <nav>
              <List sx={{ maxHeight: "50vh", overflow: "auto", pb: 0 }}>
                {props.data.map((item, index) => {
                  if (item.access && item.access.includes(user_login_type)) {
                    return (
                      <div key={"menu-" + index} className="menu-item">
                        <ListItem disablePadding>
                          <ListItemButton
                            selected={settingsActiveIndex === item.index}
                            onClick={handleListItemClick(item)}
                            sx={{ p: 0, pl: 1 }}
                          >
                            <ListItemIcon sx={{ minWidth: 25, ml: 2 }}>
                              {item.icon}
                            </ListItemIcon>
                            <ListItemText
                              className="menu-item-label"
                              primary={item.label}
                            />
                          </ListItemButton>
                        </ListItem>
                        <Divider sx={{ display: item.isLast ? "none" : "block" }} />
                      </div>
                    )
                  } })}
              </List>
            </nav>
          </Box>
        </AccordionDetails>
      </Accordion>}
    </Box>
  );
}

export default SettingsSideMenu;
