import React, { useState, useEffect } from 'react'
import { useDispatch, useSelector } from "react-redux";
import { updateAppState, getPropertyInfo } from "../../actions/appstate";
import {
    safeReference, 
} from "../../app/util"
import { styled } from '@mui/material/styles';
import Tab from '@mui/material/Tab';
import Box from '@mui/material/Box';
import { height } from '@mui/system';
import Chip from '@mui/material/Chip';
import Stack from '@mui/material/Stack';
import Avatar from '@mui/material/Avatar';


const handleClick = () => {
  console.info('You clicked the Chip.');
};

const handleDelete = () => {
  console.info('You clicked the delete icon.');
};

const AntTab = styled((props) => <Tab disableRipple {...props} />)(({ theme }) => ({
  textTransform: 'none',
  minWidth: 0,
  [theme.breakpoints.up('sm')]: {
    minWidth: 0,
  },
  fontWeight: theme.typography.fontWeightRegular,
  marginRight: theme.spacing(1),
  color: 'rgba(0, 0, 0, 0.85)',
  fontFamily: [
    '-apple-system',
    'BlinkMacSystemFont',
    '"Segoe UI"',
    'Roboto',
    '"Helvetica Neue"',
    'Arial',
    'sans-serif',
    '"Apple Color Emoji"',
    '"Segoe UI Emoji"',
    '"Segoe UI Symbol"',
  ].join(','),
  '&:hover': {
    color: '#40a9ff',
    opacity: 1,
  },
  '&.Mui-selected': {
    color: '#1890ff',
    fontWeight: theme.typography.fontWeightMedium,
  },
  '&.Mui-focusVisible': {
    backgroundColor: '#d1eaff',
  },
}));



const StyledTab = styled((props) => <Tab disableRipple {...props} />)(
  ({ theme }) => ({
    textTransform: 'none',
    fontWeight: theme.typography.fontWeightRegular,
    fontSize: theme.typography.pxToRem(15),
    marginRight: theme.spacing(1),
    color: 'rgba(255, 255, 255, 0.7)',
    '&.Mui-selected': {
      color: '#fff',
    },
    '&.Mui-focusVisible': {
      backgroundColor: 'rgba(100, 95, 228, 0.32)',
    },
  }),
);
function RoomTypeFilter() {
  const dispatch = useDispatch();
  const { profile } = useSelector(
    (state) => state.auth
  );
  const { app_state, ui_state } = useSelector(
    (state) => state.app.current_state
  );
  
  const [categoryList, setCategoryList] = useState([]);

  useEffect(() => {
    let superTypes = {
      "All":[],
    };
    let categoryList = [];
    // let propertyInfo = getPropertyInfo(profile.property_details, app_state.property_id);
    // let room_settings = safeReference(propertyInfo.system_settings[0], [], ["inventory_settings", "current", "room_settings"])
    let {room_settings} = app_state;
    room_settings.forEach((room, index) => {
      if (superTypes[room.room_class]) {
        superTypes[room.room_class].push(room.room_code)
      } else {
        superTypes[room.room_class] = [room.room_code]

      }
      superTypes["All"].push(room.room_code);
    });
    Object.keys(superTypes).map(type => {
      categoryList.push({ room_class: type, room_codes: superTypes[type] });
    })
    setCategoryList(categoryList)
   
    return () => {

    }
  }, [app_state.workspace_controls.isRoomTypePricing, app_state.property_id,])
  let roomSelectDisp = {};
  let avail_room_cat = ui_state.selectedRoomPricingCategory.map(item=>  item.room_class);

  if(avail_room_cat.includes("All")){
    roomSelectDisp={display:"none"};
  }
  let roomCatDisp = {};
  if(app_state.workspace_controls.isRoomTypePricing === false){
    roomCatDisp={visibility:"hidden"};
    roomSelectDisp={display:"none"}
  }
  return (
    <Box sx={{ width: '100%' }}>
      {/* <Box>
        <Stack direction="row" spacing={1}>
          <Chip className='room-property'
            avatar={<div className='avtar-size'>S1KHA</div>}
            label={<div className='property-value'>Kashi</div>}
            // onClick={handleClick}
            onDelete={() => { }}
            deleteIcon={<span className='delete-button'>x</span>}
          />
          <Chip className='room-property'
            avatar={<div className='avtar-size'>S1KHA</div>}
            label={"Clickable Deletable"}
            // onClick={handleClick}
            onDelete={() => { }}
            deleteIcon={<span className='delete-button'>x</span>}
          />
          <Chip className='room-property'
            avatar={<div className='avtar-size'>S1KHA</div>}
            label={"Clickable Deletable"}
            // onClick={handleClick}
            onDelete={() => { }}
            deleteIcon={<span className='delete-button'>x</span>}
          />
        </Stack>
      </Box> */}
      <Box className='rooms-category' style={roomCatDisp} >
        {
          categoryList.map((category, index) => {
            let selectedClass = "room-class-category-tab";
            let avail_room_cat = ui_state.selectedRoomPricingCategory.map(item=>  item.room_class);
            if (avail_room_cat.includes(category.room_class)) {
              selectedClass += " Mui-selected";
            }
            return (<AntTab onClick={(e) => {
              let room_codes = [];
              let avail_room_cat = ui_state.selectedRoomPricingCategory.map(item=>  item.room_class);

              if (e.shiftKey && category.room_class !== "All"  && !avail_room_cat.includes("All")) {
                if(!avail_room_cat.includes(category.room_class)){
                  ui_state.selectedRoomPricingCategory.push(category);
                }else {
                  let indexat =-1;
                   ui_state.selectedRoomPricingCategory.forEach((cat, ind)=>{
                     if(category.room_class === cat.room_class){
                       indexat = ind;
                     }
                   })
                  ui_state.selectedRoomPricingCategory.splice(indexat, 1);
                }
                ui_state.selectedRoomPricingCategory.forEach(item=>{
                  room_codes = room_codes.concat(item.room_codes);
                })
              } else {
                room_codes = [...category.room_codes];
                ui_state.selectedRoomPricingCategory=[category]
              }
              dispatch(updateAppState("change_room_pricing_category", {
                ui_state: {
                  selectedRoomPricingCategory: ui_state.selectedRoomPricingCategory,
                  selectedRoomPricingRoomCodes: room_codes,
                  updatedFilter:true,
                  // showspinner:true
                }
              }));
            
            }} label={category.room_class} key={index} className={selectedClass} />)
          })
        }
      </Box>
      <Box className='room-type-pricing' style={roomSelectDisp} >
        {
          categoryList.map(category => {
            let returnDiv = [];
            let avail_room_cat = ui_state.selectedRoomPricingCategory.map(item=>  item.room_class);
            if (avail_room_cat.includes(category.room_class)) {

              category.room_codes.map((code, index) => {
                //return (<StyledTab label={code} key={index}/>);
                let selectedClass = "";
                if (ui_state.selectedRoomPricingRoomCodes.includes(code)) {
                  selectedClass += "primary-selected-tab";
                }

                returnDiv.push(<StyledTab onClick={(e) => {
                  if (e.shiftKey ) {
                    if(!ui_state.selectedRoomPricingRoomCodes.includes(code)){
                      ui_state.selectedRoomPricingRoomCodes.push(code);
                    }else {
                      let indexat = ui_state.selectedRoomPricingRoomCodes.indexOf(code)
                      ui_state.selectedRoomPricingRoomCodes.splice(indexat, 1);
                    }
                  } else {
                    ui_state.selectedRoomPricingRoomCodes=[code]
                  }
                  dispatch(updateAppState("change_room_pricing_room_codes", {
                    ui_state: {
                      selectedRoomPricingRoomCodes: ui_state.selectedRoomPricingRoomCodes,
                      updatedFilter:true,
                      // showspinner:true
                    }
                  }));
                }} label={code} key={index} className={selectedClass} />)
              })
            }
            return returnDiv;
          })
        }
      </Box>

    </Box>
  )
}

export default RoomTypeFilter
