import React, { useState, useEffect, useReducer, useRef } from 'react';
import { API_REGISTRY } from "../../app/config";
import moment from 'moment';
import {
    Tooltip, Box, Alert, Stack, Chip, CircularProgress,
    Grid, Button, LinearProgress, Badge, Modal, IconButton,
    Table, TableBody, TableCell, TableContainer, TableHead, TableRow
} from '@mui/material';
import {
    CloseSharp as CloseSharpIcon,
    ArrowBackIosNewSharp as ArrowBackIosNewSharpIcon,
    ArrowForwardIosSharp as ArrowForwardIosSharpIcon,
    CheckSharp as CheckSharpIcon,
    CloudUploadOutlined as CloudUploadOutlinedIcon,
    FileDownloadOutlined as FileDownloadOutlinedIcon
}
    from '@mui/icons-material';
import { useDispatch, useSelector } from 'react-redux';
import { getPropertyInfo, updateAppState } from '../../actions/appstate';
import dataService from '../../services/data.service';
import { deepCopy, getFromSession, safeReference } from "../../app/util";
import "./style.css";
import TableToExcel from "@linways/table-to-excel";

export default function SendRestrictions(props) {
    const downloadReportToExcel =  (name, selectedPropertyName, tableID)=> {

        let exportFileName = name + "_" + selectedPropertyName;
        let table = document.getElementById(tableID).cloneNode(true);
        table.querySelectorAll(".ratepush-tbody .res-rules-display").forEach(node=>{
            let rulesArr = [];
            node.querySelectorAll(".chip").forEach(chip=>{
                rulesArr.push(chip.textContent);
            })
            node.innerHTML=rulesArr.join(", ")  
        })


        TableToExcel.convert(table, {
            name: exportFileName + ".xlsx",
            sheet: {
                name: name.toUpperCase()
            }
        });
    
    }
    const { modalOpen, handleModalClose } = props;
    const dispatch = useDispatch();
    const { app_state, ui_state } = useSelector((state) => state.app.current_state);
    const { roomTypeRestrictions } = app_state.workspace_controls;

    const { roomtype_pricing_overrides, pricing_override_rates, property_id, property_name } = app_state;
    const [rows, setRows] = useState([]);
    const [numUpdates, setNumUpdates] = useState(0);
    const [roomTypePricingData, setRoomTypePricingData] = useState([]);

    const [ratePushSucessOpen, setRatePushSuccessOpen] = useState(false);
    const [ratePushFail, setRatePushFail] = useState(false);
    const compRef = useRef({ pushedCount: 0, rows: [] });
    const [compState, setCompState] = useState({
        count: app_state.savedRestrictions.length,
        rows: deepCopy(app_state.savedRestrictions),
        pushSuccess: false,
        pushError: false,
        progress: {
            all: 'idle',
        },
        pushStatus: {},
        pushedCount: 0,
        successTimeout: 4000
    });
    // debugger;
    useEffect(() => {
        let isSubscribed = true;
        // debugger
        if (compRef.current.pushedCount === compState.pushedCount) {
            app_state.savedRestrictions.sort((a, b) => {
                return moment(a.stay_date).format('x') - moment(b.stay_date).format('x');
            });
            compRef.current.rows = deepCopy(app_state.savedRestrictions);
            compState.rows = deepCopy(app_state.savedRestrictions);
            setCompState(state => {
                return { ...state, ...compState }
            });
        } else if (compRef.current.pushedCount < compState.pushedCount) {
            //compRef.current.pushedCount = compState.pushedCount
        }

        return () => (isSubscribed = false);
    }, [app_state.savedRestrictions, compState.pushedCount])

    //debugger;
    const handleClose = (e) => {
        // debugger; 
        compState.progress = { all: 'idle' };
        compState.pushStatus = { all: 'idle' };
        compRef.current.rows.map((item, index) => {
            if (item.pushed) {
                compState.rows.splice(index, 1);
                compRef.current.rows.splice(index, 1);
                compState.pushedCount = 0;
            }
        });
        setCompState(state => {
            return { ...state, ...compState }
        });
        handleModalClose();
    };
    const handleDeleteRule = (index, rule) => {
        return (e) => {
            // let prp = props;
            // debugger;
            delete compState.rows[index].delta[rule];
            delete compRef.current.rows[index].delta[rule];
            delete app_state.savedRestrictions[index].delta[rule];
            // let entry = deepCopy(compState.rows[index].entry);
            let cur = deepCopy(compState.rows[index].cur);
            compState.rows[index].entry[rule] = cur[rule];
            compRef.current.rows[index].entry[rule] = cur[rule];
            app_state.savedRestrictions[index].entry[rule] = cur[rule];
            // compState.rows[index].entry[rule] = compState.rows[index].cur[rule];
            //compRef.current.rows[index].entry[rule] = compState.rows[index].cur[rule];
            // app_state.savedRestrictions[index].entry[rule] = compState.rows[index].cur[rule];

            let hasDelta = Object.keys(compState.rows[index].delta);
            if (hasDelta && hasDelta.length === 1) {
                // debugger;  
                compState.rows.splice(index, 1);
                compRef.current.rows.splice(index, 1);
                app_state.savedRestrictions.splice(index, 1);

            } else {
                // app_state.savedRestrictions = compState.rows;
                // dispatch(updateAppState("removeRestrictionRule", {
                //     app_state: {
                //         ...app_state,
                //         // savedRestrictions: compState.rows
                //     }
                // }));
            }
            setCompState(state => {
                return { ...state, ...compState }
            });
            dispatch(updateAppState("removeRestrictionRule", {
                app_state: {
                    ...app_state,
                    // savedRestrictions: compState.rows
                }
            }));

            if (!compState.rows.length) {
                handleClose();
            }
        }
    };
    const handleDelete = (index) => {
        // debugger;
        compState.rows.splice(index, 1);
        setCompState(state => {
            return { ...state, rows: compState.rows }
        });
        // let savedRestrictions = compState.restrictions
        dispatch(updateAppState("removeRestrictionRow", {
            app_state: {
                ...app_state,
                savedRestrictions: compState.rows
            }
        }))
    }

    const pushRestrictions = ({ restrictions, all }) => {
        let registery = API_REGISTRY['SendRestrictionsPush'];
        dataService.getData({
            api: registery.uri + app_state.property_id + "/",
            request: { restrictions: restrictions }
        })
            .then((rs) => {
                // debugger;
                if (rs.status === 'success') {
                    if (app_state.savedRestrictions.length === restrictions.length) {
                        compState.pushStatus.all = 'loaded';
                        compState.progress.all = 'loaded';
                        setTimeout(() => {
                            compState.pushStatus.all = 'idle';
                            compState.progress.all = 'idle';
                            handleClose();
                        }, compState.successTimeout);
                        app_state.savedRestrictions.splice(0);
                    }
                    restrictions.map((rest) => {
                        compState.pushStatus[rest.stay_date] = 'success';
                        compState.progress[rest.stay_date] = 'loaded';
                        compState.rows.map((itm, index) => {
                            let item = deepCopy(itm);
                            if (item.stay_date === rest.stay_date) {
                                // debugger;
                                if(item.entry.min_los == 0){
                                    item.entry.min_los = 1;
                                    item.cur.min_los = 1;
                                } 
                                if(item.entry.max_los == 0){
                                    item.entry.max_los = 1;
                                    item.cur.max_los = 1;
                                }

                                // if(item.min_los == "") delete item.min_los;
                                // if(item.max_los == "") delete  item.max_los;

                                let updatedIndex;
                                app_state.updatedRestrictions.filter((updated, i) => {
                                    if (updated.stay_date === rest.stay_date) {
                                        updatedIndex = i;
                                    }
                                });
                                if (updatedIndex > -1) {
                                    app_state.updatedRestrictions[updatedIndex] = deepCopy(item);
                                } else {
                                    app_state.updatedRestrictions.push(deepCopy(item));
                                }


                                if (compState.rows[index].entry.min_los == 0) {
                                    compState.rows[index].entry.min_los = 1;
                                }
                                if (compState.rows[index].entry.max_los == 0) {
                                    compState.rows[index].entry.max_los = 1;
                                }
                                compState.rows[index].cur = deepCopy(item.entry);
                                compState.rows[index].pushed = true;
                                compRef.current.rows[index].pushed = true;

                                app_state.savedRestrictions.splice(index, 1);
                                compState.pushedCount += 1;
                            }
                        });
                    });


                    // debugger
                    setCompState(state => {
                        return { ...state, ...compState }
                    });
                    dispatch(
                        updateAppState("removeRestrictionRow", {
                            app_state: {
                                ...app_state,
                                data_load: true,
                                //savedRestrictions: compState.rows
                            },
                            ui_state: {
                                ...ui_state,
                                showSpinner: true,
                            }
                        })
                    )
                    console.log("Send restrictions success", rs);
                } else {
                    pushErrorHandler(restrictions)
                }
            }, pushErrorHandler(restrictions))
            .then(pushErrorHandler(restrictions));
    }

    const pushErrorHandler = (restrictions) => {
        return (err) => {
            if (err) {
                restrictions.map((rest) => {
                    compState.pushStatus[rest.stay_date] = 'error';
                    compState.progress[rest.stay_date] = 'error';
                });
                setCompState(state => {
                    return { ...state, ...compState }
                });
            }
            // console.error("Send restrictions error");
        };
    }

    const handleRestrictionPush = (row) => {
        //Handling
        let restrictions = [];
        let rows = [];
        if (!row) {
            rows = compState.rows;
            compState.progress.all = 'loading';
        } else if (row && row.stay_date) {
            rows.push(row);
        }

        rows.map(row => {
            let restriction = deepCopy(row.delta);
            restriction.stay_date = row.stay_date;
            if (row.entry.min_los === 0) {
                restriction.min_los = 1;
            }

            if (row.entry.max_los === 0) {
                restriction.max_los = 1;
            }

            if (row.entry.min_los > 1) {
                restriction.min_los = row.entry.min_los;
            }
            
            if (row.entry.max_los > 1 && row.entry.max_los !== row.cur.max_los) {
                restriction.max_los = row.entry.max_los;
            }

            if (row.entry.min_los > 1 && row.entry.min_los !== row.cur.min_los) {
                restriction.min_los = row.entry.min_los;
            }

            if(restriction.min_los === true) delete restriction.min_los;
            if(restriction.max_los === true) delete restriction.max_los;

            restrictions.push(restriction);
            compState.progress[row.stay_date] = 'loading';
            //pushRestrictions({ restrictions, all: false });
        });

        if (!row) {
            pushRestrictions({ restrictions, all: true });

        } else if (row && row.stay_date) {
            pushRestrictions({ restrictions, all: false });
        }
        setCompState(state => {
            return { ...state, ...compState }
        });

    }
    const restrictionPushSucessDiv = (<>
        <Grid item className='success-popup'></Grid>
        <div className='rate-push-success'>
            <div className='rate-push-success-container'>
                <Grid item className='task-complete'></Grid>
                <Grid item className='success-text'>Success!</Grid>
                {/* <Grid item>Check your restrictions push status below </Grid>
                <Button variant="contained" onClick={() => {
                    // setRatePushSuccessOpen(false);
                }} className='button-green'>RESTRICTIONS PUSH STATUS</Button> */}
            </div>
        </div></>);
    const chipEl = (chipClass, )=>{
        return 
    }
    const  chipsCurDisplay = (row)=>{
        let chipsArr =[];
        if(row.cur.cta){
            chipsArr.push(<div data-mui-internal-clone-element="true" className="chip res-rule res-cta" size='small'  >CTA</div>)
        }
        if(row.cur.ctd){
            chipsArr.push(<div  data-mui-internal-clone-element="true" className="chip res-rule res-ctd" size='small'  >CTD</div>)
        }
        if(row.cur.close){
            chipsArr.push(<div data-mui-internal-clone-element="true" className="chip res-rule res-cls" size='small'>CLS</div>)
        }
        if(row.cur.min_los != 0 && row.cur.min_los > 1 ){
            chipsArr.push(<div data-mui-internal-clone-element="true" className="chip res-rule res-min_los" size='small'  >{"MN " + row.cur.min_los}</div>)
        }
        if(row.cur.max_los != 0 && row.cur.max_los > 1 ){
            chipsArr.push(<div data-mui-internal-clone-element="true" className="chip res-rule res-max_los" size='small'  >{"MX " + row.cur.max_los} </div>)
        }

        return chipsArr;                    
    }
    const chipsDisplay = (row,index)=>{
        let chipClass = "";
        let ruleType = "";
        let label = "";
        let chipArr=[];
        if(row.delta.cta === false && row.cur.cta ){
            chipClass = "chip removed";
            ruleType="cta";
            label ="CTA";
            chipArr.push(<div className={chipClass} size="small" onDelete={handleDeleteRule(index, ruleType)}>{label}</div> )

        }
        if(row.delta.cta && !row.cur.cta){
          chipClass ="chip res-rule res-cta"
          label="CTA"
          ruleType="cta"
          chipArr.push(<div className={chipClass} size="small" onDelete={handleDeleteRule(index, ruleType)}>{label}</div> )

        }
        if(row.delta.ctd === false && row.cur.ctd ){
            chipClass ="chip removed"
            label="CTD"
            ruleType="ctd"
            chipArr.push(<div  data-mui-internal-clone-element="true" className={chipClass} size="small" onDelete={handleDeleteRule(index, ruleType)}>{label}</div> )

          }
          if(row.delta.ctd && !row.cur.ctd){
            chipClass ="chip res-rule res-ctd"
            label="CTD"
            ruleType="ctd"
            chipArr.push(<div  data-mui-internal-clone-element="true" className={chipClass} size="small" onDelete={handleDeleteRule(index, ruleType)}>{label}</div> )

          }
          if(row.delta.close === false && row.cur.close){
            chipClass ="chip removed"
            label="CLS"
            ruleType="close"
            chipArr.push(<div data-mui-internal-clone-element="true" className={chipClass} size="small" onDelete={handleDeleteRule(index, ruleType)}>{label}</div> )

          }
          if(row.delta.close && !row.cur.close){
            chipClass ="chip res-rule res-cls"
            label="CLS"
            ruleType="close"
            chipArr.push(<div data-mui-internal-clone-element="true" className={chipClass} size="small" onDelete={handleDeleteRule(index, ruleType)}>{label}</div> )

          }
          if(row.entry.min_los === 0){
            chipClass ="chip removed"
            label="MN "
            ruleType="min_los"
            chipArr.push(<div data-mui-internal-clone-element="true" className={chipClass} size="small" onDelete={handleDeleteRule(index, ruleType)}>{label}</div> )

          }
          if(row.delta.min_los && row.entry.min_los > 1 ){
            chipClass ="chip res-rule res-min_los"
            label="MN "+ row.entry.min_los
            ruleType="min_los"
            chipArr.push(<div data-mui-internal-clone-element="true" className={chipClass} size="small" onDelete={handleDeleteRule(index, ruleType)}>{label}</div> )

          }
          if(row.entry.max_los === 0){
            chipClass ="chip removed"
            label="MX "
            ruleType="max_los"
            chipArr.push(<div data-mui-internal-clone-element="true" className={chipClass} size="small" onDelete={handleDeleteRule(index, ruleType)}>{label}</div> )

          }
          if(row.delta.max_los && row.entry.max_los  ){
            chipClass ="chip res-rule res-max_los"
            label="MX "+ row.entry.max_los
            ruleType="max_los"
            chipArr.push(<div data-mui-internal-clone-element="true"className={chipClass} size="small" onDelete={handleDeleteRule(index, ruleType)}>{label}</div> )

          }
        return  chipArr;
    }
    const houseRestrictionTable = (<TableContainer className='rate-push-table' sx={{ maxHeight: "91%" }}>
        <Table stickyHeader aria-label="simple table">
            <TableHead>
                <TableRow sx={{ backgroundColor: '#606060', '& td, & th': { pt: 1, pb: 1 } }}>
                    <TableCell align="center" sx={{ width: 175 }}>DATE</TableCell>
                    <TableCell align="center" sx={{ width: 255 }}>CURRENT RESTRICTIONS</TableCell>
                    <TableCell align="center" sx={{ width: 350 }}>UPDATED RESTRICTIONS</TableCell>
                    {/* <TableCell align="center" sx={{ color: '#fff', width: 150 }}>RATE OVERRIDE</TableCell> */}
                    <TableCell align="right" className='status-column' sx={{ width: 150 }}>STATUS</TableCell>
                </TableRow>
            </TableHead>
            <TableBody className='ratepush-tbody' sx={{ overflowX: "hidden", overflowY: "auto" }}>

                {compRef.current.rows.length > 0 ?
                    compRef.current.rows.map((row, index) => (
                        <TableRow
                            key={index}
                            className={row.pushed ? 'pushed' : ''}
                            sx={{ '&:last-child td, &:last-child th': { border: 0 }, '& td, & th': { pt: 1, pb: 1, fontSize: 14 } }}
                        >
                            <TableCell align='left' component="th" scope="row">
                                {moment(row.stay_date).format("ddd, MMM DD, YYYY")}
                            </TableCell>
                            <TableCell   data-t="s"  
                                data-b-r-s="thin"
                                data-b-a-c="ffffff"
                                data-fill-color="ebebeb"
                                data-f-sz="12"
                                // data-exclude="true" 
                                data-a-h="center" 
                                 component="th" scope="row" align='center' sx={{ color: "#000" }}>
                                <Stack className='res-rules-display restrictions-' justifyContent="center" direction="row" spacing={1}>
                                    {row.cur.cta && <Chip className="chip res-rule res-cta" size='small' label="CTA" />}
                                    {row.cur.ctd && <Chip className="chip res-rule res-ctd" size='small' label="CTD" />}
                                    {row.cur.close && <Chip className="chip res-rule res-cls" size='small' label="CLS" />}
                                    {row.cur.min_los != 0 && row.cur.min_los > 1 && <Chip className="chip res-rule res-min_los" size='small' label={"MN " + row.cur.min_los} />}
                                    {row.cur.max_los != 0 && row.cur.max_los > 1 && <Chip className="chip res-rule res-max_los" size='small' label={"MX " + row.cur.max_los} />}
                                   {/* {chipsCurDisplay(row)} */}
                                </Stack>
                                {/* <Stack className='res-rules-display' direction="row" spacing={0.5}>
                                    {row.cur.cta && <Chip className='chip res-rule' label="CTA" size="small" />}
                                    {row.cur.ctd && <Chip className='chip res-rule' label="CTD" size="small" />}
                                    {row.cur.close && <Chip className='chip res-rule' label="CLS" size="small" />}
                                    {row.cur.min_los && <Chip className='chip res-rule' label={"MN " + row.cur.min_los} size="small" />}
                                    {row.cur.max_los && <Chip className='chip res-rule' label={"MX " + row.cur.max_los} size="small" />}
                                </Stack> */}
                            </TableCell>
                            <TableCell component="th"   
                                data-t="s"  
                                data-fill-color="ebebeb"
                                data-b-a-c="ffffff"
                                data-f-sz="12"
                                // data-exclude="true" 
                                data-b-r-s="thin"
                                data-a-h="center" 
                                scope="row" align='center' sx={{ fontWeight: "bold" }}>
                                <Stack className='res-rules-display restrictions-delta' justifyContent="center" direction="row" spacing={1}>
                                    {row.delta.cta === false && row.cur.cta && <Chip className="chip removed" label="CTA" size="small" onDelete={handleDeleteRule(index, 'cta')} />}
                                    {row.delta.cta && !row.cur.cta && <Chip className="chip res-rule res-cta" size='small' label="CTA" onDelete={handleDeleteRule(index, 'cta')} />}

                                    {row.delta.ctd === false && row.cur.ctd && <Chip className="chip removed" label="CTD" size="small" onDelete={handleDeleteRule(index, 'ctd')} />}
                                    {row.delta.ctd && !row.cur.ctd && <Chip className="chip res-rule res-ctd" label="CTD" size="small" onDelete={handleDeleteRule(index, 'ctd')} />}

                                    {row.delta.close === false && row.cur.close && <Chip className="chip removed" label="CLS" size="small" onDelete={handleDeleteRule(index, 'close')} />}
                                    {row.delta.close && !row.cur.close && <Chip className="chip res-rule res-cls" label="CLS" size="small" onDelete={handleDeleteRule(index, 'close')} />}

                                    {row.entry.min_los === 0 && row.cur.min_los > 1 && <Chip className="chip removed" label={"MN "+ row.cur.min_los} size="small" onDelete={handleDeleteRule(index, 'min_los')} />}
                                    {row.delta.min_los && row.entry.min_los > 1 && <Chip className="chip res-rule res-min_los" label={"MN " + row.entry.min_los} size="small" onDelete={handleDeleteRule(index, 'min_los')} />}

                                    {row.entry.max_los === 0 && row.cur.max_los > 1 && <Chip className="chip removed" label={"MX "+ row.cur.max_los} size="small" onDelete={handleDeleteRule(index, 'max_los')} />}
                                    {row.delta.max_los && row.entry.max_los > 1 && <Chip className="chip res-rule res-max_los" label={"MX " + row.entry.max_los} size="small" onDelete={handleDeleteRule(index, 'max_los')} />}
                                
                                 {/* {chipsDisplay(row)} */}
                                </Stack>
                                {/* <div class="res-rules-display">
                                    {!row.delta.cta && row.cur.cta && <span class="res-rule res-cta removed">CTA</span>}
                                    {row.delta.cta && <span class="res-rule res-cta">CTA</span>}

                                    {!row.delta.ctd && row.cur.ctd && <span class="res-rule res-ctd removed">CTD</span>}
                                    {row.delta.ctd && <span class="res-rule res-ctd">CTD</span>}

                                    {!row.delta.close && row.cur.close && <span class="res-rule res-cls removed">CLS</span>}
                                    {row.delta.close && <span class="res-rule res-cls">CLS</span>}

                                    {!row.delta.min_los && row.entry.min_los && <span class="res-rule res-min_los removed">MN {row.entry.min_los}</span>}
                                    {row.delta.min_los && <span class="res-rule res-min_los">MN {row.entry.min_los}</span>}

                                    {!row.delta.max_los && row.entry.max_los && <span class="res-rule res-max_los removed">MX {row.entry.max_los}</span>}
                                    {row.delta.max_los && <span class="res-rule res-max_los">MX {row.entry.max_los}</span>}
                                </div> */}
                            </TableCell>
                            {/* <TableCell component="th" scope="row">
                                                <TextField
                                                    hiddenLabel
                                                    type="number"
                                                    size="small"
                                                />

                                            </TableCell> */}
                            <TableCell component="th" scope="row">
                                <Grid container direction="row" justifyContent="flex-end" alignItems="center">
                                    {!['loaded', 'loading'].includes(compState.progress[row.stay_date]) &&
                                        <Grid>
                                            <Button onClick={() => handleRestrictionPush(row)} className='icon-btn icon-status icon-ratepush'>
                                                <span className='upload-button'></span>
                                            </Button>
                                        </Grid>}

                                    {compState.progress[row.stay_date] === 'loading' &&
                                        <Grid item><CircularProgress size={18} /></Grid>}

                                    {compState.progress[row.stay_date] === 'loaded' &&
                                        <Grid item>
                                            <Button sx={{ minWidth: 'auto' }} disabled><span className='in-progress'></span></Button>
                                        </Grid>}

                                    {compState.progress[row.stay_date] === 'failed' && <Grid item><Tooltip title="Some Error happened" arrow><Button className='icon-btn icon-status icon-ratepush'><span className='error'></span></Button></Tooltip></Grid>}


                                    {!['loading'].includes(compState.progress[row.stay_date]) &&
                                        <Grid item>
                                            <Button onClick={() => {
                                                handleDelete(index)
                                            }} className='icon-btn icon-status icon-cancel'>
                                                <span className='push-delete'></span>
                                            </Button>
                                        </Grid>}

                                    {['loading'].includes(compState.progress[row.stay_date]) &&
                                        <Grid item>
                                            <Button disabled onClick={() => {
                                                handleDelete(index)
                                            }} className='icon-btn icon-status icon-cancel'>
                                                <span className='push-delete'></span>
                                            </Button>
                                        </Grid>}


                                    {/* <Grid item>

                                        
                                        {compState.progress && compState.progress.all && <CircularProgress size={18} />}
                                        {compState.progress && compState.progress[row.stay_date] && <CircularProgress size={18} />}

                                    </Grid> */}

                                    {/* <Grid item>
                                        <Button className='icon-btn icon-status icon-warning'>
                                            <span className='error'></span>
                                        </Button>
                                    </Grid> */}

                                </Grid>



                                {/* <CloudUploadOutlinedIcon onClick={() => handleRatePush(index)} sx={{ color: "#9ed00d", cursor: "pointer" }} /> */}

                                {/* <CloseSharpIcon onClick={() => {
                                        //props.handleDelete(index, row.id)
                                    }} sx={{ ml: 2, color: "#ddd", cursor: "pointer" }} /> */}
                            </TableCell>
                        </TableRow>
                    ))
                    : (

                        <TableRow>
                            <TableCell colSpan={4} align="center">
                                <p >
                                    No Record Found!
                                </p>
                            </TableCell>
                        </TableRow>
                    )
                }
            </TableBody>
        </Table>
    </TableContainer>)

    const roomTypeRestrictionUpdates = (<div className='RTP-Pricing-Model '>
        {roomTypePricingData.map((date_data, index) => {
            let header = (<div>
                <div className='rtp-info-heading'>
                    <Grid container direction="row" alignItems="center"
                    >
                        <Grid className='rtp-heading' item xs={1}>
                            <Grid container direction="row" alignItems="center"
                            >
                                <Grid item>{moment(date_data.stay_date).format("ddd, MMM DD, YYYY")}</Grid>
                                <Grid item>
                                    <Button onClick={() => handleRestrictionPush(date_data)}
                                        className='icon-btn icon-status icon-ratepush'>
                                        <span className='upload-button'></span>
                                    </Button>
                                </Grid>
                                {/* <Grid item >

                                        <CircularProgress size={20} />

                                    </Grid> */}
                                {/* <Grid item >
                                        <Button>
                                            <span className='in-progress'></span>
                                        </Button>
                                    </Grid> */}
                                {/* <Grid item >
                                        <Button className='icon-btn icon-status icon-warning'>
                                            <span className='error'></span>
                                        </Button>
                                    </Grid> */}
                                <Grid item>
                                    <Button className='icon-btn icon-status icon-cancel'
                                        onClick={() => {
                                            delete roomtype_pricing_overrides[date_data.stay_date]
                                            roomTypePricingData.splice(index, 1)
                                            setRoomTypePricingData(roomTypePricingData)
                                            dispatch(updateAppState("change_pricing_rates", {
                                                app_state: {
                                                    roomtype_pricing_overrides: { ...roomtype_pricing_overrides }
                                                }
                                            }))
                                        }}>
                                        <span classNamde='push-delete'>x</span>
                                    </Button>
                                </Grid>
                            </Grid>
                        </Grid>

                    </Grid>

                </div></div>);

            let updates = [];
            date_data.date_updates.map((room_update, update_index) => {
                updates.push(<Grid item className='margin-bottom' xs={3} md={2}>

                    <Box>
                        <Stack direction="row" spacing={1}>
                            <Chip className='room-property'
                                avatar={<div className='avtar-size'>{room_update.room_code}</div>}
                                label={<div className='property-value'><span>
                                    ${room_update.previous_rate}</span>
                                    ${room_update.SellRate}
                                </div>}

                                onDelete={() => {
                                    delete roomtype_pricing_overrides[date_data.stay_date][room_update.room_code];
                                    roomTypePricingData[index].date_updates.splice(update_index, 1)
                                    setRoomTypePricingData(roomTypePricingData)
                                    dispatch(updateAppState("change_pricing_rates", {
                                        app_state: {
                                            roomtype_pricing_overrides: { ...roomtype_pricing_overrides }
                                        }
                                    }))
                                }}
                                deleteIcon={<span className='delete-button'>x</span>}
                            />
                        </Stack>
                    </Box>
                </Grid>)
            })
            return (<Box sx={{ flexGrow: 1 }} key={index}>{header}<Grid container spacing={1}>{updates}</Grid></Box>);
        })}
    </div>)
    return (<>
        <Modal
            className="popup-modal-wrap"
            open={modalOpen}
            onClose={handleClose}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
        >
            <Box className='popup-modal restrictions-push'>
                <Box className='modal-titlebar'>
                    <Grid container spacing={0} className='modal restrictions-update'>
                        <Grid item xs={7}>
                            <item><span className='text-green'>Restrictions -</span> Review and update</item>
                        </Grid>
                        <Grid className='right-cta' xs={5} item alignItems="end">
                            <Badge anchorOrigin={{ vertical: 'top', horizontal: 'right', }} component="div" badgeContent={app_state.savedRestrictions.length} color="primary" className='btn-ratepush' sx={{ mt: 0, mr: 1, position: 'relative' }}>
                                <Button disabled={!compState.rows.length} className='PushRateButton-popup' size="small" sx={{ m: "auto" }} onClick={() => handleRestrictionPush(false)}>
                                    <span>confirm all changes</span>
                                </Button>
                            </Badge>
                            <IconButton aria-label="delete" onClick={() => {
                                let name = "";
                                if (roomTypeRestrictions) {
                                    name = "Restrictions"
                                } else {
                                    name = "RoomRestrictions"
                                }
                                downloadReportToExcel("Restrictions", property_name, "restrictionsTable")
                            }}>
                                <FileDownloadOutlinedIcon className='Rate-Push-DownloadButton '

                                    fontSize='large' />
                            </IconButton>

                            <Button sx={{
                                minWidth: 'auto', position: 'absolute', top: -12, right: -12, width: '24px', height: '24px', p: '7px',
                                borderRadius: '50%', borderWidth: '1px', borderStyle: 'solid',
                                borderColor: '#ccc', boxShadow: '0 0 4px #000', background: '#000', color: '#FFF', fontSize: '10px',
                                '&:hover': { backgroundColor: '#000' }
                            }} onClick={handleClose}>
                                <CloseSharpIcon fontSize='small' />
                            </Button>



                        </Grid>
                    </Grid>
                </Box>

                <Box sx={{ height: '100%' }} id="restrictionsTable">

                    {/* rate listing white section */}

                    {!roomTypeRestrictions && houseRestrictionTable}
                    {roomTypeRestrictions && roomTypeRestrictionUpdates}
                    {/* <Stack sx={{ width: '100%' }} spacing={2}>

                        <Alert severity="warning" sx={{ color:"#C02E2E" }}>Something Went Wrong, Please Try Again</Alert>
                    </Stack> */}
                    {/* <Stack sx={{ width: '100%' }} spacing={2}>

                        <Alert severity="success" sx={{ color:"#56874C" }}>Success! </Alert>
                    </Stack> */}


                    {compState.progress.all === 'loaded' && restrictionPushSucessDiv}

                    {ratePushFail && (<Stack sx={{ width: '100%' }} spacing={2}>
                        <Alert severity="warning" sx={{ color: "#C02E2E" }}>Something Went Wrong, Please Try Again</Alert>
                    </Stack>)}
                </Box>
            </Box>
        </Modal>
    </>);
}
