import React, { useEffect, useRef, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import {
    Button, CssBaseline, TextField, FormControlLabel, Checkbox, Link, Grid, Box, Paper,
    Typography, Container, IconButton, Alert, AlertTitle, Snackbar, LinearProgress, InputAdornment
}
    from '@mui/material/';

    import VisibilityIcon from '@mui/icons-material/Visibility';
    import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';

import { ThemeProvider } from '@mui/material/styles';

import { login, resetPassword, getUserProfile, logout } from "../../actions/auth";
import { clearMessage, setMessage } from "../../actions/message";
import { buildSession, updateAppState } from "../../actions/appstate";
import { checkActivityIntervalTrigger, getCookie, getDataSources } from "../../app/util";
//Global custom theme configuration
import theme from '../../theme';
import { loginImgBaseUrl, API_REGISTRY } from "../../app/config"
//UI Elements for login screen
import { Copyright, LoginLogo, LoginProgressbar, LoginAlertBar, LoginForm } from "../../components/layout/login-ui"
import { error } from 'highcharts';
import moment from 'moment';
import chameleon from "@chamaeleonidae/chmln"

const Login = ({ }) => {
    const { UserProfile } = API_REGISTRY;
    const [email, setEmail] = useState("");
    const [password, setPassword] = useState("");
    const [isrequesting, setIsRequesting] = useState(false);
    const [resError, setResError] = useState(false);
    const [resErrorMsg, setResErrorMsg] = useState('');
    const [severity, setSeverity] = useState('error');
    const authState = useSelector(state => state.auth)
    const [errorType, setErrorType] = useState("");
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const { app, auth } = useSelector((state) => state);
    const { profile } = auth;
    const { app_state, ui_state } = app.current_state;
    const color = theme.palette;
    const [showPassword, setShowPassword] = useState(false);
   
    useEffect(() => {
        chameleon.init('S9tGHVPHxtdC09mFZkrHpHP4YrLnXDuKXNy6usb4GOSEyK-1QzdZB-EC3XKYs6hR2Zwdcx',
         { fastUrl: 'https://fast.chameleon.io/' });
        /* chameleon.io user identification and data */

        // IMPORTANT: Replace USER.ID_IN_DB, USER.EMAIL, USER.FULL_NAME below with your app's actual variable names.

        chameleon.identify(5, {     // REQUIRED, the unique ID of each user in your database (e.g. 23443 or "690b80e5f433ea81b96c9bf6")
        email: "shifat@lodgiq.com",                // RECOMMENDED, email is used as the key to map user data for integrations
        name: "shifat salim",             // RECOMMENDED, name can be used to greet and/or personalize content
        })
    }, [])
    useEffect(() => {
        let isSubscribed = true;
        // sessionStorage.clear();
        // localStorage.clear();
        if (isSubscribed && authState.profileSuccess === true) {
            // debugger;
            dispatch(buildSession(authState));
            navigate("/");
        }
        return () => {
            return isSubscribed = false
        }

    }, [authState.profileSuccess])
    const handleSubmit = (event) => {
        event.preventDefault();
        dispatch(clearMessage());
        setResError(false);
        setIsRequesting(true);
        const payload = {
            email: email,
            password: password,
            user_login_type: "normal",
            ts: moment().format()
        };
        //let xhr = new XMLHttpRequest();
        dispatch(login(payload))
            .then((data) => {
                //console.log(xhr.getResponseHeader('Set-Cookie'));
                //console.log(getCookie("sessionid"))
                const { token_string: token, user_id } = data;

                window.checkInactivityId = checkActivityIntervalTrigger(() => {
                    navigate("/login");
                    dispatch(logout());
                    clearTimeout(window.checkInactivityId);
                })
                dispatch(getUserProfile({ api: UserProfile.uri, request: { ...UserProfile.payload, token_string: token, user_id: user_id } }))
                    .then((rs) => {
                        
                        if (rs && rs.status === 'success') {                                                    
                           
                            setIsRequesting(false);
                            // navigate("/");
                            setErrorType("")

                        }
                    }).catch((err) => {
                        console.log(err);
                        if (err && err.original) {
                            setIsRequesting(false);
                            setSeverity('error');
                            setResError(true);
                            setResErrorMsg(err.original.statusText);
                        } else {
                            let errorTxt = toString(err);
                            setIsRequesting(false);
                            setSeverity('error');
                            setResError(true);
                            setResErrorMsg(errorTxt);
                        }
                    });
            }).catch((msg) => {
                setSeverity('error');
                setIsRequesting(false);
                setResError(true);
                setResErrorMsg(msg);
                if (msg) {
                    if (msg.toLowerCase().includes('invalid email')) {
                        //#endregion
                        setErrorType("email")
                    }
                    else if (msg === "Wrong password") {
                        setErrorType("password")
                    }
                }
            });

    };
    const handleClose = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }
        setResError(false);
    };
    const [isForgetPassword, setIsForgetPassword] = React.useState(false);
    const gotToForgotPassword = () => {
        setIsForgetPassword(true);
    };
    const gotToLogin = () => {
        setIsForgetPassword(false);
    };
    const [resetPassEmail, setResetPassEmail] = useState('');
    const requestPassword = (event) => {
        event.preventDefault();
        setIsRequesting(true);
        dispatch(clearMessage());
        setResError(false);
        setIsRequesting(true);
        const payload = {
            email: resetPassEmail,
        };
        dispatch(resetPassword(payload))
            .then((rs) => {
                setSeverity('success');
                setResError(true);
                setResErrorMsg("Please check your inbox to set new password, Thanks!");
                setIsRequesting(false);
                setErrorType("")
                setIsForgetPassword(false);
            }).catch((msg) => {
                setSeverity('error');
                setIsRequesting(false);
                setResError(true);
                setResErrorMsg(msg);
            });
    };

    return (

        <ThemeProvider theme={theme}>
            <Grid container component="main" sx={{ height: '100vh' }}>
                <CssBaseline />
               
                <Grid className='loginMainContainer' item xs={12} sm={8} md={5} component={Paper} elevation={6} square sx={{ position: 'relative' }}>
                    
                    <Box sx={{ width: '100%', position: 'absolute', zIndex: 999 }}>
                        {isrequesting && <LinearProgress sx={{ position: 'absolute', width: '100%' }} color='green' />}
                        <LoginAlertBar className='loginAlertBar' resError={resError} handleClose={handleClose} resErrorMsg={resErrorMsg} severity={severity}>
                            <AlertTitle>{resErrorMsg}</AlertTitle>
                        </LoginAlertBar> 
                    </Box>
                    
                    {/* Logo part */}
                    <Box className='loginHeader'>
                        <LoginLogo />
                    </Box>


                    {/* Body part */}
                    <Box className='loginBody'>
                       
                        <Box component="form" noValidate onSubmit={handleSubmit}>
                            <Container className='loginBodyMain' component="main" maxWidth="xs">
                               
                               <Box className='letsGetStarted'>
                                    <svg xmlns="http://www.w3.org/2000/svg" width="229.998" height="29" viewBox="0 0 229.998 29">
                                        <g id="Group_2892" data-name="Group 2892" transform="translate(23813 18434)">
                                            <path id="Path_686" data-name="Path 686" d="M761.974,107.093l3.331,3.331,12.909-12.909v10.594h4.711V89.266H764.082v4.711H775.09Z" transform="translate(-24366.426 -18518.857)" fill="#B0E80E" stroke="rgba(0,0,0,0)" stroke-width="1"/>
                                            <text id="Let_s_get_started" data-name="Let&apos;s get started" transform="translate(-23813 -18411)" fill="#333" font-size="24" font-weight="600"><tspan x="0" y="0">Let&apos;s get started</tspan></text>
                                        </g>
                                    </svg>
                               </Box>
                               
                                <Box className='inputsContainer'>

                                    {!isForgetPassword &&
                                        <Box noValidate >
                                            <lebel class="inputLebels">Email Address</lebel>
                                            <TextField className='inputBox'
                                                sx={{'& .MuiOutlinedInput-root': {
                                                    paddingRight: 0, 
                                                    backgroundColor: color.grey.Gray4,
                                                    borderRadius: '8px'},
                                                }}
                                                placeholder='john@domain.com'
                                                size='small'
                                                error={errorType === "email"}
                                                helperText={errorType === "email" ? "Invalid email" : ""}
                                                margin="normal"
                                                required
                                                fullWidth
                                                id="email"
                                                // label="Email Address"
                                                name="email"
                                                autoComplete=""
                                                value={email}
                                                onChange={(e) => {
                                                    const email = e.target.value;
                                                    setEmail(email);
                                                    setErrorType("")
                                                }}
                                                autoFocus
                                            />
                                            
                                            <lebel class="inputLebels">Password</lebel>
                                            <Button className='forgotPasswordLink' size="small" variant="text" sx={[{
                                                float: 'right',
                                                padding: 0,
                                                '&:hover': {
                                                    color: 'green',
                                                    backgroundColor: 'white',
                                                },
                                                textTransform: 'none'
                                                }]} onClick={gotToForgotPassword}>Forgot Password?
                                            </Button>                                                                      
                                            <TextField
                                                sx={{'& .MuiOutlinedInput-root': {
                                                    paddingRight: 0, 
                                                    backgroundColor: color.grey.Gray4,
                                                    borderRadius: '8px'},
                                                }}
                                                className='inputBox'
                                                placeholder='********'
                                                size='small'
                                                margin="normal"
                                                error={errorType === "password"}
                                                helperText={errorType === "password" ? "Invalid password" : ""}
                                                required
                                                fullWidth
                                                name="password"
                                                type={showPassword ? 'text' : 'password'}
                                                id="password"
                                                autoComplete=""
                                                value={password}
                                                onChange={(e) => {
                                                    const password = e.target.value;
                                                    setPassword(password);
                                                    setErrorType("");
                                                }}
                                                InputProps={{
                                                    endAdornment: (
                                                        <InputAdornment position="end" >
                                                            <IconButton
                                                            aria-label="toggle password visibility"
                                                            onClick={() => setShowPassword(!showPassword)}
                                                            onMouseDown={(e) => e.preventDefault()} 
                                                            style={{ backgroundColor: '#ebebeb' }}
                                                        >
                                                            {showPassword ? <i class="ph ph-eye"></i> : <i class="ph ph-eye-closed"></i>}
                                                            </IconButton>
                                                        </InputAdornment>
                                                    ),
                                                }}
                                            />

                                            <Button className='signInBtn'
                                                type="submit"
                                                fullWidth
                                                variant="contained"
                                                onClick={handleSubmit}
                                            >
                                                Sign In
                                            </Button>
                                            
                                        </Box>
                                    }
                                    {isForgetPassword &&
                                        <Box noValidate >
                                            <lebel class="inputLebels">Email Address</lebel>
                                            <TextField className='inputBox'
                                                placeholder='john@domain.com'
                                                size='small'
                                                error={errorType === "email"}
                                                helperText={errorType === "email" ? "Invalid email" : ""}
                                                margin="normal"
                                                required
                                                fullWidth
                                                id="email"
                                                // label="Email Address"
                                                name="email"
                                                autoComplete=""
                                                value={resetPassEmail}
                                                onChange={(e) => {
                                                    const resetPassEmail = e.target.value;
                                                    setResetPassEmail(resetPassEmail);
                                                    setErrorType("")
                                                }}
                                                autoFocus
                                            />

                                            <Button 
                                                className='requestPasswordBtn'
                                                type="button"
                                                fullWidth
                                                variant="contained"
                                                sx={{ mt: 3, mb: 2 }}
                                                onClick={requestPassword}
                                            >
                                                Request Password
                                            </Button>                                            
                                           
                                            <Box sx={{textAlign: 'center'}}>
                                                <Button className='forgotPasswordLink' size="small" variant="text" sx={[{
                                                    '&:hover': {
                                                            color: 'green',
                                                            backgroundColor: 'white',
                                                        },
                                                        textTransform: 'none'
                                                    }]} onClick={gotToLogin}>Sign In
                                                </Button>
                                            </Box>    
                                                                                               
                                        </Box>
                                    }

                                </Box>
                               
                            </Container>
                        </Box>
                    </Box>


                    {/* Copyright part */}
                    <Box className='loginFooter' sx={{ width: '100%', position: 'absolute', zIndex: 999 }}>
                        <Copyright/>
                    </Box>                               

                </Grid>
                
                <Grid className='loginImage' item xs={false} sm={4} md={7}
                    sx={{
                        backgroundImage: 'url(assets/img/login-img/seven.jpeg)',
                        backgroundRepeat: 'no-repeat',
                        backgroundColor: (t) =>
                            t.palette.mode === 'light' ? t.palette.grey[50] : t.palette.grey[900],
                        backgroundSize: 'cover',
                        backgroundPosition: 'center',
                    }}
                />

            </Grid>

        </ThemeProvider>
    );
};
export default Login;