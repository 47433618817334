import { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import moment from 'moment';
import { API_DI_CERT, API_BASE_URL } from "../app/config";
import { updateAppState } from "../actions/appstate";
import { dateDiff } from "../app/util";
import { deepCopy } from "@firebase/util";

function useDateRanges(params) {
  const dispatch = useDispatch();
  const { app } = useSelector((state) => state);
  const { app_state, ui_state } = app.current_state;
  const { date_range_type } = app_state;
  const [dateRange, setDateRange] = useState({
    primary_stay_range: app_state.primary_stay_range,
    comp_stay_range: { ...app_state.comp_stay_range },
    historicalRange: app_state.historicalRange,
  });
  // const dateRange = {
  //   primary_stay_range: app_state.primary_stay_range,
  //   comp_stay_range: {...app_state.comp_stay_range, comp_as_of_date: app_state.comp_stay_range.as_of_date},
  //   historicalRange: app_state.historicalRange,
  // };
  // debugger;

  useEffect(() => {
    let isSubscribed = true;
    // debugger;
    //Date picker logics
    const { scopeType } = app_state.workspace_controls;
    const { active_tiles } = ui_state;
    setDateRange({
      ...dateRange,
      primary_stay_range: { ...app_state.primary_stay_range },
      comp_stay_range: { ...app_state.comp_stay_range },
      historicalRange: { ...app_state.historicalRange }
    });
    // let daysDiff = dateDiff(app_state.primary_stay_range.date_until, app_state.primary_stay_range.date_from);
    // let fromDateLastYear = new moment(app_state.primary_stay_range.date_from).subtract(1, 'year').startOf('months').format("YYYY-MM-DD");
    // let untilDateLastYear = new moment(fromDateLastYear).add(daysDiff, 'day').format("YYYY-MM-DD");
    // let primaryAsOf = new moment(app_state.primary_stay_range.as_of_date);
    // let sdlyAsOf = new moment(app_state.primary_stay_range.as_of_date).subtract(1, 'year')
    //     .isoWeek(primaryAsOf.isoWeek())
    //     .isoWeekday(primaryAsOf.isoWeekday());
    // let cAsOfDate = sdlyAsOf.format("YYYY-MM-DD");
    // let comp_stay_range = { date_from: fromDateLastYear, date_until: untilDateLastYear, comp_as_of_date: cAsOfDate }

    if (date_range_type === "scopeTypeChanged_Dba") {
      let comp_range_type = "";
      //Do needed task on scope type changed
      // debugger;
      let comp_stay_range = { ...app_state.comp_stay_range };
      dateRange.historicalRange = deepCopy(dateRange.primary_stay_range);

      if (ui_state.active_tiles.includes('pace')) {
        let date_until = app_state.comp_stay_range.date_from;
        comp_stay_range = { ...app_state.comp_stay_range, date_until: date_until };
        dateRange.comp_stay_range = { ...comp_stay_range, date_until: date_until };
        // comp_range_type = "scopeTypeChanged_Dba";
      }
      dateRange.primary_stay_range.date_until = app_state.primary_stay_range.date_from;

      setDateRange({
        ...dateRange,
        // primary_stay_range: {...dateRange.primary_stay_range, date_until:app_state.primary_stay_range.date_from },
        // comp_stay_range: {...comp_stay_range, date_until:comp_stay_range.date_until}
      });
    }
    if (date_range_type === "scopeTypeChanged_Date") {
      //Do needed task on scope type changed
      // debugger;
      let comp_stay_range = { ...app_state.comp_stay_range };
      let comp_range_type = "";
      let primary_stay_range = app_state.historicalRange;
      if (['pace', 'market', 'compset'].includes(active_tiles[0])) {
        let daysDiff = dateDiff(app_state.historicalRange.date_until, app_state.historicalRange.date_from);
        // let fromDateLastYear = new moment(app_state.primary_stay_range.date_from).subtract(1, 'year').startOf('months').format("YYYY-MM-DD");
        let date_until = new moment(app_state.comp_stay_range.date_from).add(daysDiff, 'day').format("YYYY-MM-DD");
        // let primaryAsOf = new moment(app_state.primary_stay_range.as_of_date);
        // let sdlyAsOf = new moment(app_state.primary_stay_range.as_of_date).subtract(1, 'year')
        //   .isoWeek(primaryAsOf.isoWeek())
        //   .isoWeekday(primaryAsOf.isoWeekday());

        // let cAsOfDate = sdlyAsOf.format("YYYY-MM-DD");
        comp_stay_range.date_until = date_until;
        dateRange.comp_stay_range = deepCopy(comp_stay_range);
        comp_range_type = "scopeTypeChanged_Date";
      }
      dateRange.primary_stay_range.date_from = primary_stay_range.date_from;
      dateRange.primary_stay_range.date_until = app_state.primary_stay_range.date_until;

      setDateRange({
        ...dateRange,
        // primary_stay_range: dateRange.primary_stay_range,
        // comp_stay_range: dateRange.comp_stay_range,
      });
    }
    if (date_range_type === "rangeTileChange") {
      //Do needed task on scope type changed
      // debugger;
      let comp_stay_range = { ...app_state.comp_stay_range };
      let comp_range_type = "";
      let primary_stay_range = app_state.historicalRange && app_state.historicalRange.date_from ? deepCopy(app_state.historicalRange) : dateRange.primary_stay_range;
      
      let { date_from, date_until } = primary_stay_range;
      dateRange.primary_stay_range.date_from = date_from ? date_from : dateRange.primary_stay_range.date_from;
      dateRange.primary_stay_range.date_until = date_until ? date_until : dateRange.primary_stay_range.date_until;

      setDateRange({
        ...dateRange,
      });
    }


    if (['logsDateRange', 'logsSingleDate'].includes(app_state.date_range_type)
      && ['DailyRatesendStatus', 'rm_room_type_ratesend_status'].includes(ui_state.active_tiles[0])) {
      // debugger;
      if (app_state.date_range_type === 'logsDateRange') {
        // debugger;
        let daysDiff = dateDiff(app_state.historicalRange.date_until, app_state.historicalRange.date_from);
        let date_until = new moment(app_state.primary_stay_range.date_from).add(daysDiff, 'day').format("YYYY-MM-DD");
        dateRange.primary_stay_range.date_until = date_until;

        setDateRange({
          ...dateRange,
        });
      }
      if (app_state.date_range_type === 'logsSingleDate') {
        // debugger;
        dateRange.historicalRange = deepCopy(app_state.primary_stay_range);
        dateRange.primary_stay_range.date_until = app_state.primary_stay_range.date_from;

        setDateRange({
          ...dateRange
        });
      }
    }

    // debugger;
    return () => {
      return isSubscribed = false
    }
  }, [
    // ui_state.active_tiles.join(),
    app_state.date_range_type,
    app_state.workspace_controls.logsRatePushMode,

    app_state.primary_stay_range.as_of_date,
    app_state.primary_stay_range.date_from,
    app_state.primary_stay_range.date_until,
  ]);
  // debugger;
  return dateRange;
}
export {
  useDateRanges,
};
