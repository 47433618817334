export function settingsReducers(state, actions) {
    const type = actions.type;
   // debugger;
    switch(type){
      case "rateCountUpdate": {
        state.rateCount = actions.checkedLength;  
        state.daystoUpdate = actions.daystoUpdate;
        state.data = actions.data;
       
        // return { ...state,data:[...actions.data] };
        return { ...state };
      }  
      default: {
        throw Error("Error occour in document listing.");
      }
      
     
    }
   

}