import React, { useCallback, useState } from 'react'
import { useSelector } from 'react-redux';
import MenuSingleItem from './MenuSingleItem';

import {
    AppBar
    , Box
    , Toolbar
    , IconButton
    , Typography
    , Menu
    , MenuItem
    , Container
    , Avatar
    , Button
    , Grid
    , Tooltip
    , FormControl
    , InputLabel
    , Select
    , ListSubheader
    , TextField
    , InputAdornment
    , List
    , ListItem
    , ListItemIcon
    , ListItemText
    , Divider
    , Drawer
} from '@mui/material/';
import {
    Person as PersonIcon
    , SettingsOutlined as SettingsOutlinedIcon
    , ScheduleOutlined as ScheduleOutlinedIcon
    , VideocamOutlined as VideocamOutlinedIcon
    , PowerSettingsNewOutlined as PowerSettingsNewOutlinedIcon
    , AssessmentOutlined as AssessmentOutlinedIcon
    , ArrowForwardRounded as ArrowForwardRoundedIcon
    , ApartmentSharp as ApartmentSharpIcon
    , Search as SearchIcon
    , KeyboardDoubleArrowRight as KeyboardDoubleArrowRightIcon
} from '@mui/icons-material/';
import { deepCopy, getFromSession, getTileById, replaceMultiText, abortRequestSafe, calculateCompStayRange } from '../../app/util';
import UploadsPopUp from './UploadsPopUp';
import dataService, { fetchDataSimple } from "../../services/data.service";
import { TILES, API_BASE_URL, API_REPORT_URL } from "../../app/config";
import { useDispatch } from 'react-redux';

import { getData, updateAppState } from '../../actions/appstate';

import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { Slide, Zoom, Flip, Bounce } from 'react-toastify';
// import { useDispatch, useSelector } from 'react-redux';


import LinearProgress from '@mui/material/LinearProgress';

const ReportMenusList = (props) => {
    const dispatch = useDispatch();

    const [openPopup, setOpenPopup] = React.useState(false);
    // const [label, setLabel] = React.useState("Budget"); 
    const [reportId, setReportId] = React.useState("budget_upload");

    const { app, auth } = useSelector((state) => state);
    const { user, profile } = auth;
    const { app_state, ui_state } = app.current_state;


    const handleClose = useCallback(() => { setOpenPopup(false) })
    //  const handleClose = () => {
    //     setOpenPopup(false);
    //   };

    const reportDownload_old = (apiUrl, resType, reportName) => {
        let resource_id;
        let msg;
        if (resType !== "") {
            resource_id = (resType === "market") ? app_state.selected_market.market_id : app_state.property_id;
            msg = reportName + " report is generating , please wait..."
            dispatch(updateAppState('downloadReport', {
                ui_state: {
                    header_alert_msg: msg,
                }
            }));
        }
        const request = {
            resourceId: resource_id,
            resourceType: resType,
            asOfDate: app_state.as_of_date,
            rangeFromDate: app_state.primary_stay_range.date_from,
            rangeUntilDate: app_state.primary_stay_range.date_until,
            exportCommand: "HSHA_v0.1",
            exportType: "excel",
            debug: false,
            s3: true,
        };
        // loader will apear here 
        dataService
            .getData({
                // api: API_REPORT_URL + "export/" + apiUrl,
                api: "export/" + apiUrl,
                //api: "http://rm-app-staging.lodgiq.com/export/combined_data_export_v2",
                request: request,
            })
            .then((response) => {
                setTimeout(() => {
                    dispatch(updateAppState('onHideAlert', {
                        ui_state: {
                            header_alert_msg: "",
                        }
                    }));
                }, 3000);
                if (response.exportUrl) {
                    window.location.href = response.exportUrl;
                } else if (response.url) {
                    window.location.href = response.url;
                } else if (response.data[0].url) {
                    window.location.href = response.data[0].url
                }
            })
            .catch((error) => {
                msg = "Oops! Something went wrong.";
                dispatch(updateAppState('error', {
                    ui_state: {
                        header_alert_msg: "",
                    }
                }));
                setTimeout(() => {
                    dispatch(updateAppState('onHideAlert', {
                        ui_state: {
                            header_alert_msg: "",
                        }
                    }));
                }, 3000);
            });


    };





    const reportDownload = (report_id, resType, reportName, apiUrl) => {
        const { data_popup_api } = getTileById('export_reports');
        let activeApi = data_popup_api.OnSubmit;
        let resource_id;
        let msg;
        if (resType !== "") {
            resource_id = (resType === "market") ? app_state.selected_market.market_id : app_state.property_id;

        }

        // msg = reportName + " report is generating , please wait..."
        msg = "Generating the report, please wait..."
        const exportToast = toast(<div><LinearProgress className='toast-popup-progress' color='green' /> {msg}</div>, {
            position: "bottom-right",
            autoClose: false,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "light",
            transition: Slide
        });

        const correction = {
            "{pid}": resource_id,
            "{mid}": resource_id,
            "{asOfDate}": app_state.as_of_date
        };

        const request = {
            "asOfDate": app_state.as_of_date,
            "method": "GET",
        };
        let api = apiUrl;
        api = replaceMultiText(api, correction);
        fetchDataSimple({ api, request })
            .then((response) => {
                toast.update(exportToast, {
                    render: "Report Successfully Downloaded.",
                    type: toast.TYPE.SUCCESS,
                    autoClose: 3000
                })
                if (response.exportUrl) {
                    window.location.href = response.exportUrl;
                } else if (response.url) {
                    window.location.href = response.url;
                } else if (response.data[0].url) {
                    window.location.href = response.data[0].url
                }
            })
            .catch((error) => {
                msg = "Some error occour.Please try again.";
                toast.update(exportToast, {
                    render: msg,
                    type: toast.TYPE.ERROR,
                    autoClose: 3000
                })
                setTimeout(() => {
                    dispatch(updateAppState('onHideAlert', {
                        ui_state: {
                            header_alert_msg: "",
                        }
                    }));
                }, 3000);
            });
    }

    const handleOpenReport = (id) => {
        let currTile = TILES[id];
        let active_tiles = ui_state.active_tiles;
        let workspace_view = 'table';
        if (ui_state.active_tiles[0] !== id) {
            app_state.active_apis.map((api_id, index) => {
                // debugger;
                abortRequestSafe(api_id, 'property changed');
            });
            active_tiles = [id];
          
            if (currTile.defaultView) {
                workspace_view = currTile.defaultView;
            }
            if (id === "otb_v2_multi_segment") {
                app_state.workspace_controls.segmented_view = (app_state.workspace_controls.segmentType === "hotel") ? false : true;
            }
            if (id === "DailyRatesendStatus") {
                app_state.workspace_controls.logsRatePushMode = "date_range";
                //app_state.workspace_controls.segmented_view = (app_state.workspace_controls.segmentType === "hotel") ? false : true;
            }
            // debugger;
            if(currTile.controlSupport){
                if(!currTile.controlSupport.scopeType){
                    app_state.workspace_controls.scopeType = 'Date'; 
                    app_state.primary_stay_range = deepCopy(app_state.historicalRange);
                }                
            } else {
                app_state.workspace_controls.scopeType = 'Date'; 
                app_state.primary_stay_range = deepCopy(app_state.historicalRange);
            }
            
            
            
        }
        let appstateNew={
            date_range_type: 'rangeTileChange',
            primary_stay_range: {...deepCopy(app_state.historicalRange)},
            workspace_controls: app_state.workspace_controls,
            data_load: true
        };
        if(id === "bi_sensitivity_analysis"){
            let comp_stay_range =calculateCompStayRange({primary_stay_range:appstateNew.primary_stay_range});
            appstateNew["comp_stay_range"]=comp_stay_range;
        }
        dispatch(
            updateAppState("change_active_tiles", {
                app_state: {
                    ...app_state,
                    ...appstateNew
                },
                ui_state: {
                    ...ui_state,
                    active_tiles,
                    workspace_view,
                    showspinner: true
                },
            },"report_menu_click")
        );
    }

    const handleMenuClick = (id, label) => {
        console.log("Report Clicked => ", id, " - ", label);
        props.onMenuClicked(id, label);
        // debugger;
        let apiUrl = "";
        switch (id) {
            case "budget_upload":
            case "forecast_upload":
            case "documents_upload":
                // setLabel(label);
                setReportId(id);
                setOpenPopup(true);
                break;
            case "HMSReport":
                apiUrl = "reports/combine-export/{pid}/?asOfDate={asOfDate}";
                reportDownload(id, "property", "365 Day pace & pickup", apiUrl);
                break;
            case "bi_hotel_segmented_summary_report":
                apiUrl = "reports/hotel-segmented-summary-report/{pid}/?asOfDate={asOfDate}";
                reportDownload(id, "property", "KPI Summary Report", apiUrl);
                break;
            case "bi_market_segmented_summary_report":
                apiUrl = "reports/market-segmented-summary-report/{mid}/?asOfDate={asOfDate}";
                reportDownload(id, "market", "Segmented Market Summary", apiUrl);
                break;
            default:
                handleOpenReport(id);
                break;
        }
        // debugger;
    };

    let reportsList = auth.profile['application_config'].report_settings.report_access_settings || [];
    let reportsListNew = reportsList.filter((item) => item.report_category === props.categoryType);
    if (props.categoryType === "Property - Revenue Intelligence") {
        let marketList = reportsList.filter((item) => item.report_category === "Market");
        let labsList = reportsList.filter((item) => (item.report_category === "Labs" && item.report_id === "otb_v2_multi_segment"));
        reportsListNew = [...reportsListNew, ...marketList, ...labsList];
    }
    reportsListNew.sort((a, b) => a.display_name.toLowerCase() > b.display_name.toLowerCase() ? 1 : -1);
    return (
        <>
            {
                reportsListNew.map((item, index) => (
                    <MenuSingleItem key={"id_" + index} access={item.access_level} label={item.display_name}
                        onClick={handleMenuClick} menuId={item.report_id} />

                ))
            }
            {/* {props.categoryType} */}
            {props.categoryType == "Uploads" && openPopup &&
                <UploadsPopUp action={openPopup} onClose={handleClose} reportId={reportId} />
            }
        </>
    )
}

export default ReportMenusList
