
import { Box, Select, FormControl, MenuItem, Tooltip } from '@mui/material'
import { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { updateAppState } from '../../../../actions/appstate';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';

const SummaryPicker = () => {
    const controls = useSelector(state => state.app.current_state.app_state.workspace_controls);
    const { app_state, ui_state } = useSelector(
        (state) => state.app.current_state
    );
    const [summary, setSummary] = useState(controls.summaryType);
    const dispatch = useDispatch();
     

    const handleChange = (type, value) => {
        controls.summaryType = value;

        dispatch(updateAppState("change_active_tiles", {
            app_state: {
                date_range_type: 'rangeTileChange',
                workspace_controls: controls,
                data_load: true 
            },
            ui_state: {
                ...ui_state,
                active_tiles : [controls.summaryType] ,
                showspinner: true
            },
        }))
    }

    return (
        <>
            <Box id="pickupBox" sx={{ position: "relative", paddingLeft: 0, backgroundColor: 'blue', height: 1 / 2 }}>
                <Box sx={{ display: 'block', fontSize: '75%', fontWeight: 'bold' }}>Summary Reports</Box>
                <FormControl size="small" sx={{ minWidth: "100%", borderRadius: "16px" }}>
                    <Select className='tabular-data-filter' value={summary} sx={{ height: "27px", mt: "5px" }} onChange={(e) => {
                        setSummary(e.target.value);
                        handleChange("pickup_select", e.target.value);
                    }}>
                        <MenuItem value="hotel_summary" className='tabular-data-filter'> Hotel Summary </MenuItem>
                        <MenuItem value="market_summary" className='tabular-data-filter'> Market Summary </MenuItem>
                    </Select>

                </FormControl>
            </Box>
             
        </>
    )
}


export default SummaryPicker