import { createTheme } from '@mui/material/styles';
const defaultTheme = createTheme();
const Lato = './fonts/Lato/light/Lato-Light.woff';
const CustomTheme = createTheme({
  palette: {
    green:{
      main: '#9ed00d',
      green: '#9ed00d',
    },
    white:{
      main: '#FFF',
      main2: '#ced4da',
    },
    dark:{
      main: '#333',
      darker:'#171717',
      darkest:'#000',
      light:'#b1b1b1',
      lighter: '#606060',
      light2: '#444444'
    },
    blue:{
      main: '#8FD5EF',
      dark: '#01a4ef',
      darker: '#01a4ef',
      light: '#8FD5EF',
      Tertiary: '#90D6F0'
      
    },
    grey:{
      main: '#ADAFB2',
      light: '#EFEFEF',
      lighter: "#ebebeb",
      Grey1: '#333333',
      Gray2: '#606060',
      Gray3: '#B2B2B2',
      Gray4: '#EBEBEB'
    },
    red:{
      main: '#FF0000',
      light: '#EFEFEF',
      lighter: "#ebebeb"
    }
  },
    components: {
      typography: {
        fontFamily: 'SFUI Medium',
      },
      // Name of the component ⚛️
      MuiCheckbox: {
        defaultProps: {
            // Checked: true,
          // The default props to change
          disableRipple: true, // No more ripple, on the whole application 💣!
        },
      },
      MuiButton:{
        variants: [
        {
          props: { variant: 'dashed' },
          style: {
            textTransform: 'none',
            border: `2px dashed ${defaultTheme.palette.primary.main}`,
            color: defaultTheme.palette.primary.main,
          },
        }]
      }
    },
  });
   
  export default CustomTheme;