import React, { useState, useEffect } from 'react';
import {
    Link,
    Typography,
    LinearProgress, Alert, 
    Container, Grid, Button,
    Dialog, DialogTitle, DialogContentText, DialogContent, DialogActions
} from '@mui/material';

function ConfirmDialog(props) {
    const { title, descriptions, labelOk, 
        labelCancel, open, showLoader, 
        showSuccess, handleUserAction,  } = props;
    let newTitle = title;
 
    return (
        <Dialog
            open={open}
            // onClose={() => handleUserAction()}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
        >
            {/* {showLoader && <>
                <LinearProgress color="green" />
                <Alert severity='info'>
                    Please wait...<br />
                    We are switching data source to {pms}
                </Alert>
            </>}
            {showSuccess && <>
                <LinearProgress color="green" />              
                <Alert severity='success'>
                    Data source is switched to {pms}<br />
                    We are reloading data...
                </Alert>
            </>} */}
            {!showLoader && !showSuccess && <>
            <DialogTitle id="alert-dialog-title">
                {!title && "Are you sure about this action?"}
                {title && newTitle}
            </DialogTitle>
            <DialogContent>
                <DialogContentText id="alert-dialog-description">
                    {!descriptions && "Do this only if you prety sure about impacts of this action."}
                    {descriptions && descriptions}
                </DialogContentText>
            </DialogContent>
            <DialogActions sx={{pr:3}}>
                <Button variant='outlined' color='info' onClick={() => handleUserAction('disagree')}>
                    {labelCancel && labelCancel}
                    {!labelCancel && "Cancel"}
                </Button>
                <Button variant='outlined' color='warning' onClick={() => handleUserAction('agree')} autoFocus>
                    {labelOk && labelOk}
                    {!labelOk && "Agree"}
                </Button>
            </DialogActions>
            </>}
        </Dialog>
    )
}
export default ConfirmDialog
