import { NEW_PROPERTY_SYSTEM_SETTINGS } from "../app/config";
import {
    LOGIN_SUCCESS,
    LOGIN_FAIL,
    LOGOUT,
    SET_MESSAGE,
    PROFILE_FAILED,
    PROFILE_SUCCESS,
    //For reset password
    RESET_PASSWORD_SUCCESS,
    RESET_PASSWORD_FAIL,
} from "./types";
import { addToSession, getFromSession, getDataSources, clearAPIRegistryData } from "../app/util";
import DataService from "../services/data.service";
import AuthService from "../services/auth.service";


export const getUserProfile = (payload) => (dispatch) => {
    return DataService.getData(payload).then(
        (data) => {
            let payLoadData = data.user_profile;
            payLoadData.justLoggedIn = true;
            //   debugger;
            //Do some task here just after response
            //Handle API response change for profile
            if (data && data.status === "success") {
                let system_settings = {};
                let defaultPropIndex = 0;
                let defaultProp = payLoadData.property_details.filter((prop, i) => {
                    if(prop.default === 'Y'){
                        defaultPropIndex = i;
                        return prop.default === 'Y';
                    }
                });
                
                if (defaultProp && defaultProp.length && defaultProp[0].system_settings) {
                    system_settings = defaultProp[0].system_settings;                    
                    if (system_settings && !system_settings[0].inventory_settings) {                        
                        console.error("CRITICAL: System settings -> Inventory settings not available");
                        payLoadData.property_details[defaultPropIndex].system_settings[0] = { ...NEW_PROPERTY_SYSTEM_SETTINGS, ...system_settings };
                    }
                }                

                dispatch({ type: PROFILE_SUCCESS, payload: payLoadData });

                return Promise.resolve(data);
            }
            if (data && data.status !== "success") {
                dispatch({ type: PROFILE_FAILED, payload: data });
                return Promise.reject(data);
            }
            // else {
            //   dispatch({type: PROFILE_FAILED, payload: data});
            //   return Promise.reject(data);
            // }
        }
    )
};

export const login = (payload) => (dispatch) => {
    return AuthService.login(payload).then(
        (data) => {
            if (data.status === "success") {
                addToSession("token", data.token_string || data.token);
                dispatch({
                    type: LOGIN_SUCCESS,
                    payload: { user: data },
                });

                return Promise.resolve(data);
            } else {

                dispatch({
                    type: LOGIN_FAIL,
                });
                dispatch({
                    type: SET_MESSAGE,
                    payload: data.message,
                });
                return Promise.reject(data.message);
            }
        },
        (error) => {
            const message =
                (error.response &&
                    error.response.data &&
                    error.response.data.message) ||
                error.message ||
                error.toString();
            dispatch({
                type: LOGIN_FAIL,
            });
            dispatch({
                type: SET_MESSAGE,
                payload: message,
            });
            return Promise.reject();
        }
    );
};
export const logout = () => (dispatch) => {
    let token = getFromSession('token');
    dispatch({
        type: LOGOUT,
    });
    AuthService.logout({ token: token })
        .then((rs) => {
            clearAPIRegistryData();
            dispatch({ type: 'USER_LOGOUT' });

            setTimeout(() => {
                // debugger;
                // window.location.reload();
            }, 50)
            //window.location.reload();
        });
};

export const resetPassword = (payload) => (dispatch) => {
    return AuthService.resetPassword(payload).then(
        (data) => {
            if (data.status === "success") {
                dispatch({
                    type: RESET_PASSWORD_SUCCESS,
                    payload: { user: data },
                });
                return Promise.resolve();
            } else {
                dispatch({
                    type: RESET_PASSWORD_FAIL,
                });
                dispatch({
                    type: SET_MESSAGE,
                    payload: data.error_message,
                });
                return Promise.reject(data.error_message);
            }
        },
        (error) => {
            const message =
                (error.response &&
                    error.response.data &&
                    error.response.data.message) ||
                error.message ||
                error.toString();
            dispatch({
                type: LOGIN_FAIL,
            });
            dispatch({
                type: SET_MESSAGE,
                payload: message,
            });
            return Promise.reject();
        }
    );
};