
import { Box, TextField } from '@mui/material'
import { LocalizationProvider, MobileDatePicker } from '@mui/x-date-pickers';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import moment from 'moment';
import { useState } from 'react';


const compareToPicker = (
    
    <LocalizationProvider dateAdapter={AdapterMoment}>

    
    <Box sx={{}}>
    <MobileDatePicker
      disableCloseOnSelect={false}
      inputFormat="YYYY-MM-DD"
      value={"2022-10-10"}
      onChange={(val) => {

      }}
      renderInput={(params) => (
          <TextField
            {...params}
            size="large"
            sx={{ mt: "18px",width:1}}
            InputProps={{
                style: { height: 28 }
            }}
          />
      )}
    />
  </Box>
  </LocalizationProvider>
  

    // <Box id="compareToBox" className='control-options pickup-days' sx={{ position: "relative", width: "210px;" }}>
    //     <Box className='filterElementText'> COMPARE TO</Box>
    //     <TextField
    //         size="large"
    //         sx={{ mt: "6px",width:1}}
    //         InputProps={{
    //             style: { height: 28 }
    //         }}
    //         // onBlur={(e) => { handleChange("pickupDays", e.target.value) }}
    //         defaultValue="Select Dates"
    //         color="blue"
    //     >
    //     </TextField>
    // </Box>
)


export default compareToPicker


