import "./style.css";

import {
    ArrowDropDown as ArrowDropDownIcon,
    ArrowDropUp as ArrowDropUpIcon,
    ArrowForwardIosOutlined as ArrowForwardIosOutlinedIcon,
    Delete as DeleteIcon,
    Difference as CopyRuleIcon,
    PlaylistAdd as AddNewIcon,
    KeyboardArrowDownOutlined as KeyboardArrowDownOutlinedIcon,
    KeyboardArrowUpOutlined as KeyboardArrowUpOutlinedIcon,
    KeyboardArrowDownOutlined as KeyboardArrowDownOutlined,
    DeleteOutlineOutlinedIcon as DeleteOutlineOutlined,


} from "@mui/icons-material/";
import {

    UserConfirmationAlert,
} from "./comp.static";
import {
    Autocomplete,
    Alert,
    Badge,
    Box,
    Button,
    ButtonGroup,
    Checkbox,
    Chip,
    CircularProgress,
    Collapse,
    Divider,
    FormControl,
    FormControlLabel,
    FormLabel,
    Grid,
    Input,
    InputAdornment,
    InputLabel,
    MenuItem,
    OutlinedInput,
    Paper,
    Radio,
    RadioGroup,
    Select,
    Switch,
    TextField,
    Tooltip,
    Typography,
} from "@mui/material/";
import CalendarMonthOutlinedIcon from '@mui/icons-material/CalendarMonthOutlined';
import DeleteOutlineOutlinedIcon from '@mui/icons-material/DeleteOutlineOutlined';
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';
import { MobileDatePicker } from "@mui/x-date-pickers/";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import moment from "moment";
import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import { NEW_PROPERTY_SYSTEM_SETTINGS } from "../../app/config";
import { updateAppState } from '../../actions/appstate';
import { SYSTEM_SETTINGS_UPDATED } from "../../actions/types";
import { dateFormat, deepCopy, getDataSources, toInteger } from "../../app/util";
import { API_REGISTRY } from "../../app/config";
import dataService, { fetchDataSimple } from "../../services/data.service";

import { color } from "highcharts";
import { lineHeight } from "@mui/system";
import PropTypes from 'prop-types';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import { pt } from "date-fns/locale";

function InventorySettings(props) {
    const dispatch = useDispatch();

    const [isrequesting, setIsRequesting] = useState(false);
    // const count = useRef(0);
    // useEffect(() => {
    //     count.current = count.current + 1;
    //   },[]);
    //console.log("count.current", count.current);
    const [resError, setResError] = useState(false);
    const [isRequestSuccess, setIsRequestSuccess] = useState(false);
    const [requestSuccessMsg, setRequestSuccessMsg] = useState(
        "Your change request for inventory settings was successfull!"
    );
    const [isRequestError, setIsRequestError] = useState(false);
    const [resErrorMsg, setResErrorMsg] = useState("");

    const { app, auth } = useSelector((state) => state);
    const { user, profile } = auth;
    const { app_state, ui_state } = app.current_state;
    const { property_details } = auth.profile || [];
    //Data source information
    const [dsInfo, setDsInfo] = useState(getDataSources(property_details, app_state.property_id));
    // debugger;
    let prop_details_index = 0;
    const prop_details = property_details.filter((item, index) => {
        if (item.id === app_state.property_id) {
            prop_details_index = index;
            return item.id === app_state.property_id;
        }
        //
    });

    let { system_settings } =
        prop_details && prop_details.length ? prop_details[0] : null;

    const inventory_settings_ = JSON.parse(
        JSON.stringify(system_settings[0].inventory_settings)
    );

    const pricing_settings_ = JSON.parse(
        JSON.stringify(system_settings[0].pricing_settings)
    );

    inventory_settings_.current.external_systems[0].system_info =
        inventory_settings_.current.external_systems[0].system_info || " ";
    const inventory_settings = { ...inventory_settings_ };
    //const {status, system_name} = inventory_settings;
    const [requestData, setRequestData] = useState(inventory_settings);
    // const [requestPriceSettings, setRequestPriceSettings] = useState(pricing_settings_);
    const compRef = useRef({ requestPriceSettings: pricing_settings_ });

    const [isChangeRequest, setIsChangeRequest] = useState(false);

    const cancelSave = () => {
        setRequestData(inventory_settings_);
        system_settings[0].pricing_settings = pricing_settings_;
        setIsChangeRequest(false);
    };
    const updateRequestData = (keys, val, index) => {
        // debugger;
        const updatedObj = { ...requestData };
        const key = keys.split(">");
        switch (key[0]) {
            case "status":
                updatedObj.status = val;
                //setRequestData({...requestData, status: val});
                break;

            case "system_name":
            case "system_info":
            case "system_code":
            case "integration_code":

                updatedObj.current.external_systems[0][key] = val;

                break;

            case "total_capacity":
                updatedObj.current.total_capacity = val;
                break;
            case "room_settings":
                // debugger;
                if (key[1] === 'is_base') {
                    let old = updatedObj.current.room_settings.filter((itm, i) => {
                        if (itm.is_base) {
                            updatedObj.current.room_settings[i].is_base = false;
                            return true;
                        }
                    });

                    updatedObj.current.room_settings[index][key[1]] = true;
                } else {
                    // debugger;
                    updatedObj.current.room_settings[index][key[1]] = val;
                    if (key[1] === 'room_code') {
                        updatedObj.current.room_settings[index]["room_codes"]['pms'] = [val];
                    }
                    if (key[1] === "display_name") {
                        let old_room_lodgiq_code = inventory_settings_.current.room_settings[index] ? inventory_settings_.current.room_settings[index].room_lodgiq_code : '';
                        let { is_base } = inventory_settings_.current.room_settings[index] ? inventory_settings_.current.room_settings[index] : { is_base: false };
                        let new_room_lodgiq_code = val.replace(/[^a-zA-Z0-9|]/gi, '');

                        let originalPriceSettings = deepCopy(pricing_settings_);

                        // let rps = compRef.current.requestPriceSettings;
                        // debugger;

                        // let room_lodgiq_code = val.split(' ').join('');
                        let exists = updatedObj.current.room_settings.filter(item => item.room_lodgiq_code === new_room_lodgiq_code);
                        new_room_lodgiq_code = exists.length ? new_room_lodgiq_code + exists.length : new_room_lodgiq_code;
                        updatedObj.current.room_settings[index]["room_lodgiq_code"] = new_room_lodgiq_code;

                        originalPriceSettings.room_dow_plans.map((item, i) => {
                            let plan = old_room_lodgiq_code ? item.room_plans[old_room_lodgiq_code] : null;
                            if (plan) {
                                if (is_base) {
                                    originalPriceSettings.room_dow_plans[i].base_room = new_room_lodgiq_code;
                                    // originalPriceSettings.room_dow_plans[i].base_rate_code = ?
                                }
                                originalPriceSettings.room_dow_plans[i].room_plans[new_room_lodgiq_code] = plan;
                                delete item.room_plans[old_room_lodgiq_code];
                                compRef.current.requestPriceSettings = originalPriceSettings;
                            } else {
                                if (is_base) {
                                    originalPriceSettings.room_dow_plans[i].base_room = new_room_lodgiq_code;
                                    // originalPriceSettings.room_dow_plans[i].base_rate_code = ?
                                }
                                originalPriceSettings.room_dow_plans[i].room_plans[new_room_lodgiq_code] = {
                                    "Sun": 0,
                                    "Mon": 0,
                                    "Tue": 0,
                                    "Wed": 0,
                                    "Thu": 0,
                                    "Fri": 0,
                                    "Sat": 0
                                };
                                compRef.current.requestPriceSettings = originalPriceSettings;
                            }
                        });
                    }
                }

                break;


            default:
        }
        setRequestData({ ...requestData, ...updatedObj });
        setIsChangeRequest(true);
    };
    const deleteRoomType = (index) => {
        //debugger;
        let { room_lodgiq_code } = deepCopy(requestData.current.room_settings[index]);
        compRef.current.requestPriceSettings.room_dow_plans.map((plan, i) => {
            delete compRef.current.requestPriceSettings.room_dow_plans[i].room_plans[room_lodgiq_code];
        });
        // compRef.current.requestPriceSettings = originalPriceSettings;
        requestData.current.room_settings.splice(index, 1);
        setRequestData({ ...requestData, current: requestData.current });
        setIsChangeRequest(true);
    };
    const changeRoomTypeOrder = (item, index, type) => {
        //debugger;
        const tempObj = item;
        const size = requestData.current.room_settings.length;
        if (type == "up" && index) {
            requestData.current.room_settings[index] =
                requestData.current.room_settings[index - 1];
            requestData.current.room_settings[index - 1] = item;
            setRequestData({ ...requestData, current: requestData.current });
            setIsChangeRequest(true);
        }
        if (type == "down" && index < size) {
            requestData.current.room_settings[index] =
                requestData.current.room_settings[index + 1];
            requestData.current.room_settings[index + 1] = item;
            setRequestData({ ...requestData, current: requestData.current });
            setIsChangeRequest(true);
        }
    };
    const addNewRoomType = (e) => {
        const new_room_type = {
            room_code: "",
            display_name: "",
            room_lodgiq_code: "",
            max_rate: 0,
            crs_room_code: "",
            room_class: "Standard",
            max_diff: 0,
            min_rate: 0,
            inventory: 0,
            room_codes: {
                pms: [],
            },
            new: false,
            min_diff: 0,
            standard_room_category: "default",
            ada_codes: [],
            room_type_proxy: "",
            is_base: false,
            rate_push_enabled: false,
        };
        requestData.current.room_settings.push(new_room_type);
        setRequestData({ ...requestData, current: requestData.current });
        setIsChangeRequest(true);
    };

    const saveChanges = (event) => {
        setIsRequesting(true);

        let { room_dow_plans } = compRef.current.requestPriceSettings;

        room_dow_plans.map((plan, i) => {
            let new_plans = {};
            requestData.current.room_settings.map((room_settings) => {
                let { room_lodgiq_code } = room_settings;

                if (!plan.room_plans[room_lodgiq_code]) {
                    new_plans[room_lodgiq_code] = {
                        "Sun": 0,
                        "Mon": 0,
                        "Tue": 0,
                        "Wed": 0,
                        "Thu": 0,
                        "Fri": 0,
                        "Sat": 0
                    };
                }
                if (plan.room_plans[room_lodgiq_code]) {
                    new_plans[room_lodgiq_code] = plan.room_plans[room_lodgiq_code];
                }

            });
            compRef.current.requestPriceSettings.room_dow_plans[i].room_plans = new_plans;
        });

        const request_system_settings = {
            ...system_settings[0],
            inventory_settings: requestData,
            pricing_settings: compRef.current.requestPriceSettings
        };
        // debugger;
        let payload = {
            "resource_id": app_state.property_id,
            "resource_type": "property",
            "update_type": "full_system_settings",
            "data": {
                "full_ss": request_system_settings
            },
            // action: "settings",
            // method: "save_system_settings",
            // resourceId: app_state.property_id,
            // resourceType: "property",
            // update_type: "",
            // user_id: user.user_id,
            // token_string: user.token_string,
            // system_settings: request_system_settings,
        };
        let ss_api_config = deepCopy(API_REGISTRY['SaveSystemSettings']);
        let active_ds_id = dsInfo.active_source.id;
        ss_api_config.uri = ss_api_config.uri + app_state.property_id + '/' + active_ds_id + '/';
        // debugger;
        dataService
            .update({ api: ss_api_config.uri, request: { ...ss_api_config.payload, ...payload } })
            .then((data) => {
                //Do some task here just after respons
                if (data.status === "success") {
                    setIsRequestSuccess(true);
                    setIsRequesting(false);
                    setTimeout(() => {
                        const updatedProfile = deepCopy(profile);
                        updatedProfile.property_details[
                            prop_details_index
                        ].system_settings[0] = request_system_settings;
                        dispatch({
                            type: SYSTEM_SETTINGS_UPDATED,
                            payload: updatedProfile,
                        });
                    }, 500);

                    setTimeout(() => {
                        setIsRequestSuccess(false);
                    }, 5000);
                } else {
                    setIsRequestError(true);
                    setIsRequesting(false);
                    setResErrorMsg(data.message);
                    //return Promise.reject(data);
                }
            })
            .catch((err) => {
                setIsRequesting(false);
                setIsRequestError(true);
                setResErrorMsg(
                    (err && err.original && err.original.statusText) ||
                    "Something went wrong, please try again later!"
                );
                setTimeout(() => {
                    setIsRequestError(false);
                }, 5000);
                //return Promise.reject(err);
            });
    };

    console.log(requestData);

    return (
        <div style={{ position: 'relative' }}>
            <Box className='breadcrumb' sx={{ color: 'white.main', position: 'absolute', top: -40 }}>
                <span className="breadcrum-inactive">Settings</span>
                <ArrowForwardIosOutlinedIcon className="breadcrum-inactive"
                    sx={{ top: 3, position: "relative", height: 18 }}
                ></ArrowForwardIosOutlinedIcon>
                <span className="breadcrum-inactive">Property Settings</span>

                <ArrowForwardIosOutlinedIcon className="breadcrum-inactive"
                    sx={{ top: 3, position: "relative", height: 18 }}
                ></ArrowForwardIosOutlinedIcon>
                <span className="breadcrum-active">Inventory</span>
            </Box>
            <div className="property-setting-heading">
                <Typography
                    className="property-setting-main-heading"
                    // variant="h1"
                    gutterBottom
                    sx={{ mr: 3, display: { lg: "inline-block", xs: "none" } }}
                >
                    Inventory
                </Typography>
                <Box sx={{ display: "inline-block", ml: 0 }}>
                    <RadioGroup
                        row
                        aria-labelledby="demo-row-radio-buttons-group-label"
                        name="row-radio-buttons-group"
                        defaultValue="room_level"
                        value={requestData.status}
                        onChange={(e) => {
                            const value = e.target.value;
                            // setRequestData({...requestData, status:value})
                            updateRequestData("status", value);
                        }}
                        sx={{ position: "relative", top: 3, mb: 0 }}
                        className="radio-group"
                    >
                        <FormControlLabel
                            value="total_only"
                            control={<Radio size="small" />}
                            label="Total Only"
                            sx={{ ml: 1 }}
                        />
                        <FormControlLabel
                            value="room_level"
                            control={<Radio size="small" />}
                            label="Room Level"
                            sx={{ ml: 1 }}
                        />
                    </RadioGroup>
                </Box>
                <Box sx={{ float: "right" }}>
                    <Button
                        disabled={!isChangeRequest}
                        onClick={() => {
                            saveChanges();
                        }}
                        variant="outlined"
                        size="small"
                        className={(isChangeRequest) ? "navigate-button btn-active" : "navigate-button"}
                        // color="green"
                        sx={{ minWidth: "auto", mr: 1, color: "white.main" }}
                    >
                        <span style={{ display: !isrequesting ? "block" : "none" }}>
                            Save
                        </span>
                        <span
                            style={{
                                display: isrequesting ? "block" : "none",
                                paddingRight: 10,
                            }}
                        >
                            saving...
                        </span>
                        <CircularProgress
                            sx={{ display: isrequesting ? "block" : "none" }}
                            color="white"
                            size="16px"
                        />
                    </Button>
                    <Button
                        disabled={!isChangeRequest}
                        onClick={cancelSave}
                        variant="outlined"
                        className={(isChangeRequest) ? "navigate-button btn-active" : "navigate-button"}
                        // color="error"
                        size="small"
                        sx={{ minWidth: "auto" }}
                    >
                        cancel
                    </Button>
                </Box>
                <Divider sx={{ clear: "both", mt: { xs: 2, lg: 0 } }} />
            </div>
            {/* <Paper className="property-setting-container-spacing-outside"> */}
            <Box className="inventory-settings " sx={{ maxHeight: "75vh", p: 1, pt: 0, mb: 2, mt: 2, ml: 1, mr: 1 }}>

                {/* <Divider sx={{ clear: "both", mt: { xs: 2, lg: 0 } }} /> */}

                <Box
                    sx={{
                        pt: 2,
                        flexDirection: "column",
                        position: "relative",
                        display: "flex",
                    }}
                >
                    <Alert
                        severity="success"
                        sx={{
                            width: "100%",
                            marginTop: -10,
                            display: isRequestSuccess ? "flex" : "none",
                        }}
                    >
                        {requestSuccessMsg}
                    </Alert>
                    <Alert
                        severity="error"
                        sx={{
                            width: "100%",
                            marginTop: -10,
                            display: isRequestError ? "flex" : "none",
                        }}
                    >
                        {resErrorMsg}
                    </Alert>

                    <Box className="profile-setting-section" display="flex" flexDirection="row">
                        <Box sx={{ mt: { lg: 0, } }}>
                            <FormControl className="textfield" size="small" sx={{ mb: 0, ml: { lg: 0 }, width: 205 }}>
                                <InputLabel>SYSTEM TYPE</InputLabel>
                                <Select

                                    label="SYSTEM TYPE"
                                    sx={{ color: '#797979' }}
                                    value={requestData.current.external_systems[0].system_name}
                                    onChange={(e) => {
                                        const value = e.target.value;
                                        requestData.current.external_systems[0].system_name = value;
                                        updateRequestData("system_name", value);
                                    }}
                                >
                                    <MenuItem value="CRS">CRS</MenuItem>
                                    <MenuItem value="PMS">PMS</MenuItem>
                                </Select>
                            </FormControl>
                            <FormControl className="textfield"
                                size="small"
                                sx={{ mb: 0, ml: { lg: 2 }, width: { xs: "100%", lg: "auto" } }}
                            >
                                <InputLabel>SYSTEM INFO</InputLabel>
                                <OutlinedInput
                                    value={requestData.current.external_systems[0].system_info}
                                    onChange={(e) => {
                                        updateRequestData("system_info", e.currentTarget.value);
                                    }}
                                    label="SYSTEM IFNO"
                                />
                            </FormControl>
                            <FormControl className="textfield"
                                size="small"
                                sx={{ mb: 0, ml: { lg: 2 }, width: { xs: "100%", lg: "auto" } }}
                            >
                                <InputLabel>RATE PUSH VENDOR</InputLabel>
                                <OutlinedInput className="input-text"
                                    label="RATE PUSH VENDOR"
                                    value={requestData.current.external_systems[0].system_code}
                                    onChange={(e) => {
                                        updateRequestData("system_code", e.currentTarget.value);
                                    }}
                                />
                            </FormControl>
                            <FormControl className="textfield"
                                size="small"
                                sx={{ mb: 0, ml: { lg: 2 }, width: { xs: "100%", lg: "auto" } }}
                            >
                                <InputLabel>VENDOR HOTEL ID</InputLabel>
                                <OutlinedInput className="input-text"
                                    label="VENDOR HOTEL ID"
                                    value={
                                        requestData.current.external_systems[0].integration_code
                                    }
                                    onChange={(e) => {
                                        updateRequestData(
                                            "integration_code",
                                            e.currentTarget.value
                                        );
                                    }}
                                />
                            </FormControl>
                            <FormControl className="textfield"
                                size="small"
                                sx={{
                                    mb: 0,
                                    ml: { lg: 2 },
                                    mr: { lg: 2 },
                                    width: { xs: "100%", lg: "auto" },
                                }}
                            >
                                <InputLabel>TOTAL # OF ROOMS</InputLabel>
                                <OutlinedInput className="input-text"
                                    label="TOTAL # OF ROOMS"
                                    value={requestData.current.total_capacity}
                                    onChange={(e) => {
                                        updateRequestData("total_capacity", e.currentTarget.value);
                                    }}
                                />
                            </FormControl>
                        </Box>
                    </Box>

                    <Box
                        sx={{

                            mt: { lg: 1 },
                            display: requestData.status == "room_level" ? "block" : "none",
                        }}
                    >
                        <Grid
                            container
                            className="room-type-details head"
                            spacing={0}
                            sx={{ fontSize: 12 }}
                        >
                            <Grid item className="room-type-details-col" md={2} sx={{}}>
                                <br />ROOM TYPE DESCRIPTION
                            </Grid>
                            <Grid item className="room-type-details-col" md={1}>
                                PMS ROOM TYPE CODE
                            </Grid>
                            <Grid item className="room-type-details-col" md={1}>
                                CRS ROOM TYPE CODE
                            </Grid>
                            <Grid item className="room-type-details-col" md={1.25}>
                                <br />ROOM
                                CLASS
                            </Grid>
                            <Grid item className="room-type-details-col" md={0.75}>
                                <br />COUNT
                            </Grid>
                            <Grid item className="room-type-details-col" md={1}>
                                <br />MIN RATE
                            </Grid>
                            <Grid item className="room-type-details-col" md={1}>
                                <br />MAX RATE
                            </Grid>
                            <Grid item className="room-type-details-col" md={0.75}>
                                <br />BASE
                            </Grid>
                            <Grid item className="room-type-details-col" md={1.1}>
                                MATCH <br />
                                RATE TO
                            </Grid>

                            <Grid item className="room-type-details-col border-rights-none" md={2.15}>
                                RATE PUSH <br />
                                ENABLED
                            </Grid>
                        </Grid>
                        <Box sx={{ mb: 0.5, 'fieldset': { borderRadius: 1 }, 'div[role=button]': { p: '2px 4px 3px', fontSize: 12 }, 'input[type=text]': { p: 0.5, fontSize: 12 }, maxHeight: 'calc(100vh - 330px)', overflowY: 'auto' }}>
                            {requestData.current.room_settings.map((item, index) => {
                                return (
                                    <Grid
                                        key={"roomsettings-" + index}
                                        container
                                        className="room-type-details"
                                        spacing={1}
                                        sx={{ fontSize: 12.5, mt: 0 }}
                                    >
                                        <Grid item className="room-type-details-col" md={2}>
                                            <FormControl fullWidth size="small" className="textfield">
                                                <OutlinedInput
                                                    className="field"
                                                    // sx={{ fontSize: 12 }}
                                                    value={item.display_name}
                                                    onChange={(e) => {
                                                        const value = e.target.value;
                                                        updateRequestData(
                                                            "room_settings>display_name",
                                                            value,
                                                            index
                                                        );
                                                    }}
                                                />
                                            </FormControl>
                                        </Grid>
                                        <Grid item className="room-type-details-col" md={1}>
                                            <FormControl size="small" sx={{}}>
                                                <OutlinedInput
                                                    className="field code textfield"
                                                    value={item.room_code}
                                                    onChange={(e) => {
                                                        const value = e.target.value;
                                                        updateRequestData(
                                                            "room_settings>room_code",
                                                            value,
                                                            index
                                                        );
                                                    }}
                                                />
                                            </FormControl>
                                        </Grid>
                                        <Grid item md={1} className="room-type-details-col">
                                            <FormControl size="small" sx={{}}>
                                                <OutlinedInput
                                                    className="field code textfield"
                                                    value={item.crs_room_code}
                                                    onChange={(e) => {
                                                        const value = e.target.value;
                                                        updateRequestData(
                                                            "room_settings>crs_room_code",
                                                            value,
                                                            index
                                                        );
                                                    }}
                                                />
                                            </FormControl>
                                        </Grid>
                                        <Grid item md={1.25} className="room-type-details-col">
                                            <FormControl size="small" sx={{ width: "100%" }}>
                                                <Select
                                                    className="field textfield"
                                                    sx={{ width: "100%" }}
                                                    value={item.room_class}
                                                    onChange={(e) => {
                                                        const value = e.target.value;
                                                        updateRequestData(
                                                            "room_settings>room_class",
                                                            value,
                                                            index
                                                        );
                                                    }}
                                                >
                                                    <MenuItem value="Standard">Standard</MenuItem>
                                                    <MenuItem value="Superior">Superior</MenuItem>
                                                    <MenuItem value="Deluxe">Deluxe</MenuItem>
                                                    <MenuItem value="Accessible">Accessible</MenuItem>
                                                    <MenuItem value="Jr-Suite">Jr-Suite</MenuItem>
                                                    <MenuItem value="Suite">Suite</MenuItem>
                                                    <MenuItem value="Suites">Suites</MenuItem>
                                                    <MenuItem value="Specialty">Specialty</MenuItem>
                                                </Select>
                                            </FormControl>
                                        </Grid>
                                        <Grid item md={0.75} className="room-type-details-col">
                                            <FormControl size="small" sx={{}}>
                                                <OutlinedInput
                                                    className="field number textfield"
                                                    value={item.inventory}
                                                    onChange={(e) => {
                                                        const value = e.target.value;
                                                        updateRequestData(
                                                            "room_settings>inventory",
                                                            value,
                                                            index
                                                        );
                                                    }}
                                                />
                                            </FormControl>
                                        </Grid>
                                        <Grid item md={1} className="room-type-details-col">
                                            <FormControl size="small" sx={{}}>
                                                <OutlinedInput
                                                    className="field textfield"
                                                    value={item.min_rate}
                                                    onChange={(e) => {
                                                        const value = e.target.value;
                                                        updateRequestData(
                                                            "room_settings>min_rate",
                                                            value,
                                                            index
                                                        );
                                                    }}
                                                />
                                            </FormControl>
                                        </Grid>
                                        <Grid item md={1} className="room-type-details-col">
                                            <FormControl size="small" sx={{}}>
                                                <OutlinedInput
                                                    className="field textfield"
                                                    value={item.max_rate}
                                                    onChange={(e) => {
                                                        const value = e.target.value;
                                                        updateRequestData(
                                                            "room_settings>max_rate",
                                                            value,
                                                            index
                                                        );
                                                    }}
                                                />
                                            </FormControl>
                                        </Grid>
                                        <Grid item md={0.75} className="room-type-details-col">
                                            <Checkbox
                                                className="colored-checkbox"
                                                checked={item.is_base}
                                                name="is_base"
                                                onChange={(e) => {
                                                    const value = e.target.value;
                                                    item.is_base = !item.is_base;
                                                    updateRequestData(
                                                        "room_settings>is_base",
                                                        item.is_base,
                                                        index
                                                    );
                                                }}
                                            />
                                        </Grid>
                                        <Grid item md={1.1} className="room-type-details-col">
                                            <FormControl size="small" sx={{}}>
                                                <OutlinedInput
                                                    className="field textfield"
                                                    placeholder="PMS Code"
                                                    value={item.room_type_proxy}
                                                    onChange={(e) => {
                                                        const value = e.target.value;
                                                        updateRequestData(
                                                            "room_settings>room_type_proxy",
                                                            value,
                                                            index
                                                        );
                                                    }}
                                                />
                                            </FormControl>
                                        </Grid>

                                        <Grid
                                            item
                                            md={1}
                                            className="room-type-details-col"
                                            alignContent="stretch"
                                        >
                                            <Checkbox
                                                checked={item.rate_push_enabled}
                                                value={true}
                                                className="colored-checkbox"
                                                name="rate_push_enabled"
                                                onChange={(e) => {
                                                    const value = e.target.value;
                                                    item.rate_push_enabled = !item.rate_push_enabled;
                                                    updateRequestData(
                                                        "room_settings>rate_push_enabled",
                                                        item.rate_push_enabled,
                                                        index
                                                    );
                                                }}
                                            />
                                        </Grid>
                                        <Grid item md={0.5}>
                                            <Box display="flex" spacing={1}>
                                                <Button
                                                    onClick={() => {
                                                        changeRoomTypeOrder(item, index, "down");
                                                    }}
                                                    size="large"
                                                    sx={{ minWidth: "auto", p: 0, lineHeight: "auto" }}
                                                    disabled={
                                                        index ===
                                                        requestData.current.room_settings.length - 1
                                                    }
                                                >

                                                    <KeyboardArrowDownOutlined fontSize="small"></KeyboardArrowDownOutlined>
                                                </Button>
                                                <Button
                                                    onClick={() => {
                                                        changeRoomTypeOrder(item, index, "up");
                                                    }}
                                                    size="small"
                                                    sx={{ minWidth: "auto", p: 0, lineHeight: "auto" }}
                                                    disabled={index === 0}
                                                >
                                                    <KeyboardArrowUpOutlinedIcon fontSize="small"></KeyboardArrowUpOutlinedIcon>
                                                </Button>
                                                <Button
                                                    variant=""
                                                    size="small"
                                                    sx={{ minWidth: "auto", p: 0, lineHeight: "auto" }}
                                                    onClick={() => {
                                                        deleteRoomType(index);
                                                    }}
                                                >

                                                    <DeleteOutlineOutlinedIcon
                                                        fontSize="small"
                                                        color="error"
                                                    ></DeleteOutlineOutlinedIcon>
                                                </Button>
                                            </Box>
                                        </Grid>
                                    </Grid>
                                );
                            })}

                            <Box sx={{ p: 0, m: 0, mt: 1, }}>
                                <Button
                                    variant="outlined"
                                    className="add-button"
                                    size="small"
                                    sx={{ lineHeight: "auto" }}
                                    onClick={() => {
                                        addNewRoomType();
                                    }}
                                >
                                    <span>Add ROOM TYPE</span>
                                </Button>
                            </Box>
                        </Box>

                    </Box>
                    {isrequesting && (
                        <Box
                            sx={{
                                textAlighn: "center",
                                backgroundColor: "dark.darkest",
                                opacity: 0.8,
                                position: "fixed",
                                top: 40,
                                left: 0,
                                width: "100%",
                                height: "100%",
                            }}
                        >
                            <CircularProgress
                                size={40}
                                sx={{
                                    color: "white",
                                    position: "absolute",
                                    top: "50%",
                                    left: "50%",
                                    marginTop: "-20px",
                                    marginLeft: "-20px",
                                }}
                            />
                        </Box>
                    )}
                </Box>
            </Box>
            {/* </Paper> */}
        </div>
    );
}

function CompsetSettings(props) {
    const dispatch = useDispatch();

    const [isrequesting, setIsRequesting] = useState(false);
    const [resError, setResError] = useState(false);
    const [isRequestSuccess, setIsRequestSuccess] = useState(false);
    const [requestSuccessMsg, setRequestSuccessMsg] = useState(
        "Your change request for inventory settings was successfull!"
    );
    const [isRequestError, setIsRequestError] = useState(false);
    const [resErrorMsg, setResErrorMsg] = useState("");

    const { app, auth } = useSelector((state) => state);
    const { user, profile } = auth;
    const { app_state, ui_state } = app.current_state;
    const { property_details } = auth.profile || [];
    //Data source information
    const [dsInfo, setDsInfo] = useState(getDataSources(property_details, app_state.property_id));

    let prop_details_index = 0;
    const prop_details = property_details.filter((item, index) => {
        if (item.id === app_state.property_id) {
            prop_details_index = index;
            return item.id === app_state.property_id;
        }
        //
    });
    const { system_settings } =
        prop_details && prop_details.length ? prop_details[0] : null;
    const { compsetSettings, vendor_integation_mappings } = JSON.parse(
        JSON.stringify(system_settings[0])
    );
    const compsetSettings_ = JSON.parse(
        JSON.stringify(system_settings[0].compsetSettings)
    );
    const vendor_integation_mappings_ = JSON.parse(
        JSON.stringify(system_settings[0].vendor_integation_mappings)
    );
    //debugger;
    if (compsetSettings.source
        && vendor_integation_mappings.compset[compsetSettings.source]
        && vendor_integation_mappings.compset[compsetSettings.source].load_schedule) {
        vendor_integation_mappings.compset[compsetSettings.source].load_schedule =
            vendor_integation_mappings.compset[compsetSettings.source].load_schedule
                ? vendor_integation_mappings.compset[compsetSettings.source].load_schedule
                : {};
    }

    const [requestData, setRequestData] = useState({
        compsetSettings: compsetSettings,
        vendor_integation_mappings: vendor_integation_mappings,
    });
    const [isChangeRequest, setIsChangeRequest] = useState(false);

    const cancelSave = () => {
        setRequestData({
            compsetSettings: compsetSettings_,
            vendor_integation_mappings: vendor_integation_mappings_,
        });
        setIsChangeRequest(false);
        //Setting this ss_update variable to be used  in prop change confirmation 
        dispatch(updateAppState('settingStatus', {
            app_state: {
                ...app_state,
                ss_updated: false
            },
            ui_state: { ...ui_state }
        }, "settingStatus"));
    };
    const updateRequestData = (keys, val, index) => {
        const updatedObj = { ...requestData };
        const key = keys.split(">");
        // debugger;
        switch (key[0]) {
            case "source":
                updatedObj.compsetSettings[key[0]] = val;
                //We will make this active in case we need source other than ata_i_api
                // if (!updatedObj.vendor_integation_mappings.compset[updatedObj.compsetSettings.source]) {
                //   updatedObj.vendor_integation_mappings.compset[updatedObj.compsetSettings.source] = {
                //     "subscription_id": "",
                //     "channel": "",
                //     "hotel_id": "",
                //     "load_schedule": {
                //       "data_repull_interval_minutes": "",
                //       "from": "",
                //       "until": "",
                //       "timezone": ""
                //     },
                //     "is_bar": false
                //   }
                // }

                break;
            case "compsetSettings":
                // updatedObj.compsetSettings[key[1]] = val;
                if (key[1] == "compset_filter") {
                    let size = val.length;
                    updatedObj.compsetSettings[key[1]] = updatedObj.compsetSettings[
                        key[1]
                    ]
                        ? updatedObj.compsetSettings[key[1]]
                        : [];
                    let l = updatedObj.compsetSettings[key[1]].length;
                    updatedObj.compsetSettings[key[1]].splice(0, l);
                    val.map((item, i) => {
                        let compsetId = Number(item);
                        if (!isNaN(compsetId)) {
                            updatedObj.compsetSettings[key[1]].push(compsetId);
                        } else {
                            //updatedObj.compsetSettings[key[1]].push(compsetId);
                        }

                    });
                } else {
                    updatedObj.compsetSettings[key[1]] = val;
                }
                break;

            case "vendor_integation_mappings":
                // debugger;
                if (key[1] === "load_schedule") {
                    if (!updatedObj.vendor_integation_mappings.compset[updatedObj.compsetSettings.source][key[1]]) {
                        updatedObj.vendor_integation_mappings.compset[updatedObj.compsetSettings.source][key[1]] = {};
                    }
                    updatedObj.vendor_integation_mappings.compset[updatedObj.compsetSettings.source][key[1]][key[2]] = val;

                }
                else if (key[1] == "is_bar" && updatedObj.vendor_integation_mappings.compset[updatedObj.compsetSettings.source]
                [key[1]] === 'on') {
                    updatedObj.vendor_integation_mappings.compset[
                        updatedObj.compsetSettings.source
                    ][key[1]] = '';
                } else if (key[1] !== "load_schedule") {
                    updatedObj.vendor_integation_mappings.compset[
                        updatedObj.compsetSettings.source
                    ][key[1]] = val;
                }

                break;

            default:
        }
        setRequestData({ ...requestData, ...updatedObj });
        setIsChangeRequest(true);
        updateAppState('settingStatus', {
            app_state: {
                ...app_state,
                ss_updated: true
            },
            ui_state: { ...ui_state }
        }, "settingStatus");

    };

    const saveChanges = (event) => {
        setIsRequesting(true);
        const request_system_settings = {
            ...system_settings[0],
            compsetSettings: requestData.compsetSettings,
            vendor_integation_mappings: requestData.vendor_integation_mappings,
        };
        //let  compset_filter = request_system_settings.compsetSettings.compset_filter.split(',');
        //request_system_settings.compsetSettings.compset_filter = []
        // compset_filter.map((item, index)=>{
        //   //request_system_settings.compsetSettings.compset_filter.push(Number(item));
        // });

        let payload = {
            "resource_id": app_state.property_id,
            "resource_type": "property",
            "update_type": "full_system_settings",
            "data": {
                "full_ss": request_system_settings
            }
        };

        let ss_api_config = deepCopy(API_REGISTRY['SaveSystemSettings']);
        let active_ds_id = dsInfo.active_source.id;
        ss_api_config.uri = ss_api_config.uri + app_state.property_id + '/' + active_ds_id + '/';

        dataService
            .update({ api: ss_api_config.uri, request: { ...ss_api_config.payload, ...payload } })
            .then((data) => {
                //Do some task here just after respons
                if (data.status === "success") {
                    setIsRequestSuccess(true);
                    setIsRequesting(false);
                    setTimeout(() => {
                        const updatedProfile = deepCopy(profile);
                        updatedProfile.property_details[
                            prop_details_index
                        ].system_settings[0] = request_system_settings;
                        dispatch({
                            type: SYSTEM_SETTINGS_UPDATED,
                            payload: updatedProfile,
                        });
                    }, 500);
                    setTimeout(() => {
                        setIsRequestSuccess(false);
                    }, 5000);
                } else {
                    setIsRequestError(true);
                    setIsRequesting(false);
                    setResErrorMsg(data.message);
                    //return Promise.reject(data);
                }
            })
            .catch((err) => {
                setIsRequesting(false);
                setIsRequestError(true);
                setResErrorMsg(
                    (err && err.original && err.original.statusText) ||
                    "Something went wrong, please try again later!"
                );
                setTimeout(() => {
                    setIsRequestError(false);
                }, 5000);
                //return Promise.reject(err);
            });
    };

    console.log(requestData);

    return (
        <div>
            <Box className='breadcrumb' sx={{ color: 'white.main', position: 'absolute', top: -40 }}>
                <span className="breadcrum-inactive">Settings</span>
                <ArrowForwardIosOutlinedIcon className="breadcrum-inactive"
                    sx={{ top: 3, position: "relative", height: 18 }}
                ></ArrowForwardIosOutlinedIcon>
                <span className="breadcrum-inactive">Property Settings</span>

                <ArrowForwardIosOutlinedIcon className="breadcrum-inactive"
                    sx={{ top: 3, position: "relative", height: 18 }}
                ></ArrowForwardIosOutlinedIcon>
                <span className="breadcrum-active">Compset</span>

            </Box>
            <div className="property-setting-heading">
                <Typography
                    className="property-setting-main-heading"
                    // variant="h1"
                    gutterBottom
                    sx={{ mr: 3, display: { lg: "inline-block", xs: "none" } }}
                >
                    Compset
                </Typography>
                <Box sx={{ float: "right" }}>
                    <Button
                        disabled={!isChangeRequest}
                        onClick={() => {
                            saveChanges();
                        }}
                        variant="outlined"
                        size="small"
                        className={(isChangeRequest) ? "navigate-button btn-active" : "navigate-button"}
                        // color="green"
                        sx={{ minWidth: "auto", mr: 1, color: "white.main" }}
                    >
                        <span style={{ display: !isrequesting ? "block" : "none" }}>
                            Save
                        </span>
                        <span
                            style={{
                                display: isrequesting ? "block" : "none",
                                paddingRight: 10,
                            }}
                        >
                            saving...
                        </span>
                        <CircularProgress
                            sx={{ display: isrequesting ? "block" : "none" }}
                            color="white"
                            size="16px"
                        />
                    </Button>
                    <Button
                        disabled={!isChangeRequest}
                        onClick={cancelSave}
                        variant="outlined"
                        className={(isChangeRequest) ? "navigate-button btn-active" : "navigate-button"}
                        // color="error"
                        size="small"
                        sx={{ minWidth: "auto" }}
                    >
                        cancel
                    </Button>
                </Box>
                <Divider sx={{ clear: "both", mt: { xs: 2, lg: 0 } }} />
            </div>

            <Box className="inventory-settings " sx={{ p: 1, pt: 0, mb: 2, mt: 0, ml: 1, mr: 1 }}>

                {/* <Divider sx={{ clear: "both", mt: { xs: 2, lg: 0 } }} /> */}

                <Box
                    sx={{
                        pt: 2,
                        flexDirection: "column",
                        position: "relative",
                        display: "flex",
                    }}
                >
                    <Alert
                        severity="success"
                        sx={{
                            position: "absolute",
                            zIndex: 100,
                            width: "100%",
                            marginTop: -10,
                            display: isRequestSuccess ? "flex" : "none",
                        }}
                    >
                        {requestSuccessMsg}
                    </Alert>
                    <Alert
                        severity="error"
                        sx={{
                            width: "100%",
                            marginTop: -10,
                            display: isRequestError ? "flex" : "none",
                        }}
                    >
                        {resErrorMsg}
                    </Alert>
                    {/* <Paper> */}
                    <Box>
                        {/* className="property-setting-container-spacing" */}
                        <Grid container spacing={2} sx={{ fontSize: 10 }}>
                            <Grid container spacing={0}>
                                <Grid className="seperate-section-compset" item xs={12}>

                                    <Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 2 }}>
                                        <Grid item xs={12}>
                                            <Typography
                                                className="pd-bottom"
                                                gutterBottom
                                                sx={{ fontSize: 14, paddingBottom: "8px" }}
                                            >
                                                COMPSET SETTINGS
                                            </Typography>
                                            {/* <Divider sx={{clear:'both'}} /> */}
                                        </Grid>
                                        <Grid container spacing={2} item xs={6}>
                                            <Grid item lg={12}>
                                                <FormControl className="textfield" fullWidth size="small">
                                                    <InputLabel>RATE SOURCE</InputLabel>
                                                    <OutlinedInput
                                                        className="input-text"
                                                        value={requestData.compsetSettings.source}
                                                        onChange={(e) => {
                                                            updateRequestData("source", e.currentTarget.value);
                                                        }}
                                                        label="RATE SOURCE"
                                                    />
                                                </FormControl>
                                            </Grid>
                                            <Grid item lg={12}>
                                                <FormControl className="textfield" size="small" fullWidth>
                                                    <InputLabel>SHOP ID</InputLabel>
                                                    <OutlinedInput
                                                        className="input-text"
                                                        label="SHOP ID"
                                                        value={requestData.compsetSettings.resource_id}
                                                        onChange={(e) => {
                                                            updateRequestData(
                                                                "compsetSettings>resource_id",
                                                                e.currentTarget.value
                                                            );
                                                        }}
                                                    />
                                                </FormControl>
                                            </Grid>
                                            <Grid item lg={12}>
                                                <FormControl className="textfield" fullWidth size="small">
                                                    <InputLabel>RESOURCE SCOPE</InputLabel>
                                                    <OutlinedInput
                                                        className="input-text"
                                                        label="RESOURCE SCOPE"
                                                        value={requestData.compsetSettings.resource_scope}
                                                        onChange={(e) => {
                                                            updateRequestData(
                                                                "compsetSettings>resource_scope",
                                                                e.currentTarget.value
                                                            );
                                                        }}
                                                    />
                                                </FormControl>
                                            </Grid>
                                        </Grid>
                                        <Grid item xs={6} >
                                            <Grid item lg={12}>
                                                <FormControl className="textfield" fullWidth size="small">
                                                    {/* <InputLabel>COMPSET FILTER</InputLabel> */}

                                                    <Autocomplete
                                                        // size="Extra"
                                                        className="fieldset-size"
                                                        multiple
                                                        options={[]}
                                                        value={requestData.compsetSettings.compset_filter}
                                                        freeSolo

                                                        onChange={(e, newVal) => {
                                                            //item.recipients;
                                                            updateRequestData(
                                                                "compsetSettings>compset_filter",
                                                                newVal
                                                            );
                                                        }}
                                                        renderTags={(value, getTagProps) =>
                                                            value.map((option, index) => (
                                                                <div className="compset-filter" sx={{ minHeight: '300px' }}>
                                                                    <Chip
                                                                        key={"id-" + index}
                                                                        size="small"

                                                                        variant="outlined"
                                                                        // color="primary"
                                                                        label={option}
                                                                        {...getTagProps({ index })}
                                                                        className="property-schedule-chip"
                                                                    />
                                                                </div>
                                                            ))
                                                        }
                                                        renderInput={(params) => (
                                                            <TextField
                                                                className="textfield"
                                                                {...params}
                                                                size="small"
                                                                variant="outlined"
                                                                label="COMPSET FILTER"
                                                                placeholder="Type compset ID and press Enter"
                                                            />
                                                        )}
                                                    />

                                                    {/* <TextField
                    helperText="Enter comma seprated property IDs"
                    label="COMPSET FILTER"
                    size="small"
                    value={requestData.compsetSettings.compset_filter}
                    onChange={(e) => {
                      updateRequestData(
                        "compsetSettings>compset_filter",
                        e.currentTarget.value
                      );
                    }}
                  /> */}
                                                </FormControl>
                                            </Grid>
                                        </Grid>
                                    </Grid>


                                </Grid>
                            </Grid>
                            {requestData.compsetSettings.source && requestData.vendor_integation_mappings.compset[requestData.compsetSettings.source] &&
                                <>
                                    <Grid item xs={12}>
                                        <Typography
                                            gutterBottom
                                            sx={{ fontSize: 14 }}
                                        >
                                            VENDOR INTEGRATION MAPPING
                                        </Typography>
                                        {/* <Divider sx={{clear:'both'}} /> */}
                                    </Grid>

                                    <Grid item lg={6}>
                                        <FormControl className="textfield" fullWidth size="small">
                                            <InputLabel>HOTEL ID</InputLabel>
                                            <OutlinedInput
                                                className="input-text"
                                                label="HOTEL ID"
                                                value={requestData.compsetSettings.source &&
                                                    requestData.vendor_integation_mappings.compset[
                                                        requestData.compsetSettings.source
                                                    ].hotel_id
                                                }
                                                onChange={(e) => {
                                                    updateRequestData(
                                                        "vendor_integation_mappings>hotel_id",
                                                        e.currentTarget.value
                                                    );
                                                }}
                                            />
                                        </FormControl>
                                        {/* <FormControl className="textfield" fullWidth size="small">
                  <OutlinedInput
                    className="input-text"
                    label="HOTEL ID"
                    value={
                      requestData.vendor_integation_mappings.compset[
                        requestData.compsetSettings.source
                      ].hotel_id
                    }
                    onChange={(e) => {
                      updateRequestData(
                        "vendor_integation_mappings>hotel_id",
                        e.currentTarget.value
                      );
                    }}
                  />
                </FormControl> */}
                                    </Grid>
                                    <Grid item lg={6}>
                                        <FormControl className="textfield" fullWidth size="small">
                                            <InputLabel>SUBSCRIPTION ID</InputLabel>
                                            <OutlinedInput
                                                className="input-text"
                                                label="SUBSCRIPTION ID"
                                                value={requestData.compsetSettings.source &&
                                                    requestData.vendor_integation_mappings.compset[
                                                        requestData.compsetSettings.source
                                                    ].subscription_id
                                                }
                                                onChange={(e) => {
                                                    updateRequestData(
                                                        "vendor_integation_mappings>subscription_id",
                                                        e.currentTarget.value
                                                    );
                                                }}
                                            />
                                        </FormControl>
                                    </Grid>
                                    <Grid item lg={6}>
                                        <FormControl className="textfield" fullWidth size="small">
                                            <InputLabel>NUMBER OF GUESTS</InputLabel>
                                            <OutlinedInput
                                                className="input-text"
                                                label="NUMBER OF GUESTS"
                                                type="number"
                                                value={requestData.compsetSettings.source &&
                                                    requestData.vendor_integation_mappings.compset[
                                                        requestData.compsetSettings.source
                                                    ].guests
                                                }
                                                onChange={(e) => {
                                                    updateRequestData(
                                                        "vendor_integation_mappings>guests",
                                                        e.currentTarget.value
                                                    );
                                                }}
                                            />
                                        </FormControl>
                                    </Grid>

                                    <Grid item lg={6}>
                                        <FormControl className="textfield" fullWidth size="small">
                                            <InputLabel>LENGTH OF STAY</InputLabel>
                                            <OutlinedInput
                                                className="input-text"
                                                label="LENGTH OF STAY"
                                                type="number"
                                                value={requestData.compsetSettings.source &&
                                                    requestData.vendor_integation_mappings.compset[
                                                        requestData.compsetSettings.source
                                                    ].length_of_stay
                                                }
                                                onChange={(e) => {
                                                    updateRequestData(
                                                        "vendor_integation_mappings>length_of_stay",
                                                        e.currentTarget.value
                                                    );
                                                }}
                                            />
                                        </FormControl>
                                    </Grid>
                                    <Grid item lg={6}>
                                        <FormControl className="textfield" fullWidth size="small">
                                            <InputLabel>CHANNEL</InputLabel>
                                            <OutlinedInput
                                                className="input-text"
                                                label="CHANNEL"
                                                value={requestData.compsetSettings.source &&
                                                    requestData.vendor_integation_mappings.compset[
                                                        requestData.compsetSettings.source
                                                    ].channel
                                                }
                                                onChange={(e) => {
                                                    updateRequestData(
                                                        "vendor_integation_mappings>channel",
                                                        e.currentTarget.value
                                                    );
                                                }}
                                            />
                                        </FormControl>
                                    </Grid>
                                    <Grid item lg={6}>
                                        <FormControlLabel className="textfield input-text"
                                            control={
                                                <Checkbox
                                                    className="colored-checkbox"
                                                    checked={requestData.compsetSettings.source &&
                                                        requestData.vendor_integation_mappings.compset[
                                                            requestData.compsetSettings.source
                                                        ].is_bar
                                                    }
                                                />
                                            }
                                            label="BAR"
                                            onChange={(e) => {
                                                updateRequestData(
                                                    "vendor_integation_mappings>is_bar",
                                                    e.currentTarget.checked
                                                );
                                            }}
                                        />
                                    </Grid>
                                    <Grid item xs={12}>
                                        <Typography gutterBottom sx={{ fontSize: 14 }}>
                                            LOAD SCHEDULE
                                        </Typography>
                                    </Grid>
                                    <Grid item lg={3}>
                                        <FormControl name="from" label="From" className="textfield" fullWidth size="small">
                                            <InputLabel>From</InputLabel>

                                            <OutlinedInput
                                                className="input-text"
                                                label="From"
                                                placeholder="00:00"
                                                value={requestData.compsetSettings.source &&
                                                    requestData.vendor_integation_mappings.compset[
                                                        requestData.compsetSettings.source
                                                    ].load_schedule &&
                                                    requestData.vendor_integation_mappings.compset[
                                                        requestData.compsetSettings.source
                                                    ].load_schedule.from
                                                }
                                                onChange={(e) => {
                                                    updateRequestData(
                                                        "vendor_integation_mappings>load_schedule>from",
                                                        e.currentTarget.value
                                                    );
                                                }}

                                            />
                                        </FormControl>
                                    </Grid>
                                    <Grid item lg={3}>
                                        <FormControl className="textfield" label="Until" fullWidth size="small">
                                            <InputLabel>Until</InputLabel>
                                            <OutlinedInput
                                                className="input-text"
                                                label="Until"
                                                name="source"
                                                value={requestData.compsetSettings.source &&
                                                    requestData.vendor_integation_mappings.compset[
                                                        requestData.compsetSettings.source
                                                    ].load_schedule &&
                                                    requestData.vendor_integation_mappings.compset[
                                                        requestData.compsetSettings.source
                                                    ].load_schedule.until
                                                }
                                                onChange={(e) => {
                                                    updateRequestData(
                                                        "vendor_integation_mappings>load_schedule>until",
                                                        e.currentTarget.value
                                                    );
                                                }}
                                            />
                                        </FormControl>
                                    </Grid>
                                    <Grid item lg={3}>
                                        <FormControl className="textfield" fullWidth size="small">
                                            <InputLabel>Pull Interval</InputLabel>
                                            <OutlinedInput
                                                className="input-text"
                                                label="Pull Interval"
                                                name="data_repull_interval_minutes"
                                                value={requestData.compsetSettings.source &&
                                                    requestData.vendor_integation_mappings.compset[
                                                        requestData.compsetSettings.source
                                                    ].load_schedule &&
                                                    requestData.vendor_integation_mappings.compset[
                                                        requestData.compsetSettings.source
                                                    ].load_schedule.data_repull_interval_minutes
                                                }
                                                onChange={(e) => {
                                                    updateRequestData(
                                                        "vendor_integation_mappings>load_schedule>data_repull_interval_minutes",
                                                        e.currentTarget.value
                                                    );
                                                }}
                                            />
                                        </FormControl>
                                    </Grid>

                                    <Grid item lg={3}>
                                        <FormControl className="textfield" fullWidth size="small">
                                            <InputLabel>Timezone</InputLabel>
                                            <OutlinedInput
                                                className="input-text"
                                                label="Timezone"
                                                value={requestData.compsetSettings.source &&
                                                    requestData.vendor_integation_mappings.compset[
                                                        requestData.compsetSettings.source
                                                    ].load_schedule &&
                                                    requestData.vendor_integation_mappings.compset[
                                                        requestData.compsetSettings.source
                                                    ].load_schedule.timezone
                                                }
                                                onChange={(e) => {
                                                    updateRequestData(
                                                        "vendor_integation_mappings>load_schedule>timezone",
                                                        e.currentTarget.value
                                                    );
                                                }}
                                            />
                                        </FormControl>
                                    </Grid>
                                </>}
                        </Grid>

                    </Box>
                    {/* </Paper> */}

                    {isrequesting && (
                        <Box
                            sx={{
                                textAlighn: "center",
                                backgroundColor: "dark.darkest",
                                opacity: 0.8,
                                position: "fixed",
                                top: 40,
                                left: 0,
                                width: "100%",
                                height: "100%",
                            }}
                        >
                            <CircularProgress
                                size={40}
                                sx={{
                                    color: "white",
                                    position: "absolute",
                                    top: "50%",
                                    left: "50%",
                                    marginTop: "-20px",
                                    marginLeft: "-20px",
                                }}
                            />
                        </Box>
                    )}
                </Box>
            </Box>
        </div>
    );
}

function RatesSettings(props) {
    const dispatch = useDispatch();

    const [isrequesting, setIsRequesting] = useState(false);
    const [resError, setResError] = useState(false);
    const [isRequestSuccess, setIsRequestSuccess] = useState(false);
    const [requestSuccessMsg, setRequestSuccessMsg] = useState(
        "Your change request for inventory settings was successfull!"
    );
    const [isRequestError, setIsRequestError] = useState(false);
    const [resErrorMsg, setResErrorMsg] = useState("");
    const [value, setValue] = React.useState(0);

    const handleChange = (event, newValue) => {
        setValue(newValue);
    };
    const { app, auth } = useSelector((state) => state);
    const { user, profile } = auth;
    const { app_state, ui_state } = app.current_state;
    const { property_details } = auth.profile || [];
    //Data source information
    const [dsInfo, setDsInfo] = useState(getDataSources(property_details, app_state.property_id));
    let prop_details_index = 0;
    const prop_details = property_details.filter((item, index) => {
        if (item.id === app_state.property_id) {
            prop_details_index = index;
            return item.id === app_state.property_id;
        }
        //
    });
    const { system_settings } =
        prop_details && prop_details.length ? prop_details[0] : null;

    //Handling if resource_lookup is missing in system_settings object
    if (!system_settings[0].rate_source.resource_lookup) {
        system_settings[0].rate_source.resource_lookup = { rate_code: "" };
    }
    // debugger;
    const rateSettings = {
        rate_source: {
            resource_lookup: { rate_code: system_settings[0].rate_source.resource_lookup.rate_code },
            type: system_settings[0].rate_source.type, //RATE SOURCE
            source: system_settings[0].rate_source.source, //SOURCE
            resource_id: system_settings[0].rate_source.resource_id, //RESOURCE IDS
        },
        default_rate_segment_code: system_settings[0].default_rate_segment_code, //DEFAULT MARKET CODE
        rate_push_type: system_settings[0].rate_push_type, //RATE PUSH MODE
        rtp_grid_option_type: system_settings[0].rtp_grid_option_type, //RTP SETTINGS
    };
    // debugger;
    const [requestData, setRequestData] = useState(rateSettings);
    const [isChangeRequest, setIsChangeRequest] = useState(false);

    const cancelSave = () => {
        setRequestData({
            rate_source: system_settings[0].rate_source,
            default_rate_segment_code: system_settings[0].default_rate_segment_code,
            rate_push_type: system_settings[0].rate_push_type,
            rtp_grid_option_type: system_settings[0].rtp_grid_option_type,
        });
        setIsChangeRequest(false);
    };
    const updateRequestData = (keys, val, index) => {
        debugger
        const updatedObj = { ...requestData };
        const key = keys.split(">");
        switch (key[0]) {
            case "default_rate_segment_code":
            case "rate_push_type":
            case "rtp_grid_option_type":
                updatedObj[key[0]] = val;
                break;

            case "rate_source":
                if (key.length === 2) {
                    if (key[1] === 'resource_id') {
                        updatedObj[key[0]][key[1]] = val.map(v => Number(v));
                    } else {
                        updatedObj[key[0]][key[1]] = val;
                    }

                }
                if (key.length === 3) {
                    updatedObj[key[0]][key[1]][key[2]] = val;
                }
                break;

            default:
        }
        setRequestData({ ...requestData, ...updatedObj });
        setIsChangeRequest(true);
    };

    const saveChanges = (event) => {
        // debugger;
        setIsRequesting(true);
        if (requestData.rate_source.type !== 'compset') {
            requestData.rate_source.source = "";
            requestData.rate_source.resource_id = [];
        }
        const request_system_settings = {
            ...system_settings[0],
            rate_source: requestData.rate_source,
            default_rate_segment_code: requestData.default_rate_segment_code,
            rate_push_type: requestData.rate_push_type,
            rtp_grid_option_type: requestData.rtp_grid_option_type,
        };
        let payload = {
            "resource_id": app_state.property_id,
            "resource_type": "property",
            "update_type": "full_system_settings",
            "data": {
                "full_ss": request_system_settings
            }
        };
        // debugger;

        let ss_api_config = deepCopy(API_REGISTRY['SaveSystemSettings']);
        let active_ds_id = dsInfo.active_source.id;
        ss_api_config.uri = ss_api_config.uri + app_state.property_id + '/' + active_ds_id + '/';

        dataService
            .update({ api: ss_api_config.uri, request: { ...ss_api_config.payload, ...payload } })
            .then((data) => {
                //Do some task here just after respons
                if (data.status === "success") {
                    setIsRequestSuccess(true);
                    setIsRequesting(false);
                    setTimeout(() => {
                        profile.property_details[prop_details_index].system_settings[0] =
                            request_system_settings;
                        dispatch({ type: SYSTEM_SETTINGS_UPDATED, payload: profile });
                    }, 500);
                    setTimeout(() => {
                        setIsRequestSuccess(false);
                    }, 5000);
                } else {
                    setIsRequestError(true);
                    setIsRequesting(false);
                    setResErrorMsg(data.message);
                    //return Promise.reject(data);
                }
            })
            .catch((err) => {
                setIsRequesting(false);
                setIsRequestError(true);
                setResErrorMsg(
                    (err && err.original && err.original.statusText) ||
                    "Something went wrong, please try again later!"
                );
                setTimeout(() => {
                    setIsRequestError(false);
                }, 5000);
                //return Promise.reject(err);
            });
    };

    console.log(system_settings[0]);

    return (
        <div>
            <Box className='breadcrumb' sx={{ color: 'white.main', position: 'absolute', top: -40 }}>
                <span className="breadcrum-inactive">Settings</span>
                <ArrowForwardIosOutlinedIcon className="breadcrum-inactive"
                    sx={{ top: 3, position: "relative", height: 18 }}
                ></ArrowForwardIosOutlinedIcon>
                <span className="breadcrum-inactive">Property Settings</span>
                <ArrowForwardIosOutlinedIcon className="breadcrum-inactive"
                    sx={{ top: 3, position: "relative", height: 18 }}
                ></ArrowForwardIosOutlinedIcon>
                <span className="breadcrum-active">Rates</span>
            </Box>
            <div className="property-setting-heading">
                <Typography
                    className="property-setting-main-heading"
                    // variant="h1"
                    gutterBottom
                    sx={{ mr: 3, display: { lg: "inline-block", xs: "none" } }}
                >
                    Rates
                </Typography>
                <Box sx={{ float: "right" }}>
                    <Button
                        disabled={!isChangeRequest}
                        onClick={() => {
                            saveChanges();
                        }}
                        variant="outlined"
                        size="small"
                        className={(isChangeRequest) ? "navigate-button btn-active" : "navigate-button"}
                        // color="green"
                        sx={{ minWidth: "auto", mr: 1, color: "white.main" }}
                    >
                        <span style={{ display: !isrequesting ? "block" : "none" }}>
                            Save
                        </span>
                        <span
                            style={{
                                display: isrequesting ? "block" : "none",
                                paddingRight: 10,
                            }}
                        >
                            saving...
                        </span>
                        <CircularProgress
                            sx={{ display: isrequesting ? "block" : "none" }}
                            color="white"
                            size="16px"
                        />
                    </Button>
                    <Button
                        disabled={!isChangeRequest}
                        onClick={cancelSave}
                        variant="outlined"
                        className={(isChangeRequest) ? "navigate-button btn-active" : "navigate-button"}
                        // color="error"
                        size="small"
                        sx={{ minWidth: "auto" }}
                    >
                        cancel
                    </Button>
                </Box>
                <Divider sx={{ clear: "both", mt: { xs: 2, lg: 0 } }} />
            </div>


            <Box className="inventory-settings " sx={{ p: 1, pt: 0, mb: 2, mt: 2, ml: 1, mr: 1 }}>
                {/* <div className="border-bottom">
          <Typography
            variant="h5"
            gutterBottom
            sx={{ mr: 3, display: { lg: "inline-block", xs: "none" } }}
          >
            Rates
          </Typography>

          <Box sx={{ float: "right" }}>
            <Button
              disabled={!isChangeRequest}
              onClick={() => {
                saveChanges();
              }}
              variant="contained"
              size="small"
              className="navigate-button"
              // color="green"
              sx={{ minWidth: "auto", mr: 1, color: "white.main" }}
            >
              <span style={{ display: !isrequesting ? "block" : "none" }}>
                Save
              </span>
              <span
                style={{
                  display: isrequesting ? "block" : "none",
                  paddingRight: 10,
                }}
              >
                saving...
              </span>
              <CircularProgress
                sx={{ display: isrequesting ? "block" : "none" }}
                color="white"
                size="16px"
              />
            </Button>
            <Button
              disabled={!isChangeRequest}
              onClick={cancelSave}
              variant="contained"
              className="navigate-button"
              // color="error"
              size="small"
              sx={{ minWidth: "auto" }}
            >
              cancel
            </Button>
          </Box>
        </div>
        <Divider sx={{ clear: "both", mt: { xs: 2, lg: 0 } }} /> */}

                <Box
                    sx={{
                        pt: 2,
                        flexDirection: "column",
                        position: "relative",
                        display: "flex",
                    }}
                >
                    <Alert
                        severity="success"
                        sx={{
                            position: "absolute",
                            zIndex: 100,
                            width: "100%",
                            marginTop: -10,
                            display: isRequestSuccess ? "flex" : "none",
                        }}
                    >
                        {requestSuccessMsg}
                    </Alert>
                    <Alert
                        severity="error"
                        sx={{
                            width: "100%",
                            marginTop: -10,
                            display: isRequestError ? "flex" : "none",
                        }}
                    >
                        {resErrorMsg}
                    </Alert>

                    <Box>

                        <Grid container spacing={2} sx={{ fontSize: 10 }}>
                            <Grid item lg={4}>
                                <FormControl className="textfield" fullWidth size="small">
                                    <InputLabel>RATE SOURCE</InputLabel>
                                    <Select
                                        className="field"
                                        sx={{ width: "100%" }}
                                        value={requestData.rate_source.type}
                                        label="RATE SOURCE"
                                        onChange={(e) => {
                                            const value = e.target.value;
                                            updateRequestData("rate_source>type", value);
                                        }}
                                    >
                                        <MenuItem value="compset">compset</MenuItem>
                                        <MenuItem value="tt">tt</MenuItem>
                                        <MenuItem value="di">di</MenuItem>
                                        <MenuItem value="pms">pms</MenuItem>
                                        <MenuItem value="revup">revup</MenuItem>
                                    </Select>
                                </FormControl>
                            </Grid>

                            {requestData.rate_source.type === 'compset' &&
                                <>
                                    <Grid item lg={4}>
                                        <FormControl className="textfield" fullWidth size="small">
                                            <InputLabel>SOURCE</InputLabel>
                                            <OutlinedInput
                                                label="SOURCE"
                                                value={requestData.rate_source.source}
                                                onChange={(e) => {
                                                    updateRequestData(
                                                        "rate_source>source",
                                                        e.currentTarget.value
                                                    );
                                                }}
                                            />
                                        </FormControl>
                                    </Grid>

                                    <Grid item lg={4}>
                                        {/* <FormControl className="textfield" fullWidth size="small">
                                            <InputLabel>RESOURCE IDS</InputLabel>
                                            <OutlinedInput
                                                label="RESOURCE IDS"
                                                value={requestData.rate_source.resource_id}
                                                onChange={(e) => {
                                                    updateRequestData(
                                                        "rate_source>resource_id",
                                                        e.currentTarget.value
                                                    );
                                                }}
                                            />
                                        </FormControl> */}
                                        <Autocomplete
                                            size="small"
                                            multiple
                                            options={[]}
                                            value={
                                                requestData.rate_source.resource_id
                                            }
                                            freeSolo
                                            onChange={(e, newVal) => {
                                                const value = newVal;
                                                updateRequestData(
                                                    "rate_source>resource_id",
                                                    value
                                                );
                                            }}
                                            renderTags={(value, getTagProps) =>
                                                value.map((option, index) => (
                                                    <Chip className="chip-close"
                                                        key={"id-" + index}
                                                        size="small"
                                                        variant="filled"
                                                        // color="primary"
                                                        label={option}
                                                        {...getTagProps({ index })}
                                                    />
                                                ))
                                            }
                                            renderInput={(params) => (
                                                <TextField
                                                    className="textfield"
                                                    {...params}
                                                    size="small"
                                                    variant="outlined"
                                                    label="RESOURCE IDS"
                                                    placeholder="type & press enter"
                                                />
                                            )}
                                        />

                                    </Grid>
                                </>
                            }

                            <Grid item lg={4}>
                                <FormControl className="textfield" fullWidth size="small">
                                    <InputLabel>DEFAULT RATE PLAN CODE</InputLabel>
                                    <OutlinedInput
                                        label="DEFAULT RATE PLAN CODE"
                                        value={requestData.rate_source.resource_lookup.rate_code}
                                        onChange={(e) => {
                                            updateRequestData(
                                                "rate_source>resource_lookup>rate_code",
                                                e.currentTarget.value
                                            );
                                        }}
                                    />
                                </FormControl>
                            </Grid>

                            <Grid item lg={4}>
                                <FormControl className="textfield" size="small" fullWidth>
                                    <InputLabel>DEFAULT MARKET CODE</InputLabel>
                                    <OutlinedInput
                                        className="input-text"
                                        label="DEFAULT MARKET CODE"
                                        value={requestData.default_rate_segment_code}
                                        onChange={(e) => {
                                            updateRequestData(
                                                "default_rate_segment_code",
                                                e.currentTarget.value
                                            );
                                        }}
                                    />
                                </FormControl>
                            </Grid>

                            <Grid item lg={4}>
                                <FormControl className="textfield" fullWidth size="small">
                                    <InputLabel>RATE PUSH MODE</InputLabel>
                                    <Select
                                        className="field"
                                        sx={{ width: "100%" }}
                                        label="RATE PUSH MODE"
                                        value={requestData.rate_push_type}
                                        onChange={(e) => {
                                            updateRequestData("rate_push_type", e.target.value);
                                        }}
                                    >
                                        <MenuItem value="default">Default</MenuItem>
                                        <MenuItem value="v2">V2</MenuItem>
                                    </Select>
                                </FormControl>
                            </Grid>
                            <Grid item lg={4}>
                                <FormControl className="textfield"
                                    fullWidth
                                    size="small"
                                    helperText="Enter comma seprated property IDs"
                                >
                                    <InputLabel>RTP SETTINGS</InputLabel>
                                    <Select
                                        className="field"
                                        disabled
                                        sx={{ width: "100%" }}
                                        label="RTP SETTINGS"
                                        value={requestData.rtp_grid_option_type}
                                        onChange={(e) => {
                                            updateRequestData("rtp_grid_option_type", e.target.value);
                                        }}
                                    >
                                        <MenuItem value="default">Default</MenuItem>
                                        <MenuItem value="avail_ldiff_currentrate">
                                            Availability, LodgIQ Diff, Current Rate
                                        </MenuItem>
                                        <MenuItem value="ldiff_currentrate">
                                            LodgIQ Diff, Current Rate
                                        </MenuItem>
                                        <MenuItem value="currentdiff_currentrate">
                                            Current Diff, Current Rate
                                        </MenuItem>
                                        <MenuItem value="ldiff_currentdiff_currentrate">
                                            LodgIQ Diff, Current Diff, Current Rate
                                        </MenuItem>
                                        <MenuItem value="currentrate">Current Rate</MenuItem>
                                    </Select>
                                </FormControl>
                            </Grid>
                        </Grid>

                    </Box>

                    {isrequesting && (
                        <Box
                            sx={{
                                textAlighn: "center",
                                backgroundColor: "dark.darkest",
                                opacity: 0.8,
                                position: "fixed",
                                top: 40,
                                left: 0,
                                width: "100%",
                                height: "100%",
                            }}
                        >
                            <CircularProgress
                                size={40}
                                sx={{
                                    color: "white",
                                    position: "absolute",
                                    top: "50%",
                                    left: "50%",
                                    marginTop: "-20px",
                                    marginLeft: "-20px",
                                }}
                            />
                        </Box>
                    )}
                </Box>
            </Box>
        </div>
    );
}

function SegmentsSettings(props) {
    const dispatch = useDispatch();

    const [isrequesting, setIsRequesting] = useState(false);
    const [resError, setResError] = useState(false);
    const [isRequestSuccess, setIsRequestSuccess] = useState(false);
    const [requestSuccessMsg, setRequestSuccessMsg] = useState(
        "Your change request for inventory settings was successfull!"
    );
    const [isRequestError, setIsRequestError] = useState(false);
    const [resErrorMsg, setResErrorMsg] = useState("");

    const { app, auth } = useSelector((state) => state);
    const { user, profile } = auth;
    const { app_state, ui_state } = app.current_state;
    const { property_details } = auth.profile || [];
    //Data source information
    const [dsInfo, setDsInfo] = useState(getDataSources(property_details, app_state.property_id));
    let prop_details_index = 0;
    const prop_details = property_details.filter((item, index) => {
        if (item.id === app_state.property_id) {
            prop_details_index = index;
            return item.id === app_state.property_id;
        }
        //
    });

    const { system_settings } =
        prop_details && prop_details.length ? prop_details[0] : null;
    const market_segment_category_mapping = JSON.parse(
        JSON.stringify(system_settings[0].market_segment_category_mapping)
    );
    const market_segment_category_order = JSON.parse(
        JSON.stringify(system_settings[0].market_segment_category_order)
    );
    let market_code_ignorelist = JSON.parse(
        JSON.stringify(system_settings[0].market_code_ignorelist)
    );
    //Handling market_code_ignorelist in case its coming wrong from API
    market_code_ignorelist = market_code_ignorelist && market_code_ignorelist[0] !== "{NULL}" ? market_code_ignorelist : [];

    const lvl1_mseg_grouping = JSON.parse(
        JSON.stringify(system_settings[0].lvl1_mseg_grouping)
    );
    const topSegments = {};
    // debugger;
    let Transient = lvl1_mseg_grouping.filter((item) => item.Transient);
    let Group = lvl1_mseg_grouping.filter((item) => item.Group);
    let Contract = lvl1_mseg_grouping.filter((item) => item.Contract);
    topSegments.Transient = Transient.length ? Transient[0].Transient : [];
    topSegments.Group = Group.length ? Group[0].Group : [];
    topSegments.Contract = Contract.length ? Contract[0].Contract : [];

    // debugger;
    const settings_ = JSON.parse(
        JSON.stringify({
            market_segment_category_mapping: market_segment_category_mapping,
            market_segment_category_order: market_segment_category_order,
            market_code_ignorelist: market_code_ignorelist,
            topSegments: topSegments,
            lvl1_mseg_grouping: lvl1_mseg_grouping,
        })
    );
    const settings = JSON.parse(
        JSON.stringify({
            market_segment_category_mapping:
                system_settings[0].market_segment_category_mapping,
            market_segment_category_order:
                system_settings[0].market_segment_category_order,
            market_code_ignorelist: market_code_ignorelist,
            topSegments: topSegments,
            lvl1_mseg_grouping: lvl1_mseg_grouping,
        })
    );




    //const settings = JSON.parse(JSON.stringify(system_settings[0].market_segment_category_mapping));
    const [requestData, setRequestData] = useState(settings);
    const [isChangeRequest, setIsChangeRequest] = useState(false);

    // debugger; 
    const [ignoreCodes, setIgnoreCodes] = useState([]);
    useEffect(() => {
        requestData.market_segment_category_order.map((item, index) => {
            requestData.market_segment_category_mapping[item].map((itm, idx) => {
                if (ignoreCodes.indexOf(itm) === -1) {
                    ignoreCodes.push(itm);
                }
            });
        });
        // debugger;
        // setRequestData({ ...requestData });
        setIgnoreCodes([...ignoreCodes]);
    }, []);

    const cancelSave = () => {
        setRequestData(settings_);
        setIsChangeRequest(false);
    };


    const handleDelete = () => {
        console.info('You clicked the delete icon.');
    };

    const updateRequestData = (keys, val, index, item) => {
        const updatedObj = { ...requestData };
        const key = keys.split(">");
        // const originalSettings = settings_;
        // debugger;
        if (key[0] === 'market_segment_category_mapping') {
            const size = updatedObj[key[0]][key[1]].length;
            updatedObj[key[0]][key[1]].splice(0, size);
            updatedObj[key[0]][key[1]] = [...val];
        } else
            if (key[0] == "lvl1_mseg_grouping") {
                updatedObj.topSegments[key[1]] = val;
            } else
                if (key[0] == "market_segment_category_order") {
                    updatedObj.market_segment_category_order[index] = val;
                    let temp = updatedObj.market_segment_category_mapping[item];
                    updatedObj.market_segment_category_mapping[val] = temp;
                    if (updatedObj.topSegments.Transient.indexOf(item) !== -1) {
                        let i = updatedObj.topSegments.Transient.indexOf(item);
                        updatedObj.topSegments.Transient.splice(i, 1, val);
                    }
                    if (updatedObj.topSegments.Group.indexOf(item) !== -1) {
                        let i = updatedObj.topSegments.Group.indexOf(item);
                        updatedObj.topSegments.Group.splice(i, 1, val);
                    }
                    if (updatedObj.topSegments.Contract.indexOf(item) !== -1) {
                        let i = updatedObj.topSegments.Contract.indexOf(item);
                        updatedObj.topSegments.Contract.splice(i, 1, val);
                    }
                    delete updatedObj.market_segment_category_mapping[item];

                    //debugger;
                    // let size = val.length;
                    // let l = updatedObj.market_segment_category_order[key[1]].length;
                    // updatedObj.market_segment_category_order[key[1]].splice(0, l);
                    // val.map((item, i) => {
                    //   updatedObj.market_segment_category_order[key[1]].push(item);
                    // });
                } else if (key[0] == "market_code_ignorelist") {
                    // updatedObj.market_code_ignorelist = val;
                    let size = val.length;

                    let l = updatedObj.market_code_ignorelist.length;
                    updatedObj.market_code_ignorelist.splice(0, l);
                    val.map((item, i) => {
                        updatedObj.market_code_ignorelist.push(item);
                    });
                } else {
                    let finalVal;
                    if (typeof val === "string" && val !== "") {
                        // finalVal = val.split(",");
                        finalVal = val;
                        // finalVal.map((item, index) => {
                        //   finalVal[index] = finalVal[index].trim();
                        // });
                        //updatedObj[key[0]][key[1]].push(finalVal);
                    } else {
                        //updatedObj[key[0]][key[1]].pop();
                    }
                    //updatedObj[key[0]][key[1]] = finalVal;
                }
        //  debugger;
        setRequestData({ ...requestData, ...updatedObj });
        setIsChangeRequest(true);
        //debugger;
    };
    const deleteMarketSegment = (index) => {

        const segment = requestData.market_segment_category_order[index];
        requestData.market_segment_category_order.splice(index, 1);
        delete requestData.market_segment_category_mapping[segment];
        // debugger;
        //Removing from top segment
        if (requestData.topSegments.Group.indexOf(segment) !== -1) {
            let i = requestData.topSegments.Group.indexOf(segment);
            requestData.topSegments.Group.splice(i, 1);
        }
        if (requestData.topSegments.Contract.indexOf(segment) !== -1) {
            let i = requestData.topSegments.Contract.indexOf(segment);
            requestData.topSegments.Contract.splice(i, 1);
        }
        if (requestData.topSegments.Transient.indexOf(segment) !== -1) {
            let i = requestData.topSegments.Transient.indexOf(segment);
            requestData.topSegments.Transient.splice(i, 1);
        }

        setRequestData({
            ...requestData,
            market_segment_category_order: requestData.market_segment_category_order,
            market_segment_category_mapping: requestData.market_segment_category_mapping
        });
        setIsChangeRequest(true);
    };
    const changeSegmentsOrder = (item, index, type) => {
        //debugger;
        const tempObj = item;
        const size = requestData.market_segment_category_order.length;
        if (type == "up" && index) {
            requestData.market_segment_category_order[index] =
                requestData.market_segment_category_order[index - 1];
            requestData.market_segment_category_order[index - 1] = item;
            setRequestData({
                ...requestData,
                market_segment_category_order:
                    requestData.market_segment_category_order,
            });
            setIsChangeRequest(true);
        }
        if (type == "down" && index < size) {
            requestData.current.room_settings[index] =
                requestData.current.room_settings[index + 1];
            requestData.current.room_settings[index + 1] = item;
            setRequestData({ ...requestData, current: requestData.current });
            setIsChangeRequest(true);
        }
    };
    const addNewSegmentMap = (e, value) => {
        const newSegment = "new" + (requestData.market_segment_category_order.length + 1);
        requestData.market_segment_category_order.push(newSegment);
        requestData.market_segment_category_mapping[newSegment] = [];

        setRequestData({
            ...requestData,
            market_segment_category_mapping:
                requestData.market_segment_category_mapping,
            market_segment_category_order: requestData.market_segment_category_order,
        });
        setIsChangeRequest(true);
    };

    const saveChanges = (event) => {
        setIsRequesting(true);
        let lvl1_mseg_grouping = [];
        lvl1_mseg_grouping.push({ Transient: requestData.topSegments.Transient });
        lvl1_mseg_grouping.push({ Group: requestData.topSegments.Group });
        lvl1_mseg_grouping.push({ Contract: requestData.topSegments.Contract });

        const request_system_settings = {
            ...system_settings[0],
            market_segment_category_mapping:
                requestData.market_segment_category_mapping,
            market_segment_category_order: requestData.market_segment_category_order,
            market_code_ignorelist: requestData.market_code_ignorelist,
            lvl1_mseg_grouping: lvl1_mseg_grouping,
        };
        // debugger;
        let payload = {
            "resource_id": app_state.property_id,
            "resource_type": "property",
            "update_type": "full_system_settings",
            "data": {
                "full_ss": request_system_settings
            }
        };

        let ss_api_config = deepCopy(API_REGISTRY['SaveSystemSettings']);
        let active_ds_id = dsInfo.active_source.id;
        ss_api_config.uri = ss_api_config.uri + app_state.property_id + '/' + active_ds_id + '/';

        dataService
            .update({ api: ss_api_config.uri, request: { ...ss_api_config.payload, ...payload } })
            .then((data) => {
                //Do some task here just after respons
                if (data.status === "success") {
                    setIsRequestSuccess(true);
                    setIsRequesting(false);
                    setIsChangeRequest(false);
                    setTimeout(() => {
                        profile.property_details[prop_details_index].system_settings[0] =
                            request_system_settings;
                        dispatch({ type: SYSTEM_SETTINGS_UPDATED, payload: profile });
                    }, 500);
                    setTimeout(() => {
                        setIsRequestSuccess(false);
                    }, 5000);
                } else {
                    setIsRequestError(true);
                    setIsRequesting(false);
                    setResErrorMsg(data.message);
                    //return Promise.reject(data);
                }
            })
            .catch((err) => {
                setIsRequesting(false);
                setIsRequestError(true);
                setResErrorMsg(
                    (err && err.original && err.original.statusText) ||
                    "Something went wrong, please try again later!"
                );
                setTimeout(() => {
                    setIsRequestError(false);
                }, 5000);
                //return Promise.reject(err);
            });
    };
    function TabPanel(props) {
        const { children, value, index, ...other } = props;

        return (
            <div
                role="tabpanel"
                hidden={value !== index}
                id={`simple-tabpanel-${index}`}
                aria-labelledby={`simple-tab-${index}`}
                {...other}
            >
                {value === index && (
                    <Box sx={{ p: 3 }}>
                        <Typography>{children}</Typography>
                    </Box>
                )}
            </div>
        );
    }
    TabPanel.propTypes = {
        children: PropTypes.node,
        index: PropTypes.number.isRequired,
        value: PropTypes.number.isRequired,
    };
    const [value, setValue] = React.useState(0);

    const handleChange = (event, newValue) => {
        setValue(newValue);
    };


    return (
        <div style={{ maxWidth: "unset" }}>
            <Box className='breadcrumb' sx={{ color: 'white.main', position: 'absolute', top: -40 }}>
                <span className="breadcrum-inactive">Settings</span>
                <ArrowForwardIosOutlinedIcon className="breadcrum-inactive"
                    sx={{ top: 3, position: "relative", height: 18 }}
                ></ArrowForwardIosOutlinedIcon>
                <span className="breadcrum-inactive">Property Settings</span>
                <ArrowForwardIosOutlinedIcon className="breadcrum-inactive"
                    sx={{ top: 3, position: "relative", height: 18 }}
                ></ArrowForwardIosOutlinedIcon>
                <span className="breadcrum-active">Segments</span>
            </Box>
            <div className="property-setting-heading">
                <Typography
                    className="property-setting-main-heading"
                    // variant="h1"
                    gutterBottom
                    sx={{ mr: 3, display: { lg: "inline-block", xs: "none" } }}
                >
                    Segments
                </Typography>
                <Box sx={{ float: "right" }}>
                    <Button
                        disabled={!isChangeRequest}
                        onClick={() => {
                            saveChanges();
                        }}
                        variant="outlined"
                        size="small"
                        className={(isChangeRequest) ? "navigate-button btn-active" : "navigate-button"}
                        // color="green"
                        sx={{ minWidth: "auto", mr: 1, color: "white.main" }}
                    >
                        <span style={{ display: !isrequesting ? "block" : "none" }}>
                            Save
                        </span>
                        <span
                            style={{
                                display: isrequesting ? "block" : "none",
                                paddingRight: 10,
                            }}
                        >
                            saving...
                        </span>
                        <CircularProgress
                            sx={{ display: isrequesting ? "block" : "none" }}
                            color="white"
                            size="16px"
                        />
                    </Button>
                    <Button
                        disabled={!isChangeRequest}
                        onClick={cancelSave}
                        variant="outlined"
                        className={(isChangeRequest) ? "navigate-button btn-active" : "navigate-button"}
                        // color="error"
                        size="small"
                        sx={{ minWidth: "auto" }}
                    >
                        cancel
                    </Button>
                </Box>
                <Divider sx={{ clear: "both", mt: { xs: 2, lg: 0 } }} />
            </div>
            <Box className="inventory-settings" sx={{ p: 1, pt: 0, mb: 2, mt: 0, ml: 1, mr: 1 }}>

                {/* <Divider sx={{ clear: "both", mt: { xs: 2, lg: 0 } }} /> */}

                <Box
                    sx={{
                        pt: 2,
                        flexDirection: "column",
                        position: "relative",
                        display: "flex",
                    }}
                >
                    <Alert
                        severity="success"
                        sx={{
                            width: "100%",
                            marginTop: -10,
                            display: isRequestSuccess ? "flex" : "none",
                        }}
                    >
                        {requestSuccessMsg}
                    </Alert>
                    <Alert
                        severity="error"
                        sx={{
                            width: "100%",
                            marginTop: -10,
                            display: isRequestError ? "flex" : "none",
                        }}
                    >
                        {resErrorMsg}
                    </Alert>
                    {/* HIDDEN FOR FUTURE USE */}
                    <Box sx={{ display: 'none' }} className="rate-plans-tab">
                        <Tabs value={value} onChange={handleChange} aria-label="basic tabs example">
                            <Tab className="tab-bg" label="TOP SEGMENTS" />
                            <Tab className="tab-bg" label="SEGMENTS" />
                            <Tab className="tab-bg" label="RATE PLANS" />
                        </Tabs>
                    </Box>

                    <Box sx={{ mt: { lg: 0 }, maxHeight: "74vh", overflowY: "auto" }}>

                        {/* mani work start for Rate Plan*/}
                        {/* HIDDEN FOR FUTURE USE */}
                        <Box sx={{ flexGrow: 1, display: 'none' }}>
                            <Grid container spacing={0}>

                                <Grid item xs={12}>
                                    {/* <Grid container spacing={2} fullWidth className="save-cancel">
                    <Grid item xs={10}></Grid>
                    <Grid item xs={1}><Button size="small" variant="outlined">
                      Save
                    </Button></Grid>
                    <Grid item xs={1}><Button size="small" variant="outlined">
                      Cancel
                    </Button></Grid>
                  </Grid> */}

                                    <Box sx={{ width: '100%' }}>

                                        <TabPanel value={value} index={0}>
                                            <Grid className="reports-right-container" >
                                                <Grid className=" unmapped-segements" >
                                                    <div className="heaading">unmapped segments</div>
                                                    <div>

                                                        <Chip className="schedule-chip" label="Comp/House" variant="outlined" />

                                                    </div>

                                                </Grid>
                                                <Grid container spacing={0}>
                                                    <Grid item xs={11.3}> <Grid className="segement-container" >
                                                        <div className="heaading">Transient</div>
                                                        <div>
                                                            <Chip className="property-schedule-chip" label="Package" variant="outlined" onDelete={handleDelete} />
                                                            <Chip className="property-schedule-chip" label="Discount" variant="outlined" onDelete={handleDelete} />
                                                            <Chip className="property-schedule-chip" label="Transient/Other" variant="outlined" onDelete={handleDelete} />
                                                            <Chip className="property-schedule-chip" label="Corporate" variant="outlined" onDelete={handleDelete} />
                                                            <Chip className="property-schedule-chip" label="BAR" variant="outlined" onDelete={handleDelete} />
                                                            <Chip className="property-schedule-chip" label="OTA" variant="outlined" onDelete={handleDelete} />
                                                        </div>

                                                    </Grid></Grid>
                                                    <Grid item xs={.7}>
                                                        <Grid container className="rateplan-button" columnSpacing={0} sx={{ order: { xs: 1, sm: 2 } }}>
                                                            <Grid item>
                                                                <KeyboardArrowUpOutlinedIcon className="inside-line-rateplan expend-arrow" sx={{ fontSize: 30 }}></KeyboardArrowUpOutlinedIcon>

                                                            </Grid>
                                                            <Grid item>
                                                                <KeyboardArrowDownOutlinedIcon color="primary" className="inside-line-rateplan  expend-arrow" sx={{ fontSize: 30 }}></KeyboardArrowDownOutlinedIcon>
                                                            </Grid>
                                                            <Grid item>

                                                                <DeleteOutlineIcon className="delete-button inside-line-rateplan" sx={{ fontSize: 20 }}></DeleteOutlineIcon>
                                                            </Grid>
                                                        </Grid>


                                                    </Grid>
                                                </Grid>
                                                <Grid container spacing={0}>
                                                    <Grid item xs={11.3}> <Grid className="segement-container" >
                                                        <div className="heaading">Group</div>
                                                        <div>
                                                            <Chip className="property-schedule-chip" label="Group" variant="outlined" onDelete={handleDelete} />

                                                        </div>

                                                    </Grid></Grid>
                                                    <Grid item xs={.7}>
                                                        <Grid container className="rateplan-button" columnSpacing={0} sx={{ order: { xs: 1, sm: 2 } }}>
                                                            <Grid item>
                                                                <KeyboardArrowUpOutlinedIcon className="inside-line-rateplan expend-arrow" sx={{ fontSize: 30 }}></KeyboardArrowUpOutlinedIcon>

                                                            </Grid>
                                                            <Grid item>
                                                                <KeyboardArrowDownOutlinedIcon color="primary" className="inside-line-rateplan  expend-arrow" sx={{ fontSize: 30 }}></KeyboardArrowDownOutlinedIcon>
                                                            </Grid>
                                                            <Grid item>

                                                                <DeleteOutlineIcon className="delete-button inside-line-rateplan" sx={{ fontSize: 20 }}></DeleteOutlineIcon>
                                                            </Grid>
                                                        </Grid>


                                                    </Grid>
                                                </Grid>
                                                <Grid container spacing={0}>
                                                    <Grid item xs={11.3}> <Grid className="segement-container" >
                                                        <div className="heaading">Contract</div>

                                                        <div>
                                                            <Chip className="property-schedule-chip" label="Wholesale" variant="outlined" onDelete={handleDelete} />
                                                            <Chip className="property-schedule-chip" label="Consortia" variant="outlined" onDelete={handleDelete} />

                                                        </div>

                                                    </Grid></Grid>
                                                    <Grid item xs={.7}>
                                                        <Grid container className="rateplan-button" columnSpacing={0} sx={{ order: { xs: 1, sm: 2 } }}>
                                                            <Grid item>
                                                                <KeyboardArrowUpOutlinedIcon className="inside-line-rateplan expend-arrow" sx={{ fontSize: 30 }}></KeyboardArrowUpOutlinedIcon>

                                                            </Grid>
                                                            <Grid item>
                                                                <KeyboardArrowDownOutlinedIcon color="primary" className="inside-line-rateplan  expend-arrow" sx={{ fontSize: 30 }}></KeyboardArrowDownOutlinedIcon>
                                                            </Grid>
                                                            <Grid item>

                                                                <DeleteOutlineIcon className="delete-button inside-line-rateplan" sx={{ fontSize: 20 }}></DeleteOutlineIcon>
                                                            </Grid>
                                                        </Grid>
                                                    </Grid>
                                                </Grid>

                                            </Grid>
                                        </TabPanel>
                                        <TabPanel value={value} index={1}>
                                            <Grid className="reports-right-container" >
                                                <Grid className="unmapped-segements" >
                                                    <div className="heaading">unmapped Segment codes</div>
                                                    <div>
                                                        <Chip className="schedule-chip" label="ccon" variant="outlined" />
                                                    </div>
                                                </Grid>
                                                <Grid container spacing={0}>
                                                    <Grid item xs={11.3}> <Grid className="segement-container" >
                                                        <div className="heaading">Wholesale</div>
                                                        <div>
                                                            <Chip className="property-schedule-chip" label="TIFT" variant="outlined" onDelete={handleDelete} />

                                                        </div>

                                                    </Grid></Grid>
                                                    <Grid item xs={.7}>
                                                        <Grid container className="rateplan-button" columnSpacing={0} sx={{ order: { xs: 1, sm: 2 } }}>
                                                            <Grid item>
                                                                <KeyboardArrowUpOutlinedIcon className="inside-line-rateplan expend-arrow" sx={{ fontSize: 30 }}></KeyboardArrowUpOutlinedIcon>

                                                            </Grid>
                                                            <Grid item>
                                                                <KeyboardArrowDownOutlinedIcon color="primary" className="inside-line-rateplan  expend-arrow" sx={{ fontSize: 30 }}></KeyboardArrowDownOutlinedIcon>
                                                            </Grid>
                                                            <Grid item>

                                                                <DeleteOutlineIcon className="delete-button inside-line-rateplan" sx={{ fontSize: 20 }}></DeleteOutlineIcon>
                                                            </Grid>
                                                        </Grid>


                                                    </Grid>
                                                </Grid>
                                                <Grid container spacing={0}>
                                                    <Grid item xs={11.3}> <Grid className="segement-container" >
                                                        <div className="heaading">Consortia</div>
                                                        <div>
                                                            <Chip className="property-schedule-chip" label="TCON" variant="outlined" onDelete={handleDelete} />
                                                        </div>

                                                    </Grid></Grid>
                                                    <Grid item xs={.7}>
                                                        <Grid container className="rateplan-button" columnSpacing={0} sx={{ order: { xs: 1, sm: 2 } }}>
                                                            <Grid item>
                                                                <KeyboardArrowUpOutlinedIcon className="inside-line-rateplan expend-arrow" sx={{ fontSize: 30 }}></KeyboardArrowUpOutlinedIcon>

                                                            </Grid>
                                                            <Grid item>
                                                                <KeyboardArrowDownOutlinedIcon color="primary" className="inside-line-rateplan  expend-arrow" sx={{ fontSize: 30 }}></KeyboardArrowDownOutlinedIcon>
                                                            </Grid>
                                                            <Grid item>

                                                                <DeleteOutlineIcon className="delete-button inside-line-rateplan" sx={{ fontSize: 20 }}></DeleteOutlineIcon>
                                                            </Grid>
                                                        </Grid>


                                                    </Grid>
                                                </Grid>
                                                <Grid container spacing={0}>
                                                    <Grid item xs={11.3}> <Grid className="segement-container" >
                                                        <div className="heaading">Package</div>
                                                        <div>
                                                            <Chip className="property-schedule-chip" label="TPKG" variant="outlined" onDelete={handleDelete} />
                                                        </div>

                                                    </Grid></Grid>
                                                    <Grid item xs={.7}>
                                                        <Grid container className="rateplan-button" columnSpacing={0} sx={{ order: { xs: 1, sm: 2 } }}>
                                                            <Grid item>
                                                                <KeyboardArrowUpOutlinedIcon className="inside-line-rateplan expend-arrow" sx={{ fontSize: 30 }}></KeyboardArrowUpOutlinedIcon>

                                                            </Grid>
                                                            <Grid item>
                                                                <KeyboardArrowDownOutlinedIcon color="primary" className="inside-line-rateplan  expend-arrow" sx={{ fontSize: 30 }}></KeyboardArrowDownOutlinedIcon>
                                                            </Grid>
                                                            <Grid item>

                                                                <DeleteOutlineIcon className="delete-button inside-line-rateplan" sx={{ fontSize: 20 }}></DeleteOutlineIcon>
                                                            </Grid>
                                                        </Grid>


                                                    </Grid>
                                                </Grid>
                                                <Grid container spacing={0}>
                                                    <Grid item xs={11.3}> <Grid className="segement-container" >
                                                        <div className="heaading">Discount</div>
                                                        <div>
                                                            <Chip className="property-schedule-chip" label="TDIS" variant="outlined" onDelete={handleDelete} />
                                                        </div>

                                                    </Grid></Grid>
                                                    <Grid item xs={.7}>
                                                        <Grid container className="rateplan-button" columnSpacing={0} sx={{ order: { xs: 1, sm: 2 } }}>
                                                            <Grid item>
                                                                <KeyboardArrowUpOutlinedIcon className="inside-line-rateplan expend-arrow" sx={{ fontSize: 30 }}></KeyboardArrowUpOutlinedIcon>

                                                            </Grid>
                                                            <Grid item>
                                                                <KeyboardArrowDownOutlinedIcon color="primary" className="inside-line-rateplan  expend-arrow" sx={{ fontSize: 30 }}></KeyboardArrowDownOutlinedIcon>
                                                            </Grid>
                                                            <Grid item>

                                                                <DeleteOutlineIcon className="delete-button inside-line-rateplan" sx={{ fontSize: 20 }}></DeleteOutlineIcon>
                                                            </Grid>
                                                        </Grid>


                                                    </Grid>
                                                </Grid>
                                                <Grid container spacing={0}>
                                                    <Grid item xs={11.3}> <Grid className="segement-container" >
                                                        <div className="heaading">Comp/House</div>
                                                        <div>
                                                            <Chip className="property-schedule-chip" label="COMP" variant="outlined" onDelete={handleDelete} />
                                                            <Chip className="property-schedule-chip" label="HUSE" variant="outlined" onDelete={handleDelete} />
                                                        </div>

                                                    </Grid></Grid>
                                                    <Grid item xs={.7}>
                                                        <Grid container className="rateplan-button" columnSpacing={0} sx={{ order: { xs: 1, sm: 2 } }}>
                                                            <Grid item>
                                                                <KeyboardArrowUpOutlinedIcon className="inside-line-rateplan expend-arrow" sx={{ fontSize: 30 }}></KeyboardArrowUpOutlinedIcon>

                                                            </Grid>
                                                            <Grid item>
                                                                <KeyboardArrowDownOutlinedIcon color="primary" className="inside-line-rateplan  expend-arrow" sx={{ fontSize: 30 }}></KeyboardArrowDownOutlinedIcon>
                                                            </Grid>
                                                            <Grid item>

                                                                <DeleteOutlineIcon className="delete-button inside-line-rateplan" sx={{ fontSize: 20 }}></DeleteOutlineIcon>
                                                            </Grid>
                                                        </Grid>


                                                    </Grid>
                                                </Grid>
                                                <Grid container spacing={0}>
                                                    <Grid item xs={11.3}> <Grid className="segement-container" >
                                                        <div className="heaading">Transient/Other</div>
                                                        <div>
                                                            <Chip className="property-schedule-chip" label="CAIR" variant="outlined" onDelete={handleDelete} />
                                                            <Chip className="property-schedule-chip" label="COTH" variant="outlined" onDelete={handleDelete} />
                                                            <Chip className="property-schedule-chip" label="TGOV" variant="outlined" onDelete={handleDelete} />
                                                            <Chip className="property-schedule-chip" label="TOTH" variant="outlined" onDelete={handleDelete} />
                                                            <Chip className="property-schedule-chip" label="TPRM" variant="outlined" onDelete={handleDelete} />
                                                        </div>

                                                    </Grid></Grid>
                                                    <Grid item xs={.7}>
                                                        <Grid container className="rateplan-button" columnSpacing={0} sx={{ order: { xs: 1, sm: 2 } }}>
                                                            <Grid item>
                                                                <KeyboardArrowUpOutlinedIcon className="inside-line-rateplan expend-arrow" sx={{ fontSize: 30 }}></KeyboardArrowUpOutlinedIcon>

                                                            </Grid>
                                                            <Grid item>
                                                                <KeyboardArrowDownOutlinedIcon color="primary" className="inside-line-rateplan  expend-arrow" sx={{ fontSize: 30 }}></KeyboardArrowDownOutlinedIcon>
                                                            </Grid>
                                                            <Grid item>

                                                                <DeleteOutlineIcon className="delete-button inside-line-rateplan" sx={{ fontSize: 20 }}></DeleteOutlineIcon>
                                                            </Grid>
                                                        </Grid>


                                                    </Grid>
                                                </Grid>
                                                <Grid container spacing={0}>
                                                    <Grid item xs={11.3}> <Grid className="segement-container" >
                                                        <div className="heaading">Corporate</div>
                                                        <div>
                                                            <Chip className="property-schedule-chip" label="TPRC" variant="outlined" onDelete={handleDelete} />
                                                            <Chip className="property-schedule-chip" label="CCOR" variant="outlined" onDelete={handleDelete} />
                                                        </div>

                                                    </Grid></Grid>
                                                    <Grid item xs={.7}>
                                                        <Grid container className="rateplan-button" columnSpacing={0} sx={{ order: { xs: 1, sm: 2 } }}>
                                                            <Grid item>
                                                                <KeyboardArrowUpOutlinedIcon className="inside-line-rateplan expend-arrow" sx={{ fontSize: 30 }}></KeyboardArrowUpOutlinedIcon>

                                                            </Grid>
                                                            <Grid item>
                                                                <KeyboardArrowDownOutlinedIcon color="primary" className="inside-line-rateplan  expend-arrow" sx={{ fontSize: 30 }}></KeyboardArrowDownOutlinedIcon>
                                                            </Grid>
                                                            <Grid item>

                                                                <DeleteOutlineIcon className="delete-button inside-line-rateplan" sx={{ fontSize: 20 }}></DeleteOutlineIcon>
                                                            </Grid>
                                                        </Grid>


                                                    </Grid>
                                                </Grid>
                                                <Grid container spacing={0}>
                                                    <Grid item xs={11.3}> <Grid className="segement-container">
                                                        <div className="heaading">BAR</div>
                                                        <div>
                                                            <Chip className="property-schedule-chip" label="TBAR" variant="outlined" onDelete={handleDelete} />
                                                        </div>

                                                    </Grid></Grid>
                                                    <Grid item xs={.7}>
                                                        <Grid container className="rateplan-button" columnSpacing={0} sx={{ order: { xs: 1, sm: 2 } }}>
                                                            <Grid item>
                                                                <KeyboardArrowUpOutlinedIcon className="inside-line-rateplan expend-arrow" sx={{ fontSize: 30 }}></KeyboardArrowUpOutlinedIcon>

                                                            </Grid>
                                                            <Grid item>
                                                                <KeyboardArrowDownOutlinedIcon color="primary" className="inside-line-rateplan  expend-arrow" sx={{ fontSize: 30 }}></KeyboardArrowDownOutlinedIcon>
                                                            </Grid>
                                                            <Grid item>

                                                                <DeleteOutlineIcon className="delete-button inside-line-rateplan" sx={{ fontSize: 20 }}></DeleteOutlineIcon>
                                                            </Grid>
                                                        </Grid>


                                                    </Grid>
                                                </Grid>
                                                <Grid container spacing={0}>
                                                    <Grid item xs={11.3}> <Grid className="segement-container" >
                                                        <div className="heaading">OTA</div>
                                                        <div>
                                                            <Chip className="property-schedule-chip" label="TNOP" variant="outlined" onDelete={handleDelete} />
                                                            <Chip className="property-schedule-chip" label="TOPA" variant="outlined" onDelete={handleDelete} />
                                                        </div>

                                                    </Grid></Grid>
                                                    <Grid item xs={.7}>
                                                        <Grid container className="rateplan-button" columnSpacing={0} sx={{ order: { xs: 1, sm: 2 } }}>
                                                            <Grid item>
                                                                <KeyboardArrowUpOutlinedIcon className="inside-line-rateplan expend-arrow" sx={{ fontSize: 30 }}></KeyboardArrowUpOutlinedIcon>

                                                            </Grid>
                                                            <Grid item>
                                                                <KeyboardArrowDownOutlinedIcon color="primary" className="inside-line-rateplan  expend-arrow" sx={{ fontSize: 30 }}></KeyboardArrowDownOutlinedIcon>
                                                            </Grid>
                                                            <Grid item>

                                                                <DeleteOutlineIcon className="delete-button inside-line-rateplan" sx={{ fontSize: 20 }}></DeleteOutlineIcon>
                                                            </Grid>
                                                        </Grid>


                                                    </Grid>
                                                </Grid>
                                                <Grid container spacing={0}>
                                                    <Grid item xs={11.3}> <Grid className="segement-container" >
                                                        <div className="heaading">Group</div>
                                                        <div>
                                                            <Chip className="property-schedule-chip" label="GASC" variant="outlined" onDelete={handleDelete} />
                                                            <Chip className="property-schedule-chip" label="GCOR" variant="outlined" onDelete={handleDelete} />
                                                            <Chip className="property-schedule-chip" label="GGOV" variant="outlined" onDelete={handleDelete} />
                                                            <Chip className="property-schedule-chip" label="GOTH" variant="outlined" onDelete={handleDelete} />
                                                            <Chip className="property-schedule-chip" label="GSMF" variant="outlined" onDelete={handleDelete} />
                                                            <Chip className="property-schedule-chip" label="GTRT" variant="outlined" onDelete={handleDelete} />
                                                        </div>

                                                    </Grid></Grid>
                                                    <Grid item xs={.7}>
                                                        <Grid container className="rateplan-button" columnSpacing={0} sx={{ order: { xs: 1, sm: 2 } }}>
                                                            <Grid item>
                                                                <KeyboardArrowUpOutlinedIcon className="inside-line-rateplan expend-arrow" sx={{ fontSize: 30 }}></KeyboardArrowUpOutlinedIcon>

                                                            </Grid>
                                                            <Grid item>
                                                                <KeyboardArrowDownOutlinedIcon color="primary" className="inside-line-rateplan  expend-arrow" sx={{ fontSize: 30 }}></KeyboardArrowDownOutlinedIcon>
                                                            </Grid>
                                                            <Grid item>

                                                                <DeleteOutlineIcon className="delete-button inside-line-rateplan" sx={{ fontSize: 20 }}></DeleteOutlineIcon>
                                                            </Grid>
                                                        </Grid>


                                                    </Grid>
                                                </Grid>
                                                <Grid container spacing={0}>
                                                    <Grid item xs={12}>
                                                        <Grid >
                                                            <div className="heaading"><Button className="navigate-button" variant="outlined">ADD A SEGMENT</Button></div>

                                                        </Grid></Grid>

                                                </Grid>

                                            </Grid>
                                        </TabPanel>
                                        <TabPanel value={value} index={2}>
                                            <Grid className="reports-right-container" >
                                                <Grid className="unmapped-segements" >
                                                    <div className="heaading">unmapped rate plan codes</div>
                                                    <div>
                                                        <Chip className="schedule-chip" label="RP12" variant="outlined" />
                                                    </div>

                                                </Grid>
                                                <Grid container spacing={0}>
                                                    <Grid item xs={11.3}> <Grid className="segement-container" >
                                                        <div className="heaading">TPKG</div>
                                                        <div>
                                                            <Chip className="property-schedule-chip" label="Rp1" variant="outlined" onDelete={handleDelete} />
                                                            <Chip className="property-schedule-chip" label="RP2" variant="outlined" onDelete={handleDelete} />
                                                            <Chip className="property-schedule-chip" label="RP3" variant="outlined" onDelete={handleDelete} />
                                                            <Chip className="property-schedule-chip" label="RP4" variant="outlined" onDelete={handleDelete} />
                                                            <Chip className="property-schedule-chip" label="RP5" variant="outlined" onDelete={handleDelete} />
                                                            <Chip className="property-schedule-chip" label="RP6" variant="outlined" onDelete={handleDelete} />
                                                        </div>

                                                    </Grid></Grid>
                                                    <Grid item xs={.7}>
                                                        <Grid container className="rateplan-button" columnSpacing={0} sx={{ order: { xs: 1, sm: 2 } }}>
                                                            <Grid item>
                                                                <KeyboardArrowUpOutlinedIcon className="inside-line-rateplan expend-arrow" sx={{ fontSize: 30 }}></KeyboardArrowUpOutlinedIcon>

                                                            </Grid>
                                                            <Grid item>
                                                                <KeyboardArrowDownOutlinedIcon color="primary" className="inside-line-rateplan  expend-arrow" sx={{ fontSize: 30 }}></KeyboardArrowDownOutlinedIcon>
                                                            </Grid>
                                                            <Grid item>

                                                                <DeleteOutlineIcon className="delete-button inside-line-rateplan" sx={{ fontSize: 20 }}></DeleteOutlineIcon>
                                                            </Grid>
                                                        </Grid>


                                                    </Grid>
                                                </Grid>
                                                <Grid container spacing={0}>
                                                    <Grid item xs={11.3}> <Grid className="segement-container" >
                                                        <div className="heaading">TIFT</div>
                                                        <div>
                                                            <Chip className="property-schedule-chip" label="RP7" variant="outlined" onDelete={handleDelete} />
                                                            <Chip className="property-schedule-chip" label="RP8" variant="outlined" onDelete={handleDelete} />
                                                        </div>

                                                    </Grid></Grid>
                                                    <Grid item xs={.7}>
                                                        <Grid container className="rateplan-button" columnSpacing={0} sx={{ order: { xs: 1, sm: 2 } }}>
                                                            <Grid item>
                                                                <KeyboardArrowUpOutlinedIcon className="inside-line-rateplan expend-arrow" sx={{ fontSize: 30 }}></KeyboardArrowUpOutlinedIcon>

                                                            </Grid>
                                                            <Grid item>
                                                                <KeyboardArrowDownOutlinedIcon color="primary" className="inside-line-rateplan  expend-arrow" sx={{ fontSize: 30 }}></KeyboardArrowDownOutlinedIcon>
                                                            </Grid>
                                                            <Grid item>

                                                                <DeleteOutlineIcon className="delete-button inside-line-rateplan" sx={{ fontSize: 20 }}></DeleteOutlineIcon>
                                                            </Grid>
                                                        </Grid>


                                                    </Grid>
                                                </Grid>
                                                <Grid container spacing={0}>
                                                    <Grid item xs={11.3}> <Grid className="segement-container" >
                                                        <div className="heaading">CCON</div>
                                                        <div>
                                                            <Chip className="property-schedule-chip" label="RP9" variant="outlined" onDelete={handleDelete} />
                                                            <Chip className="property-schedule-chip" label="RP10" variant="outlined" onDelete={handleDelete} />
                                                            <Chip className="property-schedule-chip" label="RP11" variant="outlined" onDelete={handleDelete} />

                                                        </div>

                                                    </Grid></Grid>
                                                    <Grid item xs={.7}>
                                                        <Grid container className="rateplan-button" columnSpacing={0} sx={{ order: { xs: 1, sm: 2 } }}>
                                                            <Grid item>
                                                                <KeyboardArrowUpOutlinedIcon className="inside-line-rateplan expend-arrow" sx={{ fontSize: 30 }}></KeyboardArrowUpOutlinedIcon>

                                                            </Grid>
                                                            <Grid item>
                                                                <KeyboardArrowDownOutlinedIcon color="primary" className="inside-line-rateplan  expend-arrow" sx={{ fontSize: 30 }}></KeyboardArrowDownOutlinedIcon>
                                                            </Grid>
                                                            <Grid item>

                                                                <DeleteOutlineIcon className="delete-button inside-line-rateplan" sx={{ fontSize: 20 }}></DeleteOutlineIcon>
                                                            </Grid>
                                                        </Grid>


                                                    </Grid>
                                                </Grid>

                                            </Grid>
                                        </TabPanel>
                                    </Box>



                                </Grid>
                            </Grid>
                        </Box>

                        {/* mani work start for Rate Plan end */}

                        <Grid container spacing={2}>
                            <Grid item xs={3}>SEGMENT NAME</Grid>
                            <Grid item xs={9}>SEGMENT CODES</Grid>
                        </Grid>

                        <Box sx={{}}>
                            {requestData.market_segment_category_order.map((item, index) => {
                                // let t = requestData.market_segment_category_mapping[item];
                                // debugger;
                                return (
                                    <Grid
                                        container
                                        className="room-type-details"
                                        spacing={1}
                                        sx={{ fontSize: 12.5, mt: 0 }}
                                    >
                                        <Grid item className="segments-detail" xs={3}>

                                            <FormControl className="textfield" fullWidth size="small">
                                                <OutlinedInput
                                                    className="field"
                                                    sx={{ fontSize: 12 }}
                                                    value={item}
                                                    onChange={(e) => {
                                                        const value = e.target.value;
                                                        updateRequestData(
                                                            "market_segment_category_order",
                                                            value,
                                                            index,
                                                            item
                                                        );
                                                    }}
                                                />
                                            </FormControl>
                                        </Grid>
                                        <Grid item className="segments-detail" xs={8}>
                                            {/* <FormControl fullWidth size="small" sx={{}}>
                        <OutlinedInput
                          className="field code"
                          value={
                            requestData.market_segment_category_mapping[item]
                          }
                          onChange={(e) => {
                            const value = e.target.value;
                            updateRequestData(
                              "market_segment_category_mapping>" + item,
                              value,
                              index,
                              item
                            );
                          }}
                        />
                      </FormControl> */}
                                            <Autocomplete
                                                size="small"
                                                multiple
                                                options={[]}
                                                value={
                                                    requestData.market_segment_category_mapping[item]
                                                }
                                                freeSolo
                                                onChange={(e, newVal) => {
                                                    const value = newVal;
                                                    updateRequestData(
                                                        "market_segment_category_mapping>" + item,
                                                        value,
                                                        index
                                                    );
                                                }}
                                                renderTags={(value, getTagProps) =>
                                                    value.map((option, index) => (
                                                        <Chip className="chip-close"
                                                            key={"id-" + index}
                                                            size="small"
                                                            variant="filled"
                                                            // color="primary"
                                                            label={option}
                                                            {...getTagProps({ index })}
                                                        />
                                                    ))
                                                }
                                                renderInput={(params) => (
                                                    <TextField
                                                        className="textfield"
                                                        {...params}
                                                        size="small"
                                                        variant="outlined"
                                                        label=""
                                                        placeholder=""
                                                    />
                                                )}
                                            />
                                        </Grid>

                                        <Grid item md={0.5}>
                                            <Box display="flex" spacing={1}>
                                                <Button
                                                    onClick={() => {
                                                        changeSegmentsOrder(item, index, "down");
                                                    }}
                                                    size="large"
                                                    sx={{ minWidth: "auto", p: 0, lineHeight: "auto" }}
                                                    disabled={
                                                        index ===
                                                        requestData.market_segment_category_order.length - 1
                                                    }
                                                >
                                                    <KeyboardArrowDownOutlined fontSize="small"></KeyboardArrowDownOutlined>
                                                </Button>
                                                <Button
                                                    onClick={() => {
                                                        changeSegmentsOrder(item, index, "up");
                                                    }}
                                                    size="small"
                                                    sx={{ minWidth: "auto", p: 0, lineHeight: "auto" }}
                                                    disabled={index === 0}
                                                >
                                                    <KeyboardArrowUpOutlinedIcon fontSize="small"></KeyboardArrowUpOutlinedIcon>
                                                </Button>
                                                <Button
                                                    variant=""
                                                    size="small"
                                                    sx={{ minWidth: "auto", p: 0, lineHeight: "auto" }}
                                                    onClick={() => {
                                                        deleteMarketSegment(index);
                                                    }}
                                                >
                                                    <DeleteOutlineOutlinedIcon
                                                        fontSize="small"
                                                        color="error"
                                                    ></DeleteOutlineOutlinedIcon>
                                                </Button>
                                            </Box>
                                        </Grid>
                                    </Grid>
                                );
                            })}
                        </Box>
                        <div className="add-more">
                            <Button
                                className="add-button"
                                variant="outlined"
                                size="small"
                                sx={{ lineHeight: "auto", mt: 1 }}
                                onClick={() => {
                                    addNewSegmentMap();
                                }}
                            >
                                Add A SEGMENT
                            </Button>
                        </div>
                        <Divider sx={{ clear: "both", mt: 1, mb: 2 }} />
                        <Box>
                            <Grid container spacing={2}>
                                <Grid item lg={11.4}>
                                    <FormControl fullWidth size="small">
                                        <Autocomplete
                                            size="small"
                                            multiple
                                            options={ignoreCodes}
                                            value={requestData.market_code_ignorelist}
                                            freeSolo
                                            onChange={(e, newVal) => {
                                                //item.recipients;
                                                updateRequestData("market_code_ignorelist", newVal);
                                            }}
                                            renderTags={(value, getTagProps) =>
                                                value.map((option, index) => (
                                                    <Chip
                                                        key={"id-" + index}
                                                        size="small"
                                                        variant="filled"
                                                        // color="primary"
                                                        label={option}
                                                        {...getTagProps({ index })}
                                                    />
                                                ))
                                            }
                                            renderInput={(params) => (
                                                <TextField
                                                    className="textfield"
                                                    {...params}
                                                    size="small"
                                                    variant="outlined"
                                                    label="IGNORED CODES"
                                                    placeholder="Type code and press enter"
                                                />
                                            )}
                                        />

                                        {/* <InputLabel>IGNORED CODES</InputLabel>
                    <OutlinedInput
                      className="field code"
                      label="IGNORED CODES"
                      value={requestData.market_code_ignorelist}
                      onChange={(e) => {
                        const value = e.target.value;
                        updateRequestData("market_code_ignorelist", value);
                      }}
                    /> */}
                                    </FormControl>
                                </Grid>

                                <Grid item xs={12}>
                                    <Typography
                                        gutterBottom
                                        sx={{
                                            fontSize: 14,
                                            // color: "dark.main",
                                            pt: 1,
                                        }}
                                    >
                                        TOP SEGMENTS
                                    </Typography>
                                    {/* <Divider sx={{clear:'both'}} /> */}
                                </Grid>

                                <Grid item lg={3.8}>
                                    <FormControl className="textfield"
                                        fullWidth
                                        size="small"
                                        helperText="Enter comma seprated property IDs"
                                    >
                                        <InputLabel>TRANSIENT</InputLabel>
                                        <Select
                                            className="field"
                                            label="TRANSIENT"
                                            multiple
                                            value={requestData.topSegments["Transient"]}
                                            onChange={(e) => {
                                                updateRequestData(
                                                    "lvl1_mseg_grouping>Transient",
                                                    e.target.value
                                                );
                                            }}
                                            renderValue={(selected) => (
                                                <Box
                                                    sx={{ display: "flex", flexWrap: "wrap", gap: 0.5 }}
                                                >
                                                    {selected.map((value) => (
                                                        <Chip
                                                            key={value}
                                                            label={value}
                                                            variant="filled"
                                                        // onDelete={(e)=>{e.preventDefault(); e.stopPropagation();}}
                                                        // color="primary"
                                                        />
                                                    ))}
                                                </Box>
                                            )}
                                        >
                                            {requestData.market_segment_category_order.map(
                                                (item, index) => {
                                                    return <MenuItem value={item}>{item}</MenuItem>;
                                                }
                                            )}
                                        </Select>
                                    </FormControl>
                                </Grid>

                                <Grid item lg={3.8}>
                                    <FormControl className="textfield"
                                        fullWidth
                                        size="small"
                                        helperText="Enter comma seprated property IDs"
                                    >
                                        <InputLabel>GROUP</InputLabel>
                                        <Select
                                            className="field"
                                            label="GROUP"
                                            multiple
                                            value={requestData.topSegments["Group"]}
                                            onChange={(e) => {
                                                updateRequestData(
                                                    "lvl1_mseg_grouping>Group",
                                                    e.target.value
                                                );
                                            }}
                                            renderValue={(selected) => (
                                                <Box
                                                    sx={{ display: "flex", flexWrap: "wrap", gap: 0.5 }}
                                                >
                                                    {selected.map((value) => (
                                                        <Chip
                                                            key={value}
                                                            label={value}
                                                            variant="filled"
                                                        // color="primary"
                                                        />
                                                    ))}
                                                </Box>
                                            )}
                                        >
                                            {requestData.market_segment_category_order.map(
                                                (item, index) => {
                                                    return <MenuItem value={item}>{item}</MenuItem>;
                                                }
                                            )}
                                        </Select>
                                    </FormControl>
                                </Grid>
                                <Grid item lg={3.8}>
                                    <FormControl className="textfield"
                                        fullWidth
                                        size="small"
                                        helperText="Enter comma seprated property IDs"
                                    >
                                        <InputLabel className="input-text">CONTRACT</InputLabel>
                                        <Select
                                            className="field"
                                            label="CONTRACT"
                                            multiple
                                            value={requestData.topSegments["Contract"]}
                                            onChange={(e) => {
                                                updateRequestData(
                                                    "lvl1_mseg_grouping>Contract",
                                                    e.target.value
                                                );
                                            }}
                                            renderValue={(selected) => (
                                                <Box
                                                    sx={{ display: "flex", flexWrap: "wrap", gap: 0.5 }}
                                                >
                                                    {selected.map((value) => (
                                                        <Chip
                                                            key={value}
                                                            label={value}
                                                            variant="filled"
                                                        // color="primary"
                                                        />
                                                    ))}
                                                </Box>
                                            )}
                                        >
                                            {requestData.market_segment_category_order.map(
                                                (item, index) => {
                                                    return <MenuItem value={item}>{item}</MenuItem>;
                                                }
                                            )}
                                        </Select>
                                    </FormControl>
                                </Grid>
                            </Grid>
                        </Box>

                    </Box>

                    {isrequesting && (
                        <Box
                            sx={{
                                textAlighn: "center",
                                backgroundColor: "dark.darkest",
                                opacity: 0.8,
                                position: "fixed",
                                top: 40,
                                left: 0,
                                width: "100%",
                                height: "100%",
                            }}
                        >
                            <CircularProgress
                                size={40}
                                sx={{
                                    color: "white",
                                    position: "absolute",
                                    top: "50%",
                                    left: "50%",
                                    marginTop: "-20px",
                                    marginLeft: "-20px",
                                }}
                            />
                        </Box>
                    )}
                </Box>
            </Box>
        </div>
    );
}

function MarketsSettings(props) {
    const dispatch = useDispatch();

    const [isrequesting, setIsRequesting] = useState(false);
    const [resError, setResError] = useState(false);
    const [isRequestSuccess, setIsRequestSuccess] = useState(false);
    const [requestSuccessMsg, setRequestSuccessMsg] = useState(
        "Your change request for settings was successfull!"
    );
    const [isRequestError, setIsRequestError] = useState(false);
    const [resErrorMsg, setResErrorMsg] = useState("");

    const { app, auth } = useSelector((state) => state);
    const { user, profile } = auth;
    const { app_state, ui_state } = app.current_state;
    const { property_details } = profile || [];
    //Data source information
    const [dsInfo, setDsInfo] = useState(getDataSources(property_details, app_state.property_id));
    let prop_details_index = 0;
    const prop_details = property_details.filter((item, index) => {
        if (item.id === app_state.property_id) {
            prop_details_index = index;
            return item.id === app_state.property_id;
        }
        //
    });

    const { system_settings } =
        prop_details && prop_details.length ? prop_details[0] : null;
    const market_settings = JSON.parse(
        JSON.stringify(system_settings[0].market_settings)
    );
    const primary_vacation_rental_market =
        system_settings[0].primary_vacation_rental_market;
    const settings_ = JSON.parse(
        JSON.stringify({
            market_settings: market_settings,
            primary_vacation_rental_market: primary_vacation_rental_market,
        })
    );
    const settings = JSON.parse(
        JSON.stringify({
            market_settings: system_settings[0].market_settings,
            primary_vacation_rental_market: primary_vacation_rental_market,
        })
    );

    //const settings = JSON.parse(JSON.stringify(system_settings[0].market_segment_category_mapping));
    const [requestData, setRequestData] = useState(settings);
    const [isChangeRequest, setIsChangeRequest] = useState(false);

    const cancelSave = () => {
        setRequestData(settings_);
        setIsChangeRequest(false);
    };
    const updateRequestData = (keys, val, index) => {
        const updatedObj = { ...requestData };
        const key = keys.split(">");
        //const newVal = val.split(',');

        if (key[0] === "primary_vacation_rental_market") {
            updatedObj[key[0]] = val;
        }
        if (key[0] == "market_settings") {
            if (key[1] === "market_id") {
                let selectedMarket = profile.market_settings.filter((item) => {
                    return item[key[1]] === val;
                });
                updatedObj[key[0]][index].market_id = val;
                updatedObj[key[0]][index].value = val;
                updatedObj[key[0]][index].market_name = selectedMarket[0].market_name;
                updatedObj[key[0]][index].label = selectedMarket[0].market_name;
                updatedObj[key[0]][index].market_source_name =
                    selectedMarket[0].collection;
                updatedObj[key[0]][index].revup_market_idOFF = "";
                // label: "Key West Combined Market - Key West, FL"
                // market_id: 7777258
                // market_name: "Key West Combined Market - Key West, FL"
                // market_source_name: "Test_Key_West_Market_Combined"
                // revup_market_idOFF: ""
                // value: 7777258
                //debugger;
                //updatedObj[key[0]][index][key[1]] = val;
            } else if (key[1] === "is_default") {
                updatedObj.market_settings.map((item, I) => {
                    if (index !== I && item.is_default) {
                        updatedObj[key[0]][I][key[1]] = false;
                    }
                });
                updatedObj[key[0]][index][key[1]] = true;
            } else {
                updatedObj[key[0]][index][key[1]] = val;
            }
        }

        setRequestData({ ...requestData, ...updatedObj });
        setIsChangeRequest(true);
    };
    const deleteSettingsRow = (index) => {
        const segment = requestData.market_settings[index];
        requestData.market_settings.splice(index, 1);

        setRequestData({
            ...requestData,
            market_settings: requestData.market_settings,
        });
        setIsChangeRequest(true);
    };

    const addNewSettingsRow = (e, value) => {
        const newRow = {
            is_default: false,
            label: "",
            market_id: "",
            market_name: "",
            market_source_name: "",
            revup_market_idOFF: "",
            value: "",
        };
        requestData.market_settings.push(newRow);
        setRequestData({
            ...requestData,
            market_settings: requestData.market_settings,
        });
        setIsChangeRequest(true);
    };

    const saveChanges = (event) => {
        setIsRequesting(true);
        const request_system_settings = {
            ...system_settings[0],
            market_settings: [],
            primary_vacation_rental_market:
                requestData.primary_vacation_rental_market,
        };
        requestData.market_settings.map((item, index) => {
            if (item.market_id) {
                request_system_settings.market_settings.push(item);
            }
        });
        //debugger;
        let payload = {
            "resource_id": app_state.property_id,
            "resource_type": "property",
            "update_type": "full_system_settings",
            "data": {
                "full_ss": request_system_settings
            }
        };

        let ss_api_config = deepCopy(API_REGISTRY['SaveSystemSettings']);
        let active_ds_id = dsInfo.active_source.id;
        ss_api_config.uri = ss_api_config.uri + app_state.property_id + '/' + active_ds_id + '/';

        dataService
            .update({ api: ss_api_config.uri, request: { ...ss_api_config.payload, ...payload } })
            .then((data) => {
                //Do some task here just after respons
                if (data.status === "success") {
                    setIsRequestSuccess(true);
                    setIsRequesting(false);
                    setTimeout(() => {
                        profile.property_details[prop_details_index].system_settings[0] =
                            request_system_settings;
                        dispatch({ type: SYSTEM_SETTINGS_UPDATED, payload: profile });
                    }, 500);
                    setTimeout(() => {
                        setIsRequestSuccess(false);
                    }, 5000);
                } else {
                    setIsRequestError(true);
                    setIsRequesting(false);
                    setResErrorMsg(data.message);
                    //return Promise.reject(data);
                }
            })
            .catch((err) => {
                setIsRequesting(false);
                setIsRequestError(true);
                setResErrorMsg(
                    (err && err.original && err.original.statusText) ||
                    "Something went wrong, please try again later!"
                );
                setTimeout(() => {
                    setIsRequestError(false);
                }, 5000);
                //return Promise.reject(err);
            });
    };

    console.log("requestData.market_settings", requestData.market_settings, profile);

    return (
        <div>
            <Box className='breadcrumb' sx={{ color: 'white.main', position: 'absolute', top: -40 }}>
                <span className="breadcrum-inactive">Settings</span>
                <ArrowForwardIosOutlinedIcon className="breadcrum-inactive"
                    sx={{ top: 3, position: "relative", height: 18 }}
                ></ArrowForwardIosOutlinedIcon>
                <span className="breadcrum-inactive">Property Settings</span>
                <ArrowForwardIosOutlinedIcon className="breadcrum-inactive"
                    sx={{ top: 3, position: "relative", height: 18 }}
                ></ArrowForwardIosOutlinedIcon>
                <span className="breadcrum-active">Markets</span>
            </Box>
            <div className="property-setting-heading">
                <Typography
                    className="property-setting-main-heading"
                    // variant="h1"
                    gutterBottom
                    sx={{ mr: 3, display: { lg: "inline-block", xs: "none" } }}
                >
                    Markets
                </Typography>
                <Box sx={{ float: "right" }}>
                    <Button
                        disabled={!isChangeRequest}
                        onClick={() => {
                            saveChanges();
                        }}
                        variant="outlined"
                        size="small"
                        className={(isChangeRequest) ? "navigate-button btn-active" : "navigate-button"}
                        // color="green"
                        sx={{ minWidth: "auto", mr: 1, color: "white.main" }}
                    >
                        <span style={{ display: !isrequesting ? "block" : "none" }}>
                            Save
                        </span>
                        <span
                            style={{
                                display: isrequesting ? "block" : "none",
                                paddingRight: 10,
                            }}
                        >
                            saving...
                        </span>
                        <CircularProgress
                            sx={{ display: isrequesting ? "block" : "none" }}
                            color="white"
                            size="16px"
                        />
                    </Button>
                    <Button
                        disabled={!isChangeRequest}
                        onClick={cancelSave}
                        variant="outlined"
                        className={(isChangeRequest) ? "navigate-button btn-active" : "navigate-button"}
                        // color="error"
                        size="small"
                        sx={{ minWidth: "auto" }}
                    >
                        cancel
                    </Button>
                </Box>
                <Divider sx={{ clear: "both", mt: { xs: 2, lg: 0 } }} />
            </div>

            <Box className="inventory-settings" sx={{ p: 1, pt: 0, mb: 2, mt: 0, ml: 1, mr: 1 }}>

                <Box
                    sx={{
                        pt: 1,
                        flexDirection: "column",
                        position: "relative",
                        display: "flex",
                    }}
                >
                    <Alert
                        severity="success"
                        sx={{
                            width: "100%",
                            marginTop: -10,
                            display: isRequestSuccess ? "flex" : "none",
                        }}
                    >
                        {requestSuccessMsg}
                    </Alert>
                    <Alert
                        severity="error"
                        sx={{
                            width: "100%",
                            marginTop: -10,
                            display: isRequestError ? "flex" : "none",
                        }}
                    >
                        {resErrorMsg}
                    </Alert>

                    <Box sx={{ mt: { lg: 1 } }}>

                        <Box sx={{ maxHeight: "58vh", overflowY: "auto" }}>


                            <Grid>
                                <Grid
                                    container
                                    className="property-heading-title-heading"
                                    // spacing={1}
                                    sx={{
                                        fontSize: 12.5,
                                        // mt: 1,
                                        pb: 1,
                                        // borderTop: "1px solid #ccc",
                                        fontWeight: "bold",
                                    }}
                                >
                                    <Grid item md={7.5} sx={{}}>
                                        MARKET
                                    </Grid>
                                    <Grid item md={2} textAlign="center">
                                        IS DEFAULT
                                    </Grid>
                                    <Grid item md={2} textAlign="center">
                                        GROUP COMM IN TOTAL
                                    </Grid>
                                    <Grid item md={1}>
                                        {" "}
                                    </Grid>
                                </Grid>

                                {requestData.market_settings.map((item, index) => {
                                    return (
                                        <Grid
                                            container
                                            className="room-type-details"
                                            spacing={1}
                                            sx={{ fontSize: 12.5, mt: 0 }}
                                        >
                                            <Grid item md={7.5}>
                                                <FormControl className="textfield" fullWidth size="small" sx={{}}>
                                                    <Select
                                                        className="field"
                                                        sx={{ width: "100%" }}
                                                        value={item.market_id}
                                                        onChange={(e) => {
                                                            const value = e.target.value;
                                                            updateRequestData(
                                                                "market_settings>market_id",
                                                                value,
                                                                index
                                                            );
                                                        }}
                                                    >
                                                        {profile.market_settings && profile.market_settings.map((market, index) => {
                                                            return (
                                                                market.active && (
                                                                    <MenuItem value={market.market_id}>
                                                                        {market.market_name} ({market.market_id})
                                                                    </MenuItem>
                                                                )
                                                            );
                                                        })}
                                                    </Select>
                                                </FormControl>
                                            </Grid>
                                            <Grid item md={2} sx={{ textAlign: "center" }}>
                                                <Radio
                                                    className="colored-radiobutton"
                                                    checked={item.is_default}
                                                    name="is_default"
                                                    onChange={(e) => {
                                                        const value = e.target.value;
                                                        updateRequestData(
                                                            "market_settings>is_default",
                                                            value,
                                                            index
                                                        );
                                                    }}
                                                />
                                            </Grid>
                                            <Grid item md={2} sx={{ textAlign: "center" }}>
                                                <Checkbox
                                                    className="colored-checkbox"
                                                    checked={item.market_mode === "comm"}
                                                    name="market_mode"
                                                    onChange={(e) => {
                                                        const value = e.target.value;
                                                        item.market_mode = item.market_mode ? "" : "comm";
                                                        updateRequestData(
                                                            "market_settings>market_mode",
                                                            item.market_mode,
                                                            index
                                                        );
                                                    }}
                                                />
                                            </Grid>

                                            <Grid item md={0.5}>
                                                <Box display="flex" spacing={1}>
                                                    <Button
                                                        variant=""
                                                        className="delete-pd"
                                                        size="small"
                                                        sx={{ minWidth: "auto", p: 0, lineHeight: "auto" }}
                                                        onClick={() => {
                                                            deleteSettingsRow(index);
                                                        }}
                                                    >
                                                        <DeleteOutlineOutlinedIcon
                                                            fontSize="small"
                                                            color="error"
                                                            className="arrow-down inside-line-rateplan expend-arrow delete-icon-button"
                                                        ></DeleteOutlineOutlinedIcon>
                                                    </Button>
                                                </Box>
                                            </Grid>
                                        </Grid>
                                    );
                                })}

                                <Button
                                    className="add-button"
                                    variant="outlined"
                                    size="small"
                                    sx={{ lineHeight: "auto", mt: 1 }}
                                    onClick={() => {
                                        addNewSettingsRow();
                                    }}
                                >
                                    Add A Market
                                </Button>
                                <Divider sx={{ clear: "both", mt: 1, mb: 2 }} />
                                <Grid item lg={12}>
                                    <FormControl className="textfield" fullWidth size="small">
                                        <InputLabel>VR MARKET</InputLabel>
                                        <OutlinedInput
                                            className="field code"
                                            label="VR MARKET"
                                            value={requestData.primary_vacation_rental_market}
                                            onChange={(e) => {
                                                const value = e.target.value;
                                                updateRequestData(
                                                    "primary_vacation_rental_market",
                                                    value
                                                );
                                            }}
                                        />
                                    </FormControl>
                                </Grid>
                            </Grid>


                        </Box>

                    </Box>
                    {isrequesting && (
                        <Box
                            sx={{
                                textAlighn: "center",
                                backgroundColor: "dark.darkest",
                                opacity: 0.8,
                                position: "fixed",
                                top: 40,
                                left: 0,
                                width: "100%",
                                height: "100%",
                            }}
                        >
                            <CircularProgress
                                size={40}
                                sx={{
                                    color: "white",
                                    position: "absolute",
                                    top: "50%",
                                    left: "50%",
                                    marginTop: "-20px",
                                    marginLeft: "-20px",
                                }}
                            />
                        </Box>
                    )}
                </Box>
            </Box>
        </div>
    );
}

function LocaleSettings(props) {
    const dispatch = useDispatch();

    const [isrequesting, setIsRequesting] = useState(false);
    const [resError, setResError] = useState(false);
    const [isRequestSuccess, setIsRequestSuccess] = useState(false);
    const [requestSuccessMsg, setRequestSuccessMsg] = useState(
        "Your change request for inventory settings was successfull!"
    );
    const [isRequestError, setIsRequestError] = useState(false);
    const [resErrorMsg, setResErrorMsg] = useState("");

    const { app, auth } = useSelector((state) => state);
    const { user, profile } = auth;
    const { app_state, ui_state } = app.current_state;
    const { property_details } = auth.profile || [];
    //Data source information
    const [dsInfo, setDsInfo] = useState(getDataSources(property_details, app_state.property_id));
    let prop_details_index = 0;
    const prop_details = property_details.filter((item, index) => {
        if (item.id === app_state.property_id) {
            prop_details_index = index;
            return item.id === app_state.property_id;
        }
        //
    });
    const { system_settings } =
        prop_details && prop_details.length ? prop_details[0] : null;
    //debugger;
    const localeSettings = {
        default_locale: system_settings[0].default_locale, //DEFAULT LOCALE
        locale_city: system_settings[0].locale_city, //CITY
    };
    //debugger;
    const [requestData, setRequestData] = useState(localeSettings);
    const [isChangeRequest, setIsChangeRequest] = useState(false);

    const cancelSave = () => {
        setRequestData({
            default_locale: system_settings[0].default_locale, //DEFAULT LOCALE
            locale_city: system_settings[0].locale_city, //CITY
        });
        setIsChangeRequest(false);
    };
    const updateRequestData = (keys, val, index) => {
        const updatedObj = { ...requestData };
        const key = keys.split(">");
        switch (key[0]) {
            case "default_locale":
            case "locale_city":
                updatedObj[key[0]] = val;
                break;

            case "rate_source":
                updatedObj[key[0]][key[1]] = val;
                break;

            default:
        }
        setRequestData({ ...requestData, ...updatedObj });
        setIsChangeRequest(true);
    };

    const saveChanges = (event) => {
        setIsRequesting(true);
        const request_system_settings = {
            ...system_settings[0],
            default_locale: requestData.default_locale, //DEFAULT LOCALE
            locale_city: requestData.locale_city, //CITY
        };
        let payload = {
            "resource_id": app_state.property_id,
            "resource_type": "property",
            "update_type": "full_system_settings",
            "data": {
                "full_ss": request_system_settings
            }
        };

        let ss_api_config = deepCopy(API_REGISTRY['SaveSystemSettings']);
        let active_ds_id = dsInfo.active_source.id;
        ss_api_config.uri = ss_api_config.uri + app_state.property_id + '/' + active_ds_id + '/';

        dataService
            .update({ api: ss_api_config.uri, request: { ...ss_api_config.payload, ...payload } })
            .then((data) => {
                //Do some task here just after respons
                if (data.status === "success") {
                    setIsRequestSuccess(true);
                    setIsRequesting(false);
                    setTimeout(() => {
                        profile.property_details[prop_details_index].system_settings[0] =
                            request_system_settings;
                        dispatch({ type: SYSTEM_SETTINGS_UPDATED, payload: profile });
                    }, 500);
                    setTimeout(() => {
                        setIsRequestSuccess(false);
                    }, 5000);
                } else {
                    setIsRequestError(true);
                    setIsRequesting(false);
                    setResErrorMsg(data.message);
                    //return Promise.reject(data);
                }
            })
            .catch((err) => {
                setIsRequesting(false);
                setIsRequestError(true);
                setResErrorMsg(
                    (err && err.original && err.original.statusText) ||
                    "Something went wrong, please try again later!"
                );
                setTimeout(() => {
                    setIsRequestError(false);
                }, 5000);
                //return Promise.reject(err);
            });
    };

    //console.log(system_settings[0]);

    return (
        <div>
            <Box className='breadcrumb' sx={{ color: 'white.main', position: 'absolute', top: -40 }}>
                <span className="breadcrum-inactive">Settings</span>
                <ArrowForwardIosOutlinedIcon className="breadcrum-inactive"
                    sx={{ top: 3, position: "relative", height: 18 }}
                ></ArrowForwardIosOutlinedIcon>
                <span className="breadcrum-inactive">Property Settings</span>
                <ArrowForwardIosOutlinedIcon className="breadcrum-inactive"
                    sx={{ top: 3, position: "relative", height: 18 }}
                ></ArrowForwardIosOutlinedIcon>
                <span className="breadcrum-active">Locale</span>
            </Box>
            <div className="property-setting-heading">
                <Typography
                    className="property-setting-main-heading"
                    // variant="h1"
                    gutterBottom
                    sx={{ mr: 3, display: { lg: "inline-block", xs: "none" } }}
                >
                    Locale
                </Typography>
                <Box sx={{ float: "right" }}>
                    <Button
                        disabled={!isChangeRequest}
                        onClick={() => {
                            saveChanges();
                        }}
                        variant="outlined"
                        size="small"
                        className={(isChangeRequest) ? "navigate-button btn-active" : "navigate-button"}
                        // color="green"
                        sx={{ minWidth: "auto", mr: 1, color: "white.main" }}
                    >
                        <span style={{ display: !isrequesting ? "block" : "none" }}>
                            Save
                        </span>
                        <span
                            style={{
                                display: isrequesting ? "block" : "none",
                                paddingRight: 10,
                            }}
                        >
                            saving...
                        </span>
                        <CircularProgress
                            sx={{ display: isrequesting ? "block" : "none" }}
                            color="white"
                            size="16px"
                        />
                    </Button>
                    <Button
                        disabled={!isChangeRequest}
                        onClick={cancelSave}
                        variant="outlined"
                        className={(isChangeRequest) ? "navigate-button btn-active" : "navigate-button"}
                        // color="error"
                        size="small"
                        sx={{ minWidth: "auto" }}
                    >
                        cancel
                    </Button>
                </Box>
                <Divider sx={{ clear: "both", mt: { xs: 2, lg: 0 } }} />
            </div>

            <Box className="inventory-settings" sx={{ p: 1, pt: 1, mb: 2, mt: 1, ml: 1, mr: 0 }}>

                {/* <Divider sx={{ clear: "both", mt: { xs: 2, lg: 0 } }} /> */}

                <Grid>
                    <Box
                        sx={{
                            pt: 1,
                            flexDirection: "column",
                            position: "relative",
                            display: "flex",
                        }}
                    >
                        <Alert
                            severity="success"
                            sx={{
                                position: "absolute",
                                zIndex: 100,
                                width: "100%",
                                marginTop: -10,
                                display: isRequestSuccess ? "flex" : "none",
                            }}
                        >
                            {requestSuccessMsg}
                        </Alert>
                        <Alert
                            severity="error"
                            sx={{
                                width: "100%",
                                marginTop: -10,
                                display: isRequestError ? "flex" : "none",
                            }}
                        >
                            {resErrorMsg}
                        </Alert>

                        <Box>
                            <Grid container spacing={2} sx={{ fontSize: 10 }}>
                                <Grid item lg={6}>
                                    <FormControl className="textfield" fullWidth size="small">
                                        <InputLabel>DEFAULT LOCALE</InputLabel>
                                        <OutlinedInput
                                            label="DEFAULT LOCALE"
                                            value={requestData.default_locale}
                                            onChange={(e) => {
                                                updateRequestData(
                                                    "default_locale",
                                                    e.currentTarget.value
                                                );
                                            }}
                                        />
                                    </FormControl>
                                </Grid>
                                <Grid item lg={6}>
                                    <FormControl className="textfield" fullWidth size="small">
                                        <InputLabel>CITY</InputLabel>
                                        <OutlinedInput
                                            className="input-text"
                                            label="RESOURCE IDS"
                                            value={requestData.locale_city}
                                            onChange={(e) => {
                                                updateRequestData("locale_city", e.currentTarget.value);
                                            }}
                                        />
                                    </FormControl>
                                </Grid>
                            </Grid>
                        </Box>

                        {isrequesting && (
                            <Box
                                sx={{
                                    textAlighn: "center",
                                    backgroundColor: "dark.darkest",
                                    opacity: 0.8,
                                    position: "fixed",
                                    top: 40,
                                    left: 0,
                                    width: "100%",
                                    height: "100%",
                                }}
                            >
                                <CircularProgress
                                    size={40}
                                    sx={{
                                        color: "white",
                                        position: "absolute",
                                        top: "50%",
                                        left: "50%",
                                        marginTop: "-20px",
                                        marginLeft: "-20px",
                                    }}
                                />
                            </Box>
                        )}
                    </Box>
                </Grid>

            </Box>
        </div>
    );
}

function IntegrationSettings(props) {
    const dispatch = useDispatch();

    const [isrequesting, setIsRequesting] = useState(false);
    const [resError, setResError] = useState(false);
    const [isRequestSuccess, setIsRequestSuccess] = useState(false);
    const [requestSuccessMsg, setRequestSuccessMsg] = useState(
        "Your change request for inventory settings was successfull!"
    );
    const [isRequestError, setIsRequestError] = useState(false);
    const [resErrorMsg, setResErrorMsg] = useState("");

    const { app, auth } = useSelector((state) => state);
    const { user, profile } = auth;
    const { app_state, ui_state } = app.current_state;
    const { property_details } = auth.profile || [];
    //Data source information
    const [dsInfo, setDsInfo] = useState(getDataSources(property_details, app_state.property_id));
    let prop_details_index = 0;
    const prop_details = property_details.filter((item, index) => {
        if (item.id === app_state.property_id) {
            prop_details_index = index;
            return item.id === app_state.property_id;
        }
        //
    });
    const { system_settings } =
        prop_details && prop_details.length ? prop_details[0] : null;
    //debugger;
    const integrationSettings = {
        primary_revup_property_id: system_settings[0].primary_revup_property_id, //RU ID
    };
    //debugger;
    const [requestData, setRequestData] = useState(integrationSettings);
    const [isChangeRequest, setIsChangeRequest] = useState(false);

    const cancelSave = () => {
        setRequestData({
            primary_revup_property_id: system_settings[0].primary_revup_property_id, //RU ID
        });
        setIsChangeRequest(false);
    };
    const updateRequestData = (keys, val, index) => {
        const updatedObj = { ...requestData };
        const key = keys.split(">");
        switch (key[0]) {
            case "primary_revup_property_id":
                updatedObj[key[0]] = val;
                break;

            default:
        }
        setRequestData({ ...requestData, ...updatedObj });
        setIsChangeRequest(true);
    };

    const saveChanges = (event) => {
        setIsRequesting(true);
        const request_system_settings = {
            ...system_settings[0],
            primary_revup_property_id: requestData.primary_revup_property_id, //RU ID
        };
        let payload = {
            "resource_id": app_state.property_id,
            "resource_type": "property",
            "update_type": "full_system_settings",
            "data": {
                "full_ss": request_system_settings
            }
        };

        let ss_api_config = deepCopy(API_REGISTRY['SaveSystemSettings']);
        let active_ds_id = dsInfo.active_source.id;
        ss_api_config.uri = ss_api_config.uri + app_state.property_id + '/' + active_ds_id + '/';

        dataService
            .update({ api: ss_api_config.uri, request: { ...ss_api_config.payload, ...payload } })
            .then((data) => {
                //Do some task here just after respons
                if (data.status === "success") {
                    setIsRequestSuccess(true);
                    setIsRequesting(false);
                    setTimeout(() => {
                        profile.property_details[prop_details_index].system_settings[0] =
                            request_system_settings;
                        dispatch({ type: SYSTEM_SETTINGS_UPDATED, payload: profile });
                    }, 500);
                    setTimeout(() => {
                        setIsRequestSuccess(false);
                    }, 5000);
                } else {
                    setIsRequestError(true);
                    setIsRequesting(false);
                    setResErrorMsg(data.message);
                    //return Promise.reject(data);
                }
            })
            .catch((err) => {
                setIsRequesting(false);
                setIsRequestError(true);
                setResErrorMsg(
                    (err && err.original && err.original.statusText) ||
                    "Something went wrong, please try again later!"
                );
                setTimeout(() => {
                    setIsRequestError(false);
                }, 5000);
                //return Promise.reject(err);
            });
    };

    // console.log(system_settings[0]);

    return (
        <div>
            <Box className='breadcrumb' sx={{ color: 'white.main', position: 'absolute', top: -40 }}>
                <span className="breadcrum-inactive">Settings</span>
                <ArrowForwardIosOutlinedIcon className="breadcrum-inactive"
                    sx={{ top: 3, position: "relative", height: 18 }}
                ></ArrowForwardIosOutlinedIcon>
                <span className="breadcrum-inactive">Property Settings</span>
                <ArrowForwardIosOutlinedIcon className="breadcrum-inactive"
                    sx={{ top: 3, position: "relative", height: 18 }}
                ></ArrowForwardIosOutlinedIcon>
                <span className="breadcrum-active">Integration</span>
            </Box>
            <div className="property-setting-heading">
                <Typography
                    className="property-setting-main-heading"
                    // variant="h1"
                    gutterBottom
                    sx={{ mr: 3, display: { lg: "inline-block", xs: "none" } }}
                >
                    Integration
                </Typography>
                <Box sx={{ float: "right" }}>
                    <Button
                        disabled={!isChangeRequest}
                        onClick={() => {
                            saveChanges();
                        }}
                        variant="outlined"
                        size="small"
                        // className="navigate-button"
                        className={(isChangeRequest) ? "navigate-button btn-active" : "navigate-button"}

                        // color="green"
                        sx={{ minWidth: "auto", mr: 1, color: "white.main" }}
                    >
                        <span style={{ display: !isrequesting ? "block" : "none" }}>
                            Save
                        </span>
                        <span
                            style={{
                                display: isrequesting ? "block" : "none",
                                paddingRight: 10,
                            }}
                        >
                            saving...
                        </span>
                        <CircularProgress
                            sx={{ display: isrequesting ? "block" : "none" }}
                            color="white"
                            size="16px"
                        />
                    </Button>
                    <Button
                        disabled={!isChangeRequest}
                        onClick={cancelSave}
                        variant="outlined"
                        className={(isChangeRequest) ? "navigate-button btn-active" : "navigate-button"}

                        // color="error"
                        size="small"
                        sx={{ minWidth: "auto" }}

                    >
                        cancel
                    </Button>
                </Box>
                <Divider sx={{ clear: "both", mt: { xs: 2, lg: 0 } }} />
            </div>

            <Box className="inventory-settings" sx={{ p: 1, pt: 1, mb: 2, mt: 0, ml: 1, mr: 1 }}>

                <Grid >
                    <Box
                        sx={{
                            pt: 2,
                            flexDirection: "column",
                            position: "relative",
                            display: "flex",
                        }}
                    >
                        <Alert
                            severity="success"
                            sx={{
                                position: "absolute",
                                zIndex: 100,
                                width: "100%",
                                marginTop: -10,
                                display: isRequestSuccess ? "flex" : "none",
                            }}
                        >
                            {requestSuccessMsg}
                        </Alert>
                        <Alert
                            severity="error"
                            sx={{
                                width: "100%",
                                marginTop: -10,
                                display: isRequestError ? "flex" : "none",
                            }}
                        >
                            {resErrorMsg}
                        </Alert>

                        <Box>
                            <Grid container spacing={2} sx={{ fontSize: 10 }}>
                                <Grid item lg={12}>
                                    <FormControl className="textfield" fullWidth size="small">
                                        <InputLabel>RU ID</InputLabel>
                                        <OutlinedInput
                                            className="input-text"
                                            label="RU ID"
                                            value={requestData.primary_revup_property_id}
                                            onChange={(e) => {
                                                updateRequestData(
                                                    "primary_revup_property_id",
                                                    e.currentTarget.value
                                                );
                                            }}
                                        />
                                    </FormControl>
                                </Grid>
                            </Grid>
                        </Box>

                        {isrequesting && (
                            <Box
                                sx={{
                                    textAlighn: "center",
                                    backgroundColor: "dark.darkest",
                                    opacity: 0.8,
                                    position: "fixed",
                                    top: 40,
                                    left: 0,
                                    width: "100%",
                                    height: "100%",
                                }}
                            >
                                <CircularProgress
                                    size={40}
                                    sx={{
                                        color: "white",
                                        position: "absolute",
                                        top: "50%",
                                        left: "50%",
                                        marginTop: "-20px",
                                        marginLeft: "-20px",
                                    }}
                                />
                            </Box>
                        )}
                    </Box>
                </Grid>

            </Box>
        </div>
    );
}

function DiscountRulesSettings(props) {
    const dispatch = useDispatch();

    const [isrequesting, setIsRequesting] = useState(false);
    const [resError, setResError] = useState(false);
    const [isRequestSuccess, setIsRequestSuccess] = useState(false);
    const [requestSuccessMsg, setRequestSuccessMsg] = useState(
        "Your change request for settings was successfull!"
    );
    const [isRequestError, setIsRequestError] = useState(false);
    const [resErrorMsg, setResErrorMsg] = useState("");

    const { app, auth } = useSelector((state) => state);
    const { user, profile } = auth;
    const { app_state, ui_state } = app.current_state;

    const { property_details } = profile || [];
    //Data source information
    const [dsInfo, setDsInfo] = useState(getDataSources(property_details, app_state.property_id));
    let prop_details_index = 0;
    const prop_details = property_details.filter((item, index) => {
        if (item.id === app_state.property_id) {
            prop_details_index = index;
            return item.id === app_state.property_id;
        }
        //
    });
    const months = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12];
    const { system_settings } =
        prop_details && prop_details.length ? prop_details[0] : null;
    const rate_discount_rules = {
        enabled: false,
        default: {
            discount: {
                branddotcom: {
                    month: {
                        1: 0,
                        2: 0,
                        3: 0,
                        4: 0,
                        5: 0,
                        6: 0,
                        7: 0,
                        8: 0,
                        9: 0,
                        10: 0,
                        11: 0,
                        12: 0,
                    },
                },
                ota: {
                    month: {
                        1: 0,
                        2: 0,
                        3: 0,
                        4: 0,
                        5: 0,
                        6: 0,
                        7: 0,
                        8: 0,
                        9: 0,
                        10: 0,
                        11: 0,
                        12: 0,
                    },
                },
            },
            markup: 0,
        },
    };
    system_settings[0].rate_discount_rules = system_settings[0]
        .rate_discount_rules
        ? system_settings[0].rate_discount_rules
        : rate_discount_rules;
    const settings_ = JSON.parse(
        JSON.stringify({
            rate_discount_rules: system_settings[0].rate_discount_rules,
        })
    );
    const settings = JSON.parse(
        JSON.stringify({
            rate_discount_rules: system_settings[0].rate_discount_rules,
        })
    );

    //const settings = JSON.parse(JSON.stringify(system_settings[0].market_segment_category_mapping));
    const [requestData, setRequestData] = useState(settings);
    const [isChangeRequest, setIsChangeRequest] = useState(false);

    const cancelSave = () => {
        setRequestData(settings_);
        setIsChangeRequest(false);
    };
    const updateRequestData = (keys, val, index) => {
        const updatedObj = { ...requestData };
        const key = keys.split(">");

        if (key[0] == "rate_discount_rules") {
            //debugger;
            if (key[1] === "enabled") {
                updatedObj[key[0]][key[1]] = !updatedObj[key[0]][key[1]];
            }
            if (key[1] === "markup") {
                updatedObj[key[0]]["default"][key[1]] = isNaN(Number(val))
                    ? 0
                    : Number(val);
            }

            if (key[1] === "branddotcom") {
                updatedObj[key[0]]["default"]["discount"][key[1]]["month"][index + 1] =
                    isNaN(Number(val)) ? 0 : Number(val);
            }
            if (key[1] === "ota") {
                updatedObj[key[0]]["default"]["discount"][key[1]]["month"][index + 1] =
                    isNaN(Number(val)) ? 0 : Number(val);
            }
        }

        setRequestData({ ...requestData, ...updatedObj });
        setIsChangeRequest(true);
    };

    const saveChanges = (event) => {
        setIsRequesting(true);
        const request_system_settings = {
            ...system_settings[0],
            rate_discount_rules: requestData.rate_discount_rules,
        };

        //debugger;
        let payload = {
            "resource_id": app_state.property_id,
            "resource_type": "property",
            "update_type": "full_system_settings",
            "data": {
                "full_ss": request_system_settings
            }
        };

        let ss_api_config = deepCopy(API_REGISTRY['SaveSystemSettings']);
        let active_ds_id = dsInfo.active_source.id;
        ss_api_config.uri = ss_api_config.uri + app_state.property_id + '/' + active_ds_id + '/';

        dataService
            .update({ api: ss_api_config.uri, request: { ...ss_api_config.payload, ...payload } })
            .then((data) => {
                //Do some task here just after respons
                if (data.status === "success") {
                    setIsRequestSuccess(true);
                    setIsRequesting(false);
                    setTimeout(() => {
                        profile.property_details[prop_details_index].system_settings[0] =
                            request_system_settings;
                        dispatch({ type: SYSTEM_SETTINGS_UPDATED, payload: profile });
                    }, 500);
                    setTimeout(() => {
                        setIsRequestSuccess(false);
                    }, 5000);
                } else {
                    setIsRequestError(true);
                    setIsRequesting(false);
                    setResErrorMsg(data.message);
                    //return Promise.reject(data);
                }
            })
            .catch((err) => {
                setIsRequesting(false);
                setIsRequestError(true);
                setResErrorMsg(
                    (err && err.original && err.original.statusText) ||
                    "Something went wrong, please try again later!"
                );
                setTimeout(() => {
                    setIsRequestError(false);
                }, 5000);
                //return Promise.reject(err);
            });
    };

    // console.log(system_settings);

    return (
        <div>
            <Box className='breadcrumb' sx={{ color: 'white.main', position: 'absolute', top: -40 }}>
                <span className="breadcrum-inactive">Settings</span>
                <ArrowForwardIosOutlinedIcon className="breadcrum-inactive"
                    sx={{ top: 3, position: "relative", height: 18 }}
                ></ArrowForwardIosOutlinedIcon>
                <span className="breadcrum-inactive">Property Settings</span>
                <ArrowForwardIosOutlinedIcon className="breadcrum-inactive"
                    sx={{ top: 3, position: "relative", height: 18 }}
                ></ArrowForwardIosOutlinedIcon>
                <span className="breadcrum-active">Discount Rules</span>
            </Box>
            <div className="property-setting-heading">
                <Typography
                    className="property-setting-main-heading"
                    // variant="h1"
                    gutterBottom
                    sx={{ mr: 3, display: { lg: "inline-block", xs: "none" } }}
                >
                    Discount Rules
                </Typography>
                <Box sx={{ float: "right" }}>
                    <Button
                        disabled={!isChangeRequest}
                        onClick={() => {
                            saveChanges();
                        }}
                        variant="outlined"
                        size="small"
                        className={(isChangeRequest) ? "navigate-button btn-active" : "navigate-button"}

                        // color="green"
                        sx={{ minWidth: "auto", mr: 1, color: "white.main" }}
                    >
                        <span style={{ display: !isrequesting ? "block" : "none" }}>
                            Save
                        </span>
                        <span
                            style={{
                                display: isrequesting ? "block" : "none",
                                paddingRight: 10,
                            }}
                        >
                            saving...
                        </span>
                        <CircularProgress
                            sx={{ display: isrequesting ? "block" : "none" }}
                            color="white"
                            size="16px"
                        />
                    </Button>
                    <Button
                        disabled={!isChangeRequest}
                        onClick={cancelSave}
                        variant="outlined"
                        className={(isChangeRequest) ? "navigate-button btn-active" : "navigate-button"}

                        // color="error"
                        size="small"
                        sx={{ minWidth: "auto" }}
                    >
                        cancel
                    </Button>
                </Box>
                <Divider sx={{ clear: "both", mt: { xs: 2, lg: 0 } }} />
            </div>

            <Box className="inventory-settings border-radious4 " sx={{ p: 1, pt: 0, mb: 2, mt: 2, ml: 1, mr: 1 }}>

                {/* <Divider sx={{ clear: "both", mt: { xs: 2, lg: 0 } }} /> */}

                <Grid>
                    <Box
                        sx={{
                            pt: 0,
                            flexDirection: "column",
                            position: "relative",
                            display: "flex",
                        }}
                    >
                        <Alert
                            severity="success"
                            sx={{
                                width: "100%",
                                marginTop: -10,
                                display: isRequestSuccess ? "flex" : "none",
                            }}
                        >
                            {requestSuccessMsg}
                        </Alert>
                        <Alert
                            severity="error"
                            sx={{
                                width: "100%",
                                marginTop: -10,
                                display: isRequestError ? "flex" : "none",
                            }}
                        >
                            {resErrorMsg}
                        </Alert>

                        <Box sx={{ mt: { lg: 0 } }}>
                            <Box sx={{}}>
                                <Grid
                                    container
                                    spacing={0}
                                    sx={{ fontSize: 14, mt: 0, pb: 1, fontWeight: "bold" }}
                                >
                                    <Grid item lg={3}>
                                        <FormControlLabel
                                            sx={{ m: 0, p: 0 }}
                                            control={
                                                <Switch
                                                    className="switch-button"
                                                    size="small"
                                                    // color="green"
                                                    checked={requestData.rate_discount_rules.enabled}
                                                    onChange={(e) => {
                                                        updateRequestData(
                                                            "rate_discount_rules>enabled",
                                                            e.currentTarget.value
                                                        );
                                                    }}
                                                />
                                            }
                                            label="Enable Discount"
                                        />
                                    </Grid>
                                    <Grid
                                        item
                                        lg={9}
                                        sx={{
                                            visibility: requestData.rate_discount_rules.enabled
                                                ? "visible"
                                                : "hidden",
                                            pt: 2,
                                        }}
                                    >
                                        <FormControl className="textfield" fullWidth size="small">
                                            <InputLabel>MARKUP</InputLabel>
                                            <OutlinedInput
                                                className="input-text"
                                                label="MARKUP"
                                                value={requestData.rate_discount_rules.default.markup}
                                                onChange={(e) => {
                                                    updateRequestData(
                                                        "rate_discount_rules>markup",
                                                        e.currentTarget.value
                                                    );
                                                }}
                                                endAdornment={
                                                    <InputAdornment position="end">%</InputAdornment>
                                                }
                                            />
                                        </FormControl>
                                    </Grid>
                                </Grid>
                                <Grid
                                    className="property-heading-title-heading"
                                    container
                                    spacing={0}
                                    sx={{
                                        visibility: requestData.rate_discount_rules.enabled
                                            ? "visible"
                                            : "hidden",
                                        fontSize: 12.5,
                                        mt: 1,
                                        pb: 1,
                                        // borderTop: "1px solid #ccc",
                                        // borderBottom: "1px solid #ccc",
                                        // fontWeight: "bold",
                                        // color: "#01a4ef",
                                    }}
                                >
                                    <Grid item xs={2}>
                                        MONTHS
                                    </Grid>
                                    <Grid item xs={5}>
                                        BRAND.COM
                                    </Grid>
                                    <Grid item xs={5}>
                                        OTA
                                    </Grid>
                                </Grid>
                                <Box
                                    sx={{
                                        visibility: requestData.rate_discount_rules.enabled
                                            ? "visible"
                                            : "hidden",
                                        maxHeight: "48vh",
                                        overflowY: "auto",
                                    }}
                                >
                                    {months.map((item, index) => {
                                        return (
                                            <Grid container spacing={1} sx={{ fontSize: 12.5, pt: 1 }}>
                                                <Grid item xs={2} sx={{ lineHeight: 3 }}>
                                                    {moment().month(index).format("MMMM").toUpperCase()}
                                                </Grid>
                                                <Grid item xs={4}>
                                                    <FormControl className="textfield" fullWidth size="small">
                                                        <OutlinedInput className="input-text"
                                                            value={
                                                                requestData.rate_discount_rules.default.discount
                                                                    .branddotcom.month[item]
                                                            }
                                                            onChange={(e) => {
                                                                updateRequestData(
                                                                    "rate_discount_rules>branddotcom",
                                                                    e.target.value,
                                                                    index
                                                                );
                                                            }}
                                                            endAdornment={
                                                                <InputAdornment position="end">%</InputAdornment>
                                                            }
                                                        />
                                                    </FormControl>
                                                </Grid>
                                                <Grid item xs={1}></Grid>
                                                <Grid item xs={4}>
                                                    <FormControl className="textfield" fullWidth size="small">
                                                        <OutlinedInput className="input-text"
                                                            value={
                                                                requestData.rate_discount_rules.default.discount
                                                                    .ota.month[item]
                                                            }
                                                            onChange={(e) => {
                                                                updateRequestData(
                                                                    "rate_discount_rules>ota",
                                                                    e.target.value,
                                                                    index
                                                                );
                                                            }}
                                                            endAdornment={
                                                                <InputAdornment position="end">%</InputAdornment>
                                                            }
                                                        />
                                                    </FormControl>
                                                </Grid>
                                            </Grid>
                                        );
                                    })}
                                </Box>

                                {/* <Divider sx={{clear:'both', mt:1, mb:2}} /> */}
                            </Box>
                        </Box>
                        {isrequesting && (
                            <Box
                                sx={{
                                    textAlighn: "center",
                                    backgroundColor: "dark.darkest",
                                    opacity: 0.8,
                                    position: "fixed",
                                    top: 40,
                                    left: 0,
                                    width: "100%",
                                    height: "100%",
                                }}
                            >
                                <CircularProgress
                                    size={40}
                                    sx={{
                                        color: "white",
                                        position: "absolute",
                                        top: "50%",
                                        left: "50%",
                                        marginTop: "-20px",
                                        marginLeft: "-20px",
                                    }}
                                />
                            </Box>
                        )}
                    </Box>
                </Grid>

            </Box>
        </div>
    );
}

function AlertsSettings(props) {
    const dispatch = useDispatch();

    const [isrequesting, setIsRequesting] = useState(false);
    const [resError, setResError] = useState(false);
    const [isRequestSuccess, setIsRequestSuccess] = useState(false);
    const [requestSuccessMsg, setRequestSuccessMsg] = useState(
        "Your change request for settings was successfull!"
    );
    const [isRequestError, setIsRequestError] = useState(false);
    const [resErrorMsg, setResErrorMsg] = useState("");
    const [expanded, setExpanded] = useState({});

    const { app, auth } = useSelector((state) => state);
    const { user, profile } = auth;
    const { app_state, ui_state } = app.current_state;
    const { property_details } = profile || [];
    const [openConfirm, setOpenConfirm] = useState(false);
    const [selectedAlert, setSelectedAlert] = useState({});
    //Data source information
    const [dsInfo, setDsInfo] = useState(getDataSources(property_details, app_state.property_id));
    let prop_details_index = 0;
    const prop_details = property_details.filter((item, index) => {
        if (item.id === app_state.property_id) {
            prop_details_index = index;
            return item.id === app_state.property_id;
        }
        //
    });
    const { system_settings } =
        prop_details && prop_details.length ? prop_details[0] : null;

    const settings_ = JSON.parse(
        JSON.stringify({
            notification_settings: system_settings[0].notification_settings,
        })
    );
    const settings = JSON.parse(
        JSON.stringify({
            notification_settings: system_settings[0].notification_settings,
        })
    );

    //const settings = JSON.parse(JSON.stringify(system_settings[0].market_segment_category_mapping));
    const [requestData, setRequestData] = useState(settings);
    const [isChangeRequest, setIsChangeRequest] = useState(false);

    const [selectedResource, setSelectedResource] = useState("market");
    const [compsetNames, setCompsetNames] = useState([]);
    let compsetPropOptions = compsetNames.map(item => {
        return { display: item, value: item };
    });
    const resourceOptions = [{ display: "Market", value: "market" },
    { display: "Hotel", value: "property" },
    { display: "Compset", value: "compset" },
    ...compsetPropOptions
    ]
    const getKpiOptions = (selectedResource) => {
        let options = [];

        switch (selectedResource) {
            case "market":
                options = [{ display: "Occupancy", value: "occ" },
                { display: "ADR", value: "adr" },
                { display: "RevPar", value: "revpar" },
                { display: "Revenue", value: "revenue" },
                { display: "Rooms Sold", value: "rooms_sold" },
                { display: "Rooms Available", value: "rooms_available" },
                ]
                break;
            case "property":
                options = [{ display: "Occupancy", value: "occ" },
                { display: "ADR", value: "adr" },
                { display: "RevPar", value: "revpar" },
                { display: "Revenue", value: "revenue" },
                { display: "Rooms Sold", value: "rooms_sold" },
                { display: "Rooms Available", value: "rooms_available" },
                { display: "Rate", value: "rate" },
                ]
                break;
            case "compset":
                options = [{ display: "Median Rate", value: "median" },
                { display: "Average Rate", value: "average" },

                ]
                break;

            default:
                options = [{ display: "Rate", value: "rate" },
                ]
                break;
        }
        return options
    }


    const [kpiOptions, setkpiOptions] = useState(getKpiOptions(selectedResource))
    const [selectedKpi, setSelectedKpi] = useState(kpiOptions[0].value);

    const getUnitOptions = (selectedKpi) => {
        let options = [];
        if (selectedKpi === "occ") {
            options = [{ display: "%", value: "percentage" }]

        }
        if (selectedKpi === "rooms_sold") {
            options = [{ display: "Rooms", value: "rooms" }]
        }
        if (selectedKpi === "rooms_available") {
            options = [{ display: "Rooms", value: "rooms" },
            { display: "%", value: "percentage" }
            ]
        }
        if (selectedKpi === "adr" || selectedKpi === "revpar" || selectedKpi === "revenue" ||
            selectedKpi === "median" || selectedKpi === "average" || selectedKpi === "rate") {
            options = [{ display: "%", value: "percentage" },
            { display: ui_state.currency_symbol, value: ui_state.currency_symbol }
            ]
        }

        return options;

    }
    const [unitOptions, setUnitOptions] = useState(getUnitOptions(selectedKpi));
    const [selectedUnit, setSelectedUnit] = useState(unitOptions[0].value);
    const [delta, setDelta] = useState("decrease");
    const [unitVal, setUnitVal] = useState("");
    const [days, setDays] = useState("");
    const [ruleAlerts, setRuleAlerts] = useState([]);

    const getAlerts = async () => {
        let requestAPI = "alerts/rules/" + app_state.property_id + "/"
        try {
            let response = await fetchDataSimple({ api: requestAPI, request: { "method": "GET" } });
            console.log(response);
            if (response.status === "success" && response.setting) {
                setRuleAlerts(response.setting.rules)
            }

        } catch (error) {
            console.log(error);
        }

    };
    const getCompsetNames = async () => {
        let requestAPI = "rms/properties/compset-names/" + app_state.property_id + "/";
        try {
            let response = await fetchDataSimple({ api: requestAPI, request: { "method": "GET" } });
            console.log(response);
            if (response.status === "success") {
                setCompsetNames(response.compset)
            }

        } catch (error) {
            console.log(error);
        }
    }
    useEffect(() => {
        getCompsetNames();
        getAlerts();
        return () => {

        }
    }, [])
    const cancelSave = () => {
        setRequestData();
        setIsChangeRequest(false);
    };
    // const updateRequestData = (keys, val, index) => {
    //     const updatedObj = { ...requestData };
    //     const key = keys.split(">");

    //     if (key[0] == "notification_settings") {
    //         if (key[1] == "dynamic_notifications") {
    //             updatedObj[key[0]][key[1]][index][key[2]] = val;
    //         } else {
    //             if (key[2] == "threshold_value" || key[2] == "range_horizon") {
    //                 updatedObj[key[0]][key[1]][key[2]] = isNaN(Number(val))
    //                     ? 0
    //                     : Number(val);
    //             } else {
    //                 updatedObj[key[0]][key[1]][key[2]] = val;
    //             }
    //         }
    //     }

    //     setRequestData({ ...requestData, ...updatedObj });
    //     setIsChangeRequest(true);
    // };

    const addNewAlert = async () => {
        setIsRequesting(true);
        let alertType = "pickup";
        if (selectedResource !== "market" && selectedResource !== "property") {
            alertType = "compset";
        }
        let newAlert = {
            "name": "",
            "resource_type": selectedResource,
            "alert_type": alertType,
            "kpi": selectedKpi,
            "delta": delta,
            "threshold": unitVal,
            "unit": selectedUnit,
            //"dba_range": days,
            "pickup": days
        };
        let payload = {
            "newRules": [
                { ...newAlert }

            ],
            "method": "POST"
        }
        let requestAPI = "alerts/rules/" + app_state.property_id + "/"
        try {
            let response = await fetchDataSimple({ api: requestAPI, request: payload });
            console.log(response);
            if (response.status === "success" && response.setting) {
                setRuleAlerts(response.setting.rules);
                setDays(prev => "");
                setUnitVal(prev => "");
            }
            setIsRequesting(false)
        } catch (error) {
            console.log(error);
            setIsRequesting(false)
        }
        setRequestData({ ...requestData });
    };
    const deleteItem = async (alert) => {
        setIsRequesting(true);
        console.log(alert);
        let payload = {
            "uid": alert.uid,
            "method": "DELETE"
        }
        let requestAPI = "alerts/rules/" + app_state.property_id + "/"
        try {
            let response = await fetchDataSimple({ api: requestAPI, request: payload });
            console.log(response);
            if (response.status === "success" && response.setting) {
                setRuleAlerts(response.setting.rules)
            }
            setIsRequesting(false);
        } catch (error) {
            console.log(error);
            setIsRequesting(false);
        }
    };

    const handleConfirmation = (index) => {
        deleteItem(selectedAlert);
        setOpenConfirm(false)
    };
    const handleConfirmationCancel = () => {
        setOpenConfirm(false)
    }


    // console.log(system_settings);
    let menuItemStyle = {
        textTransform: "uppercase",
        fontSize: "13px"
    }
    let createAlertBox = (<Paper elevation={2} className="create-alert-section" variant="outlined" sx={{ mb: 1, overflow: "hidden" }} >
        <h2 className="title" >Create New Alerts</h2>
        <Box sx={{ fontSize: "15px", pb: 1 }}>
            <p >
                If
                <Select
                    size="small"
                    sx={{ height: "26px", mr: 1 }}
                    value={selectedResource}
                    className="create-alert-select"
                    onChange={(e) => {
                        const value = e.target.value;
                        setSelectedResource(value);
                        const kpiOptions = getKpiOptions(value);
                        setSelectedKpi(kpiOptions[0].value);
                        setkpiOptions(kpiOptions);
                        const unitOptions = getUnitOptions(kpiOptions[0].value);
                        setUnitOptions(unitOptions);
                        setSelectedUnit(unitOptions[0].value)
                    }}
                >
                    {resourceOptions.map((item, index) => {
                        return (<MenuItem key={index} value={item.value} style={menuItemStyle}>{item.display}</MenuItem>)
                    })}

                </Select>
                <Select
                    size="small"
                    className="create-alert-select"
                    sx={{ height: "26px", mr: 1 }}
                    value={selectedKpi}
                    onChange={(e) => {
                        const value = e.target.value;
                        setSelectedKpi(value);
                        const unitOptions = getUnitOptions(value);
                        setSelectedUnit(unitOptions[0].value);
                        setUnitOptions(unitOptions);
                    }}
                >
                    {kpiOptions.map((item, index) => {
                        return (<MenuItem key={index} style={menuItemStyle} value={item.value}>{item.display}</MenuItem>)
                    })}
                </Select>
                <Select
                    size="small"
                    className="create-alert-select"
                    sx={{ height: "26px", mr: 1 }}
                    value={delta}
                    onChange={(e) => {
                        const value = e.target.value;
                        setDelta(value);
                    }}
                >

                    <MenuItem style={menuItemStyle} value="decrease">Decrease</MenuItem>
                    <MenuItem style={menuItemStyle} value="increase">Increase</MenuItem>
                </Select>
                by
                <OutlinedInput
                    value={unitVal}
                    className="create-alert-input"
                    onChange={e => {
                        let { value } = e.target;
                        setUnitVal(prev => {
                            return value;
                        });
                    }}
                    onBlur={e => {
                        let { value } = e.target;
                        value = Number(value);
                        if (isNaN(value) || value <= 0) {
                            value = "";
                        }
                        setUnitVal(prev => {
                            return value;
                        });
                    }}
                />
                <Select
                    size="small"
                    className="create-alert-select"
                    sx={{ height: "26px", mr: 1 }}
                    value={selectedUnit}
                    onChange={(e) => {
                        const value = e.target.value;
                        console.log(value);
                        setSelectedUnit(value);

                    }}
                >
                    {unitOptions.map((item, index) => {
                        return (<MenuItem style={menuItemStyle} value={item.value} key={index} >{item.display}</MenuItem>)
                    })}

                </Select>
                compared to past
                <OutlinedInput
                    value={days}
                    className="create-alert-input"
                    onChange={e => {
                        let { value } = e.target;
                        setDays(prev => {
                            return value;
                        });
                    }}
                    onBlur={e => {
                        let { value } = e.target;
                        value = toInteger(value);
                        if (isNaN(value) || value <= 0) {
                            value = "";
                        }
                        setDays(prev => {
                            return value;
                        });
                    }}
                />
                days
            </p>
        </Box>
        <Box>
            {/* <Button
            // disabled={!isChangeRequest}
            onClick={cancelSave}
            variant="outlined"
            className={(true) ? "navigate-button btn-active" : "navigate-button"}

            // color="error"
            size="small"
            sx={{ minWidth: "auto" }}
        >
            cancel
        </Button> */}
            <Button
                disabled={
                    !days || !unitVal
                }
                onClick={() => {
                    addNewAlert();
                }}
                variant="outlined"
                size="small"
                className={(!days || !unitVal) ? "navigate-button" : "navigate-button btn-active"}

                // color="green"
                sx={{ minWidth: "auto", mr: 1, color: "white.main" }}
            >
                <span style={{ display: !isrequesting ? "block" : "none" }}>
                    Save
                </span>
                <span
                    style={{
                        display: isrequesting ? "block" : "none",
                        paddingRight: 10,
                    }}
                >
                    saving...
                </span>
                <CircularProgress
                    sx={{ display: isrequesting ? "block" : "none" }}
                    color="white"
                    size="16px"
                />
            </Button>


        </Box>
    </Paper>)
    return (
        <div>
            <Box className='breadcrumb' sx={{ color: 'white.main', position: 'absolute', top: -40 }}>
                Settings
                <ArrowForwardIosOutlinedIcon
                    sx={{ top: 3, position: "relative", height: 18 }}
                ></ArrowForwardIosOutlinedIcon>
                Property Settings
                <ArrowForwardIosOutlinedIcon
                    sx={{ top: 3, position: "relative", height: 18 }}
                ></ArrowForwardIosOutlinedIcon>
                Alerts
            </Box>
            <div className="property-setting-heading">
                <Typography
                    className="property-setting-main-heading"
                    // variant="h1"
                    gutterBottom
                    sx={{ mr: 3, display: { lg: "inline-block", xs: "none" } }}
                >
                    Alerts
                </Typography>
                <Box sx={{ float: "right" }}>

                </Box>
                <Divider sx={{ clear: "both", mt: { xs: 2, lg: 0 } }} />
            </div>

            <Box className="inventory-settings alert-settings" sx={{ p: 1, pt: 0, mb: 2, mt: 0, ml: 1, mr: 1 }}>
                <UserConfirmationAlert open={openConfirm}
                    onConfirm={handleConfirmation} index={1} onCancel={handleConfirmationCancel} />

                <Grid>
                    <Box
                        sx={{
                            pt: 2,
                            flexDirection: "column",
                            position: "relative",
                            display: "flex",
                        }}
                    >
                        <Alert
                            severity="success"
                            sx={{
                                width: "100%",
                                marginTop: -10,
                                display: isRequestSuccess ? "flex" : "none",
                            }}
                        >
                            {requestSuccessMsg}
                        </Alert>
                        <Alert
                            severity="error"
                            sx={{
                                width: "100%",
                                marginTop: -10,
                                display: isRequestError ? "flex" : "none",
                            }}
                        >
                            {resErrorMsg}
                        </Alert>

                        <Box sx={{ mt: { lg: 0 } }}>


                            {createAlertBox}
                            <Box sx={{ mt: 1, mb: 2 }}>
                                {ruleAlerts.map(
                                    (item, index) => {
                                        return (
                                            <Paper elevation={2} sx={{ mb: 1, overflow: "hidden" }} variant="outlined">
                                                <Grid
                                                    container
                                                    className="header alertitem"
                                                // sx={{ backgroundColor: "#F9F9F9" }}
                                                > <div className="rate-push-badge">{index + 1}</div>
                                                    <Grid item xs={11} className="alert-text-section"
                                                    >

                                                        <p className="alert-text">{item.text_rule}</p>
                                                        <div className="bottom-text">{item.desc}</div> {/* might change to bottom text */}
                                                        {/* <div className="bottom-text">Created By: {item.created_by}, Created Date: {moment(item.ts).format("ddd, MMM DD, YYYY")}</div> */}
                                                    </Grid>
                                                    {/* <Grid item xs={1} textAlign="right">
                                                        <Switch
                                                            className="switch-button alert-swicth-button"
                                                            size="medium"
                                                            // color="green"
                                                            checked={item.active}
                                                            onChange={(e) => {
                                                                const checked = e.target.checked;

                                                            }}
                                                        />
                                                        
                                                    </Grid> */}
                                                    <Grid item xs={1} textAlign="right">
                                                        <Button
                                                            variant="text"
                                                            onClick={() => {
                                                                setOpenConfirm(true);
                                                                setSelectedAlert(item);
                                                            }}
                                                            className="deleteBtn"
                                                        >
                                                            <Tooltip title="Delete">
                                                                <DeleteOutlineOutlinedIcon
                                                                    fontSize="small"
                                                                    color="error"
                                                                    className="arrow-down inside-line-rateplan expend-arrow delete-icon-button"
                                                                ></DeleteOutlineOutlinedIcon>

                                                            </Tooltip>
                                                        </Button>

                                                    </Grid>
                                                </Grid>


                                            </Paper>
                                        );
                                    }
                                )}
                            </Box>

                        </Box>
                        {isrequesting && (
                            <Box
                                sx={{
                                    textAlighn: "center",
                                    backgroundColor: "dark.darkest",
                                    opacity: 0.8,
                                    position: "fixed",
                                    top: 40,
                                    left: 0,
                                    width: "100%",
                                    height: "100%",
                                }}
                            >
                                <CircularProgress
                                    size={40}
                                    sx={{
                                        color: "white",
                                        position: "absolute",
                                        top: "50%",
                                        left: "50%",
                                        marginTop: "-20px",
                                        marginLeft: "-20px",
                                    }}
                                />
                            </Box>
                        )}
                    </Box>
                </Grid>

            </Box>
        </div>
    );
}

function ManageRatePlans(props) {
    const dispatch = useDispatch();

    const [isrequesting, setIsRequesting] = useState(false);
    const [resError, setResError] = useState(false);
    const [isRequestSuccess, setIsRequestSuccess] = useState(false);
    const [requestSuccessMsg, setRequestSuccessMsg] = useState(
        "Your change request for settings was successfull!"
    );
    const [isRequestError, setIsRequestError] = useState(false);
    const [resErrorMsg, setResErrorMsg] = useState("");
    const [expanded, setExpanded] = useState({});

    const { app, auth } = useSelector((state) => state);
    const { user, profile } = auth;
    const { app_state, ui_state } = app.current_state;
    const { property_details } = profile || [];
    //Data source information
    const [dsInfo, setDsInfo] = useState(getDataSources(property_details, app_state.property_id));
    let prop_details_index = 0;
    const prop_details = property_details.filter((item, index) => {
        if (item.id === app_state.property_id) {
            prop_details_index = index;
            return item.id === app_state.property_id;
        }
        //
    });
    const { system_settings } =
        prop_details && prop_details.length ? prop_details[0] : null;

    const settings_ = JSON.parse(
        JSON.stringify({
            notification_settings: system_settings[0].notification_settings,
        })
    );
    const settings = JSON.parse(
        JSON.stringify({
            notification_settings: system_settings[0].notification_settings,
        })
    );
    if (
        settings.notification_settings.pickup_up_adr.threshold_type === "percent"
    ) {
        settings.notification_settings.pickup_up_adr.threshold_value = Math.round(
            settings.notification_settings.pickup_up_adr.threshold_value * 100
        );
    }
    if (
        settings.notification_settings.pickup_down_adr.threshold_type === "percent"
    ) {
        settings.notification_settings.pickup_down_adr.threshold_value = Math.round(
            settings.notification_settings.pickup_down_adr.threshold_value * 100
        );
    }
    if (
        settings.notification_settings.pickup_up_occ.threshold_type === "percent"
    ) {
        settings.notification_settings.pickup_up_occ.threshold_value = Math.round(
            settings.notification_settings.pickup_up_occ.threshold_value * 100
        );
    }
    if (
        settings.notification_settings.pickup_down_occ.threshold_type === "percent"
    ) {
        settings.notification_settings.pickup_down_occ.threshold_value = Math.round(
            settings.notification_settings.pickup_down_occ.threshold_value * 100
        );
    }

    //const settings = JSON.parse(JSON.stringify(system_settings[0].market_segment_category_mapping));
    const [requestData, setRequestData] = useState(settings);
    const [isChangeRequest, setIsChangeRequest] = useState(false);

    const cancelSave = () => {
        setRequestData(settings_);
        setIsChangeRequest(false);
    };
    const updateRequestData = (keys, val, index) => {
        const updatedObj = { ...requestData };
        const key = keys.split(">");

        if (key[0] == "notification_settings") {
            if (key[1] == "dynamic_notifications") {
                updatedObj[key[0]][key[1]][index][key[2]] = val;
            } else {
                if (key[2] == "threshold_value" || key[2] == "range_horizon") {
                    updatedObj[key[0]][key[1]][key[2]] = isNaN(Number(val))
                        ? 0
                        : Number(val);
                } else {
                    updatedObj[key[0]][key[1]][key[2]] = val;
                }
            }
        }

        setRequestData({ ...requestData, ...updatedObj });
        setIsChangeRequest(true);
    };

    const addNewDynamicAlert = () => {
        const item = {
            active: false,
            name:
                "New Dynamic Notification " +
                (requestData.notification_settings.dynamic_notifications.length + 1),
            reference_source: "hotel_otb",
            field: "occ",
            field_comparison: "higher",
            comparison_operation: "",
            reference_comparison_source: "yeseterday_hotel_otb",
            field_scope: "total",
            field_scope_filter: "BAR",
            alert_level: "FYI",
        };
        requestData.notification_settings.dynamic_notifications.push(item);
        setRequestData({ ...requestData });
    };


    const deleteItem = (index) => {
        requestData.notification_settings.dynamic_notifications.splice(index, 1);
        setRequestData({ ...requestData });
    };
    const saveChanges = (event) => {
        setIsRequesting(true);
        const notification_settings = JSON.parse(
            JSON.stringify(requestData.notification_settings)
        );
        if (notification_settings.pickup_up_adr.threshold_type === "percent") {
            notification_settings.pickup_up_adr.threshold_value =
                notification_settings.pickup_up_adr.threshold_value / 100;
        }
        if (notification_settings.pickup_down_adr.threshold_type === "percent") {
            notification_settings.pickup_down_adr.threshold_value =
                notification_settings.pickup_down_adr.threshold_value / 100;
        }
        if (notification_settings.pickup_up_occ.threshold_type === "percent") {
            notification_settings.pickup_up_occ.threshold_value =
                notification_settings.pickup_up_occ.threshold_value / 100;
        }
        if (notification_settings.pickup_down_occ.threshold_type === "percent") {
            notification_settings.pickup_down_occ.threshold_value =
                notification_settings.pickup_down_occ.threshold_value / 100;
        }
        const request_system_settings = {
            ...system_settings[0],
            notification_settings: notification_settings,
        };

        // debugger;
        let payload = {
            "resource_id": app_state.property_id,
            "resource_type": "property",
            "update_type": "full_system_settings",
            "data": {
                "full_ss": request_system_settings
            }
        };

        let ss_api_config = deepCopy(API_REGISTRY['SaveSystemSettings']);
        let active_ds_id = dsInfo.active_source.id;
        ss_api_config.uri = ss_api_config.uri + app_state.property_id + '/' + active_ds_id + '/';

        dataService
            .update({ api: ss_api_config.uri, request: { ...ss_api_config.payload, ...payload } })
            .then((data) => {
                //Do some task here just after respons
                if (data.status === "success") {
                    setIsRequestSuccess(true);
                    setIsRequesting(false);
                    setTimeout(() => {
                        profile.property_details[prop_details_index].system_settings[0] =
                            request_system_settings;
                        dispatch({ type: SYSTEM_SETTINGS_UPDATED, payload: profile });
                    }, 500);
                    setTimeout(() => {
                        setIsRequestSuccess(false);
                    }, 5000);
                } else {
                    setIsRequestError(true);
                    setIsRequesting(false);
                    setResErrorMsg(data.message);
                    //return Promise.reject(data);
                }
            })
            .catch((err) => {
                setIsRequesting(false);
                setIsRequestError(true);
                setResErrorMsg(
                    (err && err.original && err.original.statusText) ||
                    "Something went wrong, please try again later!"
                );
                setTimeout(() => {
                    setIsRequestError(false);
                }, 5000);
                //return Promise.reject(err);
            });
    };

    const handleExpand = (index) => {
        if (!expanded["id_" + index]) {
            expanded["id_" + index] = true;
        } else {
            expanded["id_" + index] = false;
        }
        setExpanded({ ...expanded });
    };
    // console.log(system_settings);

    return (
        <div>
            <Box className='breadcrumb' sx={{ color: 'white.main', position: 'absolute', top: -40 }}>
                Settings
                <ArrowForwardIosOutlinedIcon
                    sx={{ top: 3, position: "relative", height: 18 }}
                ></ArrowForwardIosOutlinedIcon>
                Property Settings
                <ArrowForwardIosOutlinedIcon
                    sx={{ top: 3, position: "relative", height: 18 }}
                ></ArrowForwardIosOutlinedIcon>
                Manage Rate Plans
            </Box>
            <div className="property-setting-heading">
                <Typography
                    className="property-setting-main-heading"
                    // variant="h1"
                    gutterBottom
                    sx={{ mr: 3, display: { lg: "inline-block", xs: "none" } }}
                >
                    Manage Rate Plans
                </Typography>
                <Box sx={{ float: "right" }}>
                    <Button
                        disabled={!isChangeRequest}
                        onClick={() => {
                            saveChanges();
                        }}
                        variant="outlined"
                        size="small"
                        color="green"
                        sx={{ minWidth: "auto", mr: 1, color: "white.main" }}
                    >
                        <span style={{ display: !isrequesting ? "block" : "none" }}>
                            Save
                        </span>
                        <span
                            style={{
                                display: isrequesting ? "block" : "none",
                                paddingRight: 10,
                            }}
                        >
                            saving...
                        </span>
                        <CircularProgress
                            sx={{ display: isrequesting ? "block" : "none" }}
                            color="white"
                            size="16px"
                        />
                    </Button>
                    <Button
                        disabled={!isChangeRequest}
                        onClick={cancelSave}
                        variant="outlined"
                        color="error"
                        size="small"
                        sx={{ minWidth: "auto" }}
                    >
                        cancel
                    </Button>
                </Box>
                <Divider sx={{ clear: "both", mt: { xs: 2, lg: 0 } }} />
            </div>

            <Box className="inventory-settings border-radious4 " sx={{ p: 1, pt: 0, mb: 2, mt: 2, ml: 1, mr: 1 }}>


                {/* <Divider sx={{ clear: "both", mt: { xs: 2, lg: 0 } }} /> */}
                <Paper>
                    <Grid className="property-setting-container-spacing">
                        <Box
                            sx={{
                                pt: 2,
                                flexDirection: "column",
                                position: "relative",
                                display: "flex",
                            }}
                        >
                            <Alert
                                severity="success"
                                sx={{
                                    width: "100%",
                                    marginTop: -10,
                                    display: isRequestSuccess ? "flex" : "none",
                                }}
                            >
                                {requestSuccessMsg}
                            </Alert>
                            <Alert
                                severity="error"
                                sx={{
                                    width: "100%",
                                    marginTop: -10,
                                    display: isRequestError ? "flex" : "none",
                                }}
                            >
                                {resErrorMsg}
                            </Alert>

                            <Box sx={{ mt: { lg: 0 } }}>
                                <Box sx={{ mt: 1, mb: 2, display: "none" }}>
                                    {requestData.notification_settings.dynamic_notifications.map(
                                        (item, index) => {
                                            return (
                                                <Paper elevation={2} sx={{ mb: 1, overflow: "hidden" }}>
                                                    <Grid
                                                        container
                                                        className="header"
                                                        sx={{ backgroundColor: "blue.main" }}
                                                    >
                                                        <Grid item xs={6}>
                                                            <Input
                                                                fullWidth
                                                                className="accordian-field"
                                                                size="small"
                                                                sx={{
                                                                    ml: 1,
                                                                    mr: 1,
                                                                    mt: 1,
                                                                    textAlign: "center",
                                                                    color: "blue.dark",
                                                                }}
                                                                value={item.name}
                                                                onChange={(e) => {
                                                                    const value = e.target.value;
                                                                    updateRequestData(
                                                                        "notification_settings>dynamic_notifications>name",
                                                                        value,
                                                                        index
                                                                    );
                                                                }}
                                                            />
                                                        </Grid>
                                                        <Grid item xs={3} textAlign="right">
                                                            <Switch
                                                                className=""
                                                                size="medium"
                                                                color="green"
                                                                checked={item.active}
                                                                onChange={(e) => {
                                                                    const checked = e.target.checked;
                                                                    updateRequestData(
                                                                        "notification_settings>dynamic_notifications>active",
                                                                        checked,
                                                                        index
                                                                    );
                                                                }}
                                                            />{" "}
                                                            <Typography
                                                                sx={{ color: "blue.dark", display: "inline-block" }}
                                                                visibility={item.active ? "hidden" : "visible"}
                                                            >
                                                                Activate
                                                            </Typography>
                                                        </Grid>
                                                        <Grid item xs={3} textAlign="right">
                                                            <Button
                                                                variant="text"
                                                                onClick={() => {
                                                                    deleteItem(index);
                                                                }}
                                                            >
                                                                <Tooltip title="Delete">
                                                                    <DeleteIcon
                                                                        fontSize="medium"
                                                                        color="warning"
                                                                    ></DeleteIcon>
                                                                </Tooltip>
                                                            </Button>
                                                            <Button
                                                                variant="contained"
                                                                type="button"
                                                                sx={{
                                                                    backgroundColor: "blue.dark",
                                                                    borderRadius: 0,
                                                                    border: "none",
                                                                    margin: 0,
                                                                    pb: 1.5,
                                                                }}
                                                                onClick={() => {
                                                                    handleExpand(index);
                                                                }}
                                                            >
                                                                <ArrowDropDownIcon
                                                                    sx={{
                                                                        display: expanded["id_" + index]
                                                                            ? "none"
                                                                            : "inline-block",
                                                                    }}
                                                                    className="arrow-down"
                                                                />
                                                                <ArrowDropUpIcon
                                                                    sx={{
                                                                        display: expanded["id_" + index]
                                                                            ? "inline-block"
                                                                            : "none",
                                                                    }}
                                                                    className="arrow-up"
                                                                />
                                                            </Button>
                                                        </Grid>
                                                    </Grid>

                                                    <Collapse
                                                        in={expanded["id_" + index]}
                                                        timeout="auto"
                                                        unmountOnExit
                                                    >
                                                        <Box sx={{ fontSize: "15px", p: 2 }}>
                                                            When
                                                            <Select
                                                                className="inline-select"
                                                                sx={{ mr: 1, ml: 1 }}
                                                                value={item.reference_source}
                                                                onChange={(e) => {
                                                                    const value = e.target.value;
                                                                    updateRequestData(
                                                                        "notification_settings>dynamic_notifications>reference_source",
                                                                        value,
                                                                        index
                                                                    );
                                                                }}
                                                            >
                                                                <MenuItem value="hotel_otb">on the books</MenuItem>
                                                                <MenuItem value="hotel_forecast">forecast</MenuItem>
                                                            </Select>
                                                            <Select
                                                                className="inline-select"
                                                                size="small"
                                                                sx={{ mr: 1, ml: 1 }}
                                                                value={item.field}
                                                                onChange={(e) => {
                                                                    const value = e.target.value;
                                                                    updateRequestData(
                                                                        "notification_settings>dynamic_notifications>field",
                                                                        value,
                                                                        index
                                                                    );
                                                                }}
                                                            >
                                                                <MenuItem value="occ">occ</MenuItem>
                                                                <MenuItem value="revpar">revpar</MenuItem>
                                                                <MenuItem value="adr">adr</MenuItem>
                                                                <MenuItem value="rooms">rooms</MenuItem>
                                                            </Select>
                                                            is
                                                            <Select
                                                                className="inline-select"
                                                                size="small"
                                                                sx={{ mr: 1, ml: 1 }}
                                                                value={item.field_comparison}
                                                                onChange={(e) => {
                                                                    const value = e.target.value;
                                                                    updateRequestData(
                                                                        "notification_settings>dynamic_notifications>field_comparison",
                                                                        value,
                                                                        index
                                                                    );
                                                                }}
                                                            >
                                                                <MenuItem value="higher">higher</MenuItem>
                                                                <MenuItem value="higher">lower</MenuItem>
                                                            </Select>
                                                            than
                                                            <Input
                                                                className="alert-input inline-input"
                                                                size="small"
                                                                sx={{ width: 40, ml: 0.5, mr: 0.5 }}
                                                                value={item.comparison_operation}
                                                                onChange={(e) => {
                                                                    const value = e.target.value;
                                                                    updateRequestData(
                                                                        "notification_settings>dynamic_notifications>comparison_operation",
                                                                        value,
                                                                        index
                                                                    );
                                                                }}
                                                            />
                                                            vs
                                                            <Select
                                                                className="inline-select"
                                                                size="small"
                                                                sx={{ mr: 1, ml: 1 }}
                                                                value={item.reference_comparison_source}
                                                                onChange={(e) => {
                                                                    const value = e.target.value;
                                                                    updateRequestData(
                                                                        "notification_settings>dynamic_notifications>reference_comparison_source",
                                                                        value,
                                                                        index
                                                                    );
                                                                }}
                                                            >
                                                                <MenuItem value="yeseterday_hotel_otb">
                                                                    yesterday's hotel otb
                                                                </MenuItem>
                                                                <MenuItem value="yeseterday_hotel_forecast">
                                                                    yesterday's hotel forecast
                                                                </MenuItem>
                                                                <MenuItem value="sdly_hotel_otb">
                                                                    sdly hotel otb
                                                                </MenuItem>
                                                                <MenuItem value="sdly_hotel_actual">
                                                                    sdly hotel actual{" "}
                                                                </MenuItem>
                                                            </Select>
                                                            <br />
                                                            <br />
                                                            Field Scope
                                                            <Select
                                                                className="inline-select"
                                                                size="small"
                                                                sx={{ mr: 1, ml: 1 }}
                                                                value={item.field_scope}
                                                                onChange={(e) => {
                                                                    const value = e.target.value;
                                                                    updateRequestData(
                                                                        "notification_settings>dynamic_notifications>field_scope",
                                                                        value,
                                                                        index
                                                                    );
                                                                }}
                                                            >
                                                                <MenuItem value="total">Total</MenuItem>
                                                                <MenuItem value="segmented">Segmented</MenuItem>
                                                            </Select>
                                                            {item.field_scope == "segmented" && (
                                                                <Box sx={{ display: "inline-block" }}>
                                                                    Field Scope Filter
                                                                    <Select
                                                                        className="inline-select"
                                                                        size="small"
                                                                        sx={{ mr: 1, ml: 1 }}
                                                                        value={item.field_scope_filter}
                                                                        onChange={(e) => {
                                                                            const value = e.target.value;
                                                                            updateRequestData(
                                                                                "notification_settings>dynamic_notifications>field_scope_filter",
                                                                                value,
                                                                                index
                                                                            );
                                                                        }}
                                                                    >
                                                                        {/* {system_settings[0].market_segment_category_mapping} */}
                                                                        {Object.keys(
                                                                            system_settings[0]
                                                                                .market_segment_category_mapping
                                                                        ).map((key) => {
                                                                            return <MenuItem value={key}>{key}</MenuItem>;
                                                                        })}
                                                                    </Select>
                                                                </Box>
                                                            )}
                                                            Alert level
                                                            <Select
                                                                className="inline-select"
                                                                size="small"
                                                                sx={{ mr: 1, ml: 1 }}
                                                                value={item.alert_level}
                                                                onChange={(e) => {
                                                                    const value = e.target.value;
                                                                    updateRequestData(
                                                                        "notification_settings>dynamic_notifications>alert_level",
                                                                        value,
                                                                        index
                                                                    );
                                                                }}
                                                            >
                                                                <MenuItem value="FYI">FYI</MenuItem>
                                                                <MenuItem value="Normal">Normal</MenuItem>
                                                                <MenuItem value="Urgent">Urgent</MenuItem>
                                                            </Select>
                                                        </Box>
                                                    </Collapse>
                                                </Paper>
                                            );
                                        }
                                    )}
                                </Box>
                                <Box sx={{ mt: 1, mb: 2 }}>
                                    <Button
                                        variant="outlined"
                                        size="small"
                                        className="add-button"
                                        sx={{
                                            lineHeight: "auto",
                                            backgroundColor: "#fff",
                                            // fontWeight: "bold",
                                        }}
                                        onClick={() => {
                                            addNewDynamicAlert();
                                        }}
                                    >
                                        Add New Plan
                                    </Button>
                                </Box>
                            </Box>
                            {isrequesting && (
                                <Box
                                    sx={{
                                        textAlighn: "center",
                                        backgroundColor: "dark.darkest",
                                        opacity: 0.8,
                                        position: "fixed",
                                        top: 40,
                                        left: 0,
                                        width: "100%",
                                        height: "100%",
                                    }}
                                >
                                    <CircularProgress
                                        size={40}
                                        sx={{
                                            color: "white",
                                            position: "absolute",
                                            top: "50%",
                                            left: "50%",
                                            marginTop: "-20px",
                                            marginLeft: "-20px",
                                        }}
                                    />
                                </Box>
                            )}
                        </Box>
                    </Grid>
                </Paper>
            </Box>
        </div>
    );
}

function PricingRules(props) {
    const dispatch = useDispatch();

    const [isrequesting, setIsRequesting] = useState(false);
    const [resError, setResError] = useState(false);
    const [isRequestSuccess, setIsRequestSuccess] = useState(false);
    const [requestSuccessMsg, setRequestSuccessMsg] = useState(
        "Your change request for settings was successfull!"
    );
    const [isRequestError, setIsRequestError] = useState(false);
    const [resErrorMsg, setResErrorMsg] = useState("");
    const [expanded, setExpanded] = useState({});

    const { app, auth } = useSelector((state) => state);
    const { user, profile } = auth;
    const { app_state, ui_state } = app.current_state;
    const { property_details } = profile || [];
    //Data source information
    const [dsInfo, setDsInfo] = useState(getDataSources(property_details, app_state.property_id));
    let prop_details_index = 0;
    const prop_details = property_details.filter((item, index) => {
        if (item.id === app_state.property_id) {
            prop_details_index = index;
            return item.id === app_state.property_id;
        }
        //
    });
    const { system_settings } =
        prop_details && prop_details.length ? prop_details[0] : null;

    const settings_ = JSON.parse(
        JSON.stringify({
            price_rule_settings: system_settings[0].price_rule_settings,
        })
    );
    const settings = JSON.parse(JSON.stringify(settings_));

    const [requestData, setRequestData] = useState(settings);
    const [isChangeRequest, setIsChangeRequest] = useState(false);

    const cancelSave = () => {
        setRequestData(settings_);
        setIsChangeRequest(false);
    };
    const updateRequestData = (keys, value, index) => {
        const updatedObj = { ...requestData };
        const key = keys.split(">");
        const size = key.length;
        let val = value;
        if (moment.isMoment(value)) {
            //val = value.format('YYYY-MM-DD');
        } else {
            //val = value
        }
        // debugger;
        if (size == 2) {
            updatedObj[key[0]]["rules"][index][key[1]] = val;
        }
        if (size == 3) {
            if (key[2] === "dow_filter") {
                updatedObj[key[0]]["rules"][index][key[1]][key[2]][val] =
                    !updatedObj[key[0]]["rules"][index][key[1]][key[2]][val];
            } else {
                updatedObj[key[0]]["rules"][index][key[1]][key[2]] = val;
            }
        }
        if (size == 4) {
            updatedObj[key[0]]["rules"][index][key[1]][key[2]][key[3]] = val;
        }
        if (size == 5) {
            updatedObj[key[0]]["rules"][index][key[1]][key[2]][key[3]][key[4]] = val;
        }

        setRequestData({
            ...requestData,
            price_rule_settings: updatedObj.price_rule_settings,
        });
        setIsChangeRequest(true);
    };

    const addNewPricingRule = () => {
        const today = new moment().format("YYYY-MM-DD");
        const tomorrow = new moment().add(1, "days").format("YYYY-MM-DD");
        const item = {
            rule_name:
                "Pricing Rule " + (requestData.price_rule_settings.rules.length + 1),
            open: false,
            rule_period: {
                blackout_date_ranges: [],
                effective_date_range: {
                    from: today,
                    until: tomorrow,
                },
                start_date: today,
                dow_filter: {
                    Sun: true,
                    Mon: true,
                    Tue: true,
                    Wed: true,
                    Thu: true,
                    Fri: true,
                    Sat: true,
                },
                days_before_arrival_range: {
                    from: 0,
                    to: 0,
                },
            },
            rule_conditions: {
                hotel_occupancy_threshold: {
                    rule_type: "value_threshold",
                    threshold_value: 0,
                    range_horizon: 0,
                    threshold_type: "fixed_val",
                    rule_metrics: ["occ"],
                    comparison_operation: "greater_than",
                    active: false,
                },
                hotel_occupancy_vs_sdly: {
                    rule_type: "value_threshold",
                    threshold_value: 3,
                    range_horizon: 0,
                    threshold_type: "diff_threshold",
                    rule_metrics: ["occ"],
                    comparison_operation: "greater_than",
                    active: false,
                },
                hotel_occupancy_pickup_threshold: {
                    rule_type: "value_threshold",
                    threshold_value: 0,
                    range_horizon: 0,
                    threshold_type: "diff_threshold",
                    pickup_days: 0,
                    rule_metrics: ["occ"],
                    comparison_operation: "greater_than",
                    active: false,
                },
                hotel_occupancy_pickup_vs_sdly: {
                    rule_type: "value_threshold",
                    threshold_value: 0,
                    range_horizon: 0,
                    threshold_type: "diff_threshold",
                    pickup_days: 0,
                    rule_metrics: ["occ"],
                    comparison_operation: "greater_than",
                    active: false,
                },
                market_forecast_occupancy_threshold: {
                    rule_type: "value_threshold",
                    threshold_value: 0,
                    range_horizon: 0,
                    threshold_type: "fixed_val",
                    rule_metrics: ["occ"],
                    comparison_operation: "greater_than",
                    active: false,
                },
                hotel_forecast_occupancy_threshold: {
                    rule_type: "value_threshold",
                    threshold_value: 0,
                    range_horizon: 0,
                    threshold_type: "fixed_val",
                    rule_metrics: ["occ"],
                    comparison_operation: "greater_than",
                    active: false,
                },
            },
            rule_actions: [
                {
                    target_settings: "BAR",
                    target_action: "increase",
                    change_value: 0,
                    change_metric: "currency",
                    target_rule: "greater_than",
                    target_hotel: "Compset_Avg",
                },
            ],
            rule_action_setting: "simple",
            active: false,
        };
        requestData.price_rule_settings.rules.push(item);
        setRequestData({ ...requestData });
    };
    const copyRule = (index) => {
        const clone = JSON.parse(
            JSON.stringify(requestData.price_rule_settings["rules"][index])
        );
        requestData.price_rule_settings.rules.push(clone);
        setRequestData({ ...requestData });
    };
    const addBlackOutDates = (rowIndex) => {
        const from = new moment().format("YYYY-MM-DD");
        const until = new moment().add(1, "days").format("YYYY-MM-DD");
        const datePair = { from: from, until: until };
        requestData.price_rule_settings.rules[
            rowIndex
        ].rule_period.blackout_date_ranges.push(datePair);
        setRequestData({ ...requestData });
    };
    const removeBlackOutDateRange = (rowIndex, itemIndex) => {
        requestData.price_rule_settings.rules[
            rowIndex
        ].rule_period.blackout_date_ranges.splice(itemIndex, 1);
        setRequestData({ ...requestData });
    };
    const deleteItem = (index) => {
        requestData.price_rule_settings.rules.splice(index, 1);
        setRequestData({ ...requestData });
    };
    const saveChanges = (event) => {
        setIsRequesting(true);
        const price_rule_settings = JSON.parse(
            JSON.stringify(requestData.price_rule_settings)
        );
        const request_system_settings = {
            ...system_settings[0],
            price_rule_settings: price_rule_settings,
        };

        //debugger;
        let payload = {
            "resource_id": app_state.property_id,
            "resource_type": "property",
            "update_type": "full_system_settings",
            "data": {
                "full_ss": request_system_settings
            }
        };

        let ss_api_config = deepCopy(API_REGISTRY['SaveSystemSettings']);
        let active_ds_id = dsInfo.active_source.id;
        ss_api_config.uri = ss_api_config.uri + app_state.property_id + '/' + active_ds_id + '/';

        dataService
            .update({ api: ss_api_config.uri, request: { ...ss_api_config.payload, ...payload } })
            .then((data) => {
                //Do some task here just after respons
                if (data.status === "success") {
                    setIsRequestSuccess(true);
                    setIsRequesting(false);
                    setTimeout(() => {
                        profile.property_details[prop_details_index].system_settings[0] =
                            request_system_settings;
                        dispatch({ type: SYSTEM_SETTINGS_UPDATED, payload: profile });
                    }, 500);
                    setTimeout(() => {
                        setIsRequestSuccess(false);
                    }, 5000);
                } else {
                    setIsRequestError(true);
                    setIsRequesting(false);
                    setResErrorMsg(data.message);
                    //return Promise.reject(data);
                }
            })
            .catch((err) => {
                setIsRequesting(false);
                setIsRequestError(true);
                setResErrorMsg(
                    (err && err.original && err.original.statusText) ||
                    "Something went wrong, please try again later!"
                );
                setTimeout(() => {
                    setIsRequestError(false);
                }, 5000);
                //return Promise.reject(err);
            });
    };

    const changePricingRuleOrder = (item, index, type) => {
        const size = requestData.price_rule_settings.rules.length;

        if (type == "up" && index) {
            requestData.price_rule_settings.rules[index] =
                requestData.price_rule_settings.rules[index - 1];
            requestData.price_rule_settings.rules[index - 1] = item;

            setRequestData({
                ...requestData,
                rules:
                    requestData.price_rule_settings.rules,
            });
            setIsChangeRequest(true);
            // debugger;
        }
        if (type == "down" && index < size) {
            requestData.price_rule_settings.rules[index] =
                requestData.price_rule_settings.rules[index + 1];
            requestData.price_rule_settings.rules[index + 1] = item;
            setRequestData({
                ...requestData, rules:
                    requestData.price_rule_settings.rules,
            });
            setIsChangeRequest(true);
        }
    };




    const handleExpand = (index) => {
        if (!expanded["id_" + index]) {
            expanded["id_" + index] = true;
        } else {
            expanded["id_" + index] = false;
        }
        setExpanded({ ...expanded });
    };
    // console.log(system_settings);

    return (
        <LocalizationProvider dateAdapter={AdapterMoment}>
            <Box className='breadcrumb' sx={{ color: 'white.main', position: 'absolute', top: -40 }}>
                Settings
                <ArrowForwardIosOutlinedIcon
                    sx={{ top: 3, position: "relative", height: 18 }}
                ></ArrowForwardIosOutlinedIcon>
                Property Settings
                <ArrowForwardIosOutlinedIcon
                    sx={{ top: 3, position: "relative", height: 18 }}
                ></ArrowForwardIosOutlinedIcon>
                Pricing Rules
            </Box>
            <div className="property-setting-heading">
                <Typography
                    className="property-setting-main-heading"
                    // variant="h1"
                    gutterBottom
                    sx={{ mr: 3, display: { lg: "inline-block", xs: "none" } }}
                >
                    Pricing Rules
                </Typography>
                <Box sx={{ float: "right" }}>
                    <Button
                        variant="outlined"
                        size="small"
                        className={(isChangeRequest) ? "navigate-button btn-active" : "navigate-button"}

                        // color="green"
                        sx={{ minWidth: "auto", mr: 1, color: "white.main" }}
                        onClick={() => {
                            addNewPricingRule();
                        }}
                    >
                        Add New
                    </Button>
                    <Button
                        disabled={!isChangeRequest}
                        onClick={() => {
                            saveChanges();
                        }}
                        variant="outlined"
                        size="small"
                        className={(isChangeRequest) ? "navigate-button btn-active" : "navigate-button"}

                        // color="green"
                        sx={{ minWidth: "auto", mr: 1, color: "white.main" }}
                    >
                        <span style={{ display: !isrequesting ? "block" : "none" }}>
                            Save
                        </span>
                        <span
                            style={{
                                display: isrequesting ? "block" : "none",
                                paddingRight: 10,
                            }}
                        >
                            saving...
                        </span>
                        <CircularProgress
                            sx={{ display: isrequesting ? "block" : "none" }}
                            color="white"
                            size="16px"
                        />
                    </Button>
                    <Button
                        disabled={!isChangeRequest}
                        onClick={cancelSave}
                        variant="outlined"
                        className={(isChangeRequest) ? "navigate-button btn-active" : "navigate-button"}

                        // color="error"
                        size="small"
                        sx={{ minWidth: "auto" }}
                    >
                        cancel
                    </Button>
                </Box>
                <Divider sx={{ clear: "both", mt: { xs: 2, lg: 0 } }} />
            </div>

            <Box className="inventory-settings " sx={{ p: 1, pt: 0, mb: 2, mt: 0, ml: 1, mr: 1 }}>


                {/* <Divider sx={{ clear: "both", mt: { xs: 2, lg: 0 } }} /> */}

                <Grid>
                    <Box
                        sx={{
                            pt: 2,
                            flexDirection: "column",
                            position: "relative",
                            display: "flex",
                        }}
                    >
                        <Alert
                            severity="success"
                            sx={{
                                width: "100%",
                                marginTop: -10,
                                display: isRequestSuccess ? "flex" : "none",
                            }}
                        >
                            {requestSuccessMsg}
                        </Alert>
                        <Alert
                            severity="error"
                            sx={{
                                width: "100%",
                                marginTop: -10,
                                display: isRequestError ? "flex" : "none",
                            }}
                        >
                            {resErrorMsg}
                        </Alert>

                        <Box
                            sx={{ mt: { lg: 0 }, pr: 0, maxHeight: "70vh", overflow: "auto" }}
                        >
                            <Box sx={{ mt: 0, mb: 2 }}>
                                {requestData.price_rule_settings.rules.map((item, index) => {
                                    return (
                                        <Paper
                                            key={"row-uid-" + index}
                                            className="pricing-rules item"
                                            elevation={2}
                                            sx={{ mb: 2, overflow: "hidden", border: "1px solid #d1d1d1", borderRadius: "4px", marginBottom: "20px" }}
                                        >
                                            <Grid
                                                container
                                                className="header"
                                                sx={{
                                                    backgroundColor: expanded["id_" + index]
                                                        ? "blue.main"
                                                        : "grey.lighter",
                                                }}
                                            >

                                                <Grid item xs={0.3}>
                                                    <Box sx={{ position: "relative", height: "100%", ml: .35, textAlign: "center" }}>
                                                        <Button
                                                            size="small"
                                                            sx={{ minWidth: "auto", p: 0, lineHeight: "auto" }}
                                                            disabled={index === 0}
                                                            onClick={() => {
                                                                changePricingRuleOrder(item, index, "up");
                                                            }}
                                                        >
                                                            <KeyboardArrowUpOutlinedIcon
                                                                fontSize="large"
                                                                color="{index == 0}? disabled :primary" sx={{ position: "absolute", top: -32, marginLeft: 1.25 }} />
                                                        </Button>
                                                        <span style={{ fontSize: 17, marginTop: 13, display: "inline-block" }}>{index + 1}</span>

                                                        <Button
                                                            size="small"
                                                            sx={{ minWidth: "auto", p: 0, lineHeight: "auto" }}
                                                            disabled={index === requestData.price_rule_settings.rules.length - 1}
                                                        >
                                                            <KeyboardArrowDownOutlinedIcon
                                                                fontSize="large"
                                                                color="{index === requestData.price_rule_settings.rules.length-1}?disabled:primary"
                                                                sx={{ position: "absolute", bottom: -32, ml: -1.2 }}
                                                                onClick={() => {
                                                                    changePricingRuleOrder(item, index, "down");
                                                                }}
                                                            />

                                                        </Button>
                                                    </Box>
                                                </Grid>


                                                <Grid item xs={2}>

                                                    <Input
                                                        className="accordian-field"
                                                        size="small"
                                                        sx={{
                                                            ml: 4,
                                                            mr: 1,
                                                            mt: 1,
                                                            textAlign: "center",
                                                            color: "blue.dark",
                                                        }}
                                                        value={item.rule_name}
                                                        onChange={(e) => {
                                                            const value = e.target.value;
                                                            updateRequestData(
                                                                "price_rule_settings>rule_name",
                                                                value,
                                                                index
                                                            );
                                                        }}
                                                    />
                                                </Grid>
                                                <Grid item xs={3.4}>
                                                    <Box
                                                        sx={{ ml: 0, mt: 0.8, color: "#1976d2" }}
                                                        className="checkbox-push-btns pricing-rule-dow"
                                                    >
                                                        <ButtonGroup disableElevation variant="contained">

                                                            {Object.keys(item.rule_period.dow_filter).map(
                                                                (dow) => (
                                                                    <Button
                                                                        // className="pricing-rule-dow"
                                                                        sx={{ paddingLeft: 0.5, paddingRight: 0.5, border: "1px solid #1565c0" }}

                                                                        key={"dow-uid-" + dow}
                                                                        size="small"
                                                                        color={
                                                                            item.rule_period.dow_filter[dow]
                                                                                ? "primary"
                                                                                : "white"
                                                                        }
                                                                        onClick={() => {
                                                                            updateRequestData(
                                                                                "price_rule_settings>rule_period>dow_filter",
                                                                                dow,
                                                                                index
                                                                            );
                                                                        }}
                                                                    >
                                                                        {/* {dow} */}
                                                                        {dow.charAt(0)}
                                                                    </Button>
                                                                )
                                                            )}
                                                        </ButtonGroup>
                                                    </Box>
                                                </Grid>
                                                <Grid item xs={3.5}>
                                                    <MobileDatePicker
                                                        sx={{ fontSize: 10 }}
                                                        inputFormat="ddd, MMM DD YYYY"
                                                        disablePast={true}
                                                        value={item.rule_period.effective_date_range.from}
                                                        onChange={(val) => {
                                                            const formated = new moment(val).format(
                                                                "YYYY-MM-DD"
                                                            );
                                                            updateRequestData(
                                                                "price_rule_settings>rule_period>effective_date_range>from",
                                                                formated,
                                                                index
                                                            );
                                                        }}
                                                        renderInput={(params) => (
                                                            <Tooltip title="Effective From">
                                                                <TextField
                                                                    label="From"
                                                                    className="date-picker textfield"
                                                                    variant="standard"
                                                                    sx={{ mt: 0.5, ml: 0.5, width: 140 }}
                                                                    {...params}
                                                                />
                                                            </Tooltip>
                                                        )}
                                                    />
                                                    <MobileDatePicker
                                                        inputFormat="ddd, MMM DD YYYY"
                                                        value={item.rule_period.effective_date_range.until}
                                                        onChange={(val) => {
                                                            const formated = val.format("YYYY-MM-DD");
                                                            updateRequestData(
                                                                "price_rule_settings>rule_period>effective_date_range>until",
                                                                formated,
                                                                index
                                                            );
                                                        }}
                                                        renderInput={(params) => (
                                                            <Tooltip title="Effective Until">
                                                                <TextField
                                                                    label="From"
                                                                    className="date-picker textfield"
                                                                    variant="standard"
                                                                    sx={{ mt: 0.5, ml: 1, width: 140 }}
                                                                    {...params}
                                                                />
                                                            </Tooltip>
                                                        )}
                                                    />
                                                </Grid>

                                                <Grid item xs={2.8} textAlign="right">
                                                    <Switch
                                                        className="switch-button"
                                                        size="medium"
                                                        // color="green"
                                                        checked={item.active}
                                                        onChange={(e) => {
                                                            const checked = e.target.checked;
                                                            updateRequestData(
                                                                "price_rule_settings>active",
                                                                checked,
                                                                index
                                                            );
                                                        }}
                                                    />
                                                    {/* <Typography
                          sx={{ display: "inline-block" }}
                          visibility={item.active ? "hidden" : "visible"}
                        >
                          Activate
                        </Typography> */}

                                                    <Button
                                                        variant="text"
                                                        onClick={() => {
                                                            copyRule(index);
                                                        }}
                                                    >
                                                        <Tooltip title="Copy Rule">
                                                            <CopyRuleIcon
                                                                fontSize="small"
                                                                color="primary"
                                                            ></CopyRuleIcon>
                                                        </Tooltip>
                                                    </Button>
                                                    <Button
                                                        variant="text"
                                                        onClick={() => {
                                                            deleteItem(index);
                                                        }}
                                                    >
                                                        <Tooltip title="Delete">
                                                            <DeleteOutlineOutlinedIcon
                                                                className="arrow-down inside-line-rateplan expend-arrow delete-icon-button"

                                                                color="error"
                                                            ></DeleteOutlineOutlinedIcon>
                                                        </Tooltip>
                                                    </Button>
                                                    <Button
                                                        variant="text"
                                                        type="button"

                                                        onClick={() => {
                                                            handleExpand(index);
                                                        }}
                                                    >
                                                        <KeyboardArrowDownOutlined

                                                            sx={{
                                                                display: expanded["id_" + index]
                                                                    ? "none"
                                                                    : "inline-block",
                                                                fontSize: 40,
                                                            }}
                                                            // color="primary"
                                                            className="arrow-down inside-line-rateplan expend-arrow button-grey"
                                                        />
                                                        <KeyboardArrowUpOutlinedIcon
                                                            color="primary"
                                                            sx={{
                                                                display: expanded["id_" + index]
                                                                    ? "inline-block"
                                                                    : "none",
                                                                fontSize: 40,
                                                            }}
                                                            className="arrow-down inside-line-rateplan expend-arrow button-grey"
                                                        />
                                                    </Button>
                                                </Grid>
                                            </Grid>

                                            <Collapse
                                                in={expanded["id_" + index]}
                                                timeout="auto"
                                                unmountOnExit
                                                sx={{
                                                    backgroundColor: "white",
                                                    maxHeight: "55vh",
                                                    overflow: "auto",
                                                }}
                                            >
                                                <Box sx={{ fontSize: "15px", p: 2 }}>
                                                    <Box display="inline-block" sx={{ float: "left" }}>
                                                        Start Date
                                                        <MobileDatePicker
                                                            sx={{ fontSize: 10 }}
                                                            inputFormat="dd, MMM DD YYYY"
                                                            value={item.rule_period.effective_date_range.from}
                                                            onChange={(val) => {
                                                                const formated = val.format("YYYY-MM-DD");
                                                                updateRequestData(
                                                                    "price_rule_settings>rule_period>effective_date_range>from",
                                                                    formated,
                                                                    index
                                                                );
                                                            }}
                                                            renderInput={(params) => (
                                                                <Tooltip title="Effective From">
                                                                    <TextField
                                                                        label="From"
                                                                        className="date-picker textfield"
                                                                        variant="standard"
                                                                        sx={{ mt: -1, ml: 0.5, mr: 1, width: 110 }}
                                                                        {...params}
                                                                    />
                                                                </Tooltip>
                                                            )}
                                                        />
                                                        Between
                                                        <Input
                                                            className="alert-input inline-input"
                                                            size="small"
                                                            sx={{
                                                                width: 40,
                                                                position: "relative",
                                                                top: -4,
                                                                ml: 0.5,
                                                                mr: 0.5,
                                                            }}
                                                            value={
                                                                item.rule_period.days_before_arrival_range.from
                                                            }
                                                            onChange={(e) => {
                                                                const value = e.target.value;
                                                                updateRequestData(
                                                                    "price_rule_settings>rule_period>days_before_arrival_range>from",
                                                                    value,
                                                                    index
                                                                );
                                                            }}
                                                        />
                                                        &#38;
                                                        <Input
                                                            className="alert-input inline-input"
                                                            size="small"
                                                            sx={{
                                                                width: 40,
                                                                position: "relative",
                                                                top: -4,
                                                                ml: 0.5,
                                                                mr: 0.5,
                                                            }}
                                                            value={
                                                                item.rule_period.days_before_arrival_range.to
                                                            }
                                                            onChange={(e) => {
                                                                const value = e.target.value;
                                                                updateRequestData(
                                                                    "price_rule_settings>rule_period>days_before_arrival_range>to",
                                                                    value,
                                                                    index
                                                                );
                                                            }}
                                                        />
                                                        days before arrival
                                                        <Typography
                                                            fontWeight="bold"
                                                            display="inline-block"
                                                            sx={{ ml: 0.5, fontSize: 14 }}
                                                        >
                                                            Black out Dates
                                                        </Typography>
                                                    </Box>

                                                    <Box display="inline-block" float="left">
                                                        <Button
                                                            type="button"
                                                            variant="contained"
                                                            size="small"
                                                            className="add-button"
                                                            // color="green"
                                                            sx={{
                                                                backgroundColor: "#fff",
                                                                fontWeight: "bold",
                                                                ml: 1,
                                                                mt: -1,
                                                                minWidth: "auto",
                                                                textTransform: "none",
                                                                float: "left",
                                                                display: "inline-block",
                                                                color: "white.main",
                                                            }}
                                                            onClick={(e) => {
                                                                addBlackOutDates(index);
                                                            }}
                                                        >
                                                            + Add
                                                        </Button>
                                                        <Box
                                                            float="left"
                                                            sx={{ display: "inline-block", mt: -1 }}
                                                        >
                                                            {item.rule_period.blackout_date_ranges.map(
                                                                (dateItem, ind) => (
                                                                    <Box
                                                                        sx={{
                                                                            pb: 1,
                                                                            backgroundColor: "grey.lighter",
                                                                            p: 1,
                                                                            m: 0.5,
                                                                            mt: 0,
                                                                        }}
                                                                    >
                                                                        <MobileDatePicker
                                                                            sx={{ fontSize: 10 }}
                                                                            inputFormat="ddd, MMM DD YYYY"
                                                                            value={dateItem.from}
                                                                            onChange={(val) => {
                                                                                const formated = val.format("YYYY-MM-DD");
                                                                                updateRequestData(
                                                                                    "price_rule_settings>rule_period>blackout_date_ranges>" +
                                                                                    ind +
                                                                                    ">from",
                                                                                    formated,
                                                                                    index
                                                                                );
                                                                            }}
                                                                            renderInput={(params) => (
                                                                                <Tooltip title="From">
                                                                                    <TextField
                                                                                        label="From"
                                                                                        className="date-picker"
                                                                                        variant="standard"
                                                                                        sx={{
                                                                                            mt: -1,
                                                                                            ml: 0.5,
                                                                                            mr: 1,
                                                                                            width: 110,
                                                                                        }}
                                                                                        {...params}
                                                                                    />
                                                                                </Tooltip>
                                                                            )}
                                                                        />
                                                                        <MobileDatePicker
                                                                            sx={{ fontSize: 10 }}
                                                                            inputFormat="ddd, MMM DD YYYY"
                                                                            value={dateItem.until}
                                                                            onChange={(val) => {
                                                                                const formated = val.format("YYYY-MM-DD");
                                                                                updateRequestData(
                                                                                    "price_rule_settings>rule_period>blackout_date_ranges>" +
                                                                                    ind +
                                                                                    ">until",
                                                                                    formated,
                                                                                    index
                                                                                );
                                                                            }}
                                                                            renderInput={(params) => (
                                                                                <Tooltip title="To">
                                                                                    <TextField
                                                                                        label="From"
                                                                                        className="date-picker"
                                                                                        variant="standard"
                                                                                        sx={{
                                                                                            mt: -1,
                                                                                            ml: 0.5,
                                                                                            mr: 1,
                                                                                            width: 110,
                                                                                        }}
                                                                                        {...params}
                                                                                    />
                                                                                </Tooltip>
                                                                            )}
                                                                        />
                                                                        <Button
                                                                            size="small"
                                                                            variant="outlined"
                                                                            color="error"
                                                                            sx={{ fontSize: 10 }}
                                                                            onClick={() => {
                                                                                removeBlackOutDateRange(index, ind);
                                                                            }}
                                                                        >
                                                                            Remove
                                                                        </Button>
                                                                    </Box>
                                                                )
                                                            )}
                                                        </Box>
                                                    </Box>
                                                </Box>
                                                <Grid container spacing={1}>
                                                    <Grid item xs={6}>
                                                        <Box sx={{ fontSize: "15px", pb: 1 }}>
                                                            <FormControlLabel
                                                                sx={{ m: 0, p: 0 }}
                                                                control={
                                                                    <Switch
                                                                        className="switch-button"
                                                                        size="medium"
                                                                        // color="green"
                                                                        checked={
                                                                            item.rule_conditions
                                                                                .hotel_occupancy_threshold.active
                                                                        }
                                                                        onChange={(e) => {
                                                                            const checked = e.target.checked;
                                                                            updateRequestData(
                                                                                "price_rule_settings>rule_conditions>hotel_occupancy_threshold>active",
                                                                                checked,
                                                                                index
                                                                            );
                                                                        }}
                                                                    />
                                                                }
                                                                label=""
                                                            />{" "}
                                                            Hotel OTB occupancy is
                                                            <Select
                                                                className="alert-input inline-select"
                                                                size="small"
                                                                sx={{
                                                                    height: "22px",
                                                                    ml: 1,
                                                                    mr: 1,
                                                                    fontSize: 11,
                                                                }}
                                                                value={
                                                                    item.rule_conditions.hotel_occupancy_threshold
                                                                        .comparison_operation
                                                                }
                                                                onChange={(e) => {
                                                                    const value = e.target.value;
                                                                    updateRequestData(
                                                                        "price_rule_settings>rule_conditions>hotel_occupancy_threshold>comparison_operation",
                                                                        value,
                                                                        index
                                                                    );
                                                                }}
                                                            >
                                                                <MenuItem value="less_than">less than</MenuItem>
                                                                <MenuItem value="greater_than">
                                                                    greater than
                                                                </MenuItem>
                                                            </Select>
                                                            <Input
                                                                className="alert-input inline-input"
                                                                size="small"
                                                                sx={{
                                                                    width: 40,
                                                                    position: "relative",
                                                                    ml: 0.5,
                                                                    mr: 0.5,
                                                                }}
                                                                value={
                                                                    item.rule_conditions.hotel_occupancy_threshold
                                                                        .threshold_value
                                                                }
                                                                endAdornment={
                                                                    <InputAdornment position="end">
                                                                        %
                                                                    </InputAdornment>
                                                                }
                                                                onChange={(e) => {
                                                                    const value = e.target.value;
                                                                    updateRequestData(
                                                                        "price_rule_settings>rule_conditions>hotel_occupancy_threshold>threshold_value",
                                                                        value,
                                                                        index
                                                                    );
                                                                }}
                                                            />
                                                        </Box>
                                                    </Grid>
                                                    <Grid item xs={6}>
                                                        <Box sx={{ fontSize: "15px", pb: 1 }}>
                                                            <FormControlLabel
                                                                sx={{ m: 0, p: 0 }}
                                                                control={
                                                                    <Switch
                                                                        className="switch-button"
                                                                        size="medium"
                                                                        // color="green"
                                                                        checked={
                                                                            item.rule_conditions.hotel_occupancy_vs_sdly
                                                                                .active
                                                                        }
                                                                        onChange={(e) => {
                                                                            const checked = e.target.checked;
                                                                            updateRequestData(
                                                                                "price_rule_settings>rule_conditions>hotel_occupancy_vs_sdly>active",
                                                                                checked,
                                                                                index
                                                                            );
                                                                        }}
                                                                    />
                                                                }
                                                                label=""
                                                            />{" "}
                                                            Hotel OTB occupancy is
                                                            <Select
                                                                className="alert-input inline-select"
                                                                size="small"
                                                                sx={{
                                                                    height: "22px",
                                                                    ml: 1,
                                                                    mr: 1,
                                                                    fontSize: 11,
                                                                }}
                                                                value={
                                                                    item.rule_conditions.hotel_occupancy_vs_sdly
                                                                        .comparison_operation
                                                                }
                                                                onChange={(e) => {
                                                                    const value = e.target.value;
                                                                    updateRequestData(
                                                                        "price_rule_settings>rule_conditions>hotel_occupancy_vs_sdly>comparison_operation",
                                                                        value,
                                                                        index
                                                                    );
                                                                }}
                                                            >
                                                                <MenuItem value="greater_than">ahead of</MenuItem>
                                                                <MenuItem value="less_than">behind</MenuItem>
                                                            </Select>
                                                            last year by more than
                                                            <Input
                                                                className="alert-input inline-input"
                                                                size="small"
                                                                sx={{
                                                                    width: 40,
                                                                    position: "relative",
                                                                    ml: 0.5,
                                                                    mr: 0.5,
                                                                }}
                                                                value={
                                                                    item.rule_conditions.hotel_occupancy_vs_sdly
                                                                        .threshold_value
                                                                }
                                                                endAdornment={
                                                                    <InputAdornment position="end">
                                                                        %
                                                                    </InputAdornment>
                                                                }
                                                                onChange={(e) => {
                                                                    const value = e.target.value;
                                                                    updateRequestData(
                                                                        "price_rule_settings>rule_conditions>hotel_occupancy_vs_sdly>threshold_value",
                                                                        value,
                                                                        index
                                                                    );
                                                                }}
                                                            />
                                                        </Box>
                                                    </Grid>

                                                    <Grid item xs={6}>
                                                        <Box sx={{ fontSize: "15px", pb: 1 }}>
                                                            <FormControlLabel
                                                                sx={{ m: 0, p: 0 }}
                                                                control={
                                                                    <Switch
                                                                        className="switch-button"
                                                                        size="medium"
                                                                        // color="green"
                                                                        checked={
                                                                            item.rule_conditions
                                                                                .hotel_occupancy_pickup_threshold.active
                                                                        }
                                                                        onChange={(e) => {
                                                                            const checked = e.target.checked;
                                                                            updateRequestData(
                                                                                "price_rule_settings>rule_conditions>hotel_occupancy_pickup_threshold>active",
                                                                                checked,
                                                                                index
                                                                            );
                                                                        }}
                                                                    />
                                                                }
                                                                label=""
                                                            />{" "}
                                                            Hotel
                                                            <Input
                                                                className="alert-input inline-input"
                                                                size="small"
                                                                sx={{
                                                                    width: 40,
                                                                    position: "relative",
                                                                    ml: 0.5,
                                                                    mr: 0.5,
                                                                }}
                                                                value={
                                                                    item.rule_conditions
                                                                        .hotel_occupancy_pickup_threshold.pickup_days
                                                                }
                                                                onChange={(e) => {
                                                                    const value = e.target.value;
                                                                    updateRequestData(
                                                                        "price_rule_settings>rule_conditions>hotel_occupancy_pickup_threshold>pickup_days",
                                                                        value,
                                                                        index
                                                                    );
                                                                }}
                                                            />
                                                            day occupancy pickup is
                                                            <Select
                                                                className="alert-input inline-select"
                                                                size="small"
                                                                sx={{
                                                                    height: "22px",
                                                                    ml: 1,
                                                                    mr: 1,
                                                                    fontSize: 11,
                                                                }}
                                                                value={
                                                                    item.rule_conditions
                                                                        .hotel_occupancy_pickup_threshold
                                                                        .comparison_operation
                                                                }
                                                                onChange={(e) => {
                                                                    const value = e.target.value;
                                                                    updateRequestData(
                                                                        "price_rule_settings>rule_conditions>hotel_occupancy_pickup_threshold>comparison_operation",
                                                                        value,
                                                                        index
                                                                    );
                                                                }}
                                                            >
                                                                <MenuItem value="less_than">less than</MenuItem>
                                                                <MenuItem value="greater_than">
                                                                    greater than
                                                                </MenuItem>
                                                            </Select>
                                                            <Input
                                                                className="alert-input inline-input"
                                                                size="small"
                                                                sx={{
                                                                    width: 40,
                                                                    position: "relative",
                                                                    ml: 0.5,
                                                                    mr: 0.5,
                                                                }}
                                                                value={
                                                                    item.rule_conditions
                                                                        .hotel_occupancy_pickup_threshold
                                                                        .threshold_value
                                                                }
                                                                endAdornment={
                                                                    <InputAdornment position="end">
                                                                        %
                                                                    </InputAdornment>
                                                                }
                                                                onChange={(e) => {
                                                                    const value = e.target.value;
                                                                    updateRequestData(
                                                                        "price_rule_settings>rule_conditions>hotel_occupancy_pickup_threshold>threshold_value",
                                                                        value,
                                                                        index
                                                                    );
                                                                }}
                                                            />
                                                        </Box>
                                                    </Grid>
                                                    <Grid item xs={6}>
                                                        <Box sx={{ fontSize: "15px", pb: 1 }}>
                                                            <FormControlLabel
                                                                sx={{ m: 0, p: 0 }}
                                                                control={
                                                                    <Switch
                                                                        className="switch-button"
                                                                        size="medium"
                                                                        // color="green"
                                                                        checked={
                                                                            item.rule_conditions
                                                                                .hotel_occupancy_pickup_vs_sdly.active
                                                                        }
                                                                        onChange={(e) => {
                                                                            const checked = e.target.checked;
                                                                            updateRequestData(
                                                                                "price_rule_settings>rule_conditions>hotel_occupancy_pickup_vs_sdly>active",
                                                                                checked,
                                                                                index
                                                                            );
                                                                        }}
                                                                    />
                                                                }
                                                                label=""
                                                            />{" "}
                                                            Hotel
                                                            <Input
                                                                className="alert-input inline-input"
                                                                size="small"
                                                                sx={{
                                                                    width: 40,
                                                                    position: "relative",
                                                                    ml: 0.5,
                                                                    mr: 0.5,
                                                                }}
                                                                value={
                                                                    item.rule_conditions
                                                                        .hotel_occupancy_pickup_vs_sdly.pickup_days
                                                                }
                                                                onChange={(e) => {
                                                                    const value = e.target.value;
                                                                    updateRequestData(
                                                                        "price_rule_settings>rule_conditions>hotel_occupancy_pickup_vs_sdly>pickup_days",
                                                                        value,
                                                                        index
                                                                    );
                                                                }}
                                                            />
                                                            day occupancy pickup is
                                                            <Select
                                                                className="alert-input inline-select"
                                                                size="small"
                                                                sx={{
                                                                    height: "22px",
                                                                    ml: 1,
                                                                    mr: 1,
                                                                    fontSize: 11,
                                                                }}
                                                                value={
                                                                    item.rule_conditions
                                                                        .hotel_occupancy_pickup_vs_sdly
                                                                        .comparison_operation
                                                                }
                                                                onChange={(e) => {
                                                                    const value = e.target.value;
                                                                    updateRequestData(
                                                                        "price_rule_settings>rule_conditions>hotel_occupancy_pickup_vs_sdly>comparison_operation",
                                                                        value,
                                                                        index
                                                                    );
                                                                }}
                                                            >
                                                                <MenuItem value="greater_than">
                                                                    greater than
                                                                </MenuItem>
                                                                <MenuItem value="less_than">less than</MenuItem>
                                                            </Select>
                                                            <br />
                                                            <span style={{ marginLeft: 60 }}>
                                                                last year pickup by more than
                                                            </span>
                                                            <Input
                                                                className="alert-input inline-input"
                                                                size="small"
                                                                sx={{
                                                                    width: 40,
                                                                    position: "relative",
                                                                    ml: 0.5,
                                                                    mr: 0.5,
                                                                }}
                                                                value={
                                                                    item.rule_conditions
                                                                        .hotel_occupancy_pickup_vs_sdly
                                                                        .threshold_value
                                                                }
                                                                endAdornment={
                                                                    <InputAdornment position="end">
                                                                        %
                                                                    </InputAdornment>
                                                                }
                                                                onChange={(e) => {
                                                                    const value = e.target.value;
                                                                    updateRequestData(
                                                                        "price_rule_settings>rule_conditions>hotel_occupancy_pickup_vs_sdly>threshold_value",
                                                                        value,
                                                                        index
                                                                    );
                                                                }}
                                                            />
                                                        </Box>
                                                    </Grid>

                                                    <Grid item xs={6}>
                                                        <Box sx={{ fontSize: "15px", pb: 1 }}>
                                                            <FormControlLabel
                                                                sx={{ m: 0, p: 0 }}
                                                                control={
                                                                    <Switch
                                                                        className="switch-button"
                                                                        size="medium"
                                                                        // color="green"
                                                                        checked={
                                                                            item.rule_conditions
                                                                                .market_forecast_occupancy_threshold
                                                                                .active
                                                                        }
                                                                        onChange={(e) => {
                                                                            const checked = e.target.checked;
                                                                            updateRequestData(
                                                                                "price_rule_settings>rule_conditions>market_forecast_occupancy_threshold>active",
                                                                                checked,
                                                                                index
                                                                            );
                                                                        }}
                                                                    />
                                                                }
                                                                label=""
                                                            />{" "}
                                                            Market forecast occupancy is
                                                            <Select
                                                                className="alert-input inline-select"
                                                                size="small"
                                                                sx={{
                                                                    height: "22px",
                                                                    ml: 1,
                                                                    mr: 1,
                                                                    fontSize: 11,
                                                                }}
                                                                value={
                                                                    item.rule_conditions
                                                                        .market_forecast_occupancy_threshold
                                                                        .comparison_operation
                                                                }
                                                                onChange={(e) => {
                                                                    const value = e.target.value;
                                                                    updateRequestData(
                                                                        "price_rule_settings>rule_conditions>market_forecast_occupancy_threshold>comparison_operation",
                                                                        value,
                                                                        index
                                                                    );
                                                                }}
                                                            >
                                                                <MenuItem value="greater_than">
                                                                    greater than
                                                                </MenuItem>
                                                                <MenuItem value="less_than">less than</MenuItem>
                                                            </Select>
                                                            <Input
                                                                className="alert-input inline-input"
                                                                size="small"
                                                                sx={{
                                                                    width: 40,
                                                                    position: "relative",
                                                                    ml: 0.5,
                                                                    mr: 0.5,
                                                                }}
                                                                value={
                                                                    item.rule_conditions
                                                                        .market_forecast_occupancy_threshold
                                                                        .threshold_value
                                                                }
                                                                endAdornment={
                                                                    <InputAdornment position="end">
                                                                        %
                                                                    </InputAdornment>
                                                                }
                                                                onChange={(e) => {
                                                                    const value = e.target.value;
                                                                    updateRequestData(
                                                                        "price_rule_settings>rule_conditions>market_forecast_occupancy_threshold>threshold_value",
                                                                        value,
                                                                        index
                                                                    );
                                                                }}
                                                            />
                                                        </Box>
                                                    </Grid>
                                                    <Grid item xs={6}>
                                                        <Box sx={{ fontSize: "15px", pb: 1 }}>
                                                            <FormControlLabel
                                                                sx={{ m: 0, p: 0 }}
                                                                control={
                                                                    <Switch
                                                                        className="switch-button"
                                                                        size="medium"
                                                                        // color="green"
                                                                        checked={
                                                                            item.rule_conditions
                                                                                .hotel_forecast_occupancy_threshold.active
                                                                        }
                                                                        onChange={(e) => {
                                                                            const checked = e.target.checked;
                                                                            updateRequestData(
                                                                                "price_rule_settings>rule_conditions>hotel_forecast_occupancy_threshold>active",
                                                                                checked,
                                                                                index
                                                                            );
                                                                        }}
                                                                    />
                                                                }
                                                                label=""
                                                            />{" "}
                                                            Hotel forecast is
                                                            <Select
                                                                className="alert-input inline-select"
                                                                size="small"
                                                                sx={{
                                                                    height: "22px",
                                                                    ml: 1,
                                                                    mr: 1,
                                                                    fontSize: 11,
                                                                }}
                                                                value={
                                                                    item.rule_conditions
                                                                        .hotel_forecast_occupancy_threshold
                                                                        .comparison_operation
                                                                }
                                                                onChange={(e) => {
                                                                    const value = e.target.value;
                                                                    updateRequestData(
                                                                        "price_rule_settings>rule_conditions>hotel_forecast_occupancy_threshold>comparison_operation",
                                                                        value,
                                                                        index
                                                                    );
                                                                }}
                                                            >
                                                                <MenuItem value="greater_than">
                                                                    greater than
                                                                </MenuItem>
                                                                <MenuItem value="less_than">less than</MenuItem>
                                                            </Select>
                                                            <Input
                                                                className="alert-input inline-input"
                                                                size="small"
                                                                sx={{
                                                                    width: 40,
                                                                    position: "relative",
                                                                    ml: 0.5,
                                                                    mr: 0.5,
                                                                }}
                                                                value={
                                                                    item.rule_conditions
                                                                        .hotel_forecast_occupancy_threshold
                                                                        .threshold_value
                                                                }
                                                                endAdornment={
                                                                    <InputAdornment position="end">
                                                                        %
                                                                    </InputAdornment>
                                                                }
                                                                onChange={(e) => {
                                                                    const value = e.target.value;
                                                                    updateRequestData(
                                                                        "price_rule_settings>rule_conditions>hotel_forecast_occupancy_threshold>threshold_value",
                                                                        value,
                                                                        index
                                                                    );
                                                                }}
                                                            />
                                                        </Box>
                                                    </Grid>
                                                    <Box
                                                        sx={{ m: 2, mb: 0, p: 1, pl: 2 }}
                                                    >
                                                        SET YOUR PRICING RULE OUTPUT HERE:
                                                    </Box>
                                                    <Grid item xs={12}>
                                                        <Paper elevation={0} sx={{ m: 2, mt: 0, p: 1, border: "1px solid #DADADA" }}>
                                                            <Box
                                                                sx={{ mt: 0.8 }}
                                                                className="checkbox-push-btns"
                                                            >
                                                                <span
                                                                    style={{ color: "#333", fontSize: "14px", marginRight: 10 }}
                                                                >
                                                                    PRICING RULE OUTPUT
                                                                </span>
                                                                <ButtonGroup disableElevation variant="contained">
                                                                    <Button
                                                                        size="small"
                                                                        color={
                                                                            item.rule_action_setting === "simple"
                                                                                ? "blue"
                                                                                : "dark"
                                                                        }
                                                                        sx={{ color: "white.main" }}
                                                                        onClick={() => {
                                                                            updateRequestData(
                                                                                "price_rule_settings>rule_action_setting",
                                                                                "simple",
                                                                                index
                                                                            );
                                                                        }}
                                                                    >
                                                                        Simple
                                                                    </Button>
                                                                    <Button
                                                                        size="small"
                                                                        color={
                                                                            item.rule_action_setting === "advanced"
                                                                                ? "blue"
                                                                                : "dark"
                                                                        }
                                                                        sx={{ color: "white.main" }}
                                                                        onClick={() => {
                                                                            updateRequestData(
                                                                                "price_rule_settings>rule_action_setting",
                                                                                "advanced",
                                                                                index
                                                                            );
                                                                        }}
                                                                    >
                                                                        Advanced
                                                                    </Button>
                                                                </ButtonGroup>
                                                                <Box sx={{ mt: 3, mb: 2, fontSize: 14 }}>
                                                                    <Select
                                                                        className="alert-input inline-select"
                                                                        size="small"
                                                                        sx={{
                                                                            height: "22px",
                                                                            ml: 1,
                                                                            mr: 1,
                                                                            fontSize: 11,
                                                                        }}
                                                                        value={item.rule_actions[0].target_settings}
                                                                        onChange={(e) => {
                                                                            const value = e.target.value;
                                                                            updateRequestData(
                                                                                "price_rule_settings>rule_actions>0>target_settings",
                                                                                value,
                                                                                index
                                                                            );
                                                                        }}
                                                                    >
                                                                        <MenuItem value="BAR">BAR</MenuItem>
                                                                    </Select>
                                                                    Must be
                                                                    <Select
                                                                        className="alert-input inline-select"
                                                                        size="small"
                                                                        sx={{
                                                                            height: "22px",
                                                                            ml: 1,
                                                                            mr: 1,
                                                                            fontSize: 11,
                                                                            display:
                                                                                item.rule_action_setting === "advanced"
                                                                                    ? "none"
                                                                                    : "inline-flex",
                                                                        }}
                                                                        value={item.rule_actions[0].target_action}
                                                                        onChange={(e) => {
                                                                            const value = e.target.value;
                                                                            updateRequestData(
                                                                                "price_rule_settings>rule_actions>0>target_action",
                                                                                value,
                                                                                index
                                                                            );
                                                                        }}
                                                                    >
                                                                        <MenuItem value="increase">increase</MenuItem>
                                                                        <MenuItem value="decrease">decrease</MenuItem>
                                                                    </Select>
                                                                    <Select
                                                                        className="alert-input inline-select"
                                                                        size="small"
                                                                        sx={{
                                                                            height: "22px",
                                                                            ml: 1,
                                                                            mr: 1,
                                                                            fontSize: 11,
                                                                            display:
                                                                                item.rule_action_setting === "simple"
                                                                                    ? "none"
                                                                                    : "inline-flex",
                                                                        }}
                                                                        value={item.rule_actions[0].target_rule}
                                                                        onChange={(e) => {
                                                                            const value = e.target.value;
                                                                            updateRequestData(
                                                                                "price_rule_settings>rule_actions>0>target_rule",
                                                                                value,
                                                                                index
                                                                            );
                                                                        }}
                                                                    >
                                                                        <MenuItem value="greater_than">
                                                                            greater than
                                                                        </MenuItem>
                                                                        <MenuItem value="less_than">
                                                                            less than
                                                                        </MenuItem>
                                                                    </Select>
                                                                    <Select
                                                                        className="alert-input inline-select"
                                                                        size="small"
                                                                        sx={{
                                                                            height: "22px",
                                                                            ml: 1,
                                                                            mr: 1,
                                                                            fontSize: 11,
                                                                            display:
                                                                                item.rule_action_setting === "simple"
                                                                                    ? "none"
                                                                                    : "inline-flex",
                                                                        }}
                                                                        value={item.rule_actions[0].target_hotel}
                                                                        onChange={(e) => {
                                                                            const value = e.target.value;
                                                                            updateRequestData(
                                                                                "price_rule_settings>rule_actions>0>target_hotel",
                                                                                value,
                                                                                index
                                                                            );
                                                                        }}
                                                                    >
                                                                        <MenuItem value="Compset_Median">
                                                                            Compset Median{" "}
                                                                        </MenuItem>
                                                                        <MenuItem value="Compset_Avg">
                                                                            Compset Avg{" "}
                                                                        </MenuItem>
                                                                        <MenuItem value="Compset_Max">
                                                                            Compset Max{" "}
                                                                        </MenuItem>
                                                                        <MenuItem value="Compset_Min">
                                                                            Compset Min{" "}
                                                                        </MenuItem>
                                                                    </Select>
                                                                    by
                                                                    <Input
                                                                        className="alert-input inline-input"
                                                                        size="small"
                                                                        sx={{
                                                                            width: 40,
                                                                            position: "relative",
                                                                            ml: 0.5,
                                                                            mr: 0.5,
                                                                        }}
                                                                        value={item.rule_actions[0].change_value}
                                                                        onChange={(e) => {
                                                                            const value = e.target.value;
                                                                            updateRequestData(
                                                                                "price_rule_settings>rule_actions>0>change_value",
                                                                                value,
                                                                                index
                                                                            );
                                                                        }}
                                                                    />
                                                                    <Select
                                                                        className="alert-input inline-select"
                                                                        size="small"
                                                                        sx={{
                                                                            height: "22px",
                                                                            ml: 1,
                                                                            mr: 1,
                                                                            fontSize: 11,
                                                                        }}
                                                                        value={item.rule_actions[0].change_metric}
                                                                        onChange={(e) => {
                                                                            const value = e.target.value;
                                                                            updateRequestData(
                                                                                "price_rule_settings>rule_actions>0>change_metric",
                                                                                value,
                                                                                index
                                                                            );
                                                                        }}
                                                                    >
                                                                        <MenuItem value="currency">$</MenuItem>
                                                                    </Select>
                                                                </Box>
                                                            </Box>
                                                        </Paper>
                                                    </Grid>
                                                </Grid>
                                            </Collapse>
                                        </Paper>
                                    );
                                })}
                            </Box>
                        </Box>
                        {isrequesting && (
                            <Box
                                sx={{
                                    textAlighn: "center",
                                    backgroundColor: "dark.darkest",
                                    opacity: 0.8,
                                    position: "fixed",
                                    top: 40,
                                    left: 0,
                                    width: "100%",
                                    height: "100%",
                                }}
                            >
                                <CircularProgress
                                    size={40}
                                    sx={{
                                        color: "white",
                                        position: "absolute",
                                        top: "50%",
                                        left: "50%",
                                        marginTop: "-20px",
                                        marginLeft: "-20px",
                                    }}
                                />
                            </Box>
                        )}
                    </Box>
                </Grid>

            </Box>
        </LocalizationProvider>
    );
}

function DefaultRoomTypePricing(props) {
    const dispatch = useDispatch();

    const [isrequesting, setIsRequesting] = useState(false);
    const [resError, setResError] = useState(false);
    const [isRequestSuccess, setIsRequestSuccess] = useState(false);
    const [requestSuccessMsg, setRequestSuccessMsg] = useState(
        "Your change request for settings was successfull!"
    );
    const [isRequestError, setIsRequestError] = useState(false);
    const [resErrorMsg, setResErrorMsg] = useState("");
    const [expanded, setExpanded] = useState({});

    const { app, auth } = useSelector((state) => state);
    const { user, profile } = auth;
    const { app_state, ui_state } = app.current_state;
    const { property_details } = profile || [];
    //Data source information
    const [dsInfo, setDsInfo] = useState(getDataSources(property_details, app_state.property_id));
    let prop_details_index = 0;
    const prop_details = property_details.filter((item, index) => {
        if (item.id === app_state.property_id) {
            prop_details_index = index;
            return item.id === app_state.property_id;
        }
        //
    });

    const { system_settings } =
        prop_details && prop_details.length ? prop_details[0] : null;
    const room_settings = JSON.parse(
        JSON.stringify(system_settings[0].inventory_settings.current.room_settings)
    );
    const settings_ = JSON.parse(
        JSON.stringify({
            pricing_settings: system_settings[0].pricing_settings,
        })
    );
    // debugger; 
    //   {
    //     "plan_name": "default",
    //     "plan_period": {
    //         "effective_date_range": {
    //             "from": "2022-01-01",
    //             "until": "2026-01-01"
    //         }
    //     },
    //     "base_room": "",
    //     "room_plans": {
    //         "": {
    //             "Wed": 0,
    //             "Sun": 0,
    //             "Fri": 0,
    //             "Tue": 0,
    //             "Mon": 0,
    //             "Thu": 0,
    //             "Sat": 0
    //         },
    //         "PresidentialSuite1": {
    //             "Wed": 100,
    //             "Sun": 100,
    //             "Fri": 100,
    //             "Tue": 100,
    //             "Mon": 100,
    //             "Thu": 100,
    //             "Sat": 100
    //         },
    //         "JuniorDouble1": {
    //             "Wed": 0,
    //             "Sun": 0,
    //             "Fri": 0,
    //             "Tue": 0,
    //             "Mon": 0,
    //             "Thu": 0,
    //             "Sat": 0
    //         },
    //         "ConciergeQueen1": {
    //             "Wed": 30,
    //             "Sun": 30,
    //             "Fri": 30,
    //             "Tue": 30,
    //             "Mon": 30,
    //             "Thu": 30,
    //             "Sat": 30
    //         },
    //         "StandardQueen1": {
    //             "Wed": 10,
    //             "Sun": 10,
    //             "Fri": 10,
    //             "Tue": 10,
    //             "Mon": 10,
    //             "Thu": 10,
    //             "Sat": 10
    //         },
    //         "ConciergeKing1": {
    //             "Wed": 40,
    //             "Sun": 40,
    //             "Fri": 40,
    //             "Tue": 40,
    //             "Mon": 40,
    //             "Thu": 40,
    //             "Sat": 40
    //         },
    //         "StandardKing1": {
    //             "Wed": 20,
    //             "Sun": 20,
    //             "Fri": 20,
    //             "Tue": 20,
    //             "Mon": 20,
    //             "Thu": 20,
    //             "Sat": 20
    //         },
    //         "ExecutiveSuite1": {
    //             "Wed": 80,
    //             "Sun": 80,
    //             "Fri": 80,
    //             "Tue": 80,
    //             "Mon": 80,
    //             "Thu": 80,
    //             "Sat": 80
    //         }
    //     },
    //     "active": true,
    //     "open": false,
    //     "base_rate_code": "42985"
    // }
    const settings = JSON.parse(JSON.stringify(settings_));

    const [requestData, setRequestData] = useState(settings);
    const [isChangeRequest, setIsChangeRequest] = useState(false);



    const cancelSave = () => {
        setRequestData(settings_);
        setIsChangeRequest(false);
    };
    const updateRequestData = (keys, value, index) => {
        const updatedObj = { ...requestData };
        const key = keys.split(">");
        const size = key.length;
        let val = value;

        // debugger;
        if (size == 2) {
            updatedObj[key[0]]["room_dow_plans"][index][key[1]] = val;
        }
        if (size == 3) {
            updatedObj[key[0]]["room_dow_plans"][index][key[1]][key[2]] = val;
        }
        if (size == 4) {
            if (key[2] === 'effective_date_range') {
                updatedObj[key[0]]["room_dow_plans"][index][key[1]][key[2]][key[3]] =
                    val !== '' ? val : '';
            } else {
                updatedObj[key[0]]["room_dow_plans"][index][key[1]][key[2]][key[3]] =
                    !isNaN(val) ? Number(val) : 0;
            }

        }

        setRequestData({
            ...requestData,
            price_rule_settings: updatedObj.price_rule_settings,
        });
        setIsChangeRequest(true);
    };

    const addNewPricingRule = (plan_name = null) => {

        let daysAdd = (plan_name && plan_name.toLowerCase() === "default") ? 1460 : 1;
        const today = new moment().format("YYYY-MM-DD");
        const tomorrow = new moment().add(daysAdd, "days").format("YYYY-MM-DD");
        const size = requestData.pricing_settings.room_dow_plans.length;
        const item = {
            open: false,
            plan_name: (plan_name) ? plan_name : "New Room Type Plan " + size,
            active: false,
            plan_period: {
                effective_date_range: {
                    from: today,
                    until: tomorrow,
                },
            },
            base_room: "StandardTwoQueens1",
            base_rate_code: "",
            room_plans: {},
        };
        //Making DRTP rows according to inventory room types
        if (room_settings.length) {
            room_settings.map((room, index) => {
                item.room_plans[room.room_lodgiq_code] = {
                    Sun: 0,
                    Mon: 0,
                    Tue: 0,
                    Wed: 0,
                    Thu: 0,
                    Fri: 0,
                    Sat: 0,
                }
            });
        }
        // debugger;
        requestData.pricing_settings.room_dow_plans.push(item);
        setRequestData({ ...requestData });
    };
    const copyRule = (index) => {
        const clone = JSON.parse(
            JSON.stringify(requestData.pricing_settings["room_dow_plans"][index])
        );
        clone.plan_name = "Copy of " + clone.plan_name;
        requestData.pricing_settings.room_dow_plans.push(clone);
        setRequestData({ ...requestData });
        setIsChangeRequest(true);
    };

    const deleteItem = (index) => {
        requestData.pricing_settings.room_dow_plans.splice(index, 1);
        setRequestData({ ...requestData });
        setIsChangeRequest(true);
    };

    const saveChangesTemp = (event) => {
        setIsRequesting(true);
        setTimeout(() => {
            setIsRequesting(false);
        }, 3000);
    };
    const saveChanges = (event) => {
        setIsRequesting(true);
        const pricing_settings = JSON.parse(
            JSON.stringify(requestData.pricing_settings)
        );
        const request_system_settings = {
            ...system_settings[0],
            pricing_settings: pricing_settings,
        };

        //debugger;
        let payload = {
            "resource_id": app_state.property_id,
            "resource_type": "property",
            "update_type": "full_system_settings",
            "data": {
                "full_ss": request_system_settings
            }
        };

        let ss_api_config = deepCopy(API_REGISTRY['SaveSystemSettings']);
        let active_ds_id = dsInfo.active_source.id;
        ss_api_config.uri = ss_api_config.uri + app_state.property_id + '/' + active_ds_id + '/';

        dataService
            .update({ api: ss_api_config.uri, request: { ...ss_api_config.payload, ...payload } })
            .then((data) => {
                //Do some task here just after respons
                if (data.status === "success") {
                    setIsRequestSuccess(true);
                    setIsRequesting(false);
                    setTimeout(() => {
                        profile.property_details[prop_details_index].system_settings[0] =
                            request_system_settings;
                        dispatch({ type: SYSTEM_SETTINGS_UPDATED, payload: profile });
                    }, 500);
                    setTimeout(() => {
                        setIsRequestSuccess(false);
                    }, 5000);
                } else {
                    setIsRequestError(true);
                    setIsRequesting(false);
                    setResErrorMsg(data.message);
                    //return Promise.reject(data);
                }
            })
            .catch((err) => {
                setIsRequesting(false);
                setIsRequestError(true);
                setResErrorMsg(
                    (err && err.original && err.original.statusText) ||
                    "Something went wrong, please try again later!"
                );
                setTimeout(() => {
                    setIsRequestError(false);
                }, 5000);
                //return Promise.reject(err);
            });
    };

    const handleExpand = (index) => {
        if (!expanded["id_" + index]) {
            expanded["id_" + index] = true;
        } else {
            expanded["id_" + index] = false;
        }
        setExpanded({ ...expanded });
    };
    // console.log(system_settings);

    const changeRoomTypePricingOrder = (item, index, type) => {

        const tempObj = item;
        const size = requestData.pricing_settings.room_dow_plans.length;

        if (type == "up" && index) {
            requestData.pricing_settings.room_dow_plans[index] =
                requestData.pricing_settings.room_dow_plans[index - 1];
            requestData.pricing_settings.room_dow_plans[index - 1] = item;

            setRequestData({
                ...requestData,
                room_dow_plans:
                    requestData.pricing_settings.room_dow_plans,
            });
            setIsChangeRequest(true);
            // debugger;
        }
        if (type == "down" && index < size) {
            requestData.pricing_settings.room_dow_plans[index] =
                requestData.pricing_settings.room_dow_plans[index + 1];
            requestData.pricing_settings.room_dow_plans[index + 1] = item;
            setRequestData({
                ...requestData, room_dow_plans:
                    requestData.pricing_settings.room_dow_plans,
            });
            setIsChangeRequest(true);
        }
    };

    // console.log(requestData.pricing_settings.room_dow_plans);
    if (requestData.pricing_settings.room_dow_plans.length === 0) {
        addNewPricingRule('Default');
    }
    // debugger;

    return (
        <LocalizationProvider dateAdapter={AdapterMoment}>

            <Box className='breadcrumb' sx={{ color: 'white.main', position: 'absolute', top: -40 }}>
                <span className="breadcrum-inactive">Settings</span>
                <ArrowForwardIosOutlinedIcon className="breadcrum-inactive"
                    sx={{ top: 3, position: "relative", height: 18 }}
                ></ArrowForwardIosOutlinedIcon>
                <span className="breadcrum-inactive">Property Settings</span>

                <ArrowForwardIosOutlinedIcon className="breadcrum-inactive"
                    sx={{ top: 3, position: "relative", height: 18 }}
                ></ArrowForwardIosOutlinedIcon>
                <span className="breadcrum-active">Pricing Seasons</span>

            </Box>
            <div className="property-setting-heading">
                <Typography
                    className="property-setting-main-heading"
                    // variant="h1"
                    gutterBottom
                    sx={{ mr: 3, display: { lg: "inline-block", xs: "none" } }}
                >
                    Pricing Seasons
                </Typography>
                <Box sx={{ float: "right" }}>
                    <Button
                        variant="outlined"
                        size="small"
                        className="navigate-button btn-active"
                        // color="green"
                        sx={{ minWidth: "auto", mr: 1, color: "white.main" }}
                        onClick={() => {
                            addNewPricingRule();
                        }}
                    >
                        Add New
                    </Button>
                    {/* <AddNewIcon sx={{ mr: 0.5 }}></AddNewIcon> */}
                    <Button
                        disabled={!isChangeRequest}
                        onClick={() => {
                            saveChanges();
                        }}
                        variant="outlined"
                        size="small"
                        className={(isChangeRequest) ? "navigate-button btn-active" : "navigate-button"}
                        // color="green"
                        sx={{ minWidth: "auto", mr: 1, color: "white.main" }}
                    >
                        <span style={{ display: !isrequesting ? "block" : "none" }}>
                            Save
                        </span>
                        <span
                            style={{
                                display: isrequesting ? "block" : "none",
                                paddingRight: 10,
                            }}
                        >
                            saving...
                        </span>
                        <CircularProgress
                            sx={{ display: isrequesting ? "block" : "none" }}
                            color="white"
                            size="16px"
                        />
                    </Button>
                    <Button
                        disabled={!isChangeRequest}
                        onClick={cancelSave}
                        variant="outlined"
                        className={(isChangeRequest) ? "navigate-button btn-active" : "navigate-button"}

                        // color="error"
                        size="small"
                        sx={{ minWidth: "auto" }}
                    >
                        cancel
                    </Button>
                </Box>
                <Divider sx={{ clear: "both", mt: { xs: 2, lg: 0 } }} />
            </div>

            <Box className="inventory-settings" sx={{ p: 1, pt: 0, mb: 2, mt: 0, ml: 1, mr: 1 }}>


                {/* <Divider sx={{ clear: "both", mt: { xs: 2, lg: 0 } }} /> */}

                <Grid>
                    <Box
                        sx={{
                            pt: 2,
                            flexDirection: "column",
                            position: "relative",
                            display: "flex",
                        }}
                    >
                        <Alert
                            severity="success"
                            sx={{
                                width: "100%",
                                marginTop: -10,
                                display: isRequestSuccess ? "flex" : "none",
                            }}
                        >
                            {requestSuccessMsg}
                        </Alert>
                        <Alert
                            severity="error"
                            sx={{
                                width: "100%",
                                marginTop: -10,
                                display: isRequestError ? "flex" : "none",
                            }}
                        >
                            {resErrorMsg}
                        </Alert>

                        <Box
                            sx={{ mt: { lg: 0 }, pr: 0, maxHeight: "calc(100vh - 185px)", overflow: "auto" }}
                        >
                            <Box sx={{ mt: 0, mb: 2 }}>
                                {requestData.pricing_settings.room_dow_plans.map(
                                    (item, index) => {
                                        return (
                                            <Paper
                                                key={"row-uid-" + index}
                                                className="pricing-rules item"
                                                elevation={1}
                                                sx={{ mb: 1, overflow: "hidden", border: "1px solid #d1d1d1", borderRadius: "4px", marginBottom: "20px" }}
                                            >
                                                <Grid
                                                    container
                                                    className="header"
                                                    sx={{
                                                        backgroundColor: expanded["id_" + index]
                                                            ? "blue.main"
                                                            : "grey.lighter",
                                                    }}
                                                >

                                                    <Grid item xs={0.3}>
                                                        <Box sx={{ position: "relative", height: "100%" }}>
                                                            <Button
                                                                variant="outlined"
                                                                size="small"
                                                                sx={{ minWidth: "auto", p: 0, lineHeight: "auto", position: "absolute", top: "20px", left: "2px" }}
                                                                disabled={index === 0}
                                                                onClick={() => {
                                                                    changeRoomTypePricingOrder(item, index, "up");
                                                                }}
                                                                className={((index === 1 && item.plan_name.toLowerCase() !== "default") || index === 0) ? "display-none" : ""}
                                                            >
                                                                <KeyboardArrowUpOutlinedIcon
                                                                    color="{index == 0}? disabled :primary" sx={{ fontSize: "32px", position: "absolute", top: -30, marginLeft: 1.8, border: "none" }} />
                                                            </Button>
                                                            <span className="rate-push-badge"
                                                                style={{ fontSize: 17, marginTop: 13, display: "inline-block", border: "none" }}>{index + 1}</span>

                                                            <Button
                                                                variant="outlined"
                                                                size="small"
                                                                sx={{ minWidth: "auto", p: 0, lineHeight: "auto", position: "absolute", left: "14px", top: "22px" }}
                                                                disabled={index === requestData.pricing_settings.room_dow_plans.length - 1}
                                                                className={(index === 0) ? "display-none" : ""}

                                                            >

                                                                <KeyboardArrowDownOutlined
                                                                    color="{index === requestData.pricing_settings.room_dow_plans.length-1}?disabled:primary"
                                                                    sx={{ position: "absolute", bottom: -30, ml: -1.25, border: "none", fontSize: "32px" }}
                                                                    onClick={() => {
                                                                        changeRoomTypePricingOrder(item, index, "down");
                                                                    }} />

                                                            </Button>
                                                        </Box>
                                                    </Grid>

                                                    <Grid item xs={2}>
                                                        {/* <TextField
          label="Size"
          id="outlined-size-small"
          defaultValue="Small"
          size="small"
        /> */}
                                                        {(expanded["id_" + index]) ?
                                                            <><TextField
                                                                className="accordian-field input-style"
                                                                size="small"
                                                                fullWidth
                                                                margin="dense"
                                                                sx={{
                                                                    mt: 1,
                                                                    textAlign: "center",
                                                                    color: "blue.dark",
                                                                }}
                                                                value={item.plan_name}
                                                                onChange={(e) => {
                                                                    const value = e.target.value;
                                                                    updateRequestData(
                                                                        "pricing_settings>plan_name",
                                                                        value,
                                                                        index
                                                                    );
                                                                }}
                                                                // className={(item.plan_name === "Default") ? "display-none right-icons" : "right-icons"}
                                                                inputProps={
                                                                    { readOnly: (item.plan_name.toLowerCase() === "default") ? true : false }
                                                                }
                                                            /></>
                                                            :
                                                            <>
                                                                <div className="mt-5">{item.plan_name}</div></>
                                                        }

                                                    </Grid>

                                                    <Grid item xs={3.0} sx={{ pl: 16, pt: 1.5, textAlign: "center", fontSize: "13px" }}>{(item.base_rate_code) ? "Base Rate Plan - " + item.base_rate_code : ""}</Grid>
                                                    {/* <Grid item xs={.7} sx={{border:"1px solid #ff0000"}}></Grid> */}

                                                    <Grid item xs={4.2} sx={{ textAlign: "right" }} className="">



                                                        <Box sx={{ width: "100%" }}>
                                                            <Box sx={{ mt: 1, float: "left", width: "35%" }} className="">
                                                                <CalendarMonthOutlinedIcon color="primary" sx={{ float: "right" }} />
                                                            </Box>
                                                            <Box sx={{ width: "65%", float: "right", pl: 1, textAlign: "left" }} className="">
                                                                {(expanded["id_" + index]) ?
                                                                    <> <MobileDatePicker
                                                                        sx={{ fontSize: 10, ml: 0 }}
                                                                        inputFormat="ddd,MMM DD YYYY"
                                                                        value={item.plan_period.effective_date_range.from}
                                                                        onChange={(val) => {
                                                                            // debugger;
                                                                            const formated = new moment(val._d).format("YYYY-MM-DD");
                                                                            updateRequestData(
                                                                                "pricing_settings>plan_period>effective_date_range>from",
                                                                                formated,
                                                                                index
                                                                            );
                                                                        }}
                                                                        renderInput={(params) => (
                                                                            <Tooltip title="Effective From" arrow followCursor>
                                                                                <Input
                                                                                    label="From"

                                                                                    className="textfield date-picker input-style-picker"
                                                                                    size="small"
                                                                                    margin="dense"
                                                                                    sx={{ mt: 0.5, ml: 1, width: 175 }}
                                                                                    {...params}
                                                                                />
                                                                            </Tooltip>
                                                                        )}
                                                                    />
                                                                        <span>-</span>
                                                                        <MobileDatePicker
                                                                            inputFormat="ddd,MMM DD YYYY"
                                                                            value={item.plan_period.effective_date_range.until}
                                                                            onChange={(val) => {
                                                                                const formated = val.format("YYYY-MM-DD");
                                                                                updateRequestData(
                                                                                    "pricing_settings>plan_period>effective_date_range>until",
                                                                                    formated,
                                                                                    index
                                                                                );
                                                                            }}
                                                                            renderInput={(params) => (
                                                                                <Tooltip title="Effective Until" arrow followCursor>
                                                                                    <Input
                                                                                        label="From"
                                                                                        className="textfield date-picker input-style-picker"
                                                                                        size="small"
                                                                                        margin="dense"
                                                                                        sx={{ mt: 0.5, width: 175 }}
                                                                                        {...params}
                                                                                    />
                                                                                </Tooltip>
                                                                            )}
                                                                        />
                                                                    </>
                                                                    :

                                                                    <>
                                                                        <div className="mt-5">{dateFormat(item.plan_period.effective_date_range.from, "ddd,MMM DD YYYY")} - {dateFormat(item.plan_period.effective_date_range.until, "ddd,MMM DD YYYY")}</div>
                                                                    </>
                                                                }
                                                            </Box>
                                                        </Box>
                                                    </Grid>

                                                    <Grid item xs={2.5} textAlign="right">
                                                        <Switch
                                                            className="switch-button"
                                                            size="medium"
                                                            // color="green"
                                                            checked={item.active}
                                                            onChange={(e) => {
                                                                const checked = e.target.checked;
                                                                updateRequestData(
                                                                    "pricing_settings>active",
                                                                    checked,
                                                                    index
                                                                );
                                                            }}
                                                        />
                                                        {/* <Typography
                            sx={{ display: "inline-block" }}
                            visibility={item.active ? "hidden" : "visible"}
                          >
                            Activate
                          </Typography> */}


                                                        <Button
                                                            variant="text"
                                                            onClick={() => {
                                                                copyRule(index);
                                                            }}
                                                            className="right-icons"
                                                        >
                                                            <Tooltip title="Copy Rule">
                                                                <CopyRuleIcon
                                                                    fontSize="small"
                                                                    color="primary"
                                                                ></CopyRuleIcon>
                                                            </Tooltip>
                                                        </Button>

                                                        <Button
                                                            variant="text"
                                                            onClick={() => {
                                                                deleteItem(index);
                                                            }}
                                                            className={(item.plan_name.toLowerCase() === "default") ? "display-none right-icons" : "right-icons"}>
                                                            <Tooltip title="Delete">
                                                                <DeleteOutlineOutlinedIcon
                                                                    fontSize="small"
                                                                    color="error"
                                                                    className="arrow-down inside-line-rateplan expend-arrow delete-icon-button"
                                                                ></DeleteOutlineOutlinedIcon>


                                                            </Tooltip>
                                                        </Button>
                                                        <Button
                                                            variant="text"
                                                            type="button"
                                                            // className="navigate-button"
                                                            // sx={{
                                                            //   backgroundColor: "#333",
                                                            //   borderRadius: 0,
                                                            //   border: "none",
                                                            //   margin: 0,
                                                            //   pb: 1.5,
                                                            //   height: 50,
                                                            // }}
                                                            onClick={() => {
                                                                handleExpand(index);
                                                            }}
                                                        >
                                                            <KeyboardArrowDownOutlined fontSize="small"
                                                                sx={{
                                                                    display: expanded["id_" + index]
                                                                        ? "none"
                                                                        : "inline-block",
                                                                }}
                                                                className="arrow-down inside-line-rateplan expend-arrow button-grey"
                                                            />
                                                            <KeyboardArrowUpOutlinedIcon fontSize="small"
                                                                sx={{
                                                                    display: expanded["id_" + index]
                                                                        ? "inline-block"
                                                                        : "none",
                                                                }}
                                                                className="arrow-down inside-line-rateplan expend-arrow button-grey"
                                                            />
                                                        </Button>
                                                    </Grid>
                                                </Grid>

                                                <Collapse
                                                    in={expanded["id_" + index]}
                                                    timeout="auto"
                                                    unmountOnExit
                                                    sx={{ backgroundColor: "white", overflow: "visible" }}
                                                >
                                                    <Box sx={{ pb: 1.1 }}>
                                                        <Box
                                                            display="inline-block"
                                                            sx={{ p: 1, pl: 3 }}
                                                        >
                                                            Base Rate Plan
                                                            <TextField
                                                                className="accordian-field input-style-baserate"
                                                                size="small"

                                                                sx={{
                                                                    ml: 2,
                                                                    width: 80,
                                                                    "& input": { textAlign: "center" },
                                                                    color: "blue.dark",
                                                                }}
                                                                value={item.base_rate_code}
                                                                onChange={(e) => {
                                                                    const value = e.target.value;
                                                                    updateRequestData(
                                                                        "pricing_settings>base_rate_code",
                                                                        value,
                                                                        index
                                                                    );
                                                                }}
                                                            />
                                                        </Box>
                                                        <Box className="room-plans-grids">
                                                            <Grid container className="header">
                                                                <Grid
                                                                    className="header-item header-color"
                                                                    item
                                                                    xs={3.5}
                                                                    sx={{ textAlign: "left" }}
                                                                >
                                                                    <span style={{ paddingLeft: 22 }}>
                                                                        ROOM TYPE
                                                                    </span>
                                                                </Grid>
                                                                <Grid className="header-color pl-10" item xs={1.2} sx={{ textAlign: "left" }}>
                                                                    SUN
                                                                </Grid>
                                                                <Grid className="header-color pl-10" item xs={1.2} sx={{ textAlign: "left" }}>
                                                                    MON
                                                                </Grid>
                                                                <Grid className="header-color pl-10" item xs={1.2} sx={{ textAlign: "left" }}>
                                                                    TUE
                                                                </Grid>
                                                                <Grid className="header-color pl-10" item xs={1.2} sx={{ textAlign: "left" }}>
                                                                    WED
                                                                </Grid>
                                                                <Grid className="header-color pl-10" item xs={1.2} sx={{ textAlign: "left" }}>
                                                                    THU
                                                                </Grid>
                                                                <Grid className="header-color pl-10" item xs={1.2} sx={{ textAlign: "left" }}>FRI</Grid>
                                                                <Grid className="header-color pl-10" item xs={1.2} sx={{ textAlign: "left" }}>SAT</Grid>
                                                            </Grid>

                                                            <Box sx={{ overflow: "auto" }}>
                                                                {room_settings.map((itm, i) => {
                                                                    const dow = (item.room_plans[itm.room_lodgiq_code]) ? item.room_plans[itm.room_lodgiq_code] : [];
                                                                    const days = ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'];
                                                                    // const days = Object.keys(dow);
                                                                    return (
                                                                        <Grid
                                                                            container
                                                                            className={
                                                                                "content" + (i % 2 ? " odd" : " even")
                                                                            }
                                                                        >
                                                                            <Grid item className="room-type" xs={3.5}>
                                                                                <div className="name">
                                                                                    {itm.display_name}
                                                                                </div>
                                                                            </Grid>

                                                                            {days.map((val, ind) => (
                                                                                <Grid
                                                                                    item
                                                                                    className="value-edit"
                                                                                    xs={1.2}
                                                                                >
                                                                                    <Input
                                                                                        className="form-control value-edit-text"
                                                                                        sx={{ input: { textAlign: "center" } }}
                                                                                        size="small"
                                                                                        value={dow[val]}
                                                                                        onChange={(e) => {
                                                                                            const value = e.target.value;
                                                                                            updateRequestData(
                                                                                                "pricing_settings>room_plans>" +
                                                                                                itm.room_lodgiq_code +
                                                                                                ">" +
                                                                                                val,
                                                                                                value,
                                                                                                index
                                                                                            );
                                                                                        }}
                                                                                    />
                                                                                </Grid>
                                                                            ))}
                                                                        </Grid>
                                                                    );
                                                                })}
                                                            </Box>

                                                            <Box sx={{ p: 1.1, backgroundColor: "#f5f6fd" }}>
                                                                <Button
                                                                    disabled={!isChangeRequest}
                                                                    onClick={() => {
                                                                        saveChangesTemp();
                                                                    }}
                                                                    variant="outlined"
                                                                    size="small"
                                                                    className={(isChangeRequest) ? "navigate-button btn-active" : "navigate-button"}

                                                                    // color="green"
                                                                    sx={{ minWidth: "auto", mr: 1, color: "white.main" }}
                                                                >
                                                                    <span style={{ display: !isrequesting ? "block" : "none" }}>
                                                                        Save
                                                                    </span>
                                                                    <span
                                                                        style={{
                                                                            display: isrequesting ? "block" : "none",
                                                                            paddingRight: 10,
                                                                        }}
                                                                    >
                                                                        saving...
                                                                    </span>
                                                                    <CircularProgress
                                                                        sx={{ display: isrequesting ? "block" : "none" }}
                                                                        color="white"
                                                                        size="16px"
                                                                    />
                                                                </Button>
                                                                <Button
                                                                    disabled={!isChangeRequest}
                                                                    onClick={cancelSave}
                                                                    variant="outlined"
                                                                    className={(isChangeRequest) ? "navigate-button btn-active" : "navigate-button"}

                                                                    // color="error"
                                                                    size="small"
                                                                    sx={{ minWidth: "auto" }}
                                                                >
                                                                    cancel
                                                                </Button>
                                                            </Box>


                                                        </Box>
                                                    </Box>
                                                    <Grid container spacing={1}></Grid>
                                                </Collapse>
                                            </Paper>
                                        );
                                    }
                                )}
                            </Box>
                        </Box>
                        {isrequesting && (
                            <Box
                                sx={{
                                    textAlighn: "center",
                                    backgroundColor: "dark.darkest",
                                    opacity: 0.8,
                                    position: "fixed",
                                    top: 40,
                                    left: 0,
                                    width: "100%",
                                    height: "100%",
                                }}
                            >
                                <CircularProgress
                                    size={40}
                                    sx={{
                                        color: "white",
                                        position: "absolute",
                                        top: "50%",
                                        left: "50%",
                                        marginTop: "-20px",
                                        marginLeft: "-20px",
                                    }}
                                />
                            </Box>
                        )}
                    </Box>
                </Grid>

            </Box>
        </LocalizationProvider>
    );
}

function AddNewProperty(props) {
    const dispatch = useDispatch();

    const [isrequesting, setIsRequesting] = useState(false);
    const [resError, setResError] = useState(false);
    const [isRequestSuccess, setIsRequestSuccess] = useState(false);
    const [requestSuccessMsg, setRequestSuccessMsg] = useState(
        "New property is added successfull!"
    );
    const [isRequestError, setIsRequestError] = useState(false);
    const [resErrorMsg, setResErrorMsg] = useState("");

    const { app, auth } = useSelector((state) => state);
    const { user, profile } = auth;
    const { app_state, ui_state } = app.current_state;
    const { property_details } = profile || [];
    //Data source information
    const [dsInfo, setDsInfo] = useState(getDataSources(property_details, app_state.property_id));
    const settings_ = {
        property_name: "",
        status: "PRELAUNCH",
        assigned_id: "",
        assigned_id_type: "MANUAL",
    };

    const settings = JSON.parse(JSON.stringify(settings_));
    const [requestData, setRequestData] = useState(settings);
    const [isChangeRequest, setIsChangeRequest] = useState(false);

    const cancelSave = () => {
        setRequestData(settings_);
        setIsChangeRequest(false);
    };
    const updateRequestData = (keys, val) => {
        const updatedObj = { ...requestData };
        const key = keys.split(">");
        //debugger;
        if (key[0]) {
            updatedObj[key[0]] = val;
        }
        if (key[0] === "assigned_id_type" && val === "AUTO") {
            updatedObj.assigned_id_type = val;
            updatedObj.assigned_id_manual = "";
        }
        setRequestData({ ...requestData, ...updatedObj });
        setIsChangeRequest(true);
    };

    const saveChanges = (event) => {
        setIsRequesting(true);
        const new_property = JSON.parse(JSON.stringify(requestData));
        // debugger;
        let payload = {
            property_id: app_state.property_id,
            user_id: user.user_id,
            token_string: user.token_string,
            property_name: new_property.property_name,
            status: new_property.status,
            assigned_id:
                new_property.assigned_id_manual || new_property.assigned_id_type,
        };
        dataService
            .update({ api: "settings/add_property", request: payload })
            .then((data) => {
                //Do some task here just after respons
                if (data.status === "success") {
                    setIsRequestSuccess(true);
                    setIsRequesting(false);
                    setTimeout(() => {
                        setIsRequestSuccess(false);
                    }, 5000);
                } else {
                    setIsRequestError(true);
                    setIsRequesting(false);
                    setResErrorMsg(data.message);
                    //return Promise.reject(data);
                }
            })
            .catch((err) => {
                setIsRequesting(false);
                setIsRequestError(true);
                setResErrorMsg(
                    (err && err.original && err.original.statusText) ||
                    "Something went wrong, please try again later!"
                );
                setTimeout(() => {
                    setIsRequestError(false);
                }, 5000);
                //return Promise.reject(err);
            });
    };
    //console.log(system_settings);

    return (
        <div>
            <Box className='breadcrumb' sx={{ color: 'white.main', position: 'absolute', top: -60 }}>
                Settings
                <ArrowForwardIosOutlinedIcon
                    sx={{ top: 3, position: "relative", height: 18 }}
                ></ArrowForwardIosOutlinedIcon>
                Property Settings
                <ArrowForwardIosOutlinedIcon
                    sx={{ top: 3, position: "relative", height: 18 }}
                ></ArrowForwardIosOutlinedIcon>
                Add New Property
            </Box>

            <Box className="segments-settings" sx={{ p: 2, pt: 2, mb: 2, mt: 2, ml: 2, mr: 2, border: "1px solid #efefef" }}>
                <Typography
                    variant="h5"
                    gutterBottom
                    sx={{ mr: 3, display: { lg: "inline-block", xs: "none" } }}
                >
                    Add New Property
                </Typography>
                <Box sx={{ float: "right" }}>
                    <Button
                        disabled={!isChangeRequest}
                        onClick={() => {
                            saveChanges();
                        }}
                        variant="contained"
                        size="small"
                        color="green"
                        sx={{ minWidth: "auto", mr: 1, color: "white.main" }}
                    >
                        <span style={{ display: !isrequesting ? "block" : "none" }}>
                            Add New
                        </span>
                        <span
                            style={{
                                display: isrequesting ? "block" : "none",
                                paddingRight: 10,
                            }}
                        >
                            saving...
                        </span>
                        <CircularProgress
                            sx={{ display: isrequesting ? "block" : "none" }}
                            color="white"
                            size="16px"
                        />
                    </Button>
                    <Button
                        disabled={!isChangeRequest}
                        onClick={cancelSave}
                        variant="contained"
                        color="error"
                        size="small"
                        sx={{ minWidth: "auto" }}
                    >
                        cancel
                    </Button>
                </Box>
                <Divider sx={{ clear: "both", mt: { xs: 2, lg: 0 } }} />

                <Box
                    sx={{
                        pt: 2,
                        flexDirection: "column",
                        position: "relative",
                        display: "flex",
                    }}
                >
                    <Alert
                        severity="success"
                        sx={{
                            width: "100%",
                            marginTop: -10,
                            display: isRequestSuccess ? "flex" : "none",
                        }}
                    >
                        {requestSuccessMsg}
                    </Alert>
                    <Alert
                        severity="error"
                        sx={{
                            width: "100%",
                            marginTop: -10,
                            display: isRequestError ? "flex" : "none",
                        }}
                    >
                        {resErrorMsg}
                    </Alert>

                    <Box sx={{ mt: { lg: 0 } }}>
                        <Grid container spacing={2} sx={{ fontSize: 10 }}>
                            <Grid item lg={12}>
                                <FormControl fullWidth size="small">
                                    <InputLabel>Property Name</InputLabel>
                                    <OutlinedInput
                                        label="Property Name"
                                        value={requestData.property_name}
                                        onChange={(e) => {
                                            updateRequestData("property_name", e.currentTarget.value);
                                        }}
                                    />
                                </FormControl>
                            </Grid>

                            <Grid item lg={12}>
                                <FormControl fullWidth size="small">
                                    <InputLabel> Property ID</InputLabel>
                                    <OutlinedInput
                                        label=" Property ID"
                                        value={requestData.assigned_id_type}
                                        onChange={(e) => {
                                            // const value = e.target.checked;
                                            requestData.assigned_id_type = e.target.value;
                                            updateRequestData("assigned_id_type", e.target.value);
                                        }}
                                    />
                                </FormControl>
                            </Grid>

                            {/* <Grid item lg={12}>
                <FormControl fullWidth size="small">
                  <FormLabel id="demo-radio-buttons-group-label">
                    Property ID
                  </FormLabel>
                  <RadioGroup
                    row
                    value={requestData.assigned_id_type}
                    defaultValue="MANUAL"
                    onChange={(e) => {
                      const value = e.target.checked;
                      requestData.assigned_id_type = e.target.value;
                      updateRequestData("assigned_id_type", e.target.value);
                    }}
                  >
                    <FormControlLabel
                      value="MANUAL"
                      control={<Radio size="small" />}
                      label="Manual"
                    />
                    <FormControlLabel
                      value="AUTO"
                      control={<Radio size="small" />}
                      label="Auto Assign"
                    />
                  </RadioGroup>
                </FormControl>
              </Grid> 
              {requestData.assigned_id_type === "MANUAL" && (
                <Grid item lg={12} sx={{ mt: -2.5 }}>
                  <FormControl fullWidth size="small" sx={{ pt: 0, mt: 0 }}>
                    <OutlinedInput
                      value={requestData.assigned_id_manual}
                      onChange={(e) => {
                        const value = e.target.value;
                        updateRequestData("assigned_id_manual", value);
                      }}
                    />
                  </FormControl>
                </Grid>
              )}
              <Grid item lg={12}>
                <FormControl fullWidth size="small">
                  <FormLabel id="demo-radio-buttons-group-label">
                    Initial Status
                  </FormLabel>
                  <RadioGroup
                    row
                    name="row-radio-buttons-group"
                    value={requestData.status}
                    onChange={(e) => {
                      const value = e.target.value;
                      requestData.status = value;
                      updateRequestData("status", value);
                    }}
                  >
                    <FormControlLabel
                      control={
                        <Radio
                          size="small"
                          value="PRELAUNCH"
                          checked={requestData.status === "PRELAUNCH"}
                        />
                      }
                      label="Prelaunch"
                    />
                    <FormControlLabel
                      control={
                        <Radio
                          size="small"
                          value="ACTIVE"
                          checked={requestData.status === "ACTIVE"}
                        />
                      }
                      label="Live"
                    />
                  </RadioGroup>
                </FormControl>
              </Grid>*/}
                        </Grid>
                    </Box>
                    {isrequesting && (
                        <Box
                            sx={{
                                textAlighn: "center",
                                backgroundColor: "dark.darkest",
                                opacity: 0.8,
                                position: "fixed",
                                top: 40,
                                left: 0,
                                width: "100%",
                                height: "100%",
                            }}
                        >
                            <CircularProgress
                                size={40}
                                sx={{
                                    color: "white",
                                    position: "absolute",
                                    top: "50%",
                                    left: "50%",
                                    marginTop: "-20px",
                                    marginLeft: "-20px",
                                }}
                            />
                        </Box>
                    )}
                </Box>
            </Box>
        </div>
    );
}

export {
    InventorySettings,
    CompsetSettings,
    RatesSettings,
    SegmentsSettings,
    MarketsSettings,
    LocaleSettings,
    IntegrationSettings,
    DiscountRulesSettings,
    AlertsSettings,
    ManageRatePlans,
    PricingRules,
    DefaultRoomTypePricing,
    AddNewProperty,
};
