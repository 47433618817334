import React, { useState, useEffect, useReducer } from 'react';

import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import CloudUploadOutlinedIcon from '@mui/icons-material/CloudUploadOutlined';
import FileDownloadOutlinedIcon from '@mui/icons-material/FileDownloadOutlined';
import CircularProgress from '@mui/material/CircularProgress';
import TextField from '@mui/material/TextField';
import MailIcon from '@mui/icons-material/Mail';
import Alert from '@mui/material/Alert';
import Stack from '@mui/material/Stack';
import Avatar from '@mui/material/Avatar';
import Chip from '@mui/material/Chip';
import Tooltip from '@mui/material/Tooltip';

import moment from 'moment';
import {
    Grid, Button, LinearProgress, Badge, Modal, IconButton,
    Table, TableBody, TableCell, TableContainer, TableHead, TableRow
} from '@mui/material';
import {
    CloseSharp as CloseSharpIcon,
    ArrowBackIosNewSharp as ArrowBackIosNewSharpIcon,
    ArrowForwardIosSharp as ArrowForwardIosSharpIcon,
    CheckSharp as CheckSharpIcon
}
    from '@mui/icons-material';
import { useDispatch, useSelector } from 'react-redux';
import { getPropertyInfo, updateAppState, getData } from '../../actions/appstate';
import { getPricingDataFormatted } from '../DynamicTable/tilesData/pricing';
import { API_REGISTRY } from "../../app/config";
import DataService from "../../services/data.service";
import { deepCopy, generateRoomTypeExcel, downloadReportToExcel, safeReference } from "../../app/util";
import { getForecastNonSegmentedDataNew } from '../DynamicTable/tilesData/forecast';
import { fontWeight } from '@mui/system';
import "./style.css";

export default function SendPricingRate({ isOpen }) {

    const { auth } = useSelector((state) => state);
    const { user, profile } = auth;
    const dispatch = useDispatch();
    const { app_state, ui_state } = useSelector((state) => state.app.current_state);
    const { roomtype_pricing_overrides, pricing_override_rates, property_id, property_name, room_settings } = app_state;
    const { isRoomTypePricing } = app_state.workspace_controls;
    const [rows, setRows] = useState([]);
    const [numUpdates, setNumUpdates] = useState(0);
    const [roomTypePricingData, setRoomTypePricingData] = useState([]);
    const [isModelOpen, setOpen] = useState(isOpen);

    const [ratePushSucessOpen, setRatePushSuccessOpen] = useState(false);
    const [ratePushFail, setRatePushFail] = useState(false);
    const [compState, setCompState] = useState({
        count: 0,
        rows: [],
        pushSuccess: false,
        pushError: false,
        progress: {
            all: 'idle',
        },
        pushStatus: {},
        pushedCount: 0,
        successTimeout: 4000
    });
    useEffect(() => {
        dispatch(updateAppState("close modal", {
            ui_state: { pricingRateModalOpen: false }
        }))
    }, [])
    useEffect(() => {
        let isSubscribed = true;
        if (ui_state.pricingRateModalOpen === true) {
            setOpen(true);
            const pricingData = getPricingDataFormatted(app_state, isRoomTypePricing);
            if (isRoomTypePricing === true && roomtype_pricing_overrides) {
                const roomPricingDataArr = []
                Object.keys(roomtype_pricing_overrides).forEach(stay_date => {
                    const dateUpdates = []
                    room_settings.forEach((room) => {
                        let previous_rate = 0;
                        pricingData.map(pricing => {
                            if (pricing.stay_date === stay_date && pricing[room.room_code]) {
                                previous_rate = pricing[room.room_code].SellRate; // dont know about this
                            }
                        })
                        if (roomtype_pricing_overrides[stay_date] &&
                            roomtype_pricing_overrides[stay_date][room.room_code]) {
                            let roomCodeUpdate = { ...roomtype_pricing_overrides[stay_date][room.room_code] };

                            roomCodeUpdate["previous_rate"] = previous_rate;
                            roomCodeUpdate["room_code"] = room.room_code;
                            if (previous_rate !== roomCodeUpdate.SellRate || roomtype_pricing_overrides[stay_date].success_push === true) {
                                dateUpdates.push(roomCodeUpdate);
                            }
                        }


                    })
                    if (dateUpdates.length > 0) {
                        let priceData = {
                            ...roomtype_pricing_overrides[stay_date],
                            stay_date,
                            date_updates: dateUpdates
                        };

                        roomPricingDataArr.push(priceData)
                    }


                })
                setRoomTypePricingData(roomPricingDataArr);
                setNumUpdates(roomPricingDataArr.length)
            } else if (pricing_override_rates) {
                const dataArr = [];
                Object.keys(pricing_override_rates).map(date => {
                    const { override_rate, approved, recommend_accept } = pricing_override_rates[date];
                    let current_rate = "";
                    pricingData.map(pricing => {
                        if (pricing.stay_date === date) {
                            current_rate = pricing.SellRate; // dont know about this

                        }
                    })
                    if (approved === true) {
                        const row = {
                            stay_date: date,
                            current_rate,
                            override_rate,
                        }
                        row.message = pricing_override_rates[date].message;
                        row.success_push = pricing_override_rates[date].success_push;
                        row.loading = pricing_override_rates[date].loading;
                        row.fail = pricing_override_rates[date].fail;
                        dataArr.push(row)
                    }
                })
                setCompState(prevState => {
                    prevState.count = dataArr.length;
                    prevState.rows = [...dataArr];
                    return prevState;
                })
                setRows(dataArr);
                setNumUpdates(dataArr.length)
            }
        } else {
            setOpen(false);
        }
        return () => (isSubscribed = false);
    }, [app_state.pricing_override_rates,
    app_state.roomtype_pricing_overrides,
    ui_state.pricingRateModalOpen])
    const initalState = {
        data: {},
        rateCount: 10,
        ui: {
            status: "idle",
            message: "",
        }

    };


    //debugger;
    const handleDelete = (index) => {
        const { stay_date } = rows[index];
        rows.splice(index, 1);
        delete pricing_override_rates[stay_date]
        setRows(rows);
        dispatch(updateAppState("change_pricing_rates", {
            app_state: {
                pricing_override_rates: { ...pricing_override_rates }
            }
        }))
    }

    const handleRatePush = (dayData) => {
        let { uri } = API_REGISTRY["SendRatePush"];
        uri += app_state.property_id + "/";
        let request = {};
        const pricingData = getPricingDataFormatted(app_state);
        const pricingDataRoomType = getPricingDataFormatted(app_state, true);
        const forecastData = getForecastNonSegmentedDataNew();
        const otbData = deepCopy(DataService.getSeriesById("OSR").data.data);
        let rates = {}, otb = {}, forecast = {};
        if (dayData) {
            if (isRoomTypePricing === false) {

                request = {
                    ...API_REGISTRY["SendRatePush"].payload,
                    "user_id": user.user_id,
                    "pid": app_state.property_id,
                    "asof_date": app_state.primary_stay_range.asof_date,
                }
                request["segment_type"] = "market_code";
                request["rate_code"] = "TBAR";
                pricingData.map(pricing => {
                    if (pricing.stay_date === dayData.stay_date) {
                        rates = pricing;
                    }
                })
                forecastData.map(fdata => {
                    if (fdata.index.date === dayData.stay_date) {
                        forecast = fdata.HotelTotal.primary;
                    }
                })
                otbData.map(onthebooks => {
                    if (onthebooks.index.date === dayData.stay_date) {
                        otb = onthebooks.HotelTotal.primary;
                    }
                })
                let user_action = (dayData.override_rate === rates.RecSellRate) ? "recommendation_accept" : "overridden";
                request.rate_updates = [];
                request.rate_updates.push({
                    "stay_date": dayData.stay_date,
                    "new_rate": Number(dayData.override_rate),
                    "old_rate": Number(dayData.current_rate),
                    "system_recommended_rate": Number(rates.RecSellRate),
                    // "user_override_rate": dayData.override_rate,
                    "user_action": user_action,
                    "snapshot": {
                        "otb": { rooms: otb.Rooms, occ: otb.Occ, adr: otb.ADR, revenue: otb.Revenue },
                        "forecast": { rooms: forecast.Rooms, occ: forecast.Occ, adr: forecast.ADR, revenue: forecast.Revenue },
                        // "rates": { "current_rate": rates.RecSellRate, "recommended_rate": rates.SellRate }
                        "rates": { "current_rate": rates.SellRate, "recommended_rate": rates.RecSellRate }
                    },
                    // "rule_rate": 900 // hardCode
                })
                app_state.pricing_override_rates[dayData.stay_date]["loading"] = true;
                setRows(prevRows => {
                    return [...prevRows.map(row => {
                        if (row.stay_date === dayData.stay_date) {
                            // row.status= "loading";
                            row.loading = true;
                        }
                        return row;
                    })]
                });
            } else {

                request = {
                    ...API_REGISTRY["SendRatePushRoomType"].payload,
                    "user_id": user.user_id,
                    "pid": app_state.property_id,
                    "asof_date": app_state.primary_stay_range.asof_date,

                }
                let propertyInfo = getPropertyInfo(profile.property_details, property_id);
                let room_settings = safeReference(propertyInfo.system_settings[0], [], ["inventory_settings", "current", "room_settings"])
                //delete app_state.roomtype_pricing_overrides[dayData.stay_date];
                app_state.roomtype_pricing_overrides[dayData.stay_date].loading = true;
                let room_rates = [];
                dayData.date_updates.forEach(room_data => {
                    let roomDetails;
                    room_settings.map(room => {
                        if (room.room_code === room_data.room_code) {
                            roomDetails = room;
                        }
                    })
                    let user_action = "recommendation_accept";
                    pricingDataRoomType.forEach(item => {
                        if (item.stay_date === dayData.stay_date) {
                            if (room_data.SellRate !== item[room_data.room_code].RecSellRate) {
                                user_action = "overridden";
                            }
                        }
                    })
                    room_rates.push({
                        "room_type": room_data.room_code,
                        "new_rate": Number(room_data.SellRate),
                        "diff_on_record": 0,
                        "old_rate": Number(room_data.previous_rate),
                        "prior_diff": 0,
                        "is_base": roomDetails.is_base,
                        "user_action": user_action,
                        "snapshot": {
                            "pms_room_code": room_data.room_code,
                            "otb": {
                                "rooms_sold": roomDetails.inventory,
                                "room_capacity": "8"
                            }
                        },
                        "is_override": true,
                        "is_rec_accept": false,
                        "override_type": "rate",
                        "rec_accept_type": "",
                        "is_cascade": false
                    })
                })
                request.rate_updates = [];
                request.rate_updates.push({
                    "stay_date": dayData.stay_date,
                    "snapshot": {},
                    room_rates
                })
                // setRoomTypePricingData(pervData=>{
                //     return [...pervData.filter(data=>data.stay_date !== dayData.stay_date)]
                // });
                setRoomTypePricingData(pervData => {
                    return [...pervData.map(data => {
                        if (data.stay_date === dayData.stay_date) {
                            data.loading = true;

                        }
                        return data;
                    })]
                });
            }


            dispatch(updateAppState("confirmed_rates_success", {
                app_state: {
                    pricing_override_rates: { ...app_state.pricing_override_rates },
                    roomtype_pricing_overrides: { ...app_state.roomtype_pricing_overrides }
                },
                // ui_state: { pricingRateModalOpen: false }
            }))

            // return DataService.getData({api:uri,request}).then((res)=>{
            //     console.log(res)
            // }).catch(err=>{
            //     console.log(err);
            // })
        } else {

            if (isRoomTypePricing === false) {
                request = {
                    ...API_REGISTRY["SendRatePush"].payload,
                    "user_id": user.user_id,
                    "pid": app_state.property_id,
                    "asof_date": app_state.primary_stay_range.asof_date,
                }
                request["segment_type"] = "market_code";
                request["rate_code"] = "TBAR";
                request.rate_updates = [];
                rows.map(data => {
                    pricingData.map(pricing => {
                        if (pricing.stay_date === data.stay_date) {
                            rates = pricing;
                        }
                    })
                    forecastData.map(fdata => {
                        if (fdata.index.date === data.stay_date) {
                            forecast = fdata.HotelTotal.primary;
                        }
                    })
                    otbData.map(onthebooks => {
                        if (onthebooks.index.date === data.stay_date) {
                            otb = onthebooks.HotelTotal.primary;
                        }
                    })
                    let user_action = (data.override_rate === rates.RecSellRate) ? "recommendation_accept" : "overridden";

                    request.rate_updates.push({
                        "stay_date": data.stay_date,
                        "new_rate": Number(data.override_rate),
                        "old_rate": Number(data.current_rate),
                        "system_recommended_rate": rates.RecSellRate,
                        // "user_override_rate": data.override_rate,
                        "user_action": user_action,
                        "snapshot": {
                            "otb": { rooms: otb.Rooms, occ: otb.Occ, adr: otb.ADR, revenue: otb.Revenue },
                            "forecast": { rooms: forecast.Rooms, occ: forecast.Occ, adr: forecast.ADR, revenue: forecast.Revenue },
                            // "rates": { "current_rate": rates.RecSellRate, "recommended_rate": rates.SellRate }
                            "rates": { "current_rate": rates.SellRate, "recommended_rate": rates.RecSellRate }

                        },
                        // "rule_rate": 900 // hardCode
                    })
                })

            } else {

                request = {
                    ...API_REGISTRY["SendRatePushRoomType"].payload,
                    "user_id": user.user_id,
                    "pid": app_state.property_id,
                    "asof_date": app_state.primary_stay_range.asof_date,

                }
                let propertyInfo = getPropertyInfo(profile.property_details, property_id);
                let room_settings = safeReference(propertyInfo.system_settings[0], [], ["inventory_settings", "current", "room_settings"])
                request.rate_updates = [];
                roomTypePricingData.forEach(data => {
                    if (data.success_push === true) {
                        return;
                    }
                    let room_rates = [];
                    data.date_updates.forEach(room_data => {
                        let roomDetails = {};
                        let user_action = "recommendation_accept";
                        pricingDataRoomType.forEach(item => {
                            if (item.stay_date === data.stay_date) {
                                if (room_data.SellRate !== item[room_data.room_code].RecSellRate) {
                                    user_action = "overridden";
                                }
                            }
                        })
                        room_settings.map(room => {
                            if (room.room_code === room_data.room_code) {
                                roomDetails = room;
                            }
                        })
                        if (room_data.SellRate) {
                            room_rates.push({
                                "room_type": room_data.room_code,
                                "new_rate": Number(room_data.SellRate),
                                "diff_on_record": 0,
                                "old_rate": Number(room_data.previous_rate),
                                "prior_diff": 0,
                                "is_base": roomDetails.is_base,
                                "user_action": user_action,
                                "snapshot": {
                                    "pms_room_code": room_data.room_code,
                                    "otb": {
                                        "rooms_sold": roomDetails.inventory,
                                        "room_capacity": "8"
                                    }
                                },
                                "is_override": true,
                                "is_rec_accept": false,
                                "override_type": "rate",
                                "rec_accept_type": "",
                                "is_cascade": false
                            })
                        }

                    })
                    request.rate_updates.push({
                        "stay_date": data.stay_date,
                        "snapshot": {},
                        room_rates
                    })

                })
            }





            // return DataService.getData({api:uri,request}).then((res)=>{
            //     console.log(res)
            //     setRatePushSuccessOpen(true);
            // }).catch(err=>{
            //     console.log(err);
            // })
        }
        //

        DataService.getData({ api: uri, request }).then((res) => {


            if (!dayData) {
                if (res.status !== "success") {
                    if (isRoomTypePricing === false) {
                        Object.keys(app_state.pricing_override_rates).forEach(stay_date => {
                            app_state.pricing_override_rates[stay_date].fail = true;
                            app_state.pricing_override_rates[stay_date].message = res.message;
                        })

                        setRows(prevRows => {
                            return [...prevRows.map(row => {
                                row.fail = true;
                                row.message = res.message;
                                row.loading = false;
                                return row;
                            })]
                        });
                    }
                    else {

                        Object.keys(app_state.roomtype_pricing_overrides).forEach(stay_date => {
                            app_state.roomtype_pricing_overrides[stay_date].fail = true;
                            app_state.roomtype_pricing_overrides[stay_date].message = res.message;
                        })
                        setRoomTypePricingData(pervData => {
                            return [...pervData.map(data => {

                                data.loading = false;
                                data.fail = true;
                                data.message = res.message

                                return data;
                            })]
                        });
                    }
                } else {
                    setRatePushSuccessOpen(true);
                    setRows([]);
                    setRoomTypePricingData(prev => {
                        prev = [];
                        return prev;
                    });
                    getData({
                        ...app_state, active_api: "OSRRateRecommend", without_cache: true
                    })().then(res => {
                        dispatch(updateAppState("reload", { // this should be somwhere else when you change property not on menu close
                            app_state: {
                                data_load: true,
                                pricing_override_rates: {},
                                roomtype_pricing_overrides: {}
                            },
                            ui_state: {
                                showspinner: true,
                            }
                        }));
                    })
                    setTimeout(() => {
                        setRatePushSuccessOpen(false);
                        dispatch(updateAppState("confirmed_rates_success_empty", {

                            ui_state: { pricingRateModalOpen: false }
                        }));
                    }, 4000)
                }

            } else {
                if (isRoomTypePricing === false) {
                    app_state.pricing_override_rates[dayData.stay_date].loading = false;

                } else {
                    app_state.roomtype_pricing_overrides[dayData.stay_date].loading = false;

                }

                if (res.status !== 'success') {
                    if (isRoomTypePricing === false) {
                        app_state.pricing_override_rates[dayData.stay_date].fail = true;
                        app_state.pricing_override_rates[dayData.stay_date].message = res.message;
                        setRows(prevRows => {
                            return [...prevRows.map(row => {
                                if (row.stay_date === dayData.stay_date) {
                                    //row.status="success_push"
                                    row.message = res.message;
                                    row.fail = true;
                                    row.loading = false;
                                }
                                return row;
                            })]
                        });

                    } else {
                        app_state.roomtype_pricing_overrides[dayData.stay_date].fail = true;
                        app_state.roomtype_pricing_overrides[dayData.stay_date].message = res.message;
                        setRoomTypePricingData(pervData => {
                            return [...pervData.filter(data => {
                                if (data.stay_date === dayData.stay_date) {
                                    data.loading = false;
                                    data.fail = true;
                                    data.message = res.message
                                }
                                return data;
                            })]
                        });
                    }
                }
                else {
                    if (isRoomTypePricing === false) {
                        app_state.pricing_override_rates[dayData.stay_date].success_push = true;
                    } else {
                        app_state.roomtype_pricing_overrides[dayData.stay_date].success_push = true;
                    }

                    getData({
                        ...app_state, active_api: "OSRRateRecommend", without_cache: true
                    })().then(res => {
                        dispatch(updateAppState("reload", { // this should be somwhere else when you change property not on menu close
                            app_state: {
                                data_load: true,
                            },
                            ui_state: {
                                showspinner: true,
                            }
                        }));
                    })

                    if (isRoomTypePricing === true) {
                        setRoomTypePricingData(pervData => {
                            return [...pervData.filter(data => {
                                if (data.stay_date === dayData.stay_date) {
                                    data.loading = false;
                                    data.success_push = true;

                                }
                                return data;
                            })]
                        });
                    } else {
                        setRows(prevRows => {
                            return [...prevRows.map(row => {
                                if (row.stay_date === dayData.stay_date) {
                                    //row.status="success_push"
                                    row.success_push = true;
                                    row.loading = false;
                                }
                                return row;
                            })]
                        });
                    }
                }



                dispatch(updateAppState("update_pricing_override_rates", {
                    app_state: {
                        pricing_override_rates: { ...app_state.pricing_override_rates },
                        roomtype_pricing_overrides: { ...app_state.roomtype_pricing_overrides }
                    }
                }));

            }

        }).catch(err => {
            console.log(err);
            if (dayData) {
                setRows(prevRows => {
                    return [...prevRows.map(row => {
                        if (row.stay_date === dayData.stay_date) {
                            row.fail = true;
                            row.loading = false;
                        }
                        return row;
                    })]
                });
            }
        })

    }
    const ratePushSucessDiv = (<>
        <Grid item className='success-popup'></Grid>
        <div className='rate-push-success'>
            <div className='rate-push-success-container'>
                <Grid item className='task-complete'></Grid>
                <Grid item className='success-text'>Success!</Grid>
                <Grid item>Check you rates push status beloW </Grid>
                <Button variant="contained" onClick={() => {
                    setRatePushSuccessOpen(false);
                    Object.keys(app_state.pricing_override_rates).forEach(stay_date => {
                        if (app_state.pricing_override_rates[stay_date].success_push) {
                            delete app_state.pricing_override_rates[stay_date];
                        }
                    })
                    Object.keys(app_state.roomtype_pricing_overrides).forEach(stay_date => {
                        if (app_state.roomtype_pricing_overrides[stay_date].success_push) {
                            delete app_state.roomtype_pricing_overrides[stay_date];
                        }
                    })
                    let tile = (isRoomTypePricing) ? "rm_room_type_ratesend_status" : "DailyRatesendStatus";
                    dispatch(updateAppState("sendPricingRateClose", {
                        app_state: {
                            pricing_override_rates: app_state.pricing_override_rates,
                            roomtype_pricing_overrides: { ...app_state.roomtype_pricing_overrides }
                        },
                        ui_state: { pricingRateModalOpen: false, active_tiles: [tile] }
                    }));
                    setOpen(false);
                }} className='button-green'>
                    {isRoomTypePricing === false && "DAILY RATE PUSH STATUS"}
                    {isRoomTypePricing === true && "ROOMS RATE PUSH STATUS"}
                </Button>
            </div>
        </div></>);
    const housePricingTable = (<TableContainer className='rate-push-table' sx={{ maxHeight: "91%" }}>
        <Table stickyHeader aria-label="simple table">
            <TableHead>
                <TableRow sx={{ backgroundColor: '#606060', '& td, & th': { pt: 1, pb: 1 } }}>
                    <TableCell align="center">DATE	</TableCell>
                    <TableCell align="center">CURRENT RATE</TableCell>
                    <TableCell align="center">APPROVED RATE</TableCell>
                    {/* <TableCell align="center" sx={{ color: '#fff', width: 150 }}>RATE OVERRIDE</TableCell> */}
                    <TableCell align="left" className='status-column'>STATUS</TableCell>
                </TableRow>
            </TableHead>
            <TableBody className='ratepush-tbody pricing' sx={{ overflowX: "hidden", overflowY: "auto" }}>


                {rows.length > 0 ?
                    rows.map((row, index) => (
                        <TableRow
                            key={index}
                            sx={{ '&:last-child td, &:last-child th': { border: 0 }, '& td, & th': { pt: 1, pb: 1, fontSize: 14 } }}
                        >
                            <TableCell align='center' component="th" scope="row">
                                {moment(row.stay_date).format("ddd, MMM DD, YYYY")}
                            </TableCell>
                            <TableCell component="th" scope="row" align='center' sx={{ color: "#000" }}>
                                {ui_state.currency_symbol} {row.current_rate}
                            </TableCell>
                            <TableCell component="th" scope="row" align='center' sx={{ fontWeight: "bold" }}>
                                {ui_state.currency_symbol} {row.override_rate}
                            </TableCell>
                            {/* <TableCell component="th" scope="row">
                                                <TextField
                                                    hiddenLabel
                                                    type="number"
                                                    size="small"
                                                />

                                            </TableCell> */}
                            <TableCell component="th" scope="row">
                                <Grid container direction="row" justifyContent="space-between" alignItems="center"
                                >

                                    <Grid item >
                                        {(!row.success_push
                                            && !row.loading && !row.fail) &&
                                            <Button onClick={() => handleRatePush(row)} className='icon-btn icon-status icon-ratepush'>
                                                <span className='upload-button'></span>
                                            </Button>
                                        }
                                        {(!row.fail && !row.loading && row.success_push === true) && (
                                            <Button className="icon-btn icon-status">
                                                <span className='in-progress'></span>
                                            </Button>
                                        )}
                                        {(!row.fail && !row.success_push && row.loading) && <Grid item xs={4}>

                                            <CircularProgress size={20} />

                                        </Grid>}
                                        {(!row.loading && !row.success_push && row.fail === true) &&
                                            (<Tooltip title={(row.message) ? row.message : ""} placement="bottom-start" arrow>
                                                <Button className='icon-btn icon-status icon-warning'>
                                                    <span className='error' ></span>
                                                </Button>
                                                {/* <div className="tooltip">{row.message }</div> */}
                                            </Tooltip>)}
                                    </Grid>
                                    <Grid item>
                                        <Button onClick={() => {
                                            handleDelete(index, row.id)
                                        }} className='icon-btn icon-status icon-cancel'>
                                            <span className='push-delete'></span>
                                        </Button>
                                    </Grid>
                                </Grid>



                                {/* <CloudUploadOutlinedIcon onClick={() => handleRatePush(index)} sx={{ color: "#9ed00d", cursor: "pointer" }} /> */}

                                {/* <CloseSharpIcon onClick={() => {
                                        //props.handleDelete(index, row.id)
                                    }} sx={{ ml: 2, color: "#ddd", cursor: "pointer" }} /> */}
                            </TableCell>
                        </TableRow>
                    ))
                    : (
                        <p>
                            No Items in Cart!
                        </p>
                    )
                }
            </TableBody>
        </Table>
    </TableContainer>)
    const roomTypePricingUpdates = (<div className='RTP-Pricing-Model '>
        {roomTypePricingData.map((date_data, index) => {
            let header = (<div>
                <div className='rtp-info-heading'>
                    <Grid container direction="row" alignItems="center"
                    >
                        <Grid className='rtp-heading' item xs={1}>
                            <Grid container direction="row" alignItems="center"
                            >
                                <Grid item>{moment(date_data.stay_date).format("ddd, MMM DD, YYYY")}</Grid>
                                <Grid item>
                                    {/* <Button onClick={() => handleRatePush(date_data)}
                                        className='icon-btn icon-status icon-ratepush'>
                                            <span className='upload-button'></span>
                                        </Button> */}
                                    {(!date_data.success_push
                                        && !date_data.loading && !date_data.fail) &&
                                        <Button onClick={() => handleRatePush(date_data)}
                                            className='icon-btn icon-status icon-ratepush'>
                                            <span className='upload-button'></span>
                                        </Button>
                                    }
                                    {(!date_data.fail && !date_data.loading && date_data.success_push === true) && (
                                        <Button className="icon-btn icon-status">
                                            <span className='in-progress'></span>
                                        </Button>
                                    )}
                                    {(!date_data.fail && !date_data.success_push && date_data.loading) && <Grid item xs={4}>

                                        <CircularProgress size={20} />

                                    </Grid>}
                                    {(!date_data.loading && !date_data.success_push && date_data.fail === true) &&
                                        (<Tooltip title={(date_data.message) ? date_data.message : ""} placement="bottom-start" arrow>
                                            <Button className='icon-btn icon-status icon-warning'>
                                                <span className='error'></span>
                                            </Button></Tooltip>)}
                                </Grid>
                                {/* <Grid item >

                                        <CircularProgress size={20} />

                                    </Grid> */}
                                {/* <Grid item >
                                        <Button>
                                            <span className='in-progress'></span>
                                        </Button>
                                    </Grid> */}
                                {/* <Grid item >
                                        <Button className='icon-btn icon-status icon-warning'>
                                            <span className='error'></span>
                                        </Button>
                                    </Grid> */}
                                <Grid item>
                                    <Button className='icon-btn icon-status icon-cancel'
                                        onClick={() => {
                                            delete roomtype_pricing_overrides[date_data.stay_date]
                                            roomTypePricingData.splice(index, 1)
                                            setRoomTypePricingData(roomTypePricingData)
                                            dispatch(updateAppState("change_pricing_rates", {
                                                app_state: {
                                                    roomtype_pricing_overrides: { ...roomtype_pricing_overrides }
                                                }
                                            }))
                                        }}>
                                        <span classNamde='push-delete'>x</span>
                                    </Button>
                                </Grid>
                            </Grid>
                        </Grid>

                    </Grid>

                </div></div>);

            let updates = [];
            date_data.date_updates.map((room_update, update_index) => {
                if (room_update.SellRate) {
                    updates.push(<Grid item className='margin-bottom' xs={3} md={2}>

                        <Box>
                            <Stack direction="row" spacing={1}>
                                <Chip className='room-property'
                                    avatar={<div className='avtar-size'>{room_update.room_code}</div>}
                                    label={<div className='property-value'><span>
                                        ${room_update.previous_rate}</span>
                                        ${room_update.SellRate}
                                    </div>}

                                    onDelete={() => {
                                        delete roomtype_pricing_overrides[date_data.stay_date][room_update.room_code];
                                        roomTypePricingData[index].date_updates.splice(update_index, 1)
                                        setRoomTypePricingData(roomTypePricingData)
                                        dispatch(updateAppState("change_pricing_rates", {
                                            app_state: {
                                                roomtype_pricing_overrides: { ...roomtype_pricing_overrides }
                                            }
                                        }))
                                    }}
                                    deleteIcon={<span className='delete-button'>x</span>}
                                />
                            </Stack>
                        </Box>
                    </Grid>)
                }

            })
            return (<Box sx={{ flexGrow: 1 }} key={index}>{header}<Grid container spacing={1}>{updates}</Grid></Box>);
        })}
    </div>)
    return (<>
        <Modal
            className="popup-modal-wrap"
            open={isModelOpen}
            onClose={() => {
                Object.keys(app_state.pricing_override_rates).forEach(stay_date => {
                    if (app_state.pricing_override_rates[stay_date].success_push) {
                        delete app_state.pricing_override_rates[stay_date];
                    }
                })
                Object.keys(app_state.roomtype_pricing_overrides).forEach(stay_date => {
                    if (app_state.roomtype_pricing_overrides[stay_date].success_push) {
                        delete app_state.roomtype_pricing_overrides[stay_date];
                    }
                })
                dispatch(updateAppState("sendPricingRateClose", {
                    app_state: {
                        pricing_override_rates: app_state.pricing_override_rates,
                        roomtype_pricing_overrides: { ...app_state.roomtype_pricing_overrides }
                    },
                    ui_state: { pricingRateModalOpen: false }
                }));
                setOpen(false);
            }}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
        >
            <Box className='popup-modal modal-pricing'>



                <Box className='modal-titlebar'>
                    <Grid container spacing={0} className='PricingModal'>
                        <Grid item xs={7}>
                            <item><span className='text-green'>Pricing -</span> Review and update</item>
                        </Grid>
                        <Grid className='right-cta' xs={5} item alignItems="end">
                            <Badge anchorOrigin={{ vertical: 'top', horizontal: 'right', }} component="div" badgeContent={rows.numUpdates} color="primary" className='btn-ratepush' sx={{ mt: 0, mr: 1, position: 'relative' }}>
                                <Button className='PushRateButton-popup' size="small" sx={{ m: "auto" }} onClick={() => handleRatePush()}>
                                    <span>confirm all changes</span>
                                </Button>

                            </Badge>
                            <IconButton aria-label="delete" onClick={() => {
                                let name = "";
                                if (isRoomTypePricing) {
                                    name = "RoomPricingRates"
                                    generateRoomTypeExcel(name, property_name, app_state.roomtype_pricing_overrides)
                                } else {
                                    name = "PricingRates"
                                    downloadReportToExcel(name, property_name, "pricingRatesTable")
                                }

                            }}>
                                <FileDownloadOutlinedIcon className='Rate-Push-DownloadButton '

                                    fontSize='large' />
                            </IconButton>

                            <Button sx={{
                                minWidth: 'auto', position: 'absolute', top: -12, right: -12, width: '24px', height: '24px', p: '7px',
                                borderRadius: '50%', borderWidth: '1px', borderStyle: 'solid',
                                borderColor: '#ccc', boxShadow: '0 0 4px #000', background: '#000', color: '#FFF', fontSize: '10px',
                                '&:hover': { backgroundColor: '#000' }
                            }} onClick={() => {

                                Object.keys(app_state.pricing_override_rates).forEach(stay_date => {
                                    if (app_state.pricing_override_rates[stay_date].success_push) {
                                        delete app_state.pricing_override_rates[stay_date];
                                    }
                                })
                                Object.keys(app_state.roomtype_pricing_overrides).forEach(stay_date => {
                                    if (app_state.roomtype_pricing_overrides[stay_date].success_push) {
                                        delete app_state.roomtype_pricing_overrides[stay_date];
                                    }
                                })
                                dispatch(updateAppState("sendPricingRateClose", {
                                    app_state: {
                                        pricing_override_rates: app_state.pricing_override_rates,
                                        roomtype_pricing_overrides: { ...app_state.roomtype_pricing_overrides }
                                    },
                                    ui_state: { pricingRateModalOpen: false }
                                }));
                                setOpen(false)
                            }}>
                                <CloseSharpIcon fontSize='small' />
                            </Button>



                        </Grid>
                    </Grid>
                </Box>


                <Box sx={{ height: '100%' }} id="pricingRatesTable">

                    {/* rate listing white section */}

                    {(numUpdates > 0 && isRoomTypePricing === false) && housePricingTable}
                    {(numUpdates > 0 && isRoomTypePricing === true) && roomTypePricingUpdates}
                    {numUpdates < 1 && (
                        <p>
                            No Items in the Cart!
                        </p>
                    )}
                    {/* <Stack sx={{ width: '100%' }} spacing={2}>

                        <Alert severity="warning" sx={{ color:"#C02E2E" }}>Something Went Wrong, Please Try Again</Alert>
                    </Stack> */}
                    {/* <Stack sx={{ width: '100%' }} spacing={2}>

                        <Alert severity="success" sx={{ color:"#56874C" }}>Success! </Alert>
                    </Stack> */}


                    {ratePushSucessOpen && ratePushSucessDiv}

                    {ratePushFail && (<Stack sx={{ width: '100%' }} spacing={2}>
                        <Alert severity="warning" sx={{ color: "#C02E2E" }}>Something Went Wrong, Please Try Again</Alert>
                    </Stack>)}
                </Box>



            </Box>
        </Modal>
    </>);
}
