import { Button, Box, Modal, FormControl, Select, Typography, Container, Grid } from '@mui/material'
import { border } from '@mui/system';
import React, { Fragment } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { updateAppState } from '../../../../actions/appstate';
import CloseIcon from '@mui/icons-material/Close';
import Stack from '@mui/material/Stack';
import dataService, { fetchDataSimple } from '../../../../services/data.service';
import { API_BASE_URL, API_REPORT_URL } from "../../../../app/config";
import jsonData from './market.json';
import Chip from '@mui/material/Chip';

import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import { replaceMultiText, deepCopy } from '../../../../app/util';
import { LinearProgress } from '@mui/material';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import moment from "moment";

import Skeleton from '@mui/material/Skeleton';

const InfoButtonMarketSellRate = (props) => {
    const controls = useSelector(state => state.app.current_state.app_state.workspace_controls);
    const { app_state } = useSelector(
        (state) => state.app.current_state
    );
    const dispatch = useDispatch();
    const [jsonData, setJsonData] = React.useState(null);

    const handleClick = (type, value) => {
        console.log(props.star);

        setOpen(true);
        const updateTypes = [type];
        controls.infoButton = value;
        dispatch(updateAppState(updateTypes, { app_state: { workspace_controls: controls } }));
        let censusData = deepCopy(dataService.getSeriesById("MarketSellRate").data);
        if (censusData) {
            let idx = props.star.charAt(0);
            setJsonData(censusData['census'][idx]);
            debugger;
        }
    }
    const [open, setOpen] = React.useState(false);
    const handleClose = () => setOpen(false);
    let capacity = (jsonData && jsonData.data) ? "CAPACITY : " + jsonData.data.capacity : 0;
    let available = (jsonData && jsonData.data) ? "AVAILABLE : " + jsonData.data.approved_capacity : 0;
    let lastUpdate = (jsonData && jsonData.meta) ? moment(jsonData.meta.date).format('MMM DD YYYY,  ddd') : '';

    return (
        <React.Fragment>
            <Box alignItems="center" justifyContent="center" sx={{ width: 1, height: 1 / 2 }}>
                <InfoOutlinedIcon fontSize="small"
                    sx={{ fontSize: '18px', color: "#ccc", cursor: "pointer", display:"none" }}
                    onClick={(e) => handleClick("workspace_update", !controls.infoButton)} />
            </Box>

            <Modal
                open={open}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <Box sx={{
                    position: 'absolute',
                    top: '40%',
                    left: '50%',
                    transform: 'translate(-50%, -50%)',
                    width: '650px', height: '400',
                    bgcolor: '#333',
                    boxShadow: 24, borderRadius: '5px',
                    border: '1px solid #333'
                }}>
                    <Box sx={{ background: "#333333", color: "#dfdfdf", position: "relative" }}>
                        <Typography id="modal-modal-title" variant="h6" component="h2" sx={{ ml: 1.5, mr: .5, mt: 1, mb: 1, fontSize: "15px" }}>
                            <span className='text-green'>MARKET -</span> INFO
                            <CloseIcon onClick={handleClose} sx={{ float: "right", cursor: "pointer" }} />
                        </Typography>
                    </Box>

                    <Paper sx={{ width: '100%', overflow: 'hidden', background: "#454545" }}>
                        <Grid container>
                            <Grid xs={12} sx={{ background: "#454545", textAlign: "center", borderRight: "0px solid #ddd" }}>
                                <Chip variant="outlined" size="small" color="success" label={props.star} sx={{ color: "#dfdfdf", m:.5 }} />
                            </Grid>
                        </Grid>
                        <TableContainer component={Paper} sx={{ maxHeight: 350, borderRadius: 0, borderTopLeftRadius: "0px !important;" }}>
                            <Table stickyHeader>
                                {(jsonData === null &&
                                    <>
                                        <TableHead>
                                            <TableRow sx={{ background: "#6b6b6a" }}>
                                                <TableCell ><Skeleton /></TableCell>
                                            </TableRow>
                                            <TableRow sx={{ background: "#6b6b6a" }}>
                                                <TableCell ><Skeleton /></TableCell>
                                            </TableRow>
                                            <TableRow sx={{ background: "#6b6b6a" }}>
                                                <TableCell ><Skeleton /></TableCell>
                                            </TableRow>
                                            <TableRow sx={{ background: "#6b6b6a" }}>
                                                <TableCell ><Skeleton /></TableCell>
                                            </TableRow>
                                            <TableRow sx={{ background: "#6b6b6a" }}>
                                                <TableCell ><Skeleton /></TableCell>
                                            </TableRow>
                                            <TableRow sx={{ background: "#6b6b6a" }}>
                                                <TableCell ><Skeleton /></TableCell>
                                            </TableRow>
                                        </TableHead>
                                        <Skeleton />

                                    </>
                                )}
                                {(jsonData !== null &&
                                    <>
                                        <TableHead>
                                            <TableRow sx={{ background: "#6b6b6a" }}>
                                                <TableCell align='left' sx={{ pt: "6px", pb: "6px", color: "#fff", borderRight: "1px solid #ddd", zIndex: 99999, background: "#6b6b6a" }}>PROPERTY NAME</TableCell>
                                            </TableRow>
                                        </TableHead>
                                    </>
                                )}
                                <>
                                    <TableBody>
                                        {(jsonData !== null && jsonData.map((item, index) => (
                                            <TableRow
                                                key={index}
                                                sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                            >

                                                <TableCell align="left" sx={{ pt: "8px", pb: "8px", borderRight: "1px solid #ddd", textTransform: "capitalize" }}>{item}</TableCell>
                                            </TableRow>
                                        ))
                                        )}
                                    </TableBody>
                                </>
                                {/* )} */}
                            </Table>
                        </TableContainer>
                    </Paper>
                </Box>
            </Modal>
        </React.Fragment>
    )
}

export default InfoButtonMarketSellRate