import { ErrorBoundary } from 'react-error-boundary';
import { ErrorHandler } from "../../app/ErrorHandler";
import { Container, Grid, Modal } from "@mui/material";
import { ThemeProvider, useTheme } from "@mui/material/styles";
import { Box } from "@mui/system";
import React, { useEffect, useState } from "react";
import moment from 'moment';
import useMediaQuery from "@mui/material/useMediaQuery";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";

import { updateAppState } from "../../actions/appstate";
import { getTilesInOrder, getTileById, deepCopy, abortRequestSafe, calculateCompStayRange, getPermissions } from "../../app/util";
import theme from "../../theme";
import Header from "../Header";
import { DashboardFooter } from "../layout/dashboard-ui";

import { TILES, TILE_GROUP_ONE, TILE_GROUP_TWO, api_status } from "../../app/config";
import { UPDATE_STATE } from "../../actions/types";
import PriceCalendar from "../PriceCalendar";
import Tile from "../Tile/";
import Workspace from "../Workspace";
import DashboardSidebar from "./DashboardSidebar";
import { CompressOutlined } from "@mui/icons-material";
import DailyFlash from "../DailyFlash";
import GroupPricing from "../GroupPricing";
import { determineMeasureStates } from "../../components/Measure/measure-logic";

/**
 * Be careful using this hook. It only works because the number of
 * breakpoints in theme is static. It will break once you change the number of
 * breakpoints. See https://reactjs.org/docs/hooks-rules.html#only-call-hooks-at-the-top-level
 */
function useWidth() {
    const theme = useTheme();
    const keys = [...theme.breakpoints.keys].reverse();
    return (
        keys.reduce((output, key) => {
            // eslint-disable-next-line react-hooks/rules-of-hooks
            const matches = useMediaQuery(theme.breakpoints.up(key));
            return !output && matches ? key : output;
        }, null) || "xs"
    );
}
function ShowWidth() {
    const width = useWidth();
    const matches = useMediaQuery("(min-width:600px)");
    return "";
    return (
        <Box
            sx={{
                position: "absolute",
                zIndex: "9999",
                backgroundColor: "red",
                color: "#FFF",
                p: 1,
            }}
        >
            {width}
        </Box>
    );
}

export default function DashboardLayout() {
    const dispatch = useDispatch();
    const [displayData, setDisplayData] = useState([]);
    const [openPriceCalendar, setOpenPriceCalendar] = React.useState(false);
    const [openGroupPricing, setOpenGroupPricing] = React.useState(false);
    const [mergeCompatibilty, setMergeCompatibilty] = React.useState({});
    const navigate = useNavigate();
    const state = useSelector(state => state);
    const { auth, app } = state;
    const { profile } = auth;
    const { app_state, ui_state } = useSelector(
        (state) => state.app.current_state
    );
    const { controlSupport } = app_state;
    const { scopeType, segmented_view } = app_state.workspace_controls;
    const { active_tiles } = ui_state;
    const tileDetails = getTileById(active_tiles[0]);
    let { defaultMeasures } = tileDetails.chartConfig;
    defaultMeasures = defaultMeasures ? defaultMeasures : ['Occ', 'ADR'];
    const tilesGroupOne = getTilesInOrder(TILES, TILE_GROUP_ONE);
    const tilesGroupTwo = getTilesInOrder(TILES, TILE_GROUP_TWO);
    const [activeTile, setActiveTile] = useState(active_tiles[0]);
    const [openDailyFlash, setOpenDailyFlash] = React.useState(false);

    let controlSupportTileMap = {
        segmented_view: ['otb', 'pickup', 'pace', 'forecast'],
        scopeType: {
            dateView: [
                'otb', 'pickup', 'pace', 'compset', 'market',
                'market_sell_rates', 'forecast',
            ],
            dbaView: ['pace', 'compset', 'market']
        },
    };

    useEffect(() => {
        let isSubscribed = true;
        let compatibility = {};
        tilesGroupOne.map((tile, index) => {
            compatibility[tile.id] = tile.mergeable ? true : false;
        });
        tilesGroupTwo.map((tile, index) => {
            compatibility[tile.id] = tile.mergeable ? true : false;
        });
        setMergeCompatibilty((state) => {
            return { ...state, ...compatibility };
        });
        tileMergeLogic(ui_state.active_tiles);
        return () => (isSubscribed = false);
    }, []);

    useEffect(() => {
        let isSubscribed = true;
        tileMergeLogic(ui_state.active_tiles);
        return () => (isSubscribed = false);
    }, [scopeType, segmented_view, ui_state.active_tiles]);

    useEffect(() => {
        let isSubscribed = true
        // debugger;
        if (auth.profile.justLoggedIn) {
            if (auth.profile.flash_report) {
                setOpenDailyFlash(true);
            }
            dispatch({ type: "PROFILE_SUCCESS", payload: { ...auth.profile, justLoggedIn: false } });

        }
        if (!auth.profile.justLoggedIn && ui_state.show_flash_report) {
            setOpenDailyFlash(true);
        }

        /** for Group pricing popup */
        if (ui_state.show_group_pricing) {
            setOpenGroupPricing(true);
            dispatch(
                updateAppState("change_group_pricing", {
                    ui_state: {
                        show_group_pricing: false,
                    },
                })
            );
        }
        if (ui_state.show_price_calendar) {
            setOpenPriceCalendar(true);
            dispatch(
                updateAppState("change_group_pricing", {
                    ui_state: {
                        show_price_calendar: false,
                    },
                })
            );
        }
        if (!app_state.data_load) {
            dispatch({
                type: UPDATE_STATE,
                payload: {
                    app_state: {
                        data_load: true,
                        kpi_load: true,
                        // data_status: { ...data_status },
                        // active_apis: active_apis,
                    },
                    ui_state: {
                        showspinner: true
                    },
                }
            });
        }
        return () => (isSubscribed = false);
    }, [ui_state.show_flash_report, ui_state.show_group_pricing, ui_state.show_price_calendar]);


    const handlePriceCalendarClose = () => setOpenPriceCalendar(false);
    const handleDailyFlashClose = () => {
        setOpenDailyFlash(false);
        dispatch(updateAppState("change_flash_report", {
            app_state: {
                ...app_state,
                data_load: true, 
            },
            ui_state: {
                ...ui_state,
                show_flash_report: false,
            },
        }));

    }
    const handleGroupPricingClose = () => setOpenGroupPricing(false);
    let historicalRange = null;

    const handleTileClick = (event) => {
        // debugger;
        const workspace_controls = {};
        let id = event.currentTarget.id;
        const previousTiles = ui_state.active_tiles;
        let currTile = TILES[id];
        let { defaultMeasures } = currTile.chartConfig;
        let date_range_type = "tileChanged";
        defaultMeasures = defaultMeasures ? defaultMeasures : ['Occ', 'ADR'];

        //Aborting active apis
        app_state.active_apis.map((api_id, index) => {
            // debugger;
            if (!['OSR', 'OSRContrainedForecast'].includes(api_id)) {
                abortRequestSafe(api_id, true);
            }
        });

        if (currTile.controlSupport) {
            for (let key in app_state.workspace_controls) {
                if (!currTile.controlSupport[key]) {
                    if (key === 'sdlyOn') {
                        workspace_controls[key] = false;
                        app_state.workspace_controls[key] = false;
                    }
                    if (key === 'segmented_view') {
                        workspace_controls[key] = false;
                        app_state.workspace_controls[key] = false;
                    }
                    if (key === 'scopeType' && app_state.workspace_controls[key] === 'Dba') {
                        workspace_controls[key] = 'Date';
                        app_state.workspace_controls[key] = 'Date';

                        app_state.primary_stay_range = { ...app_state.primary_stay_range, ...app_state.historicalRange };

                        date_range_type = 'scopeTypeChanged_Date';
                        app_state.date_range_type = 'scopeTypeChanged_Date';
                    }
                } else {
                    if (app_state.workspace_controls[key]) {
                        workspace_controls[key] = app_state.workspace_controls[key];
                        // date_range_type = 'titeChanged';
                    }
                }
            }
        }
        // debugger;
        if (currTile.view === "popup") {
            if (id === "price_calendar") {
                setOpenPriceCalendar(true);
            }
            if (id === "daily_flash") {
                setOpenDailyFlash(true);
            }
            if (id === "group") {
                setOpenGroupPricing(true);
            }
        } else {
            if (ui_state.active_tiles.length === 1 && ui_state.active_tiles[0] !== id) {
                // debugger;
                setDisplayData([]);
                setActiveTile(id);
                const active_tiles = [id];
                // const active_apis = [];
                // const active_api = TILES[id].data_sources[0];
                // const active_dataId = active_api;
                //ui_state.active_tiles.splice(0, -1);
                //ui_state.active_tiles.push(id);

                //If default view is configured in tile configuration then use that view otherwise
                // use the default view coming in profile settings

                let new_ui_state = { ...ui_state, active_tiles };
                ui_state.active_tiles = active_tiles;
                if (currTile.defaultView) {
                    new_ui_state.workspace_view = currTile.defaultView;
                    ui_state.workspace_view = currTile.defaultView;
                } else {
                    new_ui_state.workspace_view = auth.profile.default_view.toLowerCase();
                    ui_state.workspace_view = auth.profile.default_view.toLowerCase();

                    if (app_state.user_view) {
                        new_ui_state.workspace_view = app_state.user_view.toLowerCase();
                        ui_state.workspace_view = app_state.user_view.toLowerCase();
                    }
                }
                ui_state.left_measure = defaultMeasures[0];
                ui_state.right_measure = defaultMeasures[1];


                if (id === 'pace') {
                    let comp_as_of_date = new moment(app_state.primary_stay_range.as_of_date).subtract(1, 'year').format('YYYY-MM-DD');
                    let comp_date_from = new moment(app_state.primary_stay_range.date_from).subtract(1, 'year').format('YYYY-MM-DD');
                    let comp_date_until = new moment(app_state.primary_stay_range.date_from).subtract(1, 'year').format('YYYY-MM-DD');
                    let comp_stay_range = { date_from: comp_date_from, date_until: comp_date_until, as_of_date: comp_as_of_date }
                    // app_state.comp_stay_range = deepCopy(comp_stay_range);
                    app_state.comp_stay_range = calculateCompStayRange({ primary_stay_range: app_state.primary_stay_range });;


                    if (app_state.workspace_controls.scopeType !== "Dba") {
                        //First set the historical range
                        historicalRange = deepCopy(app_state.primary_stay_range);
                        app_state.historicalRange = deepCopy(app_state.primary_stay_range);

                        workspace_controls.scopeType = "Dba";
                        app_state.workspace_controls.scopeType = "Dba";

                        date_range_type = 'scopeTypeChanged_Dba';
                        app_state.date_range_type = 'scopeTypeChanged_Dba';

                        app_state.primary_stay_range.date_until = app_state.primary_stay_range.date_from;
                    } else {
                        app_state.primary_stay_range = { ...app_state.primary_stay_range, ...deepCopy(app_state.historicalRange) };
                        date_range_type = 'tileChanged';
                        app_state.date_range_type = 'rangeTileChange_pace';
                    }
                    // console.log("curr app_state", app_state);
                    // debugger;
                    ui_state.showspinner = true;
                    dispatch(
                        updateAppState("change_active_tiles", {
                            app_state: {
                                ...app_state,
                                //date_range_type: date_range_type,
                                data_load: true,
                                //kpi_load: true,
                                //historicalRange,
                                controlSupport,
                                //primary_stay_range: { ...app_state.primary_stay_range, date_until: app_state.primary_stay_range.date_from },
                                //comp_stay_range: { ...comp_stay_range },
                                //workspace_controls: { ...app_state.workspace_controls, ...workspace_controls },
                            },
                            ui_state: {
                                ...ui_state,
                                // ...new_ui_state,
                                // showspinner: true
                            },
                        }, "dashboard_click")
                    );
                    // debugger;                    
                } else {
                    //Handle in case of scope type date
                    //historicalRange = deepCopy(app_state.primary_stay_range);
                    if (id === "hotel_summary") {
                        new_ui_state.active_tiles[0] = app_state.workspace_controls.summaryType;
                    }
                    
                    date_range_type = 'rangeTileChange_'+ui_state.active_tiles.join('');

                    let primaryStayRange = deepCopy(app_state.primary_stay_range);

                    if (app_state.workspace_controls.scopeType === "Date" && id !== "pricing") {
                        if (app_state.workspace_controls.isRoomTypePricing) {
                            app_state.workspace_controls.isRoomTypePricing = false;
                        }

                        // comment this below line because we are geeting a error (primary_stay_range was getting null due to this line )
                        // app_state.primary_stay_range = deepCopy(app_state.historicalRange);

                        primaryStayRange = deepCopy(app_state.primary_stay_range);
                        historicalRange = deepCopy(app_state.primary_stay_range);
                        app_state.historicalRange = deepCopy(historicalRange);
                    }
                    if (app_state.workspace_controls.scopeType === "Dba") {
                        primaryStayRange = deepCopy(app_state.primary_stay_range);
                        historicalRange = {};
                    }

                    // app_state.primary_stay_range = {...app_state.primary_stay_range, ...historicalRange};
                    // app_state.data_load = true;
                    // app_state.kpi_load = true;
                    // debugger;
                    dispatch(
                        updateAppState("change_active_tiles", {
                            app_state: {
                                ...app_state,
                                date_range_type: date_range_type,
                                controlSupport,
                                // data_load: true,
                                // kpi_load: true,
                                //historicalRange:{...app_state.historicalRange},
                                // primary_stay_range: { ...app_state.primary_stay_range, ...historicalRange },
                                workspace_controls: { ...app_state.workspace_controls, ...workspace_controls }
                            },
                            //app_state: {active_dataId, active_api, active_apis, data_load: true },
                            ui_state: {
                                ...new_ui_state,
                                showspinner: true
                            },
                        }, "dashboard_click")
                    );
                    if (app_state.workspace_controls.scopeType !== "Dba") {
                        //historicalRange = deepCopy(app_state.primary_stay_range);
                    }

                }

                // console.log("ID => ",id);
                // if (id === 'flights' || id === "compset") {
                //     dispatch(
                //         updateAppState("change_active_tiles", {
                //             app_state: {
                //                 ...app_state,
                //                 data_load: true,
                //                 kpi_load: true,
                //             },
                //             ui_state: {
                //                 showspinner: true
                //             },
                //         }, "dashboard_click")
                //     );
                // }


                tileMergeLogic([id]);

            } else if (ui_state.active_tiles.length > 1) {
                //Handling intellimerge conditions
                let thisIndex = ui_state.active_tiles.indexOf(id);
                let activeTiles = ui_state.active_tiles;
                // debugger;
                if (thisIndex !== -1) {
                    activeTiles.splice(thisIndex, 1);
                    
                    dispatch(
                        updateAppState("change_active_tiles", {
                            app_state: {
                                ...app_state,
                                date_range_type: 'rangeTileChange_'+activeTiles.join(''),
                                controlSupport,
                                workspace_controls: { ...app_state.workspace_controls, ...workspace_controls },
                                // data_load: true,
                            },
                            //app_state: {active_dataId, active_api, active_apis, data_load: true },
                            ui_state: {
                                ...ui_state,
                                active_tiles: activeTiles,
                                // workspace_view,
                                showspinner: true
                            },
                        }, "dashboard_click")
                    );
                } else {
                    activeTiles.splice(0);
                    activeTiles.push(id);
                    
                    if (currTile.defaultView) {
                        ui_state.workspace_view = currTile.defaultView;
                    }

                    if (id === 'pace' && workspace_controls.scopeType!=='Dba') {
                        workspace_controls.scopeType = workspace_controls.scopeType!=='Dba' ? 'Dba' : workspace_controls.scopeType;
                        app_state.date_range_type = "scopeTypeChanged_Dba";
                        // debugger;
                        app_state.historicalRange = deepCopy(app_state.primary_stay_range);
                        app_state.primary_stay_range.date_until = app_state.primary_stay_range.date_from;
                        app_state.comp_stay_range.date_until = app_state.comp_stay_range.date_from;
                    } else if(id !== 'pace') {
                        // app_state.date_range_type = "scopeTypeChanged_Date";
                        app_state.date_range_type = "rangeTileChange_"+id;
                    }
                    dispatch(
                        updateAppState("change_active_tiles", {
                            app_state: {
                                ...app_state,
                                // date_range_type: 'singleTileMode',
                                controlSupport,
                                // historicalRange,
                                workspace_controls: { ...app_state.workspace_controls, ...workspace_controls },
                                // data_load: true,
                                primary_stay_range: { ...app_state.primary_stay_range },
                                comp_stay_range: { ...app_state.comp_stay_range }
                            },
                            //app_state: {active_dataId, active_api, active_apis, data_load: true },
                            ui_state: {
                                ...ui_state,
                                active_tiles: activeTiles,
                                // workspace_view,
                                showspinner: true
                            },
                        }, "dashboard_click")
                    );
                }
                // tileMergeLogic([id]);
                //making control options available on the basis of tiles                
            }
        }
    };

    const [dashboardMode, setDashboadMode] = useState("expanded_view");
    const renderExpandedView = () => {
        return (
            <div>
                <DashboardSidebar onClose={() => { }} open={true} />
            </div>
        );
    };

    const onTileMergeHandler = (tile) => {
        // debugger;
        const newTile = tile;
        let active_tiles = ui_state.active_tiles;
        let firstTile = TILES[active_tiles[0]];
        let comp_stay_range = {};
        //Do merging actions only if existing tile has mergeable true
        if (!active_tiles.includes(newTile.id) && firstTile.mergeable) {
            let id = newTile.id;
            let currTile = TILES[id];
            if (id === 'pace') {
                app_state.comp_stay_range = calculateCompStayRange({ primary_stay_range: app_state.primary_stay_range });
            }
            let { defaultMeasures } = currTile.chartConfig;

            const measuresState = determineMeasureStates({
                active_tiles: [id],
                userSelected_left_measure: "Occ",
                userSelected_right_measure: "ADR",
            });

            ui_state.left_measures_list = measuresState.leftList;
            ui_state.right_measures_list = measuresState.rightList;

            // debugger;
            if (!defaultMeasures) {
                // defaultMeasures = ['Occ', 'ADR'];
                ui_state.left_measure = 'Occ';
                ui_state.right_measure = ui_state.right_measure;
            } else if (defaultMeasures && !defaultMeasures[0] && !ui_state.left_measure) {
                ui_state.left_measure = 'Occ';
                ui_state.right_measure = defaultMeasures[1];
            } else if (defaultMeasures && defaultMeasures[0]) {
                ui_state.left_measure = defaultMeasures[0];
                ui_state.right_measure = defaultMeasures[1];
            }
            // if(['market_sell_rates', 'pricing'].includes(id) && ui_state.active_tiles.length > 1) {
            //     ui_state.left_measure = "Occ";
            //     ui_state.right_measure = "ADR";
            // } 
            // defaultMeasures = defaultMeasures ? defaultMeasures : ['Occ', 'ADR'];
            active_tiles.push(newTile.id);
            tileMergeLogic(active_tiles);

            // debugger;
            dispatch(
                updateAppState("change_active_tiles", {
                    app_state: { ...app_state, controlSupport },
                    ui_state: {
                        ...ui_state,
                        active_tiles: active_tiles,
                        showspinner: true
                    },
                })
            );
        } else {
            console.warn(firstTile.title, "is not mergeable");
        }
        // debugger;
    }

    const tileMergeLogic = (active_tiles = []) => {
        // const {scopeType, segmented_view} = app_state.workspace_controls;

        let activeTile = ui_state.active_tiles;
        let tileDetails = getTileById(activeTile);
        let { segmented_view, scopeType } = app_state.workspace_controls;

        // debugger;
        // let firstTile = active_tiles ? TILES[active_tiles[0]] : {};
        let compatibleTiles = [];
        let compatibility = {};
        let dateView = ['pace', 'otb', 'pickup', 'budget',
            'forecast', 'market', 'compset', 'market_sell_rates', 'restrictions'];
        let dateViewSegmented = ['pace', 'otb', 'pickup', 'forecast'];
        let segmentedView = ['pace', 'otb', 'pickup', 'forecast'];
        let dbaView = ['pace', 'market', 'compset'];
        const { permissions, index } = getPermissions(profile.property_details, app_state.property_id);
        // debugger;
        if (ui_state.active_tiles.length === 1) {
            activeTile = activeTile[0];
            if (['market'].includes(activeTile) && segmented_view) {
                segmentedView = [];
                dbaView = [];
            }

            if (['pace'].includes(activeTile) && segmented_view && scopeType === 'Date') {
                segmentedView = ['otb', 'pickup', 'pace', 'forecast'];
                dbaView = [];
                // dateView = ['pace', 'otb', 'pickup', 'budget', 'forecast'];
            }
            if (['pace'].includes(activeTile) && segmented_view && scopeType === 'Dba') {
                segmentedView = [];
                dbaView = [];
                // dateView = ['pace', 'otb', 'pickup', 'budget', 'forecast'];
            }
        }
        // debugger;
        compatibleTiles = dateView;
        if (segmented_view) {
            compatibleTiles = segmentedView;
            dateView.map((tile) => {
                if (!segmentedView.includes(tile)) {
                    compatibility[tile] = false;
                } else {
                    compatibility[tile] = true;
                }
            });
            if (scopeType === 'Date') {
                dateView.map((tile) => {
                    if (!segmentedView.includes(tile)) {
                        compatibility[tile] = false;
                    } else {
                        compatibility[tile] = true;
                    }

                });
            }
        } else {
            compatibleTiles = dateView;
            dateView.map((tile) => {
                compatibility[tile] = true;
            });
        }
        if (scopeType === 'Dba') {
            compatibleTiles = dbaView;
            dateView.map((tile) => {
                if (!dbaView.includes(tile)) {
                    compatibility[tile] = false;
                } else {
                    compatibility[tile] = true;
                }
            });
        }

        if (['budget'].includes(activeTile) && permissions.budget === 0) {
            dateView.map((tile) => {
                compatibility[tile] = false;
            });
        }

        if (['pricing', 'restrictions', 'hotel_summary', 'flights'].includes(activeTile) || (tileDetails && tileDetails.type && ['report', 'summary_report'].includes(tileDetails.type))) {
            dateView.map((tile) => {
                compatibility[tile] = false;
            });

            // // debugger;
            // setMergeCompatibilty((state) => {
            //     return { ...state, ...compatibility };
            // });
        } else {
            // dateView.map((tile) => {
            //     compatibility[tile] = true;
            // });
            // setMergeCompatibilty((state) => {
            //     return { ...state, ...compatibility };
            // });
        }

        setMergeCompatibilty((state) => {
            return { ...state, ...compatibility };
        });
    }

    return (
        <ErrorBoundary FallbackComponent={ErrorHandler}>
            <ThemeProvider theme={theme}>
                <Box sx={{ backgroundColor: "dark.lighter", minHeight: "100%" }}>
                    <Header />
                    <ShowWidth />
                    <Container
                        maxWidth="xxl"
                        className="container-control"
                        sx={{
                            pt: { xs: "65px", sm: "55px", md: "85px", lg: "55px" },
                            pl: 1,
                            pr: 1,
                        }}
                    >
                        <Grid
                            sx={{ mb: { sm: 10, lg: 0 }, minHeight: { xs: "100%" } }}
                            container
                            direction="row"
                            justifyContent="flex-start"
                            alignItems="stretch"
                        >
                            <Grid item lg={11} xs={12} className="work-space-wrapper">
                                <Workspace id="workspace" onTileMerge={onTileMergeHandler} />
                            </Grid>
                            <Grid
                                sx={{ display: { xs: "none", lg: "flex" }, flexWrap: "wrap" }}
                                item
                                lg={1}
                                xs={6}
                            >
                                {tilesGroupOne.map((tile, index) => (

                                    <Grid
                                        item
                                        lg={12}
                                        xs={4}
                                        id={tile.id}
                                        key={index}
                                        onClick={handleTileClick}
                                        className={(() => {
                                            // debugger;
                                            if (tile.id === "hotel_summary") {
                                                return (active_tiles[0] === "hotel_summary" || active_tiles[0] === "market_summary") ? "active" : "";
                                            } else {

                                                return active_tiles.includes(tile.id) ? "active" : ""
                                            }
                                        })()}
                                    >
                                        <Tile tile={tile} activeTiles={active_tiles} activeTile={activeTile} compatibility={mergeCompatibilty[tile.id]} />
                                    </Grid>
                                ))}
                                <Modal
                                    open={openPriceCalendar}
                                    onClose={handlePriceCalendarClose}
                                    aria-labelledby="modal-modal-title"
                                    aria-describedby="modal-modal-description"
                                >
                                    <PriceCalendar handleClose={handlePriceCalendarClose} />
                                </Modal>

                                {/* Daily Flash Popup  */}
                                <Modal
                                    open={openDailyFlash}
                                    onClose={handleDailyFlashClose}
                                    aria-labelledby="modal-modal-title"
                                    aria-describedby="modal-modal-description" className="modal-style">
                                    <DailyFlash handleClose={handleDailyFlashClose} />
                                </Modal>
                                {/* End Daily Flash Popup */}

                                <Modal
                                    open={openGroupPricing}
                                    onClose={handleGroupPricingClose}
                                    aria-labelledby="modal-modal-title"
                                    aria-describedby="modal-modal-description" className="modal-style">
                                    <GroupPricing handleClose={handleGroupPricingClose} />
                                </Modal>
                            </Grid>
                        </Grid>
                        <Grid
                            container
                            sx={{ pb: 4, display: { xs: "none", lg: "flex" } }}
                            direction="row"
                            justifyContent="flex-start"
                            alignItems="flex-start"
                        >
                            {tilesGroupTwo.map((tile, index) => (
                                <Grid
                                    item
                                    key={index}
                                    lg={!tile.usedSpace ? 2 : 1 * tile.usedSpace}
                                    xs={4}
                                    id={tile.id}
                                    onClick={handleTileClick}
                                    className={active_tiles.includes(tile.id) ? "active" : ""}
                                >
                                    {!active_tiles.includes('hotel_summary') && !active_tiles.includes('market_summary') && <Tile tile={tile} activeTiles={active_tiles} activeTile={activeTile} compatibility={mergeCompatibilty[tile.id]} />}
                                    {(active_tiles.includes('hotel_summary') || active_tiles.includes('market_summary')) && <Tile tile={tile} activeTiles={active_tiles} activeTile={activeTile} compatibility={mergeCompatibilty[tile.id]} />}

                                </Grid>
                            ))}
                        </Grid>
                    </Container>
                </Box>
                <DashboardFooter />
            </ThemeProvider>
        </ErrorBoundary>
    );
}
