import { Box, Button, Badge } from '@mui/material'
import CloudDownloadIcon from '@mui/icons-material/CloudDownload';
import CloudUploadOutlinedIcon from '@mui/icons-material/CloudUploadOutlined';
import { useDispatch, useSelector } from 'react-redux';
import { updateAppState } from '../../../../actions/appstate';
import React, { useState, useEffect } from "react";
import SendRestrictions from "../../../Modal/SendRestrictions"
import { getPricingDataFormatted } from '../../../DynamicTable/tilesData/pricing';

const UpdateRestrictionsButton = (props) => {
    const { app_state } = useSelector(state => state.app.current_state);
    const { roomTypeRestrictions } = app_state.workspace_controls;
    const [isOpen, setisOpen] = useState(false);
    const [compState, setcompState] = useState({ modalOpen: false, updateRestrictionCount: app_state.savedRestrictions.length });
    const dispatch = useDispatch();
    // debugger; 
    useEffect(() => {
        let isSubscribed = true;

        // setNumRatesChanged(ratesChanged);

        return () => (isSubscribed = false);
    }, [app_state.savedRestrictions.length])

    const handleClick = () => {
        setcompState(state => {
            // debugger
            return { ...state, modalOpen: true }
        });
        ;
        //dispatch(updateAppState("sendRestrictionsClick", { ui_state: { sendRestrictionsModalOpen: true } }));
    }

    const handleModalClose = () => {
        compState.modalOpen = false;
        setcompState(state => {
            // debugger
            return { ...state, ...compState }
        });
        ;
        //dispatch(updateAppState("sendRestrictionsClick", { ui_state: { sendRestrictionsModalOpen: true } }));
    }

    const { modalOpen, updateRestrictionCount } = compState;
    return (
        <>
            <Badge component="div" classes={{badge:'pulse restriction-badge'}} badgeContent={app_state.savedRestrictions.length} color="primary" className='btn-ratepush' sx={{ mt: 1.2, mr: 0, position: 'relative' }}>
                <Button className='PushRateButton' size="small" sx={{ m: "auto" }} onClick={handleClick} disabled={!app_state.savedRestrictions.length}>
                    <span>Update</span>
                </Button>
            </Badge>
            <SendRestrictions modalOpen={modalOpen} handleModalClose={handleModalClose} />
        </>
    )

}


export default UpdateRestrictionsButton