import * as React from 'react';
import Popover from '@mui/material/Popover';
import Typography from '@mui/material/Typography';
import Stack from '@mui/material/Stack';
import { getClassName } from './Restrictions';
import { CardContent, Chip, Container, Table, TableCell, TableContainer, TableHead, TableRow, TableBody } from '@mui/material';
import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import { InsertEmoticon } from '@mui/icons-material';


import Avatar from '@mui/material/Avatar';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import ListItemIcon from '@mui/material/ListItemIcon';
import Divider from '@mui/material/Divider';
import IconButton from '@mui/material/IconButton';
import Tooltip from '@mui/material/Tooltip';
import PersonAdd from '@mui/icons-material/PersonAdd';
import Settings from '@mui/icons-material/Settings';
import Logout from '@mui/icons-material/Logout';


export default function RestrictionsList(props) {
  let houseItems = props.chipArrAll;
  let roomTypeRestdata = []; //props.roomTypeRestdata;  we need to open this when we nee dto show Room type restrictions

  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <div className='restrictions-details'>
      <div
        aria-owns={open ? 'mouse-over-popover' : undefined}
        aria-haspopup="true"
        // onClick={handleClick}
        onMouseOver={handleClick}
        // onMouseLeave={handleClose}
        sx={{ lineHeight: 1, fontSize: "18px", mt: "-7px" }}
      >...
      </div>
      <Menu
        className='restrictions-details'
        anchorEl={anchorEl}
        id="account-menu"
        open={open}
        onClose={handleClose}
        onClick={handleClose}
        PaperProps={{
          elevation: 0,
          sx: {
            overflow: 'visible',
            filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
            mt: 1,
            '& .MuiAvatar-root': {
              width: 32,
              height: 32,
              ml: -0.5,
              mr: 1,
            },
            '&:before': {
              content: '""',
              display: 'block',
              position: 'absolute',
              top: -7,
              right: 8,
              width: 0,
              height: 0,
              borderLeft: "8px solid transparent",
              borderRight: "8px solid transparent",
              borderBottom: "8px solid black",
              // bgcolor: 'background.paper',
              // transform: 'translateY(-50%) rotate(45deg)',
              zIndex: 1,
            },
            '&:after': {
              content: '""',
              display: 'block',
              position: 'absolute',
              top: -6,
              right: 8,
              width: 0,
              height: 0,
              borderLeft: "8px solid transparent",
              borderRight: "8px solid transparent",
              borderBottom: "8px solid white",
              // bgcolor: 'background.paper',
              // transform: 'translateY(-50%) rotate(45deg)',
              zIndex: 1,
            },
          },
        }}
        transformOrigin={{ horizontal: 'right', vertical: 'top' }}
        anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
      >

        <Box className='restriction-box'>
          <Box className='restriction-box-title'>Restrictions</Box>
          <Box sx={{ m: .5, overflow: "auto", maxHeight: 170 }}>
            <TableContainer sx={{}}>
              <Table sx={{}}>
                <TableHead>
                  <TableRow className='restriction-table-row' sx={{ '& td, & th': { pt: .5, pb: .2, borderBottom: 1 } }}>
                    <TableCell className='table-heading' sx={{}}>Room Type	</TableCell>
                    <TableCell className='table-heading'>Room Code</TableCell>
                    <TableCell className='table-heading'>Restriction</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody sx={{ overflowX: "hidden", overflowY: "auto" }}>

                  {
                    (roomTypeRestdata && roomTypeRestdata.map((itm, k) => {
                      return <>
                        <TableRow
                          sx={{ '&:first-child td, &:first-child th': { border: 0, pt: 1 }, '&:last-child td, &:last-child th': { border: 0 }, '& td, & th': { border: 0, pt: .6, pb: 0, fontSize: 12, color: "#333333" } }}
                        >
                          <TableCell component="th" scope="row">
                            {itm.room_type_display}
                          </TableCell> <TableCell component="th" scope="row">
                            {itm.room_type}
                          </TableCell> <TableCell component="th" scope="row">
                            {itm.restrictions}
                          </TableCell>
                        </TableRow>

                      </>
                    }))

                  }

                  <TableRow
                    sx={{ '&:last-child td, &:last-child th': { border: 0 }, '& td, & th': { border: 0, pt: .6, pb: .8, fontSize: 12, color: "#333333" } }}
                  >
                    <TableCell component="th" scope="row">
                      House
                    </TableCell> <TableCell component="th" scope="row">
                      House
                    </TableCell> <TableCell component="th" scope="row">
                      {houseItems}
                    </TableCell>
                  </TableRow>




                </TableBody>
              </Table></TableContainer>
          </Box>
        </Box>

      </Menu>
    </div >
  );
}