import { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import moment from 'moment';
import { API_DI_CERT, API_BASE_URL } from "../app/config";
import { updateAppState } from "../actions/appstate";
import { getDataSources } from "../app/util";
import { deepCopy } from "@firebase/util";

function useDataSource(params) {
  const dispatch = useDispatch();
  const { app, auth } = useSelector((state) => state);
  const { profile } = auth;
  const { app_state, ui_state } = app.current_state;
  const { property_details } = profile;
  // const [dsInfo, setDsInfo] = useState(getDataSources(property_details, app_state.property_id));
  const [currentDataSource, setCurrentDataSource] = useState(getDataSources(property_details, app_state.property_id));

  // debugger;

  useEffect(() => {
    let isSubscribed = true;
    let newDataSrc = getDataSources(property_details, app_state.property_id);
    // debugger;
    setCurrentDataSource((state) => {
      return {
        ...state,
        ...newDataSrc
      };
    });
    // dispatch(updateAppState('dataSrcChanged', {
    //   app_state: {
    //     ...app_state,
    //     active_data_src: newDataSrc.active_source
    //   },
    //   ui_state: { ...ui_state }
    // }, "dataSrcUpdated"));

    // debugger;
    return () => (isSubscribed = false)
  }, [
    app_state.property_id,
    app_state.active_data_src.id
  ]);
  // debugger;
  return currentDataSource;
}
export {
  useDataSource,
};
