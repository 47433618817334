import moment from "moment";
import { convertToPercent, deepCopy, in_array, round, setUpDate } from "../../../app/util";
import { useDataSummary } from "../../Tile/kpiService";
import dataService from "../../../services/data.service";
import { useSelector } from "react-redux";

export function getFlightsData(data, columns, app_state) {
    console.log("Data => ",data);
    let dataKey = "HotelTotal";
    let setup_data = [];
    data = data.filter((itm) => moment(itm.date).format('YYYY-MM-01') >= moment().format('YYYY-MM-01'))
    data.map((item, index) => {

            let data_columns = [];
            columns.map(col => {
                let dataObj = { id: col.id };
                if (col.type === 'dateColumn') {
                    dataObj['month'] = moment(item.date).format('MMM, YYYY');
                }
                if (col.type === 'dataColumn') {
                    let { mainKey } = col;
                    col.subcolumns.map(key => {
                        // console.log(key.access_key," <= item ",item);
                        if(in_array([key.access_key],["arrival_variance_sdly","arrival_variance_sdlm","arrival_variance_sdlw","stayover_variance_sdlm","stayover_variance_sdlw","stayover_variance_sdly"])){
                            dataObj[key.value] = convertToPercent(item[key.access_key])
                        }else{
                            dataObj[key.value] = item[key.access_key]
                        }
                    })
                }

                data_columns.push(dataObj);
            })
            setup_data.push(data_columns);


    })
    // console.log(setup_data); 
    // debugger;
    return setup_data;
}

