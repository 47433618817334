import moment from "moment";
import { API_REGISTRY, api_status } from "../app/config";
import { deepCopy, getFromArr, getSeriesById, getTileById, getFromState, safeReference, getDataSources } from "../app/util";
import { determineMeasureStates } from "../components/Measure/measure-logic";
import DataService from "../services/data.service";
import {sendLog} from "../app/log-interface";
import {
    UPDATE_STATE
} from "./types";
import { time } from "highcharts";
import { TILES } from "../app/config";
export const buildSession =
    ({ user, profile }) =>
        (dispatch) => {
            // console.log(user, profile);
            const { property_details, default_property_config,application_config } = profile;
            const propertySettings = getFromArr(property_details, "id", profile.default_property_id);
            let newDataSrc = getDataSources(property_details, profile.default_property_id);
            // if(!propertySettings.system_settings && default_property_config){
            //     propertySettings.system_settings = default_property_config.system_settings;
            // }
            // debugger;

            //// / for local setting  
            let property_info = {};
            const prop_details = property_details.filter((item, index) => {
                if (item.id === profile.default_property_id) {
                    // prop_details_index = index;
                    property_info = { ...item };
                    return item.id === profile.default_property_id;
                }
            });
            const { system_settings } =
                prop_details && prop_details.length ? prop_details[0] : null;
            let default_locale_name = system_settings[0].default_locale ? system_settings[0].default_locale : "default";
            const localeSettings = {
                default_locale: system_settings[0].default_locale, //DEFAULT LOCALE
                locale_city: system_settings[0].locale_city, //CITY
                currency_symbol : application_config['locales'][default_locale_name]['currency'],
                currencyCode : application_config['locales'][default_locale_name]['currencyCode']
            };
            // debugger;
            ////

            let room_settings = safeReference(propertySettings.system_settings[0], [], ["inventory_settings", "current", "room_settings"])
            let defaultRoomCategory = room_settings[0];
            defaultRoomCategory = {room_class:"All"}
            let defaultRoomCodesList = [];
            room_settings.forEach(room => {
                // if (room.room_class === "Standard") {
                //     defaultRoomCategory = "Standard";
                //     defaultRoomCodesList.push(room.room_code)
                // }
                
                defaultRoomCodesList.push(room.room_code)
            })
            // debugger;
            if (defaultRoomCodesList.length < 1 && room_settings && room_settings.length) {
                defaultRoomCodesList = [room_settings[0].room_code]
            }
            let { compsetSettings, market_settings } =
                propertySettings.system_settings[0];
            if(!market_settings) market_settings = [];
            let selected_market = deepCopy(market_settings[0]);
            market_settings.map((market) => {
                if (market.is_default === true) {
                    selected_market = deepCopy(market);
                }
            });
            const measuresState = determineMeasureStates({
                active_tiles: ["otb"],
                userSelected_left_measure: "Occ",
                userSelected_right_measure: "ADR",
            });
            let as_of_date = moment().format("YYYY-MM-DD");
            if (
                window.location.hostname === "localhost" ||
                window.location.hostname === "127.0.0.1"
            ) {
                //as_of_date = moment(as_of_date).subtract(1, "days").format("YYYY-MM-DD");
            }
            let defaultDayOfWeek = moment(as_of_date).format("ddd").toLocaleLowerCase();
            const state = {
                app_state: {
                    timeoutID: null,
                    active_data_src: newDataSrc.active_source,
                    savedRestrictions: [],
                    updatedRestrictions: [],
                    controlSupport: {
                        segmented_view: true,
                        scopeType: true,
                    },
                    primary_stay_range: {
                        date_from: moment().startOf("month").format("YYYY-MM-DD"),
                        date_until: moment().endOf("month").format("YYYY-MM-DD"),
                    },
                    comp_stay_range: {
                        as_of_date: "", comp_as_of_date: "",
                        date_from: moment().startOf("month").format("YYYY-MM-DD"),
                        date_until: moment().endOf("month").format("YYYY-MM-DD"),
                    },
                    comparison_stay_range: null,
                    property_id: profile.default_property_id,
                    property_name:property_info.name,
                    comp_as_of_date: as_of_date,
                    as_of_date: as_of_date,
                    data_availability: propertySettings.data_availability,
                    data_load: true,
                    active_api: "OSR",
                    active_apis: ["OSR"],
                    pricing_override_rates: {},
                    roomtype_pricing_overrides: {},
                    room_settings,
                    compsetSettings,
                    selected_market,
                    market_settings,
                    kpi_load: true,
                    kpi_status: { 'OSR': '', 'OSRContrainedForecast': '' },
                    curr_loading: [],
                    data_status: {},
                    data_promise: [],
                    alertCounts : 0,
                    user_view: profile.default_view.toLowerCase(),
                    workspace_controls: {
                        isRoomTypePricing: false,
                        roomTypeRestrictions: false,
                        dbaStart: -1,
                        dba: 45,
                        sdlyOn: false,
                        categoryType: "All",
                        clearComparision: true,
                        compareTo: "",
                        scopeType: "Date",
                        logsRatePushMode: "date_range",
                        pickUpType: "Standard",
                        tableType: "monthly",
                        dayOfWeek: defaultDayOfWeek,
                        segmentType: "hotel",
                        airport_codes: [],
                        summaryType: "hotel_summary",
                        infoButton: false,
                        pickUpDays: 1,
                        gridView: false,
                        downloadButton: false,
                        downloadExportButton: false,
                        enableExportButton: false,
                        optionsClick: false,
                        sendRates: false,
                        expand_button: false,
                        marketSelect: "Market 1",
                        sdly2019On: false,
                        sdYear: moment(as_of_date).subtract(1,'years').format('YYYY'),
                        sdYearsArr : [],
                        rt_years : [],
                        rp_years : [],
                        rate_plan : 0,
                        room_type : 0,
                        exportReport: null,
                        segmented_view: false,
                        unconstrained:false
                    },
                },
                ui_state: {
                    pricingRateModalOpen: false,
                    expanded_view: false,
                    flash_showOnLogin:null,
                    table_view: false,// not using anymore
                    workspace_view: profile.default_view.toLowerCase(),
                    show_price_calendar:false,
                    show_chart_icon: true,
                    currency: localeSettings['currencyCode'],
                    currency_symbol: localeSettings['currency_symbol'],
                    tile_config: [],
                    active_tiles: ["otb"],
                    showspinner: true,
                    left_measure: "Occ",
                    right_measure: "ADR",
                    selectedRoomPricingCategory: [defaultRoomCategory],
                    selectedRoomPricingRoomCodes: defaultRoomCodesList,
                    right_measures_list: measuresState.rightList,
                    left_measures_list: measuresState.leftList,
                    header_alert_msg: "",
                    show_flash_report: false, justLoggedIn: true,
                    show_group_pricing: false,
                    updatedFilter:false,
                    showDownloadReport:true,
                    reports_dpbs:{selectedCategory:"Hotel",selectedSegment:""}
                },
            };
            sendLog({category:"login_page_click", action: "login"},{... state} ,{ ...state } )
            dispatch({ type: UPDATE_STATE, payload: deepCopy(state) });
        };
export const getMultipleSeries = () => {
    const promise1 = new Promise((resolve, reject) => {
        setTimeout(() => {
            const newValue = Math.floor(Math.random() * 20);
            resolve(newValue);
        }, 5000);
    });

    const promise2 = new Promise((resolve, reject) => {
        setTimeout(() => {
            const newValue = Math.floor(Math.random() * 20);
            resolve(newValue);
        }, 8000);
    });

    const promise3 = new Promise((resolve, reject) => {
        setTimeout(() => {
            const newValue = Math.floor(Math.random() * 20);
            resolve(newValue);
        }, 2000);
    });
};
export const getData =
    ({
        as_of_date,
        // comp_as_of_date,
        property_id,
        from_pid, to_pid,
        system_settings,
        primary_stay_range,
        comp_stay_range,
        active_api,
        active_apis,
        workspace_controls,
        selected_market,
        compsetSettings,
        without_cache,
        data_status,
        active_tiles,
        active_data_src,
        abort_signal
    }) =>
        async (dispatch) => {
            // debugger;
            let date_until = primary_stay_range.date_until;
            let date_from = primary_stay_range.date_from;
            let comp_date_from = comp_stay_range.date_from;
            let comp_date_until = comp_stay_range.date_until;
            let comp_as_of_date = comp_stay_range.as_of_date;
            // let comp_date_from = "2023-01-10"; let comp_date_until = "2023-01-20";
            // let comp_as_of_date = "2023-01-15";
            let data_source_id = active_data_src.id;
            let selectedAPI = DataService.getSeriesById(active_api);
            let varMaps = {
                pid: property_id
            };
            if (!selectedAPI) {
                selectedAPI = DataService.getSeriesById("OSR", null);
            }
            //selectedAPI.uri = setApiURLVars(selectedAPI.uri, {asof_date:as_of_date,date_from, date_until, pid:property_id, });
            const payload = {
                //abort_signal: abort_signal,
                api: selectedAPI.uri,
                request: {},
            };
            // debugger;
            if (selectedAPI.uri.indexOf("api.v1") > -1) {
                // debugger;
                payload.request = {
                    method: selectedAPI.payload.method,
                    params: {
                        ...selectedAPI.payload.params,
                        asof_date: as_of_date,
                    },
                    token_string: "",
                };
                if (selectedAPI.payload.params.hasOwnProperty("resource_id")) {
                    payload.request.params["resource_id"] = selected_market.market_id;
                }
                if (selectedAPI.payload.params.hasOwnProperty("marketid")) {
                    payload.request.params["marketid"] = selected_market.market_id;
                }
                if (selectedAPI.payload.params.hasOwnProperty("algo")) {
                    payload.request.params["algo"] = 'MFC_v3';
                }
                if (selectedAPI.payload.params.hasOwnProperty("date_to")) {
                    payload.request.params["date_to"] = primary_stay_range.date_until;
                }
                if (selectedAPI.payload.params.hasOwnProperty("date_from")) {
                    payload.request.params["date_from"] = primary_stay_range.date_from;
                }
                if (selectedAPI.payload.params.hasOwnProperty("date_until")) {
                    payload.request.params["date_until"] = primary_stay_range.date_until;
                }
                if (selectedAPI.payload.params.hasOwnProperty("pid")) {
                    payload.request.params["pid"] = property_id;
                }
            } else {
                let resourceId = "";
                let to_date = "";
                let from_date = "";
                let compId = "";
                let fromDate = ""; let toDate = "";
                // console.log(active_api);
                // debugger;
                if (active_api === "HotelSummary") {
                    date_until = moment(primary_stay_range.date_until).add(1, "year").format('YYYY-MM-DD');
                } else if (active_api === "CompsetBookingPace" || active_api === "Compset") {
                    resourceId = compsetSettings.resource_id;
                    compId = compsetSettings.compset_filter;
                    if (active_api === "CompsetBookingPace") {
                        date_until = primary_stay_range.date_from;
                    }
                } else if (active_api === "MarketSdlyDba" || active_api === "MarketDba" || active_api === "MarketARI" || active_api === "MarketARISegmented") {
                    resourceId = selected_market.market_source_name;
                    if (active_api === "MarketDba") {
                        date_until = primary_stay_range.date_from;
                        //resourceId = property_id.toString();
                    }
                } else if (active_api === "OSRYear" || active_api === "OSRContrainedForecastYear") {
                    // date_from = moment().format('YYYY-MM-DD');
                    date_from = primary_stay_range.date_from;
                    date_until = moment().add(1, "year").format('YYYY-MM-DD');
                    resourceId = property_id.toString();

                } else if (active_api === "OSRBookingPaceSdly" || active_api === "OSRBookingPaceSdlySegment") {
                    date_from = moment(primary_stay_range.date_from).subtract(1, "year").format('YYYY-MM-DD');
                    date_until = moment(primary_stay_range.date_until).subtract(1, "year").format('YYYY-MM-DD');
                    resourceId = property_id.toString();

                } else if (active_api === "OSRBookingPaceCompareTo" || active_api === "OSRBookingPaceCompareToSegment") {
                    if (workspace_controls.compareTo !== "" && workspace_controls.compareTo !== null) {
                        date_from = moment(workspace_controls.compareTo).format('YYYY-MM-DD');
                        date_until = moment(workspace_controls.compareTo).format('YYYY-MM-DD');
                    }
                    resourceId = property_id.toString();

                } else {
                    resourceId = property_id.toString();
                }

                // debugger;
                payload.request = {
                    ...selectedAPI.payload,
                    // resourceId,
                    // rangeFromDate: date_from,
                    // rangeUntilDate: date_until,
                };

                if (selectedAPI.payload.hasOwnProperty("resourceId")) {
                    payload.request["resourceId"] = resourceId;
                    // debugger;
                }
                if (selectedAPI.payload.hasOwnProperty("rangeFromDate")) {
                    payload.request["rangeFromDate"] = date_from;
                    // debugger;
                }

                if (selectedAPI.payload.hasOwnProperty("rangeUntilDate")) {
                    payload.request["rangeUntilDate"] = date_until;
                    // debugger;
                }

                if (selectedAPI.payload.hasOwnProperty("compId")) {
                    payload.request["compId"] = compId;
                    // debugger;
                }
                if (selectedAPI.payload.hasOwnProperty("as_of_date")) {
                    payload.request["as_of_date"] = as_of_date;
                }
                if (selectedAPI.payload.hasOwnProperty("market_id")) {
                    payload.request["market_id"] = selected_market.market_id;
                }
                if (selectedAPI.payload.hasOwnProperty("property_id")) {
                    payload.request["property_id"] = property_id;
                }
                // debugger;
                if (selectedAPI.payload.hasOwnProperty("from_date")) {
                    payload.request["from_date"] = moment(as_of_date).format("YYYY-MM-01");
                }
                if (selectedAPI.payload.hasOwnProperty("to_date")) {
                    // payload.request ["to_date"] = moment(as_of_date).add('11', 'M').format("YYYY-MM-") + moment().daysInMonth();
                    payload.request["to_date"] = moment(as_of_date)
                        .add(11, "M")
                        .endOf("month")
                        .format("YYYY-MM-DD");
                }

                if (selectedAPI.payload.hasOwnProperty("asOfDate")) {
                    // payload.request["asOfDate"] = primary_stay_range.date_from;
                    payload.request["asOfDate"] = as_of_date;
                }
                if (selectedAPI.payload.hasOwnProperty("pickupDays")) {
                    payload.request["pickupDays"] = parseInt(workspace_controls.pickUpDays);
                }
                if (selectedAPI.payload.hasOwnProperty("sdYear")) {
                    payload.request["sdYear"] = parseInt(workspace_controls.sdYear);
                }
                if (selectedAPI.payload.hasOwnProperty("dowFilter")) {
                    // let dowFilter = (workspace_controls.dayOfWeek === "all") ? "mon,tue,wed,thu,fri,sat,sun" : workspace_controls.dayOfWeek ;
                    payload.request["dowFilter"] = workspace_controls.dayOfWeek;
                }

            }
            //Overrides for daily_flash apis
            let { data_sources } = getTileById('daily_flash');
            if (data_sources.includes(active_api)) {
                let series = deepCopy(getSeriesById(active_api));
                // debugger;
                payload.request = series.payload;
            }
            //--->

            payload.selectedAPI = selectedAPI;
            payload.selectedAPI.id = active_api;
            payload.active_api = active_api;
            payload.active_apis = active_apis;
            payload.without_cache = without_cache;
            let compDate = {sdly:workspace_controls.sdlyOn};
            compDate = (workspace_controls.clearComparision) ? comp_date_from : '';
            
            varMaps = {
                ...varMaps,
                scopeType: (workspace_controls.scopeType).toUpperCase(),
                pickUpDays: workspace_controls.pickUpDays,
                sdYear: workspace_controls.sdYear,
                dowFilter:workspace_controls.dayOfWeek,
                compDate : compDate,
                payload,
                date_from,
                date_from_daily: new moment(as_of_date).startOf('month').format('YYYY-MM-DD'),
                date_to_daily: new moment(as_of_date).add(12,'Month').endOf('month').format('YYYY-MM-DD'),
                fromDate: date_from,
                rangeFromDate: date_from,
                comp_date_from,
                date_until,
                rangeUntilDate: date_until,
                toDate: date_until,
                comp_date_until,
                mid: selected_market.market_id,
                asof_date: as_of_date,
                compAsOfDate:comp_as_of_date,
                comp_as_of_date,
                asOfDate: as_of_date,
                pid: property_id,
                from_pid, to_pid,
                system_settings,
                dbaFilter: workspace_controls.dba,
                removeCompId:true,
                dsid: data_source_id,
                timestamp:Math.random()
            };
            // debugger; 
            payload.api = setApiURLVars(selectedAPI.uri, varMaps);
            // console.log("payload", payload);

            return DataService.getDataDashboard(payload);
            if (api_status[active_api] !== 'loading' && api_status[active_api] !== 'loaded') {
                // data_status[active_api] = 'loading';
                // api_status[active_api] = 'loading';
                console.log("API Called", active_api);
                // DataService.updateDataCache({
                //   id: active_api,
                //   //data: null,
                //   loadStatus: "loading"
                // });

            }
        };
let prevChange = "";
let timeoutID = null;
export const updateAppState = (change, payload, cat="side_effect") => (dispatch, getState) => {
    let updated_state = deepCopy(payload);
    let isLogSendable =false;
    if(prevChange !== change){
        isLogSendable=true;
    }
    const state = getState();
    const { app, auth } = state;
    const { current_state } = app;
    const { property_details } = auth.profile;
    if (!updated_state.app_state) {
        updated_state.app_state = {};
    }
    if (!updated_state.ui_state) {
        updated_state.ui_state = {};
    }

    if (change === "property_change") {
        isLogSendable =true;
        let compsetSettings = getFromState('system_settings>compsetSettings', state, updated_state.app_state.property_id);
        let market_settings = getFromState('system_settings>market_settings', state, updated_state.app_state.property_id);
        market_settings = market_settings ? market_settings : [];
        let inventory_settings = getFromState('system_settings>inventory_settings>', state, updated_state.app_state.property_id);
        let room_settings = safeReference(inventory_settings, [], ["current", "room_settings"])
        let defaultRoomCategory = room_settings && room_settings.length ? room_settings[0] : {room_class:"All"};
        // debugger;
        let defaultRoomCodesList = [];
        room_settings.forEach(room => {
            if (room.room_class === "Standard") {
                defaultRoomCategory = room
                
            }
            defaultRoomCodesList.push(room.room_code)
        })
        if (defaultRoomCodesList.length < 1 && room_settings.length) {
            defaultRoomCodesList = [room_settings[0].room_code]
        }
        // const propertySettings = getFromArr(
        //     property_details,
        //     "id",
        //     updated_state.app_state.property_id
        // );
        // const { compsetSettings, market_settings } = 
        //     propertySettings.system_settings[0];
        let selected_market = deepCopy(market_settings);
        market_settings.map((market) => {
            if (market.is_default === true) {
                selected_market = deepCopy(market);
            }
        });
        updated_state.app_state.data_availability = current_state.app_state.as_of_date;
        updated_state.app_state.compsetSettings = compsetSettings;
        updated_state.app_state.selected_market = selected_market;
        updated_state.app_state.market_settings = market_settings;
        updated_state.app_state.kpi_load = true;
        // updated_state.ui_state.selectedRoomPricingCategory = defaultRoomCategory;
        updated_state.ui_state.selectedRoomPricingRoomCodes = defaultRoomCodesList;
        updated_state.app_state.workspace_controls= { ...current_state.app_state.workspace_controls,
                                                        isRoomTypePricing:false
                                                            }
    }
    if (change.indexOf("change_active_tiles")>-1) {
        isLogSendable =true;
        // let tile_config = TILES[updated_state.ui_state.active_tiles[0]];
        // if(tile_config && !tile_config.views.includes("chart")){
        //     console.log("chart view")
        // }else {
           
        // }
        const measuresState = determineMeasureStates({
            active_tiles: updated_state.ui_state.active_tiles,
            userSelected_left_measure: current_state.ui_state.left_measure,
            userSelected_right_measure: current_state.ui_state.right_measure,
        });
        updated_state.ui_state["left_measure"] = measuresState.selectedLeftMeasure
            .value
            ? measuresState.selectedLeftMeasure.value
            : null;
        updated_state.ui_state["right_measure"] = measuresState.selectedRightMeasure
            .value
            ? measuresState.selectedRightMeasure.value
            : null;
        updated_state.ui_state["right_measures_list"] = measuresState.rightList;
        updated_state.ui_state["left_measures_list"] = measuresState.leftList;
        // updated_state.app_state.kip_load = true;
        console.log(updated_state);
    }

    if (change === "downloadReport") {
        updated_state.ui_state.header_alert_msg =
            updated_state.ui_state.header_alert_msg;
    }
    if (change === "onHideAlert") {
        updated_state.ui_state.header_alert_msg = "";
    }
    if (change === "error") {
        updated_state.ui_state.header_alert_msg = "Oops! Something went wrong.";
    }
    if(change === "confirmed_rates_success_empty"){
        updated_state= {
            app_state: {
                pricing_override_rates: {},
                roomtype_pricing_overrides:{}
            },
            ui_state: { pricingRateModalOpen: false }
        }
    }
    if(change ===  "sendPricingRateClose"){
        updated_state.ui_state.pricingRateModalOpen= false ;
    }
   
    
    if( isLogSendable ===true && cat !== "side_effect"){
        sendLog({category:cat, action: change},{...current_state} ,{...payload} )
        prevChange = change;
   }
   
    //console.log("updateAppState called", updated_state);
    // debugger;
    if(timeoutID){
        clearTimeout(timeoutID);
    }
    if(['stay_range_shiftArrow'].includes(change)){
        timeoutID = setTimeout(()=>{
            dispatch({ type: UPDATE_STATE, payload: updated_state });
        }, 500);
    } else {
        dispatch({ type: UPDATE_STATE, payload: updated_state });
    }
    
};

export const getPropertyInfo = (property_details, property_id) => {
    // const { app, auth } = getState();

    // const { property_details } = profile
    let property_info = {};
    property_details.map(property => {
        if (property.id === property_id) {
            property_info = { ...property };
        }
    })
    return property_info
}
export const setApiURLVars = (url, params) => {
    let url_vars = url.match(/{?[a-z0-9 -_]+}/gi);
    let new_url = url;
    let { payload } = params;
    if (url_vars && url_vars.length) {
        url_vars.map(val => {
            let varName = val.substr(1, val.length - 2);
            let varVal = !params.hasOwnProperty(varName) ? val : params[varName];
            if (varVal === val) {
                varVal = !payload[varName] ? val : payload[varName];
                if (payload.request[varName]) {
                    varVal = payload.request[varName]
                }
                // try {
                //     varVal = eval(varName);
                // } catch (error) {
                //     varVal = val;
                // }
            }

            new_url = new_url.replace(val, varVal);
        })
    }
    return new_url;
};

export const getProperty = () => {

}
export const  restrictionsAvailable = (propertyInfo)=>{
    // return true ; //temp
     let hasRestrictions = false;
     if(propertyInfo.system_settings[0].restrictions && 
        propertyInfo.system_settings[0].restrictions.enable === true){
         hasRestrictions =true;
     }
     return hasRestrictions;
}
export const getSystemSettings = () => {
    return DataService.getData({
        api: "", request: {
            method: "GET",

        }
    }).then(res => {

    })
}