import React, { useEffect } from 'react';
import { useDispatch } from "react-redux";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom"
import Login from "../components/Auth/Login";
import NewPassword from "../components/Auth/NewPassword";
import { PrivateRoute } from './PrivateRoute';
import DashboardLayout from "../components/Dashboard/DashboardLayout";
import { clearMessage } from "../actions/message";
import { history } from "./history";
import ProfileSettings from "../components/Settings";
import { setLastEventLog } from "./util"

import { FBNotifications } from "../components/layout/dashboard-ui";
const cacheStore = {};

function App() {
    const dispatch = useDispatch();
    
    useEffect(() => {
        let isSubscribed = true;

        history.listen((location) => {
            dispatch(clearMessage()); // clear message when changing location
        });

        return () => (isSubscribed = false);
    }, [dispatch]); 


    

    return (
        <div onClick={() => {
            setLastEventLog();
        }} className="App" style={{ height: '100vh' }}>
            <Router>
                <Routes>
                    <Route element={<Login />} path="/login" />
                    <Route element={<NewPassword />} path="/new-password" />
                    <Route element={<PrivateRoute component={DashboardLayout} />} path="/" />
                    <Route element={<PrivateRoute component={ProfileSettings} />} path="/settings" />
                </Routes>
            </Router>
            <FBNotifications />
        </div>
    );
}

export default App;
