import React, { useEffect, useState } from 'react';
import { Box, Chip } from '@mui/material';
import Stack from '@mui/material/Stack';
import Tooltip from '@mui/material/Tooltip';
import MoreHorizIcon from '@mui/icons-material/MoreHoriz';
import { keys } from 'highcharts';
import UpdateRestrictionsModal from '../Modal/UpdateRestrictionsModal'
import RestrictionsList from './RestrictionsList';
import RoomTypeRestrictionsList from './RoomTypeRestrictionsList';
import restRoomTypeDemoData from "./room_type.json";
import { deepCopy, getAppData, getObjectFilter } from '../../app/util';
import moment from 'moment';
import { useSelector } from 'react-redux';
import dataService from '../../services/data.service';


export const getClassName = (type) => {
    let classStyle = "btn-cta";
    if (type.toLowerCase() === "cta") {
        classStyle = "btn-cta";
    } else if (type.toLowerCase() === "ctd") {
        classStyle = "btn-ctd";
    } else if (type.toLowerCase() === "cls") {
        classStyle = "btn-cls";
    } else if (type.toLowerCase() === "open") {
        classStyle = "btn-open";
    } else if (type.toLowerCase() === "close") {
        classStyle = "btn-close";
    } else if (type.toLowerCase() === "min_los") {
        classStyle = "btn-min_los";
    } else if (type.toLowerCase() === "max_los") {
        classStyle = "btn-max_los";
    }

    return classStyle;
}
export const getResName = (type, value) => {
    let resDetail = {};
    let resName;
    if (type.toLowerCase() === "cta") {
        resDetail.resName = "cta";
        resDetail.fullName = "Close To Arrival";
    } else if (type.toLowerCase() === "ctd") {
        resDetail.resName = "ctd";
        resDetail.fullName = "Close To Departure";
    } else if (type.toLowerCase() === "cls") {
        resDetail.resName = "cls";
        resDetail.fullName = "Close";
    } else if (type.toLowerCase() === "open") {
        resDetail.resName = "open";
        resDetail.fullName = "Open";
    } else if (type.toLowerCase() === "close") {
        resDetail.resName = "close";
        resDetail.fullName = "Close";
    } else if (type.toLowerCase() === "min_los") {
        resDetail.resName = "mn " + value;
        resDetail.fullName = "Minimum Length Of Stay";
    } else if (type.toLowerCase() === "max_los") {
        resDetail.resName = "mx " + value;
        resDetail.fullName = "Maximum Length Of Stay";
    }
    return resDetail;
}

const getRoomTypeRestrictions = (stay_date, roomSettings) => {
    stay_date = moment(stay_date).format('YYYY-MM-DD');
    let restRoomTypeDemoDataAPI = dataService.getSeriesById("GetRestrictionsRoomType").data;
    // debugger;
    let roomTypeRestData = restRoomTypeDemoDataAPI ? deepCopy(restRoomTypeDemoDataAPI).cur : [];
    // let roomTypeRestData = deepCopy(restRoomTypeDemoData).cur;
    let filteredData = roomTypeRestData.length ? getObjectFilter(roomTypeRestData, stay_date, "stay_date") : [];
    let filterRestData = [];
    filteredData.map((item, index) => {
        if (item) {
            let restItem = {};
            let chipArr = [];
            Object.keys(item).forEach(function (key) {
                if (item[key] === true || item[key] > 1) {
                    let itemName = key.toUpperCase();
                    let classStyle = getClassName(itemName);
                    let itemNameDetails = getResName(key, item[key]);
                    itemName = itemNameDetails.resName;
                    let itemFullName = itemNameDetails.fullName;
                    let roomTypeDetails = getObjectFilter(roomSettings, item.room_type, "room_code")
                    chipArr.push(
                        <Tooltip title={itemFullName} placement="top" arrow>
                            <div className={"chip " + classStyle}>{itemName}</div>
                        </Tooltip>
                    )
                    let room_type_display = (roomTypeDetails[0] && roomTypeDetails[0]['room_class']) ? roomTypeDetails[0]['room_class'] : '';
                    item.room_type_display = room_type_display;
                    restItem = item;
                    restItem.restrictions = chipArr;
                }
            })
            if (Object.keys(restItem).length > 0) {
                filterRestData.push(restItem);
            }
        }
    })

    return filterRestData;
}

function RestrictionsUpdate(props) {
    const blankRestriction = { cta: false, ctd: false, close: false };
    const { editable, recData, data, cur, stay_date } = props;
    const { auth, app } = useSelector(state => state);
    const { app_state, ui_state } = useSelector(
        (state) => state.app.current_state
    );
    let systemSettings = getAppData(auth, app_state);
    let roomSettings = systemSettings.inventory_settings.current.room_settings;
    let roomTypeRestdata = getRoomTypeRestrictions(props.stay_date, roomSettings);
    let chipArr = [];
    let chipArrAll = [];
    const { savedRestrictions, updatedRestrictions } = app_state;
    let current = savedRestrictions.filter(item => item.stay_date === stay_date);
    if(current && current.length){
        current = current[0];
        current.min_los = current.min_los == 1 ? '' : current.min_los;
        current.max_los = current.max_los == 1 ? '' : current.max_los;
    } else {
        current = deepCopy(blankRestriction);
    }
    const [compState, setCompState] = useState({
        modalOpen: false, entryFields: [],
        cur: cur ? deepCopy({ ...blankRestriction, ...cur }) : deepCopy(blankRestriction),
        entry: current
    });

    useEffect(() => {
        let isSubscribed = true;
        // debugger
        let current = savedRestrictions.filter(item => item.stay_date === stay_date);
        let updated = updatedRestrictions.filter(item => item.stay_date === stay_date);
        if (current && current.length) {
            // debugger;
            setCompState(state => {
                return { ...state, entry: current[0].entry }
            });
        } else if (updated && updated.length) {
            setCompState(state => {
                return { ...state, entry: updated[0].entry, cur:  updated[0].entry}
            });
        } else if (cur) {
            setCompState(state => {
                return { ...state, entry: cur }
            });
        } else {
            setCompState(state => {
                return { ...state, entry: blankRestriction }
            });
        }

        return () => (isSubscribed = false);
    }, [app_state.savedRestrictions]);

    const handleCellClick = (entry) => {
        // debugger;

        return (event) => {
            // debugger;
            let entryFields = [];
            if (data[0]) {
                let keys = Object.keys(entry);
                // debugger;
                keys.map((key) => {
                    if (['cta', 'ctd', 'close'].includes(key) && entry[key]) {
                        entryFields.push(key);
                    }
                });
            }
            setCompState(state => {
                return { ...state, modalOpen: true, entryFields, entry: entry };
            });
        }
    }

    const handleModalClose = () => {
        setCompState(state => {
            return { ...state, modalOpen: false };
        })
    }

    if (data && data.length) {
        // debugger;
        let keys = Object.keys(data[0]);
        keys.map((item, index) => {
            if (!['asof_date', 'stay_date'].includes(item)) {
                let itemName = item.toUpperCase();
                let classStyle = getClassName(itemName);
                let itemNameDetails = getResName(item, data[0][item]);
                itemName = itemNameDetails.resName;
                let itemFullName = itemNameDetails.fullName;
                chipArrAll.push(
                    // <Chip label={itemName} size="small" className={classStyle} sx={{marginRight:"5px !important", minWidth: "45px !important", height: "25px !important" }} />
                    <div className={"chip " + classStyle}>{itemName}</div>
                )
                if (index <= 1) {
                    chipArr.push(
                        <Tooltip title={itemFullName} placement="top" arrow>
                            {/* <Chip label={itemName} size="small" className={classStyle} sx={{ minWidth: "45px !important", height: "17px !important" }} /> */}
                            <div className={"chip " + classStyle}>{itemName}</div>
                        </Tooltip>
                    )
                }
            }
        })
    }
    const { modalOpen, entry } = compState;
    return (<>
        {!editable && <>
            <Box className="update-restrictions" sx={{ position: 'relative', "min-height": 16, "max-height": '100%' }}>
                <Box sx={{ position: 'absolute', top: -5, pl: 0.5, pt: .6, left: 0, width: '100%', "min-height": 26, height: '100%', '&:hover .add-restriction-blanck': { visibility: 'visible' } }}>
                    <div className="res-rules-display">
                        {recData.cta && <span className="res-rule res-cta">CTA</span>}
                        {recData.ctd && <span className="res-rule res-ctd">CTD</span>}
                        {recData.close && <span className="res-rule res-cls">CLS</span>}
                        {recData.min_los && recData.min_los !== 1 && <span className="res-rule res-min_los">MN {recData.min_los}</span>}
                        {recData.max_los && recData.max_los !== 1 && <span className="res-rule res-max_los">MX {recData.max_los}</span>}
                    </div>
                </Box>
            </Box>
        </>}
        {/* {!editable && <Stack spacing={1} alignItems="center">
            
            <Stack direction="row" spacing={1}>
                {chipArr}
                {
                    // (data[0] && (Object.keys(data[0]).length) > 2) ? <RestrictionsList chipArrAll={chipArrAll} /> : <></>
                    (data[0] && (Object.keys(data[0]).length) > 2) ? <RoomTypeRestrictionsList chipArrAll={chipArrAll} roomTypeRestdata={roomTypeRestdata} /> : <></>
                }
            </Stack>
        </Stack>} */}

        {editable &&
            <><Box className="update-restrictions" onClick={handleCellClick(entry)} sx={{ position: 'relative', "min-height": 16, "max-height": '100%' }}>
                <Box sx={{ border: '1px solid transparent', position: 'absolute', top: -5, pl: 0.4, pt: .5, left: 0, width: '100%', "min-height": 26, height: '100%', cursor: 'pointer', '&:hover': { border: '1px solid #616161', borderCollapse: 'collapse' }, '&:hover .add-restriction-blanck': { visibility: 'visible' } }}>
                    <div className="res-rules-display">
                        {entry.cta && <span className="res-rule res-cta">CTA</span>}
                        {entry.ctd && <span className="res-rule res-ctd">CTD</span>}
                        {entry.close && <span className="res-rule res-cls">CLS</span>}
                        {entry.min_los > 1 && entry.min_los !== 0 && <span className="res-rule res-min_los">MN {entry.min_los}</span>}
                        {entry.max_los > 1 && entry.max_los !== 0 && <span className="res-rule res-max_los">MX {entry.max_los}</span>}
                    </div>
                </Box>
            </Box>
                {modalOpen && <UpdateRestrictionsModal {...props} modalOpen={modalOpen} handleModalClose={handleModalClose} entryFields={compState.entryFields} entry={compState.entry} cur={compState.cur} data={[compState.cur]} />}
            </>
        }
    </>)
}

export default RestrictionsUpdate;
