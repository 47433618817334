

import {Button, Box, Select, MenuItem} from '@mui/material'
import {useDispatch, useSelector} from 'react-redux';
import { updateAppState } from '../../../../actions/appstate';
import {useState, useEffect} from 'react'

//menu items needs to be markets
const MarketPicker = () => {
   
    const  {app_state,ui_state}= useSelector(state => state.app.current_state);
    const {selected_market, market_settings}=app_state
    const [market, setMarket] = useState(selected_market.market_id)
    const dispatch = useDispatch();
    useEffect(() => {
        setMarket(app_state.selected_market.market_id);
        
    }, [app_state])
    const handleChange = (type,value)=>{
        
        dispatch(updateAppState(type, {app_state: {selected_market:value,data_load:true},ui_state:{showspinner:true}}));
    }
    let label = (ui_state.active_tiles[0] === "market_sell_rates") ? "Locale" : "Market" ;

    return (
        <Box id="market_select" sx={{backgroundColor:'blue', height: 1/2, maxWidth:150}}>
        <Box sx={{display:'block', fontSize:'75%', fontWeight:'bold'}}>{label} </Box>
        <Box> 
            <Select fullWidth value={market} sx={{height: "27px", mt: "5px", fontSize:"13px",}} onChange={(e) => {
                setMarket(e.target.value); 
                let selected_market;
                market_settings.map(market=>{
                    if(market.market_id === e.target.value){
                        selected_market = market;
                    }
                })
                handleChange("market_select", selected_market);
                }}> 
                {market_settings.map((market,index)=>{
                    return (<MenuItem className='tabular-data-filter' value={market.market_id} key={index}>{market.label}</MenuItem>)
                })}
            </Select> 
        </Box>
     </Box>
    )
}

export default MarketPicker