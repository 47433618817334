import React, { useEffect, useReducer, useRef, useState } from "react";
import ReactDOM from "react-dom";
import { styled } from "@mui/material/styles";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import FolderIcon from "@mui/icons-material/Folder";
import CircularProgress from "@mui/material/CircularProgress";
import DeleteIcon from "@mui/icons-material/Delete";
import moment from "moment";
import { useSelector } from "react-redux";
import Alert from "@mui/material/Alert";
import FilePresentIcon from "@mui/icons-material/FilePresent";
import Breadcrumbs from '@mui/material/Breadcrumbs';
import Link from '@mui/material/Link';
import CloseIcon from '@mui/icons-material/Close';

import CloudDownloadIcon from '@mui/icons-material/CloudDownload';
import { API_BASE_URL } from "../../app/config";
import CreateNewFolderIcon from '@mui/icons-material/CreateNewFolder';
import TextSnippetIcon from '@mui/icons-material/TextSnippet';
import { replaceMultiText } from "../../app/util";
import { fetchDataSimple } from "../../services/data.service";
import FolderOpenIcon from '@mui/icons-material/FolderOpen';
import FolderCopyIcon from '@mui/icons-material/FolderCopy';
import PictureAsPdfIcon from '@mui/icons-material/PictureAsPdf';
import { LightTooltip } from "../layout/dashboard-ui";
import { Tooltip } from "@mui/material";
import { textAlign } from "@mui/system";
const FileFolderList = (props) => {


    const { app_state, ui_state } = useSelector(state => state.app.current_state);
    const { data, fileType } = props.params;
    let file_type;
    if (fileType === "forecast_upload") {
        file_type = "manual_forecast";
    } else if (fileType === "documents_upload") {
        file_type = "document";
    } else {
        file_type = "budget";
    }
    let filesData = data.files.filter((item) => item.file_type === file_type)
    let foldersData = (file_type === "document") ? data.folders : [];
    foldersData.sort((a, b) => a.folder_name.toLowerCase() > b.folder_name.toLowerCase() ? 1 : -1);
    // console.log("foldersData",foldersData);
    const handleDownload = (filesDetails)=>{
        console.log("download",filesDetails)
        let request = {"method":"GET", "type":"blob"};
        let url = `upload/download-file/${app_state.property_id}/${filesDetails.document_uid}/`
        fetchDataSimple({api:url, request}).then(res=>{
            const newBlob = new Blob([res]);

            const blobUrl = window.URL.createObjectURL(newBlob);
        
            const link = document.createElement('a');
            link.href = blobUrl;
            link.setAttribute('download', `${filesDetails.file_name}`);
            document.body.appendChild(link);
            link.click();
            link.parentNode.removeChild(link);
        
            // clean up Url
            window.URL.revokeObjectURL(blobUrl);
        }).catch(err=>{
            console.log(err);
        })
    }
    return (
        <>
            <Table sx={{ minWidth: 550 }} size="small" stickyHeader aria-label="a dense table">
                {((foldersData.length > 0 || filesData.length > 0) &&
                    <TableHead>
                        <TableRow key={Math.random()} sx={{ background: "#6b6b6a", color: "#fff" }}>
                            <TableCell sx={{ background: "#6b6b6a", color: "#fff" }}></TableCell>
                            <TableCell sx={{ background: "#6b6b6a", color: "#fff" }} align="right">Date Uploaded</TableCell>
                      
                        </TableRow>
                    </TableHead>
                )}
                <TableBody>

                {/* <TableRow key={Math.random()} >
                    <TableCell>1</TableCell>
                    <TableCell>1</TableCell>
                    <TableCell>1</TableCell>
                    <TableCell>1</TableCell>
                </TableRow> */}

                    {(foldersData && foldersData.length > 0 && foldersData.map((foldersDetails, index) => {
                        let bgClass = (index % 2 === 0) ? "bg-gray file-column" : "file-column";
                        let path = index + ">folders>" + foldersDetails.folder_uid + ">" + foldersDetails.folder_name;

                        return (<TableRow key={Math.random()}>
                            <TableCell className={bgClass} sx={{ position: "relative" }}>
                                <FolderCopyIcon onClick={() => { props.handleFolderChange(path, foldersDetails.folder_uid); }} variant="" sx={{verticalAlign:"bottom",color: "#9ed00d", cursor: "pointer" }} />
                                <span className="filename-style cursor-style" onClick={() => { props.handleFolderChange(path, foldersDetails.folder_uid); }}>{foldersDetails.folder_name}</span>
                           
                            </TableCell>
                            {/* <TableCell className={bgClass} align="left" sx={{ pl:1 }}><span className="folder-name" onClick={() => { props.handleFolderChange(path, foldersDetails.folder_uid); }} >{foldersDetails.folder_name}</span></TableCell> */}
                            <TableCell className={bgClass} align="right" sx={{ position: "relative", pr: 5 }}>
                                {moment(foldersDetails.insert_timestamp).format(" MMM DD, YYYY hh:mm")}
                                <Tooltip title="Delete Folder" arrow followCursor>
                                    <span className="icon-delete">
                                        <DeleteIcon onClick={() => props.deleteItem(index, foldersDetails.folder_uid)} />
                                    </span>
                                </Tooltip>
                            </TableCell>
                        </TableRow>
                        )
                    })

                    )}

                    {(filesData && filesData.length > 0 && filesData.map((filesDetails, index) => {
                        let bgClass = (index % 2 !== 0) ? "bg-gray file-column" : "file-column";
                        let fileName = filesDetails.file_name;
                        let chkExtension = fileName.split('.').pop();
                        return (<TableRow key={Math.random()}>
                            <TableCell className={bgClass}>
                                <span style={{paddingRight:"5px"}}>
                                    <CloudDownloadIcon sx={{cursor:"pointer", color: "#9ed00d", verticalAlign:"bottom"}} 
                                    onClick={()=>{
                                        handleDownload(filesDetails)
                                    }}/>
                                </span>
                                {(chkExtension.toLowerCase() === "pdf") ?
                                    <PictureAsPdfIcon variant="outlined" sx={{ color: "#9ed00d", verticalAlign:"bottom"}} />
                                    :
                                    <TextSnippetIcon variant="outlined" sx={{ color: "#9ed00d", verticalAlign:"bottom" }} />
                                }
                                <span className="filename-style">{filesDetails.file_name}</span>
                            </TableCell>


                            <TableCell className={bgClass} align="right" sx={{ position: "relative", pr: 5 }}>
                                {moment(filesDetails.update_timestamp).format(" MMM DD, YYYY hh:mm")}
                                <Tooltip title="Delete Document" arrow followCursor>
                                    <span className="icon-delete">
                                        <DeleteIcon onClick={() => props.deleteDocument(index, filesDetails.document_uid)} />
                                    </span>
                                </Tooltip>
                            </TableCell>


                        </TableRow>
                        )
                    })

                    )}


                </TableBody>
            </Table>
        </>
    )
}

export default FileFolderList
