import { Alert, Button, Typography } from "@mui/material";
import { Box } from "@mui/system";
import React, { useReducer, useState } from "react";
import { FileUploader } from "react-drag-drop-files";
import PostAddIcon from '@mui/icons-material/PostAdd';
import CloudUploadOutlinedIcon from '@mui/icons-material/CloudUploadOutlined';
import { getFromSession, replaceMultiText } from "../../app/util";
import { settingsReducers } from "./reducers";
import { fetchDataSimple } from "../../services/data.service";
import { useSelector } from "react-redux";
import { API_BASE_URL } from "../../app/config";
import { time } from "highcharts";
import moment from "moment";
import CircularProgress from '@mui/material/CircularProgress';
import { Chip } from '@mui/material';
import {
    CloseSharp as CloseSharpIcon
}
    from '@mui/icons-material';


const fileTypes = ["xls", "xlsx"];
function FileUploadDragDrop(props) {

    const initalState = {
        data: { files: [], folders: [] },
        ui: {
            request: {
                resType: null,
                status: "idle",
                message: "",
            },
            path: [],
            breadcrumb: []
        },
        parent: null
    };

    const [settingsState, dispatchSettings] = useReducer(settingsReducers, initalState);



    const { app, auth } = useSelector((state) => state);
    const { user, profile } = auth;
    const { app_state } = app.current_state;
    const { ui, data } = settingsState;

    const [file, setFile] = useState(null);
    const [buttonText, setButtonText] = useState("Upload File");

    const handleChange = (file) => {
        setButtonText("Upload File");

        setFile(file);
    };
    const submitFileData = async () => {
        setButtonText("Uploading...");
        let formData = new FormData();
        let uploadReport = props.file_type;
        var file_upload_type;
        if (uploadReport === 'budget_upload') {
            file_upload_type = "BUDGET";
        } else if (uploadReport === 'forecast_upload') {
            file_upload_type = "FORECAST";
        } else if (uploadReport === 'documents_upload') {
            file_upload_type = "DOCUMENT";
        }
        if (file) {
            let newFileName = moment().format('MMM-DD_mm_ss') + "_" + file.name
            //formData.append("file", file);
            formData.append("fileUploadType", file_upload_type);
            if (props.data.parent) {
                formData.append("folderUid", props.data.parent);
            }
            formData.append('file', file, newFileName);
            formData.append("asOfDate", app_state.as_of_date)
            formData.append("documentTags", "")

        }
        const options = {
            method: "POST",
            body: formData,
        };
        const token = getFromSession("token");

        // debugger;
        dispatchSettings({ type: "wait" });
        const correction = {
            "{pid}": app_state.property_id,
        };
        let apiUrl = "upload/property-file/{pid}/";
        apiUrl = replaceMultiText(apiUrl, correction);
        let api = API_BASE_URL + "" + apiUrl;

        let res = await fetch(api, {
            method: "POST", // *GET, POST, PUT, DELETE, etc.
            cache: "no-cache", // *default, no-cache, reload, force-cache, only-if-cached
            headers: {
                "Authorization": "Token " + token,
                //'Access-Control-Allow-Origin':'*'
                // 'Content-Type': 'application/x-www-form-urlencoded',
            },
            redirect: "follow", // manual, *follow, error
            //referrerPolicy: 'origin', // no-referrer, *no-referrer-when-downgrade, origin, origin-when-cross-origin, same-origin, strict-origin, strict-origin-when-cross-origin, unsafe-url
            body: formData, // body data type must match "Content-Type" header
        })
        const data = await res.json();
        if (data.status === "success") {
            setFile("");
            props.getListing("navigateFolder")
            dispatchSettings({ type: "onFileUpload" });
        } else {
            dispatchSettings({ type: "onResponseError" });
        }
        setTimeout(() => {
            dispatchSettings({ type: "onHideAlert" });
        }, 3000);

    }

    const deselectFile = () => {
        setFile("");
    }

    let fileName = (file && file.name) ? file.name : "";
    let disbaled = (file && file.name) ? true : false;
    return (
        <>
            {/* {
            ui.request.message !== "" ? (
                <Box>
                    <Alert icon={false} severity="success" sx={{ minWidth: "500px !important", pl: "39%" }}>
                        {ui.request.message}
                    </Alert>
                </Box>
            ) : (
                <></>
            )
            } */}

            <FileUploader
                handleChange={handleChange}
                name="file"
                label="Select or Drop a file to upload"
                classes="drop_area"
                disabled={disbaled}
                children={<UploadInnerHtml buttonText={buttonText} fileName={fileName} deselectFile={deselectFile} submitFileData={submitFileData} />}
            />

        </>
    );
}



export function UploadInnerHtml(props) {
    let isDisabled = (props.buttonText !== "Upload File") ? "disabled" : "";
    return (
        <>
            {(props.fileName) ?

                <>
                    <div className="file-container">
                        <div className="upload-area-filename">
                            <Chip label={props.fileName} onDelete={props.deselectFile} />
                        </div>
                        <div className="upload-area-button">
                            <Button variant="contained"
                                disabled={isDisabled}
                                startIcon={(props.buttonText === "Upload File") ? <CloudUploadOutlinedIcon /> : <CircularProgress size={15} sx={{ color: "#fff" }} />}
                                onClick={(e) => { e.stopPropagation(); props.submitFileData() }}
                                size="midium">{props.buttonText}</Button>
                        </div>
                    </div>
                </>
                :
                <div className="upload-area">
                    <PostAddIcon color="primary" sx={{ float: "left" }} />
                    <span className="upload-area-txt">Select or Drop a file to upload</span>
                </div>
            }
        </>
    )
}




export default FileUploadDragDrop;