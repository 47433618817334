import moment from "moment";
import { convertToPercent, deepCopy, getAppData, getFromState, getObjectFilter, getTransformedSegments, round, setUpDate } from "../../../app/util";
import { useDataSummary } from "../../Tile/kpiService";
import dataService from "../../../services/data.service";
import { useSelector } from "react-redux";
import { transformSegmentData } from "../segmentedData";

export function getOtbSegmentData(data, columns, app_state) {
    console.log(data, "  Segmented Data  ==> ", Object.keys(data.data[0]).length);
    // data.data.slice(0,22);

    let setup_data = [];
    data.data.map((item, index) => {
        delete item.HotelTotal;
        let data_columns = [];
        columns.map(col => {
            let dataObj = {};
            let dataArr = [];

            let segmentArr = [];
            for (var segmentKey of Object.keys(item)) {
                segmentArr.push(segmentKey);
            }
            //   console.log(segmentArr);
            // for (var k of Object.keys(item)) { 
            for (let aj = 1; aj < segmentArr.length - 1; aj++) {
                // console.log(item.index.date + " <= Key => " + segmentArr[aj]);
                if (segmentArr[aj] !== "index") {
                    if (col.type === 'dateColumn') {
                        dataObj["date"] = moment(item.index.date).format("MM/DD");
                        dataObj["dow"] = moment(item.index.date).format("ddd");
                        dataObj["segment"] = segmentArr[aj];
                    }
                    if (col.type === 'dataColumn') {
                        col.subcolumns.map(key => {
                            let value = key.value;
                            dataObj[value] = Math.random();//item[segmentArr[aj]]['primary']['value'];
                            // console.log(k," <==> ",(value),"===== ",item[k]['primary'][key.);
                            if (item[segmentArr[aj]]['primary'] !== 'undefined') {
                                //  console.log(value+" <> ",segmentArr[aj]+" = "+aj+"  - ",item[segmentArr[aj]]['primary'] );
                            }
                            // console.log(("Rooms" in item[k]['primary']));
                            // console.log(item[k]['primary'].hasOwnProperty("Rooms"));

                        })
                    }
                }

            }
            // console.log("data => ",dataObj);

            data_columns.push(dataObj);

        })

        setup_data.push(data_columns);
    })
    return setup_data;


}


// Function to create OTB data for Intellimerge

export const getOtbDataInt = (mainKey, id, tileName, key, index, dataKey, auth, app_state, tileData) => {
    let otbData = deepCopy(tileData); //deepCopy(dataService.getSeriesById("OSR").data.data);
    // debugger;
    let date = otbData['index'].date;
    let value = key.value;
    let otbDataKey = otbData[dataKey];
    let otbDataMain = otbDataKey[mainKey];
    let otbPrimary = otbDataKey["primary"];
    let otbSdly = otbDataKey["sdly"];
    let otbSdlyActual = otbDataKey["sdly_actual"];
    let dataObj = {};
    
    if (mainKey === "difference_sdly") {
        if (value === "Occ") {
            dataObj[value] = convertToPercent(otbPrimary[value] - otbSdly[value])
        } else {
            dataObj[value] = round(otbPrimary[value] - otbSdly[value])
        }
    }
    else if (mainKey === "variance_sdly") {
        let varianceData = convertToPercent((otbPrimary[value] - otbSdly[value]) / otbSdly[value]) ;
        dataObj[value] = ((isFinite(varianceData)) ? varianceData : 0.00) + "%";
    }
    else if (mainKey === "pickup_diff_sdly") {
        if (value === "Occ") {
            let pick_diff_Val = otbSdlyActual[value] - otbSdly[value]
            dataObj[value] = (pick_diff_Val) ? pick_diff_Val : 0;
        }else{
            dataObj[value] = round(otbSdlyActual[value] - otbSdly[value])
        }   
        // dataObj[value] = round(otbDataKey["sdly_actual"][value] - otbDataKey["sdly"][value])
    }
    else if (mainKey === "restrictions") {

        let resData = dataService.getSeriesById("GetRestrictions").data;
        // let resData = restDemoData;
        resData = resData && resData.cur ? resData.cur : [];
        let filteredData = getObjectFilter(deepCopy(resData), date, "stay_date")
        let filterRestData = [];
        if (filteredData[0]) {
            Object.keys(filteredData[0]).forEach(function (key) {
                if (filteredData[0][key] === false || filteredData[0][key] === 1) {
                    delete filteredData[0][key];
                }
                if (filteredData[0]["stay_date"]) {
                    //    delete filteredData[0]["stay_date"];
                }
            });
            filterRestData.push(filteredData[0]);
        }

        dataObj[value] = filterRestData;
    } else if (otbData && tileName === "otb") {
        // debugger;
        if (value == "Occ") {
            dataObj[value] = convertToPercent(otbDataKey[mainKey][value]);
        }
        else if (value == "SellRate") {
            dataObj[value] = (otbDataMain[value]) ? otbDataMain[value] : 0.00;
        }
        else {
            dataObj[value] =  (otbDataMain[value]) ? otbDataMain[value] : 0 ; //round(otbDataKey[mainKey][value]);
        }
    }
    return dataObj;
}



export const getOtbDataIntTest = (mainKey, id, tileName, key, index, dataKey, auth, app_state, otbSegmented) => {
    let value = key.value;
    let dataObj = {};
    let date = otbSegmented.index.date;
    let otbSegmentDataKey = otbSegmented[dataKey];
    let otbSegmentDataPrimary = otbSegmentDataKey["primary"];
    let otbSegmentDataSdly = otbSegmentDataKey["sdly"];
    let otbSegmentDataSdlyActual = otbSegmentDataKey["sdly_actual"];
    let otbSegmentDataMainKey =  otbSegmentDataKey[mainKey];
    
    if (mainKey === "difference_sdly") {
        if (value === "Occ") {
            dataObj[value] = convertToPercent(otbSegmentDataPrimary[value] - otbSegmentDataSdly[value])
        } else {
            dataObj[value] = round(otbSegmentDataPrimary[value] - otbSegmentDataSdly[value])
        }
    }
    else if (mainKey === "variance_sdly") {
        let varianceData = convertToPercent((otbSegmentDataPrimary[value] - otbSegmentDataSdly[value]) / otbSegmentDataKey["sdly"][value]);
        dataObj[value] = ((isFinite(varianceData)) ? varianceData : 0.00) + "%";
    }
    else if (mainKey === "pickup_diff_sdly") {
        if (value === "Occ") {
            let pick_diff_Val = otbSegmentDataSdlyActual[value] - otbSegmentDataSdly[value]
            dataObj[value] = (pick_diff_Val) ? pick_diff_Val : 0;

        }else{
            dataObj[value] = round(otbSegmentDataSdlyActual[value] - otbSegmentDataSdly[value])
        }   
        // dataObj[value] = round(otbSegmentDataSdlyActual[value] - otbSegmentDataSdly[value])
    }
    else if (mainKey === "restrictions" && getAppData(auth, app_state).restrictions.enable) {

        let resData = dataService.getSeriesById("GetRestrictions").data;
        // let resData = restDemoData;
        resData = resData && resData.cur ? resData.cur : [];
        let filteredData = getObjectFilter(deepCopy(resData), date, "stay_date")
        let filterRestData = [];
        if (filteredData[0]) {
            Object.keys(filteredData[0]).forEach(function (key) {
                if (filteredData[0][key] === false || filteredData[0][key] === 1) {
                    delete filteredData[0][key];
                }
                // if (filteredData[0]["stay_date"]) {
                //     //    delete filteredData[0]["stay_date"];
                // }
            });
            filterRestData.push(filteredData[0]);
        }

        dataObj[value] = filterRestData;
    } else if (otbSegmented && tileName === "otb") {
        // debugger;
        if (otbSegmentDataKey) {
            if (value == "Occ") {
                dataObj[value] = convertToPercent(otbSegmentDataMainKey[value]);
            } else {
                dataObj[value] = round(otbSegmentDataMainKey[value]);
            }
        }
    }
    return dataObj;
}