import { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { API_DI_CERT, API_BASE_URL } from "../app/config";
import { updateAppState } from "../actions/appstate";

function useMergedControls(params) {
  const dispatch = useDispatch();
  const { app } = useSelector((state) => state);
  const { app_state, ui_state } = app.current_state;

  let controlSupportTileMap = {
    segmented_view: ['otb', 'pickup', 'pace', 'forecast', 'budget', 'bi_pace_comparison', 'productionMarketReport','bi_sensitivity_analysi','bi_pickup_analysis',"productionReport"],
    scopeType: {
      common: ['pace', 'compset', 'market'],
      dateView: [
        'otb', 'pickup', 'pace', 'compset', 'market',
        'market_sell_rates', 'pricing', 'forecast', 'restrictions'
      ],
      dbaView: ['pace', 'compset', 'market']
    },
  };

  useEffect(() => {
    //Control options support logic in tile merge logic

    const { scopeType } = app_state.workspace_controls;
    const { active_tiles } = ui_state;
    //Handling different for market tile
    if(active_tiles.length===1 && active_tiles[0]==='market' && scopeType==='Dba'){
      //controlSupportTileMap.segmented_view.push('market');
    }
    if(active_tiles.length===1 && active_tiles[0]==='market' && scopeType==='Date'){
      controlSupportTileMap.segmented_view.push('market');
    }
    // debugger;
    // if (active_tiles.length > 1) {
      for (let key in app_state.controlSupport) {
        let option = key;
        if (key === 'scopeType') {
          option = "common";
          app_state.controlSupport['scopeType'] = active_tiles.every((tile) => controlSupportTileMap[key][option].includes(tile));
        } else {
          app_state.controlSupport[option] = active_tiles.every((tile) => controlSupportTileMap[option].includes(tile));
        }

      }
      // debugger;
      dispatch(
        updateAppState("change_active_tiles", {
          app_state: {
            ...app_state,
            date_range_type: 'rangeTileChange',
            controlSupport: app_state.controlSupport,
          },
          //app_state: {active_dataId, active_api, active_apis, data_load: true },
          ui_state: {
            ...ui_state,
            // active_tiles: activeTiles,
            // workspace_view,
            //showspinner: true
          },
        })
      );
    // }

    // return () => { 

    // }
    // debugger;
  }, [ui_state.active_tiles.length, ui_state.active_tiles[0], app_state.workspace_controls.scopeType]);

  return app_state.controlSupport;
}
export {
  useMergedControls,
};
