import { Alert, AlertTitle } from '@mui/material/';

export function ErrorHandler({ error, resetErrorBoundary }) {
    // debugger;
    return (
        <Alert severity="error">
            <AlertTitle>Error!</AlertTitle>
            <pre>Something went wrong while rendering, please check dev console for detailed info 
                <br /><strong>{error.message}</strong>
            </pre>
            <button onClick={resetErrorBoundary}>Try again</button>
        </Alert>
    )
}