import { TILES } from "../../app/config";
import { deepCopy, array_move } from "../../app/util"
export function determineMeasureStates({ active_tiles, userSelected_left_measure, userSelected_right_measure }) {

  let leftList = [];
  let rightList = [];
  let left_consolidated = {};
  let right_consolidated = {};

  let consolidateMeasures = function (combined_list, unique_lookup, tile_list) {
    for (let x = 0; x < tile_list.length; x++) {
      let measure = tile_list[x];
      if (!unique_lookup[measure.value]) {
        unique_lookup[measure.value] = measure;
        combined_list.push(measure);

      }
      else {
        //the measure is already there - we have to think through this a bit
        //when measures may have same display names but different rules/values
      }


    }
  }
  for (let i = 0; i < active_tiles.length; i++) {
    let tile = active_tiles[i];
    if (!tile) {
      continue;
    }
    let tile_measures = createTileMeasures(tile, i);
    consolidateMeasures(leftList, left_consolidated, tile_measures.left_measures);
    consolidateMeasures(rightList, right_consolidated, tile_measures.right_measures);
  }
  let useDefault = false;
  if (active_tiles[0] === "bi_sensitivity_analysis"){
    useDefault = true;
    // let occIndex = -1;
    // rightList.forEach((lItem, index)=>{
    //   if(lItem.value === "Occ"){
    //     occIndex= index;
    //   }
    // });
    // if(occIndex>-1){
    //   let lastVal = rightList[rightList.length-1];
    //   rightList[rightList.length-1]= rightList[occIndex];
    //   rightList[occIndex] = lastVal;
    // }
    // console.log(rightList);
  }

  let selectedLeftMeasure = getSelectedMeasure(leftList, userSelected_left_measure, useDefault);
  let selectedRightMeasure = getSelectedMeasure(rightList, userSelected_right_measure, false);
  if (active_tiles[0] === "bi_sensitivity_analysis"){
    rightList.forEach(item=>{
      if(item.value==="Occ"){
        selectedRightMeasure= deepCopy(item);
      }
    })
  }
  if (userSelected_left_measure && userSelected_left_measure.value !== selectedLeftMeasure.value) {
    //appStateModule.updateState({userSelected_left_measure:null});
  }
  if (userSelected_right_measure && userSelected_right_measure.value !== selectedRightMeasure.value) {
    // appStateModule.updateState({userSelected_right_measure:null});
  }
  leftList = fixMeasureList(selectedLeftMeasure, leftList);
  rightList = fixMeasureList(selectedRightMeasure, rightList);

  return {
    selectedLeftMeasure: selectedLeftMeasure,
    selectedRightMeasure: selectedRightMeasure,
    leftList: leftList,
    rightList: rightList
  };
}
function createTileMeasures(tile_name, indexAt) {


  let tile = TILES["otb"];
  if (TILES[tile_name]) {
    tile = TILES[tile_name];
  }
  const measures = {
    left_measures: [],
    right_measures: [],
  }
  if (!tile) {
    return measures;
  }
  let avail_measures = tile.measures_list;
  if (!avail_measures || avail_measures.length < 1) {
    return measures;
  }
  for (let i = 0; i < avail_measures.length; i++) {
    let md = avail_measures[i];
    let measure = { label: md.label, value: md.value, selected: false, unselectable: true, display: true, force_for_tile: null };
    if (avail_measures.length == 1) {
      if (indexAt === 0) {
        measure.selected = true;
      }
      measure.unselectable = false;
      measure.force_for_tile = [tile.tile_name];
      measures.right_measures.push(measure);
    }
    else {
      let right_measure = deepCopy(measure);
      let left_measure = deepCopy(measure);
      if (tile.default_right_measure === right_measure.value) {
        right_measure.selected = true;
      }
      if (tile.default_left_measure === left_measure.value) {
        left_measure.selected = true;
      }
      measures.right_measures.push(right_measure);
      measures.left_measures.push(left_measure);
    }

  }
  let right_selected = false;
  if (tile.default_right_measure === "n") {
    right_selected = true;
  }
  if (measures.right_measures.length > 0) {
    measures.right_measures.push({
      selected: right_selected,
      label: "Hide",
      value: null
    })
  }

  let left_selected = false;
  if (tile.default_left_measure === "n") {
    left_selected = true;
  }
  if (measures.left_measures.length > 0) {
    measures.left_measures.push({
      selected: left_selected,
      label: "Hide",
      value: null
    })
  }
  return measures;

}

function getSelectedMeasure(list, prior_selected, useDefault) {
  let measure = "";// this will be problem ...
  for (let i = 0; i < list.length; i++) {
    if (prior_selected && list[i].value === prior_selected && !useDefault) {
      measure = list[i];
      return measure;
    } else if (list[i].selected === true) {
      measure = list[i];
    }
  }

  return measure;
}
function fixMeasureList(measure, measureList) {
  let indexAt = -1;
  if (measureList.length < 1) {
    return measureList;
  }
  if (measure.label === "Hide") {
    for (let index = 0; index < measureList.length; index++) {
      if (measureList[index].label === "Hide") {
        indexAt = index;
      }

    }
    if (indexAt > -1) {
      measureList.splice(indexAt, 1)

    }
  } else {
    for (let index = 0; index < measureList.length; index++) {
      if (measureList[index].label === "Hide") {
        indexAt = index;
      }

    }
    if (indexAt === -1) {
      measureList.push({ label: "Hide", value: "n" });

    }
  }
  measureList = fixHidePosition(measureList);
  return measureList;
}
function fixHidePosition(measurelist) {
  let pos = -1;
  for (let index = 0; index < measurelist.length; index++) {
    if (measurelist[index].label === "Hide") {
      pos = index;
    }

  }
  if (pos > -1 && pos < measurelist.length - 1) {
    measurelist = array_move(measurelist, pos, measurelist.length - 1)
  }
  if (pos === -1) {
    measurelist.push({
      value: null,
      label: "Hide"
    });
  }
  return measurelist;
}