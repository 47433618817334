import { Button, Box, Modal, FormControl, Select, Typography, Container, Grid } from '@mui/material'
import { border } from '@mui/system';
import React, { Fragment } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { updateAppState } from '../../../../actions/appstate';
import CloseIcon from '@mui/icons-material/Close';
import Stack from '@mui/material/Stack';
import dataService, { fetchDataSimple } from '../../../../services/data.service';
import { API_BASE_URL, API_REPORT_URL } from "../../../../app/config";
import jsonData from './market.json';
import Chip from '@mui/material/Chip';

import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import { replaceMultiText, deepCopy } from '../../../../app/util';
import { LinearProgress } from '@mui/material';
import moment from "moment";

import Skeleton from '@mui/material/Skeleton';

const InfoButton = () => {
    const controls = useSelector(state => state.app.current_state.app_state.workspace_controls);
    const { app_state } = useSelector(
        (state) => state.app.current_state
    );
    const dispatch = useDispatch();
    const [jsonData, setJsonData] = React.useState(null);

    const handleClick = (type, value) => {
        setOpen(true);
        const updateTypes = [type];
        controls.infoButton = value;
        dispatch(updateAppState(updateTypes, { app_state: { workspace_controls: controls } }));
        let censusData = deepCopy(dataService.getSeriesById("CensusInfo").data);
        if (censusData) {
            setJsonData(censusData);
        } else {
            console.log("censusData ", censusData);
            const correction = {
                "{mid}": app_state.selected_market.market_id,
            };
            const request = {
                "method": "GET",
            };
            let api = "rms/markets/census-info/{mid}/";
            api = replaceMultiText(api, correction);
            fetchDataSimple({ api, request })
                .then((response) => {
                    setJsonData(response);
                    console.log("rms/markets/census-info/{mid} ==> ", response);
                })
                .catch((error) => {
                    console.log(error);
                });
        }
    }

    const [open, setOpen] = React.useState(false);
    const handleClose = () => setOpen(false);
    let capacity = (jsonData && jsonData.data) ? "CAPACITY : " + jsonData.data.capacity : 0;
    let available = (jsonData && jsonData.data) ? "AVAILABLE : " + jsonData.data.approved_capacity : 0;
    let lastUpdate = (jsonData && jsonData.meta) ? moment(jsonData.meta.date).format('MMM DD YYYY,  ddd') : '';

    return (
        <React.Fragment>
            <Box alignItems="center" justifyContent="center" sx={{ width: 1, height: 1 / 2 }}>
                <Button className='infoBtn' variant="contained" style={{ background: `rgb(53,131,184)` }} sx={{
                    display: "block", mt: 2.2, fontSize: "13px", pt: "2px", pb: "2px", textTransform: "capitalize"
                }} onClick={(e) => handleClick("workspace_update", !controls.infoButton)}>
                    Info
                </Button>
            </Box>

            <Modal
                open={open}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <Box sx={{
                    position: 'absolute',
                    top: '40%',
                    left: '50%',
                    transform: 'translate(-50%, -50%)',
                    width: '750px', height: '400',
                    bgcolor: '#333',
                    boxShadow: 24, borderRadius: '5px',
                    border: '1px solid #333'
                }}>
                    <Box sx={{ background: "#333333", color: "#dfdfdf", position: "relative" }}>
                        <Typography id="modal-modal-title" variant="h6" component="h2" sx={{ ml: 1.5, mr: .5, mt: 1, mb: 1, fontSize: "15px" }}>
                            <span className='text-green'>MARKET -</span> INFO
                            <CloseIcon onClick={handleClose} sx={{ float: "right", cursor: "pointer" }} />
                        </Typography>
                    </Box>

                    <Paper sx={{ width: '100%', overflow: 'hidden', background: "#454545" }}>
                        <Grid container>
                            <Grid xs={6} sx={{ background: "#454545", textAlign: "left", borderRight: "0px solid #ddd" }}>
                                <Typography sx={{ color: "#dfdfdf", fontSize: ".7rem", lineHeight: 3, pl: 1 }}>
                                    Census last updated : {lastUpdate}
                                </Typography>
                            </Grid>
                            <Grid xs={6} sx={{ textAlign: "right" }}>
                                <Box sx={{ textAlign: "center", mt: .60, mr: -13 }}>
                                    <Chip variant="outlined" size="small" color="success" label={capacity} sx={{ color: "#dfdfdf" }} />
                                    <Chip variant="outlined" size="small" color="success" label={available} sx={{ ml: 1, color: "#dfdfdf" }} />
                                </Box>
                            </Grid>
                        </Grid>
                        <TableContainer component={Paper} sx={{ maxHeight: 350, borderRadius: 0, borderTopLeftRadius: "0px !important;" }}>
                            <Table stickyHeader>
                                {(jsonData === null &&
                                    <>
                                        <TableHead>
                                            <TableRow sx={{ background: "#6b6b6a" }}>
                                                <TableCell ><Skeleton /></TableCell>
                                            </TableRow>
                                            <TableRow sx={{ background: "#6b6b6a" }}>
                                                <TableCell ><Skeleton /></TableCell>
                                            </TableRow>
                                            <TableRow sx={{ background: "#6b6b6a" }}>
                                                <TableCell ><Skeleton /></TableCell>
                                            </TableRow>
                                            <TableRow sx={{ background: "#6b6b6a" }}>
                                                <TableCell ><Skeleton /></TableCell>
                                            </TableRow>
                                            <TableRow sx={{ background: "#6b6b6a" }}>
                                                <TableCell ><Skeleton /></TableCell>
                                            </TableRow>
                                            <TableRow sx={{ background: "#6b6b6a" }}>
                                                <TableCell ><Skeleton /></TableCell>
                                            </TableRow>
                                        </TableHead>
                                        <Skeleton />

                                    </>
                                )}
                                {(jsonData !== null &&
                                    <>
                                        <TableHead>
                                            <TableRow sx={{ background: "#6b6b6a" }}>
                                                <TableCell align='left' sx={{ pt: "6px", pb: "6px", color: "#fff", borderRight: "1px solid #ddd", zIndex: 99999, background: "#6b6b6a" }}>PROPERTY NAME</TableCell>
                                                <TableCell align='center' sx={{ pt: "6px", pb: "6px", color: "#fff", background: "#6b6b6a" }}>AVAILABLE ROOMS</TableCell>
                                            </TableRow>
                                        </TableHead>
                                    </>
                                )}

                                {/* { (jsonData.data &&  */}
                                <TableBody sx={{ minHeight: "800px", height: "800px" }}>

                                    {(jsonData !== null && jsonData.data.property_mix.map((item, index) => (
                                        <TableRow
                                            key={index}
                                            sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                        >

                                            <TableCell align="left" sx={{ pt: "8px", pb: "8px", borderRight: "1px solid #ddd", textTransform: "capitalize" }}>{item.prop_name.toLowerCase()}</TableCell>
                                            <TableCell align="center" sx={{ pt: "8px", pb: "8px", }}>{item.capacity}</TableCell>
                                        </TableRow>
                                    ))
                                    )}
                                </TableBody>
                                {/* )} */}
                            </Table>
                        </TableContainer>
                    </Paper>

                </Box>
            </Modal>

        </React.Fragment>
    )
}

export default InfoButton