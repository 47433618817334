import { KeyboardDoubleArrowRight as KeyboardDoubleArrowRightIcon } from "@mui/icons-material/";
import {
    Divider,
    Grid,
    IconButton,
    Menu,
    MenuItem,
    Tooltip,
    Accordion,
    AccordionSummary,
    AccordionDetails
} from "@mui/material/";
import React, { useState } from "react";
import { useSelector } from "react-redux";
import ReportMenusList from "./ReportMenusList";

//Global custom theme configuration
import BookOutlinedIcon from "@mui/icons-material/BookOutlined";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";

import {CustomSVGIcon} from "../../components/layout/dashboard-ui"

const reportsMenu_propertyRevenueInt = [
    {
        label: "365 Day Pace & Pickup",
        axn: "365dayPacePickup",
        icon: (
            <KeyboardDoubleArrowRightIcon
                fontSize="small"
                sx={{ mr: 1, color: "green.main" }}
            />
        ),
    },
    {
        label: "BI Sensitivity Analysis",
        axn: "BiSensitivityAnalysis",
        // icon: <ArrowForwardRoundedIcon fontSize='small' color='secondary'  sx={{ mr: 1, color: 'green.main' }} />
        icon: (
            <KeyboardDoubleArrowRightIcon
                fontSize="small"
                sx={{ mr: 1, color: "green.main" }}
            />
        ),
    },
    {
        label: "Daily Pace by Segment",
        axn: "DailyPacebySegment",
        icon: (
            <KeyboardDoubleArrowRightIcon
                fontSize="small"
                sx={{ mr: 1, color: "green.main" }}
            />
        ),
    },
    {
        label: "KPI Summary Report",
        axn: "kpiSummaryReport",
        icon: (
            <KeyboardDoubleArrowRightIcon
                fontSize="small"
                sx={{ mr: 1, color: "green.main" }}
            />
        ),
    },
    {
        label: "Market Code Report",
        axn: "MarketCodeReport",
        icon: (
            <KeyboardDoubleArrowRightIcon
                fontSize="small"
                sx={{ mr: 1, color: "green.main" }}
            />
        ),
    },

    {
        label: "Pickup By Segment",
        axn: "PickupBySegment",
        icon: (
            <KeyboardDoubleArrowRightIcon
                fontSize="small"
                sx={{ mr: 1, color: "green.main" }}
            />
        ),
    },
    {
        label: "RevPAR Index Tracker",
        axn: "RevPARIndexTracker",
        icon: (
            <KeyboardDoubleArrowRightIcon
                fontSize="small"
                sx={{ mr: 1, color: "green.main" }}
            />
        ),
    },
    {
        label: "Segment Production",
        axn: "SegmentProduction",
        icon: (
            <KeyboardDoubleArrowRightIcon
                fontSize="small"
                sx={{ mr: 1, color: "green.main" }}
            />
        ),
    },
];

const reportsMenu_uploads = [
    {
        label: "Budget",
        axn: "Budget",
        icon: (
            <KeyboardDoubleArrowRightIcon
                fontSize="small"
                sx={{ mr: 1, color: "green.main" }}
            />
        ),
    },
    {
        label: "Documents",
        axn: "Documents",
        icon: (
            <KeyboardDoubleArrowRightIcon
                fontSize="small"
                sx={{ mr: 1, color: "green.main" }}
            />
        ),
    },
    {
        label: "User Forecast",
        axn: "UserForecast",
        icon: (
            <KeyboardDoubleArrowRightIcon
                fontSize="small"
                sx={{ mr: 1, color: "green.main" }}
            />
        ),
    },
];

const reportsMenu_resources = [
    {
        label: "Opera Scheduling Guide",
        axn: "OperaSchedulingGuide",
        icon: (
            <KeyboardDoubleArrowRightIcon
                fontSize="small"
                sx={{ mr: 1, color: "green.main" }}
            />
        ),
    },
    {
        label: "Release Notes",
        axn: "ReleaseNotes",
        icon: (
            <KeyboardDoubleArrowRightIcon
                fontSize="small"
                sx={{ mr: 1, color: "green.main" }}
            />
        ),
    },
    {
        label: "User Guide",
        axn: "UserGuide",
        icon: (
            <KeyboardDoubleArrowRightIcon
                fontSize="small"
                sx={{ mr: 1, color: "green.main" }}
            />
        ),
    },
];

const reportsMenu_market = [
    {
        label: "Segmented Market Summary",
        axn: "SegmentedMarketSummary",
        icon: (
            <KeyboardDoubleArrowRightIcon
                fontSize="small"
                sx={{ mr: 1, color: "green.main" }}
            />
        ),
    },
];

const reportsMenu_propertyDecisions = [
    {
        label: "Daily Rate Push Status",
        axn: "DailyRatePushStatus",
        icon: (
            <KeyboardDoubleArrowRightIcon
                fontSize="small"
                sx={{ mr: 1, color: "green.main" }}
            />
        ),
    },
];

const ReportsMenu = () => {
    const { app, auth } = useSelector((state) => state);
    const [expanded, setExpanded] = useState("panel1");
    let reportsList =
        auth.profile["application_config"].report_settings.report_access_settings ||
        [];
    reportsList.sort((a, b) => (a.report_category > b.report_category ? 1 : -1));
    let categoryList = [];
    // let reportsListNew = reportsList.filter((item) => item.report_category);
    reportsList.map(
        (itm, index) =>
            itm.access_level !== "disabled" &&
            categoryList.indexOf(itm.report_category) === -1 &&
            categoryList.push(itm.report_category)
    );
    categoryList = categoryList.filter(Boolean);
    //categoryList.sort((a, b) => (a.report_category < b.report_category) ? 1 : -1)

    // console.log(JSON.stringify(categoryList));

    const [anchorElReports, setAnchorElReports] = React.useState(null);
    const handleOpenReportsMenu = (event) => {
        setAnchorElReports(event.currentTarget);
        // debugger;
    };
    const handleCloseReportsMenu = (id, label) => {
        setAnchorElReports(false);
    };

    const handleChange = (panel) => (event, isExpanded) => {
        setExpanded(isExpanded ? panel : false);
    }

    return (
        <div>
            <Tooltip title="Reports">
                <IconButton className="icon-thin-dark icon-btn" onClick={handleOpenReportsMenu}>
                    {/* <AssessmentOutlinedIcon fontSize='large' color='white' alt="Reports" /> */}

                    {/* <BookOutlinedIcon fontSize="large" color="grey" alt="Reports" /> */}
                   
                    <CustomSVGIcon icon="icon-reports" path="/assets/img/noteBook.svg" width="25" height="25" className="report-icon"  />
                </IconButton>
            </Tooltip>
            <Menu
                sx={{ mt: 2.5, zIndex: 1100 }}
                id="menu-reports"
                anchorEl={anchorElReports}
                anchorOrigin={{
                    vertical: "top",
                    horizontal: "right",
                }}
                keepMounted
                transformOrigin={{
                    vertical: "top",
                    horizontal: "right",
                }}
                open={Boolean(anchorElReports)}
                onClose={handleCloseReportsMenu}
                PaperProps={{
                    elevation: 0,
                    sx: {
                        overflow: "visible",
                        filter: "drop-shadow(0px 2px 8px rgba(0,0,0,0.32))",
                        mt: 1.5,
                        "& .MuiAvatar-root": {
                            width: 32,
                            height: 32,
                            // ml: -0.5,
                            mr: 1,
                            right: -10,
                        },
                        "&:before": {
                            content: '""',
                            display: "block",
                            position: "absolute",
                            top: 0,
                            right: 8,
                            width: 10,
                            height: 10,
                            bgcolor: "background.paper",
                            transform: "translateY(-50%) rotate(45deg)",
                            zIndex: 9992,
                        },
                    },
                }}
            >
                <Grid
                    container
                    spacing={{ xs: 2 }}
                    columns={{ xs: 6 }}
                    sx={{ pl: 0, pr: 0 }}
                >
                    {/* property revenue  */}
                    {/* <Grid item xs={2} sm={4} md={3}>
            <MenuItem
              sx={{ cursor: "unset", ":hover": { background: "unset" } }}
            >
              <h3
                style={{
                  margin: 0,
                  color: "#1976d2",
                  fontSize: 13,
                  marginLeft: 0,
                  fontWeight: "bold",
                }}
              >
                PROPERTY REVENUE INTELLIGENCE
              </h3>
            </MenuItem>{" "}
            <Divider />
            <ReportMenusList
              onMenuClicked={handleCloseReportsMenu}
              categoryType="Property - Revenue Intelligence"
            />
          </Grid> */}
                    {/* <Grid item xs={2} sm={4} md={3}>
            <MenuItem
              sx={{ cursor: "unset", ":hover": { background: "unset" } }}
            >
              <h3
                style={{
                  margin: 0,
                  color: "#1976d2",
                  fontSize: 13,
                  marginLeft: 0,
                  fontWeight: "bold",
                }}
              >
                UPLOADS
              </h3>
            </MenuItem>
            <Divider />
            <ReportMenusList
              onMenuClicked={handleCloseReportsMenu}
              categoryType="Uploads"
            />
            <MenuItem
              sx={{ mt: 1, cursor: "unset", ":hover": { background: "unset" } }}
            >
              <h3
                style={{
                  margin: 0,
                  color: "#1976d2",
                  fontSize: 13,
                  marginLeft: 0,
                  fontWeight: "bold",
                }}
              >
                RESOURCES
              </h3>
            </MenuItem>{" "}
            <Divider />
            <ReportMenusList categoryType="Resources" />
          </Grid> */}
                    <Grid item xs={2} sm={4} md={3}>
                        {/* <MenuItem
              sx={{ cursor: "unset", ":hover": { background: "unset" } }}
            >
              <h3
                style={{
                  margin: 0,
                  color: "#1976d2",
                  fontSize: 13,
                  marginLeft: 0,
                  fontWeight: "bold",
                }}
              >
                MARKET
              </h3>
            </MenuItem>{" "} */}
                        {/* <Divider /> */}
                        {/* <ReportMenusList
              onMenuClicked={handleCloseReportsMenu}
              categoryType="Market"
            /> */}
                        {/* <MenuItem
              sx={{ mt: 1, cursor: "unset", ":hover": { background: "unset" } }}
            >
              <h3
                style={{
                  margin: 0,
                  color: "#1976d2",
                  fontSize: 13,
                  marginLeft: 0,
                  fontWeight: "bold",
                }}
              >
                PROPERTY DECISIONS
              </h3>
            </MenuItem> */}
                        {/* <Divider /> */}
                        {/* <ReportMenusList categoryType="Property - Decisions" /> */}
                        <div className="nootbook-menu">
                            <Accordion expanded={expanded === 'panel1'} onChange={handleChange('panel1')}>
                                <AccordionSummary
                                    expandIcon={<ExpandMoreIcon />}
                                    aria-controls="panel1bh-content"
                                    id="panel1bh-header"
                                >
                                    <div className="heading"><span className="reports"></span>REPORTS</div>
                                </AccordionSummary>
                                <AccordionDetails className="accordian-details-container">
                                    <ReportMenusList
                                        onMenuClicked={handleCloseReportsMenu}
                                        categoryType="Property - Revenue Intelligence"
                                    />
                                </AccordionDetails>
                            </Accordion>
                            <Accordion expanded={expanded === 'panel2'} onChange={handleChange('panel2')}>
                                <AccordionSummary
                                    expandIcon={<ExpandMoreIcon />}
                                    aria-controls="panel1bh-content"
                                    id="panel1bh-header"
                                >
                                    <div className="heading"><span className="uploads"></span>UPLOADS</div>

                                </AccordionSummary>
                                <AccordionDetails className="accordian-details-container">
                                    <ReportMenusList
                                        onMenuClicked={handleCloseReportsMenu}
                                        categoryType="Uploads"
                                    />
                                </AccordionDetails>
                            </Accordion>
                            <Accordion expanded={expanded === 'panel3'} onChange={handleChange('panel3')}>
                                <AccordionSummary
                                    expandIcon={<ExpandMoreIcon />}
                                    aria-controls="panel1bh-content"
                                    id="panel1bh-header"
                                >
                                    <div className="heading"><span className="resources"></span>RESOURCES</div>
                                </AccordionSummary>
                                <AccordionDetails className="accordian-details-container">
                                    <ReportMenusList categoryType="Resources" />
                                </AccordionDetails>
                            </Accordion>
                            <Accordion expanded={expanded === 'panel4'} onChange={handleChange('panel4')}>
                                <AccordionSummary
                                    expandIcon={<ExpandMoreIcon />}
                                    aria-controls="panel1bh-content"
                                    id="panel1bh-header"
                                >
                                    <div className="heading"><span className="logs"></span>LOGS</div>

                                </AccordionSummary>
                                <AccordionDetails className="accordian-details-container">
                                    <ReportMenusList onMenuClicked={handleCloseReportsMenu} categoryType="Property - Decisions" />
                                </AccordionDetails>
                            </Accordion>
                        </div>
                    </Grid>
                </Grid>
            </Menu>
        </div>
    );
};

export default ReportsMenu;
