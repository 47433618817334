
import {Box, Switch} from '@mui/material'
import {useDispatch, useSelector} from 'react-redux';
import { updateAppState } from '../../../../actions/appstate';

const UnconstrainedPicker = () => {
    const controls = useSelector(state => state.app.current_state.app_state.workspace_controls);
    const dispatch = useDispatch();

    const handleClick = (type,value)=>{
        const updateTypes = [type];
        controls.unconstrained = value;
        debugger;
        // dispatch(updateAppState(updateTypes, {app_state: {workspace_controls: controls}}));
        dispatch(updateAppState(type, {
            app_state: {
                workspace_controls: { ...controls },
                data_load: true
            },
            ui_state: { showspinner: true }
        }));
    }

    return(
        <Box className='switch-box'>
            <Box className='switch-label' sx={{display:'block', fontSize:'75%', fontWeight:'bold'}}>UNCONSTRAINED</Box>
            <Switch sx={{ml:0}} 
                checked={controls.unconstrained} 
                onChange={(e) => {handleClick("unconToggle", !controls.unconstrained)}} 
            />
        </Box>
        )

}

export default UnconstrainedPicker