import moment from "moment";
import { checkProperValue, convertToPercent, deepCopy, getCorrectValue, round } from "../../../app/util";
import { useDataSummary } from "../../Tile/kpiService";
import dataService from "../../../services/data.service";
import { useSelector } from "react-redux";
import marketSegmentedJson from '../marketSegmented.json';


// function to join market proeject for segmented view 

export function getMarketForecast(date, segment,app_state) {

    let marketForcastData = (dataService.getSeriesById("MarketForecastSegmented").data) ? dataService.getSeriesById("MarketForecastSegmented").data.data : [];
    // console.log(marketForcastData.length);
    // debugger;
    let resultFilter = (marketForcastData.length > 0) ? marketForcastData.filter((item) =>
        (moment(item.stay_date).format('YYYY-MM-DD') === moment(date).format('YYYY-MM-DD'))) 
        : []
    let otbData = [];
    if (!resultFilter.length) {
        /** get from OTB */
        let marketOtb = dataService.getSeriesById("MarketARISegmented").data.data;
        let marketOtbData  = marketOtb.filter((itm) => itm.index.date === date)
        if (marketOtbData.length > 0) {
            let segmentName = "MarketSegment_"+segment;
            let finalData = (segmentName === "MarketSegment_Total") ? marketOtbData[0].MarketTotal : marketOtbData[0][segmentName];
            if(finalData){
                otbData.push({
                    market_segment: segment,
                    MarketTotal: marketOtbData[0].MarketTotal,
                    stay_date: marketOtbData[0].index.date,
                    forecast_rooms: finalData.primary.Rooms,
                    forecast_adr: finalData.primary.ADR,
                    forecast_revpar: finalData.primary.RevPAR,
                    forecast_revenue: finalData.primary.Revenue,
                    forecast_occ: finalData.primary.Occ,
                    forecast_sellrate: finalData.primary.SellRate
                })
            }

        }
    } else {
        let marketForcast = (dataService.getSeriesById("MarketForecastSegmented").data) ? dataService.getSeriesById("MarketForecastSegmented").data.data : [];
        let marketForecastData  = marketForcast.filter((itm) => (moment(itm.stay_date).format('YYYY-MM-DD') === moment(date).format('YYYY-MM-DD')) && (itm.market_segment === segment))

        let marketForcastNonSegmented = dataService.getSeriesById("MarketForecast").data.data
        let marketForcastNonSegmentedData  = marketForcastNonSegmented.filter((ds) => (moment(ds.stay_date).format('YYYY-MM-DD') === moment(date).format('YYYY-MM-DD')))
            let segmentName = "MarketSegment_"+segment;
            let finalData = (segmentName === "MarketSegment_Total") ? marketForcastNonSegmentedData[0] : marketForecastData[0];
            let unconstrained = app_state.workspace_controls.unconstrained;
            let rooms = (unconstrained) ? finalData.unconstrained_forecast_rooms : finalData.constrained_forecast_rooms;
            let occ = (unconstrained) ? finalData.unconstrained_forecast_occ : finalData.constrained_forecast_occ;
            let adr = (unconstrained) ? finalData.unconstrained_forecast_adr : finalData.constrained_forecast_adr;
            let revpar = (unconstrained) ? finalData.unconstrained_forecast_revpar : finalData.constrained_forecast_revpar;
            let revenue = (unconstrained) ? finalData.unconstrained_forecast_revenue : finalData.constrained_forecast_revenue;
            let sellrate = 0;//(unconstrained) ? finalData.unconstrained_forecast_adr : finalData.constrained_forecast_adr;
            otbData.push({
                market_segment: segment,
                stay_date: finalData.sdly_date,
                forecast_rooms: rooms,
                forecast_adr: adr,
                forecast_revpar: revpar,
                forecast_revenue: revenue,
                forecast_occ: occ,
                forecast_sellrate: sellrate,
            })
    }
    return otbData;
}


export function getMarketForecastNonSegment(date,app_state) {

    let marketForcastData = (dataService.getSeriesById("MarketForecast").data) ? dataService.getSeriesById("MarketForecast").data.data : [];
    console.log(marketForcastData.length);
    // debugger;
    let resultFilter = (marketForcastData.length > 0) ? marketForcastData.filter((item) =>
        (moment(item.stay_date).format('YYYY-MM-DD') === moment(date).format('YYYY-MM-DD'))) 
        : []
    let otbData = [];
    if (!resultFilter.length) {
        /** get from OTB */
        let marketOtb = dataService.getSeriesById("MarketARI").data.data;
        let marketOtbData  = marketOtb.filter((itm) => itm.index.date === date)
        if (marketOtbData.length > 0) {
                otbData.push({
                    stay_date: marketOtbData[0].index.date,
                    forecast_rooms: marketOtbData[0].MarketTotal.primary.Rooms,
                    forecast_adr: marketOtbData[0].MarketTotal.primary.ADR,
                    forecast_revpar: marketOtbData[0].MarketTotal.primary.RevPAR,
                    forecast_revenue: marketOtbData[0].MarketTotal.primary.Revenue,
                    forecast_occ: marketOtbData[0].MarketTotal.primary.Occ,
                    forecast_sellrate: marketOtbData[0].MarketTotal.primary.SellRate
                })
        }
    } else {
        let marketForcast = (dataService.getSeriesById("MarketForecast").data) ? dataService.getSeriesById("MarketForecast").data.data : [];
        let marketForecastData  = marketForcast.filter((itm) => (moment(itm.stay_date).format('YYYY-MM-DD') === moment(date).format('YYYY-MM-DD')))
        let finalData = marketForecastData[0];
        // debugger;
        let unconstrained = app_state.workspace_controls.unconstrained;
            let rooms = (unconstrained) ? finalData.unconstrained_forecast_rooms : finalData.constrained_forecast_rooms;
            let occ = (unconstrained) ? finalData.unconstrained_forecast_occ : finalData.constrained_forecast_occ;
            let adr = (unconstrained) ? finalData.unconstrained_forecast_adr : finalData.constrained_forecast_adr;
            let revpar = (unconstrained) ? finalData.unconstrained_forecast_revpar : finalData.constrained_forecast_revpar;
            let revenue = (unconstrained) ? finalData.unconstrained_forecast_revenue : finalData.constrained_forecast_revenue;
            let sellrate = 0;//(unconstrained) ? finalData.unconstrained_forecast_adr : finalData.constrained_forecast_adr;
            otbData.push({
                stay_date: finalData.stay_date,
                forecast_rooms: rooms,
                forecast_adr: adr,
                forecast_revpar: revpar,
                forecast_revenue: revenue,
                forecast_occ: occ,
                forecast_sellrate: sellrate,
            })
    }
    return otbData;
}




export function concatForcastDataSegmented(app_state) {
    let otbData = [];
    let otb = dataService.getSeriesById("MarketARISegmented").data;
    // let marketForcastData = (dataService.getSeriesById("MarketForecastSegmented").data) ? dataService.getSeriesById("MarketForecastSegmented").data.data : [];

    // let otb = marketSegmentedJson;
    let as_of_date = app_state.as_of_date;
    let startDate = app_state.primary_stay_range.date_from;
    let endDate = app_state.primary_stay_range.date_until;
    let beforeDatesCount = Math.abs(moment.duration(moment(startDate).diff(moment(as_of_date))).asDays());
    const getPastOtbData = otb.data.slice(0, beforeDatesCount);
    // debugger;
    let ingonreKeys = ['MarketTotalx', 'index'];
    for (let n = 0; n < getPastOtbData.length; n++) {
        let keysArr = Object.keys(getPastOtbData[n]).sort();
        keysArr.forEach(key => {
            if (!ingonreKeys.includes(key)) {
                otbData.push({
                    market_segment: key,
                    MarketTotal: getPastOtbData[n].MarketTotal,
                    stay_date: getPastOtbData[n]['index'].date,
                    forecast_rooms: getPastOtbData[n][key]['primary_noncomm'].Rooms,
                    forecast_adr: getPastOtbData[n][key]['primary_noncomm'].ADR,
                    forecast_revpar: getPastOtbData[n][key]['primary_noncomm'].RevPAR,
                    forecast_revenue: getPastOtbData[n][key]['primary_noncomm'].Revenue,
                    forecast_occ: getPastOtbData[n][key]['primary_noncomm'].Occ,
                    forecast_sellrate: getPastOtbData[n][key]['primary_noncomm'].SellRate
                })
            }
        });
    }
    let n = Math.abs(moment.duration(moment(as_of_date).diff(moment(endDate))).asDays()) + 1;
    let marketForcastData = (dataService.getSeriesById("MarketForecastSegmented").data) ? dataService.getSeriesById("MarketForecastSegmented").data.data : [];
    marketForcastData = marketForcastData.sort((a, b) => (a.market_segment > b.market_segment ? 1 : -1))
    // debugger; 
    // let marketTotal =  {
    //     "MarketTotal": {
    //         "primary": {
    //             "ADR": 339.23381033867116,
    //             "Occ": 0.6831460674157304,
    //             "RevPAR": 231.7462434673169,
    //             "Revenue": 721889.5484006922,
    //             "Rooms": 2128.0,
    //             "SellRate": 391.875,
    //             "MedianSellRate": 384.0,
    //             "TCOcc": null
    //         },
    //     }
    let otbTotal = dataService.getSeriesById("MarketARI").data;
    // debugger;
    marketForcastData.map((itm, idx) => {

    })



    let marketForcastFinalData = otbData.concat(marketForcastData);
    return marketForcastFinalData;
}

// export function concatForcastData(app_state) {
//     let otbData = [];
//     let as_of_date = app_state.as_of_date;
//     let otb = dataService.getSeriesById("MarketARI").data;
//     console.log(otb);
//      marketOtb = otb.filter((item,index)=> moment(item.index.date).isBefore(as_of_date));
//     return marketOtb;
// }



// function to join forcast and otp data for market tile
export function concatForcastData(as_of_date,marketAriData,MarketForecastData) {
    // debugger;
    let otbData = [];
    let otb = marketAriData;//dataService.getSeriesById("MarketARI").data;
    // let as_of_date = as_of_date;
    // let startDate = app_state.primary_stay_range.date_from;
    // let endDate = app_state.primary_stay_range.date_until;
    // let beforeDatesCount = Math.abs(moment.duration(moment(startDate).diff(moment(as_of_date))).asDays());
    // const getPastOtbData = otb.slice(0, beforeDatesCount);
   let  getPastOtbData = marketAriData.filter((item,index)=> moment(item.index.date).isBefore(as_of_date));
    let size = getPastOtbData.length;
    for (let n = 0; n < size; n++) {
        let marketPrimary = getPastOtbData[n]['MarketTotal']['primary'];
        let otbDate = getPastOtbData[n]['index'].date;
        otbData.push({
            stay_date: otbDate,
            forecast_rooms: marketPrimary.Rooms,
            forecast_adr: marketPrimary.ADR,
            forecast_revpar: marketPrimary.RevPAR,
            forecast_revenue: marketPrimary.Revenue,
            forecast_occ: marketPrimary.Occ,
            forecast_sellrate: marketPrimary.SellRate
        })
    }
    // let n = Math.abs(moment.duration(moment(as_of_date).diff(moment(endDate))).asDays()) + 1;
    let marketForcastData = (MarketForecastData) ? MarketForecastData : [];
    let marketForcastFinalData = otbData.concat(marketForcastData);
    return marketForcastFinalData;
}


export function addMarketSdlyData(app_state) {
    let dataMarketSdlyDba = dataService.getSeriesById("MarketSdlyDba").data.data;
    let as_of_date = app_state.as_of_date;
    let startDate = app_state.primary_stay_range.date_from;
    let dataKey = "HotelTotal";
    let beforeDatesCount = Math.abs(moment.duration(moment(startDate).diff(moment())).asDays() + 1);
    dataMarketSdlyDba = dataMarketSdlyDba.slice(beforeDatesCount, (200 + beforeDatesCount + 2));

    return dataMarketSdlyDba;

}
export function getMarketDbaData(data, columns, app_state) {
    // debugger;
    let as_of_date = app_state.as_of_date;
    const { sdlyOn, pickUpType } = app_state.workspace_controls;
    let startDate = app_state.primary_stay_range.date_from;
    let dataKey = "MarketTotal";
    let setup_data = [];
    let idx = 0;
    let beforeDatesCount = Math.abs(moment.duration(moment(startDate).diff(moment())).asDays() + 1);
    // data = data.slice(beforeDatesCount, (200 + beforeDatesCount + 2));
    data.sort((a, b) => a.Index.dba - b.Index.dba);
    data = data.slice(0, app_state.workspace_controls.dba);
    // debugger;  

    data.map((item, index) => {
        let data_columns = [];
        columns.map(col => {
            let dataObj = { id: col.id };
            if (col.type === 'dateColumn') {
                dataObj['dba'] = item.Index.dba;
            }
            if (col.type === 'dataColumn') {
                let { mainKey } = col;
                col.subcolumns.map(key => {
                    if (mainKey === "primary") {
                        if (key.value === "Occ") {
                            dataObj[key.value] = convertToPercent(item[dataKey][mainKey][key.value]);
                        } else if (key.value === "SellRate") {
                            let sellRate = (typeof item[dataKey][mainKey].sellRate !== 'undefined') ? round(item[dataKey][mainKey].sellRate) : 0;
                            dataObj[key.value] = sellRate;//item[dataKey][mainKey].sellRate;
                        } else if (key.value === "Rooms") {
                            dataObj[key.value] = Math.round(item[dataKey][mainKey][key.value]);
                        } else {
                            dataObj[key.value] = round(item[dataKey][mainKey][key.value]);
                        }
                        // } else if (mainKey === "sdly_dba_comp" && sdlyOn) {
                    } else if (mainKey === "sdly_dba_comp") {
                        // let dataMarketSdly = addMarketSdlyData(app_state);
                        // let k = key.value;
                        // if (key.value === "Occ") {
                        //     dataObj[key.value] = convertToPercent(dataMarketSdly[idx][dataKey].primary[key.value]);
                        // } else if (key.value === "SellRate") {
                        //     let sellRate = (typeof dataMarketSdly[idx][dataKey].primary[key.value] !== 'undefined' && dataMarketSdly[idx][dataKey].primary[key.value] > 0) ? round(dataMarketSdly[idx][dataKey].primary[key.value]) : 0;
                        //     dataObj[key.value] = sellRate;//item[dataKey][mainKey].sellRate;
                        // } else if (key.value === "Rooms") {
                        //     dataObj[key.value] = Math.round(dataMarketSdly[idx][dataKey].primary[key.value]);
                        // } else {
                        //     dataObj[key.value] = round(dataMarketSdly[idx][dataKey].primary[key.value]);
                        // }
                        if (key.value === "Occ") {
                            dataObj[key.value] = convertToPercent(item[dataKey]["sdly"][key.value]);
                        } else if (key.value === "SellRate") {
                            let sellRate = (typeof item[dataKey]["sdly"].sellRate !== 'undefined') ? round(item[dataKey]["sdly"].sellRate) : 0;
                            dataObj[key.value] = sellRate;//item[dataKey]["sdly"].sellRate;
                        } else if (key.value === "Rooms") {
                            dataObj[key.value] = Math.round(item[dataKey]["sdly"][key.value]);
                        } else {
                            dataObj[key.value] = round(item[dataKey]["sdly"][key.value]);
                        }


                    } else {
                        let dataMarketSdly = addMarketSdlyData(app_state);
                        if (key.value === "Occ") {
                            dataObj[key.value] = convertToPercent(item[dataKey]['primary'][key.value] - item[dataKey]['sdly'][key.value]);

                        } else if (key.value === "SellRate") {
                            let diffRate = item[dataKey]['primary'][key.value] - item[dataKey]['sdly'][key.value]
                            let sellRate = (typeof diffRate !== 'undefined' && diffRate > 0) ? round(diffRate) : 0;
                            dataObj[key.value] = sellRate;//item[dataKey][mainKey].sellRate;
                        } else if (key.value === "Rooms") {
                            dataObj[key.value] = Math.round(item[dataKey]['primary'][key.value] - item[dataKey]['sdly'][key.value]);

                        } else {
                            dataObj[key.value] = round(item[dataKey]['primary'][key.value] - item[dataKey]['sdly'][key.value]);

                        }
                    }
                })
            }
            data_columns.push(dataObj);
        })
        setup_data.push(data_columns);
        idx++;
    })
    return setup_data;
}





// function to get Market data for Intellimerge 
export const getMarketDataInt = (mainKey, id, tileName, key, index, dataKey, auth, app_state, tileData,marketForecastOtb) => {
    let marketData = tileData;// deepCopy(dataService.getSeriesById("MarketARI").data.data);
    let dataObj = {};
    let keyValue = key.value;
    let marketDataKey = marketData[dataKey];
    let marketPrimary = marketDataKey["primary"];
    let marketPickup = marketDataKey["pickup"];
    let marketSdly = marketDataKey["sdly"];
    let marketSdlyActual = marketDataKey["sdly_actual"];

    if (marketDataKey) {
        if (mainKey === "marketotbpickup") {
            if (keyValue == 'Occ') {
                dataObj[keyValue] = convertToPercent(marketPrimary[keyValue] - marketPickup[keyValue]);

            } else if (keyValue == 'ADR') {
                let rev = round(marketPrimary['Revenue'] - marketPickup['Revenue']);
                let rooms = round(marketPrimary['Rooms'] - marketPickup['Rooms']);
                // debugger;
                let adrValue = round(rev / rooms);
                dataObj[keyValue] = (isNaN(adrValue)) ? 0.00 : adrValue;
            } else if (keyValue == 'Revenue') {
                dataObj[keyValue] = round(marketPrimary['Revenue'] - marketPickup['Revenue'])
            } else {
                dataObj[keyValue] = round(marketPrimary[keyValue] - marketPickup[keyValue])
            }
        } else if (mainKey === "sdly_diff_sdowly") {
            if (keyValue == 'Occ') {
                dataObj[keyValue] = convertToPercent(marketPickup[keyValue] - marketSdly[keyValue]);

            } else if (keyValue == 'ADR') {
                let rev = round(marketPickup['Revenue'] - marketSdly['Revenue']);
                let rooms = round(marketPickup['Rooms'] - marketSdly['Rooms']);
                // debugger;
                let adrValue = round(rev / rooms);
                dataObj[keyValue] = (isNaN(adrValue)) ? 0.00 : adrValue;
            } else if (keyValue == 'Revenue') {
                dataObj[keyValue] = round(marketPickup['Revenue'] - marketSdly['Revenue'])
            } else {
                dataObj[keyValue] = round(marketPickup[keyValue] - marketSdly[keyValue])
            }
        } else if (mainKey === "sdly_variance_sdowly") {
            if (keyValue == 'Occ') {
                let occVal = (marketPrimary[keyValue] - marketSdly[keyValue]) / marketSdly[keyValue];
                dataObj[keyValue] = convertToPercent(checkProperValue(occVal)) + "%";
            } else if (keyValue == 'ADR') {
                let adr1 = marketPrimary['Revenue'] / marketPrimary['Rooms'];
                let adr2 = marketSdly['Revenue'] / marketSdly['Rooms'];
                let adrValue = ((round(adr1) - round(adr2)) / adr2) * 100
                dataObj[keyValue] = checkProperValue(adrValue) + "%";
            } else if (keyValue == 'Revenue') {
                let revenueVal = ((marketPrimary['Revenue'] - marketSdly['Revenue']) / marketSdly['Revenue']) * 100;
                dataObj[keyValue] = + checkProperValue(revenueVal) + "%";
            } else {
                let calVal = ((marketPrimary[keyValue] - marketSdly[keyValue]) / marketSdly[keyValue]) * 100;
                dataObj[keyValue] = checkProperValue(calVal) + "%";
            }
        } else if (mainKey === "sdly_diff_market_last_year_actuals") {
            if (keyValue == 'Occ') {
                // debugger;
                dataObj[keyValue] = convertToPercent(marketSdly[keyValue] - marketSdlyActual[keyValue]);

            } else if (keyValue == 'ADR') {
                let rev = round(marketSdly['Revenue'] - marketSdlyActual['Revenue']);
                let rooms = round(marketSdly['Rooms'] - marketSdlyActual['Rooms']);
                let adrValue = round(rev / rooms);
                dataObj[keyValue] = (isNaN(adrValue)) ? 0.00 : adrValue;
            } else if (keyValue == 'Revenue') {
                dataObj[keyValue] = round(marketSdly['Revenue'] - marketSdlyActual['Revenue'])
            } else {
                dataObj[keyValue] = round(marketSdly[keyValue] - marketSdlyActual[keyValue])
            }
        } else if (mainKey === "sdly_variance_market_last_year_actuals") {
            if (keyValue == 'Occ') {
                let occValue = (marketSdly[keyValue] - marketSdlyActual[keyValue]) / marketSdly[keyValue];
                dataObj[keyValue] = checkProperValue(convertToPercent(occValue)) + "%";
            } else if (keyValue == 'ADR') {
                let adr1 = marketSdly['Revenue'] / marketSdly['Rooms'];
                let adr2 = marketSdlyActual['Revenue'] / marketSdlyActual['Rooms'];
                let adrVal = ((round(adr1) - round(adr2)) / adr2) * 100 ;
                // console.log(adr1," <-> ",adr2," ==> ",adrVal," => ",getCorrectValue(adrVal));
                dataObj[keyValue] = getCorrectValue(adrVal) + "%";
            } else if (keyValue == 'Revenue') {
                let revenueValue = ((round(marketSdly['Revenue']) - round(marketSdlyActual['Revenue'])) / round(marketSdlyActual['Revenue'])) * 100;
                dataObj[keyValue] = checkProperValue(revenueValue) + "%"
            } else {
                let calVal = ((round(marketSdly[keyValue]) - round(marketSdlyActual[keyValue])) / round(marketSdlyActual[keyValue])) * 100;
                dataObj[keyValue] = checkProperValue(calVal) + "%";
            }
        } else if (mainKey === "market_projected") {
            let market_projected = marketForecastOtb; // (!app_state.workspace_controls.segmented_view) ?   (app_state) : concatForcastDataSegmented(app_state);
            // debugger; 
            if (market_projected) {
                let forcastItem = market_projected;
                if (keyValue === "Occ") {
                    if (typeof forcastItem['forecast_occ'] !== 'undefined' && !isNaN(forcastItem['forecast_occ'])) {
                        dataObj[keyValue] = convertToPercent(forcastItem['forecast_occ']);
                    } else {
                        dataObj[keyValue] = 0;
                    }
                } else if (keyValue === "Rooms") {
                    dataObj[keyValue] = (typeof forcastItem['forecast_rooms'] === "number") ? forcastItem['forecast_rooms'] : 0;
                } else if (keyValue === "ADR") {
                    dataObj[keyValue] = (typeof forcastItem['forecast_adr'] === "number") ? round(forcastItem['forecast_adr']) : parseFloat(0);
                } else if (keyValue === "RevPAR") {
                    if (!isNaN(forcastItem['forecast_revpar'])) {
                        dataObj[keyValue] = round(forcastItem['forecast_revpar']);
                    } else {
                        dataObj[keyValue] = 0;
                    }
                } else if (keyValue === "Revenue") {
                    dataObj[keyValue] = (!isNaN(forcastItem['forecast_revenue'])) ? round(forcastItem['forecast_revenue']) : 0;
                } else if (keyValue === "SellRate") {
                    let sellRate = (forcastItem['forecast_sellrate'] && forcastItem['forecast_sellrate'] !== undefined) ? round(forcastItem['forecast_sellrate']) : parseFloat(0);
                    dataObj[keyValue] = sellRate;
                }
            }
        }
        else {
            if (tileName === "market" && marketData) {

                if (keyValue === "Occ" && tileName === "market") {
                    // console.log(dataKey, " ", mainKey, " ", keyValue);
                    dataObj[keyValue] = convertToPercent(marketDataKey[mainKey][keyValue]);
                } else {
                    dataObj[keyValue] = round(marketDataKey[mainKey][keyValue]);
                }
            }
        }
    }
    return dataObj;
}




// function to get Market data for Intellimerge 
export const getMarketDbaDataInt_old = (mainKey, id, tileName, key, index, dataKey, auth, app_state, ui_state) => {
    let marketDbaData = deepCopy(dataService.getSeriesById("MarketDba").data.data);
    // console.log("Market Dba Data ",marketDbaData);
    let dataObj = {};
    let idx = index;
    if (mainKey === "primary") {
        if (key.value === "Occ") {
            dataObj[key.value] = convertToPercent(marketDbaData[index][dataKey][mainKey][key.value]);
        } else if (key.value === "SellRate") {
            let sellRate = (typeof marketDbaData[index][dataKey][mainKey].sellRate !== 'undefined') ? round(marketDbaData[index][dataKey][mainKey].sellRate) : 0;
            dataObj[key.value] = sellRate;//marketDbaData[index][dataKey][mainKey].sellRate;
        } else if (key.value === "Rooms") {
            dataObj[key.value] = Math.round(marketDbaData[index][dataKey][mainKey][key.value]);
        } else {
            dataObj[key.value] = round(marketDbaData[index][dataKey][mainKey][key.value]);
        }
        // } else if (mainKey === "sdly_dba_comp" && sdlyOn) {
    } else if (mainKey === "sdly_dba_comp") {
        let dataMarketSdly = addMarketSdlyData(app_state);
        let k = key.value;
        if (key.value === "Occ") {
            dataObj[key.value] = convertToPercent(dataMarketSdly[idx][dataKey].primary[key.value]);
        } else if (key.value === "SellRate") {
            let sellRate = (typeof dataMarketSdly[idx][dataKey].primary[key.value] !== 'undefined' && dataMarketSdly[idx][dataKey].primary[key.value] > 0) ? round(dataMarketSdly[idx][dataKey].primary[key.value]) : 0;
            dataObj[key.value] = sellRate;//marketDbaData[index][dataKey][mainKey].sellRate;
        } else if (key.value === "Rooms") {
            dataObj[key.value] = Math.round(dataMarketSdly[idx][dataKey].primary[key.value]);
        } else {
            dataObj[key.value] = round(dataMarketSdly[idx][dataKey].primary[key.value]);
        }
    } else {
        let dataMarketSdly = addMarketSdlyData(app_state);
        if (key.value === "Occ") {
            dataObj[key.value] = convertToPercent(marketDbaData[index][dataKey]['primary'][key.value] - dataMarketSdly[idx][dataKey].primary[key.value]);

        } else if (key.value === "SellRate") {
            let diffRate = marketDbaData[index][dataKey]['primary'][key.value] - dataMarketSdly[idx][dataKey].primary[key.value]
            let sellRate = (typeof diffRate !== 'undefined' && diffRate > 0) ? round(diffRate) : 0;
            dataObj[key.value] = sellRate;//marketDbaData[index][dataKey][mainKey].sellRate;
        } else if (key.value === "Rooms") {
            dataObj[key.value] = Math.round(marketDbaData[index][dataKey]['primary'][key.value] - dataMarketSdly[idx][dataKey].primary[key.value]);

        } else {
            dataObj[key.value] = round(marketDbaData[index][dataKey]['primary'][key.value] - dataMarketSdly[idx][dataKey].primary[key.value]);

        }
    }



    return dataObj;
}






export const getMarketDbaDataInt = (mainKey, id, tileName, key, index, dataKey, auth, app_state, ui_state) => {

    let blankObj = {
        primary:{ADR: 0, Occ: 0, RevPAR: 0, Revenue: 0, Rooms: 0},
        primary_noncomm: {ADR: 0, Occ: 0, RevPAR: 0, Revenue: 0, Rooms: 0},
        sdly : {ADR: 0, Occ: 0, RevPAR: 0, Revenue: 0, Rooms: 0} 
    };

    let marketDbaDataFull = deepCopy(dataService.getSeriesById("MarketSdlyDba").data.data);
    let marketDbaData = marketDbaDataFull.sort((a, b) => a.Index.dba - b.Index.dba);
    let idx = index;
    let keyValue = key.value;
    let marketDbaDataIndexDataKey = (marketDbaData[index]) ? marketDbaData[index][dataKey][mainKey] : {keyValue:0};
    let  marketDbaData_dataKey = (marketDbaData[idx]) ? marketDbaData[idx][dataKey] : blankObj;
    // debugger;
    let marketPrimary = (marketDbaData_dataKey) ?  marketDbaData_dataKey['primary'][keyValue] : 0;
    let marketSdly = marketDbaData_dataKey['sdly'];
    let marketSdlyValue = marketSdly[keyValue];

    // debugger;  
    let dataObj = {};
    
    if (marketDbaData[index]) {
        if (mainKey === "primary") {
            if (keyValue === "Occ") {
                dataObj[keyValue] = convertToPercent(marketDbaDataIndexDataKey[keyValue]);
            } else if (keyValue === "SellRate") {
                let sellRate = (typeof marketDbaDataIndexDataKey.sellRate !== 'undefined') ? round(marketDbaDataIndexDataKey.sellRate) : 0;
                dataObj[keyValue] = sellRate;//marketDbaDataIndexDataKey.sellRate;
            } else if (keyValue === "Rooms") {
                dataObj[keyValue] = Math.round(marketDbaDataIndexDataKey[keyValue]);
            } else {
                dataObj[keyValue] = round(marketDbaDataIndexDataKey[keyValue]);
            }
            // } else if (mainKey === "sdly_dba_comp" && sdlyOn) {
        } else if (mainKey === "sdly_dba_comp") {
            let dataMarketSdly = addMarketSdlyData(app_state);
            let k = keyValue;
            if (keyValue === "Occ") {
                dataObj[keyValue] = convertToPercent(marketSdlyValue);
            } else if (keyValue === "SellRate") {
                let sellRate = (typeof marketSdlyValue !== 'undefined') ? round(marketSdlyValue) : 0;
                dataObj[keyValue] = sellRate;//marketDbaData_dataKey["sdly"].sellRate;
            } else if (keyValue === "Rooms") {
                dataObj[keyValue] = Math.round(marketSdlyValue);
            } else {
                dataObj[keyValue] = round(marketSdlyValue);
            }
        } else {
            if (mainKey !== "comparison" && mainKey !== "compDateDIff") {
                let dataMarketSdly = addMarketSdlyData(app_state);
                if (keyValue === "Occ") {
                    dataObj[keyValue] = convertToPercent(marketPrimary - marketSdlyValue);

                } else if (keyValue === "SellRate") {
                    let diffRate = marketPrimary - marketSdlyValue
                    let sellRate = (typeof diffRate !== 'undefined' && diffRate > 0) ? round(diffRate) : 0;
                    dataObj[keyValue] = sellRate; 
                } else if (keyValue === "Rooms") {
                    dataObj[keyValue] = Math.round(marketPrimary - marketSdlyValue);
                } else {
                    dataObj[keyValue] = round(marketPrimary - marketSdlyValue);
                }
            }
        }
    }
    return dataObj;
}