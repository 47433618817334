import { API_BASE_URL, API_REGISTRY, api_status, promises, ABORT_REQUEST_CONTROLLERS } from "../app/config";
import { getFromSession, setAbortSignal } from "../app/util";

const fetchData =
  //function ({ api, request, active_api }) {
  async ({ api, request, active_api, active_apis }) => {
    let token = getFromSession("token");
    // request.token_string = getFromSession("token");
    // request.token = request.token ? request.token : getFromSession("token");
    let REQUEST_URL = api.search("://") === -1 ? API_BASE_URL + api : api;
    // debugger;
    let method = '';
    if (request && request.hasOwnProperty('method')) {
      method = request.method;
      delete request.method;
    } else {
      method = 'POST';
    }

    // debugger;
    return new Promise((reslove, reject) => {
      if (method === 'GET' && request.query) {
        // let urlParams = new URLSearchParams(request.query).toString();
        //REQUEST_URL += request.resourceId+"?"+urlParams;
      }
     
      let abortSignal = setAbortSignal(active_api, true);
      let abrc = ABORT_REQUEST_CONTROLLERS.get(active_api).signal;
      // debugger;
      fetch(REQUEST_URL, {
        signal: abortSignal,
        method: method, // *GET, POST, PUT, DELETE, etc.
        cache: "no-cache", // *default, no-cache, reload, force-cache, only-if-cached
        headers: {
          "Content-Type": "application/json",
          "Authorization": "Token " + token,
          //'Access-Control-Allow-Origin':'*'
          // 'Content-Type': 'application/x-www-form-urlencoded',
        },
        redirect: "follow", // manual, *follow, error
        //referrerPolicy: 'origin', // no-referrer, *no-referrer-when-downgrade, origin, origin-when-cross-origin, same-origin, strict-origin, strict-origin-when-cross-origin, unsafe-url
        body: method === 'GET' ? null : JSON.stringify(request), // body data type must match "Content-Type" header
      })
        .then((original) => {
          // debugger;
          console.log("API response for ", active_api)
          let t = active_api;
          try {
            if (original.ok && original.status === 200) {

              let resJson = async () => await original.json();
              return resJson();

            } else {
              let rs = { status: 'error', response: original, data_id: active_api, data: null, message: "Data loading failed" }
              reject(rs);
              // debugger;
              // promises[active_api + '_callback_error'](rs);
              // return Promise.reject(rs);
            }
          } catch (e) {
            let rs = { status: 'error', response: original, data_id: active_api, data: null, message: e.toString() }
            reject(rs);
          }


        })
        .then((resData) => {
          // if (active_api==='MarketForecastSegmented') debugger;
          let ignoreStatus = ['SystemSettings', 'SwitchProperty'];
          if (!resData) {
            reject({ status: 'error', data: null, message: "Failed!" });
          } else if (resData && ignoreStatus.indexOf(active_api) > -1) {
            let reqParams = { api, request };
            let finalData = { data: JSON.parse(JSON.stringify(resData)) };
            finalData.data_id = active_api;
            finalData.ok = true;
            updateDataCache(active_api, { data: finalData, loaded: true, loading: false, reqParams: reqParams });            

            reslove(finalData);
          } else if (resData && resData.status !== 'success') {

            let rs = { status: 'error', data: null, message: "Response Failed!", data_id: active_api }
            updateDataCache(active_api, { data: null, loaded: false, loading: false, reqParams: null });
            //promises[active_api + '_callback_error'](resData.data);

            //adding this to reduce otb api calls
            if(active_api==='OSR'){
              updateDataCache("OSRSegment", { data: null, loaded: true, loading: false, reqParams: null });
              updateDataCache("OSRYear", { data: null, loaded: true, loading: false, reqParams: null });
            }

            reject(rs);
          } else if (resData.status === 'success') {
            let reqParams = { api, request };
            let finalData = JSON.parse(JSON.stringify(resData));

            //Handling consistency for data sceince APIs data
            if (resData.data && resData.data.data
              && resData.data.constructor.name === 'Object'
              && resData.data.data.constructor.name === 'Array') {
              finalData = JSON.parse(JSON.stringify(resData.data));
              finalData.proxy = resData.proxy;
              finalData.requestTimestamp = resData.requestTimestamp;
              finalData.status = resData.status;
            }
            if (resData.data && resData.data.data
              && resData.data.constructor.name === 'Object'
              && resData.data.data.constructor.name !== 'Array') {
              finalData = JSON.parse(JSON.stringify(resData.data));
              finalData.data = [];
              finalData.proxy = resData.proxy;
              finalData.requestTimestamp = resData.requestTimestamp;
              finalData.status = 'error';
            }

            if ((REQUEST_URL.search('/api.v1/') !== -1)) {
              finalData = JSON.parse(JSON.stringify(resData.data));
              finalData.proxy = resData.proxy;
              finalData.requestTimestamp = resData.requestTimestamp;
              finalData.status = resData.status;
              //Storing in cache
              console.log("Forecast API response transformed", resData);
            }
            // return finalData;
            finalData.data_id = active_api;
            finalData.ok = true;
            updateDataCache(active_api, { data: finalData, loaded: true, loading: false, reqParams: reqParams });
            
            //adding this to reduce otb api calls
            if(active_api==='OSR'){
              updateDataCache("OSRSegment", { data: finalData, loaded: true, loading: false, reqParams: reqParams });
              updateDataCache("OSRYear", { data: finalData, loaded: true, loading: false, reqParams: reqParams });
            }

            reslove(finalData);

            if (!promises[active_api + '_callback_success']) {
              //console.log("No callback function found for:", active_api);
              // return Promise.resolve(cached.data);
              // Promise.resolve(cached.data);
            }
            //promises[active_api + '_callback_success'](cached.data);  
          } else if (resData.status === 'success' && resData.data.status==='failed') {
            reject({ status: 'error', data: [], message: resData.data.message, data_id: active_api });
          }
        }

        )
        .catch((err) => {
          // debugger;
          console.log("Error block for  API");
          reject({ status: 'error', data: err.toString(), message: "Response Failed!", data_id: active_api });
          //promises[active_api + '_callback_error'](err);
        });
    })
  };
const getData = ({ api, request, active_apis }) => {
  if (request.method && request.method === "GET") {
    return fetchData({ api, request, active_apis });
  }
  return fetchDataSimple({ api, request, active_apis });
}
const updateData = ({ api, request }) => {
  if (request.method && request.method === "GET") {
    return fetchData({ api, request });
  }
  return fetchDataSimple({ api, request });
}
const getDataDashboard = async ({ api, request, selectedAPI, active_api, active_apis, data_status, without_cache, dispatch }) => {
  if (api.indexOf('get_profile') > -1) {
    return fetchDataSimple({ api, request });
  }
  // debugger;
  // request.token = getFromSession("token");
  // request.token_string = getFromSession("token");

  let activeSeries = '';
  if (selectedAPI) {
    //updateDataCache(selectedAPI.id, {loaded: false, loading:true, data:null});
    activeSeries = getSeriesById(selectedAPI.id);
    if (activeSeries && !activeSeries.data) {
      //updateDataCache({ id: selectedAPI.id, data: null, loadStatus: "idle" });
    }
  }

  //Return from cache if payload is same
  // debugger;
  if (
    activeSeries &&
    activeSeries.data &&
    activeSeries.reqParams
  ) {

    let payloadCopy = JSON.parse(JSON.stringify({ api, request }));
    let oldPayloadCopy = selectedAPI.reqParams ? selectedAPI.reqParams : {};
    if (request.method === "GET" && payloadCopy.api === oldPayloadCopy.api && !without_cache) {
      const cacheData = getSeriesById(selectedAPI.id);
      console.log("Data Found in cashe for " + active_api);
      cacheData.from_local_cache = true;
      updateDataCache(active_api, { from_local_cache: true, loaded: true, loading: false });
      return Promise.resolve(cacheData.data);
    }
    // debugger;
    if (request.method !== "GET" &&
      JSON.stringify(oldPayloadCopy) ===
      JSON.stringify(payloadCopy)
    ) {
      const cacheData = getSeriesById(selectedAPI.id);
      console.log("Data Found in cashe for " + active_api);
      cacheData.from_local_cache = true;
      updateDataCache(active_api, { from_local_cache: true, loaded: true, loading: false });
      return Promise.resolve(cacheData.data);

    }
    if (request.method !== "GET" &&
      JSON.stringify(oldPayloadCopy) !==
      JSON.stringify(payloadCopy)
    ) {
      return fetchData({ api, request, active_api, active_apis });
      updateDataCache(active_api, { data: null, from_local_cache: false, loaded: false, loading: true });
      api_status[activeSeries.id] = 'inprogress';
      // data_status[activeSeries.id] = 'inprogress';
    }
  }

  // activeSeries.reqParams = { api, request };
  //updateDataCache(selectedAPI.id, {loading:true, loaded: false, reqParams: { api, request }})


  // if (api_status[active_api] === 'loading') {
  activeSeries.loading = true;
  activeSeries.loaded = false;
  api_status[active_api] = 'loading';
  console.log("Fetching data starts", active_api);
  console.log("API Called...", api_status, active_api, data_status);
  //Now call APIs
  // debugger;
  return fetchData({ api, request, active_api, active_apis });

};

const getSeriesById = (id) => {
  let series = null;
  if (API_REGISTRY[id]) {
    const dataSeries = API_REGISTRY[id];
    return dataSeries;
  }
  return series;
};
window.getSeriesById = getSeriesById;

const updateDataCache = (key, val) => {
  let cache = API_REGISTRY[key];
  if (cache) {
    API_REGISTRY[key] = { ...API_REGISTRY[key], ...val }
    return API_REGISTRY[key];
  }
  if (!cache) {
    API_REGISTRY[key] = val;
    return API_REGISTRY[key];
  }

};

const update = async ({ api, request }) => {
  if (request.hasOwnProperty("token_string")) {
    request.token = getFromSession("token");
  }
  const REQUEST_URL = api.search("://") === -1 ? API_BASE_URL + api : api;
  const method = !request.method ? 'POST' : request.method;
  delete request.method;
  const response = await fetch(REQUEST_URL, {
    method: method, // *GET, POST, PUT, DELETE, etc.
    cache: "no-cache", // *default, no-cache, reload, force-cache, only-if-cached
    headers: {
      "Content-Type": "application/json",
      "Authorization": "Token " + getFromSession("token"),
      //'Access-Control-Allow-Origin':'*'
      // 'Content-Type': 'application/x-www-form-urlencoded',
    },
    redirect: "follow", // manual, *follow, error
    //referrerPolicy: 'origin', // no-referrer, *no-referrer-when-downgrade, origin, origin-when-cross-origin, same-origin, strict-origin, strict-origin-when-cross-origin, unsafe-url
    body: JSON.stringify(request), // body data type must match "Content-Type" header
  });
  const data = await response.json();
  data.original = { status: response.status, statusText: response.statusText };
  return data;
};

export const fetchDataSimple = async ({ api, request }) => {
  if (!request.token_string) {
    //request.token = getFromSession("token");
  }
  // debugger;
  const token = getFromSession("token");
  let REQUEST_URL = api.search("://") === -1 ? API_BASE_URL + api : api;
  const method = request.method ? request.method : "POST";
  delete request.method;
  let body = JSON.stringify(request);

  let headers= {
    "Content-Type": 'application/json',
     "Authorization": "Token " + token,

     // 'Content-Type': 'application/x-www-form-urlencoded',
   };
  if(request.formData){
    body = request.formData;
    // contentType = 'multipart/form-data; boundary=<calculated when request is sent>'
    delete headers["Content-Type"];
  }
  
  
  if (method === "GET") {
    body = null;
    // REQUEST_URL += "?"+ encodeURIComponent(JSON.stringify(request));
    // debugger;
  }

  const response = await fetch(REQUEST_URL, {
    method: method, // *GET, POST, PUT, DELETE, etc.
    cache: "no-cache", // *default, no-cache, reload, force-cache, only-if-cached
    headers:headers,
    
 
    redirect: "follow", // manual, *follow, error
    //referrerPolicy: 'origin', // no-referrer, *no-referrer-when-downgrade, origin, origin-when-cross-origin, same-origin, strict-origin, strict-origin-when-cross-origin, unsafe-url
    body: body, // body data type must match "Content-Type" header
  });
  if(request.type ==="blob"){
    const data = await response.blob();
    
    return data;
  }
  let data;
  try{
    data = await response.json();
  } catch(err){
    data = {status:'error', message: err.message};
  }
  

  data.original = { status: response.status, statusText: response.statusText };
  return data;
};

const callAPI = async ({ api, request }) => {
  const REQUEST_URL = api.search("://") === -1 ? API_BASE_URL + api : api;
  const method = request.method ? request.method : "POST";
  delete request.method;
  const requestBody = method === "GET" ? null : JSON.stringify(request);
  const response = await fetch(REQUEST_URL, {
    method: method, // *GET, POST, PUT, DELETE, etc.
    cache: "no-cache", // *default, no-cache, reload, force-cache, only-if-cached
    headers: {
      "Content-Type": "application/json",
      // "Authorization": "Token " + request.token,
      //'Access-Control-Allow-Origin':'*'
      // 'Content-Type': 'application/x-www-form-urlencoded',
    },
    redirect: "follow", // manual, *follow, error
    //referrerPolicy: 'origin', // no-referrer, *no-referrer-when-downgrade, origin, origin-when-cross-origin, same-origin, strict-origin, strict-origin-when-cross-origin, unsafe-url
    body: requestBody, // body data type must match "Content-Type" header
  });
  const data = await response.json();
  data.original = { status: response.status, statusText: response.statusText };
  return data;
};

export default {
  getData, updateData,
  getDataDashboard,
  getSeriesById,
  updateDataCache,
  update,
  callAPI
};
