import React, { useState, useEffect, useReducer } from 'react';

import { styled, Switch, Box, Tooltip } from '@mui/material';
import TextField from '@mui/material/TextField';
import { Info as InfoIcon, Mail as MailIcon } from '@mui/icons-material';

import moment from 'moment';
import {
    Grid, Button, LinearProgress, Badge, Modal, IconButton,
    Table, TableBody, TableCell, TableContainer, TableHead, TableRow
} from '@mui/material';
import {
    CloseSharp as CloseSharpIcon,
    ArrowBackIosNewSharp as ArrowBackIosNewSharpIcon,
    ArrowForwardIosSharp as ArrowForwardIosSharpIcon,
    CheckSharp as CheckSharpIcon
}
    from '@mui/icons-material';
import { useDispatch, useSelector } from 'react-redux';
import { updateAppState } from '../../actions/appstate';
import DataService from "../../services/data.service";
import { deepCopy, dateFormat } from "../../app/util";
import "./update-restrictions-modal.scss";

export default function UpdateRestrictionsModal(props) {
    const { modalOpen, handleModalClose, stay_date, recData, data, entryFields, entry } = props;
    const [compState, setcompState] = useState({ open: true, entryFields: [...entryFields], entry: entry, modified: false });
    const { auth } = useSelector((state) => state);
    const dispatch = useDispatch();
    const { app_state, ui_state } = useSelector((state) => state.app.current_state);
    // debugger;
    useEffect(() => {
        let isSubscribed = true;
        if (compState.entryFields.includes('cta')
            || compState.entryFields.includes('ctd')
            || compState.entryFields.includes('close')) {
            compState.open = false;
        } else {
            compState.open = true;
        }
        if (data && data[0]) {
            // entryFields
            let saved = app_state.savedRestrictions;
            // debugger;
            //changing value of min/max los in case of 1
            let newEntry = deepCopy(entry);
            if (saved.length) {
                let exist = saved.filter(item => item.stay_date === stay_date);
                if (exist.length) {
                    if (data[0].min_los == 1 && newEntry.min_los == 1) {
                        newEntry.min_los = '';
                    }
                    if (data[0].max_los == 1 && newEntry.max_los == 1) {
                        newEntry.max_los = '';
                    }
                } else {
                    newEntry = deepCopy(data[0]);
                    if (newEntry.min_los == 1) {
                        newEntry.min_los = '';
                    }

                    if (newEntry.max_los == 1) {
                        newEntry.max_los = '';
                    }
                }

            } else {
                newEntry = deepCopy(data[0]);

                if (newEntry.min_los == 1) {
                    newEntry.min_los = '';
                }

                if (newEntry.max_los == 1) {
                    newEntry.max_los = '';
                }

            }

            setcompState(state => {
                return { ...state, open: compState.open, entry: { ...compState.entry, ...newEntry } }
            })
        }
        return () => {
            return (isSubscribed = false);
        }
    }, [])

    const handleClose = () => {
        handleModalClose(false);
        if (data && data[0]) {
            setcompState(state => {
                return { ...state, entry: { ...compState.entry, ...data[0] } }
            })
        }
    }

    const updateEntry = (key) => {
        return (event) => {
            let ent = compState.entry;

            // debugger;
            const target = event.currentTarget;
            const value = target.value;
            const entry = {};
            if (target.type === 'checkbox') {
                let fieldIndex = compState.entryFields.indexOf(key);
                if (target.checked) {
                    entry[key] = target.checked;
                    if (!compState.entryFields.includes(key)) {
                        compState.entryFields.push(key);
                    }
                } else {
                    entry[key] = false;
                    compState.entryFields.splice(fieldIndex, 1);
                }
            }

            if (target.type !== 'checkbox') {
                // debugger;
                let fieldIndex = compState.entryFields.indexOf(key);
                if (value !== '' && !isNaN(Number(value.trim().toString()))) {
                    entry[key] = Number(value);
                    // debugger
                    if (!compState.entryFields.includes(key)) {
                        compState.entryFields.push(key);
                    }
                } else {
                    entry[key] = value.trim().toString();
                    if (props.cur[key] > 1 && value == '') {
                        entry[key] = 0;
                    } else {
                        // let keyIndex = compState.entryFields.indexOf(key);
                        // if (keyIndex > -1) {
                        //     compState.entryFields.splice(keyIndex, 1);
                        // } else {
                        //     entry[key] = '';
                        // }
                    }

                }
            }
            if (compState.entryFields.includes('cta')
                || compState.entryFields.includes('ctd')
                || compState.entryFields.includes('close')) {
                compState.open = false;
            } else {
                compState.open = true;
            }
            // debugger;
            setcompState(state => {
                // debugger
                let oldEntry = deepCopy({ ...props.cur });
                let newEntry = deepCopy({ ...state.entry, ...entry });
                let newEntry_ = deepCopy(newEntry);

                //oldEntry.min_los = oldEntry.min_los && oldEntry.min_los !== 1 ? oldEntry.min_los : "";
                //oldEntry.max_los = oldEntry.max_los && oldEntry.max_los !== 1 ? oldEntry.max_los : "";

                // newEntry.min_los = newEntry.min_los  ? newEntry.min_los : "";
                // newEntry.max_los = newEntry.max_los ? newEntry.max_los : "";

                // let blank = { cta: false, ctd: false, close: false, min_los: "", max_los: "" }
                let blank = { cta: false, ctd: false, close: false, min_los: 1, max_los: 1 };
                if(newEntry_.min_los === ''){
                    delete newEntry_.min_los;
                }
                if(newEntry_.max_los === ''){
                    delete newEntry_.max_los;
                }
                
                // debugger;
                let oldEntryStr = JSON.stringify({ ...blank, ...oldEntry });
                let newEntryStr = JSON.stringify({ ...blank, ...newEntry_ });
                
                let modified = false;
                if (oldEntryStr !== newEntryStr) { modified = true }
                if (oldEntry.min_los > 1 && (newEntry.min_los === '' || newEntry.min_los === 1)) {
                    //newEntry.min_los = 0;
                }
                if (oldEntry.max_los > 1 && (newEntry.max_los === '' || newEntry.max_los === 1)) {
                    //newEntry.max_los = 0;
                }
                return { ...state, modified, entry: { ...state.entry, ...entry, ...newEntry } }
            })
        }
    }
    const handleBlur = ({ key, val }) => {
        // debugger;
        if (key === 'min_los' || key === 'max_los') {
            if (val == 1) {
                compState.entry[key] = '';
                compState.cur = compState.cur ? compState.cur : {};
                compState.cur[key] = '';
                setcompState(state => {
                    return { ...state, ...compState }
                })
            }
        }
    };
    const handleSaveEntry = () => {
        // handleSave(compState.entry);
        // debugger;
        let savedRestrictions = { stay_date: stay_date, rec: recData, cur: data[0], entry: compState.entry };
        let existingEntryIndex;
        let haveDelta;
        let existingEntry = app_state.savedRestrictions.filter((item, i) => {
            if (item.stay_date === stay_date) {
                existingEntryIndex = i;
                return true;
            }
        })

        if (existingEntry && existingEntry.length) {
            existingEntry = existingEntry[0];
            app_state.savedRestrictions[existingEntryIndex].cur = savedRestrictions.cur;
            app_state.savedRestrictions[existingEntryIndex].entry = compState.entry;
            // app_state.savedRestrictions.splice(existingEntryIndex, 1, compState.entry);
        } else {
            app_state.savedRestrictions.push(savedRestrictions);
        }
        app_state.savedRestrictions.map((item, index) => {
            let delta = { stay_date: item.stay_date };
            if (item.entry.cta && !item.cur.cta) {
                delta.cta = true;
            }
            if (!item.entry.cta && item.cur.cta) {
                delta.cta = false;
            }
            if (item.entry.ctd && !item.cur.ctd) {
                delta.ctd = true;
            }
            if (!item.entry.ctd && item.cur.ctd) {
                delta.ctd = false;
            }
            if (item.entry.close && !item.cur.close) {
                delta.close = true;
            }
            if (!item.entry.close && item.cur.close) {
                delta.close = false;
            }
            if (item.entry.min_los == item.cur.min_los) {
                delete delta.min_los;
            }
            if (item.entry.min_los != item.cur.min_los) {
                delta.min_los = true;
            }
            if (item.entry.max_los == item.cur.max_los) {
                delete delta.max_los;
            }
            if (item.entry.max_los != item.cur.max_los) {
                delta.max_los = true;
            }
            // debugger;
            app_state.savedRestrictions[index].delta = delta;
        });
        haveDelta = existingEntry && existingEntry.delta ? Object.keys(existingEntry.delta) : [];

        dispatch(updateAppState("saveRestrictions", {
            app_state: { ...app_state, savedRestrictions: app_state.savedRestrictions }
        }
        ));
        handleModalClose();
    }

    return (<>
        <Modal
            className="popup-modal-wrap"
            open={modalOpen}
            onClose={handleClose}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
        >
            <Box className='popup-modal restrictions-update'>
                <Box className='modal-titlebar'>
                    <Grid container spacing={0} className='modal restrictions-update'>
                        <Grid item xs={10}>
                            <item><span className='text-green'>Restrictions - </span>Update</item>
                        </Grid>
                        <Grid className='right-cta' xs={2} item alignItems="end">
                            <Button sx={{
                                minWidth: 'auto', position: 'absolute', top: -12, right: -12, width: '24px', height: '24px', p: '7px',
                                borderRadius: '50%', borderWidth: '1px', borderStyle: 'solid',
                                borderColor: '#ccc', boxShadow: '0 0 4px #000', background: '#000', color: '#FFF', fontSize: '10px',
                                '&:hover': { backgroundColor: '#000' }
                            }} onClick={handleClose}>
                                <CloseSharpIcon fontSize='small' />
                            </Button>
                        </Grid>
                    </Grid>
                </Box>

                <Grid container spacing={1}>

                    <Grid item xs={12}>
                        <Box className="restriction-row stay-date">
                            {dateFormat(stay_date, "ddd, MMM DD, YYYY")}
                        </Box>
                    </Grid>

                </Grid>
                <Box className="restriction-row oval">
                    <Grid container>
                        <Grid item xs={6}>
                            <div className="res-label">Open</div>
                        </Grid>
                        <Grid item xs={6} sx={{ textAlign: 'right' }}>
                            <RestrictionSwitch className='switch open' checked={compState.open} disabled />
                        </Grid>
                    </Grid>
                </Box>
                <Box className="restriction-row oval">
                    <Grid container>
                        <Grid item xs={6}>
                            <div className="res-label">CTA</div>
                        </Grid>
                        <Grid item xs={6} sx={{ textAlign: 'right' }}>
                            <RestrictionSwitch className='switch cta' checked={compState.entry.cta} onChange={updateEntry('cta')} />
                        </Grid>

                    </Grid>
                </Box>
                <Box className="restriction-row oval">
                    <Grid container>
                        <Grid item xs={6}>
                            <div className="res-label">CTD</div>
                        </Grid>
                        <Grid item xs={6} sx={{ textAlign: 'right' }}>
                            <RestrictionSwitch className='switch ctd' checked={compState.entry.ctd} onChange={updateEntry('ctd')} />
                        </Grid>

                    </Grid>
                </Box>
                <Box className="restriction-row oval">
                    <Grid container>
                        <Grid item xs={6}>
                            <div className="res-label">Close</div>
                        </Grid>
                        <Grid item xs={6} sx={{ textAlign: 'right' }}>
                            <RestrictionSwitch className='switch close' checked={compState.entry.close} onChange={updateEntry('close')} />
                        </Grid>

                    </Grid>
                </Box>
                <Box className="restriction-row">
                    <Grid container spacing={1}>
                        <Grid item xs={7}>
                            <Box className="oval">
                                <div className="res-label">Min LOS</div>
                            </Box>

                        </Grid>
                        <Grid item xs={3.5} sx={{ textAlign: 'right' }}>
                            <TextField type='number' size='small' className='field min-los'
                                value={compState.entry.min_los}
                                onBlur={(e) => handleBlur({ key: 'min_los', val: compState.entry.min_los })}
                                onChange={updateEntry('min_los')} />
                        </Grid>
                        <Grid item xs={1} sx={{ fontSize: 25 }}>
                            <Tooltip title="Enter '0' to remove LOS" sx={{ mt: 0.5 }} arrow>
                                <InfoIcon />
                            </Tooltip>
                        </Grid>
                    </Grid>
                </Box>
                <Box className="restriction-row">
                    <Grid container spacing={1}>
                        <Grid item xs={7}>
                            <Box className="oval">
                                <div className="res-label">Max LOS</div>
                            </Box>

                        </Grid>
                        <Grid item xs={3.5} sx={{ textAlign: 'right' }}>
                            <TextField type='number' size='small' className='field max-los' value={compState.entry.max_los} onBlur={(e) => handleBlur({ key: 'max_los', val: compState.entry.max_los })} onChange={updateEntry('max_los')} />
                        </Grid>
                        <Grid item xs={1.5} sx={{ fontSize: 25 }}>
                            <Tooltip title="Enter '0' to remove LOS" sx={{ mt: 0.5 }} arrow>
                                <InfoIcon />
                            </Tooltip>
                        </Grid>
                    </Grid>
                </Box>

                <footer className="footer-block">
                    <Grid container spacing={1}>
                        <Grid item xs={.5}></Grid>
                        <Grid item xs={5.5}>
                            <Button fullWidth className='axn-btn' onClick={() => { handleModalClose(); }}>
                                <span>Cancel</span>
                            </Button>

                        </Grid>
                        <Grid item xs={5.5}>

                            <Button fullWidth className='axn-btn btn-save'
                                disabled={!compState.modified}
                                onClick={handleSaveEntry}>
                                <span>Save</span>
                            </Button>
                        </Grid>
                        <Grid item xs={.5}></Grid>
                    </Grid>
                </footer>
            </Box>
        </Modal>
    </>);
}

const RestrictionSwitch = styled((props) => (
    <Switch focusVisibleClassName=".Mui-focusVisible" disableRipple {...props} />
))(({ theme }) => ({
    width: 56,
    height: 26,
    padding: 0,
    '& .MuiSwitch-switchBase': {
        padding: 0,
        margin: 2,
        transitionDuration: '300ms',
        '&.Mui-checked': {
            transform: 'translateX(30px)',
            color: '#fff',
            '& + .MuiSwitch-track': {
                backgroundColor: theme.palette.mode === 'dark' ? '#2ECA45' : '#01A4EF',
                opacity: 1,
                border: 0,
            },
            '&.Mui-disabled + .MuiSwitch-track': {
                opacity: 0.5,
            },
        },
        '&.Mui-focusVisible .MuiSwitch-thumb': {
            color: '#33cf4d',
            border: '6px solid #fff',
        },
        '&.Mui-disabled .MuiSwitch-thumb': {
            color:
                theme.palette.mode === 'light'
                    ? theme.palette.grey[100]
                    : theme.palette.grey[600],
        },
        '&.Mui-disabled + .MuiSwitch-track': {
            opacity: theme.palette.mode === 'light' ? 0.7 : 0.3,
        },
    },
    '& .MuiSwitch-thumb': {
        boxSizing: 'border-box',
        width: 22,
        height: 22,
    },
    '& .MuiSwitch-track': {
        borderRadius: 26 / 2,
        backgroundColor: theme.palette.mode === 'light' ? '#E9E9EA' : '#39393D',
        opacity: 1,
        transition: theme.transitions.create(['background-color'], {
            duration: 500,
        }),
    },
}));
