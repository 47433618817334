import moment from "moment";
import { convertToPercent, round } from "../../app/util";
import { useDataSummary } from "../Tile/kpiService";
import dataService from "../../services/data.service";
import { useSelector } from "react-redux";
import { ExitToApp } from "@mui/icons-material";


export const getRatesData = (dates,app_state) => {
    let res;
    let apiUrl = "api.v1/get_current_recommended_sell_rates";
    const payload =  {
        "params": {
            "pid": app_state.property_id,
            "asof_date": app_state.as_of_date,
            "stay_date_from": dates.days[0].dateStr,
            "stay_date_to": dates.days[dates.days.length-1].dateStr
        },
    }
   return payload;

}

