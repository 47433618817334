
import { Box, Select, FormControl, MenuItem, Tooltip } from '@mui/material'
import { useState } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { updateAppState } from '../../../../actions/appstate';
import { TILES } from "../../../../app/config";

const RatePushMode = () => {
    const controls = useSelector(state => state.app.current_state.app_state.workspace_controls);
    const { app_state, ui_state } = useSelector(
        (state) => state.app.current_state
    );

    const [mode, setMode] = useState(controls.logsRatePushMode);
    const dispatch = useDispatch();

    const handleChange = (type, value) => {
        //controls.logsRatePushMode = value;
        app_state.workspace_controls.logsRatePushMode = value;
        app_state.date_range_type = value === 'date' ? 'logsSingleDate' : 'logsDateRange';
        if(value !== 'date'){
            // debugger;
            // app_state.primary_stay_range.date_until = app_state.historicalRange.date_until;
        }
        
        dispatch(updateAppState(type,
            {
                app_state: { 
                    ...app_state,
                    //workspace_controls: controls, 
                    // date_range_type: 'logsSingleDate',
                    data_load: true, kpi_load: true 
                },
                ui_state: { showspinner: true }
            }));

        // handleOpenReportMode('');
    }



    const handleOpenReportMode = (id) => {
        id = (app_state.workspace_controls.logsRatePushMode === "date_range") ? "room_type_ratesend_status_date_range" : "rm_room_type_ratesend_status";
        let currTile = TILES[id];

        if (ui_state.active_tiles.length === 1 && ui_state.active_tiles[0] !== id) {
            const active_tiles = [id];
            let workspace_view = 'table';
            if (currTile.defaultView) {
                workspace_view = currTile.defaultView;
            }

            dispatch(
                updateAppState("change_active_tiles", {
                    app_state: {
                        date_range_type: 'rangeTileChange',
                        workspace_controls: app_state.workspace_controls,
                    },
                    ui_state: {
                        ...ui_state,
                        active_tiles,
                        workspace_view,
                    },
                })
            );
        }
    }

    return (
        <div style={{ position: 'relative' }}>
            <Box id="mode" sx={{ position: "relative", paddingLeft: 0, backgroundColor: 'blue', height: 1 / 2 }}>
                <Box sx={{ display: 'block', fontSize: '75%', fontWeight: 'bold' }}>Mode</Box>
                <FormControl size="small" sx={{ borderRadius: "16px" }}>
                    <Select fullWidth className='tabular-data-filter' value={mode}
                        sx={{ height: "27px", minWidth: "100px", mt: "5px" }}
                        onChange={(e) => {
                            setMode(e.target.value);
                            handleChange("logs_mode", e.target.value);
                        }}>
                        <MenuItem value="date" className='tabular-data-filter'> Date </MenuItem>
                        <MenuItem value="date_range" className='tabular-data-filter'> Date Range </MenuItem>
                    </Select>

                </FormControl>
            </Box>

        </div>
    )
}


export default RatePushMode