import * as React from 'react';
import Popover from '@mui/material/Popover';
import Typography from '@mui/material/Typography';
import Stack from '@mui/material/Stack';
import { getClassName } from './Restrictions';
import { CardContent, Chip, Container, Table, TableCell, TableContainer, TableHead, TableRow, TableBody } from '@mui/material';
import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import { InsertEmoticon } from '@mui/icons-material';


import Avatar from '@mui/material/Avatar';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import ListItemIcon from '@mui/material/ListItemIcon';
import Divider from '@mui/material/Divider';
import IconButton from '@mui/material/IconButton';
import Tooltip from '@mui/material/Tooltip';
import PersonAdd from '@mui/icons-material/PersonAdd';
import Settings from '@mui/icons-material/Settings';
import Logout from '@mui/icons-material/Logout';
import StarOutlineIcon from '@mui/icons-material/StarOutline';
import CurrencyExchangeIcon from '@mui/icons-material/CurrencyExchange';
import moment from 'moment';
import { deepCopy, getCorrectValue, getFromState, getPermissions, getSeriesById, getTransformedSegments } from '../../app/util';
import { useSelector } from 'react-redux';
import dataService from '../../services/data.service';
import RatePlanList from './RatePlanList';
import Skeleton from '@mui/material/Skeleton';
import KeyboardDoubleArrowRightIcon from '@mui/icons-material/KeyboardDoubleArrowRight';
import RatePlanListTotal from './RatePlanListTotal';
import { Alert, AlertTitle } from '@mui/material';


export default function RatePlanPopup(props) {

    const state = useSelector(appState => appState);
    let { stay_date, segmentName } = props.params;
    const [anchorEl, setAnchorEl] = React.useState(null);
    const open = Boolean(anchorEl);

    const { app_state, ui_state } = useSelector(state => state.app.current_state);
    const { property_details } = useSelector(state => state.auth.profile);
    const { permissions, index } = getPermissions(property_details, app_state.property_id);


    const [ratePlans, setRatePlans] = React.useState(null);
    const [ratePlansTotal, setRatePlansTotal] = React.useState(null);
    let resTotal = segmentName.includes("Total")
    segmentName = segmentName.replaceAll(" Total", "");

    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
        /** BL to get the Rate plans  */
        let rateCodesMapping = dataService.getSeriesById("GetRateCodeMapping").data;
        let ratePlanDataAll = deepCopy(dataService.getSeriesById("GetRatePlan").data);
        let ratePlanData = deepCopy(dataService.getSeriesById("GetRatePlan").data);
        // debugger;
        ratePlanData = (ratePlanData && ratePlanData.data.length > 0) ? ratePlanData.data.filter(itmData => moment(itmData.index.date).format("YYYY-MM-DD") === moment(stay_date).format("YYYY-MM-DD")) : [];
        if (ratePlanData) {
            let marketSegments = getFromState('system_settings>market_segment_category_mapping', state);
            let segments = getTransformedSegments(marketSegments);
            let codes = Object.keys(segments.map).filter((item) => segments.map[item] === segmentName)
            // debugger;
            let ratePlanCodes = [];
            codes.map((code) => {
                if (rateCodesMapping && rateCodesMapping["agg"][code]) {
                    ratePlanCodes = [...ratePlanCodes, ...rateCodesMapping["agg"][code]];
                }
            })

            let finalData = [];
            /** Full final data calc */
            let finalFullData = [];
            let plansTotal = {};

            ratePlanCodes.map((item) => {
                plansTotal[item] = {
                    "data": {
                        "primary": { Rooms: 0, Occ: 0, ADR: 0, Revenue:0 },
                        "ratePlanCode": null
                    }
                };
            })
            /** end full final  */
            ratePlanCodes.map((item) => {
                if (ratePlanData[0] && ratePlanData[0]["Segment_" + item]) {
                    let obj = {};
                    obj.ratePlanCode = item;
                    obj.data = ratePlanData[0]["Segment_" + item];
                    finalData.push(obj);
                }

                ratePlanDataAll.data.map((itm) => {
                    if (itm["Segment_" + item]) {
                        plansTotal[item].ratePlanCode = item
                        plansTotal[item].data.primary['Rooms'] += itm["Segment_" + item].primary.Rooms;
                        plansTotal[item].data.primary['Occ'] += itm["Segment_" + item].primary.Occ;
                        // plansTotal[item].data.primary['ADR'] += itm["Segment_" + item].primary.ADR;
                        plansTotal[item].data.primary.Revenue += itm["Segment_" + item].primary.Revenue;

                        /////
                        let adr = getCorrectValue(plansTotal[item].data.primary.Revenue / plansTotal[item].data.primary['Rooms'])
                        plansTotal[item].data.primary['ADR'] = adr;
                        // console.log(item,"---> ",plansTotal[item].data.primary.Revenue," => ",adr);
                        // console.log(plansTotal[item].data.primary);
                        /////

                        finalFullData.push(plansTotal);
                    }

                })
            })
            setRatePlansTotal(finalFullData);
            // debugger;
            setRatePlans(finalData);
            /** END BL  */
        }
    };
    const handleClose = () => {
        setAnchorEl(null);
    };

    // permissions.hfc =
    return (
        <div className='rateplan-details'>
            <div
                aria-owns={open ? 'mouse-over-popover' : undefined}
                aria-haspopup="true"
                onClick={handleClick}
                // onMouseOver={handleClick}
                // onMouseLeave={handleClose}
                sx={{ lineHeight: 1, fontSize: "18px", mt: "-7px" }}
            >
                {/* <Tooltip title={"Rate Plan Of " + segmentName} arrow followCursor> */}
                {permissions.rate_plan == 1 && <KeyboardDoubleArrowRightIcon sx={{ fontSize: 13, position: "absolute", top: 5, right: 0, cursor: "pointer", color: "#808080" }} />
                }
                {/* </Tooltip> */}
            </div>
            <Menu
                className='rateplan-details'
                anchorEl={anchorEl}
                id="account-menu"
                open={open}
                onClose={handleClose}
                onClick={handleClose}
                PaperProps={{
                    elevation: 0,
                    sx: {
                        overflow: 'visible',
                        filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
                        mt: -3,
                        ml: 2,
                        '& .MuiAvatar-root': {
                            width: 32,
                            height: 32,
                            ml: -0.5,
                            mr: 1,
                        },
                        '&:before': {
                            content: '""',
                            display: 'block',
                            position: 'absolute',
                            top: 10,
                            left: -8,
                            width: 0,
                            height: 0,
                            borderTop: "8px solid transparent",
                            borderBottom: "8px solid transparent",
                            borderRight: "8px solid #7A7A7A",
                            // bgcolor: 'background.paper',
                            // transform: 'translateY(-50%) rotate(45deg)',
                            zIndex: 1,
                        },
                        '&:after': {
                            content: '""',
                            display: 'block',
                            position: 'absolute',
                            top: 10,
                            left: -8,
                            width: 0,
                            height: 0,
                            borderTop: "8px solid transparent",
                            borderBottom: "8px solid transparent",
                            borderRight: "8px solid #7A7A7A",
                            zIndex: 1,
                        },
                    },
                }}
                transformOrigin={{ horizontal: 'left', vertical: 'top' }}
                anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}>

                <Box className='rateplan-box'>
                    <Box className='rateplan-box-title'>{segmentName}
                        <Box sx={{ float: "right", pr: 1 }}>
                            {(stay_date &&
                                <>{moment(stay_date).format("ddd, MMM DD, YYYY")}</>
                            )}
                        </Box>
                    </Box>
                    <Box sx={{ overflow: "auto", maxHeight: 200 }}>
                        <TableContainer sx={{}}>
                            <Table sx={{}}>
                                <TableHead>
                                    <TableRow className='rateplan-table-row' sx={{ '& td, & th': { pt: .5, pb: .2 } }}>
                                        <TableCell className='table-heading' sx={{ width: "40%", borderRight: "1px solid #ddd" }}>Rate Plan</TableCell>
                                        <TableCell className='table-heading-occ'>Occ%</TableCell>
                                        <TableCell className='table-heading'>Rooms</TableCell>
                                        <TableCell className='table-heading-occ'>ADR</TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody sx={{ overflowX: "hidden", overflowY: "auto" }}>
                                    {(stay_date === "" &&
                                        <>
                                            <RatePlanListTotal data={ratePlansTotal} />
                                        </>
                                    )}

                                    {(stay_date !== "" && ratePlans && ratePlans.length > 0 &&
                                        <>
                                            <RatePlanList data={ratePlans} />
                                        </>
                                    )}
                                    {(stay_date !== "" && ratePlans && ratePlans.length <= 0 &&
                                        <>
                                            <TableRow>
                                                <TableCell colSpan={4} className='table-heading' sx={{ textAlign: "center" }}>No Rate Plan Found!</TableCell>
                                            </TableRow>
                                        </>
                                    )}
                                </TableBody>
                            </Table></TableContainer>
                    </Box>
                </Box>

            </Menu>
        </div >
    );
}