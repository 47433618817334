import React from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { updateAppState } from '../../../../actions/appstate';
import { Box, Switch } from '@mui/material'
import moment from "moment";
function ToggleRoomTypeRestrictions() {
    const {workspace_controls, primary_stay_range, as_of_date} = useSelector(state => state.app.current_state.app_state);
    const dispatch = useDispatch();

    const handleChange = ( value) => {

        workspace_controls.roomTypeRestrictions = value;
        let date_range_type = "rangeTileChange";
        if(workspace_controls.roomTypeRestrictions === true){
            date_range_type =  "restrictionsRoomType"
        }
        dispatch(updateAppState("toggleRoomTypeRestrictions", {
            app_state: { 
                workspace_controls: { ...workspace_controls},
                // primary_stay_range:{
                //     ...primary_stay_range,
                //     date_from:as_of_date,
                //     date_to:moment(as_of_date).add(29,'days').format("YYYY-MM-DD")
                // },
                // date_range_type,
                data_load: true
            },
            ui_state: { showspinner: true }
        }));
    }


    return (
        <Box className='control-option' sx={{ backgroundColor: 'blue' }}>
            <Box className='filterElementText' sx={{ ml: .5 }}>Room Type</Box>
            <Switch className='control-switch' checked={workspace_controls.roomTypeRestrictions} onChange={() => {
                handleChange(!workspace_controls.roomTypeRestrictions)
            }} />
            
            {/* <div class="checkbox-wrapper-51" checked={workspace_controls.roomTypeRestrictions} onChange={() => {
                handleChange(!workspace_controls.roomTypeRestrictions)
            }} >
                <input type="checkbox" id="cbx-51" />
                <label for="cbx-51" class="toggle">
                    <span>
                    <svg width="10px" height="10px" viewBox="0 0 10 10">
                        <path d="M5,1 L5,1 C2.790861,1 1,2.790861 1,5 L1,5 C1,7.209139 2.790861,9 5,9 L5,9 C7.209139,9 9,7.209139 9,5 L9,5 C9,2.790861 7.209139,1 5,1 L5,9 L5,1 Z"></path>
                    </svg>
                    </span>
                </label>
            </div> */}
        </Box>
    )
}

export default ToggleRoomTypeRestrictions;
