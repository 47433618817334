import React, { useState, useEffect } from 'react';
import Calendar from 'react-calendar';
import moment from 'moment';
import './style.scss';
import { deepCopy, isMobileDevice, dateFormat, dateDiff } from "../../app/util";
import { updateAppState } from "../../actions/appstate";
import { Button, Popover, ButtonGroup, TextField, Grid } from "@mui/material/";
import { ArrowBackIos as ArrowBackIosIcon, ArrowForwardIos as ArrowForwardIosIcon } from '@mui/icons-material';
import { useSelector } from "react-redux";
import { useDateRanges } from "../../hooks/date-picker-logics";
import './styles.css';

import { style } from '@mui/system';
import { useDispatch } from 'react-redux';
// You can customize style by copying asset folder.
export default function CompDatePicker(props) {
  // debugger;
  const dateRanges = useDateRanges();

  const { current_state } = useSelector((state) => state.app);
  const { app_state, ui_state } = current_state;
  const { active_tiles } = ui_state;

  const { comp_stay_range } = app_state;
  let asofDate = comp_stay_range.as_of_date;
  // debugger;
  const dispatch = useDispatch();
  let [tempDateRange, setTempDateRange] = useState(comp_stay_range);
  let [displayDateRange, setDisplayDateRange] = useState(props.dateRange);
  let [comp_as_of_date, setAsOfDate] = useState(asofDate)
  const [isOpen, setIsOpen] = useState(false)
  let [range_type, setRangeType] = useState("single_date");
  let [range_segment, setRangeSegment] = useState("");
  let [calendarOn, setCalendarOn] = useState("single");
  const [datePickerConfig, setDatePickerConfig] = useState(props.datePickerConfig)
  const [applyAsOfBtn, setApplyAsOfBtn] = useState({})
  const [initialLoad, setIntitialLoad] = useState({})
  const [showRangeSegment, setShowRangeSegment] = useState(true);
  const [anchorEl, setAnchorEl] = React.useState(null);
  let { navigation } = datePickerConfig;
  

  const openDatePicker = Boolean(anchorEl);
  const id = openDatePicker ? 'date-picker-popover' : undefined;

  let singleDate = moment(tempDateRange.date_from, "YYYY-MM-DD").toDate();
  let asOfDate = moment(comp_as_of_date, "YYYY-MM-DD").toDate();
  let selector = (<span></span>);

  const [dateRange, setDateRange] = useState([moment(tempDateRange.date_from).toDate(), moment(tempDateRange.date_until).toDate()]);
  // let inputDateFrom = moment(tempDateRange.date_from).format("MM/DD/YYYY");
  // let inputDateUntil = moment(tempDateRange.date_until).format("MM/DD/YYYY");
  let { date_from, date_until } = app_state.comp_stay_range;

  useEffect(() => {
    let isSubscribed = true;
    // debugger;
    const { scopeType } = app_state.workspace_controls;
    const { date_from, date_until, comp_as_of_date } = app_state.comp_stay_range;

    setTempDateRange({ ...tempDateRange, ...app_state.comp_stay_range });
    setDisplayDateRange({ ...displayDateRange, ...app_state.comp_stay_range });

    if(app_state.comp_stay_range.as_of_date !== tempDateRange.as_of_date){
      // debugger;
      onDateChange(null, app_state.comp_stay_range.as_of_date, 'as_of');
      setRangeType('range');
      applyChange('comp_as_of_date');
      // setAsOfDate(app_state.comp_stay_range.as_of_date);
    }

    
    // if (app_state.comp_range_type!=='') {
    // debugger;
    let from_value = moment(date_from);
    let until_value = moment(date_until);
    setCalendarOn('single_date');
    if (scopeType === 'Dba') {
      onDateChange(null, [from_value], 'single');
      // onDateChange(null, [until_value], 'date_until');
      setRangeType('single_date');
      applyChange('compStaydates');
    }
    if (scopeType === 'Date') {
      onDateChange(null, [from_value], 'date_from');
      onDateChange(null, [until_value], 'date_until');
      setRangeType('range');
      // applyChange('staydates');
      applyChange('compStaydates');
    }

    

    // }

    return () => (isSubscribed = false)
  }
    , [
      // active_tiles.join(),
      app_state.workspace_controls.scopeType,
      app_state.primary_stay_range.date_from,
      app_state.primary_stay_range.date_until,
      app_state.comp_stay_range.as_of_date,
      
      // app_state.primary_stay_range
      // app_state.comp_stay_range.comp_as_of_date,
      // app_state.comp_stay_range.date_from,
      // app_state.comp_stay_range.date_until
    ]
  );

  const applyChange = (key) => {
    // debugger;

    // let t = datePickerConfig.dateRangeKey;

    // console.log(app_state.primary_stay_range.date_from);
    let d1 = app_state.primary_stay_range.date_from;
    let d2 = app_state.primary_stay_range.date_until;
    // let daysDiffCount = Math.abs(moment.duration(moment(d2).diff(moment(d1))).asDays());
    let daysDiffCount = Math.abs(dateDiff(d2, d1));
    let comFromDate = tempDateRange.date_from;
    let comToDate = moment(comFromDate).add(daysDiffCount, 'days').format("YYYY-MM-DD");

    tempDateRange.comp_as_of_date = app_state.comp_stay_range.as_of_date;
    tempDateRange.as_of_date = app_state.comp_stay_range.comp_as_of_date;

    let date_until_comp = {
      date_until: comToDate
    }
    tempDateRange = { ...tempDateRange, ...date_until_comp };
    // debugger;
    if ("compStaydates" === key) {
      setTempDateRange(tempDateRange);
      dispatch(updateAppState('comp_stay_range', {
        app_state: {
          ...app_state,
          comp_range_type: 'rangeTileChange',
          date_range_type: '',
          workspace_controls: { ...app_state.workspace_controls },
          comp_as_of_date: app_state.comp_stay_range.comp_as_of_date,
          comp_stay_range: { ...app_state.comp_stay_range, ...tempDateRange },
          // data_load: true,
          // kpi_load: true
        },
        ui_state: { ...ui_state, showspinner: true }
      }))
    }
    if ("staydates" === key) {
      if (datePickerConfig.dateRangeKey === "comp_stay_range") {
        // debugger;
        //This will make latest stay_range available while calling data
        //if date range is not changed, make kpi_load value false

        //app_state.comp_stay_range = { ...tempDateRange };
        app_state.workspace_controls.clearComparision = true;
        // debugger;
        dispatch(updateAppState('comp_stay_range', {
          app_state: {
            ...app_state,
            comp_range_type: 'rangeTileChange',
            // date_range_type: '',
            workspace_controls: { ...app_state.workspace_controls },
            comp_as_of_date: app_state.comp_stay_range.comp_as_of_date,
            comp_stay_range: { ...app_state.comp_stay_range, ...tempDateRange },
            data_load: true,
            // kpi_load: true
          },
          ui_state: { ...ui_state, showspinner: true }
        }))
      }
    }
    if ("comp_as_of_date" === key) {
      // debugger;
      tempDateRange.comp_as_of_date = comp_as_of_date;
      tempDateRange.as_of_date = comp_as_of_date;
      setDisplayDateRange(tempDateRange);
      setTempDateRange(tempDateRange);
      dispatch(updateAppState('comp_stay_range', {
        app_state: {
          ...app_state,
          workspace_controls: { ...app_state.workspace_controls },
          comp_stay_range: { ...tempDateRange },
          comp_as_of_date,
          //date_range_type: 'compAsOfChanged',
          data_load: true,
          kpi_load: true
        },
        ui_state: { ...ui_state, showspinner: true }
      }))
      setApplyAsOfBtn(false)
    }
    // debugger;
    let dateRange = deepCopy(tempDateRange);
    dateRange.comp_as_of_date = comp_as_of_date;
    dateRange.as_of_date = comp_as_of_date;
    setShowRangeSegment(true);
    //setIsOpen(false);
    setDisplayDateRange(dateRange);
    setAnchorEl(null);


    // app_state.workspace_controls.clearComparision = true;
    // dispatch(updateAppState("clear_comparision_button_click", {
    //   app_state: { workspace_controls: app_state.workspace_controls, data_load: true },
    //   ui_state: { showspinner: true }
    // }));
  }

  const determineRangeSegment = (tempDateRange, type) => {
    let daysDiff = dateDiff(tempDateRange.date_until, tempDateRange.date_from);
    if (type === "comparisonStayDates" && daysDiff === 364) {
      return "sdly";
    }
    let startOfMonth = moment(tempDateRange.date_from).startOf('month');
    let endOfMonth = moment(tempDateRange.date_until).endOf('month');
    if (dateDiff(startOfMonth, tempDateRange.date_from) === 0 && dateDiff(endOfMonth, tempDateRange.date_until) === 0) {
      return "current_month";
    }
    let isToday = (dateDiff(moment(), tempDateRange.date_from) === 0) ? true : false;
    if (isToday) {
      if (daysDiff === 30 || daysDiff === 60 || daysDiff === 90) {
        return daysDiff;
      }
    }
    else {
      return "custom";
    }

  }
  const shiftBackward = () => {
    let primaryFrom = new moment(app_state.primary_stay_range.date_from);
    let primaryUntil = new moment(app_state.primary_stay_range.date_until);
    let diffDays = dateDiff(primaryUntil, primaryFrom);

    let dateFrom = new moment(app_state.comp_stay_range.date_from);
    let dateUntil = new moment(app_state.comp_stay_range.date_until);
    // debugger;
    // tempDateRange.date_from = dateFrom.format("YYYY-MM-DD");
    // tempDateRange.date_until = dateUntil.format("YYYY-MM-DD");

    if (diffDays === 0) {
      tempDateRange.date_from = dateFrom.subtract(1, 'day').format("YYYY-MM-DD");
      tempDateRange.date_until = tempDateRange.date_from;
      app_state.comp_stay_range.date_from = tempDateRange.date_from;
      app_state.comp_stay_range.date_until = tempDateRange.date_until;
    }
    if (diffDays > 0) {
      // debugger;
      tempDateRange.date_from = new moment(tempDateRange.date_from).subtract(diffDays + 1, 'day').format("YYYY-MM-DD");
      tempDateRange.date_until = new moment(tempDateRange.date_from).add(diffDays, 'day').format("YYYY-MM-DD");
      app_state.comp_stay_range.date_from = tempDateRange.date_from;
      app_state.comp_stay_range.date_until = tempDateRange.date_until;
    }
    
    if (datePickerConfig.dateRangeKey === "comp_stay_range") {
      displayDateRange.date_from = tempDateRange.date_from;
      displayDateRange.date_until = tempDateRange.date_until;

      onDateChange(null, [tempDateRange.date_from], 'date_from');
      onDateChange(null, [tempDateRange.date_until], 'date_until');
      setRangeType('range');
      setDisplayDateRange(displayDateRange);
      setTempDateRange(tempDateRange)
      // applyChange('staydates');
      // applyChange('compStaydates');
      // debugger
      dispatch(updateAppState('stay_range', {
        app_state: {
          comp_range_type: 'backward',
          comp_stay_range: { ...app_state.comp_stay_range },
          data_load: true,
          // kpi_load: true
        },
        ui_state: { ...ui_state, showspinner: true }
      }));
      
      
    }

  }

  const shiftFoward = () => {
    // debugger;
    // let isMonth = false;
    // let startOfMonth = moment(tempDateRange.date_from).startOf('month');
    // let endOfMonth = moment(tempDateRange.date_until).endOf('month');

    let primaryFrom = new moment(app_state.primary_stay_range.date_from);
    let primaryUntil = new moment(app_state.primary_stay_range.date_until);
    let diffDays = dateDiff(primaryUntil, primaryFrom);

    // let dateFrom = new moment(app_state.comp_stay_range.date_until);
    // let dateUntil = new moment(app_state.comp_stay_range.date_until);

    // tempDateRange.date_from = dateFrom.format("YYYY-MM-DD");
    // tempDateRange.date_until = dateUntil.format("YYYY-MM-DD");

    if (diffDays === 0) {
      let dateFrom = new moment(app_state.comp_stay_range.date_until);
      let dateUntil = new moment(app_state.comp_stay_range.date_until);
      tempDateRange.date_from = dateFrom.add(1, 'day').format("YYYY-MM-DD");
      tempDateRange.date_until = tempDateRange.date_from;
      app_state.comp_stay_range.date_from = tempDateRange.date_from;
      app_state.comp_stay_range.date_until = tempDateRange.date_until;
    }
    if (diffDays > 0) {
      let dateFrom = new moment(app_state.comp_stay_range.date_until);
      let dateUntil = new moment(app_state.comp_stay_range.date_until);
      tempDateRange.date_from = dateFrom.add(1, 'day').format("YYYY-MM-DD")
      tempDateRange.date_until = dateUntil.add(diffDays + 1, 'day').format("YYYY-MM-DD");
      app_state.comp_stay_range.date_from = tempDateRange.date_from;
      app_state.comp_stay_range.date_until = tempDateRange.date_until;
    }

    displayDateRange.date_from = tempDateRange.date_from;
    displayDateRange.date_until = tempDateRange.date_until;
    setDisplayDateRange(displayDateRange);
    setTempDateRange(tempDateRange);
    onDateChange(null, [tempDateRange.date_from], 'date_from');
    onDateChange(null, [tempDateRange.date_until], 'date_until');
    setRangeType('range');
    // applyChange('staydates');
    // applyChange('compStaydates');

    if (datePickerConfig.dateRangeKey === "comp_stay_range") {
      dispatch(updateAppState('stay_range', {
        app_state: {
          // comp_range_type: 'forward',
          comp_stay_range: { ...app_state.comp_stay_range },
          data_load: true,
          // kpi_load: true
        },
        ui_state: { ...ui_state, showspinner: true }
      }))
    }

  }

  const onDateChange = (event, date, key) => {

    app_state.workspace_controls.clearComparision = true;
    dispatch(updateAppState("clear_comparision_button_click", {
      app_state: { workspace_controls: app_state.workspace_controls, data_load: false },
      ui_state: { showspinner: false }
    }));


    calendarOn = "";
    if (datePickerConfig.type === "comparisonStayDates") {
      if ("date_from" === key) {
        tempDateRange.date_from = moment(date[0]).format("YYYY-MM-DD");
        tempDateRange.date_until = moment(tempDateRange.date_from).add(datePickerConfig.comparisonDiff, 'days').format("YYYY-MM-DD");
      }

    } else {
      if ("single" === key) {
        tempDateRange.date_from = moment(date[0]).format("YYYY-MM-DD");
        tempDateRange.date_until = tempDateRange.date_from;
      }

      if ("as_of" === key) {
        comp_as_of_date = moment(date).format("YYYY-MM-DD");
        setAsOfDate(comp_as_of_date);
        setApplyAsOfBtn(true);
      }
      if ("date_from" === key) {
        tempDateRange.date_from = moment(date[0]).format("YYYY-MM-DD");
        if (moment(tempDateRange.date_until).diff(moment(tempDateRange.date_from), 'days') < 0) {
          tempDateRange.date_until = tempDateRange.date_from
        }
        setRangeSegment("custom");
        setShowRangeSegment(true);
      }
      if ("date_until" === key) {
        tempDateRange.date_until = moment(date[0]).format("YYYY-MM-DD");
        if (moment(tempDateRange.date_until).diff(moment(tempDateRange.date_from), 'days') < 0) {
          tempDateRange.date_from = tempDateRange.date_until
        }
        setRangeSegment("custom");
        setShowRangeSegment(true);
      }
      if ("weeks" === key) {
        if (event && event.shiftKey === true) {
          let orgStartDate = moment(tempDateRange.date_from, "YYYY-MM-DD").toDate();
          let orgEndDate = moment(tempDateRange.date_until, "YYYY-MM-DD").toDate();
          let start = moment(date[0]).startOf("week"), end = moment(date[0]).endOf("week");

          if (moment(date[0]).diff(orgStartDate, 'days') < 0) {
            start = moment(date[0]).startOf("week");
            end = moment(orgEndDate).endOf("week");
          }

          if (moment(date[0]).diff(orgEndDate, 'days') > 0) {
            end = moment(date[0]).endOf('week');
            start = moment(orgStartDate).startOf("week");

          }
          if (moment(date[0]).diff(orgEndDate, 'days') < -7 && moment(date[0]).diff(orgStartDate, 'days') > 0) {
            end = moment(date[0]).endOf('week');

          }

          tempDateRange.date_from = moment(start).format("YYYY-MM-DD");
          tempDateRange.date_until = moment(end).format("YYYY-MM-DD");
          // dateRange = {date_from: tempDateRange.date_from, date_to: tempDateRange.date_to}
        }
        else {
          let start = moment(date[0]).startOf('week');
          let end = moment(date[0]).endOf('week');
          tempDateRange.date_from = moment(start).format("YYYY-MM-DD");
          tempDateRange.date_until = moment(end).format("YYYY-MM-DD");

          calendarOn = "weeks";

          //setRangeType("range")
        }

      }
      if ("months" === key) {
        if (event && event.shiftKey === true) {

          let start = moment(date[0]).startOf('month');
          let end = moment(date[0]).endOf('month');
          if (moment(date[0]).diff(moment(tempDateRange.date_from, "YYYY-MM-DD"), 'days') < 0) {
            start = moment(date[0]).startOf("month");
            end = moment(moment(tempDateRange.date_until, "YYYY-MM-DD")).endOf("month");
          }

          if (moment(date[0]).diff(moment(tempDateRange.date_until, "YYYY-MM-DD"), 'days') > 0) {
            end = moment(date[0]).endOf('month');
            start = moment(moment(tempDateRange.date_from, "YYYY-MM-DD")).startOf("month");

          }

          tempDateRange.date_from = moment(start).format("YYYY-MM-DD");
          tempDateRange.date_until = moment(end).format("YYYY-MM-DD");
        } else {
          let start = moment(date[0]).startOf('month');
          let end = moment(date[0]).endOf('month');
          tempDateRange.date_from = moment(start).format("YYYY-MM-DD");
          tempDateRange.date_until = moment(end).format("YYYY-MM-DD");
          //calendarOn="months";
          //setRangeType("range")
        }

      }
    }
    setCalendarOn(calendarOn);
    setTempDateRange(tempDateRange);
    setDateRange([moment(tempDateRange.date_from).toDate(), moment(tempDateRange.date_until).toDate()])


  }

  const rangeSegmentChange = (range_segment) => {
    // debugger;
    if (range_segment === "current_month") {
      tempDateRange.date_from = moment().startOf('month').format("YYYY-MM-DD");
      tempDateRange.date_until = moment().endOf('month').format("YYYY-MM-DD");
    }
    if (range_segment === 30 || range_segment === 60 || range_segment === 90) {
      tempDateRange.date_from = moment().format("YYYY-MM-DD");
      tempDateRange.date_until = moment().add(range_segment - 1, 'days').format("YYYY-MM-DD");

    }
    if (range_segment === "custom") {
      tempDateRange.date_from = moment().format("YYYY-MM-DD");
      tempDateRange.date_until = moment().add(range_segment - 1, 'days').format("YYYY-MM-DD");
      // calendarOn = "date_from";
      // setShowRangeSegment(false);
    }
    //  if(range_segment === "sdly"){
    //   tempDateRange.date_from = moment(tempDateRange.date_from).subtract(364, "days");
    //   tempDateRange.date_until = moment(tempDateRange.date_until).subtract(364, "days");
    //   initialLoad =false;
    //  }
    setTempDateRange(tempDateRange);
    setRangeSegment(range_segment);
    // setCalendarOn(calendarOn);
    setCalendarOn('');
  }

  let fromDateCalendar = (<div id="date-picker-calendar">
    <Calendar
      // allowPartialRange={true}
      onChange={(date) => { onDateChange(null, date, "date_from"); }}
      defaultValue={dateRange}
      returnValue="range"
      calendarType="US"
      minDetail="year"
      formatMonth={(locale, date) => dateFormat(date, 'MMM')}
    />
  </div>);


  let toDateCalendar = (<div id="date-picker-calendar">
    <Calendar
      onChange={(date) => { onDateChange(null, date, "date_until"); }}
      defaultValue={dateRange}
      returnValue="range"
      calendarType="US"
      minDetail="year"
      formatMonth={(locale, date) => dateFormat(date, 'MMM')}

    />
  </div>);

  let singleCalendar = (<div id="single-date-picker-calendar">
    <Calendar
      onChange={(date) => { onDateChange(null, date, "single"); }}
      value={dateRange}
      returnValue="range"
      calendarType="US"
      minDetail="year"
      formatMonth={(locale, date) => dateFormat(date, 'MMM')}
    />
  </div>);
  let weeksCalendar = (<div id="week-picker-calendar">
    <Calendar
      onChange={(date) => { onDateChange(null, date, "weeks"); }}
      value={dateRange}
      returnValue="range"
      calendarType="US"
      maxDetail="month"
      minDetail="year"
      view="month"
      formatMonth={(locale, date) => dateFormat(date, 'MMM')}

    />
  </div>);
  let monthsCalendar = (<div id="month-picker-calendar">
    <Calendar
      onChange={(date) => { onDateChange(null, date, "months"); }}
      value={dateRange}
      returnValue="range"
      calendarType="US"
      maxDetail="year"
      view="year"
      minDetail="year"
      formatMonth={(locale, date) => dateFormat(date, 'MMM')}


    />
  </div>);
  let asOfCalendar = (<div id="date-picker-calendar-asof">
    <Calendar
      onChange={(date) => { onDateChange(null, date, "as_of"); }}
      value={asOfDate}
      returnValue="start"
      calendarType="US"
      minDetail="year"
      formatMonth={(locale, date) => dateFormat(date, 'MMM')}
    />
  </div>);
  let rangeSegmentLabels = navigation.range.range_segments.map((segment, index) => {
    let label = "";
    switch (segment) {
      case "current_month":
        label = <><span className='this-month'>This</span><span className='days-label'> Month</span></>;
        break;
      case 30:
        label = <><span className='next-label'>next</span><span className='days-num'>30</span><span className='days-label'> days</span></>;
        break;
      case 60:
        label = <><span className='next-label'>next</span><span className='days-num'>60</span><span className='days-label'> days</span></>;
        break;
      case 90:
        label = <><span className='next-label'>next</span><span className='days-num'>90</span><span className='days-label'> days</span></>;
        break;
      case "sdly":
        label = "Same Period, SDLY";
        break;
      case "custom":
        label = "Custom";
        break;
    }
    return (<Button key={index} className={(range_segment === segment) ? "days-num-btn active" : "days-num-btn"} onClick={() => { rangeSegmentChange(segment) }}>{label}</Button>)
  })
  // if(active_tiles[0] === "pace"){
  // setRangeType("weeks");
  // }
  let ranges = Object.entries(navigation);
  let rangeTypes = ranges.map((item, index) => {
    let disabledClass = (active_tiles[0] === "pace" && item[0] !== "single_date") ? "disabled-range-type" : "";
    return (<label key={index} className={(range_type === item[0]) ? disabledClass + " btn btn-default active" : "btn btn-default " + disabledClass} onClick={() => {
      if (!disabledClass) {

        let range_type = item[0];
        if (range_type === "weeks" || range_type === "months") {
          calendarOn = range_type;
        }
        setCalendarOn(range_type);
        setRangeType(range_type);
        setShowRangeSegment(true);

      }
      // this.setState({range_type:range_type,calendarOn:"", showRangeSegment:true, rangeTypeChanged:true});

    }}>{item[1].label}</label>)
  });

  // setCalendarOn(range_type);
  // setRangeType(range_type);
  range_type = "single_date";
  // setCalendarOn("single");
  let singleCompDate = (app_state.workspace_controls.clearComparision) ?
                         moment(singleDate).format("MM/DD/YYYY") : "";
  switch (range_type) {
    case "single_date":
      selector = (<div className="range-selector row" style={{ "paddingTop": "10px" }}>
        <div style={{ "display": "block", "width": "100%" }}>
          <div className="form-group col-md-12" style={{ "margin-bottom": 0 }}>
            {/* <TextField
                label="Comparision Date"
                size="small"
                id="singleDatePicker"
                className='form-control'
                onBlur={() => {
                  // setCalendarOn("")
                }}
                name="singleDate"
                onChange={() => { }} value={singleCompDate} onClick={() => { setCalendarOn("single") }} /> */}
            <input type="text" placeholder='Select Comparison Date' label="" className="form-control test" onChange={() => { }} name="singleDate" value={singleCompDate} id="singleDatePicker" onClick={() => { setCalendarOn("single") }} />
          </div>
        </div>
        {/* {(calendarOn === "single") && singleCalendar} */}
        {((calendarOn === "single" || calendarOn === "") &&
          <>
            {singleCalendar}
          </>
        )}
        {/* <div className="col-md-12 btn-apply">
          <button type="button" className="btn btn-info btn-block" onChange={() => { }} onClick={() => applyChange("staydates")}>Apply</button>
        </div> */}
      </div>);
      break;
  }
  let popoverStyles = { "top": "0px", "display": "block", "visibility": "visible", "width": "300px", };
  let isMobile = isMobileDevice();
  if (isMobile) {
    popoverStyles.top = "-40px";
    popoverStyles.transform = "scale(.65)";
  }
  let singleCompAsOfDate = (app_state.workspace_controls.clearComparision) ? moment(asOfDate).format("MM/DD/YYYY") : "";

  let popup = (<div className="popover bottom-left" style={popoverStyles}>
    <div className="arrow"></div>
    <div className="popover-content" style={{ "padding": "9px" }}>
      <div>
        {/* <div className="btn-group btn-group-justified range-types " >
          {rangeTypes}
          <label key={Math.random()} className=" btn btn-default active"> Comparison Date -1</label>
        </div> */}
        {selector}

        {/* {(navigation[range_type].comp_as_of_date === true) && (<div className="asof-selector clearfix" style={{ "clear": "both" }}> */}

        {/* <hr /> */}

        <Grid container spacing={1} sx={{ mt: 1.5 }}>
          <Grid item xs={7}>
            {(app_state.workspace_controls.scopeType !== "Dba" && ui_state.active_tiles[0] !== "bi_sensitivity_analysis" &&
              <div>
                <TextField
                  label="As Of Date"
                  size="small"
                  id="asOfDate"
                  className='date-picker'
                  onBlur={() => {
                    // setCalendarOn("")
                  }}
                  onChange={() => { }} value={singleCompAsOfDate} onClick={() => { setCalendarOn("as_of") }} />
              </div>
            )}
          </Grid>
          <Grid item xs={5}>
            <div>{(<button type="button" className="btn btn-info btn-block" onClick={() => applyChange("comp_as_of_date")} >Apply</button>)}</div>
          </Grid>


          <Grid item xs={12}>
            {(calendarOn === "as_of" && calendarOn && <div className='calendar-box'>

              {asOfCalendar}
            </div>)}
          </Grid>

        </Grid>
        <div className="row">


        </div>

        {/* </div>
        )} */}
      </div>
    </div>
  </div>
  )
  let shiftBackwardBtn = (<button type="button" className="btn-stay-range shift-prev" onClick={shiftBackward}><ArrowBackIosIcon fontSize='small' /></button>);
  let shiftFowardBtn = (<button type="button" className="btn-stay-range shift-next" onClick={shiftFoward}><ArrowForwardIosIcon fontSize='small' /></button>);
  let topLabel = (<span></span>);
  if (!app_state.workspace_controls.clearComparision || ui_state.active_tiles[0] === "bi_sensitivity_analysis") {
    topLabel = (<label className="label">Comparison Date
    </label>);
  } else if (datePickerConfig.type === "primaryStayDates" && app_state.workspace_controls.scopeType !== "Dba" && ui_state.active_tiles[0] !== "bi_sensitivity_analysis") {
    topLabel = (<label className="label">Comparision Dates -
      <span className="date-text"> AS OF </span>
      <span className="">{dateFormat(displayDateRange.comp_as_of_date, "ddd, MMM DD, YYYY")}</span>
    </label>);
  } else {
    topLabel = (<label className="label"> </label>);
  }
  return (
    <>


      <div className="smart-date-picker" style={{ "display": " inline-block", "verticalAlign": "top" }}>
        <div className="chart-stay-date clearfix current-date-range" id="stay-date">
          {topLabel}
          <div className="stay-date-picker clearfix" id="stay-date-picker-id">
            {(datePickerConfig.shiftBtns === true) && shiftBackwardBtn}
            <button type="button" className="btn-stay-picker clearfix"
              onClick={(event) => {
                // debugger;
                setAnchorEl(event.currentTarget)
                if (!openDatePicker) {
                  calendarOn = calendarOn ? calendarOn : "";
                  //range_type = "range";
                  range_segment = determineRangeSegment(tempDateRange);
                  setShowRangeSegment(true);
                }
                // window.addEventListener("touchstart", function(e){
                //   let $target = $(e.target);
                //       if(!$target.closest('.smart-date-picker').length) {
                //           that.setState({open:false, initialLoad: false});
                //       }
                // });

                // setIsOpen(!isOpen);
                setCalendarOn(calendarOn);
                setRangeSegment(range_segment);
                setRangeType(range_type)
              }}
            >
              {(datePickerConfig.dateRangeKey === "comparison_stay_range" && initialLoad) && (<span>Select Dates</span>)}
              {
                ((datePickerConfig.dateRangeKey !== "comparison_stay_range" || !initialLoad) && app_state.workspace_controls.clearComparision) ?
                  <span>
                    <span className="rangeFromDate ">{dateFormat(displayDateRange.date_from, "ddd, MMM DD")}</span> -
                    <span className="rangeToDate" >{dateFormat(displayDateRange.date_until, "ddd, MMM DD, YYYY")}</span>
                  </span>
                  : <>
                    Select Date for Comparison</>
              }
            </button>
            <Popover
              PaperProps={{ elevation: 0, sx: { backgroundColor: 'transparent', boxShadow: 'none', mt: -1 } }}
              id={id}
              open={openDatePicker}
              anchorEl={anchorEl}
              onClose={() => { setAnchorEl(null) }}
              anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'left',
              }}
              sx={{ backgroundColor: 'transparent' }}
              className="elevation0 date-picker-popover"
            >
              <div className='date-picker-wrapper'>
                {popup}
              </div>

            </Popover>
            {(datePickerConfig.shiftBtns === true) && shiftFowardBtn}

          </div>
        </div>



      </div>
    </>);

}