import { useState } from "react";
import { Button, Tooltip } from '@mui/material';
import CircularProgress, {
    circularProgressClasses,
} from '@mui/material/CircularProgress';
import GridOnIcon from '@mui/icons-material/GridOn';
import React, { useEffect } from "react";
import { useDispatch, useSelector } from 'react-redux';
import { updateAppState } from '../../../../actions/appstate';
import FileDownloadIcon from '@mui/icons-material/FileDownload';
import { API_BASE_URL, API_REPORT_URL } from "../../../../app/config";
import BarChartIcon from '@mui/icons-material/BarChart';
import BarChartSharpIcon from '@mui/icons-material/BarChartSharp';
import moment from 'moment';
import dataService, { fetchDataSimple } from "../../../../services/data.service";
import { in_array, replaceMultiText } from '../../../../app/util';


import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { Slide, Zoom, Flip, Bounce } from 'react-toastify';

import LinearProgress from '@mui/material/LinearProgress';
import MultiSegmentPicker from '../leftComponents/MultiSegmentPicker';

// this component is my test of a state change of only the workspace control - Neeval

const GridViewButton = () => {
    const dispatch = useDispatch();
    const { app_state, ui_state } = useSelector(state => state.app.current_state);
    const { app, auth } = useSelector((state) => state);
    const { sdlyOn, isRoomTypePricing, segmentType } = app_state.workspace_controls;
    const [view, setView] = useState(ui_state.workspace_view == 'table' ? 'table' : 'chart');

    useEffect(() => {
        let isSubscribed = true;
        // debugger;
        if (!app_state.workspace_controls.disableExport) {

            dispatch(updateAppState("exportStatus", {
                app_state: {
                    workspace_controls: { ...app_state.workspace_controls, disableExport: false, }
                }
            }));
        } else {
            dispatch(updateAppState("exportStatus", {
                app_state: {
                    workspace_controls: { ...app_state.workspace_controls, disableExport: true, }
                }
            }));
        }
        return () => (isSubscribed = false);
    }, [app_state.workspace_controls.disableExport]);

    const handleSwitch = () => {
        let workspace_view = 'table';
        if (ui_state.workspace_view === 'table') {
            ui_state.workspace_view = 'chart';
        } else {
            ui_state.workspace_view = 'table';
        }
        ui_state.workspace_view_changed = true;
        app_state.user_view = ui_state.workspace_view;
        ui_state.showSpinner = true;

        setView(ui_state.workspace_view);

        setTimeout(() => {
            dispatch(updateAppState("toggleChartTableView", {
                ui_state: {
                    ...ui_state,
                    // user_view: workspace_view,
                    // workspace_view,
                },
                app_state: {
                    ...app_state,
                    data_load: true,
                }
            }, "toggle"));
        }, 50);



    }

    const exportReportTableToExcel = () => {
        // debugger;
        dispatch(updateAppState("toggleChartTableView", {
            app_state: {
                workspace_controls: { ...app_state.workspace_controls, downloadExportButton: true, }
            }
        }));
    }

    const exportReportToExcel = () => {
        let apiUrl;
        let market_id = app_state.selected_market.market_id;
        let user_id = auth.user.user_id
        let from_date = moment(app_state.as_of_date).format("YYYY-MM-01");
        let to_date = moment(app_state.as_of_date).add(11, "M").endOf("month").format("YYYY-MM-DD");
        let msg;
        const request = {
            "asOfDate": app_state.as_of_date,
            "method": "GET",
        };
        const correction = {
            "{pid}": app_state.property_id,
            "{asOfDate}": app_state.as_of_date,
            "{mid}": market_id,
            // "{toDate}": 
        };

        let tileName;
        if (ui_state.active_tiles[0] === "market_summary") {
            apiUrl = "reports/market-summary-report/{mid}/?asOfDate={asOfDate}";
            apiUrl = replaceMultiText(apiUrl, correction);
            tileName = "Market Summary"
        } else if (ui_state.active_tiles[0] === "hotel_summary") {
            apiUrl = "reports/hotel-summary-report/{pid}/?asOfDate={asOfDate}";
            apiUrl = replaceMultiText(apiUrl, correction);
            tileName = "Hotel Summary"
        }

        msg = "Generating the report, please wait..."
        const exportToast = toast(<div><LinearProgress className='toast-popup-progress' color='green' /> {msg}</div>, {
            position: "bottom-right",
            autoClose: false,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "light",
            transition: Slide
        });

        // dispatch(updateAppState('downloadReport', {
        //     ui_state: {
        //         header_alert_msg: tileName + " report is generating , please wait..."
        //     }
        // }));

        // debugger;
        let api = apiUrl;
        fetchDataSimple({ api, request })
            .then((response) => {
                if (response.status == "success") {
                    toast.update(exportToast, {
                        render: "Report Successfully Downloaded.",
                        type: toast.TYPE.SUCCESS,
                        autoClose: 3000
                    })
                    if (response.data.length > 0) {
                        window.location.href = response.data[0].url;
                    }
                }

            })
            .catch((error) => {
                msg = "Some error occour.Please try again.";
                toast.update(exportToast, {
                    render: msg,
                    type: toast.TYPE.ERROR,
                    autoClose: 3000
                })
                // setTimeout(() => {
                //     dispatch(updateAppState('onHideAlert', {
                //         ui_state: {
                //             header_alert_msg: "",
                //         }
                //     }));
                // }, 3000);
            });
    }

    let icon;
    let iconExportReport;
    let multiPicker;
    let enableSwitch = !app_state.workspace_controls.disableExport;
    let reportsArr = ["bi_pace_comparison", "productionMarketReport", "productionReport", "revpar_index", "bi_pickup_analysis", "flights", "rm_room_type_ratesend_status", "DailyRatesendStatus", "budget","hotel_summary","market_summary"];
    if (!in_array(reportsArr, ui_state.active_tiles) && !isRoomTypePricing) {
        if (view === 'chart') {
            icon = (
                <Tooltip title="Table View" followCursor>
                    <GridOnIcon onClick={handleSwitch} className='grid-view' />
                    {/* <svg onClick={handleSwitch} className='grid-view' xmlns="http://www.w3.org/2000/svg" viewBox="0 0 256 256"><rect width="256" height="256" fill="none"/><path d="M32,56H224a0,0,0,0,1,0,0V192a8,8,0,0,1-8,8H40a8,8,0,0,1-8-8V56A0,0,0,0,1,32,56Z" fill="none" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="16"/><line x1="32" y1="104" x2="224" y2="104" fill="none" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="16"/><line x1="32" y1="152" x2="224" y2="152" fill="none" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="16"/><line x1="88" y1="104" x2="88" y2="200" fill="none" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="16"/></svg> */}
                </Tooltip>
            )
        }

        if (view === 'table' && ui_state.show_chart_icon) {
            icon = (
                <Tooltip title="Chart View" followCursor>
                    <BarChartSharpIcon onClick={handleSwitch} className='chart-view' />
                    {/* <svg onClick={handleSwitch} className='chart-view' xmlns="http://www.w3.org/2000/svg" viewBox="0 0 256 256"><rect width="256" height="256" fill="none"/><polyline points="48 208 48 136 96 136" fill="none" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="16"/><line x1="224" y1="208" x2="32" y2="208" fill="none" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="16"/><polyline points="96 208 96 88 152 88" fill="none" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="16"/><polyline points="152 208 152 40 208 40 208 208" fill="none" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="16"/></svg> */}
                </Tooltip>
            )
        }
        if (enableSwitch === false) {
            icon = "";
        }
    }

    if (ui_state.active_tiles[0] === "otb_v2_multi_segment") {
        multiPicker = (<MultiSegmentPicker />);
    }
    // debugger; 
    if (ui_state.active_tiles[0] === "market_summary"
        || ui_state.active_tiles[0] === "hotel_summary"
    ) {
        iconExportReport = (
            <Button disabled={app_state.workspace_controls.disableExport} size='small' className="control-btns" sx={{ minWidth: 'auto', ml: -2, mr: 1 }}>
                <Tooltip title="Download Report" arrow followCursor>
                    <FileDownloadIcon onClick={exportReportToExcel} fontSize="large" sx={{ mt: 1.2, mr: "-6px" }}
                        className='downloadBtn' />
                    {/* <svg onClick={exportReportToExcel} className='downloadBtn' xmlns="http://www.w3.org/2000/svg" viewBox="0 0 256 256"><rect width="256" height="256" fill="none"/><line x1="128" y1="152" x2="128" y2="40" fill="none" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="16"/><path d="M216,152v56a8,8,0,0,1-8,8H48a8,8,0,0,1-8-8V152" fill="none" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="16"/><polyline points="168 112 128 152 88 112" fill="none" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="16"/></svg> */}
                </Tooltip>
            </Button>
        )
    } else if (ui_state.active_tiles[0] === "restrictions") {

        iconExportReport = (
            <>
                <Button size='small' className="control-btns" sx={{ position: "relative", minWidth: 'auto', ml: 0, mr: 1 }} onClick={exportReportTableToExcel}>
                    <Tooltip title="Download Restrictions" arrow followCursor>
                        <FileDownloadIcon fontSize="large" sx={{ mt: 1.2, mr: "-6px" }}
                            className='downloadBtn' />
                    </Tooltip>
                </Button>
            </>
        )

    } else if (ui_state.active_tiles[0] === "forecast"
        || ui_state.active_tiles[0] === "otb" || ui_state.active_tiles[0] === "market"
        || ui_state.active_tiles[0] === "pickup" || ui_state.active_tiles[0] === "pace"
        || ui_state.active_tiles[0] === "compset" || ui_state.active_tiles[0] === "market_sell_rates"
        || ui_state.active_tiles[0] === "bi_pace_comparison" || ui_state.active_tiles[0] === "flights"
        || ui_state.active_tiles[0] === "productionMarketReport" || ui_state.active_tiles[0] === "revpar_index"
        || ui_state.active_tiles[0] === "productionReport" || ui_state.active_tiles[0] === "bi_sensitivity_analysis"
        || ui_state.active_tiles[0] === "otb_v2_multi_segment"
        || ui_state.active_tiles[0] === "rm_room_type_ratesend_status"
        || ui_state.active_tiles[0] === "DailyRatesendStatus"
        || ui_state.active_tiles[0] === "budget"
        || ui_state.active_tiles[0] === "pricing"
        || ui_state.active_tiles[0] === "bi_pickup_analysis") {

        if ((ui_state.workspace_view === 'table') && ui_state.show_chart_icon) {
            if (!app_state.workspace_controls.disableExport) {
                if (ui_state.showDownloadReport) {
                    iconExportReport = (
                        <>
                            <Button size='small' className="control-btns" sx={{ position: "relative", minWidth: 'auto', ml: -2, mr: 1 }} onClick={exportReportTableToExcel}>

                                <Tooltip title="Download Report" followCursor arrow>
                                    <FileDownloadIcon fontSize="large" sx={{ mt: 1.2, mr: "-6px" }}
                                        className='downloadBtn' />
                                    {/* <svg className='downloadBtn' xmlns="http://www.w3.org/2000/svg" viewBox="0 0 256 256"><rect width="256" height="256" fill="none"/><line x1="128" y1="152" x2="128" y2="40" fill="none" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="16"/><path d="M216,152v56a8,8,0,0,1-8,8H48a8,8,0,0,1-8-8V152" fill="none" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="16"/><polyline points="168 112 128 152 88 112" fill="none" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="16"/></svg> */}
                                </Tooltip>
                            </Button>
                        </>
                    )
                }
            } else {
                iconExportReport = (
                    <span style={{ position: 'relative' }}>
                        <Tooltip title="Loading...">
                            <CircularProgress size={25} sx={{ position: 'absolute', top: 12, right: 10 }} disableShrink />
                        </Tooltip>
                        <Button size='small' disabled className="control-btns" sx={{ position: "relative", minWidth: 'auto', ml: -2, mr: 1 }}>

                            <FileDownloadIcon fontSize="large" sx={{ mt: 1.2, mr: "-6px" }}
                                className='downloadBtn' />

                        </Button>
                    </span>


                )
            }

        }
    }

    return (
        <>
            {/* {multiPicker} */}
            {icon && <>
                <Button size='small' className="control-btns" sx={{ minWidth: 'auto', mr: 1, mt: .5 }}>
                {icon}
            </Button>
            {!iconExportReport && <Button disabled size='small' className="control-btns" sx={{ minWidth: 'auto', ml: -2, mr: 1 }}>
                <Tooltip title="Download Report" arrow followCursor>
                    <FileDownloadIcon onClick={exportReportToExcel} fontSize="large" sx={{ mt: 1.2, mr: "-6px" }}
                        className='downloadBtn' />
                </Tooltip>
            </Button>}
            </>
            
            }
            {iconExportReport}
        </>
    );
}

export default GridViewButton