import moment from "moment";
import { convertToPercent, deepCopy, getAppData, getDatesInRange, getFromState, getObjectFilter, getTransformedSegments, in_array, round, setUpDate } from "../../../app/util";
import { useDataSummary } from "../../Tile/kpiService";
import dataService from "../../../services/data.service";
import { useSelector } from "react-redux";
import { transformSegmentData } from "../segmentedData";
import { getSegmentedCode } from "../processDataReports";

import jsonData from '../test.json';
import { generatDefaultSubcolumns } from "../../../app/table-config";

/**   */

export const getReportData_biSensitivity = (columns, auth, app_state, ui_state) => {
    console.log("Columns =>> ",columns);
    let biSensitivity_data = dataService.getSeriesById("BiSensitivity").data;
    let data = (biSensitivity_data) ? biSensitivity_data.data : [];
    let setup_data = [];
    if (data && data.length > 0) {
        data.map((item, index) => {
            let data_columns = [];
            columns.map(col => {
                let dataObj = { id: col.id };
                let { mainKey, k } = col;
                if (col.type === 'dateColumn') {
                    dataObj['dba']  = item.index.dba;
                }
                if (col.type === 'dataColumn') {
                    col.subcolumns.map(key => {
                        if (item['HotelTotal'][mainKey]) {
                            if (key.value == "Occ") {
                                dataObj[key.value] = convertToPercent(item['HotelTotal'][mainKey][key.value]);
                            }else if (key.value == "Rooms") {
                                dataObj[key.value] = Math.round(item['HotelTotal'][mainKey][key.value]);
                            } else {
                                dataObj[key.value] = item['HotelTotal'][mainKey][key.value];
                            }
                        } else {
                            dataObj[key.value] = 0;
                        }
                    })
                }
                data_columns.push(dataObj);
            })
            setup_data.push(data_columns);
        })
    }
    // debugger;
    return setup_data;
}


export const getMaxColumnName = () => {
    let biSensitivity_data = dataService.getSeriesById("BiSensitivity").data;
    let data = (biSensitivity_data) ? biSensitivity_data.data : [];
    let max = 0;
    let allData = {};
    data.map((item,index)=>{
        let len = Object.keys(item.HotelTotal).length;
        if(len > max){
            max = len;
            allData = item.HotelTotal;
        }
    })
    return allData;
}


/** get columns for Bi Sensitivity report */
export const getColumnName_biSensitivity = (ord = 3, app_state) => {
    
    let biSensitivity_data = dataService.getSeriesById("BiSensitivity").data;
    let data = (biSensitivity_data) ? biSensitivity_data.data : [];
    const mainColumnn = []
    const subColumns = [];
    // debugger;
    const firstData =  getMaxColumnName();
    let order = ord
    // console.log(Object.keys(firstData).reverse());
    let headerColArr = Object.keys(firstData);
    headerColArr.map((key, keysIndex) => {
        let mainColOrder = keysIndex + 2;
        let keysArr = key.split("_");
        let id = moment(keysArr[2]).format("ddd") + "_" + keysIndex;
        let display = moment(keysArr[2]).format("dddd, MMM Do YYYY");
        display = (keysArr[3] && (app_state.workspace_controls.dayOfWeek === 'mon,tue,wed,thu,fri,sat,sun' || app_state.workspace_controls.dayOfWeek === 'mon,tue,wed,thu' || app_state.workspace_controls.dayOfWeek === 'fri,sat,sun') ) ? display+" - "+moment(keysArr[3]).format("dddd, MMM Do YYYY") : display;
        let mainColObj = {
            "id": id,
            "type": "dataColumn",
            "mainKey": key,
            "tileName": "Report",
            "display": display,
            "backgroundColor": "grey",
            "className": "col-main",
            "draggable": true,
            "hidden": false,
            "colspan": 6,
            "order": mainColOrder,
            "subcolumns": [
                {
                    ...generatDefaultSubcolumns("Rooms", id),
                    "order": 2,
                },
                {
                    ...generatDefaultSubcolumns("Occ", id),
                    "order": 1,
                },
                {
                    ...generatDefaultSubcolumns("ADR", id),
                    "order": 3
                },
                {
                    ...generatDefaultSubcolumns("RevPAR", id),
                    "order": 4
                },
                {
                    ...generatDefaultSubcolumns("Revenue", id),
                    "order": 5
                },
                {
                    ...generatDefaultSubcolumns("SellRate", id),
                    "hidden": true,
                    "order": 6
                }
            ],
        }
        mainColumnn.push(mainColObj);
    })
    return mainColumnn;
}