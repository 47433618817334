import {  deepCopy,  safeReference, getNumDaysFromDateRange } from "../../../app/util";
import { useDataSummary } from "../../Tile/kpiService";
import dataService from "../../../services/data.service";
import moment from "moment";
export function getRestrictionsFormatted({primary_stay_range, room_settings}, type="cur", isRoomType =false, restrictions=[]){
    const numDays = getNumDaysFromDateRange(primary_stay_range);
    // let baseRoom="";
    // room_settings.map(room=>{
    //     if(room.is_base === true){
    //         baseRoom=room.room_code;
    //        }
    // })
  
    if(restrictions.length ===0 && 
        dataService.getSeriesById("GetRestrictions") 
        && dataService.getSeriesById("GetRestrictions").data   ){
           const resData = deepCopy(dataService.getSeriesById("GetRestrictions").data);
            restrictions= resData[type] ;
    }
    let currDate = moment(primary_stay_range.date_from);
    let restrictionsFormatted =[];
    for (let index = 0; index < numDays; index++) {
        let dataPoint ={
            stay_date:currDate.format("YYYY-MM-DD")
        };
     
        if(!isRoomType){
            let found = false;
            restrictions.map(restriction=>{

                if(restriction.stay_date === currDate.format("YYYY-MM-DD")){
                    dataPoint={...dataPoint,
                        ...restriction
                    }
                    found = true;
                }
            });
            if(!found){
                // dataPoint={...dataPoint,
                //     cta:false,ctd:false, min_los:1, max_los:1
                // }
            }
      
        } 
        restrictionsFormatted.push(dataPoint);
        currDate.add(1,'days');
    }
    return restrictionsFormatted;
}