//React
import  React, {useState} from 'react';

//React Redux
import {useDispatch, useSelector} from 'react-redux';

//MUI
import { 
    Button, TextField
    , LinearProgress, CircularProgress
 } from '@mui/material';
import {CloseSharp as CloseSharpIcon} from '@mui/icons-material';
import { Box } from '@mui/system';

//Moments
import moment from 'moment';

//APP's
import { SUPPORT_REQUEST_SUCCESS, SUPPORT_REQUEST_FAILED } from "../../actions/types";
import theme from '../../theme'; 
import DataService from "../../services/data.service";

//Styles
import './style.css';

const RequestSupport = (props) => {
  const color = theme.palette;
  const dispatch = useDispatch();
  const {auth, app} = useSelector(state => state);
  const selectedProperty = app.current_state.app_state.property_id;
  let {user:AuthUser} = auth;
  const [submitDisabled, setSubmitDisabled] = useState(1);
  const [isRequestSuccess, setIsRequestSuccess] = useState(false);
  const [requestSuccessMsg, setRequestSuccessMsg] = useState('Your request is sent successfully, team will get back to you soon!');
  const [isRequestError, setIsRequestError] = useState(false);
  const [resErrorMsg, setResErrorMsg] = useState('');
  const[isrequesting, setIsrequesting] = useState(false);
  const [requestData, setRequestData] = useState({
    email: AuthUser.email,
    fullname: AuthUser.fullname,
    user_id: AuthUser.user_id,
    property_id: selectedProperty,
    subject: "Request Support",
    message: '',
    token_string: AuthUser.token_string,
    token: AuthUser.token_string
  });
  
  function submitRequestTicket(payload){
    return DataService.getData({api:'users/send_user_feedback', request:payload})
    .then(
      (data) => {
        //Do some task here just after respons
        if(data.status ==="success"){
          setIsRequestSuccess(true);
          dispatch({type: SUPPORT_REQUEST_SUCCESS, payload: data});
          return Promise.resolve(data);
        }
        else {
          setIsRequestError(true);
          setResErrorMsg(data.message);
          dispatch({type: SUPPORT_REQUEST_FAILED, payload: data});
          return Promise.reject(data);
        }
      }
    )
    .catch((err) => {
      setIsRequestError(true);
      setResErrorMsg(err.original.statusText || 'Something went wrong, please try again later!');
      dispatch({type: SUPPORT_REQUEST_FAILED, payload: err});
      return Promise.reject(err);
    })
  }
  function updateRequestData(e, type){
    requestData[type] = e.target.value;
    setRequestData({...requestData});
    if(requestData.fullname !== '' && requestData.email !=='' && requestData.message !==''){
      setSubmitDisabled(0);
    } else {
      setSubmitDisabled(1);
    }
  }
  function handleSubmit(event){
    event.preventDefault();
    setIsrequesting(true);
    let payload = requestData;
    submitRequestTicket(payload)
    .then((rs) => {
      setIsrequesting(false);
      setIsRequestSuccess(true);
    })
    .catch((err) => {
      setIsrequesting(false);
      setIsRequestError(true);
      setResErrorMsg(err.original.statusText);
      
    })
  }
  return (
    <Box sx={{position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: {lg:'25vw', xs:'90vw'}, height:'auto',
    bgcolor: 'dark.darker',
    boxShadow: 24, borderRadius:'5px', overflow:'hidden'}}>
      <div className="pop-up-header clearfix request-popup-header">
  <Box sx={{float:'left'}}>
    <div className="tile-title" data-tile-icon="icon-icon-events" data-id="events">
      <span className="tile-name">REQUEST SUPPORT</span>
    </div>
  </Box>
  <Box sx={{float:'right'}}>
    <Button sx={{minWidth:'auto', width:'22px', height:'22px', p:'7px', borderRadius:'50%', backgroundColor:'dark.darkest', color:'white.main', fontSize:'10px'}} onClick={props.handleClose}>
      <CloseSharpIcon fontSize='small' />
    </Button>
  </Box>
  </div>
  {isRequestSuccess && 
  <Box sx={{p: 2, pt:3, pb:3, position:'relative', color:'green.green', textAlign:'center', fontSize:17}}>
    {requestSuccessMsg}
  </Box>
  }
  {!isRequestSuccess && <Box component="form" onSubmit={handleSubmit} noValidate sx={{p: 2, pt:1, position:'relative'}}>
  
             <TextField
              size='small'
              placeholder='Name'
              margin="normal"
              required
              fullWidth
              id="name"
              
              name="name"
              autoComplete=""
              value={requestData.fullname}
              onChange={(e) => { updateRequestData(e, 'fullname')}}
              className="form-input"
              sx={{backgroundColor:'white.main', borderRadius:1}}
            /> 
            <TextField
              size='small'
              placeholder='Email'
              margin="normal"
              required
              fullWidth
              id="email"
              name="email"
              autoComplete=""
              value={requestData.email}
              onChange={(e) => { updateRequestData(e, 'email')}}
              sx={{backgroundColor:'white.main', borderRadius:1}}
            /> 
            <TextField
          id="message"
          margin="normal"
          multiline
          fullWidth
          rows={2}
          defaultValue=""
          value={requestData.message}
          onChange={(e) => { updateRequestData(e, 'message')}}
          placeholder='Enter your comments here' 
          className="form-textarea"
          sx={{backgroundColor:'white.main', borderRadius:1}}
        />
            
            <Box sx={{textAlighn:'center'}}>
            <Button
              type="submit"
              disabled={submitDisabled}
              fullWidth
              variant="contained"
              sx={{ mt: 2, mb: 2, display:'inherit', backgroundColor:'green.green', opacity:0.9, fontSize:16, '&:disabled':{backgroundColor:'green.green', cursor:'not-allowed'}, '&:hover':{backgroundColor:'green.green', opacity:1}}}
              onClick={handleSubmit}
            >
              SUBMIT
            </Button>
            
            </Box>
            
            </Box>}
            {isrequesting && (
              <Box sx={{textAlighn:'center', backgroundColor:'dark.darkest', opacity:0.8, position: 'absolute', top:40, left:0, width:'100%', height:'100%'}}>
                <CircularProgress
            size={40}
            sx={{
              color: 'white',
              position: 'absolute',
              top: '50%',
              left: '50%',
              marginTop: '-20px',
              marginLeft: '-20px',
            }}
          />
              </Box>
          
        )}
    </Box>
  )
}
export default RequestSupport;