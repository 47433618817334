import moment from "moment";
import { dateFormat, deepCopy, getList, getSeriesById } from "../../app/util";

export const primaryHeaderDisplay = ({display, type}, app_state, ui_state) => {
    const { workspace_controls, comp_as_of_date,comp_stay_range } = app_state;
   const as_of_date =  app_state.primary_stay_range.as_of_date;
    const { sdlyOn, pickUpType, isRoomTypePricing} = workspace_controls;
    const { active_tiles } = ui_state;
    let dtFormat = "ddd, MMM DD, YYYY";
    let pickupDays = app_state.workspace_controls.pickUpDays;
    let sdlyPickupDays = 364 + parseInt(pickupDays);

    let startDate = app_state.primary_stay_range.date_from;
    let endDate = app_state.primary_stay_range.date_until;
    let yearArr = getList(startDate, endDate);
    let compYearArr = getList(app_state.comp_stay_range.date_from, app_state.comp_stay_range.date_until)

    let asOfDate = dateFormat(as_of_date, "ddd, MMM DD, YYYY");
    let finalValue = display;
    if(active_tiles[0] === 'pricing' && isRoomTypePricing && type === "roomTypeColumn"){
        
        finalValue = (<><p className="room-code">{display.room_code} {(display.is_base && "(Base) ")} 
        {"["+ display.room_inventory + "] "} </p> 
         <p className="room-display-name">{ display.room_display_name}</p></>);
    } else{
        display = replaceAll(display, "%dayscount%", app_state.workspace_controls.pickUpDays)
        display = replaceAll(display, "%asOfDate%", asOfDate)
        var result = display.split(/[{}]/);
        // debugger;
        // console.log("result => ",result);
        if (result.length > 1) {
            let dayDisplay = result[0].split(/[%%]/);
            // result[0] =  replaceAll(result[0], "%dayscount%", app_state.workspace_controls.pickUpDays)
    
            if (result[1] === "asofdatetime") {
                finalValue = result[0] + " " + moment(as_of_date).format("ddd, MMM DD") + " " + moment().format("HH:MM A") + " (GMT" + moment().format("Z") + ")";
            } else if (result[1] === "compAsOfDate") {
                finalValue = result[0] + " " + moment(comp_stay_range.comp_as_of_date).format(dtFormat);
            } else if (result[1] === "dbamaindate") {
                finalValue = result[0] + " " + moment(startDate).format(dtFormat);
            } else if (result[1] === "dbacomparisiondate") {
                finalValue = result[0] + " " + moment(app_state.comp_stay_range.date_from).format(dtFormat);
            } else if (result[1] === "comparisonrangeyear") {
                finalValue = (compYearArr.length) ? compYearArr.join(" - ") : moment().format("YYYY");
                finalValue = "Comparison Pace - " + finalValue;
            } else if (result[1] === "rangeyear") {
                finalValue = (yearArr.length) ? yearArr.join(" - ") : moment().format("YYYY");
            } else if (result[1] === "asOfDate") {
                finalValue = result[0] + " " + asOfDate;
            } else if (result[1] === "otbSdlyDate") {
                let propDetails = getSeriesById("OSR").data;
                let otbSdlyDate = (propDetails) ? propDetails['meta']['sdly_asof'] : dateFormat(moment(as_of_date).subtract(364, "days"), "ddd, MMM DD, YYYY");
                finalValue = result[0] + " " + dateFormat(otbSdlyDate, "ddd, MMM DD, YYYY");
            } else if (result[1] === "subsYearDays" || result[1] === "substract364") {
                finalValue = result[0] + " " + dateFormat(moment(as_of_date).subtract(364, "days"), "ddd, MMM DD, YYYY");
            } else if (result[1] === "subsPickupDays") {
                finalValue = result[0] + " " + dateFormat(moment(as_of_date).subtract(pickupDays, "days"), "ddd, MMM DD, YYYY");
            } else if (result[1] === "sdlyPickupDays") {
                finalValue = result[0] + " " + dateFormat(moment(as_of_date).subtract(sdlyPickupDays, "days"), "ddd, MMM DD, YYYY");
            }else if (result[1] === "UNCONSTRAINED") {
                finalValue = (workspace_controls.unconstrained) ? "Unconstrained "+result[2] : ""+result[2];// dateFormat(moment(as_of_date).subtract(sdlyPickupDays, "days"), "ddd, MMM DD, YYYY");
            }
           
        }else {
            finalValue = result[0];
        }
        
        
    
        if(active_tiles[0] === 'pricing'){
            finalValue= (<p>{finalValue}</p>);
        }else {
            finalValue= (<span>{finalValue}</span>);
        }
    }
   
    return finalValue;
}

function escapeRegExp(string) {
    return string.replace(/[.*+?^${}()|[\]\\]/g, '\\$&'); // $& means the whole matched string
}

function replaceAll(str, find, replace) {
    return str.replace(new RegExp(escapeRegExp(find), 'g'), replace);
}
