import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Alert, AlertTitle, Box } from '@mui/material';
import TablePricing from "./TablePricing";
import TableCommon from "./TableCommon";
import TableReports from "./TableReports";
import TableRestrictions from "./TableRestrictions";

import TableIntelimerge from "./TableIntelimerge";
import { getPermissions, deepCopy } from '../../app/util';
import { TILES } from "../../app/config";
// import "./style.css";



function DynamicTable({ compDisplayData }) {
    // debugger;
    // const state = useSelector(appState => appState);
    const { auth } = useSelector(state => state);
    const { profile } = auth;
    const { app_state, ui_state } = useSelector(
        (state) => state.app.current_state
    );
    const { active_tiles } = ui_state;
    const { permissions, index } = getPermissions(profile.property_details, app_state.property_id);
    // const displayData = deepCopy(compDisplayData);
    let [displayData, setDisplayData] = useState(compDisplayData);
    const tile = TILES[active_tiles[0]];
    let wrapperClass = 'tile-' + active_tiles.join(' tile-');
    let isolatedTiles = ['pricing', 'restrictions'];
    let infoText = '';

    if (ui_state.active_tiles[0] === 'pricing' && permissions.rate_push === 0) {
        infoText = 'Pricing is not enabled for this data source! Please contact success@lodgiq.com for further details.';
    }
    const hasInfoBox = () => {

        if (ui_state.active_tiles[0] === 'pricing' && permissions.rate_push === 0) {
            return true;
        }
        return false;
    }

    const hasInfoBoxTable = () => {
        let infoText = false;
        if (ui_state.active_tiles[0] === 'forecast' && permissions.hfc === 0) {
            infoText = 'System forecast is not enabled for this property! Please contact success@lodgiq.com for further details.';
        }

        if (ui_state.active_tiles[0] === 'market_sell_rates' && ((compDisplayData.data && compDisplayData.data.length <= 0) || (compDisplayData[0].data && compDisplayData[0].data.length <= 0))) {
            infoText = 'Market Sell Rates are not available for this Locale, Please contact success@lodgiq.com for further details.';
        }

        return infoText;
    }

    if (tile.type === 'report') {
        return (<div className={'table-reports ' + wrapperClass}>
            {active_tiles.length === 1 && <TableReports compDisplayData={displayData} />}
        </div>);
    } else if (hasInfoBox()) {
        return (<Box sx={{ p: 1 }}>
            <Alert severity="info">
                <AlertTitle>Info</AlertTitle>
                {infoText}
            </Alert></Box>)
    } else {
        return (<>
            {active_tiles.length === 1 && !isolatedTiles.includes(active_tiles[0]) && displayData.length && <div className={'table-common ' + wrapperClass}><TableCommon compDisplayData={displayData} /></div>}
            {active_tiles.length === 1 && active_tiles[0] === 'restrictions' && permissions.restrictions == 0 && <Box sx={{ p: 1 }}>
                <Alert severity="info">
                    <AlertTitle>Info</AlertTitle>
                    Restrictions is not enabled for this property! Please contact success@lodgiq.com for further details.</Alert></Box>}
            {active_tiles.length === 1 && active_tiles[0] === 'restrictions' && permissions.restrictions == 1 && <div className={'table-restrictions ' + wrapperClass}><TableRestrictions compDisplayData={displayData} /></div>}

            {active_tiles.length === 1 && active_tiles[0] === 'pricing' && <div className={'table-pricing ' + wrapperClass}><TablePricing compDisplayData={displayData} /></div>}

            {!hasInfoBoxTable() && active_tiles.length > 1 && <div className={'table-merged ' + wrapperClass}><TableIntelimerge compDisplayData={displayData} /></div>}
            {hasInfoBoxTable() && active_tiles.length > 1 && <Box sx={{ p: 1 }}>
                <Alert severity="info">
                    <AlertTitle>Info</AlertTitle>
                    {hasInfoBoxTable()}</Alert></Box>}

        </>);
    }
}




export default DynamicTable;