import zIndex from "@mui/material/styles/zIndex";
import React from "react";

function Loading(props) {
  return (
    
        <img
          src={`../../assets/img/loading.gif`}
          alt={`Loading...`}
          loading="lazy"
          style={{ width:12 }}
        />
         
  );
}

export default Loading;
