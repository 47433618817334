
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import ConnectingAirportsSharpIcon from '@mui/icons-material/ConnectingAirportsSharp';
import FlightIcon from '@mui/icons-material/Flight';
import { Button, Box, Modal, FormControl, Select, Typography, Container, Grid, Icon } from '@mui/material'
import Chip from '@mui/material/Chip';
import { Tooltip } from '@mui/material';
import { deepCopy, getSeriesById } from "../../app/util";
import dataService from "../../services/data.service";
import StarOutlineIcon from '@mui/icons-material/StarOutline';
import CurrencyExchangeIcon from '@mui/icons-material/CurrencyExchange';
import RatePlanPopup from './RatePlanPopup';


export const getCodeDetails = (segmentCode, app_state) => {
    let data = (dataService.getSeriesById("GetRateCodeMapping").data) ? deepCopy(dataService.getSeriesById("GetRateCodeMapping").data.data) : [];
    segmentCode = segmentCode.replaceAll("Segment_", "");
    let getCodeDetails = data.filter(item => item.rate_code === segmentCode);
    // console.log(getCodeDetails);
    let segmentName = (getCodeDetails.length > 0) ? getCodeDetails[0].desc : "Not available";
    // debugger;
    // return (app_state.workspace_controls.segmentType === "rate_plan") ? segmentName : segmentCode;
    return segmentName;
}


const DisplayData = (props) => {
    const controls = useSelector(state => state.app.current_state.app_state.workspace_controls);
    const { app_state, ui_state } = useSelector(
        (state) => state.app.current_state
    );
    let segmentType = app_state.workspace_controls.segmentType;
    let item = props.displayValue;
    let chipArrAll = [];
    let roomTypeRestdata = [];

    return (
        <>
            {
                (segmentType && segmentType === "rate_plan" && props.dataKey === "segment" && ui_state.active_tiles[0] === "otb_v2_multi_segment") ?
                    <Tooltip title={getCodeDetails(item, app_state)} arrow followCursor>
                        <div className="table-content-style">{item}</div>
                    </Tooltip>
                    : item
            }

            {
                (ui_state.active_tiles[0] === "otb" 
                && app_state.workspace_controls.segmented_view && props.dataKey === "segment" 
                && item !== "Total" && app_state.workspace_controls.rate_plan === 1 &&
                <RatePlanPopup params={{stay_date:props.stay_date,segmentName:item}} />
                // <StarOutlineIcon sx={{ fontSize: 15 }} color="primary" />
                    //  <CurrencyExchangeIcon sx={{ fontSize: 12, position:"absolute", top:0,right:2 }} color="primary" />
                    // <Icon sx={{ fontSize:"10px", position:"absolute", top:0,right:0 }} color="primary" >star</Icon>
                )}
        </>
    )
}

export default DisplayData