import React from 'react'
import { Button, Tooltip } from '@mui/material';
import CalendarMonthIcon from '@mui/icons-material/CalendarMonth';
import { useDispatch } from "react-redux";
import { updateAppState } from "../../../../actions/appstate";
function PriceCalendarBtn() {
    const dispatch = useDispatch();
    const handleClick = ()=>{
        dispatch(updateAppState("open_price_calendar", {
            ui_state:{
                show_price_calendar:true
            }
        }))
    }
    let icon= (<Tooltip title="Pricing Calendar">
        <CalendarMonthIcon onClick={handleClick} className='grid-view' />
        {/* <svg onClick={handleClick} className='grid-view' xmlns="http://www.w3.org/2000/svg" viewBox="0 0 256 256"><rect width="256" height="256" fill="none"/><rect x="40" y="40" width="176" height="176" rx="8" fill="none" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="16"/><line x1="176" y1="24" x2="176" y2="56" fill="none" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="16"/><line x1="80" y1="24" x2="80" y2="56" fill="none" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="16"/><line x1="40" y1="88" x2="216" y2="88" fill="none" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="16"/><polyline points="88 128 104 120 104 184" fill="none" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="16"/><path d="M138.14,128a16,16,0,1,1,26.64,17.63L136,184h32" fill="none" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="16"/></svg> */}
    </Tooltip>);
    return (
        <Button size='small' className="control-btns" sx={{ minWidth: 'auto' }}>
        {icon}
        </Button>
    );
}

export default PriceCalendarBtn
