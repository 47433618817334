import moment from "moment";
import { convertToPercent, deepCopy, round, setUpDate } from "../../../app/util";
import { useDataSummary } from "../../Tile/kpiService";
import dataService from "../../../services/data.service";
import { useSelector } from "react-redux";

export function getBudgetData(data, columns, app_state) {
    let dataKey = "HotelTotal";
    let setup_data = [];
    console.log("data ",data);
    // debugger;
    // data = data.filter((itm) => moment(itm.date).format('YYYY-MM-01') >= moment().format('YYYY-MM-01'))
    data.map((item, index) => {

        // if (moment(item.date).format('MMM, YYYY') >= moment().format('MMM, YYYY')) {
            let data_columns = [];
            columns.map(col => {
                let dataObj = { id: col.id };
                if (col.type === 'dateColumn') {
                    dataObj = setUpDate(dataObj, item.index.date);
                }
                if (col.type === 'dataColumn') {
                    col.subcolumns.map(key => {
                        if(key.value === "Occ"){
                            dataObj[key.value] = item.budgetDataset['Occ%']
                        }else{
                            dataObj[key.value] = item.budgetDataset[key.value]
                        }
                    })
                }
                data_columns.push(dataObj);
            })
            setup_data.push(data_columns);
        // }
    })
    return setup_data;
}


// function to get Budget data 
export const getBudgetInt = (date,mainKey, id, tileName, k, key, index, dataKey, auth, app_state) => {
     let budgetData = (dataService.getSeriesById("GetBudget").data)?deepCopy(dataService.getSeriesById("GetBudget").data.data):[];
    let dataObj = {};
    let keyValue = key.value;
    if(tileName === "budget" && budgetData){
        let itemsArr = budgetData.filter((item)=> item.index.date === moment(date).format('YYYY-MM-DD'));
        if(itemsArr && itemsArr.length){
            if(keyValue === "Occ"){
                dataObj[keyValue] = itemsArr[0].budgetDataset['Occ%']
            }else{
                dataObj[keyValue] = itemsArr[0].budgetDataset[keyValue]
            }
        }else{
            dataObj[keyValue] = 0;
        }
    }
    return dataObj;
}


