import moment from "moment";
import { aggregateObject, convertToPercent, deepCopy, round, sortStringArray, truncate, getObjectFilter, daysCountFromRange, getAppData, getCorrectValue } from "../../../app/util";
import { useDataSummary } from "../../Tile/kpiService";
import dataService from "../../../services/data.service";
import { useSelector } from "react-redux";


export function getForecastSegmentedData() {
    let otb = deepCopy(dataService.getSeriesById("OSRSegment").data.data);
    let newTransformData = [];
    otb.map((item, index) => {
        let stayDate = item.index.date;
        let forecastSegData = (dataService.getSeriesById("OSRContrainedForecast").data) ? dataService.getSeriesById("OSRContrainedForecast").data.data : [];
        let forecastSegfiltered = forecastSegData.filter((data) => moment(data.stay_date).format("YYYY-MM-DD") === moment(stayDate).format("YYYY-MM-DD"));
        // debugger; 
        if (forecastSegfiltered.length) {
            otb[index] = { index: {} };
            forecastSegfiltered.map((itm, idx) => {
                otb[index]["index"].date = stayDate;
                if (itm.market_code) {
                    let segCode = "Segment_" + itm.market_code;
                    otb[index]["HotelTotal"] = {
                        primary: {
                            "Rooms": itm.predicted_total_cons_rooms,
                            "Occ": itm.predicted_total_cons_occ,
                            "ADR": itm.predicted_total_cons_adr,
                            "RevPAR": itm.predicted_total_cons_revpar,
                            "Revenue": itm.predicted_total_cons_revenue,
                            "SellRate": 0.00,
                        }
                    }
                    otb[index][segCode] = {
                        primary: {
                            "Rooms": itm.predicted_cons_rooms,
                            "Occ": itm.predicted_cons_occ,
                            "ADR": itm.predicted_cons_adr,
                            "RevPAR": itm.predicted_cons_revpar,
                            "Revenue": itm.predicted_cons_revenue,
                            "SellRate": 0.00,
                        }
                    }
                }
            })
        } else {
            let tmp = { index: item.index, HotelTotal: { primary: item.HotelTotal.primary } };
            for (let k in item) {
                if (k !== "index" && k !== "HotelTotal") {
                    tmp[k] = item[k];
                }
            }
            newTransformData.push(tmp)
        }
    })
    return otb;
}

export function forecastSegmentedData(auth, app_state, params) {
    let { segmentedOn, tile, segments } = params;
    let transformed = [];
    let otbData = [];
    let getPastOtbData = getForecastSegmentedData();
    // debugger;
    let segmentTotalAll = {};
    let blankObj = { Rooms: 0, ADR: 0, RevPAR: 0, Revenue: 0, Occ: 0 };
    let blankObjForcast = { predicted_total_cons_rooms: 0, predicted_total_cons_adr: 0, predicted_total_cons_revpar: 0, predicted_total_cons_revenue: 0, predicted_total_cons_occ: 0 };
    let ingonreKeys = ['HotelTotalxx', 'index','Segment_TOTAL', 'forecastDate', 'total_forecast_xx'];
    getPastOtbData.map((item, i) => {
        let segmentsKeys = Object.keys(item).sort().reverse();
        let primaryTotal = {
            predicted_total_cons_rooms: 0,
            predicted_total_cons_occ: 0,
            predicted_total_cons_adr: 0,
            predicted_total_cons_revpar: 0,
            SellRate: 0,
            predicted_total_cons_revenue: 0
        };
        let row = 0;
        segmentsKeys.forEach(key => {
            let totalAll = {
            }
            let tmp = {};
            if (!ingonreKeys.includes(key)) {
                let segmentCode = key.split('_')[1];
                let segmentName = segments.map[segmentCode];
                if (key === "HotelTotal") { segmentName = "Total"; }
                if (!segmentName) segmentName = "Other";
                if (!item[key].primary) {
                    item[key].primary = deepCopy(blankObj);
                }
                tmp = {
                    stay_date: item.index.date,
                    segment: segmentName,//truncate(segmentName, 18),
                    predicted_total_cons_rooms: item[key]['primary'].Rooms,
                    predicted_total_cons_adr: item[key]['primary'].ADR,
                    predicted_total_cons_revpar: item[key]['primary'].RevPAR,
                    predicted_total_cons_revenue: item[key]['primary'].Revenue,
                    predicted_total_cons_occ: item[key]['primary'].Occ
                }

                //////////////////////////////
                let idx = null;
                for (let i = 0; i < otbData.length; i++) {
                    if (item.index.date === otbData[i].stay_date && otbData[i].segment === tmp.segment) {
                        idx = i;
                    }
                }
                if (idx !== null) {
                    let keys = [
                        ['primary'],
                        ['predicted_total_cons_rooms', 'predicted_total_cons_adr', 'predicted_total_cons_revpar', 'predicted_total_cons_revenue', 'predicted_total_cons_occ']
                    ];
                    let aggTotal = aggregateObject({ primary: otbData[idx] }, { primary: tmp }, keys, 2);
                    otbData[idx] = aggTotal.primary;
                    otbData[idx].segment = tmp.segment;
                    otbData[idx].stay_date = tmp.stay_date;
                } else {
                    otbData.push(tmp);
                }
                row++;

                /// Segment Wise Total ///
                if (!segmentTotalAll[key]) {
                    segmentTotalAll[key] = deepCopy(blankObjForcast);
                }
                let myKey = key;
                let fieldArr = Object.keys(blankObj);
                if (key !== 'total_forecast') {
                    segmentTotalAll[key]['predicted_total_cons_rooms'] += getCorrectValue(item[key]['primary'].Rooms);
                    segmentTotalAll[key]['predicted_total_cons_revpar'] += getCorrectValue(item[key]['primary'].RevPAR);
                    segmentTotalAll[key]['predicted_total_cons_revenue'] += getCorrectValue(item[key]['primary'].Revenue);
                    segmentTotalAll[key]['predicted_total_cons_occ'] += getCorrectValue(item[key]['primary'].Occ);
                    segmentTotalAll[key]['predicted_total_cons_adr'] += getCorrectValue(parseFloat(item[key]['primary'].Revenue) / parseFloat(item[key]['primary'].Rooms));
                }
            }
        });
    })
    let resultSet = otbData.concat(forecastSegmentTotal(segmentTotalAll, segments, blankObjForcast, auth, app_state));
    return resultSet;
}

// function : To calculate the segment total .
// Modified : 31/Dec/2022

function forecastSegmentTotal(forecastSegmentData, segments, blankObjForcast, auth, app_state) {
    let transformed = [];
    let forcastSegmentKeys = Object.keys(forecastSegmentData)
    forcastSegmentKeys.map((key) => {
        let tmp = {};
        if (key !== "total_forecast") {
            let segmentName = getObjectFilter(segments.list, key.split("_")[1], "code");
            if (segmentName.length) {
                tmp = (forecastSegmentData[key]);
                tmp.stay_date = "";
                tmp.segment = segmentName[0].name;
                transformed.push(tmp);
            }
        }
    })
    let forecastKeys = Object.keys(blankObjForcast);
    let obj = {}
    transformed.forEach((item) => {
        if (obj[item.segment]) {
            forecastKeys.forEach(element => {
                obj[item.segment][element] = obj[item.segment][element] + item[element];
            });
        } else {
            obj[item.segment] = item
        }
    })
    let calculatedValue = Object.values(obj);
    // calculation for Occ , ADR and RevPar 
    let totalCapacity = getAppData(auth, app_state).property_current_total_capacity;
    let daysCount = daysCountFromRange(app_state);
    let totalCap = totalCapacity * daysCount;
    calculatedValue.map((itm) => {
        itm['predicted_total_cons_adr'] = (itm['predicted_total_cons_revenue'] / itm['predicted_total_cons_rooms']);
        itm['predicted_total_cons_occ'] = (itm['predicted_total_cons_rooms'] / totalCap);
        itm['predicted_total_cons_revpar'] = (itm['predicted_total_cons_revenue'] / totalCap);
    })
    return calculatedValue;
}



export function getForecastNonSegmentedData() {
    let otb = deepCopy(dataService.getSeriesById("OSR").data.data);
    let forecastSegData = (dataService.getSeriesById("OSRContrainedForecast").data) ? dataService.getSeriesById("OSRContrainedForecast").data.data : [];
    let newTransformData = [];
   
   
    otb.map((item, index) => {
    let itemHotelTotalPrimary = item['HotelTotal']['primary'];

        otb[index] = {
            stay_date: item.index.date,
            predicted_total_cons_rooms: itemHotelTotalPrimary['Rooms'],
            predicted_total_cons_adr: itemHotelTotalPrimary['ADR'],
            predicted_total_cons_revpar: itemHotelTotalPrimary['RevPAR'],
            predicted_total_cons_revenue: itemHotelTotalPrimary['Revenue'],
            predicted_total_cons_occ: itemHotelTotalPrimary['Occ'],
            index:{date:item.index.date}
        }
        let stayDate = moment(item.index.date).format('YYYY-MM-DD');
        let forecastSegfiltered = forecastSegData.filter((data) => data.stay_date === stayDate);
        // debugger;
        if (forecastSegfiltered.length) {
            otb[index] = { index: {} };
            forecastSegfiltered.map((itm, idx) => {
                if (itm.market_code) {
                    otb[index] = {
                        stay_date: stayDate,
                        predicted_total_cons_rooms: itm.predicted_total_cons_rooms,
                        predicted_total_cons_adr: itm.predicted_total_cons_adr,
                        predicted_total_cons_revpar: itm.predicted_total_cons_revpar,
                        predicted_total_cons_revenue: itm.predicted_total_cons_revenue,
                        predicted_total_cons_occ: itm.predicted_total_cons_occ,
                        index:{date:stayDate}

                    }
                }
            })
        } else {
            let tmp = { index: item.index, HotelTotal: { primary: item.HotelTotal.primary } };
            for (let k in item) {
                if (k !== "index" && k !== "HotelTotal") {
                    tmp[k] = item[k];
                }
            }
            newTransformData.push(tmp)
        }
    })
    return otb;
}




export function getForecastNonSegmentedDataNew() { /// this is for pricing tile
    let otb = deepCopy(dataService.getSeriesById("OSR").data.data);
    let newTransformData = [];
    let forecastSegData =[];
    if(dataService.getSeriesById("OSRContrainedForecast").data 
    && dataService.getSeriesById("OSRContrainedForecast").data.data){
        forecastSegData=dataService.getSeriesById("OSRContrainedForecast").data.data
    }
    otb.map((item, index) => {
        let stayDate = moment(item.index.date).format("YYYY-MM-DD");
        let forecastSegfiltered = forecastSegData.filter((data) => data.stay_date === stayDate);
        if (forecastSegfiltered.length) {
            otb[index] = { index: {} };
            forecastSegfiltered.map((itm, idx) => {
                if (itm.market_code) {
                    otb[index] = {
                        "HotelTotal": {
                            "primary": {
                                stay_date: stayDate,
                                Rooms: itm.predicted_total_cons_rooms,
                                ADR: itm.predicted_total_cons_adr,
                                RevPAR: itm.predicted_total_cons_revpar,
                                Revenue: itm.predicted_total_cons_revenue,
                                Occ: itm.predicted_total_cons_occ,


                            }
                        },
                        index: { date: stayDate, }
                    }
                }
            })
        } else {
            let tmp = { index: item.index, HotelTotal: { primary: item.HotelTotal.primary } };
            for (let k in item) {
                if (k !== "index" && k !== "HotelTotal") {
                    tmp[k] = item[k];
                }
            }
            newTransformData.push(tmp)
        }
    })
    return otb;
}


// Function to create data for Forecast for Intellimerge
export const getForeCastDataInt = (mainKey,id,key,index,app_state,forcastFinalData) => { 
    let dataObj = {};
    // let forcastFinalData = (app_state.workspace_controls.segmented_view) ? [] : getForecastNonSegmentedData();
    let keyValue = key.value;
    let forecastDataIndex = forcastFinalData;

    if (id === "forecast_primary" && mainKey === "primary") {
        if ((keyValue).toLowerCase() == "rooms") {
            dataObj[keyValue] = forecastDataIndex.predicted_total_cons_rooms;
        } else if ((keyValue).toLowerCase() == "occ") {
            dataObj[keyValue] = (forecastDataIndex.predicted_total_cons_occ)
            ?convertToPercent(forecastDataIndex.predicted_total_cons_occ):0;
        } else if ((keyValue).toLowerCase() == "adr") {
            dataObj[keyValue] = round(forecastDataIndex.predicted_total_cons_adr);
        } else if ((keyValue).toLowerCase() == "revpar") {
            dataObj[keyValue] = round(forecastDataIndex.predicted_total_cons_revpar);
        } else if ((keyValue).toLowerCase() == "revenue") {
            dataObj[keyValue] = round(forecastDataIndex.predicted_total_cons_revenue);
        } else {
            dataObj[keyValue] = round("0.00");
        }
    }

    let userForecastData = [];
    userForecastData = (dataService.getSeriesById("ManualForecast") && dataService.getSeriesById("ManualForecast").data) ? dataService.getSeriesById("ManualForecast").data.data : [];
    if (userForecastData && userForecastData.length > 0 && !app_state.workspace_controls.segmented_view) {
        let  userForecastIndex = userForecastData[index];
        if (mainKey === "userforecastdata") {
            /// add userForecast Data ///
            if (userForecastData && userForecastData.length > 0) {
                if ((keyValue).toLowerCase() == "rooms") {
                    dataObj[keyValue] = userForecastIndex['total_forecast']['Rooms'];
                } else if ((keyValue).toLowerCase() == "occ") {
                    dataObj[keyValue] = (userForecastIndex['total_forecast']['Occ%']);
                } else if ((keyValue).toLowerCase() == "adr") {
                    dataObj[keyValue] = round(userForecastIndex['total_forecast'].ADR);
                } else if ((keyValue).toLowerCase() == "revpar") {
                    dataObj[keyValue] = round(userForecastIndex['total_forecast'].RevPAR);
                } else if ((keyValue).toLowerCase() == "revenue") {
                    dataObj[keyValue] = round(userForecastIndex['total_forecast'].Revenue);
                }
            }
        }
        if (mainKey === "difference") {
            /// add userForecast Data Difference ///
            // debugger;
            if ((keyValue).toLowerCase() == "rooms") {
                dataObj[keyValue] = forcastFinalData.predicted_total_cons_rooms - userForecastIndex['total_forecast']['Rooms'];
            } else if ((keyValue).toLowerCase() == "occ") {
                dataObj[keyValue] = convertToPercent(forcastFinalData.predicted_total_cons_occ) - (userForecastIndex['total_forecast']['Occ%']);
            } else if ((keyValue).toLowerCase() == "adr") {
                dataObj[keyValue] = round(forcastFinalData.predicted_total_cons_adr - userForecastIndex['total_forecast'].ADR);
            } else if ((keyValue).toLowerCase() == "revpar") {
                dataObj[keyValue] = round(forcastFinalData.predicted_total_cons_revpar) - round(userForecastIndex['total_forecast'].RevPAR);
            } else if ((keyValue).toLowerCase() == "revenue") {
                dataObj[keyValue] = round(forcastFinalData.predicted_total_cons_revenue) - round(userForecastIndex['total_forecast'].Revenue);
            }
        }
    } else {
        if (mainKey === "userforecastdata") {
            /// add userForecast Data ///
            if (userForecastData && userForecastData.length > 0) {
                if ((keyValue).toLowerCase() == "rooms") {
                    dataObj[keyValue] = 0;
                } else if ((keyValue).toLowerCase() == "occ") {
                    dataObj[keyValue] = 0.00;
                } else if ((keyValue).toLowerCase() == "adr") {
                    dataObj[keyValue] = 0.00;
                } else if ((keyValue).toLowerCase() == "revpar") {
                    dataObj[keyValue] = 0.00;
                } else if ((keyValue).toLowerCase() == "revenue") {
                    dataObj[keyValue] = 0.00;
                }
            }
        }
        if (mainKey === "difference") {
            /// add userForecast Data ///
            // if ((keyValue).toLowerCase() == "rooms") {
            //     dataObj[keyValue] = item.predicted_total_cons_rooms;
            // } else if ((keyValue).toLowerCase() == "occ") {
            //     dataObj[keyValue] = convertToPercent(item.predicted_total_cons_occ);
            // } else if ((keyValue).toLowerCase() == "adr") {
            //     dataObj[keyValue] = round(item.predicted_total_cons_adr);
            // } else if ((keyValue).toLowerCase() == "revpar") {
            //     dataObj[keyValue] = round(item.predicted_total_cons_revpar);
            // } else if ((keyValue).toLowerCase() == "revenue") {
            //     dataObj[keyValue] = round(item.predicted_total_cons_revenue);
            // }
        }
    }
    // forecastObj = {...dataObj};
    return dataObj;
}




export const getForeCastDataInt_segment = (mainKey,id,key,index,app_state,forecastData) => { 
    let dataObj = {};
   let value = key.value;
    if (id === "forecast_primary" && mainKey === "primary") {
        if ((value).toLowerCase() == "rooms") {
            dataObj[value] = (forecastData) ? forecastData.predicted_total_cons_rooms : 0;
        } else if ((value).toLowerCase() == "occ") {
            dataObj[value] = (forecastData)
            ?convertToPercent(forecastData.predicted_total_cons_occ):0;
        } else if ((value).toLowerCase() == "adr") {
            dataObj[value] = (forecastData) ? round(forecastData.predicted_total_cons_adr) : 0;
        } else if ((value).toLowerCase() == "revpar") {
            dataObj[value] = (forecastData) ? round(forecastData.predicted_total_cons_revpar) : 0;
        } else if ((value).toLowerCase() == "revenue") {
            dataObj[value] = (forecastData) ? round(forecastData.predicted_total_cons_revenue) : 0;
        } else {
            dataObj[value] = round("0.00");
        }
    }

    let userForecastData = [];
    userForecastData = (dataService.getSeriesById("ManualForecast") && dataService.getSeriesById("ManualForecast").data) ? dataService.getSeriesById("ManualForecast").data.data : [];
   
    if (userForecastData && userForecastData.length > 0 && !app_state.workspace_controls.segmented_view) {
       let userForecast_totalforecast =  userForecastData[index]['total_forecast'];
        if (mainKey === "userforecastdata") {
            /// add userForecast Data ///
            if (userForecastData && userForecastData.length > 0) {
                if ((value).toLowerCase() == "rooms") {
                    dataObj[value] = userForecast_totalforecast['Rooms'];
                } else if ((value).toLowerCase() == "occ") {
                    dataObj[value] = (userForecast_totalforecast['Occ%']);
                } else if ((value).toLowerCase() == "adr") {
                    dataObj[value] = round(userForecast_totalforecast.ADR);
                } else if ((value).toLowerCase() == "revpar") {
                    dataObj[value] = round(userForecast_totalforecast.RevPAR);
                } else if ((value).toLowerCase() == "revenue") {
                    dataObj[value] = round(userForecast_totalforecast.Revenue);
                }
            }
        }
        if (mainKey === "difference") {
            /// add userForecast Data Difference ///
            if ((value).toLowerCase() == "rooms") {
                dataObj[value] = forecastData.predicted_total_cons_rooms - userForecast_totalforecast['Rooms'];
            } else if ((value).toLowerCase() == "occ") {
                dataObj[value] = convertToPercent(forecastData.predicted_total_cons_occ) - (userForecast_totalforecast['Occ%']);
            } else if ((value).toLowerCase() == "adr") {
                dataObj[value] = round(forecastData.predicted_total_cons_adr - userForecast_totalforecast.ADR);
            } else if ((value).toLowerCase() == "revpar") {
                dataObj[value] = round(forecastData.predicted_total_cons_revpar) - round(userForecast_totalforecast.RevPAR);
            } else if ((value).toLowerCase() == "revenue") {
                dataObj[value] = round(forecastData.predicted_total_cons_revenue) - round(userForecast_totalforecast.Revenue);
            }
        }
    } else {
        if (mainKey === "userforecastdata") {
            /// add userForecast Data ///
            if (userForecastData && userForecastData.length > 0) {
                if ((value).toLowerCase() == "rooms") {
                    dataObj[value] = 0;
                } else if ((value).toLowerCase() == "occ") {
                    dataObj[value] = 0.00;
                } else if ((value).toLowerCase() == "adr") {
                    dataObj[value] = 0.00;
                } else if ((value).toLowerCase() == "revpar") {
                    dataObj[value] = 0.00;
                } else if ((value).toLowerCase() == "revenue") {
                    dataObj[value] = 0.00;
                }
            }
        }
        if (mainKey === "difference") {
            /// add userForecast Data ///
            // if ((value).toLowerCase() == "rooms") {
            //     dataObj[value] = item.predicted_total_cons_rooms;
            // } else if ((value).toLowerCase() == "occ") {
            //     dataObj[value] = convertToPercent(item.predicted_total_cons_occ);
            // } else if ((value).toLowerCase() == "adr") {
            //     dataObj[value] = round(item.predicted_total_cons_adr);
            // } else if ((value).toLowerCase() == "revpar") {
            //     dataObj[value] = round(item.predicted_total_cons_revpar);
            // } else if ((value).toLowerCase() == "revenue") {
            //     dataObj[value] = round(item.predicted_total_cons_revenue);
            // }
        }
    }
    // forecastObj = {...dataObj};
    return dataObj;
}


