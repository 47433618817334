import moment from "moment";
import { convertToPercent, deepCopy, getAppData, getDatesInRange, getFromState, getObjectFilter, getTransformedSegments, in_array, round, setUpDate } from "../../../app/util";
import { useDataSummary } from "../../Tile/kpiService";
import dataService from "../../../services/data.service";
import { useSelector } from "react-redux";
import { transformSegmentData } from "../segmentedData";
import { getSegmentedCode } from "../processDataReports";



/*  */
export function getReportData_segment_production(data, columns, auth, app_state, ui_state) {
    let setup_data = [];
    const { workspace_controls } = app_state;
    const { sdlyOn } = workspace_controls;
    const { active_tiles } = ui_state;
    let startDate = app_state.primary_stay_range.date_from;
    let endDate = app_state.primary_stay_range.date_until;
    let tableLength = moment(endDate).diff(startDate, 'days') + 1;
    let dataKey = 'HotelTotal';
    data.map((item, index) => {
        let data_columns = [];
        columns.map(col => {
            let dataObj = {};
            if (col.type === 'dateColumn') {
                dataObj = setUpDate(dataObj, item.index.date);
                dataObj["segment"] = item.index.segment;
            }

            if (col.type === 'dataColumn') {
                let { mainKey } = col;
                col.subcolumns.map(key => {
                    if (mainKey === "difference_sdly") {
                        if (key.value === "Occ") {
                            dataObj[key.value] = convertToPercent(item[dataKey]["primary"][key.value] - item[dataKey]["sdly"][key.value])
                        } else {
                            dataObj[key.value] = round(item[dataKey]["primary"][key.value] - item[dataKey]["sdly"][key.value])
                        }
                    }
                    else if (mainKey === "variance_sdly") {
                        dataObj[key.value] = convertToPercent((item[dataKey]["primary"][key.value] - item[dataKey]["sdly"][key.value]) / item[dataKey]["sdly"][key.value]) + "%";
                    }
                    else if (mainKey === "pickup_diff_sdly") {
                        dataObj[key.value] = round(item[dataKey]["sdly_actual"][key.value] - item[dataKey]["sdly"][key.value])
                    } else if (mainKey === "restrictions" && getAppData(auth, app_state).restrictions.enable) {

                        let resData = dataService.getSeriesById("GetRestrictions").data;
                        // let resData = restDemoData;
                        resData = resData && resData.cur ? resData.cur : [];
                        let filteredData = getObjectFilter(deepCopy(resData), item.index.date, "stay_date")
                        let filterRestData = [];
                        if (filteredData[0]) {
                            Object.keys(filteredData[0]).forEach(function (key) {
                                if (filteredData[0][key] === false || filteredData[0][key] === 1) {
                                    delete filteredData[0][key];
                                }
                                if (filteredData[0]["stay_date"]) {
                                    //    delete filteredData[0]["stay_date"];
                                }
                            });
                            filterRestData.push(filteredData[0]);
                        }

                        dataObj[key.value] = filterRestData;
                    } else if (item[dataKey][mainKey]) {
                        if (key.value == "Occ") {
                            dataObj[key.value] = convertToPercent(item[dataKey][mainKey][key.value]);
                        } else {
                            dataObj[key.value] = round(item[dataKey][mainKey][key.value]);
                        }
                    }
                })
            }
            dataObj.id = col.id;
            data_columns.push(dataObj);
        })

        data_columns.map((itmArr, idx) => {
            if (itmArr['date'] == "" || itmArr['segment'] === "Total") {
                data_columns[idx]["ignore_sort"] = true;
            }
        })
        setup_data.push(data_columns);
        // debugger;
    })
    return setup_data;
}
/* */





function getKeyByValue(object, value) {
    // console.log(value);
    value = value.replaceAll("Segment_", "")
    let segmentName = Object.keys(object).find(key => in_array(object[key], [value]));
    console.log(segmentName);
    return (segmentName) ? segmentName : "Other";
}

/*  */
export function getReportData_segment_production_old(columns, auth, app_state) {
    let data = deepCopy(dataService.getSeriesById("OSRSegment").data.data);
    let setup_data = [];
    let { date_from, date_until } = app_state.primary_stay_range;
    let dates = getDatesInRange(date_from, date_until);
    let segmentTotalAll = {};
    let blankObj = { Rooms: 0, Occ: 0, ADR: 0, RevPAR: 0, SellRate: 0, Revenue: 0 };

    let settings = getAppData(auth, app_state);
    let market_segment_category_mapping = settings.market_segment_category_mapping;
    console.log("mapping => ", market_segment_category_mapping);


    dates.map((date, dateIndex) => {
        let dataRecord = data.filter((item) => moment(item.index.date).format("yyyy-mm-dd") === moment(date).format("yyyy-mm-dd"));
        let segmentCodes = Object.keys(dataRecord[0]);
        segmentCodes.sort().push(segmentCodes.shift());

        segmentCodes.map((segmentCode, segmentIndex) => {
            if (segmentCode !== "index") {

                console.log("Segname => ", getKeyByValue(market_segment_category_mapping, segmentCode));
                let segment_name = getKeyByValue(market_segment_category_mapping, segmentCode);

                if (!segmentTotalAll[segmentCode]) {
                    segmentTotalAll[segmentCode] = {
                        primary: deepCopy(blankObj),
                        sdly: deepCopy(blankObj),
                        sdly_actual: deepCopy(blankObj),
                        difference_sdly: deepCopy(blankObj)
                    };
                }

                let data_columns = [];
                let segmentName = (segmentCode && segmentCode === "HotelTotal") ? "Total" : segment_name;
                let segmentKey = segmentCode;
                columns.map(col => {
                    let dataObj = {};
                    dataObj.id = col.id;

                    if (col.type === 'dateColumn') {
                        dataObj = setUpDate(dataObj, date);
                        dataObj["segment"] = segmentName;
                    }
                    if (col.type === 'dataColumn') {
                        let { mainKey } = col;
                        col.subcolumns.map(key => {
                            if (mainKey === "difference_sdly") {
                                if (key.value === "Occ") {
                                    let diffVal = convertToPercent(dataRecord[0][segmentKey]["primary"][key.value] - dataRecord[0][segmentKey]["sdly"][key.value]);
                                    dataObj[key.value] = diffVal;//convertToPercent(dataRecord[0][segmentKey]["primary"][key.value] - dataRecord[0][segmentKey]["sdly"][key.value])
                                    segmentTotalAll[segmentCode][mainKey][key.value] += diffVal;
                                } else {
                                    let diffVal = round(dataRecord[0][segmentKey]["primary"][key.value] - dataRecord[0][segmentKey]["sdly"][key.value]);
                                    dataObj[key.value] = diffVal;//round(dataRecord[0][segmentKey]["primary"][key.value] - dataRecord[0][segmentKey]["sdly"][key.value])
                                    segmentTotalAll[segmentCode][mainKey][key.value] += diffVal;
                                }
                            } else {
                                if (key.value == "Occ") {
                                    dataObj[key.value] = convertToPercent(dataRecord[0][segmentKey][mainKey][key.value]);
                                    segmentTotalAll[segmentCode][mainKey][key.value] += convertToPercent(dataRecord[0][segmentKey][mainKey][key.value]);
                                } else {
                                    dataObj[key.value] = round(dataRecord[0][segmentKey][mainKey][key.value]);
                                    segmentTotalAll[segmentCode][mainKey][key.value] += dataRecord[0][segmentKey][mainKey][key.value];
                                }
                            }
                        })
                    }

                    data_columns.push(dataObj);
                })
                setup_data.push(data_columns);
            }
        })
    })
    setup_data = [...setup_data, ...getTotalAll(segmentTotalAll, columns, app_state)]
    return setup_data;
}
/* */

const getTotalAllSegment = (key, dataObj, app_state) => {
    var date1 = moment(app_state.primary_stay_range.date_from);
    var date2 = moment(app_state.primary_stay_range.date_until);
    var daysCount = date2.diff(date1, 'days') + 1;
    let finalValue = 0;
    let value = dataObj[key];
    if (key === "Occ") {
        finalValue = value / daysCount;
    } else if (key === "ADR") {
        finalValue = dataObj["Revenue"] / dataObj["Rooms"];
    } else if (key === "RevPAR") {
        finalValue = value / daysCount;
    } else {
        finalValue = value;
    }
    return finalValue;
}


function getTotalAll(segmentTotalAll, columns, app_state) {
    let setup_data = [];
    let segmentCodes = Object.keys(segmentTotalAll);
    segmentCodes.sort().push(segmentCodes.shift());

    segmentCodes.map((segmentCode, segmentIndex) => {
        if (segmentCode !== "HotelTotal") {
            let data_columns = [];
            let segmentName = (segmentCode && segmentCode === "HotelTotal") ? "Total" : segmentCode.replaceAll("Segment_", "");
            let segmentKey = segmentCode;
            columns.map(col => {
                let dataObj = {};
                dataObj.id = col.id;

                if (col.type === 'dateColumn') {
                    dataObj = setUpDate(dataObj, "");
                    dataObj["segment"] = segmentName;
                }
                if (col.type === 'dataColumn') {
                    let { mainKey } = col;
                    col.subcolumns.map(key => {
                        console.log();
                        if (mainKey === "difference_sdly") {
                            if (key.value === "ADR") {
                                let diffADR = getTotalAllSegment("ADR", segmentTotalAll[segmentKey]["primary"], app_state) - getTotalAllSegment("ADR", segmentTotalAll[segmentKey]["sdly"], app_state);
                                dataObj[key.value] = diffADR; //segmentTotalAll[segmentKey]["primary"][key.value] - segmentTotalAll[segmentKey]["sdly"][key.value];
                            } else {
                                dataObj[key.value] = getTotalAllSegment(key.value, segmentTotalAll[segmentKey][mainKey], app_state);
                            }
                        } else {
                            dataObj[key.value] = getTotalAllSegment(key.value, segmentTotalAll[segmentKey][mainKey], app_state);

                        }
                    })
                }
                data_columns.push(dataObj);
            })
            setup_data.push(data_columns);
        }
    })
    return setup_data;
}




