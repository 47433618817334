import React, {useState} from 'react'
import { Button, Popover } from "@mui/material";
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import moment from "moment";
function InfoPopover(
    {anchorEl,setAnchorEl,popOverData,revertData, isOpen }
) {
    let {stay_date, sellRate, otb, forecast, room_code,isBase, edited} = popOverData;
    const [innerEl, setInnerEl] = useState(null)

    let popoverClass =(isOpen)? "info-popover open":"info-popover"

    
   let popOver=( <Popover
    id={"infoPopover"}
    open={Boolean(innerEl)}
    anchorEl={innerEl}
    onClose={()=>{
        setAnchorEl(null)
        setInnerEl(null)
        //setIsOpen(false);
        
    }}
    modifiers={{
        preventOverflow: {
          enabled: true,
          boundariesElement: "window"
        },
        arrow: {
          enabled: true,

        }
      }}
    anchorOrigin={{
      vertical: 'center',
      horizontal: 'right',
    }}
    className="info-popover"
>
        
        
        {/* <div className="popover-arrow popover-arrow-lc"></div> */}
        <div className="heading">{moment(stay_date).format("ddd, MMM DD, YYYY")}</div>
        <div className="info-section">
            <p><span className="bold-text">Current Rate</span>: <span className="blue-highlight">{sellRate}</span></p>
            <p>
                <span className="bold-text">OTB </span> 
                OCC%: <span className="blue-highlight"> {otb.occ} </span> 
                ADR: <span className="blue-highlight"> {otb.adr}</span>
            </p>
            <p>
                <span className="bold-text">Forecast </span> 
                OCC%: <span className="blue-highlight"> {forecast.occ} </span> 
                ADR: <span className="blue-highlight"> {forecast.adr}</span>
            </p>
        </div>
       
        {edited &&  (<div className="revert-btns">
            <Button variant="outlined" size="small" onClick={()=>{
                revertData("single",stay_date,room_code, isBase)
            }}>Revert</Button>
            <Button  variant="outlined" size="small" onClick={()=>{
                     revertData("day", stay_date)
            }}>Revert Day</Button>
        </div>)
        }
</Popover>)
    return (<>
    <InfoOutlinedIcon className="info-icon" onClick={(event) => {
               // setAnchorEl(event.currentTarget);
                setInnerEl(event.currentTarget)
            }}/> 
        {popOver}
    </>)
    
   
}

export default InfoPopover
