//imports for React and redux
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { updateAppState } from "../../actions/appstate";

//imports for MUI
import { Grid, Box } from "@mui/material";

//imports for left subcomponents
import SmartDatePicker from "../SmartDatePicker";
import DbaPicker from "./subComponents/leftComponents/dbaPicker";
import PickUpDays from "./subComponents/leftComponents/pickUpDaysBlock";
import PickUpType from "./subComponents/leftComponents/pickUpTypeSelect";
import ClearComparisionPicker from "./subComponents/leftComponents/clearComparisionButton";
import ScopePicker from "./subComponents/leftComponents/scopeSelect";
import InfoButton from "./subComponents/leftComponents/infoButton";
import MarketPicker from "./subComponents/leftComponents/marketPicker";
import UnconstrainedPicker from "./subComponents/leftComponents/unconstrainedPicker";
import EventType from "./subComponents/leftComponents/eventTypeSelect";
import CategoriesType from "./subComponents/leftComponents/categoriesTypeSelect";
import compareToPicker from "./subComponents/leftComponents/compareToPicker";
import SdlyButton from "./subComponents/leftComponents/SdlyButton";
import Sdly2019Btn from "./subComponents/leftComponents/Sdly2019Btn";

import ExpandBtn from "./subComponents/leftComponents/ExpandButton";
import SegmentBtn from "./subComponents/leftComponents/SegmentButton";
import ToggleRoomTypePricing from "./subComponents/leftComponents/ToggleRoomTypePricing";
import ToggleRoomTypeRestrictions from "./subComponents/leftComponents/ToggleRoomTypeRestrictions";


//imports for right subcomponents
import GridViewButton from "./subComponents/rightComponents/gridViewButton";
import DownloadButton from "./subComponents/rightComponents/downloadButton";
import SendRatesButton from "./subComponents/rightComponents/sendRatesButton";
import UpdateRestrictionsButton from "./subComponents/rightComponents/updateRestrictionsButton";
import OptionsButton from "./subComponents/rightComponents/optionsButton";
import CompareTo from "./subComponents/leftComponents/CompareTo";
import { TILES } from "../../app/config";
import { combineArr, in_array, getPermissions,dateDiff } from "../../app/util";
import CompDatePicker from "../CompDatePicker";
import moment from "moment";
import SummaryPicker from "./subComponents/leftComponents/SummaryPicker";
import SdYears from "./subComponents/leftComponents/SdYears";
import TableType from "./subComponents/leftComponents/TableType";
import DayOfWeek from "./subComponents/leftComponents/DayOfWeek";
import MultiSegmentPicker from "./subComponents/leftComponents/MultiSegmentPicker";
import PriceCalendarBtn from "./subComponents/rightComponents/PriceCalendarBtn";
import AirportCodes from "./subComponents/leftComponents/AirportCodes";
import InfoButtonMarketSellRate from "./subComponents/leftComponents/InfoButtonMarketSellRate";
import RatePushMode from "./subComponents/leftComponents/RatePushMode";
import { useMergedControls } from "../../hooks/tile-merge-logics";

function WorkspaceControls() {
    //needs to be implemented into redux stores, checks which view on
    const onChartView = false;
    const mergedControls = useMergedControls();
    const { property_details } = useSelector(state => state.auth.profile);
    const { app_state, ui_state } = useSelector((state) => state.app.current_state);
    const { controlSupport } = app_state;
    const { active_tiles, workspace_view } = ui_state;
    const { sdlyOn, isRoomTypePricing, segmentType } = app_state.workspace_controls;
    const { date_from, date_until } = app_state.primary_stay_range;
    const { permissions, index } = getPermissions(property_details, app_state.property_id);

    const dispatch = useDispatch();
    const ratePushedEnabled = () => {
   
        if (ui_state.active_tiles[0] === 'pricing' && permissions.rate_push === 0) {
            return false;
        }
        return true; 
    }
    const hasViews = () => {
        // debugger;  
        // const { active_tiles } = ui_state;
        if (TILES[active_tiles[0]].views.length < 2 && TILES[active_tiles[0]].type === "summary_report") {
            return true;
        }
        if (TILES[active_tiles[0]].views.length < 2 && TILES[active_tiles[0]].id === "flights") {
            return true;
        }
        if (TILES[active_tiles[0]].views.length < 2 && TILES[active_tiles[0]].type !== "report") {
            return false;
        }

        // if (isRoomTypePricing === true && active_tiles[0] === 'pricing') {
        //     return false;
        // }
        return true;
    }

    const hasWorkspaceControls = function (controlID) {
        const { active_tiles } = ui_state;
        let workspaceControls = [];
        let hasWorkspaceControls = false;
        active_tiles.map(tile => {
            if (TILES[tile] && TILES[tile].workspaceControls) {
                workspaceControls = combineArr(workspaceControls, TILES[tile].workspaceControls);
            }
        })
        workspaceControls.forEach(control => {
            if (controlID === control) {
                hasWorkspaceControls = true;
            }
        })
        return hasWorkspaceControls;
    }

    // let pickerStyle = (ui_state.active_tiles[0] === "hotel_summary" || ui_state.active_tiles[0] === "market_summary") ? "display-picker-hide" : "";

    // debugger;
    const dateRange = (
        <Box className={"control-option "} sx={{ position: "relative" }}>
            <SmartDatePicker
                dateRange={{ date_from, date_until, as_of_date: app_state.as_of_date }}
                datePickerConfig={{
                    datepicker_name: "primarydates",
                    type: "primaryStayDates",
                    dateRangeKey: "primary_stay_range",
                    shiftBtns: true,
                    navigation: {
                        single_date: {
                            type: "singleDate",
                            range_segments: [],
                            inputs: [],
                            as_of_date: true,
                            label: "Date",
                        },
                        range: {
                            type: "range",
                            range_segments: ["current_month", 30, 60, 90],
                            inputs: ["date_from", "date_until"],
                            as_of_date: true,
                            label: "Range",
                        },
                        weeks: {
                            type: "weeks",
                            range_segments: [],
                            inputs: ["date_from", "date_until"],
                            as_of_date: true,
                            label: "Weeks",
                        },
                        months: {
                            type: "months",
                            range_segments: [],
                            inputs: ["date_from", "date_until"],
                            as_of_date: true,
                            label: "Months",
                        },
                    },
                }}
            />
        </Box>
    );
    // let daysDiff = dateDiff(app_state.primary_stay_range.date_until, app_state.primary_stay_range.date_from);
    // let fromDateLastYear = new moment(app_state.primary_stay_range.date_from).subtract(1, 'year').startOf('months').format("YYYY-MM-DD");
    // let untilDateLastYear = new moment(fromDateLastYear).add(daysDiff, 'day').format("YYYY-MM-DD");
    // let primaryAsOf = new moment(app_state.primary_stay_range.as_of_date);
    // let sdlyAsOf = new moment(app_state.primary_stay_range.as_of_date).subtract(1, 'year')
    //                    .isoWeek(primaryAsOf.isoWeek())
    //                    .isoWeekday(primaryAsOf.isoWeekday());
    // let cAsOfDate = sdlyAsOf.format("YYYY-MM-DD");
    // // let cAsOfDate = moment(app_state.as_of_date).subtract(1, 'year').startOf('week').format("YYYY-MM-DD");
    // if(app_state.workspace_controls.scopeType === "Dba"){
    //     untilDateLastYear = new moment(fromDateLastYear).format("YYYY-MM-DD");
    // }
    // debugger;
    const compdateRange = (
        <Box className="comparison-range control-option" sx={{ position: "relative" }}>
            <CompDatePicker
                dateRange={{ ...app_state.comp_stay_range }}
                datePickerConfig={{
                    datepicker_name: "comparisonDates",
                    type: "primaryStayDates",
                    dateRangeKey: "comp_stay_range",
                    shiftBtns: true,
                    navigation: {
                        single_date: {
                            type: "singleDate",
                            range_segments: [],
                            inputs: [],
                            as_of_date: true,
                            label: "Date",
                        },
                        range: {
                            type: "range",
                            range_segments: ["current_month", 30, 60, 90],
                            inputs: ["date_from", "date_until"],
                            as_of_date: true,
                            label: "Range",
                        },
                        weeks: {
                            type: "weeks",
                            range_segments: [],
                            inputs: ["date_from", "date_until"],
                            as_of_date: true,
                            label: "Weeks",
                        },
                        months: {
                            type: "months",
                            range_segments: [],
                            inputs: ["date_from", "date_until"],
                            as_of_date: true,
                            label: "Months",
                        },
                    },
                }}
            />
        </Box>
    );

    let showPicker = (ui_state.active_tiles[0] === "revpar_index" || ui_state.active_tiles[0] === "flights_x") ? "dn" : "";
    //Adjusting width of control options area to fit
    let optionsWidth = 8.6;
    if (app_state.controlSupport.segmented_view) {
        optionsWidth = optionsWidth - 0.4;
    }
    if (ui_state.show_chart_icon) {
        optionsWidth = optionsWidth - 0.3;
    }
    if (ui_state.active_tiles[0] === "pricing") {
        optionsWidth = optionsWidth - 1;
    }
    if (ui_state.active_tiles[0] === "restrictions") {
        optionsWidth = optionsWidth - 1.1;
    }

    return (
        <Box id="full_workspace" sx={{ display: "flex", }}>
            <Grid
                id="workspace_left"
                elevation={1}
                container
                spacing={0}
                sx={{
                    mb: 0,
                    pt: 0,
                    pb: 0,
                    mt: 0,
                    display: { xs: "none", md: "flex" },
                }}
            >
                {/* <Grid item>{<ExpandBtn />}</Grid> */}
                {in_array(ui_state.active_tiles, ["pickup", "pace", "market", "otb", "forecast", "bi_pace_comparison", "bi_pickup_analysis", "bi_sensitivity_analysi", "bi_pickup_analysis"])
                    && !in_array(ui_state.active_tiles, ["budget"]) && app_state.controlSupport.segmented_view ? (
                    <Grid item>{<SegmentBtn />}</Grid>
                ) : null}

                <Grid item className={showPicker}>
                    {dateRange}
                </Grid>
                <Grid item xs={optionsWidth} sx={{ height: 57, overflowY: 'hidden' }}>
                    <Grid container className="control-scroll" sx={{ ':hover': { overflowX: 'auto' }, overflow: 'hidden' }} flexWrap="nowrap" alignContent="flex-start">

                        {in_array(ui_state.active_tiles, ["revpar_index"]) ? (
                            <Grid item className="control-grid-item table-type">
                                <TableType />
                            </Grid>
                        ) : null}
                        {ui_state.active_tiles[0] === "events" && <Grid item className="control-grid-item event-type">
                            <EventType />
                        </Grid>}

                        {ui_state.active_tiles[0] === "events" && <Grid item className="control-grid-item categories-types">
                            <CategoriesType />
                        </Grid>}

                        {/* <Grid item>
                            {ui_state.active_tiles[0] === "pace" ? compareToPicker : null}
                            </Grid> */}
                        {/* <Grid item>{dateRange}</Grid> */}

                        {in_array(ui_state.active_tiles, ["bi_sensitivity_analysis", "pace"]) ? (
                            <Grid item className="control-grid-item comparison-date-picker">
                                {compdateRange}
                            </Grid>
                        ) : null}

                        {in_array(ui_state.active_tiles, ["pickup", "market", "otb", "otb_v2_multi_segment"]) ? (
                            <Grid item className="control-grid-item sdly-button">
                                <SdlyButton />
                            </Grid>
                        ) : null}


                        {in_array(ui_state.active_tiles, ["flights"]) ? (
                            <Grid item className="control-grid-item airport-codes">
                                <AirportCodes />
                            </Grid>
                        ) : null}

                        {hasWorkspaceControls("toggleRoomTypePricing") && workspace_view === "table" ? (
                            <Grid item className="control-grid-item toggle-room-rype-pricing">
                                <ToggleRoomTypePricing />
                            </Grid>
                        ) : null}

                        {in_array(ui_state.active_tiles, ["restrictions"]) ? (
                            <Grid item className="control-grid-item toggle-room-rype-restriction" sx={{ display: "none" }}>
                                <ToggleRoomTypeRestrictions />
                            </Grid>
                        ) : null}


                        {in_array(ui_state.active_tiles, ["otb", "otb_v2_multi_segment"]) && sdlyOn === true ? (
                            <Grid item className="control-grid-item sd-year">
                                {/* <Sdly2019Btn /> */}
                                <SdYears />
                            </Grid>
                        ) : null}

                        {in_array(ui_state.active_tiles, ["otb_v2_multi_segment"]) ? (
                            <Grid item className="control-grid-item multi-segment-picker">
                                <MultiSegmentPicker />
                            </Grid>
                        ) : null}


                        {in_array(ui_state.active_tiles, ["bi_sensitivity_analysis"]) ? (
                            <Grid item className="control-grid-item day-of-week">
                                <DayOfWeek />
                            </Grid>
                        ) : null}



                        {in_array(ui_state.active_tiles, ["market", "pickup", "bi_pickup_analysis"]) && app_state.workspace_controls.scopeType !== "Dba" ? (
                            <Grid item className="control-grid-item pick-up-days">
                                <PickUpDays />
                            </Grid>
                        ) : <></>
                        }

                        {/* {ui_state.active_tiles[0] === "pickup" || ui_state.active_tiles[0] === "market" ? (
                            <Grid item xs={1}>
                             <PickUpDays />
                            </Grid>
                            ) : null} */}

                        {(ui_state.active_tiles[0] === "hotel_summary" || ui_state.active_tiles[0] === "market_summary") ? (
                            <Grid item className="control-grid-item summary-picker">
                                <SummaryPicker />
                            </Grid>
                        ) : null}

                        {in_array(ui_state.active_tiles, ["pickup"]) && <Grid item className="control-grid-item pick-up-type">
                            <PickUpType />
                        </Grid>}

                        {in_array(ui_state.active_tiles, ["compset", "market", "pace"]) && app_state.controlSupport.scopeType ? (<Grid item className="control-grid-item scope-picker">
                            <ScopePicker />
                        </Grid>
                        ) : null}

                        {in_array(ui_state.active_tiles, ["compset", "market", "pace"]) && app_state.workspace_controls.scopeType === "Dba" && <Grid item className="control-grid-item dba-picker">
                            <DbaPicker />
                        </Grid>}

                        {in_array(ui_state.active_tiles, ["market_summary", "market", "market_sell_rates"]) ? (<Grid item className="control-grid-item market-picker">
                            <MarketPicker />
                        </Grid>
                        ) : null}

                        {in_array(ui_state.active_tiles, ["rm_room_type_ratesend_status", "room_type_ratesend_status_date_range", "DailyRatesendStatus"]) ? (<Grid item className="control-grid-item rate-push-mode">
                            <RatePushMode />
                        </Grid>
                        ) : null}

                        {in_array(ui_state.active_tiles,["market"]) ? (
                            <Grid item className="control-grid-item unconstrained-picker">
                                <UnconstrainedPicker />
                            </Grid>
                        ) : null}
                        {/* <Grid item> */}
                        {/* {ui_state.active_tiles[0] === "pace" ? <DbaPicker /> : null} */}
                        {/* </Grid> */}


                        {in_array(ui_state.active_tiles, ["pace", "bi_sensitivity_analysis"]) ? (
                            <Grid item className="control-grid-item clear-comparison-btn">
                                <ClearComparisionPicker />
                            </Grid>) : null}

                        {/* <Grid item>
                            {ui_state.active_tiles[0] === "pace" && (
                            <ClearComparisionPicker />
                            )}
                            </Grid> */}

                        <Grid item className="control-grid-item info-button">
                            {ui_state.active_tiles[0] === "marketx" || ui_state.active_tiles[0] === "market_summaryx" ? (
                                <InfoButton />
                            ) : null}
                        </Grid>
                    </Grid>
                </Grid>
                <Grid item sx={{ ml: 'auto' }}>
                    <Box
                        id="workspace_right"
                        display="flex"
                        flexDirection="row-reverse"
                        sx={{ pt: 0.7 }}
                    >
                        {/* <Box display="flex">
                    {" "}
                    {hasWorkspaceControls("sendRatesBtn") ? (
                        <SendRatesButton  />
                    ) : null}{" "}
                </Box> */}
                        {in_array(ui_state.active_tiles, ["restrictions"]) ? (
                            <><Box display="flex">
                                <UpdateRestrictionsButton />
                                <GridViewButton />

                            </Box></>) : null}
                        <Box display="flex">
                            {hasViews() && <GridViewButton />}
                            {(ratePushedEnabled() && hasWorkspaceControls("pricing_calendar_btn") && !isRoomTypePricing) ? (

                                <PriceCalendarBtn />

                            ) : null}
                        </Box>
                        {/* <Box display="flex"> {onChartView ? <DownloadButton /> : null} </Box> */}

                        <Box>
                            {" "}
                            {ui_state.active_tiles[0] === "events" ? (
                                <OptionsButton />
                            ) : null}{" "}
                        </Box>
                    </Box>
                </Grid>
            </Grid>




        </Box>
    );
}

export default WorkspaceControls;
