import { ErrorBoundary } from 'react-error-boundary';
import { ErrorHandler } from "../../app/ErrorHandler";
import ArrowBackIosOutlinedIcon from '@mui/icons-material/ArrowBackIosOutlined';
import { API_REGISTRY } from "../../app/config";
import { clearAPIRegistryData } from "../../app/util";
import {
  AccessTime as LocaleIcon,
  AddAlert as AddAlertIcon,
  AlarmOutlined as SchedulerIcon,
  Apartment as PropertiesIcon,
  ArrowBackOutlined as ArrowBackOutlinedIcon,
  Assessment as ReportsIcon,
  AttachMoneyOutlined as RatePushIcon,
  Bed as DefaultRoomTypePricingIcon,
  Discount as DiscountRuleIcon,
  DomainAddOutlined as AddNewPropertyIcon,
  IntegrationInstructions as IntegrationIcon,
  Inventory as InventoryIcon,
  ManageAccounts as ManageAccountsIcon,
  ManageHistory as ManageRatePlanIcon,
  MonetizationOnOutlined as PricingRulesIcon,
  MoreVertOutlined as MoreVertOutlinedIcon,
  PriceCheck as RatesIcon,
  Refresh as RefreshIcon,
  Security as SecurityIcon,
  Segment as SegmentsIcon,
  Storefront as MarketIcon,
  Summarize as CompsetIcon,
} from "@mui/icons-material/";
import {
  Alert,
  Box,
  Button,
  CircularProgress,
  Container,
  Grid,
  Tooltip,
} from "@mui/material/";
import { ThemeProvider } from "@mui/material/styles";
import React, { useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";

import { updateAppState } from "../../actions/appstate";
import { getUserProfile } from "../../actions/auth";
import { PROFILE_SUCCESS, UPDATE_STATE } from "../../actions/types";
import DataService from "../../services/data.service";
import theme from "../../theme";
import Header from "../Header";
import { DashboardFooter } from "../layout/dashboard-ui";
import { ProfileSettings, SecuritySettings } from "./profile-settings";
import {
  AddNewProperty,
  AlertsSettings,
  CompsetSettings,
  DefaultRoomTypePricing,
  DiscountRulesSettings,
  IntegrationSettings,
  InventorySettings,
  LocaleSettings,
  ManageRatePlans,
  MarketsSettings,
  PricingRules,
  RatesSettings,
  SegmentsSettings,
} from "./property-settings";

import ScheduleReportsSettings from "./scheduler-reports";
import ScheduleRatePushSettings from "./scheduler-ratepush";
import SettingsSideMenu from "./settings-menu";

//Global custom theme configuration
//import DataService from "../services/data.service";;
//Common UI Elements for screen
const Settings = () => {
  const location = useLocation();
  //User permissions mapping
  //    (0, 'User'),
  //   (1, 'user Admin'),
  //   (2, 'LodgIQ Admin'),
  //   (3, 'Read only'),
  //   (4, 'QA'),
  //   (5, 'Limited'),
  const profileSettingsMenu = [
    {
      id: "profile",
      label: "Profile",
      component: "ProfileSettings",
      index: 1,
      access: [0, 1, 2, 3, 4, 5],
      icon: <span className='profile-icon'></span>,
    }
  ];
  const propertySettingsMenu = [
    {
      id: "inventory",
      label: "Inventory",
      component: "InventorySettings",
      index: 3,
      access: [0, 1, 2, 3, 4, 5],
      icon: <span className='inventory-icon'></span>,
    },
    {
      id: "compset",
      label: "Compset *",
      component: "CompsetSettings",
      index: 4,
      access: [2],
      icon: <span className='compset-icon'></span>,
    },
    {
      id: "rates",
      label: "Rates *",
      component: "RatesSettings",
      index: 5,
      access: [2],
      icon: <span className='rates-icon'></span>,
    },
    {
      id: "segments",
      label: "Segments",
      component: "SegmentsSettings",
      index: 6,
      access: [0, 1, 2, 3, 4, 5],
      icon: <span className='mappings-icon'></span>,
    },
    {
      id: "markets",
      label: "Markets *",
      component: "MarketsSettings",
      index: 7,
      access: [2],
      icon: <span className='markets-icon'></span>,
    },
    {
      id: "locale",
      label: "Locale *",
      component: "LocaleSettings",
      index: 8,
      access: [2],
      icon: <LocaleIcon color="white" sx={{ width: 17 }} />,
      icon: <span className='local-icon'></span>,
    },
    {
      id: "integration",
      label: "Integration *",
      component: "IntegrationSettings",
      index: 9,
      access: [2],
      icon: <span className='integration-icon'></span>,
    },
    {
      id: "discountrules",
      label: "Discount Rules *",
      component: "DiscountRulesSettings",
      index: 10,
      access: [2],
      icon: <span className='discount-rules-icon'></span>,
    },

    {
      id: "alerts",
      label: "Alerts",
      component: "AlertsSettings",
      index: 11,
      access: [0, 1, 2, 3, 4, 5],
      icon: <span className='alerts-icon'></span>,
    },
    // {
    //   id: "managerateplans",
    //   label: "Manage Rate Plans",
    //   component: "ManageRatePlans",
    //   index: 12,
    //   icon: <span className='pricing-rules-icon'></span>,
    // },
    // {
    //   id: "pricingrules",
    //   label: "Pricing Rules",
    //   component: "PricingRules",
    //   index: 13,
    //   icon: <span className='pricing-rules-icon'></span>,
    // },
    {
      id: "defaultroomtypepricing",
      label: "Pricing Seasons",
      component: "DefaultRoomTypePricing",
      index: 14,
      access: [0, 1, 2, 3, 4, 5],
      icon: <span className='default-room-pricings-icon'></span>,
    },
    // {
    //   id: "addnewproperty",
    //   label: "Add New Property",
    //   component: "AddNewProperty",
    //   index: 15,
    //   icon: <AddNewPropertyIcon color="white" sx={{ width: 17 }} />,
    //   isLast: true,
    // },
  ];
  const schedulerSettingsMenu = [
    {
      id: "reports",
      label: "Reports",
      component: "ScheduleReportsSettings",
      index: 16,
      access: [0, 1, 2, 3, 4, 5],
      icon: <ReportsIcon color="white" sx={{ width: 20 }} />,
    },
    {
      id: "ratepush",
      label: "Rate Push",
      component: "ScheduleRatePushSettings",
      index: 17,
      access: [0, 1, 2, 3, 4, 5],
      icon: <RatePushIcon color="white" sx={{ width: 20 }} />,
      isLast: true,
    },
  ];
  const settingsComponentsMap = {
    ProfileSettings: <ProfileSettings></ProfileSettings>,
    SecuritySettings: <SecuritySettings></SecuritySettings>,
    InventorySettings: <InventorySettings></InventorySettings>,
    CompsetSettings: <CompsetSettings></CompsetSettings>,
    RatesSettings: <RatesSettings></RatesSettings>,
    SegmentsSettings: <SegmentsSettings></SegmentsSettings>,
    MarketsSettings: <MarketsSettings></MarketsSettings>,
    LocaleSettings: <LocaleSettings></LocaleSettings>,
    IntegrationSettings: <IntegrationSettings></IntegrationSettings>,
    DiscountRulesSettings: <DiscountRulesSettings></DiscountRulesSettings>,
    AlertsSettings: <AlertsSettings></AlertsSettings>,
    ManageRatePlans: <ManageRatePlans></ManageRatePlans>,
    PricingRules: <PricingRules></PricingRules>,
    DefaultRoomTypePricing: <DefaultRoomTypePricing></DefaultRoomTypePricing>,
    AddNewProperty: <AddNewProperty></AddNewProperty>,
    ScheduleReportsSettings: (
      <ScheduleReportsSettings></ScheduleReportsSettings>
    ),
    ScheduleRatePushSettings: (
      <ScheduleRatePushSettings></ScheduleRatePushSettings>
    ),
  };

  const { app, auth } = useSelector((state) => state);
  const { app_state, ui_state } = app.current_state;
  const { user } = auth;
  const { user_login_type } = user;
  const ActiveSettingsComponent = () => {
    const {
      activeSettingsComponentName,
      active_settings_menu,
      settingsActiveItem,
    } = ui_state;
    let component = activeSettingsComponentName;
    if (ui_state.isFromHeader) {
      component = eval(active_settings_menu)[0].component;
    }
    if (!component) return "";
    return <ErrorBoundary FallbackComponent={ErrorHandler}>{settingsComponentsMap[component]}</ErrorBoundary>;
  };
  const navigate = useNavigate();
  const isFromHeader = ui_state.isFromHeader;
  const dispatch = useDispatch();
  const active_settings_menu = ui_state.active_settings_menu;
  const { settingsActiveIndex } = ui_state;
  const [expanded, setExpanded] = useState(active_settings_menu);
  const [selectedIndex, setSelectedIndex] = useState(settingsActiveIndex);
  const [refreshingData, setRefreshingData] = useState(false);
  const [refreshedData, setRefreshedData] = useState(false);
  let selected_index = settingsActiveIndex;
  if (active_settings_menu == "profileSettingsMenu") selected_index = 1;
  if (active_settings_menu == "propertySettingsMenu") selected_index = 3;
  if (active_settings_menu == "schedulerSettingsMenu") selected_index = 16;

  const active_settings_menu_map = {
    profile_settings: "Profile Settings",
    property_settings: "Property Settings",
    scheduler_settings: "Schedule Settings",
  };

  const active_settings_classNames = {
    ProfileSettings: "profile-settings",
    ScheduleReportsSettings: "scheduler reports",
    ScheduleRatePushSettings: "scheduler ratepush",
  };

  if (ui_state.isFromHeader) {
    const settingsActiveItem = eval(active_settings_menu)[0];
    dispatch({
      type: UPDATE_STATE,
      payload: {
        ui_state: {
          isFromHeader: false,
          activeSettingsComponentName: settingsActiveItem.component,
          settingsActiveIndex: selected_index,
        },
      },
    });
  }

  const handleChange = (panel, ind) => (event, isExpanded) => {
    setSelectedIndex(settingsActiveIndex);
    setExpanded(isExpanded ? panel : false);
    //setSelectedIndex(selected_index);
    if (isExpanded) {
      dispatch({
        type: UPDATE_STATE,
        payload: { ui_state: { active_settings_menu: panel } },
      });
    }
  };

  function refreshData() {
    const payload = {
      token_string: user.token_string,
      resourceId: app_state.property_id,
      resourceType: "property",
      targetCache: ["srr", "forecast", "otb"],
      operation: "flush",
    };
    setRefreshingData(true);
    // debugger;
    DataService.getData({ api: "rms/properties/clear-cache/" + app_state.property_id + "/" + app_state.active_data_src.id + "/", request: { method: 'GET' } })
      .then((data) => {
        //Do some task here just after respons
        if (data.status === "success") {
          setRefreshedData(true);
          setRefreshingData(false);
          console.log("refreshData Success");
          setTimeout(() => {
            setRefreshedData(false);
            // window.location.reload();
          }, 2000);
          clearAPIRegistryData();
        } else {
          setRefreshingData(false);
          console.warn("refreshData Error, data could not refreshed");
          setTimeout(() => {
            setRefreshedData(false);
          }, 3000);
        }
      })
      .catch((err) => {
        setRefreshingData(false);
        setTimeout(() => {
          setRefreshedData(false);
        }, 3000);
      });

    // getUserProfile({
    //   api: "users/get_profile",
    //   request: { token_string: user.token_string, user_id: user.user_id },
    // })
    //   .then((data) => {
    //     setRefreshingData(false);
    //     console.log("refreshData Success");
    //     console.warn("refreshData Error, data could not refreshed");
    //     dispatch({ type: PROFILE_SUCCESS, payload: data.user_profile });
    //   })
    //   .catch((err) => {
    //     setRefreshingData(false);
    //     console.warn("refreshData Error, data could not refreshed");
    //   });
  }

  function goBackToDashboard() {
    dispatch(
      updateAppState("navigate_to_dashboard", {
        app_state: {
          data_load: true,
          kpi_load: true,
        },
        ui_state: {
          active_settings_menu: "",
          showspinner: true,
        },
      }, "settings_click")
    );
    navigate("/");
  }
  return (
    <ThemeProvider theme={theme}>
      <Box className="settings" sx={{ backgroundColor: "white.main", minHeight: "100%" }}>
        <Header />



        <Grid
          className={active_settings_classNames[ui_state.activeSettingsComponentName]}
          container
          display="flex"
          sx={{ minHeight: "calc(100vh - 35px)" }}
        >
          <Grid className='setting-sidemenu'
            item
            lg={2.5}
            sx={{ display: { xs: "none", lg: "flex" }, minHeight: "100%" }}
          >
            <Container style={{ minHeight: "100%" }}>
              <Box
                sx={{
                  minHeight: "100%",
                  // borderRight: "5px solid",
                  // borderRightColor: "blue.light",
                  // pt: 1,
                  // pr: 0,
                  pt: 1
                }}
              >
                <SettingsSideMenu
                  menuIcon={<ManageAccountsIcon></ManageAccountsIcon>}
                  handleChange={handleChange}
                  expanded={active_settings_menu === "profileSettingsMenu"}
                  menuId="profileSettingsMenu"
                  activeItemIndex={selected_index}
                  title="Profile settings"
                  data={profileSettingsMenu}
                  isFromHeader={isFromHeader}
                />
                <SettingsSideMenu

                  menuIcon={<PropertiesIcon></PropertiesIcon>}
                  handleChange={handleChange}
                  expanded={active_settings_menu === "propertySettingsMenu"}
                  menuId="propertySettingsMenu"
                  activeItemIndex={selected_index}
                  title="Property settings"
                  data={propertySettingsMenu}
                  isFromHeader={isFromHeader}

                />
                <SettingsSideMenu
                  menuIcon={<SchedulerIcon></SchedulerIcon>}
                  handleChange={handleChange}
                  expanded={active_settings_menu === "schedulerSettingsMenu"}
                  menuId="schedulerSettingsMenu"
                  activeItemIndex={selected_index}
                  title="Scheduler"
                  data={schedulerSettingsMenu}
                  isFromHeader={isFromHeader}
                />
              </Box>
            </Container>
          </Grid>
          <Grid
            item
            xs={12}
            lg={9.5}
            maxWidth="100%"
            sx={{ minHeight: "100%", position: "relative" }}
            className="settings-forms"
          >
            {/* <div>Breadcrumb</div> */}

            <Box
              className='top-axn-bar'
              sx={{ background: '#F5F5F5', boxShadow: '0 0 3px #000', pt: 0, pb: { lg: 1.5, xs: 5 } }}
            // backgroundColor: "#F5F5F5",
            >

              <Container style={{ maxWidth: "100%", minHeight: "100%" }}>
                <Grid
                  container
                  display="flex"
                  sx={{ pt: { xs: 5, sm: 4.5, md: 7, lg: 7.3 } }}
                >
                  <Alert
                    icon={false}
                    sx={{
                      display: refreshedData ? "block" : "none",
                      position: "fixed",
                      left: 0,
                      fontSize: 17,
                      textAlign: "center",
                      borderRadius: 0,
                      lineHeight: 1.5,
                      bottom: 30,
                      width: "100%",
                      zIndex: 100,
                      backgroundColor: "green.main",
                      color: "white.main",
                    }}
                    severity="success"
                  >
                    Data refreshed successfully!
                  </Alert>
                  <Grid item lg={2.5} xs={5}>
                    <Box sx={{ position: "relative", zIndex: 100, top: 0, width: "100%" }}>
                      <Tooltip title="Back to Dashboard" arrow followCursor>
                        <Button
                          size="medium"
                          variant="contained"
                          className='go-back-button '
                          // sx={{
                          //   color: "white.main",
                          //   backgroundColor: "#000",
                          // }}
                          onClick={goBackToDashboard}
                        >
                          <ArrowBackIosOutlinedIcon
                            className='inside-line breadcrum-inactive'
                            color="white.main"
                            fontSize="medium"
                          ></ArrowBackIosOutlinedIcon>
                        </Button>
                      </Tooltip>
                    </Box>
                  </Grid>
                  <Grid item xs sx={{ textAlign: "center" }}>
                    <Box
                      sx={{
                        textAlign: "right",
                        position: "relative",
                        top: 5,
                        width: "100%",
                        display: { lg: "none" },
                      }}
                    >
                      <Button
                        size="medium"
                        variant="contained"
                        sx={{ color: "white.main", backgroundColor: "green.main" }}
                      >
                        <MoreVertOutlinedIcon fontSize="medium"></MoreVertOutlinedIcon>
                      </Button>
                    </Box>
                  </Grid>
                  <Grid item lg={2.5} xs={5}>
                    {user_login_type === 2 && (<Box
                      sx={{
                        textAlign: "right",
                        position: "relative",
                        top: 0,
                        width: "100%",
                      }}
                    >
                      <Tooltip title="Refresh Data">
                        <Button
                          onClick={refreshData}
                          size="medium"
                          variant="contained"
                          className='go-back-button'
                        // sx={{
                        //   color: "white.main",
                        //   backgroundColor: "#01A4EF",
                        // }}
                        >
                          <RefreshIcon
                            className='inside-line'
                            color="white.main"
                            fontSize="medium"
                          ></RefreshIcon>
                        </Button>
                      </Tooltip>
                    </Box>)}

                  </Grid>
                </Grid>
              </Container>
            </Box>

            <Box sx={{ position: 'relative' }}>
              {/* <Container sx={{ position:'relative', minHeight: "100%", paddingLeft: { md: 0 }, paddingRight: { md: 0 } }}> */}

              <ActiveSettingsComponent></ActiveSettingsComponent>

              {/* </Container> */}
            </Box>
          </Grid>
        </Grid>
        {refreshingData && (
          <Box
            sx={{
              textAlighn: "center",
              backgroundColor: "dark.darkest",
              opacity: 0.8,
              position: "fixed",
              top: 40,
              left: 0,
              width: "100%",
              height: "100%",
            }}
          >
            <CircularProgress
              size={40}
              sx={{
                color: "white",
                position: "absolute",
                top: "50%",
                left: "50%",
                marginTop: "-20px",
                marginLeft: "-20px",
              }}
            />
          </Box>
        )}
      </Box>
      <DashboardFooter />
    </ThemeProvider>
  );
};
export default Settings;
