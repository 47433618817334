import {
   
    LOGIN_SUCCESS,
    LOGIN_FAIL,
    LOGOUT,
    PROFILE_FAILED,
    PROFILE_SUCCESS,
    SYSTEM_SETTINGS_UPDATED
  } from "../actions/types";
  // const user = JSON.parse(localStorage.getItem("user"));
  const initialState ={ isLoggedIn: false, user: null, profile:null, profileSuccess:false} ;
  export default function (state = initialState, action) {
    //debugger;
    const { type, payload } = action;
    switch (type) {
      case SYSTEM_SETTINGS_UPDATED:
        // const {index, settings} = payload;
        // const profile = JSON.parse(JSON.stringify(state.profile));
        // profile.property_details[index].system_settings[0] = settings;
        
        //state.profile.property_details[index].system_settings[0] = settings;
        //const profile = state.profile
        //debugger; 
        state.profile = payload;
        return {...state};
        break;       
      case PROFILE_SUCCESS:
        state.profile = payload;
        return {
          ...state,
          profileSuccess:true,
          profile: payload,
        };
        break;
      case PROFILE_FAILED:
        return {
          ...state,
          profileSuccess: false,
          profile: null,
        };
        break;
      case LOGIN_SUCCESS:
        return {
          ...state,
          isLoggedIn: true,
          user: payload.user,
        };
        break;
      case LOGIN_FAIL:
        return {
          ...state,
          isLoggedIn: false,
          profileSuccess: false,
          user: null,
        };
        break;
      case LOGOUT:
        sessionStorage.clear();
        localStorage.clear();
        return {
          ...state,
          isLoggedIn: false,
          profileSuccess: false,
          user: null,
          profile:null,
        };
        break;
      default:
        return state;
    }
  }
  