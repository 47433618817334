import React, { useReducer, useRef, useState } from 'react'

import {
  AppBar
  , Box
  , Toolbar
  , IconButton
  , Typography
  , Menu
  , MenuItem
  , Container
  , Avatar
  , Button
  , Grid
  , Tooltip
  , Paper
  , FormControl
  , OutlinedInput
  , InputLabel
  , Select
  , ListSubheader
  , TextField
  , InputAdornment
  , List
  , Link
  , ListItem
  , ListItemIcon
  , ListItemText
  , Divider
  , Drawer
} from '@mui/material/';
import {
  Person as PersonIcon
  , SettingsOutlined as SettingsOutlinedIcon
  , ScheduleOutlined as ScheduleOutlinedIcon
  , VideocamOutlined as VideocamOutlinedIcon
  , PowerSettingsNewOutlined as PowerSettingsNewOutlinedIcon
  , AssessmentOutlined as AssessmentOutlinedIcon
  , ArrowForwardRounded as ArrowForwardRoundedIcon
  , ApartmentSharp as ApartmentSharpIcon
  , Search as SearchIcon
  , KeyboardDoubleArrowRight as KeyboardDoubleArrowRightIcon
} from '@mui/icons-material/';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import CloseIcon from '@mui/icons-material/Close';
import CreateNewFolderIcon from '@mui/icons-material/CreateNewFolder';

import PhotoCamera from '@mui/icons-material/PhotoCamera';
import Stack from '@mui/material/Stack';
import DocumentList from './DocumentList';
import { color } from 'highcharts';
import CloudUploadOutlinedIcon from '@mui/icons-material/CloudUploadOutlined';
import { settingsReducers } from "./reducers";
import moment from "moment";
import { getFromSession } from '../../app/util';
import { API_BASE_URL } from "../../app/config";
import { useSelector } from 'react-redux';
import Alert from '@mui/material/Alert';
import LinearProgress from '@mui/material/LinearProgress';

import { FileUploader } from "react-drag-drop-files";
import FileUploadDragDrop from './FileUploadDragDrop';

const UploadFile = (props) => {

  const initalState = {
    data: { files: [], folders: [] },
    ui: {
      request: {
        resType: null,
        status: "idle",
        message: "",
      },
      path: [],
      breadcrumb: []
    },
  };

  const [settingsState, dispatchSettings] = useReducer(settingsReducers, initalState);
  const { app, auth } = useSelector((state) => state);
  const { user, profile } = auth;
  const { app_state } = app.current_state;
  const inputFile = useRef(null)
  const [fileName, setFileName] = useState("");
  const [fileData, setFileData] = useState("");
  const { ui, data } = settingsState;
  const onButtonClick = () => {
    inputFile.current.click();
  };

  let uploadReport = props.reportId

  // Upload file function 
  const handleSelectFile = (e) => {
    let file = e.target.files;
    setFileData(file);
    setFileName(file[0].name);
  }

  // End Upload file function 



  const submitFileData = () => {
    console.log(fileData);
    let file = fileData;
    let formData = new FormData();

    var today = moment().format('YYYY-MM-DD');
    var file_upload_type;
    if (uploadReport === 'budget_upload') {
      file_upload_type = "budget";
    } else if (uploadReport === 'forecast_upload') {
      file_upload_type = "manual_forecast";
    } else if (uploadReport === 'documents_upload') {
      file_upload_type = "document";
    }

    if (file[0]) {
      formData.append("file", file[0]);
      formData.append("property_id", app_state.property_id);
      formData.append("user_id", auth.user.user_id);
      formData.append("file_upload_type", file_upload_type);
      formData.append("asof_date", today);
      formData.append("folder_uid", "");
      formData.append("debug", true);
      formData.append("token", getFromSession("token"));
      formData.append("token_string", getFromSession("token"));
    }

    const options = {
      method: "POST",
      body: formData,
    };

    // debugger;
    dispatchSettings({ type: "wait" });
    fetch(API_BASE_URL + "documents/property_file_upload", options)
      .then((rs) => {
        console.log("file uploaded=>", rs);
        setFileName("");
        if (rs.status === 200) {
          //  settingsState.data.files  = rs.data.files;
          dispatchSettings({ type: "onFileUpload" });
        } else {
          dispatchSettings({ type: "onResponseError" });
        }
        setTimeout(() => {
          dispatchSettings({ type: "onHideAlert" });
        }, 3000);
      })
      .catch(() => {
        dispatchSettings({ type: "onResponseError" });
        setTimeout(() => {
          dispatchSettings({ type: "onHideAlert" });
        }, 3000);
      });
  }


  const btnStyle = {
    width: "auto", background: "#9ed00d", top: 2, fontSize: 13, pt: 1, mt: 2, pb: 1
  }

  return (
    <>
      {ui.request.message !== "" ? (
        <Box>
          <Alert icon={false} severity="success" sx={{ minWidth: "500px !important", pl: "39%" }}>

            {ui.request.message}
          </Alert>
        </Box>
      ) : (
        <></>
      )
      }
       <FileUploadDragDrop data={props.data} getListing={props.getListing} submitFileData={submitFileData} file_type={props.reportId} />

      <Container className='mainContainer' sx={{ border: "0px dashed #666666", background: "#ebebeb", ml: 0, borderRadius: 1 }}>
       
       <Box>
       
       </Box>
       
        <Box sx={{ position: "relative", display:"none" }} onClick={onButtonClick} >
          <input hidden accept="*/*" multiple type="file" ref={inputFile} onChange={(e) => handleSelectFile(e)} />
          <Typography sx={{ textAlign: "center", pt: 0, color: "#bbb" }}>
            <CloudUploadOutlinedIcon sx={{ fontSize: 60, color: "#9ed00d" }} />
          </Typography>
          {fileName == '' ? (
            <>
              <Typography sx={{ textAlign: "center", color: "#bbb", mb: 1 }}>
                Please select a file to upload.
              </Typography></>
          ) : (
            <>
              <Typography sx={{ textAlign: "center", color: "#bbb" }}>
                {fileName}
              </Typography>
              <Typography sx={{ textAlign: "center", color: "#bbb", mb: 1.5 }}>
                <Button variant="contained"
                  onClick={(e) => { e.stopPropagation(); submitFileData() }}
                  sx={btnStyle} size="midium">Upload File</Button>

              </Typography>
            </>
          )
          }

        </Box>

      </Container>
    </>
  )
}

export default UploadFile
