

import { Box, TextField, InputAdornment } from '@mui/material'
import { useDispatch, useSelector } from 'react-redux';
import { updateAppState } from '../../../../actions/appstate';



const PickUpDays = () => {
    const controls = useSelector(state => state.app.current_state.app_state.workspace_controls);
    const dispatch = useDispatch();

    const handleChange = (type, value) => {
        const updateTypes = [type];
        controls.pickUpDays = value;
        dispatch(
            updateAppState(
                updateTypes, { app_state: { workspace_controls: controls, data_load: true },
                 ui_state: { showspinner: true } }));
        // dispatch(updateAppState(type, {app_state: {selected_market:value,data_load:true},ui_state:{showspinner:true}}));

    }

    return (
        <Box id="pickupDaysBox" className='control-options pickup-days' sx={{position:"relative"}}>
            <Box className='filterElementText'> PICKUP </Box>
            <TextField
                size="small"
                fullWidth
                sx={{ mt: "5px", width:75}}
                InputProps={{
                    style: { height: 27, fontSize:13, },
                    endAdornment: <InputAdornment disableTypography={true} fontSize="12px" position="end">Day</InputAdornment>
                }}
                onBlur={(e) => {if(controls.pickUpDays != e.target.value) handleChange("pickupDays", e.target.value) }}
                defaultValue={controls.pickUpDays}
                type="Number"
                // endAdornment={<InputAdornment position="end">Day</InputAdornment>}
                // value={controls.pickUpDays}
            >
            </TextField>
            {/* <span className='label-days'>Day</span> */}
        </Box>
    )
}

export default PickUpDays