import _ from "lodash";
import moment from "moment";
import dataService from "../services/data.service";
import { API_REGISTRY, TILES, ABORT_REQUEST_CONTROLLERS } from "./config";
import TableToExcel from "@linways/table-to-excel";
import AbortController from "abort-controller";

export function calculateCompStayRange(params) {
    // debugger;
    let app_state = { primary_stay_range: params.primary_stay_range };
    let daysDiff = dateDiff(app_state.primary_stay_range.date_until, app_state.primary_stay_range.date_from);
    // let fromDateLastYear = new moment(app_state.primary_stay_range.date_from).subtract(1, 'year').startOf('months').format("YYYY-MM-DD");
    let primaryDateFrom = new moment(app_state.primary_stay_range.date_from);
    let fromDateLastYear = new moment(app_state.primary_stay_range.date_from).subtract(1, 'year')
        .isoWeek(primaryDateFrom.isoWeek())
        .isoWeekday(primaryDateFrom.isoWeekday()).format("YYYY-MM-DD");
    let untilDateLastYear = new moment(fromDateLastYear).add(daysDiff, 'day').format("YYYY-MM-DD");
    let primaryAsOf = new moment(app_state.primary_stay_range.as_of_date);
    let sdlyAsOf = new moment(app_state.primary_stay_range.as_of_date).subtract(1, 'year')
        .isoWeek(primaryAsOf.isoWeek())
        .isoWeekday(primaryAsOf.isoWeekday());
    let cAsOfDate = sdlyAsOf.format("YYYY-MM-DD");
    let comp_stay_range = { date_from: fromDateLastYear, date_until: untilDateLastYear, comp_as_of_date: cAsOfDate, as_of_date: cAsOfDate };
    return comp_stay_range;
}

export function abortRequestSafe(key, reason = "CANCELLED") {
    if (key && ABORT_REQUEST_CONTROLLERS.get(key)) {
        let ab = ABORT_REQUEST_CONTROLLERS.get(key);
        // debugger;
        ABORT_REQUEST_CONTROLLERS.get(key).abort(reason);
        console.log('ABORT_REQUEST_CONTROLLERS', ABORT_REQUEST_CONTROLLERS);
    }
}

export function setAbortSignal(key) {
    const newController = new AbortController();
    ABORT_REQUEST_CONTROLLERS.set(key, newController);
    return newController.signal;
}

export function clearAPIRegistryData() {
    for (let key in API_REGISTRY) {
        API_REGISTRY[key].data = null;
    }
}

export function isEmptyObj(obj) {
    return _.isEmpty(obj);
}
export const checkExistance = (val, blankValue = '',key = 'Revenue') => {
    // return (val) ? parseFloat(val).toFixed(2) : blankValue;
    if(key === "Rooms"){
        return (val) ? val.toLocaleString() : blankValue;
    }else{
        return (val) ? val.toLocaleString(undefined, {minimumFractionDigits: 2}) : blankValue;
    }
}


export function getCorrectValue(val) {
    val = +val || 0;
    return val;
 }

export function convertTOCorrectValue(val,kpiType="Occ",currSymbol='') {
    console.log(val," => ",kpiType);
    if(kpiType !== "Rooms" && kpiType !== "Occ" && !Number(removeSpecialChar(val))){
        val = +val || currSymbol+"0.00"
    }
    if(kpiType === "Occ" && !Number(removeSpecialChar(val))){
        val = +val || "0.00%"
    }
    return val;
 }

 export function removeSpecialChar(val){
    val = (val) ?  val.replace(/[&\/\\#,+()$~%.'":*?<>{}]/g, '') : '';
    return val;
 }


 export function removeSpecialCharWithOutDecimal(val){
    val = (val) ?  val.replace(/[&\/\\#,+()$~%'":*?<>{}]/g, '') : '';
    return val;
 }


export function capitalize(str) {
    var pieces = str.split(" ");
    for (var i = 0; i < pieces.length; i++) {
        var j = pieces[i].charAt(0).toUpperCase();
        pieces[i] = j + pieces[i].substr(1);
    }
    return pieces.join(" ");
}

export function getDataSources(properties, propertyID) {
    const property_details = getFromArr(properties, 'id', propertyID);
    const { data_sources, active_source, default_source, i } = property_details;
    return { data_sources, active_source, default_source, index: i };
}

export function getPermissions(properties, propertyID) {
    const property_details = getFromArr(properties, 'id', propertyID);

    const { permissions, i } = property_details;
    return { permissions, index: i };
}

export const getDaysForPickupOcc = (app_state, ui_state) => {
    let startDate = moment(app_state.as_of_date).subtract(1, "days").format('YYYY-MM-DD');
    let endDate = app_state.primary_stay_range.date_until;
    let noOfDays = moment(startDate).diff(moment(endDate), "days");
    // console.log(startDate," ",endDate," from method => ",noOfDays);
    return Math.abs(noOfDays) + 1;
}

export const getDatesInRange = (start, end) => {
    let startDate = moment(start);
    let endDate = moment(end);
    let dates = [];
    while (startDate.isSameOrBefore(endDate)) {
        dates.push(startDate.format("YYYY-MM-DD"));
        startDate.add(1, 'day');
    }
    return dates;
}

export const in_array = (arr, searchElementArr) => {
    let isFound = false;
    searchElementArr.map((item) => {
        if (arr.includes(item)) {
            isFound = true;
        }
    })
    return isFound;
}

// this function is used to pause the code for the given amount of time 
export function wait(ms){
    var start = new Date().getTime();
    var end = start;
    while(end < start + ms) {
      end = new Date().getTime();
   }
 }

export const checkProperValue = (val) => {
    let correctValue = (val && isFinite(val)) ? val : 0.00
    return correctValue;
}


// function to replace multi values from a string 
export function replaceMultiText(text, correction) {
    const reg = new RegExp(Object.keys(correction).join("|"), "g");
    return text.replace(reg, (matched) => correction[matched]);
}
export function generateTableHead(table, data) {
    let thead = table.createTHead();
    let row = thead.insertRow();
    for (let key of Object.keys(data)) {
      let th = document.createElement("th");
      key = key.replace("_", " ");
      let text = document.createTextNode(key);
      th.appendChild(text);
      row.appendChild(th);
    }
  }
  
  export function generateTable(table, data) {
  
    for (let element of data) {
      let row = table.insertRow();
      for (let key of  Object.keys(element)) {
        let cell = row.insertCell();
        let val = element[key];
        
        if(!isNaN(val) && val !==""){
            cell.dataset.t="n";
        }
        
        let text = document.createTextNode(element[key]);
        cell.appendChild(text);
      }
    }
  }

export function downloadReportToExcel(name, selectedPropertyName, tableID) {

    let exportFileName = name + "_" + selectedPropertyName;
    let table =document.getElementById(tableID)
    TableToExcel.convert(table, {
        name: exportFileName + ".xlsx",
        sheet: {
            name: name.toUpperCase()
        }
    });

}

export function generateRoomTypeExcel(name, selectedPropertyName, roomtype_pricing_overrides) {
    const table = document.createElement('table');
    let exportFileName = name + "_" + selectedPropertyName;
    let thead = table.createTHead();
    let row = thead.insertRow();
    let colData = ["Date", "Dow", "Rooms & Rate"];
    colData.forEach(col => {
        let th = document.createElement("th");
        let text = document.createTextNode(col);
        th.appendChild(text);
        row.appendChild(th);
    })

    Object.keys(roomtype_pricing_overrides).forEach((stay_date, i) => {
        const tr = table.insertRow();
        colData.forEach((col, j) => {
            const td = tr.insertCell();
            let text = "";
            if (col === "Date") {
                text = stay_date
            }
            if (col === "Dow") {
                text = moment(stay_date).format("ddd")
            }

            if (col === "Rooms & Rate") {
                for (const [key, item] of Object.entries(roomtype_pricing_overrides[stay_date])) {
                    text += `${key}- ${item.SellRate} `
                }
            }
            td.appendChild(document.createTextNode(text));
            tr.appendChild(td);
        })
        table.appendChild(tr);
    })
    TableToExcel.convert(table, {
        name: exportFileName + ".xlsx",
        sheet: {
            name: name.toUpperCase()
        }
    });
}
export function getSystemSettings(property_id, property_details) {

    const prop_details = property_details.filter((item, index) => {
        if (item.id === property_id) {
            return item.id === property_id;
        }
    });
    const { system_settings } =
        prop_details && prop_details.length ? prop_details[0] : null;
    return system_settings[0];
}
export const getCookie = (cname) => {
    let name = cname + "=";
    let ca = document.cookie.split(';');
    for (let i = 0; i < ca.length; i++) {
        let c = ca[i];
        while (c.charAt(0) == ' ') c = c.substring(1);
        if (c.indexOf(name) != -1) return c.substring(name.length, c.length);
    }
    return "";
}
export const totalRowCalculation = (tile, app_state, i, displayDataFormat) => {
    console.log("Tile => ", tile);
    if (tile.toLowerCase() === "market_summaryx") {

    } else {
        let occValue = (tile.toLowerCase() === 'pace') ? i.Occ / app_state.workspace_controls.dba : i.Occ;
        let revParValue = (tile.toLowerCase() === 'pace') ? i.RevPAR / app_state.workspace_controls.dba : i.RevPAR;
        revParValue = ((typeof revParValue).toLowerCase() === "string") ? revParValue : displayDataFormat(revParValue, "RevPAR", "");
        let adrValue = ((typeof i.ADR).toLowerCase() === "string") ? i.ADR : displayDataFormat(i.ADR, "ADR", "");
        i.occValue = occValue;
        i.revParValue = revParValue;
        i.adrValue = adrValue;
    }
    return i;
    // console.log(i);
}

export const processColumnsNew = (mainCol) => {
    mainCol.sort(function (a, b) { return a.order - b.order });
    mainCol.map((item, index) => {
        item.order = index + 1;
        let subColumns = item.subcolumns
        subColumns.sort(function (a, b) { return a.order - b.order });
    })

    let sortedColumns = [...mainCol];
    return sortedColumns;
}



//Common functions for display value formatting
export const displayFormat = (value, type, options) => {
    let formatted = value;
    switch (type) {
        case "percent":
            formatted += "%";
            break;
        case "currency":
            formatted = currencyFormat(formatted, options);
            break;
    }
    return formatted;
}

export const currencyFormat = (val, currency) => {
    //debugger;
    return currency.symbol + val;
}


// function to get Local data 

export function getLocalData(auth, app_state, property_id) {
    const { property_details, default_property_config, application_config } = auth.profile;
    const prop_details = property_details.filter((item, index) => {
        if (item.id === property_id) {
            return item.id === property_id;
        }
    });
    const { system_settings } = (prop_details && prop_details.length) ? prop_details[0] : null;
    // console.log(app_state.property_id,"system_settings[0].default_locale",system_settings);
    let default_locale_name = (system_settings && system_settings[0].default_locale) ? system_settings[0].default_locale : 'default';
    if (!application_config['locales'][default_locale_name]) default_locale_name = 'default';
    const localeSettings = {
        currency_symbol: application_config['locales'][default_locale_name]['currency'],
        currencyCode: application_config['locales'][default_locale_name]['currencyCode']
    };
    return localeSettings;
}


// function is used to get the application data 
// Parameter : auth , app_state

export function getAppData(auth, app_state) {
    const { user, profile } = auth;
    const { property_details } = auth.profile || [];
    let prop_details_index = 0;
    const prop_details = property_details.filter((item, index) => {
        if (item.id === app_state.property_id) {
            prop_details_index = index;
            return item.id === app_state.property_id;
        }
    });
    const { system_settings } = prop_details && prop_details.length ? prop_details[0] : null;
    let inventory_settings_ = {};
    if (system_settings && system_settings.length && system_settings[0].inventory_settings) {
        inventory_settings_ = JSON.parse(JSON.stringify(system_settings[0].inventory_settings));
        inventory_settings_.current.external_systems[0].system_info =
            inventory_settings_.current.external_systems[0].system_info || " ";
        const inventory_settings = { ...inventory_settings_ };

        return system_settings[0];
    } else {
        return system_settings[0];
    }

}

// function is used to get all dates from the range selected
// Parameters : startDate / endDate
export function getAllDates(startDate, endDate) {
    let rangeDates = [];
    for (var m = moment(startDate); m.isSameOrBefore(endDate); m.add(1, 'days')) {
        let dateValue = m.format('YYYY-MM-DD');
        if (!rangeDates.includes(dateValue)) {
            rangeDates.push(dateValue);
        }
    }
    return rangeDates;
}


// function is used to get the list of years only ,from the range selected
// Parameters : startDate / endDate
export function getList(startDate, endDate) {
    let rangeYears = [];
    for (var m = moment(startDate); m.isBefore(endDate); m.add(1, 'days')) {
        let yearValue = m.format('YYYY');
        if (!rangeYears.includes(yearValue)) {
            rangeYears.push(yearValue);
        }
    }
    return rangeYears;
}

export function daysCountFromRange(app_state) {
    let date1 = moment(app_state.primary_stay_range.date_from);
    let date2 = moment(app_state.primary_stay_range.date_until);
    let daysCount = date2.diff(date1, 'days') + 1;
    return daysCount;
}

export function getObjectFilter(array, value, key) {
    return array.filter(key
        ? a => a[key] === value
        : a => Object.keys(a).some(k => a[k] === value)
    );
}

// sort an string array by key 

export const sortStringArray = (arr, key) => {
    console.log(arr, key);
    arr.sort(function (a, b) {
        let x = a[key].toLowerCase();
        let y = b[key].toLowerCase();
        if (x < y) { return -1; }
        if (x > y) { return 1; }
        return 0;
    });
    return arr;
}

// function to truncate the string and add the 3 dots ...

export function truncate(str, n) {
    return (str && str.length > n) ? str.slice(0, n - 1) + '...' : str;
};

export const numberFormatForReport = (key) => {
    let formatValues = { type: 'n', numfmt: '0.00' };
    if (key === "compdate" || key === "date" || key === "dow" || key === "month" || key === "segment"
        || key === "UpdateTime" || key === "User" || key === "Action" || key === "Status" || key === "Roomsrate" || key === "Restrictions" || key === "rec" || key === "cur") {
        formatValues = { type: 's', numfmt: '' }
    } else if (key === "Occ") {
        formatValues = { type: 'n', numfmt: '' }
    } else if (key === "Rooms") {
        formatValues = { type: 'n', numfmt: '0' }
    } else {
        formatValues = { type: 'n', numfmt: '$0.00' }
    }
    return formatValues;
}

export const calc = (n1, n2, limit = 2) => {
    let multiplier = 1;
    for (let i = 0; i < limit; i++) {
        multiplier = multiplier * 10;
    }
    return {
        diff: () => {
            return (Math.round(n2 * multiplier) - Math.round(n1 * multiplier)) / multiplier
        },
        add: () => {
            n1 = Number(n1);
            n2 = Number(n2);
            if (isNaN(n1)) {
                n1 = 0;
            }
            if (isNaN(n2)) {
                n2 = 0;
            }
            return (Math.round(n2 * multiplier) + Math.round(n1 * multiplier)) / multiplier
        }
    }
}
export function getRestrictionsStr(restrictions, house_level=true){
    let arr=[];
    Object.keys(restrictions).forEach((key, index)=>{
        if(key !=="stay_date"){
            if(key === "ctd" || key === "cta"){
                if(restrictions[key] === true){
                    arr.push(key.toUpperCase())
                }
                
            }
            if(key === "close"){
                if(restrictions[key] === true){
                    arr.push("CLS");
                }
               
            }
            if(key === "min_los"){
                if(restrictions[key]> 1){
                    arr.push("MN "+restrictions[key])
                }
               
            }
            if(key === "max_los"){
                if(restrictions[key]> 1){
                    arr.push("MX "+restrictions[key])
                }
               
            }
        }
    })
    if(arr.length === 0){
       return null;   
    }
    return arr.join(", ");
}
export function getResourceIdByPropId(prop_id, propDetails) {
    // debugger; 
    if (!propDetails) { return null; }
    const property_details = propDetails;
    let prop_details_index = 0;
    const property_detail = property_details.filter((item, index) => {
        return item.id === prop_id;
        //
    });
    if (!property_detail.length) { return null; }

    const { system_settings } = property_detail[0];
    const { compsetSettings } = system_settings[0];
    if (!compsetSettings) { return null; }
    return compsetSettings.resource_id;
}

export function getSeriesById(id) {
    return API_REGISTRY[id] || null;
}

export function getTileDataSource(id) {
    return TILES[id].data_sources || null
}

export function setUpDate(data_row, date) {
    const displayDate = moment(date).format("YYYY/MM/DD");
    const dow = moment(date).format("ddd");
    if (date) {
        data_row["date"] = displayDate;
        data_row["dow"] = dow;
    } else {
        data_row["date"] = "";
        data_row["dow"] = "";
    }
    return data_row;
}

export function getTileById(id) {
    return TILES[id] || null
}

export function getFromSession(key) {
    return sessionStorage.getItem(key);
}
export function addToSession(key, val) {
    sessionStorage.setItem(key, val)
}
export function getFromArr(arr, key, val) {
    let obj = null;
    arr.map((item, i) => {
        if (item[key] && item[key] === val) {
            obj = _.cloneDeep(item);
            obj.i = i;
        }
    })
    return obj;
}
export function capitalizeFirstLetter(string) {
    return string.charAt(0).toUpperCase() + string.slice(1);
}
//Arranging Tile in defined order
export function getTilesInOrder(tilesObj, group) {
    let orderedTiles = [];
    for (let i = 0; i < group.length; i++) {
        if (tilesObj[group[i]]) {
            orderedTiles.push(tilesObj[group[i]]);
        }
        // for (let key in tilesObj) {
        //     if (tilesObj[key].order == group[i]) {
        //         orderedTiles.push(tilesObj[key]);
        //     }
        // }
    }
    return orderedTiles;
}
export function deepCopy(obj) {
    //return JSON.parse(JSON.stringify(obj));
    return _.cloneDeep(obj);
}


// function is used to calculate the compset median 
export function getCompsetMedian(dataArr) {
    dataArr.sort();
    let len = dataArr.length;
    let compMedianValue = 0;
    if (len % 2 !== 0) {
        let index = (dataArr.length - 1) / 2;
        compMedianValue = dataArr[index];
    } else {
        let m1 = len / 2;
        let m2 = m1 - 1;
        compMedianValue = (dataArr[m1] + dataArr[m2]) / 2;
    }
    compMedianValue = (compMedianValue > 0) ? compMedianValue : 0;
    return Number(compMedianValue.toFixed(2));
}

export function round(num) {
    return Math.round(num * 100) / 100
}
export function isMobileDevice() {
    return false;
}

export function dateFormat(date, format) {
    return moment(date).format(format);
}
export function dateDiff(date1, date2, type) {
    let d1 = new moment(date1); // moment object of now
    let d2 = new moment(date2); // moment object of other date

    return d1.diff(d2, 'days'); // calculate the difference in days
}
export function array_move(arr, old_index, new_index) {
    while (old_index < 0) {
        old_index += arr.length;
    }
    while (new_index < 0) {
        new_index += arr.length;
    }
    if (new_index >= arr.length) {
        var k = new_index - arr.length + 1;
        while (k--) {
            arr.push(undefined);
        }
    }
    arr.splice(new_index, 0, arr.splice(old_index, 1)[0]);
    return arr; // for testing purposes
};
export function convertToPercent(num, type = 'dec') {
    if (!num) {
        return 0;
    }
    if (type === 'int') {
        num = num / 100;
    } else if (type === 'dec') {
        num = num * 100;
    }


    return Number.parseFloat((Math.round((num * 1000) / 10) / 100).toFixed(2));
}
export function hasStandardPickup() {
    let hasStandard = false;
    // if(ctr.getSeriesLoadStatus("OSR") === "loaded"){
    //     let osrData = ctr.getSeriesById("OSR");
    //     let { property_id} = appStateModule.getCurrentState();

    //     if(osrData && osrData.data){
    //         osrData.data.map(item=>{
    //             if(!item.HotelTotal.current || !item.HotelTotal.prior){
    //                 hasStandard=false
    //             }
    //         });
    //     }

    //     if((300000 < property_id ) &&  (property_id < 399999)){
    //         hasStandard = false;
    //     }
    //     if(property_id === 1263){
    //         hasStandard =false;
    //     }

    // } 
    return hasStandard;
}
function timeDiff(time_from, time_to, type) {

    return moment(time_to).diff(moment(time_from), type);

}
let loggedIn = false;
function isLoggedIn() {
    if (getFromSession("token")) {
        loggedIn = true;
    }
    else {
        loggedIn = false;
    }
    return loggedIn;
}
function getTimeMilliSeconds() {
    return parseInt(moment().format("x"));
}
const inActivityInterval = 60 * 1000; //milliseconds how often iniactivity is checked
const maxUserInactivityLimit = 4 * 60 * 60; //seconds inactivity limit until modal is showed
let timeUserInactive = 0;
let ignoreInactivity = false;
let lastEventLog = getTimeMilliSeconds();
export function setLastEventLog() {
    if (isLoggedIn()) {
        lastEventLog = getTimeMilliSeconds();
        timeUserInactive = 0;
    }


}
let startedModal = false;
export function checkActivityIntervalTrigger(logoutApp) {
    timeUserInactive = 0;
    lastEventLog = getTimeMilliSeconds();
    //startedModal=false;
    return setInterval(function () {
        if (lastEventLog && isLoggedIn() && !ignoreInactivity) {
            const timeNow = getTimeMilliSeconds();
            timeUserInactive = timeDiff(lastEventLog, timeNow, 'seconds');
            if (timeUserInactive > maxUserInactivityLimit && !startedModal) {
                console.log(timeUserInactive + " seconds of inactivity");
                logoutApp();
                //showInactivityModal();
                //startedModal=true;
            }
        }
    },
        inActivityInterval);
}
export function divide(num1, num2) {
    if (num1 == 0 || num2 === 0) {
        return 0;
    }
    return (num1 / num2);

}
export function sdly(date) {
    return moment(date).subtract(364, 'days').format("YYYY-MM-DD")
}
export function toSdly(date) {
    return moment(date).subtract(364, 'days').format("YYYY-MM-DD")
}
export function largeNumToDisplayString(num) {
    // if (number < 1000 && number > -1000) {
    //     return round(number,0);
    // }
    // else {
    //     return round(number/1000,0)+"K";
    // }
    let digits = 2;
    if (isNaN(num)) {
        return 0;
    }

    var si = [
        { value: 1E18, symbol: "E" },
        { value: 1E15, symbol: "P" },
        { value: 1E12, symbol: "T" },
        { value: 1E9, symbol: "B" },
        { value: 1E6, symbol: "M" },
        { value: 1E3, symbol: "K" }
    ],
        rx = /\.0+$|(\.[0-9]*[1-9])0+$/,
        i;

    if (num < 9999) { digits = 2 };
    if (num > 9999 && num < 99999) { digits = 1 };
    if (num > 99999 && num < 999999) { digits = 0 };
    if (num > 999999 && num < 9999999) { digits = 2 };
    if (num > 9999999 && num < 99999999) { digits = 1 };
    if (num > 99999999 && num < 999999999) { digits = 0 };
    if (num > 999999999 && num < 9999999999) { digits = 2 };
    for (i = 0; i < si.length; i++) {
        if (num >= si[i].value) {
            var beforeformattedNum = (num / si[i].value).toFixed(digits).replace(rx, "$1");

            if (beforeformattedNum.indexOf(".") >= 0) {
                var before_decimal = beforeformattedNum.split(".")[0];
                var after_decimal = beforeformattedNum.split(".")[1];
                var total_length = before_decimal.length + after_decimal.length;
                if (total_length != 3) {
                    beforeformattedNum = before_decimal + "." + after_decimal + "0";
                }
            } else if (beforeformattedNum.length == 2) {
                beforeformattedNum = beforeformattedNum + ".0"
            } else if (beforeformattedNum.length == 1) {
                beforeformattedNum = beforeformattedNum + ".00"
            }
            var finalformattedNum = beforeformattedNum + si[i].symbol
            return finalformattedNum;
        }
    }

    return num.toFixed(digits).replace(rx, "$1");
}

//get frequently used data from state
export function getFromState(key, state, propertyID) {
    // key can be like this "system_settings>market_segment_category_mapping"
    // debugger;
    let data = {};
    if (!key || !state) { return false; }
    let keys = key.split(">");
    const { app, auth } = state;
    const { app_state, ui_state } = app.current_state;

    let { property_id } = app_state;
    property_id = propertyID ? propertyID : property_id;
    const { user, profile } = auth;
    let settings = profile.property_details.filter((detail) => detail.id === property_id);
    let { system_settings } = settings.length ? settings[0] : {};
    system_settings = system_settings && system_settings.length ? system_settings[0] : null;
    switch (keys[0]) {
        case 'system_settings':
            data = system_settings ? system_settings[keys[1]] : null;
            break;
        case 'check_system_settings':
            data = system_settings ? property_id : null;
            break;
    }
    return data;
}

export function getTransformedSegments(marketSegments) {
    if (marketSegments) {
        let segments = { list: [], map: {} };
        let segmentKeys = Object.keys(marketSegments);
        segmentKeys.map((item) => {
            if (marketSegments[item].length) {
                marketSegments[item].map((code) => {
                    segments.list.push({ code: code, name: item });
                    segments.map[code] = item;
                });
            }
        });
        return segments;
    }
    return null;
}
export function filterTopSegments(lvl1_mseg_grouping) {

    let topSegments={};
    lvl1_mseg_grouping.map((item) => {
        if (item.Transient) topSegments.Transient = item.Transient;
        if (item.Group) topSegments.Group = item.Group;
        if (item.Contract) topSegments.Contract = item.Contract;
    });
    return topSegments;
    // let segments = { list: [], map: {} };
    // let segmentKeys = Object.keys(marketSegments);
    // segmentsGrouping.map()
    // segmentKeys.map((item) => {
    //     if (marketSegments[item].length) {
    //         marketSegments[item].map((code) => {
    //             segments.list.push({ code: code, name: item });
    //             segments.map[code] = item;
    //         });
    //     }
    // });
    // return segments;
}
export function aggregateObject(ob1, ob2, keys, depth) {
    // debugger
    let aggregated = {};
    if (depth && depth === 2) {

        keys[0].map((k) => {
            aggregated[k] = aggregated[k] ? aggregated[k] : {};
            ob1[k] = ob1[k] ? ob1[k] : {};
            ob2[k] = ob2[k] ? ob2[k] : {};
            keys[1].map((k2) => {
                let ag = aggregated;
                // debugger;
                aggregated[k][k2] = aggregated[k][k2] ? aggregated[k][k2] : 0;
                aggregated[k][k2] = calc(ob1[k][k2], ob2[k][k2], 4).add();
            });
        });
        return aggregated;
    }
}
export function absouluteVal(num) {
    return Math.abs(num);
}
// const inputObj = {
//     data1: {
//         key1: {
//             innerKey1: 10,
//             innerKey2: 20
//         },
//         key2: {
//             innerKey1: 5,
//             innerKey2: 15
//         }
//     },
//     data2: {
//         key1: {
//             innerKey1: 8,
//             innerKey2: 12
//         },
//         key2: {
//             innerKey1: 3,
//             innerKey2: 7
//         }
//     }
// };

// input->inputObj,  keys: [['key1', 'key2'],['innerKey1', 'innerKey2']]
// Output:
// {
//     key1: {
//         innerKey1: 18,
//         innerKey2: 32
//     },
//     key2: {
//         innerKey1: 8,
//         innerKey2: 22
//     }
// }
export function aggregateInner(obj, keys=[]) {
   
    let aggregated = {};
    keys[0].forEach(item => {
        aggregated[item] = {};
        keys[1].forEach(innerItem => {
            aggregated[item][innerItem] = 0;
        })
    })
    let measureCounts = {};
    keys[0].forEach(key1=>{
        keys[1].forEach(item=>{
            measureCounts[key1+"_"+item+"_count"] = 0;
        })
    })
    
    Object.keys(obj).map(obKey => {
        let dataItem = obj[obKey];

        keys[0].forEach(keyitem => {
            if (dataItem[keyitem]) {
                
                keys[1].forEach(innerItem => {
                    aggregated[keyitem][innerItem] += dataItem[keyitem][innerItem];
                    // if(dataItem[keyitem][innerItem]!==0){
                    //     measureCounts[keyitem+"_"+innerItem+"_count"]+=1;
                    // }
                    // aggregated[keyitem][innerItem+"_count"]= measureCounts[keyitem+"_"+innerItem+"_count"];
                })
               
            
            }

        })
    })
    
    return aggregated;
}


export function toInteger(val) {
    return _.toInteger(val)
}
export function isString(val) {
    return _.isString(val);
}
export function isNumber(val) {
    return _.isNumber(val);
}
export function dataTransformer(data, params) {
    // In this function we are generalising the data and 
    // transform it into more specific and meaningful format
    let transformedData = [];
    let metadata = {};
    let { keyMap, mainkey, segmented_view } = params;
    if (!data || !data.length) return [];
    let dataSample = {
        "Rooms": 0,
        "Occ": 0,
        "ADR": 0,
        "RevPAR": 0,
        "Revenue": 0,
        "SellRate": 0
    };
    let meta = {
        asof_date: "",
        capacity: 0
    };
    let dataWrapHotel = {
        current: deepCopy(dataSample),
        primary: deepCopy(dataSample),
        pickup: deepCopy(dataSample),
        prior: deepCopy(dataSample),
        sdly: deepCopy(dataSample),
        sdly_actual: deepCopy(dataSample),
        sdly_picup: deepCopy(dataSample),
    };
    let dataWrapHotelForecast = {
        primary: deepCopy(dataSample),
    };
    let dataWrapBookingCurve = {
        primary: deepCopy(dataSample),
    }
    let dataWrapMarket = {
        primary: deepCopy(dataSample),
        primary_noncomm: deepCopy(dataSample),
        pickup: deepCopy(dataSample),
        pickup_noncomm: deepCopy(dataSample),
        sdly: deepCopy(dataSample),
        sdly_noncomm: deepCopy(dataSample),
        sdly_actual: deepCopy(dataSample),
        sdly_actual_noncomm: deepCopy(dataSample),
    };

    let outputSample = {
        HotelTotal: deepCopy(dataWrapHotel),

        HotelForecastTotal: deepCopy(dataWrapHotelForecast),
        HotelForecastUnconTotal: deepCopy(dataWrapHotelForecast),

        MarketTotal: deepCopy(dataWrapMarket),
        MarketForecastTotal: deepCopy(dataWrapMarket),
        MarketForecastConTotal: deepCopy(dataWrapMarket),
        MarketForecastUnconTotal: deepCopy(dataWrapMarket),

        bookingCurveTotal: deepCopy(dataWrapBookingCurve),
        bookingCurveSdlyTotal: deepCopy(dataWrapBookingCurve),
        bookingCurveComparisonTotal: deepCopy(dataWrapBookingCurve),

        segmented: {
            hotel: {},
            hotelForecast: {},
            hotelForecastUncon: {},

            market: {},
            marketForecast: {},
            marketForecastUncon: {},

            bookingCurve: {},
            bookingCurveSdly: {},
            bookingCurveCompareTo: {},
        },
        index: { date: "", dba: "" }
    };
    if (!keyMap) {
        keyMap = {
            // "asof_date": "meta.asof_date",
            // "capacity": "meta.capacity",
            // "dba": "index.dba",
            // "stay_date": "index.date",
            // "hotel_segment": "Segment_NAME",
            // "market_code": "Segment_CODE",

            hotelForecast: {
                "asof_date": "meta.asof_date",
                "capacity": "meta.capacity",
                "dba": "index.dba",
                "stay_date": "index.date",
                "hotel_segment": "Segment_NAME",
                "market_code": "Segment_CODE",

                "predicted_cons_adr": "segmented.hotelForecast.CODE.ADR",
                "predicted_cons_occ": "segmented.hotelForecast.CODE.Occ",
                "predicted_cons_revenue": "segmented.hotelForecast.CODE.Revenue",
                "predicted_cons_revpar": "segmented.hotelForecast.CODE.RevPAR",
                "predicted_cons_rooms": "segmented.hotelForecast.CODE.Rooms",

                "predicted_total_cons_adr": "HotelForecastTotal.ADR",
                "predicted_total_cons_occ": "HotelForecastTotal.Occ",
                "predicted_total_cons_revenue": "HotelForecastTotal.Revenue",
                "predicted_total_cons_revpar": "HotelForecastTotal.RevPAR",
                "predicted_total_cons_rooms": "HotelForecastTotal.Rooms",

                "predicted_total_uncons_adr": "HotelForecastUnconTotal.ADR",
                "predicted_total_uncons_occ": "HotelForecastUnconTotal.Occ",
                "predicted_total_uncons_revenue": "HotelForecastUnconTotal.Revenue",
                "predicted_total_uncons_revpar": "HotelForecastUnconTotal.RevPAR",
                "predicted_total_uncons_rooms": "HotelForecastUnconTotal.Rooms",
            },
            marketForecast: {
                //For market Forecast
                "constrained_forecast_adr": "MarketForecastConTotal.ADR",
                "constrained_forecast_occ": "MarketForecastConTotal.Occ",
                "constrained_forecast_revenue": "MarketForecastConTotal.Revenue",
                "constrained_forecast_revpar": "MarketForecastConTotal.RevPAR",
                "constrained_forecast_rooms": "MarketForecastConTotal.Rooms",

                "forecast_adr": "MarketForecastTotal.ADR",
                "forecast_occ": "MarketForecastTotal.Occ",
                "forecast_revenue": "MarketForecastTotal.Revenue",
                "forecast_revpar": "MarketForecastTotal.RevPAR",
                "forecast_rooms": "MarketForecastTotal.Rooms",

                "unconstrained_forecast_adr": "MarketForecastUnconTotal.ADR",
                "unconstrained_forecast_occ": "MarketForecastUnconTotal.Occ",
                "unconstrained_forecast_revenue": "MarketForecastUnconTotal.Revenue",
                "unconstrained_forecast_revpar": "MarketForecastUnconTotal.RevPAR",
                "unconstrained_forecast_rooms": "MarketForecastUnconTotal.Rooms",
            },
            marketForecastSegmented: {
                //For segmented market Forecast
                "market_segment": "segmented.marketForecast.CODE",
                "constrained_forecast_adr": "segmented.marketForecast.CODE.ADR",
                "constrained_forecast_occ": "segmented.marketForecast.CODE.Occ",
                "constrained_forecast_revenue": "segmented.marketForecast.CODE.Revenue",
                "constrained_forecast_revpar": "segmented.marketForecast.CODE.RevPAR",
                "constrained_forecast_rooms": "segmented.marketForecast.CODE.Rooms",

                "unconstrained_forecast_adr": "segmented.marketForecastUncon.CODE.ADR",
                "unconstrained_forecast_occ": "segmented.marketForecastUncon.CODE.Occ",
                "unconstrained_forecast_revenue": "segmented.marketForecastUncon.CODE.Revenue",
                "unconstrained_forecast_revpar": "segmented.marketForecastUncon.CODE.RevPAR",
                "unconstrained_forecast_rooms": "segmented.marketForecastUncon.CODE.Rooms",
            },


        };
    }
    let keyMapSelector = "hotelForecast";
    data.map((item, i) => {
        let output = deepCopy(outputSample);
        let itemData = item;
        if (mainkey && item[mainkey]) {
            itemData = item[mainkey];
        }
        for (let key in itemData) {
            if (keyMap[keyMapSelector][key]) {
                let keys = keyMap[keyMapSelector][key].split(".");
                if (keys.length > 1) {
                    if (keys[0] === 'meta') {
                        metadata[keys[1]] = itemData[key];
                    } else if (keys[0] === 'index') {
                        output.index[keys[1]] = itemData[key][keys[1]];
                    } else if (keys[0] === 'segmented') {
                        output.segmented[keyMapSelector][key] = itemData[key][keys[1]];
                    } else {

                    }

                } else {

                }
            }
        }

    });
    //if(!inputSample || !outputSample) return data;

}

export function combineArr(arr1, arr2, duplicates = false) {
    let combinedArr = [];
    if (duplicates === false) {
        combinedArr = [...new Set([...arr1, ...arr2])]
    } else {
        combinedArr = arr1.concat(arr2);
    }

    return combinedArr;
}

export function getNumDaysFromDateRange({ date_from, date_until }) {
    return moment(date_until).diff(date_from, 'days') + 1;
}
export function safeDivide(num1 , num2){
    if(num1==0 || num2==0 ){
        return 0;
    } 
    return num1/num2;
}
export function safeReference(ref, defaultValIfEmpty, path) {
    var currentRef = ref;
    if (!currentRef) {
        return defaultValIfEmpty;
    }
    if (!path || path.length < 1) {
        return currentRef;
    }
    for (var i = 0; i < path.length; i++) {
        currentRef = currentRef[path[i]];
        if (!currentRef) {
            return defaultValIfEmpty;
        }
    }
    return currentRef;
}

// get Compset Name 
export const getCompsetName = (ord = 4, app_state) => {

    let compsetData = (app_state.workspace_controls.scopeType !== 'Dba') ? dataService.getSeriesById("Compset").data : dataService.getSeriesById("CompsetBookingPace").data;
    let data = (compsetData) ? compsetData.data : [];
    const subColumns = [];
    const firstData = data[0] ? data[0] : {};
    let order = ord
    Object.keys(firstData).map((key) => {
        // console.log("here is => ", key);
        if (key !== 'Avg' && key !== 'Total' && key !== 'index' && key !== 'Index' && key !== 'Median' && key !== 'Min' && key !== 'Max') {
            let key_ = key;
            let name = key.split("|");
            subColumns.push({
                display: name[0].replaceAll("compset_", ""),
                subcol_id: "compset_medium_" + key,
                type: "currency",
                value: key,
                "parent": "compset_medium",
                "className": "col-sub",
                "draggable": true,
                "sortable": true,
                "filterable": true,
                "filterMenu": true,
                "sorted": null,
                "hidden": false,
                "order": order
            })
            order++;
        }

    })
    return subColumns;
}
// end 
