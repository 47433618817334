import React, { useEffect, useReducer, useRef, useState } from "react";
import {
  AppBar,
  Box,
  Toolbar,
  IconButton,
  Typography,
  Menu,
  MenuItem,
  Container,
  Avatar,
  Button,
  Grid,
  Tooltip,
  FormControl,
  InputLabel,
  Select,
  ListSubheader,
  TextField,
  InputAdornment,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Divider,
  Drawer,
} from "@mui/material/";
import {
  Person as PersonIcon,
  SettingsOutlined as SettingsOutlinedIcon,
  ScheduleOutlined as ScheduleOutlinedIcon,
  VideocamOutlined as VideocamOutlinedIcon,
  PowerSettingsNewOutlined as PowerSettingsNewOutlinedIcon,
  AssessmentOutlined as AssessmentOutlinedIcon,
  ArrowForwardRounded as ArrowForwardRoundedIcon,
  ApartmentSharp as ApartmentSharpIcon,
  Search as SearchIcon,
  KeyboardDoubleArrowRight as KeyboardDoubleArrowRightIcon,
  Splitscreen,
  Height,
} from "@mui/icons-material/";

import { styled } from "@mui/material/styles";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import FolderIcon from "@mui/icons-material/Folder";
import CircularProgress from "@mui/material/CircularProgress";
import DeleteIcon from "@mui/icons-material/Delete";
import moment from "moment";
import { useSelector } from "react-redux";
import Alert from "@mui/material/Alert";
import FilePresentIcon from "@mui/icons-material/FilePresent";
import Breadcrumbs from '@mui/material/Breadcrumbs';
import Link from '@mui/material/Link';
import CloseIcon from '@mui/icons-material/Close';

import CloudDownloadIcon from '@mui/icons-material/CloudDownload';
import { API_BASE_URL } from "../../app/config";
import CreateNewFolderIcon from '@mui/icons-material/CreateNewFolder';
import TextSnippetIcon from '@mui/icons-material/TextSnippet';
import FileFolderList from "./FileFolderList";
import { replaceMultiText } from "../../app/util";
import { fetchDataSimple } from "../../services/data.service";
import { LightTooltip } from "../layout/dashboard-ui";


const DocumentList = (props) => {
  const { app, auth } = useSelector((state) => state);
  const { app_state } = app.current_state;
  const [folderShow, setFolderShow] = useState(false);
  const [value, setValue] = useState("");

  let label;
  if (props.reportType === "budget_upload") {
    label = "Budget";
  } else if (props.reportType === "forecast_upload") {
    label = "User Forcast";
  } else if (props.reportType === "documents_upload") {
    label = "Document";
  } else {
    label = "";
  }
  const [templateUrls, setTemplateUrls] = useState({
    "forcast":"http://rm-app-staging.lodgiq.com/app/v3/docs/2022_LodgIQ%20Forecast%20Upload%20Template.xls",
    "budget":"http://rm-app-staging.lodgiq.com/app/v3/docs/2022_LodgIQ%20Budget%20Upload%20Template.xls"
  })
  useEffect(() => {
    let request ={method:"GET"}
    fetchDataSimple({api:"upload/templates/",request }).then(res=>{
      console.log(res)
      if(res.status === "success"){
        setTemplateUrls(res.templates);
      }
    }).catch(err=>{
      console.log(err);
    })
    return () => {
      
    }
  }, [])


  const downloadFile = () => {
    // console.log("test="+uploadReport);
    let downloadFileName = "forecast_upload";
    if (props.reportType === "forecast_upload") {
      //downloadFileName = "http://rm-app-staging.lodgiq.com/app/v3/docs/2022_LodgIQ%20Forecast%20Upload%20Template.xls";
      downloadFileName =templateUrls["forcast"];
    } else {
      //downloadFileName = "http://rm-app-staging.lodgiq.com/app/v3/docs/2022_LodgIQ%20Budget%20Upload%20Template.xls";
      downloadFileName =templateUrls["budget"];
    }
    window.location.href = downloadFileName;
  }

  const handleToggle = (val) => {
    setFolderShow(val)
  }

  const emptyValue = (val) => {
    setValue('');
    setFolderShow(false);
  }


  return (
    <>
      <Box sx={{ flexGrow: 1, mt: 1, position: "relative" }}>
        <Grid container className="gContainer" spacing={2} sx={{ background: "#333" }}>
          <Grid xs={12} sx={{}}>
            <Breadcrumbs aria-label="breadcrumb" sx={{ color: "#fff", m: 1.5, width: "600px" , cursor:"pointer"}}>
              <Link underline="hover" color="inherit" onClick={() => {
                      props.handleBreadcrumb("0");
                    }} separator="/" aria-label="breadcrumb">
                {label}
              </Link>

              {
                props.ui.breadcrumb.map((item, index) => {
                  let breadPath = item.split(">");
                  return (
                    <> <Link onClick={() => {
                      props.handleBreadcrumb(item);
                     
                    }} underline="hover" color="inherit" separator="›" aria-label="breadcrumb">
                      {breadPath[3]}
                    </Link>
                    </>
                  )
                })
              }
            </Breadcrumbs>
            {props.reportType !== "documents_upload" ? (
              <Tooltip title="Download the template,Fill it and upload the competed file" arrow>
                <Button variant="outlined" sx={{
                  width: "auto", float: "right", position: "absolute", border: "1px solid #eee",
                  right: 14, top: 8, textAlign: "center", fontSize: 11, pl: 1.5, pr: 1.5, pt: .5, pb: .5, color: "#80cf46"
                }} size='large' component="label" onClick={downloadFile} startIcon={<CloudDownloadIcon />}>
                  {label} Template
                </Button>
              </Tooltip>
            ) : (
              <>

                <Typography sx={{ position: "absolute", pr: 1, float: "right", top: 12, right: 2 }}>
                  {folderShow === true ? (
                    <>
                      <Paper sx={{ minWidth: "5px !important;", position: "absolute", right: "38px", width: "360px", top: "-9px" }}>
                        <TextField sx={{ width: 296, borderTopRightRadius: 0, borderBottomRightRadius: 0 }}
                          value={value} onChange={(e) => { setValue(e.target.value) }} size="small" />
                        <Button onClick={() => { props.createFolder(value); emptyValue(); }} variant="contained" sx={{ p: 1, ml: 0, right: 0, borderTopLeftRadius: 0, borderBottomLeftRadius: 0, background: "#9ed00d" }} size="midium">Ok</Button>
                      </Paper>
                      <CloseIcon onClick={(e) => { handleToggle(false) }} sx={{ float: "right" }} />

                    </>
                  ) : (
                    <LightTooltip title="Add New Folder" arrow followCursor>

                      <CreateNewFolderIcon variant="outlined" onClick={(e) => { handleToggle(true) }} sx={{ float: "right", color: "#9ed00d" }} />
                    </LightTooltip>
                  )
                  }
                </Typography>
              </>
            )}
          </Grid>

          {/* Table Container */}
          <TableContainer component={Paper} className="table-container" sx={{ height: 200 }}>
             <FileFolderList params={{fileType:props.reportType,data:props.data}} deleteItem={props.deleteItem} deleteDocument={props.deleteDocument} handleFolderChange={props.handleFolderChange} />
          </TableContainer>

          {/* Table Container */}

        </Grid>
        <Grid container spacing={2} sx={{pt: 2, overflow: "auto", height: 200,display:"none" }}>
        {props.reportType === "documents_upload" ? (
             props.data.folders.length > 0 ? (
          props.data.folders.map((item, index) => {
            // const test = ["index>folders>uid1","index>folders>uid2","index>folders>uid3"]
            let path = index + ">folders>" + item.folder_uid + ">" + item.folder_name;

            return (
              <>
                <Grid xs={2}>
                  <Button
                    onClick={() => {
                      props.handleFolderChange(path);
                    }}
                    startIcon={<FolderIcon color="success" />}
                    sx={{ color: "#000", textTransform: "none" }}
                  >
                    {item.folder_name}
                  </Button>
                </Grid>
                <Grid xs={2} sx={{ textAlign: "center" }}>
                  <Grid item xs={12} sx={{ mt: 1, ml: 1 }}></Grid>
                </Grid>
                <Grid xs={2} sx={{ textAlign: "center" }}>
                  <Grid item xs={12} sx={{ mt: 1, ml: 4 }}>
                    {moment(item.insert_timestamp).format("MM/DD/YYYY")}
                    <DeleteIcon
                      onClick={() => props.deleteItem(index)}
                      color="error"
                      sx={{ float: "right", mr: 1, cursor: "pointer" }}
                    />
                  </Grid>
                </Grid>
              </>
            );
          })
             ):(
              <> <Grid xs={6} sx={{ boxShadow: 4}}>
              <Grid item xs={12} sx={{ margin: 1, textAlign:"center",pt:6,color: "#bbb" }}>
                No Record Found 
                </Grid></Grid>
              </>
             )

        ) : (
          <> 
          </>
        )}
          {
            props.data.files.length > 0 ?(
              props.data.files.map((item, index) => {
              let rType = "";
              let noRecord = 0;
              if (props.reportType === "forecast_upload") {
                rType = "manual_forecast";
              
              } else if (props.reportType === "budget_upload") {
                rType = "budget";
      
              } else if (props.reportType === "document") {
                rType = "document";
              } 
              // console.log(label, "here = ", rType + " " + item.file_type);
              if (rType === item.file_type ) {
               

                return (
                  <>

                    <Grid xs={2} sx={{}}>
                      <Button
                        startIcon={<FilePresentIcon color="success" />}
                        sx={{ color: "#fff", textTransform: "none" }}
                      >
                         {item.file_name.substring(0, 25)}
                      </Button>
                    </Grid>
                    <Grid xs={2} sx={{ textAlign: "center" }}>
                      <Grid item xs={12} sx={{ mt: 1, ml: 1 }}>
                        {item.file_type}
                      </Grid>
                    </Grid>
                    <Grid xs={2} sx={{ textAlign: "center" }}>
                      <Grid item xs={12} sx={{ mt: 1, ml: 3 }}>
                        {" "}
                        {moment(item.insert_timestamp.$date).format("MM/DD/YYYY")}
                        <DeleteIcon
                          index={index}
                          onClick={() => props.deleteDocument(index)}
                          color="error"
                          sx={{ float: "right", mr: 2, cursor: "pointer" }}
                        />
                      </Grid>
                    </Grid>

                  </>
                );
              }else {
                <></>
              }

            })
            ):(
              <> <Grid xs={6} sx={{ boxShadow: 4}}>
          <Grid item xs={12} sx={{ margin: 1, textAlign:"center",pt:6 }}>
            No Record Found 
            </Grid></Grid>
          </>
            )
          }
        </Grid>

      </Box>
    </>
  );
};

export default DocumentList;
