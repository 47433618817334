import moment from "moment";
import { convertToPercent, deepCopy, getAppData, getDatesInRange, getFromState, getObjectFilter, getTransformedSegments, round, setUpDate } from "../../../app/util";
import { useDataSummary } from "../../Tile/kpiService";
import dataService from "../../../services/data.service";
import { useSelector } from "react-redux";
import { transformSegmentData } from "../segmentedData";
import { getSegmentedCode } from "../processDataReports";
import { getSegmentKeyTotal, getTopSegments } from "./pace_report_functions";

export function getReportData_otbMultiSegment(data, columns, auth, app_state) {
    // let data = (app_state.workspace_controls.segmented_view) ? [] : deepCopy(dataService.getSeriesById("OSRSegment").data.data);
    let setup_data = [];
    if (data) {
        let dataKey = "HotelTotal";
        data.map((item, index) => {
            let data_columns = [];
            columns.map(col => {
                let dataObj = {};
                if (col.type === 'dateColumn') {
                    dataObj = setUpDate(dataObj, item.index.date);
                    if (app_state.workspace_controls.segmented_view) {
                        dataObj["segment"] = item.index.segment;
                    }
                }
                if (col.type === 'dataColumn') {
                    let { mainKey } = col;
                    col.subcolumns.map(key => {
                        if (mainKey === "difference_sdly") {
                            if (key.value === "Occ") {
                                dataObj[key.value] = convertToPercent(item[dataKey]["primary"][key.value] - item[dataKey]["sdly"][key.value])
                            } else {
                                dataObj[key.value] = round(item[dataKey]["primary"][key.value] - item[dataKey]["sdly"][key.value])
                            }
                        }
                        else if (mainKey === "variance_sdly") {
                            let varianceValue = (item[dataKey]["primary"][key.value] - item[dataKey]["sdly"][key.value]) / item[dataKey]["sdly"][key.value];
                            dataObj[key.value] = (varianceValue && isFinite(varianceValue)) ? convertToPercent(varianceValue) + "%" : 0 + "%";
                        }
                        else if (mainKey === "pickup_diff_sdly") {
                            dataObj[key.value] = round(item[dataKey]["sdly_actual"][key.value] - item[dataKey]["sdly"][key.value])
                        } else if (mainKey === "restrictions" && getAppData(auth, app_state).restrictions.enable) {
                            let resData = dataService.getSeriesById("GetRestrictions").data;
                            resData = resData && resData.cur ? resData.cur : [];
                            let filteredData = getObjectFilter(deepCopy(resData), item.index.date, "stay_date")
                            let filterRestData = [];
                            if (filteredData[0]) {
                                Object.keys(filteredData[0]).forEach(function (key) {
                                    if (filteredData[0][key] === false || filteredData[0][key] === 1) {
                                        delete filteredData[0][key];
                                    }
                                });
                                filterRestData.push(filteredData[0]);
                            }

                            dataObj[key.value] = filterRestData;
                        } else if (item[dataKey][mainKey]) {
                            if (key.value == "Occ") {
                                dataObj[key.value] = convertToPercent(item[dataKey][mainKey][key.value]);
                            } else {
                                dataObj[key.value] = round(item[dataKey][mainKey][key.value]);
                            }
                        }
                    })
                }
                dataObj.id = col.id;
                data_columns.push(dataObj);
            })

            data_columns.map((itmArr, idx) => {
                if (itmArr['date'] == "" || itmArr['segment'] === "Total") {
                    data_columns[idx]["ignore_sort"] = true;
                }
            })
            setup_data.push(data_columns);
        })
    }
    return setup_data;
}



export function getOtbMultiSegmentReportSegmentedData_hotel(columns, auth, app_state, ui_state) {
    let data = deepCopy(dataService.getSeriesById("OSRSegment").data.data);
    // let marketData = deepCopy(dataService.getSeriesById("MarketARISegmented").data.data);
    let setup_data = [];
    let { date_from, date_until } = app_state.primary_stay_range;

    let dates = getDatesInRange(date_from, date_until);
    let categoryList = ["Transient", "Group", "Contract", "Total"];

    /////// final Total /////

    let blankObj = { Rooms: 0, Occ: 0, ADR: 0, RevPAR: 0, SellRate: 0, Revenue: 0 };
    let primaryTotal = { Rooms: 0, Occ: 0, ADR: 0, RevPAR: 0, SellRate: 0, Revenue: 0 };
    let sdlyTotal = { Rooms: 0, Occ: 0, ADR: 0, RevPAR: 0, SellRate: 0, Revenue: 0 };
    let sdly_actualTotal = { Rooms: 0, Occ: 0, ADR: 0, RevPAR: 0, SellRate: 0, Revenue: 0 };

    let allTotal = {
        "Transient": {
            "primary": blankObj,
            "sdly": blankObj,
            "sdly_actual": blankObj
        },
        "Group": {
            "primary": blankObj,
            "sdly": blankObj,
            "sdly_actual": blankObj
        },
        "Contract": {
            "primary": blankObj,
            "sdly": blankObj,
            "sdly_actual": blankObj
        },

    }

    ///// end final total //// 
    if (dates && dates.length > 0) {

        dates.map((date, dateIndex) => {

            categoryList.map((category, categoryIndex) => {
                let data_columns = [];
                let segmentCodes = getTopSegments(auth, app_state, category);
                columns.map(col => {
                    let dataObj = {};
                    dataObj.id = col.id;
                    if (col.type === 'dateColumn') {
                        dataObj = setUpDate(dataObj, date);
                        if (app_state.workspace_controls.segmented_view) {
                            dataObj["segment"] = category;
                            // dataObj["segment"] = (categoryIndex == (categoryList.length - 1)) ? "Total" : category ;
                        }
                    }
                    if (col.type === 'dataColumn') {
                        let { mainKey } = col;
                        col.subcolumns.map(key => {
                            if (mainKey === "difference_sdly") {
                                let keyPrimaryTotalValue = getSegmentKeyTotal(data[dateIndex], key.value, segmentCodes, "primary", "osr", category);
                                let keySdlyTotalValue = getSegmentKeyTotal(data[dateIndex], key.value, segmentCodes, "sdly", "osr", category);

                                if (key.value === "Occ") {
                                    dataObj[key.value] = convertToPercent(keyPrimaryTotalValue - keySdlyTotalValue)
                                } else {
                                    dataObj[key.value] = round(keyPrimaryTotalValue - keySdlyTotalValue)
                                }
                            } else if (mainKey === "variance_sdly") {

                                let keyPrimaryTotalValue = getSegmentKeyTotal(data[dateIndex], key.value, segmentCodes, "primary", "osr", category);
                                let keySdlyTotalValue = getSegmentKeyTotal(data[dateIndex], key.value, segmentCodes, "sdly", "osr", category);
                                let varianceValue = (keyPrimaryTotalValue - keySdlyTotalValue) / keySdlyTotalValue;
                                dataObj[key.value] = (varianceValue && isFinite(varianceValue)) ? convertToPercent(varianceValue) + "%" : 0 + "%";


                            } else if (mainKey === "pickup_diff_sdly") {
                                let keySdlyActualTotalValue = getSegmentKeyTotal(data[dateIndex], key.value, segmentCodes, "sdly_actual", "osr", category);
                                let keySdlyTotalValue = getSegmentKeyTotal(data[dateIndex], key.value, segmentCodes, "sdly", "osr", category);
                                dataObj[key.value] = round(keySdlyActualTotalValue - keySdlyTotalValue)
                            }
                            else {
                                let keyTotalValue = getSegmentKeyTotal(data[dateIndex], key.value, segmentCodes, mainKey, "osr", category);
                                if (key.value == "Occ") {
                                    dataObj[key.value] = convertToPercent(keyTotalValue);

                                } else {
                                    dataObj[key.value] = round(keyTotalValue);
                                    if (category !== "Total" && mainKey === "primary") {
                                        let fieldArr = Object.keys(primaryTotal);
                                        fieldArr.map((k) => {
                                            allTotal[category][mainKey][k] += round(keyTotalValue);
                                        })

                                    }
                                    // debugger; will start from here
                                }
                            }
                        })
                    }
                    data_columns.push(dataObj);
                })
                setup_data.push(data_columns);
            })

        })
    }
    return setup_data;
}



const getRoomClassCategory = (data) => {
    let classCategories = [];
    for (var k in data[0]) {
        if (k !== "index" && k !== "HotelTotal")
            classCategories.push(k);
    }
    return classCategories;
}

const getCodeOfClass = (category, data) => {
    let roomCode = [];
    for (var k in data[0][category]) {
        roomCode.push(k.replace("Segment_", ""));
    }
    return roomCode;
}

export function getOtbMultiSegmentReportSegmentedData_roomClass(columns, auth, app_state, ui_state) {
    let data = (dataService.getSeriesById("GetRoomClass").data) ? deepCopy(dataService.getSeriesById("GetRoomClass").data.data) : [];
    let setup_data = [];
    let { date_from, date_until } = app_state.primary_stay_range;
    let dates = getDatesInRange(date_from, date_until);

    if (data && data.length > 0) {
        let categoryList = [...getRoomClassCategory(data), "Total"];
        /////// final Total /////
        let blankObj = { Rooms: 0, Occ: 0, ADR: 0, RevPAR: 0, SellRate: 0, Revenue: 0 };
        let primaryTotal = { Rooms: 0, Occ: 0, ADR: 0, RevPAR: 0, SellRate: 0, Revenue: 0 };
        let sdlyTotal = { Rooms: 0, Occ: 0, ADR: 0, RevPAR: 0, SellRate: 0, Revenue: 0 };
        let sdly_actualTotal = { Rooms: 0, Occ: 0, ADR: 0, RevPAR: 0, SellRate: 0, Revenue: 0 };

        let allTotal = {
            "roomclass_Deluxe": {
                "primary": blankObj,
                "sdly": blankObj,
            }
        }
        categoryList.map((itm) => {
            let tmpObj = {
                [itm]: {
                    "primary": blankObj,
                    "sdly": blankObj,
                    "sdly_actual": blankObj
                }
            }
            // Object.assign(allTotal, tmpObj);
            // allTotal.push(tmpObj);
        })
        allTotal["roomclass_Deluxe"]['primary']['Rooms'] = 333;
        ///// end final total //// 

        if (dates && dates.length > 0) {
            dates.map((date, dateIndex) => {
                categoryList.map((category, categoryIndex) => {
                    let data_columns = [];
                    let segmentCodes = getCodeOfClass(category, data);
                    // debugger; 
                    columns.map(col => {
                        let dataObj = {};
                        dataObj.id = col.id;
                        if (col.type === 'dateColumn') {
                            dataObj = setUpDate(dataObj, date);
                            if (app_state.workspace_controls.segmented_view) {
                                dataObj["segment"] = category.replace("roomclass_", "");
                                // dataObj["segment"] = (categoryIndex == (categoryList.length - 1)) ? "Total" : category ;
                            }
                        }
                        if (col.type === 'dataColumn') {
                            let { mainKey } = col;
                            col.subcolumns.map(key => {
                                if (mainKey === "difference_sdly") {
                                    let keyPrimaryTotalValue = getSegmentKeyTotal_roomClass(data[dateIndex], key.value, segmentCodes, "primary", "osr", category);
                                    let keySdlyTotalValue = getSegmentKeyTotal_roomClass(data[dateIndex], key.value, segmentCodes, "sdly", "osr", category);

                                    if (key.value === "Occ") {
                                        dataObj[key.value] = convertToPercent(keyPrimaryTotalValue - keySdlyTotalValue)
                                    } else {
                                        dataObj[key.value] = round(keyPrimaryTotalValue - keySdlyTotalValue)
                                    }
                                } else if (mainKey === "variance_sdly") {

                                    let keyPrimaryTotalValue = getSegmentKeyTotal_roomClass(data[dateIndex], key.value, segmentCodes, "primary", "osr", category);
                                    let keySdlyTotalValue = getSegmentKeyTotal_roomClass(data[dateIndex], key.value, segmentCodes, "sdly", "osr", category);
                                    let varianceValue = (keyPrimaryTotalValue - keySdlyTotalValue) / keySdlyTotalValue;
                                    dataObj[key.value] = (varianceValue && isFinite(varianceValue)) ? convertToPercent(varianceValue) + "%" : 0 + "%";


                                } else if (mainKey === "pickup_diff_sdly") {
                                    let keySdlyActualTotalValue = getSegmentKeyTotal_roomClass(data[dateIndex], key.value, segmentCodes, "sdly_actual", "osr", category);
                                    let keySdlyTotalValue = getSegmentKeyTotal_roomClass(data[dateIndex], key.value, segmentCodes, "sdly", "osr", category);
                                    dataObj[key.value] = round(keySdlyActualTotalValue - keySdlyTotalValue)
                                }
                                else {

                                    // debugger
                                    let keyTotalValue = getSegmentKeyTotal_roomClass(data[dateIndex], key.value, segmentCodes, mainKey, "osr", category);

                                    // if (key.value === "Rooms") {

                                    // }


                                    if (key.value == "Occ") {
                                        dataObj[key.value] = convertToPercent(keyTotalValue);
                                    } else {
                                        dataObj[key.value] = round(keyTotalValue);
                                        if (category !== "Total" && mainKey === "primary") {
                                            let fieldArr = Object.keys(primaryTotal);
                                            fieldArr.map((k) => {
                                            })

                                        }
                                        // debugger; will start from here
                                    }
                                }
                            })
                        }
                        data_columns.push(dataObj);
                    })
                    setup_data.push(data_columns);
                })

            })
        }
    }
    return setup_data;
}

export const getSegmentKeyTotal_roomClass = (item, key, segmentCodes, mainKey, segmentType = "osr", segmentTotalRow = "") => {
    let keyTotal = 0;
    let revenueTotal = 0;
    let roomsTotal = 0

    if (segmentTotalRow && segmentTotalRow === "Total") {
        if (key !== "SellRate") {
            keyTotal = item["HotelTotal"][mainKey][key];
        } else {
            keyTotal = 0;
        }
        return keyTotal;
    } else {

        if (segmentCodes && segmentCodes.length > 0) {
            segmentCodes.map((code, index) => {
                let segmentCode = (segmentType === "market") ? "MarketSegment_" + code : "Segment_" + code;
                if (key === "ADR") {
                    revenueTotal += (item[segmentTotalRow][segmentCode][mainKey]["Revenue"]) ? item[segmentTotalRow][segmentCode][mainKey]["Revenue"] : 0;
                    roomsTotal += (item[segmentTotalRow][segmentCode][mainKey]["Rooms"]) ? item[segmentTotalRow][segmentCode][mainKey]["Rooms"] : 0;
                } else {
                    keyTotal += (item[segmentTotalRow][segmentCode][mainKey][key]) ? item[segmentTotalRow][segmentCode][mainKey][key] : 0;
                }
            })
            if (key === "ADR") {
                keyTotal = revenueTotal / roomsTotal;
            }
            return keyTotal;
        }

    }

}