import React, { useState, useEffect } from 'react';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import {
    Grid, Button, Badge, Modal
   
} from '@mui/material';
function Prompt({isOpen, options,handleClose}) {
    //const [open, setOpen] = useState(isOpen)
    
    return (
        <Modal
        className="popup-modal-wrap"
        open={isOpen}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box className='popup-modal modal-prompt'>
        <Box className='modal-titlebar'>
        <Grid container spacing={0}>
          <Typography id="modal-modal-title" variant="h6" component="h2">
            {options.title}
          </Typography>
          </Grid>
          </Box>
          <Box >
            <Typography id="modal-modal-description" sx={{ mt: 2 }}>
                {options.prompt}
            
            </Typography>
            <Button onClick={()=>{
                    handleClose(true)
                }}>Yes</Button>
                <Button onClick={()=>{
                    handleClose(false)
                }}>No</Button>
          </Box>
         
        </Box>
      </Modal>
    )
}

export default Prompt
