import React, { useEffect, useRef, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import {
    Button, CssBaseline, TextField, FormControlLabel, Checkbox, Link, Grid, Box, Paper,
    Typography, Container, IconButton, Alert, AlertTitle, Snackbar, LinearProgress, InputAdornment
}
    from '@mui/material/';
import VisibilityIcon from '@mui/icons-material/Visibility';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';

import { ThemeProvider } from '@mui/material/styles';

//Global custom theme configuration
import theme from '../../theme';
import { loginImgBaseUrl, API_REGISTRY } from "../../app/config"
//UI Elements for login screen
import { Copyright, LoginLogo, LoginProgressbar, LoginAlertBar, LoginForm } from "../../components/layout/login-ui"
import dataService from '../../services/data.service';


const NewPassword = ({ }) => {
    const { UserResetNewPassword } = API_REGISTRY;
    const query = new URLSearchParams(window.location.search);
    const reset_code = query.get('code');
    const [compState, setCompState] = useState({
        isPasswordChanged: false, new_password: '', re_new_password: '',
        reset_token: reset_code,
        isRequesting: false,
        severity: '',
        errorType: '',
        resError: false, resErrorMsg: 'Oops something went wrong, try again!'
    });
    const color = theme.palette;
    const navigate = useNavigate();
    const dispatch = useDispatch();

    const handleSubmit = (event) => {
        event.preventDefault();
        setCompState({ ...compState, isRequesting: true, resError: false });
        // debugger;
        const payload = {
            code: compState.reset_token,
            password: compState.new_password
        };
        dataService.callAPI({ api: UserResetNewPassword.uri, request: payload })
            .then((rs) => {
                // debugger;
                if (rs && rs.status === 'success') {
                    setCompState({ ...compState, isRequesting: false, isPasswordChanged: true });
                } else {
                    setCompState({ ...compState, isRequesting: false, isPasswordChanged: false, resError: true, severity: 'error' });
                }

            })
            .catch((err) => {
                // debugger;
                setCompState({ ...compState, isRequesting: false, resError: true, severity: 'error' });
            });
        // navigate("/login");
        // dispatch(logout());
        // clearTimeout(window.checkInactivityId);
    };
    const handleClose = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }
        // setResError(false);
    };

    const {
        isPasswordChanged,
        isRequesting, reset_token,
        severity, resError, resErrorMsg,
    } = compState;
    
    const [new_password, setNewPassword] = useState("");
    const [re_new_password, setReNewPassword] = useState("");
    const [showNewPassword, setShowNewPassword] = useState(false);
    const [showReNewPassword, setShowReNewPassword] = useState(false);
    const [errorType, setErrorType] = useState("");

    const togglePasswordVisibility = (field) => {
        if (field === "new") {
            setShowNewPassword(!showNewPassword);
        } else if (field === "renew") {
            setShowReNewPassword(!showReNewPassword);
        }
    };

    return (
        <ThemeProvider theme={theme}>
            <Grid container component="main" sx={{ height: '100vh' }}>
                <CssBaseline />

                <Grid className='loginMainContainer' item xs={12} sm={8} md={5} component={Paper} elevation={6} square sx={{ position: 'relative' }}>

                    <Box sx={{ width: '100%', position: 'absolute', zIndex: 999 }}>
                        {isRequesting && <LinearProgress sx={{ position: 'absolute', width: '100%', zIndex: 999 }} color='green' />}
                        {resError &&
                            <LoginAlertBar className='loginAlertBar' resError={resError} resErrorMsg={resErrorMsg} severity='error'>
                                <AlertTitle>{resErrorMsg}</AlertTitle>
                            </LoginAlertBar>
                        }
                    </Box>


                    {/* Logo part */}
                    <Box className='loginHeader'>
                        <LoginLogo />
                    </Box>


                    {/* Body part */}
                    <Box className='loginBody2'>

                        <Box component="form" noValidate onSubmit={handleSubmit}>
                            <Container className='loginBodyMain' component="main" maxWidth="xs">
                                {/* <CssBaseline /> */}

                                <Box className='letsGetStarted'>
                                    {reset_token && !isPasswordChanged &&
                                        <>
                                            <Typography className='resetPassHeading' variant="h5">
                                                Reset Password
                                            </Typography>
                                            <Alert className='resetPassSubHeaging' icon={false} severity='info' style={{ textAlign: 'left' }}>
                                                Enter a new password below which doesn't match your previous password.
                                            </Alert>
                                        </>
                                    }
                                    {reset_token && isPasswordChanged &&
                                        <Box className='passwordChangeSuccess'>
                                            <Alert severity="success">
                                                Password was changed successfully!
                                            </Alert>
                                            <Button className='signInBtn'
                                                type="button"
                                                fullWidth
                                                variant="contained"
                                                onClick={() => {
                                                    navigate("/login");
                                                }}
                                            >
                                                Back to Login
                                            </Button>
                                        </Box>
                                    }
                                </Box>

                                <Box className='inputsContainer'>
                                    {reset_token && !isPasswordChanged &&
                                        <Box noValidate>

                                            <label className="inputLebels">New Password</label>
                                            <TextField
                                                sx={{'& .MuiOutlinedInput-root': {
                                                    paddingRight: 0, 
                                                    backgroundColor: color.grey.Gray4,
                                                    borderRadius: '8px'},
                                                }}
                                                className='inputBox'
                                                placeholder='********'
                                                size='small'
                                                error={errorType === "email"}
                                                helperText={errorType === "email" ? "Invalid email" : ""}
                                                margin="normal"
                                                required
                                                fullWidth
                                                id="newpassword"
                                                type={showNewPassword ? 'text' : 'password'}
                                                name="newpassword"
                                                autoComplete=""
                                                value={new_password}
                                                onChange={(e) => {
                                                    const password = e.target.value;
                                                    setNewPassword(password);
                                                    setErrorType("");
                                                    setCompState({ ...compState, new_password:password });
                                                    // debugger;
                                                }}
                                                autoFocus
                                                InputProps={{
                                                    endAdornment: (
                                                        <InputAdornment position="end">
                                                            <IconButton
                                                                aria-label="toggle new password visibility"
                                                                onClick={() => togglePasswordVisibility("new")}
                                                                onMouseDown={(e) => e.preventDefault()} 
                                                                style={{ backgroundColor: '#ebebeb' }}
                                                            >
                                                                {showNewPassword ? <i class="ph ph-eye"></i> : <i class="ph ph-eye-closed"></i>}
                                                            </IconButton>
                                                        </InputAdornment>
                                                    ),
                                                }}
                                            />

                                            <label className="inputLebels">Re-Enter New Password</label>
                                            <TextField
                                                sx={{'& .MuiOutlinedInput-root': {
                                                    paddingRight: 0, 
                                                    backgroundColor: color.grey.Gray4,
                                                    borderRadius: '8px'},
                                                }}
                                                className='inputBox'
                                                placeholder='********'
                                                size='small'
                                                margin="normal"
                                                error={errorType === "repassword"}
                                                helperText={errorType === "repassword" ? "Password is not matching" : ""}
                                                required
                                                fullWidth
                                                name="renewpassword"
                                                type={showReNewPassword ? 'text' : 'password'}
                                                id="renewpassword"
                                                autoComplete=""
                                                value={re_new_password}
                                                onChange={(e) => {
                                                    const password = e.target.value;
                                                    if (password !== new_password) {
                                                        setReNewPassword(password);
                                                        setErrorType("repassword");
                                                    } else {
                                                        setReNewPassword(password);
                                                        setErrorType("");
                                                    }
                                                    setCompState({ ...compState, re_new_password:password });
                                                }}
                                                disabled={!new_password}
                                                InputProps={{
                                                    endAdornment: (
                                                        <InputAdornment position="end">
                                                            <IconButton
                                                                aria-label="toggle re-enter new password visibility"
                                                                onClick={() => togglePasswordVisibility("renew")}
                                                                onMouseDown={(e) => e.preventDefault()} 
                                                                style={{ backgroundColor: '#ebebeb' }}
                                                            >
                                                                {showReNewPassword ? <i class="ph ph-eye"></i> : <i class="ph ph-eye-closed"></i>}
                                                            </IconButton>
                                                        </InputAdornment>
                                                    ),
                                                }}
                                            />

                                            <Button className='signInBtn'
                                                type="submit"
                                                fullWidth
                                                variant="contained"
                                                disabled={!reset_token || re_new_password === '' || errorType === 'repassword'}
                                            >
                                                Reset
                                            </Button>

                                        </Box>
                                    }
                                </Box>

                            </Container>

                        </Box>
                    </Box>


                    {/* Copyright part */}
                    <Box className='loginFooter' sx={{ width: '100%', position: 'absolute', zIndex: 999 }}>
                        <Copyright />
                    </Box>

                </Grid>



                <Grid
                    item
                    xs={false}
                    sm={4}
                    md={7}
                    sx={{
                        backgroundImage: 'url(assets/img/login-img/seven.jpeg)',
                        backgroundRepeat: 'no-repeat',
                        backgroundColor: (t) =>
                            t.palette.mode === 'light' ? t.palette.grey[50] : t.palette.grey[900],
                        backgroundSize: 'cover',
                        backgroundPosition: 'center',
                    }}
                />

            </Grid>

        </ThemeProvider>
    );
};
export default NewPassword;