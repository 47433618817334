//React
import React, { useState, useEffect, useReducer } from 'react';
import { useNavigate } from "react-router-dom";
import PropTypes from 'prop-types';
//React Redux
import { useDispatch, useSelector } from 'react-redux';

//
import { getData, updateAppState } from "../../actions/appstate";

//MUI
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import { DesktopDatePicker } from '@mui/x-date-pickers/DesktopDatePicker';
import {
    Grid, Button, LinearProgress, Badge, Modal, Tabs, Tab, Stack, TextareaAutosize,
    Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography, Paper, Switch, TextField,
    InputAdornment, IconButton, TableFooter, Popover, Input, Alert
} from '@mui/material';
import {
    CloseSharp as CloseSharpIcon,
    ArrowBackIosNewSharp as ArrowBackIosNewSharpIcon,
    ArrowForwardIosSharp as ArrowForwardIosSharpIcon,
    CheckSharp as CheckSharpIcon,
    GridOn as GridOnIcon
}
    from '@mui/icons-material';
import { CalendarMonthOutlined as CalendarMonthOutlinedIcon } from '@mui/icons-material';
import DownloadIcon from '@mui/icons-material/Download';
import BarChartIcon from '@mui/icons-material/BarChart';
import FileDownloadOutlinedIcon from '@mui/icons-material/FileDownloadOutlined';
import EmailOutlinedIcon from '@mui/icons-material/EmailOutlined';
import SendIcon from '@mui/icons-material/Send';
import './grouppricing.css'
import Spinner from "../Spinner";
import SmallLoader from '../DailyFlash/SmallLoader'


import { border, Box, fontSize, textAlign } from '@mui/system';

//Moments
import moment from 'moment';

//APP's
import theme from '../../theme';
import { styled } from "@mui/material/styles";
import dataService from '../../services/data.service';
import { getTileById, getSeriesById, round } from '../../app/util';
import TableToExcel from "@linways/table-to-excel";
import Loading from '../DailyFlash/Loading';
import { calculateRoomDetails, getCorrectValue, getOtbData } from './group_pricing_functions';
import { parse } from 'date-fns';
import { convertToPercent } from '../DynamicTable/segmentedData';
import { dataFormatMonthlyCard } from '../DailyFlash/functions';

function TabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box sx={{ p: 0 }}>
                    {children}
                </Box>
            )}
        </div>
    );
}
TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.number.isRequired,
    value: PropTypes.number.isRequired,
};

function a11yProps(index) {
    return {
        id: `simple-tab-${index}`,
        'aria-controls': `simple-tabpanel-${index}`,
    };
}
function GroupPricing(props) {
    const Item = styled(Paper)(({ theme }) => ({
        backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
        ...theme.typography.body2,
        padding: theme.spacing(2),
        textAlign: 'center',
        color: theme.palette.text.secondary,
    }));

    const { app_state, ui_state } = useSelector(
        (state) => state.app.current_state
    );
    const { auth, app } = useSelector(state => state);
    const { user, profile } = auth;
    let currSymbol = ui_state.currency_symbol;
    const [sendDetails, setSendDetails] = React.useState(false);
    const [value, setValue] = React.useState(0);
    const [rohTotal, setRohTotal] = React.useState(0);
    const [arrivalDate, setArrivalDate] = React.useState(moment());
    const [departureDate, setDepartureDate] = React.useState(moment().add(1, 'day'));

    const [fbTotal, setFbTotal] = React.useState(0);
    const [facTotal, setFacTotal] = React.useState(0);
    const [avTotal, setAvTotal] = React.useState(0);
    const [otherTotal, setOtherTotal] = React.useState(0);

    const [fbValues, setFbValues] = React.useState([]);
    const [facValues, setFacValues] = React.useState([]);
    const [avValues, setAvValues] = React.useState([]);
    const [otherValues, setOtherValues] = React.useState([]);

    const [finalRowTotal, setFinalRowTotal] = React.useState(0);
    const [finalRowWiseTotal, setFinalRowWiseTotal] = React.useState(0);
    const [groupName, setGroupName] = React.useState(null);

    const [pmfb, setPmFb] = React.useState(30);
    const handlePmFb = (event) => {
        setPmFb(event.currentTarget.value);
    }
    const [pmFacility, setPmFacility] = React.useState(75);
    const handlePmFacility = (event) => {
        setPmFacility(event.currentTarget.value);
    }
    const [pmAudio, setPmAudio] = React.useState(70);
    const handlePmAudio = (event) => {
        setPmAudio(event.currentTarget.value);
    }
    const [pmOther, setPmOther] = React.useState(45);
    const handlePmOther = (event) => {
        setPmOther(event.currentTarget.value);
    }
    const [compRooms, setCompRooms] = React.useState(0);
    const handleCompRooms = (event) => {
        let value = getCorrectValue(event.currentTarget.value);
        setCompRooms(value);
    }
    const [compRoomsPer, setCompRoomsPer] = React.useState(0);
    const handleCompRoomsPer = (event) => {
        let value = getCorrectValue(event.currentTarget.value);
        setCompRoomsPer(value);
    }
    const [commissionPercent, setCommissionPercent] = React.useState(0);
    const handleCommissionPercent = (event) => {
        let value = getCorrectValue(event.currentTarget.value);
        setCommissionPercent(value);
    }
    let obj = {
        "loading": false,
        "loaded": false,
        "error": false
    }
    const [compState, setCompState] = React.useState(obj);
    const handleGroupName = (event) => {
        let grpNameValue = event.currentTarget.value;
        setGroupName(grpNameValue);
        (rohTotal > 0 && grpNameValue.length > 0) ? setDisabled(false) : setDisabled(true);
        console.log(event.currentTarget.value, "  ==   ", groupName, "string length => ", groupName.length);
    }
    const handleChange = (event, newValue) => {
        setValue(newValue);
    };
    //For mailer popover
    const [anchorEl, setAnchorEl] = React.useState(null);
    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };
    const openSendMail = Boolean(anchorEl);
    const id = openSendMail ? 'simple-popover' : undefined;

    const [payload, setPayload] = useState();
    const [disabled, setDisabled] = useState(true);
    const [reset, setReset] = useState(false);

    const [referesh, setReferesh] = useState(false);


    useEffect(() => {
        const { selectedDates, finalPayload } = calculateRoomDetails(auth, app_state, dates);
        setPayload(finalPayload)

        // send a request to get 365 data 
        const { data_sources } = getTileById('group');
            let rangeFromDate = new moment(app_state.as_of_date).startOf('month').format('YYYY-MM-DD');
            let rangeUntilDate = new moment(rangeFromDate).add(1, "year").endOf('month').format('YYYY-MM-DD');
            const mainPromises = [];
            const payLoads = {
                OSRYear: {
                    "method": "GET",
                    "asOfDate": app_state.as_of_date,
                    "resourceId": app_state.property_id,
                    "resourceScope": "property",
                    "asOfDate": app_state.as_of_date,
                    "pickupDays": 1,
                    "rangeFromDate": rangeFromDate,
                    "rangeUntilDate": rangeUntilDate,
                    "queryType": "ARI",
                    "querySubtype": "",
                    "segmentationType": "market_code",
                    "segmentFilter": "1",
                    "type": 1,
                },
            };
            data_sources.map(activeApi => {
                let payload = payLoads[activeApi];
                dataService.updateDataCache(activeApi, { payload: payload });
                let dataSeries = getSeriesById(activeApi);
                let apiCallStarting = getData({
                    ...app_state, active_api: activeApi,
                })();
                mainPromises.push(apiCallStarting);
                apiCallStarting.then(
                    (rs) => {
                       setReferesh(true);
                    },
                    (erro) => {
                         
                    }
                )
            });

        // end send request to get 365 data 

    }, [arrivalDate, departureDate, reset, setArrivalDate])

    const handlePayload = (event, index) => {
        ancillaryCal();
        const { name, value } = event.currentTarget;
        let datesSelectedArrObj = [];
        let fb = document.getElementsByName("fb_txt[]");
        let faci = document.getElementsByName("faci_txt[]");
        let av = document.getElementsByName("av_txt[]");
        let other = document.getElementsByName("other_txt[]");
        selectedDates.map((items, i) => {
            let otbData = getOtbData(items);
            let roomsAvailable = totalCapacity - otbData[0]['HotelTotal']['primary']['Rooms'];
            let dataObj = {
                "primary": {
                    "rooms": runHouse[i]
                },
                "date": items,
                "ancillary": {
                    "food_bev": Number(fb[i].value),
                    "facilities": Number(faci[i].value),
                    "audio_visual": Number(av[i].value),
                    "other": Number(other[i].value)
                }
            }
            // debugger;
            datesSelectedArrObj.push(dataObj);

        })

        payload['dates'] = datesSelectedArrObj;
        setPayload(payload);
    }


    const ancillaryCal = () => {

        let rowWiseTotalArr = [];

        let fb = document.getElementsByName("fb_txt[]");
        let fac = document.getElementsByName("faci_txt[]");
        let av = document.getElementsByName("av_txt[]");
        let other = document.getElementsByName("other_txt[]");

        var fb_total = 0;
        var fb_values = [];

        var fac_total = 0;
        var fac_values = [];

        var av_total = 0;
        var av_values = [];

        var other_total = 0;
        var other_values = [];

        for (var i = 0; i < fb.length; i++) {
            var rTotal = 0;
            if (typeof fb[i].value !== "undefined") {
                // let val = (fb[i].value) ? fb[i].value : 0;
                let val = getCorrectValue(fb[i].value);
                fb_values.push(val);
                rTotal = parseFloat(rTotal) + parseFloat(val);
                fb_total = parseFloat(fb_total) + parseFloat(val);
            }
            if (typeof fac[i].value !== "undefined") {
                // let val = (fac[i].value) ? fac[i].value : 0;
                let val = getCorrectValue(fac[i].value);
                fac_values.push(val);
                rTotal = parseFloat(rTotal) + parseFloat(val);
                fac_total = parseFloat(fac_total) + parseFloat(val);
            }
            if (typeof av[i].value !== "undefined") {
                // let val = (av[i].value) ? av[i].value : 0;
                let val = getCorrectValue(av[i].value);
                av_values.push(val);
                rTotal = parseFloat(rTotal) + parseFloat(val);
                av_total = parseFloat(av_total) + parseFloat(val);
            }
            if (typeof other[i].value !== "undefined") {
                // let val = (other[i].value) ? other[i].value : 0;
                let val = getCorrectValue(other[i].value);
                other_values.push(val);
                rTotal = parseFloat(rTotal) + parseFloat(val);
                other_total = parseFloat(other_total) + parseFloat(val);
            }
            rowWiseTotalArr.push(rTotal);

        }
        setFbValues(fb_values);
        setFacValues(fac_values)
        setAvValues(av_values);
        setOtherValues(other_values);

        setFinalRowWiseTotal(rowWiseTotalArr)
        setFbTotal(fb_total);
        setFacTotal(fac_total);
        setAvTotal(av_total);
        setOtherTotal(other_total);
        let final_Row_total = rowWiseTotalArr.reduce(function (x, y) { return x + y; }, 0);
        setFinalRowTotal(final_Row_total);

        console.log("rowWiseTotalArr ", rowWiseTotalArr);

    }

    /// getting Room details details
    let dates = { arrivalDate, departureDate };
    const { totalNights, totalCapacity, selectedDates, finalPayload } = calculateRoomDetails(auth, app_state, dates);
    let totalRoomsAvailable = 0;

    const [runHouse, setRunHouse] = useState([]);
    const handleRunOfHouse = (event, index, roomsAvailable) => {
        let { name, value } = event.currentTarget;
        value = (!value || value < 0 || !Number(value)) ? 0 : value.replace(/^0+/, '');
        value = (value > roomsAvailable) ? roomsAvailable : value;
        const list = [...runHouse];
        list[index] = value;
        setRunHouse(list);
        /// calculate the total of run of house 
        var run_of_house_total = 0;
        if (list.length > 0) {
            list.map((val) => {
                if (typeof val !== "undefined" && val) {
                    run_of_house_total = parseFloat(run_of_house_total) + parseFloat(val);
                }
            })
        }
        setRohTotal(run_of_house_total);
        (run_of_house_total > 0 && groupName) ? setDisabled(false) : setDisabled(true);
    }

    /////   submit button functionality ////
    let resObj = {
        "expected_total_revenue": 0,
        "expected_incremental_revenue": 0,
        "arrival_date": "",
        "departure_date": "",
        "recommended_rate": 0,
        "total_revenue_displaced": 0
    };
    const [result, setResult] = useState(resObj)
    const [discount_from_retail, set_discount_from_retail] = useState([]);
    const [recommended_rate, set_recommended_rate] = useState([]);

    const [payloadall, setPayloadAll] = useState([]);
    const [emails, setEmails] = useState(null)
    const handleEmails = (event) => {
        setEmails(event.currentTarget.value);
    }

    function validateEmail(email) {
        var re = /\S+@\S+\.\S+/;
        return re.test(email);
    }

    function handleDownloadReport() {
        setCompState({ ...compState, "error": false, "errorEmailDownload": true, "errorMsg": "Please wait..., We are generating a quote for you.", "errorType": "info" });
        const { data_popup_api } = getTileById('save_quote');
        let activeApi = data_popup_api.OnSubmit;
        dataService.updateDataCache(activeApi, { payload: payloadall });
        getData({
            ...app_state, active_api: activeApi,
        })()
            .then((response) => {
                if (response.status === "success") {
                    setCompState({ ...compState, "error": false, "errorEmailDownload": true, "errorMsg": "Quote has been downloaded successfully.", "errorType": "success" });
                    if (response.data.url) {
                        window.location.href = response.data.url;
                    }
                    setTimeout(() => {
                        setCompState({ ...compState, "errorEmailDownload": false, "errorMsg": "" });
                    }, 5000);
                }
            })
            .catch((error) => {
                setCompState({ ...compState, "error": false, "errorEmailDownload": true, "errorMsg": "Please try again !", "errorType": "error" });
                setTimeout(() => {
                    setCompState({ ...compState, "errorEmailDownload": false, "errorMsg": "" });
                }, 5000);
            })
    }

    const handleEmailReport = () => {
        // to check all emails format is correct or not.
        var emailList = emails.split(',');
        var flag = 1;
        for (let i = 0; i < emailList.length; i++) {
            if (validateEmail(emailList[i]) == false) {
                flag = 0;
                break;
            }
        }

        if (flag == 0) {
            setCompState({ ...compState, "error": false, "errorEmailDownload": true, "errorMsg": "Please enter the correct email format.", "errorType": "info" });
            setTimeout(() => {
                setCompState({ ...compState, "error": false });
            }, 3000);
        } else {
            setCompState({ ...compState, "error": false, "errorEmailDownload": true, "errorMsg": "Please wait,sending quote to your email.", "errorType": "info" });
            payloadall['emails'] = emails;
            const { data_popup_api } = getTileById('save_quote');
            let activeApi = data_popup_api.OnSubmit;
            dataService.updateDataCache(activeApi, { payload: payloadall });
            getData({
                ...app_state, active_api: activeApi,
            })()
                .then((response) => {
                    if (response.status === "success") {
                        setCompState({ ...compState, "error": false, "errorEmailDownload": true, "errorMsg": "Quote has been sent.Thankyou!", "errorType": "success" });
                    }
                    setTimeout(() => {
                        setCompState({ ...compState, "errorEmailDownload": false, "errorMsg": "" });
                    }, 3000);
                })
                .catch((error) => {
                    setCompState({ ...compState, "error": true, "errorEmailDownload": true, "errorMsg": "Something went wrong! Please try again", "errorType": "info" });
                    setTimeout(() => {
                        setCompState({ ...compState, "errorEmailDownload": false, "errorMsg": "" });
                    }, 3000);
                })
                openSendMail = false;
        }
    }

    const handleSubmit = () => {
        setCompState({ ...compState, "loaded": false, "loading": true, "error": false })
        let datesArr = [];
        selectedDates.map((items, i) => {
            let otbData = getOtbData(items);
            let roomsAvailable = totalCapacity - otbData[0]['HotelTotal']['primary']['Rooms'];
            let rooms = (runHouse[i] && Number(runHouse[i]) >= 0) ? Number(runHouse[i]) : 0;
            let dataObj = {
                "primary": {
                    "rooms": Number(rooms)
                },
                "date": items,
                "ancillary": {
                    "food_bev": Number(fbValues[i]),
                    "facilities": Number(facValues[i]),
                    "audio_visual": Number(avValues[i]),
                    "other": Number(otherValues[i])
                }
            }
            datesArr.push(dataObj);
        })

        let finalPayload = {
            "as_of_date": app_state.as_of_date,
            "group_name": groupName,
            "dates": datesArr,
            "overall_request": {
                "profit_percents": {
                    "food_bev": Number(pmfb / 100),
                    "facilities": Number(pmFacility / 100),
                    "audio_visual": Number(pmAudio / 100),
                    "other": Number(pmOther / 100)
                },
                "comp_rooms": {
                    "comp_room_amt": Number(compRooms),
                    "comp_room_per_rooms": Number(compRoomsPer)
                },
                "comission_percent": Number(commissionPercent / 100)
            }
        }
        setPayloadAll(finalPayload);
        const { data_popup_api } = getTileById('group');
        let activeApi = data_popup_api.OnSubmit;
        dataService.updateDataCache(activeApi, { payload: finalPayload });
        getData({
            ...app_state, active_api: activeApi,
        })()
            // debugger;
            .then((response) => {
                setCompState({ ...compState, "loaded": true, "loading": false, "error": false });
                setSendDetails(true);
                console.log(" response ", response['recommendations'][0]['dates']);
                let datesDiscountArr = [];
                let datesRecRate = [];

                response['recommendations'][0]['dates'].map((item) => {
                    datesDiscountArr.push(convertToPercent(item.discount_from_retail));
                    datesRecRate.push(Math.round(item.recommended_rate));
                })
                set_discount_from_retail([...datesDiscountArr]);
                set_recommended_rate([...datesRecRate]);
                let resObj = {
                    "expected_total_revenue": currSymbol + "" + Math.round(response['recommendations'][0]['overall_recommendation']['expected_total_revenue']),
                    "expected_incremental_revenue": currSymbol + "" + Math.round(response['recommendations'][0]['overall_recommendation']['expected_incremental_revenue']),
                    "arrival_date": response['recommendations'][0]['overall_recommendation']['arrival_date'],
                    "departure_date": response['recommendations'][0]['overall_recommendation']['departure_date'],
                    "recommended_rate": currSymbol + "" + Math.round(response['recommendations'][0]['overall_recommendation']['recommended_rate']),
                    "total_revenue_displaced": currSymbol + "" + Math.round(response['recommendations'][0]['overall_recommendation']['total_revenue_displaced']),
                }
                setResult(resObj);
            })
            .catch((error) => {
                setCompState({ ...compState, "loaded": true, "loading": false, "error": true, "errorMsg": "Something went wrong! Please try again", "errorType": "error" });
                setTimeout(() => {
                    setCompState({ ...compState, "error": false });
                }, 5000);
            });
    }

    // function for reset button 
    const handleReset = () => {
        setRohTotal(0);

        let resObj = {
            "expected_total_revenue": 0,
            "expected_incremental_revenue": 0,
            "arrival_date": "",
            "departure_date": "",
            "recommended_rate": 0,
            "total_revenue_displaced": 0
        };
        setResult(resObj)
        setGroupName("");
        setArrivalDate(moment());
        setDepartureDate(moment().add(1, 'day'))
        setRunHouse([]);
        set_discount_from_retail([]);
        set_recommended_rate([]);
        setCompRooms(0);
        setCompRoomsPer(0);
        setCommissionPercent(0);
        setPmFb(30);
        setPmAudio(70);
        setPmFacility(75);
        setPmOther(45);
        setFbValues([]);
        setFacValues([]);
        setAvValues([]);
        setOtherValues([]);
        setFinalRowTotal(0);
        setFinalRowWiseTotal(0);
        setFbTotal(0);
        setFacTotal(0);
        setAvTotal(0);
        setOtherTotal(0);

        setCompState({ "loaded": false, "loading": false, "error": false });
        setEmails("");
        setReset(true);
        setDisabled(true);

    }

    let recRateTotal = 0;
    let discountTotal = 0;
    const [openArrival, setOpenArrival] = useState(false);
    const [openDeparture, setOpenDeparture] = useState(false);
    const preventTyping = (e) => {
        e.preventDefault();
        return false;
    }
    return (
        <LocalizationProvider dateAdapter={AdapterMoment}>
            <Box className="group-pricing-wrap">
                <div className="modal-titlebar">
                    <Box>
                        <div data-id="events">
                            {/* <span className="icon-style icon-icon-groups-pricing"></span> */}
                            <span className='title-with-icon_xx'><span className="text-green">Group - </span>Pricing</span>
                        </div>
                    </Box>
                    <Box sx={{ float: 'right' }}>
                        <Button sx={{
                            minWidth: 'auto', width: '22px', height: '22px', p: '7px', position: 'absolute', top: 9, right: 0,
                            mr: '7px', borderRadius: '50%', background: '#000', color: '#FFF', fontSize: '10px'
                        }} onClick={props.handleClose}>
                            <CloseSharpIcon fontSize='small' />
                        </Button>
                    </Box>
                </div>

                <Grid container direction="row">
                    <Grid item className='left-part' xs={(compState.loaded && !compState.error) ? 8 : 12}>
                        <div className='linear-div'>
                            {compState.loading && <LinearProgress color='green' />}

                        </div>

                        <Grid container sx={{ pt: 2, pb: 2 }} spacing={0} justifyContent="space-between" className="group-top-bar">
                            <Grid item xs={4} >
                                <div className='top-bar-item first'>
                                    <TextField className='text-field group-name' fullWidth size='small' onChange={handleGroupName} value={groupName} label="GROUP NAME *" placeholder='Group Name' autoFocus />
                                </div>
                            </Grid>
                            <Grid item xs={4}>
                                <div className='top-bar-item'>
                                    <DesktopDatePicker
                                        className='text-field'
                                        open={openArrival}
                                        onOpen={() => setOpenArrival(true)}
                                        onClose={() => setOpenArrival(false)}
                                        label={<>ARRIVAL DATE</>}
                                        inputFormat="ddd, MMM DD, YYYY"
                                        value={arrivalDate}
                                        onChange={(newValue) => {
                                            setArrivalDate(newValue);
                                        }}
                                        disablePast={true}
                                        renderInput={(params) => {
                                            return <TextField onKeyDown={preventTyping} onClick={(e) => setOpenArrival(true)} fullWidth className="text-field vijay" size="small" {...params} />
                                        }}
                                    />
                                </div>
                            </Grid>
                            <Grid item xs={4}>
                                <div className='top-bar-item last'>
                                    <DesktopDatePicker
                                        className='text-field'
                                        open={openDeparture}
                                        onOpen={() => setOpenDeparture(true)}
                                        onClose={() => setOpenDeparture(false)}
                                        label={<>DEPARTURE DATE</>}
                                        inputFormat="ddd, MMM DD, YYYY"
                                        value={departureDate}
                                        onChange={(newValue) => {
                                            setDepartureDate(newValue);
                                        }}
                                        minDate={arrivalDate}
                                        renderInput={(params) => {
                                            return <TextField onKeyDown={preventTyping} onClick={(e) => setOpenDeparture(true)} fullWidth className="text-field" size="small" {...params} />
                                        }}
                                    />
                                </div>
                            </Grid>
                        </Grid>
                        <Grid container direction="row" justifyContent="space-between" alignItems="center" className='facts'>
                            <Grid item>
                                <div className='heading font-light'>
                                    {totalNights}
                                </div>
                                <div className='subheading'>
                                    TOTAL NIGHTS
                                </div>
                            </Grid>
                            <Grid item>
                                <div className='heading font-light'>
                                    {dataFormatMonthlyCard('Rooms', rohTotal, ui_state)}
                                </div>
                                <div className='subheading'>
                                    TOTAL ROOMS
                                </div>
                            </Grid>
                            <Grid item>
                                <div className='heading font-light'>
                                    {(compState.loading) ? <SmallLoader /> : dataFormatMonthlyCard('', result.expected_total_revenue, ui_state)}
                                </div>
                                <div className='subheading'>
                                    ROOMS REVENUE
                                </div>
                            </Grid>
                            <Grid item>
                                <div className='heading font-light'>
                                    {(compState.loading) ? <SmallLoader /> : dataFormatMonthlyCard('', result.expected_incremental_revenue, ui_state)}
                                </div>
                                <div className='subheading'>
                                    INCREMENTAL REVENUE
                                </div>
                            </Grid>
                        </Grid>
                        <Box className="tabular-data">

                            <Box sx={{ backgroundColor: '#F4F2F2' }}>
                                <Tabs value={value} onChange={handleChange} className="tabs-wrap" aria-label="basic tabs example">
                                    <Tab label="ROOM BLOCK*" {...a11yProps(0)} />
                                    <Tab label="GROUP DETAILS" {...a11yProps(1)} />
                                    <Tab label="ANCILLARY REV" {...a11yProps(2)} />
                                </Tabs>
                            </Box>
                            <TabPanel className="tab-content room-block" value={value} index={0}>
                                <TableContainer sx={{ mb: 5, maxHeight: "33vh", minHeight: 200 }} size="small">
                                    <Table className='group-pricing-table' stickyHeader size="small" aria-label="a dense table">
                                        <TableHead className='thead'>
                                            <TableRow>
                                                <TableCell align="center">	</TableCell>
                                                <TableCell align="center">AVAIL ROOMS</TableCell>
                                                <TableCell align="center">RUN OF HOUSE</TableCell>
                                                <TableCell align="center">RATES</TableCell>
                                                <TableCell align="center">DISCOUNT</TableCell>
                                            </TableRow>

                                        </TableHead>
                                        <TableBody className='tbody-stylex'>
                                            {
                                                selectedDates.map((items, i) => {
                                                    let recRate = (recommended_rate[i]) ? recommended_rate[i] : 0;
                                                    let disRetail = (discount_from_retail[i]) ? discount_from_retail[i] : 0;
                                                    recRateTotal = parseFloat(recRateTotal) + parseFloat(recRate);
                                                    discountTotal = parseFloat(discountTotal) + parseFloat(disRetail);
                                                    let otbData = getOtbData(items);
                                                    // let roomsAvailable = totalCapacity - otbData[0]['HotelTotal']['primary']['Rooms'];
                                                    let roomsAvailable = (otbData.length > 0) ? totalCapacity - otbData[0]['HotelTotal']['primary']['Rooms'] : totalCapacity;

                                                    roomsAvailable = (roomsAvailable < 0) ? 0 : roomsAvailable;
                                                    totalRoomsAvailable = totalRoomsAvailable + roomsAvailable;
                                                    return (<TableRow className='tr-stylex' key={i} sx={{ '&:last-child td, &:last-child th': { border: 0 }, maxHeight: "30px" }}>
                                                        <TableCell className='date-th-style' align="center">{moment(items).format('ddd, MMM DD')}</TableCell>
                                                        <TableCell align="center">{roomsAvailable}</TableCell>
                                                        <TableCell align="center" className='editable'>
                                                            <TextField onChange={e => handleRunOfHouse(e, i, roomsAvailable)} type="number"
                                                                inputProps={{ min: 0, max: roomsAvailable }} className='user-entry run_of_house_txt' value={(runHouse[i]) ? runHouse[i] : 0} name='run_of_house[i]' />

                                                        </TableCell>
                                                        <TableCell align="center">
                                                            {(compState.loading) ? <SmallLoader /> : currSymbol + "" + recRate}

                                                        </TableCell>
                                                        <TableCell align="center">
                                                            {(compState.loading) ? <SmallLoader /> : disRetail + "%"}
                                                        </TableCell>
                                                    </TableRow>)
                                                })
                                            }
                                        </TableBody>
                                        <TableFooter className='thead stiky-bottom font-bold'>
                                            <TableRow className='footer-row'>
                                                <TableCell align="center" className='footer-row date-th-style'>Total	</TableCell>
                                                <TableCell align="center" className='footer-row'>{totalRoomsAvailable}</TableCell>
                                                <TableCell align="center" className='footer-row'>{rohTotal}</TableCell>
                                                <TableCell align="center" className='footer-row'>{currSymbol + "" + Math.round(recRateTotal / totalNights)}</TableCell>
                                                <TableCell align="center" className='footer-row'></TableCell>
                                            </TableRow>
                                        </TableFooter>
                                    </Table>

                                </TableContainer>
                            </TabPanel>
                            <TabPanel value={value} index={1}>
                                <div sx={{ mb: 5, maxHeight: "33vh", minHeight: 200 }}>
                                    <div className='tab2-container'>
                                        <div className='tab-two-inner-div one fs-15'># OF COMP ROOMS</div>
                                        <div className='tab-two-inner-div third fs-15'>COMMISSION</div>
                                    </div>
                                    <div className='tab2-container'>
                                        <div className='tab-two-inner-div one' >
                                            <TextField value={compRooms} onChange={handleCompRooms} type="number" className='tab-two-txt' variant="outlined" />
                                            <label className='label-style'> PER </label>
                                            <TextField value={compRoomsPer} onChange={handleCompRoomsPer} type="number" className='tab-two-txt' label="" variant="outlined" sx={{ width: "70px", textAlign: "center" }} />
                                            <label className='label-style'>ROOMS</label>
                                        </div>
                                        <div className='tab-two-inner-div third'>
                                            <TextField value={commissionPercent} onChange={handleCommissionPercent} type="number" className='tab-two-txt' variant="outlined" sx={{ width: "70px", textAlign: "center" }} />
                                            <label className='label-style-percent'>%</label>
                                        </div>
                                    </div>
                                </div>


                            </TabPanel>
                            <TabPanel value={value} index={2} className="tab-content ancillary-rev">
                                <TableContainer sx={{ mb: 5, maxHeight: "33vh", minHeight: 200 }}>
                                    <Table className='group-pricing-table' stickyHeader size="small" aria-label="a dense table">
                                        <TableHead className='thead'>
                                            <TableRow>
                                                <TableCell align="center"></TableCell>
                                                <TableCell align="center">Food & Beverage</TableCell>
                                                <TableCell align="center">Facilities</TableCell>
                                                <TableCell align="center">Audio Visual</TableCell>
                                                <TableCell align="center">Other</TableCell>
                                                <TableCell align="center">Totals</TableCell>
                                            </TableRow>

                                        </TableHead>
                                        <TableBody sx={{ minHeight: "300px" }}>
                                            <TableRow className='pm-row' sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                                                <TableCell className='date-th-style'>Profit Margin</TableCell>
                                                <TableCell align="center" className='editable'>
                                                    <TextField type="number" value={pmfb} onChange={handlePmFb} className='user-entry' name='pm_fb' />
                                                    <label className='ml-5'>%</label>
                                                </TableCell>
                                                <TableCell align="center" className='editable'>
                                                    <TextField type="number" value={pmFacility} onChange={handlePmFacility} className='user-entry' name='pm_facility' />
                                                    <label className='ml-5'>%</label>
                                                </TableCell>
                                                <TableCell align="center" className='editable'>
                                                    <TextField type="number" value={pmAudio} onChange={handlePmAudio} className='user-entry' name='pm_audio' />
                                                    <label className='ml-5'>%</label>
                                                </TableCell>
                                                <TableCell align="center" className='editable'>
                                                    <TextField type="number" value={pmOther} onChange={handlePmOther} className='user-entry' name='pm_other' />
                                                    <label className='ml-5'>%</label>
                                                </TableCell>
                                                <TableCell align="center" className='editable'>

                                                </TableCell>
                                            </TableRow>

                                            {
                                                selectedDates.map((items, i) => {
                                                    return (<TableRow key={i} sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                                                        <TableCell align="center" className='date-th-style'>{moment(items).format('ddd, MMM DD')}</TableCell>
                                                        <TableCell align="center" className='editable '>
                                                            <TextField onChange={e => handlePayload(e, i)} name='fb_txt[]' value={(fbValues[i]) ? fbValues[i] : 0} type="number" className='user-entry' inputProps={{ min: 0 }} />
                                                            <label className='ml-15'></label>

                                                        </TableCell>
                                                        <TableCell align="center" className='editable'>
                                                            <TextField onChange={e => handlePayload(e, i)} name='faci_txt[]' value={(facValues[i]) ? facValues[i] : 0} type="number" className='user-entry' inputProps={{ min: 0 }} />
                                                            <label className='ml-15'></label>

                                                        </TableCell>
                                                        <TableCell align="center" className='editable'>
                                                            <TextField onChange={e => handlePayload(e, i)} name='av_txt[]' value={(avValues[i]) ? avValues[i] : 0} type="number" className='user-entry' inputProps={{ min: 0 }} />
                                                            <label className='ml-15'></label>

                                                        </TableCell>
                                                        <TableCell align="center" className='editable'>
                                                            <TextField onChange={e => handlePayload(e, i)} name='other_txt[]' value={(otherValues[i]) ? otherValues[i] : 0} type="number" className='user-entry' inputProps={{ min: 0 }} />
                                                            <label className='ml-15'></label>

                                                        </TableCell>
                                                        <TableCell align="center" className='footer-row'>
                                                            {(finalRowWiseTotal[i]) ? "$" + finalRowWiseTotal[i] : <></>}
                                                        </TableCell>

                                                    </TableRow>)
                                                })
                                            }
                                        </TableBody>
                                        <TableFooter className='thead stiky-bottom font-bold'>
                                            <TableRow className='footer-row'>
                                                <TableCell align="center" className='footer-row date-th-style'>Total </TableCell>
                                                <TableCell align="center" className='footer-row pr-50'>{currSymbol}{fbTotal}</TableCell>
                                                <TableCell align="center" className='footer-row pr-50'>{currSymbol}{facTotal}</TableCell>
                                                <TableCell align="center" className='footer-row pr-50'>{currSymbol}{avTotal}</TableCell>
                                                <TableCell align="center" className='footer-row pr-50'>{currSymbol}{otherTotal}</TableCell>
                                                <TableCell align="center" className='footer-row'>{currSymbol}{finalRowTotal}</TableCell>
                                            </TableRow>
                                        </TableFooter>
                                    </Table>
                                </TableContainer>
                            </TabPanel>
                        </Box>

                        <div className='submit-button'>
                            {compState.error &&
                                <div className='error-style'>
                                    <Alert severity={compState.errorType} className='alert-style'>{compState.errorMsg}</Alert>
                                </div>
                            }

                            {
                                (compState.loaded) ?
                                    <>
                                        <Button className="submit" size='medium' onClick={handleReset} variant="outlined">Reset</Button>
                                    </>
                                    :
                                    <>
                                        <Button className={(disabled) ? "disabled" : "submit"} size='medium' disabled={(disabled) ? "disabled" : ""} onClick={handleSubmit} variant="outlined">Submit</Button>
                                    </>
                            }
                        </div>

                    </Grid>

                    {compState.loaded && !compState.error && <Grid item className='right-part' xs={4}>
                        {
                            (sendDetails && sendDetails !== 'loader' && <>
                                <div className='arrow-right'></div>
                                <div className='recommended-rate'>

                                    <div className='heading'>
                                        <div>RECOMMENDED RATE</div>

                                        <span className='subheading'>
                                            {moment(result.arrival_date).format('ddd, MMM DD')} - {moment(result.departure_date).format('ddd, MMM DD')}
                                        </span>
                                    </div>
                                    <div className='acceptable'>
                                        <span className='acceptable-rate'>
                                            {dataFormatMonthlyCard('', result.recommended_rate, ui_state)}
                                        </span>
                                        <div>OPTIMAL ACCEPTABLE RATE</div>
                                        <span className='task-done'></span>
                                    </div>
                                    <Grid container direction="row" justifyContent="space-between" alignItems="center" className='rate-facts'>
                                        <Grid item xs={6} className="rev-exp">
                                            <div>
                                                TOTAL REVENUE
                                                EXPECTED
                                            </div>
                                            <div className='subheading'>
                                                {dataFormatMonthlyCard('', result.expected_total_revenue, ui_state)}
                                            </div>
                                        </Grid>
                                        <Grid item xs={6} className="rev-displayed">
                                            <div>
                                                TOTAL REVENUE
                                                DISPLACED
                                            </div>
                                            <div className='subheading'>
                                                {dataFormatMonthlyCard('', result.total_revenue_displaced, ui_state)}
                                            </div>
                                        </Grid>
                                        <div className='disclaimer'>This Group quote is valid only for 7 days from today ({moment().add(7, "days").format('MMM DD,YYYY')})</div>
                                    </Grid>

                                    <div className=''>
                                        <div className='export'>
                                            <Stack direction="row" className='export-row' spacing={1}>
                                                <Button className='export-quote' variant="outlined" onClick={handleDownloadReport} startIcon={<FileDownloadOutlinedIcon />}>
                                                </Button>
                                                <Button onClick={handleClick} className='export-quote' variant="outlined" startIcon={<EmailOutlinedIcon />}>
                                                </Button>
                                            </Stack>
                                        </div>

                                        {openSendMail && <div className='sent-email'>
                                            <div className='message-text'>
                                                <TextField
                                                    id="input-with-icon-textfield"
                                                    multiline
                                                    minRows={2}
                                                    sx={{minWidth:250, color:"#ccc"}}
                                                    placeholder="abc@domain.com, "
                                                    value={emails} 
                                                    onChange={handleEmails}
                                                    InputProps={{
                                                        endAdornment: (
                                                            <InputAdornment position="end">
                                                                <SendIcon sx={{cursor:"pointer"}} onClick={handleEmailReport} />
                                                            </InputAdornment>
                                                        ),
                                                    }}
                                                />
                                                 
                                            </div>
                                        </div>
                                        }

                                        {/* {openSendMail && <div className='sent-email'>
                                            <div className='message-text'>
                                                <TextareaAutosize className='message-ted' aria-label="minimum height" minRows={3}
                                                    placeholder="abc@domain.com," style={{ minWidth: 290, width: "auto" }} value={emails} onChange={handleEmails} />
                                                <div className='send-email-message'>
                                                    <Stack direction="row" spacing={0}>
                                                        <IconButton aria-label="send" sx={{ fontSize: 'small' }} onClick={handleEmailReport}>
                                                            <SendIcon />
                                                        </IconButton>
                                                    </Stack>
                                                </div>
                                            </div>
                                        </div>
                                        } */}


                                    </div>
                                    <div className='alert-style-container'>
                                        {compState.errorEmailDownload &&
                                            <Alert severity={compState.errorType} className='alert-style'>{compState.errorMsg}</Alert>
                                        }
                                    </div>
                                </div>

                            </>
                            )
                        }

                        {(sendDetails && sendDetails === 'loader' && <>
                            <div className='arrow-right'></div>
                            <Grid sx={{ pl: 0, pr: 0, pt: 12, background: '#fff', minHeight: "348px" }}>
                                {/* <Loading showText="Generating the Quote, Please wait..." showLoader="false" leftStyle="10px" /> */}
                            </Grid>
                        </>
                        )

                        }

                    </Grid>}
                </Grid>
            </Box>
        </LocalizationProvider>
    )
}

export default GroupPricing
