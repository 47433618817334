import React from 'react'
import { CardContent, Chip, Container, Table, TableCell, TableContainer, TableHead, TableRow, TableBody, Tooltip } from '@mui/material';
import { convertToPercent } from '../../app/util';
import { getCodeDetails } from './DisplayData';
import { useSelector } from 'react-redux';

const checkCorrectValue = (val, symbol = '') => {
    if (!val) {
        return "Not Available";
    }
    if (symbol === '%') {
        return (convertToPercent(val) + symbol);
    }
    val = (typeof (val) === 'number' && symbol) ? parseFloat(val).toFixed(2) : val;
    return (symbol + val);
}

function RatePlanList(props) {
    const state = useSelector(appState => appState);
    const { auth, app } = useSelector(state => state);
    const { profile } = auth;
    const { app_state, ui_state } = useSelector(
        (state) => state.app.current_state
    );
    let { data } = props;
    let data1 = data.sort((a, b) => b.data.primary.Rooms - a.data.primary.Rooms);
    let dataStatus = (data && data.length) ? true : false;
    let dataAvailable = false;
    return (
        <>
            {(dataStatus && data && data.map((item, index) => {
                if (item.data.primary.Rooms !== 0) {
                    dataAvailable = true;
                    let bgcolor = (index % 2 === 0) ? "" : "#ebebeb";
                    return (
                        <TableRow key={index} className='rateplan-row-col-content' sx={{ background: bgcolor }}>
                            <TableCell component="th" scope="row" className='rateplan-row-col-content border-right'>
                                <Tooltip title={getCodeDetails(item.ratePlanCode, app_state)} arrow followCursor>
                                    <div>{checkCorrectValue(item.ratePlanCode)}</div>
                                </Tooltip>
                            </TableCell>
                            <TableCell component="th" scope="row">
                                {checkCorrectValue(item.data.primary.Occ, '%')}
                            </TableCell>
                            <TableCell component="th" scope="row" sx={{ textAlign: "center" }}>
                                {checkCorrectValue(item.data.primary.Rooms)}
                            </TableCell>
                            <TableCell component="th" scope="row">
                                {checkCorrectValue(item.data.primary.ADR, ui_state.currency_symbol)}
                            </TableCell>
                        </TableRow>
                    )
                } else {
                    //    return (
                    //     <TableRow>
                    //         <TableCell colSpan={4} className='table-heading' sx={{ textAlign: "center" }}>No Rate Plan Found!</TableCell>
                    //     </TableRow>
                    //    )
                }
            })
            )}

            {( (!dataStatus || !dataAvailable) && <>
                <TableRow>
                    <TableCell colSpan={4} className='table-heading' sx={{ textAlign: "center" }}>No Rate Plan Found!</TableCell>
                </TableRow>
            </>
            )}



        </>
    )
}

export default RatePlanList
