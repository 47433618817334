import ArrowDropUpIcon from '@mui/icons-material/ArrowDropUp';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import Paper from "@mui/material/Paper";
import { styled } from "@mui/material/styles";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import ArrowForward from '@mui/icons-material/ArrowForward';
import EastIcon from '@mui/icons-material/East';
import WestIcon from '@mui/icons-material/West';

import ArrowBack from '@mui/icons-material/ArrowBack';
import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { processColumns, processData } from "./processData";
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import { LibraryAddCheck as CheckboxDoubleIcon, CheckBox as CheckBoxIcon, CheckBoxOutlineBlank as CheckBoxOutlineBlankIcon } from '@mui/icons-material';
import moment from "moment";
import {
    convertToPercent, getCompsetName, round, getNumDaysFromDateRange, safeReference, dateDiff,
    isString, isEmptyObj, getFromState, getTransformedSegments,getRestrictionsStr,
    numberFormatForReport, truncate, isNumber, dateFormat, deepCopy, processColumnsNew, getAppData, divide, generateTable
} from "../../app/util"
import { transformSegmentData } from "./segmentedData";
import "./style.scss";
import { updateAppState, getPropertyInfo, restrictionsAvailable } from "../../actions/appstate";
import { Button, Tooltip, getTableSortLabelUtilityClass, IconButton, Popover } from "@mui/material";
import { Box } from "@mui/system";
import { marketSellRatesTotal } from "./tilesData/marketsellrates";
import TableToExcel from "@linways/table-to-excel";
import Spinner from "../Spinner";
import MoreVertIcon from '@mui/icons-material/MoreVert';
import Filter from "./Filter";
import { TILES } from "../../app/config";
import Restrictions, { getResName } from "./Restrictions";
import RestrictionsList from "./RestrictionsList";
import RestrictionsUpdate from './RestrictionsUpdate';
import { primaryHeaderDisplay } from './table-functions';
import TablePagination from '@mui/material/TablePagination';
import DataService from "../../services/data.service";
import RoomTypeFilter from "./RoomTypeFilter"
import { getPricingDataFormatted } from './tilesData/pricing';
import DataGrid, { Row, RowRendererProps } from 'react-data-grid';
import InputEl from './InputEl';
import CheckboxEl from './CheckboxEl';
import InfoPopover from './InfoPopover';
import "./table-pricing.css"
import { getRestrictionsDisplay } from '../Chart/functions';
const PrimaryHeaderCell = styled(TableCell)(() => ({
    [`&.${tableCellClasses.head}`]: {
        backgroundColor: "#606060",
        color: "white",
        borderRight: "1px solid white",
        textTransform: "uppercase",
        fontWieght: "700",
    },
    [`&.${tableCellClasses.body}`]: {
        fontSize: 16,
    },
}));
const SecondaryHeaderGreyCell = styled(TableCell)(() => ({
    [`&.${tableCellClasses.head}`]: {
        backgroundColor: "#b2b2b2",
        color: "white",
        borderRight: "1px solid white",
        textTransform: "uppercase",
        fontWieght: "700",
        textAlign: "center",
    },
    [`&.${tableCellClasses.body}`]: {
        fontSize: 14,
    },
}));
const SecondaryHeaderBlueCell = styled(TableCell)(() => ({
    [`&.${tableCellClasses.head}`]: {
        backgroundColor: "#3fa7cd",
        color: "white",
        borderRight: "1px solid white",
        textTransform: "uppercase",
        fontWieght: "700",
        textAlign: "center",
    },
    [`&.${tableCellClasses.body}`]: {
        fontSize: 16,
    },
}));
const StyledTableRow = styled(TableRow)(({ theme }) => ({
    "&:nth-of-type(even)": {
        backgroundColor: "#ebebeb",
    },
    // hide last border
    "&:last-child td, &:last-child th": {
        border: 0,
    },
}));






function TablePricing({ compDisplayData }) {
    const state = useSelector(appState => appState);

    const { auth } = useSelector(state => state);
    const { profile } = auth;
    const { app_state, ui_state } = useSelector(
        (state) => state.app.current_state
    );

    const { workspace_controls, as_of_date, primary_stay_range } = app_state;
    const { sdlyOn, pickUpType, segmented_view, isRoomTypePricing } = workspace_controls;
    const { active_tiles } = ui_state;
    const [selectedPropertyName, setPropertyName] = useState(profile.property_details.filter(property => property.id == app_state.property_id)[0].name)
    const active_tiles_className ="tile-active-" + active_tiles.join(" tile-active-");
    const [columns, setColumns] = useState([]);
    const [allChecked, setAllChecked] = useState(false);
    const [data_cols, setDataCols] = useState([]);
    const [data_setup, setDataSetup] = useState([]);
    const [page, setPage] = React.useState(0);
    const dispatch = useDispatch();
    const [displayData, setDisplayData] = useState(compDisplayData);
    const [compLoad, setCompLoad] = useState(false);
    const [reloadGrid, setReloadGrid] = useState("");
    const rowsPerPage = 31;
    const [compLoadStatus, setCompLoadStatus] = useState('loading');
    const [anchorEl, setAnchorEl] = useState(null);
    const [isPopoverOpen, setIsPopoverOpen] = useState(false);
    const [popOverData, setPopOverData] = useState({
        anchorEl: null,
        stay_date: "",
        sellRate: "",
        otb: {},
        forecast: {},
        room_code: "",
        isBase: "",
    });
    const [roomTypeInputs, setRoomTypeInputs] = useState({});
    const [pricingInputs, setPricingInputs] = useState([]);
    const [pricingData, setPricingData] = useState(getPricingDataFormatted(app_state, true))
    const [propertyInfo, setPropertyInfo] = useState(getPropertyInfo(profile.property_details, app_state.property_id));
    const [selectColumAll, setSelectColumAll] = useState({});
    const myRowRenderer = (key, props) => {
        return (
            <TableRow key={key}>
                <Row {...props} />
            </TableRow>
        );
    }
    const myColumnRender = (key, props) => {
        return (
            <TableRow key={key}>
                <Row {...props} />
            </TableRow>
        );
    }
    const getNeededColumns = ({ auth, app_state, ui_state }) => {

        let startDate = app_state.primary_stay_range.date_from;
        let endDate = app_state.primary_stay_range.date_until;
        let restrictions = getAppData(auth, app_state).restrictions.enable; // getAppData(auth, app_state).restrictions.enable;
        let finalColumns = [];
        active_tiles.map((tile, i) => {
            let tableConfig = deepCopy(TILES[tile]['tableConfig']);
            let columns = tableConfig['columns'];

            let sdlyColumns = deepCopy(TILES[tile]['tableConfig']['sdlyOn_columns']);
            let restrictionsColumns = deepCopy(TILES[tile]['tableConfig']['restrictionsOn_columns']);
            let dataDbaColumns = deepCopy(TILES[tile]['tableConfig']['dataDbaColumns']);
            let dataDateColumns = deepCopy(TILES[tile]['tableConfig']['dataDateColumns']);
            // let dbaSdlyColumns = deepCopy(TILES[tile]['tableConfig']['dbaOn_sdly_columns']);
            let userForecastColumns = deepCopy(TILES[tile]['tableConfig']['userForecast_columns']);

            let houseLevelColumns = deepCopy(safeReference(TILES[tile], [], ["tableConfig", "houseLevelColumns"]));
            let roomTypeOnColumns = deepCopy(safeReference(TILES[tile], [], ["tableConfig", "roomTypeOnColumns"]));

            finalColumns = [...finalColumns, ...columns];
            //  debugger;
            let tile_segmented_view = true;
            let segmentedViewIgnore = ['compset'];
            if (segmentedViewIgnore.includes(active_tiles[0])) {
                tile_segmented_view = false;
            }
            if (active_tiles[0] === "market" && app_state.workspace_controls.scopeType === 'Dba') {
                tile_segmented_view = false;
            }

            if (active_tiles[0] === "compset") {
                let subColumns = getCompsetName();
                //    console.log("subColumns ",subColumns);
                columns[1]['subcolumns'] = [...columns[1]['subcolumns'], ...subColumns];
            }

            // check Scope is DBA
            // debugger;
            if (dataDbaColumns && app_state.workspace_controls.scopeType === 'Dba') {
                let dateColIndex = columns.findIndex(item => item.type === "dateColumn");
                let dbaSubColumns = columns[dateColIndex].subcolumns_dba;
                columns[dateColIndex]['subcolumns'] = dbaSubColumns;
                if (dataDbaColumns) {
                    finalColumns = [...finalColumns, ...dataDbaColumns];
                }
            } else {
                if (dataDateColumns) {
                    finalColumns = [...finalColumns, ...dataDateColumns];
                }
            }
            /// end ///

            /// to check user forecast ///
            let userForecastData = [];
            if (!DataService.getSeriesById("ManualForecast").data) {
                userForecastData = [];
            } else {
                userForecastData = DataService.getSeriesById("ManualForecast").data.data;
            }
            if (userForecastData.length > 0 && userForecastColumns) {
                // console.log("inside userForecastData= => ", userForecastData['data']);
                finalColumns = [...finalColumns, ...userForecastColumns];
            }
            //// end ///

            if (sdlyOn && sdlyColumns) {
                finalColumns = [...finalColumns, ...sdlyColumns];
            }
            if (restrictions && restrictionsColumns) {
                finalColumns = [...finalColumns, ...restrictionsColumns];
            }

            if (tile === 'pricing') {
                if (isRoomTypePricing === false) {
                    if (restrictionsAvailable(propertyInfo)) {
                        houseLevelColumns = houseLevelColumns.map(column => {
                            if (column.id === "restrictions") {
                                column.hidden = false;
                                column.subcolumns[0].hidden = false;
                            }
                            return column;
                        })
                    }
                    finalColumns = [...finalColumns, ...houseLevelColumns];
                } else {
                    let room_settings = safeReference(propertyInfo.system_settings[0], [], ["inventory_settings", "current", "room_settings"])
                    let avail_room_cat = ui_state.selectedRoomPricingCategory.map(item=>  item.room_class);
                    let allCat = true;
                    if(!avail_room_cat.includes("All")){
                        allCat = false;
                    }
                    room_settings.map(room => {

                        if (room.is_base === true) {
                            
                            roomTypeOnColumns[0].id = "roomtype_pricing_" + room.room_code;
                            roomTypeOnColumns[0].room_code = room.room_code;

                            roomTypeOnColumns[0].room_class = room.room_class;
                            roomTypeOnColumns[0].display = {
                                room_code:room.room_code,
                                is_base:room.is_base,
                                room_inventory:room.inventory,
                                room_display_name:room.display_name
                            }
                            if(!allCat){
                                roomTypeOnColumns[0].subcolumns = roomTypeOnColumns[0].subcolumns.map(item => {
                                    if (item.subcol_id === "baseOnlyUpdate") {
                                        item.hidden = true;
                                    }
                                    return item;
                                })
                            }
                            // room.room_code + "(Base)[" + room.inventory + "] " + room.display_name;
                            // if (!restrictionsAvailable(propertyInfo)) {
                            //     roomTypeOnColumns[0].subcolumns = roomTypeOnColumns[0].subcolumns.map(item => {
                            //         if (item.subcol_id === "restrictions_current") {
                            //             item.hidden = true;
                            //         }
                            //         return item;
                            //     })
                            // }
                            roomTypeOnColumns[0].subcolumns = roomTypeOnColumns[0].subcolumns.map(item => {
                                if (item.subcol_id === "restrictions_current") {
                                    item.hidden = true;
                                }
                                return item;
                            })

                        } else {
                            let display={
                                room_code:room.room_code,
                                is_base:room.is_base,
                                room_inventory:room.inventory,
                                room_display_name:room.display_name
                            }
                            let roomTypeColumn = {
                                isBase: false,
                                room_class: room.room_class,
                                room_code: room.room_code,
                                isSticky: false,
                                type:"roomTypeColumn",
                                id: "roomtype_pricing_" + room.room_code,
                                display: display,//room.room_code + "[" + room.inventory + "] " + room.display_name,
                                max_rate: room.max_rate,
                                min_rate: room.min_rate,
                                series: 'OSRRateRecommend',
                                // type: "dataColumn",
                                subcolumns: [{ subcol_id: "Sold", display: "Sold", type: "integer", value: "Sold" },
                                {
                                    subcol_id: "restrictions_current", hidden: false,
                                    display: "restrictions", type: "restriction_display",
                                    value: "restrictions_current"
                                },
                                { subcol_id: "lodgiq_differential", display: "Lodgiq Differential", type: "currency", value: "lodgiq_differential", hasSelectAll: true },
                                { subcol_id: "current_differential", display: "Current Differential", type: "input", value: "current_differential" },
                                { subcol_id: "SellRate", display: "Current Rate", type: "input", value: "SellRate" },
                                ]
                            }
                            // if (!restrictionsAvailable(propertyInfo)) {
                            //     roomTypeColumn.subcolumns[1].hidden = true;
                            // }
                            roomTypeColumn.subcolumns[1].hidden = true;
                            if (ui_state.selectedRoomPricingRoomCodes.includes(room.room_code)) {
                                roomTypeOnColumns.push(roomTypeColumn);
                            }

                        }
                    })
                   
                    finalColumns = finalColumns.map(col => {
                        if (col.view === "hidden") {
                            col.subcolumns = col.subcolumns.map(subcol => {
                                subcol.hidden = true
                                return subcol;
                            })
                            col.hidden = true;
                        }
                        return col;
                    })
                    finalColumns = [...finalColumns, ...roomTypeOnColumns];


                }

            }
            // if (segmented_view && segmentedColumns) { not sure about this
            if (segmented_view && tile_segmented_view) {
                let dateColIndex = columns.findIndex(item => item.type === "dateColumn");
                let dateColumn = columns[dateColIndex];
                if (dateColIndex > -1 && dateColumn["subcolumns_segmented"]) {
                    dateColumn["subcolumns_segmented"].map(segmented => {
                        columns[dateColIndex].subcolumns.push(segmented);
                    });
                }
            }
        });
        // console.log("FinalColumns ==> ",finalColumns);

        //// remove the comparison column after click on the clear comparison button
        if (active_tiles[0] === "pace" && !app_state.workspace_controls.clearComparision) {
            let columnsWithOutComp = finalColumns.slice(0, 2);
            finalColumns = [...columnsWithOutComp];
        }
        // debugger;
        // End //

        finalColumns.sort(function (a, b) { return a.order - b.order });
        finalColumns.map((item, index) => {
            let subColumns = item.subcolumns;
            subColumns.sort(function (a, b) { return a.order - b.order });
        })

        let sortedColumns = [...finalColumns];
        // debugger;
        return sortedColumns;
    };

    const compRef = useRef({
        itemDragged: {},
        itemDroppedOn: {},
        columns: getNeededColumns({ auth, app_state, ui_state })
        // columns: deepCopy(TILES[active_tiles[0]]['tableConfig']['columns']),
        // sortedColumn: TILES[active_tiles[0]]['tableConfig']['columns'][0][0]
    });

    const selectedColumns = useRef({

    })
    useEffect(() => {
        let isSubscribed = true;

        setPropertyInfo(getPropertyInfo(profile.property_details, app_state.property_id));
        setPropertyName(profile.property_details.filter(property => property.id == app_state.property_id)[0].name)
        if (app_state.workspace_controls.downloadExportButton === true) {
            downloadReportToExcel( propertyInfo.name);
            dispatch(updateAppState("toggleChartTableView", {
                app_state: {
                    workspace_controls: { ...app_state.workspace_controls, downloadExportButton: false, }
                }
            }));
        }



        // debugger; 
        if (isSubscribed) {
            if (compLoad && displayData && displayData.length > 0) {
                setupData();

            }
            if (app_state.data_load) {

                dispatch(
                    updateAppState("data_load", {
                        app_state: {
                            data_load: false,
                        },

                    })
                );
            }


            if (!ui_state.showspinner) {
                //setCompLoad(false);
                //Handle multiple data set for intellimerge
                if (compDisplayData && compDisplayData.constructor.name === 'Array' && compDisplayData.length) {
                    // check for segmentrd data 
                    // console.log("time=> ", moment().format("HH:MM:SS"));
                    let transformedData = transformSegmentData(app_state, auth, compDisplayData[0].data, { segmentedOn: app_state.workspace_controls.segmented_view, tile: active_tiles[0], segments: segments });
                    // debugger;          

                    setDisplayData(transformedData);
                    setCompLoad(true);
                    // setupData();
                }
                if (compDisplayData && compDisplayData.data) {
                    let transformedData = transformSegmentData(app_state, auth, compDisplayData.data, { segmentedOn: app_state.workspace_controls.segmented_view, tile: active_tiles[0], segments: segments });
                    // debugger;
                    setDisplayData(transformedData);
                    setCompLoad(true);
                    // setupData();
                }
            }
        }
        return () => (isSubscribed = false);
    }, [ui_state.showspinner,
    workspace_controls.sdlyOn,
    workspace_controls.scopeType,
    workspace_controls.downloadExportButton,
    // ui_state.updatedFilter,
    // app_state.pricing_override_rates, app_state.roomtype_pricing_overrides,
    app_state.property_id, compLoad, //reloadGrid , displayData
    ]);
    useEffect(() => {
        let isSubscribed = true;
        if (isEmptyObj(app_state.pricing_override_rates)) {
            setAllChecked(false);
           
        }
        if(app_state.clearAllRoomTypePricing === true){
            setSelectColumAll(prev=>{
                Object.keys(prev).map(key=>{
                    prev[key] =false;
                })
                return prev
            })
            dispatch(updateAppState("toggleclear",{app_state:{clearAllRoomTypePricing:false}}))
        }
        if (compLoad && displayData && displayData.length > 0) {

            setupRoomTypeInputs()
            setupPricingInputs();
            // setAllChecked(false);
        }

        if (ui_state.updatedFilter) {
            updateRoomFilter()
        }

        return () => (isSubscribed = false);
    }, [app_state.pricing_override_rates, ui_state.updatedFilter, app_state.roomtype_pricing_overrides])
    const updateRoomFilter = () => {

        compRef.current.columns = getNeededColumns({ auth, app_state, ui_state });
        setupData();
        dispatch(updateAppState("change_room_pricing_category", {
            ui_state: {
                updatedFilter: false,
                // showspinner:false
            }
        }));
    }
    let segments = null
    if (segmented_view) {
        let marketSegments = getFromState('system_settings>market_segment_category_mapping', state);
        segments = getTransformedSegments(marketSegments);
        // console.log("Segments=>",segments);
    }
    const TableStyle = {
        height: "60vh",
        overflow: "auto",
    };
    const toggleAllColumn = (checked, { isBase, room_code }, type) => {
        const baseRoomType = app_state.room_settings.filter(item => item.is_base === true)[0];
        console.log(selectColumAll);
        if (!isBase) {
            type+="_"+room_code;
            pricingData.forEach(item => {
                const { stay_date } = item;
                let diff = moment(stay_date).diff(primary_stay_range.as_of_date, 'days');
                if(diff <0){
                    return;
                }
                const baseRate = item[baseRoomType.room_code];

                if (checked) {
                    if (!app_state.roomtype_pricing_overrides[stay_date]) {
                        app_state.roomtype_pricing_overrides[stay_date] = {}
                    }
                    if (!app_state.roomtype_pricing_overrides[stay_date][room_code]) {
                        app_state.roomtype_pricing_overrides[stay_date][room_code] = {}
                    }
                    let current_differential = item[room_code].SellRate -baseRate.SellRate;
                    if(app_state.roomtype_pricing_overrides[stay_date][baseRoomType.room_code] &&
                        app_state.roomtype_pricing_overrides[stay_date][baseRoomType.room_code]["baseOnlyUpdate"]
                        ){
                        app_state.roomtype_pricing_overrides[stay_date][baseRoomType.room_code]["baseOnlyUpdate"] =false;
                    }
                    if(item[room_code].lodgiq_differential !== current_differential){
                        app_state.roomtype_pricing_overrides[stay_date][room_code]["lodgiq_diff_checked"] = true;
                     
                        app_state.roomtype_pricing_overrides[stay_date][room_code]["curr_diff_arrow_right"] = true;
                        app_state.roomtype_pricing_overrides[stay_date][room_code]["current_differential"] = item[room_code].lodgiq_differential;
                        app_state.roomtype_pricing_overrides[stay_date][room_code]["SellRate"] = baseRate.SellRate + item[room_code].lodgiq_differential;
    
                    }else {
                        delete app_state.roomtype_pricing_overrides[stay_date][room_code]
                    }
                   
                } else if(app_state.roomtype_pricing_overrides[stay_date]&& app_state.roomtype_pricing_overrides[stay_date][room_code]) {
                    delete app_state.roomtype_pricing_overrides[stay_date][room_code]
                    // app_state.roomtype_pricing_overrides[stay_date][room_code]["current_differential"]= item[room_code].SellRate -baseRate.SellRate ;
                    // app_state.roomtype_pricing_overrides[stay_date][room_code]["SellRate"] = item[room_code].SellRate ;
                }
            })
           
        } else {

            setSelectColumAll(prev=>{
               
                
                if(type === "double" && checked){
                    prev["baseOnlyUpdate"]=false;
                    prev["single"] = false;
                }
                if(type === "baseOnlyUpdate" && checked){
                    Object.keys(prev).forEach(key=>{
                        if(key !== "single" || key !=="baseOnlyUpdate"){
                            prev[key]=false;
                        }
                    })
                }
                prev[type]=checked;
                return {...prev};
            })
            pricingData.forEach(dayData => {
                const { stay_date } = dayData;
                let diff = moment(stay_date).diff(primary_stay_range.as_of_date, 'days');
                if(diff <0){
                    return;
                }
                const baseRate = dayData[baseRoomType.room_code];
                let isRateSame = (baseRate.RecSellRate === baseRate.SellRate)? true :false;

                if (checked) {
                    if (!app_state.roomtype_pricing_overrides[stay_date]) {
                        app_state.roomtype_pricing_overrides[stay_date] = {}
                    }
                    if (!app_state.roomtype_pricing_overrides[stay_date][room_code] && !isRateSame) {
                        app_state.roomtype_pricing_overrides[stay_date][room_code] = {}
                    }
                }
             
                if (type === "single") {
                    // baseRate.SellRate = baseRate.RecSellRate | 0;
                    if (checked && !isRateSame) {
                        let baseOnlyUpdate= app_state.roomtype_pricing_overrides[stay_date][room_code]["baseOnlyUpdate"]

                        app_state.roomtype_pricing_overrides[stay_date][room_code]["SellRate"] = baseRate.RecSellRate;
                        app_state.roomtype_pricing_overrides[stay_date][room_code]["lodgiqRateSingle"] = true;    
                        Object.keys(dayData).map(key => {
                            if (key !== "stay_date" && key !== room_code) {
                                const roomData = dayData[key];
                                if (!app_state.roomtype_pricing_overrides[stay_date][key]) {
                                    app_state.roomtype_pricing_overrides[stay_date][key] = {}
                                }
                                if(baseOnlyUpdate){
                                    app_state.roomtype_pricing_overrides[stay_date][key]["curr_diff_arrow_left"] = true;
                                    app_state.roomtype_pricing_overrides[stay_date][key]["curr_diff_arrow_right"] = false;
                                    app_state.roomtype_pricing_overrides[stay_date][key]["current_differential"]=roomData.SellRate -baseRate.RecSellRate;
                                    app_state.roomtype_pricing_overrides[stay_date][key]["SellRate"] = roomData.SellRate;
                                }else{
                                    app_state.roomtype_pricing_overrides[stay_date][key]["curr_diff_arrow_right"] = true;

                                    // app_state.roomtype_pricing_overrides[stay_date][key]["current_differential"]=roomData.SellRate -baseRate.SellRate;
                                    app_state.roomtype_pricing_overrides[stay_date][key]["SellRate"] = baseRate.RecSellRate + (roomData.SellRate - baseRate.SellRate);
                               
                                }
                            }
                        })
                    } 
                    if(checked === false && app_state.roomtype_pricing_overrides[stay_date][room_code]) {
                        app_state.roomtype_pricing_overrides[stay_date][room_code]["SellRate"] = baseRate.SellRate;
                        app_state.roomtype_pricing_overrides[stay_date][room_code]["lodgiqRateSingle"] = false;
                        Object.keys(dayData).map(key => {
                            if (key !== "stay_date" && key !== room_code) {
                                const roomData = dayData[key];
                                if (!app_state.roomtype_pricing_overrides[stay_date][key]) {
                                    app_state.roomtype_pricing_overrides[stay_date][key] = {}
                                }
                                app_state.roomtype_pricing_overrides[stay_date][key]["curr_diff_arrow_left"] = false;
                                app_state.roomtype_pricing_overrides[stay_date][key]["curr_diff_arrow_right"] = false;
                                app_state.roomtype_pricing_overrides[stay_date][key]["current_differential"]=roomData.SellRate -baseRate.SellRate;
                                app_state.roomtype_pricing_overrides[stay_date][key]["SellRate"] = roomData.SellRate;
                            }
                        })
                    }

                }
                if (type === "double") {

                    if (checked && !isRateSame) {
                        
                        app_state.roomtype_pricing_overrides[stay_date][room_code]["SellRate"] = baseRate.RecSellRate;
                        app_state.roomtype_pricing_overrides[stay_date][room_code]["lodgiqRateDouble"] = true;
                        app_state.roomtype_pricing_overrides[stay_date][room_code]["lodgiqRateSingle"] = false;
                        Object.keys(dayData).map(key => {
                            if (key !== "stay_date" && key !== room_code) {
                                const roomData = dayData[key];
                                if (!app_state.roomtype_pricing_overrides[stay_date][key]) {
                                    app_state.roomtype_pricing_overrides[stay_date][key] = {}
                                } 
                                

                                app_state.roomtype_pricing_overrides[stay_date][key]["lodgiq_diff_checked"] = true;
                                if(app_state.roomtype_pricing_overrides[stay_date][baseRoomType.room_code]["baseOnlyUpdate"]){
                                    app_state.roomtype_pricing_overrides[stay_date][baseRoomType.room_code]["baseOnlyUpdate"]=false;
                                }
                                app_state.roomtype_pricing_overrides[stay_date][key]["curr_diff_arrow_right"] = true;
                                app_state.roomtype_pricing_overrides[stay_date][key]["current_differential"] = roomData["lodgiq_differential"];
                                app_state.roomtype_pricing_overrides[stay_date][key]["SellRate"] = baseRate.RecSellRate + app_state.roomtype_pricing_overrides[stay_date][key]["current_differential"];
                            }
                        })
                    }
                    if(checked === false){
                        app_state.roomtype_pricing_overrides[stay_date]={}
                    }

                }
                if (type === "baseOnlyUpdate") {
                    if (!app_state.roomtype_pricing_overrides[stay_date]) {
                        app_state.roomtype_pricing_overrides[stay_date] = {}
                    }
                    app_state.room_settings.map(room => {
                        let selected = false;
                        compRef.current.columns.map(item => {
                            if (item.room_code && room.room_code === item.room_code) {
                                selected = true;
                            }
                        })
                        if (room.is_base === false && true === selected){
                            if (!app_state.roomtype_pricing_overrides[stay_date][room.room_code]) {
                                app_state.roomtype_pricing_overrides[stay_date][room.room_code] = {}
                            }
                            if(checked){
                                
                                if(app_state.roomtype_pricing_overrides[stay_date][room.room_code]["lodgiq_diff_checked"]){
                                    const updatedRate = dayData[room.room_code]["SellRate"];
                                    if (app_state.roomtype_pricing_overrides[stay_date][room.room_code]) { // not sure what to do
                                        app_state.roomtype_pricing_overrides[stay_date][room.room_code]["SellRate"] = updatedRate;
                                    }
                                    app_state.roomtype_pricing_overrides[stay_date][room.room_code]["curr_diff_arrow_right"] = false;
                                    app_state.roomtype_pricing_overrides[stay_date][room.room_code]["lodgiq_diff_checked"] = false;
                                }
                            }
                        }
                    })
                    if (app_state.roomtype_pricing_overrides[stay_date][baseRoomType.room_code] && app_state.roomtype_pricing_overrides[stay_date][baseRoomType.room_code]["SellRate"] 
                    && app_state.roomtype_pricing_overrides[stay_date][baseRoomType.room_code]["SellRate"] !== dayData[baseRoomType.room_code]["SellRate"]
                        ) {
                        
                        app_state.room_settings.map(room => {
                            let selected = false;
                            compRef.current.columns.map(item => {
                                if (item.room_code && room.room_code === item.room_code) {
                                    selected = true;
                                }
                            })
                            if (!app_state.roomtype_pricing_overrides[stay_date][room.room_code]) {
                                app_state.roomtype_pricing_overrides[stay_date][room.room_code] = {}
                            }
                            if (room.is_base === false && true === selected) {
                                if (!app_state.roomtype_pricing_overrides[stay_date][room.room_code]) {
                                    app_state.roomtype_pricing_overrides[stay_date][room.room_code] = {}
                                }
                                let currentRoomData = app_state.roomtype_pricing_overrides[stay_date][room.room_code];
                                let currentBaseRoomData = app_state.roomtype_pricing_overrides[stay_date][baseRoomType.room_code];
                                if (checked === true) {
                                    const updatedRate = dayData[room.room_code]["SellRate"];
                                    app_state.roomtype_pricing_overrides[stay_date][room.room_code]["SellRate"] = updatedRate;
                                    app_state.roomtype_pricing_overrides[stay_date][room.room_code]["curr_diff_arrow_right"] = false;
                                    app_state.roomtype_pricing_overrides[stay_date][room.room_code]["curr_diff_arrow_left"] = true;
                                    app_state.roomtype_pricing_overrides[stay_date][room.room_code]["current_differential"] = updatedRate - app_state.roomtype_pricing_overrides[stay_date][baseRoomType.room_code]["SellRate"]
                                    app_state.roomtype_pricing_overrides[stay_date][baseRoomType.room_code].lodgiqRateDouble = false;
                                } else {
                                    // let currentBaseRate = (currentBaseRoomData) ? currentBaseRoomData.SellRate: dayData[baseRoomType.room_code]["SellRate"];
                                    //delete app_state.roomtype_pricing_overrides[stay_date][room.room_code];
                                    let current_diff = parseInt(dayData[room.room_code].SellRate) - parseInt(dayData[baseRoomType.room_code].SellRate);
                                    const updatedRate = app_state.roomtype_pricing_overrides[stay_date][baseRoomType.room_code].SellRate + current_diff;
                                    app_state.roomtype_pricing_overrides[stay_date][room.room_code]["SellRate"] = updatedRate;
                                    app_state.roomtype_pricing_overrides[stay_date][room.room_code]["curr_diff_arrow_right"] = true;
                                    app_state.roomtype_pricing_overrides[stay_date][room.room_code]["curr_diff_arrow_left"] = false;
                                    app_state.roomtype_pricing_overrides[stay_date][room.room_code]["current_differential"]=current_diff;
                                    // if(selectColumAll["double"] = true){
                                    //     app_state.roomtype_pricing_overrides[stay_date][baseRoomType.room_code].lodgiqRateDouble =true
                                        
                                    // }
                                    // if(app_state.roomtype_pricing_overrides[stay_date][baseRoomType.room_code].lodgiqRateDouble){
                                    //     app_state.roomtype_pricing_overrides[stay_date][room.room_code]["lodgiq_diff_checked"] = true;
                                    //     app_state.roomtype_pricing_overrides[stay_date][room.room_code]["curr_diff_arrow_right"] = true;
                                    // } 
                                    
                                }
                            }
                        })
                        
                    } else {
                        app_state.roomtype_pricing_overrides[stay_date][baseRoomType.room_code] = {
                            SellRate: dayData[baseRoomType.room_code].SellRate
                        }
                    }
                    app_state.roomtype_pricing_overrides[stay_date][baseRoomType.room_code]["baseOnlyUpdate"] = checked;

                }
                // if(checked){


                // }
                // if (checked === false) {
                //     app_state.roomtype_pricing_overrides = {}
                // }

            })
            
        }
        dispatch(updateAppState("roomtype_updates", {
            app_state: { roomtype_pricing_overrides:{...app_state.roomtype_pricing_overrides} }
        }));
        //setupRoomTypeInputs();
        //setupPricingInputs();
    }
    const onDragStartHandler = (columParam) => {
        return (event) => {
            // event.preventDefault();
            event.dataTransfer.setData("columnMeta", JSON.stringify(columParam));
            compRef.current.itemDragged = deepCopy(columParam);
        }
    };


    const mainColumnOnDropHandler = (columParam) => {
        return (event) => {
            event.preventDefault();
            let columnMeta = JSON.parse(event.dataTransfer.getData("columnMeta"));
            const draggedCellOrder = columnMeta.order;
            const draggedCellOrderIndex = compRef.current.columns.findIndex(col => col.order === draggedCellOrder);
            const droppedCellOrder = columParam.order;
            const droppedCellOrderIndex = compRef.current.columns.findIndex(col => col.order === droppedCellOrder);
            compRef.current.itemDroppedOn = columParam;

            compRef.current.columns.map((mainCol, i) => {
                //moving to left
                if (draggedCellOrder > droppedCellOrder && i <= draggedCellOrderIndex) {
                    // debugger;
                    if (draggedCellOrderIndex === i) {
                        mainCol.order = droppedCellOrder;
                    } else if (i <= droppedCellOrderIndex) {
                        mainCol.order = mainCol.order + 1;
                    }
                }
                //moving to right
                if (draggedCellOrder < droppedCellOrder && i >= draggedCellOrderIndex) {
                    // debugger;
                    if (draggedCellOrderIndex === i) {
                        mainCol.order = droppedCellOrder;
                    } else if (i >= droppedCellOrderIndex) {
                        mainCol.order = mainCol.order - 1;
                    }
                }
            });
            compRef.current.columns = processColumnsNew(compRef.current.columns);
            // setupData();
            // let ts = Date.now();
            // setReloadGrid(ts);
            // setCompLoadStatus('loading');
        }
    };
    const dataColumnOnDropHandler = (columParam) => {
        return (event) => {
            event.preventDefault();
            let columnMeta = JSON.parse(event.dataTransfer.getData("columnMeta"));
            compRef.current.itemDroppedOn = columParam;
            // debugger;
            let { itemDragged, itemDroppedOn } = compRef.current;
            if (columnMeta.parent === columParam.parent) {
                let draggedOrder = columnMeta.order;
                let droppedOrder = columParam.order;
                let mainColumn = compRef.current.columns.filter((item) => item.id === columnMeta.parent);
                compRef.current.columns.map((mainCol, i) => {
                    if (mainCol.id === columnMeta.parent) {
                        compRef.current.columns[i].subcolumns.map((col) => {
                            if (col.value === columnMeta.value) {
                                col.order = droppedOrder;
                            }
                            if (col.value === columParam.value) {
                                col.order = draggedOrder;
                            }
                        });
                    }
                });
                let columns = processColumnsNew(compRef.current.columns);
                compRef.current.columns = columns;
                // setupData();
                let ts = Date.now();
                setCompLoadStatus('loading');
                setReloadGrid(ts);
            }
        }
    };
    const columnToggleHandler = (item) => {
        let cols = compRef.current.columns;
        let ts = Date.now();
        setCompLoadStatus('loading');
        setReloadGrid(ts);
    }
    const applyFilterHandler = (item) => {
        let ts = Date.now();
        setCompLoadStatus('loading');
        setReloadGrid(ts);
    };
    const clearFilterHandler = (item, type) => {
        // debugger;
        if (type === 'all') {
            compRef.current.columns.map((main, i) => {
                main.subcolumns.map((sub, j) => {
                    //it depends on property sorted
                    sub.filter = null;
                });
            });
        }
        setCompLoadStatus('loading');
        let ts = Date.now();
        setReloadGrid(ts);
    };

    const onOrderByChangeHandler = (item) => {
        // debugger;
        setCompLoadStatus('loading');
        let cols = compRef.current.columns;
        compRef.current.sortedColumn = item;
        compRef.current.columns.map((main) => {
            if (main.id === item.parent) {
                main.subcolumns.map((col) => {
                    if (col.value === item.value) {
                        col.sorted = item.sorted;
                    } else {
                        col.sorted = null;
                    }
                })
            } else {
                main.subcolumns.map((col) => {
                    col.sorted = null;
                });
            }

        });
        let ts = Date.now();
        setReloadGrid(ts);
        // setupData();
        // debugger;
    }
    const updateCheckedAll = (checked, sub_col_index) => {

        let daysFromAsOf = dateDiff(app_state.primary_stay_range.as_of_date, app_state.primary_stay_range.date_from);
        let fromDate = app_state.primary_stay_range.date_from;

        if (daysFromAsOf > 0) {
            fromDate = app_state.primary_stay_range.as_of_date;

        } else {
            daysFromAsOf = 0;
        }
        let date = fromDate;
        let numDays = dateDiff(app_state.primary_stay_range.date_until, fromDate) + 1;
        for (let index = 0; index < numDays; index++) {
            if (app_state.pricing_override_rates[date]) {
                app_state.pricing_override_rates[date].approved = checked;
                app_state.pricing_override_rates[date].recommend_accept = checked;
                
            } else {
                let value = data_setup[index + daysFromAsOf][sub_col_index - 1]["RecSellRate"];
                app_state.pricing_override_rates[date] = {

                   override_rate: value,
                    approved: checked,
                    recommend_accept:checked,
                };
            }
            let currentRate = data_setup[index + daysFromAsOf][sub_col_index - 1]["SellRate"];
            if (app_state.pricing_override_rates[date].override_rate === currentRate) {
                app_state.pricing_override_rates[date].approved = false;
            }
            if(checked === false){
                delete app_state.pricing_override_rates[date];
            }
            date = moment(date).add(1, 'days').format("YYYY-MM-DD");
        }

        dispatch(updateAppState("change_pricing_rates", {
            app_state: {
                pricing_override_rates: { ...app_state.pricing_override_rates }
            }
        }))
        setupData();
    }
    const setupPricingInputs = ()=>{
        if (!isRoomTypePricing) {
            let inPuts = {}
            compRef.current.data_setup.forEach(dayData => {
               
                const stay_date = dayData[0].date;
                const { columns } = compRef.current;
                inPuts[stay_date] = {...dayData[4], override_rate:""};
              
            });
            setPricingInputs(prevInputs => {
                return { ...inPuts };
            });
        }
    }
    const setupRoomTypeInputs = () => {
       
        if (isRoomTypePricing) {
            let inPuts = {}
            compRef.current.data_setup.forEach(dayData => {
                let stay_date = dayData[0].date;
                const { columns } = compRef.current;
                inPuts[stay_date] = {};
                columns.forEach((col, col_index) => {
                    if (col.room_code) {
                        inPuts[stay_date][col.room_code] = {};
                        if (app_state.roomtype_pricing_overrides[stay_date] &&
                            app_state.roomtype_pricing_overrides[stay_date][col.room_code]) {
                            inPuts[stay_date][col.room_code] = { ...dayData[col_index],
                                ...app_state.roomtype_pricing_overrides[stay_date][col.room_code] }
                        } else {
                            inPuts[stay_date][col.room_code] = { ...dayData[col_index] };
                        }

                    }
                })
            })
            console.log("setupRoomTypeInputs",inPuts )
            setRoomTypeInputs(prevInputs => {
                return { ...inPuts };
            });
        }
    }
    const setupData = () => {
        // console.log("Columns => ", columns);
       
        let columns = processColumnsNew(compRef.current.columns);
        compRef.current.columns = columns;
        let data_cols = [];
        columns.map((i) => {
            i.subcolumns.map((x) => {
                data_cols.push(x.value);
            });
        });
        compRef.current.data_cols = data_cols;
        //setDataCols(data_cols);
        let data_setup = processData(columns, displayData, {
            auth,
            app_state,
            ui_state,
        });
        let t = compRef.current.sortedColumn;

        let sortedColumn = getSortedColumn(compRef.current.columns);
        data_setup = sortData({ data: data_setup, sortBy: sortedColumn.value, parent: sortedColumn.parent, order: sortedColumn.sorted })

        compRef.current.filteredColumns = getFilteredColumns(compRef.current.columns);
        data_setup = getFilteredData({ data: data_setup, columns });

        compRef.current.data_setup = deepCopy(data_setup);
        let columnSelects={};
        app_state.room_settings.forEach(room=>{
            if(room.is_base === true){
                columnSelects["single"]=false;
                columnSelects["double"]=false;
            }else {
                columnSelects["lodgiq_diff_"+room.room_code]=false;

            }
        })
        setSelectColumAll(prev=>{
            prev =columnSelects;
            return {...prev};
        });
        // debugger;
        setPricingData(getPricingDataFormatted(app_state, true))
        setupRoomTypeInputs()
        setDataSetup(data_setup);
        setCompLoadStatus('loaded');
    };
    const renderHeader = () => {
        let columns = compRef.current.columns;
        let cols = [];
        let colheaders = [];
        let count = 0;
        let bckgClass = "grey";
        let subHeaderStickyLeft = 0;
        let avail_room_cat = ui_state.selectedRoomPricingCategory.map(item=>  item.room_class);
        let isAllCat = true;
        if(!avail_room_cat.includes("All")){
            isAllCat = false;
        }
        for (let index = 0; index < columns.length; index++) {
            const col = columns[index];
            if (!col.isSticky) {
                bckgClass = "light_blue";
            }
            // const col_len = col["subcolumns"].length;
            let colSpan = col["subcolumns"].length;
            let hiddenCols = col["subcolumns"].filter(item => item.hidden);
            colSpan = colSpan - hiddenCols.length;
            // debugger
            let primaryHSty = {};
            if (col.type == "dateColumn" || col.isSticky === true) {
                let left =col.left ? col.left : 0;
                if(isRoomTypePricing && col.type === "roomTypeColumn"){
                    left = 105; // the other configs need updates
                }
                primaryHSty = {
                    position: "sticky",
                    left: left,
                    zIndex: 5,
                };
                // colSpan = 2;
            }
            colSpan = colSpan > 1 ? colSpan : null;
            const header = (
                <PrimaryHeaderCell
                    onDrop={mainColumnOnDropHandler(col)}
                    key={index}
                    align="center"
                    colSpan={colSpan}
                    style={primaryHSty}
                    className={"primary-header-cell header_col "}
                    sx={{ fontWeight: "bold !important" }}
                    data-fill-color="606060"
                    data-f-color="ffffff"
                    data-b-r-s="thin"
                    data-a-v="middle"
                    data-b-a-c="ffffff"
                    data-a-h="center"
                    data-f-sz="16"
                >
                    {col.draggable && <div draggable={col.draggable} onDragStart={onDragStartHandler(col)} onDragOver={(e) => { e.preventDefault() }}>
                        {primaryHeaderDisplay(col, app_state, ui_state)}

                    </div>}

                    {!col.draggable && <div>
                        {primaryHeaderDisplay(col, app_state, ui_state)}

                    </div>}

                </PrimaryHeaderCell>
            )
            let subColClass = "";
            col["subcolumns"].map((item, sub_col_index) => {
                bckgClass = "";
                let sty = {};
                subColClass = item.subcol_id;
                let stickyStyInner = {}
                // sty["paddingBottom"] = "18px";
                sty["fontWeight"] = "bold";
                sty["background"] = "#3fa7cd";
                sty["fontSize"] = "19px !important";
                if (col.isSticky === true) {
                    sty["position"] = "sticky";
                    sty["zIndex"] = 2;

                    
                    if (!item.hidden) {
                       
                        if(item.left){
                            subHeaderStickyLeft += item.left;
                        } else {
                            if(isRoomTypePricing === true){
                                if(index ===0 && sub_col_index === 1){
                                    subHeaderStickyLeft +=63;
                                }else {
                                    subHeaderStickyLeft += 48; //

                                }
                            } else {
                                subHeaderStickyLeft += 72;

                            }
                        }

                    }
                    if(index ===0 && sub_col_index === 0){
                        subHeaderStickyLeft = 0;
                    }
                    
                    sty["left"] = subHeaderStickyLeft;
                    sty["background"] = "#b2b2b2";
                    //stickyStyInner["width"] = "50px";
                } else {
                    sty["zIndex"] = 1
                }
                let selectAllCheckBox = "";
                if (item.hasSelectAll) {
                    if (col.isBase !== true) {
                        selectAllCheckBox = (  <label className='checkbox-label lodgiq-diff checked'>
                            { selectColumAll["lodgiq_diff_"+col.room_code] && <CheckBoxIcon className='icon-check icon-check-single' />}
                            { !selectColumAll["lodgiq_diff_"+col.room_code] &&<CheckBoxOutlineBlankIcon className='icon-uncheck icon-check-single' />}

                           <input type="checkbox" onClick={(e) => {
                            const { checked } = e.target;
                            if(selectColumAll["baseOnlyUpdate"] === true){
                                return;
                            }
                            setSelectColumAll(prev=>{
                                prev["lodgiq_diff"+"_"+col.room_code]=checked;
                                
                
                                return {...prev};
                            })
                           
                            toggleAllColumn(checked, col, "lodgiq_diff")
                        }} />
                        </label>
                        )
                    } else {
                        if (item.subcol_id === "RecSellRate") {
                            selectAllCheckBox = (<>
                             <label className='checkbox-label single checked'>
                             { selectColumAll["single"] && <CheckBoxIcon className='icon-check icon-check-single' />}
                             { !selectColumAll["single"] &&<CheckBoxOutlineBlankIcon className='icon-uncheck icon-check-single' />}
                                <input type="checkbox" value={selectColumAll["single"]} className='single' onClick={(e) => {
                                const { checked } = e.target;
                                toggleAllColumn(checked, col, "single")
                            }} />
                            </label>
                                <label className='checkbox-label double checked'>
                                    {/* <CheckboxDoubleIcon className='icon-check icon-check-double' /> */}
                                    {/* <CheckboxDoubleIcon className='icon-uncheck icon-check-double ' /> */}
                                   {selectColumAll["double"] && <img src='assets/img/checkbox.svg' className='icon-check icon-check-double checked' />}
                                   {!selectColumAll["double"] && <img src='assets/img/uncheckbox.svg' className='icon-uncheck icon-check-double' />}

                                    <input type="checkbox" value={selectColumAll["double"]} className='double' onClick={(e) => {
                                    const { checked } = e.target;
                                    toggleAllColumn(checked, col, "double")
                                }} />
                                </label>
                            </>)
                            if(!isAllCat){
                                selectAllCheckBox="";
                            }
                        }
                        if (item.subcol_id === "baseOnlyUpdate") {
                            selectAllCheckBox = (<input type="checkbox" checked={selectColumAll["baseOnlyUpdate"]}  onClick={(e) => {
                                const { checked } = e.target;
                                toggleAllColumn(checked, col, "baseOnlyUpdate")
                            }} />)
                        }
                    }

                }
                let el = (
                    <SecondaryHeaderBlueCell
                        onDrop={dataColumnOnDropHandler(item)}
                        className={"secondary-header-cell header_col  " + bckgClass + " col_len_1  header-col-" + item.value}
                        style={sty}
                        key={count}
                        align="center"
                        data-fill-color="B2B2B2"
                        data-f-color="ffffff"
                        data-a-v="middle"
                        data-a-h="center"
                        data-f-bold="true"
                        data-f-sz="15"
                        // data-b-a-c="ffffff"
                        data-b-r-s="thin"
                        data-b-a-c="ffffff"
                        sx={{ fontSize: "13px", top: "30px" }}
                    >
                        <div className={"filter-position-r " + subColClass + " " + bckgClass} style={stickyStyInner} onDragOver={(e) => { e.preventDefault() }}>

                            <ColumnSorting
                                className={"column-sorting field-" + item.value}
                                colItem={item} sortable={item.sortable} sorted={item.sorted}
                                label={item.display} value={item.value}
                                onOrderByChange={onOrderByChangeHandler} />
                                    {item.subcol_id === "pricing_Approve" && (<input type="checkbox" checked={allChecked} onChange={(e) => {
                                        const checked = e.target.checked;
                                        setAllChecked(checked);
                                        updateCheckedAll(checked, sub_col_index);
                                    }} />)}
                            <div className="col-head-label" draggable={item.draggable} onDragStart={onDragStartHandler(item)}>
                              
                                {selectAllCheckBox}
                                {item.display}
                            </div>
                            {item.filterMenu &&
                                <div className="filter-main">
                                    <Filter filteredColumns={compRef.current.filteredColumns} columnList={compRef.current.columns} currentColumn={item}
                                        columnToggleHandler={columnToggleHandler}
                                        applyFilterHandler={applyFilterHandler}
                                        clearFilterHandler={clearFilterHandler}
                                    />
                                </div>}
                        </div>
                    </SecondaryHeaderBlueCell>
                );


                if (col.type == "dateColumn") {
                    var colClass = "date_subcolumn";
                    sty["position"] = "sticky";
                    sty["zIndex"] = "5";
                    sty["textAlign"] = "center";
                    // sty["paddingBottom"] = "18px";
                    sty["fontWeight"] = "bold";
                    // sty["paddingLeft"] = "15px";
                    // sty["paddingRight"] = "15px";
                    sty["background"] = "#b2b2b2";

                    if ((item.display).toLowerCase() === 'dba' || (item.display).toLowerCase() === 'month') {
                        sty["left"] = sub_col_index * 64;
                    }

                    if (item.hasOwnProperty("value") && (item.display).toLowerCase() === 'dow') {
                        // sty["left"] = "70px !important";
                        sty["width"] = "60px !important";
                    }
                    if (item.hasOwnProperty("value") && (item.value).toLowerCase() === 'segment' && active_tiles[0].toLowerCase() !== "pace") {
                        sty["left"] = sub_col_index * 64;
                        sty["width"] = "120px !important";
                        colClass = "date_subcolumn_segment";
                    }

                    if (item.hasOwnProperty("value") && (item.display).toLowerCase() === 'segment' && app_state.workspace_controls.scopeType === "Dba" && ui_state.active_tiles[0].toLowerCase() === "pace") {
                        sty["left"] = sub_col_index * 100;
                        sty["width"] = "120px !important";
                        colClass = "date_subcolumn_segment";
                    }
                    if ((item.display).toLowerCase() === 'segment' && app_state.workspace_controls.scopeType === "Date" && ui_state.active_tiles[0].toLowerCase() === "pace") {
                        sty["left"] = sub_col_index * 74;
                        sty["width"] = "120px !important";
                        colClass = "date_subcolumn_segment";
                    }


                    let el = (
                        <SecondaryHeaderGreyCell
                            className={"header_col  " + bckgClass + " " + colClass}
                            style={sty}
                            key={count}
                            align="left"
                            data-fill-color="3fa7cd"
                            data-f-color="ffffff"

                            data-a-v="middle"
                            data-a-h="center"
                            data-f-bold="true"
                            data-f-sz="15"
                            data-b-r-s="thin"
                            data-b-a-c="ffffff"
                            // sx={{pl:3.5,pr:13.5,pt:1}}
                            sx={{ fontSize: "13px", padding: "0px !important", top: "30px !important" }}
                        >
                            {/* <div className="filter-position-r">
                                {item.display}
                                <Filter className="filter-position-a" />
                            </div> */}
                            <div className="filter-position-r date-col" sx={{ minWidth: "80px !important" }}>
                                <div className="col-head-label">
                                    {item.display}
                                </div>
                                <div className="filter-main">
                                    <Filter />
                                </div>
                            </div>
                        </SecondaryHeaderGreyCell>
                    );
                }
                if (!item.hidden) {
                    cols.push(el);
                }
                count++;

            });
            if (!col.hidden) {
                colheaders.push(header);
            }

        }

        return (
            <TableHead className="table-head">
                <TableRow data-height="30" className="header_row top_header">{colheaders}</TableRow>
                <TableRow data-height="30" className="header_row bottom_header">{cols}</TableRow>
            </TableHead>
        );
    };
    const toggleLodgiqRecRoomType = ({ checked, stay_date, room_code, column }) => {
        let baseRate = {}, baseRoom;

        let room_settings = safeReference(propertyInfo.system_settings[0], [], ["inventory_settings", "current", "room_settings"])
        room_settings.forEach(room => {
            if (room.is_base === true) {
                baseRoom = room.room_code;
            }
        });
        // let pricingData = getPricingDataFormatted(app_state,true);
        
        pricingData.forEach(item => {
            if (item.stay_date === stay_date) {
                baseRate = item[baseRoom];
            }
        })
        if (!app_state.roomtype_pricing_overrides[stay_date]) {
            app_state.roomtype_pricing_overrides[stay_date] = {}
        }
       
        if(app_state.roomtype_pricing_overrides[stay_date][baseRoom] &&
                app_state.roomtype_pricing_overrides[stay_date][baseRoom]["baseOnlyUpdate"] === true
                ){
            return;
        }
        if (!app_state.roomtype_pricing_overrides[stay_date][room_code]) {
            app_state.roomtype_pricing_overrides[stay_date][room_code] = {}
        }
        let current_diff = column.SellRate - baseRate.SellRate ;
        if(app_state.roomtype_pricing_overrides[stay_date][room_code]["current_differential"]){
            current_diff= app_state.roomtype_pricing_overrides[stay_date][room_code]["current_differential"];
        }
        if(checked && current_diff === column.lodgiq_differential ){
            return;
        }
        app_state.roomtype_pricing_overrides[stay_date][room_code]["lodgiq_diff_checked"] = checked;
        app_state.roomtype_pricing_overrides[stay_date][room_code]["curr_diff_arrow_right"] = checked;
        if (checked) {
            app_state.roomtype_pricing_overrides[stay_date][room_code]["current_differential"] = app_state.roomtype_pricing_overrides[stay_date][room_code]["lodgiq_differential"] | column.lodgiq_differential;
            app_state.roomtype_pricing_overrides[stay_date][room_code]["SellRate"] = baseRate.SellRate + app_state.roomtype_pricing_overrides[stay_date][room_code]["current_differential"];
           
        }
        else {
            delete app_state.roomtype_pricing_overrides[stay_date][room_code];
            // app_state.roomtype_pricing_overrides[stay_date][room_code]["current_differential"] = column.current_differential;
            // app_state.roomtype_pricing_overrides[stay_date][room_code]["SellRate"] = column.SellRate;
        }
        setRoomTypeInputs(prevInputs => {
            prevInputs[stay_date][room_code] = { ...app_state.roomtype_pricing_overrides[stay_date][room_code] }
            return { ...prevInputs }
        })
        dispatch(
            updateAppState("toggleLodgiqRecRoomType", {
                app_state: {
                    roomtype_pricing_overrides: { ...app_state.roomtype_pricing_overrides },
                },
            })
        );
        setupRoomTypeInputs();
    }
    const updateInput = (value, index, col_data, subcol_id, currCol, options) => {

        const date = moment(app_state.primary_stay_range.date_from).add(index, 'days').format("YYYY-MM-DD");
        // value = value || 0;
       
        // data_setup[rowindex][sub_col_index][subcol_id]= value;
        // setDataSetup(data_setup);
       
        if(value === col_data[subcol_id]){
            return;
        }
        
        if (isRoomTypePricing === false) {

           
            if (value === "" ) {
                if(app_state.pricing_override_rates[date]){
                    app_state.pricing_override_rates[date].approved = false;
                    delete app_state.pricing_override_rates[date];
                }
                
               // app_state.pricing_override_rates[date].override_rate = col_data.RecSellRate;
            } else {
                app_state.pricing_override_rates[date] = { override_rate: value, approved: true };
            }
            if(value <= 0){
                delete app_state.pricing_override_rates[date];
            }
            if(options && options.minRate &&  options.maxRate && app_state.pricing_override_rates[date]){
                if(value< options.minRate || value> options.maxRate){
                    app_state.pricing_override_rates[date].approved = false;
                }
            }

            dispatch(updateAppState("update_pricing_rates", {
                app_state: {
                    pricing_override_rates: app_state.pricing_override_rates
                }
            }))
            setupData();
        }
        else {
            let { room_code, isBase } = currCol;

            // const  pricingData = getPricingDataFormatted(app_state,true);
            const dayData = pricingData.filter(item => item.stay_date === date)[0];
            let sameVal = true;
            if (value !== dayData[room_code][subcol_id]) {
                sameVal = false
            }
           
            const room_settings = safeReference(propertyInfo.system_settings[0], [], ["inventory_settings", "current", "room_settings"])
            const baseRoom = room_settings.filter(room => room.is_base === true)[0];
            if (!app_state.roomtype_pricing_overrides[date]) {
                app_state.roomtype_pricing_overrides[date] = {}
            }
            if (!app_state.roomtype_pricing_overrides[date][room_code]) {
                app_state.roomtype_pricing_overrides[date][room_code] = {};
            }
            if (subcol_id !== "current_differential" && value <=0){
                return;
            } else if(((dayData[room_code]["SellRate"] + value) < 0 ) ||
                ((app_state.roomtype_pricing_overrides[date][room_code]["SellRate"] + value) < 0)){
                return;
            }
            app_state.roomtype_pricing_overrides[date][room_code][subcol_id] = value
            app_state.roomtype_pricing_overrides[date][room_code]["edited"] = (sameVal) ? false : true;
            if (subcol_id === "SellRate" && isBase === false) {
                app_state.roomtype_pricing_overrides[date][room_code]["curr_diff_arrow_left"] = true;
                app_state.roomtype_pricing_overrides[date][room_code]["current_differential"] = parseInt(value) - dayData[baseRoom.room_code]["SellRate"]

            }
            if (subcol_id === "current_differential") {
                app_state.roomtype_pricing_overrides[date][room_code]["curr_diff_arrow_right"] = true;
                app_state.roomtype_pricing_overrides[date][room_code]["SellRate"] = dayData[baseRoom.room_code].SellRate + parseInt(value)
                if(app_state.roomtype_pricing_overrides[date][baseRoom.room_code]){
                    app_state.roomtype_pricing_overrides[date][room_code]["SellRate"] = app_state.roomtype_pricing_overrides[date][baseRoom.room_code].SellRate + parseInt(value)

                }
            }
           
            if (isBase === true) {
                
                const baseSellRate = app_state.roomtype_pricing_overrides[date][room_code]["SellRate"];
                let isBaseOnlyUpdate =  app_state.roomtype_pricing_overrides[date][room_code]["baseOnlyUpdate"];
                room_settings.map(room => {
                    let selected = false;
                   
                    compRef.current.columns.map(item => {
                        if (item.room_code && room.room_code === item.room_code) {
                            selected = true;
                        }
                    })
                    if (!app_state.roomtype_pricing_overrides[date][room.room_code]) {
                        app_state.roomtype_pricing_overrides[date][room.room_code] = {}
                    }
                     if(isBaseOnlyUpdate){
                        app_state.roomtype_pricing_overrides[date][room.room_code]["current_differential"] = baseSellRate - dayData[room.room_code]["SellRate"];
                        app_state.roomtype_pricing_overrides[date][room.room_code]["curr_diff_arrow_left"] = true;
                    }
                    if (selected && app_state.roomtype_pricing_overrides[date]
                         && app_state.roomtype_pricing_overrides[date][room_code]
                         && !app_state.roomtype_pricing_overrides[date][room_code]["baseOnlyUpdate"]) {
                       
                        let diff = dayData[room.room_code]["SellRate"] - dayData[baseRoom.room_code]["SellRate"]
                        if (app_state.roomtype_pricing_overrides[date][room.room_code]["current_differential"]) {
                            diff = app_state.roomtype_pricing_overrides[date][room.room_code]["current_differential"]
                        } else {
                            app_state.roomtype_pricing_overrides[date][room.room_code]["current_differential"] = diff;
                        }
                        //if (!app_state.roomtype_pricing_overrides[date][room.room_code]["edited"]) {
                            app_state.roomtype_pricing_overrides[date][room.room_code]["curr_diff_arrow_right"] = true;
                            app_state.roomtype_pricing_overrides[date][room.room_code]["SellRate"] = parseInt(baseSellRate) + parseInt(diff)
                        //}
                    }
                   
                    if (sameVal) {
                        app_state.roomtype_pricing_overrides[date][room.room_code]["SellRate"] = dayData[room.room_code]["SellRate"]
                        app_state.roomtype_pricing_overrides[date][room.room_code]["curr_diff_arrow_right"] = false;
                        app_state.roomtype_pricing_overrides[date][room.room_code]["current_differential"] = dayData[room.room_code]["SellRate"] - dayData[baseRoom.room_code]["SellRate"];
                    }
                    if(value ===0){
                        delete app_state.roomtype_pricing_overrides[date][room.room_code];
                    }
                })
                
            } 
            if(value === '' || (subcol_id !== "current_differential" && value ===0)){
                
                delete app_state.roomtype_pricing_overrides[date][room_code][subcol_id];
                setRoomTypeInputs(prevInputs => {
                    prevInputs[date][room_code][subcol_id] = { ...dayData[room_code][subcol_id] };
                    return { ...prevInputs }
                });
            } else {
                setRoomTypeInputs(prevInputs => {
                    // if(!prevInputs[date]){
                    //     prevInputs[date]={}
                    // }
                    // if(!prevInputs[date][room_code]){
                    //     prevInputs[date][room_code]={}
                    // }
                    prevInputs[date][room_code] = { ...app_state.roomtype_pricing_overrides[date][room_code] };
                    return { ...prevInputs }
                });
            }
            dispatch(updateAppState("update_roomtype_pricing", {
                app_state: {
                    roomtype_pricing_overrides: { ...app_state.roomtype_pricing_overrides }
                }
            }))
           
        }
        // let ts = Date.now();
        // setReloadGrid(ts);
        // setCompLoadStatus('loading');

    }
    const updateCheckbox = ({ checked, index, sub_col_index, subcol_id }) => {

        let date = moment(app_state.primary_stay_range.date_from).add(index, 'days').format("YYYY-MM-DD");
        if (subcol_id === "pricing_Approve") {
            if (app_state.pricing_override_rates[date]) {
                app_state.pricing_override_rates[date].approved = checked;
                app_state.pricing_override_rates[date].recommend_accept = checked;
                let minRate=0, maxRate=10000;
                let value =app_state.pricing_override_rates[date].override_rate;
                app_state.room_settings.forEach(room => {
                    if (room.is_base === true &&
                        room.hasOwnProperty("max_rate") &&
                        room.hasOwnProperty("min_rate") &&
                        room.max_rate !== 0
                    ) {
                        maxRate = room.max_rate;
                        minRate = room.min_rate;
                    }
                })
                if(value>maxRate || value<minRate){
                    app_state.pricing_override_rates[date].approved = false;
                }
            } else {
                let value = data_setup[index][sub_col_index - 1]["RecSellRate"];
                
                app_state.pricing_override_rates[date] = {
                   override_rate: value,
                   recommend_accept:checked,
                    approved: checked
                };
                
            }
            if(!checked){
               delete app_state.pricing_override_rates[date]
            }
        }
        if (subcol_id === "baseOnlyUpdate") {
            // const pricingData = getPricingDataFormatted(app_state,true);
            const room_settings = safeReference(propertyInfo.system_settings[0], [], ["inventory_settings", "current", "room_settings"])

            const dayData = pricingData.filter(item => item.stay_date === date)[0];
            if (!app_state.roomtype_pricing_overrides[date]) {
                app_state.roomtype_pricing_overrides[date] = {}
            }
            let baseRoom = room_settings.filter(item => item.is_base === true)[0];
          
            if (app_state.roomtype_pricing_overrides[date][baseRoom.room_code]) {
                let baseRoomSellRate = parseFloat(app_state.roomtype_pricing_overrides[date][baseRoom.room_code].SellRate)
               ;
                room_settings.map(room => {
                    let selected = false;
                    compRef.current.columns.map(item => {
                        if (item.room_code && room.room_code === item.room_code) {
                            selected = true;
                        }
                    })
                    if (room.is_base === false && true === selected) {

                        if (checked === false) {
                            if (!app_state.roomtype_pricing_overrides[date][room.room_code]) {
                                app_state.roomtype_pricing_overrides[date][room.room_code] = {};
                            }
                            let current_diff = parseFloat(dayData[room.room_code].SellRate) - parseFloat(dayData[baseRoom.room_code].SellRate);
                         
                            const updatedRate = baseRoomSellRate + current_diff;
                            //delete app_state.roomtype_pricing_overrides[date][room.room_code];
                            
                            app_state.roomtype_pricing_overrides[date][room.room_code]["SellRate"] = updatedRate;
                           
                            app_state.roomtype_pricing_overrides[date][room.room_code]["current_differential"] = current_diff;
                            app_state.roomtype_pricing_overrides[date][room.room_code]["curr_diff_arrow_left"] = false;
                            app_state.roomtype_pricing_overrides[date][room.room_code]["curr_diff_arrow_right"] = true;
                           
                            // if(app_state.roomtype_pricing_overrides[date][baseRoom.room_code].lodgiqRateDouble){
                            //     app_state.roomtype_pricing_overrides[date][room.room_code]["lodgiq_diff_checked"]=true;
                            // }
                        } else {
                            const updatedRate = dayData[room.room_code]["SellRate"];
                       
                            if (app_state.roomtype_pricing_overrides[date][room.room_code]) { // not sure what to do
                                app_state.roomtype_pricing_overrides[date][room.room_code]["SellRate"] = updatedRate;
                                app_state.roomtype_pricing_overrides[date][room.room_code]["current_differential"] = updatedRate - app_state.roomtype_pricing_overrides[date][baseRoom.room_code]["SellRate"];
                            }
                            
                            
                            app_state.roomtype_pricing_overrides[date][room.room_code]["lodgiq_diff_checked"]=false
                            app_state.roomtype_pricing_overrides[date][room.room_code]["curr_diff_arrow_right"] = false;
                            app_state.roomtype_pricing_overrides[date][room.room_code]["curr_diff_arrow_left"] = true;

                        }
                    }
                })
            } else {
                app_state.roomtype_pricing_overrides[date][baseRoom.room_code] = {
                    SellRate: dayData[baseRoom.room_code].SellRate
                }
            }
            app_state.roomtype_pricing_overrides[date][baseRoom.room_code]["baseOnlyUpdate"] = checked;
            // if(!checked){
            //     delete app_state.roomtype_pricing_overrides[date][baseRoom.room_code];
            // }
            // setupRoomTypeInputs(prevInputs=>{
            //     room_settings.map(room=>{
            //         prevInputs[date][room.room_code]= app_state.roomtype_pricing_overrides[date][room.room_code]
            //     })

            //     return {...prevInputs};
            // })
        }
        //setReloadGrid(moment())
        dispatch(updateAppState("change_pricing_rates", {
            app_state: {
                pricing_override_rates: { ...app_state.pricing_override_rates },
                roomtype_pricing_overrides: { ...app_state.roomtype_pricing_overrides }
            }
        }))
        setupData();
    }
    const toggleLodgiqRecBase = ({ checked, type, stay_date, room_code, column }) => {
        // let pricingData = getPricingDataFormatted(app_state,true);
        let dateInventory = {};

        pricingData.forEach(item => {
            if (item.stay_date === stay_date) {
                dateInventory = { ...item };
            }
        })
        const { RecSellRate, SellRate } = column;
        if (!app_state.roomtype_pricing_overrides[stay_date]) {
            app_state.roomtype_pricing_overrides[stay_date] = {}
        }
        if (!app_state.roomtype_pricing_overrides[stay_date][room_code]) {
            app_state.roomtype_pricing_overrides[stay_date][room_code] = {}
        }
        app_state.roomtype_pricing_overrides[stay_date][room_code]["lodgiqRate" + type] = checked;
        let room_settings = safeReference(propertyInfo.system_settings[0], [], ["inventory_settings", "current", "room_settings"])
        let baseRoomType=room_code;
        room_settings.map(room => {
            if (!dateInventory[room.room_code]) {
                dateInventory[room.room_code] = {
                    "SellRate": 0,
                    "lodgiq_differential": 0,
                    "current_differential": 0,
                }
            }
            if (room.room_code === room_code) {

                app_state.roomtype_pricing_overrides[stay_date][room.room_code]["SellRate"] = RecSellRate;
                if (!checked) {
                    app_state.roomtype_pricing_overrides[stay_date][room.room_code]["SellRate"] = dateInventory[room.room_code]["SellRate"];
                }
            }
        })
        let baseOnlyUpdate = app_state.roomtype_pricing_overrides[stay_date][baseRoomType]["baseOnlyUpdate"];
        room_settings.map(room => {
            if (room.room_code !== room_code) {
                if (!app_state.roomtype_pricing_overrides[stay_date][room.room_code]) {
                    app_state.roomtype_pricing_overrides[stay_date][room.room_code] = {}
                }
                app_state.roomtype_pricing_overrides[stay_date][room.room_code]["curr_diff_arrow_right"] = true;
                app_state.roomtype_pricing_overrides[stay_date][room.room_code]["curr_diff_arrow_left"] = false;
                if (type === "Double") {
                    app_state.roomtype_pricing_overrides[stay_date][room.room_code]["lodgiq_diff_checked"] = true;
                    app_state.roomtype_pricing_overrides[stay_date][room.room_code]["current_differential"] = dateInventory[room.room_code]["lodgiq_differential"];
                    app_state.roomtype_pricing_overrides[stay_date][room.room_code]["SellRate"] = RecSellRate + app_state.roomtype_pricing_overrides[stay_date][room.room_code]["current_differential"];
                    if (!checked) {
                        delete app_state.roomtype_pricing_overrides[stay_date][room.room_code]
                        delete app_state.roomtype_pricing_overrides[stay_date][room_code]
                        // app_state.roomtype_pricing_overrides[stay_date][room.room_code]["curr_diff_arrow_right"]=false;
                        // app_state.roomtype_pricing_overrides[stay_date][room.room_code]["lodgiq_diff_checked"]=false;
                        // app_state.roomtype_pricing_overrides[stay_date][room.room_code]["current_differential"]=dateInventory[room.room_code]["SellRate"] - SellRate;

                    }
                }
                if (type === "Single") {
                    if(baseOnlyUpdate){
                        app_state.roomtype_pricing_overrides[stay_date][room.room_code]["curr_diff_arrow_left"] = true;
                        app_state.roomtype_pricing_overrides[stay_date][room.room_code]["curr_diff_arrow_right"] = false;
                        app_state.roomtype_pricing_overrides[stay_date][room.room_code]["current_differential"] = dateInventory[room.room_code]["SellRate"] - RecSellRate ;
                    
                    }else {
                        app_state.roomtype_pricing_overrides[stay_date][room.room_code]["SellRate"] = RecSellRate + (dateInventory[room.room_code]["SellRate"] - SellRate);
                    }
                    if (!checked) {
                        delete app_state.roomtype_pricing_overrides[stay_date][room.room_code]
                        delete app_state.roomtype_pricing_overrides[stay_date][room_code]
                        // app_state.roomtype_pricing_overrides[stay_date][room.room_code]["SellRate"]= dateInventory[room.room_code]["SellRate"];
                        // app_state.roomtype_pricing_overrides[stay_date][room.room_code]["curr_diff_arrow_right"]=false;
                    }
                }
            }
        })
        setupRoomTypeInputs()
        // setRoomTypeInputs(prevInputs=>{
        //     return {...prevInputs,
        //         ...app_state.roomtype_pricing_overrides
        //         }
        // })
        dispatch(updateAppState("change_pricing_rates", {
            app_state: {
                roomtype_pricing_overrides: { ...app_state.roomtype_pricing_overrides }
            }
        }))
    }
    const revertData = (type, stay_date, room_code, isBase) => {
        if (type === "day") {
            delete app_state.roomtype_pricing_overrides[stay_date];

        }
        if (type === "single") {
            delete app_state.roomtype_pricing_overrides[stay_date][room_code];
            if (isBase === true) {
                delete app_state.roomtype_pricing_overrides[stay_date];
            }
        }
        dispatch(updateAppState("change_pricing_rates", {
            app_state: {
                roomtype_pricing_overrides: { ...app_state.roomtype_pricing_overrides }
            }
        }))
        setupRoomTypeInputs();
        setAnchorEl(null)
        setIsPopoverOpen(false);
        setPopOverData({
            anchorEl: null,
            stay_date: "",
            sellRate: "",
            otb: {},
            forecast: {},
            room_code: "",
            isBase: "",
        })
    }
    const renderBody = (params) => {
        const data_setup = compRef.current.data_setup;
        const rows = [];
        let {as_of_date} = primary_stay_range;
        let size = rowsPerPage + (page * rowsPerPage);
        if (compRef.current.data_setup.length < size || !isRoomTypePricing) {
            size = compRef.current.data_setup.length
        }
        
        let colCount = 0;
        let totalArr = [];
        let rowsCount = 0;
        let avail_room_cat = ui_state.selectedRoomPricingCategory.map(item=>  item.room_class);
        let isAllCat = true;
        if(!avail_room_cat.includes("All")){
            isAllCat = false;
        }
        console.log('render body')
        let columnsCounts = 0;
        let baseRoom = app_state.room_settings.filter(item=> item.is_base === true)[0]
        if (size > 0) {
            for (let index = 0 + (rowsPerPage * page); index < size; index++) {

                let columnTotal = 0;

                const data_columns = data_setup[index];
                const cols = [];
                const reportCols = [];
                let count = 0;
                totalArr[index] = 0;
                colCount = 0;
                var totalRow = "";
                var totalCol = "";
                let rowDate;
                let leftSticky = 72;
                if(isRoomTypePricing==true){
                    leftSticky=63;
                }
                let stay_date = data_columns[0].date;
                if(moment(stay_date).diff(as_of_date, 'days') <0 && isRoomTypePricing === true){
                    continue;
                }
             
                let dateData = deepCopy(data_setup[index]);
                data_columns.map((column, col_index) => {
                    let subCols = compRef.current.columns[col_index].subcolumns.filter(col => !col.hidden);
                    // let subCols = Object.keys(column); debugger;
                    let currColumn = compRef.current.columns[col_index];
                    let cellClass = "data-cell";
                    subCols.map((sublcol, sub_col_index) => {
                        let type = sublcol.type || "";

                        let { subcol_id } = sublcol;
                        let subcol_key = subcol_id.replace(currColumn.id + "_", "");
                        let val = column[subcol_key];


                        let txtAlignClass = (app_state.workspace_controls.segmented_view && subcol_key === 'Revenue') ? "text-align-right" : "";
                        let txtAlignClassml = (subcol_key === 'ADR' || subcol_key === 'Occ') ? "text-align-left-ml" : "";
                        let txtAlignClassRight = (subcol_key === 'Rooms') ? "" : "";

                        // if (subcol_key === 'segment' && val === "Total") {
                        //     totalRow = "total-row";
                        //     totalCol = "total-col";
                        // }
                        let sty = {};
                        if (col_index === 0) {
                            sty = { position: "sticky", zIndex: 1, textAlign: "center" };
                         
                                sty["left"] = sub_col_index * 54; // old

                                sty["left"] = sub_col_index * 72;
                                if(isRoomTypePricing == true){
                                    sty["left"] = sub_col_index * 63;
                                }
                            
                        } else {
                            if (currColumn.isSticky === true) {
                                sty = { position: "sticky", zIndex: 1, textAlign: "center" };
                                if(sublcol.left){
                                    leftSticky += sublcol.left;
                                } else {
                                    if(isRoomTypePricing == true){
                                        leftSticky+=64
                                    } else {
                                        leftSticky += 72;
                                    }

                                }
                                //if(col_index ===0)
                                sty["left"] = leftSticky;
                                
                            }
                            if (subcol_key === "Revenue") {
                                sty["color"] = "#3583B8";
                                sty["textAlign"] = "center";
                                sty["ml"] = "5px !important";
                                sty["padingLeft"] = "5px";
                            }
                        }
                        var txtAlign = { textAlign: "center" };
                        let rowsBackColor = (rowsCount % 2 === 0) ? "ebebeb" : "ffffff";
                        // var totalRow = "1";
                        let dataType = numberFormatForReport(subcol_key);
                        let reportData;
                        // if (!val)
                        let tmpVal;

                        if (val && typeof val === "number") {
                            tmpVal = val;
                        } else if (val && typeof val !== "object") {
                            tmpVal = val.replaceAll("%", "");
                        } else if (val && typeof val === "object") {
                            let resVal = (val[0]) ? Object.keys(val[0]).join(",") : "";
                            // let resValDetails = getResName(resVal,val[0]['max_los']);
                            //  console.log(resVal," ",val[0]['max_los'],"TmpVal => ",resValDetails);
                            tmpVal = resVal;
                        }

                        if (subcol_key === 'Date') {
                            reportData = (val) ? moment(val).format('YYYY/MM/DD') : '';
                            rowDate = reportData;
                        } else if (subcol_key === 'Revenue' || subcol_key === 'ADR' || subcol_key === 'Occ' || subcol_key === 'RevPAR') {
                            tmpVal = (tmpVal && isFinite(tmpVal) && !isNaN(tmpVal)) ? tmpVal : parseFloat(0.00);
                            reportData = tmpVal;//(Number.isFinite(tmpVal)) ? parseFloat(tmpVal).toFixed(2) : parseFloat(0.00).toFixed(2);
                        } else {
                            reportData = val;
                        }
                        let brdRight;
                        let dowStyle
                        if (subcol_key === "dow") {
                            dowStyle = "dow-style";
                        }
                        if (subcol_key === "compdate") {
                            reportData = moment(val).format('MMM DD YYYY,  ddd');
                            dowStyle = "dow-style-comp-date";
                        }
                        if (subcol_key === "segment") {
                            dowStyle = "segment-td-style";
                        }

                        // reportCols.push(
                        //     <TableCell
                        //         data-t={dataType.type}
                        //         // data-num-fmt={dataType.numfmt}
                        //         data-fill-color={rowsBackColor}
                        //         data-b-r-s="thin"
                        //         data-b-a-c="ffffff"
                        //         data-f-sz="12"
                        //         data-a-h="center"
                        //         subcol_key={count}
                        //         className={cellClass + " " + totalCol + " data-cell-" + subcol_key}
                        //         style={sty}>{reportData}
                        //     </TableCell>
                        // );
                        let displayData = "";
                        let disabledInput = false;

                        if (moment(stay_date).diff(app_state.as_of_date, 'days') < 0) {
                            disabledInput = true;
                        }

                        if (type === "input") {
                            if (subcol_id === "pricing_Override") {
                                let inputVal = "";
                                if (app_state.pricing_override_rates[stay_date] && app_state.pricing_override_rates[stay_date]["override_rate"]
                                    && !app_state.pricing_override_rates[stay_date].recommend_accept) {
                                    inputVal = getCurrency() + app_state.pricing_override_rates[stay_date]["override_rate"];
                                }
                                
                                let text = "Please setup min/max rate in property settings → inventory, before proceeding with rate push";
                                let maxRate=10000, minRate =0;
                                if (isRoomTypePricing === false) {
                                    let room_settings = safeReference(propertyInfo.system_settings[0], [], ["inventory_settings", "current", "room_settings"])

                                    room_settings.forEach(room => {
                                        if (room.is_base === true &&
                                            room.hasOwnProperty("max_rate") &&
                                            room.hasOwnProperty("min_rate") &&
                                            room.max_rate !== 0
                                        ) {
                                            maxRate = room.max_rate;
                                            minRate = room.min_rate;
                                            text = `Please enter a value\n between ${minRate} and ${maxRate}`
                                        }
                                    })
                                }
                                if(disabledInput){
                                    text = ""
                                }
                               
                               
                                displayData = (
                                    <div autoFocus className="wrapper-tooltip">
                                        <InputEl disabledInput={disabledInput}
                                        
                                        currency={getCurrency()}
                                        inputVal={inputVal} 
                                         subcol_id={subcol_id} 
                                         isRoomTypePricing={isRoomTypePricing}
                                         handleKeyDown={(e)=>{
                                            let {key} = e;
                                            if(key === 'Tab'){
                                                e.preventDefault();
                                                const clickEvent = new MouseEvent("click", {
                                                    "view": window,
                                                    "bubbles": true,
                                                    "cancelable": false
                                                });
                                                let scrollQuery = ".table-scrollable.houselevel";
                                                let newStayDate = stay_date;
                                                let rowIndex = index;
                                                let shiftY = 0;
                                                if(e.shiftKey === true   ){
                                                    shiftY-=30
                                                    newStayDate = moment(stay_date).add(-1, 'days').format("YYYY-MM-DD");
                                                }
                                                if(e.shiftKey === false ){
                                                    if(rowIndex>12){
                                                        shiftY+=25

                                                    }
                                                    
                                                    newStayDate = moment(stay_date).add(1, 'days').format("YYYY-MM-DD");
                                                }
                                                let querySelector = `[stay_date="${newStayDate}"][subcol_key="11"] .dataView`;
                                                console.log(querySelector);
                                                document.querySelector(querySelector).dispatchEvent(clickEvent);
                                             
                                               
                                                document.querySelector(scrollQuery).scrollBy(0,shiftY)
                                            }
                                        }}
                                        onBlur={(value) => {
                                            updateInput(value, index, column, subcol_id, currColumn, {minRate, maxRate})
                                        }} />
                                        {text !== "" && <div className="tooltip">{text}</div>}
                                    </div>
                                )
                            } else {
                                let inputVal = "";

                                if (roomTypeInputs[stay_date] &&
                                    roomTypeInputs[stay_date][currColumn.room_code]
                                    && roomTypeInputs[stay_date][currColumn.room_code][subcol_id]) {
                                    inputVal = roomTypeInputs[stay_date][currColumn.room_code][subcol_id];
                                } else {
                                    // inputVal = round(column[subcol_id])
                                }
                                let arrow = "";
                                if (subcol_key === "current_differential") {
                                    if (roomTypeInputs[stay_date][currColumn.room_code]["curr_diff_arrow_right"] === true) {
                                        arrow = (<EastIcon className={"curr-diff-arrow " + currColumn.room_code} />);
                                    } else if (roomTypeInputs[stay_date][currColumn.room_code]["curr_diff_arrow_left"] === true) {
                                        arrow = (<WestIcon className={"curr-diff-arrow " + currColumn.room_code} />);
                                    }
                                    if(parseInt(inputVal) <0){
                                        inputVal= "("+getCurrency()+Math.abs(inputVal)+")"
                                    }
                                    else {
                                        if(inputVal===""){
                                            inputVal = 0;
                                        }
                                        inputVal=getCurrency()+inputVal;
                                    }
                                } 
                                if(subcol_key === "SellRate"){
                                    
                                    inputVal = inputVal.toString().replace(getCurrency(), "")
                                    if(inputVal === ""){
                                        inputVal = 0;
                                    }
                                    inputVal= getCurrency()+inputVal;
                                }
                                let otb = {
                                    occ: data_setup[index][1].Occ + "%",
                                    adr: round(data_setup[index][1].ADR)
                                }
                                let forecast = {
                                    occ: data_setup[index][2].Occ + "%",
                                    adr: round(data_setup[index][2].ADR)
                                }
                                let sellRate = roomTypeInputs[stay_date][currColumn.room_code]["SellRate"];
                                let popover = (<>
                                    {/* <InfoOutlinedIcon className="info-icon" onClick={(event) => {
                                            
                                    setPopOverData({
                                        anchorEl:event.currentTarget,
                                        stay_date,
                                        sellRate,
                                        otb,
                                        forecast,
                                        room_code:currColumn.room_code,
                                        isBase:false,
                                    })
                                    setAnchorEl(event.currentTarget);
                                    setIsPopoverOpen(true)
                                }}/>   */}
                                    <InfoPopover
                                        setPopOverData={setPopOverData}
                                        anchorEl={anchorEl}
                                        popOverData={{
                                            // anchorEl:event.currentTarget,
                                            stay_date,
                                            sellRate,
                                            otb,
                                            forecast,
                                            room_code: currColumn.room_code,
                                            isBase: false,
                                            edited: roomTypeInputs[stay_date][currColumn.room_code]["edited"]
                                        }}
                                        isOpen={isPopoverOpen}

                                        setAnchorEl={() => {
                                            setAnchorEl(null)
                                            setIsPopoverOpen(false)
                                            setPopOverData({
                                                anchorEl: null,
                                                stay_date: "",
                                                sellRate: "",
                                                otb: {},
                                                forecast: {},
                                                room_code: "",
                                                isBase: "",
                                            })
                                        }}
                                        revertData={revertData}
                                    /> </>);
                             
                                displayData = (< ><InputEl
                                    currency={getCurrency()}
                                    inputVal={inputVal}
                                    autofocus
                                    handleKeyDown={e=>{
                                        handleKeyDown(e,stay_date, subcolCount)
                                    }}
                                    onBlur={(value,resetInputText) => {
                                        
                                        if (value!== '') {
                                           

                                            if(value.toString().includes("(")){
                                                value = value.replace("(","");
                                                value = value.replace(")","");
                                                value = parseInt(value)*-1;
                                            }
                                            
                                            updateInput(value, index, column, subcol_id, currColumn)
                                            resetInputText();
                                        }
                                        
                                    }}
                                    edited={roomTypeInputs[stay_date][currColumn.room_code]["edited"]}
                                    disabledInput={disabledInput}
                                    handleChange={(e) => {
                                        let value = e.target.value;
                                        setRoomTypeInputs(prevInputs => {
                                            prevInputs[stay_date][currColumn.room_code][subcol_id] = value;
                                            return { ...prevInputs };
                                        })
                                    }}
                                    subcol_id={subcol_id}
                                />{
                                        subcol_id === "SellRate" && (<>
                                            {/* <InfoOutlinedIcon className="info-icon" onClick={(event) => {
                                            setAnchorEl(event.currentTarget);
                                            setPopOverData({
                                                anchorEl:event.currentTarget,
                                                stay_date,
                                                sellRate,
                                                otb,
                                                forecast,
                                                room_code:currColumn.room_code,
                                                isBase:false,
                                                edited:roomTypeInputs[stay_date][currColumn.room_code]["edited"]
                                            })
                                        }}/>  */}
                                            {popover}
                                        </>)
                                    }
                                    {arrow}</>)

                            }
                        }
                        else if (type === "checkbox") {
                            if (subcol_id === "pricing_Approve") {
                                let pricingApproved = false;
                                let override_rate ="";
                                if (app_state.pricing_override_rates[stay_date]) {
                                    pricingApproved = app_state.pricing_override_rates[stay_date].approved;
                                   
                                }
                                let approve_pricing_disabled = disabledInput;
                                if(column.SellRate === column.RecSellRate){
                                    approve_pricing_disabled = true
                                    if(pricingApproved){
                                        approve_pricing_disabled=false;
                                    }
                                }
                                displayData = (<input type="checkbox"  checked={pricingApproved} disabled={approve_pricing_disabled} onChange={(e) => {
                                    const checked = e.target.checked;
                                    updateCheckbox({ checked, index, sub_col_index, subcol_id })
                                }} />);
                            }
                            if (subcol_id === "baseOnlyUpdate") {
                                let baseOnly = false;
                                if (roomTypeInputs[stay_date] &&
                                    roomTypeInputs[stay_date][currColumn.room_code]) {
                                    baseOnly = roomTypeInputs[stay_date][currColumn.room_code]["baseOnlyUpdate"];
                                }
                                displayData=(<input autofocus type="checkbox" className="dataView checkbox" onChange={(e)=>{
                                    const checked = e.target.checked;
                                    updateCheckbox({checked,index, sub_col_index, subcol_id })
                                }}  checked={baseOnly}
                                options={{ index, sub_col_index, subcol_id }}
                                disabled={disabledInput} classes={subcol_id}/>)
                                // displayData = (<CheckboxEl method={updateCheckbox}
                                //     isChecked={baseOnly}
                                //     options={}
                                //     disabledInput={disabledInput} classes={subcol_id} />);
                            }

                        } else if (currColumn.isBase === true && subcol_key === "RecSellRate") {
                            let lodgiqRateDouble = false, lodgiqRateSingle = false;
                            let singeDisabled = disabledInput;
                            let doubleDisabled = disabledInput;
                            if (app_state.roomtype_pricing_overrides[stay_date] &&
                                app_state.roomtype_pricing_overrides[stay_date][currColumn.room_code]) {

                                lodgiqRateSingle = app_state.roomtype_pricing_overrides[stay_date][currColumn.room_code]["lodgiqRateSingle"];
                                lodgiqRateDouble = app_state.roomtype_pricing_overrides[stay_date][currColumn.room_code]["lodgiqRateDouble"];
                                if(app_state.roomtype_pricing_overrides[stay_date][currColumn.room_code]["baseOnlyUpdate"]){
                                    doubleDisabled = true;
                                }
                            }
                            let checkboxStyleSingle = (lodgiqRateSingle == true ) ? "showCheckbox" : "";
                            let checkboxStyleDouble = (lodgiqRateDouble == true) ? "showCheckbox" : "";
                           
                            if(lodgiqRateDouble === true){
                                lodgiqRateSingle = false;
                            }
                            displayData = (<span className="baseRecomendation">{displayDataFormatNew(subcol_id, column, type, subcol_key)}
                                <CheckboxEl disabledInput={singeDisabled}
                                    isChecked={lodgiqRateSingle}
                                    options={{ type: "Single", stay_date, room_code: currColumn.room_code, column }}
                                    subcol_id={subcol_id} method={toggleLodgiqRecBase}
                                    classes={"singleCheckbox lodigRecCheckbox " + checkboxStyleSingle} />
                                {!doubleDisabled && <CheckboxEl disabledInput={doubleDisabled}
                                    isChecked={lodgiqRateDouble}
                                    options={{ type: "Double", stay_date, room_code: currColumn.room_code, column }}
                                    subcol_id={subcol_id} method={toggleLodgiqRecBase}
                                    classes={"doubleCheckbox lodigRecCheckbox " + checkboxStyleDouble} />}
                            </span>)

                            if(!isAllCat){
                                displayData=(<span className="baseRecomendation">{displayDataFormatNew(subcol_id, column, type, subcol_key)}</span>)                     
                            }

                        } else if (currColumn.isBase === true && subcol_key === "SellRate" && isRoomTypePricing === true) {
                            let baseSellRate = column[subcol_id]//displayDataFormatNew(subcol_id, column, type, subcol_key);
                            let otb = {
                                occ: data_setup[index][1].Occ + "%",
                                adr: round(data_setup[index][1].ADR)
                            }
                            let forecast = {
                                occ: data_setup[index][2].Occ + "%",
                                adr: round(data_setup[index][2].ADR)
                            }
                            if (roomTypeInputs[stay_date] &&
                                roomTypeInputs[stay_date][currColumn.room_code]
                                && roomTypeInputs[stay_date][currColumn.room_code].SellRate) {
                                baseSellRate = roomTypeInputs[stay_date][currColumn.room_code]["SellRate"];
                               
                            }
                           
                            if(!isNaN(baseSellRate)){
                                baseSellRate = getCurrency()+ roomTypeInputs[stay_date][currColumn.room_code]["SellRate"];
                            }
                            let inputEl = (<InputEl
                                currency={getCurrency()}
                                inputVal={baseSellRate}
                                handleKeyDown={e=>{
                                    handleKeyDown(e,stay_date, subcolCount)
                                }}
                                onBlur={(value,resetInputText) => {
                                  
                                    if (value !== '') {
                                        // value=parseInt(value.replace(getCurrency(), ""));
                                        updateInput(value, index, column, subcol_id, currColumn)
                                        resetInputText()
                                    }
                                }}
                                edited={roomTypeInputs[stay_date][currColumn.room_code]["edited"]}
                                disabledInput={disabledInput}
                                subcol_id={subcol_id}
                                handleChange={(e) => {
                                    let value = e.target.value;
                                    // setInputVal(value)
                                    value=value.toString().replace(getCurrency(), "");
                                    if (value !== '') {
                                        value = parseInt(value);
                                        setRoomTypeInputs(prevInputs => {
                                            prevInputs[stay_date][currColumn.room_code][subcol_id] = value;
                                            return { ...prevInputs };
                                        })
                                    } else {
                                        setRoomTypeInputs(prevInputs => {
                                            prevInputs[stay_date][currColumn.room_code][subcol_id] =0 ;
                                            return { ...prevInputs };
                                        })
                                    }

                                    
                                }}
                            />)

                            if(!isAllCat){
                                inputEl=(<span className="data-view">{getCurrency()}{roomTypeInputs[stay_date][currColumn.room_code][subcol_id]}</span>)       

                            }else {
                                
                            }
                            displayData = (<>
                                {inputEl}
                                
                                <InfoPopover
                                    anchorEl={anchorEl}
                                    setPopOverData={setPopOverData}
                                    isOpen={isPopoverOpen}
                                    setAnchorEl={() => {
                                        setAnchorEl(null)
                                        setIsPopoverOpen(false);
                                        setPopOverData({
                                            anchorEl: null,
                                            stay_date: "",
                                            sellRate: "",
                                            otb: {},
                                            forecast: {},
                                            room_code: "",
                                            isBase: "",
                                        })
                                    }}
                                    revertData={revertData}

                                    popOverData={{
                                        // anchorEl:event.currentTarget,
                                        stay_date,
                                        sellRate: baseSellRate,
                                        otb,
                                        forecast,
                                        room_code: currColumn.room_code,
                                        isBase: true,
                                        edited: roomTypeInputs[stay_date][currColumn.room_code]["edited"]
                                    }}
                                /></>)

                        } else if (subcol_key === "lodgiq_differential") {
                            let lodgiq_diff_checked = false;
                            if (app_state.roomtype_pricing_overrides[stay_date] &&
                                app_state.roomtype_pricing_overrides[stay_date][currColumn.room_code]
                                && app_state.roomtype_pricing_overrides[stay_date][currColumn.room_code]["lodgiq_diff_checked"]
                            ) {
                                lodgiq_diff_checked = true;
                               
                            }
                            let checkboxStyle = (lodgiq_diff_checked == true) ? "showCheckbox" : "";
                            displayData = (<span>{displayDataFormatNew(subcol_id, column, type, subcol_key)}
                              <CheckboxEl isChecked={lodgiq_diff_checked}
                                    disabledInput={disabledInput}
                                    classes={"singleCheckbox lodigRecCheckbox " + checkboxStyle}
                                    method={toggleLodgiqRecRoomType}
                                    options={{ stay_date, room_code: currColumn.room_code, column }}
                                />
                            </span>)

                        }
                        else if (subcol_id === "restrictions_house") {
                            // displayData =( <RestrictionsList chipArrAll={chipArrAll} roomTypeRestdata={roomTypeRestdata} /> )
                            // displayData = (<Restrictions data={[{...column[subcol_key]}]} stay_date={column[subcol_key].stay_date} />);
                            displayData= getRestrictionsDisplay(column[subcol_key]);
                            
                        } else if(subcol_id === "pricing_ratediff"){
                            let pricing_ratediff = column["ratediff"];
                            if (app_state.pricing_override_rates[stay_date]) {
                                pricing_ratediff =  app_state.pricing_override_rates[stay_date]["override_rate"] - column["SellRate"];
                            }
                            displayData=displayDataFormatNew(subcol_id, {"ratediff":pricing_ratediff}, type, subcol_key);
                        } else {
                            displayData = displayDataFormatNew(subcol_id, column, type, subcol_key);
                        }
                        let subcolCount = count;
                        let isInputDisabled ="";
                        if(disabledInput){
                            isInputDisabled ="disabled-input";
                        }
                        if(!isRoomTypePricing && subcol_key === "SellRate" ){
                            cellClass+=" current-rate"
                        }
                        let tCell = (<TableCell autofocus key={colCount} data-fill-color={rowsBackColor} data-b-r-s="thin" data-b-a-c="ffffff"
                            data-t="s" data-exclude="true" data-f-sz="5" data-a-h="center" 
                            stay_date={stay_date}
                            subcol_key={count}
                            className={cellClass + " data-cell-" + subcol_key + " " + isInputDisabled+" "+
                            totalCol + " " + txtAlignClass + " " + txtAlignClassml + " " + txtAlignClassRight}
                            style={sty} >


                            {displayData}

                        </TableCell>)
                        cols.push(tCell);
                        colCount++;

                        if (typeof val === 'string') {
                            //totalArr[count] = parseFloat(totalArr[count] + 0);
                        } else {
                            if (count === 4) {
                                if (totalArr[count] === 'undefind') {
                                    totalArr[count] = 0;
                                }
                            }
                            totalArr[count] += val;// parseFloat(totalArr[count] + val);
                        }
                        count += 1;
                    });

                    columnsCounts++;
                });
                rows.push(<StyledTableRow data-exclude="true" key={rowsCount} data-height="20" className={"table-row"}>{cols}</StyledTableRow>);
                // rows.push(<StyledTableRow data-height="20" sx={{ display: "none" }}>{reportCols}</StyledTableRow>);

                rowsCount++;
            }
        } else {
            rows.push(<StyledTableRow className="">
                <TableCell colSpan={5}
                    sx={{ height: "auto", borderRight: "0px solid #ddd", background: "#fff", textAlign: "center", borderBottom: "0px solid" }}>
                    <Spinner />
                </TableCell></StyledTableRow>);
        }

        if (rowsCount <= 12) {
            rows.push(<StyledTableRow className="">
                <TableCell
                    sx={{ height: "auto", borderRight: "0px solid #ddd", background: "#fff", borderBottom: "0px solid" }}>
                </TableCell></StyledTableRow>);
        }

        let footerCols = [];
        var colSpanTotal = 2;
        let columns = processColumnsNew(compRef.current.columns);
        // if (data_setup.length > 0) {
        //     columns = data_setup[0];
        // }
        let count = 0;
        // console.log("test=> ",columns);


        return rows;
        //full table body is array of rows
        return <TableBody className="table-body">{rows}</TableBody>;
    };


    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };
    ////////
    /////////


    /////////
    const getCurrency = () => {
        return ui_state.currency_symbol;
    }

    const displayDataFormatNew = (subcol_id, column, type, subcol_key) => {
        let returnElement = "";
        let val = column[subcol_key];

        if (type === "date") {
            returnElement = moment(val).format("MMM DD");
        }
        if (type === "dow") {
            returnElement = val;
        }


        if (type === "percent") {
            returnElement = val.toFixed(2) + "%";
        }
        if (subcol_key !== "rooms" && subcol_key !== "dba" && subcol_key !== "revenue") {
            val = Number.isInteger(val) ? val.toFixed(2) : val;
        }

        if (type === "integer") {
            returnElement = parseInt(val) | "";
        }
        if (type === "diff" && val !== '-') {
            let symbol = "", colorClass = "";
            if (val < 0) {
                val *= -1;
                symbol = "-"
                colorClass = "negative"
            } else if (val > 0) {
                symbol = "+"
                colorClass = "positive"
            }
            returnElement = (<span className={colorClass}>{symbol + getCurrency() + parseInt(val)}</span>)
        }
        if (type === "string") {
            returnElement = val.toString();
        }

        if (subcol_key === 'segment') {
            returnElement = truncate(val, 17);
        }
        if (type === 'compdate') {
            // let compVal =  dateFormat(val, "MMM DD, YYYY ddd");
            returnElement = moment(val).format("MMM DD YYYY,  ddd");
            // debugger;
        }
        if (type === "currency") {

            if (val === '-') {
                returnElement = getCurrency() + val;
            } else {
                val = Number(val);
                if(subcol_key == "RecSellRate" || 
                subcol_key == "SellRate" || 
                subcol_key=="lodgiq_differential" ){
                    val = parseInt(val);
                }
                if (val >= 0) {
                    
                    if(subcol_key !== "SellRate" ||
                    subcol_key !== "RecSellRate" || 
                    subcol_key!=="lodgiq_differential" ){
                        val = val.toFixed(2);
                        val = round(val);
                    }
                    returnElement = getCurrency() + val;
                } else {
                    if (Number.isFinite(val)) {
                        val = round(val); //parseFloat(val).toFixed(2);
                        val = "(" +  getCurrency() +val.toString().replaceAll("-", "") + ")";
                        returnElement=val;
                    } else {
                        val = 0
                        returnElement = getCurrency() + val;
                    }
                    
                }
                

            }
        }


        return returnElement;

    }


    const handleKeyDown = (e,stay_date, subcolCount)=>{
        
        let {key} = e;
        let querySelector="";
        let newStayDate = stay_date;
        let newSubColKey = subcolCount;
        let innerClass =".dataView"
        if(key === "ArrowRight"){
            newSubColKey+=1;
            querySelector = `[stay_date="${newStayDate}"][subcol_key="${newSubColKey}"] ${innerClass}`;
           

            if(!document.querySelector(querySelector)){
                newSubColKey+=2;
            }
            if(document.querySelector(querySelector) && 
            document.querySelector(querySelector).classList.contains("checkbox")){
                newSubColKey+=3;
            }
        }
        if(key === "ArrowLeft"){
            newSubColKey-=1;
            querySelector = `[stay_date="${newStayDate}"][subcol_key="${newSubColKey}"]  ${innerClass}`;
            if(!document.querySelector(querySelector)){
                newSubColKey-=2;
            }
            
        }
        if(key === "ArrowUp"){
            newStayDate = moment(stay_date).add(-1, 'days').format("YYYY-MM-DD");
        }
        if(key === "ArrowDown"){
            newStayDate = moment(stay_date).add(1, 'days').format("YYYY-MM-DD");
        }
        querySelector = `[stay_date="${newStayDate}"][subcol_key="${newSubColKey}"] ${innerClass}`;
        console.log(querySelector);
        
        setTimeout(() => {
            const clickEvent = new MouseEvent("click", {
                "view": window,
                "bubbles": true,
                "cancelable": false
            });
            if(document.querySelector(querySelector)){
                if(document.querySelector(querySelector).classList.contains("checkbox")){
                    //querySelector= querySelector.replace(innerClass, "");
                    if(key === "ArrowLeft"){
                        newSubColKey-=1;
                    }

                   
                    querySelector=`[stay_date="${newStayDate}"][subcol_key="${newSubColKey}"] ${innerClass}`;
                }
                document.querySelector(querySelector).dispatchEvent(clickEvent);
                
            }
            
        }, 100)
        
    }




    const calculateColWidth = (tile) => {
        let colWidth = [];
        let dataColumn = (tile === "compset") ? 20 : 12;
        for (let i = 1; i <= data_cols.length; i++) {
            if (i <= 2) {
                colWidth.push(10);
            } else {
                colWidth.push(dataColumn)
            }
        }
        return (colWidth.toString()).trim();
    }

    const  downloadReportToExcel = (selectedPropertyName)=> {
        let exportFileName = moment(app_state.as_of_date).format('MMM DD YYYY') + "_" + selectedPropertyName + "_" + active_tiles[0];
        if (app_state.workspace_controls.downloadExportButton === true
            &&  compRef.current.data_setup) {
            let sheetName = active_tiles[0] ? active_tiles[0] : " ";
            const {current} = compRef;
            const table  = document.createElement('table');

            const header = document.querySelector("#mainTable .table-head").cloneNode( true )  //generateTableHead(table,colKeys)
            table.append(header);
            let data=[];
            let columnNodes = document.querySelectorAll(".filter-position-r .col-head-label");
            let colNames =[];
            columnNodes.forEach(item=>{
                colNames.push(item.textContent);
            })
            let colNameHash ={};
            current.columns.forEach(col=>{
                col.subcolumns.forEach(subcol=>{
                    if(colNames.includes(subcol.display)){
                        colNameHash[subcol.value]=subcol.display;
                    }
                })
            })
            compRef.current.data_setup.forEach(dItem=>{
                let dataPoint={};
                dItem.forEach((item,index)=>{
                   
                    if(item.hasOwnProperty("house")){
                        item["house"]=getRestrictionsStr(item["house"]);
                    }
                    if(isRoomTypePricing){
                        if(item.hasOwnProperty("Occ") || item.hasOwnProperty("ADR")){
                            return;
                        }
                       
                    }
                    Object.keys(item).forEach(key=>{
                        let value = item[key];
                        if(value === true){
                            value = "true"
                        }
                        if(value ===null || value ===""){
                            value="";
                        }
                        else if(!isNaN(value)){
                            value = round(value)
                        }
                        
                        if(isRoomTypePricing){
                            if(!Object.keys(colNameHash).includes(key)){
                                return;
                            }
                        }
                        dataPoint[key+"-"+index]=value;
                    })
                   
                })
                data.push(dataPoint);
            })
            const tBody = generateTable(table, data);
            TableToExcel.convert(table, {
                name: exportFileName + ".xlsx",
                sheet: {
                    name: sheetName.toUpperCase()
                }
            });
        }
    }
    let tableContClass = "table-scrollable ";
    if (workspace_controls.isRoomTypePricing === true) {
        tableContClass += "room-type-pricing"
        let avail_room_cat = ui_state.selectedRoomPricingCategory.map(item=>  item.room_class);

        if(avail_room_cat.includes("All")){
            tableContClass+=" category-all";
        }
    } else {
        tableContClass += "houselevel"
    }
    
   
    return (

        <Paper sx={{ width: "100%", height:"inherit" }} elevation={0}>
            {/* <button onClick={onDownload} className="primary"> Download</button> */}
            {
                (compRef.current.data_setup &&
                    compRef.current.data_setup.length && (isRoomTypePricing ===true)
                    ) 
                    && (<TablePagination
                        rowsPerPageOptions={[20]}
                        component="div"
                        count={compRef.current.data_setup.length}
                        rowsPerPage={rowsPerPage}
                        page={page}
                        onPageChange={handleChangePage}
                        onRowsPerPageChange={() => {

                        }}
                    />)
            }
            {<RoomTypeFilter />}

            <TableContainer className={tableContClass} >
                <Table
                    stickyHeader
                    size="small"
                    aria-label="a dense table"
                    className={active_tiles_className + " react-dynamic-table"}
                    id="mainTable"
                    data-cols-width={calculateColWidth(active_tiles[0])}
                >
                    {renderHeader()}
                    <tbody className="table-body">

                        {
                            (compLoadStatus !== 'loading' && compLoad && displayData.length && data_setup.length) ? renderBody() : ''
                        }
                    </tbody>
                </Table>
            </TableContainer>


        </Paper>
    );
}

export default TablePricing;
//Helper functions
const getFilteredData = (params) => {
    // debugger;
    // {data:data_setup, sortBy: 'Occ', parent: 'otb_prior'};
    const { data, columns } = params;
    const filteredColumns = getFilteredColumns(columns);
    let filtereddData = deepCopy(data);
    if (!filteredColumns.length) {
        return filtereddData;
    }
    // debugger
    filtereddData = [];
    let pushedRow = [];
    data.map((row, i) => {
        for (let j = 0; j < row.length; j++) {
            for (let k = 0; k < filteredColumns.length; k++) {
                let { parent, value, filter } = filteredColumns[k];
                if (row[j].id === parent) {
                    // debugger
                    let rowData = null;
                    if (filter) {
                        let dataValue = row[j][value];
                        if (typeof dataValue !== 'number' && dataValue.indexOf('%') !== -1) {
                            dataValue = Number(dataValue.replaceAll(/%/g, ''));
                        }
                        if (filter.gte && filter.lte) {
                            if (dataValue >= Number(filter.gte) && dataValue <= filter.lte) {
                                rowData = row;
                            }
                        } else {
                            if (filter.gte && dataValue >= Number(filter.gte)) {
                                rowData = row;
                            }
                            if (filter.lte && dataValue <= filter.lte) {
                                rowData = row;
                            }
                        }

                        if (pushedRow.indexOf(i) === -1 && rowData) {
                            filtereddData.push(rowData);
                            pushedRow.push(i);
                        }
                    }
                }
            }
        }
    });
    // debugger;
    return filtereddData;
}

const sortData = (params) => {
    // {data:data_setup, sortBy: 'Occ', parent: 'otb_prior'};
    const { data, sortBy, parent, order } = params;
    const sortedData = deepCopy(data);
    if (!order) {
        return sortedData;
    }
    let sortedParentIndex = null;
    if (sortedData[0]) {
        sortedData[0].map((item, i) => {
            if (item.id === parent) {
                sortedParentIndex = i;
            }
        });
        let weekdaysShort = moment.weekdaysShort();
        //  debugger;
        sortedData.sort((a, b) => {
            if (sortedParentIndex !== null) {
                let A = a[sortedParentIndex][sortBy];
                let B = b[sortedParentIndex][sortBy];
                //This is added for Pickup variance to sdly column
                A = (A) ? A : 0;
                B = (B) ? B : 0;
                // console.log("a=> ", A);

                if (typeof A !== 'number' && A.indexOf('%') !== -1) {
                    A = Number(A.replaceAll(/%/g, ''));
                }
                if (typeof B !== 'number' && B.indexOf('%') !== -1) {
                    B = Number(B.replaceAll(/%/g, ''));
                }
                //---
                if ((sortBy === 'date') || (sortBy === 'compdate')) {
                    A = moment(A).format('x');
                    B = moment(B).format('x');
                }
                if (sortBy === 'dow') {
                    A = weekdaysShort.indexOf(A);
                    B = weekdaysShort.indexOf(B);
                }

                if (order === 'asc') {
                    return A - B;
                }
                if (order === 'desc') {
                    return B - A;
                }
            }
        });
    }
    return sortedData;
}

const getSortedColumn = (columns) => {
    //Get sorted column details from column configuration
    let sortedColumn = {};
    columns.map((main, i) => {
        main.subcolumns.map((sub, j) => {
            //it depends on property sorted
            if (sub.sorted) {
                sortedColumn = sub;
            }
        });
    });
    return sortedColumn;
};
const getFilteredColumns = (columns) => {
    //Get filter column details from column configuration
    let filteredColumns = [];
    columns.map((main, i) => {
        let subcols = main.subcolumns;
        for (let j = 0; j < subcols.length; j++) {
            let sub = subcols[j];
            if (sub.filterable && sub.filter && (sub.filter.gte || sub.filter.lte)) {
                filteredColumns.push(sub);
            }
        }
    });
    // debugger;
    return filteredColumns;
};
//Helper components
const ColumnSorting = (props) => {
    const { colItem, sortable, onOrderByChange, sorted, label, value } = props;

    const onClickHandler = (ev) => {
        // debugger;
        if (!sorted) {
            colItem.sorted = 'asc';
        }
        if (sorted === 'asc') {
            colItem.sorted = 'desc';
        }
        if (sorted === 'desc') {
            colItem.sorted = null;
        }
        onOrderByChange(colItem);
    }

    if (!sortable) {
        return (<></>);
    }
    return (
        <div style={{ position: 'relative' }}>
            <Tooltip title={"Sort by " + label} arrow>
                <Button className={props.className + ' order-' + colItem.sorted} onClick={onClickHandler}>
                    <ArrowDropUpIcon className='order-arrow order-arrow-up' />
                    <ArrowDropDownIcon className='order-arrow order-arrow-down' />
                </Button>
            </Tooltip>

        </div>

    );
}