import { API_BASE_URL, API_REGISTRY } from "../app/config"
import { getFromSession } from "../app/util";

const { UserLogin, UserRequestResetPassword, UserProfile } = API_REGISTRY;


const login = async (payload) => {
    const response = await fetch(API_BASE_URL + UserLogin.uri, {
        method: 'POST', // *GET, POST, PUT, DELETE, etc.
        //mode: 'cors', // no-cors, *cors, same-origin
        cache: 'no-cache', // *default, no-cache, reload, force-cache, only-if-cached
        //credentials: 'same-origin', // include, *same-origin, omit
        // credentials:'include',
        headers: {
            'Content-Type': 'application/json',

            // "Access-Control-Allow-Origin":API_BASE_URL,
            // "Access-Control-Allow-Credentials": true
            //'Access-Control-Allow-Origin':'*'
            // 'Content-Type': 'application/x-www-form-urlencoded',
        },
        
        redirect: 'follow', // manual, *follow, error
        //referrerPolicy: 'origin', // no-referrer, *no-referrer-when-downgrade, origin, origin-when-cross-origin, same-origin, strict-origin, strict-origin-when-cross-origin, unsafe-url
        body: JSON.stringify(payload) // body data type must match "Content-Type" header
    });
    console.log(response.headers.get('set-cookie'))
    return response.json();
};
const logout = async (payload) => {
   
    const response = await fetch(API_BASE_URL + "users/logout/", {
        method: 'POST', // *GET, POST, PUT, DELETE, etc.
        mode: 'cors', // no-cors, *cors, same-origin
        cache: 'no-cache', // *default, no-cache, reload, force-cache, only-if-cached
        credentials: 'same-origin', // include, *same-origin, omit
        headers: {
            'Content-Type': 'application/json',
            "Authorization": "Token " + payload.token,
            // 'Content-Type': 'application/x-www-form-urlencoded',
        },
        redirect: 'follow', // manual, *follow, error
        referrerPolicy: 'no-referrer', // no-referrer, *no-referrer-when-downgrade, origin, origin-when-cross-origin, same-origin, strict-origin, strict-origin-when-cross-origin, unsafe-url
        // body: {} // body data type must match "Content-Type" header
    });
    sessionStorage.clear();
    return response.json();
    // /users/logout
};

const resetPassword = async (params) => {
    const { payload, uri } = UserRequestResetPassword;
    let method = payload.method ? payload.method : 'POST';
    delete payload.method;
    let requestBody = JSON.stringify(params);
    if (method === 'GET') {
        requestBody = null;
    }
    const response = await fetch(API_BASE_URL + uri, {
        method: method, // *GET, POST, PUT, DELETE, etc.
        mode: 'cors', // no-cors, *cors, same-origin
        cache: 'no-cache', // *default, no-cache, reload, force-cache, only-if-cached
        credentials: 'same-origin', // include, *same-origin, omit
        headers: {
            'Content-Type': 'application/json'
            // 'Content-Type': 'application/x-www-form-urlencoded',
        },
        redirect: 'follow', // manual, *follow, error
        referrerPolicy: 'no-referrer', // no-referrer, *no-referrer-when-downgrade, origin, origin-when-cross-origin, same-origin, strict-origin, strict-origin-when-cross-origin, unsafe-url
        body: requestBody // body data type must match "Content-Type" header
    });
    return response.json();
};
const getUserProfile = async (payload) => {
    const response = await fetch(API_BASE_URL + UserProfile.uri, {
        method: 'POST', // *GET, POST, PUT, DELETE, etc.
        mode: 'cors', // no-cors, *cors, same-origin
        cache: 'no-cache', // *default, no-cache, reload, force-cache, only-if-cached
        credentials: 'same-origin', // include, *same-origin, omit
        headers: {
            'Content-Type': 'application/json'
            // 'Content-Type': 'application/x-www-form-urlencoded',
        },
        redirect: 'follow', // manual, *follow, error
        referrerPolicy: 'no-referrer', // no-referrer, *no-referrer-when-downgrade, origin, origin-when-cross-origin, same-origin, strict-origin, strict-origin-when-cross-origin, unsafe-url
        body: JSON.stringify(payload) // body data type must match "Content-Type" header
    });
    return response.json();
};

export default {
    login,
    logout,
    resetPassword,
    getUserProfile
};