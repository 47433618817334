
import SettingsIcon from '@mui/icons-material/Settings';
import {Button, Tooltip} from '@mui/material'
import {useDispatch, useSelector} from 'react-redux';
import { updateAppState } from '../../../../actions/appstate';


const OptionsButton = () => {
    const controls = useSelector(state => state.app.current_state.app_state.workspace_controls);
    const dispatch = useDispatch();

    const handleClick = (type,value)=>{
        const updateTypes = [type];
        controls.optionsButton = value;
        dispatch(updateAppState(updateTypes, {workspace_controls: controls}));
    }
    return (
        <Button onClick={(e) => handleClick("optionsClick", !controls.downloadButton)}>
            <Tooltip title="Add Event"> 
            <SettingsIcon fontSize="large" color="grey" /> 
             </Tooltip>
        </Button>
    )
}

export default OptionsButton