import {  deepCopy,  safeReference, getNumDaysFromDateRange } from "../../../app/util";
import { useDataSummary } from "../../Tile/kpiService";
import dataService from "../../../services/data.service";
import moment from "moment";
export function getPricingDataFormatted ({primary_stay_range, room_settings}, isRoomType =false, rateRecomended=[]){
    const numDays = getNumDaysFromDateRange(primary_stay_range);
    let baseRoom="";
    room_settings.map(room=>{
        if(room.is_base === true){
            baseRoom=room.room_code;
           }
    })
  
    if(rateRecomended.length ===0 && 
        dataService.getSeriesById("OSRRateRecommend") 
        && dataService.getSeriesById("OSRRateRecommend").data   ){
        rateRecomended = deepCopy(dataService.getSeriesById("OSRRateRecommend").data.data);
    }
    
    let pricingData =[];
    let currDate = moment(primary_stay_range.date_from);

    for (let index = 0; index < numDays; index++) {
        let dataPoint ={
            stay_date:currDate.format("YYYY-MM-DD")
        };
        if(!isRoomType){
            let found = false;
            rateRecomended.map(rateData=>{

                if(rateData.stay_date === currDate.format("YYYY-MM-DD")){
                    if(rateData.is_base === true || rateData.room_type === baseRoom){
                        
                        dataPoint.RecSellRate = rateData.algo_generated_recommended_rate;
                        dataPoint.SellRate = rateData.current_sell_rate;
                        if(rateData.pushed === true){
                            dataPoint.SellRate = rateData.resolved_sell_rate
                        }
                        found = true;
                    }
                  
                   
                }
            })
            if(!found){
                dataPoint.RecSellRate = 0;
                dataPoint.SellRate =0
            }
        } else {
            
            rateRecomended.map(rateData=>{

                if(rateData.stay_date === currDate.format("YYYY-MM-DD") && rateData.room_type){

                    dataPoint[rateData.room_type]= {
                        RecSellRate :rateData.algo_generated_recommended_rate,
                        SellRate :rateData.current_sell_rate,
                        lodgiq_differential:rateData.algo_generated_differential,
                        hierarchy_order:rateData.hierarchy_order,
                        rooms_sold:rateData.rooms_sold
                    }
                    if(rateData.pushed === true){
                        dataPoint[rateData.room_type].SellRate = rateData.resolved_sell_rate
                    }
                }
            })
        }
    
        pricingData.push(dataPoint);
        currDate.add(1,'days');
    }
    return pricingData;
}