//React
import React, { useState, useEffect, useReducer } from 'react';
import { useNavigate } from "react-router-dom";

//React Redux
import { useDispatch, useSelector } from 'react-redux';
import { settingsReducers } from './reducers';
//MUI
import {
    Grid, Button, LinearProgress, Badge, Modal,
    Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography, Switch
} from '@mui/material';
import {
    CloseSharp as CloseSharpIcon,
    ArrowBackIosNewSharp as ArrowBackIosNewSharpIcon,
    ArrowForwardIosSharp as ArrowForwardIosSharpIcon,
    CheckSharp as CheckSharpIcon
}
    from '@mui/icons-material';
import DownloadForOfflineRoundedIcon from '@mui/icons-material/DownloadForOfflineRounded';
import DownloadIcon from '@mui/icons-material/Download';
import DownloadOutlinedIcon from '@mui/icons-material/DownloadOutlined';
import BarChartIcon from '@mui/icons-material/BarChart';

import { border, Box, fontSize, textAlign } from '@mui/system';

//Moments
import moment from 'moment';

import Paper from '@mui/material/Paper';

//APP's
import theme from '../../theme';
import { styled } from "@mui/material/styles";

import './dailyflash.css'
import dataJson from './data365.json';
import { calculateMonthlyData, getMonthlyData } from './functions';
import dataService from '../../services/data.service';
import Loading from './Loading';
import { convertTOCorrectValue } from '../../app/util';

const StyledTableRow = styled(TableRow)(({ theme }) => ({
    "&:nth-of-type(even)": {
        backgroundColor: "#ebebeb",
    },
    // hide last border
    "&:last-child td, &:last-child th": {
        border: 0,
    },
}));


function DataTable(props) {
    const { app_state, ui_state } = useSelector(
        (state) => state.app.current_state
    );
    let currSymbol = ui_state.currency_symbol;
    const data = props.data;
    const [expView, setExpView] = useState(true);
    const handleChange = (type, value) => {
        setExpView(value);
    }
    let expViewClass = "dn";
    let colspanCount = 3;
    let colspanPickOver = 3;
    if (expView === true) {
        expViewClass = "";
        colspanCount = 6;
        colspanPickOver = 6;
    }

    const calculateColWidth = () => {
        let colWidth = [];
        let data_cols = (expView) ? 25 : 15;
        for (let i = 1; i <= data_cols; i++) {
            colWidth.push(13)
        }
        return (colWidth.toString()).trim();
    }
    let colExclude = (expViewClass && expViewClass != "") ? true : false;
    let dtFormat = "ddd, MMM DD, YYYY";

    return (
        <div className='data-table-container'>
            {(props.data && props.data.length > 0) ? (
                <>
                    <Grid className='inner-header'>
                        <span className='days-update-label' style={{ textTransform: "unset" }}>On the Book (By Month)</span>
                        <span className='days-update-label' style={{ float: "right", textTransform: "unset" }}>
                            Expanded Data View  <Switch color='white' checked={expView} onChange={() => {
                                handleChange("expendedView", !expView)
                            }} /></span>
                    </Grid>
                    <Grid sx={{ pl: 0, pr: 0, background: '#dfdfdf' }}>
                        <Paper>
                            <TableContainer className='daily-flash-table' sx={{ maxHeight: 310, overflow: 'auto' }}>

                                <Table id="dailyFlashTable" data-cols-width={calculateColWidth()} stickyHeader size="small" aria-label="sticky table">
                                    <TableHead>
                                        <TableRow sx={{ '& td, & th': { pt: 1.5, pb: 1.5, top: "0px !important" } }}>
                                            <TableCell stickyHeader align="center" colSpan={1} className='table-columns-header top-header firstcolumn'
                                                sx={{ background: '#3EA7CD', minWidth: '100px', }}
                                                data-fill-color="3EA7CD"
                                                data-f-color="ffffff"
                                                data-b-r-s="hair"
                                                data-b-b-s="hair"
                                                data-a-v="middle"
                                                data-b-a-c="ffffff"
                                                data-a-h="center"
                                                data-f-sz="14">
                                                {/* {calculateColWidth()} */}
                                            </TableCell>
                                            <TableCell align="center" colSpan={colspanCount} className='table-columns-header top-header' 
                                                data-fill-color="81d4fa"
                                                data-f-color="ffffff"
                                                data-b-r-s="hair"
                                                data-b-b-s="hair"
                                                data-a-v="middle"
                                                data-b-a-c="ffffff"
                                                data-a-h="center"
                                                data-f-sz="14">
                                                OTB AS OF - {moment(app_state.as_of_date).format(dtFormat)}
                                            </TableCell>
                                            <TableCell align="center" colSpan={colspanPickOver} className='table-columns-header top-header'
                                                data-fill-color="606060"
                                                data-f-color="ffffff"
                                                data-b-b-s="hair"
                                                data-b-r-s="hair"
                                                data-a-v="middle"
                                                data-b-a-c="ffffff"
                                                data-a-h="center"
                                                data-f-sz="14">PICKUP OVER - {moment(app_state.as_of_date).subtract(1, "days").format(dtFormat)}
                                            </TableCell>
                                            <TableCell align="center" colSpan={colspanCount} className='table-columns-header top-header'
                                                data-fill-color="29b6f6"
                                                data-f-color="ffffff"
                                                data-b-r-s="hair"
                                                data-b-b-s="hair"
                                                data-a-v="middle"
                                                data-b-a-c="ffffff"
                                                data-a-h="center"
                                                data-f-sz="14">
                                                OTB AS OF SDLY - {moment(app_state.as_of_date).subtract(364, 'days').format(dtFormat)}
                                            </TableCell>
                                            <TableCell align="center" colSpan={6} className='table-columns-header top-header'
                                                data-fill-color="0277bd"
                                                data-f-color="ffffff"
                                                data-b-r-s="hair"
                                                data-b-b-s="hair"
                                                data-a-v="middle"
                                                data-b-a-c="ffffff"
                                                data-a-h="center"
                                                data-f-sz="14">
                                                Difference TO SDLY
                                            </TableCell>

                                        </TableRow>


                                        <TableRow sx={{ '& td, & th': { pt: 1.5, pb: 1.5, top: "30px" } }}>
                                            <TableCell align="center" colSpan={1} className='table-columns-header second-header firstcolumn'
                                                sx={{ background: '#3EA7CD', minWidth: '100px' }}
                                                data-fill-color="3EA7CD"
                                                data-f-color="ffffff"
                                                data-b-r-s="hair"
                                                data-a-v="middle"
                                                data-b-a-c="ffffff"
                                                data-a-h="center"
                                                data-f-sz="14">
                                                MONTH
                                            </TableCell>
                                           
                                            <TableCell data-exclude={colExclude} align="center" className={'table-columns-header second-header ' + expViewClass} sx={{ background: '#81d4fa' }}
                                                data-fill-color="81d4fa"
                                                data-f-color="ffffff"
                                                data-b-r-s="hair"
                                                data-a-v="middle"
                                                data-b-a-c="ffffff"
                                                data-a-h="center"
                                                data-f-sz="14">
                                                OCC
                                            </TableCell>
                                            <TableCell align="center" className='table-columns-header second-header' sx={{ background: '#81d4fa' }}
                                                data-fill-color="81d4fa"
                                                data-f-color="ffffff"
                                                data-b-r-s="hair"
                                                data-a-v="middle"
                                                data-b-a-c="ffffff"
                                                data-a-h="center"
                                                data-cols-width="30"
                                                data-f-sz="14">
                                                ROOMS
                                            </TableCell>
                                            <TableCell align="center" className='table-columns-header second-header' sx={{ background: '#81d4fa' }}
                                                data-fill-color="81d4fa"
                                                data-f-color="ffffff"
                                                data-b-r-s="hair"
                                                data-a-v="middle"
                                                data-b-a-c="ffffff"
                                                data-a-h="center"
                                                data-f-sz="14">
                                                ADR
                                            </TableCell>
                                            <TableCell data-exclude={colExclude} align="center" className={'table-columns-header second-header ' + expViewClass} sx={{ background: '#81d4fa' }}
                                                data-fill-color="81d4fa"
                                                data-f-color="ffffff"
                                                data-b-r-s="hair"
                                                data-a-v="middle"
                                                data-b-a-c="ffffff"
                                                data-a-h="center"
                                                data-f-sz="14">
                                                REVPAR
                                            </TableCell>
                                            <TableCell data-exclude={colExclude} align="center" className={'table-columns-header second-header ' + expViewClass} sx={{ background: '#81d4fa' }}
                                                data-fill-color="81d4fa"
                                                data-f-color="ffffff"
                                                data-b-r-s="hair"
                                                data-a-v="middle"
                                                data-b-a-c="ffffff"
                                                data-a-h="center"
                                                data-f-sz="14">
                                                REVENUE
                                            </TableCell> 
                                            <TableCell align="center" className='table-columns-header second-header' 
                                                sx={{ background: '#81d4fa' }}
                                                data-fill-color="81d4fa"
                                                data-f-color="ffffff"
                                                data-b-r-s="hair"
                                                data-a-v="middle"
                                                data-b-a-c="ffffff"
                                                data-a-h="center"
                                                data-f-sz="14">
                                                SELL RATE
                                            </TableCell>
                                            {/* end of OTB As Of  */}


                                           
                                            <TableCell align="center" key={Math.random()} className={'table-columns-header second-header ' + expViewClass} sx={{ background: '#25a4dd' }}
                                                data-fill-color="606060"
                                                data-f-color="ffffff"
                                                data-b-r-s="hair"
                                                data-a-v="middle"
                                                data-b-a-c="ffffff"
                                                data-a-h="center"
                                                data-exclude={colExclude}
                                                data-f-sz="14">
                                                OCC
                                            </TableCell>
                                            <TableCell align="center" key={Math.random()} className='table-columns-header second-header' sx={{ background: '#25a4dd' }}
                                                data-fill-color="606060"
                                                data-f-color="ffffff"
                                                data-b-r-s="hair"
                                                data-a-v="middle"
                                                data-b-a-c="ffffff"
                                                data-a-h="center"
                                                data-f-sz="14">
                                                ROOMS
                                            </TableCell> <TableCell align="center" key={Math.random()} className={'table-columns-header second-header'} sx={{ background: '#25a4dd' }}
                                                data-fill-color="606060"
                                                data-f-color="ffffff"
                                                data-b-r-s="hair"
                                                data-a-v="middle"
                                                data-b-a-c="ffffff"
                                                data-a-h="center"
                                                data-f-sz="14">
                                                ADR
                                            </TableCell>
                                            <TableCell align="center" key={Math.random()} className={'table-columns-header second-header ' + expViewClass} sx={{ background: '#25a4dd' }}
                                                data-fill-color="606060"
                                                data-f-color="ffffff"
                                                data-b-r-s="hair"
                                                data-a-v="middle"
                                                data-b-a-c="ffffff"
                                                data-a-h="center"
                                                data-exclude={colExclude}
                                                data-f-sz="14">
                                                REVPAR
                                            </TableCell>
                                            <TableCell align="center" key={Math.random()} className={'table-columns-header second-header ' + expViewClass} sx={{ background: '#25a4dd' }}
                                                data-fill-color="606060"
                                                data-f-color="ffffff"
                                                data-b-r-s="hair"
                                                data-a-v="middle"
                                                data-b-a-c="ffffff"
                                                data-a-h="center"
                                                data-exclude={colExclude}
                                                data-f-sz="14">
                                                REVENUE
                                            </TableCell>
                                            <TableCell align="center" key={Math.random()} className='table-columns-header second-header' sx={{ background: '#25a4dd' }}
                                                data-fill-color="606060"
                                                data-f-color="ffffff"
                                                data-b-r-s="hair"
                                                data-a-v="middle"
                                                data-b-a-c="ffffff"
                                                data-a-h="center"
                                                // data-exclude={colExclude}
                                                data-f-sz="14">
                                                SELL RATE
                                            </TableCell>
                                            {/* End of PickOver  */}
                                            <TableCell align="center" key={Math.random()} className={'table-columns-header second-header ' + expViewClass} sx={{ background: '#29b6f6' }}
                                                data-fill-color="29b6f6"
                                                data-f-color="ffffff"
                                                data-b-r-s="hair"
                                                data-a-v="middle"
                                                data-b-a-c="ffffff"
                                                data-a-h="center"
                                                data-exclude={colExclude}
                                                data-f-sz="14">
                                                OCC
                                            </TableCell><TableCell align="center" key={Math.random()} className='table-columns-header second-header' sx={{ background: '#29b6f6' }}
                                                data-fill-color="29b6f6"
                                                data-f-color="ffffff"
                                                data-b-r-s="hair"
                                                data-a-v="middle"
                                                data-b-a-c="ffffff"
                                                data-a-h="center"
                                                data-f-sz="14">

                                                ROOMS
                                            </TableCell>
                                             <TableCell align="center" key={Math.random()} className='table-columns-header second-header' sx={{ background: '#29b6f6' }}
                                                data-fill-color="29b6f6"
                                                data-f-color="ffffff"
                                                data-b-r-s="hair"
                                                data-a-v="middle"
                                                data-b-a-c="ffffff"
                                                data-a-h="center"
                                                data-f-sz="14">
                                                ADR
                                            </TableCell>
                                            <TableCell align="center" key={Math.random()} className={'table-columns-header second-header ' + expViewClass} sx={{ background: '#29b6f6' }}
                                                data-fill-color="29b6f6"
                                                data-f-color="ffffff"
                                                data-b-r-s="hair"
                                                data-a-v="middle"
                                                data-b-a-c="ffffff"
                                                data-a-h="center"
                                                data-exclude={colExclude}
                                                data-f-sz="14">
                                                REVPAR
                                            </TableCell>
                                            <TableCell align="center" key={Math.random()} className={'table-columns-header second-header ' + expViewClass} sx={{ background: '#29b6f6' }}
                                                data-fill-color="29b6f6"
                                                data-f-color="ffffff"
                                                data-b-r-s="hair"
                                                data-a-v="middle"
                                                data-b-a-c="ffffff"
                                                data-a-h="center"
                                                data-exclude={colExclude}
                                                data-f-sz="14">
                                                REVENUE
                                            </TableCell>
                                            <TableCell align="center" key={Math.random()} className='table-columns-header second-header' sx={{ background: '#29b6f6' }}
                                                data-fill-color="29b6f6"
                                                data-f-color="ffffff"
                                                data-b-r-s="hair"
                                                data-a-v="middle"
                                                data-b-a-c="ffffff"
                                                data-a-h="center"
                                                data-f-sz="14">
                                                SELL RATE
                                            </TableCell>
                                            {/* End of OTB as of SDLY  */}


                                            <TableCell align="center" key={Math.random()} className='table-columns-header second-header' sx={{ background: '#0277bd' }}
                                                data-fill-color="0277bd"
                                                data-f-color="ffffff"
                                                data-b-r-s="hair"
                                                data-a-v="middle"
                                                data-b-a-c="ffffff"
                                                data-a-h="center"
                                                data-f-sz="14">
                                                OCC
                                            </TableCell> <TableCell align="center" key={Math.random()} className='table-columns-header second-header' sx={{ background: '#0277bd' }}
                                                data-fill-color="0277bd"
                                                data-f-color="ffffff"
                                                data-b-r-s="hair"
                                                data-a-v="middle"
                                                data-b-a-c="ffffff"
                                                data-a-h="center"
                                                data-f-sz="14">
                                                ROOMS
                                            </TableCell> <TableCell align="center" key={Math.random()} className='table-columns-header second-header' sx={{ background: '#0277bd' }}
                                                data-fill-color="0277bd"
                                                data-f-color="ffffff"
                                                data-b-r-s="hair"
                                                data-a-v="middle"
                                                data-b-a-c="ffffff"
                                                data-a-h="center"
                                                data-f-sz="14">
                                                ADR
                                            </TableCell>
                                            <TableCell align="center" key={Math.random()} className='table-columns-header second-header' sx={{ background: '#0277bd' }}
                                                data-fill-color="0277bd"
                                                data-f-color="ffffff"
                                                data-b-r-s="hair"
                                                data-a-v="middle"
                                                data-b-a-c="ffffff"
                                                data-a-h="center"
                                                data-f-sz="14">
                                                REVPAR
                                            </TableCell> <TableCell align="center" key={Math.random()} className='table-columns-header second-header' sx={{ background: '#0277bd' }}
                                                data-fill-color="0277bd"
                                                data-f-color="ffffff"
                                                data-b-r-s="hair"
                                                data-a-v="middle"
                                                data-b-a-c="ffffff"
                                                data-a-h="center"
                                                data-f-sz="14">
                                                REVENUE
                                            </TableCell> <TableCell align="center" key={Math.random()} className='table-columns-header second-header' sx={{ background: '#0277bd' }}
                                                data-fill-color="0277bd"
                                                data-f-color="ffffff"
                                                data-b-r-s="hair"
                                                data-a-v="middle"
                                                data-b-a-c="ffffff"
                                                data-a-h="center"
                                                data-f-sz="14">
                                                SELL RATE
                                            </TableCell>
                                            {/* End Of Variance  */}



                                        </TableRow>


                                    </TableHead>


                                    <TableBody>
                                        {
                                            data.map((item, index) => {
                                                let rowsBackColor = (index % 2 !== 0) ? "ebebeb" : "ffffff";
                                                return <StyledTableRow className='data-rows' sx={{ '& td, & th': { pt: 1.5, pb: 1.5, top: "62px !important" } }}>

                                                    <>
                                                        <TableCell
                                                            data-fill-color={rowsBackColor}
                                                            data-b-r-s="dashed" data-b-a-c="ffffff"
                                                            data-t="s" data-f-sz="13" data-a-h="center"
                                                            className='table-columns firstcolumn'>
                                                            {item.monthData}
                                                        </TableCell>
                                                        <TableCell data-exclude={colExclude} data-fill-color={rowsBackColor} data-b-r-s="dashed" data-b-a-c="ffffff" data-t="s" data-f-sz="13" data-a-h="center" className={'table-columns ' + expViewClass}>
                                                            {convertTOCorrectValue(item.primary.Occ,"Occ",currSymbol)}
                                                        </TableCell>
                                                        
                                                        <TableCell data-fill-color={rowsBackColor} data-b-r-s="dashed" data-b-a-c="ffffff" data-t="s" data-f-sz="13" data-a-h="center" className='table-columns'>
                                                            {convertTOCorrectValue(item.primary.Rooms,"Rooms",currSymbol)}
                                                        </TableCell>
                                                        <TableCell data-fill-color={rowsBackColor} data-b-r-s="dashed" data-b-a-c="ffffff" data-t="s" data-f-sz="13" data-a-h="center" className='table-columns'>
                                                            {convertTOCorrectValue(item.primary.ADR,"ADR",currSymbol)}
                                                        </TableCell>
                                                        <TableCell data-exclude={colExclude} data-fill-color={rowsBackColor} data-b-r-s="dashed" data-b-a-c="ffffff" data-t="s" data-f-sz="13" data-a-h="center" className={'table-columns ' + expViewClass}>
                                                            {convertTOCorrectValue(item.primary.RevPAR,"RevPAR",currSymbol)}
                                                        </TableCell>
                                                        <TableCell data-exclude={colExclude} data-a-h="right" data-fill-color={rowsBackColor} data-b-r-s="dashed" data-b-a-c="ffffff" data-f-sz="13" className={'table-columns revenue-txt ' + expViewClass}>
                                                            {convertTOCorrectValue(item.primary.Revenue,"Revenue",currSymbol)}
                                                        </TableCell>
                                                        <TableCell data-fill-color={rowsBackColor} data-b-r-s="dashed" data-b-a-c="ffffff" data-t="s" data-f-sz="13" data-a-h="center" className='table-columns rightBorder'>
                                                            {convertTOCorrectValue(item.primary.SellRate,"SellRate",currSymbol)}
                                                        </TableCell>


                                                        
                                                        <TableCell data-exclude={colExclude} data-fill-color={rowsBackColor} data-b-r-s="dashed" data-b-a-c="ffffff" data-t="s" data-f-sz="13" data-a-h="center" className={'table-columns ' + expViewClass}>
                                                            {convertTOCorrectValue(item.pickup.Occ,"Occ",currSymbol)}
                                                        </TableCell>
                                                        <TableCell data-fill-color={rowsBackColor} data-b-r-s="dashed" data-b-a-c="ffffff" data-t="s" data-f-sz="13" data-a-h="center" className='table-columns'>
                                                            {convertTOCorrectValue(item.pickup.Rooms,"Rooms",currSymbol)}
                                                        </TableCell><TableCell data-fill-color={rowsBackColor} data-b-r-s="dashed" data-b-a-c="ffffff" data-t="s" data-f-sz="13" data-a-h="center" className={'table-columns '}>
                                                            {convertTOCorrectValue(item.pickup.ADR,"ADR",currSymbol)}
                                                        </TableCell>
                                                        <TableCell data-exclude={colExclude} data-fill-color={rowsBackColor} data-b-r-s="dashed" data-b-a-c="ffffff" data-t="s" data-f-sz="13" data-a-h="center" className={'table-columns ' + expViewClass}>
                                                            {convertTOCorrectValue(item.pickup.RevPAR,"RevPAR",currSymbol)}
                                                        </TableCell>
                                                        <TableCell data-exclude={colExclude} data-fill-color={rowsBackColor} data-b-r-s="dashed" data-b-a-c="ffffff" data-t="s" data-f-sz="13" data-a-h="right" className={'table-columns revenue-txt ' + expViewClass}>
                                                            {convertTOCorrectValue(item.pickup.Revenue,"Revenue",currSymbol)}
                                                        </TableCell>
                                                        <TableCell 
                                                            // data-exclude={colExclude} 
                                                            data-fill-color={rowsBackColor} 
                                                            data-b-r-s="dashed" data-b-a-c="ffffff" data-t="s" data-f-sz="13" data-a-h="center" className={'table-columns rightBorder'}>
                                                            {convertTOCorrectValue(item.pickup.SellRate,"SellRate",currSymbol)}
                                                        </TableCell>

                                                        
                                                        <TableCell data-exclude={colExclude} data-fill-color={rowsBackColor} data-b-r-s="dashed" data-b-a-c="ffffff" data-t="s" data-f-sz="13" data-a-h="center" className={'table-columns ' + expViewClass}>
                                                            {convertTOCorrectValue(item.sdly.Occ,"Occ",currSymbol)}
                                                        </TableCell>
                                                        <TableCell data-fill-color={rowsBackColor} data-b-r-s="dashed" data-b-a-c="ffffff" data-t="s" data-f-sz="13" data-a-h="center" className='table-columns'>
                                                            {convertTOCorrectValue(item.sdly.Rooms,"Rooms",currSymbol)}
                                                        </TableCell><TableCell data-fill-color={rowsBackColor} data-b-r-s="dashed" data-b-a-c="ffffff" data-t="s" data-f-sz="13" data-a-h="center" className='table-columns'>
                                                            {convertTOCorrectValue(item.sdly.ADR,"ADR",currSymbol)}
                                                        </TableCell>
                                                        <TableCell data-exclude={colExclude} data-fill-color={rowsBackColor} data-b-r-s="dashed" data-b-a-c="ffffff" data-t="s" data-f-sz="13" data-a-h="center" className={'table-columns ' + expViewClass}>
                                                            {convertTOCorrectValue(item.sdly.RevPAR,"RevPAR",currSymbol)}
                                                        </TableCell>
                                                        <TableCell data-exclude={colExclude} data-fill-color={rowsBackColor} data-b-r-s="dashed" data-b-a-c="ffffff" data-t="s" data-f-sz="13" data-a-h="right" className={'table-columns revenue-txt ' + expViewClass}>
                                                            {convertTOCorrectValue(item.sdly.Revenue,"Revenue",currSymbol)}
                                                        </TableCell>
                                                        <TableCell data-fill-color={rowsBackColor} data-b-r-s="dashed" data-b-a-c="ffffff" data-t="s" data-f-sz="13" data-a-h="center" className='table-columns rightBorder'>
                                                            {convertTOCorrectValue(item.sdly.SellRate,"SellRate",currSymbol)}
                                                        </TableCell>



                                                        <TableCell data-fill-color={rowsBackColor} data-b-r-s="dashed" data-b-a-c="ffffff" data-t="s" data-f-sz="13" data-a-h="center" className='table-columns'>
                                                            {convertTOCorrectValue(item.variance.Occ,"Occ",currSymbol)}
                                                        </TableCell>
                                                        <TableCell data-fill-color={rowsBackColor} data-b-r-s="dashed" data-b-a-c="ffffff" data-t="s" data-f-sz="13" data-a-h="center" className='table-columns'>
                                                            {convertTOCorrectValue(item.variance.Rooms,"Rooms",currSymbol)}
                                                        </TableCell>
                                                        <TableCell data-fill-color={rowsBackColor} data-b-r-s="dashed" data-b-a-c="ffffff" data-t="s" data-f-sz="13" data-a-h="center" className='table-columns'>
                                                            {convertTOCorrectValue(item.variance.ADR,"ADR",currSymbol)}
                                                        </TableCell>
                                                        <TableCell data-fill-color={rowsBackColor} data-b-r-s="dashed" data-b-a-c="ffffff" data-t="s" data-f-sz="13" data-a-h="center" className='table-columns'>
                                                            {convertTOCorrectValue(item.variance.RevPAR,"RevPAR",currSymbol)}
                                                        </TableCell>
                                                        <TableCell data-fill-color={rowsBackColor} data-b-r-s="dashed" data-b-a-c="ffffff" data-t="s" data-f-sz="13" data-a-h="right" className='table-columns revenue-txt'>
                                                            {convertTOCorrectValue(item.variance.Revenue,"Revenue",currSymbol)}
                                                        </TableCell>
                                                        <TableCell data-fill-color={rowsBackColor} data-b-r-s="dashed" data-b-a-c="ffffff" data-t="s" data-f-sz="13" data-a-h="center" className='table-columns rightBorder'>
                                                            {convertTOCorrectValue(item.variance.SellRate,"SellRate",currSymbol)}
                                                        </TableCell>




                                                    </>

                                                </StyledTableRow>

                                            }
                                            )
                                        }
                                    </TableBody>


                                </Table>

                            </TableContainer>
                        </Paper>
                    </Grid>
                </>
            ) : (<>
                <Grid className='daily-flash-waiting' sx={{ pl: 0, pr: 0, pt: 12, background: '#dfdfdf', minHeight: "348px" }}>
                    <Loading showText="Generating the Report, Please wait..." showLoader="false" />
                </Grid>
            </>)}
        </div>
    )
}

export default DataTable
