import { Button, Tooltip } from "@mui/material";
import {
  ZoomIn as SegmentIcon,
  ZoomOut as UnsegmentIcon,
} from "@mui/icons-material";
import { useDispatch, useSelector } from "react-redux";
import { updateAppState } from "../../../../actions/appstate";

const SegmentBtn = () => {
  const controls = useSelector(
    (state) => state.app.current_state.app_state.workspace_controls
  );
  const dispatch = useDispatch();

  const handleChange = (type, value) => {
    const updateTypes = [type];
    controls.segmented_view = value;
    dispatch(
      updateAppState(updateTypes, {
        app_state: {data_load: true, workspace_controls: controls},
        ui_state: { showspinner: true } 
      })
    );
  };

  return (
    <Tooltip title={!controls.segmented_view ? "Segmented View" : "Non-Segmented View"} arrow followCursor>
      <Button
        className={controls.segmented_view ? "control-btns segmentation active" : "control-btns segmentation"}
        size="small"
        sx={{ mt: 1.7, minWidth: 'auto' }}
        id="segmented"
        onClick={(e) => {
          handleChange("segmented", !controls.segmented_view);
        }}

      >
        {!controls.segmented_view && 
          <SegmentIcon className="icon-thin-white expand" />
          // <svg className="icon-thin-white expand" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 256 256"><rect width="256" height="256" fill="none"/><line x1="40" y1="64" x2="216" y2="64" fill="none" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="16"/><line x1="40" y1="128" x2="112" y2="128" fill="none" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="16"/><line x1="40" y1="192" x2="128" y2="192" fill="none" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="16"/><circle cx="184" cy="144" r="32" fill="none" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="16"/><line x1="206.63" y1="166.63" x2="232" y2="192" fill="none" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="16"/></svg>
        }
        {controls.segmented_view && 
          <UnsegmentIcon className="icon-thin-white exit-expand" />
          // <svg className="icon-thin-white exit-expand" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 256 256"><rect width="256" height="256" fill="none"/><line x1="40" y1="64" x2="216" y2="64" fill="none" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="16"/><line x1="40" y1="128" x2="112" y2="128" fill="none" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="16"/><line x1="40" y1="192" x2="128" y2="192" fill="none" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="16"/><circle cx="184" cy="144" r="32" fill="none" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="16"/><line x1="206.63" y1="166.63" x2="232" y2="192" fill="none" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="16"/></svg>
        }

      </Button>
    </Tooltip>
  );
};

export default SegmentBtn;
