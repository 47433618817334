import ArrowBackIosNewSharpIcon from '@mui/icons-material/ArrowBackIosNewSharp';
import {
  KeyboardArrowUpOutlined as KeyboardArrowUpOutlinedIcon,
  KeyboardArrowDownOutlined as KeyboardArrowDownOutlined,
  ArrowForwardIosOutlined as ArrowForwardIosOutlinedIcon,
  PlaylistAdd as AddNewIcon,
  ArrowDropDown as ArrowDropDownIcon,
  ArrowDropUp as ArrowDropUpIcon,
  Delete as DeleteIcon,
  Preview as PreviewIcon,
  Edit as EditIcon,
}
  from "@mui/icons-material/";
import {
  Box,
  Typography,
  Button,
  Alert,
  AlertTitle,
  Tooltip,
  Switch,
  Dialog,
  DialogTitle,
  DialogActions,
  Grid,
} from "@mui/material/";
import moment from "moment";
import { useState } from "react";

export function RatePushSchedulePreview(props) {
  const { data } = props;
  const dataMap = [
    { key: "schedule_name", label: "Schedule Name" },
    { key: "room_types", label: "Selected Room Types" },
    { key: "enabled", label: "Active Status" },
    { key: "approval_range", label: "Date Range" },
    { key: "skip_dates", label: "Skip Dates" },
    { key: "schedule_time", label: "Schedule Time" },
    { key: "dow", label: "Day of week" },
    { key: "horizon", label: "Number of Days" },
    { key: "room_types", label: "Selected Room Types" }
  ];
  return dataMap.map((item) => {
    if (item.key === "approval_range") {
      return (
        <Grid container spacing={0.2} sx={{ mt: 0.1 }}>
          <Grid item xs={3} sx={{ backgroundColor: "grey.light" }}>
            <Box
              sx={{ p: 1, backgroundColor: "grey.light" }}
              alignContent="center"
            >
              {item.label}
            </Box>
          </Grid>
          <Grid item xs={3}>
            <Alert severity="info" sx={{ m: 0.3, mt: 0.3, backgroundColor: "#eeeeee" }}>
              <strong>From:</strong> {moment(data[item.key]["start"]).format("ddd MMM, DD YYYY")}
            </Alert>
          </Grid>
          <Grid item xs={3}>
            <Alert severity="info" sx={{ m: 0.3, mt: 0.3, backgroundColor: "#eeeeee" }}>
              <strong>To:</strong> {moment(data[item.key]["start"]).format("ddd MMM, DD YYYY")}
            </Alert>
          </Grid>
          <Grid item xs={3}>

          </Grid>
        </Grid>
      );

    } else if (item.key === "enabled") {
      return (
        <Grid container spacing={0.2} sx={{ mt: 0.1 }}>
          <Grid item xs={3} sx={{ backgroundColor: "grey.light" }}>
            <Box
              sx={{ p: 1, backgroundColor: "grey.light" }}
              alignContent="center"
            >
              {item.label}
            </Box>
          </Grid>
          <Grid item xs={9}>

            {data[item.key] ? <Alert severity="success" sx={{ m: 0.3, mt: 0.3, width: 'auto' }}>Active</Alert> : (<Alert severity="warning" sx={{ m: 0.3, mt: 0.3, width: 'auto' }}>Inactive</Alert>)}

          </Grid>

        </Grid>
      );
    } else {
      return (
        <Grid container spacing={0.1} sx={{ mt: .1 }}>
          <Grid item xs={3} sx={{ backgroundColor: "grey.light" }}>
            <Box
              sx={{ height: '100%', p: 1, backgroundColor: "grey.light" }}
              alignContent="center"
            >{item.label}</Box>

          </Grid>
          <Grid item xs={9}>
            {item.key === 'enabled' && data[item.key] && <Box
              sx={{ p: 1, backgroundColor: "#eeeeee" }}
              alignContent="center"
            >
              {data[item.key] ? 'Active' : 'Inactive'}
            </Box>}
            {item.key !== 'enabled' && data[item.key] && <Box
              sx={{ p: .5 }}
              alignContent="center"
              display="flex"
            >
              {Array.isArray(data[item.key]) ? data[item.key].map(val => (<Alert severity="info" sx={{ m: 0.3, mt: 0.3, backgroundColor: "#eeeeee" }}>{val}</Alert>)) : (<Alert severity="info" sx={{ mr: 1, width: 'auto', backgroundColor: "#eeeeee" }}>{data[item.key]}</Alert>)}
            </Box>}
          </Grid>
        </Grid>
      );
    }
  });
}

export function UserConfirmationAlert(props) {
  const { open, onConfirm, onCancel, index } = props;

  return (
    <Dialog variant="warning" open={open || false}>
      <DialogTitle>Are you sure you want to delete?</DialogTitle>
      <DialogActions>
        <Button onClick={onCancel}>Cancel</Button>
        <Button onClick={() => { onConfirm(index) }} autoFocus>
          OK
        </Button>
      </DialogActions>
    </Dialog>
  );
}

export function PreviewButton(props) {
  return (
    <Button
      disabled={props.disabled}
      variant="contained"
      size="small"
      // color={"green"}
      className="preiview-icon"
      sx={{
        minWidth: "auto",
        // ml: 1,
        // mr: 1,
        marginLeft: '0',
      }}
      onClick={() => {
        props.onClick(props.index);
      }}
    >
      {props.mode === "edit" && (
        <Tooltip title="Preview" arrow={true} disableInteractive>
          <PreviewIcon color="white" fontSize="small" />
        </Tooltip>
      )}
      {props.mode === "preview" && (
        <Tooltip title="Edit" arrow={true} disableInteractive>
          <EditIcon color="white" fontSize="medium" />
        </Tooltip>
      )}
    </Button>
  );
}

export function ToggleSwitch(props) {
  return (
    <Tooltip
      title={props.active ? "Deactivate" : "Activate"}
      arrow={true}
      disableInteractive
    >
      <Switch
        size="medium"
        color="green"
        checked={props.active}
        onChange={props.onChange}
      />
    </Tooltip>
  );
}

export function CancelButton(props) {
  return (
    <Tooltip title="Cancel Changes" arrow={true} disableInteractive>
      <span>
        <Button
          disabled={props.disabled}
          onClick={() => {
            props.onClick(props.index);
          }}
          variant="contained"
          className="navigate-button"
          // color="warning"
          size="small"
          sx={{ minWidth: "auto" }}
        >
          cancel
        </Button>
      </span>
    </Tooltip>
  );
}

export function SaveButton(props) {
  return (
    <Tooltip title="Save Changes" arrow={true} disableInteractive>
      <span>
        <Button
          disabled={props.disabled}
          onClick={props.onClick}
          variant="contained"
          size="small"
          // color="green"
          className="navigate-button"
          sx={{ minWidth: "auto", mr: 1, color: "white.main" }}
        >
          Save
        </Button>
      </span>
    </Tooltip>
  );
}

export function DeleteButton(props) {
  return (
    <Button
      disabled={props.disabled}
      variant="contained"
      size="small"
      color="white"
      sx={{
        color: "error.main",
        minWidth: "auto",
        ml: 1,
        mr: 1,
      }}
      onClick={() => {
        props.onClick(props.index);
      }}
    >
      <Tooltip title="Delete This" arrow={true} disableInteractive>
        <DeleteIcon fontSize="medium"></DeleteIcon>
      </Tooltip>
    </Button>
  );
}

export function CollapseButton(props) {
  return (
    <Tooltip
      title={props.expanded ? "Collapse" : "Expand"}
      arrow={true}
      disableInteractive
    >
      <Button
        variant="contained"
        type="button"
        className="navigate-button"
        sx={{
          backgroundColor: "#333",
          // backgroundColor: "blue.dark",
          borderRadius: 0,
          border: "none",
          margin: 0,
          pb: 1.5,
        }}
        onClick={() => {
          props.onClick(props.index);
        }}
      >
        {!props.expanded && <KeyboardArrowDownOutlined className="arrow-down" />}
        {props.expanded && <KeyboardArrowUpOutlinedIcon className="arrow-up" />}
      </Button>
    </Tooltip>
  );
}

export function AlertError(props) {
  return (
    props.status === "error" && (
      <Alert
        severity="error"
        sx={{
          width: "100%",
          marginTop: -2,
        }}
      >
        {props.message}
      </Alert>
    )
  );
}

export function UserAlertInfo(props) {
  const { status, message } = props;
  return (
    <Alert
      severity={status}
      sx={{
        width: "100%",
        marginTop: -2,
      }}
    >
      <AlertTitle textTransform="capitalize">{status}!</AlertTitle>

      {message}
    </Alert>
  );
}
export function AlertSuccess(props) {
  return (
    props.status === "success" && (
      <Alert
        severity="success"
        sx={{
          width: "100%",
          marginTop: -2,
        }}
      >
        {props.message}
      </Alert>
    )
  );
}

export function SettingsTitle(props) {
  return (
    <Typography
      className='heading-title'
      variant="h5"
      gutterBottom
      sx={{ mr: 3, display: { lg: "inline-block", xs: "none" } }}
    >
      {props.text}
    </Typography>
  );
}
export function Breadcrumb(props) {
  return (
    <Box
      className="breadcrumb"
      sx={{ color: "white.main", position: "absolute", top: -35 }}
    >
      {props.hierarchy[1]}
      <ArrowForwardIosOutlinedIcon
        sx={{ top: 3, position: "relative", height: 14 }}
      ></ArrowForwardIosOutlinedIcon>
      {props.hierarchy[2]}
      <ArrowForwardIosOutlinedIcon
        sx={{ top: 3, position: "relative", height: 14, color: 'blue', }}
      ></ArrowForwardIosOutlinedIcon>
      {props.hierarchy[0]}
    </Box>
  );
}

export function AddNewItem(props) {
  return (
    <Box sx={{ float: "right" }}>
      <Button
        variant="contained"
        size="small"
        className="add-new"
        // color="green"
        // sx={{backgroundColor: "#333", minWidth: "auto", mr: 1, color: "white.main" }}
        onClick={props.onClick}
      >
        <AddNewIcon sx={{ mr: 0.5 }}></AddNewIcon>
        Add New
      </Button>
    </Box>
  );
}
