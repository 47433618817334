export const REGISTER_SUCCESS = "REGISTER_SUCCESS";
export const REGISTER_FAIL = "REGISTER_FAIL";
export const LOGIN_SUCCESS = "LOGIN_SUCCESS";
export const LOGIN_FAIL = "LOGIN_FAIL";
export const LOGOUT = "LOGOUT";
export const SET_MESSAGE = "SET_MESSAGE";
export const CLEAR_MESSAGE = "CLEAR_MESSAGE";


//Reset Password

export const RESET_PASSWORD_SUCCESS = "RESET_PASSWORD_SUCCESS";
export const RESET_PASSWORD_FAIL = "RESET_PASSWORD_FAIL";

export const PROFILE_SUCCESS = "PROFILE_SUCCESS";
export const PROFILE_FAILED = "PROFILE_FAILED";
export const UPDATE_STATE = "UPDATE_STATE";
export const STATE_HIST = "STATE_HIST";

export const DATA_SUCCESS = "DATA_SUCCESS";
export const DATA_FAILED = "DATA_SUCCESS"

//Request Support Ticket
export const SUPPORT_REQUEST_SUCCESS = 'SUPPORT_REQUEST_SUCCESS';
export const SUPPORT_REQUEST_FAILED = 'SUPPORT_REQUEST_FAILED';
export const WORKSPACE_UPDATE = "WORKSPACE_UPDATE"

//SYSTEM SETTINGS PROFILE/PROPERTY/SCHEDULER
export const SYSTEM_SETTINGS_UPDATED = 'SYSTEM_SETTINGS_UPDATED';
export const REPORT_SCHEDULE_SUCCESS = 'REPORT_SCHEDULE_SUCCESS';
export const RATEPUSH_SCHEDULE_SUCCESS = 'RATEPUSH_SCHEDULE_SUCCESS';
