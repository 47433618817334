import moment from "moment";
import {fetchDataSimple} from "../services/data.service"
let queue = [];
const max_batch_size = 10;
let isTrackingAPIInProgress = false;
let lastEventLog =getTimeMilliSeconds();
function getTimeMilliSeconds(){
   return  moment().format("x");
}

let session_id = sessionStorage.getItem("session_id");

export async function   sendLog(event, current_state, updated_state) {
    const common_state = {
        app_state:{...current_state.app_state, ...updated_state.app_state},
        ui_state:{...current_state.ui_state, ...updated_state.ui_state}
    };
    if(!session_id){
        const respuid = await fetchDataSimple({api:"uid",request:{metod:"POST"}});
        sessionStorage.setItem("session_id",respuid.uid)
        session_id=respuid.uid;
    }
    
    lastEventLog=getTimeMilliSeconds();
    
    if (!event) {
        event = {category:"",action:""};
    }
    
   
    const clientside_timestamp = moment().toString();
    let action = event.category + ", " + event.action 
    const record = {
        "session_id": session_id,
        "current_view": common_state.ui_state.active_tiles.join("+"),
        "action": action,
        "state": {...updated_state},
        "domain_url": window.location.hostname,
        "selected_hotel": common_state.app_state.property_id,
        "selected_market": common_state.app_state.market_id,
        "clientside_timestamp": clientside_timestamp,
        //"browser": getBrowser()
    }
    queue.push(record);
    console.log("Log Interface: ADD record", record);
    if(!isTrackingAPIInProgress) {
        sendTrackingRecords();
    } 
  
}

function sendTrackingRecords() {
    isTrackingAPIInProgress=true;
    if (queue.length < max_batch_size) {
        // take all records in queue & send them
        // via sendRecords(recors)
        sendRecords(queue);
        queue=[];
      }
      else {
        // take all records up to batch size (oldest first)
        // and send those, reducing the queue by max_batch_size
        let records = queue.splice(0,max_batch_size-1);
        sendRecords(records);
      }
}
function sendRecords(records){
    let payload ={"records":records};
    
    fetchDataSimple({api:"diagnostics/insert-tracking-record",request:payload}).then((res) => {
        console.log("Log Interface: Sent Record", res.data);
        isTrackingAPIInProgress=false;
        if(queue.length>0){
            sendTrackingRecords();
        }
    }).catch((err) => {
        console.error(err);
        isTrackingAPIInProgress=false;
        if(queue.length>0){
            sendTrackingRecords();
        }
    });
}
