import React, { useState, useEffect, useRef } from "react";
import { ErrorBoundary } from 'react-error-boundary';
import { ErrorHandler } from "../../app/ErrorHandler";
import { Box, Paper, Fade, Tab, Tabs, Chip, Badge, Button, } from "@mui/material";
import WorkspaceControls from "./WorkspaceControls";
import SendRatesButton from "./subComponents/rightComponents/sendRatesButton";
import moment from "moment";
import Chart from "../Chart";
import Spinner from "../Spinner";
import Measure from "../Measure";
import { useDispatch, useSelector } from "react-redux";
import DynamicTable from "../DynamicTable";
import { getData, getPropertyInfo, updateAppState } from "../../actions/appstate";
import { useNavigate } from "react-router-dom";
import { logout } from "../../actions/auth";
import { getTileById, getSeriesById, dateDiff, safeReference, abortAndGetSignal, abortRequestSafe, deepCopy } from "../../app/util";
import { api_status, promises, API_REGISTRY, TILES } from "../../app/config";
import { UPDATE_STATE } from "../../actions/types";
import DataService from "../../services/data.service";
import './workspace_style.scss';
import '../../simple-grid.css';

import FileDownloadOutlinedIcon from '@mui/icons-material/FileDownloadOutlined';
import { getPricingDataFormatted } from "../DynamicTable/tilesData/pricing";

// import AbortController from "abort-controller";

function Workspace(props) {
    const navigate = useNavigate();
    const { property_details } = useSelector(
        (state) => state.auth.profile
    );
    const { app_state, ui_state } = useSelector(
        (state) => state.app.current_state
    );
    const {
        data_status, active_api, active_apis,
        kpi_load, roomtype_pricing_overrides,
        as_of_date, property_id, data_load, workspace_controls, primary_stay_range
    } = app_state;

    const dispatch = useDispatch();
    let [displayData, setDisplayData] = useState([]);
    // const [activeTile, setActiveTile] = useState("otb");
    const [compState, setCompState] = useState({
        active_api: active_api, active_apis: active_apis,
        activeTiles: ui_state.active_tiles,
        displayDataId: app_state.active_api,
        dataLoading: false, dataStatus: {}, dataLoaded: false,
        kpiLoading: false,
        aborted: false
    });

    let compRef = useRef({
        active_api: active_api, active_apis: active_apis,
        activeTiles: ui_state.active_tiles,
        displayDataId: app_state.active_api,
        dataLoading: false, dataStatus: {}, dataLoaded: false,
        kpiLoading: false
    });

    // console.log("setJustLoaded called after commonSuccessHandler", data_status); 
    const {
        left_measure, right_measure, table_view, active_tiles, showspinner
    } = ui_state;

    const {
        dba,
        sdlyOn,
        categoryType,
        clearComparision,
        scopeType,
        pickUpType,
        infoButton,
        pickUpDays,
        gridView,
        downloadButton,
        optionsClick,
        sendRates,
        expand_button,
        marketSelect,
        sdly2019On,
        sdYear,
        isRoomTypePricing
    } = workspace_controls;

    const setInitialState = () => {

    }

    useEffect(() => {
        let isSubscribed = true;
        if (isSubscribed && app_state.data_load && ui_state.showspinner) {
            compRef.current.kpi_load = true;
        }

        return () => (isSubscribed = false);
    }, []);

    //Handle First time after component mounted
    useEffect(() => {
        let isSubscribed = true;
        // debugger;
        //setting DATA call for active_tiles[0]
        //This will be modifed for intelligence scenario
        if (isSubscribed && data_load && !compRef.current.dataLoading) {
            compRef.current.dataLoading = true;
            // setCompState({ ...compState, dataLoading: true });

            // setDisplayData([]);
            let dataStatus = {};
            let activeApis = [];
            let t = active_tiles;
            // debugger;
            // let active_dataId = getTileById(active_tiles[0]).data_sources[0]; debugger;
            for (let i = 0; i < active_tiles.length; i++) {
                let currTile = active_tiles[i];
                let tileDetails = getTileById(currTile);
                //This is temporary until data_sources is not added to the tile config
                // debugger;
                if (!tileDetails.data_sources.length) tileDetails.data_sources = ['OSR'];
                tileDetails.data_sources.map((name) => {
                    if (activeApis.indexOf(name) === -1) {
                        activeApis.push(name);
                    }
                })
            }
            let ai = activeApis;
            activeApis.map((name) => {
                // debugger;
                api_status[name] = 'initiated';
                dataStatus[name] = 'initiated';
            });

            //Now call data calling function
            // debugger;
            fetchData(activeApis);
        }


        if (ui_state.showspinner === true) {
            setDisplayData([]);
            setCompState({ ...compState, dataLoading: true, dataLoaded: false });
        }
        if (kpi_load) {
            compRef.current.kpiLoading = false;
        }

        // debugger;  
        return () => (isSubscribed = false);
    }, [
        data_load,
        kpi_load,
        //active_tiles.join(''),
        //primary_stay_range.date_from, primary_stay_range.date_until,
        //primary_stay_range.as_of_date, as_of_date,
        // sdlyOn,
        showspinner,
        // categoryType,
        // clearComparision,
        // scopeType,
        // pickUpType,
        // pickUpDays,
        // marketSelect,
        // sdly2019On,
        // table_view
        // compState.aborted

    ]);
    //--->

    useEffect(() => {


        dispatch({
            type: UPDATE_STATE,
            payload: {
                app_state: {
                    ...app_state,
                    //kpi_status: { ...new_data_status },
                }
            }
        });
    }, [app_state.kpi_status['OSR'], app_state.kpi_status['OSRContrainedForecast']]);

    const fetchData = (activeApis) => {
        console.log("fetchData called with", app_state);
        // debugger;
        //Load main workspace data
        if (data_load) {
            //setDisplayData([]);
            // setCompState({ ...compState, dataLoading: true });
            let new_data_status = {};
            let mainPromises = [];
            let secondaryPromises = [];
            let main_active_api = '';

            //For cancel all api calls

            let ignore_api_registies = ["OSRYear"];
            if (ui_state.active_tiles.includes('otb')) {
                if (ui_state.active_tiles.length === 1) {
                    ignore_api_registies.splice(0, 0, "OSRSegment");
                }

                if (!workspace_controls.segmented_view) {
                    ignore_api_registies.push("GetRatePlan");
                    //ignore_api_registies.splice(ignore_api_registies.indexOf("GetRatePlan"), 1);
                }
            }


            activeApis.map(activeApi => {
                // debugger;
                //Adding this to reduce otp api calls to one               

                // debugger
                if (api_status[activeApi] !== 'loadings') {

                    if (!ignore_api_registies.includes(activeApi)) {
                        new_data_status[activeApi] = 'loading';
                        app_state.data_status[activeApi] = 'loading';
                        //api_status[activeApi] = 'loading';

                        app_state.active_apis = activeApis;
                        let apiCallStarting = getData({
                            ...app_state, active_api: activeApi,
                            active_apis: app_state.active_apis,
                            data_status: app_state.data_status,
                            active_tiles: ui_state.active_tiles
                        })();
                        mainPromises.push(apiCallStarting);
                        apiCallStarting.then(
                            commonSuccessHandler(activeApi),
                            commonErrorHandler(activeApi)
                        )
                    };

                    compRef.current.dataStatus = new_data_status;
                    //setCompState({ ...compState, dataStatus: { ...new_data_status } })
                }
            });

            if (ui_state.active_tiles.includes('otb')) {
                let activeApi = "GetRatePlan";
                if (compRef.current.dataStatus['GetRatePlan'] !== 'loading') {
                    compRef.current.dataStatus['GetRatePlan'] = 'loading';
                    let GetRatePlan = getData({
                        ...app_state, active_api: "GetRatePlan",
                        active_apis: app_state.active_apis,
                        data_status: app_state.data_status,
                        active_tiles: ui_state.active_tiles
                    })();
                    secondaryPromises.push(GetRatePlan);
                    GetRatePlan.then(
                        commonSuccessHandler(activeApi),
                        commonErrorHandler(activeApi)
                    )
                }
            };

            Promise.allSettled(mainPromises).then(
                (settled) => {
                    let isAborted = false;

                    let aborted = settled.filter(item => item.status === 'rejected' && item.reason.data && (item.reason.data.indexOf('AbortError:') > -1));
                    isAborted = aborted.length ? true : false;
                    if (isAborted) {
                        compRef.current.dataLoading = false;
                        compRef.current.dataLoaded = false;
                        setCompState({ ...compState, dataLoading: false, dataLoaded: false });
                        window.location.reload();
                    } else {
                        // debugger;
                        if (settled[0].status === 'rejected' && settled[0].reason && settled[0].reason.response && settled[0].reason.response.status === 401) {
                            navigate("/login");
                        }
                        let dataAsOf = app_state.primary_stay_range.as_of_date;
                        compRef.current.dataLoading = false;
                        compRef.current.dataLoaded = true;
                        let dataArray = settled.map((item) => {
                            if (item.status === 'fulfilled') {
                                if (item.value.data_id === 'OSR' && item.value.meta && item.value.meta.as_of) {
                                    // debugger;
                                    dataAsOf = item.value.meta.as_of;
                                    if (!app_state.data_as_of) {
                                        app_state.data_as_of = dataAsOf;
                                    }
                                }
                                // debugger;
                                return item.value;
                            } else {
                                return { data: [], status: 'error', data_id: item.reason.data_id, message: item.reason.message };
                            }
                        });

                        // debugger;
                        if (settled.length === 1) {

                            setDisplayData(prev => {
                                return dataArray;
                            });
                            // setCompState({ ...compState, dataLoading: false, dataLoaded:true });
                        };
                        if (settled.length > 1) {
                            // let dataArray = settled.map(({ value }) => value);
                            if (active_tiles.length > 1) {

                                setDisplayData(prev => {
                                    return deepCopy(dataArray);
                                });
                            } else {
                                
                                if (['compset', 'market'].includes(active_tiles[0])) {
                                    // debugger;
                                    if (dataArray.length) {
                                        if (scopeType === 'Date') {
                                            setDisplayData([dataArray[0]]);
                                            main_active_api = dataArray[0].data_id;
                                        }
                                        if (scopeType === 'Dba') {
                                            setDisplayData([dataArray[1]]);
                                            main_active_api = dataArray[1].data_id;
                                        }
                                    }

                                    // debugger;
                                } else {
                                    //Adding this to reduce otb api calls
                                    let osr = dataArray.filter((data) => data.data_id === 'OSR');
                                    if (osr.length) {
                                        let OSRSegment = deepCopy(osr[0]);
                                        OSRSegment.data_id = "OSRSegment";
                                        dataArray.splice(1, 0, OSRSegment);
                                        let OSRYear = deepCopy(osr[0]);
                                        OSRYear.data_id = "OSRYear";
                                        dataArray.splice(2, 0, OSRYear);
                                        // dataArray.push(OSRSegment);
                                        // dataArray.push(OSRYear);
                                    }

                                    // debugger;
                                    setDisplayData(prev => {
                                        return deepCopy(dataArray);
                                    });
                                }
                            }
                        };

                        // debugger;

                        if (app_state.primary_stay_range.as_of_date !== dataAsOf) {
                            ui_state.showspinner = false;
                            app_state.data_load = false;
                            app_state.primary_stay_range.as_of_date = dataAsOf;
                            app_state.date_range_type = 'asOfChanged';
                            app_state.active_api = main_active_api;

                            dispatch({
                                type: UPDATE_STATE,
                                payload: {
                                    app_state: {
                                        ...app_state,
                                        // data_as_of:  dataAsOf,  
                                        // primary_stay_range: { ...app_state.primary_stay_range, as_of_date: dataAsOf },
                                        // date_range_type: 'asOfChanged',
                                        // data_load: false,

                                        // active_api: main_active_api
                                    },
                                    ui_state: {
                                        ...ui_state,
                                        // showspinner: false
                                    }
                                }
                            });
                        } else {
                            ui_state.showspinner = false;
                            app_state.data_load = false;
                            app_state.active_api = main_active_api;
                            app_state.api_call_complete = true;
                            console.log(displayData)
                            dispatch({
                                type: UPDATE_STATE,
                                payload: {
                                    app_state: {
                                        ...app_state,
                                        // primary_stay_range: {...app_state.primary_stay_range, as_of_date:dataAsOf},
                                        // date_range_type: '',
                                        // data_load: false,
                                        // active_api: main_active_api
                                    },
                                    ui_state: {
                                        ...ui_state,
                                        // showspinner: false
                                    }
                                }
                            });
                        }

                        // debugger;
                        setCompState({ ...compState, dataLoading: false, dataLoaded: true, dataStatus: { ...new_data_status } })

                        console.log('Promise.allSettled', settled);
                    }
                })
        }
        //-->

        //For KPI data only
        // debugger
        console.log("selectedAPI kpi_load", String(kpi_load), String(compRef.current.kpiLoading));
        if ((compRef.current.kpi_load || kpi_load) && !compRef.current.kpiLoading) {
            compRef.current.kpiLoading = true;
            // debugger;
            //setCompState({ ...compState, kpiLoading: true });
            let kpiPromises = [];
            let kpi_datas = ['OSRContrainedForecast'];

            if (!ui_state.active_tiles.includes('otb')) {
                if (kpi_datas.indexOf('OSR') < 0) {
                    kpi_datas.push('OSR');
                }
            }
            app_state.kpi_status['OSR'] = 'loading';
            app_state.kpi_status['OSRContrainedForecast'] = 'loading';
            let new_data_status = {
                OSRContrainedForecast: 'loading',
                OSR: 'loading'
            };
            kpi_datas.map((active_api) => {
                let kpiPromise = getData({
                    ...app_state, active_api: active_api,
                    active_apis: app_state.active_apis,
                    data_status: app_state.data_status,
                    active_tiles: ui_state.active_tiles
                })();
                kpiPromises.push(kpiPromise);
                kpiPromise
                    .then((data) => {
                        // debugger;  
                        if (data && data.ok) {
                            new_data_status[data.data_id] = 'loaded';
                            app_state.kpi_status[data.data_id] = 'loaded';
                            if (app_state.kpi_status['OSR'] && app_state.kpi_status['OSRContrainedForecast']) {
                                app_state.kpi_load = false;
                            }
                            dispatch({
                                type: UPDATE_STATE,
                                payload: {
                                    app_state: {
                                        ...app_state,
                                        //kpi_status: { ...new_data_status },
                                    }
                                }
                            });
                        }
                    })
                    .catch((err) => {
                        //  debugger;
                        new_data_status[err.data_id] = 'loaded';
                        app_state.kpi_status[err.data_id] = 'loaded';

                        dispatch({
                            type: UPDATE_STATE,
                            payload: {
                                app_state: {
                                    ...app_state,
                                    // kpi_status: { ...new_data_status },
                                },
                                ui_state: {
                                    ...ui_state
                                }
                            }
                        });
                        // debugger;
                    });
            });
            dispatch({
                type: UPDATE_STATE,
                payload: {
                    app_state: {
                        ...app_state,
                        // kpi_status: { ...new_data_status },
                    }
                }
            });
            //on all kpiPromises
            Promise.allSettled(kpiPromises).then((result) => {
                // debugger;
                compRef.current.kpiLoading = false;
                compRef.current.kpi_load = false;
                app_state.kpi_load = false;
                setCompState({ ...compState, kpiLoading: false });
                let new_data_status = {};
                result.map(({ status, value }, i) => {
                    if (status === 'fulfilled' && value) {
                        new_data_status[value.data_id] = "loaded";
                    } else {
                        new_data_status[kpi_datas[i]] = "loaded";
                    }
                    // app_state.kpi_status[value.data_id] = 'loaded';
                });
                if (app_state.kpi_status['OSR'] && app_state.kpi_status['OSRContrainedForecast']) {
                    app_state.kpi_load = false;
                }
                dispatch({
                    type: UPDATE_STATE,
                    payload: {
                        app_state: {
                            ...app_state,
                            // kpi_status: { ...new_data_status },
                            // kpi_load: false
                        }
                    }
                });
            });
        }
        //-->

        // console.log("fetchData end with data_status:", compState);
    };

    function commonSuccessHandler(data_id) {
        // debugger;
        return handleData;
        function handleData(rs) {
            let new_data_status = {};
            if (rs && rs.status === "success") {
                api_status[data_id] = 'loaded';

                if (data_id === "GetRatePlan") {
                    compRef.current.dataStatus['GetRatePlan'] = 'loaded';
                }

                if (data_id === 'OSR') {
                    app_state.kpi_status['OSR'] = 'loaded';
                    // debugger
                    dispatch({
                        type: UPDATE_STATE,
                        payload: {
                            app_state: {
                                ...app_state,
                                // kpi_status: { ...new_data_status },
                                // kpi_load: false
                            },
                            ui_state: { ...ui_state }
                        }
                    });
                }
                //let cached = DataService.getSeriesById(data_id);
                // console.log('commonSuccessHandler sent for data_status update active_dataId', data_id);
            } else {
                // console.log('commonSuccessHandler Data NOT OK for:', data_id);
                new_data_status[data_id] = 'error';
                api_status[data_id] = 'error';
            }
        }
    }

    function commonErrorHandler(data_id, active_dataId) {
        let new_data_status = {};
        return handleError;
        function handleError(data) {
            // debugger;
            console.log('commonErrorHandler called for: ', app_state, data, data_id, active_dataId);
            api_status[data_id] = "error";
            new_data_status[data_id] = "error";
        }

    }

    const acceptAllRates = () => {
        const { date_from, date_until } = primary_stay_range;

        let stay_date = date_from;
        const propertyInfo = getPropertyInfo(property_details, property_id);
        const room_settings = safeReference(propertyInfo.system_settings[0], [], ["inventory_settings", "current", "room_settings"])
        const pricingData = getPricingDataFormatted(app_state, true);
        const numDays = pricingData.length
        let baseRoom = room_settings.filter(room => room.is_base)[0];
        for (let index = 0; index < numDays; index++) {
            if (!roomtype_pricing_overrides[stay_date]) {
                roomtype_pricing_overrides[stay_date] = {}
            }
            room_settings.forEach(room => {
               
                let override = {
                    SellRate: 0,
                }

                if (room.is_base === true) {
                    override = {
                        lodgiqRateDouble: true,
                        lodgiqRateSingle: true
                    }
                    override.SellRate = pricingData[index][room.room_code].RecSellRate;
                } else {
                    let baseRate = pricingData[index][baseRoom.room_code].SellRate
                    override = {
                        curr_diff_arrow: true,
                        current_differential: pricingData[index][room.room_code].lodgiq_differential,
                        lodgiq_diff_checked: true,
                    }
                    override.SellRate = pricingData[index][room.room_code].RecSellRate;
                }
                if(pricingData[index][room.room_code].SellRate !==  pricingData[index][room.room_code].RecSellRate){
                    if (!roomtype_pricing_overrides[stay_date][room.room_code]) {
                        roomtype_pricing_overrides[stay_date][room.room_code] = {}
                    }
                    roomtype_pricing_overrides[stay_date][room.room_code] = { ...override };

                }
               

            })
            stay_date = moment(stay_date).add(1, 'days').format("YYYY-MM-DD");
        }
        dispatch(updateAppState("change_pricing_rates", {
            app_state: {
                roomtype_pricing_overrides: roomtype_pricing_overrides,
            }
        }))
    }

    const OnDropHandler = (event) => {
        event.preventDefault();
        let tileDetails = JSON.parse(event.dataTransfer.getData("tileDetails"));
        let activeTile = TILES[ui_state.active_tiles];
        // debugger
        props.onTileMerge(tileDetails);
        // debugger;
    };
    // console.log("Rerendering with", displayData, Date.now());

    const renderWorkspacePanel = () => {
        let tiles = ui_state.active_tiles;
        let titles = [];
        let title = "";
        let user_view = app_state.user_view;
        tiles.map((tile) => {
            let title = TILES[tile].title ? TILES[tile].title : TILES[tile].label;
            let id = TILES[tile].id;
            if (tile === "pickup") {
                title = app_state.workspace_controls.pickUpType + " " + title
            }
            titles.push({ label: title, id: id });
        });
        ui_state.show_chart_icon = true;
        if (ui_state.active_tiles[0] === "restrictions") {
            ui_state.table_view = true;
            ui_state.workspace_view = "table";
            ui_state.show_chart_icon = false;
        }
        if (ui_state.active_tiles[0] === "market_summary") {
            ui_state.table_view = true;
            ui_state.workspace_view = "table";
            ui_state.show_chart_icon = false;
        }
        if (ui_state.active_tiles[0] === "hotel_summary") {
            ui_state.table_view = true;
            ui_state.workspace_view = "table";
            ui_state.show_chart_icon = false;
        }
        if (ui_state.active_tiles[0] === "forcast") {
            ui_state.table_view = true;
            ui_state.workspace_view = "table";
            ui_state.show_chart_icon = true;
        }
        if (ui_state.active_tiles[0] === "pace") {
            // title = "Booking Curve";
            //ui_state.table_view = true;
            //ui_state.show_chart_icon = false;
        }
        if (ui_state.active_tiles[0] === "pickup") {
            // title = app_state.workspace_controls.pickUpType + "  Pickup";
        }
        // title = titles.join(" + ");
        // debugger;
        let measures = (
            <>
                {ui_state.left_measures_list && ui_state.left_measures_list.length > 0 && (
                    <Measure
                        options={ui_state.left_measures_list}
                        selectedMeasure={
                            (() => {
                                //debugger;  
                                let selected = ui_state.left_measures_list.filter((measure) => measure.value === ui_state.left_measure);
                                return selected.length ? selected[0] : ui_state.left_measures_list[0];
                            })()
                        }
                        side="left"
                    />
                )}
                {ui_state.right_measures_list && ui_state.right_measures_list.length > 0 && (
                    <Measure
                        options={ui_state.right_measures_list}
                        selectedMeasure={
                            (() => {
                                //debugger;  
                                let selected = ui_state.right_measures_list.filter((measure) => measure.value === ui_state.right_measure);
                                return selected.length ? selected[0] : ui_state.right_measures_list[0];
                            })()
                        }
                        side="right"
                    />
                )}
            </>
        );
        let workspacePanelClasses = 'work-space controls';

        switch (ui_state.workspace_view) {
            case 'table':
                workspacePanelClasses += ' table-view'
                break;
            case 'chart':
                workspacePanelClasses += ' chart-view'
                break;

            default:
                break;
        }
        const pricingButtons = (<Box className="pricing-buttons">

            {isRoomTypePricing === true && (<> <Button className='closeall' onClick={() => {
                dispatch(updateAppState("change_pricing_rates", {
                    app_state: {
                        roomtype_pricing_overrides: {},
                        clearAllRoomTypePricing: true,
                    }
                }))
            }} size="small" sx={{ m: "auto" }}>
                <span>Clear all</span>
            </Button>
                <Button className='acceptall' size="small" onClick={acceptAllRates} sx={{ m: "auto" }}>
                    <span>Accept all</span>
                </Button></>)}
            {ui_state.workspace_view !== "chart" && <SendRatesButton />}


        </Box>)
        return (
            <Paper
                className={workspacePanelClasses}
                elevation={1}
                sx={{ borderRadius: 0 }}
            >
                <Box>
                    <WorkspaceControls />

                    {titles.length === 1 && (<Box
                        className="title single-title"
                        style={{ textTransform: "uppercase", textAlign: "center" }}
                    >
                        {titles[0].label}
                    </Box>)}
                    {titles.length > 1 && (<Box
                        className="title"

                        style={{ textTransform: "uppercase", textAlign: "center" }}
                    >
                        {titles.map((title, i) => {
                            return <Chip className="merged-title" sx={{ mr: 1 }} variant="outlined" color="info" size="small" label={title.label}
                                onDelete={handleUnslect(title.id)}
                            />
                        })}
                        {titles.length > 2 && <Chip sx={{ mr: 1 }} variant="outlined" color="warning" size="small" label="Clear"
                            onDelete={handleUnslect('all')}
                        />}
                    </Box>)}
                    {active_tiles[0] === "pricing" && pricingButtons}
                    {ui_state.workspace_view == "chart" && measures}
                </Box>
            </Paper>
        );
    };
    // console.log("State Updating for: Workspace", displayData, app_state, ui_state);

    const handleUnslect = (id) => {
        // debugger;
        return (event) => {
            let thisIndex = ui_state.active_tiles.indexOf(id);
            let activeTiles = ui_state.active_tiles;
            if (thisIndex !== -1) {
                activeTiles.splice(thisIndex, 1);
            } else {
                activeTiles.splice(0);
                activeTiles.push('otb');
            }


            dispatch(
                updateAppState("change_active_tiles", {
                    app_state: {
                        ...app_state,
                        date_range_type: 'rangeTileChange_'+activeTiles.join(''),
                        // data_load: true,
                        workspace_controls: { ...app_state.workspace_controls }
                    },
                    //app_state: {active_dataId, active_api, active_apis, data_load: true },
                    ui_state: {
                        ...ui_state,
                        active_tiles: activeTiles,
                        // workspace_view,
                        showspinner: true
                    },
                })
            );
        }

    }

    return (
        <Box item lg={12} id="mychartview" sx={{ md: { pb: 3 } }}>
            {renderWorkspacePanel()}

            {showspinner && ui_state.active_tiles[0] === "restrictions"  && <Box className="workspace-spinner"> <Spinner /></Box>}
           
            {showspinner && ui_state.active_tiles[0] !== "restrictions"&& <Box className="workspace-spinner"> <Spinner /></Box>}

            {!showspinner && (ui_state.workspace_view === 'chart') && (
                <Fade in={true} timeout={500}>
                    <Box onDragOver={(e) => { e.preventDefault() }} onDrop={OnDropHandler} className="workspace-chart-container">
                        <ErrorBoundary FallbackComponent={ErrorHandler}>
                            <Chart compDisplayData={deepCopy(displayData)} />
                        </ErrorBoundary>
                    </Box>
                </Fade>
            )}
            {!showspinner && (ui_state.workspace_view === 'table') && (
                <Fade in={true} timeout={500}>
                    <Box onDragOver={(e) => { e.preventDefault() }} onDrop={OnDropHandler} className="workspace-table-container">
                        {/* {(active_tiles[0] === "pricing" && isRoomTypePricing === true) && <RoomTypeFilter />} */}
                        <ErrorBoundary FallbackComponent={ErrorHandler}>

                            {displayData && displayData.length && ui_state.active_tiles[0] === "restrictions" && app_state.kpi_status['OSR'] === "loaded" && app_state.kpi_status['OSRContrainedForecast'] === "loaded" &&  <DynamicTable compDisplayData={deepCopy(displayData)} />}
                            {displayData && displayData.length &&  ui_state.active_tiles[0] !== "restrictions" && <DynamicTable compDisplayData={deepCopy(displayData)} />}
                            

                        </ErrorBoundary>
                    </Box>
                </Fade>)
            }

        </Box>
    );


}

export default Workspace;

