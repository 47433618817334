import React from 'react'
import { CardContent, Chip, Container, Table, TableCell, TableContainer, TableHead, TableRow, TableBody, Tooltip } from '@mui/material';
import { convertToPercent, daysCountFromRange } from '../../app/util';
import { getCodeDetails } from './DisplayData';
import { useSelector } from 'react-redux';

function RatePlanListTotal(props) {
    const state = useSelector(appState => appState);
    const { auth, app } = useSelector(state => state);
    const { profile } = auth;
    const { app_state, ui_state } = useSelector(
        (state) => state.app.current_state
    );
    let { data } = props;
    let dataSet = [];
    if (data && data.length > 0) {
        let data1 = Object.keys(data[0]);
        data1.map((item) => {
            dataSet.push(data[0][item]);
        })
    }
    let data2 = dataSet.sort((a, b) => b.data.primary.Rooms - a.data.primary.Rooms);
    let daysCount = daysCountFromRange(app_state);
    let dataAvailable = false;

    return (
        <>
            {(dataSet && dataSet.map((item, index) => {
                    if (item.data.primary.Rooms !== 0) {
                        dataAvailable = true;
                        let bgcolor = (index % 2 === 0) ? "" : "#ebebeb";
                        return (
                            <TableRow key={index} className='rateplan-row-col-content' sx={{ background: bgcolor }}>
                                <TableCell component="th" scope="row" className='rateplan-row-col-content border-right'>
                                    <Tooltip title={getCodeDetails(item.ratePlanCode, app_state)} arrow followCursor>
                                        <div>{item.ratePlanCode}</div>
                                    </Tooltip>
                                </TableCell> <TableCell component="th" scope="row">
                                    {convertToPercent(item.data.primary.Occ / daysCount)}%
                                </TableCell> <TableCell component="th" scope="row" sx={{ textAlign: "center" }}>
                                    {item.data.primary.Rooms}
                                </TableCell> <TableCell component="th" scope="row">
                                    ${((item.data.primary.ADR)).toFixed(2)}
                                </TableCell>
                            </TableRow>
                        )
                    }
                })
            )}

            {((dataSet.length === 0 || !dataAvailable) && <>
                <TableRow>
                    <TableCell colSpan={4} className='table-heading' sx={{ textAlign: "center" }}>No Rate Plan Found!</TableCell>
                </TableRow>
            </>
            )}


        </>
    )
}

export default RatePlanListTotal
