import React from 'react';
import { Navigate } from 'react-router-dom';
import {getFromSession} from "./util"
export const PrivateRoute = ({ component: Component, ...rest }) =>{
  return getFromSession('token') ? (
    <Component {...rest} />
  ) : (
    <Navigate
      to={{ pathname: '/login', state: { from: rest.location } }}
    />
  )
}