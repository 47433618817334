
import { Box, Select, FormControl, MenuItem, Tooltip } from '@mui/material'
import { useState } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { updateAppState } from '../../../../actions/appstate';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';

const PickUpType = () => {
    const controls = useSelector(state => state.app.current_state.app_state.workspace_controls);
    const [pickType, setPickType] = useState(controls.pickUpType);
    const dispatch = useDispatch();
    let standardText = "Standard is the default pickup, which compares early morning reservations between current and previous as of dates.";
    let latestText = "Latest pickup compares booking data between evening of yesterday to latest data of today.";

    let info;

    if(controls.pickUpType === "Latest"){
        info = "Latest pickup compares booking data between evening of yesterday to latest data of today.";
    }else{
        info = "Standard is the default pickup, which compares early morning reservations between current and previous as of dates.";
    }

    const [infoText, setInfoText] = useState(standardText);

    const handleChange = (type, value) => {
        controls.pickUpType = value;
        dispatch(updateAppState(type, { app_state: { workspace_controls: controls, data_load: true }, ui_state: { showspinner: true } }));
        (controls.pickUpType === "Latest") ? setInfoText(latestText) : setInfoText(standardText);
   
    }

    return (
        <div style={{position:'relative'}}>
            <Box id="pickupBox" sx={{ position: "relative", paddingLeft: 0, backgroundColor: 'blue', height: 1 / 2 }}>
                <Box sx={{ display: 'block', fontSize: '75%', fontWeight: 'bold' }}>PICKUP TYPE</Box>
                <FormControl size="small" sx={{ minWidth: "100%", borderRadius: "16px" }}>
                    {/* <Select value={pickType} sx={{ backgroundColor: "grey.300", height: "27px", mt: "5px" }} onChange={(e) => { */}
                    <Select fullWidth className='tabular-data-filter' value={pickType} sx={{ height: "27px", minWidth: "90px", mt: "5px" }} onChange={(e) => {

                        setPickType(e.target.value);
                        handleChange("pickup_select", e.target.value);
                    }}>
                        <MenuItem value="Standard" className='tabular-data-filter'> Standard </MenuItem>
                        <MenuItem value="Latest" className='tabular-data-filter'> Latest </MenuItem>
                    </Select>

                </FormControl>
            </Box>
            <Box sx={{ position: "absolute", right:0, mr: 0.2, top: -1 }}>
                <Tooltip placement="right-start" arrow title={info}>
                    <InfoOutlinedIcon fontSize="small" sx={{ fontSize:'18px', color: "#ff0000" }} />
                    {/* <svg style={{ color: "#ff0000"}} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 256 256"><rect width="256" height="256" fill="none"/><circle cx="128" cy="128" r="96" fill="none" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="16"/><path d="M120,120a8,8,0,0,1,8,8v40a8,8,0,0,0,8,8" fill="none" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="16"/><circle cx="124" cy="84" r="12"/></svg> */}
                </Tooltip>
            </Box>
        </div>
    )
}


export default PickUpType