
import { Box, Select, FormControl, MenuItem, Tooltip } from '@mui/material'
import { useState } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { updateAppState } from '../../../../actions/appstate';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import Chip from '@mui/material/Chip';
import Grid from '@mui/material/Grid';

const TableType = () => {
    const controls = useSelector(state => state.app.current_state.app_state.workspace_controls);
    const [tableType, setTableType] = useState(controls.tableType);
    const dispatch = useDispatch();

    const handleChange = (type, value) => {
        controls.tableType = value;
        dispatch(updateAppState(type,
            { app_state: { workspace_controls: controls, data_load: true }, ui_state: { showspinner: true } }));
    }


    return (
        <>
            <Box id="tabletype" sx={{ position: "relative", paddingLeft: 0, backgroundColor: 'blue', height: 1 / 2 }}>
                <Box sx={{ display: 'block', fontSize: '75%', fontWeight: 'bold', minHeight: .6 }}></Box>
                <FormControl size="small" sx={{ minWidth: "100%", borderRadius: "16px" }}>
                    <Select className='tabular-data-filter' value={tableType} sx={{ height: "27px", minWidth: "112px", mt: "5px" }} onChange={(e) => {
                        setTableType(e.target.value);
                        handleChange("table_type", e.target.value);
                    }}>
                        <MenuItem value="monthly" className='tabular-data-filter'> Monthly </MenuItem>
                        <MenuItem value="daily" className='tabular-data-filter'> Daily </MenuItem>
                    </Select>
                </FormControl>
            </Box>
        </>
    )
}

export default TableType