
import { Button, Box } from '@mui/material'
import { useDispatch, useSelector } from 'react-redux';
import { updateAppState } from '../../../../actions/appstate';



const ClearComparisionPicker = () => {
    const controls = useSelector(state => state.app.current_state.app_state.workspace_controls);
    const app_state = useSelector(state => state.app.current_state.app_state);
    const dispatch = useDispatch();
    let enableClearBtn = controls.clearComparision;
    const handleClick = (type, value) => {
        const updateTypes = [type];
        controls.clearComparision = value;
        controls.compareTo = null;
        // controls.sdlyOn = false;
        // debugger;
        dispatch(updateAppState("updateTypes", {
            app_state: { workspace_controls: controls, data_load: true },
            ui_state: { showspinner: true }
        }));
        // alert();
    }

    return (
        <>
            <Box alignItems="center" justifyContent="center" sx={{height: 1 / 2 }}>
                <Button className='control-btn infoBtn' variant="contained" style={{ lineHeight:1.80, background: `rgb(53,131,184)` }} sx={{
                    display: "block", fontSize: "12px", p:0.3, pl:1, pr:1,textTransform: "uppercase",
                }} onClick={(e) => handleClick("clear_comparision_button_click", !controls.clearComparision)} disabled={!enableClearBtn}>
                    Clear Comparison
                </Button>
            </Box>
             
        </>
    )
}

export default ClearComparisionPicker