import React, { useState, useEffect, useRef } from 'react';
import Calendar from 'react-calendar';
import moment from 'moment';
import './style.scss';
import { deepCopy, isMobileDevice, dateFormat, dateDiff, in_array, calculateCompStayRange,toSdly } from "../../app/util";
import { updateAppState } from "../../actions/appstate";
import { Button, Popover, ButtonGroup, TextField, Grid } from "@mui/material/";
import { ArrowBackIos as ArrowBackIosIcon, ArrowForwardIos as ArrowForwardIosIcon } from '@mui/icons-material';
import { useSelector, useDispatch } from "react-redux";
import { useDateRanges } from "../../hooks/date-picker-logics";
import './styles.css';

import { style } from '@mui/system';
// You can customize style by copying asset folder.

export default function SmartDatePicker(props) {
  const dateRanges = useDateRanges();
  const { primary_stay_range } = dateRanges;
  let asofDate = props.dateRange.as_of_date;
  const dispatch = useDispatch();
  let [tempDateRange, setTempDateRange] = useState(primary_stay_range)
  let [displayDateRange, setDisplayDateRange] = useState(primary_stay_range)
  let [as_of_date, setAsOfDate] = useState(asofDate)
  const [isOpen, setIsOpen] = useState(false)
  let [range_type, setRangeType] = useState("range");
  let [range_segment, setRangeSegment] = useState("");
  let [calendarOn, setCalendarOn] = useState("range");
  const [datePickerConfig, setDatePickerConfig] = useState(props.datePickerConfig)
  const [applyAsOfBtn, setApplyAsOfBtn] = useState({});
  const [initialLoad, setIntitialLoad] = useState({});
  const [showRangeSegment, setShowRangeSegment] = useState(true);
  const [anchorEl, setAnchorEl] = React.useState(null);
  let { navigation } = datePickerConfig;
  const { current_state } = useSelector((state) => state.app);
  const { app_state, ui_state } = current_state;
  const { active_tiles } = ui_state;

  const compRef = useRef({ rendered: 1 });


  const openDatePicker = Boolean(anchorEl);
  const id = openDatePicker ? 'date-picker-popover' : undefined;

  let singleDate = moment(tempDateRange.date_from, "YYYY-MM-DD").toDate();
  let asOfDate = moment(as_of_date, "YYYY-MM-DD").toDate();
  let selector = (<span></span>);

  const [dateRange, setDateRange] = useState([moment(tempDateRange.date_from).toDate(), moment(tempDateRange.date_until).toDate()]);
  let inputDateFrom = moment(tempDateRange.date_from).format("MM/DD/YYYY");
  let inputDateUntil = moment(tempDateRange.date_until).format("MM/DD/YYYY");
  let { date_from, date_until } = app_state.primary_stay_range;
  // let { date_from, date_until } = dateRanges.primary_stay_range;

  // const calculateCompStayRange = function (params) {
  //   let app_state = { primary_stay_range: params.primary_stay_range };
  //   let daysDiff = dateDiff(app_state.primary_stay_range.date_until, app_state.primary_stay_range.date_from);
  //   // let fromDateLastYear = new moment(app_state.primary_stay_range.date_from).subtract(1, 'year').startOf('months').format("YYYY-MM-DD");
  //   let fromDateLastYear = new moment(app_state.primary_stay_range.date_from).subtract(1, 'year')
  //   .isoWeek(primaryAsOf.isoWeek())
  //   .isoWeekday(primaryAsOf.isoWeekday());
  //   let untilDateLastYear = new moment(fromDateLastYear).add(daysDiff, 'day').format("YYYY-MM-DD");
  //   let primaryAsOf = new moment(app_state.primary_stay_range.as_of_date);
  //   let sdlyAsOf = new moment(app_state.primary_stay_range.as_of_date).subtract(1, 'year')
  //     .isoWeek(primaryAsOf.isoWeek())
  //     .isoWeekday(primaryAsOf.isoWeekday());
  //   let cAsOfDate = sdlyAsOf.format("YYYY-MM-DD");
  //   let comp_stay_range = { date_from: fromDateLastYear, date_until: untilDateLastYear, comp_as_of_date: cAsOfDate, as_of_date: cAsOfDate };
  //   return comp_stay_range;
  // }

  useEffect(() => {
    let isSubscribed = true;
    let dr = dateRanges;
    let tr = tempDateRange;
    const { date_from, date_until } = dateRanges.primary_stay_range;
    // let dr = app_state.primary_stay_range;
    // let tr = tempDateRange;
    //setTempDateRange({ ...tempDateRange, ...dateRanges.primary_stay_range });
    //setDisplayDateRange({ ...displayDateRange, ...dateRanges.primary_stay_range });

    // Don only for very first time this component renders
    // debugger;
    // if (compRef.current.rendered === 1) {
    //Setting comp stay dates
    if (app_state.date_range_type && app_state.date_range_type !== 'propertyChanged') {
      let comp_stay_range = calculateCompStayRange({ primary_stay_range });
      //  debugger; 
      //Changing as of date to date available as of date

      if (app_state.date_range_type === 'scopeTypeChanged_Dba') {
        let {date_from, date_until} = app_state.primary_stay_range;
        let from_value = moment(date_from);
        setRangeType('single_date');
        setCalendarOn('single_date');
        onDateChange(null, [from_value], 'single');

        applyChange('singleStaydates');
      }
      if (app_state.date_range_type === 'logsSingleDate') {
        let {date_from, date_until} = dateRanges.primary_stay_range;
        let from_value = moment(date_from);
        setRangeType('single_date');
        setCalendarOn('single_date');
        onDateChange(null, [from_value], 'single');

        applyChange('singleStaydates');
      }
      
      if (app_state.date_range_type === 'logsDateRange') {
        // debugger;
        let {date_from, date_until} = dateRanges.historicalRange;
        let from_value = moment(date_from);
        let until_value = moment(date_until);
        onDateChange(null, [from_value], 'date_from');
        onDateChange(null, [until_value], 'date_until');
        setRangeType('range');
        applyChange('staydates');
      }
      if (app_state.date_range_type === 'scopeTypeChanged_Date') {
        // debugger;
        // let {date_from, date_until} = app_state.primary_stay_range;
        let from_value = moment(date_from);
        let until_value = moment(date_until);
        onDateChange(null, [from_value], 'date_from');
        onDateChange(null, [until_value], 'date_until');
        setRangeType('range');
        applyChange('staydates');
      }
      if (app_state.date_range_type === 'asOfChanged') {
        as_of_date = app_state.primary_stay_range.as_of_date;
        setAsOfDate(as_of_date);
        setTempDateRange({ ...tempDateRange, as_of_date });
        setDisplayDateRange({ ...displayDateRange, as_of_date });
        setRangeType('range');
        setCalendarOn('range');
        applyChange('as_of_date');
      }
      dispatch(updateAppState('comp_range', {
        app_state: {
          ...app_state,
          primary_stay_range: { ...app_state.primary_stay_range },
          comp_stay_range: { ...app_state.comp_stay_range, ...comp_stay_range }
        },
        ui_state: { ...ui_state }
      }, "datepicker_update"))
      // }


      if (app_state.date_range_type.split('_')[0] === "rangeTileChange") {
        // debugger;
        let from_value = moment(date_from);
        let until_value = moment(date_until);
        onDateChange(null, [from_value], 'date_from');
        onDateChange(null, [until_value], 'date_until');
        if (app_state.workspace_controls.scopeType === 'Dba') {

          setRangeType('single_date');
          setCalendarOn('single_date');
          applyChange('singleStaydates');
        } else {
          setRangeType('range');
          setCalendarOn('range');
          applyChange('staydates');
        }
      }
      if (app_state.workspace_controls.scopeType === 'Dba') {
        if (app_state.date_range_type === 'single_date') {
          // debugger;
          let from_value = moment(date_from);
          setRangeType('single_date');
          setCalendarOn('single_date');
          onDateChange(null, [from_value], 'single');

          applyChange('singleStaydates');
        }
        if (app_state.date_range_type === 'singlePace') {
          // debugger;
          let from_value = moment(date_from);
          setRangeType('single_date');
          setCalendarOn('single_date');
          onDateChange(null, [from_value], 'single');
          applyChange('staydates');
        }
        if (app_state.workspace_controls.scopeType === "Dba") {
          tempDateRange.date_until = displayDateRange.date_from;
          let from_value = moment(date_from);
          setRangeType('single_date');
          setCalendarOn('single_date');
          onDateChange(null, [from_value], 'single');
          applyChange('staydates');
        }
        // else {
        //   tempDateRange.date_from = moment().startOf('month').format("YYYY-MM-DD");
        //   tempDateRange.date_until = moment().endOf('month').format("YYYY-MM-DD");
        // }

      }

      if (app_state.date_range_type === 'range') {
        // debugger;
        let from_value = moment(date_from);
        let until_value = moment(date_until);
        onDateChange(null, [from_value], 'date_from');
        onDateChange(null, [until_value], 'date_until');
        setRangeType('range');
        applyChange('staydates');
        // dispatch(updateAppState("reset date range", {
        //   app_state: {
        //     date_range_type: '',
        //   }
        // }));
      }

      if (app_state.date_range_type === 'compAsOfChanged') {
        // debugger;
        // let from_value = moment(date_from);
        // let until_value = moment(date_until);
        // onDateChange(null, [from_value], 'date_from');
        // onDateChange(null, [until_value], 'date_until');
        //setRangeType('range');
        //applyChange('compAsOfChanged');
      }


      //applyChange('resetRange');

      if (app_state.date_range_type === 'pricingRoomType') {
        rangeSegmentChange(30);
        setRangeType('range');
        applyChange('staydates');
      }
    }
    // debugger;
    if (app_state.date_range_type === 'propertyChanged') {
      
      tempDateRange = { ...tempDateRange, ...app_state.primary_stay_range };
      let dateRange = deepCopy(tempDateRange);
      //dateRange.as_of_date = as_of_date;

      setShowRangeSegment(true);
      setDisplayDateRange(dateRange);
      let comp_stay_range = calculateCompStayRange({ primary_stay_range: tempDateRange });
      app_state.primary_stay_range = {
        date_from: moment().startOf('month').format('YYYY-MM-DD'),
        date_until: moment().endOf('month').format('YYYY-MM-DD'),
        as_of_date: new moment().format('YYYY-MM-DD'),
      };
      // app_state.comp_stay_range = comp_stay_range;
      // app_state.workspace_controls.scopeType = 'Date';
      // app_state.data_load = true;
      // app_state.kpi_load = true;
      app_state.workspace_controls.scopeType = "Date";
      dispatch(updateAppState('stay_range', {
        app_state: {
          ...app_state,
          // date_range_type: '',
          // primary_stay_range: {
          //   date_from: moment().startOf('month').format('YYYY-MM-DD'),
          //   date_until: moment().endOf('month').format('YYYY-MM-DD'),
          //   as_of_date: new moment().format('YYYY-MM-DD'),
          // },
          // comp_stay_range: { ...app_state.comp_stay_range, comp_stay_range },
          // workspace_controls: { ...app_state.workspace_controls, scopeType: 'Date' },
          data_load: true,
          kpi_load: true
        },
        ui_state: { ...ui_state, showspinner: true }
      }, "datepicker_update"));
    }

    // debugger;

    compRef.current.rendered += 1;
    return () => (isSubscribed = false)
  }, [
    app_state.workspace_controls.scopeType,
    app_state.date_range_type,
    app_state.primary_stay_range.as_of_date,
    app_state.primary_stay_range.date_from,
    app_state.primary_stay_range.date_until,
  ]);

  const applyChange = (key) => {
    // debugger;
    tempDateRange.as_of_date = as_of_date;
    let historicalRange = {};

    if (app_state.workspace_controls.scopeType === 'Date' 
    && !app_state.workspace_controls.isRoomTypePricing 
    && ui_state.active_tiles[0]!== 'pricing') {
      historicalRange = deepCopy(app_state.primary_stay_range);
    }

    if ("singleStaydates" === key) {
      // debugger;
      tempDateRange.date_until = dateRanges.primary_stay_range.date_until;
      displayDateRange.date_until = dateRanges.primary_stay_range.date_until;
      setDisplayDateRange(displayDateRange);
      let comp_stay_range = calculateCompStayRange({ primary_stay_range: tempDateRange });
      dispatch(updateAppState('stay_date', {
        app_state: {
          ...app_state,
          historicalRange: { ...app_state.historicalRange, ...historicalRange },
          comp_stay_range: { ...app_state.comp_stay_range, ...comp_stay_range },
          //date_range_type: '',
          //primary_stay_range: deepCopy({ ...tempDateRange }),
          data_load: true, kpi_load: true
        },
        ui_state: { ...ui_state, showspinner: true }
      }, "datepicker_update"))
    }

    if ("staydates" === key) {
      if (datePickerConfig.dateRangeKey === "primary_stay_range") {
        //This will make latest stay_range available while calling data
        //if date range is not changed, make kpi_load value false
        app_state.primary_stay_range = { ...tempDateRange };
        // let t = app_state.date_range_type; debugger;
        let comp_stay_range = calculateCompStayRange({ primary_stay_range: tempDateRange });
        dispatch(updateAppState('stay_range', {
          app_state: {
            ...app_state,
            historicalRange: { ...app_state.historicalRange, ...historicalRange },
            comp_stay_range: { ...app_state.comp_stay_range, ...comp_stay_range },
            date_range_type: '',
            as_of_date,
            // comp_as_of_date,
            data_load: true,
            kpi_load: true
          },
          ui_state: { ...ui_state, showspinner: true }
        }, "datepicker_update"))
      }
    }

    if ("as_of_date" === key) {
      // debugger;

      let historicalRange = {};
      if (app_state.workspace_controls.scopeType !== "Dba") {
        historicalRange = { ...historicalRange, ...deepCopy(tempDateRange) };
      }
      // setDisplayDateRange(app_state.primary_stay_range);
      app_state.comp_stay_range = calculateCompStayRange({ primary_stay_range: tempDateRange });

      // update ly year 
      console.log(as_of_date);
      let lyYears = moment(as_of_date).subtract(1, 'years').format('YYYY');
      // end ly year 
      // debugger;

      dispatch(updateAppState('stay_date', {
        app_state: {
          ...app_state,
          historicalRange: { ...app_state.historicalRange, ...historicalRange },
          primary_stay_range: deepCopy({ ...tempDateRange, as_of_date }),
          comp_stay_range: { ...app_state.comp_stay_range },
          as_of_date,
          date_range_type: '',
          workspace_controls: { ...app_state.workspace_controls, sdYear: lyYears },
          data_load: true, kpi_load: true
        },
        ui_state: { ...ui_state, showspinner: true }
      }, "datepicker_update"))
      setApplyAsOfBtn(false)
    }
    let dateRange = deepCopy(tempDateRange);
    //dateRange.as_of_date = as_of_date;
    setShowRangeSegment(true);
    //setIsOpen(false);
    setDisplayDateRange({ ...displayDateRange, ...tempDateRange });
    setAnchorEl(null);
  }


  const determineRangeSegment = (tempDateRange, type) => {
    let daysDiff = dateDiff(tempDateRange.date_until, tempDateRange.date_from);
    if (type === "comparisonStayDates" && daysDiff === 364) {
      return "sdly";
    }
    let startOfMonth = moment(tempDateRange.date_from).startOf('month');
    let endOfMonth = moment(tempDateRange.date_until).endOf('month');
    if (dateDiff(startOfMonth, tempDateRange.date_from) === 0 && dateDiff(endOfMonth, tempDateRange.date_until) === 0) {
      return "current_month";
    }
    let isToday = (dateDiff(moment(), tempDateRange.date_from) === 0) ? true : false;
    if (isToday) {
      if (daysDiff === 30 || daysDiff === 60 || daysDiff === 90) {
        return daysDiff;
      }
    }
    else {
      return "custom";
    }

  }

  const delayShiftFoward = () => {
    clearTimeout(compRef.current.shiftTimeoutId);
    if(compRef.current.shiftTimeoutId){
      clearTimeout(compRef.current.shiftTimeoutId);
    }    
    compRef.current.shiftTimeoutId = setTimeout(shiftFoward, 400);
  };
  const delayShiftBackward = () => {
    clearTimeout(compRef.current.shiftTimeoutId);
    if(compRef.current.shiftTimeoutId){
      //clearTimeout(compRef.current.shiftTimeoutId);
    }
    compRef.current.shiftTimeoutId = setTimeout(shiftBackward, 400);
  };

  const shiftBackward = () => {
    let isMonth = false;
    let startOfMonth = new moment(tempDateRange.date_from).startOf('month');
    let endOfMonth = new moment(tempDateRange.date_until).endOf('month');
    let dateFrom = new moment(tempDateRange.date_from);
    let dateUntil = new moment(tempDateRange.date_until);
    let diffDays = dateDiff(dateUntil, dateFrom);
    if (startOfMonth.format("YYYY-MM-DD") === dateFrom.format("YYYY-MM-DD") 
    && endOfMonth.format("YYYY-MM-DD") === dateUntil.format("YYYY-MM-DD") ) {
      isMonth = true;
    }
    // debugger;
    if (isMonth) {
      tempDateRange.date_from = dateFrom.subtract(1, 'month').startOf('month').format("YYYY-MM-DD");
      tempDateRange.date_until = dateUntil.subtract(1, 'month').endOf('month').format("YYYY-MM-DD");
    }
    if (diffDays === 0) {
      tempDateRange.date_from = dateFrom.subtract(1, 'day').format("YYYY-MM-DD");
      tempDateRange.date_until = dateUntil.subtract(1, 'day').format("YYYY-MM-DD");
    }
    if (!isMonth && diffDays > 0) {
      tempDateRange.date_from = dateFrom.subtract(diffDays + 1, 'day').format("YYYY-MM-DD");
      tempDateRange.date_until = dateUntil.subtract(diffDays + 1, 'day').format("YYYY-MM-DD");
    }
    if (datePickerConfig.dateRangeKey === "primary_stay_range") {
      app_state.primary_stay_range.date_from = tempDateRange.date_from;
      app_state.primary_stay_range.date_until = tempDateRange.date_until;
      app_state.comp_stay_range.date_from = toSdly(tempDateRange.date_from);
      app_state.comp_stay_range.date_until = toSdly(tempDateRange.date_until);
      if(tempDateRange.date_from!==tempDateRange.date_until){
        app_state.historicalRange = deepCopy(app_state.primary_stay_range);
      }
      dispatch(updateAppState('stay_range_shiftArrow', {
        app_state: {
          ...app_state,
          date_range_type: '',
          primary_stay_range: { ...tempDateRange, date_from: tempDateRange.date_from, date_until: tempDateRange.date_until },
          data_load: true, kpi_load: true
        },
        ui_state: { ...ui_state, showspinner: true }
      }, "datepicker_update"))
    }
    displayDateRange.date_from = tempDateRange.date_from;
    displayDateRange.date_until = tempDateRange.date_until;
    setDisplayDateRange(displayDateRange);
    setTempDateRange(tempDateRange)
  }
  
  const shiftFoward = () => {
    let isMonth = false;
    // debugger;
    let startOfMonth = new moment(tempDateRange.date_from).startOf('month');
    let endOfMonth = new moment(tempDateRange.date_until).endOf('month');
    let dateFrom = new moment(tempDateRange.date_from);
    let dateUntil = new moment(tempDateRange.date_until);
    let diffDays = dateDiff(dateUntil, dateFrom);
    if (startOfMonth.format("YYYY-MM-DD") === dateFrom.format("YYYY-MM-DD") 
    && endOfMonth.format("YYYY-MM-DD") === dateUntil.format("YYYY-MM-DD") ) {
      isMonth = true;
    }
    
    if (isMonth) {
      tempDateRange.date_from = dateFrom.add(1, 'month').startOf('month').format("YYYY-MM-DD");
      tempDateRange.date_until = dateUntil.add(1, 'month').endOf('month').format("YYYY-MM-DD");
    }
    if (diffDays === 0) {
      tempDateRange.date_from = dateFrom.add(1, 'day').format("YYYY-MM-DD");
      tempDateRange.date_until = dateUntil.add(1, 'day').format("YYYY-MM-DD");
    }
    if (!isMonth && diffDays > 0) {
      tempDateRange.date_from = dateUntil.add(1, 'day').format("YYYY-MM-DD");
      tempDateRange.date_until = dateUntil.add(diffDays, 'day').format("YYYY-MM-DD");
    }
    
    // debugger;
    if (datePickerConfig.dateRangeKey === "primary_stay_range") {
      app_state.primary_stay_range.date_from = tempDateRange.date_from;
      app_state.primary_stay_range.date_until = tempDateRange.date_until;
      app_state.comp_stay_range.date_from = toSdly(tempDateRange.date_from);
      app_state.comp_stay_range.date_until = toSdly(tempDateRange.date_until);
      app_state.data_load = true;
      app_state.kpi_load = true;
      app_state.date_range_type = '';
      if(tempDateRange.date_from!==tempDateRange.date_until){
        app_state.historicalRange = deepCopy(app_state.primary_stay_range);
      }

      ui_state.showspinner = true;
      dispatch(
        updateAppState('stay_range_shiftArrow', {
        app_state: {
          ...app_state,
          // date_range_type: '',
          // primary_stay_range: { ...tempDateRange, date_from: tempDateRange.date_from, date_until: tempDateRange.date_until },
          // data_load: true, kpi_load: true
        },
        ui_state: {
           ...ui_state,
          //  showspinner: true 
          }
      }, "datepicker_update")
      )
    }
    displayDateRange.date_from = tempDateRange.date_from;
    displayDateRange.date_until = tempDateRange.date_until;
    setDisplayDateRange(displayDateRange);
    setTempDateRange(tempDateRange);
  }

  const onDateChange = (event, date, key) => {
    calendarOn = "";
    if (datePickerConfig.type === "comparisonStayDates") {
      if ("date_from" === key) {
        // if ("date_from" === key && range_segment === "custom") {

        tempDateRange.date_from = moment(date[0]).format("YYYY-MM-DD");
        tempDateRange.date_until = moment(tempDateRange.date_from).add(datePickerConfig.comparisonDiff, 'days').format("YYYY-MM-DD");
      }

    } else {
      if ("single" === key) {
        tempDateRange.date_from = moment(date[0]).format("YYYY-MM-DD");
        tempDateRange.date_until = tempDateRange.date_from;
      }

      if ("as_of" === key) {
        as_of_date = moment(date).format("YYYY-MM-DD");
        setAsOfDate(as_of_date);
        setApplyAsOfBtn(true);
      }
      if ("date_from" === key) {
        tempDateRange.date_from = moment(date[0]).format("YYYY-MM-DD");
        if (moment(tempDateRange.date_until).diff(moment(tempDateRange.date_from), 'days') < 0) {
          tempDateRange.date_until = tempDateRange.date_from
        }
        setRangeSegment("custom");
        setShowRangeSegment(true);
      }
      if ("date_until" === key) {
        tempDateRange.date_until = moment(date[0]).format("YYYY-MM-DD");
        if (moment(tempDateRange.date_until).diff(moment(tempDateRange.date_from), 'days') < 0) {
          tempDateRange.date_from = tempDateRange.date_until
        }
        setRangeSegment("custom");
        setShowRangeSegment(true);
      }
      if ("weeks" === key) {
        debugger;
        if (event && event.shiftKey === true) {
          let orgStartDate = moment(tempDateRange.date_from, "YYYY-MM-DD").toDate();
          let orgEndDate = moment(tempDateRange.date_until, "YYYY-MM-DD").toDate();
          let start = moment(date[0]).startOf("week"), end = moment(date[0]).endOf("week");

          if (moment(date[0]).diff(orgStartDate, 'days') < 0) {
            start = moment(date[0]).startOf("week");
            end = moment(orgEndDate).endOf("week");
          }

          if (moment(date[0]).diff(orgEndDate, 'days') > 0) {
            end = moment(date[0]).endOf('week');
            start = moment(orgStartDate).startOf("week");

          }
          if (moment(date[0]).diff(orgEndDate, 'days') < -7 && moment(date[0]).diff(orgStartDate, 'days') > 0) {
            end = moment(date[0]).endOf('week');

          }

          tempDateRange.date_from = moment(start).format("YYYY-MM-DD");
          tempDateRange.date_until = moment(end).format("YYYY-MM-DD");
          // dateRange = {date_from: tempDateRange.date_from, date_to: tempDateRange.date_to}
          calendarOn = "weeks";
          // setShowRangeSegment(true);
        }
        else {
          let start = moment(date[0]).startOf('week');
          let end = moment(date[0]).endOf('week');
          tempDateRange.date_from = moment(start).format("YYYY-MM-DD");
          tempDateRange.date_until = moment(end).format("YYYY-MM-DD");

          calendarOn = "weeks";

          //setRangeType("range")
        }

      }
      if ("months" === key) {
        if (event && event.shiftKey === true) {

          let start = moment(date[0]).startOf('month');
          let end = moment(date[0]).endOf('month');
          if (moment(date[0]).diff(moment(tempDateRange.date_from, "YYYY-MM-DD"), 'days') < 0) {
            start = moment(date[0]).startOf("month");
            end = moment(moment(tempDateRange.date_until, "YYYY-MM-DD")).endOf("month");
          }

          if (moment(date[0]).diff(moment(tempDateRange.date_until, "YYYY-MM-DD"), 'days') > 0) {
            end = moment(date[0]).endOf('month');
            start = moment(moment(tempDateRange.date_from, "YYYY-MM-DD")).startOf("month");

          }

          tempDateRange.date_from = moment(start).format("YYYY-MM-DD");
          tempDateRange.date_until = moment(end).format("YYYY-MM-DD");
        } else {
          let start = moment(date[0]).startOf('month');
          let end = moment(date[0]).endOf('month');
          tempDateRange.date_from = moment(start).format("YYYY-MM-DD");
          tempDateRange.date_until = moment(end).format("YYYY-MM-DD");
          //calendarOn="months";
          //setRangeType("range")
        }

      }
    }
    setCalendarOn(calendarOn);
    setTempDateRange(tempDateRange);
    setDateRange([moment(tempDateRange.date_from).toDate(), moment(tempDateRange.date_until).toDate()])


  }

  const rangeSegmentChange = (range_segment) => {
    // debugger;
    if (range_segment === "current_month") {
      tempDateRange.date_from = moment().startOf('month').format("YYYY-MM-DD");
      tempDateRange.date_until = moment().endOf('month').format("YYYY-MM-DD");
    }
    if (range_segment === 30 || range_segment === 60 || range_segment === 90) {
      tempDateRange.date_from = moment().format("YYYY-MM-DD"); /// need to add as of date/ data availabilty issue
      tempDateRange.date_until = moment().add(range_segment, 'days').format("YYYY-MM-DD");

    }
    if (range_segment === "custom") {
      tempDateRange.date_from = moment().format("YYYY-MM-DD");
      tempDateRange.date_until = moment().add(range_segment, 'days').format("YYYY-MM-DD");
      // calendarOn = "date_from";
      // setShowRangeSegment(false);
    }
    //  if(range_segment === "sdly"){
    //   tempDateRange.date_from = moment(tempDateRange.date_from).subtract(364, "days");
    //   tempDateRange.date_until = moment(tempDateRange.date_until).subtract(364, "days");
    //   initialLoad =false;
    //  }
    setTempDateRange(tempDateRange);
    setRangeSegment(range_segment);
    // setCalendarOn(calendarOn);
    setCalendarOn('');
  }

  let fromDateCalendar = (<div id="date-picker-calendar">
    <Calendar
      // allowPartialRange={true}
      onChange={(date) => { onDateChange(null, date, "date_from"); }}
      defaultValue={dateRange}
      returnValue="range"
      calendarType="US"
      minDetail="year"
      formatMonth={(locale, date) => dateFormat(date, 'MMM')}
    />
  </div>);


  let toDateCalendar = (<div id="date-picker-calendar">
    <Calendar
      onChange={(date) => { onDateChange(null, date, "date_until"); }}
      defaultValue={dateRange}
      returnValue="range"
      calendarType="US"
      minDetail="year"
      formatMonth={(locale, date) => dateFormat(date, 'MMM')}

    />
  </div>);

  let singleCalendar = (<div id="single-date-picker-calendar">
    <Calendar
      onChange={(date) => { onDateChange(null, date, "single"); }}
      value={dateRange}
      returnValue="range"
      calendarType="US"
      minDetail="year"
      formatMonth={(locale, date) => dateFormat(date, 'MMM')}
    />
  </div>);
  let weeksCalendar = (<div id="week-picker-calendar">
    <Calendar
      onChange={(date, event) => { onDateChange(event, date, "weeks"); }}
      value={dateRange}
      returnValue="range"
      calendarType="US"
      maxDetail="month"
      minDetail="year"
      view="month"
      formatMonth={(locale, date) => dateFormat(date, 'MMM')}

    />
  </div>);
  let monthsCalendar = (<div id="month-picker-calendar">
    <Calendar
      onChange={(date, event) => { onDateChange(event, date, "months"); }}
      value={dateRange}
      returnValue="range"
      calendarType="US"
      maxDetail="year"
      view="year"
      minDetail="year"
      formatMonth={(locale, date) => dateFormat(date, 'MMM')}


    />
  </div>);
  // let maxAsOfDate = moment().format('YYYY-MM-DD');
  // console.log(maxAsOfDate," dddd ",app_state.data_as_of);
  // console.log(new Date(maxAsOfDate));

  let asOfCalendar = (<div id="date-picker-calendar-asof">
    <Calendar
      onChange={(date) => { onDateChange(null, date, "as_of"); }}
      value={asOfDate}
      returnValue="start"
      calendarType="US"
      minDetail="year"
      maxDate={new Date()}
      formatMonth={(locale, date) => dateFormat(date, 'MMM')}
    />
  </div>);
  let rangeSegmentLabels = navigation.range.range_segments.map((segment, index) => {
    let label = "";
    switch (segment) {
      case "current_month":
        label = <><span className='this-month'>This</span><span className='days-label'> Month</span></>;
        break;
      case 30:
        label = <><span className='next-label'>next</span><span className='days-num'>30</span><span className='days-label'> days</span></>;
        break;
      case 60:
        label = <><span className='next-label'>next</span><span className='days-num'>60</span><span className='days-label'> days</span></>;
        break;
      case 90:
        label = <><span className='next-label'>next</span><span className='days-num'>90</span><span className='days-label'> days</span></>;
        break;
      case "sdly":
        label = "Same Period, SDLY";
        break;
      case "custom":
        label = "Custom";
        break;
    }
    return (<Button key={index} className={(range_segment === segment) ? "days-num-btn active" : "days-num-btn"} onClick={() => { rangeSegmentChange(segment) }}>{label}</Button>)
  })
  // if(active_tiles[0] === "pace"){
  // setRangeType("weeks");
  // }
  // debugger;
  let ranges = Object.entries(navigation);
  let rangeTypes = ranges.map((item, index) => {
    let disabledClass = (in_array(active_tiles[0], ["DailyRatesendStatus", "rm_room_type_ratesend_status"]) && app_state.workspace_controls.logsRatePushMode === "date" && item[0] !== "single_date") ? "disabled-range-type" : "";
    // let disabledClass = "";

    range_type = (disabledClass && disabledClass !== "") ? "single_date" : range_type;
    if (app_state.workspace_controls.scopeType === 'Dba') {
      disabledClass = "disabled-range-type";
      // disabledClass = "";
      range_type = "single_date";
    } else {
      disabledClass = "";
      range_type = range_type;
    }
    return (<label key={index} className={(range_type !== item[0]) ? disabledClass + " btn btn-default " : "btn btn-default active"} onClick={() => {
      if (!disabledClass) {
        let range_type = item[0];
        if (range_type === "weeks" || range_type === "months") {
          calendarOn = range_type;
        }
        setCalendarOn(range_type);
        setRangeType(range_type);
        setShowRangeSegment(true);
      }
      // this.setState({range_type:range_type,calendarOn:"", showRangeSegment:true, rangeTypeChanged:true});

    }}>{item[1].label}</label>)
  });
  switch (range_type) {
    case "single_date":
      selector = (<div className="range-selector row" style={{ "paddingTop": "10px" }}>
        <div style={{ "display": "block", "width": "100%" }}>
          <div className="form-group col-md-12" style={{ "margin-bottom": 0 }}>
            <input type="text" className="form-control " onChange={() => { }} name="singleDate" value={moment(singleDate).format("MM/DD/YYYY")} id="singleDatePicker" onClick={() => { setCalendarOn("single") }} />
          </div>

        </div>
        {(calendarOn === "single") && singleCalendar}
        {/* <div className="col-md-12 btn-apply">
          <button type="button" className="btn btn-info btn-block" onChange={() => { }} onClick={() => applyChange("staydates")}>Apply</button>
        </div> */}
      </div>);
      break;
    case "range":

      selector = (<div>
        <div className="range-segments " >
          {/* <label>NEXT:</label>   */}
          <ButtonGroup className='days-slect-group' size='small' fullWidth variant="outlined" aria-label="outlined primary button group">
            {/* <Button disabled>Next</Button> */}
            {showRangeSegment && rangeSegmentLabels}
          </ButtonGroup>
          <div className="btn-group">

          </div>
        </div>
        <div className="range-selector" style={{ "paddingTop": "20px" }}>
          <Grid container spacing={1}>
            <Grid item xs={6}>
              <TextField
                label="From:"
                size="small"
                id="dateFrom"
                className="date-picker range-from"
                // onBlur={()=>{setCalendarOn("")}}
                name="dateFrom" value={inputDateFrom} onChange={(a, b, c) => { }} onClick={() => {

                  // if(datePickerConfig.type === "comparisonStayDates" && inputDateFrom === ""){
                  //   initialLoad=false;
                  //   tempDateRange.date_from= moment().format("YYYY-MM-DD");
                  //   tempDateRange.date_until= tempDateRange.date_from;
                  // } 
                  setCalendarOn("date_from")
                  // setShowRangeSegment(false);
                }} />
            </Grid>
            <Grid item xs={6}>
              <TextField
                label="To:"
                size="small"
                id="dateUntil"
                className="date-picker range-until"
                sx={{}}

                onChange={() => { }} value={inputDateUntil} disabled={datePickerConfig.type === "comparisonStayDates"} onClick={() => {
                  setCalendarOn("date_until")
                  // setShowRangeSegment(false);
                }} />
            </Grid>
            {/* <Grid item xs={12}>
              <div className="btn-apply">
                <button type="button" className="btn btn-info btn-block" onClick={() => applyChange("staydates")}>Apply</button>
              </div>
            </Grid> */}
            <div>

              {(calendarOn === "date_from" && navigation.range.inputs.indexOf("date_from") > -1) && <div className='calendar-box'>{fromDateCalendar}</div>}
              {(calendarOn === "date_until" && navigation.range.inputs.indexOf("date_until") > -1) && <div className='calendar-box'>{toDateCalendar}</div>}
            </div>
          </Grid>



        </div>
      </div>);
      break;

    case "weeks":
      selector = (<div className="range-selector row" style={{ "paddingTop": "10px" }}>
        {(calendarOn === "weeks") && weeksCalendar}

        {/* <div className="col-md-12 btn-apply">
          <button type="button" className="btn btn-info btn-block" onClick={() => applyChange("staydates")}>Apply</button>
        </div> */}
      </div>);
      break;

    case "months":
      selector = (<div className="range-selector row" style={{ "paddingTop": "10px" }}>
        {monthsCalendar}

        {/* <div className="col-md-12 btn-apply">
          <button type="button" className="btn btn-info btn-block" onClick={() => applyChange("staydates")}>Apply</button>
        </div> */}
      </div>);
      break;
  }

  let popoverStyles = { "top": "0px", "display": "block", "visibility": "visible", "width": "300px", };
  let isMobile = isMobileDevice();
  if (isMobile) {
    popoverStyles.top = "-40px";
    popoverStyles.transform = "scale(.65)";
  }

  let popup = (<div className="popover bottom-left" style={popoverStyles}>
    <div className="arrow"></div>
    <div className="popover-content" style={{ "padding": "9px" }}>
      <div>
        <div className="btn-group btn-group-justified range-types " >
          {rangeTypes}
        </div>
        {selector}

        {(navigation[range_type].as_of_date === true) && (<div className="asof-selector clearfix" style={{ "clear": "both" }}>

          {/* <hr /> */}

          <Grid container spacing={1} sx={{ mt: 1.5 }}>

            <Grid item xs={7}>

              {
                (
                  (app_state.workspace_controls.scopeType === "Dba" && active_tiles[0] === "pace") ||
                    (in_array(active_tiles[0], ["DailyRatesendStatus", "rm_room_type_ratesend_status"]) && app_state.workspace_controls.logsRatePushMode === "date") ?
                    <></> : <div>
                      <TextField
                        label="As Of Date:"
                        size="small"
                        id="asOfDate"
                        className='date-picker'
                        onBlur={() => {
                          // setCalendarOn("")
                        }}
                        onChange={() => { }} value={moment(asOfDate).format("MM/DD/YYYY")} onClick={() => { setCalendarOn("as_of") }} />
                    </div>
                )}
            </Grid>
            <Grid item xs={5}>
              <div>{(<button type="button" className="btn btn-info btn-block" onClick={() => applyChange("as_of_date")} >Apply</button>)}</div>
            </Grid>

            {(calendarOn === "as_of" === true) &&
              <Grid xs={12}>
                <div className='calendar-box'>
                  {asOfCalendar}
                </div>
              </Grid>
            }


          </Grid>
          <div className="row">


          </div>

        </div>
        )}
      </div>
    </div>
  </div>
  )
  let shiftBackwardBtn = (<button type="button" className="btn-stay-range shift-prev" onClick={shiftBackward} disabled={app_state.data_load}><ArrowBackIosIcon fontSize='small' /></button>);
  let shiftFowardBtn = (<button type="button" className="btn-stay-range shift-next" onClick={shiftFoward} disabled={app_state.data_load}><ArrowForwardIosIcon fontSize='small' /></button>);
  let topLabel = (<span></span>);
  let noAsOfTiles = ['DailyRatesendStatus', 'rm_room_type_ratesend_status'];
  if (noAsOfTiles.includes(active_tiles[0]) && app_state.workspace_controls.logsRatePushMode === 'date') {
    // debugger;
    topLabel = (<label className="label"> </label>);
  } else if (app_state.workspace_controls.scopeType === "Dba" && active_tiles[0] === 'pace') {
    topLabel = (<label className="label"> </label>);
  } else if (datePickerConfig.type === "primaryStayDates") {
    topLabel = (<label className="label">Stay Dates -
      <span className="date-text"> AS OF </span>
      <span className="">{dateFormat(displayDateRange.as_of_date, "ddd, MMM DD, YYYY")}</span>
    </label>);
  } else if (datePickerConfig.type === "comparisonStayDates") {
    topLabel = (<label className="label">Compare To</label>);
  }
  return (
    <>


      <div className="smart-date-picker" style={{ "display": " inline-block", "verticalAlign": "top" }}>
        <div className="chart-stay-date clearfix current-date-range" id="stay-date">
          {topLabel}
          <div className="stay-date-picker clearfix" id="stay-date-picker-id">
            {(datePickerConfig.shiftBtns === true) && shiftBackwardBtn}
            <button type="button" className="btn-stay-picker clearfix"
              onClick={(event) => {
                // debugger;
                setAnchorEl(event.currentTarget)
                if (!openDatePicker) {
                  calendarOn = calendarOn ? calendarOn : "";
                  //range_type = "range";
                  range_segment = determineRangeSegment(tempDateRange);
                  setShowRangeSegment(true);
                }
                // window.addEventListener("touchstart", function(e){
                //   let $target = $(e.target);
                //       if(!$target.closest('.smart-date-picker').length) {
                //           that.setState({open:false, initialLoad: false});
                //       }
                // });

                // setIsOpen(!isOpen);
                setCalendarOn(calendarOn);
                setRangeSegment(range_segment);
                setRangeType(range_type)
              }}
            >
              {(datePickerConfig.dateRangeKey === "comparison_stay_range" && initialLoad) && (<span>Select Dates</span>)}
              {(datePickerConfig.dateRangeKey !== "comparison_stay_range" || !initialLoad) && (<span><span className="rangeFromDate ">{dateFormat(displayDateRange.date_from, "ddd, MMM DD")}</span> -
                <span className="rangeToDate" >{dateFormat(displayDateRange.date_until, "ddd, MMM DD, YYYY")}</span> </span>)}
            </button>
            <Popover
              PaperProps={{ elevation: 0, sx: { backgroundColor: 'transparent', boxShadow: 'none', mt: -1 } }}
              id={id}
              open={openDatePicker}
              anchorEl={anchorEl}
              onClose={() => { setAnchorEl(null) }}
              anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'left',
              }}
              sx={{ backgroundColor: 'transparent' }}
              className="elevation0 date-picker-popover"
            >
              <div className='date-picker-wrapper'>
                {popup}
              </div>

            </Popover>
            {(datePickerConfig.shiftBtns === true) && shiftFowardBtn}

          </div>
        </div>



      </div>
    </>);

}