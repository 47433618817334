import React, { useState, useEffect, useMemo } from 'react';
import chameleon from "@chamaeleonidae/chmln"
import {
    AppBar
    , Box
    , Toolbar
    , IconButton
    , Typography
    , Menu
    , MenuItem
    , Container
    , Avatar
    , Button
    , Tooltip
    , FormControl
    , AlertTitle
    , Select
    , ListSubheader
    , TextField
    , InputAdornment
    , List
    , ListItem
    , ListItemIcon
    , ListItemText
    , Divider
    , Drawer,
    LinearProgress
} from '@mui/material/';
import {
    Person as PersonIcon
    , SettingsOutlined as SettingsOutlinedIcon
    , ScheduleOutlined as ScheduleOutlinedIcon
    , VideocamOutlined as VideocamOutlinedIcon
    , PowerSettingsNewOutlined as PowerSettingsNewOutlinedIcon
    , AssessmentOutlined as AssessmentOutlinedIcon
    , ArrowForwardRounded as ArrowForwardRoundedIcon
    , ApartmentSharp as ApartmentSharpIcon
    , Search as SearchIcon
} from '@mui/icons-material/';
import CircularProgress from '@mui/material/CircularProgress';
import ApartmentIcon from '@mui/icons-material/Apartment';
import AccessAlarmIcon from '@mui/icons-material/AccessAlarm';
import AccountCircleOutlinedIcon from '@mui/icons-material/AccountCircleOutlined';
import Alert from '@mui/material/Alert';
import Snackbar from '@mui/material/Snackbar';
import NotificationsOutlinedIcon from '@mui/icons-material/NotificationsOutlined';

//FB Push notifications
// import Notification from "../../notifications/Notification";
import { requestForToken } from "../../notifications/firebase";

//Toast notifications
import { ToastContainer, toast } from 'react-toastify';

//Global custom theme configuration
import { ThemeProvider } from '@mui/material/styles';
import theme from '../../theme';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useLocation } from "react-router-dom";
import { logout } from "../../actions/auth";
//UI Elements for dashboard screen
import { DashboardLogo, DashboardLogoSmall, DashboardFooter, ResponsiveTilesMenu, CustomSVGIcon } from "../../components/layout/dashboard-ui"
import { ClassNames } from '@emotion/react';
import { updateAppState, getData, getPropertyInfo } from '../../actions/appstate';

import Tile from '../Tile/';
import { TILES, TILE_GROUP_ONE, TILE_GROUP_TWO, NEW_PROPERTY_SYSTEM_SETTINGS } from '../../app/config';
import { UPDATE_STATE } from '../../actions/types';
import { getTilesInOrder, getFromState, getLocalData, safeReference, isEmptyObj, getDataSources, abortRequestSafe, deepCopy, clearAPIRegistryData } from "../../app/util";
import "./styles.css";
import ReportsMenu from '../Reports/ReportsMenu';
import AlertList from './AlertList';
import AlertBell from './AlertBell';
import Prompt from '../Modal/Prompt';
import { sendLog } from '../../app/log-interface';
import ConfirmDialog from '../Modal/ConfirmDialog';
import moment from 'moment';
import { determineMeasureStates } from "../../components/Measure/measure-logic";

const tilesGroupOne = getTilesInOrder(TILES, TILE_GROUP_ONE);
const tilesGroupTwo = getTilesInOrder(TILES, TILE_GROUP_TWO);

const pages = [];

const userMenu = [
    {
        id: 'profileSettingsMenu', label: 'My Profile', axn: 'goToSettings',
        icon: <AccountCircleOutlinedIcon fontSize='small' sx={{ mr: 1, color: 'blue.main' }} />
    }
    , { id: 'propertySettingsMenu', label: 'Property Settings', axn: 'goToSettings', icon: <ApartmentIcon fontSize='small' sx={{ mr: 1, color: 'blue.main' }} /> }
    , { id: 'schedulerSettingsMenu', label: 'Scheduler', axn: 'goToSettings', icon: <AccessAlarmIcon fontSize='small' sx={{ mr: 1, color: 'blue.main' }} /> }
    , { id: 'videos', label: 'Videos', axn: 'gotoVideos', icon: <VideocamOutlinedIcon fontSize='small' sx={{ mr: 1, color: 'blue.main' }} /> }
    , { id: 'logout', label: 'Logout', axn: 'signOut', icon: <PowerSettingsNewOutlinedIcon fontSize='small' sx={{ mr: 1, color: 'blue.main' }} /> }
];


const Header = () => {
    const { pathname } = useLocation();

    const [selectedMenu, setSelectedMenu] = useState('');
    const handleListItemClick = (event, index) => {
        setSelectedMenu(index);
    };

    const [promptOpen, setPromptOpen] = useState(false);

    const [showTileDrawer, setShowTileDrawer] = useState(false);
    const [anchorElNav, setAnchorElNav] = useState(null);
    const [anchorElUser, setAnchorElUser] = useState(null);
    const [loading, setLoading] = useState({ ss: false });

    const dispatch = useDispatch();
    const state = useSelector(state => state);
    const { auth, app } = state;
    const { user: AuthUser, profile } = auth;
    const { app_state, ui_state } = app.current_state;
    const [propertyMenu, setPropertyMenu] = useState(profile.property_details.map(property => {
        return {
            label: property.name, default: property.default, id: property.id
        }
    }))

    const [anchorElProperties, setAnchorElProperties] = React.useState(null);
    const [selectedProperty, setSelectedProperty] = useState((propertyMenu.filter(property => property.id == profile.default_property_id))[0]);
    const [whichProperty, setWhichProperty] = useState(selectedProperty);

    const [confirmOptions, setConfirmOptions] = useState({
        title: "Are you sure you want to switch to ",
        descriptions: "",
        labelOk: "Change",
        labelCancel: "Cancel",
        open: false,
    });

    let propChangeToast = null;

    function handleUserAction(action) {

        if (!action) {
            setConfirmOptions((state) => {
                return { ...state, open: false };
            })
            // handleDSChange(conFirmDialog.event, conFirmDialog.option);
        }
        if (action === 'agree') {
            // debugger;
            setConfirmOptions((state) => {
                return { ...state, showLoader: true, open: false };
            });

            dispatch(updateAppState("empty cart", { // this should be somwhere else when you change property not on menu close
                app_state: {
                    roomtype_pricing_overrides: {},
                    pricing_override_rates: {},
                    savedRestrictions: []
                },

            }));
            handleClosePropertiesMenu(whichProperty, true)
        }
        if (action === 'disagree') {
            setConfirmOptions((state) => {
                return { ...state, open: false };
            })
            // dispatch(updateAppState("disagree", { // this should be somwhere else when you change property not on menu close
            //     app_state: {
            //         //data_load:false
            //     },
            //     ui_state:{
            //         showspinner:false
            //     }

            // }));
            // handleDSChange(conFirmDialog.event, conFirmDialog.option);
        }

    }
    const navigate = useNavigate();
    useEffect(() => {
        let isSubscribed = true;

        //   profile.property_details.map(property=>{
        //     if(property.id ===app_state.property_id){
        //       selectedProperty= property
        //     }
        //   })
        //   setSelectedProperty(selectedProperty);

        // propChangeToast = toast(<><strong>Property Change Error! Please try again</strong><br />...</>, {
        //     position: "bottom-right",
        //     autoClose: true,
        //     hideProgressBar: false,
        //     closeOnClick: true,
        //     pauseOnHover: true,
        //     draggable: true,
        //     progress: undefined,
        //     theme: "light",
        //     transition: "Slide",
        //     // autoClose: 0,
        //     // type: toast.TYPE.SUCCESS,
        // });

        chameleon.init('S9tGHVPHxtdC09mFZkrHpHP4YrLnXDuKXNy6usb4GOSEyK-1QzdZB-EC3XKYs6hR2Zwdcx',
            { fastUrl: 'https://fast.chameleon.io/' });
        /* chameleon.io user identification and data */

        // IMPORTANT: Replace USER.ID_IN_DB, USER.EMAIL, USER.FULL_NAME below with your app's actual variable names.

        chameleon.identify(AuthUser.email, {     // REQUIRED, the unique ID of each user in your database (e.g. 23443 or "690b80e5f433ea81b96c9bf6")
            email: AuthUser.email,                // RECOMMENDED, email is used as the key to map user data for integrations
            name: AuthUser.fullname,             // RECOMMENDED, name can be used to greet and/or personalize content
        })

        requestForToken();
    }, [])
    useEffect(() => {
        let isSubscribed = true;
        if (!AuthUser) {
            navigate("/login");
        }
        return () => isSubscribed = false;
    }, [AuthUser])
    useEffect(() => {
        let isSubscribed = true;
        let selProperty = {}
        profile.property_details.map(property => {
            if (property.id === app_state.property_id) {
                selProperty = property;
                selProperty.label = property.name;
            }
        })
        setSelectedProperty(selProperty);
        return () => isSubscribed = false;
    }, [app_state])


    const handleOpenNavMenu = (event) => {
        setAnchorElNav(event.currentTarget);
    };
    const handleCloseNavMenu = () => {
        setAnchorElNav(null);
    };
    const handleOpenUserMenu = (event) => {
        setAnchorElUser(event.currentTarget);
    };
    const handleCloseUserMenu = (setting) => {
        setAnchorElUser(null);
    };



    const profileMenuAxns = {
        signOut: (() => {
            sendLog({ category: "menu_click", action: "logout" }, { ...app.current_state }, { app_state: {}, ui_state: {} })

            dispatch(logout());
            navigate("/login");
            setAnchorElUser(null);

        }),
        goToSettings: (item) => {
            setAnchorElUser(null);
            sendLog({ category: "menu_click", action: "goto_" + item.id }, { ...app.current_state }, { app_state: {}, ui_state: { active_settings_menu: item.id, isFromHeader: true } })
            dispatch({ type: UPDATE_STATE, payload: { ui_state: { active_settings_menu: item.id, isFromHeader: true } } });
            if (pathname !== '/settings') {
                navigate("/settings");
            }

        }
    };


    const [searchText, setSearchText] = useState("");
    const allOptions = propertyMenu;
    const containsText = (text, searchText) =>
        text.toLowerCase().indexOf(searchText.toLowerCase()) > -1;
    const [selectedOption, setSelectedOption] = useState(selectedProperty);

    const displayedOptions = useMemo(
        () => allOptions.filter((option) => containsText(option.label, searchText)),
        [searchText]
    );

    const handleDailyFlash = () => {
        // debugger;
        // dispatch(updateAppState({ "UPDATE_STATE", ui_state: { show_flash_report: true } }));
        dispatch(
            updateAppState("change_flash_report", {
                ui_state: {
                    show_flash_report: true,
                },
            }, "flash_report_click")
        );
    }

    const handleGroupPricing = () => {
        dispatch(
            updateAppState("change_group_pricing", {
                ui_state: {
                    show_group_pricing: true,
                },
            }, "group_click")
        );
    }

    const handleOpenPropertiesMenu = (event) => {
        //setAnchorElProperties(event.currentTarget);
    };

    const handleClosePropertiesMenu = (property, fromPrompt = false) => {

        // console.log(app_state.property_name," Atul testing ",property);
        let changedMessage = "Property changed from " + app_state.property_name + " to " + property.label + "!";
        // debugger;
        //setAnchorElProperties(null);
        // setConfirmOptions((state) => {          
        //     return { ...state, open: true, selectedIndex: selectedIndex, event: event, item: property };
        // });
        if (selectedProperty.id !== property.id) {
            clearAPIRegistryData();
            if (!fromPrompt && (!isEmptyObj(app_state.pricing_override_rates) || !isEmptyObj(app_state.roomtype_pricing_overrides))) {
                setWhichProperty(property)
                //setPromptOpen(true);
                let descriptions = (<Alert severity="warning">
                    <AlertTitle>Warning</AlertTitle>
                    Switching properties will clear all items in your pricing cart
                </Alert>)
                setConfirmOptions((state) => {
                    return { ...state, open: true, descriptions, title: `Are you sure you want to switch to ${property.label}?` };
                })
                return;
            }
            if (!fromPrompt && app_state.savedRestrictions.length > 0) {
                setWhichProperty(property)

                let descriptions = (<Alert severity="warning">
                    <AlertTitle>Warning</AlertTitle>
                    Switching properties will clear all items in restrictions cart
                </Alert>)
                setConfirmOptions((state) => {
                    return { ...state, open: true, descriptions, title: `Are you sure you want to switch to ${property.label}?` };
                })
                return;

            }
            const measuresState = determineMeasureStates({
                active_tiles: ["otb"],
                userSelected_left_measure: "Occ",
                userSelected_right_measure: "ADR",
            });

            ui_state.left_measures_list = measuresState.leftList;
            ui_state.right_measures_list = measuresState.rightList;
            ui_state.left_measure = "Occ";
            ui_state.right_measure = "ADR";
            ui_state.showspinner = true;

            if (fromPrompt) {
                app_state.pricing_override_rates = {};
                app_state.roomtype_pricing_overrides = {};
                app_state.savedRestrictions = [];
            }

            dispatch(updateAppState("property_change", { // this should be somwhere else when you change property not on menu close
                app_state: {
                    ...app_state,
                    // kpi_load: true,
                    date_range_type: ''
                },
                ui_state: {
                    ...ui_state,
                }
            }));
            app_state.active_apis.map((api_id, index) => {
                // debugger;
                abortRequestSafe(api_id, 'property changed');
            });
            app_state.property_id = property.id;
            ui_state.active_tiles = ['otb'];
            // debugger;
            // let primaryStayRange = {
            //     date_from: new moment().startOf('month').format("YYYY-MM-DD"),
            //     date_until: new moment().endOf('month').format("YYYY-MM-DD")
            // }
            // app_state.primary_stay_range = primaryStayRange;
            // app_state.workspace_controles.scopeType = 'Date';


            let ss_exists = getFromState('check_system_settings', state, property.id);
            ss_exists = false;
            if (ss_exists === property.id) {
                let newDataSrc = getDataSources(profile.property_details, property.id);
                // debugger;           
                setLoading({ ...loading, ss: true })

                app_state.data_as_of = null; //Setting null on prop change 
                getData({
                    ...app_state, system_settings: false, to_pid: property.id, from_pid: selectedProperty.id, active_api: "SwitchProperty",
                })()
                    .then((res) => {
                        ///
                        // debugger
                        let localeSettings = getLocalData(auth, app_state, property.id);
                        setLoading({ ...loading, ss: false });
                        setSelectedProperty(property);
                        let property_info = getPropertyInfo(profile.property_details, property.id);
                        let room_settings = [];
                        if (property_info.system_settings) {
                            room_settings = safeReference(property_info.system_settings[0], [], ["inventory_settings", "current", "room_settings"])
                        }
                        let selectedRoomPricingRoomCodes = []
                        room_settings.forEach(room => {
                            selectedRoomPricingRoomCodes.push(room.room_code)
                        })
                        let newDataSrc = getDataSources(profile.property_details, property.id);
                        dispatch({ type: "SYSTEM_SETTINGS_UPDATED", payload: profile });
                        app_state.date_range_type = 'propertyChanged';
                        app_state.data_load = true;
                        app_state.kpi_load = true;
                        app_state.primary_stay_range = { ...app_state.primary_stay_range, as_of_date: new moment().startOf('day').format("YYYY-MM-DD"), date_from: new moment().startOf('month').format("YYYY-MM-DD"), date_until: new moment().endOf('month').format("YYYY-MM-DD") };
                        app_state.historicalRange = deepCopy(app_state.primary_stay_range);
                        app_state.property_name = property_info.name;
                        app_state.workspace_controls.scopeType = "Date";
                        app_state.workspace_controls.isRoomTypePricing = false;
                        app_state.room_settings = room_settings;
                        app_state.savedRestrictions = [];
                        app_state.active_data_src = newDataSrc.active_source;

                        ui_state.showspinner = true;
                        ui_state.currency = localeSettings['currencyCode'];
                        ui_state.currency_symbol = localeSettings['currency_symbol'];
                        // debugger;
                        dispatch(
                            updateAppState("property_change", { // this should be somwhere else when you change property not on menu close
                                app_state: {
                                    ...app_state,
                                    // property_id: property.id,
                                    // date_range_type: 'propertyChanged',
                                    // primary_stay_range: { ...app_state.primary_stay_range, ...primaryStayRange },
                                    // data_load: true,
                                    // kpi_load: true,
                                    // property_name: property_info.name,
                                    // room_settings,
                                    // savedRestrictions: [],
                                    // active_data_src: newDataSrc.active_source
                                },
                                ui_state: {
                                    ...ui_state,
                                    // showspinner: true,
                                    // active_tiles: ['otb'],
                                    // currency: localeSettings['currencyCode'],
                                    // currency_symbol: localeSettings['currency_symbol'],
                                }
                            })
                        );
                        setLoading({ ...loading, ss: false });
                        toast.dismiss();

                        toast.success(changedMessage, {
                            position: "bottom-right"
                        });
                        // toast.success('Property Changed Successfully!', {
                        //     position: "bottom-right"
                        // });
                    }, (error) => {

                        console.error("error in handleClosePropertiesMenu!!");
                        setLoading({ ...loading, ss: false });

                        toast.error('Error! Property Changed failed, please try again', {
                            position: "bottom-right"
                        });
                    });
            } else if (!ss_exists) {

                setLoading({ ...loading, ss: true })
                getData({
                    ...app_state, system_settings: true, to_pid: property.id, from_pid: selectedProperty.id, active_api: "SwitchProperty",
                })()
                    .then((res) => {
                        // NEW_PROPERTY_SYSTEM_SETTINGS
                        // debugger;
                        let { system_settings, data_sources, active_source, default_source, active_ds_id, permissions } = res.data;
                        if (!system_settings.inventory_settings) {
                            console.error("CRITICAL: System settings -> Inventory settings not available");
                            system_settings = { ...NEW_PROPERTY_SYSTEM_SETTINGS, ...system_settings };
                            // delete system_settings.setup_mode;
                        }

                        setLoading({ ...loading, ss: false });
                        setSelectedProperty(property);
                        let prop_details_index = profile.property_details.findIndex(item => item.id === property.id);
                        // profile.property_details[prop_details_index].system_settings = [system_settings];
                        //Adding data sources
                        profile.property_details[prop_details_index] = { ...profile.property_details[prop_details_index], system_settings: [system_settings], active_ds_id, permissions, data_sources, active_source, default_source };
                        let localeSettings = getLocalData(auth, app_state, property.id);
                        let property_info = getPropertyInfo(profile.property_details, property.id);
                        let room_settings = [];
                        if (property_info.system_settings) {
                            room_settings = safeReference(property_info.system_settings[0], [], ["inventory_settings", "current", "room_settings"])
                        }
                        dispatch({ type: "SYSTEM_SETTINGS_UPDATED", payload: profile });
                        let newDataSrc = getDataSources(profile.property_details, property.id);

                        app_state.date_range_type = 'propertyChanged';
                        app_state.data_load = true;
                        app_state.kpi_load = true;
                        app_state.primary_stay_range = { ...app_state.primary_stay_range, as_of_date: new moment().startOf('day').format("YYYY-MM-DD"), date_from: new moment().startOf('month').format("YYYY-MM-DD"), date_until: new moment().endOf('month').format("YYYY-MM-DD") };
                        app_state.historicalRange = deepCopy(app_state.primary_stay_range);
                        app_state.property_name = property_info.name;
                        app_state.workspace_controls.scopeType = "Date";
                        app_state.workspace_controls.isRoomTypePricing = false;
                        app_state.room_settings = room_settings;
                        app_state.savedRestrictions = [];
                        app_state.active_data_src = newDataSrc.active_source;

                        ui_state.showspinner = true;
                        ui_state.currency = localeSettings['currencyCode'];
                        ui_state.currency_symbol = localeSettings['currency_symbol'];
                        // debugger;
                        dispatch(
                            updateAppState("property_change", { // this should be somwhere else when you change property not on menu close
                                app_state: {
                                    ...app_state,
                                    // property_id: property.id,
                                    // date_range_type: 'propertyChanged',
                                    // primary_stay_range: { ...app_state.primary_stay_range, ...primaryStayRange },
                                    // data_load: true,
                                    // kpi_load: true,
                                    // property_name: property_info.name,
                                    // room_settings,
                                    // savedRestrictions: [],
                                    // active_data_src: newDataSrc.active_source
                                },
                                ui_state: {
                                    ...ui_state,
                                    // showspinner: true,
                                    // active_tiles: ['otb'],
                                    // currency: localeSettings['currencyCode'],
                                    // currency_symbol: localeSettings['currency_symbol'],
                                }
                            })
                        );
                        toast.dismiss();
                        // toast.success('Property Changed Successfully!', {
                        //     position: "bottom-right"
                        // });
                        toast.success(changedMessage, {
                            position: "bottom-right"
                        });
                    },
                        (error) => {

                            console.error("error in handleClosePropertiesMenu!!");
                            setLoading({ ...loading, ss: false });
                            toast.dismiss();
                            toast.error('Error! Property Changed failed, please try again', {
                                position: "bottom-right"
                            });
                        })
            }
        }

    };
    const propertiesMenuAxns = ((prop) => {
        //handleClosePropertiesMenu();
    });

    const handlePropertiesChange = ((event) => {
        let v = event.target.value;
        //let t = event.currentTarget; debugger;
        //setSelectedProperty(event.target);

    })


    const [activeTile, setActiveTile] = useState(ui_state.active_tiles[0]);
    const { current_state } = useSelector(state => state.app);
    const toggleTilesDrawer = ((event) => {
        //debugger;
        setActiveTile(current_state.ui_state.active_tiles[0]);
        if (showTileDrawer) {
            setShowTileDrawer(false);
        } else {
            setShowTileDrawer(true);
        }
    });

    const handleTileClick = (event) => {
        //let states = states();
        let id = event.currentTarget.id;
        if (activeTile !== id) {
            setActiveTile(id);
            dispatch(updateAppState('tile', {
                ui_state: {
                    active_tiles: [id]
                }
            }));
        }
    };
    const anchor = 'left';
    const color = theme.palette;
    const list = () => (
        <Box
            sx={{ width: 230, height: '100%', background: color.dark.main }}
            role="presentation"
            onClick={() => { toggleTilesDrawer() }}
            onKeyDown={() => { toggleTilesDrawer() }}
        >

            <List sx={{ padding: 0, width: '100%', display: 'block' }}>
                {tilesGroupOne.map((tile, index) => (
                    <ListItem sx={{ padding: 0, width: '100%', height: '48px', display: 'block', borderTop: '1px solid #444' }} button id={tile.id} key={index} onClick={handleTileClick} className={tile.id == activeTile ? 'active' : ''}>
                        <Tile variant='drawer' tile={tile} activeTile={activeTile} />
                    </ListItem>
                ))}
                {tilesGroupTwo.map((tile, index) => (
                    <ListItem sx={{ padding: 0, width: '100%', height: '50px', display: 'block', borderBottom: '1px solid #444' }} button id={tile.id} key={index} onClick={handleTileClick} className={tile.id == activeTile ? 'active' : ''}>
                        <Tile variant='drawer' tile={tile} activeTile={activeTile} />
                    </ListItem>
                ))}
            </List>
            <Divider />
            <ConfirmDialog {...confirmOptions} handleUserAction={handleUserAction} />
        </Box>
    );


    return (
        <ThemeProvider theme={theme}>
            <AppBar position="fixed" color='default' elevation={0} className='app-top-bar' sx={{ pt: '0px', pb: '0px', mb: 0 }}>
                {
                    // (ui_state.header_alert_msg !== "" || ui_state.showspinner === true) ? (
                    // (ui_state.header_alert_msg !== "") ? (
                    //   <LinearProgress sx={{ position: 'absolute', width: '100%' }} color='green' />
                    //   // <></>
                    // ) : (
                    //   <></>
                    // )
                }
                <Container maxWidth="xxl" className='container-control'>

                    <Toolbar disableGutters sx={{ minHeight: { sm: 'auto' } }}>

                        <Typography
                            variant="h6"
                            noWrap
                            component="div"
                            className='app-logo'
                            sx={{ pt: 0, mr: 2, display: { xs: 'none', md: 'flex' } }}
                        >
                            <DashboardLogo />
                        </Typography>


                        <Typography
                            variant="h6"
                            noWrap
                            component="div"
                            sx={{ flexGrow: 1, display: { xs: 'flex', md: 'none' } }}
                        >
                            <DashboardLogoSmall />

                        </Typography>
                        {
                            ui_state.header_alert_msg !== "" ? (
                                <Box sx={{ flexGrow: 1, color: "#9ed00d", ml: 21, position: "relative", width: "20%" }}>
                                    {ui_state.header_alert_msg}
                                </Box>
                            ) : (
                                <></>
                            )
                        }

                        <Box sx={{ flexGrow: 1, display: { xs: 'none', md: 'flex' } }}>
                            {pages.map((page) => (
                                <Button
                                    key={page}
                                    onClick={handleCloseNavMenu}
                                    sx={{ my: 2, color: 'white', display: 'block' }}
                                >
                                    {page}
                                </Button>
                            ))}
                        </Box>

                        <Box sx={{ flexGrow: 0, display: { lg: 'none' } }}>
                            <ResponsiveTilesMenu onClick={() => { toggleTilesDrawer() }} />
                            <Drawer
                                anchor={anchor}
                                open={showTileDrawer}
                                onClose={() => { toggleTilesDrawer() }}
                            >
                                {list(anchor)}
                            </Drawer>
                        </Box>

                        {/* select hotel dropdown*/}
                        <Box className='hotel-dropdown' sx={{ flexGrow: 0 }}>
                            <Tooltip title="Properties">
                                <svg className="hotel-dropdown-icon-header" xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="#F8F9FA" viewBox="0 0 256 256"><path d="M240,208H224V96a16,16,0,0,0-16-16H144V32a16,16,0,0,0-24.88-13.32L39.12,72A16,16,0,0,0,32,85.34V208H16a8,8,0,0,0,0,16H240a8,8,0,0,0,0-16ZM208,96V208H144V96ZM48,85.34,128,32V208H48ZM112,112v16a8,8,0,0,1-16,0V112a8,8,0,1,1,16,0Zm-32,0v16a8,8,0,0,1-16,0V112a8,8,0,1,1,16,0Zm0,56v16a8,8,0,0,1-16,0V168a8,8,0,0,1,16,0Zm32,0v16a8,8,0,0,1-16,0V168a8,8,0,0,1,16,0Z"></path></svg>
                            </Tooltip>

                            <FormControl variant="standard">
                                <Select
                                    // Disables auto focus on MenuItems and allows TextField to be in focus

                                    MenuProps={{ autoFocus: false }}
                                    labelId="search-select-label"
                                    id="search-select"
                                    className='property-menu'
                                    value={selectedProperty.id}
                                    onChange={handlePropertiesChange}
                                    onClose={() => setSearchText("")}
                                    // This prevents rendering empty string in Select's value
                                    // if search text would exclude currently selected option.

                                    renderValue={() => (<>{selectedProperty.label} {loading.ss && <CircularProgress size={18} sx={{ position: 'absolute', right: "3px", top: "5px" }} color='green' />}</>)}
                                    inputProps={{ 'aria-label': 'Without label' }}

                                    sx={[{ width: { xs: 100, sm: 140 }, color: '#fff', mr: 0, mt: 1.5, ml: 0 },
                                    {
                                        '&::before': {
                                            borderBottom: 'none'
                                        },
                                        '&::after': {
                                            borderBottom: 'none'
                                        },
                                        '& .MuiSelect-icon': {
                                            color: 'white.main'
                                        },

                                        '&.MuiInput-root:hover:before': {
                                            border: 'none'
                                        },
                                        '&.MuiInput-root .MuiSelect-select': {
                                            background: 'none'
                                        }
                                    }
                                    ]}
                                >
                                    {/* TextField is put into ListSubheader so that it doesn't
                                        act as a selectable item in the menu
                                        i.e. we can click the TextField without triggering any selection.*/}
                                    <ListSubheader>
                                        <TextField
                                            size="small"
                                            // Autofocus on textfield
                                            autoFocus
                                            placeholder="Type to search..."
                                            fullWidth
                                            InputProps={{
                                                startAdornment: (
                                                    <InputAdornment position="start">
                                                        <SearchIcon />
                                                    </InputAdornment>
                                                )
                                            }}
                                            onChange={(e) => setSearchText(e.target.value)}
                                            onKeyDown={(e) => {
                                                if (e.key !== "Escape") {
                                                    // Prevents autoselecting item while typing (default Select behaviour)
                                                    e.stopPropagation();
                                                }
                                            }}
                                        />
                                    </ListSubheader>

                                    {displayedOptions.map((option, i) => (

                                        <MenuItem key={i} title={option.label + '-' + option.id} value={option.id} onClick={() => handleClosePropertiesMenu(option)}>
                                            {option.label}
                                            {/* ({option.id}) */}
                                        </MenuItem>
                                    ))}

                                </Select>
                            </FormControl>
                        </Box>


                        <Box sx={{ flexGrow: 0, display: { lg: 'none' } }}>
                            <ResponsiveTilesMenu onClick={() => { toggleTilesDrawer() }} />
                            <Drawer
                                anchor={anchor}
                                open={showTileDrawer}
                                onClose={() => { toggleTilesDrawer() }}
                            >
                                {list(anchor)}
                            </Drawer>
                        </Box>


                        <Box className='divider-left' sx={{ flexGrow: 0 }}>
                            <Tooltip title="Daily Flash">
                                <IconButton onClick={handleDailyFlash} sx={{ p: 0 }} className="icon-btn">
                                    {/* <NotificationsOutlinedIcon className='notification' sx={{width:30, height:30,}} alt={AuthUser.fullname} src={AuthUser.profile_image_url} /> */}
                                    <CustomSVGIcon icon="icon-daily-flash" path="/assets/img/business-intelligence.svg" width="25" height="25" className="daily-flash" />
                                </IconButton>
                            </Tooltip>
                        </Box>

                        <Box className='divider-left' sx={{ flexGrow: 0 }}>
                            <Tooltip title="Group Pricing">
                                <IconButton onClick={handleGroupPricing} sx={{ p: 0 }} className="icon-btn">
                                    <CustomSVGIcon icon="icon-daily-flash" path="/assets/img/users-three.svg" width="25" height="25" className="Group-Pricing" />
                                </IconButton>
                            </Tooltip>
                        </Box>


                        <Box className='divider-left' sx={{ flexGrow: 0 }}>
                            <ReportsMenu />
                        </Box>

                        {/* Notification AlertBell */}
                        <Box className='divider-left' sx={{ flexGrow: 0, pt: 0.5, pb: 0.5 }}>
                            <AlertBell />

                        </Box>

                        {/* Profile Img Icon */}
                        <Box className='divider-left2' sx={{ flexGrow: 0 }}>
                            <Tooltip title="Profile & Settings">
                                <IconButton className='icon-btn' onClick={handleOpenUserMenu} sx={{ p: 0 }}>
                                    {profile.profile_image.indexOf('person.png') === -1 && <Avatar className='profileIcon' sx={{ width: 30, height: 30 }} alt={AuthUser.fullname} src={AuthUser.profile_image_url} />}
                                    {profile.profile_image.indexOf('person.png') !== -1 && <Avatar className='profileIcon' sx={{ width: 30, height: 30, textTransform: "uppercase", backgroundColor: "#01A4EF" }} alt={AuthUser.fullname}><strong>{AuthUser.fullname[0]}</strong></Avatar>}

                                </IconButton>
                            </Tooltip>
                            <Menu
                                sx={{ mt: '20px', zIndex: 9992, }}
                                id="menu-appbar"
                                anchorEl={anchorElUser}
                                anchorOrigin={{
                                    vertical: 'top',
                                    horizontal: 'right',
                                }}
                                keepMounted
                                transformOrigin={{
                                    vertical: 'top',
                                    horizontal: 'right',
                                }}
                                open={Boolean(anchorElUser)}
                                onClose={handleCloseUserMenu}
                                PaperProps={{

                                    elevation: 0,
                                    sx: {
                                        overflow: 'visible',
                                        filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
                                        mt: 1.5,
                                        '& .MuiAvatar-root': {
                                            width: 32,
                                            height: 32,
                                            ml: -0.5,
                                            mr: 1,
                                        },
                                        '&:before': {
                                            content: '""',
                                            display: 'block',
                                            position: 'absolute',
                                            top: 0,
                                            right: 14,
                                            width: 10,
                                            height: 10,
                                            bgcolor: 'background.paper',
                                            transform: 'translateY(-50%) rotate(45deg)',
                                            zIndex: 0,
                                        },
                                    },
                                }}
                            >
                                {
                                    userMenu.map((item, index) => (
                                        <MenuItem onClick={() => profileMenuAxns[item.axn](item, Event)} key={index} selected={ui_state.active_settings_menu === item.id}>
                                            {item.icon}  {item.label}
                                        </MenuItem>
                                    ))
                                }


                            </Menu>
                        </Box>

                    </Toolbar>
                </Container>
                {/* <Prompt isOpen={promptOpen} options={{title:"Switching properties will clear all items in your pricing cart", 
                prompt:"Would you like to continue?"}} 
                handleClose={(response)=>{
                    if(response === true){
                        dispatch(updateAppState("empty cart", { // this should be somwhere else when you change property not on menu close
                            app_state: {
                                roomtype_pricing_overrides: {},
                                pricing_override_rates: {}
                            },
                           
                        }));
                        handleClosePropertiesMenu(whichProperty, true)
                    }
                    setPromptOpen(false);
                }} /> */}
                <ConfirmDialog {...confirmOptions} handleUserAction={handleUserAction} />
            </AppBar>
            <ToastContainer />
        </ThemeProvider>
    );
};
export default Header;