import React,{useState,useEffect} from 'react'
import { LibraryAddCheck as CheckboxDoubleIcon, CheckBox as CheckBoxIcon, CheckBoxOutlineBlank as CheckBoxOutlineBlankIcon } from '@mui/icons-material';

function CheckboxEl({isChecked, disabledInput, options, method,classes}) {
    const [checkedVal, setCheckedVal] = useState(isChecked);
    let isSingle = "single";
    let checkBoxEl =(isChecked) ? (<img src='assets/img/single-checkbox-blue.svg' className={'icon-check icon-check-double checked '}/>):
    (<CheckBoxOutlineBlankIcon className={'icon-uncheck icon-check-single '} />)
    if(classes.includes("doubleCheckbox")){
        isSingle = "double";
        checkBoxEl =(isChecked) ? (<img src='assets/img/checkbox-blue.svg' className={'icon-check icon-check-double checked '}/>):
    (<img src='assets/img/uncheckbox.svg' className={'icon-uncheck icon-check-double '} />)
    }
    return (
        <label className={"checkbox-label  " +isSingle + " " + classes}>
        {checkBoxEl}
        <input 
                checked={isChecked}               
                type="checkbox"
                disabled={disabledInput}
                className={classes} 
                style={{visibility:"hidden"}}
                onChange={(e) => {
                let checked = e.target.checked;
                // setCheckedVal(checked);
                method({checked, ...options})
            }}
        />
        </label>
    )
}

export default CheckboxEl
