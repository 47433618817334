import { createStore, applyMiddleware } from "redux";
import { composeWithDevTools } from "redux-devtools-extension";
import thunk from "redux-thunk";
import rootReducer from "../reducers";
const middleware = [thunk];
let intialState = {};
try {
  intialState = sessionStorage.getItem("master_class") ? JSON.parse(sessionStorage.getItem("master_class")) : {};
} catch (error) {
  console.log('getError', error)
}
const saver = (store) => next => action => {
  let stateToSave = store.getState();
  sessionStorage.setItem("master_class", JSON.stringify({ ...stateToSave }))
  return next(action);
}
const store = createStore(
  rootReducer,
  intialState,
  composeWithDevTools(applyMiddleware(...middleware,saver))
);
export default store;