import moment from 'moment';
import { useSelector } from 'react-redux';
import { TILES, CHART_OPTIONS, API_REGISTRY } from "../../app/config";
import { convertToPercent, round, capitalizeFirstLetter, deepCopy, calc } from "../../app/util"
import dataService from "../../services/data.service";
import TableToExcel from "@linways/table-to-excel";
import {setApiURLVars} from "../../actions/appstate";

function kFormatter(num) {
    //return Math.abs(num) > 999 ? Math.sign(num) * ((Math.abs(num) / 1000).toFixed(1)) + 'k' : Math.sign(num) * Math.abs(num)

    if (num >= 1000000000) {
        return (num / 1000000000).toFixed(2).replace(/\.0$/, '') + 'G';
     }
     if (num >= 1000000) {
        return (num / 1000000).toFixed(2).replace(/\.0$/, '') + 'M';
     }
     if (num >= 1000) {
        return (num / 1000).toFixed(2).replace(/\.0$/, '') + 'K';
     }
     return num;
}

const chkValueNegative = (val, oriValue) => {
    let displayValue =
        oriValue >= 0 || typeof oriValue === "string"
            ? val
            : "(" + val.toString().replaceAll("-", "") + ")";
    return displayValue;
}



export const dataFormatMonthlyCard = (key, val, ui_state) => {
    let currency_symbol = (key && key === "Rooms") ? '' : ui_state.currency_symbol ;
    var displayValue;
    val = parseFloat(val.toString().replaceAll(currency_symbol, ""));
    // alert(val);
    if (val > 9999) {
        displayValue = currency_symbol + "" + kFormatter(val);
    } else {
        displayValue = currency_symbol + "" + val.toLocaleString();
    }
    return displayValue.toLocaleString();
}


const dataFormat = (key, val, ui_state) => {

    let currency_symbol = ui_state.currency_symbol;
    var displayValue = val;
    //  if (key === "Rooms") {
    //     console.log(typeof displayValue," type of rooms",displayValue);
    // }
    if (key === "Occ") {
        displayValue = val + "%";
    }else if (key === "Revenue") {
        displayValue = currency_symbol+val.toLocaleString();
    }  else if (key === "Rooms") {
        displayValue = val.toLocaleString();
    }else if (key === "ADR") {
        val = isFinite(val) ? val : 0;
        displayValue = currency_symbol + "" + val.toFixed(2).toLocaleString();
    } else {
        displayValue = currency_symbol + "" + val.toFixed(2).toLocaleString();
    }
    if (key !== "Rooms") {
        displayValue = chkValueNegative(displayValue, val);
    }
    // return displayValue.toLocaleString();
    return displayValue;
}

export function calculateMonthlyData(data, selectedMonth, ui_state) {
    const startDate = moment(selectedMonth).startOf('month').format('YYYY-MM-DD');
    const endDate = moment(selectedMonth).endOf('month').format('YYYY-MM-DD');
    let monthData = moment(selectedMonth).format('MMM, YYYY');
    let noOfDays = moment(monthData).daysInMonth();
    let primary = { Rooms: 0, Occ: 0, ADR: 0, RevPAR: 0, SellRate: 0, Revenue: 0 };
    let sdly = { Rooms: 0, Occ: 0, ADR: 0, RevPAR: 0, SellRate: 0, Revenue: 0 };
    let sdly_actual = { Rooms: 0, Occ: 0, ADR: 0, RevPAR: 0, SellRate: 0, Revenue: 0 };
    let variance = { Rooms: 0, Occ: 0, ADR: 0, RevPAR: 0, SellRate: 0, Revenue: 0 };
    let pickup = { Rooms: 0, Occ: 0, ADR: 0, RevPAR: 0, SellRate: 0, Revenue: 0 };
    let aggregates = {
        sdly: { Rooms: 0, Occ: 0, ADR: 0, Revenue: 0 },
        sdly_actual: { Rooms: 0, Occ: 0, ADR: 0, Revenue: 0 },
        primary: { Rooms: 0, Occ: 0, ADR: 0, Revenue: 0 },
        pickup: { Rooms: 0, Occ: 0, ADR: 0, Revenue: 0 }
    };

    data.map((item) => {
        let yearMonth = new moment(endDate).format("YYYY-MM");
        let itemYearMonth = new moment(item.index.date).format("YYYY-MM");

        if (yearMonth === itemYearMonth) {
            primary.Occ = calc(primary.Occ, item.HotelTotal.primary.Occ, 3).add();
            primary.Rooms += (item.HotelTotal.primary.Rooms) ? parseInt(item.HotelTotal.primary.Rooms) : 0;
            primary.Revenue = calc(primary.Revenue, item.HotelTotal.primary.Revenue).add();
            primary.ADR += parseFloat(primary.Revenue / primary.Rooms);
            primary.RevPAR = calc(primary.RevPAR, item.HotelTotal.primary.RevPAR, 3).add();
            primary.SellRate = calc(primary.SellRate, item.HotelTotal.primary.SellRate, 4).add();

            sdly.Rooms += (item.HotelTotal.sdly.Rooms) ? parseInt(item.HotelTotal.sdly.Rooms) : 0;
            sdly.Occ = calc(sdly.Occ, item.HotelTotal.sdly.Occ, 3).add();
            sdly.ADR += parseFloat(item.HotelTotal.sdly.ADR);
            sdly.RevPAR += parseFloat(item.HotelTotal.sdly.RevPAR);
            sdly.SellRate += parseFloat(item.HotelTotal.sdly.SellRate);
            sdly.Revenue += parseFloat(item.HotelTotal.sdly.Revenue);

            sdly_actual.Rooms += parseInt(item.HotelTotal.sdly_actual.Rooms);
            sdly_actual.Occ = calc(sdly_actual.Occ, item.HotelTotal.sdly_actual.Occ, 3).add();
            sdly_actual.ADR += parseFloat(item.HotelTotal.sdly_actual.ADR);
            sdly_actual.RevPAR += parseFloat(item.HotelTotal.sdly_actual.RevPAR);
            sdly_actual.SellRate += parseFloat(item.HotelTotal.sdly_actual.SellRate);
            sdly_actual.Revenue += parseFloat(item.HotelTotal.sdly_actual.Revenue);

            variance.Rooms += parseInt(item.HotelTotal.primary.Rooms - item.HotelTotal.sdly.Rooms);
            variance.Occ = calc(sdly.Occ, item.HotelTotal.sdly.Occ, 3).add();
            variance.ADR += parseFloat(primary.ADR - sdly.ADR);
            variance.RevPAR += parseFloat(item.HotelTotal.sdly.RevPAR);
            variance.SellRate += parseFloat(item.HotelTotal.sdly.SellRate);
            variance.Revenue += parseFloat(item.HotelTotal.sdly.Revenue);

            pickup.Rooms += item.HotelTotal.pickup.Rooms;
            pickup.Revenue = calc(item.HotelTotal.pickup.Revenue, pickup.Revenue).add();
            pickup.Occ = calc(pickup.Occ, item.HotelTotal.pickup.Occ, 4).add();
            pickup.RevPAR = calc(pickup.RevPAR, item.HotelTotal.pickup.RevPAR, 5).add();
            pickup.SellRate = calc(pickup.SellRate, item.HotelTotal.pickup.SellRate).add();
            //pickup.ADR += Math.round(calc(primary.ADR, (item.HotelTotal.pickup.Revenue / pickup.Rooms)).diff() / 100);

            // debugger;
        }
    });
    // let dif = calc(0.1,0.2,2).add();
    aggregates.primary.Occ = Math.round(primary.Occ*10000 / noOfDays) /10000 ;
    aggregates.primary.ADR = Math.round(primary.Revenue / primary.Rooms);
    aggregates.sdly.Occ = Math.round(sdly.Occ*10000 / noOfDays) / 10000;
    aggregates.sdly.ADR = Math.round(sdly.Revenue / sdly.Rooms);
    aggregates.sdly_actual.Occ = Math.round(sdly_actual.Occ*10000 / noOfDays) / 10000;
    aggregates.sdly_actual.ADR = Math.round(sdly_actual.Revenue / sdly_actual.Rooms);

    aggregates.pickup.Rooms = calc(pickup.Rooms, primary.Rooms , 2).diff();
    aggregates.pickup.Revenue = Math.round(calc(pickup.Revenue, primary.Revenue , 2).diff());
    aggregates.pickup.ADR = aggregates.pickup.Revenue / aggregates.pickup.Rooms;
    aggregates.pickup.Occ = (calc(pickup.Occ, primary.Occ , 6).diff());
    aggregates.pickup.RevPAR = calc(pickup.RevPAR, primary.RevPAR , 2).diff();
    aggregates.pickup.SellRate = calc(pickup.SellRate, primary.SellRate, 4).diff();
    // debugger;
    //primary.Occ = primary.Occ / 10000;
    //sdly.Occ = sdly.Occ / 10000;
    //sdly_actual.Occ = sdly_actual.Occ / 10000;
    primary = {
        ...primary,
        Rooms: dataFormat("Rooms", primary.Rooms, ui_state),
        Occ: dataFormat("Occ", convertToPercent(primary.Occ / noOfDays), ui_state),
        ADR: isNaN(round(primary.Revenue / primary.Rooms)) ? "$0.00" :
            dataFormat("ADR", round(primary.Revenue / primary.Rooms), ui_state),
        RevPAR: dataFormat("RevPAR", round(primary.RevPAR / noOfDays), ui_state),
        Revenue: dataFormat("Revenue", Math.round(primary.Revenue), ui_state),
        SellRate: dataFormat("SellRate", round(primary.SellRate / noOfDays), ui_state),
    };
   
    sdly = {
        ...sdly,
        //Original aggregates without formatting
        Rooms_o: sdly.Rooms,
        Occ_o: convertToPercent(sdly.Occ / noOfDays),
        ADR_o: isNaN(round(sdly.Revenue / sdly.Rooms)) ? 0 : round(sdly.Revenue / sdly.Rooms),

        Rooms: dataFormat("Rooms", sdly.Rooms, ui_state),
        Occ: dataFormat("Occ", convertToPercent(sdly.Occ / noOfDays), ui_state),
        ADR: isNaN(round(sdly.Revenue / sdly.Rooms)) ? "$0.00" :
            dataFormat("ADR", round(sdly.Revenue / sdly.Rooms), ui_state),
        RevPAR: dataFormat("RevPAR", round(sdly.RevPAR / noOfDays), ui_state),
        Revenue: dataFormat("Revenue", Math.round(sdly.Revenue), ui_state),
        SellRate: dataFormat("SellRate", round(sdly.SellRate / noOfDays), ui_state),
    };
    sdly_actual = {
        ...sdly_actual,
        //Original aggregates without formatting
        Rooms: dataFormat("Rooms", sdly_actual.Rooms, ui_state),
        Occ: dataFormat("Occ", convertToPercent(sdly_actual.Occ / noOfDays), ui_state),
        ADR: isNaN(round(sdly_actual.Revenue / sdly_actual.Rooms)) ? "$0.00" :
            dataFormat("ADR", round(sdly_actual.Revenue / sdly_actual.Rooms), ui_state),
        RevPAR: dataFormat("RevPAR", round(sdly_actual.RevPAR / noOfDays), ui_state),
        Revenue: dataFormat("Revenue", Math.round(sdly_actual.Revenue), ui_state),
        SellRate: dataFormat("SellRate", round(sdly_actual.SellRate / noOfDays), ui_state),
    };

    let varAdr = parseFloat((primary.ADR).toString().substring(1)) - parseFloat((sdly.ADR).toString().substring(1));
    let varOcc = parseFloat((primary.Occ).toString().replaceAll("%","")) - parseFloat((sdly.Occ).toString().replaceAll("%","")) ;
    let varRevPar = parseFloat((primary.RevPAR).toString().substring(1)) - parseFloat((sdly.RevPAR).toString().substring(1));
    let varRevenue = parseFloat((primary.Revenue).toString().substring(1)) - parseFloat((sdly.Revenue).toString().substring(1));
    let varSellRate = parseFloat((primary.SellRate).toString().substring(1)) - parseFloat((sdly.SellRate).toString().substring(1));

    console.log("value ADR = ",varAdr);
    variance = {
        ...variance,
        Rooms: dataFormat("Rooms", variance.Rooms, ui_state),
        Occ: dataFormat("Occ", round(varOcc), ui_state),
        ADR: isNaN(varAdr) ? "$0.00" :
            dataFormat("ADR", round(varAdr), ui_state),
        RevPAR: dataFormat("RevPAR", round(varRevPar), ui_state),
        Revenue: dataFormat("Revenue", round(varRevenue), ui_state),
        SellRate: dataFormat("SellRate", round(varSellRate), ui_state)
    };
    // debugger;
    pickup = {
        ...pickup,
        Revenue: dataFormat("Revenue", aggregates.pickup.Revenue, ui_state),
        Rooms: dataFormat("Rooms", aggregates.pickup.Rooms, ui_state),
        Occ: dataFormat("Occ", convertToPercent((aggregates.pickup.Occ / noOfDays)).toFixed(2), ui_state),
        ADR: isNaN(round(aggregates.pickup.ADR)) ? "$0.00" :
            dataFormat("ADR", round(aggregates.pickup.ADR), ui_state),
        RevPAR: dataFormat("RevPAR", round(aggregates.pickup.RevPAR / noOfDays), ui_state),
        
        SellRate: dataFormat("SellRate", (round(aggregates.pickup.SellRate) / noOfDays), ui_state),
    };

    let dataObj = { monthData, primary, sdly, variance, pickup, aggregates };
    return dataObj;
}


export function getMonthlyData(data, ui_state, app_state) {
    let currentDate = moment(app_state.as_of_date).format('YYYY-MM');
    let selectedMonth;
    let dataSetup = [];
    for (let i = 0; i < 12; i++) {
        selectedMonth = moment(currentDate).add(i, "M").format('YYYY-MM-DD');
        dataSetup.push(calculateMonthlyData(data, selectedMonth, ui_state));
    }
    // debugger;
    return dataSetup;
}


export function calculateMonthlyForecastData(data, selectedMonth, ui_state) {
    const startDate = moment(selectedMonth).startOf('month').format('YYYY-MM-DD');
    const endDate = moment(selectedMonth).endOf('month').format('YYYY-MM-DD');
    let monthData = moment(selectedMonth).format('MMM, YYYY');
    let noOfDays = moment(monthData).daysInMonth();

    let forecast = { Rooms: 0, Occ: 0, ADR: 0, RevPAR: 0, SellRate: 0, Revenue: 0 };
    data.data.map((item, index) => {
        if (moment(item.stay_date) >= moment(startDate) && moment(item.stay_date) <= moment(endDate)) {
            forecast.Rooms += parseInt(item.predicted_total_cons_rooms);
            forecast.Occ += parseFloat(item.predicted_total_cons_occ);
            forecast.ADR += parseFloat(item.predicted_total_cons_adr);
            forecast.RevPAR += parseFloat(item.predicted_total_cons_revpar);
            forecast.Revenue += parseFloat(item.predicted_total_cons_revenue);
        }
    })

    forecast = {
        ...forecast,
        Rooms: dataFormat("Rooms", forecast.Rooms, ui_state),
        Occ: dataFormat("Occ", convertToPercent(forecast.Occ / noOfDays), ui_state),
        ADR: isNaN(round(forecast.Revenue / forecast.Rooms)) ? "$0.00" :
            dataFormat("ADR", round(forecast.Revenue / forecast.Rooms), ui_state),
        RevPAR: dataFormat("RevPAR", round(forecast.RevPAR / noOfDays), ui_state),
        Revenue: dataFormat("Revenue", Math.round(forecast.Revenue), ui_state),
    };

    let dataObj = { forecast };
    // console.log("Forecast final data is =>",dataObj);

    return dataObj;
}

export function getMonthlyForecastData(data, ui_state, app_state) {
    let currentDate = moment(app_state.as_of_date).format('YYYY-MM');
    let selectedMonth;
    let dataSetup = [];
    for (let i = 0; i < 12; i++) {
        selectedMonth = moment(currentDate).add(i, "M").format('YYYY-MM-DD');
        dataSetup.push(calculateMonthlyForecastData(data, selectedMonth, ui_state));
    }
    return dataSetup;
}


// function to get 365 data from the api
export const getData365 = async ({date_from,as_of_date, property_id, workspace_controls,removeCompId}, seriesID) => {
    let rangeFromDate = date_from
    let rangeUntilDate = moment(rangeFromDate).add(1, "year").format('YYYY-MM-DD');
    let selectedAPI = API_REGISTRY[seriesID];
    let varMaps = {
        pid: property_id,
        pickUpDays: workspace_controls.pickUpDays,
        sdYear: workspace_controls.sdYear,
        fromDate: rangeFromDate,
        date_from:rangeFromDate,
        rangeFromDate: rangeFromDate,
        removeCompId:removeCompId,
        rangeUntilDate: rangeUntilDate,
        date_until:rangeUntilDate,
        toDate: rangeUntilDate,
        asof_date: as_of_date,
        asOfDate: as_of_date,
        pid: property_id,
        timestamp:Math.random()
    };
    let payload = {
        method:"GET",
    } ;
    if(seriesID === "Compset"){
        payload.api=`rms/properties/compset/${varMaps.pid}/?removeCompId=true&asOfDate=${varMaps.asOfDate}&fromDate=${varMaps.date_from}&toDate=${varMaps.date_until}&time=${varMaps.timestamp}`
    }
    else{
        payload.api = setApiURLVars(selectedAPI.uri, varMaps);

    }
 
    return dataService.getData({ api: payload.api, request:payload, });
    //return payload;

}


export function calForeCastData(OtbFullData, foreCastData, app_state, ui_state) {
    console.log("both data ");
    console.log(OtbFullData);
    console.log(foreCastData);
    let otbData = [];
    let otb = OtbFullData.data;
    let as_of_date = app_state.as_of_date;
    let startDate = moment(as_of_date).format('YYYY-MM-01');
    let endDate = moment(startDate).endOf('month').format('YYYY-MM-DD');
    let noOfDays = moment(endDate).daysInMonth();


    let beforeDatesCount = Math.abs(moment.duration(moment(startDate).diff(moment(as_of_date))).asDays());
    
    const getPastOtbData = otb.slice(0, beforeDatesCount);
    for (let n = 0; n < getPastOtbData.length; n++) {
        otbData.push({
            stay_date: getPastOtbData[n]['index'].date,
            predicted_total_cons_rooms: getPastOtbData[n]['HotelTotal']['primary'].Rooms,
            predicted_total_cons_adr: getPastOtbData[n]['HotelTotal']['primary'].ADR,
            predicted_total_cons_revpar: getPastOtbData[n]['HotelTotal']['primary'].RevPAR,
            predicted_total_cons_revenue: getPastOtbData[n]['HotelTotal']['primary'].Revenue,
            predicted_total_cons_occ: getPastOtbData[n]['HotelTotal']['primary'].Occ
        })
    }
    console.log("foreCastData.data= ", foreCastData.data);
    // debugger;
    let m = Math.abs(moment.duration(moment(as_of_date).diff(moment(endDate))).asDays()) + 1;
    let forcastRemaingData = foreCastData.data.data.slice(0, m);
    
    let firstMonthData = otbData.concat(forcastRemaingData);
    let forecastFirstMonth = { Rooms: 0, Occ: 0, ADR: 0, RevPAR: 0, Revenue: 0 };
    firstMonthData.map((item, index) => {
        forecastFirstMonth.Rooms += parseInt(item.predicted_total_cons_rooms);
        forecastFirstMonth.Occ += parseFloat(item.predicted_total_cons_occ);
        forecastFirstMonth.ADR += parseFloat(item.predicted_total_cons_adr);
        forecastFirstMonth.RevPAR += parseFloat(item.predicted_total_cons_revpar);
        forecastFirstMonth.Revenue += parseFloat(item.predicted_total_cons_revenue);
    })

    forecastFirstMonth = {
        ...forecastFirstMonth,
        Rooms: dataFormat("Rooms", forecastFirstMonth.Rooms, ui_state),
        Occ: dataFormat("Occ", convertToPercent(forecastFirstMonth.Occ / noOfDays), ui_state),
        ADR: isNaN(round(forecastFirstMonth.Revenue / forecastFirstMonth.Rooms)) ? "$0.00" :
            dataFormat("ADR", round(forecastFirstMonth.Revenue / forecastFirstMonth.Rooms), ui_state),
        RevPAR: dataFormat("RevPAR", round(forecastFirstMonth.RevPAR / noOfDays), ui_state),
        Revenue: dataFormatMonthlyCard("Revenue", Math.round(forecastFirstMonth.Revenue), ui_state),
    };

    console.log("forecastFirstMonth => ", forecastFirstMonth);


    console.log(m, " => ", moment(startDate).add(1, "month").daysInMonth());
    let nextMonthDays = moment(startDate).add(1, "month").daysInMonth();
    let nextMonthData = foreCastData.data.data.splice(m, nextMonthDays)
    let forecastNextMonth = { Rooms: 0, Occ: 0, ADR: 0, RevPAR: 0, Revenue: 0 };
    nextMonthData.map((item, index) => {
        forecastNextMonth.Rooms += parseInt(item.predicted_total_cons_rooms);
        forecastNextMonth.Occ += parseFloat(item.predicted_total_cons_occ);
        forecastNextMonth.ADR += parseFloat(item.predicted_total_cons_adr);
        forecastNextMonth.RevPAR += parseFloat(item.predicted_total_cons_revpar);
        forecastNextMonth.Revenue += parseFloat(item.predicted_total_cons_revenue);
    })

    forecastNextMonth = {
        ...forecastNextMonth,
        Rooms: dataFormat("Rooms", forecastNextMonth.Rooms, ui_state),
        Occ: dataFormat("Occ", convertToPercent(forecastNextMonth.Occ / nextMonthDays), ui_state),
        ADR: isNaN(round(forecastNextMonth.Revenue / forecastNextMonth.Rooms)) ? "$0.00" :
            dataFormat("ADR", round(forecastNextMonth.Revenue / forecastNextMonth.Rooms), ui_state),
        RevPAR: dataFormat("RevPAR", round(forecastNextMonth.RevPAR / nextMonthDays), ui_state),
        Revenue: dataFormatMonthlyCard("Revenue", Math.round(forecastNextMonth.Revenue), ui_state),
    };
    console.log("forecastNextMonth => ", forecastNextMonth);

    // far month data 
    let farMonthDays = moment(startDate).add(2, "month").daysInMonth();
    let farMonthData = foreCastData.data.data.splice((m), farMonthDays)
    console.log("m", m);
    console.log("farMonthDays ", farMonthDays);
    console.log("farMonthData", farMonthData);
    let forecastFarMonth = { Rooms: 0, Occ: 0, ADR: 0, RevPAR: 0, Revenue: 0 };
    farMonthData.map((item, index) => {
        forecastFarMonth.Rooms += parseInt(item.predicted_total_cons_rooms);
        forecastFarMonth.Occ += parseFloat(item.predicted_total_cons_occ);
        forecastFarMonth.ADR += parseFloat(item.predicted_total_cons_adr);
        forecastFarMonth.RevPAR += parseFloat(item.predicted_total_cons_revpar);
        forecastFarMonth.Revenue += parseFloat(item.predicted_total_cons_revenue);
    })

    forecastFarMonth = {
        ...forecastFarMonth,
        Rooms: dataFormat("Rooms", forecastFarMonth.Rooms, ui_state),
        Occ: dataFormat("Occ", convertToPercent(forecastFarMonth.Occ / farMonthDays), ui_state),
        ADR: isNaN(round(forecastFarMonth.Revenue / forecastFarMonth.Rooms)) ? "$0.00" :
            dataFormat("ADR", round(forecastFarMonth.Revenue / forecastFarMonth.Rooms), ui_state),
        RevPAR: dataFormat("RevPAR", round(forecastFarMonth.RevPAR / farMonthDays), ui_state),
        Revenue: dataFormatMonthlyCard("Revenue", Math.round(forecastFarMonth.Revenue), ui_state),
    };
    console.log("forecastFarMonth => ", forecastFarMonth);
    let dataObj = [forecastFirstMonth, forecastNextMonth, forecastFarMonth];
    return dataObj;
}

export function generatDailyFlashChartOptions(displayData,
    { reportType, identifiers, measures, data_id, ui_state }) {
    const chartColors = deepCopy(CHART_OPTIONS.COLORS.dailyFlash);
    const options = deepCopy(CHART_OPTIONS.dailyFlash);
    const active_api = 'OSR';
    // debugger;
    //series is the set od data points for a line/column, initial measures will check the measures input to create the data sets
    let tile_id = reportType;
    const { data_sources } = TILES['daily_flash'];
    const chartConfig = { mainkey: 'aggregates', xAxisKey: 'monthData' };
    const series = [];

    // Setting up default measures
    let intialMeasures = [measures[0], measures[1]];

    //As no pre defined identifiers is there for compset and it depends on compset names in API response

    // debugger;  
    //setting name is important for colors
    let seriesLabel = '';
    seriesLabel = capitalizeFirstLetter(tile_id);
    if (tile_id === 'otb' || tile_id === 'pickup') {
        seriesLabel = 'OTB'
    }
    let seriesName = '';

    const category = active_api + seriesLabel;
    let setYAxis = true;
    const yAxisArr = [];

    // debugger;
    // options.xAxis.min = new moment().startOf('month').format('x');
    // options.xAxis.max = moment().add(3, 'month').format('x');
    options.xAxis[0].type = 'category';
    // options.xAxis[0].labels.formatter = function () {
    //     let d = this.value;
    //     return new moment(this.value).format("MMM");
    // }
    identifiers.map((identifier, identifierIndex) => {
        seriesName = identifier
        if (identifier === 'primary') {
            seriesName = seriesLabel
        }
        if (identifier === 'pickup') {
            seriesName = seriesLabel
        }
        seriesName = seriesName.split('_').join( ' ');
        
        // debugger 
        //only setting y-axis in first iteration of identifiers
        if (identifierIndex == 1) { setYAxis = false; }

        intialMeasures.map((measure, measureIndex) => {
            // debugger;
           
            if (measure) {
                let color_key = measureIndex ? tile_id + '_' + identifier + '_R' : tile_id + '_' + identifier + '_L';
                color_key = color_key.toUpperCase();
                let seriesLabel = measure + ' ' + identifier.split('_').join(' ').toUpperCase();
                if (identifier === 'primary' || identifier === 'pickup') {
                    seriesLabel = measure + ' ';
                    if (measure === 'Occ') {
                        seriesLabel = measure + '% ';
                    }
                } else {
                    if (measure === 'Occ') {
                        seriesLabel = measure + '% ' + identifier.split('_').join(' ').toUpperCase();
                    }
                }
                


                const seriesConfig = {
                    "data": [],
                    "name": seriesLabel,
                    "id": seriesLabel + '_' + identifier + '_' + measure + '_' + measureIndex,
                    "color": chartColors[color_key],
                    "colorKey": color_key,
                    "currency":{code: ui_state.currency, symbol: ui_state.currency_symbol},
                    "dataGrouping": {
                        "units": [
                            [
                                "month",
                                [
                                    1
                                ]
                            ]
                        ],
                        "enabled": false
                    },
                    "type": 'column',
                    "negativeColor": "#FF0000",
                    "yAxis": "",
                    "category": category,
                    "seriesName": seriesName,
                    "mainMeasure": measure,
                    "subCategory": "",
                    "stack": seriesName + "_" + measureIndex
                }
                //Do some different for Occ measure
                if (measure === "Occ") {
                    seriesConfig["mainMeasure"] = measure + "%";
                }
                //first measure should always be in column form with light blue
                // debugger;
                if (measureIndex === 0) {
                    seriesConfig["yAxis"] = "primaryYAxis" + measure;
                    seriesConfig["zIndex"] = 1

                    if (measure === "Occ") {
                        seriesConfig["yAxis"] = "primaryYAxis" + measure + "%";
                    }
                }
                //otherwise second measure should be line/lines
                else {
                    seriesConfig["yAxis"] = "secondaryRightYAxis" + measure;
                    seriesConfig["type"] = 'spline';
                    seriesConfig["zIndex"] = 2
                    if (measure === "Occ") {
                        seriesConfig["yAxis"] = "secondaryRightYAxis" + measure + "%";
                    }

                }

                //code for setting y-axis
                if (setYAxis) {
                    const yAxisConfig = {
                        "id": "",
                        "title": {
                            "text": "",
                            "style": {
                                "color": "#606060",
                                "fontFamily": "Lato Regular"
                            },
                            "useHTML": true,
                            "align": "high",
                            "offset": 0,
                            "rotation": 0
                        },
                        "opposite": false,
                        "labels": {
                            "style": {
                                "color": "#606060",
                                "fontFamily": "Lato Regular"
                            }
                        },
                        "crosshair": {
                            "width": 1,
                            "color": "rgb(130,203,208)"
                        },
                        "endOnTick": true,
                        "showLastLabel": true,
                        "maxPadding": 0.25,

                    };
                    yAxisConfig["id"] = seriesConfig.yAxis;

                    if (measureIndex == 0) {

                        yAxisConfig["plotLines"] = [
                            {
                                "value": 100,
                                "color": "black",
                                "dashStyle": "shortdash",
                                "width": 2,
                                "label": {
                                    "text": ""
                                }
                            }
                        ]
                    } else {
                        yAxisConfig["gridLineColor"] = "transparent";
                        yAxisConfig["opposite"] = true;

                    }
                    options.yAxis.push(yAxisConfig);
                }

                //setting data from identifier and measure into data to graph
                if (displayData.length) {
                    let chartData = generateChartData(displayData, { chartConfig, identifier, measure, data_id, ui_state });
                    seriesConfig.data = chartData;
                }

                //seriesConfig["data"].sort((a,b) => a.x - b.x)
                seriesConfig['marker'] = { enabled: false };
                // seriesConfig.visible = true;
                seriesConfig.showInLegend = true;

                //Setting colors for the chart curves/columns
                let seriesConfigNew = { ...seriesConfig };
                options.series.push(seriesConfigNew);
            }
        })
    });
    // debugger;

    return options;
}
const generateChartData = (displayData, params) => {
    // debugger;
    const size = displayData.length;
    const { chartConfig, identifier, measure, data_id } = params;
    const { xAxisKey, mainkey } = chartConfig;
    let data = [];
    for (let index = 0; index < size; index++) {
        const dataItem = displayData[index];
        let dataObj = null;
        let xValue = 0;
        let yValue = 0;
        let xKey = xAxisKey;
        //Handle dates
        const month = dataItem[xKey];
        // debugger;
        let tooltipLabel = dataItem[xKey];
        xValue = Number(new moment(month).startOf('month').format('x'));
        // debugger 

        if (mainkey) {
            dataObj = dataItem[mainkey];
        }

        if (dataObj && dataObj[identifier][measure]) {
            yValue = round(dataObj[identifier][measure]);
            //Handle Occ differently
            if (measure === "Occ") {
                yValue = round(dataObj[identifier][measure] * 100);
            }
        }
        // data.push({ "x": month, "y": yValue, "tooltipLabel": tooltipLabel });
        // debugger
        data.push([month.split(',')[0], yValue, tooltipLabel ]);

    }
    // debugger;
    return data;
};


// function for download report 


export function downloadReportToExcelx(app_state, ui_state, selectedPropertyName,data) {
    console.log("data and clicked =>",data);

    let exportFileName = moment(app_state.as_of_date).format('MMM DD YYYY') + "_" + selectedPropertyName + "_DailyFlash" ;
    if (data && data.length > 0) {
        console.log("data and clicked =>",data);
        TableToExcel.convert(document.getElementById("dailyFlashTable"), {
            name: exportFileName + ".xlsx",
            sheet: {
                name: "Daily Flash Report"
            }
        });
    }
}




