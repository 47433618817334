import * as React from 'react';
import ListSubheader from '@mui/material/ListSubheader';
import List from '@mui/material/List';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import Collapse from '@mui/material/Collapse';
import ExpandLess from '@mui/icons-material/ExpandLess';
import ExpandMore from '@mui/icons-material/ExpandMore';
import StarBorder from '@mui/icons-material/StarBorder';
import ViewWeekIcon from '@mui/icons-material/ViewWeek';
import FilterAltIcon from '@mui/icons-material/FilterAlt';
import ArrowDropUpIcon from '@mui/icons-material/ArrowDropUp';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import { Box } from '@mui/material';
import { Button, Divider, FormControlLabel, FormGroup, ListItem, Switch, TextField } from '@mui/material';
import { deepCopy } from '../../app/util';

export default function FilterItems2(props) {
    const { currentColumn, filteredColumns } = props;
    const [subcolumns, setSubcolumns] = React.useState([]);
    const [open, setOpen] = React.useState(false);
    const [colOpen, setColOpen] = React.useState(false);
    const [filter, setFilter] = React.useState({ gte: "", lte: "" });
    const compRef = React.useRef({
        filter: {
            lte: '',
            gte: ''
        }
    });

    if (filteredColumns && filteredColumns.length) {
        filteredColumns.map(column => {
            if (column.value !== currentColumn.value) {
                compRef.current.filterExists = true;
            }
        });
    } else {
        compRef.current.filterExists = false;
    }
    const handleClick = () => {
        setOpen(!open);
    };
    const handleColClick = () => {
        setColOpen(!colOpen);

    };

    const clearFilterHandler = (ev, column) => {
        // debugger;
        column.filter = { lte: "", gte: "" };
        setFilter({ lte: "", gte: "" });
        props.clearFilterHandler(column);
    }
    const clearAllFilterHandler = (ev, column) => {
        // debugger;
        column.filter = { lte: "", gte: "" };
        setFilter({ lte: "", gte: "" });
        props.clearFilterHandler(column, "all");
    }

    const applyFilterHandler = (ev, column) => {
        let newFilter = deepCopy(filter);
        // clearAllFilterHandler(null, column);
        column.filter = newFilter;
        setFilter(newFilter);
        props.applyFilterHandler(column);
        // debugger;
    }



    React.useEffect(() => {
        let isSubscribed = true;
        let mainCols = props.columnList;
        // debugger; 
        let subcols = [];
        mainCols.map(main => {
            // debugger
            main.subcolumns.map(subcol => {
                let isExisting = subcols.filter((col) => col.value === subcol.value);
                if (!isExisting.length) {
                    subcols.push(subcol);

                }
            });
        });
        setSubcolumns(subcols);
        if (currentColumn.filter) {
            setFilter({ ...filter, gte: currentColumn.filter.gte, lte: currentColumn.filter.lte });
        };

        return () => (isSubscribed = false);
    }, []);


    return (
        <List
            sx={{ width: '100%', maxWidth: 600, bgcolor: 'background.paper' }}
            component="nav"
            dense={true}
            aria-labelledby="nested-list-subheader">

            {/* Columns Section  */}
            <ListItemButton onClick={handleColClick}>
                <ListItemIcon sx={{ minWidth: "35px !important" }}>
                    <ViewWeekIcon />
                </ListItemIcon>
                <ListItemText primary="Columns" />
                {colOpen ? <ExpandLess /> : <ExpandMore />}
            </ListItemButton>
            <Collapse in={colOpen} timeout="auto" unmountOnExit>
                <ColumnToggle colList={subcolumns} currentColumn={props.currentColumn} columnToggleHandler={props.columnToggleHandler} />
            </Collapse>
            {/* End Columns Section  */}

            {currentColumn.filterable && <>
                <Divider />
                <ListItemButton onClick={handleClick}>
                    <ListItemIcon sx={{ minWidth: "35px !important" }}>
                        <FilterAltIcon />
                    </ListItemIcon>
                    <ListItemText primary={(<><strong>{currentColumn.display}</strong>  Filter</>)} />
                    {open ? <ExpandLess /> : <ExpandMore />}
                </ListItemButton>

                {open && <Box timeout="auto" unmountOnExit>
                    <ListItemButton sx={{ p: 0, pt: 1, pb: 1, pl: 3, pr: 2 }}>

                        <ListItemText primary={"Greater Than Equal To "} />
                        <TextField
                            size="small"
                            sx={{ width: 80, "& input": { padding: "0 0 0 5px" } }}
                            // defaultValue={filter.gte}
                            value={filter.gte}
                            onChange={(ev) => {
                                // debugger;
                                setFilter({ ...filter, gte: Number(ev.target.value) });
                                // debugger;
                            }}
                            InputProps={{

                                style: { height: 26, width: 80 },
                                type: 'number',
                                // defaultValue: filter.gte,
                                // value:filter.gte,

                            }}>
                        </TextField>

                    </ListItemButton>
                    <ListItemButton sx={{ p: 0, pt: 1, pb: 1, pl: 3, pr: 2 }}>

                        <ListItemText primary={"Less Than Equal To "} />
                        <TextField
                            size="small"
                            sx={{ p: 0, "& input": { padding: "0 0 0 5px" } }}
                            value={filter.lte}
                            onChange={(ev) => {
                                // debugger;
                                setFilter({ ...filter, lte: Number(ev.target.value) });
                            }}
                            InputProps={{
                                style: { width: 80, height: 26 },
                                type: 'number',
                            }}>
                        </TextField>

                    </ListItemButton>
                    <Divider />
                    <Box sx={{ textAlign: 'right', mt: 1, p: 0.5, pr: 2 }}>

                        <Button disabled={!compRef.current.filterExists} variant="outlined" sx={{ mr: 1 }} size="small" onClick={(ev) => clearAllFilterHandler(ev, currentColumn)}>Clear All</Button>
                        <Button disabled={!filter.gte && !filter.lte} variant="outlined" sx={{ mr: 1 }} size="small" onClick={(ev) => clearFilterHandler(ev, currentColumn)}>Clear</Button>
                        <Button disabled={!filter.gte && !filter.lte} variant="outlined" size="small" onClick={(ev) => applyFilterHandler(ev, currentColumn)}>Apply</Button>
                    </Box>
                </Box>}
            </>}
        </List>
    );
}

const ColumnToggle = (props) => {
    let cols = props.colList;
    // debugger;
    // let cols = [{value: "dow", hidden: true, display: "DOW"}]
    let [columnToggle, setColumnToggle] = React.useState(cols);
    let switches = [];

    const handleChange = (ev, item, index) => {
        let target = ev.target;
        setColumnToggle(() => {
            columnToggle[index].hidden = !target.checked
            return [...columnToggle];
        });

        props.columnToggleHandler(item, index);
    }
    columnToggle.map((item, i) => {
        switches.push(<ListItem>
            <FormControlLabel key={item.value + i} sx={{ mb: -1 }} value={item.value}
                control={<Switch disabled={props.currentColumn.value === item.value} onChange={(ev) => { handleChange(ev, item, i) }} color="primary" checked={!item.hidden} name={item.display} />}
                label={item.display} labelPlacement="start" />

        </ListItem>)
    })
    return (<FormGroup>{switches}</FormGroup>);
}