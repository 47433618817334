import moment from 'moment';
import { currencyFormat } from '../app/util';
// import { TILES } from './config-default';
import { TABLE_CONFIG } from "./table-config"


const API_BASE_URL = process.env.REACT_APP_API_URL;
const API_DI_CERT = "";
const API_REPORT_URL = "";

const api_status = {};
const promises = { all: [] };
// const TILE_GROUP_ONE = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10];
// const TILE_GROUP_ONE = ['pricing', 'restrictions', 'group', 'forecast', 'budget'];
const TILE_GROUP_ONE = ['pricing', 'restrictions', 'forecast', 'budget', 'hotel_summary'];
// const TILE_GROUP_TWO = ['otb', 'pickup', 'pace', 'compset', 'market_sell_rates', 'market', 'market_summary'];
const TILE_GROUP_TWO = ['otb', 'pickup', 'pace', 'compset', 'market_sell_rates', 'market', 'flights'];

const ABORT_REQUEST_CONTROLLERS = new Map();

const API_REGISTRY = {
    "SwitchDataSource": {
        uri: "rms/properties/switch-data-source/{pid}/?from={fromDS}&to={toDS}", // add property_id
        payload: {
            method: "GET",
        },
        data: null,
        loaded: false,
        loading: false,
    },
    "SendRestrictionsPush": {
        uri: "rms/properties/restrictions/", // add property_id
        payload: {

        },
        data: null,
        loaded: false,
        loading: false,
    },
    "UserLogin": {
        uri: "users/login",
        payload: {
            "email": null,
            "password": null,
        },
        data: null,
        loaded: false,
        loading: false,
    },
    "UsersFBToken": {
        uri: "users/fbtoken/",
        payload: {
            "method": "POST",
        },
        data: null,
        loaded: false,
        loading: false,
    },
    "UserRequestResetPassword": {
        uri: "users/forget-password/",
        payload: {
            "email": null,
        },
        data: null,
        loaded: false,
        loading: false,
    },
    "UserResetNewPassword": {
        uri: "users/save-password/",
        payload: {
            "code": null,
            "password": null,
        },
        data: null,
        loaded: false,
        loading: false,
    },
    "UserProfile": {
        uri: "users/get-profile",
        payload: {
            "method": "GET",
            "token_string": null,
            "user_id": null,
        },
        data: null,
        loaded: false,
        loading: false,
    },
    "SwitchProperty": {
        uri: "users/switch-property/?from={from_pid}&to={to_pid}&ss={system_settings}",
        payload: {
            "method": "GET",
            "token_string": null,
            "user_id": null,
        },
        data: null,
        loaded: false,
        loading: false,
    },
    "SaveSystemSettings": {
        uri: "rms/system-settings/",
        payload: {
            "method": "PUT",
            // "token_string": null,
            // "user_id": null,
        },
        data: null,
        loaded: false,
        loading: false,
    },

    "UploadPropertyFile": {
        uri: "upload/property_file_upload/{pid}/",
        payload: {
            "method": "POST",
        },
        data: null,
        loaded: false,
        loading: false,
    },

    "GetGroupPricingQuote": {
        uri: "rms/properties/group-quote/{pid}/",
        payload: {
            "as_of_date": null,
            "dates": [],
            'group_name': null,
            overall_request: {}
        },
        data: null,
        loaded: false,
        loading: false,
    },

    "SaveGroupPricingQuote": {
        uri: "rms/properties/download-group-quote/{pid}/",
        payload: {
            "as_of_date": null,
            "dates": [],
            'group_name': null,
            'emails': null,
            overall_request: {}
        },
        data: null,
        loaded: false,
        loading: false,
    },

    "ExportReport": {
        uri: "reports/combine-export/{pid}/?asOfDate={asOfDate}",

        payload: {
            "method": "GET",
            "as_of_date": null,
        },
        data: null,
        loaded: false,
        loading: false,
    },




    "OSRRateRecommend": {
        uri: "rms/properties/rtp-rates/{pid}/?asOfDate={asOfDate}&fromDate={rangeFromDate}&toDate={rangeUntilDate}",
        payload: {
            "method": "GET",
            "params": {
                "pid": null,
                "asof_date": null,
                "date_from": null,
                "date_until": null,
                "from_cache": "false"
            },
            "token_string": null
        },
        data: null,
        loaded: false,
        loading: false,
    },


    "OSR": {
        //To reduce API calls for now we are including all params for OSR like segmented sdyear
        //Commenting the original/old one
        // uri: "rms/properties/otb/{pid}/?sdYear={sdYear}&pickupDays={pickUpDays}&includeSdly=true&asOfDate={asOfDate}&fromDate={rangeFromDate}&toDate={rangeUntilDate}",
        //This is new one
        uri: "rms/properties/otb/{pid}/?includeSellRate=true&segment=true&sdYear={sdYear}&pickupDays={pickUpDays}&includeSdly=true&asOfDate={asOfDate}&fromDate={rangeFromDate}&toDate={rangeUntilDate}",

        payload: {
            "method": "GET",
            "resourceId": null,
            "resourceScope": "property",
            "token": null,
            "asOfDate": null,
            "pickupDays": 1,
            "rangeFromDate": null,
            "rangeUntilDate": null,
            "queryType": "ARI",
            "querySubtype": "",
            "segmentationType": "market_code",
            "segmentFilter": "1",
            "type": 1,

            //v4 specific
            "query": {
                "includeSdly": true,
                "asOfDate": null,
                "fromDate": null,
                "toDate": null,
            }
        },
        AbortSignal: {},
        data: null,
        loaded: false,
        loading: false,
    },
    "OSRYear": {
        uri: "rms/properties/otb/{pid}/?pickupDays={pickUpDays}&includeSdly=true&asOfDate={asof_date}&fromDate={date_from_daily}&toDate={date_to_daily}",
        payload: {
            "method": "GET",
            "resourceId": null,
            "resourceScope": "property",
            "token": null,
            "asOfDate": null,
            "pickupDays": 1,
            "rangeFromDate": null,
            "rangeUntilDate": null,
            "queryType": "ARI",
            "querySubtype": "",
            "segmentationType": "market_code",
            "segmentFilter": "1",
            "type": 1
        },
        data: null,
        loaded: false,
        loading: false,
    },
    "OSRSegment": {
        uri: "rms/properties/otb/{pid}/?sdYear={sdYear}&pickupDays={pickUpDays}&segment=true&includeSdly=true&asOfDate={asof_date}&fromDate={date_from}&toDate={date_until}",
        payload: {
            "method": "GET",
            "resourceId": null,
            "resourceScope": "property",
            "token": null,
            "asOfDate": null,
            "pickupDays": 1,
            "rangeFromDate": null,
            "rangeUntilDate": null,
            "queryType": "ARI",
            "querySubtype": "",
            "segmentationType": "market_code",
            "segmentFilter": "all",
            "type": 1,
        },
        data: null,
        loaded: false,
        loading: false,
    },

    //Restrictions API registration
    "GetRestrictions": {
        uri: "rms/properties/restrictions/{pid}/?fromDate={date_from}&toDate={date_until}&time={timestamp}",
        payload: {
            "method": "GET",
        },
        data: null,
        loaded: false,
        loading: false,
    },
    "GetRestrictionsRoomType": {
        uri: "rms/properties/restrictions/{pid}/?roomType=true&fromDate={date_from}&toDate={date_until}&time={timestamp}",
        payload: {
            "method": "GET",
        },
        data: null,
        loaded: false,
        loading: false,
    },

    "GetPropData": {
        uri: "rms/properties/{pid}/",
        payload: {
            "method": "GET",
            "property_id": null,
            "token_string": null,
            "room_type": true
        },
        data: null,
        loaded: false,
        loading: false,
    },
    // "forecast": {
    //     uri: "api.v1/get_hotel_forecast/",
    //     payload: {
    //         "params": {
    //             "pid": null,
    //             "asof_date": null,
    //             "date_from": null,
    //             "date_until": null,
    //             "from_cache": "false"
    //         },
    //         "token_string": null
    //     },
    //     data: null,
    //     loaded: false,
    //     loading: false,
    // },
    "ManualForecast": {
        uri: "rms/properties/user-forecast/{pid}/?asOfDate={asOfDate}&fromDate={date_from}&toDate={date_until}",
        payload: {
            "method": "GET",
        },
        data: null,
        loaded: false,
        loading: false,
    },

    "CensusInfo": {
        uri: "rms/markets/census-info/{mid}/",
        payload: {
            "method": "GET",
        },
        data: null,
        loaded: false,
        loading: false,
    },

    "MarketARI": {
        uri: "rms/markets/otb/{mid}/?includeSellRate=true&asOfDate={asOfDate}&fromDate={date_from}&toDate={date_until}&pickupDays={pickUpDays}",
        payload: {
            "method": "GET",
            "resourceId": "",
            "resourceScope": "market",
            "token": null,
            "asOfDate": null,
            "pickupDays": 1,
            "rangeFromDate": null,
            "rangeUntilDate": null,
            "queryType": "ARI",
            "querySubtype": "",
            "segmentationType": "market_code",
            "segmentFilter": "total"
        },
        data: null,
        loaded: false,
        loading: false,
    },
    "MarketARISegmented": {
        uri: "rms/markets/otb/{mid}/?includeSellRate=false&segment=true&asOfDate={asOfDate}&fromDate={date_from}&toDate={date_until}&pickupDays={pickUpDays}",
        payload: {
            "method": "GET",
            "resourceId": "",
            "resourceScope": "market",
            "token": null,
            "asOfDate": null,
            "pickupDays": 1,
            "rangeFromDate": null,
            "rangeUntilDate": null,
            "queryType": "ARI",
            "querySubtype": "",
            "segmentationType": "market_code",
            "segmentFilter": "all"
        },
        data: null,
        loaded: false,
        loading: false,
    },
    "MarketForecast": {
        uri: "rms/markets/futures/{mid}/?includeSellRate=false&asOfDate={asOfDate}&fromDate={date_from}&toDate={date_until}",
        // uri: "api.v1/get_mfc_market_futures/",
        payload: {
            "method": "GET",
            "params": {
                "algo": "MFC_v3",
                "marketid": "",
                "asof_date": null,
                "date_from": null,
                "date_to": null,
                "all_forecast_measures": "True",
            },
        },
        data: null,
        loaded: false,
        loading: false,
    },
    "MarketForecastSegmented": {
        uri: "rms/markets/futures/{mid}/?includeSellRate=false&segment=true&asOfDate={asOfDate}&fromDate={date_from}&toDate={date_until}",

        // uri: "api.v1/get_mfc_market_futures/",
        payload: {
            "method": "GET",
            "params": {
                "algo": "MFC_v3",
                "marketid": "",
                "asof_date": null,
                "date_from": null,
                "date_to": null,
                "segmented": "True",
                "all_forecast_measures": "True",
            },
        },
        data: null,
        loaded: false,
        loading: false,
    },
    "MarketDba": {
        uri: "rms/markets/booking-curve/{mid}/?dbaFilter={dbaFilter}&stayDate={date_from}&includeSdly=true",
        // uri: "market/get_market_historical_curve/",
        payload: {
            "method": "GET",
            "resourceId": null,
            "resourceScope": "market",
            "source": "ota_i_api",
            "token": null,
            "rangeFromDate": null,
            "rangeUntilDate": null,
            "segmentFilter": "1"
        },
        data: null,
        loaded: false,
        loading: false,

    },
    "MarketCapacity": {
        uri: "rms/markets/capacity/{mid}/",
        payload: {
            "method": "GET",
            "token": null,
        },
        data: null,
        loaded: false,
        loading: false,

    },

    "MarketSdlyDba": {
        uri: "rms/markets/booking-curve/{mid}/?dbaFilter={dbaFilter}&includeSdly=true&stayDate={date_from}",
        // uri: "market/get_market_historical_curve/",
        payload: {
            "method": "GET",
            "resourceId": null,
            "resourceScope": "market",
            "token": null,
            "source": "ota_i_api",
            "rangeFromDate": null,
            "rangeUntilDate": null,
            "segmentFilter": "1"
        },
        data: null,
        loaded: false,
        loading: false,

    },

    "ForcastSegment": {
        uri: "forecast/get_constrained_multiStay_forecast",
        payload: {
            "method": "GET",
            "property_id": "396",
            "token_string": "313280f831b64bccb8e3a4b1e0f08fa3",
            "asof_date": "2022-12-13",
            "resource_type": "property",
            "forecast_start_range": "2022-12-13",
            "forecast_end_range": "2022-12-31",
            "segment_type": "market_code",
            "segment_filter": "all"
        },
        data: null,
        loaded: false,
        loading: false,
    },

    "OSRContrainedForecast": {
        uri: "rms/properties/forecast/{pid}/?asOfDate={asOfDate}&fromDate={date_from}&toDate={rangeUntilDate}",
        // uri: "http://rm-api-dev.lodgiq.com/api.v1/get_hotel_forecast/",
        payload: {
            "method": "GET",
            "asOfDate": null,
            "params": {
                "pid": null,
                "asof_date": null,
                "date_from": null,
                "date_until": null,
                "from_cache": "false"
            },
            "token_string": "73fec4cee7704775b6557e11c47f577a"
        },
        data: null,
        loaded: false,
        loading: false,
    },
    "OSRContrainedForecastYear": {
        uri: "rms/properties/forecast/{pid}/?asOfDate={asOfDate}&fromDate={date_from}&toDate={date_until}",
        // uri: "api.v1/get_hotel_forecast/",
        payload: {
            "method": "GET",
            "params": {
                "pid": null,
                "asof_date": null,
                "date_from": null,
                "date_until": null,
                "from_cache": "false"
            },
            "token_string": null
        },
        data: null,
        loaded: false,
        loading: false,
    },
    "OSRContrainedForecastSDLY": {
        uri: "rms/properties/forecast/{pid}/?asOfDate={asOfDate}&fromDate={date_from}&toDate={date_until}",
        // uri: "api.v1/get_hotel_forecast?pid={pid}",
        payload: {
            "method": "GET",
            "params": {
                "pid": null,
                "asof_date": null,
                "date_from": null,
                "date_until": null,
                "from_cache": "false"
            },
            "token_string": null
        },
        data: null,
        loaded: false,
        loading: false,
    },
    // data_sources: ["OSRBookingPace", "OSRBookingPaceDBA", "OSRBookingPaceSegment", "OSRBookingPaceSegmentDBA"],

    "OSRBookingPace": {
        uri: "rms/properties/booking-curve/{pid}/?scope=DATE&fromStayDate={date_from}&toStayDate={date_until}&asOfDate={asof_date}&compFromStayDate={comp_date_from}&compToStayDate={comp_date_until}&compAsOfDate={comp_as_of_date}",
        payload: {
            "method": "GET",
        },
        data: null,
        loaded: false,
        loading: false,
    },
    "OSRBookingPaceDBA": {
        uri: "rms/properties/booking-curve/{pid}/?scope=DBA&dbaFilter={dbaFilter}&stayDate={date_from}&compStayDate={comp_date_from}",
        payload: {
            "method": "GET"
        },
        data: null,
        loaded: false,
        loading: false,
    },
    "OSRBookingPaceSegment": {
        uri: "rms/properties/booking-curve/{pid}/?segment=True&scope=DATE&fromStayDate={date_from}&toStayDate={date_until}&asOfDate={asof_date}&compFromStayDate={comp_date_from}&compToStayDate={comp_date_until}&compAsOfDate={comp_as_of_date}",
        payload: {
            "method": "GET",
        },
        data: null,
        loaded: false,
        loading: false,
    },
    "OSRBookingPaceSegmentDBA": {
        uri: "rms/properties/booking-curve/{pid}/?segment=True&scope=DBA&dbaFilter={dbaFilter}&stayDate={date_from}&compStayDate={comp_date_from}",
        payload: {
            "method": "GET"
        },
        data: null,
        loaded: false,
        loading: false,
    },
    "SendRatePush": {
        uri: "rms/properties/rate-push/",
        method: "post",
        payload: {

            "asof_date": "",
            "segment_type": "market_code",
            "rate_code": "TBAR",
            "rate_updates": []
        },
        data: null,
        loading: false,
        loaded: false,
    },
    "SendRatePushRoomType": {
        uri: "rms/properties/rate-push/",
        method: "post",
        payload: {

            "asof_date": "",
            "rate_updates": []
        },
        data: null,
        loading: false,
        loaded: false,
    },
    // "OSRBookingPaceSdly": {
    //     uri: "rms/properties/booking-curve/{pid}/?scope=DATE&fromStayDate={date_from}&toStayDate={date_until}&asOfDate={asof_date}",
    //     // uri: "rms/properties/booking-curve/",
    //     payload: {
    //         "method": "GET",
    //         "resourceId": null,
    //         "resourceScope": "property",
    //         "token": null,
    //         "rangeFromDate": null,
    //         "rangeUntilDate": null,
    //         "queryType": "ARICurve",
    //         "querySubtype": "",
    //         "segmentationType": "market_code",
    //         "segmentFilter": "1"
    //     },
    //     data: null,
    //     loaded: false,
    //     loading: false,
    // },
    // "OSRBookingPaceCompareTo": {
    //     uri: "rms/get_historical_curve/",
    //     payload: {
    //         "method": "GET",
    //         "resourceId": null,
    //         "resourceScope": "property",
    //         "token": null,
    //         "rangeFromDate": null,
    //         "rangeUntilDate": null,
    //         "queryType": "ARICurve",
    //         "querySubtype": "",
    //         "segmentationType": "market_code",
    //         "segmentFilter": "1"
    //     },
    //     data: null,
    //     loaded: false,
    //     loading: false,
    // },



    // "OSRBookingPaceCompareToSegment": {
    //     uri: "rms/booking-curve/",
    //     payload: {
    //         "method": "GET",
    //         "resourceId": null,
    //         "resourceScope": "property",
    //         "token": null,
    //         "rangeFromDate": null,
    //         "rangeUntilDate": null,
    //         "queryType": "ARICurve",
    //         "querySubtype": "",
    //         "segmentationType": "market_code",
    //         "segmentFilter": "all"
    //     },
    //     data: null,
    //     loaded: false,
    //     loading: false,
    // },
    // /////////////  Booking Curve Segment View //////////

    // "OSRBookingPaceSegment": {
    //     uri: "rms/properties/booking-curve/{pid}/?segmented={segmented_view}&scope={scopeType}&fromStayDate={date_from}&toStayDate={date_until}&asOfDate={asof_date}&compStayDate={comp_date_from}",
    //     //uri: "rms/get_historical_curve/",
    //     payload: {
    //         "method": "GET",
    //         "resourceId": null,
    //         "resourceScope": "property",
    //         "token": null,
    //         "rangeFromDate": null,
    //         "rangeUntilDate": null,
    //         "queryType": "ARICurve",
    //         "querySubtype": "",
    //         "segmentationType": "market_code",
    //         "segmentFilter": "all"
    //     },
    //     data: null,
    //     loaded: false,
    //     loading: false,
    // },

    // "OSRBookingPaceSdlySegment": {
    //     uri: "rms/get_historical_curve/",
    //     payload: {
    //         "method": "GET",
    //         "resourceId": null,
    //         "resourceScope": "property",
    //         "token": null,
    //         "rangeFromDate": null,
    //         "rangeUntilDate": null,
    //         "queryType": "ARICurve",
    //         "querySubtype": "",
    //         "segmentationType": "market_code",
    //         "segmentFilter": "all"
    //     },
    //     data: null,
    //     loaded: false,
    //     loading: false,
    // },
    /////////// End Booking Curve Segment view //////////////

    "CompsetBookingPace": {
        uri: "rms/properties/compset-curve/{pid}/?stayDate={date_from}&dbaFilter={dbaFilter}",
        // uri: "rms/properties/compset-curve/{pid}/?stay_date={fromDate}&dbaFilter=10",
        // uri: "compset/get_compset_historical_curve/",
        payload: {
            "method": "GET",
            "resourceId": null,
            "compId": null,
            "resourceScope": "ratecompset",
            "source": "ota_i_api",
            "token": null,
            "rangeFromDate": null,
            "rangeUntilDate": null
        },
        data: null,
        loaded: false,
        loading: false,

    },
    // "Compset":{
    //     uri:"compset/get_compset_historical_curve/",
    //     payload:{
    //         "resourceId":null, 
    //         "resourceScope":"ratecompset", 
    //         "source":"ota_i_api", 
    //         "token":null,
    //         "rangeFromDate":null,
    //         "rangeUntilDate":null
    //     },
    //     data:null,
    //     loaded:false,
    //     loading:false,

    // }, 
    "Compset": {
        uri: "rms/properties/compset/{pid}/?removeCompId=true&asOfDate={asOfDate}&fromDate={date_from}&toDate={date_until}&time={timestamp}",
        // uri: "compset/get_rateshop_compset/",
        // dependency: ["CompsetBookingPace"],
        payload: {
            "method": "GET",
            "resourceId": null,
            "compId": null,
            "resourceScope": "ratecompset",
            "source": "ota_i_api",
            "token": null,
            "rangeFromDate": null,
            "rangeUntilDate": null,
            "asOfDate": null
        },
        data: null,
        loaded: false,
        loading: false,

    },
    "Event": {
        uri: "api.v1/get_events_impact/",
        payload: {
            "method": "GET",
            params: {
                "mid": null,
                "date_from": null,
                "date_to": null,
                "source": "CUSTOM_v2"
            },
            token_string: null
        }
    },
    "MarketSummary": {
        uri: "rms/markets/summary/{mid}/?asOfDate={asOfDate}",
        payload: {
            "method": "GET",
        },
        data: null,
        loaded: false,
        loading: false,
    },
    "HotelSummary": {
        // uri: "rms/properties/summary/{pid}/?asOfDate={asOfDate}&fromDate={date_from}&toDate={date_until}",
        uri: "rms/properties/summary/{pid}/?asOfDate={asOfDate}",
        payload: {
            "method": "GET",
        },
        data: null,
        loaded: false,
        loading: false,
    },
    "HotelSummary_v3": {
        uri: "export/forecast_excel_export/",
        payload: {
            "method": "GET",
            "property_id": null,
            "as_of_date": null,
            "from_date": null,
            "to_date": null,
            "data_aggregation": "month",
            "mfc_algo": "MFC_v3",
            "data_format": "json_mockapi"
        }
    },

    "MarketSellRate": {
        uri: "rms/markets/ota-sellrates/{mid}/?asOfDate={asOfDate}&fromDate={date_from}&toDate={date_until}&time={timestamp}",
        payload: {
            "method": "GET",
        },
        data: null,
        loaded: false,
        loading: false,
    },

    "RevparIndex": {
        uri: "reports/revpar-index-tracker/{pid}",
        payload: {
            "method": "GET",
        },
        data: null,
        loaded: false,
        loading: false,
    },

    "GetFlights": {
        uri: "flights/get-flight-data/{pid}/",
        payload: {
            "method": "GET",
        },
        data: null,
        loaded: false,
        loading: false,
    },

    "BiSensitivity": {
        uri: "reports/bi-sensitivity-analysis/{pid}/?fromDate={date_from}&toDate={date_until}&asOfDate={asof_date}&dowFilter={dowFilter}&compDate={compDate}",
        payload: {
            "method": "GET",
        },
        data: null,
        loaded: false,
        loading: false,
    },
    "GetRoomType": {
        uri: "rms/properties/otb-rt/{pid}/?sdYear={sdYear}&includeSdly=true&fromDate={date_from}&toDate={date_until}&asOfDate={asof_date}",
        payload: {
            "method": "GET",
        },
        data: null,
        loaded: false,
        loading: false,
    },
    "GetRoomClass": {
        uri: "rms/properties/otb-rt/{pid}/?sdYear={sdYear}&includeSdly=true&includeStandard=true&includeRoomClass=true&fromDate={date_from}&toDate={date_until}&asOfDate={asof_date}",
        payload: {
            "method": "GET",
        },
        data: null,
        loaded: false,
        loading: false,
    },
    "GetRatePlan": {
        uri: "rms/properties/otb-rp/{pid}/?useCache=true&sdYear={sdYear}&includeSdly=true&fromDate={date_from}&toDate={date_until}&asOfDate={asof_date}&fillMissingDays=true",
        payload: {
            "method": "GET",
        },
        data: null,
        loaded: false,
        loading: false,
    },
    "GetRatePlanNoMissingDays": {
        uri: "rms/properties/otb-rp/{pid}/?sdYear={sdYear}&includeSdly=true&fromDate={date_from}&toDate={date_until}&asOfDate={asof_date}",
        payload: {
            "method": "GET",
        },
        data: null,
        loaded: false,
        loading: false,
    },


    "RoomTypePushStatus": {
        uri: "rms/properties/rate-push/{pid}/?stayDate={date_from}&time={timestamp}",
        payload: {
            "method": "GET",
        },
        data: null,
        loaded: false,
        loading: false,
    },
    "RoomTypePushStatusDateRange": {
        uri: "rms/properties/rate-push/{pid}/?asOfDate={asof_date}&fromDate={date_from}&toDate={date_until}&time={timestamp}",
        payload: {
            "method": "GET",
        },
        data: null,
        loaded: false,
        loading: false,
    },




    "GetRateCodeMapping": {
        uri: "rms/properties/mapping/{pid}/{dsid}/?typeOfMapping=rate_code__market_segment",
        payload: {
            "method": "GET",
        },
        data: null,
        loaded: false,
        loading: false,
    },

    "GetAlerts": {
        uri: "alerts/alerts/?asOfDate={asof_date}&propertyIds={pid}",
        payload: {
            "method": "GET",
        },
        data: null,
        loaded: false,
        loading: false,
    },

    "GetBudget": {
        uri: "rms/properties/budget/{pid}/?fromDate={date_from}&toDate={date_until}",
        payload: {
            "method": "GET",
        },
        data: null,
        loaded: false,
        loading: false,
    },


    "BudgetYear": {
        uri: "upload/get_budget_data/",
        payload: {
            "method": "GET",
            "property_id": null,
            "asOfDate": null,
            "rangeFromDate": null,
            "rangeUntilDate": null,
        },
        data: null,
        loaded: false,
        loading: false,
    },

}


const _md = {
    standard_view_types: ['table', 'chart'],
    standard_property_fields: [{ label: "Rooms", value: "Rooms" },
    { label: "OCC %", value: "Occ" },
    { label: "RevPAR", value: "RevPAR" },
    { label: "Revenue", value: "Revenue" },
    { label: "ADR", value: "ADR" },
    { label: "Sell Rate", value: "SellRate" }],

    standard_market_fields: [{ label: "Rooms", value: "Rooms" },
    { label: "OCC %", value: "Occ" },
    { label: "RevPAR", value: "RevPAR" },
    { label: "Revenue", value: "Revenue" },
    { label: "ADR", value: "ADR" }],

    standard_compset_fields: [{ label: "Sell Rate", value: "SellRate" }]
}

const TILES = {

    ////////////// Reports Section Starts //////////////////////
    /** Pickup by Segment  */
    bi_pickup_analysis: {
        id: "bi_pickup_analysis", class_name: "tile otb-tile",
        label: "Pickup By Segment", title: "Pickup By Segment",
        views: ['table'],
        type: "report",
        tableConfig: TABLE_CONFIG['bi_pickup_analysis'],
        chartConfig: {
            identifiers: ['primary'],
            sdlyIdentifiers: ['primary', 'sdly', "sdly_actual"],
            mainkey: "HotelTotal",
            controlSupport: { sdlyOn: true, segmented_view: true },
            maps: {
                legends: {
                    //Legend keys and values as text
                    //Formula: tileID + 
                }
            },
            chartOptions: {}
        },
        labelSize: 12,
        data_sources: ["OSRSegment", "MarketARISegmented"],
        measures_list: _md.standard_property_fields,
        order: 11,
        usedSpace: 6,
        mergeable: false,
        mergeability: [], //IDs of tiles for which it can be merged and show data properly
        disabled: false,
        //Styles
        iconClass: 'icon icon-icon-onthebooks',
        iconSize: 45,
        p: 1,
        pt: 0,
        pb: 1,
        ml: 0,
        mb: 0
    },
    /**  end Pickup by segment  */

    bi_pace_comparison: {
        id: "bi_pace_comparison", class_name: "tile otb-tile",
        label: "Daily Pace By Segment", title: "Pace By Segment",
        views: ['table'],
        type: "report",
        controlSupport: { scopeType: false },
        tableConfig: TABLE_CONFIG['bi_pace_comparison'],
        chartConfig: {
            identifiers: ['primary'],
            sdlyIdentifiers: ['primary', 'sdly', "sdly_actual"],
            mainkey: "HotelTotal",
            controlSupport: { sdlyOn: true, segmented_view: true },
            maps: {
                legends: {
                    //Legend keys and values as text
                    //Formula: tileID + 
                }
            },
            chartOptions: {}
        },
        labelSize: 12,
        data_sources: ["OSR", "OSRSegment", "MarketARISegmented"],
        measures_list: _md.standard_property_fields,
        order: 11,
        usedSpace: 6,
        mergeable: false,
        mergeability: [], //IDs of tiles for which it can be merged and show data properly
        disabled: false,
        //Styles
        iconClass: 'icon icon-icon-onthebooks',
        iconSize: 45,
        p: 1,
        pt: 0,
        pb: 1,
        ml: 0,
        mb: 0
    },
    /** Market Code Report  */
    productionMarketReport: {
        id: "productionMarketReport", class_name: "tile otb-tile",
        label: "Market Code Report", title: "Market Code Report",
        views: ['table'],
        type: "report",
        tableConfig: TABLE_CONFIG['productionMarketReport'],
        chartConfig: {
            identifiers: ['primary'],
            sdlyIdentifiers: ['primary', 'sdly', "sdly_actual"],
            mainkey: "HotelTotal",
            controlSupport: { sdlyOn: true, segmented_view: true },
            maps: {
                legends: {
                    //Legend keys and values as text
                    //Formula: tileID + 
                }
            },
            chartOptions: {}
        },
        labelSize: 12,
        data_sources: ["OSR", "OSRSegment", "MarketARISegmented", "GetRateCodeMapping"],
        measures_list: _md.standard_property_fields,
        order: 11,
        usedSpace: 6,
        mergeable: false,
        mergeability: [], //IDs of tiles for which it can be merged and show data properly
        disabled: false,
        //Styles
        iconClass: 'icon icon-icon-onthebooks',
        iconSize: 45,
        p: 1,
        pt: 0,
        pb: 1,
        ml: 0,
        mb: 0
    },

    /** Segment Production Report  */
    productionReport: {
        id: "productionReport", class_name: "tile otb-tile",
        label: "Segment Production", title: "Segment Production",
        views: ['table'],
        type: "report",
        tableConfig: TABLE_CONFIG['productionReport'],
        chartConfig: {
            identifiers: ['primary'],
            sdlyIdentifiers: ['primary', 'sdly', "sdly_actual"],
            mainkey: "HotelTotal",
            controlSupport: { sdlyOn: true, segmented_view: true },
            maps: {
                legends: {
                    //Legend keys and values as text
                    //Formula: tileID + 
                }
            },
            chartOptions: {}
        },
        labelSize: 12,
        data_sources: ["OSRSegment"],
        measures_list: _md.standard_property_fields,
        order: 11,
        usedSpace: 6,
        mergeable: false,
        mergeability: [], //IDs of tiles for which it can be merged and show data properly
        disabled: false,
        //Styles
        iconClass: 'icon icon-icon-onthebooks',
        iconSize: 45,
        p: 1,
        pt: 0,
        pb: 1,
        ml: 0,
        mb: 0
    },

    /** RevPar Index Report  */
    revpar_index: {
        id: "revpar_index", class_name: "tile otb-tile",
        label: "RevPAR Index", title: "RevPAR Performance",
        views: ['table'],
        type: "report",
        tableConfig: TABLE_CONFIG['revpar_index'],
        chartConfig: {
            identifiers: ['primary'],
            sdlyIdentifiers: ['primary', 'sdly', "sdly_actual"],
            mainkey: "HotelTotal",
            controlSupport: { sdlyOn: true, segmented_view: true },
            maps: {
                legends: {
                    //Legend keys and values as text
                    //Formula: tileID + 
                }
            },
            chartOptions: {}
        },
        labelSize: 12,
        data_sources: ["RevparIndex"],
        // data_sources: [],
        measures_list: _md.standard_property_fields,
        order: 11,
        usedSpace: 6,
        mergeable: false,
        mergeability: [], //IDs of tiles for which it can be merged and show data properly
        disabled: false,
        //Styles
        iconClass: 'icon icon-icon-onthebooks',
        iconSize: 45,
        p: 1,
        pt: 0,
        pb: 1,
        ml: 0,
        mb: 0
    },

    /** Bi Sensitivity Report */
    bi_sensitivity_analysis: {
        id: "bi_sensitivity_analysis", class_name: "tile bi-sensitivity-analysis",
        label: "Report : BI Sensitivity Analysis", title: "SENSITIVITY ANALYSIS",
        type: "report",
        views: ['table', 'chart'],
        tableConfig: TABLE_CONFIG['bi_sensitivity_analysis'],
        chartConfig: {
            //Data setup options
            identifiers: ['primary'],
            sdlyIdentifiers: ['primary'],
            compareToIdentifiers: [],
            mainkey: "HotelTotal",
            // sdlyKey: 'paceSdly',
            // compareToKey: 'paceCompareTo',
            // xAxisKey: "index",
            xAxisType: "Dba",
            controlSupport: { scopeType: true, segmented_view: false },
            //Chart options settings for this tile
            chartOptions: {
                xAxis: {
                    type: 'areaspline',
                    fillColor: {
                        linearGradient: {
                            x1: 0,
                            y1: 0,
                            x2: 1,
                            y2: 1
                        },
                        stops: [
                            [0, '#3176B0'],
                            [1, window.Highcharts.Color('#3176B0').setOpacity(0).get('rgba')]
                        ]
                    }
                },
                yAxis: {}
            }
        },
        measures_list: _md.standard_property_fields,
        labelSize: 12,
        // data_sources: ["OSR","OSRYear", "OSRSegment", "GetRestrictions", "GetRestrictionsRoomType"],
        data_sources: ["BiSensitivity"],
        order: 11,
        usedSpace: 6,
        mergeable: false,
        mergeability: [], //IDs of tiles for which it can be merged and show data properly
        disabled: false,
        //Styles
        iconClass: 'icon icon-icon-onthebooks',
        iconSize: 45,
        p: 1,
        pt: 0,
        pb: 1,
        ml: 0,
        mb: 0
    },

    /** OTB MultiSegment  */
    otb_v2_multi_segment: {
        id: "otb_v2_multi_segment", class_name: "tile otb_v2_multi_segment",
        label: "Report : ON THE BOOKS MULTI SEGMENT", title: "ON THE BOOKS MULTI LEVEL",
        type: "report",
        views: ['table', "chart"],
        tableConfig: TABLE_CONFIG['otb_v2_multi_segment'],
        chartConfig: {
            identifiers: ['primary'],
            sdlyIdentifiers: ['primary', 'sdly', "sdly_actual"],
            mainkey: "HotelTotal",
            controlSupport: { sdlyOn: true, segmented_view: true },
            maps: {
                legends: {
                    //Legend keys and values as text
                    //Formula: tileID + 
                }
            },
            chartOptions: {}
        },
        labelSize: 12,
        data_sources: ["OSRSegment", "GetRoomType", "GetRatePlan", "GetRoomClass", "GetRateCodeMapping", "GetPropData", "GetRatePlanNoMissingDays"],
        measures_list: _md.standard_property_fields,
        order: 11,
        usedSpace: 6,
        mergeable: true,
        mergeability: [], //IDs of tiles for which it can be merged and show data properly
        disabled: false,
        //Styles
        iconClass: 'icon icon-icon-onthebooks',
        iconSize: 45,
        p: 1,
        pt: 0,
        pb: 1,
        ml: 0,
        mb: 0
    },

    /// Logs Reports ///

    /** Room Rate Push status Report  */
    rm_room_type_ratesend_status: {
        id: "rm_room_type_ratesend_status", class_name: "tile otb-tile",
        label: "Daily Rate Push ", title: "Room Rate Push Status Report",
        views: ['table'],
        type: "report",
        controlSupport: { scopeType: false },
        tableConfig: TABLE_CONFIG['rm_room_type_ratesend_status'],
        chartConfig: {
            identifiers: ['primary'],
            sdlyIdentifiers: ['primary', 'sdly', "sdly_actual"],
            mainkey: "HotelTotal",
            controlSupport: { sdlyOn: true, segmented_view: true },
            maps: {
                legends: {
                    //Legend keys and values as text
                    //Formula: tileID + 
                }
            },
            chartOptions: {}
        },
        labelSize: 12,
        data_sources: ["RoomTypePushStatusDateRange", "RoomTypePushStatus"],
        measures_list: _md.standard_property_fields,
        order: 11,
        usedSpace: 6,
        mergeable: false,
        mergeability: [], //IDs of tiles for which it can be merged and show data properly
        disabled: false,
        //Styles
        iconClass: 'icon icon-icon-onthebooks',
        iconSize: 45,
        p: 1,
        pt: 0,
        pb: 1,
        ml: 0,
        mb: 0
    },

    //   daily rate push
    DailyRatesendStatus: {
        id: "dailyRatesendStatus", class_name: "tile otb-tile",
        label: "Daily Rate Push ", title: "Daily Rate Push Report",
        views: ['table'],
        type: "report",
        controlSupport: { scopeType: false },
        tableConfig: TABLE_CONFIG['dailyRatesendStatus'],
        chartConfig: {
            identifiers: ['primary'],
            sdlyIdentifiers: ['primary', 'sdly', "sdly_actual"],
            mainkey: "HotelTotal",
            controlSupport: { sdlyOn: true, segmented_view: true },
            maps: {
                legends: {
                    //Legend keys and values as text
                    //Formula: tileID + 
                }
            },
            chartOptions: {}
        },
        labelSize: 12,
        data_sources: ["RoomTypePushStatusDateRange", "RoomTypePushStatus"],
        measures_list: _md.standard_property_fields,
        order: 11,
        usedSpace: 6,
        mergeable: false,
        mergeability: [], //IDs of tiles for which it can be merged and show data properly
        disabled: false,
        //Styles
        iconClass: 'icon icon-icon-onthebooks',
        iconSize: 45,
        p: 1,
        pt: 0,
        pb: 1,
        ml: 0,
        mb: 0
    },

    ////////////////// End Reports Section //////////




    pricing: {
        id: "pricing", class_name: "tile pricing",
        label: "Pricing",
        measures_list: [{ label: 'Sell Rate', value: 'SellRate' }],
        tableConfig: TABLE_CONFIG['pricing'],
        controlSupport: { scopeType: false },
        chartConfig: {
            identifiers: ['Recomended', "Today", 'Restrictions'],
            defaultMeasures: [null, 'SellRate'],
            sdlyIdentifiers: [],
            mainkey: 'sets'
        },
        data_sources: ['OSR', 'OSRRateRecommend', 'OSRContrainedForecast', 'Compset', 'GetRestrictions'],
        labelSize: 12,
        order: 1,
        mergeable: true,
        mergeability: [], //IDs of tiles for which it can be merged and show data properly
        disabled: false,
        //Styles
        iconClass: 'icon icon-icon-pricing-1',
        iconSize: 50,
        defaultView: "table",
        views: ['table', 'chart'],
        workspaceControls: ["sendRatesBtn", "toggleRoomTypePricing", "pricing_calendar_btn"],
        tableConfig: TABLE_CONFIG['pricing'],

        p: 1,
        pt: 1,
        pb: 1,
        ml: 1,
        mb: 1
    },
    restrictions: {
        id: "restrictions", class_name: "tile restrictions",
        label: "Restrictions",
        controlSupport: { roomTypeRestrictions: true },
        views: ['table'],
        measures_list: [],
        chartConfig: {
            identifiers: [],
            sdlyIdentifiers: [],
        },
        tableConfig: TABLE_CONFIG['restrictions'],
        data_sources: ['GetRestrictions', 'GetRestrictionsRoomType', 'OSRRateRecommend'],
        labelSize: 12,
        order: 1,
        mergeable: true, //may be enabled later
        mergeability: [], //IDs of tiles for which it can be merged and show data properly
        disabled: false,
        //Styles
        iconClass: 'icon icon-icon-restrictions',
        iconSize: 50,
        p: 1,
        pt: 1,
        pb: 1,
        ml: 1,
        mb: 1
    },
    room_pricing: {
        id: "room_pricing", class_name: "tile room-pricing",
        label: "Room Pricing",
        views: [],
        chartConfig: {
            identifiers: [],
            sdlyIdentifiers: [],
        },
        measures_list: [],
        data_sources: [],
        labelSize: 12,
        order: 2,
        mergeability: [], //IDs of tiles for which it can be merged and show data properly
        disabled: true,
        //Styles
        iconClass: 'icon icon-icon-pricing',
        iconSize: 50,
        p: 1,
        pb: 1,
        ml: 1,
        mb: 1
    },
    group: {
        id: "group", class_name: "tile group",
        label: "Group Pricing",
        measures_list: [],
        chartConfig: {
            identifiers: [],
            sdlyIdentifiers: [],
        },
        data_sources: ["OSRYear"],
        data_popup_api: { 'OnSubmit': 'GetGroupPricingQuote' },
        labelSize: 12,
        order: 3,
        mergeability: [], //IDs of tiles for which it can be merged and show data properly
        disabled: false,
        view: "popup",
        //Styles
        iconClass: 'icon icon-icon-groups',
        iconSize: 55,
        p: 1,
        pt: 1,
        pb: 1,
        ml: 1,
        mb: 1
    },


    save_quote: {
        id: "group", class_name: "tile group",
        label: "Group Pricing",
        measures_list: [],
        chartConfig: {
            identifiers: [],
            sdlyIdentifiers: [],
        },
        data_sources: [],
        data_popup_api: { 'OnSubmit': 'SaveGroupPricingQuote' },
        labelSize: 12,
        order: 3,
        mergeability: [], //IDs of tiles for which it can be merged and show data properly
        disabled: false,
        view: "popup",
        //Styles
        iconClass: 'icon icon-icon-groups',
        iconSize: 55,
        p: 1,
        pt: 1,
        pb: 1,
        ml: 1,
        mb: 1
    },

    export_reports: {
        id: "report", class_name: "tile group",
        label: "Export Reports",
        measures_list: [],
        chartConfig: {
            identifiers: [],
            sdlyIdentifiers: [],
        },
        data_sources: [],
        data_popup_api: { 'OnSubmit': 'ExportReport' },
        labelSize: 12,
        order: 3,
        mergeability: [], //IDs of tiles for which it can be merged and show data properly
        disabled: false,
        view: "popup",
        //Styles
        iconClass: 'icon icon-icon-groups',
        iconSize: 55,
        p: 1,
        pt: 1,
        pb: 1,
        ml: 1,
        mb: 1
    },


    price_calendar: {
        id: "price_calendar", class_name: "tile price-calendar",
        label: "Price Calendar",
        views: [],
        chartConfig: {
            identifiers: [],
            sdlyIdentifiers: [],
        },
        measures_list: [],
        data_sources: ['OSRRateRecommend'],
        labelSize: 11.5,
        order: 4,
        mergeability: [], //IDs of tiles for which it can be merged and show data properly
        disabled: false,
        view: 'popup', //This indicates that when clicked data will be shown in popup mode
        //Styles
        iconClass: 'icon icon-table-view-icon',
        iconSize: 45,
        p: 1,
        pt: 1,
        pb: 1,
        ml: 1,
        mb: 1
    },
    budget: {
        id: "budget", class_name: "tile budget",
        label: "Budget",
        measures_list: _md.standard_market_fields,
        views: _md.standard_view_types,
        defaultView: 'table',
        controlSupport: { segmented_view: true },
        chartConfig: {
            identifiers: [],
            sdlyIdentifiers: [],
        },
        tableConfig: TABLE_CONFIG['budget'],
        data_sources: ['GetBudget'],
        labelSize: 12,
        order: 5,
        mergeable: true,
        mergeability: [], //IDs of tiles for which it can be merged and show data properly
        disabled: false,
        //Styles
        iconClass: 'icon icon-icon-budget',
        iconSize: 55,
        p: 1,
        pt: 1,
        pb: 1,
        ml: 1,
        mb: 1
    },
    forecast: {
        id: "forecast", class_name: "tile forecast",
        label: "Forecast",
        tableConfig: TABLE_CONFIG['forecast'],
        views: _md.standard_view_types,
        measures_list: _md.standard_market_fields,
        controlSupport: { sdlyOn: false, segmented_view: true },
        chartConfig: {
            identifiers: ['primary'],
            sdlyIdentifiers: [],
            mainkey: 'HotelTotal',
            controlSupport: { sdlyOn: false, segmented_view: true }
        },
        measures_list: _md.standard_market_fields,
        data_sources: ['OSR', 'OSRContrainedForecast', "OSRSegment", "ManualForecast"],
        labelSize: 12,
        order: 6,
        mergeable: true,
        mergeability: [], //IDs of tiles for which it can be merged and show data properly
        disabled: false,
        //Styles
        iconClass: 'icon icon-icon-forecast',
        iconSize: 55,
        p: 1,
        pt: 1,
        pb: 1,
        ml: 1,
        mb: 1
    },
    daily_flash: {
        id: "daily_flash", class_name: "tile daily-flash",
        label: "Daily Flash",
        views: _md.standard_view_types,
        chartConfig: {
            identifiers: [],
            sdlyIdentifiers: [],
            xAxisKey: 'monthData',
            mainkey: 'aggregates'
        },
        measures_list: [],
        data_sources: ['OSRYear', 'OSRContrainedForecastYear', 'BudgetYear'],
        labelSize: 12,
        order: 7,
        usedSpace: 1,
        mergeability: [], //IDs of tiles for which it can be merged and show data properly
        disabled: false,
        view: 'popup',
        //Styles
        iconClass: 'icon icon-flash-report',
        iconSize: 50,
        p: 1,
        pt: 1,
        pb: 1,
        ml: 1,
        mb: 1
    },
    market_sell_rates: {
        id: "market_sell_rates", class_name: "tile market-sell-rates",
        label: "Market Sell Rates", title: "Market Sell Rate",
        tableConfig: TABLE_CONFIG['market_sell_rates'],
        views: _md.standard_view_types,
        controlSupport: { marketPicker: true, gridView: true, chartView: true },
        chartConfig: {
            identifiers: ['3_star', "4_star", "5_star"],
            defaultMeasures: [null, 'SellRate'],
            sdlyIdentifiers: [],
            mainkey: 'sets'
        },
        measures_list: [{
            "label": "Sell Rate",
            "value": "SellRate"
        }],
        data_sources: ["MarketSellRate"],
        labelSize: 12,
        order: 8,
        mergeable: true,
        usedSpace: 1,
        mergeability: [], //IDs of tiles for which it can be merged and show data properly
        disabled: false,
        //Styles
        iconClass: 'icon icon-market-sell-rates',
        iconSize: 45,
        p: 1,
        pt: 1,
        pb: 1,
        ml: 1,
        mb: 1
    },
    hotel_summary: {
        id: "hotel_summary", class_name: "tile hotel-summary",
        tableConfig: TABLE_CONFIG['hotel_summary'],
        views: ['table'],
        controlSupport: {},
        type: 'summary_report',
        chartConfig: {
            identifiers: [],
            sdlyIdentifiers: [],
        },
        measures_list: _md.standard_property_fields,
        label: "Summary Reports", title: "Hotel Monthly Summary",
        data_sources: ['HotelSummary'],
        labelSize: 12,
        order: 9,
        usedSpace: 1,
        mergeable: true,
        mergeability: [], //IDs of tiles for which it can be merged and show data properly
        disabled: false,
        //Styles
        iconClass: 'icon icon-hotel-summary',
        iconSize: 45,
        p: 1,
        pt: 1,
        pb: 0,
        ml: 1,
        mb: 0
    },
    market_summary: {
        id: "market_summary", class_name: "tile market-summary",
        tableConfig: TABLE_CONFIG['market_summary'],
        views: ['table'],
        type: 'summary_report',
        label: "Market Summary", title: "Market Monthly Summary",
        chartConfig: {
            identifiers: ['primary'],
            sdlyIdentifiers: ['primary', 'sdly', "sdly_actual"],
        },
        measures_list: _md.standard_property_fields,
        data_sources: ['MarketSummary'],
        labelSize: 12,
        order: 10,
        usedSpace: 1,
        mergeability: [], //IDs of tiles for which it can be merged and show data properly
        disabled: false,
        //Styles
        iconClass: 'icon icon-market-analysis',
        iconSize: 45,
        p: 1,
        pt: 1,
        pb: 0,
        ml: 1,
        mb: 0
    },
    otb: {
        id: "otb", class_name: "tile otb-tile",
        label: "OTB", title: "On The Books",
        views: _md.standard_view_types,
        // defaultView: 'chart',
        tableConfig: TABLE_CONFIG['otb'],
        controlSupport: { sdlyOn: true, segmented_view: true, gridView: true, chartView: true },
        chartConfig: {
            defaultMeasures: ['Occ', 'ADR'],
            identifiers: ['primary'],
            sdlyIdentifiers: ['primary', 'sdly', "sdly_actual"],
            mainkey: "HotelTotal",
            controlSupport: { sdlyOn: true, segmented_view: true },
            maps: {
                legends: {
                    //Legend keys and values as text
                    //Formula: tileID + 
                }
            },
            chartOptions: {}
        },
        labelSize: 12,
        // data_sources: ["OSR","OSRYear", "OSRSegment", "GetRestrictions", "GetRestrictionsRoomType"],
        //Commented on 12 july 2023
        data_sources: ["OSR", "OSRSegment", "OSRYear", "GetRestrictions", "GetRestrictionsRoomType", "GetPropData", "GetRateCodeMapping", "GetRatePlan"],
        // data_sources: ["OSR", "GetRestrictions", "GetRestrictionsRoomType", "GetPropData", "GetRateCodeMapping", "GetRatePlan"],
        measures_list: _md.standard_property_fields,
        order: 11,
        usedSpace: 6,
        mergeable: true,
        mergeability: [], //IDs of tiles for which it can be merged and show data properly
        disabled: false,
        //Styles
        iconClass: 'icon icon-icon-onthebooks',
        iconSize: 45,
        p: 1,
        pt: 0,
        pb: 1,
        ml: 0,
        mb: 0
    },
    pickup: {
        id: "pickup", class_name: "tile pickup",
        label: "Pickup", title: "Pickup",
        tableConfig: TABLE_CONFIG["pickup"],
        views: _md.standard_view_types,
        controlSupport: { sdlyOn: true, segmented_view: true, gridView: true, chartView: true },
        chartConfig: {
            identifiers: ['pickup_diff'],
            // sdlyIdentifiers: ['primary', 'pickup', 'sdly', 'sdly_pickup', "sdly_actual"],
            sdlyIdentifiers: ['pickup_diff', 'sdly_pickup_diff'],
            mainkey: "HotelTotal",
            defaultMeasures: ['Occ', 'ADR'],
            controlSupport: { sdlyOn: true, segmented_view: true },
            maps: {
                legends: {
                    //Legend keys and values as text
                    //Formula: tileID + 
                }
            },
            chartOptions: {}
        },
        measures_list: _md.standard_property_fields,
        data_sources: ["OSR", "OSRSegment", "GetPropData"],
        labelSize: 12,
        order: 12,
        usedSpace: 1,
        mergeable: true,
        mergeability: [], //IDs of tiles for which it can be merged and show data properly
        disabled: false,
        //Styles
        iconClass: 'icon icon-icon-pickup',
        iconSize: 55,
        p: 1,
        pt: 1,
        pb: 1,
        ml: 1,
        mb: 1
    },
    pace: {
        id: "pace", class_name: "tile pace",
        label: "Booking Curve",
        tableConfig: TABLE_CONFIG["pace"],
        views: _md.standard_view_types,
        controlSupport: { scopeType: true, segmented_view: true, gridView: true, chartView: true },
        chartConfig: {
            //Data setup options
            identifiers: ['primary'],
            sdlyIdentifiers: ['primary'],
            compareToIdentifiers: ['primary', 'comparison'],
            mainkey: "HotelTotal",
            // sdlyKey: 'paceSdly',
            // compareToKey: 'paceCompareTo',
            // xAxisKey: "index",
            // xAxisType: "Dba",
            controlSupport: { scopeType: true, segmented_view: true },
            //Chart options settings for this tile
            chartOptions: {
                xAxis: {
                    type: 'areaspline',
                    fillColor: {
                        linearGradient: {
                            x1: 0,
                            y1: 0,
                            x2: 1,
                            y2: 1
                        },
                        stops: [
                            [0, '#3176B0'],
                            [1, window.Highcharts.Color('#3176B0').setOpacity(0).get('rgba')]
                        ]
                    }
                },
                yAxis: {}
            }
        },
        measures_list: _md.standard_property_fields,
        // data_sources: ["OSRBookingPace", "OSRBookingPaceSdly", "OSRBookingPaceCompareTo", "OSRBookingPaceSegment", "OSRBookingPaceSdlySegment", "OSRBookingPaceCompareToSegment"],
        data_sources: ["OSRBookingPace", "OSRBookingPaceDBA", "OSRBookingPaceSegment", "OSRBookingPaceSegmentDBA", "OSRContrainedForecast"],
        labelSize: 12,
        order: 13,
        usedSpace: 1,
        mergeable: true,
        mergeability: [], //IDs of tiles for which it can be merged and show data properly
        disabled: false,
        //Styles
        iconClass: 'icon icon-icon-booking-pace',
        iconSize: 55,
        p: 1,
        pt: 1,
        pb: 1,
        ml: 1,
        mb: 1
    },
    compset: {
        id: "compset", class_name: "tile compset",
        label: "Compset",
        tableConfig: TABLE_CONFIG['compset'],
        views: _md.standard_view_types,
        controlSupport: { scopeType: true, gridView: true, chartView: true },
        chartConfig: {
            //Data setup options
            measures_list: _md.standard_compset_fields,
            identifiers: [],
            sdlyIdentifiers: [],
            defaultMeasures: [null, 'SellRate'],
            xAxisKey: "index",
            controlSupport: { scopeType: true, sdlyOn: false },
            resource: {},
            //Chart options settings for this tile
            chartOptions: {
                "legend": {
                    "enabled": true,
                    "align": "right",
                    "layout": "vertical",
                    "verticalAlign": "top",
                    "y": 10,
                    "padding": 5,
                    "useHTML": true,
                    "shadow": false,
                    "itemStyle": {
                        "color": "#606060",
                        "font-size": ".7vw",
                        "padding-bottom": ".5vw"
                    }
                },
                // xAxis: {
                //     type: 'spline',
                // },
                //yAxis: {}
            }
        },
        measures_list: _md.standard_compset_fields,
        data_sources: ["Compset", "CompsetBookingPace"],
        labelSize: 12,
        order: 14,
        usedSpace: 1,
        mergeable: true,
        mergeability: [], //IDs of tiles for which it can be merged and show data properly
        disabled: false,
        //Styles
        iconClass: 'icon icon-icon-comp-set',
        iconSize: 55,
        p: 1,
        pt: 1,
        pb: 1,
        ml: 1,
        mb: 1
    },
    market: {
        id: "market", class_name: "tile market",
        // data_sources: ["MarketARI", "MarketDba", "MarketForecast", "MarketSdlyDba", "MarketARISegmented", "MarketForecastSegmented", "CensusInfo", "GetPropData", "MarketCapacity"],
        data_sources: ["MarketARI", "MarketDba", "MarketForecast", "MarketSdlyDba", "MarketARISegmented", "MarketForecastSegmented", "GetPropData", "MarketCapacity"],
        measures_list: _md.standard_market_fields.concat([{ label: "Sell Rate", value: "SellRate" }]),
        label: "Market",
        views: _md.standard_view_types,
        tableConfig: TABLE_CONFIG['market'],
        controlSupport: { segmented_view: true, sdlyOn: true, pickupDays: true, scopeType: true, MarketPicker: true, UnconstrainedPicker: true, gridView: true, chartView: true },
        chartConfig: {
            identifiers: ['primary', 'market_forecast'],
            sdlyIdentifiers: ['primary', 'sdly', 'sdly_actual', 'market_forecast'],
            dba_identifiers: ['primary'],
            sdly_dba_identifiers: ['primary', 'sdly'],
            mainkey: "MarketTotal",
            controlSupport: {
                scopeType: true,
                segmented_view: true,
            },
            chartOptions: {
                legend: {},
                xAxis: {
                    type: 'areaspline',
                    fillColor: {
                        linearGradient: {
                            x1: 0,
                            y1: 0,
                            x2: 1,
                            y2: 1
                        },
                        // stops : [
                        //     [0, '#3176B0'],
                        //     [1, window.Highcharts.Color('#3176B0').setOpacity(0).get('rgba')]
                        // ]
                    }
                },
                //yAxis: {}
            }
        },
        labelSize: 12,
        order: 15,
        usedSpace: 1,
        mergeable: true,
        mergeability: [], //IDs of tiles for which it can be merged and show data properly
        disabled: false,
        //Styles
        iconClass: 'icon icon-icon-marke',
        iconSize: 55,
        p: 1,
        pt: 1,
        pb: 1,
        ml: 1,
        mb: 1
    },
    events: {
        id: "events", class_name: "tile events",
        label: "Events",
        views: _md.standard_view_types,
        chartConfig: {
            identifiers: [],
            sdlyIdentifiers: [],
        },
        measures_list: [],
        labelSize: 12,
        data_sources: [],
        order: 16,
        usedSpace: 1,
        mergeability: [], //IDs of tiles for which it can be merged and show data properly
        disabled: false,
        //Styles
        iconClass: 'icon icon-icon-events',
        iconSize: 55,
        p: 1,
        pt: 1,
        pb: 1,
        ml: 1,
        mb: 1
    },
    flights: {
        id: "flights", class_name: "tile flights",
        views: ['table'],
        defaultView: 'table',
        chartConfig: {
            identifiers: [],
            sdlyIdentifiers: [],
        },
        tableConfig: TABLE_CONFIG['flights'],
        measures_list: [],
        label: "Flights",
        data_sources: ["GetFlights"],
        labelSize: 12,
        order: 17,
        usedSpace: 1,
        mergeable: true,
        mergeability: [], //IDs of tiles for which it can be merged and show data properly
        disabled: false,
        //Styles
        iconClass: 'icon icon-icon-flights-default',
        iconSize: 55,
        p: 1,
        pt: 1,
        pb: 1,
        ml: 1,
        mb: 1
    },
}

//Default Configuration for chart options
const CHART_OPTIONS = {
    dailyFlash: {
        "credits": {
            "enabled": false
        },
        "chart": {
            //"height": (9 / 16 * 100) + '%', // 16:9 ratio
            //"height": (6.3 / 16 * 100) + '%',
            "height": '280px',
            "type": 'column',
            "reflow": true,
            "backgroundColor": "transparent",
            "style": {
                "color": "#606060",
                "fontFamily": "Lato Regular"
            },
            "marginBottom": 80
        },
        "legend": {
            "enabled": true,
            "floating": false,
            "verticalAlign": "bottom",
            "align": "left",
            "fontFamily": "Lato Light",
            "fontSize": 15, "marginTop": "25px"
        },
        "xAxis": [
            {
                // "min": xAxisMin,
                // "max": Number(moment(date_until).format('x')),
                //"max": xAxisMax,
                "crosshair": {
                    "width": 1,
                    "color": "rgb(130,203,208)"
                },
                // "type": 'datetime',
                "type": 'category',
                "dateTimeLabelFormats": {
                    "day": "%b %e",
                    "week": "%Y<br/>%m-%d",
                    "month": "%b",
                    "year": "%Y",
                },
                // "labels": {
                //     "style": {
                //         "color": "#606060",
                //         "fontFamily": "Lato Regular"
                //     },
                //     "formatter": function () {
                //         let d = new Date(this.value);
                //         return window.Highcharts.dateFormat('%b', d);
                //     }
                // },
                "title": {
                    "style": {
                        "color": "#606060",
                        "fontFamily": "Lato Light"
                    }
                },
                "ordinal": false,
            }
        ],
        "yAxis": [], //This need to be manipulated with left and right y axis
        "rangeSelector": {
            "enabled": true,
            "height": 0,
            "inputEnabled": false,
            "buttonTheme": {
                "visibility": "hidden"
            },
            "labelStyle": {
                "visibility": "hidden"
            }
        },
        "title": {
            "text": "",
            "style": {
                "color": "#606060",
                "fontFamily": "Open Sans"
            }
        },
        "enableMouseTracking": true,
        "enableStickyTracking": false,
        "tooltip": {
            // "outside": true,
            "animation": true,
            "enabled": true,
            "shared": true,
            "useHTML": true,
            "shape": "callout",
            "backgroundColor": "rgba(255,255,255,1)",
            "borderColor": "#777",
            "borderRadius": 5, "padding": 0,
            "hideDelay": 500,
            "className": "chart-tooltip",
            "formatter": function () {
                //  debugger;
                let tooltipTitle = '';
                let timestamp = new Date(this.x);
                // tooltipTitle = window.Highcharts.dateFormat('%b %Y', timestamp);
                tooltipTitle = moment(timestamp).endOf('month').subtract(1, 'day').format('MMM, yyyy')
                //moment.unix(timestamp).format("ddd, MMM D, YYYY");
                let shared_points = this.points
                let dataPoint = shared_points[0].series.userOptions.data;
                tooltipTitle = dataPoint.filter((p) => p[0] === this.points[0].key);
                // debugger;

                let s = '<div class="tooltip-title" style="color:white">' + tooltipTitle[0][2] + '</div>';

                //first find all headers needed to put on tooltip
                let subheaders = []
                shared_points.forEach((point) => {
                    let header = point.series.userOptions.seriesName;

                    if (!subheaders.includes(header)) {
                        subheaders.push(header)
                    }
                })


                //go through all subheaders and create the html tag, then go through each point and then put them under
                subheaders.forEach((header, I) => {
                    // debugger;
                    let header_color = shared_points[I * 2] ? shared_points[I * 2].color : '';
                    let header_html = '<div style="margin:5px 2px 0 2px;font-weight:bold;color:' + header_color + '">' + header.toUpperCase() + '</div>';
                    s += header_html
                    shared_points.forEach((point) => {
                        let seriesMeasureName = point.series.userOptions.name;
                        let seriesName = point.series.userOptions.seriesName.toUpperCase();
                        let seriesColor = point.color;

                        // debugger;
                        if (header == point.series.userOptions.seriesName) {
                            let mainMeasure = point.series.userOptions.mainMeasure;
                            let displayVal = point.y.toFixed(0);
                            if (mainMeasure !== 'Occ%' && mainMeasure !== 'Rooms') {
                                displayVal = currencyFormat(displayVal, point.series.userOptions.currency);
                            }
                            if (mainMeasure === 'Occ%') {
                                displayVal += '%';
                            }
                            //debugger;

                            let measure_element_html = '<div style="margin-left:5px;margin-right:5px;"><span style="font-size:17px; margin-right:2px;color:' + seriesColor + '">&#9679;</span> ' + point.series.userOptions.mainMeasure + "<strong> : " + displayVal + "</strong></div>"
                            s += measure_element_html
                        }
                    })
                })
                return s
            }
        },
        "navigation": {
            "buttonOptions": {
                "enabled": false
            }
        },
        "navigator": {
            "baseSeries": 0,
            "height": 15,
            "useHTML": true,
            "enabled": false
        },
        "scrollbar": {
            "height": 6,
            "barBorderRadius": 5,
            "barBorderWidth": 1,
            "barBorderColor": "purple",
            "barBackgroundColor": "purple",
            "enabled": false
        },
        //"series": series,
        "series": [],
        "plotOptions": {
            "series": {
                "pointInterval": undefined,
                "turboThreshold": 1000000000000000000,
                "showInNavigator": true,
                "events": {

                },
                "animation": {
                    duration: 1200
                },
                "stacking": 'normal',
                "borderWidth": 0
            }
        }
    },
    default: {
        option: {
            "credits": {
                "enabled": false
            },
            "chart": {
                //"height": (9 / 16 * 100) + '%', // 16:9 ratio
                //"height": (6.3 / 16 * 100) + '%',
                // "height": '100%',
                "type": 'column',
                "reflow": true,
                "backgroundColor": "transparent",
                "style": {
                    "color": "#606060",
                    "fontFamily": "Lato Regular"
                },
                "marginBottom": 80
            },
            "legend": {
                "enabled": true,
                "y": 0,
                "floating": false,
                "verticalAlign": "bottom",
                "align": "right",
                "fontFamily": `"Lato Regular"`,
                "fontSize": 16,
            },
            "xAxis": [
                {

                    //"min": xAxisMin,
                    // "max": Number(moment(date_until).format('x')),
                    //"max": xAxisMax,
                    "crosshair": {
                        "width": 1,
                        "color": "rgb(130,203,208)"
                    },
                    // "type": "category",
                    // "categories": ['JAN', 'FEB', 'MAR', 'APR', 'MAY'],
                    "type": 'datetime',
                    // "dateTimeLabelFormats": {
                    //     "day": "%b %e",
                    //     "week": "%Y<br/>%m-%d",
                    //     "month": "%b",
                    //     "year": "%Y",
                    // },
                    "labels": {
                        "style": {
                            "color": "#606060",
                            "fontFamily": "Lato Regular"
                        },
                        // "formatter": function () {
                        //     // return this.value;
                        //     return window.Highcharts.dateFormat('%a %d %b ', this.value);
                        // }
                    },
                    "title": {
                        "style": {
                            "color": "#606060",
                            "fontFamily": "Open Sans"
                        }
                    },
                    "ordinal": true,
                }
            ],
            "yAxis": [], //This need to be manipulated with left and right y axis
            "rangeSelector": {
                "enabled": true,
                "height": 0,
                "inputEnabled": false,
                "buttonTheme": {
                    "visibility": "hidden"
                },
                "labelStyle": {
                    "visibility": "hidden"
                }
            },
            "title": {
                "text": "",
                "style": {
                    "color": "#606060",
                    "fontFamily": "Open Sans"
                }
            },
            "enableMouseTracking": true,
            "enableStickyTracking": false,
            "tooltip": {
                "enabled": true,
                "animation": true,
                "shared": true,
                "useHTML": true,
                "shape": "callout",
                "backgroundColor": "transparent",
                "shadow": "none",
                "borderColor": "transparent", "marginLeft": 0,
                "borderRadius": 0,
                "hideDelay": 500,
                // "className": "chart-tooltip",
                // "className": "chart-tooltip-wrapper",
                // "formatter": function () {
                //     //  debugger;
                //     let tooltipTitle = '';
                //     let timestamp = this.x / 1000
                //     tooltipTitle = moment.unix(timestamp).format("ddd, MMM D, YYYY");
                //     let shared_points = this.points

                //     let s = '<div style="background-color:#777;padding:2px;color:white">' + tooltipTitle + '</div>';

                //     //first find all headers needed to put on tooltip
                //     let subheaders = []
                //     shared_points.forEach((point) => {
                //         let header = point.series.userOptions.seriesName;

                //         if (!subheaders.includes(header)) {
                //             subheaders.push(header)
                //         }
                //     })


                //     //go through all subheaders and create the html tag, then go through each point and then put them under
                //     subheaders.forEach((header, I) => {
                //         // debugger;
                //         let header_color = shared_points[I].color;
                //         let header_html = '<div style="margin:5px 2px 0 2px;font-weight:bold;color:' + header_color + '">' + header.toUpperCase() + '</div>';
                //         s += header_html
                //         shared_points.forEach((point) => {
                //             let seriesMeasureName = point.series.userOptions.name;
                //             let seriesName = point.series.userOptions.seriesName.toUpperCase();
                //             let seriesColor = point.color;

                //             // debugger;
                //             if (header == point.series.userOptions.seriesName) {
                //                 let measure_element_html = '<div style="margin-left:5px;margin-right:5px;"><span style="font-size:17px; margin-right:2px;color:' + seriesColor + '">&#9679;</span> ' + point.series.userOptions.mainMeasure + " : " + point.y + "</div>"
                //                 s += measure_element_html
                //             }
                //         })
                //     })
                //     return s
                // }
            },
            "navigation": {
                "buttonOptions": {
                    "enabled": false
                }
            },
            "navigator": {
                "baseSeries": 0,
                "height": 15,
                "useHTML": true,
                "enabled": false
            },
            "scrollbar": {
                "height": 6,
                "barBorderRadius": 5,
                "barBorderWidth": 1,
                "barBorderColor": "purple",
                "barBackgroundColor": "purple",
                "enabled": false
            },
            //"series": series,
            "series": [],
            "plotOptions": {
                "series": {
                    "pointInterval": undefined,
                    "turboThreshold": 1000000000000000000,
                    "showInNavigator": true,
                    "events": {

                    },
                    "animation": {
                        duration: 1200
                    },
                    //"stacking": false,
                    events: {

                        legendItemClick: function (e) {
                            // debugger;
                            let t = this;
                            let { chart, name } = this;
                            let { segmentName, id } = this.userOptions;

                            let { series } = chart;
                            let groupedSeries = chart.series.filter(series => series.name === name);
                            let linkedSeries = series.filter((series) => series.userOptions.linkedTo === segmentName);


                            var shown = this.visible ? false : true;
                            // debugger;
                            if (shown) {
                                this.update({ visible: shown });

                                chart.axes.map((item, i) => {
                                    //chart.axes[i].visible = true;
                                    //chart.axes[i].update({ visible: shown });
                                })
                                series.map((item, i) => {
                                    if (item.userOptions.linkedTo === segmentName && item.userOptions.type !== "spline") {
                                        // series[i].visible = shown;
                                        series[i].update({ visible: shown });
                                        // series[i].setVisible(true, true);
                                        // debugger;
                                        // item.visible = shown;
                                    };
                                });

                                return false;
                            }
                            //
                            if (this.initialType == 'spline') {
                                // return false;
                            }
                            // return false;
                        }
                    }
                }
            }
        },
        series: {
            "data": [], // push data for series
            "name": "series name",
            //"colorKey": setChartSeriesColorKey({ identifier, identifierIndex, measure, measureIndex, seriesName, resource_id, tile: firstTile, }),
            //"mainCKey": setChartSeriesColorKey({ isMainColor: true, identifier, identifierIndex, measure, measureIndex, seriesName, resource_id, tile: firstTile, }),
            "id": "put unique id",
            "currency": {}, //{ code: currency, symbol: currency_symbol },
            "dataGrouping": {
                "units": [
                    [
                        "day",
                        [
                            1
                        ]
                    ]
                ],
                "enabled": false
            },
            "type": 'column',
            "negativeColor": "#FF0000",
            "yAxis": "",
            "category": "category name",
            "seriesName": "pull seriesName",
            "mainMeasure": "put measure value",
            "subCategory": "",
            "color": "#81D4FA",

        },
        yAxis: {
            "id": "",
            "title": {
                "text": "",
                "style": {
                    "color": "#606060",
                    "fontFamily": "Lato Regular"
                },
                "useHTML": true,
                "align": "high",
                "offset": 0,
                "rotation": 0
            },
            "opposite": false,
            "labels": {
                "style": {
                    "color": "#606060",
                    "fontFamily": "Lato Regular"
                }
            },
            "crosshair": {
                "width": 1,
                "color": "rgb(130,203,208)"
            },
            "endOnTick": true,
            "showLastLabel": true,
            "maxPadding": 0.25,

        }

    },
    COLORS: {
        dailyFlash: {
            OTB_PRIMARY_L: "rgb(129, 212, 250)",
            OTB_PRIMARY_R: "rgb(255, 87, 34)",
            OTB_SDLY_L: "rgb(41, 182, 246)",
            OTB_SDLY_R: "rgb(255, 152, 0)",
            OTB_SDLY_ACTUAL_L: "rgb(2, 119, 189)",
            OTB_SDLY_ACTUAL_R: "rgb(255, 193, 7)",

            PICKUP_PICKUP_L: "rgb(129, 212, 250)",
            PICKUP_PICKUP_R: "rgb(255, 87, 34)",
        }
    }

}

const NEW_PROPERTY_SYSTEM_SETTINGS = {
    "base_room_code": "",
    "resource_id": '',
    "is_constrained": true,
    "market_code_ignorelist_for_ML": [],
    "SRR_V2_Settings": {
        "Comp_Set_Weightage_For_Pricing": 0.8,
        "Max_Allowed_Deviation_From_Current_Sell_Rate": 0.3
    },
    "resource_type": "property",
    "source_codes": {},
    "market_codes": {},
    "room_types": {},
    "vendor_integation_mappings": {
        "compset": {
            "ota_i_api": {
                "subscription_id": '',
                "channel": "",
                "hotel_id": '',
                "load_schedule": {
                    "data_repull_interval_minutes": 0,
                    "from": "00:00",
                    "until": "00:00",
                    "timezone": ""
                }
            }
        }
    },
    "market_segment_category_mapping": {},
    "market_segment_category_order": [],
    "property_current_total_capacity": '',
    "primary_vacation_rental_market": "",
    "primary_demand_market_id": '',
    "primary_revup_demand_market_id": '',
    "market_settings": [],
    "primary_revup_property_id": '',
    "default_rate_segment_code": "",
    "compsetSettings": {
        "source": "",
        "resource_scope": "",
        "resource_id": '',
        "compset_filter": []
    },
    "rate_source": {
        "source": "",
        "type": "",
        "resource_id": [],
        "resource_lookup": {
            "rate_code": ""
        }
    },
    "lvl1_mseg_grouping": [
        {
            "Transient": []
        },
        {
            "Group": []
        },
        {
            "Contract": []
        }
    ],
    "corporate_account": "",
    "locale_city": "",
    "users_permissions": [],
    "property_location": {},
    "last_audit_rec": {
        "user_id": '',
        "update_timestamp": "",
        "update_type": "",
        "resource_type": "property"
    },
    "market_code_ignorelist": [],
    "pricing_settings": {
        "room_dow_plans": [],
        "controls": {}
    },
    "price_rule_settings": {
        "rules": []
    },
    "budget_mode": {
        "source": "upload"
    },
    "inventory_settings": {
        "current": {
            "external_systems": [
                {
                    "system_name": "",
                    "integration_code": "",
                    "system_type": "",
                    "system_code": ""
                }
            ],
            "room_settings": [],
            "total_capacity": ''
        },
        "status": "room_level",
        "capacities_by_range": []
    },
    "capacities_by_range": [],
    "status": "active",
    "rate_push_type": "",
    "auto_rate_push": {
        "push_interval": {
            "frequency_minutes": "",
            "start_time": "",
            "end_time": ""
        },
        "all_dates": false,
        "enabled": true,
        "fixed_window": false,
        "rate_push_field": "",
        "push_base_room": false,
        "approval_ranges": [
            {
                "from": "",
                "until": ""
            }
        ],
        "approval_horizons": [
            {
                "from": 0,
                "until": 90
            },
            {
                "from": 0,
                "until": 30
            }
        ]
    },
    // "max_wtp_vals_dict": {
    //     "med_market_demand": 0,
    //     "low_market_demand": 0,
    //     "high_market_demand": 0
    // },
    // "recrate_rounding_mode": "round_to_4_or_9",
    // "maintain_hierarchy": true,
    "account_genera_notes": "",
    "account_payment_notes": "",
    // "rtp_rules": {
    //     "dba_bucket": {},
    //     "%_change": {},
    //     "%_increase": {}
    // },
    "rtp_grid_option_type": "default",
    // "new_rtp_algo": true,
    "notification_settings": {
        "pickup_down_occ": {
            "rule_type": "diff_threshold",
            "threshold_value": 0,
            "range_horizon": 0,
            "threshold_type": "percent",
            "rule_metrics": [
                "occ"
            ],
            "active": false
        },
        "pickup_down_adr": {
            "rule_type": "diff_threshold",
            "threshold_value": 0,
            "range_horizon": 0,
            "threshold_type": "percent",
            "rule_metrics": [
                "adr"
            ],
            "active": false
        },
        "pickup_up_adr": {
            "rule_type": "diff_threshold",
            "threshold_value": 0,
            "range_horizon": 0,
            "threshold_type": "percent",
            "rule_metrics": [
                "adr"
            ],
            "active": false
        },
        "pickup_up_occ": {
            "rule_type": "diff_threshold",
            "threshold_value": 0,
            "range_horizon": 0,
            "threshold_type": "fixed_val",
            "rule_metrics": [
                "occ"
            ],
            "active": false
        },
        "dynamic_notifications": []
    },
    "rate_discount_rules": {
        "enabled": false,
        "default": {
            "markup": 0,
            "discount": {
                "branddotcom": {
                    "month": {}
                },
                "ota": {
                    "month": {}
                }
            }
        }
    },
    "restrictions": {
        "enable": false
    }
};

export {
    ABORT_REQUEST_CONTROLLERS,
    NEW_PROPERTY_SYSTEM_SETTINGS,
    CHART_OPTIONS,
    api_status, promises,
    API_REGISTRY, API_BASE_URL,
    API_REPORT_URL, API_DI_CERT,
    TILES, TILE_GROUP_ONE, TILE_GROUP_TWO
};



