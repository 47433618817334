import React, { memo, useEffect, useReducer, useState } from 'react'

import {
  AppBar
  , Box
  , Toolbar
  , IconButton
  , Typography
  , Menu
  , MenuItem
  , Container
  , Avatar
  , Button
  , Grid
  , Tooltip
  , FormControl
  , InputLabel
  , Select
  , ListSubheader
  , TextField
  , InputAdornment
  , List
  , Link
  , ListItem
  , ListItemIcon
  , ListItemText
  , Divider
  , Drawer
} from '@mui/material/';
import {
  Person as PersonIcon
  , SettingsOutlined as SettingsOutlinedIcon
  , ScheduleOutlined as ScheduleOutlinedIcon
  , VideocamOutlined as VideocamOutlinedIcon
  , PowerSettingsNewOutlined as PowerSettingsNewOutlinedIcon
  , AssessmentOutlined as AssessmentOutlinedIcon
  , ArrowForwardRounded as ArrowForwardRoundedIcon
  , ApartmentSharp as ApartmentSharpIcon
  , Search as SearchIcon
  , KeyboardDoubleArrowRight as KeyboardDoubleArrowRightIcon
} from '@mui/icons-material/';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import CloseIcon from '@mui/icons-material/Close';
import NativeSelect from '@mui/material/NativeSelect';
import CreateNewFolderIcon from '@mui/icons-material/CreateNewFolder';
import CloudDownloadIcon from '@mui/icons-material/CloudDownload';
import { getFromSession,deepCopy } from '../../app/util';

import PhotoCamera from '@mui/icons-material/PhotoCamera';
import Stack from '@mui/material/Stack';
import DocumentList from './DocumentList';
import { color } from 'highcharts';
import UploadFile from './UploadFile';
import UploadFileNew from './UploadFileNew';
import { useSelector } from "react-redux";
import { API_BASE_URL } from "../../app/config";
import dataService from "../../services/data.service";
import { settingsReducers } from "./reducers";
import { useData } from "../../hooks/";
import Alert from '@mui/material/Alert';
import { mt } from 'date-fns/locale';
import moment from "moment";
import {
  CloseSharp as CloseSharpIcon,
  ArrowBackIosNewSharp as ArrowBackIosNewSharpIcon,
  ArrowForwardIosSharp as ArrowForwardIosSharpIcon,
  CheckSharp as CheckSharpIcon,
  GridOn as GridOnIcon
}
  from '@mui/icons-material';
import { replaceMultiText } from "../../app/util";
import { fetchDataSimple } from "../../services/data.service";

import './upload.css'



const UploadsPopUp = (props) => {

  const initalState = {
    data: { files: [], folders: [] },
    ui: {
      request: {
        resType: null,
        status: "idle",
        message: "",
      },
      path: [],
      breadcrumb: []
    },
    parent: null
  };

  const [settingsState, dispatchSettings] = useReducer(settingsReducers, initalState);

  const { app, auth } = useSelector((state) => state);
  const { user, profile } = auth;
  const { app_state } = app.current_state;
  const [uploadReport, setUploadReport] = useState(props.reportId);


  const { ui, data } = settingsState;

  const deleteDocument = (index, documentUid) => {
    const request = {
      "method": "DELETE",
      "documentUid": documentUid,
    };
    const correction = {
      "{pid}": app_state.property_id,
    };
    let apiUrl = "upload/property-file/{pid}/";
    apiUrl = replaceMultiText(apiUrl, correction);
    let api = apiUrl;
    fetchDataSimple({ api, request })
      .then((rs) => {
       
        settingsState.parent = "";
        if (rs.status === 'success') {
          
          dispatchSettings({ type: "onDeleteDocument", index: index,documentUid:documentUid });
        
            getListing("navigateFolder");

        } else {
        
         
          console.log("deleteItem  Failed! ", rs);
        }
        setTimeout(() => {
          dispatchSettings({ type: "onHideAlert" });
        }, 2500);
      })
      .catch(() => {
        dispatchSettings({ type: "onResponseError" });
        setTimeout(() => {
          dispatchSettings({ type: "onHideAlert" });
        }, 3000);
      });

  }

  const deleteItem = (index, folder_uid) => {
    const toBeDeleted = settingsState.data.folders[index];
    const payload = {
      folder_uid: toBeDeleted.folder_uid,
      property_id: app_state.property_id,
    }
    //const result = useData('documents/delete_folder',request);

    const request = {
      "method": "DELETE",
      "folderUid": folder_uid,
    };
    const correction = {
      "{pid}": app_state.property_id,
    };
    let apiUrl = "upload/delete-folder/{pid}/";
    apiUrl = replaceMultiText(apiUrl, correction);
    let api = apiUrl;
    fetchDataSimple({ api, request })
      .then((rs) => {
        // debugger;
        let timeout = 2500;
        if (rs.status === 'success') {
          settingsState.dataOriginal= deepCopy(rs);
          if(settingsState.ui.breadcrumb.length>0){
            let path = settingsState.ui.breadcrumb[settingsState.ui.breadcrumb.length-1];
            let fuid = path.split(">")[2];
            let objFolders = findFolderByUid(fuid,settingsState.dataOriginal.folders)
            settingsState.data = { ...data, files: objFolders.files, folders: objFolders.folders }
          }else {
            settingsState.data = { ...data, files: rs.files, folders: rs.folders }
  
          } 
          dispatchSettings({ type: "onDeleteFolder" });
        } else {

          settingsState.ui.request.message = rs.message;
          if(rs.message =="Child folders or files exist"){
            settingsState.ui.request.message="This folder cannot be deleted because it has contents. Please delete the contents first."
            timeout=5000;
          }
          dispatchSettings({ type: "onDeleteFail" });
          console.log("deleteItem  Failed! ", rs);
        }
        hideAlert(timeout)
        
      })
      .catch(() => {
        dispatchSettings({ type: "onResponseError" });
        hideAlert()
      });
  }

  const request = {
    property_id: app_state.property_id,
    user_id: auth.user.user_id
  };
  // const result = useData('documents/get_property_files',request);
  // console.log("result =>"+result.data);
  //debugger;
  useEffect(() => {
    getListing();
    console.log("render 1 time");
  }, []);
  // }, [props.reportId,deleteItem]);

  // Create new Folder 
  const createFolder = (val) => {
    console.log("settingsState =>", settingsState);
    console.log(settingsState.data, " settingsState =>", settingsState.parent);
    if (val !== "") {
      //console.log("johri= " + val.replaceAll([" "], ["_"]));
      let folder_name = val;//.replaceAll(" ","_");
      let request = {
        "method": "POST",
        "folderName": folder_name,
      };

      if (settingsState.parent) {
        Object.assign(request, { parentFolderUid: settingsState.parent });
      }
      const correction = {
        "{pid}": app_state.property_id,
      };
      let apiUrl = "upload/create-folder/{pid}/";
      apiUrl = replaceMultiText(apiUrl, correction);
      let api = apiUrl;

      fetchDataSimple({ api, request })
        // dataService.getData({ api: API_BASE_URL + "documents/create_folder", request: payload, })
        .then((rs) => {
          if (rs.status === 'success') {
            console.log("folder success => ", rs);
            settingsState.dataOriginal= deepCopy(rs);
            if(settingsState.ui.breadcrumb.length>0){
              let path = settingsState.ui.breadcrumb[settingsState.ui.breadcrumb.length-1];
              let fuid = path.split(">")[2];
              let objFolders = findFolderByUid(fuid,settingsState.dataOriginal.folders)
              settingsState.data = { ...data, files: objFolders.files, folders: objFolders.folders }
            }else {
              settingsState.data = { ...data, files: rs.files, folders: rs.folders }
    
            } 
            dispatchSettings({ type: "onFolderCreate" });
          } else {
            dispatchSettings({ type: "onResponseError" });
            console.log("deleteItem  Failed! ", rs);
          }
          setTimeout(() => {
            dispatchSettings({ type: "onHideAlert" });
          }, 5000);
        })
        .catch(() => {
          dispatchSettings({ type: "onResponseError" });
          setTimeout(() => {
            dispatchSettings({ type: "onHideAlert" });
          }, 3000);
        });
    } else {
      dispatchSettings({ type: "onResponseError" });
      setTimeout(() => {
        dispatchSettings({ type: "onHideAlert" });
      }, 3000);
    }
  }
  // End Create New folder

  // Upload file function 
  const uploadFile = (e) => {
    const formData = new FormData();
    let file = e.target.files;
    var today = moment().format('YYYY-MM-DD');
    var file_upload_type;
    if (uploadReport === 'budget_upload') {
      file_upload_type = "budget";
    } else if (uploadReport === 'forecast_upload') {
      file_upload_type = "manual_forecast";
    } else if (uploadReport === 'documents_upload') {
      file_upload_type = "document";
    }

    if (file[0]) {
      formData.append("file", file[0]);
      formData.append("property_id", app_state.property_id);
      formData.append("user_id", auth.user.user_id);
      formData.append("file_upload_type", file_upload_type);
      formData.append("asof_date", today);
      formData.append("folder_uid", "");
      formData.append("debug", true);
      // formData.append("document_tags",[{"tag_name":"abc123","tag_description":"my tag  description","tag-type":"system-tag"}]);

      formData.append("token", getFromSession("token"));
      formData.append("token_string", getFromSession("token"));

      const options = {
        method: "POST",
        body: formData,
        // headers: {
        //   'Content-Type': 'multipart/form-data',
        // }
      };
      dispatchSettings({ type: "wait" });

      fetch(API_BASE_URL + "documents/property_file_upload_x", options)
        .then((rs) => {
          console.log("file uploaded=>", rs);
          if (rs.status == 200) {
            dispatchSettings({ type: "onFileUpload" });
          } else {
            dispatchSettings({ type: "onResponseError" });
          }
          hideAlert()
        })
        .catch(() => {
          dispatchSettings({ type: "onResponseError" });
          hideAlert()
        });
    }
  }
  const hideAlert = (time= 3000)=>{
    setTimeout(() => {
      dispatchSettings({ type: "onHideAlert" });
    }, time);
  }
  // End Upload file function 

  const getListing = (type) => {
    ///////////////
    const request = {
      "method": "GET",
    };
    const correction = {
      "{pid}": app_state.property_id,
    };
    let apiUrl = "upload/get-document-tree/{pid}/";
    apiUrl = replaceMultiText(apiUrl, correction);
    let api = apiUrl;
    fetchDataSimple({ api, request })
      .then((response) => {
        // if (response.status === 'success') {
        console.log(response);
        settingsState.dataOriginal= deepCopy(response);
        let objFolders = deepCopy(response)
        if(type === "navigateFolder" && settingsState.ui.breadcrumb.length>0){
          let path = settingsState.ui.breadcrumb[settingsState.ui.breadcrumb.length-1];
          let fuid = path.split(">")[2];
           objFolders = findFolderByUid(fuid,settingsState.dataOriginal.folders)
          settingsState.data = { ...data, files: objFolders.files, folders: objFolders.folders }
        }else {
          
          settingsState.data = { ...data, files: [...objFolders.files], folders: [...objFolders.folders] }
          handleBreadcrumb("0");
        } 
        dispatchSettings({
          type: "onDataSuccess",
        });
        setTimeout(() => {
          dispatchSettings({ type: "onHideAlert" });
        }, 2000);
        // } else {
        //   console.log("failed", response);
        // }
      })
      .catch((error) => {
        console.log("Error Occour rr", error);
      });
    ///////////
  }


  const handleSelect = (event) => {
    setUploadReport(event.target.value);
    dispatchSettings({
      type: "onDataSuccess",
    });
  }


  const handleFolderChange = (path, folder_uid) => {
    let idx = settingsState.ui.path.indexOf(path);
    if (idx < 0) {
      settingsState.ui.path.push(path);
    }
    settingsState.parent = folder_uid;
    //debugger;
    const newPath = settingsState.ui.path;

    newPath.forEach(function (p) {
      if (settingsState.ui.breadcrumb.indexOf(p) < 0) {
        settingsState.ui.breadcrumb.push(p);
      }
    })
    let pathArr = newPath[newPath.length-1].split(">");
    let objFolders = findFolderByUid(pathArr[2], settingsState.dataOriginal.folders)
    // console.log("breadcrumb=",settingsState.ui);
    settingsState.data = { ...data, files: objFolders.files, folders: objFolders.folders }
    dispatchSettings({
      type: "onDataSuccess",

    });
    //debugger;
    //Do dispatchSettings({ type: "onDataChanged" });
  }

  function findFolderByUid(folderUid, folders) {
    for (const folder of folders) {
      if (folder.folder_uid === folderUid) {
        return folder;
      }
      if (folder.folders.length > 0) {
        const result = findFolderByUid(folderUid, folder.folders);
        if (result) {
          return result;
        }
      }
    }
    return null;
  }
  

  const handleBreadcrumb = (fullPath) => {
   
    if(fullPath.indexOf(">")<0){
      settingsState.ui.breadcrumb =[];
      settingsState.ui.path = [];
      settingsState.parent = null;
      settingsState.data =  { ...data, files: settingsState.dataOriginal.files, folders: settingsState.dataOriginal.folders }
    }else {
      let pathArr = fullPath.split(">");
      let idxOf = settingsState.ui.breadcrumb.indexOf(fullPath);
      settingsState.ui.breadcrumb.splice(idxOf+1 ,1)
      settingsState.parent = pathArr[2];      
      let objFolders = findFolderByUid(pathArr[2], settingsState.dataOriginal.folders)
      console.log("index of => ",settingsState.ui.breadcrumb.indexOf(fullPath));
      settingsState.data = { ...data, files: objFolders.files, folders: objFolders.folders }
    }
    dispatchSettings({
      type: "onDataSuccess",
    });
    
  }


  return (

    <div>
      {/* , "& .MuiPaper-root": { maxWidth: 900 }  */}
      <Dialog className='uploadPopup' open={props.action} maxWidth="md" sx={{ zIndex: 1150, "& .MuiPaper-root": { minWidth: 900 } }} >
        <Box sx={{ background: "#333333", color: "#fff", position: "relative" }}>
          <Typography className='upload-txt'>Upload - </Typography>
          <FormControl variant="standard" sx={{ mt: 1, ml: 10, minWidth: 90 }}>
            <Select
              value={uploadReport}
              onChange={(e) => { handleSelect(e) }}
              className='upload-selector'
            // sx={{ color: "#80cf46 !important", fontSize: 14, borderBottom:"0px solid #fff" }}
            >
              <MenuItem value="budget_upload">Budget</MenuItem>
              <MenuItem value="documents_upload">Document</MenuItem>
              <MenuItem value="forecast_upload">User Forecast</MenuItem>

            </Select>
          </FormControl>

          {/* <CloseIcon onClick={props.onClose} sx={{ float: "right", mt: 1, mr: 1, cursor: "pointer" }} /> */}
          <CloseSharpIcon onClick={props.onClose} sx={{ float: "right", mt: 1, mr: 1, cursor: "pointer" }} />

        </Box>
        {/* </DialogTitle> */}

        <DialogContent className='upload-main-container'>
          {ui.request.message !== "" ? (
            <Box>
              <Alert icon={false} severity={ui.request.resType} sx={{ minWidth: "500px !important", textAlign:"center" }}>
                {ui.request.message}
              </Alert>
            </Box>
          ) : (
            <></>
          )
          }

          {/* <UploadFileNew reportId={uploadReport} uploadFile={uploadFile} createFolder={createFolder} /> */}
          <UploadFile getListing={getListing} reportId={uploadReport} uploadFile={uploadFile} createFolder={createFolder} data={settingsState}  />
          <DialogContentText sx={{ color: "#fff", fontSize: 14, mt: 5 }}>
            <DocumentList  createFolder={createFolder} deleteItem={deleteItem} 
            handleBreadcrumb={handleBreadcrumb} handleFolderChange={handleFolderChange} deleteDocument={deleteDocument}
             reportType={uploadReport} data={settingsState.data} ui={ui} />
          </DialogContentText>
        </DialogContent>

      </Dialog>
    </div>

  )
}

//export default memo(UploadsPopUp) ;
export default UploadsPopUp;
