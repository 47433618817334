import moment from "moment";
import { convertToPercent, deepCopy, getAppData, getDatesInRange, getFromState, getObjectFilter, getTransformedSegments, round, setUpDate } from "../../../app/util";
import { useDataSummary } from "../../Tile/kpiService";
import dataService from "../../../services/data.service";
import { useSelector } from "react-redux";
import { transformSegmentData } from "../segmentedData";
import { getSegmentedCode } from "../processDataReports";

export function getPickupReportData_hotel(columns, auth, app_state) {
    let data = (app_state.workspace_controls.segmented_view) ? [] : deepCopy(dataService.getSeriesById("OSRSegment").data.data);
    let marketData = (dataService.getSeriesById("MarketARISegmented").data) ? deepCopy(dataService.getSeriesById("MarketARISegmented").data.data) : [];
    let setup_data = [];
    if (data) {
        let dataKey = "HotelTotal";
        data.map((item, index) => {
            let data_columns = [];
            columns.map(col => {
                let dataObj = {};
                if (col.type === 'dateColumn') {
                    dataObj = setUpDate(dataObj, item.index.date);
                    if (app_state.workspace_controls.segmented_view) {
                        dataObj["segment"] = item.index.segment;
                    }
                }

                if (col.type === 'dataColumn') {
                    let { mainKey } = col;
                    col.subcolumns.map(key => {
                        if (mainKey === "variance_pickup") {
                            if (key.value === "Occ") {
                                dataObj[key.value] = convertToPercent(item[dataKey]["primary"][key.value] - item[dataKey]["pickup"][key.value])
                            } else {
                                dataObj[key.value] = round(item[dataKey]["primary"][key.value] - item[dataKey]["pickup"][key.value])
                            }
                        } else if (mainKey === "difference_sdly") {
                            if (key.value === "Occ") {
                                dataObj[key.value] = convertToPercent(item[dataKey]["primary"][key.value] - item[dataKey]["sdly"][key.value])
                            } else {
                                dataObj[key.value] = round(item[dataKey]["primary"][key.value] - item[dataKey]["sdly"][key.value])
                            }
                        } else if (mainKey === "sdly_pickup_diff") {
                            dataObj[key.value] = round(item[dataKey]["sdly_actual"][key.value] - item[dataKey]["sdly"][key.value])
                        } else if (mainKey === "marketprimary") {
                            if (key.value == "Occ") {
                                dataObj[key.value] = (marketData[index]) ? convertToPercent(marketData[index]["MarketTotal"]["primary"][key.value]) : 0.00;
                            } else {
                                dataObj[key.value] = (marketData[index]) ? round(marketData[index]["MarketTotal"]["primary"][key.value]) : 0.00;
                            }
                        } else if (mainKey === "marketsdly") {
                            if (key.value == "Occ") {
                                dataObj[key.value] = (marketData[index]) ? convertToPercent(marketData[index]["MarketTotal"]["sdly"][key.value]) : 0.00;
                            } else {
                                dataObj[key.value] = (marketData[index]) ? round(marketData[index]["MarketTotal"]["sdly"][key.value]) : 0.00;
                            }
                        } else if (mainKey === "market_sdly_diff") {
                            dataObj[key.value] = (marketData[index]) ? round(marketData[index]["MarketTotal"]["primary"][key.value] - marketData[index]["MarketTotal"]["sdly"][key.value]) : 0.00
                        } else {
                            if (key.value == "Occ") {
                                dataObj[key.value] = convertToPercent(item[dataKey][mainKey][key.value]);
                            } else {
                                dataObj[key.value] = round(item[dataKey][mainKey][key.value]);
                            }
                        }
                    })
                }
                dataObj.id = col.id;
                data_columns.push(dataObj);
            })

            data_columns.map((itmArr, idx) => {
                if (itmArr['date'] == "" || itmArr['segment'] === "Total") {
                    data_columns[idx]["ignore_sort"] = true;
                }
            })
            setup_data.push(data_columns);
        })
    }
    return setup_data;

}


export function getTopSegments(auth, app_state, category) {
    let system_settings = getAppData(auth, app_state)
    let market_segment_category_mapping = system_settings.market_segment_category_mapping;
    const lvl1_mseg_grouping = JSON.parse(
        JSON.stringify(system_settings.lvl1_mseg_grouping)
    );
    const topSegments = {};
    lvl1_mseg_grouping.filter((item) => {
        if (item.Transient) topSegments.Transient = item.Transient;
        if (item.Group) topSegments.Group = item.Group;
        if (item.Contract) topSegments.Contract = item.Contract;
    });
    let segmentCodes = [];
    let topSegmentsList = topSegments[category];
    if (topSegmentsList) {
        topSegmentsList.map((item) => {
            if (market_segment_category_mapping[item]) {
                segmentCodes = [...segmentCodes, ...market_segment_category_mapping[item]];
            }
        })
        segmentCodes = [...new Set(segmentCodes)];
    }
    return segmentCodes;
}


export function getPickupReportSegmentedData_hotel(columns, auth, app_state, ui_state) {
    let data = deepCopy(dataService.getSeriesById("OSRSegment").data.data);
    console.log("data => ", data);
    let marketData = deepCopy(dataService.getSeriesById("MarketARISegmented").data.data);
    let setup_data = [];
    let { date_from, date_until } = app_state.primary_stay_range;

    let dates = getDatesInRange(date_from, date_until);
    let categoryList = ["Transient", "Group", "Contract", "Total"];

    /////// final Total /////

    let blankObj = { Rooms: 0, Occ: 0, ADR: 0, RevPAR: 0, SellRate: 0, Revenue: 0 };
    let primaryTotal = { Rooms: 0, Occ: 0, ADR: 0, RevPAR: 0, SellRate: 0, Revenue: 0 };
    let sdlyTotal = { Rooms: 0, Occ: 0, ADR: 0, RevPAR: 0, SellRate: 0, Revenue: 0 };
    let sdly_actualTotal = { Rooms: 0, Occ: 0, ADR: 0, RevPAR: 0, SellRate: 0, Revenue: 0 };

    let allTotal = {
        "Transient": {
            "primary": blankObj,
            "sdly": blankObj,
            "sdly_actual": blankObj
        },
        "Group": {
            "primary": blankObj,
            "sdly": blankObj,
            "sdly_actual": blankObj
        },
        "Contract": {
            "primary": blankObj,
            "sdly": blankObj,
            "sdly_actual": blankObj
        },

    }

    ///// end final total //// 


    if (dates && dates.length > 0) {

        dates.map((date, dateIndex) => {

            categoryList.map((category, categoryIndex) => {
                let data_columns = [];
                let segmentCodes = getTopSegments(auth, app_state, category);
                columns.map(col => {
                    let dataObj = {};
                    dataObj.id = col.id;
                    if (col.type === 'dateColumn') {
                        dataObj = setUpDate(dataObj, date);
                        if (app_state.workspace_controls.segmented_view) {
                            dataObj["segment"] = category;
                            // dataObj["segment"] = (categoryIndex == (categoryList.length - 1)) ? "Total" : category ;
                        }
                    }
                    if (col.type === 'dataColumn') {
                        let { mainKey } = col;
                        col.subcolumns.map(key => {
                            if (mainKey === "variance_pickup") {
                                let keyPrimaryTotalValue = getSegmentKeyTotal(data[dateIndex], key.value, segmentCodes, "primary", "osr", category);
                                let keyPickupTotalValue = getSegmentKeyTotal(data[dateIndex], key.value, segmentCodes, "pickup", "osr", category);

                                if (key.value === "Occ") {
                                    dataObj[key.value] = convertToPercent(keyPrimaryTotalValue - keyPickupTotalValue)
                                } else {
                                    dataObj[key.value] = round(keyPrimaryTotalValue - keyPickupTotalValue)
                                }
                            } else if (mainKey === "difference_sdly") {
                                let keyPrimaryTotalValue = getSegmentKeyTotal(data[dateIndex], key.value, segmentCodes, "primary", "osr", category);
                                let keySdlyTotalValue = getSegmentKeyTotal(data[dateIndex], key.value, segmentCodes, "sdly", "osr", category);

                                if (key.value === "Occ") {
                                    dataObj[key.value] = convertToPercent(keyPrimaryTotalValue - keySdlyTotalValue)
                                } else {
                                    dataObj[key.value] = round(keyPrimaryTotalValue - keySdlyTotalValue)
                                }
                            } else if (mainKey === "sdly_pickup_diff") {
                                let keySdlyActualTotalValue = getSegmentKeyTotal(data[dateIndex], key.value, segmentCodes, "sdly_actual", "osr", category);
                                let keySdlyTotalValue = getSegmentKeyTotal(data[dateIndex], key.value, segmentCodes, "sdly", "osr", category);
                                dataObj[key.value] = round(keySdlyActualTotalValue - keySdlyTotalValue)
                            }
                            else if (mainKey === "marketprimary") {
                                let keyTotalValue_market = 0;//getSegmentKeyTotal(marketData[index], key.value, segmentCodes, "primary","market");
                                if (key.value == "Occ") {
                                    dataObj[key.value] = convertToPercent(keyTotalValue_market);
                                } else {
                                    dataObj[key.value] = round(keyTotalValue_market);
                                }
                            } else if (mainKey === "marketsdly") {
                                let keyTotalValueSdly_market = 0;// getSegmentKeyTotal(marketData[index], key.value, segmentCodes, "primary","market");

                                if (key.value == "Occ") {
                                    dataObj[key.value] = convertToPercent(keyTotalValueSdly_market);
                                } else {
                                    dataObj[key.value] = round(keyTotalValueSdly_market);
                                }
                            } else if (mainKey === "market_sdly_diff") {
                                let keyTotalValue_market = 0;//getSegmentKeyTotal(marketData[index], key.value, segmentCodes, "primary","market");
                                let keyTotalValueSdly_market = 0;//getSegmentKeyTotal(marketData[index], key.value, segmentCodes, "primary","market");
                                dataObj[key.value] = round(keyTotalValue_market - keyTotalValueSdly_market)
                            }
                            else {
                                let keyTotalValue = getSegmentKeyTotal(data[dateIndex], key.value, segmentCodes, mainKey, "osr", category);
                                if (key.value == "Occ") {
                                    dataObj[key.value] = convertToPercent(keyTotalValue);

                                } else {
                                    dataObj[key.value] = round(keyTotalValue);
                                    if (category !== "Total" && mainKey === "primary") {
                                        console.log(allTotal["Transient"][mainKey][key.value], " keyTotalValue ", keyTotalValue);
                                        // allTotal[category][mainKey][key.value] += round(keyTotalValue);
                                        let fieldArr = Object.keys(primaryTotal);
                                        fieldArr.map((k) => {
                                            allTotal[category][mainKey][k] += round(keyTotalValue);
                                        })

                                    }
                                    // debugger; will start from here
                                }
                            }
                        })
                    }
                    data_columns.push(dataObj);
                })
                setup_data.push(data_columns);
            })

        })
    }
    return setup_data;
}

// function to calculate segmented data for individual category segment.

export function getPickupReportSegmentedData_topSegment(columns, auth, app_state, segmentCodes, ui_state) {

    console.log("ui_state.reports_dpbs.selectedSegment ",ui_state.reports_dpbs.selectedSegment);

    let data = deepCopy(dataService.getSeriesById("OSRSegment").data.data);
    let marketData = deepCopy(dataService.getSeriesById("MarketARISegmented").data.data);
    let setup_data = [];
    let { date_from, date_until } = app_state.primary_stay_range;
    let dates = getDatesInRange(date_from, date_until);
    let blankObj = { Rooms: 0, Occ: 0, ADR: 0, RevPAR: 0, SellRate: 0, Revenue: 0 };
    if (dates && dates.length > 0) {
        let currentDate;
        let totalObj = {};

        // let totalObjData = {};
        // let totalColumns = [];

        dates.map((date, dateIndex) => {

            let totalObjData = {};
            let totalColumns = [];
            if(!currentDate){
                currentDate = date;
            }

            totalObj = setUpDate(totalObj, date);
            totalObj["segment"] = "Total";
            let primaryRoomstotal = 0;
            let primaryRevenueTotal = 0;

            let dataRecord = data.filter((item) => moment(item.index.date).format("YYYY-MM-DD") === moment(date).format("YYYY-MM-DD"));
            segmentCodes.map((segmentCode, segmentIndex) => {
                let data_columns = [];
                let segmentName = segmentCode;
                let segmentKey = "Segment_" + segmentCode;
                columns.map(col => {
                    let { mainKey } = col;
                    let colId = col.id;
                    let dataObj = {};
                    dataObj.id = col.id;
                    if (col.type === 'dateColumn') {
                        dataObj = setUpDate(dataObj, date);
                        if (app_state.workspace_controls.segmented_view) {
                            dataObj["segment"] = segmentName;
                        }
                        if(colId === "staydates"){
                            totalObjData[colId] = setUpDate(totalObjData, date);
                            totalObjData[colId]["segment"] = "Total";
                        }
                    }
                    if (col.type === 'dataColumn') {
                        totalObjData[colId] = (totalObjData[colId] && totalObjData[colId] !== 'undefined') ? totalObjData[colId] :  { Rooms: 0, Occ: 0, ADR: 0, RevPAR: 0, SellRate: 0, Revenue: 0 };;
                     
                        col.subcolumns.map(key => {
                            if (mainKey === "variance_pickup") {
                                if (key.value === "Occ") {
                                    dataObj[key.value] = convertToPercent(dataRecord[0][segmentKey]["primary"][key.value] - dataRecord[0][segmentKey]["pickup"][key.value])
                                    totalObjData[colId][key.value] += dataObj[key.value];
                                } else {
                                    dataObj[key.value] = round(dataRecord[0][segmentKey]["primary"][key.value] - dataRecord[0][segmentKey]["pickup"][key.value])
                                    totalObjData[colId][key.value] += dataObj[key.value];
                                  
                                }
                            } else if (mainKey === "difference_sdly") {
                                if (key.value === "Occ") {
                                    dataObj[key.value] = convertToPercent(dataRecord[0][segmentKey]["primary"][key.value] - dataRecord[0][segmentKey]["sdly"][key.value])
                                    totalObjData[colId][key.value] += dataObj[key.value];
                                    
                                } else {
                                    dataObj[key.value] = round(dataRecord[0][segmentKey]["primary"][key.value] - dataRecord[0][segmentKey]["sdly"][key.value])
                                    totalObjData[colId][key.value] += dataObj[key.value];
                                    
                                }
                            } else if (mainKey === "sdly_pickup_diff") {
                                dataObj[key.value] = round(dataRecord[0][segmentKey]["sdly_actual"][key.value] - dataRecord[0][segmentKey]["sdly"][key.value])
                                totalObjData[colId][key.value] += dataObj[key.value];
                            } else if (mainKey === "marketprimary") {
                                if (key.value == "Occ") {
                                    dataObj[key.value] = 0;//convertToPercent(marketData[index]["MarketTotal"]["primary"][key.value]);
                                } else {
                                    dataObj[key.value] = 0;//round(marketData[index]["MarketTotal"]["primary"][key.value]);
                                }
                                totalObjData[colId][key.value] += dataObj[key.value];
                            } else if (mainKey === "marketsdly") {
                                if (key.value == "Occ") {
                                    dataObj[key.value] = 0;//convertToPercent(marketData[index]["MarketTotal"]["sdly"][key.value]);
                                    totalObjData[colId][key.value] += dataObj[key.value];
                                } else {
                                    dataObj[key.value] = 0;//round(marketData[index]["MarketTotal"]["sdly"][key.value]);
                                    totalObjData[colId][key.value] += dataObj[key.value];
                                }
                            } else if (mainKey === "market_sdly_diff") {
                                dataObj[key.value] = 0;//round(marketData[index]["MarketTotal"]["primary"][key.value] - marketData[index]["MarketTotal"]["sdly"][key.value])
                                totalObjData[colId][key.value] += dataObj[key.value];
                            } else {
                                if (key.value == "Occ") {
                                    dataObj[key.value] = convertToPercent(dataRecord[0][segmentKey][mainKey][key.value]);
                                    totalObjData[colId][key.value] += dataObj[key.value];
                                } else if(key.value === "ADR"){
                                    dataObj[key.value] = round(dataRecord[0][segmentKey][mainKey][key.value]);
                                     primaryRoomstotal += dataRecord[0][segmentKey][mainKey]['Rooms'];
                                     primaryRevenueTotal += round(dataRecord[0][segmentKey][mainKey]['Revenue']);
                                    let adrVal = round(primaryRevenueTotal) / round(primaryRoomstotal) ;
                                    console.log(adrVal," ",primaryRevenueTotal," ",primaryRoomstotal);
                                     totalObjData[colId][key.value] = adrVal;
                                }else {
                                    dataObj[key.value] = round(dataRecord[0][segmentKey][mainKey][key.value]);
                                    totalObjData[colId][key.value] += dataObj[key.value];
                                }
                            }
                        })
                    }
                    data_columns.push(dataObj);
                })
                setup_data.push(data_columns);
            })


            if(!dates[dateIndex + 1] || dates[dateIndex + 1] !== date  ){

                let tempArr = [];
                for(let k in totalObjData){
                    if(k !== "date" && k !== "dow" && k !== "segment"){
                    let tempObj = {};
                    tempObj.id = k;
                    tempObj = {...tempObj,...totalObjData[k]}
                    totalColumns.push(tempObj);
                    }
                }

                if(ui_state.reports_dpbs.selectedSegment === 'All'){
                    setup_data.push(totalColumns);
                }
                totalObjData = {};
                totalObj = {};
                currentDate = date;
                totalColumns = [];
            }
        })
    }
    // console.log("here ", setup_data);
    // debugger
    return setup_data;
}


export function getPickupReportData_topSegment(columns, auth, app_state, segmentCodes) {
    let data = (app_state.workspace_controls.segmented_view) ? [] : deepCopy(dataService.getSeriesById("OSRSegment").data.data);
    let marketData = (dataService.getSeriesById("MarketARISegmented").data) ? deepCopy(dataService.getSeriesById("MarketARISegmented").data.data) : [];
    let setup_data = [];
    if (data) {
        let dataKey = "HotelTotal";
        data.map((item, index) => {
            let data_columns = [];
            columns.map(col => {
                let dataObj = {};
                if (col.type === 'dateColumn') {
                    dataObj = setUpDate(dataObj, item.index.date);
                    if (app_state.workspace_controls.segmented_view) {
                        dataObj["segment"] = item.index.segment;
                    }
                }

                if (col.type === 'dataColumn') {
                    let { mainKey } = col;
                    col.subcolumns.map(key => {
                        if (mainKey === "variance_pickup") {
                            let keyPrimaryTotalValue = getSegmentKeyTotal(item, key.value, segmentCodes, "primary", "osr");
                            let keyPickupTotalValue = getSegmentKeyTotal(item, key.value, segmentCodes, "pickup", "osr");

                            if (key.value === "Occ") {
                                dataObj[key.value] = convertToPercent(keyPrimaryTotalValue - keyPickupTotalValue)
                            } else {
                                dataObj[key.value] = round(keyPrimaryTotalValue - keyPickupTotalValue)
                            }
                        } else if (mainKey === "difference_sdly") {
                            let keyPrimaryTotalValue = getSegmentKeyTotal(item, key.value, segmentCodes, "primary", "osr");
                            let keySdlyTotalValue = getSegmentKeyTotal(item, key.value, segmentCodes, "sdly", "osr");

                            if (key.value === "Occ") {
                                dataObj[key.value] = convertToPercent(keyPrimaryTotalValue - keySdlyTotalValue)
                            } else {
                                dataObj[key.value] = round(keyPrimaryTotalValue - keySdlyTotalValue)
                            }
                        } else if (mainKey === "sdly_pickup_diff") {
                            let keySdlyActualTotalValue = getSegmentKeyTotal(item, key.value, segmentCodes, "sdly_actual", "osr");
                            let keySdlyTotalValue = getSegmentKeyTotal(item, key.value, segmentCodes, "sdly", "osr");
                            dataObj[key.value] = round(keySdlyActualTotalValue - keySdlyTotalValue)
                        }
                        else if (mainKey === "marketprimary") {
                            let keyTotalValue_market = 0;//getSegmentKeyTotal(marketData[index], key.value, segmentCodes, "primary","market");
                            if (key.value == "Occ") {
                                dataObj[key.value] = convertToPercent(keyTotalValue_market);
                            } else {
                                dataObj[key.value] = round(keyTotalValue_market);
                            }
                        } else if (mainKey === "marketsdly") {
                            let keyTotalValueSdly_market = 0;// getSegmentKeyTotal(marketData[index], key.value, segmentCodes, "primary","market");

                            if (key.value == "Occ") {
                                dataObj[key.value] = convertToPercent(keyTotalValueSdly_market);
                            } else {
                                dataObj[key.value] = round(keyTotalValueSdly_market);
                            }
                        } else if (mainKey === "market_sdly_diff") {
                            let keyTotalValue_market = 0;//getSegmentKeyTotal(marketData[index], key.value, segmentCodes, "primary","market");
                            let keyTotalValueSdly_market = 0;//getSegmentKeyTotal(marketData[index], key.value, segmentCodes, "primary","market");
                            dataObj[key.value] = round(keyTotalValue_market - keyTotalValueSdly_market)
                        }
                        else {
                            let keyTotalValue = getSegmentKeyTotal(item, key.value, segmentCodes, mainKey, "osr");
                            if (key.value == "Occ") {
                                dataObj[key.value] = convertToPercent(keyTotalValue);
                            } else {
                                dataObj[key.value] = round(keyTotalValue);
                            }
                        }
                    })
                }
                dataObj.id = col.id;
                data_columns.push(dataObj);
            })

            data_columns.map((itmArr, idx) => {
                if (itmArr['date'] == "" || itmArr['segment'] === "Total") {
                    data_columns[idx]["ignore_sort"] = true;
                }
            })
            setup_data.push(data_columns);
        })
    }
    return setup_data;
}

// function is used to calculate the total of all passed key basis on the the segement
const getSegmentKeyTotal = (item, key, segmentCodes, mainKey, segmentType = "osr", segmentTotalRow = "") => {
    let keyTotal = 0;
    let revenueTotal = 0;
    let roomsTotal = 0

    if (segmentTotalRow && segmentTotalRow === "Total") {
        if (key !== "SellRate") {
            keyTotal = item["HotelTotal"][mainKey][key];
        } else {
            keyTotal = 0;
        }
        return keyTotal;
    } else {

        if (segmentCodes && segmentCodes.length > 0) {
            segmentCodes.map((code, index) => {
                let segmentCode = (segmentType === "market") ? "MarketSegment_" + code : "Segment_" + code;
                if (key === "ADR") {
                    revenueTotal += (item[segmentCode][mainKey]["Revenue"]) ? item[segmentCode][mainKey]["Revenue"] : 0;
                    roomsTotal += (item[segmentCode][mainKey]["Rooms"]) ? item[segmentCode][mainKey]["Rooms"] : 0;
                } else {
                    keyTotal += (item[segmentCode][mainKey][key]) ? item[segmentCode][mainKey][key] : 0;
                }
            })
            if (key === "ADR") {
                keyTotal = revenueTotal / roomsTotal;
            }
            return keyTotal;
        }

    }

}