import moment from "moment";
import { convertToPercent, deepCopy, getAppData, getDatesInRange, getFromState, getObjectFilter, getTransformedSegments, round, setUpDate } from "../../../app/util";
import { useDataSummary } from "../../Tile/kpiService";
import dataService from "../../../services/data.service";
import { useSelector } from "react-redux";
import { transformSegmentData } from "../segmentedData";
import { getSegmentedCode } from "../processDataReports";



/*  */
export function getReportData_marketCode(columns, auth, app_state) {
    let data = deepCopy(dataService.getSeriesById("OSRSegment").data.data);
    let setup_data = [];
    let { date_from, date_until } = app_state.primary_stay_range;
    let dates = getDatesInRange(date_from, date_until);
    let segmentTotalAll = {};
    let blankObj = { Rooms: 0, Occ: 0, ADR: 0, RevPAR: 0, SellRate: 0, Revenue: 0 };
    dates.map((date, dateIndex) => {
        // let dataRecord = data.filter((item) => moment(item.index.date).format("yyyy-mm-dd") === moment(date).format("yyyy-mm-dd"));
        let dataRecord = data.filter((item) => moment(item.index.date).format("YYYY-MM-DD") === moment(date).format("YYYY-MM-DD"));
        let segmentCodes = Object.keys(dataRecord[0]);
        segmentCodes.sort().push(segmentCodes.shift());

        segmentCodes.map((segmentCode, segmentIndex) => {
            if (segmentCode !== "index") {

                if (!segmentTotalAll[segmentCode]) {
                    segmentTotalAll[segmentCode] = {
                        primary: deepCopy(blankObj),
                        sdly: deepCopy(blankObj),
                        sdly_actual: deepCopy(blankObj),
                        difference_sdly: deepCopy(blankObj)
                    };
                }

                let data_columns = [];
                let segmentName = (segmentCode && segmentCode === "HotelTotal") ? "Total" : segmentCode.replaceAll("Segment_", "");
                let segmentKey = segmentCode;
                columns.map(col => {
                    let dataObj = {};
                    dataObj.id = col.id;

                    if (col.type === 'dateColumn') {
                        dataObj = setUpDate(dataObj, date);
                        dataObj["segment"] = segmentName;
                    }
                    if (col.type === 'dataColumn') {
                        let { mainKey } = col;
                        col.subcolumns.map(key => {
                            if (mainKey === "difference_sdly") {
                                if (key.value === "Occ") {
                                    let diffVal = convertToPercent(dataRecord[0][segmentKey]["primary"][key.value] - dataRecord[0][segmentKey]["sdly"][key.value]);
                                    dataObj[key.value] = diffVal;//convertToPercent(dataRecord[0][segmentKey]["primary"][key.value] - dataRecord[0][segmentKey]["sdly"][key.value])
                                    segmentTotalAll[segmentCode][mainKey][key.value] += diffVal;
                                } else {
                                    let diffVal = round(dataRecord[0][segmentKey]["primary"][key.value] - dataRecord[0][segmentKey]["sdly"][key.value]);
                                    dataObj[key.value] = diffVal;//round(dataRecord[0][segmentKey]["primary"][key.value] - dataRecord[0][segmentKey]["sdly"][key.value])
                                    segmentTotalAll[segmentCode][mainKey][key.value] += diffVal;
                                }
                            } else {
                                if (key.value == "Occ") {
                                    dataObj[key.value] = convertToPercent(dataRecord[0][segmentKey][mainKey][key.value]);
                                    segmentTotalAll[segmentCode][mainKey][key.value] += convertToPercent(dataRecord[0][segmentKey][mainKey][key.value]);
                                } else {
                                    dataObj[key.value] = round(dataRecord[0][segmentKey][mainKey][key.value]);
                                    segmentTotalAll[segmentCode][mainKey][key.value] += dataRecord[0][segmentKey][mainKey][key.value];
                                }
                            }
                        })
                    }

                    data_columns.push(dataObj);
                })
                setup_data.push(data_columns);
            }
        })
    })
    setup_data = [...setup_data, ...getTotalAll(segmentTotalAll, columns, app_state)]
    return setup_data;
}
/* */

/* for room type  */
export function getReportData_roomType(columns, auth, app_state, ui_state) {
    let data = (dataService.getSeriesById("GetRoomType").data) ? deepCopy(dataService.getSeriesById("GetRoomType").data.data) : [];
    let setup_data = [];
    let { date_from, date_until } = app_state.primary_stay_range;
    let dates = getDatesInRange(date_from, date_until);
    let segmentTotalAll = {};
    let blankObj = { Rooms: 0, Occ: 0, ADR: 0, RevPAR: 0, SellRate: 0, Revenue: 0 };

    if (data && data.length > 0) {

        dates.map((date, dateIndex) => {
            let dataRecord = data.filter((item) => moment(item.index.date).format("YYYY-MM-DD") === moment(date).format("YYYY-MM-DD"));
            let segmentCodes = Object.keys(dataRecord[0]);
            segmentCodes.sort().push(segmentCodes.shift());
            segmentCodes.map((segmentCode, segmentIndex) => {
                if (segmentCode !== "index") {

                    if (!segmentTotalAll[segmentCode]) {
                        segmentTotalAll[segmentCode] = {
                            primary: deepCopy(blankObj),
                            sdly: deepCopy(blankObj),
                            sdly_actual: deepCopy(blankObj),
                            difference_sdly: deepCopy(blankObj),
                            variance_sdly: deepCopy(blankObj),
                            pickup_diff_sdly: deepCopy(blankObj)
                        };
                    }

                    let data_columns = [];
                    let segmentName = (segmentCode && segmentCode === "HotelTotal") ? "Total" : segmentCode.replaceAll("Segment_", "");
                    let segmentKey = segmentCode;
                    columns.map(col => {
                        let dataObj = {};
                        dataObj.id = col.id;

                        if (col.type === 'dateColumn') {
                            dataObj = setUpDate(dataObj, date);
                            dataObj["segment"] = segmentName;
                        }
                        if (col.type === 'dataColumn') {
                            let { mainKey } = col;
                            col.subcolumns.map(key => {
                                if (mainKey === "difference_sdly") {
                                    if (key.value === "Occ") {
                                        let diffVal = convertToPercent(dataRecord[0][segmentKey]["primary"][key.value] - dataRecord[0][segmentKey]["sdly"][key.value]);
                                        dataObj[key.value] = diffVal;//convertToPercent(dataRecord[0][segmentKey]["primary"][key.value] - dataRecord[0][segmentKey]["sdly"][key.value])
                                        segmentTotalAll[segmentCode][mainKey][key.value] += diffVal;
                                    } else {
                                        let diffVal = round(dataRecord[0][segmentKey]["primary"][key.value] - dataRecord[0][segmentKey]["sdly"][key.value]);
                                        dataObj[key.value] = diffVal;//round(dataRecord[0][segmentKey]["primary"][key.value] - dataRecord[0][segmentKey]["sdly"][key.value])
                                        segmentTotalAll[segmentCode][mainKey][key.value] += diffVal;
                                    }
                                } else if (mainKey === "variance_sdly") {
                                    let variance_diff = convertToPercent((dataRecord[0][segmentKey]["primary"][key.value] - dataRecord[0][segmentKey]["sdly"][key.value]) / dataRecord[0][segmentKey]["sdly"][key.value]);
                                    variance_diff = (variance_diff && isFinite(variance_diff)) ? variance_diff : 0;
                                    dataObj[key.value] = variance_diff + "%";
                                    segmentTotalAll[segmentCode][mainKey][key.value] += variance_diff;

                                } else if (mainKey === "pickup_diff_sdly") {
                                    let pickup_diff = round(dataRecord[0][segmentKey]["sdly_actual"][key.value] - dataRecord[0][segmentKey]["sdly"][key.value]);
                                    dataObj[key.value] = pickup_diff;
                                    segmentTotalAll[segmentCode][mainKey][key.value] += pickup_diff; //round(dataRecord[0][segmentKey]["sdly_actual"][key.value] - dataRecord[0][segmentKey]["sdly"][key.value]);

                                } else {
                                    if (key.value == "Occ") {
                                        dataObj[key.value] = convertToPercent(dataRecord[0][segmentKey][mainKey][key.value]);
                                        segmentTotalAll[segmentCode][mainKey][key.value] += convertToPercent(dataRecord[0][segmentKey][mainKey][key.value]);
                                    } else {
                                        dataObj[key.value] = round(dataRecord[0][segmentKey][mainKey][key.value]);
                                        segmentTotalAll[segmentCode][mainKey][key.value] += dataRecord[0][segmentKey][mainKey][key.value];
                                    }
                                }
                            })
                        }

                        data_columns.push(dataObj);
                    })
                    setup_data.push(data_columns);
                }
            })
        })
        let segFinalTotal = getTotalAll(segmentTotalAll, columns, app_state);
        if (app_state.workspace_controls.sdlyOn) {
            segFinalTotal.map((item, index) => {
                let keysArr = Object.keys(segFinalTotal[index][5])
                keysArr.map((itm, idx) => {
                    if (itm !== "id") {
                        segFinalTotal[index][5][itm] = (isFinite(segFinalTotal[index][5][itm])) ? segFinalTotal[index][5][itm] + "%" : 0 + "%";
                    }
                })
            })
        }

        setup_data = [...setup_data, ...segFinalTotal]
    }
    return setup_data;
}
/* */




/* for room class  */
export function getReportData_roomClass(columns, auth, app_state, ui_state) {
    let data = deepCopy(dataService.getSeriesById("GetRoomType").data.data);
    let setup_data = [];
    let { date_from, date_until } = app_state.primary_stay_range;
    let dates = getDatesInRange(date_from, date_until);
    let segmentTotalAll = {};
    let blankObj = { Rooms: 0, Occ: 0, ADR: 0, RevPAR: 0, SellRate: 0, Revenue: 0 };
    dates.map((date, dateIndex) => {
        let dataRecord = data.filter((item) => moment(item.index.date).format("YYYY-MM-DD") === moment(date).format("YYYY-MM-DD"));
        let segmentCodes = Object.keys(dataRecord[0]);
        segmentCodes.sort().push(segmentCodes.shift());
        segmentCodes.map((segmentCode, segmentIndex) => {
            if (segmentCode !== "index") {

                if (!segmentTotalAll[segmentCode]) {
                    segmentTotalAll[segmentCode] = {
                        primary: deepCopy(blankObj),
                        sdly: deepCopy(blankObj),
                        sdly_actual: deepCopy(blankObj),
                        difference_sdly: deepCopy(blankObj),
                        variance_sdly: deepCopy(blankObj),
                        pickup_diff_sdly: deepCopy(blankObj)
                    };
                }

                let data_columns = [];
                let segmentName = (segmentCode && segmentCode === "HotelTotal") ? "Total" : segmentCode.replaceAll("Segment_", "");
                let segmentKey = segmentCode;
                columns.map(col => {
                    let dataObj = {};
                    dataObj.id = col.id;

                    if (col.type === 'dateColumn') {
                        dataObj = setUpDate(dataObj, date);
                        dataObj["segment"] = segmentName;
                    }
                    if (col.type === 'dataColumn') {
                        let { mainKey } = col;
                        col.subcolumns.map(key => {
                            if (mainKey === "difference_sdly") {
                                if (key.value === "Occ") {
                                    let diffVal = convertToPercent(dataRecord[0][segmentKey]["primary"][key.value] - dataRecord[0][segmentKey]["sdly"][key.value]);
                                    dataObj[key.value] = diffVal;//convertToPercent(dataRecord[0][segmentKey]["primary"][key.value] - dataRecord[0][segmentKey]["sdly"][key.value])
                                    segmentTotalAll[segmentCode][mainKey][key.value] += diffVal;
                                } else {
                                    let diffVal = round(dataRecord[0][segmentKey]["primary"][key.value] - dataRecord[0][segmentKey]["sdly"][key.value]);
                                    dataObj[key.value] = diffVal;//round(dataRecord[0][segmentKey]["primary"][key.value] - dataRecord[0][segmentKey]["sdly"][key.value])
                                    segmentTotalAll[segmentCode][mainKey][key.value] += diffVal;
                                }
                            } else if (mainKey === "variance_sdly") {
                                let variance_diff = convertToPercent((dataRecord[0][segmentKey]["primary"][key.value] - dataRecord[0][segmentKey]["sdly"][key.value]) / dataRecord[0][segmentKey]["sdly"][key.value]);
                                dataObj[key.value] = variance_diff + "%";
                                segmentTotalAll[segmentCode][mainKey][key.value] += variance_diff;

                            } else if (mainKey === "pickup_diff_sdly") {
                                let pickup_diff = round(dataRecord[0][segmentKey]["sdly_actual"][key.value] - dataRecord[0][segmentKey]["sdly"][key.value]);
                                dataObj[key.value] = pickup_diff;
                                segmentTotalAll[segmentCode][mainKey][key.value] += pickup_diff; //round(dataRecord[0][segmentKey]["sdly_actual"][key.value] - dataRecord[0][segmentKey]["sdly"][key.value]);

                            } else {
                                if (key.value == "Occ") {
                                    dataObj[key.value] = convertToPercent(dataRecord[0][segmentKey][mainKey][key.value]);
                                    segmentTotalAll[segmentCode][mainKey][key.value] += convertToPercent(dataRecord[0][segmentKey][mainKey][key.value]);
                                } else {
                                    dataObj[key.value] = round(dataRecord[0][segmentKey][mainKey][key.value]);
                                    segmentTotalAll[segmentCode][mainKey][key.value] += dataRecord[0][segmentKey][mainKey][key.value];
                                }
                            }
                        })
                    }

                    data_columns.push(dataObj);
                })
                setup_data.push(data_columns);
            }
        })
    })
    let segFinalTotal = getTotalAll(segmentTotalAll, columns, app_state);
    if (app_state.workspace_controls.sdlyOn) {
        segFinalTotal.map((item, index) => {
            let keysArr = Object.keys(segFinalTotal[index][5])
            keysArr.map((itm, idx) => {
                if (itm !== "id") {
                    segFinalTotal[index][5][itm] = (isFinite(segFinalTotal[index][5][itm])) ? segFinalTotal[index][5][itm] + "%" : 0 + "%";
                }
            })
        })
    }
    setup_data = [...setup_data, ...segFinalTotal]
    return setup_data;
}
/* */



/* for rate plan  */
export function getReportData_ratePlan(columns, auth, app_state, ui_state) {
    // let data = deepCopy(dataService.getSeriesById("GetRatePlan").data.data);
    let data = (dataService.getSeriesById("GetRatePlanNoMissingDays").data) ? deepCopy(dataService.getSeriesById("GetRatePlanNoMissingDays").data.data) : [];
    let setup_data = [];
    let { date_from, date_until } = app_state.primary_stay_range;
    let dates = getDatesInRange(date_from, date_until);
    let segmentTotalAll = {};
    let blankObj = { Rooms: 0, Occ: 0, ADR: 0, RevPAR: 0, SellRate: 0, Revenue: 0 };
    dates.map((date, dateIndex) => {
        let dataRecord = data.filter((item) => moment(item.index.date).format("YYYY-MM-DD") === moment(date).format("YYYY-MM-DD"));
        let recordArr = dataRecord[0];
        if (dataRecord && dataRecord.length <= 0) {
            let testObj = {
                index: { date: date },
                "Segment_blank": {
                    primary: deepCopy(blankObj),
                    sdly: deepCopy(blankObj),
                    sdly_actual: deepCopy(blankObj),
                    difference_sdly: deepCopy(blankObj),
                    variance_sdly: deepCopy(blankObj),
                    pickup_diff_sdly: deepCopy(blankObj)
                }
            }
            dataRecord[0] = testObj;
        }


        let segmentCodes = Object.keys(dataRecord[0]);
        segmentCodes.sort().push(segmentCodes.shift());
        segmentCodes.map((segmentCode, segmentIndex) => {
            if (segmentCode !== "index") {

                if (!segmentTotalAll[segmentCode]) {
                    segmentTotalAll[segmentCode] = {
                        primary: deepCopy(blankObj),
                        sdly: deepCopy(blankObj),
                        sdly_actual: deepCopy(blankObj),
                        difference_sdly: deepCopy(blankObj),
                        variance_sdly: deepCopy(blankObj),
                        pickup_diff_sdly: deepCopy(blankObj)
                    };
                }

                let data_columns = [];
                // getCodeDetails(segmentCode);
                let segmentName = (segmentCode && segmentCode === "HotelTotal") ? "Total" : segmentCode.replaceAll("Segment_", "");
                // let segmentName = (segmentCode && segmentCode === "HotelTotal") ? "Total" : getCodeDetails(segmentCode,app_state);
                let segmentKey = segmentCode;
                columns.map(col => {
                    let dataObj = {};
                    dataObj.id = col.id;

                    if (col.type === 'dateColumn') {
                        dataObj = setUpDate(dataObj, date);
                        dataObj["segment"] = (segmentName && segmentName !== "blank") ? segmentName : "No Data";
                    }
                    if (col.type === 'dataColumn') {
                        let { mainKey } = col;
                        col.subcolumns.map(key => {
                            if (mainKey === "difference_sdly") {
                                if (key.value === "Occ") {
                                    let diffVal = convertToPercent(dataRecord[0][segmentKey]["primary"][key.value] - dataRecord[0][segmentKey]["sdly"][key.value]);
                                    dataObj[key.value] = diffVal;//convertToPercent(dataRecord[0][segmentKey]["primary"][key.value] - dataRecord[0][segmentKey]["sdly"][key.value])
                                    segmentTotalAll[segmentCode][mainKey][key.value] += diffVal;
                                } else {
                                    let diffVal = round(dataRecord[0][segmentKey]["primary"][key.value] - dataRecord[0][segmentKey]["sdly"][key.value]);
                                    dataObj[key.value] = diffVal;//round(dataRecord[0][segmentKey]["primary"][key.value] - dataRecord[0][segmentKey]["sdly"][key.value])
                                    segmentTotalAll[segmentCode][mainKey][key.value] += diffVal;
                                }
                            } else if (mainKey === "variance_sdly") {
                                let variance_diff = convertToPercent((dataRecord[0][segmentKey]["primary"][key.value] - dataRecord[0][segmentKey]["sdly"][key.value]) / dataRecord[0][segmentKey]["sdly"][key.value]);
                                let variance_value = (variance_diff && isFinite(variance_diff)) ? (variance_diff * 100) : 0.00;
                                dataObj[key.value] = variance_value + "%";
                                segmentTotalAll[segmentCode][mainKey][key.value] += variance_value;

                            } else if (mainKey === "pickup_diff_sdly") {
                                let pickup_diff = round(dataRecord[0][segmentKey]["sdly_actual"][key.value] - dataRecord[0][segmentKey]["sdly"][key.value]);
                                dataObj[key.value] = pickup_diff;
                                segmentTotalAll[segmentCode][mainKey][key.value] += pickup_diff; //round(dataRecord[0][segmentKey]["sdly_actual"][key.value] - dataRecord[0][segmentKey]["sdly"][key.value]);

                            } else {
                                if (key.value == "Occ") {
                                    dataObj[key.value] = convertToPercent(dataRecord[0][segmentKey][mainKey][key.value]);
                                    segmentTotalAll[segmentCode][mainKey][key.value] += convertToPercent(dataRecord[0][segmentKey][mainKey][key.value]);
                                } else {
                                    dataObj[key.value] = round(dataRecord[0][segmentKey][mainKey][key.value]);
                                    segmentTotalAll[segmentCode][mainKey][key.value] += dataRecord[0][segmentKey][mainKey][key.value];
                                }
                            }
                        })
                    }

                    data_columns.push(dataObj);
                })
                setup_data.push(data_columns);
            }
        })
    })
    let segFinalTotal = getTotalAll(segmentTotalAll, columns, app_state);
    if (app_state.workspace_controls.sdlyOn) {
        segFinalTotal.map((item, index) => {
            let keysArr = Object.keys(segFinalTotal[index][5])
            keysArr.map((itm, idx) => {
                if (itm !== "id") {
                    segFinalTotal[index][5][itm] = (isFinite(segFinalTotal[index][5][itm])) ? segFinalTotal[index][5][itm] + "%" : 0 + "%";
                }
            })
        })
    }
    setup_data = [...setup_data, ...segFinalTotal]
    return setup_data;
}
/* */




const getTotalAllSegment = (key, dataObj, app_state) => {
    var date1 = moment(app_state.primary_stay_range.date_from);
    var date2 = moment(app_state.primary_stay_range.date_until);
    var daysCount = date2.diff(date1, 'days') + 1;
    let finalValue = 0;
    let value = dataObj[key];
    if (key === "Occ") {
        finalValue = value / daysCount;
    } else if (key === "ADR") {
        finalValue = dataObj["Revenue"] / dataObj["Rooms"];
    } else if (key === "RevPAR") {
        finalValue = value / daysCount;
    } else {
        finalValue = value;
    }
    return finalValue;
}


function getTotalAll(segmentTotalAll, columns, app_state) {
    let setup_data = [];
    let segmentCodes = Object.keys(segmentTotalAll);
    segmentCodes.sort().push(segmentCodes.shift());

    segmentCodes.map((segmentCode, segmentIndex) => {
        // console.log("segmentCodesegmentCode ",segmentCode);
        if (segmentCode !== "HotelTotal" && segmentCode !== "Segment_blank") {
            let data_columns = [];
            let segmentName = (segmentCode && segmentCode === "HotelTotal") ? "Total" : segmentCode.replaceAll("Segment_", "");

            // let segmentName = (segmentCode && segmentCode === "HotelTotal") ? "Total" : getCodeDetails(segmentCode,app_state);
            let segmentKey = segmentCode;
            columns.map(col => {
                let dataObj = {};
                dataObj.id = col.id;

                if (col.type === 'dateColumn') {
                    dataObj = setUpDate(dataObj, "");
                    dataObj["segment"] = segmentName;
                }
                if (col.type === 'dataColumn') {
                    let { mainKey } = col;
                    col.subcolumns.map(key => {
                        console.log();
                        if (mainKey === "difference_sdly") {
                            if (key.value === "ADR") {
                                let diffADR = getTotalAllSegment("ADR", segmentTotalAll[segmentKey]["primary"], app_state) - getTotalAllSegment("ADR", segmentTotalAll[segmentKey]["sdly"], app_state);
                                dataObj[key.value] = diffADR; //segmentTotalAll[segmentKey]["primary"][key.value] - segmentTotalAll[segmentKey]["sdly"][key.value];
                            } else {
                                dataObj[key.value] = getTotalAllSegment(key.value, segmentTotalAll[segmentKey][mainKey], app_state);
                            }
                        } else {
                            dataObj[key.value] = getTotalAllSegment(key.value, segmentTotalAll[segmentKey][mainKey], app_state);

                        }
                    })
                }
                data_columns.push(dataObj);
            })
            setup_data.push(data_columns);
        }
    })
    return setup_data;
}




