import { useDispatch, useSelector } from 'react-redux';
import { updateAppState } from '../../../../actions/appstate';
import { Box, Select, MenuItem, FormControl, Switch } from '@mui/material'
import { useEffect, useState } from 'react';
import { deepCopy } from '../../../../app/util';
import dataService from '../../../../services/data.service';
import moment from 'moment';


const SdYears = () => {
    const controls = useSelector(state => state.app.current_state.app_state.workspace_controls);
    const { app_state, ui_state } = useSelector(
        (state) => state.app.current_state
    );
    const [sdYear, setSdYear] = useState(controls.sdYear);
    let comObj = { default_ly: controls.sdYear, sdYearsArr: controls.sdYearsArr, rt_years: controls.rt_years, rp_years: controls.rp_years };
    const [comState, setComState] = useState(comObj);

    const dispatch = useDispatch();

    const handleChange = (e) => {
        let value = e.target.value;
        setSdYear(value);
        let type = "sdyear";
        const updateTypes = [type];
        controls.sdYear = value;
        dispatch(
            updateAppState(
                updateTypes, {
                app_state: { workspace_controls: controls, data_load: true },
                ui_state: { showspinner: true }
            }));
    }


    let yearsList = controls.sdYearsArr;
    let segmentType = app_state.workspace_controls.segmentType;

    if (ui_state.active_tiles[0] === "otb_v2_multi_segment" && segmentType === "rate_plan") {
        yearsList = controls.rp_years;
    } else if (ui_state.active_tiles[0] === "otb_v2_multi_segment" && segmentType === "room_type") {
        yearsList = controls.rt_years;
    } else {
        yearsList = controls.sdYearsArr;
    }

    return (
        <>
            <Box id="sdyearBox" sx={{ height: 1 / 2 }}>
                <Box sx={{ display: 'block', fontSize: '75%', fontWeight: 'bold' }}>SD - Years</Box>
                <FormControl size="small" sx={{ minWidth: "100%", borderRadius: "16px" }}>
                    <Select displayEmpty className='tabular-data-filter' value={controls.sdYear} sx={{ height: "27px", minWidth: "70px", mt: "5px" }}
                        onChange={handleChange}>

                        {/* <MenuItem value="" disabled className='tabular-data-filter'>Select Year </MenuItem> */}
                        {yearsList && yearsList.map((items, index) => {
                            if (items <= moment(app_state.as_of_date).subtract(1,'years').format('YYYY')) {
                                return (<MenuItem value={items} className='tabular-data-filter'> {items} </MenuItem>)
                            }
                        })}

                    </Select>

                </FormControl>
            </Box>

        </>
    )
}

export default SdYears