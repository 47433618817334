// Firebase Cloud Messaging Configuration File.
// Read more at https://firebase.google.com/docs/cloud-messaging/js/client && https://firebase.google.com/docs/cloud-messaging/js/receive
import { useNavigate } from "react-router-dom";
import { initializeApp } from 'firebase/app';
import { getMessaging, getToken, onMessage } from 'firebase/messaging';
import { API_REGISTRY } from "../app/config";
import DataService from "../services/data.service";

const firebaseConfig = {
    apiKey: process.env.REACT_APP_API_KEY,
    authDomain: process.env.REACT_APP_AUTH_DOMAIN,
    projectId: process.env.REACT_APP_PROJECT_ID,
    storageBucket: process.env.REACT_APP_STORAGE_BUCKET,
    messagingSenderId: process.env.REACT_APP_MESSAGING_SENDER_ID,
    appId: process.env.REACT_APP_APP_ID,
    measurementId: process.env.REACT_APP_MEASURMENT_ID
}; 

initializeApp(firebaseConfig);

const messaging = getMessaging();

export const requestForToken = async () => {
    return getToken(messaging, { vapidKey: process.env.REACT_APP_VAP_ID_KEY })
        .then((currentToken) => {
            if (currentToken) {
                console.log('current token for client: ', currentToken);
                // Perform any other neccessary action with the token
                let sendFBToken = API_REGISTRY['UsersFBToken'];
                DataService.getData({ api: sendFBToken.uri, request: { token: currentToken } })
                    .then((rs) => {
                        // debugger;
                        console.log('DataService.getData ', currentToken);
                        if (rs.status === 'rejected' && rs.reason && rs.status === 403) {
                            //navigate("/login");
                        }
                    });
            } else {
                // Show permission request UI
                console.warn('No registration token available. Request permission to generate one.');
            }
        })
        .catch((err) => {
            console.error('An error occurred while retrieving token. ', err);
        });
};

// Handle incoming messages. Called when:
// - a message is received while the app has focus
// - the user clicks on an app notification created by a service worker `messaging.onBackgroundMessage` handler.
export const onMessageListener = () =>
    new Promise((resolve) => {
        onMessage(messaging, (payload) => {
            resolve(payload);
        });
    });
