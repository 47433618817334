import moment from "moment";
import { convertToPercent, deepCopy, getCorrectValue, round, setUpDate } from "../../../app/util";
import { useDataSummary } from "../../Tile/kpiService";
import dataService from "../../../services/data.service";
import { useSelector } from "react-redux";

export function getPickupData(data, columns, app_state) {
    let dataKey = "HotelTotal";
    let setup_data = [];
    data.map((item, index) => {
        let data_columns = [];
        columns.map(col => {
            let dataObj = { id: col.id };
            if (col.type === 'dateColumn') {

                dataObj = setUpDate(dataObj, item.index.date);
                if (app_state.workspace_controls.segmented_view) {
                    dataObj["segment"] = item.index.segment;
                }
            }
            if (col.type === 'dataColumn') {
                let dataMainKey = (app_state.workspace_controls.pickUpType === 'Standard') ? "current" : "primary";

                let { mainKey } = col;
                col.subcolumns.map(key => {
                    let value = key.value;
                    if (mainKey === "otb_sdly_diff") {
                        if (value === "Occ") {
                            dataObj[value] = convertToPercent(item[dataKey]["sdly"][value] - item[dataKey]["sdly_pickup"][value])
                        } else if (value == "ADR") {
                            dataObj[value] = round((item[dataKey]["sdly"]["Revenue"] - item[dataKey]["sdly_pickup"]["Revenue"]) / (item[dataKey]["sdly"]["Rooms"] - item[dataKey]["sdly_pickup"]["Rooms"]))
                        } else {
                            dataObj[value] = round(item[dataKey]["sdly"][value] - item[dataKey]["sdly_pickup"][value])
                        }
                    } else if (mainKey === "otb_difference") {
                        let key2 = (app_state.workspace_controls.pickUpType === 'Standard') ? "prior" : "pickup";

                        let adrRev = item[dataKey][dataMainKey]['Revenue'] - item[dataKey][key2]['Revenue'];
                        let adrRooms = item[dataKey][dataMainKey]['Rooms'] - item[dataKey][key2]['Rooms'];
                        if (value === "Occ") {
                            dataObj[value] = convertToPercent(item[dataKey][dataMainKey][value] - item[dataKey][key2][value])
                        } else if (value == "ADR") {
                            // dataObj[value] = round(item[dataKey][dataMainKey]["Revenue"] - item[dataKey]["prior"]["Rooms"])
                            let adrValue = round(adrRev / adrRooms);
                            dataObj[value] = (isNaN(adrValue)) ? 0 : round(parseFloat(adrRev / adrRooms))
                        } else {
                            dataObj[value] = round(item[dataKey][dataMainKey][value] - item[dataKey][key2][value])
                        }
                    } else if (mainKey === "otb_pickup") {
                        dataMainKey = (app_state.workspace_controls.pickUpType === 'Standard') ? "prior" : "pickup";
                        if (value === "Occ") {
                            dataObj[value] = convertToPercent(item[dataKey][dataMainKey][value]);
                        } else {
                            dataObj[value] = round(item[dataKey][dataMainKey][value]);
                        }
                    } else if (mainKey === "sdly_pickup") {
                        dataMainKey = mainKey;
                        if (value === "Occ") {
                            dataObj[value] = convertToPercent(item[dataKey][dataMainKey][value]);
                        } else {
                            dataObj[value] = round(item[dataKey][dataMainKey][value]);
                        }
                    } else if (mainKey === "sdly") {
                        dataMainKey = mainKey;
                        if (value === "Occ") {
                            dataObj[value] = convertToPercent(item[dataKey][dataMainKey][value]);
                        } else {
                            dataObj[value] = round(item[dataKey][dataMainKey][value]);
                        }
                    }
                    else if (item[dataKey][mainKey]) {

                        if (value === "Occ") {
                            dataObj[value] = convertToPercent(item[dataKey][dataMainKey][value]);
                        } else {
                            dataObj[value] = round(item[dataKey][dataMainKey][value]);
                        }
                    }
                })
            }
            data_columns.push(dataObj);
        })
        setup_data.push(data_columns);
    })
    return setup_data;
}


// get PickData for intellimerge 
export const getPickupDataInt = (mainKey, id, tileName, key, index, dataKey, auth, app_state, tileData) => {
    let otbPickupData = deepCopy(tileData); //deepCopy(dataService.getSeriesById("OSR").data.data);
    let dataMainKey = (app_state.workspace_controls.pickUpType === 'Standard') ? "current" : "primary";
    if (mainKey === "otb_pickup") {
        dataMainKey = (app_state.workspace_controls.pickUpType === 'Standard') ? "prior" : "pickup";
    }
    let dataObj = {};
    let value = key.value;
    let otbPickupDatakey = otbPickupData[dataKey];
    let pickupDataMain = otbPickupDatakey[dataMainKey];
    // let pickupPrimary = otbPickupDatakey["primary"];
    let pickupSdly = otbPickupDatakey["sdly"];
    let pickupSdlyPickup = otbPickupDatakey["sdly_pickup"];
    // debugger; 
    if (otbPickupData) {
        if (mainKey === "otb_sdly_diff") {
            if (value === "Occ") {
                dataObj[value] = convertToPercent(pickupSdly[value] - pickupSdlyPickup[value])
            } else if (value == "ADR") {
                dataObj[value] = round((pickupSdly["Revenue"] - pickupSdlyPickup["Revenue"]) / (pickupSdly["Rooms"] - pickupSdlyPickup["Rooms"]))
            } else {
                dataObj[value] = round(pickupSdly[value] - pickupSdlyPickup[value])
            }
        } else if (mainKey === "otb_difference") {
            let key2 = (app_state.workspace_controls.pickUpType === 'Standard') ? "prior" : "pickup";
            let adrRev = pickupDataMain['Revenue'] - otbPickupDatakey[key2]['Revenue'];
            let adrRooms = pickupDataMain['Rooms'] - otbPickupDatakey[key2]['Rooms'];
            if (value === "Occ") {
                dataObj[value] = convertToPercent(pickupDataMain[value] - otbPickupDatakey[key2][value])
            } else if (value == "ADR") {
                let adrValue = round(adrRev / adrRooms);
                dataObj[value] = (isNaN(adrValue)) ? 0 : round(parseFloat(adrRev / adrRooms))
            } else {
                dataObj[value] = round(pickupDataMain[value] - otbPickupDatakey[key2][value])
            }
        } else if (mainKey === "otb_pickup") {
            // dataMainKey = (app_state.workspace_controls.pickUpType === 'Standard') ? "prior" : "pickup";
            if (value === "Occ") {
                dataObj[value] = convertToPercent(pickupDataMain[value]);
            } else {
                dataObj[value] = round(pickupDataMain[value]);
            }
        } else if (mainKey === "sdly_pickup") {
            dataMainKey = mainKey;
            if (value === "Occ") {
                dataObj[value] = convertToPercent(pickupSdlyPickup[value]);
            } else {
                dataObj[value] = round(pickupSdlyPickup[value]);
            }
        } else if (mainKey === "sdly") {
            dataMainKey = mainKey;
            if (value === "Occ") {
                dataObj[value] = convertToPercent(pickupSdly[value]);
            } else {
                dataObj[value] = round(pickupSdly[value]);
            }
        }
        else if (otbPickupData && tileName === "pickup") {

            if (value === "Occ") {
                dataObj[value] = convertToPercent(pickupDataMain[value]);
            } else {
                dataObj[value] = round(pickupDataMain[value]);
            }
        }
    }
    return dataObj;

}





export const getPickupDataInt_segment = (mainKey, id, tileName, key, index, dataKey, auth, app_state, pickupData) => {

    // console.log("pickupData => ",pickupData);
    // debugger;
    // let otbPickupData = deepCopy(dataService.getSeriesById("OSR").data.data);
    let {pickUpType} = app_state.workspace_controls;
    let value = key.value;
    let dataMainKey = (pickUpType === 'Standard') ? "current" : "primary";
    
    let dataObj = {};
    let data = pickupData[dataKey] ? pickupData[dataKey] : undefined;
    
    if (mainKey === "otb_sdly_diff" && data) {
        let dataSdly = data["sdly"];
        let dataSdlyPickup = data["sdly_pickup"];
        if (value === "Occ") {
            dataObj[value] = convertToPercent(dataSdly[value] - dataSdlyPickup[value])
        } else if (value == "ADR") {
            dataObj[value] = round((dataSdly["Revenue"] - dataSdlyPickup["Revenue"]) / (dataSdly["Rooms"] - dataSdlyPickup["Rooms"]))
        } else {
            dataObj[value] = round(dataSdly[value] - dataSdlyPickup[value])
        }
    } else if (mainKey === "otb_difference" && data) {
            let dataMain = data[dataMainKey];
            let key2 = (pickUpType === 'Standard') ? "prior" : "pickup";
            let dataKey2 = data[key2];
            let adrRev = dataMain['Revenue'] - dataKey2['Revenue'];
            let adrRooms = dataMain['Rooms'] - dataKey2['Rooms'];
            if (value === "Occ") {
                dataObj[value] = convertToPercent(getCorrectValue(dataMain[value]) - getCorrectValue(dataKey2[value]))
            } else if (value == "ADR") {
                let adrValue = round(adrRev / adrRooms);
                dataObj[value] = (isNaN(adrValue)) ? 0 : round(parseFloat(adrRev / adrRooms))
            } else if (value === "Rooms") {
                dataObj[value] = getCorrectValue(dataMain[value]) - getCorrectValue(dataKey2[value]);
            } else {
                dataObj[value] = round(dataMain[value] - dataKey2[value])
            }

    } else if (mainKey === "otb_pickup" && data) {
        dataMainKey = (pickUpType === 'Standard') ? "prior" : "pickup";
        let mainKeyData = data[dataMainKey];
        if (value === "Occ") {
            dataObj[value] = convertToPercent(mainKeyData[value]);
        } else {
            dataObj[value] = round(mainKeyData[value]);
        }
    } else if (mainKey === "sdly_pickup" && data) {
        dataMainKey = mainKey;
        let mainKeyData = data[dataMainKey];
        if (value === "Occ") {
            dataObj[value] = convertToPercent(mainKeyData[value]);
        } else {
            dataObj[value] = round(mainKeyData[value]);
        }
    } else if (mainKey === "sdly" && data) {
        dataMainKey = mainKey;
        let mainKeyData = data[dataMainKey];
        if (value === "Occ") {
            dataObj[value] = convertToPercent(mainKeyData[value]);
        } else {
            dataObj[value] = round(mainKeyData[value]);
        }
    }
    else if (pickupData && tileName === "pickup") {
        if (data) {
            let mainKeyData = data[dataMainKey];
            if (value === "Occ") {
                dataObj[value] = convertToPercent(mainKeyData[value]);
            } else {
                dataObj[value] = round(mainKeyData[value]);
            }
        }
    }

    return dataObj;

}