import moment from "moment";

export function settingsReducers(state, actions) {
  const type = actions.type;
  switch (type) {
    case "onRequest": {
      state.ui.request.status = "loading";
      state.ui.request.message = "loading";
      return { ...state };
    }
    case "request": {
      //let ui = {request:actions};
      //debugger;
      return { ...state };
    }
    case "setExpanded": {
      state.ui.expanded[actions.id] = !state.ui.expanded[actions.id]
        ? true
        : !state.ui.expanded[actions.id];
      return { ...state };
    }
    case "create": {
      //IF DON'T HAVE ANY DATA ITEM TO DISPLAY, THIS WILL RUN TO CREATE
      //debugger;
      let len = state.data.list.length;
      state.ui.expanded["id_" + len] = true;
      state.ui.mode["id_" + len] = "edit";
      state.ui.dataChanged["id_" + len] = false;
      state.data.list.push(actions.itemToBeAdded);
      state.dataOriginal.list.push(actions.itemToBeAdded);
      return { ...state };
    }
    case "onResponse": {
      //IF THERE IS API DATA DEPENDENCY, THIS BLOCK WILL RUN AFTER DATA FETCH
      state.data.list = actions.list;
      state.dataOriginal = JSON.parse(JSON.stringify(state.data));
      state.data.list.map((val, index) => {
        state.data.list[index].skip_dates = state.data.list[index].skip_dates
          ? state.data.list[index].skip_dates
          : [];

        state.ui.expanded["id_" + index] = state.ui.expanded["id_" + index]
          ? state.ui.expanded["id_" + index]
          : false;
        state.ui.mode["id_" + index] = state.ui.mode["id_" + index]
          ? state.ui.mode["id_" + index]
          : "edit";
        state.ui.dataChanged["id_" + index] = state.ui.dataChanged[
          "id_" + index
        ]
          ? state.ui.dataChanged["id_" + index]
          : false;
      });
      return { ...state };
    }
    case "onDataSuccess": {
      //IF THERE IS API DATA DEPENDENCY, THIS BLOCK WILL RUN AFTER DATA FETCH
      const start = new moment().startOf("day").format("YYYY-MM-DDThh:mm:ss");
      const end = new moment()
        .add(1, "month")
        .startOf("day")
        .format("YYYY-MM-DDThh:mm:ss");
      state.ui.request.status = "loaded";
      state.ui.request.resType = "success";
      state.ui.request.message = "Data has been loaded!!";
      state.data.list = actions.list;
      state.dataOriginal = JSON.parse(JSON.stringify(state.data));
      state.data.list.map((val, index) => {
        const id = "id_" + index;
        state.data.list[index].approval_range.start = state.data.list[index]
          .approval_range.start
          ? state.data.list[index].approval_range.start
          : start;
        state.data.list[index].approval_range.end = state.data.list[index]
          .approval_range.end
          ? state.data.list[index].approval_range.end
          : end;
        state.data.list[index].skip_dates = state.data.list[index].skip_dates
          ? state.data.list[index].skip_dates
          : [];

        state.ui.expanded[id] = state.ui.expanded[id]
          ? state.ui.expanded[id]
          : false;
        state.ui.mode[id] = state.ui.mode[id] ? state.ui.mode[id] : "edit";
        state.ui.dataChanged[id] = state.ui.dataChanged[id]
          ? state.ui.dataChanged[id]
          : false;
      });
      return { ...state };
    }
    case "onHideAlert": {
      state.ui.request.resType = null;
      state.ui.request.status = "idle";
      state.ui.request.message = "";
      return { ...state };
    }
    case "onResponseError": {
      state.ui.request.status = "idle";
      state.ui.request.resType = "error";
      state.ui.request.message = "Oops! Something went wrong.";
      state.data.list = [];
      return { ...state };
    }
    case "onDataSaved": {
      state.ui.request.resType = "success";
      state.ui.request.status = "loaded";
      state.ui.request.message = "Rate Push Schedule Added Successfully!";
      state.ui.dataChanged["id_" + actions.index] = false;
      state.data.list[actions.index] = actions.saved;
      state.dataOriginal.list[actions.index] = actions.saved;
      return { ...state };
    }
    case "updateData": {
      //THIS WILL RUN WHEN USER MAKE CHANGES
      //debugger;
      return { ...state };
    }
    case "updateUi": {
      //THIS WILL RUN WHEN USER MAKE CHANGES
      if (!state.ui[actions.key][actions.id]) {
        //state.ui[actions.key][actions.id] = !state.ui[actions.key][actions.id];
      }
      //state.ui[actions.key][actions.id] = actions.value;
      return { ...state };
    }
    case "onCancel": {
      const original = JSON.parse(JSON.stringify(state.dataOriginal));
      state.data.list[actions.index] = original.list[actions.index];
      return { ...state };
    }
    case "onSave": {
      //THIS WILL RUN WHEN DATA IS SAVED IN DB AFTER API CALL
      return { ...state };
    }
    case "onDelete": {
      state.ui.request.resType = "success";
      state.ui.request.status = "loaded";
      state.ui.request.message = "Delete request was successfully!";
      const id = "id_" + actions.index;
      state.data.list.splice(actions.index, 1);
      state.dataOriginal.list.splice(actions.index, 1);
      delete state.ui.dataChanged[id];
      delete state.ui.expanded[id];
      delete state.ui.mode[id];
      return { ...state };
    }
    default: {
      throw Error("Unknown action in scheduleRatePushReducer");
    }
  }
}
