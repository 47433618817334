import React, { useEffect, useRef, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { createSelector } from 'reselect'
import EditIcon from '@mui/icons-material/Edit';
import {
  ArrowForwardIosOutlined as ArrowForwardIosOutlinedIcon
}
  from '@mui/icons-material/';
import { UPDATE_STATE } from '../../actions/types'
import { updateAppState } from "../../actions/appstate";
import CameraAltOutlinedIcon from '@mui/icons-material/CameraAltOutlined';
import {
  Box
  , Grid
  , Paper
  , Chip
  , Avatar
  , Button
  , TextField
  , CircularProgress
  , Alert
  , FormControl
  , Radio
  , RadioGroup
  , FormControlLabel
  , Checkbox
  , label
  , Accordion
  , AccordionDetails
  , AccordionSummary
  , Typography
  , List
  , ListSubheader
  , ListItem
  , ListItemButton
  , Divider
  , ListItemIcon
  , ListItemText
  , styles as styled,
  Badge,
  Select,
  MenuItem,
  Tooltip,
  Input,
}
  from '@mui/material/';
import './style.css';
import dataService from '../../services/data.service';
import { deepCopy } from '@firebase/util';


function ProfileSettings(props) {
  const dispatch = useDispatch();
  const { app, auth } = useSelector(state => state);
  const { app_state, ui_state } = app.current_state;
  const { user, profile } = auth;
  const properties = profile.property_details;
  const label = { inputProps: { 'aria-label': 'Checkbox demo' } };
  const [compState, setCompState] = useState({
    user_info: {},
    user_info_: {},
    time_zones: [],
    skip_keys: [],
    editing: '',
    delta: {},
    isProfileChanged: false,
    msgTimeout: 4000,
  });
  // debugger;
  const updateCompState = (key, value) => {
    let keys = key ? key.split('>') : [];
    //Handling single deptch key/value pairs
    if (keys[0] && !keys[1]) {
      compState[keys[0]] = value;
    }
    //Handling double deptch key/value pairs
    if (keys[0] && keys[1]) {
      compState[keys[0]] = compState[keys[0]] ? compState[keys[0]] : {};
      compState[keys[0]][keys[1]] = value;
    }
    // compState.isProfileChanged = true;
    setCompState((state) => {
      return { ...state, ...compState };
    });
  }

  useEffect(() => {
    let isSubscribed = true;

    //Getting user profile information
    dataService
      .getData({
        api: "users/info/",
        request: { method: 'GET' },
      })
      .then((rs) => {
        console.log("users/info", rs);
        if (rs.status === "success") {
          //Changing default property name into id
          let res = deepCopy(rs);
          res.default_property = profile.default_property_id;
          let selected_property = properties.filter(p => p.id === res.default_property);
          if (selected_property && selected_property.length > 0) {
            res.selected_property = selected_property[0];

          }
          updateCompState('user_info_', rs);
          updateCompState('user_info', res);
          // debugger;
        }
      }, (err) => {
        console.log("ratePushSchedules Error!");
      });

    //Getting timezone list
    dataService
      .getData({
        api: "users/common-timezones/",
        request: { method: 'GET' },
      })
      .then((rs) => {
        console.log("timezones", rs);
        if (rs.status === "success") {
          // debugger
          updateCompState('time_zones', rs.data);
        }
      }, (err) => {
        console.log("users/common-timezones failed!");
      });

    return () => (isSubscribed = false);
  }, []);


  const { user_info } = compState;

  const handleSave = (params) => {
    // debugger;
    let { delta } = compState;
    let formData = new FormData();
    let deltaArray = Object.keys(delta);
    deltaArray.map((key) => {
      formData.append(key, delta[key]);
    });
    // formData.append('method', 'PUT');
    if (deltaArray.length) {
      updateCompState('request', 'loading');
      dataService
        .updateData({
          api: "users/edit-user/",
          request: { formData, method: 'PUT' }
        })
        .then((rs) => {
          updateCompState('request', '');

          if (rs.status === "success") {
            updateCompState('isProfileChanged', false);
            updateCompState('requestSuccess', true);
            updateCompState('requestMsg', "Data saved successfully!");

            //Updating app_state for default property id
            // debugger;
            if (delta.defaultProperty) {
              profile.default_property_id = delta.defaultProperty;
              dispatch({ type: "SYSTEM_SETTINGS_UPDATED", payload: profile });
            }


            setTimeout(() => {
              updateCompState('requestSuccess', false);
            }, compState.msgTimeout);

            // debugger;
          } else {
            updateCompState('request', '');
            updateCompState('requestError', true);
            updateCompState('requestMsg', "Data saving failed: " + rs.message);

            setTimeout(() => {
              updateCompState('requestError', false);
            }, compState.msgTimeout);
          }
        }, (err) => {
          // debugger
          updateCompState('request', '');
          updateCompState('requestError', true);
          updateCompState('requestMsg', "Data saving failed!");

          setTimeout(() => {
            updateCompState('requestError', false);
          }, compState.msgTimeout);

          console.log("ratePushSchedules Error!");
        });
    }

    // debugger;
  };

  const handleCancel = (params) => {
    // debugger
    let original = deepCopy(compState.user_info_);
    let default_property_id = profile.default_property_id;
    let selected_property = properties.filter(p => p.id === default_property_id);
    if (selected_property && selected_property.length > 0) {
      selected_property = selected_property[0];
      original.selected_property = selected_property;
    }
    updateCompState('user_info', original);
    updateCompState('isProfileChanged', false);
    // debugger;
  };

  return (
    <div>
      <Box className='breadcrumb' sx={{ color: 'white.main', position: 'absolute', top: -42 }}>
        Settings
        <ArrowForwardIosOutlinedIcon sx={{ top: 3, position: 'relative', height: 18 }}></ArrowForwardIosOutlinedIcon>
        Profile Settings
        <ArrowForwardIosOutlinedIcon sx={{ top: 3, position: 'relative', height: 18 }}></ArrowForwardIosOutlinedIcon>
        Profile
      </Box>
      <Box className="bgwhite main-scroll-wrap" sx={{ pl: 2, pr: 0, pb: 2 }}>


        <Grid container sx={{pt:0}}>
          <Grid item md={9} sx={{ mr: 0, pr: 0, position: 'relative' }}>
            <Alert
              severity="success"
              sx={{
                width: "100%",
                position: 'absolute',
                zIndex: 100,
                marginTop: 1,
                display: compState.requestSuccess ? "flex" : "none",
              }}
            >
              {compState.requestMsg}
            </Alert>
            <Alert
              severity="error"
              sx={{
                width: "100%",
                position: 'absolute',
                zIndex: 100,
                marginTop: 1,
                display: compState.requestError ? "flex" : "none",
              }}
            >
              {compState.requestMsg}
            </Alert>
            <Box className='profile-name'>
              {/* <Paper elevation={1} sx={{ p: 1, mb: 3 }}> */}
              <Grid container className='profile' >
                <Grid xs={1.5}>
                  <item>

                    {/* {user_info.profile_image_url && <Box className='profile-pic' style={{ background: 'url(' + user_info.profile_image_url + ') 0 0 no-repeat' }}>
                      <div className='profile-bio'>
                        {(!compState.localProfilePic || user_info.profile_image_url) && user.fullname && user.fullname[0]}
                        {(compState.localProfilePath || user_info.profile_image_url) && <Tooltip title={user.fullname} followCursor>
                          {<Avatar sx={{ width: 90, height: 90 }} title={user.fullname} src={compState.localProfilePath} />}
                        </Tooltip>}
                      </div>
                      <div className="half-circle">
                        <Box sx={{ position: 'relative', height: 30, mt: -1 }}>
                          <CameraAltOutlinedIcon color='white' fontSize='large' sx={{ position: 'absolute', left: 25, top: 2, zIndex: 99 }}></CameraAltOutlinedIcon>
                          <Tooltip sx={{ cursor: 'pointer' }} title="Click to choose file" arrow followCursor>
                            <Input onChange={(e) => {
                              // debugger;
                              let files = e.nativeEvent.srcElement.files;
                              // var img = document.createElement("img");
                              var reader = new FileReader();
                              reader.onloadend = function () {
                                // img.src = reader.result;
                                // debugger;
                                let localFile = reader.result;
                                updateCompState('localProfilePath', localFile);
                                updateCompState('localProfilePic', files[0]);
                                updateCompState('isProfileChanged', true);
                                updateCompState('delta>profileImage', files[0]);
                              }
                              reader.readAsDataURL(files[0]);

                              // let fileName = files.name;
                              // updateCompState('localProfilePic', img);
                              // debugger;
                            }} type="file" className='select-profile-pic' name="profileImage" />
                          </Tooltip>
                        </Box>

                      </div>

                    </Box>} */}
                    <Box className='profile-pic' style={{ background: compState.localProfilePic }}>
                      <div className='profile-bio'>
                        {!compState.localProfilePic && !user_info.profile_image_url && user.fullname && user.fullname[0]}
                        {compState.localProfilePath && user_info.profile_image_url && <Tooltip title={user.fullname} followCursor>
                          {<Avatar sx={{ width: 90, height: 90 }} title={user.fullname} src={compState.localProfilePath} />}
                        </Tooltip>}
                        {!compState.localProfilePath && user_info.profile_image_url && <Tooltip title={user.fullname} followCursor>
                          {<Avatar sx={{ width: 90, height: 90 }} title={user.fullname} src={user_info.profile_image_url} />}
                        </Tooltip>}
                      </div>
                      <div className="half-circle">
                        <Box sx={{ position: 'relative', height: 30, mt: -1 }}>
                          <CameraAltOutlinedIcon color='white' fontSize='large' sx={{ position: 'absolute', left: 25, top: 2, zIndex: 99 }}></CameraAltOutlinedIcon>
                          <Tooltip sx={{ cursor: 'pointer' }} title="Click to choose file" arrow followCursor>
                            <Input onChange={(e) => {
                              // debugger;
                              let files = e.nativeEvent.srcElement.files;
                              // var img = document.createElement("img");
                              var reader = new FileReader();
                              reader.onloadend = function () {
                                // img.src = reader.result;
                                // debugger;
                                let localFile = reader.result;
                                updateCompState('localProfilePath', localFile);
                                updateCompState('localProfilePic', files[0]);
                                updateCompState('isProfileChanged', true);
                                updateCompState('delta>profileImage', files[0]);
                              }
                              reader.readAsDataURL(files[0]);

                              // let fileName = files.name;
                              // updateCompState('localProfilePic', img);
                              // debugger;
                            }} type="file" className='select-profile-pic' name="profileImage" />
                          </Tooltip>
                        </Box>

                      </div>

                    </Box>
                    <Box sx={{ position: 'relative' }}>
                      {compState.localProfilePic && <Box sx={{ whiteSpace: 'nowrap', position: 'absolute', top: -3, color: "#ccc" }}>{compState.localProfilePic.name}</Box>}
                    </Box>
                  </item>
                </Grid>
                <Grid item className='username' xs={10.5}>


                  {compState.editing === 'fullname' && <Grid container direction="row" justifyContent="flex-start" alignItems="flex-start">
                    <Grid item>
                      <div className='fullname editable'>
                        <FormControl sx={{ mr: 1 }}>
                          <TextField className='first-name' type="text" size='small' variant='standard'
                            value={user_info.first_name}
                            // label="First Name"
                            onBlur={(e) => {
                              // debugger;
                              let value = e.target.value;
                              updateCompState('user_info>first_name', value);
                              updateCompState('editing', '');
                              updateCompState('delta>firstName', value);

                            }}
                            onChange={(e) => {
                              let value = e.target.value;
                              updateCompState('user_info>first_name', value);
                              updateCompState('isProfileChanged', true);
                            }} />
                        </FormControl>

                      </div>
                    </Grid>
                    <Grid item>
                      <div className='fullname editable'>
                        <FormControl sx={{}} label="Last Name">
                          <TextField className='last-name' size='small' variant='standard'
                            value={user_info.last_name}
                            // label="Last Name"
                            onBlur={(e) => {
                              updateCompState('editing', '');

                            }}
                            onChange={(e) => {
                              let value = e.target.value;
                              updateCompState('user_info>last_name', value);
                              updateCompState('isProfileChanged', true);
                              updateCompState('delta>lastName', value);
                            }}
                          />
                        </FormControl>
                      </div>
                    </Grid>
                  </Grid>
                  }
                  {compState.editing !== 'fullname' &&
                    <>{user_info.fullname}
                      <Box className='pencil-edit' sx={{ cursor: 'pointer', display: 'inline', ml: 1 }}>
                        <Tooltip title="Click to edit" sx={{ display: 'inline-block' }}>
                          <EditIcon fontSize='18px' onClick={() => updateCompState('editing', 'fullname')} />
                        </Tooltip>
                      </Box>
                    </>}

                </Grid>

              </Grid>
              {/* <Avatar alt={user.fullname} src={user.profile_image_url} sx={{ ml: 'auto', mr: 'auto', width: 150, height: 'auto' }} /> */}
              {/* </Paper> */}
              {/* <Grid container className='profile-info'>
                <Grid item xs={3} sx={{}}>
                  <Box><span>Full Name:</span></Box>
                </Grid>
                <Grid item xs={9}>
                  <Box>{user.fullname}</Box>
                </Grid>
              </Grid> */}
              <Grid container className='profile-info'>
                <Grid item xs={3} sx={{}}>
                  <Box><span className='main-label'>Title:</span></Box>
                </Grid>
                <Grid item xs={5}>
                  <Box elevation={2}>
                    {compState.editing === 'designation' && <FormControl fullWidth sx={{ mr: 1, fontSize: 14 }}>
                      <TextField sx={{ 'input': { fontSize: 14 } }} className='view designation' type="text" size='small' variant='standard'
                        value={user_info.designation || 'Regional Director of Revenue'}
                        // label="First Name"
                        onBlur={(e) => {
                          let value = e.target.value;
                          updateCompState('user_info>designation', value);
                          updateCompState('editing', '');
                          updateCompState('delta>designation', value);
                        }}
                        onChange={(e) => {
                          let value = e.target.value;
                          user_info.designation = value;
                          updateCompState('user_info>designation', value);
                          updateCompState('isProfileChanged', true);
                        }} />
                    </FormControl>
                    }

                    {/* {compState.editing !== 'designation' && <div onClick={() => { updateCompState('editing', 'designation') }}>{user_info.designation || 'Regional Director of Revenue'}</div>} */}
                    {compState.editing !== 'designation' &&
                      <>{user_info.designation || 'Regional Director of Revenue'}
                        <Box className='pencil-edit' sx={{ cursor: 'pointer', display: 'inline', ml: 1 }}>
                          <Tooltip title="Click to edit" sx={{ display: 'inline-block' }}>
                            <EditIcon fontSize='18px' onClick={() => updateCompState('editing', 'designation')} />
                          </Tooltip>
                        </Box>
                      </>}
                    {/* Regional Director of Revenue */}
                  </Box>
                </Grid>
              </Grid>
              <Grid container className='profile-info'>
                <Grid item xs={3} sx={{}}>
                  <Box><span className='main-label'>EMAIL:</span></Box>
                </Grid>
                <Grid item xs={9}>
                  <Box>{user_info.email}</Box>
                </Grid>
              </Grid>
              <Grid container className='profile-info'>
                <Grid item xs={3} sx={{}}>
                  <Box><span className='main-label'>MOBILE:</span></Box>
                </Grid>
                <Grid item xs={9}>
                  <Box>
                    {compState.editing === 'mobileNo' && <FormControl sx={{ mr: 1 }}>
                      <TextField className='view mobileNo' type="text" size='small' variant='standard'
                        value={user_info.mobile_no}
                        // label="First Name"
                        onBlur={(e) => {
                          let value = e.target.value;
                          updateCompState('user_info>mobile_no', value);
                          updateCompState('editing', '');
                          updateCompState('delta>mobileNo', value);
                        }}
                        onChange={(e) => {
                          let value = e.target.value;
                          user_info.mobile_no = value;
                          updateCompState('user_info>mobile_no', value);
                          updateCompState('isProfileChanged', true);
                        }} />
                    </FormControl>
                    }



                    {compState.editing !== 'mobileNo' &&
                      <>{user_info.mobile_no || 'Mobile No'}
                        <Box className='pencil-edit' sx={{ cursor: 'pointer', display: 'inline', ml: 1 }}>
                          <Tooltip title="Click to edit" sx={{ display: 'inline-block' }}>
                            <EditIcon fontSize='18px' onClick={() => updateCompState('editing', 'mobileNo')} />
                          </Tooltip>
                        </Box>
                      </>}
                  </Box>
                </Grid>
              </Grid>
              <Grid container className='profile-info'>
                <Grid item xs={3} sx={{}}>
                  <Box><span className='main-label'>User Time Zone:</span></Box>
                </Grid>
                <Grid item xs={9}>
                  <Box>
                    {compState.editing === 'timezone' && <FormControl className="rate-textfield" size="small">

                      <Select
                        sx={{ height: 30 }}
                        value={compState.user_info.timezone}
                        className="timezone-select"
                        onBlur={(e) => {
                          let value = e.target.value;
                          updateCompState('user_info>timezone', value);
                          updateCompState('editing', '');

                        }}
                        onClose={
                          () => {
                            updateCompState('editing', '');
                            updateCompState('delta>userTimezone', user_info.timezone);
                            updateCompState('isProfileChanged', true);

                          }
                        }
                        onChange={(e) => {
                          const value = e.target.value;
                          compState.userTimezone = value;
                          updateCompState("user_info>timezone", value);
                        }}
                      >
                        {compState.time_zones.map((timeZone) => {
                          return <MenuItem value={timeZone}>{timeZone}</MenuItem>
                        })}

                      </Select>
                    </FormControl>
                    }

                    {compState.editing !== 'timezone' &&
                      <>{user_info.timezone}
                        <Box className='pencil-edit' sx={{ cursor: 'pointer', display: 'inline', ml: 1 }}>
                          <Tooltip title="Click to edit" sx={{ display: 'inline-block' }}>
                            <EditIcon fontSize='18px' onClick={() => updateCompState('editing', 'timezone')} />
                          </Tooltip>
                        </Box>
                      </>}
                  </Box>

                </Grid>
              </Grid>
              <Grid container className='profile-info'>
                <Grid item xs={3} sx={{}}>
                  <Box><span className='main-label'>Default Property:</span></Box>
                </Grid>
                <Grid item xs={9}>
                  <Box>
                    {compState.editing === 'defaultProperty' && <FormControl className="rate-textfield" size="small">

                      <Select
                        sx={{ height: 30 }}
                        value={user_info.selected_property}
                        className="timezone-select"
                        onBlur={(e) => {
                          let value = e.target.value;
                          updateCompState('user_info>defaultProperty', value);
                          updateCompState('editing', '');

                        }}
                        onClose={
                          () => {
                            updateCompState('editing', '');
                            updateCompState('isProfileChanged', true);
                          }
                        }
                        onChange={(e) => {
                          const value = e.target.value;
                          // debugger;
                          // compState.defaultProperty = value;
                          updateCompState("user_info>defaultProperty", value);
                          updateCompState("user_info>selected_property", value);
                          updateCompState('delta>defaultProperty', value.id);
                        }}
                      >

                        {properties.map((item, index) => {
                          return <MenuItem value={item}>{item.name}</MenuItem>
                        })}

                      </Select>
                    </FormControl>
                    }

                    {compState.editing !== 'defaultProperty' &&
                      <>{user_info.selected_property && user_info.selected_property.name}
                        <Box className='pencil-edit' sx={{ cursor: 'pointer', display: 'inline', ml: 1 }}>
                          <Tooltip title="Click to edit" sx={{ display: 'inline-block' }}>
                            <EditIcon fontSize='18px' onClick={() => updateCompState('editing', 'defaultProperty')} />
                          </Tooltip>
                        </Box>
                      </>}
                  </Box>

                </Grid>
              </Grid>
              <Grid container className='profile-info'>
                <Grid item xs={3} sx={{}}>
                  <Box>
                    <span className='main-label'>Default Mode:</span>
                  </Box>
                </Grid>
                <Grid item xs={9}>
                  <Box sx={{ ml: 0 }}>
                    <Grid container>
                      <Grid item>
                        <FormControl sx={{ mt: 0.8 }}>
                          <RadioGroup className='radio-group'
                            aria-labelledby="demo-radio-buttons-group-label"
                            // defaultValue="Graph"
                            value={user_info.default_view}
                            name="radio-buttons-group"
                            row
                            onChange={(e) => {
                              // debugger;
                              const value = e.target.value.toLowerCase();
                              user_info.default_view = value;
                              updateCompState("user_info>default_view", value);
                              updateCompState('isProfileChanged', true);
                              updateCompState('delta>defaultView', value);
                            }}
                          >
                            <FormControlLabel value="chart" checked={user_info.default_view && user_info.default_view.toLowerCase() === 'chart'} className="graph" control={<Radio />} label="Graph View" />
                            <FormControlLabel value="table" checked={user_info.default_view && user_info.default_view.toLowerCase() === 'table'} className='table' sx={{}} control={<Radio />} label="Table View" />

                          </RadioGroup>
                        </FormControl>
                      </Grid>
                    </Grid>
                  </Box>
                </Grid>
              </Grid>
              <Grid container className='profile-info'>
                <Grid item xs={3} sx={{}}>
                  <Box><span className='main-label'>Notification Preference:</span></Box>
                </Grid>
                <Grid item xs={9}>
                  <Box>
                    <Grid container spacing={2}>
                      <Grid item>Notify me via..</Grid>
                      <Grid item >
                        {compState.user_info.text_noti_subscribed && <Checkbox
                          checked
                          onChange={(e) => {
                            // debugger;
                            const value = e.target.checked;
                            compState.user_info.text_noti_subscribed = value;
                            updateCompState("user_info>text_noti_subscribed", value);
                            updateCompState('delta>notificationPrefText', value);
                            updateCompState('isProfileChanged', true);
                          }} value={user_info.text_noti_subscribed} className='settting-profile-checkbox' />
                        }
                        {!compState.user_info.text_noti_subscribed && <Checkbox

                          onChange={(e) => {
                            // debugger;
                            const value = e.target.checked;
                            compState.user_info.text_noti_subscribed = value;
                            updateCompState("user_info>text_noti_subscribed", value);
                            updateCompState('delta>notificationPrefText', value);
                            updateCompState('isProfileChanged', true);
                          }} value={user_info.text_noti_subscribed} className='settting-profile-checkbox' />
                        }
                        <span> Mobile</span>
                      </Grid>

                      <Grid item >
                        {compState.user_info.mail_noti_subscribed && <Checkbox
                          checked
                          onChange={(e) => {
                            // debugger;
                            const value = e.target.checked;
                            compState.user_info.mail_noti_subscribed = value;
                            updateCompState("user_info>mail_noti_subscribed", value);
                            updateCompState('delta>notificationPrefEmail', value);
                            updateCompState('isProfileChanged', true);
                          }} value={user_info.mail_noti_subscribed} className='settting-profile-checkbox' />
                        }
                        {!compState.user_info.mail_noti_subscribed && <Checkbox

                          onChange={(e) => {
                            // debugger;
                            const value = e.target.checked;
                            compState.user_info.mail_noti_subscribed = value;
                            updateCompState("user_info>mail_noti_subscribed", value);
                            updateCompState('delta>notificationPrefEmail', value);
                            updateCompState('isProfileChanged', true);
                          }} value={user_info.mail_noti_subscribed} className='settting-profile-checkbox' />
                        }
                        <span> Email</span>
                      </Grid>
                      <Grid item >
                        {compState.user_info.push_noti_subscribed && <Checkbox
                          checked
                          onChange={(e) => {
                            // debugger;
                            const value = e.target.checked;
                            compState.user_info.push_noti_subscribed = value;
                            updateCompState("user_info>push_noti_subscribed", value);
                            updateCompState('delta>notificationPrefWeb', value);
                            updateCompState('isProfileChanged', true);
                          }} value={user_info.push_noti_subscribed} className='settting-profile-checkbox' />
                        }
                        {!compState.user_info.push_noti_subscribed && <Checkbox

                          onChange={(e) => {
                            // debugger;
                            const value = e.target.checked;
                            compState.user_info.push_noti_subscribed = value;
                            updateCompState("user_info>push_noti_subscribed", value);
                            updateCompState('delta>notificationPrefWeb', value);
                            updateCompState('isProfileChanged', true);
                          }} value={user_info.push_noti_subscribed} className='settting-profile-checkbox' />
                        }
                        <span> Push Notification</span>

                      </Grid>
                    </Grid>

                  </Box>
                </Grid>

              </Grid>
              <Box sx={{ textAlign: 'right' }}>
                <Button
                  disabled={!compState.isProfileChanged}
                  // type="button"
                  // variant="outlined"
                  sx={{ mr: 1, mt: 1, mb: 0, backgroundColor: 'green.main', fontSize: 14, color: '#fff', '&:disabled': { backgroundColor: '#333333', color: '#fff', opacity: 0.4, cursor: 'not-allowed' }, '&:hover': { backgroundColor: '#333333', opacity: 1 } }}

                  onClick={handleCancel}
                >
                  Cancel
                </Button>
                <Button
                  disabled={!compState.isProfileChanged}
                  // type="button"
                  // variant="outlined"
                  sx={{ mt: 1, mb: 0, backgroundColor: 'green.main', fontSize: 14, color: '#fff', '&:disabled': { backgroundColor: '#333333', color: '#fff', opacity: 0.4, cursor: 'not-allowed' }, '&:hover': { backgroundColor: '#333333', opacity: 1 } }}
                  onClick={handleSave}
                >
                  Save
                </Button>
              </Box>
            </Box>
            <Box className='profile-name change-pass' sx={{ pt: 0, pb: 0, m: 0 }}>
              <div>
                <Grid container>
                  <Grid item xs={12}>
                    <Box className='change-password'>
                      <Grid className='security-heading' container>
                        <Grid item xs={.7}>
                          <div className='security-icon'></div>
                        </Grid>
                        <Grid item xs={11.3}>
                          <div className='heading'>Change Password</div>
                        </Grid>
                      </Grid>
                      <SecuritySettings />
                    </Box>

                  </Grid>


                </Grid>
              </div>
            </Box>
          </Grid>

          <Grid item md={3}>
            <Box className='profile-dashboard' sx={{ height: 'calc(100vh - 18vh)', overflow: 'hidden', ml: 0, }}>
              <h3 className='profile-right-heading'>Access</h3>
              <Box sx={{ height: 'calc(100vh - 20vh)', overflow: 'auto', pb: 2, ml: 1, mr: 1 }}>
                <List sx={{ ml: 0, pl: 0 }}>
                  {
                    properties.map((item, index) => {
                      return (<ListItem key={`item-${index}`} className="property-list">
                        <ListItemText primary={`${item.name}`} className="property-name" />
                        {/* <Chip className='property-role border' label="Revenue" variant="outlined" /> */}
                      </ListItem>

                      )
                    })
                  }
                </List>

              </Box>
            </Box>

          </Grid>

        </Grid >
        {
          compState.request === 'loading' && (
            <Box
              sx={{
                textAlighn: "center",
                backgroundColor: "dark.darkest",
                opacity: 0.8,
                position: "fixed",
                top: 40,
                left: 0,
                width: "100%",
                height: "100%",
              }}
            >
              <CircularProgress
                size={40}
                sx={{
                  color: "white",
                  position: "absolute",
                  top: "50%",
                  left: "50%",
                  marginTop: "-20px",
                  marginLeft: "-20px",
                }}
              />
            </Box>
          )
        }
      </Box >
    </div >
  );
}

function SecuritySettings(props) {
  const dispatch = useDispatch();
  const { app, auth } = useSelector(state => state);
  const { user, profile } = auth;
  const properties = profile.property_details;
  const [formData, setFormData] = useState({ old_pass: '', new_pass: '', repeat_pass: '', submitDisabled: true });
  const [errorType, setErrorType] = useState("");
  const [isrequesting, setIsRequesting] = useState(false);
  const [resError, setResError] = useState(false);
  const [isRequestSuccess, setIsRequestSuccess] = useState(false);
  const [requestSuccessMsg, setRequestSuccessMsg] = useState('Your request for change password was successfull!');
  const [isRequestError, setIsRequestError] = useState(false);
  const [resErrorMsg, setResErrorMsg] = useState('');

  useEffect(() => {
    const { old_pass, new_pass, repeat_pass, submitDisabled } = formData;
    if (old_pass && new_pass && repeat_pass && (new_pass === repeat_pass)) {
      //setFormData({...formData, submitDisabled:false});
    } else {
      //setFormData({...formData, submitDisabled:true});
    }
  }, [formData]);
  const checkValidity = () => {
    const { old_pass, new_pass, repeat_pass } = formData;

    if (old_pass && new_pass && repeat_pass && (new_pass === repeat_pass)) {
      setFormData({ ...formData, submitDisabled: false });
    }
  }
  const handleSubmit = (event) => {
    event.preventDefault();
    const { old_pass, new_pass } = formData;
    setIsRequesting(true);
    let payload = {
      password: {
        old: old_pass,
        new: new_pass
      }
      // "current_password": old_pass,
      // "new_password": new_pass,
      // "email": user.email,
      // "token_string": user.token_string,
      // "user_id": user.user_id
    }
    dataService.update({ api: 'users/change-password', request: payload })
      .then(
        (data) => {
          //Do some task here just after respons
          if (data.status === "success") {
            setIsRequestSuccess(true);
            setIsRequesting(false);
            setFormData({ old_pass: '', new_pass: '', repeat_pass: '', submitDisabled: true });
            setTimeout(() => {
              setIsRequestSuccess(false);
            }, 3000);
            //return Promise.resolve(data);
          }
          else {
            setIsRequestError(true);
            setIsRequesting(false);
            setResErrorMsg(data.message);
            //return Promise.reject(data);
          }
        }
      )
      .catch((err) => {
        setIsRequestError(true);
        setResErrorMsg(err.original.statusText || 'Something went wrong, please try again later!');
        return Promise.reject(err);
      })
  }
  function updateRequestData(e, type) {
    formData[type] = e.target.value;
    //setFormData({...formData});
    const { old_pass, new_pass, repeat_pass } = formData;
    if (new_pass && repeat_pass && (new_pass !== repeat_pass)) {
      setIsRequestError(true);
      setResErrorMsg("New password and repeated password is not matching, please check and try again");
    } else {
      setIsRequestError(false);
      setResErrorMsg("");
    }
    if (old_pass && new_pass && repeat_pass && (new_pass === repeat_pass)) {
      setFormData({ ...formData, submitDisabled: false });
    } else {
      setFormData({ ...formData, submitDisabled: true });
    }
  }

  return (
    <>
      <Grid container spacing={3}>
        <Grid item md={12} sx={{ mr: 0, pr: 0 }}>
          <Box sx={{ pt: 2 }}>
            <Box sx={{ display: isRequestSuccess ? 'block' : 'none', p: 1, position: 'relative', color: 'white.main', backgroundColor: 'green.green', textAlign: 'center', fontSize: 17 }}>
              {requestSuccessMsg}
            </Box>
            <Alert severity="error" sx={{ display: isRequestError ? 'flex' : 'none' }}>{resErrorMsg}</Alert>
            <Box component="form" onSubmit={handleSubmit} noValidate sx={{ mt: 0, padding: 1,  }}>

              <Grid container spacing={3}>
                <Grid item xs={3}>
                  <TextField
                    className='textfield'
                    size='small'
                    error={errorType === "password"}
                    helperText={errorType === "password" ? "Invalid password" : ""}
                    margin="normal"
                    required
                    fullWidth
                    id="old_password"
                    label="Old Password"
                    type="password"
                    name="old_password"
                    autoComplete=""
                    value={formData.old_pass}
                    onChange={(e) => {
                      updateRequestData(e, 'old_pass');
                      //setFormData({...formData, old_pass:e.target.value});

                    }}
                    autoFocus
                  />

                </Grid>
                <Grid item xs={3}>
                  <TextField
                    className='textfield'
                    size='small'
                    margin="normal"
                    error={errorType === "password"}
                    helperText={errorType === "password" ? "Invalid password" : ""}
                    required
                    fullWidth
                    name="new_pass"
                    label="New Password"
                    type="password"
                    id="new_password"
                    autoComplete=""
                    value={formData.new_pass}
                    onChange={(e) => {
                      updateRequestData(e, 'new_pass');
                      //setFormData({...formData, new_pass:e.target.value});

                    }}
                  />
                </Grid>
                <Grid item xs={3}>
                  <TextField
                    className='textfield'
                    size='small'
                    margin="normal"
                    error={errorType === "password"}
                    helperText={errorType === "password" ? "Invalid password" : ""}
                    required
                    fullWidth
                    name="repeat_pass"
                    label="Repeat New Password"
                    type="password"
                    id="repeat_pass"
                    autoComplete=""
                    value={formData.repeat_pass}
                    onChange={(e) => {
                      updateRequestData(e, 'repeat_pass');
                      //setFormData({...formData, repeat_pass:e.target.value});

                    }}
                  />
                </Grid>
                <Grid item xs={3}>
                  <Button
                    type="submit"
                    fullWidth
                    variant="contained"
                    disabled={formData.submitDisabled}
                    sx={{ mr: 1, mt: 2, mb: 0, backgroundColor: 'green.main', fontSize: 14, color: '#fff', '&:disabled': { backgroundColor: '#333333', color: '#fff', opacity: 0.4, cursor: 'not-allowed' }, '&:hover': { backgroundColor: '#333333', opacity: 1 } }}
                    onClick={handleSubmit}
                  >
                    Submit
                  </Button>
                </Grid>
              </Grid>









              {isrequesting && (
                <Box sx={{ textAlighn: 'center', backgroundColor: 'dark.darkest', opacity: 0.8, position: 'absolute', top: 40, left: 0, width: '100%', height: '100%' }}>
                  <CircularProgress
                    size={40}
                    sx={{
                      color: 'white',
                      position: 'absolute',
                      top: '50%',
                      left: '50%',
                      marginTop: '-20px',
                      marginLeft: '-20px',
                    }}
                  />
                </Box>

              )}

            </Box>
          </Box>
        </Grid>

      </Grid>
    </>

  );
}



export { ProfileSettings, SecuritySettings };