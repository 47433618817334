import React, { useState, useEffect } from 'react'
import { useDispatch, useSelector } from "react-redux";
import { updateAppState, getPropertyInfo } from "../../actions/appstate";
import {
  getAppData,
  safeReference,
} from "../../app/util"
import { styled } from '@mui/material/styles';
import Tab from '@mui/material/Tab';
import Box from '@mui/material/Box';
import { height } from '@mui/system';
import Chip from '@mui/material/Chip';
import Stack from '@mui/material/Stack';
import Avatar from '@mui/material/Avatar';


const handleClick = () => {
  console.info('You clicked the Chip.');
};

const handleDelete = () => {
  console.info('You clicked the delete icon.');
};

const AntTab = styled((props) => <Tab disableRipple {...props} />)(({ theme }) => ({
  textTransform: 'none',
  minWidth: 0,
  borderRight: "1px solid #fff",
  cursor: "pointer",
  [theme.breakpoints.up('sm')]: {
    minWidth: 0,
  },
  fontWeight: theme.typography.fontWeightRegular,
  marginRight: theme.spacing(1),
  color: 'rgba(0, 0, 0, 0.85)',
  fontFamily: [
    '-apple-system',
    'BlinkMacSystemFont',
    '"Segoe UI"',
    'Roboto',
    '"Helvetica Neue"',
    'Arial',
    'sans-serif',
    '"Apple Color Emoji"',
    '"Segoe UI Emoji"',
    '"Segoe UI Symbol"',
  ].join(','),
  '&:hover': {
    color: '#40a9ff',
    opacity: 1,
  },
  '&.Mui-selected': {
    color: '#1890ff',
    fontWeight: theme.typography.fontWeightMedium,
  },
  '&.Mui-focusVisible': {
    backgroundColor: '#d1eaff',
  },
}));
const StyledTab = styled((props) => <Tab disableRipple {...props} />)(
  ({ theme }) => ({
    textTransform: 'none',
    fontWeight: theme.typography.fontWeightRegular,
    fontSize: theme.typography.pxToRem(15),
    marginRight: theme.spacing(1),
    color: 'rgba(255, 255, 255, 0.7)',
    '&.Mui-selected': {
      color: '#fff',
    },
    '&.Mui-focusVisible': {
      backgroundColor: 'rgba(100, 95, 228, 0.32)',
    },
  }),
);



function ReportFilter() {
  const dispatch = useDispatch();
  const { auth, app } = useSelector(state => state);
  const { profile } = useSelector(
    (state) => state.auth
  );
  const { app_state, ui_state } = useSelector(
    (state) => state.app.current_state
  );


  const [categoryList, setCategoryList] = useState(["Hotel", "Transient", "Group", "Contract"]);
  const [selectedCategory, setSelectedCategory] = useState(ui_state.reports_dpbs.selectedCategory);
  const [selectedSegment, setSelectedSegment] = useState('All');

  let system_settings = getAppData(auth, app_state)
  const lvl1_mseg_grouping = JSON.parse(
    JSON.stringify(system_settings.lvl1_mseg_grouping)
  );
  const topSegments = {};
  lvl1_mseg_grouping.filter((item) => {
    if (item.Transient) topSegments.Transient = item.Transient;
    if (item.Group) topSegments.Group = item.Group;
    if (item.Contract) topSegments.Contract = item.Contract;
  });

  let topSegmentsList = [];
  if (selectedCategory && selectedCategory !== "Hotel") {
    topSegmentsList = topSegments[selectedCategory];
    topSegmentsList.unshift("All");
  }
  // else{
  //   categoryList.shift("Hotel")
  //   categoryList.map((category)=>{
  //     topSegmentsList.push(...topSegments[category]); 
  //   })
  // }

  useEffect(() => {
    let categoryList = ["Hotel", "Transient", "Group", "Contract"];
    setCategoryList(categoryList)
    setSelectedSegment(ui_state.reports_dpbs.selectedSegment);
  }, [selectedCategory])

  // onclick function to update the category states.
  const onClickHandlerCategory = (category) => {
    dispatch(updateAppState("change_dpbs_category", {
      app_state: { data_load: true },
      ui_state: {
        reports_dpbs: { selectedCategory: category, selectedSegment: "All", topSegmentsList: topSegments[category] },
        showspinner: true
      }
    }));
    // set local state 
    setSelectedCategory(category);
  }

  // onclick function to update the Segments states.
  const onClickHandlerSegments = (segment) => {
    if (topSegmentsList[0] === 'All') topSegmentsList.splice(0, 1);
    dispatch(updateAppState("change_dpbs_segment", {
      app_state: { data_load: true },
      ui_state: {
        reports_dpbs: { selectedCategory: selectedCategory, selectedSegment: segment, topSegmentsList: topSegmentsList },
        showspinner: true
      }
    }));
    // set Segments local state 
    setSelectedSegment(segment);
  }

  return (
    <Box sx={{ width: '100%' }} className="reports-filter-container">
      <Box className='reports-category'>
        {
          categoryList.map((category, index) => {
            let selectedClass = "report-category-tab";
            if (category === selectedCategory) {
              selectedClass += " Mui-selected";
            }
            return (<AntTab onClick={() => { onClickHandlerCategory(category) }}
              label={category} key={index} className={selectedClass} />)
          })
        }
      </Box>
      {
        (topSegmentsList && topSegmentsList.length > 0 && <Box className='segment-type'>
          {
            topSegmentsList.map((segment, count) => {
              let selectedClass = (segment === selectedSegment) ? "primary-selected-tab" : "";
              return (<StyledTab label={segment} key={count} className={selectedClass} onClick={() => { onClickHandlerSegments(segment) }} />);
            })
          }
        </Box>
        )}
    </Box>
  )
}

export default ReportFilter
