import moment from "moment";
import { convertToPercent, deepCopy, getAppData, getDatesInRange, getFromState, getObjectFilter, getTransformedSegments, in_array, round, setUpDate } from "../../../app/util";
import { useDataSummary } from "../../Tile/kpiService";
import dataService from "../../../services/data.service";
import { useSelector } from "react-redux";
import { transformSegmentData } from "../segmentedData";
import { getSegmentedCode } from "../processDataReports";

import jsonData from '../test.json';

/**   */


export function getReportData_revparIndex(columns, auth, app_state, ui_state) {
    let revparData = dataService.getSeriesById("RevparIndex").data;
    // debugger; 
    let data = (revparData) ? revparData.data : [];
    let setup_data = [];
    let tableType = app_state.workspace_controls.tableType;
    console.log("data len",data);
    data[tableType] && data[tableType].map((item, index) => {
        let data_columns = [];
        columns.map(col => {
            let dataObj = {};
            if (col.type === 'dateColumn') {
                if (tableType === "daily") {
                    dataObj = setUpDate(dataObj, item.stay_date);
                } else {
                    dataObj['month'] = moment(item.month).format('MMM, YYYY');
                }
            }
            if (col.type === 'dataColumn') {
                col.subcolumns.map(key => {
                    if (!item[key.value]) {
                        dataObj[key.value] = 0.00;
                    } else {
                        console.log(key.value);
                        if (key.value === "revpar_lift") {
                            dataObj[key.value] = convertToPercent(item[key.value]);
                        } else {
                            dataObj[key.value] = round(item[key.value]).toFixed(2);

                        }
                    }
                })
            }
            data_columns.push(dataObj);
        })
        setup_data.push(data_columns);
    })

    console.log(setup_data);
    return setup_data;
}

/** get columns for RevPar index report */
export const getColumnName_revpar = (ord = 3, app_state) => {
    let revparData = dataService.getSeriesById("RevparIndex").data;// (app_state.workspace_controls.scopeType !== 'Dba') ? dataService.getSeriesById("RevParIndex").data : dataService.getSeriesById("RevParIndex").data;
    let data = (revparData) ? revparData.data : [];
    const subColumns = [];
    let tableType = app_state.workspace_controls.tableType;
    const firstData = (revparData && data[tableType][0]) ? data[tableType][0] : {};
    let order = ord
    if (Object.keys(firstData).length > 0) {
        Object.keys(firstData).map((key) => {
            if (key !== 'dow' && key !== 'month' && key !== 'stay_date') {
                order = (key === "revpar_lift") ? -1 : order;
                let key_ = key;
                let name = key.replaceAll("_", " ");
                subColumns.push({
                    display: name,
                    subcol_id: "inner_columns_" + key,
                    type: "currency",
                    value: key,
                    "parent": "inner_columns",
                    "className": "col-sub",
                    "draggable": true,
                    "sortable": true,
                    "filterable": true,
                    "filterMenu": true,
                    "sorted": null,
                    "hidden": false,
                    "order": order
                })
                order++;
            }
        })
    }else{
        subColumns.push({
            display: "",
            subcol_id: "inner_columns_1",
            type: "currency",
            value: "",
            "parent": "inner_columns",
            "className": "col-sub",
            "draggable": false,
            "sortable": false,
            "filterable": false,
            "filterMenu": false,
            "sorted": null,
            "hidden": false,
            "order": order
        })
    }
    return subColumns;
}