
import moment from "moment";
import { it } from "date-fns/locale";
import dataService from "../../services/data.service";
import { deepCopy, aggregateObject, truncate, daysCountFromRange, getAppData, getCorrectValue } from "../../app/util";
import { API_REGISTRY, TILES } from "../../app/config";
import { Key, Map, TempleBuddhist } from "@mui/icons-material";
import marketSegmentedJson from './marketSegmented.json';
import { forecastSegmentedData } from "./tilesData/forecast";
import { bookingCurveSegmentedDbaData, bookingCurveSegmentedDateData } from "./tilesData/bookingcurvenew";


export function convertToPercent(num, type = 'dec') {
    if (!num) {
        return 0;
    }
    if (type === 'int') {
        num = num / 100;
    } else if (type === 'dec') {
        num = num * 100;
    }
    return Number.parseFloat((Math.round((num * 1000) / 10) / 100).toFixed(2));
}



function getSegmentName(app_state, auth, name) {
    let segmentName = name.split("Segment_");
    name = segmentName[1];
    const { property_details } = auth.profile || [];
    const prop_details = property_details.filter((item, index) => {
        if (item.id === app_state.property_id) {
            return item.id === app_state.property_id;
        }
    });
    const { system_settings } =
        prop_details && prop_details.length ? prop_details[0] : null;
    let mapping = system_settings[0].market_segment_category_mapping;
    let segment = "";
    for (let [key, value] of Object.entries(mapping)) {
        if (value.includes(name)) {
            segment = key;
        }
    }
    return segment;
    // return truncate(segment, 18);
}

export function transformSegmentData(app_state, auth, data, params) {
    //    return await (() => {
    //    return new Promise((resolve, reject) => {
    let { segmentedOn, tile, segments } = params;
    let transformed = [];
    let blankObj = { Rooms: 0, Occ: 0, ADR: 0, RevPAR: 0, SellRate: 0, Revenue: 0 };

    if (segmentedOn && (tile === 'otb' || tile === 'pickup')) {
        let otbSegmentData = deepCopy(dataService.getSeriesById("OSRSegment").data.data);
        //  otbSegmentData = otbSegmentData.slice(0,20);
        let segmentTotalAll = {};
        otbSegmentData.map((item, i) => {
            let currentTotal = { Rooms: 0, Occ: 0, ADR: 0, RevPAR: 0, SellRate: 0, Revenue: 0 };
            let pickupTotal = { Rooms: 0, Occ: 0, ADR: 0, RevPAR: 0, SellRate: 0, Revenue: 0 };
            let primaryTotal = { Rooms: 0, Occ: 0, ADR: 0, RevPAR: 0, SellRate: 0, Revenue: 0 };
            let priorTotal = { Rooms: 0, Occ: 0, ADR: 0, RevPAR: 0, SellRate: 0, Revenue: 0 };
            let sdlyTotal = { Rooms: 0, Occ: 0, ADR: 0, RevPAR: 0, SellRate: 0, Revenue: 0 };
            let sdly_actualTotal = { Rooms: 0, Occ: 0, ADR: 0, RevPAR: 0, SellRate: 0, Revenue: 0 };
            let sdly_pickupTotal = { Rooms: 0, Occ: 0, ADR: 0, RevPAR: 0, SellRate: 0, Revenue: 0 };


            let row = 0;
            let HotelTotal = deepCopy(item.HotelTotal);
            let index = deepCopy(item.index);
            let ingonreKeys = ['HotelTotalx', 'index'];
            let tempDate = item.index.date;
            let itemsKeys = Object.keys(item);
            itemsKeys.sort().push(itemsKeys.shift());
            itemsKeys.map((key) => {
                
                let totalAll = {
                    HotelTotal: {}
                }
                let tmp = {};
                if (!item[key].current) {
                    item[key].current = deepCopy(blankObj);
                }
                if (!item[key].pickup) {
                    item[key].pickup = deepCopy(blankObj);
                }
                if (!item[key].primary) {
                    item[key].primary = deepCopy(blankObj);
                }
                if (!item[key].prior) {
                    item[key].prior = deepCopy(blankObj);
                }
                if (!item[key].sdly) {
                    item[key].sdly = deepCopy(blankObj);
                }
                if (!item[key].sdly_actual) {
                    item[key].sdly_actual = deepCopy(blankObj);
                }
                if (!item[key].sdly_pickup) {
                    item[key].sdly_pickup = deepCopy(blankObj);
                }
                if (!ingonreKeys.includes(key)) {
                    let segmentCode = key.split('_')[1];
                    let segmentName = segments.map[segmentCode];
                    if (key === "HotelTotal") { segmentName = "Total"; }
                    if (!segmentName) segmentName = "Other";

                    tmp.HotelTotal = deepCopy(item[key]);
                    tmp.index = deepCopy(item.index);
                    // tmp.index.segment = truncate(segmentName, 18);
                    tmp.index.segment = segmentName;

                    let idx = null;
                    for (let i = 0; i < transformed.length; i++) {
                        if (item.index.date === transformed[i].index.date && transformed[i].index.segment === tmp.index.segment) {
                            idx = i;
                            // break;
                        }
                    }
                    if (idx !== null) {
                        let keys = [
                            ['current', 'primary', 'pickup', 'prior', 'sdly', 'sdly_actual', 'sdly_pickup'],
                            ['ADR', 'Occ', 'RevPAR', 'Revenue', 'Rooms', 'SellRate']
                        ];
                        let aggTotal = aggregateObject(transformed[idx].HotelTotal, tmp.HotelTotal, keys, 2);
                        transformed[idx].HotelTotal = aggTotal;
                    } else {
                        transformed.push(tmp);
                        row++;
                    }
                    /// total calculate ///
                    let cols = Object.keys(primaryTotal);
                    cols.map((k) => {
                        currentTotal[k] += parseFloat(item[key].current[k]);
                        pickupTotal[k] += parseFloat(item[key].pickup[k]);
                        primaryTotal[k] += parseFloat(item[key].primary[k]);
                        priorTotal[k] += parseFloat(item[key].prior[k]);
                        sdlyTotal[k] += parseFloat(item[key].sdly[k]);
                        sdly_actualTotal[k] += parseFloat(item[key].sdly_actual[k]);
                        sdly_pickupTotal[k] += parseFloat(item[key].sdly_pickup[k]);
                    })

                    /// Segment Wise Total ///
                    if (!segmentTotalAll[key]) {
                        segmentTotalAll[key] = {
                            current: deepCopy(blankObj),
                            pickup: deepCopy(blankObj),
                            primary: deepCopy(blankObj),
                            prior: deepCopy(blankObj),
                            sdly: deepCopy(blankObj),
                            sdly_pickup: deepCopy(blankObj),
                            sdly_actual: deepCopy(blankObj)
                        };
                    }
                    let myKey = key;
                    let fieldArr = Object.keys(primaryTotal);
                    fieldArr.map((k) => {
                        segmentTotalAll[key].current[k] += parseFloat(getCorrectValue(item[key].current[k]));
                        segmentTotalAll[key].pickup[k] += parseFloat(getCorrectValue(item[key].pickup[k]));
                        segmentTotalAll[key].primary[k] += parseFloat(getCorrectValue(item[key].primary[k]));
                        segmentTotalAll[key].prior[k] += parseFloat(getCorrectValue(item[key].prior[k]));
                        segmentTotalAll[key].sdly[k] += parseFloat(getCorrectValue(item[key].sdly[k]));
                        segmentTotalAll[key].sdly_pickup[k] += parseFloat(getCorrectValue(item[key].sdly_pickup[k]));
                        segmentTotalAll[key].sdly_actual[k] += parseFloat(getCorrectValue(item[key].sdly_actual[k]));
                    })
                    /// End Segment wise Total /// 
                }
            })
        })

        // console.log("segmentTotalAll for otb => ", segmentTotalAll);
        // adding the final total 
        let test = osrSegmentTotal(app_state, auth, segmentTotalAll, segments);
        transformed = transformed.concat(test);
    } 
    
    else if (segmentedOn && tile === 'market_xx') {
        if (app_state.workspace_controls.scopeType === 'Dba') {
            transformed = [];
        } else {
            let marketSegmentData = deepCopy(dataService.getSeriesById("MarketARISegmented").data.data);
            // let marketSegmentData = marketSegmentedJson.data;
            // marketSegmentData = marketSegmentData.slice(0,2);

            let segmentTotalAll = {};
            let blankObj = { Rooms: 0, Occ: 0, ADR: 0, RevPAR: 0, SellRate: 0, Revenue: 0 };

            marketSegmentData.map((item, i) => {
                let marketSegments = Object.keys(marketSegmentData);
                let MarketTotal = deepCopy(item.MarketTotal);
                let index = deepCopy(item.index);
                let ingonreKeys = ['MarketTotalx', 'index'];


                ///////
                // item['MarketSegment_G-Group-Comm'] = deepCopy(item['MarketSegment_G-Group']);
                // let fieldArr1 = Object.keys(blankObj);
                // fieldArr1.map((k) => {

                //     item['MarketSegment_G-Group-Comm'].pickup[k] = parseFloat(item['MarketSegment_G-Group']['pickup'][k]) - parseFloat(item['MarketSegment_G-Group']['pickup_noncomm'][k]);
                //     item['MarketSegment_G-Group-Comm'].primary[k] = parseFloat(item['MarketSegment_G-Group']['primary'][k]) - parseFloat(item['MarketSegment_G-Group']['primary_noncomm'][k]);
                //     item['MarketSegment_G-Group-Comm'].sdly[k] = parseFloat(item['MarketSegment_G-Group']['sdly'][k]) - parseFloat(item['MarketSegment_G-Group']['sdly_noncomm'][k]);
                //     item['MarketSegment_G-Group-Comm'].sdly_actuals[k] = parseFloat(item['MarketSegment_G-Group']['sdly_actuals'][k]) - parseFloat(item['MarketSegment_G-Group']['sdly_actuals_noncomm'][k]);
                // })

                // item['MarketSegment_G-Group-Comm'].pickup["ADR"] = parseFloat(item['MarketSegment_G-Group-Comm'].pickup["Revenue"]) / parseFloat(item['MarketSegment_G-Group-Comm'].pickup["Rooms"])
                // item['MarketSegment_G-Group-Comm'].primary["ADR"] = parseFloat(item['MarketSegment_G-Group-Comm'].primary["Revenue"]) / parseFloat(item['MarketSegment_G-Group-Comm'].primary["Rooms"])
                // item['MarketSegment_G-Group-Comm'].sdly["ADR"] = parseFloat(item['MarketSegment_G-Group-Comm'].sdly["Revenue"]) / parseFloat(item['MarketSegment_G-Group-Comm'].sdly["Rooms"])
                // item['MarketSegment_G-Group-Comm'].sdly_actuals["ADR"] = parseFloat(item['MarketSegment_G-Group-Comm'].sdly_actuals["Revenue"]) / parseFloat(item['MarketSegment_G-Group-Comm'].sdly_actuals["Rooms"])

                // fieldArr1.map((k) => {
                //     item['MarketSegment_G-Group'].pickup[k] = item['MarketSegment_G-Group'].pickup_noncomm[k]
                //     item['MarketSegment_G-Group'].primary[k] = item['MarketSegment_G-Group'].primary_noncomm[k]
                //     item['MarketSegment_G-Group'].sdly[k] = item['MarketSegment_G-Group'].sdly_noncomm[k]
                //     item['MarketSegment_G-Group'].sdly_actuals[k] = item['MarketSegment_G-Group'].sdly_actuals_noncomm[k]
                // })
                /////////////////


                let keysArr = Object.keys(item).sort();
                keysArr.forEach(key => {
                    let tmp = {};
                    if (!ingonreKeys.includes(key)) {
                        let segmentDetails = key;
                        let segmentName = segmentDetails.split('_')[1];
                        if (key === "MarketTotal") { segmentName = "Total"; }
                        if (!segmentName) segmentName = "Other";
                        tmp.MarketTotal = deepCopy(item[key]);
                        tmp.index = deepCopy(item.index);
                        tmp.index.segment = segmentName;
                        transformed.push(tmp);


                        if (!segmentTotalAll[key]) {
                            segmentTotalAll[key] = {
                                pickup: deepCopy(blankObj),
                                pickup_noncomm: deepCopy(blankObj),
                                primary: deepCopy(blankObj),
                                primary_noncomm: deepCopy(blankObj),
                                sdly: deepCopy(blankObj),
                                sdly_actual_noncomm: deepCopy(blankObj),
                                sdly_actual: deepCopy(blankObj),
                                sdly_noncomm: deepCopy(blankObj)
                            };
                        }
                        let fieldArr = Object.keys(blankObj);
                        fieldArr.map((k) => {
                            // console.log(key, " ", k, "item[key].pickup[k] ", item, " "," => ",item[key].pickup[k]);
                            segmentTotalAll[key].pickup[k] += (item[key].pickup[k]) ? parseFloat(item[key].pickup[k]) : 0;
                            segmentTotalAll[key].pickup_noncomm[k] += (item[key].pickup_noncomm[k]) ? parseFloat(item[key].pickup_noncomm[k]) : 0;
                            segmentTotalAll[key].primary[k] += parseFloat(item[key].primary[k]);
                            segmentTotalAll[key].primary_noncomm[k] += parseFloat(item[key].primary_noncomm[k]);
                            segmentTotalAll[key].sdly[k] += parseFloat(item[key].sdly[k]);
                            segmentTotalAll[key].sdly_actual_noncomm[k] += parseFloat(item[key].sdly_actual_noncomm[k]);
                            segmentTotalAll[key].sdly_actual[k] += parseFloat(item[key].sdly_actual[k]);
                            segmentTotalAll[key].sdly_noncomm[k] += parseFloat(item[key].sdly_noncomm[k]);
                            
                            if(key === "MarketSegment_G-Group" && k === "Occ"){
                                console.log(key," ==> " ,segmentTotalAll[key].pickup['Occ']);
                            }
                        })

                        ///
                        fieldArr.map((k) => {
                            if(key === "MarketSegment_G-Group" && k==="Occ"){
                                // segmentTotalAll[key].pickup[k] = segmentTotalAll[key].pickup[k] / 3;
                                console.log(key," segmentTotalAll[key].pickup[k] ",segmentTotalAll[key].pickup[k]);
                            }
                        })
                        ///

                    }
                });
            })
            console.log("segmentTotalAll =>",segmentTotalAll);
            transformed = transformed.concat(marketSegmentTotal(segmentTotalAll, auth, app_state));
        }
    }
    
    else if (segmentedOn && tile === 'market') {
        if (app_state.workspace_controls.scopeType === 'Dba') {
            transformed = [];
        } else {
            let marketSegmentData = deepCopy(dataService.getSeriesById("MarketARISegmented").data.data);
            let segmentTotalAll = {};
            let blankObj = { Rooms: 0, Occ: 0, ADR: 0, RevPAR: 0, SellRate: 0, Revenue: 0 };

            marketSegmentData.map((item, i) => {
                let marketSegments = Object.keys(marketSegmentData);
                let MarketTotal = deepCopy(item.MarketTotal);
                let index = deepCopy(item.index);
                let ingonreKeys = ['MarketTotalx', 'index'];
                let keysArr = Object.keys(item).sort();
                keysArr.forEach(key => {
                    let tmp = {};
                    if (!ingonreKeys.includes(key)) {
                        let segmentDetails = key;
                        let segmentName = segmentDetails.split('_')[1];
                        if (key === "MarketTotal") { segmentName = "Total"; }
                        if (!segmentName) segmentName = "Other";
                        tmp.MarketTotal = deepCopy(item[key]);
                        tmp.index = deepCopy(item.index);
                        tmp.index.segment = segmentName;
                        transformed.push(tmp);
                        if (!segmentTotalAll[key]) {
                            segmentTotalAll[key] = {
                                pickup: deepCopy(blankObj),
                                pickup_noncomm: deepCopy(blankObj),
                                primary: deepCopy(blankObj),
                                primary_noncomm: deepCopy(blankObj),
                                sdly: deepCopy(blankObj),
                                sdly_actual_noncomm: deepCopy(blankObj),
                                sdly_actual: deepCopy(blankObj),
                                sdly_noncomm: deepCopy(blankObj)
                            };
                        }
                        let fieldArr = Object.keys(blankObj);
                        fieldArr.map((k) => {
                            segmentTotalAll[key].pickup[k] += (item[key].pickup[k]) ? parseFloat(item[key].pickup[k]) : 0;
                            segmentTotalAll[key].pickup_noncomm[k] += (item[key].pickup_noncomm[k]) ? parseFloat(item[key].pickup_noncomm[k]) : 0;
                            segmentTotalAll[key].primary[k] += (item[key].primary[k]) ? parseFloat(item[key].primary[k]) : 0;
                            segmentTotalAll[key].primary_noncomm[k] += parseFloat(item[key].primary_noncomm[k]);
                            segmentTotalAll[key].sdly[k] += parseFloat(item[key].sdly[k]);
                            segmentTotalAll[key].sdly_actual_noncomm[k] += parseFloat(item[key].sdly_actual_noncomm[k]);
                            segmentTotalAll[key].sdly_actual[k] += parseFloat(item[key].sdly_actual[k]);
                            segmentTotalAll[key].sdly_noncomm[k] += parseFloat(item[key].sdly_noncomm[k]);
                            
                            if(key === "MarketSegment_G-Group" && k === "Occ"){
                                // console.log(key," ==> " ,segmentTotalAll[key].pickup['Occ']);
                            }
                        })

                        ///
                        fieldArr.map((k) => {
                            if(key === "MarketSegment_G-Group" && k==="Occ"){
                                // segmentTotalAll[key].pickup[k] = segmentTotalAll[key].pickup[k] / 3;
                                // console.log(key," segmentTotalAll[key].pickup[k] ",segmentTotalAll[key].pickup[k]);
                            }
                        })
                        ///

                    }
                });
            })
            console.log("segmentTotalAll in market ==>",segmentTotalAll);
            transformed = transformed.concat(marketSegmentTotal(segmentTotalAll, auth, app_state));
        }
    }
    
    else if (segmentedOn && tile === 'forecast') {
        transformed = forecastSegmentedData(auth, app_state, params);
    } else if (segmentedOn && tile === 'pace') {
        transformed = (app_state.workspace_controls.scopeType === "Date") ? bookingCurveSegmentedDateData(app_state, auth, segments) : bookingCurveSegmentedDbaData(app_state, segments);
    }



    if (transformed.length) {
        return (transformed);
    }
    return (data);
}


function marketSegmentTotal(marketSegmentData, auth, app_state) {
    let transformed = [];

    let marketKeys = Object.keys(marketSegmentData)
    marketKeys.map((key) => {
        let tmp = {
            HotelTotal: {},
            index: { segment: '', date: '' }
        };
        if (key !== "MarketTotal") {
            tmp.MarketTotal = deepCopy(marketSegmentData[key]);
            tmp.index = { date: "" };
            tmp.index.segment = key.split("Segment_")[1];
            transformed.push(tmp);
        }
    })

    let totalCapacity = getAppData(auth, app_state).property_current_total_capacity;
    let mainKey = ["pickup", "pickup_noncomm", "primary", "primary_noncomm", "sdly", "sdly_actual", "sdly_actual_noncomm", "sdly_noncomm"];
    let daysCount = daysCountFromRange(app_state);
    transformed.map((itm) => {
        mainKey.map((key) => {
            let totalCap = totalCapacity * daysCount;
            itm['MarketTotal'][key]['ADR'] = (itm['MarketTotal'][key]['Revenue'] / itm['MarketTotal'][key]['Rooms']);
            itm['MarketTotal'][key]['Occ'] = itm['MarketTotal'][key]['Occ'] / daysCount ; //   (itm['MarketTotal'][key]['Rooms'] / totalCap);
            itm['MarketTotal'][key]['RevPAR'] = (itm['MarketTotal'][key]['RevPAR'] / daysCount);
        })
    })
    return transformed;
}



function osrSegmentTotal(app_state, auth, otbSegmentData, segments) {
    let transformed = [];
    let blankObj = { Rooms: 0, Occ: 0, ADR: 0, RevPAR: 0, SellRate: 0, Revenue: 0 };
    let segmentsList = segments.list;
    let idx = null;
    var tmp = {};
    for (let i = 0; i < segmentsList.length; i++) {
        tmp = {
            HotelTotal: {},
            index: { segment: '', date: '' }
        };
        let name = segmentsList[i].name;
        let segmentCode = "Segment_" + segmentsList[i].code;
        if (!otbSegmentData[segmentCode]) {
            otbSegmentData[segmentCode] = {
                current: deepCopy(blankObj),
                pickup: deepCopy(blankObj),
                primary: deepCopy(blankObj),
                prior: deepCopy(blankObj),
                sdly: deepCopy(blankObj),
                sdly_pickup: deepCopy(blankObj),
                sdly_actual: deepCopy(blankObj)
            };
        }
        tmp.HotelTotal = otbSegmentData[segmentCode];
        tmp.index = { date: "" };
        // tmp.index.segment = name + " Total";
        tmp.index.segment = name;
        transformed.push(tmp);
    }
    let Keys = Object.keys(blankObj);
    let obj = {};
    transformed.forEach((item) => {
        if (obj[item.index.segment]) {
            Keys.forEach(element => {
                obj[item.index.segment]['HotelTotal']['current'][element] = obj[item.index.segment]['HotelTotal']['current'][element] + item['HotelTotal']['current'][element];
                obj[item.index.segment]['HotelTotal']['pickup'][element] = obj[item.index.segment]['HotelTotal']['pickup'][element] + item['HotelTotal']['pickup'][element];
                obj[item.index.segment]['HotelTotal']['primary'][element] = obj[item.index.segment]['HotelTotal']['primary'][element] + item['HotelTotal']['primary'][element];
                obj[item.index.segment]['HotelTotal']['prior'][element] = obj[item.index.segment]['HotelTotal']['prior'][element] + item['HotelTotal']['prior'][element];
                obj[item.index.segment]['HotelTotal']['sdly'][element] = obj[item.index.segment]['HotelTotal']['sdly'][element] + item['HotelTotal']['sdly'][element];
                obj[item.index.segment]['HotelTotal']['sdly_pickup'][element] = obj[item.index.segment]['HotelTotal']['sdly_pickup'][element] + item['HotelTotal']['sdly_pickup'][element];
                obj[item.index.segment]['HotelTotal']['sdly_actual'][element] = obj[item.index.segment]['HotelTotal']['sdly_actual'][element] + item['HotelTotal']['sdly_actual'][element];
            });
        } else {
            obj[item.index.segment] = item
        }
    })
    let calculatedValue = Object.values(obj)
    let totalCapacity = getAppData(auth, app_state).property_current_total_capacity;
    let mainKey = ["current", "pickup", "primary", "prior", "sdly", "sdly_pickup", "sdly_actual"];
    let daysCount = daysCountFromRange(app_state);
    calculatedValue.map((itm) => {
        mainKey.map((key) => {
            let totalCap = totalCapacity * daysCount;
            itm['HotelTotal'][key]['ADR'] = (itm['HotelTotal'][key]['Revenue'] / itm['HotelTotal'][key]['Rooms']);
            itm['HotelTotal'][key]['Occ'] = (itm['HotelTotal'][key]['Rooms'] / totalCap);
            itm['HotelTotal'][key]['RevPAR'] = (itm['HotelTotal'][key]['Revenue'] / totalCap);
        })
    })
    return calculatedValue;
}



function transformedMarketData(app_state, auth, marketSegmentData) {

}






function allSegmentTotal(app_state, auth, otbSegmentData) {
    let transformed = [];
    let data = { Rooms: 0, Occ: 0, ADR: 0, RevPAR: 0, SellRate: 0, Revenue: 0 };
    let segments = Object.keys(otbSegmentData);
    delete segments.HotelTotal;
    let blankObj = { Rooms: 0, Occ: 0, ADR: 0, RevPAR: 0, SellRate: 0, Revenue: 0 };

    let currentTotal = { Rooms: 0, Occ: 0, ADR: 0, RevPAR: 0, SellRate: 0, Revenue: 0 };
    let pickupTotal = { Rooms: 0, Occ: 0, ADR: 0, RevPAR: 0, SellRate: 0, Revenue: 0 };
    let primaryTotal = { Rooms: 0, Occ: 0, ADR: 0, RevPAR: 0, SellRate: 0, Revenue: 0 };
    let priorTotal = { Rooms: 0, Occ: 0, ADR: 0, RevPAR: 0, SellRate: 0, Revenue: 0 };
    let sdlyTotal = { Rooms: 0, Occ: 0, ADR: 0, RevPAR: 0, SellRate: 0, Revenue: 0 };
    let sdly_actualTotal = { Rooms: 0, Occ: 0, ADR: 0, RevPAR: 0, SellRate: 0, Revenue: 0 };
    let sdly_pickupTotal = { Rooms: 0, Occ: 0, ADR: 0, RevPAR: 0, SellRate: 0, Revenue: 0 };
    let row = 1;
    segments.map((name) => {
        if (name !== "index") {
            let tmp = {
                HotelTotal: {},

            };
            if (name !== 'index' && name !== 'Total' && getSegmentName(app_state, auth, name) !== "") {
                if (otbSegmentData.primary === undefined) {
                    otbSegmentData.primary = { ...blankObj };
                }

                tmp.HotelTotal = otbSegmentData[name];
                tmp.index = { date: "" };//deepCopy(item.index);
                tmp.index.segment = truncate(getSegmentName(app_state, auth, name), 12) + " Total";
                transformed.push(tmp);
            }
        }
        row++;
    })
    if (transformed.length) {
        return transformed;
    }
    return data;

}