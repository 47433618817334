import { breadcrumbsClasses } from "@mui/material";
import { integerPropType } from "@mui/utils";
import moment from "moment";
import { convertToPercent, round, getCompsetMedian, setUpDate, getList, dateFormat, getAppData, getObjectFilter, deepCopy, getFromState, getTransformedSegments } from "../../app/util"
import { concatForcastData, getMarketDbaData, concatForcastDataSegmented } from "./tilesData/market";
// import {getBookingCurveSegmentedData } from "./tilesData/bookingcurve"
import { getBookingCurveData, getBookingCurveDbaData, getBookingCurveSegmentedData } from "./tilesData/bookingcurvenew"
import { useDataSummary } from "../Tile/kpiService";
import dataService from "../../services/data.service";
import { getPickupData } from "./tilesData/pickup";
import { getMarketSellRatesData } from "./tilesData/marketsellrates";
import { getOtbSegmentData } from "./tilesData/otb";
import { getForecastNonSegmentedData, getForecastNonSegmentedDataNew } from "./tilesData/forecast";
import { TILES } from "../../app/config";
import { getPricingDataFormatted } from "./tilesData/pricing";
import restDemoData from "./test.json";
import { getReportData_hotel, getReportData_topSegment, getReportSegmentedData_hotel, getReportSegmentedData_topSegment } from "./tilesData/pace_report_functions";
import { getReportData_marketCode, getReportData_roomType,getReportData_ratePlan,getReportData_roomClass } from "./tilesData/marketcode_report_functions";
import { getReportData_segment_production } from "./tilesData/segment_production_report_functions";
import { transformSegmentData } from "./segmentedData";
import { getReportData_revparIndex } from "./tilesData/revpar_index_report_functions";
import { getPickupReportSegmentedData_hotel, getPickupReportData_hotel, getPickupReportSegmentedData_topSegment, getPickupReportData_topSegment } from "./tilesData/pickup_report_functions";
import { getReportData_biSensitivity } from "./tilesData/bi_sensitivity_report_functions";
import { getOtbMultiSegmentReportSegmentedData_hotel, getReportData_otbMultiSegment,getOtbMultiSegmentReportSegmentedData_roomClass } from "./tilesData/otb_multisegment_report_functions";
import { getReportData_RoomTypePushStatus,getReportData_RoomTypePushStatusDateRange,getReportData_dailyRatesendStatus,getReportData_dailyRatesendStatusDateRange } from "./tilesData/rate_push_reports";

export function processDataReports(columns, data, { auth, app_state, ui_state, state }) {
    let setup_data = [];
    const { workspace_controls } = app_state;
    const { sdlyOn } = workspace_controls;
    const { active_tiles } = ui_state;
    let startDate = app_state.primary_stay_range.date_from;
    let endDate = app_state.primary_stay_range.date_until;
    let tableLength = moment(endDate).diff(startDate, 'days') + 1;

    for (let index = 0; index < active_tiles.length; index++) {
        const tile = active_tiles[index];
        let dataKey;

        /* data calculation for Daily Pace by Segment report */
        if (tile === "bi_pace_comparison") {
            if (ui_state.reports_dpbs.selectedCategory === "Hotel") {
                setup_data = (app_state.workspace_controls.segmented_view) ?
                    [...getReportSegmentedData_hotel(columns, auth, app_state, ui_state)]
                    :
                    [...getReportData_hotel(columns, auth, app_state)];
            } else {
                let segmentCodes = getSegmentedCode(auth, app_state, ui_state);
                setup_data = (app_state.workspace_controls.segmented_view) ?
                    [...getReportSegmentedData_topSegment(columns, auth, app_state, segmentCodes)]
                    :
                    [...getReportData_topSegment(columns, auth, app_state, segmentCodes)]
            }
        }

        /** Data caluclation for pick by segment report  */
        if (tile === "bi_pickup_analysis") {
            if (ui_state.reports_dpbs.selectedCategory === "Hotel") {
                setup_data = (app_state.workspace_controls.segmented_view) ?
                    [...getPickupReportSegmentedData_hotel(columns, auth, app_state, ui_state)]
                    :
                    [...getPickupReportData_hotel(columns, auth, app_state)];
            } else {
                let segmentCodes = getSegmentedCode(auth, app_state, ui_state);
                setup_data = (app_state.workspace_controls.segmented_view) ?
                    [...getPickupReportSegmentedData_topSegment(columns, auth, app_state, segmentCodes, ui_state)]
                    :
                    [...getPickupReportData_topSegment(columns, auth, app_state, segmentCodes)]
            }
        }

        /* Data Calculation for Market Code Report */
        if (tile === "productionMarketReport") {
            setup_data = [...getReportData_marketCode(columns, auth, app_state, ui_state)]
        }
        /* End Market Code Report */

        /* Data Calculation for Segment Production Report */
        if (tile === "productionReport") {
            let marketSegments = getFromState('system_settings>market_segment_category_mapping', state);
            let segments = getTransformedSegments(marketSegments);
            let transformedData = transformSegmentData(app_state, auth, [], { segmentedOn: true, tile: "otb", segments: segments });

            setup_data = [...getReportData_segment_production(transformedData, columns, auth, app_state, ui_state)]
        }
        /* End Market Segment Production Report */

        /** Data Calculation for Revpar index report */
        if (tile === "revpar_index") {
            setup_data = [...getReportData_revparIndex(columns, auth, app_state, ui_state)]
        }
        /** End Revpar index */

        /* data calculation for Bi Sensitivity Report */
        if (tile === "bi_sensitivity_analysis") {
            setup_data = [...getReportData_biSensitivity(columns, auth, app_state, ui_state)]
        }

        if (tile === "otb_v2_multi_segment") {
            let marketSegments = getFromState('system_settings>market_segment_category_mapping', state);
            let segments = getTransformedSegments(marketSegments);
            let transformedData = transformSegmentData(app_state, auth, [], { segmentedOn: true, tile: "otb", segments: segments });
            let data = [];
            let segmentType = app_state.workspace_controls.segmentType;

            // segmentType = (segmentType === "rate_plan" && app_state.workspace_controls.rate_plan !== 1) ? "hotel" : segmentType;
            // segmentType = (segmentType === "room_type" && app_state.workspace_controls.room_type !== 1) ? "hotel" : segmentType;

            


            if (app_state.workspace_controls.segmented_view) {
                switch (segmentType) {
                    case "market_segment":
                        data = (app_state.workspace_controls.segmented_view) ? transformedData : deepCopy(dataService.getSeriesById("OSRSegment").data.data);
                        setup_data = [...getReportData_otbMultiSegment(data, columns, auth, app_state, ui_state)]
                        break;
                    case "top_market_segment":
                        setup_data = [...getOtbMultiSegmentReportSegmentedData_hotel(columns, auth, app_state, ui_state)]
                        break;
                    case "room_type":
                        setup_data = [...getReportData_roomType(columns, auth, app_state, ui_state)]
                        break;
                    case "rate_plan":
                        setup_data = [...getReportData_ratePlan(columns, auth, app_state, ui_state)]
                        break;
                    case "room_class":
                        setup_data = [...getOtbMultiSegmentReportSegmentedData_roomClass(columns, auth, app_state, ui_state)]
                        break;
                    default:
                        break;
                }
            } else {
                data = deepCopy(dataService.getSeriesById("OSRSegment").data.data);
                setup_data = [...getReportData_otbMultiSegment(data, columns, auth, app_state, ui_state)]
            }
        } 

        if (tile === "rm_room_type_ratesend_status") {
            setup_data = (app_state.workspace_controls.logsRatePushMode === "date") ? 
                [...getReportData_RoomTypePushStatus(columns, auth, app_state, ui_state)] :
                [...getReportData_RoomTypePushStatusDateRange(columns, auth, app_state, ui_state)] 
        }

        if (tile === "DailyRatesendStatus") {
            setup_data = (app_state.workspace_controls.logsRatePushMode === "date") ? 
            [...getReportData_dailyRatesendStatus(columns, auth, app_state, ui_state)] :
            [...getReportData_dailyRatesendStatusDateRange(columns, auth, app_state, ui_state)] 
        }


    }
    return setup_data;
}

export const getSegmentedCode = (auth, app_state, ui_state) => {
    let settings = getAppData(auth, app_state);
    let market_segment_category_mapping = settings.market_segment_category_mapping;
    let topSegmentsList = [];
    if (ui_state.reports_dpbs.selectedSegment === "All") {
        topSegmentsList = ui_state.reports_dpbs.topSegmentsList;
    } else {
        topSegmentsList = [ui_state.reports_dpbs.selectedSegment]
    }
    let segmentCodes = [];
    if (topSegmentsList && topSegmentsList.length > 0) {
        topSegmentsList.map((item) => {
            if (market_segment_category_mapping[item])
                segmentCodes = [...segmentCodes, ...market_segment_category_mapping[item]];
        })
        segmentCodes = [...new Set(segmentCodes)];
    }
    return segmentCodes;
}

