//React
import React, { useState, useEffect, useReducer } from 'react';
import { useNavigate } from "react-router-dom";

//React Redux
import { useDispatch, useSelector } from 'react-redux';
import { settingsReducers } from './reducers';
//MUI
import {
  Grid, Button, LinearProgress, Badge, Modal,Paper,
  Table, TableBody, TableCell, TableContainer, TableHead, TableRow
} from '@mui/material';
import {
  CloseSharp as CloseSharpIcon,
  ArrowBackIosNewSharp as ArrowBackIosNewSharpIcon,
  ArrowForwardIosSharp as ArrowForwardIosSharpIcon,
  CheckSharp as CheckSharpIcon
}
  from '@mui/icons-material';
import { Box, textAlign } from '@mui/system';

//Moments
import moment from 'moment';

//APP's
import theme from '../../theme';

//Styles
import './style.css';
import SendRate from './SendRate';
import { id } from 'date-fns/locale';
import jsonData from './rate.json';
import { getRatesData } from './getCalData';
import DataService from '../../services/data.service';
import CircularProgress from '@mui/material/CircularProgress';
import { getPricingDataFormatted } from '../DynamicTable/tilesData/pricing';
import { convertToPercent, getSeriesById, deepCopy } from '../../app/util';
import { getData365 } from '../DailyFlash/functions';
import { updateAppState } from '../../actions/appstate';
import dataService from '../../services/data.service';
import { getForecastNonSegmentedDataNew } from '../DynamicTable/tilesData/forecast';
import { API_REGISTRY } from '../../app/config';
const weekdayStyle = {
  lineHeight: '31px',
  color: '#ADAFB2',
  textTransform: 'uppercase',
  textAlign: 'center'
};

const DateRecRate = (props) => {
  const day = props.day;
  const rec = '$' + day.rec;
  var recType = day.rec > day.curr ? 'positive' : 'negative';
  if (day.rec == day.curr) recType = 'neutral';
 
  return (
    <div className={'date-rec-rate ' + recType}>
      {!props.loading && <span dir="ltr">{rec}</span>}
      {props.loading && <small dir="ltr" style={{fontSize:"11px"}}> Loading...</small>}
    </div>
  )
}

function DayDetailsModal(props) {
  const [open, setOpen] = React.useState(false);
  const [dayData, setDayData] = useState(props.day)
  const handleOpen = () => {
    setDayData(props.day);
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };
  let overLayClass ="date-overlay"
  let isPastDate = false;
  if(moment(props.day.dateStr).diff(props.system_as_of, 'days')<0){
    isPastDate = true;
  }
  if(props.day.greyable){
    overLayClass=""
  }
  return (
    <React.Fragment>
      <Button disabled={props.day.greyable} onClick={handleOpen} 
      className={overLayClass} style={{ visibility: isPastDate ? 'hidden' : 'visible' }}></Button>
      <Modal
        hideBackdrop
        open={open}
        onClose={handleClose}
        aria-labelledby="child-modal-title"
        aria-describedby="child-modal-description"
      >
        <Box className='pricingDetailsPopup'
        sx={{
          position: 'absolute',
          top: '50%',
          left: '50%',
          transform: 'translate(-50%, -50%)',
          bgcolor: '#333',
          boxShadow: 24, 
          borderRadius: '5px'
        }}>

          <Box sx={{ float: 'right', mr: -2.5 }}>
            <Button sx={{
              zIndex: 999,
              minWidth: 'auto', position: 'absolute', top: -10, right: -10, width: '22px', height: '22px', p: '7px',
              borderRadius: '50%', borderWidth: '1px', borderStyle: 'solid',
              borderColor: '#ccc', boxShadow: '0 0 4px #000', background: '#000', color: '#FFF', fontSize: '10px',
              '&:hover': { backgroundColor: '#000' }
            }} onClick={handleClose}>
              <CloseSharpIcon fontSize='small' />
            </Button>
          </Box>

          <Box sx={{ color: 'white.main', height: '100%' }}>
            <Grid container sx={{ height: '100%' }}>
              <Grid item xs={4}>
                <Box sx={{ ml: 2, mr: 2, mt: 3 }}>
                  <h2 className="day-title">
                    {dayData.date.format('dddd MMMM DD')}
                  </h2>
                  <Grid container display='flex' spacing={1.2}>

                    <Grid item xs={6}>
                      <Box className='rate-box' sx={{ backgroundColor: '#fff', p: 1, height: '100px' }}>
                        <div className='rate-title'>CURRENT RATE</div>
                        <div className='rate'>${dayData.otb.primary.SellRate}</div>
                      </Box>
                    </Grid>
                    <Grid item xs={6}>
                      <Box className='rate-box' sx={{ backgroundColor: '#fff', p: 1, height: '100px' }}>
                        <div className='rate-title'>MEDIAN RATE</div>
                        <div className='rate'>${dayData.compSet.Median && dayData.compSet.Median.sell_rate}</div>
                      </Box>
                    </Grid>
                    <Grid item xs={6}>
                      <Box className='rate-box' sx={{ backgroundColor: '#fff', p: 1, height: '100px' }}>
                        <div className='rate-title'>MIN RATE</div>
                        <div className='rate'>${dayData.compSet.Min && dayData.compSet.Min.sell_rate}</div>
                      </Box>
                    </Grid>
                    <Grid item xs={6}>
                      <Box className='rate-box' sx={{ backgroundColor: '#fff', p: 1, height: '100px' }}>
                        <div className='rate-title'>MAX RATE</div>
                        <div className='rate'>${dayData.compSet.Max && dayData.compSet.Max.sell_rate}</div>
                      </Box>
                    </Grid>
                    <Grid item xs={12} sx={{marginTop:"5px", textAlign: 'center'}}>
                      <Box className='rate-box' sx={{ p: 1 }}>
                        <div className='compset-asof'>
                          COMPSET AS OF {moment(props.system_as_of).format('ddd, MMM DD YYYY')}
                        </div>
                      </Box>
                    </Grid>

                  </Grid>
                </Box>
              </Grid>
              
              <Grid item xs={8}>
                <Box className='competitor-current-rate' sx={{ background: '#fff', borderRadius:100 }}>
                  <CompsetTable compSet={dayData.compSet} />
                </Box>
              </Grid>
             
            </Grid>

          </Box>
        </Box>
      </Modal>
    </React.Fragment>
  );
}

function CompsetTable({compSet}) {
  const rows = [];
  Object.keys(compSet).forEach(key=>{
    if(key.includes("compset_") ){
      let sell_rate = compSet[key].sell_rate;
      if(sell_rate === 0){
        sell_rate='N/A';
      }
      rows.push(createData(key.replace("compset_",""), sell_rate))
    }
  })
  
  function createData(
    compset,
    sellRate
  ) {
    return { compset, sellRate };
  }
  return ( <Paper sx={{ width: '100%', overflow: 'hidden'}}>
    <TableContainer sx={{ maxHeight: "50vh" }}>
      <Table stickyHeader sx={{}} aria-label="sticky simple table">
        <TableHead>
          <TableRow sx={{ backgroundColor: '#606060', '& td, & th': { pt: 1, pb: 1 },  }}>
            <TableCell sx={{ color: '#fff',backgroundColor:"#606060" }}>COMPETITOR	</TableCell>
            <TableCell align="right" sx={{ color: '#fff', width: 150,backgroundColor:"#606060" }}>CURRENT RATE</TableCell>
          </TableRow>
        </TableHead>
        <TableBody className="pricing-compset-t-body">
          {rows.map((row) => (
            <TableRow
              key={row.compset}
              sx={{ '&:last-child td, &:last-child th': { border: 0 }, '& td, & th': { pt: 1, pb: 1, fontSize: 14 } }}
            >
              <TableCell component="th" scope="row">
                {row.compset}
              </TableCell>
              <TableCell component="th" scope="row" align='right'>
                {row.sellRate != "N/A" ? ('$' + row.sellRate) : row.sellRate}
              </TableCell>

            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
    </Paper>
  )
}

const PriceCalendar = (props) => {
  const color = theme.palette;
  const dispatch = useDispatch();
  const { app, auth } = useSelector((state) => state);
  const { user, profile } = auth;
  const { app_state, ui_state } = app.current_state;
  const {primary_stay_range} =app_state;
  let asOfMonth = parseInt(moment(primary_stay_range.as_of_date).format("M"));
  let dateFromMonth = parseInt(moment(primary_stay_range.date_from).format("M"));
  let diff =dateFromMonth - asOfMonth;
  const [calMonth, setCalMonth] = useState(diff);
  const [callYearAPI, setCallYearAPI] = useState(false);
  const [currUntilDate, setCurrUntilDate] = useState(primary_stay_range.date_until);
  
  
  
  const calendarDate  = new moment(primary_stay_range.as_of_date).add(calMonth, 'M');
  const firstWeekDay = new moment(calendarDate).startOf('month').startOf('week');
  const lastWeekDay = new moment(calendarDate).endOf('month').endOf('week');
  const numDays = lastWeekDay.diff(firstWeekDay, 'days');
  let today = new moment(primary_stay_range.as_of_date);
  const DaysToUpdateCount = (days) => {
    // console.log("days====", days);
    let count = 0;
    calendar.days.map(item=>{
      if(!item.isPastDate){
        count++;
      }
    })
    // count = moment( lastWeekDay).diff( firstWeekDay, 'days') 
    return count;
  }
  let monthName = new moment(primary_stay_range.as_of_date).add(calMonth, 'M').format('MMMM YYYY');
  // sendRate Popup State
  const [openSendRatePopup, setOpenSendRatePopup] = useState(false);
  const [CompsetData, setCompsetData] = useState([]);
  const [OtbData, setOtbData] = useState([])
  const handleOpenSendRatePopup = () => {
    setOpenSendRatePopup(true);
  }
  const handleCloseSendRatePopup = () => {
    setOpenSendRatePopup(false);
    dispatch(updateAppState("stay_range",{
      app_state: {
     
      
        data_load: false, kpi_load: false
      },
      ui_state: { showspinner: false }
    }))
  }
  const [loading, setLoading] = useState(true)
  const [recRates,setRecRates] = useState([]);
  const [system_as_of, setSystemAsOf] = useState(app_state.as_of_date)
  const calendar = {
    month: monthName,
    days: []
  }
  useEffect(() => {
    let loadingStates=0;
    if(getSeriesById("OSRRateRecommend").data){
      const PricingData =  getPricingDataFormatted(app_state, false);
      setRecRates(PricingData);
      loadingStates+=1;
    }
    if(getSeriesById("OSR").data){
      const OtbData = getSeriesById("OSR").data.data; 
      setOtbData(OtbData);
      loadingStates+=1;
    }
    if(getSeriesById("Compset").data){
      const CompsetData = getSeriesById("Compset").data.data;
      setCompsetData(CompsetData);
      loadingStates+=1;
    }
    if(loadingStates===3){
      setLoading(false);
    }
    getNextYearData(primary_stay_range.date_from)
   
    
  },[])
  const  getNextYearData =(date_from, callBack)=>{
    const data_sources =["OSRRateRecommend", "OSR","Compset"];
    const mainPromises=[];
    let rangeUntilDate = moment(date_from).add(1, "year").format('YYYY-MM-DD');

    data_sources.forEach(src=>{
      const apiCallStarting = getData365({date_from,...app_state, removeCompId:true},src);
      setCurrUntilDate(rangeUntilDate);
      mainPromises.push(apiCallStarting);
    })
    
    Promise.allSettled(mainPromises).then(settled=>{
      const dataArray = settled.map((item, index) => {
          if (item.status === 'fulfilled') {
              
              return {...item.value, data_id:data_sources[index]};
          } else {
              return { data: [], status: 'error', data_id: item.reason.data_id, message: item.reason.message };
          }
      });
      console.log(dataArray);
      dataArray.forEach((res)=>{
        if(res.status === "success"){
          const finalData = { data: deepCopy(res.data) };
            finalData.data_id = res.data_id;
            finalData.ok = true;
          // DataService.updateDataCache(res.data_id , { data: finalData, loaded: true, loading: false});

          if(res.data_id === "Compset"){

            setCompsetData(res.data);
          }
          if(res.data_id === "OSR"){
            setOtbData(res.data);
          }
          if(res.data_id === "OSRRateRecommend"){
            setSystemAsOf(res.meta.metadata.args.asof_date);
            const PricingData = getPricingDataFormatted({primary_stay_range:{
              date_from:date_from,
              date_until:rangeUntilDate,
            }, room_settings:app_state.room_settings}, false,res.data);

            setRecRates(PricingData);
          }
        }
      });
      setLoading(false);
      if(callBack){
        callBack()
      }
      setCallYearAPI(true);
    }).catch(err=>{
      console.log(err);

    })
  }
  // useEffect(() => {
  // }, [app_state.property_id,calMonth])

  //console.log("json d Response is =>",recRates); 

  const [ratesChecked, setRatesChecked] = useState([]);
  for (let i = 0; i <= numDays; i++) {
    let startWeek = new moment(firstWeekDay);
    let date = startWeek.add(i, 'day');
    let dateStr = date.format("YYYY-MM-DD");
    let rates={RecSellRate:0, SellRate:0};
    let compSet = {};
    let otb ={
      primary:{
        Occ:0
      }
    };
    CompsetData.forEach(item=>{
      const stay_date = item.index.date;
      if(stay_date === dateStr){
        compSet=(({ index, ...o }) => o)(item)
      }
    })
    OtbData.forEach(item=>{
      const stay_date = moment(item.index.date).format("YYYY-MM-DD");
      if(stay_date === dateStr){
        otb=item.HotelTotal;
      }
    })
    let found = false;
    recRates.forEach(item=>{
      if (item.stay_date === dateStr){
        rates = item;
        found= true;
      }
      
    });
    let active = false
    ratesChecked.map(item=>{
      if(item.stay_date === dateStr){
          active = true;
      }
    })
    
    let day = {
      name: date.format("dd").substring(0, 1),
      number: date.date(),
      isCurrentMonth: date.format('YYYY-MM') === calendarDate.format('YYYY-MM'),
      isPrevMonth: date.isBefore(today, 'month') ? true : false,
      isNextMonth: date.isAfter(today, 'month') ? true : false,
      isWeekStart: date.weekday() == 0 ? true : false,
      isWeekEnd: date.weekday() == 6 ? true : false,
      isPastDate: date.isBefore(today, 'day') ? true : false,
      isFutureDate: date.isAfter(today, 'day') ? true : false,
      isToday: date.isSame(new Date(), "day"),
      hasEvent: false,
      date: date,
      dateStr: dateStr,
      updated: false,
      rec: rates.RecSellRate,//recommended_sell_rate,
      curr: rates.SellRate,//current_price,
      compSet,
      otb,
      dateActive:active,
    };
    if(!found ){
      day.loading_day= true;
    }else {
      day.loading_day = false;
    }
    if(day.isCurrentMonth === false){
      day.greyable = true;
    }
    calendar.days.push(day);
  }

  // alert(calendar.days.length );
  const initalState = {
    data: {},
    rateCount: 0,
    daystoUpdate: DaysToUpdateCount(calendar.days),
    ui: {
      status: "idle",
      message: "",
    }

  };
  // alert(calendar.days[0].dateStr);
  const [settingsState, dispatchSettings] = useReducer(settingsReducers, initalState);
  const { ui, data, rateCount, daystoUpdate } = settingsState;
  const [calendarDays, setCalendarDays] = useState(calendar.days);
  // console.log(calendar.days[0].dateStr+" => "+ calendar.days[calendar.days.length-1].dateStr);

  const [calStartEndDates,setCalStartEndDates] = useState(calendar);
  
  const showNextMonth = () => {
    if(callYearAPI === false){
      setLoading(true);
    }
    let currMonth = calMonth+1;
    const calendarDate  = new moment(primary_stay_range.as_of_date).add(currMonth, 'M');
    if(moment(currUntilDate).diff(calendarDate, 'months')<2){
      setLoading(true);
      getNextYearData(calendarDate.startOf('month').format("YYYY-MM-DD"), ()=>{
        setCalMonth(calMonth+1);
      })
    }else {
      setCalMonth(month=>{
        console.log("month",month)
    
        return month+ 1;
      });
    }
    
    // const calendarMonth = new moment(primary_stay_range.as_of_date).startOf('month').add(month, 'months');
    // console.log(calendarMonth);
    // const date_from =moment(calendarMonth).startOf('month').format("YYYY-MM-DD");
    // const date_until =moment(calendarMonth).endOf('month').format("YYYY-MM-DD");
    // primary_stay_range.date_from = date_from;
    // primary_stay_range.date_until = date_until;
    // dispatch(updateAppState("stay_range",{
    //   app_state: {
    //     // primary_stay_range:{
    //     //   ...primary_stay_range,
    //     // },
    //     // date_range_type:'range' ,
    //     // data_load: true, kpi_load: true
    //   },
    //   // ui_state: { showspinner: true }
    // }))

   
  }
  const showPrevMonth = (val) => {
    setCalMonth(val - 1);
  }



  const [sendRatesCount, setSendRatesCount] = useState(0);


  //const [daystoUpdate, setDaysToUpdate] = useState(DaysToUpdateCount(calendarDays));

  // console.log( "total days => ", );
  // setDaysToUpdate();
  const handleChecked = (event) => {
    event.currentTarget.classList.toggle('active');
    let checkedLength = document.getElementsByClassName("btn-plus active").length;
    console.log("toggle click")
    if (event.target.className === "btn-plus active") {
      // if(Object.keys(data).length !== 0){
      //   ratesChecked.push(data);
      // }
      console.log("obj length=> ", Object.keys(data).length);
      let currentRate = event.currentTarget.getAttribute("data-curr");
      let recRate = event.currentTarget.getAttribute("data-rec");
      let dateChecked = event.currentTarget.getAttribute("data-dateStr");
      let id = dateChecked.replaceAll("-", "");
      let dataObj = {
        id: id,
        date: dateChecked,
        stay_date:dateChecked,
        currentRate: currentRate,
        approvedRate: recRate,
        current_rate:currentRate,
        override_rate:recRate,
      }

      console.log(ratesChecked);
      console.log(data, " count=>", data.length);
      ratesChecked.push(dataObj);
      setRatesChecked(prev=>{
        return [...ratesChecked];
      })
      // setDaysToUpdate(daystoUpdate-1)
      //data.push(dataObj);

      let dtoUpdate = daystoUpdate - 1;
      console.log("Creating Data=>", ratesChecked);
      dispatchSettings({ type: "rateCountUpdate",daystoUpdate:dtoUpdate, checkedLength: checkedLength, data: ratesChecked });
    } else {
      let dateChecked = event.currentTarget.getAttribute("data-dateStr");
      let id = dateChecked.replaceAll("-", "");
      let rows = ratesChecked;
      let filteredData = rows.filter(e => e.id !== id)
      console.log("removing ...")
      setRatesChecked(prev=>{
        return [...filteredData];
      })
      let dtoUpdate = daystoUpdate + 1;
      event.currentTarget.classList.remove("active")
      dispatchSettings({ type: "rateCountUpdate", daystoUpdate: dtoUpdate, 
      checkedLength: filteredData.length, data: [...filteredData] });
    }
    
    // let value = event.currentTarget.getAttribute("data");
    //let value = event.target.attributes.getNamedItem("btn-plus active"); 
 
    // event.currentTarget.classList.add('active');
    // event.currentTarget.classList.remove('bg-salmon');
  };

  const handleDelete = (index, id) => {
    const rateRows = data.filter(
      (rows) => rows.id !== id
    );
    setRatesChecked(prev=>{
      return [...rateRows];
    }) 

    document.getElementById(id).classList.remove("active");
    let dtoUpdate = daystoUpdate + 1;
    dispatchSettings({ type: "rateCountUpdate", daystoUpdate: dtoUpdate, checkedLength: rateRows.length, data: rateRows });
  }
  const handleRatePush = (dayData) => {
      let { uri } = API_REGISTRY["SendRatePush"];

      uri+=app_state.property_id+"/";
      let request={};
      const pricingData = getPricingDataFormatted(app_state);
      const forecastData = getForecastNonSegmentedDataNew();
      const otbData = deepCopy(dataService.getSeriesById("OSR").data.data);
      let rates={},otb = {}, forecast = {};
      if (dayData) {
          console.log("");
        
              request = {
                  ...API_REGISTRY["SendRatePush"].payload,
                  "user_id": user.user_id,
                  "pid": app_state.property_id,
                  "asof_date": app_state.primary_stay_range.asof_date,
                  "rate_updates":[]
                
              }
              request["segment_type"]= "market_code";
              request["rate_code"]= "TBAR";
              pricingData.map(pricing => {
                  if (pricing.stay_date === dayData.stay_date) {
                      rates = pricing;
                  }
              })
              forecastData.map(fdata => {
                  if (fdata.index.date === dayData.stay_date) {
                      forecast = fdata.HotelTotal.primary;
                  }
              })
              otbData.map(onthebooks => {
                  if (onthebooks.index.date === dayData.stay_date) {
                      otb = onthebooks.HotelTotal.primary;
                  }
              })
              let user_action = (dayData.override_rate  === rates.RecSellRate) ? "recommendation_accept" :"overridden";

              request.rate_updates.push({
                  "stay_date": dayData.stay_date,
                  "new_rate": Number(dayData.override_rate),
                  "old_rate": Number(dayData.current_rate),
                  "system_recommended_rate": rates.RecSellRate,
                  // "user_override_rate": dayData.override_rate,
                  "user_action": user_action,
                  "snapshot": {
                      "otb": { rooms: otb.Rooms, occ: otb.Occ, adr: otb.ADR, revenue: otb.Revenue },
                      "forecast": { rooms: forecast.Rooms, occ: forecast.Occ, adr: forecast.ADR, revenue: forecast.Revenue },
                      "rates": { "current_rate": rates.RecSellRate, "recommended_rate": rates.SellRate }
                  },
                  //"rule_rate": 900 // hardCode
              })
           
            const rateRows = data.filter(
                (rows) => rows.stay_date !== dayData.stay_date
            );
            setRatesChecked(prev=>{
                return [...rateRows];
            }) 
          // setRows(prevRows=>{
          //     return [...prevRows.filter(row=>{
          //         if(row.stay_date !== dayData.stay_date){
          //             row.user_override=null;
          //             return row
          //         }
          //     })]
          // });
          dispatchSettings({ type: "rateCountUpdate",checkedLength: rateRows.length, data: rateRows });

          // dispatch(updateAppState("confirmed_rates_success", {
          //     app_state: {
          //         pricing_override_rates: {...app_state.pricing_override_rates},
          //         roomtype_pricing_overrides:{...app_state.roomtype_pricing_overrides}
          //     },
          //     ui_state: { pricingRateModalOpen: false }
          // }))
      
      } else {

          
              
              request = {
                  ...API_REGISTRY["SendRatePush"].payload,
                  "user_id": user.user_id,
                  "pid": app_state.property_id,
                  "asof_date": app_state.primary_stay_range.asof_date,
                  "rate_updates":[]
                
              }
              request["segment_type"]= "market_code";
              request["rate_code"]= "TBAR";
              settingsState.data.map(dataI => {
                  pricingData.map(pricing => {
                      if (pricing.stay_date === dataI.stay_date) {
                          rates = pricing;
                      }
                  })
                  forecastData.map(fdata => {
                      if (fdata.index.date === dataI.stay_date) {
                          forecast = fdata.HotelTotal.primary;
                      }
                  })
                  otbData.map(onthebooks => {
                      if (onthebooks.index.date === dataI.stay_date) {
                          otb = onthebooks.HotelTotal.primary;
                      }
                  })
                  let user_action = (dataI.override_rate  === rates.RecSellRate) ? "recommendation_accept" :"overridden";
                  request.rate_updates.push({
                      "stay_date": dataI.stay_date,
                      "new_rate": Number(dataI.override_rate),
                      "old_rate": Number(dataI.current_rate),
                      "system_recommended_rate": rates.RecSellRate,
                      // "user_override_rate": data.override_rate,
                      "user_action":user_action,
                      "snapshot": {
                          "otb": { rooms: otb.Rooms, occ: otb.Occ, adr: otb.ADR, revenue: otb.Revenue },
                          "forecast": { rooms: forecast.Rooms, occ: forecast.Occ, adr: forecast.ADR, revenue: forecast.Revenue },
                          "rates": { "current_rate": rates.RecSellRate, "recommended_rate": rates.SellRate }
                      },
                      //"rule_rate": 900 // hardCode
                  })
              })
              setRatesChecked([]);
        
              dispatchSettings({ type: "rateCountUpdate",checkedLength: 0, data: [] });
      }
      dataService.getData({api:uri,request}).then((res)=>{
          console.log(res)
          // if(!dayData){
          //     setRatePushSuccessOpen(true);
          //     setTimeout(()=>{
          //         setRatePushSuccessOpen(false);
          //         props.onClose();
          //     },3000)
          // }
          
      }).catch(err=>{
          console.log(err);
      })

    
    
  }
  return (
    <Box 
    className='pricingCalenderPopup'
    sx={{
      position: 'absolute',
      top: '50%',
      left: '50%',
      transform: 'translate(-50%, -50%)',
      bgcolor: 'background.paper',
      border: '1px solid #333',
      boxShadow: 24, borderRadius: '5px', overflow: 'hidden'
    }}>
      <div className="pop-up-header clearfix request-popup-header">
        <Box sx={{ float: 'left' }}>
          <div className="tile-title" data-tile-icon="icon-icon-events" data-id="events">
            <span className="tile-name"> Pricing Calendar</span>

          </div>
        </Box>
        <Box sx={{ float: 'right' }}>
          <Button sx={{ minWidth: 'auto', width: '22px', height: '22px', p: '7px', borderRadius: '50%', background: '#000', color: '#FFF', fontSize: '10px' }} onClick={props.handleClose}>
            <CloseSharpIcon fontSize='small' />
          </Button>
        </Box>
      </div>

      <Box sx={{ color: 'white.main', background: '#606060' }}>
        <Grid container>
          <Grid item xs={1.6} sx={{ background: '#434343', pt: .8, pb: .8, pl: 0, pr: 0 }}>
            <Box display='flex' justifyContent='space-between'>
              <Box>
                <Button sx={{ minWidth: 'auto', p: 0 }}>
                  <ArrowBackIosNewSharpIcon onClick={() => showPrevMonth(calMonth)} sx={{ color: '#ADAFB2', '&:hover': { color: 'blue.main' } }} />
                </Button>
              </Box>
              <Box sx={{ lineHeight: 1.5, textAlign: 'center', textTransform: 'uppercase', color: '#ADAFB2' }}>{calendar.month}</Box>
              <Box>
                <Button onClick={() => showNextMonth(calMonth)} sx={{ minWidth: 'auto', p: 0 }}><ArrowForwardIosSharpIcon sx={{ color: '#ADAFB2', '&:hover': { color: 'blue.main' } }} /></Button>
              </Box>
            </Box>

          </Grid>
          <Grid item>
            {/* <Box className="lqe-update-approve" sx={{ pl: 3 }}>
              <Badge badgeContent={daystoUpdate} className='days-update-count' sx={{ mr: 2, ml: 1, mr: 1, left: 'auto', top: 'auto', backgroundColor: 'green.main' }} />
              <span className='days-update-label'>Days to Update</span>
            </Box> */}
          </Grid>
          <Grid item sx={{ marginLeft: 'auto', pr: 1, pt: 0.5, pb: 0.5, textTransform: 'uppercase', fontSize: '13px' }}>
            <Badge badgeContent={settingsState.rateCount} color='green' sx={{ top: 0 }} anchorOrigin={{

              vertical: 'top',
              horizontal: 'left',
            }}>
              <Button onClick={()=>{
                if(rateCount > 0){
                  handleOpenSendRatePopup() 
                }
              }} sx={{ color: 'white.main', fontSize: '13px', p: 0, mb: 0.2, '&:hover': { backgroundColor: 'dark.main' } }}>
                <img
                  src={`../assets/pricing-calendar/sendrates.png`}
                  alt={`SENT RATES`}
                  height="50%"
                  loading="lazy" style={{ marginRight: '5px' }}
                />
                Send Rates

              </Button>
            </Badge>
          </Grid>
        </Grid>
      </Box>
      {/* send rates popup */}
      {console.log(settingsState.data, "here")}
      <SendRate action={openSendRatePopup} handleDelete={handleDelete} handleRatePush={handleRatePush}
      rateRows={settingsState.data} onClose={handleCloseSendRatePopup} />
      {/* end Send Rate Popup */}
      <Box sx={{ width: '100%', position: 'absolute' }}>
        {loading && <LinearProgress color='green' />}
      </Box>
      <Box sx={{ background: '#EEE' }}>
        <Grid container justifyContent='space-between' sx={{ ...weekdayStyle, pt: 1, pb: 0 }}>
          <Grid item xs={1.714}>Sunday</Grid>
          <Grid item xs={1.714}>Monday</Grid>
          <Grid item xs={1.714}>Tuesday</Grid>
          <Grid item xs={1.714}>Wednesday</Grid>
          <Grid item xs={1.714}>Thursday</Grid>
          <Grid item xs={1.714}>Friday</Grid>
          <Grid item xs={1.714}>Saturday</Grid>
        </Grid>
      </Box>
      <Box sx={{ pb: 2, background: '#EEE', }}>
        <Grid container justifyContent='center' spacing={1} sx={{ overflow: 'auto', textAlign: 'center' }}>
            { 
                    console.log("calendar=> ",calendar)

            }
          {
            calendar.days.map((day,index) => {
              let keyId = day.dateStr.replaceAll("-", "");
              let btnClass = "btn-plus";
              if(day.dateActive){
                btnClass+=" active";
              }
              let checkvisable = true;
              let greyclass =""
              let overLayClass = "date-overlay"
              if(day.greyable){
                greyclass="grey-section";
                checkvisable = false;
                overLayClass=""
              }
             
              if(day.isPastDate){
                checkvisable = false;
              }
   
              return (
                <Grid item xs={1.714} sx={{ background: 'white.main', mt: 0.7 }} key={index}>
                <Box className={"date-box "+greyclass} sx={{ background: '#fff', minHeight: '92px', height: '92px', position: 'relative', ml: day.isWeekStart ? 2 : .4, mr: day.isWeekEnd ? 2 : .4 }} borderRadius={1}>
                  <span className={overLayClass} style={{ visibility: day.isPastDate ? 'hidden' : 'visible' }}></span>
                  <DayDetailsModal day={day} system_as_of={system_as_of} />
                  <span className="date-occ" style={{ visibility: day.isPastDate ? 'hidden' : 'visible' }}>OCC: {convertToPercent(day.otb.primary.Occ)}%</span>
                  <div className="rec-rate addupdate">
                    <div 
                     
                      onClick={(e)=>{
                        handleChecked(e);
                      }}
                      className={btnClass}
                      id={keyId}
                      data-dateStr={day.dateStr} data-curr={day.curr} data-rec={day.rec} 
                      style={{ visibility: !checkvisable ? 'hidden' : 'visible' }}>
                    </div>
                  </div>
                  <Box className="date-number">
                    {day.number}
                  </Box>

                  <Box sx={{ position: 'absolute', width: '100%', top: '30%', visibility: day.isPastDate ? 'hidden' : 'visible' }}>
                    <DateRecRate day={day} loading={day.loading_day} greyable={day.greyable} />
                  </Box>
                  <Box className="date-footer" sx={{ visibility: day.isPastDate ? 'hidden' : 'visible' }}>
                    {!day.loading_day && (<div className="date-curr-rate"><span dir="ltr">{ui_state.currency_symbol} {day.curr}</span></div>)}
                  </Box>
                </Box>
              </Grid>
              )
            }
            )
          }
        </Grid>
      </Box>
    </Box>


  )
}
export default PriceCalendar;