import "./style.css";

import EditIcon from '@mui/icons-material/Edit';
import CalendarMonthOutlinedIcon from '@mui/icons-material/CalendarMonthOutlined';
import ScheduleOutlinedIcon from '@mui/icons-material/ScheduleOutlined';
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';
import KeyboardArrowUpOutlinedIcon from '@mui/icons-material/KeyboardArrowUpOutlined';
import {
  Alert,
  Autocomplete,
  Badge,
  Box,
  Button,
  ButtonGroup,
  Checkbox,
  Chip,
  CircularProgress,
  Collapse,
  Divider,
  FormControl,
  Grid,
  Input,
  InputLabel,
  MenuItem,
  Paper,
  Select,
  TextField,
  Tooltip,
  Typography,
  Switch,
  IconButton,
  InputAdornment,

} from "@mui/material/";
import {
  SaveOutlined as SaveOutlinedIcon,
  ArrowForwardIosOutlined as ArrowForwardIosOutlinedIcon,
  KeyboardArrowDownOutlined as KeyboardArrowDownOutlinedIcon,
  DeleteOutlineOutlined as DeleteOutlineOutlinedIcon,
  ClearOutlined as ClearOutlinedIcon,
  CalendarMonth as CalendarMonthIcon
} from '@mui/icons-material';
// import DeleteOutlineOutlinedIcon from '@mui/icons-material/DeleteOutlineOutlined';

import { DatePicker, MobileDatePicker, PickersDay, MobileTimePicker } from "@mui/x-date-pickers/";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import moment from "moment";
import AddCircleOutlineRoundedIcon from '@mui/icons-material/AddCircleOutlineRounded';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';

import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';

import FormLabel from '@mui/material/FormLabel';

import React, { useEffect, useReducer, useRef, useState } from "react";
import { useSelector } from "react-redux";
import { API_DI_CERT } from "../../app/config";
import dataService from "../../services/data.service";
import {
  AddNewItem,
  Breadcrumb,
  CancelButton,
  CollapseButton,
  DeleteButton,
  PreviewButton,
  RatePushSchedulePreview,
  SaveButton,
  SettingsTitle,
  ToggleSwitch,
  UserAlertInfo,
  UserConfirmationAlert,
} from "./comp.static";

import { settingsReducers } from "./reducers";
// import { useData } from "../../hooks/";
import { deepCopy } from "../../app/util";

export default function ScheduleRatePushSettings(props) {
  const [isrequesting, setIsRequesting] = useState(false);
  const [requestData, setRequestData] = useState({ schedules: [] });

  const [compState, setCompState] = useState({
    editable: '',
    schedules: [],
    expanded: { id_0: true },
    skip_keys: ['expanded', 'owner', 'isChangeRequest'],
  });

  const updateCompState = (key, value) => {
    let keys = key ? key.split('>') : [];
    //Handling single deptch key/value pairs
    if (keys[0] && !keys[1]) {
      compState[keys[0]] = value;
    }
    //Handling double deptch key/value pairs
    if (keys[0] && keys[1]) {
      compState[keys[0]] = compState[keys[0]] ? compState[keys[0]] : {};
      compState[keys[0]][keys[1]] = value;
    }
    setCompState((state) => {
      return { ...state, ...compState };
    });
  }

  const toggleEditable = (e, fieldKey, index, item) => {
    const event = e;
    requestData.schedules[index].editing = fieldKey;
    setRequestData({ ...requestData });
    // updateCompState('editable', fieldKey);
    // debugger;
  }

  const label = { inputProps: { 'aria-label': 'Switch demo' } };

  const handleDelete = (params) => {
    const { date, index } = params;
    return (e) => {
      let dateIndex = requestData.schedules[index].skip_dates.indexOf(date);
      // const id = "id_" + index;
      // ui.dataChanged[id] = true;
      requestData.schedules[index].skip_dates.splice(dateIndex, 1);
      updateRequestData("skip_dates", requestData.schedules[index].skip_dates, index);
      // setRequestData({ ...requestData });
      // debugger;
    }
  }
  console.info('You clicked the delete icon.');

  const [settingsState, dispatchSettings] = useReducer(
    settingsReducers,
    initalState
  );

  // debugger;

  const { app, auth } = useSelector((state) => state);
  const { user, profile } = auth;
  const { app_state } = app.current_state;
  const { property_details } = profile || [];
  let system_settings = {};
  try {
    system_settings = property_details.filter(
      (item) => item.id === app_state.property_id
    )[0].system_settings[0];
  } catch (error) {
    console.error("room_types can not be resoved");
  }
  const { ui, data } = settingsState;
  const { room_settings } = system_settings.inventory_settings.current;
  ui.room_type_codes = room_settings.map((item) => {
    if (item.rate_push_enabled) return item.room_code;
  });

  // const requestData = { scheduled_ratepush: [] };
  
  const request = {
    property_id: app_state.property_id,
  };
  // const schedules = useData({ type: 'di', path: 'scheduling_service/get_schedules' });
  // const schedules = {};
  // debugger;
  useEffect(() => {
    //Getting existing ratepush schedules-->
    dataService
      .getData({
        api: "schedulers/rate-push/" + app_state.property_id + "/",
        request: { method: 'GET' },
      })
      .then((rs) => {
        console.log("ratePushSchedule", rs);
        if (rs.status === "success") {
          let schedules = rs.data;

          updateCompState('schedules', schedules);
          //Store original for future reference
          updateCompState('schedulesOriginal', deepCopy(schedules));
          requestData.schedules = schedules;
          setRequestData({ ...requestData });
        }
      }, (err) => {
        console.log("ratePushSchedules Error!");
      })
    //-->End of getting ratepush schedules

    const room_type_codes = room_settings.map((item) => item.room_code);
    ui.room_type_codes = room_type_codes;

  }, [app_state.property_id]);

  const cancelSave = (index) => {
    requestData.schedules[index].isChangeRequest = false;
    requestData.schedules[index] = deepCopy(compState.schedulesOriginal[index])
    setRequestData({ ...requestData });
  };
  const addNewItem = () => {
    const size = compState.schedules.length;
    const item = {
      schedule_name: "New Rate Push Schedule " + (size + 1),
      //schedule_uuid: "",

      schedule_time: "00:00",
      property_id: app_state.property_id,
      email_addresses: [],
      enabled: true,
      approval_range: {
        end: new moment()
          .add(1, "month")
          .startOf("day")
          .format("YYYY-MM-DDThh:mm:ss"),
        start: new moment().startOf("day").format("YYYY-MM-DDThh:mm:ss"),
      },
      dow: ["SUN", "MON", 'TUE', "WED", "THU", "FRI", "SAT"],
      room_types: [],
      horizon: 30,
      skip_dates: [],
      // owner: user.fullname,
      schedule_time: "00:00",
      //rate_push_field: "algo_generated_recommended_rate",
      //push_base_room: true,
      //cron_setup: false,
    };
    compState.schedules.push(item);
    updateCompState('schedules', compState.schedules);
    setRequestData({ ...requestData, schedules: compState.schedules });
    //dispatchSettings({ type: "create", itemToBeAdded: item });
  };
  function onPressDelete(index) {
    ui.ask = true;
    ui.index = index;
    dispatchSettings({ type: "updateData" });
  }
  const deleteItem = (index) => {
    // debugger;
    ui.request.status = "loading";
    const toBeDeleted = requestData.schedules[index]; //requestData.pricing_settings.room_dow_plans.splice(index,1);
    const reqestAPI = toBeDeleted.schedule_uuid
      ? "schedulers/rate-push/" + app_state.property_id + "/"
      : null;

    const payload = {
      schedule_uuid: toBeDeleted.schedule_uuid,
      method: "DELETE"
    };
    // debugger;
    if (!reqestAPI) {
      ui.request.resType = "success";
      ui.request.status = "idle";
      requestData.schedules.splice(index, 1);
      setRequestData({ ...requestData });
      setTimeout(() => {
        ui.request.resType = null;
        setRequestData({ ...requestData });
      }, 3000);
    } else {
      setIsRequesting(true);
      dataService
        .getData({ api: reqestAPI, request: payload })
        .then((rs) => {
          if (rs.status === 'success') {
            ui.request.resType = "success";
            ui.request.status = "success";
            ui.request.message = "Schedule is deleted successfully!";
            requestData.schedules.splice(index, 1);
            setRequestData({ ...requestData });
          } else {
            ui.request.resType = "dangler";
            ui.request.status = "failed";
            ui.request.message = "Schedule is deleted successfully!";
            setRequestData({ ...requestData });
          }
          setTimeout(() => {
            ui.request.resType = null;
            ui.request.status = "idle";
            ui.request.message = "Schedule is deleted successfully!";
            setRequestData({ ...requestData });
          }, 3000);
          setIsRequesting(false);
        }, (err) => {
          ui.request.resType = "error";
          ui.request.status = "failed";
          setTimeout(() => {
            ui.request.resType = null;
            ui.request.status = "idle";
            setRequestData({ ...requestData });
          }, 3000);
          setIsRequesting(false);
          console.log("delete_report_batches Error!");
        })

      //requestData.scheduled_ratepush[index].isChangeRequest = true;
      //setRequestData({...requestData});
    }
  };

  const saveChanges = (index) => {
    // debugger;
    setIsRequesting(true);
    const itemToBeSaved = deepCopy(requestData.schedules[index]);
    itemToBeSaved.skip_dates = itemToBeSaved.skip_dates.map(date => moment(date).format('YYYY-MM-DD'));
    const isNew = itemToBeSaved.schedule_uuid ? false : true;
    const reqestAPI = "schedulers/rate-push/" + app_state.property_id + "/";
    let payload = {
      method: "POST",
      schedule_name: itemToBeSaved.schedule_name,
      enabled: itemToBeSaved.enabled,
      property_id: app_state.property_id,
      approval_range:{start: itemToBeSaved.approval_range.start, end: itemToBeSaved.approval_range.end} ,
      email_addresses: itemToBeSaved.email_addresses,
      skip_dates: itemToBeSaved.skip_dates,
      dow: itemToBeSaved.dow,
      room_types: itemToBeSaved.room_types,
      // owner: user.fullname,
      schedule_time: itemToBeSaved.schedule_time,
      horizon: itemToBeSaved.horizon,
    };
    settingsState.ui.request.status = "loading";
    dispatchSettings({ type: "updateData", index: index });

    if (!isNew) {
      payload.method = "PUT";
      payload.schedule_uuid = itemToBeSaved.schedule_uuid;
    }
    compState.skip_keys.map((key)=>{
      delete payload[key];
    });
    dataService
      .update({ api: reqestAPI, request: payload })
      .then((rs) => {
        //Do some task here just after respons
        // debugger;
        requestData.schedules[index].isChangeRequest = false;
        // debugger;
        if (rs.status === 'success') {
          ui.request.resType = "success";
          ui.request.status = "success";
          ui.request.message = "Rate push schedules is updated successfully";
          compState.schedulesOriginal[index] = deepCopy(requestData.schedules[index]);
          updateCompState();
          setTimeout(() => {
            ui.request.resType = null;
            ui.request.status = "idle";
            setRequestData({ ...requestData });
          }, 3000);
        } else {
          ui.request.resType = "danger";
          ui.request.status = "error";
          ui.request.message = "Rate push schedules could not be updated, please try again later!";
          setRequestData({ ...requestData });
          setTimeout(() => {
            ui.request.resType = null;
            setRequestData({ ...requestData });
          }, 3000);
        }
        setIsRequesting(false);
      }, (err) => {
        ui.request.resType = "danger";
        ui.request.status = "error";
        ui.request.message = "Rate push schedules could not be updated, please try again later!";
        setTimeout(() => {
          ui.request.resType = null;
          setRequestData({ ...requestData });
        }, 3000);
        setIsRequesting(false);
      });
  };

  const handleWODClick = (e, item, index) => {
    const val = e.target.value;
    const idow = item.dow.indexOf(val);
    // debugger;
    if (idow === -1) {
      item.dow.push(val);
    } else {
      item.dow.splice(idow, 1);
    }
    //data.list[id] = item.dow;
    ui.dataChanged["id_" + index] = true;
    updateRequestData('dow', item.dow, index);
  };

  console.log(requestData.scheduled_ratepush);

  const breadcrumbHierarchy = ["Rate Push", "Settings", "Scheduler"];

  const handleConfirmation = (index) => {
    ui.ask = false;
    dispatchSettings({ type: "updateData" });
    deleteItem(index);
  };
  const handleConfirmationCancel = () => {
    ui.ask = false;
    dispatchSettings({ type: "updateData" });
  }

  const saveField = (index, field) => {
    // debugger;
    if (field) {
      toggleEditable(null, 'cancel', index);
    }
    setRequestData({ ...requestData });
  };

  const updateRequestData = (keys, value, index, reportIndex) => {
    // debugger;
    const updatedObj = JSON.parse(JSON.stringify(requestData));
    const key = keys.split(">");
    const size = key.length;
    let val = value;

    if (size === 1) {
      if (key[0] === "recipients") {
        let size = val.length; // debugger;
        let l = updatedObj.schedules[index][key[0]].length;
        updatedObj.schedules[index][key[0]].splice(0, l);
        val.map((item, i) => {
          updatedObj.schedules[index][key[0]].push(item);
        });
      }
      else {
        updatedObj.schedules[index][key[0]] = value;
      }

    }
    if (size === 2) {
      updatedObj.schedules[index][key[0]][key[1]] = value;
    }
    if (size === 3) {
      updatedObj.schedules[index][key[0]][key[1]][key[2]] = value;
    }

    updatedObj.schedules[index].isChangeRequest = true;

    setRequestData({ ...requestData, schedules: updatedObj.schedules });
    //setIsChangeRequest(true);
  };

  const handleExpand = (index) => {
    let { expanded } = compState;
    if (!expanded["id_" + index]) {
      expanded["id_" + index] = true;
    } else {
      expanded["id_" + index] = false;
    }
    updateCompState("expanded", expanded);
    // setExpanded({ ...expanded });
  };

  return (
    <LocalizationProvider dateAdapter={AdapterMoment}>
      <UserConfirmationAlert open={ui.ask} onConfirm={handleConfirmation} index={ui.index} onCancel={handleConfirmationCancel} />
      <Breadcrumb hierarchy={breadcrumbHierarchy} />
      <div className="heading ratepush">
        <Grid container>
          <Grid item xs={10}>
            <SettingsTitle text={breadcrumbHierarchy[0]} />
          </Grid>
          <Grid item xs={2}>
            <div className="axn-right">
              <IconButton onClick={addNewItem}>
                <AddCircleOutlineRoundedIcon className="inside-line" />
              </IconButton>
              <IconButton>
                <Tooltip title="LodgIQ provides users significant flexibility to select the 
                  Room Types, Days-of-Week, Date Range, and Horizon (e.g. 30 or 90 days) 
                  for automated rate pushes. 
                  Users can even select the specific time of day 
                  they would like the automated pushes to occur. These schedules can be 
                  updated at will, with Preview rates available for review while you 
                  determine what works best for your property.">
                  <InfoOutlinedIcon className="inside-line"></InfoOutlinedIcon>
                </Tooltip>
              </IconButton>
            </div>
          </Grid>
        </Grid>
      </div>
      {ui.request.resType && (<Box sx={{ mt: 2.5, pb: 0, mb: 0, ml: 2, mr: 2, top: 15 }}>

        <UserAlertInfo
          status={'success'}
          message={ui.request.message}
        />

      </Box>)}
      <Box sx={{ pt: 2, pb: 0, mb: 2, ml: 2, mr: 2, maxHeight: "80vh", overflow: "auto"}}>


        {requestData.schedules.map((item, index) => (
          <Paper key={"key-" + index} sx={{ mb: 2 }} variant="outlined">
            <Box
              sx={{
                flexDirection: "column",
                position: "relative",
                // display: "flex",
              }}
            >

              <Box sx={{ maxHeight: "70vh", overflow: "auto" }}>
                <Grid container spacing={0}>
                  <Grid item className="scheduler-left" xs={3.5}>
                    <Grid container sx={{ p: 1.5, pb: 0 }}>
                      <div className="rate-push-badge">{index + 1}</div>
                      <Grid item className="hotel-name" xs={10}>
                        {/* {item.editing !== 'schedule_name' &&
                          <div className="field view">
                            {item.schedule_name}
                            {compState.expanded["id_" + index] &&
                              <div className="usr-axns">
                                <IconButton size="small" onClick={(e) => toggleEditable(e, 'schedule_name', index, item)}>
                                  <EditIcon />
                                </IconButton>
                              </div>
                            }
                          </div>
                        } */}
                        {/* {item.editing == 'schedule_name' && */}
                          <div className="field edit">
                            <Input
                              className="accordian-field"
                              size="small"
                              fullWidth
                              sx={{
                                mt: 1, mr: 1,
                                textAlign: "center",
                                color: "blue.dark",
                              }}
                              value={item.schedule_name}
                              onChange={(e) => {
                                const value = e.target.value;
                                //updateCompState('schedules>schedule_name', value, index);
                                updateRequestData("schedule_name", value, index);
                              }}
                            />
                            {/* <div className="usr-axns">
                              <IconButton size="small" onClick={(e) => saveField(index, 'schedule_name')}>
                                <Tooltip title="Save">
                                  <SaveOutlinedIcon color="green" />
                                </Tooltip>
                              </IconButton>

                              <IconButton size="small" onClick={(e) => cancelSave(index, 'schedule_name')}>
                                <Tooltip title="Cancel">
                                  <ClearOutlinedIcon color="error" />
                                </Tooltip>
                              </IconButton>
                            </div> */}
                          </div>
                        {/*  } */}
                      </Grid>
                      <Grid item xs={2}>
                        <Switch
                          className="switch-button"
                          size="medium"
                          // color="green"
                          checked={item.enabled ? true : false}
                          onChange={(e) => {
                            const checked = e.target.checked;
                            const val = checked ? true : false;
                            updateRequestData("enabled", val, index);
                          }}
                        />
                      </Grid>
                    </Grid>
                  </Grid>
                  <Grid item xs={8.5}>
                    <Grid sx={{ pt: 0 }} container>
                      <Grid item xs={10}>
                        {!compState.expanded["id_" + index] &&
                          <Grid container justifyContent="space-around">
                            <Grid item>
                              <FormControl className="range-display">
                                <Grid container alignItems="center">
                                  <Grid item>
                                    <CalendarMonthOutlinedIcon className="inside-line" color="primary" sx={{ mr: .5, fontSize: 30 }} />
                                  </Grid>
                                  <Grid item>
                                    <div className="control-label">From Stay Date</div>
                                    <div className="value">
                                      <span>{moment(item.approval_range.start).format('ddd, MMM DD YYYY')}</span>
                                    </div>
                                  </Grid>
                                </Grid>
                              </FormControl>
                            </Grid>
                            <Grid item>
                              <FormControl className="range-display to">
                                <Grid container alignItems="center">
                                  <Grid item>
                                    <CalendarMonthOutlinedIcon className="inside-line" color="primary" sx={{ mr: .5, fontSize: 30 }} />
                                  </Grid>
                                  <Grid item>
                                    <div className="control-label">To Stay Date</div>
                                    <div className="value">
                                      <span>{moment(item.approval_range.end).format('ddd, MMM DD YYYY')}</span>
                                    </div>
                                  </Grid>
                                </Grid>
                              </FormControl>
                            </Grid>
                            <Grid item>
                              <FormControl className="range-display">
                                <Grid container alignItems="center" textAlign="center">
                                  <Grid item>
                                    <ScheduleOutlinedIcon className="inside-line" color="primary" sx={{ mr: .5, fontSize: 30 }} />
                                  </Grid>
                                  <Grid item textAlign="center">
                                    <div className="control-label">Push Rate At</div>
                                    <div className="value">
                                      <span>{item.schedule_time}</span>
                                    </div>
                                  </Grid>
                                </Grid>
                              </FormControl>
                            </Grid>
                            <Grid item>
                              <FormControl className="range-display">
                                <Grid container alignItems="center" textAlign="center">

                                  <Grid item textAlign="center">
                                    <div className="control-label">Push Rate For Next</div>
                                    <div className="value">
                                      <span>{item.horizon} DAYS</span>
                                    </div>
                                  </Grid>
                                </Grid>
                              </FormControl>
                            </Grid>
                          </Grid>
                        }
                      </Grid>
                      <Grid item xs={2}>
                        <Grid container justifyContent="end">
                          <Grid item>
                            <IconButton onClick={() => { deleteItem(index) }}>
                              <DeleteOutlineIcon className="delete-button" sx={{ fontSize: 24 }}></DeleteOutlineIcon>
                            </IconButton>
                          </Grid>

                          <Divider orientation="vertical" variant="middle" flexItem sx={{ m: 0.3 }} />
                          <Grid item>
                            {compState.expanded["id_" + index] &&
                              <IconButton className="collapse-arrow" onClick={() => {
                                handleExpand(index);
                              }}>
                                <KeyboardArrowUpOutlinedIcon className="up" sx={{ fontSize: 40 }} />
                              </IconButton>
                            }
                            {!compState.expanded["id_" + index] &&
                              <IconButton className="collapse-arrow" onClick={() => {
                                handleExpand(index);
                              }}>
                                <KeyboardArrowDownOutlinedIcon className="down" sx={{ fontSize: 40 }} />
                              </IconButton>
                            }
                          </Grid>
                        </Grid>
                      </Grid>

                    </Grid>


                  </Grid>
                </Grid>

                <Collapse in={compState.expanded["id_" + index]}
                  timeout="auto"
                  unmountOnExit
                  sx={{
                    overflow: "visible",
                    // border: "1px solid #000",
                  }}>

                  <Grid container spacing={0}>
                    <Grid item className="scheduler-left" xs={3.5}>

                      <Box sx={{ p: 1.5, pt: 0 }}>
                        <Box className="field-controls">

                          <div className="control-fields">
                            <FormControl className="range-pickers from">
                              <div className="control-label">From Stay Date</div>
                              <Grid container alignItems="center">
                                <Grid item>
                                  <CalendarMonthOutlinedIcon className="inside-line" color="primary" sx={{ fontSize: 40 }} />
                                </Grid>
                                <Grid item>
                                  <div className="date-heading-large">
                                    <MobileDatePicker
                                      disableCloseOnSelect={false}
                                      inputFormat="ddd, MMM DD YYYY"
                                      value={item.approval_range.start ? item.approval_range.start : new moment().add(1, 'month').format()}
                                      onChange={(val) => {
                                        const formated = new moment(val).format();
                                        updateRequestData("approval_range>start", formated, index);
                                      }}
                                      sx={{ backgroundColor: 'none' }}
                                      renderInput={(params) => (

                                        <TextField
                                          {...params}
                                          value={item.approval_range.end}
                                          className="rate-textfield date-picker-input"
                                          // variant="outlined"
                                          // label="TO"
                                          placeholder=""
                                        />
                                      )}
                                    />
                                  </div>
                                </Grid>
                              </Grid>
                            </FormControl>

                            <FormControl className="range-pickers to">
                              <div className="control-label">To Stay Date</div>
                              <Grid container alignItems="center">
                                <Grid item>
                                  <CalendarMonthOutlinedIcon className="inside-line" color="primary" sx={{ fontSize: 40 }} />
                                </Grid>
                                <Grid item>
                                  <div className="date-heading-large">
                                    <MobileDatePicker
                                      disableCloseOnSelect={false}
                                      inputFormat="ddd, MMM DD YYYY"
                                      value={item.approval_range.end !== 'never' ? item.approval_range.end : new moment().add(1, 'month').format()}
                                      onChange={(val) => {
                                        const formated = new moment(val).format();
                                        updateRequestData("approval_range>end", formated, index);

                                      }}
                                      sx={{ backgroundColor: 'none' }}
                                      renderInput={(params) => (

                                        <TextField
                                          {...params}
                                          value={item.approval_range.end}
                                          className="rate-textfield date-picker-input"
                                          // variant="outlined"
                                          // label="TO"
                                          placeholder=""
                                        />
                                      )}
                                    />
                                  </div>
                                </Grid>
                              </Grid>
                            </FormControl>
                          </div>
                        </Box>
                        <Box sx={{ pt: 0 }} className="field-controls">
                          <Grid container className="align-center">
                            <Grid item xs={6}>
                              <div className="date-heading-small">
                                <span>push rates at</span>
                                <Tooltip title="Rate push time" arrow followCursor>
                                  <InfoOutlinedIcon className="info-icon" sx={{ fontSize: 16 }} />
                                </Tooltip>
                              </div>
                              <Grid container>
                                <Grid item>
                                  <ScheduleOutlinedIcon className="inside-line" color="primary" sx={{ mr: .5, mt: -.7, fontSize: 35 }} />

                                </Grid>
                                <Grid item>
                                  <MobileTimePicker
                                    // ampmInClock="false"
                                    format="HH:mm"
                                    ampm={true}
                                    value={moment(item.schedule_time, 'hh:mm')}
                                    onChange={(a, b) => {
                                      let formated = moment(a).format("HH:mm");
                                      if (item.schedule_time !== formated) {
                                        updateRequestData("schedule_time", formated, index);
                                      }
                                      // debugger;
                                    }}
                                    renderInput={(params) => (
                                      <TextField
                                        size="small"
                                        {...params}
                                        value={item.schedule_time}
                                        className="time-picker-input"
                                        // variant="outlined"
                                        // label="TO"
                                        placeholder=""
                                      />
                                    )}
                                  />
                                </Grid>
                              </Grid>
                              {/* <Grid className="highlight-number" sx={{ pt: 1 }}>
                                
                                {moment(moment().format("YYYY-MM-DD") + " " + item.schedule_time).format("HH:mm")}
                              </Grid> */}
                              <Grid className="date-heading-small">{moment(moment().format("YYYY-MM-DD") + " " + item.schedule_time).format("a")}</Grid>

                            </Grid>

                            <Grid item xs={6}><Grid className="date-heading-small">push rates for next
                              <Tooltip title="Push rate for days" arrow followCursor>
                                <InfoOutlinedIcon className="info-icon days" sx={{ fontSize: 16 }}></InfoOutlinedIcon>
                              </Tooltip>
                            </Grid>
                              <Grid className="highlight-number">
                                <FormControl className="rate-textfield" fullWidth size="small">
                                  {/* <InputLabel>NUMBER OF DAYS</InputLabel> */}
                                  <Select
                                    value={item.horizon}
                                    className="days-to-push"
                                    // sx={{ lineHeight: "23px" }}
                                    // sx={{ lineHeight: "23px" }}
                                    // label="NUMBER OF DAYS"
                                    onChange={(e) => {
                                      const value = e.target.value;
                                      item.horizon = value;
                                      // const id = "id_" + index;
                                      // ui.dataChanged[id] = true;
                                      updateRequestData("horizon", value, index);
                                    }}
                                  >
                                    <MenuItem value="30">30</MenuItem>
                                    <MenuItem value="60">60</MenuItem>
                                    <MenuItem value="90">90</MenuItem>
                                    <MenuItem value="365">365</MenuItem>
                                  </Select>
                                </FormControl>
                              </Grid>
                              <Grid className="date-heading-small">Days</Grid>

                            </Grid>
                          </Grid>
                        </Box>
                        <Grid container>
                          <Box className="field-controls">
                            <div className="control-fields">
                              <div className="control-label">Run This Schedule every</div>
                              <Tooltip
                                title="Select day of week"
                                arrow
                                disableInteractive
                                followCursor
                              >
                                <ButtonGroup
                                  disableElevation
                                  className="dow-group"
                                  variant="contained"
                                  fullWidth
                                  sx={{ mr: 2 }}
                                >
                                  {ui.dow.map((wd) => {
                                    const currIndex = item.dow.indexOf(wd);
                                    return currIndex !== -1 ? (
                                      <Button
                                        className="wd-name active"
                                        size="small"
                                        variant="outlined"
                                        sx={{
                                          pt: 1,
                                          pb: 1,
                                          backgroundColor:
                                            item.dow.indexOf(wd) !== -1
                                              ? "grey"
                                              : "primary",
                                          color: "dark",
                                        }}
                                        key={"dow-uid-" + wd}
                                        value={wd}
                                        onClick={(e) => {
                                          handleWODClick(e, item, index);

                                        }}
                                      >
                                        {wd[0]}
                                      </Button>
                                    ) : (
                                      <Button
                                        className="wd-name"
                                        variant="outlined"
                                        sx={{
                                          pt: 1,
                                          pb: 1,
                                          backgroundColor:
                                            item.dow.indexOf(wd) !== -1
                                              ? "grey"
                                              : "primary",
                                          color: "dark",
                                          border: "1px solid rgba(25, 118, 210)",
                                        }}
                                        key={"dow-uid-" + wd}
                                        size="small"
                                        value={wd}
                                        onClick={(e) => {
                                          handleWODClick(e, item, index);
                                        }}
                                      >
                                        {wd[0]}
                                      </Button>
                                    );
                                  })}
                                </ButtonGroup>
                              </Tooltip>
                            </div>
                          </Box>
                        </Grid>

                        <Grid container justifyContent='flex-end' sx={{ pt: 1 }}>
                          <Grid item>
                            <Button
                              disabled={!item.isChangeRequest}
                              onClick={() => {
                                saveChanges(index, item.batch_id);
                              }}
                              variant="contained"
                              size="small"
                              className="btn-main save"
                              // color="green"
                              sx={{ minWidth: "auto", mr: 1, color: "white.main" }}
                            >
                              Save
                            </Button>

                          </Grid>
                          <Grid item>

                            <Button
                              disabled={!item.isChangeRequest}
                              onClick={() => {
                                cancelSave(index);
                              }}
                              variant="contained"
                              className="btn-main cancel"
                              // color="warning"
                              size="small"
                              sx={{ minWidth: "auto" }}
                            >
                              cancel
                            </Button>
                          </Grid>
                        </Grid>
                      </Box>

                    </Grid>
                    <Grid item xs={8.5}>
                      <Box sx={{ maxHeight: '45vh', overflow: 'auto', p: 1.5 }}>

                        <Box sx={{ mb: 2 }}>
                          <Grid className="scheduler-right-container">

                            <div className="heaading">
                              Skip dates
                              <DatePicker
                                className="date-picker skip"
                                disablePast={true}
                                disableCloseOnSelect={true}
                                components={{ OpenPickerIcon: CustomDatePickerIcon }}
                                // format="YYYY-MM-DDT00:00:00"
                                renderDay={(day, selected, params) => {
                                  //debugger;
                                  //const formatted = moment(day).format("ddd, MMM DD YYYY");
                                  const formatted = moment(day).format("YYYY-MM-DDT00:00:00");
                                  if (item.skip_dates.indexOf(formatted) !== -1) {
                                    params.selected = true;
                                  }
                                  params.onClick = (date) => {
                                    return (e) => {
                                      // debugger;
                                      if (params.selected) {
                                        const formatted = moment(date).format("YYYY-MM-DDT00:00:00");
                                        const i = item.skip_dates.indexOf(formatted);
                                        item.skip_dates.splice(i, 1);
                                        updateRequestData('skip_dates', item.skip_dates, index)
                                        // debugger;
                                      }
                                    }

                                  }
                                  params.onDaySelect = (a, b) => {
                                    // debugger;
                                    if (b == "finish") {
                                      const formatted = moment(a).format("YYYY-MM-DDT00:00:00");
                                      if (item.skip_dates.indexOf(formatted === -1)) {
                                        item.skip_dates.push(formatted);
                                        const id = "id_" + index;
                                        ui.dataChanged[id] = true;
                                        updateRequestData('skip_dates', item.skip_dates, index);
                                      }
                                    }

                                    return;
                                  };

                                  return <span onClick={params.onClick(day)}><PickersDay {...params} /></span>;
                                }}
                                value={requestData.skip_dates}
                                onChange={() => { }}
                                renderInput={(params) => {
                                  // let pr = params; debugger;
                                  return (
                                    <TextField
                                      className="skip-dates-picker"
                                      size="small"
                                      sx={{
                                        "& .MuiOutlinedInput-root": {
                                          paddingRight: 0,
                                        },
                                        "& .MuiButtonBase-root": {},
                                        "& input": {
                                          visibility: "hidden",
                                          pl: 0,
                                          width: 0,
                                          height: 0,
                                        },
                                        paddingRight: 0,
                                      }}
                                      {...params}
                                    />
                                  );
                                }}
                              />
                            </div>
                            <div className="skip-dates">
                              {item.skip_dates.map(date => (
                                <Chip className="schedule-chip skip-date" label={<><span className="dow">{moment(date).format("ddd")}</span> {moment(date).format("YYYY-MM-DD")}</>} variant="outlined" onDelete={handleDelete({ date, index })} />
                              )
                              )}
                            </div>

                          </Grid>
                        </Box>
                        <Box sx={{ mb: 2 }}>
                          <Grid className="scheduler-right-container">
                            <div className="heaading">Room Types</div>
                            <Autocomplete
                              // sx={{ width: "calc(100% - 60px)" }}
                              className="room-types"
                              size="small"
                              multiple
                              options={room_settings.map(
                                (option) => option.room_code
                              )}
                              getOptionLabel={(option) => option}
                              value={item.room_types}
                              freeSolo
                              onChange={(e, newVal) => {
                                // debugger;
                                item.room_types.splice(0, item.room_types.length + 1);
                                newVal.map((it) => {
                                  item.room_types.push(it);
                                });
                                const id = "id_" + index;
                                ui.dataChanged[id] = true;
                                updateRequestData('room_types', item.room_types, index);
                                // dispatchSettings({
                                //   type: "updateData",
                                //   id: id,
                                // });
                              }}
                              renderTags={(value, getTagProps) =>
                                value.map((option, index) => (
                                  <Chip
                                    key={"room-type-" + index}
                                    size="small"
                                    variant="outlined"
                                    className="schedule-chip"
                                    sx={{ border: "1px dashed #1976d2" }}
                                    // color="primary"
                                    label={option}
                                    {...getTagProps({ index })}
                                  />
                                ))
                              }
                              renderInput={(params) => (
                                <TextField
                                  {...params}
                                  size="small"
                                  className="textfield"
                                  fullWidth
                                  variant="outlined"
                                  // label="ROOM TYPES"
                                  placeholder="Add..."
                                />
                              )}
                            />
                          </Grid>
                        </Box>
                        <Box sx={{ mb: 2 }}>
                          <Grid className="scheduler-right-container" >
                            <div className="heaading">Email Recipients</div>

                            <Autocomplete
                              className="recipients"
                              size="small"
                              multiple
                              options={[]}
                              value={item.email_addresses}
                              freeSolo
                              onChange={(e, newVal) => {
                                // debugger;
                                //item.recipients;
                                updateRequestData('email_addresses', newVal, index);
                                // updateRequestData(
                                //   "recipients",
                                //   newVal,
                                //   index
                                // );
                              }}
                              renderTags={(value, getTagProps) =>
                                value.map((option, index) => (
                                  <Chip
                                    className="recipients-chip-vijay"
                                    key={"recipient-" + index}
                                    size="small"
                                    variant="outlined"

                                    sx={{ border: "1px dashed #1976d2" }}
                                    // color="primary"
                                    label={option}
                                    {...getTagProps({ index })}
                                  />

                                ))
                              }
                              renderInput={(params) => (
                                <TextField
                                  {...params}
                                  className="textfield"
                                  size="small"
                                  variant="outlined"
                                  // label="ADDITIONAL RECIPIENTS"
                                  placeholder=" Type and press enter..."
                                />
                              )}
                            />
                          </Grid>
                        </Box>
                      </Box>

                    </Grid>
                  </Grid>

                </Collapse>
              </Box>
              
            </Box>
          </Paper>
        ))}
        {isrequesting && (
                <Box
                  sx={{
                    textAlighn: "center",
                    backgroundColor: "dark.darkest",
                    opacity: 0.8,
                    position: "fixed",
                    top: 40,
                    left: 0,
                    width: "100%",
                    height: "100%",
                  }}
                >
                  <CircularProgress
                    size={40}
                    sx={{
                      color: "white",
                      position: "absolute",
                      top: "50%",
                      left: "50%",
                      marginTop: "-20px",
                      marginLeft: "-20px",
                    }}
                  />
                </Box>
              )}
      </Box>

    </LocalizationProvider>
  );
}

const initalState = {
  data: { list: [] },
  ui: {
    //Any kind of interaction with server is a request
    request: {
      resType: null,
      status: "idle", // May have these values "idle", "loading", "loaded", "empty", "error"
      message: "",
    },
    expanded: {},
    mode: {},
    dow: ["SUN", "MON", "TUE", "WED", "THU", "FRI", "SAT"],
    dataChanged: {},
    schedule_time: new moment(),
  },
};

const CustomDatePickerIcon = (props) => {

  return (
    <CalendarMonthOutlinedIcon color="primary" className="datepicker-icon" />
  )
}
