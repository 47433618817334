import React, {useState,useEffect} from 'react'
import EditIcon from '@mui/icons-material/Edit';
import { Block } from '@mui/icons-material';
import { isNumber } from '../../app/util';
function InputEl({onBlur,subcol_id,disabledInput,inputVal, handleKeyDown, edited, currency,isRoomTypePricing}) {
    // const [inputVal, setInputVal] = useState(roomTypeInputVal)
    let editbtn=(<div></div>);
    if(edited){
        editbtn=(<EditIcon className="editBtn"/>)
    }
    
    const [editMode, setEditMode] = useState(false);
  
    const [inputText, setInputText] = useState(inputVal);
    const resetInputText = ()=>{
        setInputText("");
    }
    useEffect(() => {
        let val ="";
        // if(isRoomTypePricing == false){
        //     setEditMode(true);
        // }
        if(inputVal!== ""){
            if(inputVal.includes("(")){
                val = inputVal.replace("(","");
                val = inputVal.replace(")","");
            }
            val = parseInt(inputVal.replace(currency, ""));
            if(isNaN(val)){
                val ="";
            }
            
        }
        setInputText(val)
        return () => {
           
        }
    }, [inputVal])
    const handleInputChange =(e)=>{
        let val = e.target.value;
        // if(val !== ""){
        //     val = parseInt(val);
            
        // }
       
        // if(isNaN(val)){
        //     val = "";
        // }
        setInputText(val);
    } 
   
    let inputSection =(  <input 
        autoFocus
        value={inputText}  
         onKeyDown={handleKeyDown}              
        onBlur={(e)=>{
            setEditMode(false);
            let {value} = e.target.value;
            if(value !==''){
                value = parseInt(e.target.value);
            }
            if(isNaN(value)){
                value='';
            }
            setInputText(value)
            onBlur(value, resetInputText)
        }}
            disabled={disabledInput} type="string"
        className={subcol_id} 
        onChange={handleInputChange}
    />)
    // if(isRoomTypePricing === false){
    //     inputSection =(  <input 
    //         value={inputText}  
    //          onKeyDown={handleKeyDown}              
    //         onBlur={(e)=>{
    //             setEditMode(false);
    //             let {value} = e.target.value;
    //             if(value !==''){
    //                 value = parseInt(e.target.value);
    //             }
    //             if(isNaN(value)){
    //                 value='';
    //             }
    //             setInputText(value)
    //             onBlur(value, resetInputText)
    //         }}
    //             disabled={disabledInput} type="string"
    //         className={subcol_id} 
    //         onChange={handleInputChange}
    //     />)
    // }
    let sty = {
        display:"block",
        // height:"100%",
        width:"100%"
    }
    if(!inputVal || inputVal ==""){
        sty["height"]="100%"
    }
    return (
        <>
        {!editMode &&(<span style={sty} className="dataView" onClick={()=>{
            setEditMode(true)
        }}>{inputVal}</span>)}
        {editMode && inputSection}
        {editbtn}
        </>
    )
}

export default InputEl
