import { getCompsetName } from "./util";
// Making columns config for tables 
// because it may be very big list and reduce readability if kept in main config file
export const generatDefaultSubcolumns = (kpi, parent) => {
    const kpisMap = {
        Occ: {
            "display": "Occ",
            "value": "Occ",
            "subcol_id": parent + "_Occ",
            "type": "percent",

        },
        ADR: {
            "display": "ADR",
            "value": "ADR",
            "subcol_id": parent + "_ADR",
            "type": "currency",
        },
        RevPAR: {
            "display": "RevPAR",
            "value": "RevPAR",
            "subcol_id": parent + "_RevPAR",
            "type": "currency",
        },
        Revenue: {
            "display": "Revenue",
            "value": "Revenue",
            "subcol_id": parent + "_Revenue",
            "type": "currency",
        },
        Rooms: {
            "display": "Rooms Sold",
            "value": "Rooms",
            "subcol_id": parent + "_Rooms",
            "type": "integer",
        },
        SellRate: {
            "display": "SellRate",
            "value": "SellRate",
            "subcol_id": parent + "_SellRate",
            "type": "currency",
        },
        Restrictions: {
            "display": "Restrictions",
            "value": "Restrictions",
            "subcol_id": parent + "_Restrictions",
            "type": "restrictions",
        },
        date: {
            "display": "Date",
            "value": "date",
            "subcol_id": parent + "_date",
            "type": "date",
        },
        dba: {
            "display": "Dba",
            "value": "dba",
            "subcol_id": parent + "_dba",
            "type": "integer"
        },
        dow: {
            "display": "DOW",
            "value": "dow",
            "subcol_id": parent + "_dow",
            "type": "dow",
        },
        segment: {
            "display": "segment",
            "value": "segment",
            "subcol_id": parent + "_segment",
            "type": "string",
        },
        month: {
            "display": "month",
            "value": "month",
            "subcol_id": parent + "_month",
            "type": "string"
        },
        avgrate: {
            "display": "Avg Rate",
            "value": "avg",
            "subcol_id": parent + "_avg",
            "type": "string"
        },
        cmedium: {
            "display": "Compset Median",
            "value": 'CM',
            "subcol_id": parent + "_CM",
            "type": "currency"
        },
        compdate: {
            display: "Comparison Date",
            value: 'compdate',
            "subcol_id": parent + "_compdate",
            "type": "compdate"
        },
        vararrivals: {
            display: "Arrivals",
            value: 'arrivals',
            "subcol_id": parent + "_vararrivals",
            "type": "percent"
        },
        varstayover: {
            display: "stayover",
            value: 'stayover',
            "subcol_id": parent + "_varstayover",
            "type": "percent"
        },
        otbarrivals: {
            display: "Arrivals",
            value: 'arrivals',
            "subcol_id": parent + "_otbarrivals",
            "type": "number"
        },
        otbstayover: {
            display: "stayover",
            value: 'stayover',
            "subcol_id": parent + "_otbstayover",
            "type": "number"
        },

        //For Resctrictions
        rec: {
            display: "Lodgiq Rec",
            value: 'rec',
            "subcol_id": parent + "_rec",
            "type": "number"
        },
        cur:{
            display: "Current",
            value: 'cur',
            "subcol_id": parent + "_cur",
            "type": "number"
        },

        // for Rate Push Report 
        UpdateTime:{
            display: "Update Time",
            value: 'UpdateTime',
            "subcol_id": parent + "_UpdateTime",
            "type": "number"
        },
        User:{
            display: "User",
            value: 'User',
            "subcol_id": parent + "_User",
            "type": "number"
        },
        Action:{
            display: "Action",
            value: 'Action',
            "subcol_id": parent + "_Action",
            "type": "number"
        },
        Roomsrate:{
            display: "Rooms & Rate",
            value: 'Roomsrate',
            "subcol_id": parent + "_Roomsrate",
            "type": "number"
        },
        Status:{
            display: "Status",
            value: 'Status',
            "subcol_id": parent + "_Status",
            "type": "number"
        },
        PriorRate:{
            display: "Prior Rate",
            value: 'PriorRate',
            "subcol_id": parent + "_PriorRate",
            "type": "number"
        },
        RecRate:{
            display: "Rec Rate",
            value: 'RecRate',
            "subcol_id": parent + "_RecRate",
            "type": "number"
        },
        ChangeRate:{
            display: "Change Rate",
            value: 'ChangeRate',
            "subcol_id": parent + "_ChangeRate",
            "type": "number"
        },

    };
    return {
        ...kpisMap[kpi],
        "parent": parent,
        "className": "col-sub",
        "draggable": true,
        "sortable": true,
        "filterable": true, "filterMenu": true,
        "sorted": null,
        "hidden": false,
        "order": 1
    }
}

export const TABLE_CONFIG = {
    pricing: {
        columns: [
            {

                id: "date",
                display: "{rangeyear}",
                series: 'OSR',
                left: 0,
                type: "dateColumn",
                isSticky: true,
                roomTypeCompatible: true,
                is_default:true,
                subcolumns: [
                    { subcol_id: "date", value: "date", display: "Date", type: "date", format: "MM DD" },
                    { subcol_id: "dow", value: "dow", display: "DOW", type: "dow", }
                ]
            },
            {
                id: "otb",
                display: "On The Books",
                series: 'OSR',
                type: "dataColumn",
                left: 152,
                view:"hidden",
                isSticky: true,
                roomTypeCompatible: true,
                subcolumns: [
                    { subcol_id: "otb_Occ", value: "Occ", display: "Occ", type: "percent" },
                    { subcol_id: "otb_ADR", value: "ADR", display: "ADR", type: "currency" },
                ]
            },
            {
                id: "forecast",
                type: "dataColumn",
                left: 300,
                view:"hidden",
                display: "Forecast",
                roomTypeCompatible: true,
                isSticky: true,
                series: 'OSRContrainedForecast',
                subcolumns: [{ subcol_id: "forecast_Occ", value: "Occ", display: "Occ", type: "percent" },
                { subcol_id: "forecast_ADR", value: "ADR", display: "ADR", type: "currency" },]
            },

        ],
        houseLevelColumns: [
            {
                id: "restrictions",
                type: "dataColumn",
                display: "Restrictions",
                series: 'OSR',
                hidden: false,
                isSticky: false,
                subcolumns: [{ subcol_id: "restrictions_house", hidden: false, value: "restrictions_house", display: "Current", type: "restriction_display" },]
            },
            {
                id: "pricing",
                type: "dataColumn",
                isSticky: false,
                display: "Bar Pricing",
                series: 'OSRRateRecommend',
                subcolumns: [{ subcol_id: "pricing_Sold", value: "Sold", display: "Sold", type: "integer" },
                { subcol_id: "pricing_SellRate", value: "SellRate", display: "Current Rate", type: "currency" },
                { subcol_id: "pricing_RecSellRate", value: "RecSellRate", display: "Lodgiq Rate", type: "currency" },
                {
                    subcol_id: "pricing_ratediff", value: "ratediff", display: "+/-", type: "diff", method: {
                        type: "diff",
                        keys: ["SellRate", "RecSellRate"]
                    }
                },
                { subcol_id: "pricing_Override", value: "Override", display: "Override Rate", type: "input" },
                { subcol_id: "pricing_Approve", value: "Approve", display: "Approve", type: "checkbox" },
                ]
            }],
        roomTypeOnColumns: [{
            type:"roomTypeColumn",
            isBase: true,
            series: 'OSRRateRecommend',
            isSticky: true,
            left:441,
            subcolumns: [{ subcol_id: "Sold", value: "Sold", display: "Sold", type: "integer", left:42 },
            { subcol_id: "restrictions_current", hidden: false, value: "restrictions_current", display: "restrictions", type: "restriction_display" },
            { subcol_id: "RecSellRate", value: "RecSellRate", display: "Lodgiq Rate", left: 44, type: "currency", hasSelectAll: true, },
            { subcol_id: "SellRate", value: "SellRate", display: "Current Rate", left: 100, type: "currency" },
            { subcol_id: "baseOnlyUpdate", value: "baseOnlyUpdate", hidden:false,left:82, hasSelectAll:true, display: "Update Base Only", type: "checkbox" },]
        },]
    },
    otb: {
        columns: [
            {
                "id": "staydates", // likewise: otb_sdly, otb_prior,...
                "type": "dateColumn",
                "isSticky": true,
                "display": "{rangeyear}",
                "backgroundColor": "grey",
                "className": "otb data-column otb-current",
                "draggable": false,
                "colspan": 2, //This should be <subcolumns.length>
                "order": 1,

                "subcolumns": [
                    {
                        ...generatDefaultSubcolumns("date", "staydates"),
                        // "draggable": true,
                        "sorted": null,
                        "filterable": false, "filterMenu": false,
                        "order": 1
                    },
                    {
                        ...generatDefaultSubcolumns("dow", "staydates"),
                        "draggable": false,
                        "filterable": false, "filterMenu": false,
                        "order": 2
                    },

                ],
                "subcolumns_segmented": [
                    {
                        ...generatDefaultSubcolumns("segment", "staydates"),
                        "draggable": false,
                        "filterable": false,
                        // "hidden": true,
                        "order": 3
                    }
                ]

            },
            {
                "id": "otb_current",
                "type": "dataColumn",
                "mainKey": "primary",
                "tileName": "otb",
                "display": "as of - {asOfDate}",
                "backgroundColor": "grey",
                "className": "col-main",
                "draggable": true,
                "hidden": false,
                "colspan": 6,
                "order": 2,
                "subcolumns": [
                    {
                        ...generatDefaultSubcolumns("Rooms", "otb_current"),
                        "order": 2,
                        // "hidden": false,
                        // "sortable": false,
                        // "sorted": "asc"

                    },
                    {
                        ...generatDefaultSubcolumns("Occ", "otb_current"),
                        "order": 1,
                    },
                    {
                        ...generatDefaultSubcolumns("ADR", "otb_current"),
                        "order": 3
                    },
                    {
                        ...generatDefaultSubcolumns("RevPAR", "otb_current"),
                        "order": 4
                    },
                    {
                        ...generatDefaultSubcolumns("Revenue", "otb_current"),
                        "order": 5
                    },
                    {
                        ...generatDefaultSubcolumns("SellRate", "otb_current"),
                        // "hidden": true,
                        "order": 6
                    }
                ],

            }
        ],
        restrictionsOn_columns: [
            {
                "id": "otb_restrictions",
                "type": "dataColumn",
                "mainKey": "restrictions",
                "tileName": "otb",
                "display": "",
                "backgroundColor": "grey",
                "className": "col-main",
                "draggable": true,
                "hidden": false,
                "colspan": null,
                "order": 2,
                "subcolumns": [
                    {
                        ...generatDefaultSubcolumns("Restrictions", "otb_restrictions"),
                        // "hidden": true,
                        "filterable": false,
                        "filterMenu": false,
                        "sortable": false,
                        "order": 1
                    }
                ],

            }
        ],
        sdlyOn_columns: [
            {
                "id": "otb_sdly",
                "type": "dataColumn",
                "mainKey": "sdly",
                "tileName": "otb",
                "display": "As Of - {otbSdlyDate}",
                "backgroundColor": "grey",
                "className": "col-main",
                "draggable": true,
                "hidden": false,
                "colspan": 6,
                "order": 3,
                "subcolumns": [
                    {
                        ...generatDefaultSubcolumns("Rooms", "otb_sdly"),
                        "order": 2
                    },
                    {
                        ...generatDefaultSubcolumns("Occ", "otb_sdly"),
                        "order": 1,
                    },
                    {
                        ...generatDefaultSubcolumns("ADR", "otb_sdly"),
                        "order": 3
                    },
                    {
                        ...generatDefaultSubcolumns("RevPAR", "otb_sdly"),
                        "order": 4
                    },
                    {
                        ...generatDefaultSubcolumns("Revenue", "otb_sdly"),
                        "order": 5
                    },
                    {
                        ...generatDefaultSubcolumns("SellRate", "otb_sdly"),
                        "hidden": false,
                        "order": 6
                    }
                ],

            },
            {
                "id": "otb_actual_sdly", // likewise: otb_sdly, otb_prior,...
                "type": "dataColumn",
                "mainKey": "sdly_actual",
                "tileName": "otb",
                "display": "Last Year Actuals",
                "backgroundColor": "grey",
                "className": "col-main",
                "draggable": true,
                "hidden": false,
                "colspan": 6,
                "order": 3,
                "subcolumns": [
                    {
                        ...generatDefaultSubcolumns("Rooms", "otb_actual_sdly"),
                        "order": 2
                    },
                    {
                        ...generatDefaultSubcolumns("Occ", "otb_actual_sdly"),
                        "order": 1,
                    },
                    {
                        ...generatDefaultSubcolumns("ADR", "otb_actual_sdly"),
                        "order": 3
                    },
                    {
                        ...generatDefaultSubcolumns("RevPAR", "otb_actual_sdly"),
                        "order": 4
                    },
                    {
                        ...generatDefaultSubcolumns("Revenue", "otb_actual_sdly"),
                        "order": 5
                    },
                    {
                        ...generatDefaultSubcolumns("SellRate", "otb_actual_sdly"),
                        "hidden": false,
                        "order": 6
                    }
                ],
            },
            {
                "id": "otb_diff_sdly", // likewise: otb_sdly, otb_prior,...
                "type": "dataColumn",
                "mainKey": "difference_sdly",
                "tileName": "otb",
                "display": "DIFFERENCE TO SDLY",
                "backgroundColor": "grey",
                "className": "col-main",
                "draggable": true,
                "hidden": false,
                "colspan": 6,
                "order": 4,
                "subcolumns": [
                    {
                        ...generatDefaultSubcolumns("Rooms", "otb_diff_sdly"),
                        "order": 2
                    },
                    {
                        ...generatDefaultSubcolumns("Occ", "otb_diff_sdly"),
                        "order": 1,
                    },
                    {
                        ...generatDefaultSubcolumns("ADR", "otb_diff_sdly"),
                        "order": 3
                    },
                    {
                        ...generatDefaultSubcolumns("RevPAR", "otb_diff_sdly"),
                        "order": 4
                    },
                    {
                        ...generatDefaultSubcolumns("Revenue", "otb_diff_sdly"),
                        "order": 5
                    },
                    {
                        ...generatDefaultSubcolumns("SellRate", "otb_diff_sdly"),
                        "hidden": false,
                        "order": 6
                    }
                ],
            },
            {
                "id": "otb_variance_sdly", // likewise: otb_sdly, otb_prior,...
                "type": "dataColumn",
                "mainKey": "variance_sdly",
                "tileName": "otb",
                "display": "% DIFFERENCE TO SDLY",
                "backgroundColor": "grey",
                "className": "col-main",
                "draggable": true,
                "hidden": false,
                "colspan": 6,
                "order": 5,
                "subcolumns": [
                    {
                        ...generatDefaultSubcolumns("Rooms", "otb_variance_sdly"),
                        "order": 2
                    },
                    {
                        ...generatDefaultSubcolumns("Occ", "otb_variance_sdly"),
                        "order": 1,
                    },
                    {
                        ...generatDefaultSubcolumns("ADR", "otb_variance_sdly"),
                        "order": 3
                    },
                    {
                        ...generatDefaultSubcolumns("RevPAR", "otb_variance_sdly"),
                        "order": 4
                    },
                    {
                        ...generatDefaultSubcolumns("Revenue", "otb_variance_sdly"),
                        "order": 5
                    },
                    {
                        ...generatDefaultSubcolumns("SellRate", "otb_variance_sdly"),
                        "hidden": true,
                        "order": 6
                    }
                ],
            },

            {
                "id": "otb_remaing_pickup_sdly", // likewise: otb_sdly, otb_prior,...
                "type": "dataColumn",
                "mainKey": "pickup_diff_sdly",
                "tileName": "otb",
                "display": "REMAINING PICKUP FOR SDLY",
                "backgroundColor": "grey",
                "className": "col-main",
                "draggable": true,
                "hidden": false,
                "colspan": 6,
                "order": 7,
                "subcolumns": [
                    {
                        ...generatDefaultSubcolumns("Rooms", "otb_remaing_pickup_sdly"),
                        "order": 2
                    },
                    {
                        ...generatDefaultSubcolumns("Occ", "otb_remaing_pickup_sdly"),
                        "order": 1,
                    },
                    {
                        ...generatDefaultSubcolumns("ADR", "otb_remaing_pickup_sdly"),
                        "order": 3
                    },
                    {
                        ...generatDefaultSubcolumns("RevPAR", "otb_remaing_pickup_sdly"),
                        "order": 4
                    },
                    {
                        ...generatDefaultSubcolumns("Revenue", "otb_remaing_pickup_sdly"),
                        "order": 5
                    },
                    {
                        ...generatDefaultSubcolumns("SellRate", "otb_remaing_pickup_sdly"),
                        "hidden": false,
                        "order": 6
                    }
                ],
            },
        ]
    },
    pickup: {
        columns: [
            {
                "id": "staydates", // likewise: otb_sdly, otb_prior,...
                "type": "dateColumn",
                "display": "2023",
                isSticky: true,
                "display": "{rangeyear}",
                "backgroundColor": "grey",
                "className": "otb data-column otb-current",
                "draggable": false,
                "colspan": 2, //This should be <subcolumns.length>
                "order": 1,

                "subcolumns": [
                    {
                        ...generatDefaultSubcolumns("date", "staydates"),
                        // "draggable": true,
                        "sorted": null,
                        "filterable": false, "filterMenu": false,
                        "order": 1
                    },
                    {
                        ...generatDefaultSubcolumns("dow", "staydates"),
                        "draggable": false,
                        "filterable": false, "filterMenu": false,
                        "order": 2
                    },

                ],
                "subcolumns_segmented": [
                    {
                        ...generatDefaultSubcolumns("segment", "staydates"),
                        "draggable": false,
                        "filterable": false,
                        // "hidden": true,
                        "order": 3
                    }
                ]

            },

            {
                "id": "pickup_difference",
                "type": "dataColumn",
                "mainKey": "otb_difference",
                "tileName": "pickup",
                "display": "%dayscount% Day Pickup as of - {asOfDate}",
                "backgroundColor": "grey",
                "className": "col-main",
                "draggable": true,
                "hidden": false,
                "colspan": 6,
                "order": 2,
                "subcolumns": [
                    {
                        ...generatDefaultSubcolumns("Rooms", "pickup_difference"),
                        "order": 2,
                    },
                    {
                        ...generatDefaultSubcolumns("Occ", "pickup_difference"),
                        "order": 1,
                    },
                    {
                        ...generatDefaultSubcolumns("ADR", "pickup_difference"),
                        "order": 3
                    },
                    {
                        ...generatDefaultSubcolumns("RevPAR", "pickup_difference"),
                        "order": 4
                    },
                    {
                        ...generatDefaultSubcolumns("Revenue", "pickup_difference"),
                        "order": 5
                    },
                    {
                        ...generatDefaultSubcolumns("SellRate", "pickup_difference"),
                        // "hidden": true,
                        "order": 6
                    }
                ],

            },
            {
                "id": "pickup_otb_current",
                "type": "dataColumn",
                "mainKey": "current",
                "tileName": "pickup",
                "display": "OTB - {asOfDate}",
                "backgroundColor": "grey",
                "className": "col-main",
                "draggable": true,
                "hidden": false,
                "colspan": 6,
                "order": 3,
                "subcolumns": [
                    {
                        ...generatDefaultSubcolumns("Rooms", "pickup_otb_current"),
                        "order": 2,
                        // "hidden": false,
                        // "sortable": false,
                        // "sorted": "asc"

                    },
                    {
                        ...generatDefaultSubcolumns("Occ", "pickup_otb_current"),
                        "order": 1,
                    },
                    {
                        ...generatDefaultSubcolumns("ADR", "pickup_otb_current"),
                        "order": 3
                    },
                    {
                        ...generatDefaultSubcolumns("RevPAR", "pickup_otb_current"),
                        "order": 4
                    },
                    {
                        ...generatDefaultSubcolumns("Revenue", "pickup_otb_current"),
                        "order": 5
                    },
                    {
                        ...generatDefaultSubcolumns("SellRate", "pickup_otb_current"),
                        // "hidden": true,
                        "order": 6
                    }
                ],

            },
            {
                "id": "pickup_prior",
                "type": "dataColumn",
                "mainKey": "otb_pickup",
                "tileName": "pickup",
                "display": "OTB - {subsPickupDays}",
                "backgroundColor": "grey",
                "className": "col-main",
                "draggable": true,
                "hidden": false,
                "colspan": 6,
                "order": 4,
                "subcolumns": [
                    {
                        ...generatDefaultSubcolumns("Rooms", "pickup_prior"),
                        "order": 2,
                        // "hidden": false,
                        // "sortable": false,
                        // "sorted": "asc"

                    },
                    {
                        ...generatDefaultSubcolumns("Occ", "pickup_prior"),
                        "order": 1,
                    },
                    {
                        ...generatDefaultSubcolumns("ADR", "pickup_prior"),
                        "order": 3
                    },
                    {
                        ...generatDefaultSubcolumns("RevPAR", "pickup_prior"),
                        "order": 4
                    },
                    {
                        ...generatDefaultSubcolumns("Revenue", "pickup_prior"),
                        "order": 5
                    },
                    {
                        ...generatDefaultSubcolumns("SellRate", "pickup_prior"),
                        // "hidden": true,
                        "order": 6
                    }
                ],

            }
        ],

        sdlyOn_columns: [
            {
                "id": "pickup_sdow_ly",
                "type": "dataColumn",
                "mainKey": "otb_sdly_diff",
                "tileName": "pickup",

                // "display": "PICKUP SDOW LAST YEAR",
                "display": "%dayscount% Day Pickup as of - {subsYearDays}",

                "backgroundColor": "grey",
                "className": "col-main",
                "draggable": true,
                "hidden": false,
                "colspan": 6,
                "order": 5,
                "subcolumns": [
                    {
                        ...generatDefaultSubcolumns("Rooms", "pickup_sdow_ly"),
                        "order": 2
                    },
                    {
                        ...generatDefaultSubcolumns("Occ", "pickup_sdow_ly"),
                        "order": 1,
                    },
                    {
                        ...generatDefaultSubcolumns("ADR", "pickup_sdow_ly"),
                        "order": 3
                    },
                    {
                        ...generatDefaultSubcolumns("RevPAR", "pickup_sdow_ly"),
                        "order": 4
                    },
                    {
                        ...generatDefaultSubcolumns("Revenue", "pickup_sdow_ly"),
                        "order": 5
                    },
                    {
                        ...generatDefaultSubcolumns("SellRate", "pickup_sdow_ly"),
                        "hidden": true,
                        "order": 6
                    }
                ],

            },
            {
                "id": "pickup_otb_sdly", // likewise: otb_sdly, otb_prior,...
                "type": "dataColumn",
                "mainKey": "sdly",
                "tileName": "pickup",
                "display": "OTB - SDLY - {subsYearDays}",
                "backgroundColor": "grey",
                "className": "col-main",
                "draggable": true,
                "hidden": false,
                "colspan": 6,
                "order": 6,
                "subcolumns": [
                    {
                        ...generatDefaultSubcolumns("Rooms", "pickup_otb_sdly"),
                        "order": 2
                    },
                    {
                        ...generatDefaultSubcolumns("Occ", "pickup_otb_sdly"),
                        "order": 1,
                    },
                    {
                        ...generatDefaultSubcolumns("ADR", "pickup_otb_sdly"),
                        "order": 3
                    },
                    {
                        ...generatDefaultSubcolumns("RevPAR", "pickup_otb_sdly"),
                        "order": 4
                    },
                    {
                        ...generatDefaultSubcolumns("Revenue", "pickup_otb_sdly"),
                        "order": 5
                    },
                    {
                        ...generatDefaultSubcolumns("SellRate", "pickup_otb_sdly"),
                        // "hidden": true,
                        "order": 6
                    }
                ],
            },
            {
                "id": "pickup_otb_sdly_prior", // likewise: otb_sdly, otb_prior,...
                "type": "dataColumn",
                "mainKey": "sdly_pickup",
                "tileName": "pickup",
                "display": "OTB - SDLY - {sdlyPickupDays}",
                "backgroundColor": "grey",
                "className": "col-main",
                "draggable": true,
                "hidden": false,
                "colspan": 6,
                "order": 7,
                "subcolumns": [
                    {
                        ...generatDefaultSubcolumns("Rooms", "pickup_otb_sdly_prior"),
                        "order": 2
                    },
                    {
                        ...generatDefaultSubcolumns("Occ", "pickup_otb_sdly_prior"),
                        "order": 1,
                    },
                    {
                        ...generatDefaultSubcolumns("ADR", "pickup_otb_sdly_prior"),
                        "order": 3
                    },
                    {
                        ...generatDefaultSubcolumns("RevPAR", "pickup_otb_sdly_prior"),
                        "order": 4
                    },
                    {
                        ...generatDefaultSubcolumns("Revenue", "pickup_otb_sdly_prior"),
                        "order": 5
                    },
                    {
                        ...generatDefaultSubcolumns("SellRate", "pickup_otb_sdly_prior"),
                        // "hidden": true,
                        "order": 6
                    }
                ],
            },
        ]
    },

    market: {
        columns: [
            {
                "id": "staydates", // likewise: otb_sdly, otb_prior,...
                "type": "dateColumn",
                "display": "{rangeyear}",
                "backgroundColor": "grey",
                "className": "otb data-column otb-current",
                "draggable": false,
                "colspan": 2, //This should be <subcolumns.length>
                "order": 1,
                "subcolumns": [
                    {
                        ...generatDefaultSubcolumns("date", "staydates"),
                        // "draggable": true,
                        // "sorted": 'asc',
                        "filterable": false, "filterMenu": false,
                        "order": 1
                    },
                    {
                        ...generatDefaultSubcolumns("dow", "staydates"),
                        "draggable": false,
                        "filterable": false, "filterMenu": false,
                        "order": 2
                    },

                ],
                "subcolumns_dba": [
                    {
                        ...generatDefaultSubcolumns("dba", "staydates"),
                        // "draggable": true,
                        "sorted": 'asc',
                        "filterable": false, "filterMenu": false,
                        "order": 1
                    },


                ],
                "subcolumns_segmented": [
                    {
                        ...generatDefaultSubcolumns("segment", "staydates"),
                        "draggable": false,
                        "filterable": false,
                        // "hidden": true,
                        "order": 3
                    }
                ],
            },
            // data columns

        ],
        dataDateColumns: [
            {
                "id": "market_otb",
                "type": "dataColumn",
                "mainKey": "primary",
                "tileName": "market",
                "display": "As Of - {asOfDate}",
                "backgroundColor": "grey",
                "className": "col-main",
                "draggable": true,
                "hidden": false,
                "colspan": 6,
                "order": 2,
                "subcolumns": [
                    {
                        ...generatDefaultSubcolumns("Rooms", "market_otb"),
                        "order": 2,
                        // "hidden": false,
                        // "sortable": false,
                        // "sorted": "asc"

                    },
                    {
                        ...generatDefaultSubcolumns("Occ", "market_otb"),
                        "order": 1,
                    },
                    {
                        ...generatDefaultSubcolumns("ADR", "market_otb"),
                        "order": 3
                    },
                    {
                        ...generatDefaultSubcolumns("RevPAR", "market_otb"),
                        "order": 4
                    },
                    {
                        ...generatDefaultSubcolumns("Revenue", "market_otb"),
                        "order": 5
                    },
                    {
                        ...generatDefaultSubcolumns("SellRate", "market_otb"),
                        // "hidden": true,
                        "order": 6
                    }
                ],

            },
            {
                "id": "market_otb_prior",
                "type": "dataColumn",
                "mainKey": "pickup",
                "tileName": "market",
                "display": "As Of - {subsPickupDays}",
                "backgroundColor": "grey",
                "className": "col-main",
                "draggable": true,
                "hidden": false,
                "colspan": 6,
                "order": 2,
                "subcolumns": [
                    {
                        ...generatDefaultSubcolumns("Rooms", "market_otb_prior"),
                        "order": 2,
                        // "hidden": false,
                        // "sortable": false,
                        // "sorted": "asc"

                    },
                    {
                        ...generatDefaultSubcolumns("Occ", "market_otb_prior"),
                        "order": 1,
                    },
                    {
                        ...generatDefaultSubcolumns("ADR", "market_otb_prior"),
                        "order": 3
                    },
                    {
                        ...generatDefaultSubcolumns("RevPAR", "market_otb_prior"),
                        "order": 4
                    },
                    {
                        ...generatDefaultSubcolumns("Revenue", "market_otb_prior"),
                        "order": 5
                    },
                    {
                        ...generatDefaultSubcolumns("SellRate", "market_otb_prior"),
                        // "hidden": true,
                        "order": 6
                    }
                ],

            },
            {
                "id": "market_otb_pickup",
                "type": "dataColumn",
                "mainKey": "marketotbpickup",
                "tileName": "market",
                "display": "%dayscount% Day Pickup",
                // "display": "%dayscount% Day Pickup as of - {asOfDate}",

                "backgroundColor": "grey",
                "className": "col-main",
                "draggable": true,
                "hidden": false,
                "colspan": 6,
                "order": 3,
                "subcolumns": [
                    {
                        ...generatDefaultSubcolumns("Rooms", "market_otb_pickup"),
                        "order": 2,
                        // "hidden": false,
                        // "sortable": false,
                        // "sorted": "asc"

                    },
                    {
                        ...generatDefaultSubcolumns("Occ", "market_otb_pickup"),
                        "order": 1,
                    },
                    {
                        ...generatDefaultSubcolumns("ADR", "market_otb_pickup"),
                        "order": 3
                    },
                    {
                        ...generatDefaultSubcolumns("RevPAR", "market_otb_pickup"),
                        "order": 4
                    },
                    {
                        ...generatDefaultSubcolumns("Revenue", "market_otb_pickup"),
                        "order": 5
                    },
                    {
                        ...generatDefaultSubcolumns("SellRate", "market_otb_pickup"),
                        // "hidden": true,
                        "order": 6
                    }
                ],

            },
            {
                "id": "market_projected",
                "type": "dataColumn",
                "mainKey": "market_projected",
                "tileName": "market",
                "display": "{UNCONSTRAINED} Market Forecast AS OF - %asOfDate%",
                "backgroundColor": "grey",
                "className": "col-main",
                "draggable": true,
                "hidden": false,
                "colspan": 6,
                "order": 11,
                "subcolumns": [
                    {
                        ...generatDefaultSubcolumns("Rooms", "market_projected"),
                        "order": 2,
                    },
                    {
                        ...generatDefaultSubcolumns("Occ", "market_projected"),
                        "order": 1,
                    },
                    {
                        ...generatDefaultSubcolumns("ADR", "market_projected"),
                        "order": 3
                    },
                    {
                        ...generatDefaultSubcolumns("RevPAR", "market_projected"),
                        "order": 4
                    },
                    {
                        ...generatDefaultSubcolumns("Revenue", "market_projected"),
                        "order": 5
                    },
                    {
                        ...generatDefaultSubcolumns("SellRate", "market_projected"),
                        // "hidden": true,
                        "order": 6
                    }
                ],

            }
        ],
        dataDbaColumns: [
            {
                "id": "market_pace",
                "type": "dataColumn",
                "mainKey": "primary",
                "tileName": "market",

                "display": "Market Pace",
                "backgroundColor": "grey",
                "className": "col-main",
                "draggable": true,
                "hidden": false,
                "colspan": 6,
                "order": 1,
                "subcolumns": [
                    {
                        ...generatDefaultSubcolumns("Rooms", "market_pace"),
                        "order": 2
                    },
                    {
                        ...generatDefaultSubcolumns("Occ", "market_pace"),
                        "order": 1,
                    },
                    {
                        ...generatDefaultSubcolumns("ADR", "market_pace"),
                        "order": 3
                    },
                    {
                        ...generatDefaultSubcolumns("RevPAR", "market_pace"),
                        "order": 4
                    },
                    {
                        ...generatDefaultSubcolumns("Revenue", "market_pace"),
                        "order": 5
                    },
                    {
                        ...generatDefaultSubcolumns("SellRate", "market_pace"),
                        "hidden": true,
                        "order": 6
                    }
                ],

            },
           
        ],
        dbaOn_sdly_columns:[
            {
                "id": "market_dba_sdly_pace",
                "type": "dataColumn",
                "mainKey": "sdly_dba_comp",
                "tileName": "market",

                "display": "SDLY Comparision Market Pace",
                "backgroundColor": "grey",
                "className": "col-main",
                "draggable": true,
                "hidden": false,
                "colspan": 6,
                "order": 2,
                "subcolumns": [
                    {
                        ...generatDefaultSubcolumns("Rooms", "market_dba_sdly_pace"),
                        "order": 2
                    },
                    {
                        ...generatDefaultSubcolumns("Occ", "market_dba_sdly_pace"),
                        "order": 1,
                    },
                    {
                        ...generatDefaultSubcolumns("ADR", "market_dba_sdly_pace"),
                        "order": 3
                    },
                    {
                        ...generatDefaultSubcolumns("RevPAR", "market_dba_sdly_pace"),
                        "order": 4
                    },
                    {
                        ...generatDefaultSubcolumns("Revenue", "market_dba_sdly_pace"),
                        "order": 5
                    },
                    {
                        ...generatDefaultSubcolumns("SellRate", "market_dba_sdly_pace"),
                        "hidden": true,
                        "order": 6
                    }
                ],

            },
            {
                "id": "market_dba_sdly_variance",
                "type": "dataColumn",
                "mainKey": "dba_variance",
                "tileName": "market",

                "display": "Variance",
                "backgroundColor": "grey",
                "className": "col-main",
                "draggable": true,
                "hidden": false,
                "colspan": 6,
                "order": 3,
                "subcolumns": [
                    {
                        ...generatDefaultSubcolumns("Rooms", "market_dba_sdly_variance"),
                        "order": 2
                    },
                    {
                        ...generatDefaultSubcolumns("Occ", "market_dba_sdly_variance"),
                        "order": 1,
                    },
                    {
                        ...generatDefaultSubcolumns("ADR", "market_dba_sdly_variance"),
                        "order": 3
                    },
                    {
                        ...generatDefaultSubcolumns("RevPAR", "market_dba_sdly_variance"),
                        "order": 4
                    },
                    {
                        ...generatDefaultSubcolumns("Revenue", "market_dba_sdly_variance"),
                        "order": 5
                    },
                    {
                        ...generatDefaultSubcolumns("SellRate", "market_dba_sdly_variance"),
                        "hidden": true,
                        "order": 6
                    }
                ],

            },
        ],
        marketDateSdlyOn_columns: [
            {
                "id": "market_sdly",
                "type": "dataColumn",
                "mainKey": "sdly",
                "tileName": "market",
                "display": "Market Sdly",
                "backgroundColor": "grey",
                "className": "col-main",
                "draggable": true,
                "hidden": false,
                "colspan": 6,
                "order": 5,
                "subcolumns": [
                    {
                        ...generatDefaultSubcolumns("Rooms", "market_sdly"),
                        "order": 2
                    },
                    {
                        ...generatDefaultSubcolumns("Occ", "market_sdly"),
                        "order": 1,
                    },
                    {
                        ...generatDefaultSubcolumns("ADR", "market_sdly"),
                        "order": 3
                    },
                    {
                        ...generatDefaultSubcolumns("RevPAR", "market_sdly"),
                        "order": 4
                    },
                    {
                        ...generatDefaultSubcolumns("Revenue", "market_sdly"),
                        "order": 5
                    },
                    {
                        ...generatDefaultSubcolumns("SellRate", "market_sdly"),
                        "order": 6
                    }
                ],

            },
            {
                "id": "diff_market_sdly", // likewise: otb_sdly, otb_prior,...
                "type": "dataColumn",
                "mainKey": "sdly_diff_sdowly",
                "tileName": "market",
                "display": "Difference to Market SDLY",
                "backgroundColor": "grey",
                "className": "col-main",
                "draggable": true,
                "hidden": false,
                "colspan": 6,
                "order": 6,
                "subcolumns": [
                    {
                        ...generatDefaultSubcolumns("Rooms", "diff_market_sdly"),
                        "order": 2
                    },
                    {
                        ...generatDefaultSubcolumns("Occ", "diff_market_sdly"),
                        "order": 1,
                    },
                    {
                        ...generatDefaultSubcolumns("ADR", "diff_market_sdly"),
                        "order": 3
                    },
                    {
                        ...generatDefaultSubcolumns("RevPAR", "diff_market_sdly"),
                        "order": 4
                    },
                    {
                        ...generatDefaultSubcolumns("Revenue", "diff_market_sdly"),
                        "order": 5
                    },
                    {
                        ...generatDefaultSubcolumns("SellRate", "diff_market_sdly"),
                        "order": 6
                    }
                ],
            },
            {
                "id": "market_variance_sdly", // likewise: otb_sdly, otb_prior,...
                "type": "dataColumn",
                "mainKey": "sdly_variance_sdowly",
                "tileName": "market",
                "display": "% Difference to Market SDLY",
                "backgroundColor": "grey",
                "className": "col-main",
                "draggable": true,
                "hidden": false,
                "colspan": 6,
                "order": 7,
                "subcolumns": [
                    {
                        ...generatDefaultSubcolumns("Rooms", "market_variance_sdly"),
                        "order": 2,
                    },
                    {
                        ...generatDefaultSubcolumns("Occ", "market_variance_sdly"),
                        "order": 1,
                    },
                    {
                        ...generatDefaultSubcolumns("ADR", "market_variance_sdly"),
                        "order": 3,
                    },
                    {
                        ...generatDefaultSubcolumns("RevPAR", "market_variance_sdly"),
                        "order": 4,
                    },
                    {
                        ...generatDefaultSubcolumns("Revenue", "market_variance_sdly"),
                        "order": 5,
                    },
                    {
                        ...generatDefaultSubcolumns("SellRate", "market_variance_sdly"),
                        hidden:true,
                        "order": 6,
                    }
                ],
            },
            {
                "id": "market_actual_sdly", // likewise: otb_sdly, otb_prior,...
                "type": "dataColumn",
                "mainKey": "sdly_actual",
                "tileName": "market",
                "display": "Last Year Actual",
                "backgroundColor": "grey",
                "className": "col-main",
                "draggable": true,
                "hidden": false,
                "colspan": 6,
                "order": 8,
                "subcolumns": [
                    {
                        ...generatDefaultSubcolumns("Rooms", "market_actual_sdly"),
                        "order": 2
                    },
                    {
                        ...generatDefaultSubcolumns("Occ", "market_actual_sdly"),
                        "order": 1,
                    },
                    {
                        ...generatDefaultSubcolumns("ADR", "market_actual_sdly"),
                        "order": 3
                    },
                    {
                        ...generatDefaultSubcolumns("RevPAR", "market_actual_sdly"),
                        "order": 4
                    },
                    {
                        ...generatDefaultSubcolumns("Revenue", "market_actual_sdly"),
                        "order": 5
                    },
                    {
                        ...generatDefaultSubcolumns("SellRate", "market_actual_sdly"),
                        "hidden": true,
                        "order": 6
                    }
                ],
            },
            {
                "id": "sdly_diff_last_year", // likewise: otb_sdly, otb_prior,...
                "type": "dataColumn",
                "mainKey": "sdly_diff_market_last_year_actuals",
                "tileName": "market",
                "display": "Difference to Market LY Actual ",
                "backgroundColor": "grey",
                "className": "col-main",
                "draggable": true,
                "hidden": false,
                "colspan": 6,
                "order": 9,
                "subcolumns": [
                    {
                        ...generatDefaultSubcolumns("Rooms", "sdly_diff_last_year"),
                        "order": 2
                    },
                    {
                        ...generatDefaultSubcolumns("Occ", "sdly_diff_last_year"),
                        "order": 1,
                    },
                    {
                        ...generatDefaultSubcolumns("ADR", "sdly_diff_last_year"),
                        "order": 3
                    },
                    {
                        ...generatDefaultSubcolumns("RevPAR", "sdly_diff_last_year"),
                        "order": 4
                    },
                    {
                        ...generatDefaultSubcolumns("Revenue", "sdly_diff_last_year"),
                        "order": 5
                    },
                    {
                        ...generatDefaultSubcolumns("SellRate", "sdly_diff_last_year"),
                        
                        "order": 6
                    }
                ],
            },
            {
                "id": "sdly_var_market_last_year", // likewise: otb_sdly, otb_prior,...
                "type": "dataColumn",
                "mainKey": "sdly_variance_market_last_year_actuals",
                "tileName": "market",
                "display": "% Difference to Market LY Actual",
                "backgroundColor": "grey",
                "className": "col-main",
                "draggable": true,
                "hidden": false,
                "colspan": 6,
                "order": 10,
                "subcolumns": [
                    {
                        ...generatDefaultSubcolumns("Rooms", "sdly_var_market_last_year"),
                        "order": 2,
                    },
                    {
                        ...generatDefaultSubcolumns("Occ", "sdly_var_market_last_year"),
                        "order": 1,
                    },
                    {
                        ...generatDefaultSubcolumns("ADR", "sdly_var_market_last_year"),
                        "order": 3,
                    },
                    {
                        ...generatDefaultSubcolumns("RevPAR", "sdly_var_market_last_year"),
                        "order": 4,
                    },
                    {
                        ...generatDefaultSubcolumns("Revenue", "sdly_var_market_last_year"),
                        "order": 5,
                    },
                    {
                        ...generatDefaultSubcolumns("SellRate", "sdly_var_market_last_year"),
                        "hidden": true,
                        "order": 6
                    }
                ],
            },
        ],
    },

    // configuration for Market Summary (market_summary)
    market_summary: {
        columns: [
            {
                "id": "staydates", // likewise: otb_sdly, otb_prior,...
                "type": "dateColumn",
                "display": "{rangeyear}",
                "backgroundColor": "grey",
                "className": "otb data-column otb-current",
                "draggable": false,
                "colspan": 2, //This should be <subcolumns.length>
                "order": 1,
                "subcolumns": [
                    {
                        ...generatDefaultSubcolumns("month", "staydates"),
                        // "draggable": true,
                        "sorted": 'asc',
                        "filterable": false, "filterMenu": false,
                        "order": 1
                    }
                ],
            },

            {
                "id": "market_summary_otb",
                "type": "dataColumn",
                "mainKey": "primary",
                "display": "OTB As Of - {asOfDate}",
                "backgroundColor": "grey",
                "className": "col-main",
                "draggable": true,
                "hidden": false,
                "colspan": 6,
                "order": 2,
                "subcolumns": [
                    {
                        ...generatDefaultSubcolumns("Rooms", "market_summary_otb"),
                        "order": 1,
                        // "hidden": false,
                        // "sortable": false,
                        // "sorted": "asc"

                    },
                    {
                        ...generatDefaultSubcolumns("Occ", "market_summary_otb"),
                        "order": 2,
                    },
                    {
                        ...generatDefaultSubcolumns("ADR", "market_summary_otb"),
                        "order": 3
                    },
                    {
                        ...generatDefaultSubcolumns("RevPAR", "market_summary_otb"),
                        "order": 4
                    },
                    {
                        ...generatDefaultSubcolumns("Revenue", "market_summary_otb"),
                        "order": 5
                    },
                    {
                        ...generatDefaultSubcolumns("SellRate", "market_summary_otb"),
                        // "hidden": true,
                        "order": 6
                    }
                ],

            },

            // Market summary SDLY Column
            {
                "id": "market_summary_sdly_otb",
                "type": "dataColumn",
                "mainKey": "sdly",
                "display": "OTB As Of - {substract364}",
                "backgroundColor": "grey",
                "className": "col-main",
                "draggable": true,
                "hidden": false,
                "colspan": 6,
                "order": 3,
                "subcolumns": [
                    {
                        ...generatDefaultSubcolumns("Rooms", "market_summary_sdly_otb"),
                        "order": 1,
                        // "hidden": false,
                        // "sortable": false,
                        // "sorted": "asc"

                    },
                    {
                        ...generatDefaultSubcolumns("Occ", "market_summary_sdly_otb"),
                        "order": 2,
                    },
                    {
                        ...generatDefaultSubcolumns("ADR", "market_summary_sdly_otb"),
                        "order": 3
                    },
                    {
                        ...generatDefaultSubcolumns("RevPAR", "market_summary_sdly_otb"),
                        "order": 4
                    },
                    {
                        ...generatDefaultSubcolumns("Revenue", "market_summary_sdly_otb"),
                        "order": 5
                    },
                    {
                        ...generatDefaultSubcolumns("SellRate", "market_summary_sdly_otb"),
                        // "hidden": true,
                        "order": 6
                    }
                ],

            },
            {
                "id": "market_summary_sdly_variance",
                "type": "dataColumn",
                "mainKey": "difference_sdly",
                "display": "Difference to SDLY",
                "backgroundColor": "grey",
                "className": "col-main",
                "draggable": true,
                "hidden": false,
                "colspan": 6,
                "order": 4,
                "subcolumns": [
                    {
                        ...generatDefaultSubcolumns("Rooms", "market_summary_sdly_variance"),
                        "order": 1,
                        // "hidden": false,
                        // "sortable": false,
                        // "sorted": "asc"

                    },
                    {
                        ...generatDefaultSubcolumns("Occ", "market_summary_sdly_variance"),
                        "order": 2,
                    },
                    {
                        ...generatDefaultSubcolumns("ADR", "market_summary_sdly_variance"),
                        "order": 3
                    },
                    {
                        ...generatDefaultSubcolumns("RevPAR", "market_summary_sdly_variance"),
                        "order": 4
                    },
                    {
                        ...generatDefaultSubcolumns("Revenue", "market_summary_sdly_variance"),
                        "order": 5
                    },
                    {
                        ...generatDefaultSubcolumns("SellRate", "market_summary_sdly_variance"),
                        // "hidden": true,
                        "order": 6
                    }
                ],

            },

            {
                "id": "market_summary_variance_percent",
                "type": "dataColumn",
                "mainKey": "difference_sdly_percent",
                "display": "% Difference to SDLY",
                "backgroundColor": "grey",
                "className": "col-main",
                "draggable": true,
                "hidden": false,
                "colspan": 6,
                "order": 5,
                "subcolumns": [
                    {
                        ...generatDefaultSubcolumns("Rooms", "market_summary_variance_percent"),
                        "order": 1,
                        // "hidden": false,
                        // "sortable": false,
                        // "sorted": "asc"

                    },
                    {
                        ...generatDefaultSubcolumns("Occ", "market_summary_variance_percent"),
                        "order": 2,
                    },
                    {
                        ...generatDefaultSubcolumns("ADR", "market_summary_variance_percent"),
                        "order": 3
                    },
                    {
                        ...generatDefaultSubcolumns("RevPAR", "market_summary_variance_percent"),
                        "order": 4
                    },
                    {
                        ...generatDefaultSubcolumns("Revenue", "market_summary_variance_percent"),
                        "order": 5
                    },
                    {
                        ...generatDefaultSubcolumns("SellRate", "market_summary_variance_percent"),
                        "hidden": true,
                        "order": 6
                    }
                ],

            },

            {
                "id": "market_summary_total_forcast",
                "type": "dataColumn",
                "mainKey": "total_forecast",
                "display": "System Forcast As Of - {substract364}",
                "backgroundColor": "grey",
                "className": "col-main",
                "draggable": true,
                "hidden": false,
                "colspan": 6,
                "order": 6,
                "subcolumns": [
                    {
                        ...generatDefaultSubcolumns("Rooms", "market_summary_total_forcast"),
                        "order": 1,
                        // "hidden": false,
                        // "sortable": false,
                        // "sorted": "asc"

                    },
                    {
                        ...generatDefaultSubcolumns("Occ", "market_summary_total_forcast"),
                        "order": 2,
                    },
                    {
                        ...generatDefaultSubcolumns("ADR", "market_summary_total_forcast"),
                        "order": 3
                    },
                    {
                        ...generatDefaultSubcolumns("RevPAR", "market_summary_total_forcast"),
                        "order": 4
                    },
                    {
                        ...generatDefaultSubcolumns("Revenue", "market_summary_total_forcast"),
                        "order": 5
                    },
                    {
                        ...generatDefaultSubcolumns("SellRate", "market_summary_total_forcast"),
                        // "hidden": true,
                        "order": 6
                    }
                ],

            },

            {
                "id": "market_summary_sdly_actuals",
                "type": "dataColumn",
                "mainKey": "sdly_actuals",
                "display": "Last Year Actuals",
                "backgroundColor": "grey",
                "className": "col-main",
                "draggable": true,
                "hidden": false,
                "colspan": 6,
                "order": 7,
                "subcolumns": [
                    {
                        ...generatDefaultSubcolumns("Rooms", "market_summary_sdly_actuals"),
                        "order": 1,
                        // "hidden": false,
                        // "sortable": false,
                        // "sorted": "asc"

                    },
                    {
                        ...generatDefaultSubcolumns("Occ", "market_summary_sdly_actuals"),
                        "order": 2,
                    },
                    {
                        ...generatDefaultSubcolumns("ADR", "market_summary_sdly_actuals"),
                        "order": 3
                    },
                    {
                        ...generatDefaultSubcolumns("RevPAR", "market_summary_sdly_actuals"),
                        "order": 4
                    },
                    {
                        ...generatDefaultSubcolumns("Revenue", "market_summary_sdly_actuals"),
                        "order": 5
                    },
                    {
                        ...generatDefaultSubcolumns("SellRate", "market_summary_sdly_actuals"),
                        // "hidden": true,
                        "order": 6
                    }
                ],

            },

            {
                "id": "market_summary_sdly_actuals_variance",
                "type": "dataColumn",
                "mainKey": "difference_forcast_actualSDLY",
                "display": "System forecast - LY Actuals",
                "backgroundColor": "grey",
                "className": "col-main",
                "draggable": true,
                "hidden": false,
                "colspan": 6,
                "order": 8,
                "subcolumns": [
                    {
                        ...generatDefaultSubcolumns("Rooms", "market_summary_sdly_actuals_variance"),
                        "order": 1,
                        // "hidden": false,
                        // "sortable": false,
                        // "sorted": "asc"

                    },
                    {
                        ...generatDefaultSubcolumns("Occ", "market_summary_sdly_actuals_variance"),
                        "order": 2,
                    },
                    {
                        ...generatDefaultSubcolumns("ADR", "market_summary_sdly_actuals_variance"),
                        "order": 3
                    },
                    {
                        ...generatDefaultSubcolumns("RevPAR", "market_summary_sdly_actuals_variance"),
                        "order": 4
                    },
                    {
                        ...generatDefaultSubcolumns("Revenue", "market_summary_sdly_actuals_variance"),
                        "order": 5
                    },
                    {
                        ...generatDefaultSubcolumns("SellRate", "market_summary_sdly_actuals_variance"),
                        // "hidden": true,
                        "order": 6
                    }
                ],

            },

            {
                "id": "market_summary_sdly_actuals_variance_percent",
                "type": "dataColumn",
                "mainKey": "difference_forcast_actualSDLY_percent",
                "display": "System forecast - LY Actuals (%)",
                "backgroundColor": "grey",
                "className": "col-main",
                "draggable": true,
                "hidden": false,
                "colspan": 6,
                "order": 9,
                "subcolumns": [
                    {
                        ...generatDefaultSubcolumns("Rooms", "market_summary_sdly_actuals_variance_percent"),
                        "order": 1,
                        // "hidden": false,
                        // "sortable": false,
                        // "sorted": "asc"

                    },
                    {
                        ...generatDefaultSubcolumns("Occ", "market_summary_sdly_actuals_variance_percent"),
                        "order": 2,
                    },
                    {
                        ...generatDefaultSubcolumns("ADR", "market_summary_sdly_actuals_variance_percent"),
                        "order": 3
                    },
                    {
                        ...generatDefaultSubcolumns("RevPAR", "market_summary_sdly_actuals_variance_percent"),
                        "order": 4
                    },
                    {
                        ...generatDefaultSubcolumns("Revenue", "market_summary_sdly_actuals_variance_percent"),
                        "order": 5
                    },
                    {
                        ...generatDefaultSubcolumns("SellRate", "market_summary_sdly_actuals_variance_percent"),
                        "hidden": true,
                        "order": 6
                    }
                ],

            },


        ],
    },

    // configuration for Hotel Summary (hotel_summary)
    hotel_summary: {
        columns: [
            {
                "id": "hotel_staydates", // likewise: otb_sdly, otb_prior,...
                "type": "dateColumn",
                "display": "{rangeyear}}",
                "backgroundColor": "grey",
                "className": "otb data-column otb-current",
                "draggable": false,
                "colspan": 2, //This should be <subcolumns.length>
                "order": 1,
                "subcolumns": [
                    {
                        ...generatDefaultSubcolumns("month", "hotel_staydates"),
                        // "draggable": true,
                        "sorted": 'asc',
                        "filterable": false, "filterMenu": false,
                        "order": 1
                    }
                ],
            },

            {
                "id": "hotel_summary_otb",
                "type": "dataColumn",
                "mainKey": "primary",
                "display": "OTB As Of - {asOfDate}",
                "backgroundColor": "grey",
                "className": "col-main",
                "draggable": true,
                "hidden": false,
                "colspan": 6,
                "order": 2,
                "subcolumns": [
                    {
                        ...generatDefaultSubcolumns("Occ", "hotel_summary_otb"),
                        "order": 1,
                    },
                    {
                        ...generatDefaultSubcolumns("Rooms", "hotel_summary_otb"),
                        "order": 2,
                        // "hidden": false,
                        // "sortable": false,
                        // "sorted": "asc"

                    },
                    {
                        ...generatDefaultSubcolumns("ADR", "hotel_summary_otb"),
                        "order": 3
                    },
                    {
                        ...generatDefaultSubcolumns("RevPAR", "hotel_summary_otb"),
                        "order": 4
                    },
                    {
                        ...generatDefaultSubcolumns("Revenue", "hotel_summary_otb"),
                        "order": 5
                    },
                    {
                        ...generatDefaultSubcolumns("SellRate", "hotel_summary_otb"),
                        // "hidden": true,
                        "order": 6
                    }
                ],

            },

            // Market summary SDLY Column
            {
                "id": "hotel_summary_sdly_otb",
                "type": "dataColumn",
                "mainKey": "sdly",
                "display": "OTB As Of - {substract364}",
                "backgroundColor": "grey",
                "className": "col-main",
                "draggable": true,
                "hidden": false,
                "colspan": 6,
                "order": 3,
                "subcolumns": [
                    {
                        ...generatDefaultSubcolumns("Occ", "hotel_summary_sdly_otb"),
                        "order": 1,
                    },
                    {
                        ...generatDefaultSubcolumns("Rooms", "hotel_summary_sdly_otb"),
                        "order": 2,
                    },

                    {
                        ...generatDefaultSubcolumns("ADR", "hotel_summary_sdly_otb"),
                        "order": 3
                    },
                    {
                        ...generatDefaultSubcolumns("RevPAR", "hotel_summary_sdly_otb"),
                        "order": 4
                    },
                    {
                        ...generatDefaultSubcolumns("Revenue", "hotel_summary_sdly_otb"),
                        "order": 5
                    },
                    {
                        ...generatDefaultSubcolumns("SellRate", "hotel_summary_sdly_otb"),
                        // "hidden": true,
                        "order": 6
                    }
                ],

            },
            {
                "id": "hotel_summary_sdly_variance",
                "type": "dataColumn",
                "mainKey": "difference_sdly",
                "display": "Difference To SDLY",
                "backgroundColor": "grey",
                "className": "col-main",
                "draggable": true,
                "hidden": false,
                "colspan": 6,
                "order": 4,
                "subcolumns": [
                    {
                        ...generatDefaultSubcolumns("Rooms", "hotel_summary_sdly_variance"),
                        "order": 2,
                        // "hidden": false,
                        // "sortable": false,
                        // "sorted": "asc"

                    },
                    {
                        ...generatDefaultSubcolumns("Occ", "hotel_summary_sdly_variance"),
                        "order": 1,
                    },
                    {
                        ...generatDefaultSubcolumns("ADR", "hotel_summary_sdly_variance"),
                        "order": 3
                    },
                    {
                        ...generatDefaultSubcolumns("RevPAR", "hotel_summary_sdly_variance"),
                        "order": 4
                    },
                    {
                        ...generatDefaultSubcolumns("Revenue", "hotel_summary_sdly_variance"),
                        "order": 5
                    },
                    {
                        ...generatDefaultSubcolumns("SellRate", "hotel_summary_sdly_variance"),
                        // "hidden": true,
                        "order": 6
                    }
                ],

            },

            {
                "id": "hotel_summary_variance_percent",
                "type": "dataColumn",
                "mainKey": "difference_sdly_percent",
                "display": "% Difference to SDLY",
                "backgroundColor": "grey",
                "className": "col-main",
                "draggable": true,
                "hidden": false,
                "colspan": 6,
                "order": 5,
                "subcolumns": [
                    {
                        ...generatDefaultSubcolumns("Rooms", "hotel_summary_variance_percent"),
                        "order": 2,
                        // "hidden": false,
                        // "sortable": false,
                        // "sorted": "asc"

                    },
                    {
                        ...generatDefaultSubcolumns("Occ", "hotel_summary_variance_percent"),
                        "order": 1,
                    },
                    {
                        ...generatDefaultSubcolumns("ADR", "hotel_summary_variance_percent"),
                        "order": 3
                    },
                    {
                        ...generatDefaultSubcolumns("RevPAR", "hotel_summary_variance_percent"),
                        "order": 4
                    },
                    {
                        ...generatDefaultSubcolumns("Revenue", "hotel_summary_variance_percent"),
                        "order": 5
                    },
                    {
                        ...generatDefaultSubcolumns("SellRate", "hotel_summary_variance_percent"),
                        "hidden": true,
                        "order": 6
                    }
                ],

            },

            {
                "id": "hotel_summary_total_forcast",
                "type": "dataColumn",
                "mainKey": "total_forecast",
                "display": "System Forcast As Of - {asOfDate}",
                "backgroundColor": "grey",
                "className": "col-main",
                "draggable": true,
                "hidden": false,
                "colspan": 6,
                "order": 6,
                "subcolumns": [
                    {
                        ...generatDefaultSubcolumns("Rooms", "hotel_summary_total_forcast"),
                        "order": 2,
                        // "hidden": false,
                        // "sortable": false,
                        // "sorted": "asc"

                    },
                    {
                        ...generatDefaultSubcolumns("Occ", "hotel_summary_total_forcast"),
                        "order": 1,
                    },
                    {
                        ...generatDefaultSubcolumns("ADR", "hotel_summary_total_forcast"),
                        "order": 3
                    },
                    {
                        ...generatDefaultSubcolumns("RevPAR", "hotel_summary_total_forcast"),
                        "order": 4
                    },
                    {
                        ...generatDefaultSubcolumns("Revenue", "hotel_summary_total_forcast"),
                        "order": 5
                    },
                    {
                        ...generatDefaultSubcolumns("SellRate", "hotel_summary_total_forcast"),
                        // "hidden": true,
                        "order": 6
                    }
                ],

            },

            {
                "id": "hotel_summary_sdly_actuals",
                "type": "dataColumn",
                "mainKey": "sdly_actuals",
                "display": "Last Year Actual",
                "backgroundColor": "grey",
                "className": "col-main",
                "draggable": true,
                "hidden": false,
                "colspan": 6,
                "order": 7,
                "subcolumns": [
                    {
                        ...generatDefaultSubcolumns("Rooms", "hotel_summary_sdly_actuals"),
                        "order": 2,
                        // "hidden": false,
                        // "sortable": false,
                        // "sorted": "asc"

                    },
                    {
                        ...generatDefaultSubcolumns("Occ", "hotel_summary_sdly_actuals"),
                        "order": 1,
                    },
                    {
                        ...generatDefaultSubcolumns("ADR", "hotel_summary_sdly_actuals"),
                        "order": 3
                    },
                    {
                        ...generatDefaultSubcolumns("RevPAR", "hotel_summary_sdly_actuals"),
                        "order": 4
                    },
                    {
                        ...generatDefaultSubcolumns("Revenue", "hotel_summary_sdly_actuals"),
                        "order": 5
                    },
                    {
                        ...generatDefaultSubcolumns("SellRate", "hotel_summary_sdly_actuals"),
                        // "hidden": true,
                        "order": 6
                    }
                ],

            },

            {
                "id": "hotel_summary_sdly_actuals_variance",
                "type": "dataColumn",
                "mainKey": "difference_forcast_actualSDLY",
                "display": "System Forecast - LY Actuals",
                "backgroundColor": "grey",
                "className": "col-main",
                "draggable": true,
                "hidden": false,
                "colspan": 6,
                "order": 8,
                "subcolumns": [
                    {
                        ...generatDefaultSubcolumns("Rooms", "hotel_summary_sdly_actuals_variance"),
                        "order": 2,
                        // "hidden": false,
                        // "sortable": false,
                        // "sorted": "asc"

                    },
                    {
                        ...generatDefaultSubcolumns("Occ", "hotel_summary_sdly_actuals_variance"),
                        "order": 1,
                    },
                    {
                        ...generatDefaultSubcolumns("ADR", "hotel_summary_sdly_actuals_variance"),
                        "order": 3
                    },
                    {
                        ...generatDefaultSubcolumns("RevPAR", "hotel_summary_sdly_actuals_variance"),
                        "order": 4
                    },
                    {
                        ...generatDefaultSubcolumns("Revenue", "hotel_summary_sdly_actuals_variance"),
                        "order": 5
                    },
                    {
                        ...generatDefaultSubcolumns("SellRate", "hotel_summary_sdly_actuals_variance"),
                        // "hidden": true,
                        "order": 6
                    }
                ],

            },

            {
                "id": "hotel_summary_sdly_actuals_variance_percent",
                "type": "dataColumn",
                "mainKey": "difference_forcast_actualSDLY_percent",
                "display": "System Forecast - LY Actuals (%)",
                "backgroundColor": "grey",
                "className": "col-main",
                "draggable": true,
                "hidden": false,
                "colspan": 6,
                "order": 9,
                "subcolumns": [
                    {
                        ...generatDefaultSubcolumns("Rooms", "hotel_summary_sdly_actuals_variance_percent"),
                        "order": 2,
                        // "hidden": false,
                        // "sortable": false,
                        // "sorted": "asc"

                    },
                    {
                        ...generatDefaultSubcolumns("Occ", "hotel_summary_sdly_actuals_variance_percent"),
                        "order": 1,
                    },
                    {
                        ...generatDefaultSubcolumns("ADR", "hotel_summary_sdly_actuals_variance_percent"),
                        "order": 3
                    },
                    {
                        ...generatDefaultSubcolumns("RevPAR", "hotel_summary_sdly_actuals_variance_percent"),
                        "order": 4
                    },
                    {
                        ...generatDefaultSubcolumns("Revenue", "hotel_summary_sdly_actuals_variance_percent"),
                        "order": 5
                    },
                    {
                        ...generatDefaultSubcolumns("SellRate", "hotel_summary_sdly_actuals_variance_percent"),
                        "hidden": true,
                        "order": 6
                    }
                ],

            },


        ],
    },

    // Configuration of Forcast 

    forecast: {
        columns: [
            {
                "id": "staydates", // likewise: otb_sdly, otb_prior,...
                "type": "dateColumn",
                "display": "{rangeyear}",
                "backgroundColor": "grey",
                "className": "otb data-column otb-current",
                "draggable": false,
                "colspan": 2, //This should be <subcolumns.length>
                "order": 1,

                "subcolumns": [
                    {
                        ...generatDefaultSubcolumns("date", "staydates"),
                        // "draggable": true,
                        "sorted": null,
                        "filterable": false, "filterMenu": false,
                        "order": 1
                    },
                    {
                        ...generatDefaultSubcolumns("dow", "staydates"),
                        "draggable": false,
                        "filterable": false, "filterMenu": false,
                        "order": 2
                    },

                ],
                "subcolumns_segmented": [
                    {
                        ...generatDefaultSubcolumns("segment", "staydates"),
                        "draggable": false,
                        "filterable": false,
                        // "hidden": true,
                        "order": 3
                    }
                ]

            },

        ],
        dataDateColumns: [
            {
                "id": "forecast_primary",
                "type": "dataColumn",
                "mainKey": "primary",
                "tileName": "forecast",
                "display": "System Forecast As Of - {asOfDate}",
                "backgroundColor": "grey",
                "className": "col-main",
                "draggable": true,
                "hidden": false,
                "colspan": 6,
                "order": 2,
                "subcolumns": [
                    {
                        ...generatDefaultSubcolumns("Occ", "forecast_primary"),
                        "order": 1,
                    },
                    {
                        ...generatDefaultSubcolumns("Rooms", "forecast_primary"),
                        "order": 2,
                        // "hidden": false,
                        // "sortable": false,
                        // "sorted": "asc"

                    },

                    {
                        ...generatDefaultSubcolumns("ADR", "forecast_primary"),
                        "order": 3
                    },
                    {
                        ...generatDefaultSubcolumns("RevPAR", "forecast_primary"),
                        "order": 4
                    },
                    {
                        ...generatDefaultSubcolumns("Revenue", "forecast_primary"),
                        "order": 5
                    },
                    {
                        ...generatDefaultSubcolumns("SellRate", "forecast_primary"),
                        "hidden": true,
                        "order": 6
                    }
                ],

            }
        ],
        userForecast_columns: [
            {
                "id": "userForecast",
                "type": "dataColumn",
                "mainKey": "userforecastdata",
                "tileName": "forecast",
                "display": "User Forecast Uploaded - {asOfDate}",
                "backgroundColor": "grey",
                "className": "col-main",
                "draggable": true,
                "hidden": false,
                "colspan": 6,
                "order": 3,
                "subcolumns": [
                    {
                        ...generatDefaultSubcolumns("Occ", "userForecast"),
                        "order": 1,
                    },
                    {
                        ...generatDefaultSubcolumns("Rooms", "userForecast"),
                        "order": 2
                    },
                    {
                        ...generatDefaultSubcolumns("ADR", "userForecast"),
                        "order": 3
                    },
                    {
                        ...generatDefaultSubcolumns("RevPAR", "userForecast"),
                        "order": 4
                    },
                    {
                        ...generatDefaultSubcolumns("Revenue", "userForecast"),
                        "order": 5
                    },
                    {
                        ...generatDefaultSubcolumns("SellRate", "userForecast"),
                        "hidden": true,
                        "order": 6
                    }
                ],

            },
            {
                "id": "forecast_difference", // likewise: otb_sdly, otb_prior,...
                "type": "dataColumn",
                "mainKey": "difference",
                "tileName": "forecast",
                "display": "Difference To User Forecast",
                "backgroundColor": "grey",
                "className": "col-main",
                "draggable": true,
                "hidden": false,
                "colspan": 6,
                "order": 4,
                "subcolumns": [
                    {
                        ...generatDefaultSubcolumns("Occ", "forecast_difference"),
                        "order": 1,
                    },
                    {
                        ...generatDefaultSubcolumns("Rooms", "forecast_difference"),
                        "order": 2
                    },

                    {
                        ...generatDefaultSubcolumns("ADR", "forecast_difference"),
                        "order": 3
                    },
                    {
                        ...generatDefaultSubcolumns("RevPAR", "forecast_difference"),
                        "order": 4
                    },
                    {
                        ...generatDefaultSubcolumns("Revenue", "forecast_difference"),
                        "order": 5
                    },
                    {
                        ...generatDefaultSubcolumns("SellRate", "forecast_difference"),
                        "hidden": true,
                        "order": 6
                    }
                ],
            }
        ]
    },
    /// end forecast

    // Configuration for Booking Curve tile (pace)
    pace: {
        columns: [
            {
                "id": "staydates", // likewise: otb_sdly, otb_prior,...
                "type": "dateColumn",
                "display": "{rangeyear}",
                "backgroundColor": "grey",
                "className": "otb data-column otb-current",
                "draggable": false,
                "colspan": 2, //This should be <subcolumns.length>
                "order": 1,
                "subcolumns": [
                    {
                        ...generatDefaultSubcolumns("date", "staydates"),
                        // "draggable": true,
                        "sorted": null,
                        "filterable": false, "filterMenu": false,
                        "order": 1
                    },
                    {
                        ...generatDefaultSubcolumns("dow", "staydates"),
                        "draggable": false,
                        "filterable": false, "filterMenu": false,
                        "order": 2
                    },

                ],
                "subcolumns_dba": [
                    {
                        ...generatDefaultSubcolumns("dba", "staydates"),
                        // "draggable": true,
                        "sorted": null,
                        "filterable": false, "filterMenu": false,
                        "order": 1
                    },


                ],
                "subcolumns_segmented": [
                    {
                        ...generatDefaultSubcolumns("segment", "staydates"),
                        "draggable": false,
                        "filterable": false,
                        // "hidden": true,
                        "order": 3
                    }
                ]
            },
        ],
        dataDateColumns: [
            {
                "id": "booking_curve",
                "type": "dataColumn",
                "mainKey": "primary",
                "tileName": "pace",
                "display": "as of - {asOfDate}",
                "backgroundColor": "grey",
                "className": "col-main",
                "draggable": true,
                "hidden": false,
                "colspan": 6,
                "order": 2,
                "subcolumns": [
                    {
                        ...generatDefaultSubcolumns("Rooms", "booking_curve"),
                        "order": 2,
                        // "hidden": false,
                        // "sortable": false,
                        // "sorted": "asc"

                    },
                    {
                        ...generatDefaultSubcolumns("Occ", "booking_curve"),
                        "order": 1,
                    },
                    {
                        ...generatDefaultSubcolumns("ADR", "booking_curve"),
                        "order": 3
                    },
                    {
                        ...generatDefaultSubcolumns("RevPAR", "booking_curve"),
                        "order": 4
                    },
                    {
                        ...generatDefaultSubcolumns("Revenue", "booking_curve"),
                        "order": 5
                    },
                    // {
                    //     ...generatDefaultSubcolumns("SellRate", "booking_curve"),
                    //     // "hidden": true,
                    //     "order": 6
                    // }
                ],

            },
            {
                "id": "comparison_pace",
                "type": "dataColumn",
                "mainKey": "comparison",
                "tileName": "pace",
                // "display": "{comparisonrangeyear}",
                "display": "as of - {compAsOfDate}",
                "backgroundColor": "grey",
                "className": "col-main",
                "draggable": true,
                "hidden": false,
                "colspan": 6,
                "order": 3,
                "subcolumns": [
                    {
                        ...generatDefaultSubcolumns("compdate", "comparison_pace"),
                        // "sorted": 'asc',
                        "sorted": null,
                        "filterable": false, 
                        "filterMenu": false,
                        "order": 1
                    },

                    {
                        ...generatDefaultSubcolumns("Rooms", "comparison_pace"),
                        "order": 3,
                    },
                    {
                        ...generatDefaultSubcolumns("Occ", "comparison_pace"),
                        "order": 2,
                    },
                    {
                        ...generatDefaultSubcolumns("ADR", "comparison_pace"),
                        "order": 4
                    },
                    {
                        ...generatDefaultSubcolumns("RevPAR", "comparison_pace"),
                        "order": 5
                    },
                    {
                        ...generatDefaultSubcolumns("Revenue", "comparison_pace"),
                        "order": 6
                    },
                    // {
                    //     ...generatDefaultSubcolumns("SellRate", "comparison_pace"),
                    //     // "hidden": true,
                    //     "order": 8
                    // }
                ],

            },
            {
                "id": "bc_date_diff",
                "type": "dataColumn",
                "mainKey": "compDateDIff",
                "tileName": "pace",
                "display": "Difference",
                "backgroundColor": "grey",
                "className": "col-main",
                "draggable": true,
                "hidden": false,
                "colspan": 6,
                "order": 4,
                "subcolumns": [
                    {
                        ...generatDefaultSubcolumns("Rooms", "bc_date_diff"),
                        "order": 2,
                    },
                    {
                        ...generatDefaultSubcolumns("Occ", "bc_date_diff"),
                        "order": 1,
                    },
                    {
                        ...generatDefaultSubcolumns("ADR", "bc_date_diff"),
                        "order": 3
                    },
                    {
                        ...generatDefaultSubcolumns("RevPAR", "bc_date_diff"),
                        "order": 4
                    },
                    {
                        ...generatDefaultSubcolumns("Revenue", "bc_date_diff"),
                        "order": 5
                    },

                ],

            },
        ],
        dataDbaColumns: [
            {
                "id": "booking_curve_dba",
                "type": "dataColumn",
                "mainKey": "primary",
                "tileName": "pace",
                "display": "DBA {dbamaindate}",
                "backgroundColor": "grey",
                "className": "col-main",
                "draggable": true,
                "hidden": false,
                "colspan": 6,
                "order": 2,
                "subcolumns": [
                    {
                        ...generatDefaultSubcolumns("Rooms", "booking_curve_dba"),
                        "order": 2,
                        // "hidden": false,
                        // "sortable": false,
                        // "sorted": "asc"

                    },
                    {
                        ...generatDefaultSubcolumns("Occ", "booking_curve_dba"),
                        "order": 1,
                    },
                    {
                        ...generatDefaultSubcolumns("ADR", "booking_curve_dba"),
                        "order": 3
                    },
                    {
                        ...generatDefaultSubcolumns("RevPAR", "booking_curve_dba"),
                        "order": 4
                    },
                    {
                        ...generatDefaultSubcolumns("Revenue", "booking_curve_dba"),
                        "order": 5
                    },
                    // {
                    //     ...generatDefaultSubcolumns("SellRate", "booking_curve_dba"),
                    //     // "hidden": true,
                    //     "order": 6
                    // }
                ],

            },
            {
                "id": "comparison_pace_dba",
                "type": "dataColumn",
                "mainKey": "comparison",
                "tileName": "pace",

                "display": "DBA {dbacomparisiondate}",
                "backgroundColor": "grey",
                "className": "col-main",
                "draggable": true,
                "hidden": false,
                "colspan": 6,
                "order": 3,
                "subcolumns": [
                    {
                        ...generatDefaultSubcolumns("Rooms", "comparison_pace_dba"),
                        "order": 2,
                        // "hidden": false,
                        // "sortable": false,
                        // "sorted": "asc"

                    },
                    {
                        ...generatDefaultSubcolumns("Occ", "comparison_pace_dba"),
                        "order": 1,
                    },
                    {
                        ...generatDefaultSubcolumns("ADR", "comparison_pace_dba"),
                        "order": 3
                    },
                    {
                        ...generatDefaultSubcolumns("RevPAR", "comparison_pace_dba"),
                        "order": 4
                    },
                    {
                        ...generatDefaultSubcolumns("Revenue", "comparison_pace_dba"),
                        "order": 5
                    },
                    // {
                    //     ...generatDefaultSubcolumns("SellRate", "comparison_pace_dba"),
                    //     // "hidden": true,
                    //     "order": 8
                    // }
                ],

            },
            {
                "id": "bc_dba_diff",
                "type": "dataColumn",
                "mainKey": "compDateDIff",
                "tileName": "pace",

                "display": "Difference",
                "backgroundColor": "grey",
                "className": "col-main",
                "draggable": true,
                "hidden": false,
                "colspan": 6,
                "order": 4,
                "subcolumns": [
                    {
                        ...generatDefaultSubcolumns("Rooms", "bc_dba_diff"),
                        "order": 2,
                    },
                    {
                        ...generatDefaultSubcolumns("Occ", "bc_dba_diff"),
                        "order": 1,
                    },
                    {
                        ...generatDefaultSubcolumns("ADR", "bc_dba_diff"),
                        "order": 3
                    },
                    {
                        ...generatDefaultSubcolumns("RevPAR", "bc_dba_diff"),
                        "order": 4
                    },
                    {
                        ...generatDefaultSubcolumns("Revenue", "bc_dba_diff"),
                        "order": 5
                    },

                ],

            },
        ]
    },
    /// end Booking Curve

    // Configuration for market sell rate 
    market_sell_rates: {
        columns: [
            {
                "id": "staydates", // likewise: otb_sdly, otb_prior,...
                "type": "dateColumn",
                "display": "{rangeyear}",
                "backgroundColor": "grey",
                "className": "otb data-column otb-current",
                "draggable": false,
                "colspan": 2, //This should be <subcolumns.length>
                "order": 1,
                "subcolumns": [
                    {
                        ...generatDefaultSubcolumns("date", "staydates"),
                        // "draggable": true,
                        "sorted": 'asc',
                        "filterable": false, "filterMenu": false,
                        "order": 1
                    },
                    {
                        ...generatDefaultSubcolumns("dow", "staydates"),
                        "draggable": false,
                        "filterable": false, "filterMenu": false,
                        "order": 2
                    },

                ],
            },

        ],
        dataDateColumns: [
            {
                "id": "market_sell_three_star",
                "type": "dataColumn",
                "mainKey": "sets",
                "tileName": "market_sell_rates",
                "k": "3_star",
                "display": "3 Star",
                "backgroundColor": "grey",
                "className": "col-main",
                "draggable": true,
                "hidden": false,
                "colspan": 6,
                "order": 3,
                "subcolumns": [
                    {
                        ...generatDefaultSubcolumns("avgrate", "market_sell_three_star"),
                        "order": 1,
                        // "hidden": false,
                        // "sortable": false,
                        // "sorted": "asc"

                    },

                ],

            },
            {
                "id": "market_sell_four_star",
                "type": "dataColumn",
                "mainKey": "sets",
                "tileName": "market_sell_rates",

                "k": "4_star",
                "display": "4 Star",
                "backgroundColor": "grey",
                "className": "col-main",
                "draggable": true,
                "hidden": false,
                "colspan": 6,
                "order": 4,
                "subcolumns": [
                    {
                        ...generatDefaultSubcolumns("avgrate", "market_sell_four_star"),
                        "order": 1,
                        // "hidden": false,
                        // "sortable": false,
                        // "sorted": "asc"

                    },

                ],

            },
            {
                "id": "market_sell_five_star",
                "type": "dataColumn",
                "mainKey": "sets",
                "tileName": "market_sell_rates",

                "k": "5_star",
                "display": "5 Star",
                "backgroundColor": "grey",
                "className": "col-main",
                "draggable": true,
                "hidden": false,
                "colspan": 6,
                "order": 5,
                "subcolumns": [
                    {
                        ...generatDefaultSubcolumns("avgrate", "market_sell_five_star"),
                        "order": 1,
                        // "hidden": false,
                        // "sortable": false,
                        // "sorted": "asc"

                    },

                ],

            },
        ]

    },

    // End Market Sell Rate 
    // Configuration for Compset 
    compset: {
        columns: [
            {
                "id": "staydates", // likewise: otb_sdly, otb_prior,...
                "type": "dateColumn",
                "display": "{rangeyear}",
                "backgroundColor": "grey",
                "className": "otb data-column otb-current",
                "draggable": false,
                "colspan": 2, //This should be <subcolumns.length>
                "order": 1,
                "subcolumns": [
                    {
                        ...generatDefaultSubcolumns("date", "staydates"),
                        // "draggable": true,
                        "sorted": 'asc',
                        "filterable": false, "filterMenu": false,
                        "order": 1
                    },
                    {
                        ...generatDefaultSubcolumns("dow", "staydates"),
                        "draggable": false,
                        "filterable": false, "filterMenu": false,
                        "order": 2
                    },

                ],
                "subcolumns_dba": [
                    {
                        ...generatDefaultSubcolumns("dba", "staydates"),
                        // "draggable": true,
                        "sorted": 'asc',
                        "filterable": false, "filterMenu": false,
                        "order": 1
                    },


                ],
                "subcolumns_segmented": [
                    {
                        ...generatDefaultSubcolumns("segment", "staydates"),
                        "draggable": false,
                        "filterable": false,
                        // "hidden": true,
                        "order": 3
                    }
                ]
            },
            {
                "id": "compset_medium",
                "type": "dataColumn",
                "mainKey": "primary",
                "tileName": "compset",
                "display": "Compset - As Of {asofdatetime} ",
                "backgroundColor": "grey",
                "className": "col-main",
                "draggable": true,
                "hidden": false,
                "colspan": 6,
                "order": 2,
                "subcolumns": [
                    {
                        ...generatDefaultSubcolumns("cmedium", "compset_medium"),
                        "order": 3,
                    },

                ],

            }
        ],
        dataDateColumns: [

        ],
        dataDbaColumns: [

        ]
    },

    restrictions: {
        columns: [
            {
                "id": "staydates", // likewise: otb_sdly, otb_prior,...
                "type": "dateColumn",
                "display": "{rangeyear}",
                "backgroundColor": "grey",
                "className": "otb data-column otb-current",
                "draggable": false,
                "colspan": 2, //This should be <subcolumns.length>
                "order": 1,

                "subcolumns": [
                    {
                        ...generatDefaultSubcolumns("date", "staydates"),
                        // "draggable": true,
                        // "sorted": 'asc',
                        "filterable": false,
                         "filterMenu": false,
                        "order": 1
                    },
                    {
                        ...generatDefaultSubcolumns("dow", "staydates"),
                        "draggable": false,
                        "filterable": false, "filterMenu": false,
                        "order": 2
                    },

                ],
            },
            {
                "id": "restrictions_otb",
                "type": "dataColumn",
                "mainKey": "otb",
                "display": "OTB",
                "backgroundColor": "grey",
                "className": "col-main",
                "draggable": true,
                "hidden": false,
                "colspan": 2,
                "order": 2,
                "subcolumns": [
                    {
                        ...generatDefaultSubcolumns("Occ", "restrictions_otb"),
                        "order": 1,
                    },
                    {
                        ...generatDefaultSubcolumns("ADR", "restrictions_otb"),
                        "order": 2
                    },
                ],

            },
            {
                "id": "restrictions_forecast",
                "type": "dataColumn",
                "mainKey": "forecast",
                "display": "Forecast",
                "backgroundColor": "grey",
                "className": "col-main",
                "draggable": true,
                "hidden": false,
                "colspan": 2,
                "order": 3,
                "subcolumns": [
                    {
                        ...generatDefaultSubcolumns("Occ", "restrictions_forecast"),
                        "order": 1,
                    },
                    {
                        ...generatDefaultSubcolumns("ADR", "restrictions_forecast"),
                        "order": 2
                    },
                ],

            },
            {
                "id": "restrictions_bar_pricing",
                "type": "dataColumn",
                "mainKey": "bar_pricing",
                "display": "BAR Pricing",
                "backgroundColor": "grey",
                "className": "col-main",
                "draggable": true,
                "hidden": false,
                "colspan": 2,
                "order": 3,
                "subcolumns": [
                    {
                        ...generatDefaultSubcolumns("Rooms", "restrictions_bar_pricing"),
                        "order": 1,
                    },
                    {
                        ...generatDefaultSubcolumns("SellRate", "restrictions_bar_pricing"),
                        "order": 2
                    },
                ],

            },
            {
                "id": "restrictions",
                "type": "dataColumn",
                "mainKey": "restrictions",
                "display": "Restrictions",
                "backgroundColor": "grey",
                "className": "col-main",
                "draggable": true,
                "hidden": false,
                "colspan": 2,
                "order": 4,
                "subcolumns": [
                    {
                        ...generatDefaultSubcolumns("rec", "restrictions"),
                        "order": 1,
                        "type": 'component',
                        "width":140,
                        "sortable": false, 
                    },
                    {
                        ...generatDefaultSubcolumns("cur", "restrictions"),
                        "order": 2,
                        "type": 'component',
                        "width":150,
                        "sortable": false, 
                    },
                    
                ],

            }
        ]
    },

    budget: {
        columns: [
            {
                "id": "staydates", // likewise: otb_sdly, otb_prior,...
                "type": "dateColumn",
                "display": "{rangeyear}",
                "backgroundColor": "grey",
                "className": "otb data-column otb-current",
                "draggable": false,
                "colspan": 2, //This should be <subcolumns.length>
                "order": 1,

                "subcolumns": [
                    {
                        ...generatDefaultSubcolumns("date", "staydates"),
                        // "draggable": true,
                        "sorted": 'asc',
                        "filterable": false, "filterMenu": false,
                        "order": 1
                    },
                    {
                        ...generatDefaultSubcolumns("dow", "staydates"),
                        "draggable": false,
                        "filterable": false, "filterMenu": false,
                        "order": 2
                    },

                ],
            },
            {
                "id": "restrictions_current",
                "type": "dataColumn",
                "mainKey": "primary",
                "tileName": "budget",
                "display": "",
                "backgroundColor": "grey",
                "className": "col-main",
                "draggable": true,
                "hidden": false,
                "colspan": 6,
                "order": 2,
                "subcolumns": [
                    {
                        ...generatDefaultSubcolumns("Rooms", "restrictions_current"),
                        "order": 2,
                        // "hidden": false,
                        // "sortable": false,
                        // "sorted": "asc"

                    },
                    {
                        ...generatDefaultSubcolumns("Occ", "restrictions_current"),
                        "order": 1,
                    },
                    {
                        ...generatDefaultSubcolumns("ADR", "restrictions_current"),
                        "order": 3
                    },
                    {
                        ...generatDefaultSubcolumns("RevPAR", "restrictions_current"),
                        "order": 4
                    },
                    {
                        ...generatDefaultSubcolumns("Revenue", "restrictions_current"),
                        "order": 5
                    }
                ],

            }
        ]
    },

    flights: {
        columns: [
            {
                "id": "staydates", // likewise: otb_sdly, otb_prior,...
                "type": "dateColumn",
                "display": "{rangeyear}",
                "backgroundColor": "grey",
                "className": "otb data-column otb-current",
                "draggable": false,
                "colspan": 2, //This should be <subcolumns.length>
                "order": 1,

                "subcolumns": [
                    {
                        ...generatDefaultSubcolumns("month", "staydates"),
                        // "draggable": true,
                        "sorted": 'asc',
                        "filterable": false, "filterMenu": false,
                        "order": 1
                    },

                ],
            },
        ],
        dataDateColumns: [
            {
                "id": "var_sdlw",
                "type": "dataColumn",
                "mainKey": "primary",
                "tileName": "flight",
                "display": "Variance - sdlw",
                "backgroundColor": "grey",
                "className": "col-main",
                "draggable": true,
                "hidden": false,
                "colspan": 6,
                "order": 2,
                "subcolumns": [
                    {
                        ...generatDefaultSubcolumns("vararrivals", "var_sdlw"),
                        "order": 1,
                        "access_key": "arrival_variance_sdlw",
                    },
                    {
                        ...generatDefaultSubcolumns("varstayover", "var_sdlw"),
                        "order": 2,
                        "access_key": "stayover_variance_sdlw",

                    },
                ],

            },
            {
                "id": "var_sdlm",
                "type": "dataColumn",
                "mainKey": "primary",
                "tileName": "flights",
                "display": "Variance - sdlm",
                "backgroundColor": "grey",
                "className": "col-main",
                "draggable": true,
                "hidden": false,
                "colspan": 6,
                "order": 2,
                "subcolumns": [
                    {
                        ...generatDefaultSubcolumns("vararrivals", "var_sdlm"),
                        "order": 1,
                        "access_key": "arrival_variance_sdlm"
                    },
                    {
                        ...generatDefaultSubcolumns("varstayover", "var_sdlm"),
                        "order": 2,
                        "access_key": "stayover_variance_sdlm"

                    },
                ],

            },
            {
                "id": "var_sdly",
                "type": "dataColumn",
                "mainKey": "primary",
                "tileName": "flights",
                "display": "Variance - historic sdly",
                "backgroundColor": "grey",
                "className": "col-main",
                "draggable": true,
                "hidden": false,
                "colspan": 6,
                "order": 2,
                "subcolumns": [
                    {
                        ...generatDefaultSubcolumns("vararrivals", "var_sdly"),
                        "order": 1,
                        "access_key": "arrival_variance_sdly"

                    },
                    {
                        ...generatDefaultSubcolumns("varstayover", "var_sdly"),
                        "order": 2,
                        "access_key": "stayover_variance_sdly"

                    },
                ],

            },

            {
                "id": "otb",
                "type": "dataColumn",
                "mainKey": "primary",
                "tileName": "flights",
                "display": "OTB",
                "backgroundColor": "grey",
                "className": "col-main",
                "draggable": true,
                "hidden": false,
                "colspan": 6,
                "order": 2,
                "subcolumns": [
                    {
                        ...generatDefaultSubcolumns("otbarrivals", "otb"),
                        "order": 1,
                        "access_key": "arrival_otb"

                    },
                    {
                        ...generatDefaultSubcolumns("otbstayover", "otb"),
                        "order": 2,
                        "access_key": "stayover_otb"

                    },
                ],

            },
            {
                "id": "otb_sdlw",
                "type": "dataColumn",
                "mainKey": "primary",
                "tileName": "flights",
                "display": "OTB SDLW",
                "backgroundColor": "grey",
                "className": "col-main",
                "draggable": true,
                "hidden": false,
                "colspan": 6,
                "order": 2,
                "subcolumns": [
                    {
                        ...generatDefaultSubcolumns("otbarrivals", "otb_sdlw"),
                        "order": 1,
                        "access_key": "arrival_otb_sdlw"

                    },
                    {
                        ...generatDefaultSubcolumns("otbstayover", "otb_sdlw"),
                        "order": 2,
                        "access_key": "stayover_otb_sdlw"

                    },
                ],

            },
            {
                "id": "otb_sdlm",
                "type": "dataColumn",
                "mainKey": "primary",
                "tileName": "flights",
                "display": "OTB SDLM",
                "backgroundColor": "grey",
                "className": "col-main",
                "draggable": true,
                "hidden": false,
                "colspan": 6,
                "order": 2,
                "subcolumns": [
                    {
                        ...generatDefaultSubcolumns("otbarrivals", "otb_sdlm"),
                        "order": 1,
                        "access_key": "arrival_otb_sdlm"

                    },
                    {
                        ...generatDefaultSubcolumns("otbstayover", "otb_sdlm"),
                        "order": 2,
                        "access_key": "stayover_otb_sdlm"

                    },
                ],

            },
            {
                "id": "historic_sdly",
                "type": "dataColumn",
                "mainKey": "primary",
                "tileName": "flights",
                "display": "Historic SDLY",
                "backgroundColor": "grey",
                "className": "col-main",
                "draggable": true,
                "hidden": false,
                "colspan": 6,
                "order": 2,
                "subcolumns": [
                    {
                        ...generatDefaultSubcolumns("otbarrivals", "historic_sdly"),
                        "order": 1,
                        "access_key": "arrival_otb_sdly"

                    },
                    {
                        ...generatDefaultSubcolumns("otbstayover", "historic_sdly"),
                        "order": 2,
                        "access_key": "stayover_otb_sdly"

                    },
                ],

            },
            {
                "id": "weekly_diff",
                "type": "dataColumn",
                "mainKey": "primary",
                "tileName": "flights",
                "display": "Weekly Difference",
                "backgroundColor": "grey",
                "className": "col-main",
                "draggable": true,
                "hidden": false,
                "colspan": 6,
                "order": 2,
                "subcolumns": [
                    {
                        ...generatDefaultSubcolumns("otbarrivals", "weekly_diff"),
                        "order": 1,
                        "access_key": "arrival_weekly_difference"

                    },
                    {
                        ...generatDefaultSubcolumns("otbstayover", "weekly_diff"),
                        "order": 2,
                        "access_key": "stayover_weekly_difference"

                    },
                ],

            },
            {
                "id": "monthly_diff",
                "type": "dataColumn",
                "mainKey": "primary",
                "tileName": "flights",
                "display": "Monthly Difference",
                "backgroundColor": "grey",
                "className": "col-main",
                "draggable": true,
                "hidden": false,
                "colspan": 6,
                "order": 2,
                "subcolumns": [
                    {
                        ...generatDefaultSubcolumns("otbarrivals", "monthly_diff"),
                        "order": 1,
                        "access_key": "arrival_monthly_difference"

                    },
                    {
                        ...generatDefaultSubcolumns("otbstayover", "monthly_diff"),
                        "order": 2,
                        "access_key": "stayover_monthly_difference"

                    },
                ],

            },
            {
                "id": "historic_annual",
                "type": "dataColumn",
                "mainKey": "primary",
                "tileName": "flights",
                "display": "Historic Annual",
                "backgroundColor": "grey",
                "className": "col-main",
                "draggable": true,
                "hidden": false,
                "colspan": 6,
                "order": 2,
                "subcolumns": [
                    {
                        ...generatDefaultSubcolumns("otbarrivals", "historic_annual"),
                        "order": 1,
                        "access_key": "arrival_annual_difference"

                    },
                    {
                        ...generatDefaultSubcolumns("otbstayover", "historic_annual"),
                        "order": 2,
                        "access_key": "stayover_annual_difference"

                    },
                ],

            },
        ]


    },

    //////// For Reports Section ///////

 /** BI Sensitivity  */ 
 bi_sensitivity_analysis: {
    columns: [
        {
            "id": "staydates", // likewise: otb_sdly, otb_prior,...
            "type": "dateColumn",
            "display": "{rangeyear}",
            "backgroundColor": "grey",
            "className": "otb data-column otb-current",
            "draggable": false,
            "colspan": 2, //This should be <subcolumns.length>
            "order": 1,
            "subcolumns": [
                {
                    ...generatDefaultSubcolumns("dba", "staydates"),
                    // "draggable": true,
                    "sorted": null,
                    "filterable": false, "filterMenu": false,
                    "order": 1
                },
            ],
           
        },
        // data columns

    ],
    dataDbaColumns: [],
},
/** end pickup by segment  */

    /** Pickup by segment  */
    bi_pickup_analysis: {
        columns: [
            {
                "id": "staydates", // likewise: otb_sdly, otb_prior,...
                "type": "dateColumn",
                "display": "{rangeyear}",
                "backgroundColor": "grey",
                "className": "otb data-column otb-current",
                "draggable": false,
                "colspan": 2, //This should be <subcolumns.length>
                "order": 1,
                "subcolumns": [
                    {
                        ...generatDefaultSubcolumns("date", "staydates"),
                        // "draggable": true,
                        "sorted": 'asc',
                        "filterable": false, "filterMenu": false,
                        "order": 1
                    },
                    {
                        ...generatDefaultSubcolumns("dow", "staydates"),
                        "draggable": false,
                        "filterable": false, "filterMenu": false,
                        "order": 2
                    },

                ],
                "subcolumns_segmented": [
                    {
                        ...generatDefaultSubcolumns("segment", "staydates"),
                        "draggable": false,
                        "filterable": false,
                        // "hidden": true,
                        "order": 3
                    }
                ],
            },
            // data columns

        ],
        dataDateColumns: [
            {
                "id": "variance_pickup",
                "type": "dataColumn",
                "mainKey": "variance_pickup",
                "tileName": "Report",
                "display": "Variance in Pickup",
                "backgroundColor": "grey",
                "className": "col-main",
                "draggable": true,
                "hidden": false,
                "colspan": 6,
                "order": 2,
                "subcolumns": [
                    {
                        ...generatDefaultSubcolumns("Rooms", "variance_pickup"),
                        "order": 2,
                        // "hidden": false,
                        // "sortable": false,
                        // "sorted": "asc"

                    },
                    {
                        ...generatDefaultSubcolumns("Occ", "variance_pickup"),
                        "order": 1,
                    },
                    {
                        ...generatDefaultSubcolumns("ADR", "variance_pickup"),
                        "order": 3
                    },
                    {
                        ...generatDefaultSubcolumns("RevPAR", "variance_pickup"),
                        "order": 4
                    },
                    {
                        ...generatDefaultSubcolumns("Revenue", "variance_pickup"),
                        "order": 5
                    },
                    {
                        ...generatDefaultSubcolumns("SellRate", "variance_pickup"),
                        // "hidden": true,
                        "order": 6
                    }
                ],

            },
            
            {
                "id": "otb_primary_report",
                "type": "dataColumn",
                "mainKey": "primary",
                "tileName": "Report",
                "display": "As Of - {asOfDate}",
                "backgroundColor": "grey",
                "className": "col-main",
                "draggable": true,
                "hidden": false,
                "colspan": 6,
                "order": 2,
                "subcolumns": [
                    {
                        ...generatDefaultSubcolumns("Rooms", "otb_primary_report"),
                        "order": 2,
                        // "hidden": false,
                        // "sortable": false,
                        // "sorted": "asc"

                    },
                    {
                        ...generatDefaultSubcolumns("Occ", "otb_primary_report"),
                        "order": 1,
                    },
                    {
                        ...generatDefaultSubcolumns("ADR", "otb_primary_report"),
                        "order": 3
                    },
                    {
                        ...generatDefaultSubcolumns("RevPAR", "otb_primary_report"),
                        "order": 4
                    },
                    {
                        ...generatDefaultSubcolumns("Revenue", "otb_primary_report"),
                        "order": 5
                    },
                    {
                        ...generatDefaultSubcolumns("SellRate", "otb_primary_report"),
                        // "hidden": true,
                        "order": 6
                    }
                ],

            },
            {
                "id": "otb_pickup_report",
                "type": "dataColumn",
                "mainKey": "pickup",
                "tileName": "Report",
                "display": "As Of - {subsPickupDays}",
                "backgroundColor": "grey",
                "className": "col-main",
                "draggable": true,
                "hidden": false,
                "colspan": 6,
                "order": 2,
                "subcolumns": [
                    {
                        ...generatDefaultSubcolumns("Rooms", "otb_pickup_report"),
                        "order": 2,
                        // "hidden": false,
                        // "sortable": false,
                        // "sorted": "asc"

                    },
                    {
                        ...generatDefaultSubcolumns("Occ", "otb_pickup_report"),
                        "order": 1,
                    },
                    {
                        ...generatDefaultSubcolumns("ADR", "otb_pickup_report"),
                        "order": 3
                    },
                    {
                        ...generatDefaultSubcolumns("RevPAR", "otb_pickup_report"),
                        "order": 4
                    },
                    {
                        ...generatDefaultSubcolumns("Revenue", "otb_pickup_report"),
                        "order": 5
                    },
                    {
                        ...generatDefaultSubcolumns("SellRate", "otb_pickup_report"),
                        // "hidden": true,
                        "order": 6
                    }
                ],

            },
            {
                "id": "otb_sdly",
                "type": "dataColumn",
                "mainKey": "sdly",
                "tileName": "otb",
                "display": "As Of - {otbSdlyDate}",
                "backgroundColor": "grey",
                "className": "col-main",
                "draggable": true,
                "hidden": false,
                "colspan": 6,
                "order": 3,
                "subcolumns": [
                    {
                        ...generatDefaultSubcolumns("Rooms", "otb_sdly"),
                        "order": 2
                    },
                    {
                        ...generatDefaultSubcolumns("Occ", "otb_sdly"),
                        "order": 1,
                    },
                    {
                        ...generatDefaultSubcolumns("ADR", "otb_sdly"),
                        "order": 3
                    },
                    {
                        ...generatDefaultSubcolumns("RevPAR", "otb_sdly"),
                        "order": 4
                    },
                    {
                        ...generatDefaultSubcolumns("Revenue", "otb_sdly"),
                        "order": 5
                    },
                    {
                        ...generatDefaultSubcolumns("SellRate", "otb_sdly"),
                        // "hidden": true,
                        "order": 6
                    }
                ],

            },

            // {
            //     "id": "otb_diff_sdly", // likewise: otb_sdly, otb_prior,...
            //     "type": "dataColumn",
            //     "mainKey": "difference_sdly",
            //     "tileName": "otb",
            //     "display": "DIFFERENCE TO SDLY",
            //     "backgroundColor": "grey",
            //     "className": "col-main",
            //     "draggable": true,
            //     "hidden": false,
            //     "colspan": 6,
            //     "order": 4,
            //     "subcolumns": [
            //         {
            //             ...generatDefaultSubcolumns("Rooms", "otb_diff_sdly"),
            //             "order": 2
            //         },
            //         {
            //             ...generatDefaultSubcolumns("Occ", "otb_diff_sdly"),
            //             "order": 1,
            //         },
            //         {
            //             ...generatDefaultSubcolumns("ADR", "otb_diff_sdly"),
            //             "order": 3
            //         },
            //         {
            //             ...generatDefaultSubcolumns("RevPAR", "otb_diff_sdly"),
            //             "order": 4
            //         },
            //         {
            //             ...generatDefaultSubcolumns("Revenue", "otb_diff_sdly"),
            //             "order": 5
            //         },
            //         {
            //             ...generatDefaultSubcolumns("SellRate", "otb_diff_sdly"),
            //             // "hidden": true,
            //             "order": 6
            //         }
            //     ],
            // },
            // {
            //     "id": "otb_end_sdly", // likewise: otb_sdly, otb_prior,...
            //     "type": "dataColumn",
            //     "mainKey": "sdly_actual",
            //     "tileName": "dpbs-report",
            //     "display": "End SDLY - {otbSdlyDate}",
            //     "backgroundColor": "grey",
            //     "className": "col-main",
            //     "draggable": true,
            //     "hidden": false,
            //     "colspan": 6,
            //     "order": 5,
            //     "subcolumns": [
            //         {
            //             ...generatDefaultSubcolumns("Rooms", "otb_end_sdly"),
            //             "order": 2
            //         },
            //         {
            //             ...generatDefaultSubcolumns("Occ", "otb_end_sdly"),
            //             "order": 1,
            //         },
            //         {
            //             ...generatDefaultSubcolumns("ADR", "otb_end_sdly"),
            //             "order": 3
            //         },
            //         {
            //             ...generatDefaultSubcolumns("RevPAR", "otb_end_sdly"),
            //             "order": 4
            //         },
            //         {
            //             ...generatDefaultSubcolumns("Revenue", "otb_end_sdly"),
            //             "order": 5
            //         },
            //         {
            //             ...generatDefaultSubcolumns("SellRate", "otb_end_sdly"),
            //             "hidden": true,
            //             "order": 6
            //         }
            //     ],
            // },
            // {
            //     "id": "otb_sdly_pickup_var", // likewise: otb_sdly, otb_prior,...
            //     "type": "dataColumn",
            //     "mainKey": "sdly_pickup_diff",
            //     "tileName": "dpbs-report",
            //     "display": "SDLY Pickup Difference - {otbSdlyDate}",
            //     "backgroundColor": "grey",
            //     "className": "col-main",
            //     "draggable": true,
            //     "hidden": false,
            //     "colspan": 6,
            //     "order": 6,
            //     "subcolumns": [
            //         {
            //             ...generatDefaultSubcolumns("Rooms", "otb_sdly_pickup_var"),
            //             "order": 2
            //         },
            //         {
            //             ...generatDefaultSubcolumns("Occ", "otb_sdly_pickup_var"),
            //             "order": 1,
            //         },
            //         {
            //             ...generatDefaultSubcolumns("ADR", "otb_sdly_pickup_var"),
            //             "order": 3
            //         },
            //         {
            //             ...generatDefaultSubcolumns("RevPAR", "otb_sdly_pickup_var"),
            //             "order": 4
            //         },
            //         {
            //             ...generatDefaultSubcolumns("Revenue", "otb_sdly_pickup_var"),
            //             "order": 5
            //         },
            //         {
            //             ...generatDefaultSubcolumns("SellRate", "otb_sdly_pickup_var"),
            //             "order": 6
            //         }
            //     ],
            // },

            // // /// Market columns ///
            {
                "id": "market_report",
                "type": "dataColumn",
                "mainKey": "marketprimary",
                "tileName": "Report",
                "display": "Market",
                "backgroundColor": "grey",
                "className": "col-main",
                "draggable": true,
                "hidden": false,
                "colspan": 6,
                "order": 7,
                "subcolumns": [
                    {
                        ...generatDefaultSubcolumns("Rooms", "market_report"),
                        "order": 2,
                    },
                    {
                        ...generatDefaultSubcolumns("Occ", "market_report"),
                        "order": 1,
                    },
                    {
                        ...generatDefaultSubcolumns("ADR", "market_report"),
                        "order": 3
                    },
                    {
                        ...generatDefaultSubcolumns("RevPAR", "market_report"),
                        "order": 4
                    },
                    {
                        ...generatDefaultSubcolumns("Revenue", "market_report"),
                        "order": 5
                    },
                    {
                        ...generatDefaultSubcolumns("SellRate", "market_report"),
                        "order": 6
                    }
                ],

            },
            {
                "id": "market_sdly_report",
                "type": "dataColumn",
                "mainKey": "marketsdly",
                "tileName": "Report",
                "display": "Market SDLY",
                "backgroundColor": "grey",
                "className": "col-main",
                "draggable": true,
                "hidden": false,
                "colspan": 6,
                "order": 8,
                "subcolumns": [
                    {
                        ...generatDefaultSubcolumns("Rooms", "market_sdly_report"),
                        "order": 2,
                    },
                    {
                        ...generatDefaultSubcolumns("Occ", "market_sdly_report"),
                        "order": 1,
                    },
                    {
                        ...generatDefaultSubcolumns("ADR", "market_sdly_report"),
                        "order": 3
                    },
                    {
                        ...generatDefaultSubcolumns("RevPAR", "market_sdly_report"),
                        "order": 4
                    },
                    {
                        ...generatDefaultSubcolumns("Revenue", "market_sdly_report"),
                        "order": 5
                    },
                    {
                        ...generatDefaultSubcolumns("SellRate", "market_sdly_report"),
                        // "hidden": true,
                        "order": 6
                    }
                ],

            },
            {
                "id": "market_sdly_diff_report",
                "type": "dataColumn",
                "mainKey": "market_sdly_diff",
                "tileName": "Report",
                "display": "Difference to Market SDLY",
                "backgroundColor": "grey",
                "className": "col-main",
                "draggable": true,
                "hidden": false,
                "colspan": 6,
                "order": 9,
                "subcolumns": [
                    {
                        ...generatDefaultSubcolumns("Rooms", "market_sdly_diff_report"),
                        "order": 2,
                    },
                    {
                        ...generatDefaultSubcolumns("Occ", "market_sdly_diff_report"),
                        "order": 1,
                    },
                    {
                        ...generatDefaultSubcolumns("ADR", "market_sdly_diff_report"),
                        "order": 3
                    },
                    {
                        ...generatDefaultSubcolumns("RevPAR", "market_sdly_diff_report"),
                        "order": 4
                    },
                    {
                        ...generatDefaultSubcolumns("Revenue", "market_sdly_diff_report"),
                        "order": 5
                    },
                    {
                        ...generatDefaultSubcolumns("SellRate", "market_sdly_diff_report"),
                        // "hidden": true,
                        "order": 6
                    }
                ],

            },

        ],
    },
    /** end pickup by segment  */
    
    bi_pace_comparison: {
        columns: [
            {
                "id": "staydates", // likewise: otb_sdly, otb_prior,...
                "type": "dateColumn",
                "display": "{rangeyear}",
                "backgroundColor": "grey",
                "className": "otb data-column otb-current",
                "draggable": false,
                "colspan": 2, //This should be <subcolumns.length>
                "order": 1,
                "subcolumns": [
                    {
                        ...generatDefaultSubcolumns("date", "staydates"),
                        // "draggable": true,
                        "sorted": 'asc',
                        "filterable": false, "filterMenu": false,
                        "order": 1
                    },
                    {
                        ...generatDefaultSubcolumns("dow", "staydates"),
                        "draggable": false,
                        "filterable": false, "filterMenu": false,
                        "order": 2
                    },

                ],
                "subcolumns_segmented": [
                    {
                        ...generatDefaultSubcolumns("segment", "staydates"),
                        "draggable": false,
                        "filterable": false,
                        // "hidden": true,
                        "order": 3
                    }
                ],
            },
            // data columns

        ],
        dataDateColumns: [
            {
                "id": "otb_primary_report",
                "type": "dataColumn",
                "mainKey": "primary",
                "tileName": "Report",
                "display": "As Of - {asOfDate}",
                "backgroundColor": "grey",
                "className": "col-main",
                "draggable": true,
                "hidden": false,
                "colspan": 6,
                "order": 2,
                "subcolumns": [
                    {
                        ...generatDefaultSubcolumns("Rooms", "otb_primary_report"),
                        "order": 2,
                        // "hidden": false,
                        // "sortable": false,
                        // "sorted": "asc"

                    },
                    {
                        ...generatDefaultSubcolumns("Occ", "otb_primary_report"),
                        "order": 1,
                    },
                    {
                        ...generatDefaultSubcolumns("ADR", "otb_primary_report"),
                        "order": 3
                    },
                    {
                        ...generatDefaultSubcolumns("RevPAR", "otb_primary_report"),
                        "order": 4
                    },
                    {
                        ...generatDefaultSubcolumns("Revenue", "otb_primary_report"),
                        "order": 5
                    },
                    {
                        ...generatDefaultSubcolumns("SellRate", "otb_primary_report"),
                        // "hidden": true,
                        "order": 6
                    }
                ],

            },
            {
                "id": "otb_sdly",
                "type": "dataColumn",
                "mainKey": "sdly",
                "tileName": "otb",
                "display": "As Of - {otbSdlyDate}",
                "backgroundColor": "grey",
                "className": "col-main",
                "draggable": true,
                "hidden": false,
                "colspan": 6,
                "order": 3,
                "subcolumns": [
                    {
                        ...generatDefaultSubcolumns("Rooms", "otb_sdly"),
                        "order": 2
                    },
                    {
                        ...generatDefaultSubcolumns("Occ", "otb_sdly"),
                        "order": 1,
                    },
                    {
                        ...generatDefaultSubcolumns("ADR", "otb_sdly"),
                        "order": 3
                    },
                    {
                        ...generatDefaultSubcolumns("RevPAR", "otb_sdly"),
                        "order": 4
                    },
                    {
                        ...generatDefaultSubcolumns("Revenue", "otb_sdly"),
                        "order": 5
                    },
                    {
                        ...generatDefaultSubcolumns("SellRate", "otb_sdly"),
                        // "hidden": true,
                        "order": 6
                    }
                ],

            },
            {
                "id": "otb_diff_sdly", // likewise: otb_sdly, otb_prior,...
                "type": "dataColumn",
                "mainKey": "difference_sdly",
                "tileName": "otb",
                "display": "DIFFERENCE TO SDLY",
                "backgroundColor": "grey",
                "className": "col-main",
                "draggable": true,
                "hidden": false,
                "colspan": 6,
                "order": 4,
                "subcolumns": [
                    {
                        ...generatDefaultSubcolumns("Rooms", "otb_diff_sdly"),
                        "order": 2
                    },
                    {
                        ...generatDefaultSubcolumns("Occ", "otb_diff_sdly"),
                        "order": 1,
                    },
                    {
                        ...generatDefaultSubcolumns("ADR", "otb_diff_sdly"),
                        "order": 3
                    },
                    {
                        ...generatDefaultSubcolumns("RevPAR", "otb_diff_sdly"),
                        "order": 4
                    },
                    {
                        ...generatDefaultSubcolumns("Revenue", "otb_diff_sdly"),
                        "order": 5
                    },
                    {
                        ...generatDefaultSubcolumns("SellRate", "otb_diff_sdly"),
                        // "hidden": true,
                        "order": 6
                    }
                ],
            },
            {
                "id": "otb_end_sdly", // likewise: otb_sdly, otb_prior,...
                "type": "dataColumn",
                "mainKey": "sdly_actual",
                "tileName": "dpbs-report",
                "display": "End SDLY - {otbSdlyDate}",
                "backgroundColor": "grey",
                "className": "col-main",
                "draggable": true,
                "hidden": false,
                "colspan": 6,
                "order": 5,
                "subcolumns": [
                    {
                        ...generatDefaultSubcolumns("Rooms", "otb_end_sdly"),
                        "order": 2
                    },
                    {
                        ...generatDefaultSubcolumns("Occ", "otb_end_sdly"),
                        "order": 1,
                    },
                    {
                        ...generatDefaultSubcolumns("ADR", "otb_end_sdly"),
                        "order": 3
                    },
                    {
                        ...generatDefaultSubcolumns("RevPAR", "otb_end_sdly"),
                        "order": 4
                    },
                    {
                        ...generatDefaultSubcolumns("Revenue", "otb_end_sdly"),
                        "order": 5
                    },
                    {
                        ...generatDefaultSubcolumns("SellRate", "otb_end_sdly"),
                        "hidden": true,
                        "order": 6
                    }
                ],
            },
            {
                "id": "otb_sdly_pickup_var", // likewise: otb_sdly, otb_prior,...
                "type": "dataColumn",
                "mainKey": "sdly_pickup_diff",
                "tileName": "dpbs-report",
                "display": "SDLY Pickup Difference - {otbSdlyDate}",
                "backgroundColor": "grey",
                "className": "col-main",
                "draggable": true,
                "hidden": false,
                "colspan": 6,
                "order": 6,
                "subcolumns": [
                    {
                        ...generatDefaultSubcolumns("Rooms", "otb_sdly_pickup_var"),
                        "order": 2
                    },
                    {
                        ...generatDefaultSubcolumns("Occ", "otb_sdly_pickup_var"),
                        "order": 1,
                    },
                    {
                        ...generatDefaultSubcolumns("ADR", "otb_sdly_pickup_var"),
                        "order": 3
                    },
                    {
                        ...generatDefaultSubcolumns("RevPAR", "otb_sdly_pickup_var"),
                        "order": 4
                    },
                    {
                        ...generatDefaultSubcolumns("Revenue", "otb_sdly_pickup_var"),
                        "order": 5
                    },
                    {
                        ...generatDefaultSubcolumns("SellRate", "otb_sdly_pickup_var"),
                        "order": 6
                    }
                ],
            },

            // // /// Market columns ///
            {
                "id": "market_report",
                "type": "dataColumn",
                "mainKey": "marketprimary",
                "tileName": "Report",
                "display": "Market",
                "backgroundColor": "grey",
                "className": "col-main",
                "draggable": true,
                "hidden": false,
                "colspan": 6,
                "order": 7,
                "subcolumns": [
                    {
                        ...generatDefaultSubcolumns("Rooms", "market_report"),
                        "order": 2,
                    },
                    {
                        ...generatDefaultSubcolumns("Occ", "market_report"),
                        "order": 1,
                    },
                    {
                        ...generatDefaultSubcolumns("ADR", "market_report"),
                        "order": 3
                    },
                    {
                        ...generatDefaultSubcolumns("RevPAR", "market_report"),
                        "order": 4
                    },
                    {
                        ...generatDefaultSubcolumns("Revenue", "market_report"),
                        "order": 5
                    },
                    {
                        ...generatDefaultSubcolumns("SellRate", "market_report"),
                        "order": 6
                    }
                ],

            },
            {
                "id": "market_sdly_report",
                "type": "dataColumn",
                "mainKey": "marketsdly",
                "tileName": "Report",
                "display": "Market SDLY",
                "backgroundColor": "grey",
                "className": "col-main",
                "draggable": true,
                "hidden": false,
                "colspan": 6,
                "order": 8,
                "subcolumns": [
                    {
                        ...generatDefaultSubcolumns("Rooms", "market_sdly_report"),
                        "order": 2,
                    },
                    {
                        ...generatDefaultSubcolumns("Occ", "market_sdly_report"),
                        "order": 1,
                    },
                    {
                        ...generatDefaultSubcolumns("ADR", "market_sdly_report"),
                        "order": 3
                    },
                    {
                        ...generatDefaultSubcolumns("RevPAR", "market_sdly_report"),
                        "order": 4
                    },
                    {
                        ...generatDefaultSubcolumns("Revenue", "market_sdly_report"),
                        "order": 5
                    },
                    {
                        ...generatDefaultSubcolumns("SellRate", "market_sdly_report"),
                        // "hidden": true,
                        "order": 6
                    }
                ],

            },
            {
                "id": "market_sdly_diff_report",
                "type": "dataColumn",
                "mainKey": "market_sdly_diff",
                "tileName": "Report",
                "display": "Difference to Market SDLY",
                "backgroundColor": "grey",
                "className": "col-main",
                "draggable": true,
                "hidden": false,
                "colspan": 6,
                "order": 9,
                "subcolumns": [
                    {
                        ...generatDefaultSubcolumns("Rooms", "market_sdly_diff_report"),
                        "order": 2,
                    },
                    {
                        ...generatDefaultSubcolumns("Occ", "market_sdly_diff_report"),
                        "order": 1,
                    },
                    {
                        ...generatDefaultSubcolumns("ADR", "market_sdly_diff_report"),
                        "order": 3
                    },
                    {
                        ...generatDefaultSubcolumns("RevPAR", "market_sdly_diff_report"),
                        "order": 4
                    },
                    {
                        ...generatDefaultSubcolumns("Revenue", "market_sdly_diff_report"),
                        "order": 5
                    },
                    {
                        ...generatDefaultSubcolumns("SellRate", "market_sdly_diff_report"),
                        // "hidden": true,
                        "order": 6
                    }
                ],

            },

        ],
    },

    // Market Code Report
    productionMarketReport: {
        columns: [
            {
                "id": "staydates", // likewise: otb_sdly, otb_prior,...
                "type": "dateColumn",
                "display": "{rangeyear}",
                "backgroundColor": "grey",
                "className": "otb data-column otb-current",
                "draggable": false,
                "colspan": 2, //This should be <subcolumns.length>
                "order": 1,
                "subcolumns": [
                    {
                        ...generatDefaultSubcolumns("date", "staydates"),
                        // "draggable": true,
                        "sorted": null,
                        "filterable": false, "filterMenu": false,
                        "order": 1
                    },
                    {
                        ...generatDefaultSubcolumns("dow", "staydates"),
                        "draggable": false,
                        "filterable": false, "filterMenu": false,
                        "order": 2
                    },

                ],
                "subcolumns_segmented": [
                    {
                        ...generatDefaultSubcolumns("segment", "staydates"),
                        "draggable": false,
                        "filterable": false,
                        // "hidden": true,
                        "order": 3
                    }
                ],
            },
            // data columns

        ],
        dataDateColumns: [
            {
                "id": "otb_primary_report",
                "type": "dataColumn",
                "mainKey": "primary",
                "tileName": "Report",
                "display": "As Of - {asOfDate}",
                "backgroundColor": "grey",
                "className": "col-main",
                "draggable": true,
                "hidden": false,
                "colspan": 6,
                "order": 2,
                "subcolumns": [
                    {
                        ...generatDefaultSubcolumns("Rooms", "otb_primary_report"),
                        "order": 2,
                        // "hidden": false,
                        // "sortable": false,
                        // "sorted": "asc"

                    },
                    {
                        ...generatDefaultSubcolumns("Occ", "otb_primary_report"),
                        "order": 1,
                    },
                    {
                        ...generatDefaultSubcolumns("ADR", "otb_primary_report"),
                        "order": 3
                    },
                    {
                        ...generatDefaultSubcolumns("RevPAR", "otb_primary_report"),
                        "order": 4
                    },
                    {
                        ...generatDefaultSubcolumns("Revenue", "otb_primary_report"),
                        "order": 5
                    },
                    {
                        ...generatDefaultSubcolumns("SellRate", "otb_primary_report"),
                        "hidden": true,
                        "order": 6
                    }
                ],

            },
            {
                "id": "otb_sdly",
                "type": "dataColumn",
                "mainKey": "sdly",
                "tileName": "otb",
                "display": "As Of - {otbSdlyDate}",
                "backgroundColor": "grey",
                "className": "col-main",
                "draggable": true,
                "hidden": false,
                "colspan": 6,
                "order": 3,
                "subcolumns": [
                    {
                        ...generatDefaultSubcolumns("Rooms", "otb_sdly"),
                        "order": 2
                    },
                    {
                        ...generatDefaultSubcolumns("Occ", "otb_sdly"),
                        "order": 1,
                    },
                    {
                        ...generatDefaultSubcolumns("ADR", "otb_sdly"),
                        "order": 3
                    },
                    {
                        ...generatDefaultSubcolumns("RevPAR", "otb_sdly"),
                        "order": 4
                    },
                    {
                        ...generatDefaultSubcolumns("Revenue", "otb_sdly"),
                        "order": 5
                    },
                    {
                        ...generatDefaultSubcolumns("SellRate", "otb_sdly"),
                        "hidden": true,
                        "order": 6
                    }
                ],

            },
            {
                "id": "otb_diff_sdly", // likewise: otb_sdly, otb_prior,...
                "type": "dataColumn",
                "mainKey": "difference_sdly",
                "tileName": "otb",
                "display": "DIFFERENCE TO SDLY",
                "backgroundColor": "grey",
                "className": "col-main",
                "draggable": true,
                "hidden": false,
                "colspan": 6,
                "order": 4,
                "subcolumns": [
                    {
                        ...generatDefaultSubcolumns("Rooms", "otb_diff_sdly"),
                        "order": 2
                    },
                    {
                        ...generatDefaultSubcolumns("Occ", "otb_diff_sdly"),
                        "order": 1,
                    },
                    {
                        ...generatDefaultSubcolumns("ADR", "otb_diff_sdly"),
                        "order": 3
                    },
                    {
                        ...generatDefaultSubcolumns("RevPAR", "otb_diff_sdly"),
                        "order": 4
                    },
                    {
                        ...generatDefaultSubcolumns("Revenue", "otb_diff_sdly"),
                        "order": 5
                    },
                    {
                        ...generatDefaultSubcolumns("SellRate", "otb_diff_sdly"),
                        "hidden": true,
                        "order": 6
                    }
                ],
            },
            {
                "id": "otb_end_sdly", // likewise: otb_sdly, otb_prior,...
                "type": "dataColumn",
                "mainKey": "sdly_actual",
                "tileName": "dpbs-report",
                "display": "SDLY Actual",
                "backgroundColor": "grey",
                "className": "col-main",
                "draggable": true,
                "hidden": false,
                "colspan": 6,
                "order": 5,
                "subcolumns": [
                    {
                        ...generatDefaultSubcolumns("Rooms", "otb_end_sdly"),
                        "order": 2
                    },
                    {
                        ...generatDefaultSubcolumns("Occ", "otb_end_sdly"),
                        "order": 1,
                    },
                    {
                        ...generatDefaultSubcolumns("ADR", "otb_end_sdly"),
                        "order": 3
                    },
                    {
                        ...generatDefaultSubcolumns("RevPAR", "otb_end_sdly"),
                        "order": 4
                    },
                    {
                        ...generatDefaultSubcolumns("Revenue", "otb_end_sdly"),
                        "order": 5
                    },
                    {
                        ...generatDefaultSubcolumns("SellRate", "otb_end_sdly"),
                        "hidden": true,
                        "order": 6
                    }
                ],
            },


        ],
    },


    // Segment Production 
    productionReport: {
        columns: [
            {
                "id": "staydates", // likewise: otb_sdly, otb_prior,...
                "type": "dateColumn",
                "display": "{rangeyear}",
                "backgroundColor": "grey",
                "className": "otb data-column otb-current",
                "draggable": false,
                "colspan": 2, //This should be <subcolumns.length>
                "order": 1,
                "subcolumns": [
                    {
                        ...generatDefaultSubcolumns("date", "staydates"),
                        // "draggable": true,
                        "sorted": null,
                        "filterable": false, "filterMenu": false,
                        "order": 1
                    },
                    {
                        ...generatDefaultSubcolumns("dow", "staydates"),
                        "draggable": false,
                        "filterable": false, "filterMenu": false,
                        "order": 2
                    },

                ],
                "subcolumns_segmented": [
                    {
                        ...generatDefaultSubcolumns("segment", "staydates"),
                        "draggable": false,
                        "filterable": false,
                        // "hidden": true,
                        "order": 3
                    }
                ],
            },
            // data columns

        ],
        dataDateColumns: [
            {
                "id": "otb_primary_report",
                "type": "dataColumn",
                "mainKey": "primary",
                "tileName": "Report",
                "display": "As Of - {asOfDate}",
                "backgroundColor": "grey",
                "className": "col-main",
                "draggable": true,
                "hidden": false,
                "colspan": 6,
                "order": 2,
                "subcolumns": [
                    {
                        ...generatDefaultSubcolumns("Rooms", "otb_primary_report"),
                        "order": 2,
                        // "hidden": false,
                        // "sortable": false,
                        // "sorted": "asc"

                    },
                    {
                        ...generatDefaultSubcolumns("Occ", "otb_primary_report"),
                        "order": 1,
                    },
                    {
                        ...generatDefaultSubcolumns("ADR", "otb_primary_report"),
                        "order": 3
                    },
                    {
                        ...generatDefaultSubcolumns("RevPAR", "otb_primary_report"),
                        "order": 4
                    },
                    {
                        ...generatDefaultSubcolumns("Revenue", "otb_primary_report"),
                        "order": 5
                    },
                    {
                        ...generatDefaultSubcolumns("SellRate", "otb_primary_report"),
                        "hidden": true,
                        "order": 6
                    }
                ],

            },
            {
                "id": "otb_sdly",
                "type": "dataColumn",
                "mainKey": "sdly",
                "tileName": "otb",
                "display": "As Of - {otbSdlyDate}",
                "backgroundColor": "grey",
                "className": "col-main",
                "draggable": true,
                "hidden": false,
                "colspan": 6,
                "order": 3,
                "subcolumns": [
                    {
                        ...generatDefaultSubcolumns("Rooms", "otb_sdly"),
                        "order": 2
                    },
                    {
                        ...generatDefaultSubcolumns("Occ", "otb_sdly"),
                        "order": 1,
                    },
                    {
                        ...generatDefaultSubcolumns("ADR", "otb_sdly"),
                        "order": 3
                    },
                    {
                        ...generatDefaultSubcolumns("RevPAR", "otb_sdly"),
                        "order": 4
                    },
                    {
                        ...generatDefaultSubcolumns("Revenue", "otb_sdly"),
                        "order": 5
                    },
                    {
                        ...generatDefaultSubcolumns("SellRate", "otb_sdly"),
                        "hidden": true,
                        "order": 6
                    }
                ],

            },
            {
                "id": "otb_diff_sdly", // likewise: otb_sdly, otb_prior,...
                "type": "dataColumn",
                "mainKey": "difference_sdly",
                "tileName": "otb",
                "display": "DIFFERENCE TO SDLY",
                "backgroundColor": "grey",
                "className": "col-main",
                "draggable": true,
                "hidden": false,
                "colspan": 6,
                "order": 4,
                "subcolumns": [
                    {
                        ...generatDefaultSubcolumns("Rooms", "otb_diff_sdly"),
                        "order": 2
                    },
                    {
                        ...generatDefaultSubcolumns("Occ", "otb_diff_sdly"),
                        "order": 1,
                    },
                    {
                        ...generatDefaultSubcolumns("ADR", "otb_diff_sdly"),
                        "order": 3
                    },
                    {
                        ...generatDefaultSubcolumns("RevPAR", "otb_diff_sdly"),
                        "order": 4
                    },
                    {
                        ...generatDefaultSubcolumns("Revenue", "otb_diff_sdly"),
                        "order": 5
                    },
                    {
                        ...generatDefaultSubcolumns("SellRate", "otb_diff_sdly"),
                        "hidden": true,
                        "order": 6
                    }
                ],
            },
            {
                "id": "otb_end_sdly", // likewise: otb_sdly, otb_prior,...
                "type": "dataColumn",
                "mainKey": "sdly_actual",
                "tileName": "dpbs-report",
                "display": "SDLY Actual",
                "backgroundColor": "grey",
                "className": "col-main",
                "draggable": true,
                "hidden": false,
                "colspan": 6,
                "order": 5,
                "subcolumns": [
                    {
                        ...generatDefaultSubcolumns("Rooms", "otb_end_sdly"),
                        "order": 2
                    },
                    {
                        ...generatDefaultSubcolumns("Occ", "otb_end_sdly"),
                        "order": 1,
                    },
                    {
                        ...generatDefaultSubcolumns("ADR", "otb_end_sdly"),
                        "order": 3
                    },
                    {
                        ...generatDefaultSubcolumns("RevPAR", "otb_end_sdly"),
                        "order": 4
                    },
                    {
                        ...generatDefaultSubcolumns("Revenue", "otb_end_sdly"),
                        "order": 5
                    },
                    {
                        ...generatDefaultSubcolumns("SellRate", "otb_end_sdly"),
                        "hidden": true,
                        "order": 6
                    }
                ],
            },


        ],
    },


    // Revpar index 
    revpar_index: {
        columns: [
            {
                "id": "staydates", // likewise: otb_sdly, otb_prior,...
                "type": "dateColumn",
                "display": "{rangeyear}",
                "backgroundColor": "grey",
                "className": "otb data-column otb-current",
                "draggable": false,
                "colspan": 2, //This should be <subcolumns.length>
                "order": 1,
                "subcolumns": [
                    {
                        ...generatDefaultSubcolumns("month", "staydates"),
                        // "draggable": true,
                        "sorted": null,
                        "filterable": false, "filterMenu": false,
                        "order": 1
                    },
                ],
            },

            {
                "id": "inner_columns",
                "type": "dataColumn",
                "mainKey": "primary",
                "tileName": "revpar_index",
                "display": "",
                "backgroundColor": "grey",
                "className": "col-main",
                "draggable": true,
                "hidden": false,
                "colspan": 6,
                "order": 2,
                "subcolumns": [

                ],

            }
            // data columns
        ],
        dailycolumns: [
            {
                "id": "staydates", // likewise: otb_sdly, otb_prior,...
                "type": "dateColumn",
                "display": "{rangeyear}",
                "backgroundColor": "grey",
                "className": "otb data-column otb-current",
                "draggable": false,
                "colspan": 2, //This should be <subcolumns.length>
                "order": 1,
                "subcolumns": [
                    {
                        ...generatDefaultSubcolumns("date", "staydates"),
                        // "draggable": true,
                        "sorted": null,
                        "filterable": false, "filterMenu": false,
                        "order": 1
                    },
                    {
                        ...generatDefaultSubcolumns("dow", "staydates"),
                        "draggable": false,
                        "filterable": false, "filterMenu": false,
                        "order": 2
                    },
                ],
            },

            {
                "id": "inner_columns",
                "type": "dataColumn",
                "mainKey": "primary",
                "tileName": "revpar_index",
                "display": "",
                "backgroundColor": "grey",
                "className": "col-main",
                "draggable": true,
                "hidden": false,
                "colspan": 6,
                "order": 2,
                "subcolumns": [

                ],

            }
            // data columns
        ],

    },


    /** OTB Multi Segment  */
    otb_v2_multi_segment: {
        columns: [
            {
                "id": "staydates", // likewise: otb_sdly, otb_prior,...
                "type": "dateColumn",
                "isSticky": true,
                "display": "{rangeyear}",
                "backgroundColor": "grey",
                "className": "otb data-column otb-current",
                "draggable": false,
                "colspan": 2, //This should be <subcolumns.length>
                "order": 1,

                "subcolumns": [
                    {
                        ...generatDefaultSubcolumns("date", "staydates"),
                        // "draggable": true,
                        "sorted": null,
                        "filterable": false, "filterMenu": false,
                        "order": 1
                    },
                    {
                        ...generatDefaultSubcolumns("dow", "staydates"),
                        "draggable": false,
                        "filterable": false, "filterMenu": false,
                        "order": 2
                    },

                ],
                "subcolumns_segmented": [
                    {
                        ...generatDefaultSubcolumns("segment", "staydates"),
                        "draggable": false,
                        "filterable": false,
                        // "hidden": true,
                        "order": 3
                    }
                ]

            },
            {
                "id": "otb_current",
                "type": "dataColumn",
                "mainKey": "primary",
                "tileName": "otb",
                "display": "as of - {asOfDate}",
                "backgroundColor": "grey",
                "className": "col-main",
                "draggable": true,
                "hidden": false,
                "colspan": 6,
                "order": 2,
                "subcolumns": [
                    {
                        ...generatDefaultSubcolumns("Rooms", "otb_current"),
                        "order": 2,
                        // "hidden": false,
                        // "sortable": false,
                        // "sorted": "asc"

                    },
                    {
                        ...generatDefaultSubcolumns("Occ", "otb_current"),
                        "order": 1,
                    },
                    {
                        ...generatDefaultSubcolumns("ADR", "otb_current"),
                        "order": 3
                    },
                    {
                        ...generatDefaultSubcolumns("RevPAR", "otb_current"),
                        "order": 4
                    },
                    {
                        ...generatDefaultSubcolumns("Revenue", "otb_current"),
                        "order": 5
                    },
                    {
                        ...generatDefaultSubcolumns("SellRate", "otb_current"),
                        // "hidden": true,
                        "order": 6
                    }
                ],

            }
        ],
        
        sdlyOn_columns: [
            {
                "id": "otb_sdly",
                "type": "dataColumn",
                "mainKey": "sdly",
                "tileName": "otb",
                "display": "As Of - {otbSdlyDate}",
                "backgroundColor": "grey",
                "className": "col-main",
                "draggable": true,
                "hidden": false,
                "colspan": 6,
                "order": 3,
                "subcolumns": [
                    {
                        ...generatDefaultSubcolumns("Rooms", "otb_sdly"),
                        "order": 2
                    },
                    {
                        ...generatDefaultSubcolumns("Occ", "otb_sdly"),
                        "order": 1,
                    },
                    {
                        ...generatDefaultSubcolumns("ADR", "otb_sdly"),
                        "order": 3
                    },
                    {
                        ...generatDefaultSubcolumns("RevPAR", "otb_sdly"),
                        "order": 4
                    },
                    {
                        ...generatDefaultSubcolumns("Revenue", "otb_sdly"),
                        "order": 5
                    },
                    {
                        ...generatDefaultSubcolumns("SellRate", "otb_sdly"),
                        "hidden": true,
                        "order": 6
                    }
                ],

            },
            {
                "id": "otb_actual_sdly", // likewise: otb_sdly, otb_prior,...
                "type": "dataColumn",
                "mainKey": "sdly_actual",
                "tileName": "otb",
                "display": "Last Year Actuals",
                "backgroundColor": "grey",
                "className": "col-main",
                "draggable": true,
                "hidden": false,
                "colspan": 6,
                "order": 3,
                "subcolumns": [
                    {
                        ...generatDefaultSubcolumns("Rooms", "otb_actual_sdly"),
                        "order": 2
                    },
                    {
                        ...generatDefaultSubcolumns("Occ", "otb_actual_sdly"),
                        "order": 1,
                    },
                    {
                        ...generatDefaultSubcolumns("ADR", "otb_actual_sdly"),
                        "order": 3
                    },
                    {
                        ...generatDefaultSubcolumns("RevPAR", "otb_actual_sdly"),
                        "order": 4
                    },
                    {
                        ...generatDefaultSubcolumns("Revenue", "otb_actual_sdly"),
                        "order": 5
                    },
                    {
                        ...generatDefaultSubcolumns("SellRate", "otb_actual_sdly"),
                        "hidden": true,
                        "order": 6
                    }
                ],
            },
            {
                "id": "otb_diff_sdly", // likewise: otb_sdly, otb_prior,...
                "type": "dataColumn",
                "mainKey": "difference_sdly",
                "tileName": "otb",
                "display": "DIFFERENCE TO SDLY",
                "backgroundColor": "grey",
                "className": "col-main",
                "draggable": true,
                "hidden": false,
                "colspan": 6,
                "order": 4,
                "subcolumns": [
                    {
                        ...generatDefaultSubcolumns("Rooms", "otb_diff_sdly"),
                        "order": 2
                    },
                    {
                        ...generatDefaultSubcolumns("Occ", "otb_diff_sdly"),
                        "order": 1,
                    },
                    {
                        ...generatDefaultSubcolumns("ADR", "otb_diff_sdly"),
                        "order": 3
                    },
                    {
                        ...generatDefaultSubcolumns("RevPAR", "otb_diff_sdly"),
                        "order": 4
                    },
                    {
                        ...generatDefaultSubcolumns("Revenue", "otb_diff_sdly"),
                        "order": 5
                    },
                    {
                        ...generatDefaultSubcolumns("SellRate", "otb_diff_sdly"),
                        "hidden": true,
                        "order": 6
                    }
                ],
            },
            {
                "id": "otb_variance_sdly", // likewise: otb_sdly, otb_prior,...
                "type": "dataColumn",
                "mainKey": "variance_sdly",
                "tileName": "otb",
                "display": "% DIFFERENCE TO SDLY",
                "backgroundColor": "grey",
                "className": "col-main",
                "draggable": true,
                "hidden": false,
                "colspan": 6,
                "order": 5,
                "subcolumns": [
                    {
                        ...generatDefaultSubcolumns("Rooms", "otb_variance_sdly"),
                        "order": 2
                    },
                    {
                        ...generatDefaultSubcolumns("Occ", "otb_variance_sdly"),
                        "order": 1,
                    },
                    {
                        ...generatDefaultSubcolumns("ADR", "otb_variance_sdly"),
                        "order": 3
                    },
                    {
                        ...generatDefaultSubcolumns("RevPAR", "otb_variance_sdly"),
                        "order": 4
                    },
                    {
                        ...generatDefaultSubcolumns("Revenue", "otb_variance_sdly"),
                        "order": 5
                    },
                    {
                        ...generatDefaultSubcolumns("SellRate", "otb_variance_sdly"),
                        "hidden": true,
                        "order": 6
                    }
                ],
            },

            {
                "id": "otb_remaing_pickup_sdly", // likewise: otb_sdly, otb_prior,...
                "type": "dataColumn",
                "mainKey": "pickup_diff_sdly",
                "tileName": "otb",
                "display": "REMAINING PICKUP FOR SDLY",
                "backgroundColor": "grey",
                "className": "col-main",
                "draggable": true,
                "hidden": false,
                "colspan": 6,
                "order": 7,
                "subcolumns": [
                    {
                        ...generatDefaultSubcolumns("Rooms", "otb_remaing_pickup_sdly"),
                        "order": 2
                    },
                    {
                        ...generatDefaultSubcolumns("Occ", "otb_remaing_pickup_sdly"),
                        "order": 1,
                    },
                    {
                        ...generatDefaultSubcolumns("ADR", "otb_remaing_pickup_sdly"),
                        "order": 3
                    },
                    {
                        ...generatDefaultSubcolumns("RevPAR", "otb_remaing_pickup_sdly"),
                        "order": 4
                    },
                    {
                        ...generatDefaultSubcolumns("Revenue", "otb_remaing_pickup_sdly"),
                        "order": 5
                    },
                    {
                        ...generatDefaultSubcolumns("SellRate", "otb_remaing_pickup_sdly"),
                        "hidden": true,
                        "order": 6
                    }
                ],
            },
        ]
    },


  
/** Room rate push Date range  */
  rm_room_type_ratesend_status: {
    columns: [
        {
            "id": "staydates", 
            "type": "dateColumn",
            "display": "{rangeyear}",
            "backgroundColor": "grey",
            "className": "otb data-column otb-current",
            "draggable": false,
            "colspan": 2, //This should be <subcolumns.length>
            "order": 1,
            "subcolumns": [
                {
                    ...generatDefaultSubcolumns("date", "staydates"),
                    // "draggable": true,
                    "sorted": null,
                    "filterable": false, 
                    "sortable": false,
                    "filterMenu": false,
                    "order": 1
                },
                {
                    ...generatDefaultSubcolumns("dow", "staydates"),
                    "draggable": false,
                    "filterable": false, 
                    "filterMenu": false,
                    "sortable": false,

                    "order": 2
                },

            ],
            // "subcolumns_segmented": [
            //     {
            //         ...generatDefaultSubcolumns("segment", "staydates"),
            //         "draggable": false,
            //         "filterable": false,
            //         // "hidden": true,
            //         "order": 3
            //     }
            // ],
        },
        // data columns

    ],
    dataDateColumns: [
        {
            "id": "rate_send_status",
            "type": "dataColumn",
            "mainKey": "primary",
            "tileName": "Report",
            "display": "Tracking",
            "backgroundColor": "grey",
            "className": "col-main",
            "draggable": true,
            "hidden": false,
            "colspan": 6,
            "order": 2,
            "subcolumns": [
                {
                    ...generatDefaultSubcolumns("UpdateTime", "rate_send_status"),
                    "order": 1,
                    "draggable": false,
                    "filterable": false, 
                    "filterMenu": false,
                    "sortable": false,

                },
                {
                    ...generatDefaultSubcolumns("User", "rate_send_status"),
                    "order": 2,
                    "draggable": false,
                    "filterable": false, 
                    "filterMenu": false,
                    "sortable": false,

                },
                {
                    ...generatDefaultSubcolumns("Action", "rate_send_status"),
                    "order": 3,
                    "draggable": false,
                    "filterable": false, 
                    "filterMenu": false,
                    "sortable": false,

                },
               
            ],

        },
        {
            "id": "update_details",
            "type": "dataColumn",
            "mainKey": "sdly",
            "tileName": "otb",
            "display": "Update Details",
            "backgroundColor": "grey",
            "className": "col-main",
            "draggable": true,
            "hidden": false,
            "colspan": 6,
            "order": 3,
            "subcolumns": [
                {
                    ...generatDefaultSubcolumns("Roomsrate", "update_details"),
                    "order": 1,
                    "draggable": false,
                    "filterable": false, 
                    "filterMenu": false,
                    "sortable": false,

                },
                {
                    ...generatDefaultSubcolumns("Status", "update_details"),
                    "order": 1,
                    "draggable": false,
                    "filterable": false, 
                    "filterMenu": false,
                    "sortable": false,

                },
               
            ],

        },
       

    ],
},

// Daily Rate Push Status 
dailyRatesendStatus: {
    columns: [
        {
            "id": "staydates", 
            "type": "dateColumn",
            "display": "{rangeyear}",
            "backgroundColor": "grey",
            "className": "otb data-column otb-current",
            "draggable": false,
            "colspan": 2, //This should be <subcolumns.length>
            "order": 1,
            "subcolumns": [
                {
                    ...generatDefaultSubcolumns("date", "staydates"),
                    // "draggable": true,
                    "sorted": null,
                    "filterable": false, 
                    "sortable": false,
                    "filterMenu": false,
                    "order": 1
                },
                {
                    ...generatDefaultSubcolumns("dow", "staydates"),
                    "draggable": false,
                    "filterable": false, 
                    "filterMenu": false,
                    "sortable": false,
                    "order": 2
                },
            ],
        },
        // data columns

    ],
    dataDateColumns: [
        {
            "id": "rate_send_status",
            "type": "dataColumn",
            "mainKey": "primary",
            "tileName": "Report",
            "display": "Tracking",
            "backgroundColor": "grey",
            "className": "col-main",
            "draggable": true,
            "hidden": false,
            "colspan": 6,
            "order": 2,
            "subcolumns": [
                {
                    ...generatDefaultSubcolumns("UpdateTime", "rate_send_status"),
                    "order": 1,
                    "draggable": false,
                    "filterable": false, 
                    "filterMenu": false,
                    "sortable": false,
                },
                {
                    ...generatDefaultSubcolumns("User", "rate_send_status"),
                    "order": 2,
                    "draggable": false,
                    "filterable": false, 
                    "filterMenu": false,
                    "sortable": false,
                },
               

                


            ],
        },

        {
            "id": "prior_details",
            "type": "dataColumn",
            "mainKey": "sdly",
            "tileName": "otb",
            "display": "Prior Details",
            "backgroundColor": "grey",
            "className": "col-main",
            "draggable": true,
            "hidden": false,
            "colspan": 6,
            "order": 3,
            "subcolumns": [
                {
                    ...generatDefaultSubcolumns("PriorRate", "rate_send_status"),
                    "order": 2,
                    "draggable": false,
                    "filterable": false, 
                    "filterMenu": false,
                    "sortable": false,
                },
                {
                    ...generatDefaultSubcolumns("RecRate", "rate_send_status"),
                    "order": 2,
                    "draggable": false,
                    "filterable": false, 
                    "filterMenu": false,
                    "sortable": false,
                },
            ],
        },

        {
            "id": "update_details",
            "type": "dataColumn",
            "mainKey": "sdly",
            "tileName": "otb",
            "display": "Update Details",
            "backgroundColor": "grey",
            "className": "col-main",
            "draggable": true,
            "hidden": false,
            "colspan": 6,
            "order": 3,
            "subcolumns": [
                {
                    ...generatDefaultSubcolumns("ChangeRate", "update_details"),
                    "order": 1,
                    "draggable": false,
                    "filterable": false, 
                    "filterMenu": false,
                    "sortable": false,

                },
                {
                    ...generatDefaultSubcolumns("Action", "rate_send_status"),
                    "order": 2,
                    "draggable": false,
                    "filterable": false, 
                    "filterMenu": false,
                    "sortable": false,

                },
                {
                    ...generatDefaultSubcolumns("Status", "update_details"),
                    "order": 3,
                    "draggable": false,
                    "filterable": false, 
                    "filterMenu": false,
                    "sortable": false,

                },
            ],
        },
    ],
},
    /////// End Reports Sections //////
};




