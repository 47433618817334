import React, { useEffect, useState, useRef } from 'react'
import HighchartsReact from 'highcharts-react-official';
import Highcharts from 'highcharts';
import { getOptions } from "./chartlogic"
import { useDispatch, useSelector } from 'react-redux';
import { updateAppState } from '../../actions/appstate';
import Alert from '@mui/material/Alert';
import { transformData, getMergedData } from './functions';
import {
  getTileById, getCompsetMedian,
  getResourceIdByPropId, getSeriesById,
  deepCopy, getFromState, getTransformedSegments,filterTopSegments,
  aggregateObject
} from '../../app/util';
import '../../simple-grid.css';
import './chart-view.css';
import moment from 'moment';

function ChartCommon({ compDisplayData }) {
  // debugger;
  const dispatch = useDispatch();
  const state = useSelector(appState => appState);
  const { property_details } = useSelector(state => state.auth.profile);
  const { app_state, ui_state } = useSelector(state => state.app.current_state);
  const { workspace_controls } = app_state;
  const { sdlyOn, segmented_view, pickUpType, segmentType } = workspace_controls;
  const [displayData, setDisplayData] = useState([]);
  const [compLoad, setCompLoad] = useState(false);
  const [options, setOptions] = useState({});
  const resource_id = getResourceIdByPropId(app_state.property_id, property_details);
  let segments = null

  let inventory = getFromState('system_settings>inventory_settings', state);
  if (!inventory) console.error("inventory_settings is not available for this property");
  const { total_capacity } = inventory && inventory.current ? inventory.current : {};
  // debugger
  let marketSegments = getFromState('system_settings>market_segment_category_mapping', state);
  let lv1_segGrouping = getFromState('system_settings>lvl1_mseg_grouping', state);
  if (segmented_view) {
    // debugger
    let marketSegments = getFromState('system_settings>market_segment_category_mapping', state);
    segments = getTransformedSegments(marketSegments);
    // debugger;   
  }

  let compRef = useRef({
    compLoad: false
  });

  useEffect(() => {
    // debugger;
    let isSubscribed = true;
    if (isSubscribed && compRef.current.compLoad && displayData.length) {
      let param = {
        ...app_state.primary_stay_range,
        ...app_state.workspace_controls,
        active_api: app_state.active_api,
        ...ui_state,
        resource_id,
        segmented_view,
        pickUpType,
        segments,
        total_capacity,
        property_id: app_state.property_id,
        property_details: property_details,
        lv1_segGrouping:filterTopSegments(lv1_segGrouping)
      };
      // debugger; 
      
      let chartOptions = getOptions(displayData, param);
      // debugger
      console.log('chartOptions:', chartOptions);
      setOptions({ options, ...chartOptions });
    }

    return () => (isSubscribed = false)
  }, [compLoad]);

  useEffect(() => {
    let isSubscribed = true;
    // debugger
    if (!ui_state.showspinner && !compRef.current.compLoad) {
      // debugger; 
      if (compDisplayData && compDisplayData.length ===1) {
        let transformedData = [];
        if (['market'].includes(ui_state.active_tiles[0])) {
          transformedData = getMergedData(compDisplayData, { ui_state: ui_state, app_state: app_state, segments });
          // debugger;
        } else {
          // debugger;
          let params = { dataSet: compDisplayData[0].data, data_id: compDisplayData[0].data_id,
            segmentType, topSegments:filterTopSegments(lv1_segGrouping),
             range: app_state.primary_stay_range, 
            segments }
          transformedData = transformData(params);
        }

        // debugger;  
        compRef.current.compLoad = true;
        setDisplayData(transformedData);
        setCompLoad(true);
      }
      if (compDisplayData && (compDisplayData.constructor.name === 'Array' && compDisplayData.length > 1)) {
        let mergedData = [];
        let transformedData = [];
        let topSegments = filterTopSegments(lv1_segGrouping);

        try {
          mergedData = getMergedData(compDisplayData, { ui_state: ui_state, app_state: app_state, segments, topSegments });
        } catch (e) {
          console.warn("Code Block Failed!!", e);
        }

        // debugger;  
        if (ui_state.active_tiles.includes('forecast') && segmented_view) {
          let params = { dataSet: mergedData, data_id: 'OSRSegment',
          range: ui_state.primary_stay_range, segments,
          topSegments:filterTopSegments(lv1_segGrouping),segmentType  }
          transformedData = transformData(params);
          compRef.current.compLoad = true;
          setDisplayData(transformedData);
          setCompLoad(true);
        } else {
          compRef.current.compLoad = true;
          setDisplayData(mergedData);
          setCompLoad(true);
        }

      }
    }

    return () => (isSubscribed = false)
  }, [
    // ui_state.showspinner,
    ui_state,
    app_state.workspace_controls,
    app_state.data_load
  ]);

  return (
    <>
      {compRef.current.compLoad && <HighchartsReact
        highcharts={Highcharts}
        options={options} />}
      {!compRef.current.compLoad && <div style={{ margin: "0 10px" }}><Alert sx={{ hight: 30 }} severity="warning">{compRef.current.infoTxt}</Alert></div>}
    </>
  );
}

export default ChartCommon;
