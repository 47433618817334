//React
import React, { useState, useEffect, useReducer } from 'react';
import { useNavigate } from "react-router-dom";

//React Redux
import { useDispatch, useSelector } from 'react-redux';

//
import { getData, updateAppState } from "../../actions/appstate";

//MUI
import {
    Grid, Button, LinearProgress, Badge, Modal,
    Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography, Switch, Tooltip, IconButton
} from '@mui/material';
import {
    CloseSharp as CloseSharpIcon,
    ArrowBackIosNewSharp as ArrowBackIosNewSharpIcon,
    ArrowForwardIosSharp as ArrowForwardIosSharpIcon,
    CheckSharp as CheckSharpIcon,
    GridOn as GridOnIcon
}
    from '@mui/icons-material';
import DownloadIcon from '@mui/icons-material/Download';
import BarChartIcon from '@mui/icons-material/BarChart';

import { border, Box, fontSize, textAlign } from '@mui/system';

//Moments
import moment from 'moment';

//APP's
import theme from '../../theme';
import './dailyflash.css'
import DataTable from './DataTable';
import DataChart from './DataChart';
import { styled } from "@mui/material/styles";
import MonthCard from './MonthCard';
import { calculateMonthlyData, getMonthlyData, getMonthlyForecastData, calForeCastData } from './functions';
import dataService from '../../services/data.service';
import { getTileById, getSeriesById } from '../../app/util';
import TableToExcel from "@linways/table-to-excel";
import { CustomSVGIcon } from '../layout/dashboard-ui';





function DailyFlash(props) {
    const { app_state, ui_state } = useSelector(
        (state) => state.app.current_state
    );
    const dispatch = useDispatch();
    const { auth, app } = useSelector(state => state);
    const { user, profile } = auth;

    const [compState, setCompState] = useState({ dataStatus: { all: 'idle' } });
    const [chartDisplay, setChartDisplay] = useState(false);
    const [otbData, setOtbData] = useState(null);
    const [reportData, setReportData] = useState(null);
    const [budgetData, setBudgetData] = useState(null);

    const [forecastData, setForecastData] = useState(null);
    const [showOnLogin, setShowOnLogin] = useState((ui_state.flash_showOnLogin !== null) ? ui_state.flash_showOnLogin : auth.user.flash_report);
    // const [showOnLogin, setShowOnLogin] = useState(auth.user.flash_report);
    let selectedPropertyName = profile.property_details.filter(property => property.id == app_state.property_id)[0].name;


    const handleChartShow = () => {
        console.log("Im clicked ");
        setChartDisplay(state => !state);
    }

    const handleDownloadReport = () => {
        if (reportData && reportData.length > 0) {
            let exportFileName = moment(app_state.as_of_date).format('MMM DD YYYY') + "_" + selectedPropertyName + "_DailyFlash";
            TableToExcel.convert(document.getElementById("dailyFlashTable"), {
                name: exportFileName + ".xlsx",
                sheet: {
                    name: "Daily Flash Report"
                }
            });
        }

    }

    const { data_sources } = getTileById('daily_flash');
    useEffect(() => {
        // setShowOnLogin((ui_state.flash_showOnLogin !== null) ? ui_state.flash_showOnLogin : auth.user.flash_report);
        let rangeFromDate = new moment(app_state.as_of_date).startOf('month').format('YYYY-MM-DD');
        let rangeUntilDate = new moment(rangeFromDate).add(1, "year").endOf('month').format('YYYY-MM-DD');
        // debugger;
        ///
        const mainPromises = [];
        const payLoads = {
            OSRYear: {
                "method": "GET",
                "asOfDate": app_state.as_of_date,
                "resourceId": app_state.property_id,
                "resourceScope": "property",
                "asOfDate": app_state.as_of_date,
                "pickupDays": 1,
                "rangeFromDate": rangeFromDate,
                "rangeUntilDate": rangeUntilDate,
                "queryType": "ARI",
                "querySubtype": "",
                "segmentationType": "market_code",
                "segmentFilter": "1",
                "type": 1,
            },
            BudgetYear: {
                "method": "GET",
                "property_id": app_state.property_id,
                "asOfDate": app_state.as_of_date,
                "rangeFromDate": rangeFromDate,
                "rangeUntilDate": rangeUntilDate,
            },
            OSRContrainedForecastYear: {
                "method": "GET",
                "params": {
                    "pid": app_state.property_id,
                    "asof_date": app_state.as_of_date,
                    "date_from": rangeFromDate,
                    "date_until": rangeUntilDate,
                    "from_cache": "false"
                }
            }
        };
        data_sources.map(activeApi => {
            let new_data_status = {};
            let payload = payLoads[activeApi];
            dataService.updateDataCache(activeApi, { payload: payload });
            let dataSeries = getSeriesById(activeApi);
            // debugger;
            //if (setCompState[activeApi] !== 'loadings') {
            new_data_status[activeApi] = 'loading';
            let apiCallStarting = getData({
                ...app_state, active_api: activeApi,
            })();
            mainPromises.push(apiCallStarting);
            apiCallStarting.then(
                (rs) => {
                    new_data_status[activeApi] = 'loaded';
                    setCompState({ ...compState, dataStatus: { ...new_data_status } });
                },
                (erro) => {
                    new_data_status[activeApi] = 'loaded';
                    setCompState({ ...compState, dataStatus: { ...new_data_status } });
                }
            )

            setCompState({ ...compState, dataStatus: { ...new_data_status } });
            //}
        });
        Promise.allSettled(mainPromises).then(
            (settled) => {
                let otbDataResponse = settled[0].value;
                setOtbData(otbDataResponse.data);
                let otbMonthlyData = getMonthlyData(otbDataResponse.data, ui_state, app_state);
                // debugger;
                setReportData(otbMonthlyData)

                // forcast data 
                let forecastResponse = settled[1].value;
                // debugger;
                let forecastMonthlyData = calForeCastData(otbDataResponse, { data: forecastResponse }, app_state, ui_state);

                setForecastData(forecastMonthlyData);

                // Budget Data
                let budgetMonthlyData = settled[2].value;
                setBudgetData(budgetMonthlyData);
            })
    }, [])
    const handleChange = (type, value) => {
        setShowOnLogin(value);
        let formData = new FormData();
        formData.append('flashReport', value);
        const showOnLoginPayload = {
            "flashReport": value,
        }
        dataService.getData({ api: "users/edit-user/", request: { method: 'PUT', formData } })
            .then((res) => {
                console.log("Value => ",value);
                debugger;
                dispatch(updateAppState(type, {
                    app_state: {
                        ...app_state
                    },
                    ui_state: { ...ui_state, flash_showOnLogin: value}
                }));
            })
    }
    return (
        <div>
            <Box
                className="daily-flash-wrap"
                sx={{
                    position: 'absolute',
                    top: '50%',
                    left: '50%',
                    transform: 'translate(-50%, -50%)',
                    maxWidth: '83vw',
                    width: '83vw', height: 'auto', minHeight: '95vh !important',
                    // bgcolor: '#333',
                    // boxShadow: 10, borderRadius: '5px'
                }}>
                <div className="pop-up-header clearfix request-popup-header">
                    <Box sx={{ float: 'left', position: 'relative', width: "80%" }}>
                        <div className="tile-title" data-tile-icon="icon-icon-events" data-id="events">
                            {/* <span className="icon icon-custom daily-flash">
                                <img src="/assets/img/business-intelligence.svg" width="22" height="22" />
                            </span> */}



                            <span className='report-details'>
                                <span className='report-icon' >
                                    <Tooltip title="Daily Flash Report">
                                        <IconButton className='icon-btn' color='white' sx={{ p: 0 }}>
                                            <CustomSVGIcon path="assets/img/business-intelligence.svg" width="25" height="25" />
                                        </IconButton>
                                    </Tooltip></span>
                                <span className="tile-name">Daily Flash</span>
                            </span>


                            <span className='property-details'>
                                <span>
                                    <Tooltip title="Properties">
                                        <IconButton className='icon-btn' color='white' sx={{ p: 0 }}>
                                            <CustomSVGIcon path="assets/img/building-icon.svg" width="20" height="23" />
                                        </IconButton>
                                    </Tooltip></span>
                                <span className='property-name'> {app_state.property_name}</span>
                            </span>
                        </div>
                        <div className="tile-title" data-tile-icon="icon-icon-events" data-id="events"
                            style={{ position: 'absolute', left: "58%", top: "-1px" }}>
                            <span className="tile-name" style={{ fontSize: "13px", textTransform: "unset" }}> Show On Login
                                <Switch color='white' checked={showOnLogin} onChange={() => {
                                    handleChange("showLogin", !showOnLogin)
                                }} />

                            </span>
                        </div>
                    </Box>
                    <Box sx={{ float: 'right', paddingTop: '6px' }}>
                        <Button sx={{
                            minWidth: 'auto', width: '22px', height: '22px', p: '7px', mr: '17px',
                            color: '#9ed00d'
                        }} onClick={handleChartShow} >
                            {chartDisplay && <GridOnIcon className='toggle-view table' />}
                            {!chartDisplay && <BarChartIcon className='toggle-view chart' />}
                        </Button>
                        <Button sx={{
                            minWidth: 'auto', width: '22px', height: '22px', p: '7px', mr: '17px',
                            color: '#9ed00d'
                        }} onClick={handleDownloadReport}>
                            <DownloadIcon fontSize='large' sx={{ height: '28px' }} />
                        </Button>
                        <Button sx={{
                            minWidth: 'auto', width: '22px', height: '22px', p: '7px',
                            mr: '7px', borderRadius: '50%', background: '#000', color: '#FFF', fontSize: '10px'
                        }} onClick={props.handleClose}>
                            <CloseSharpIcon fontSize='small' />
                        </Button>
                    </Box>
                </div>



                <Grid item xs={8.5}>
                    <Box sx={{
                        borderRadius: '0px 0px 5px 5px', height: 'auto', minHeight: '22%', width: '100%',
                        background: '#fff', position: "absolute",
                    }}>
                        <Box sx={{ m: 1 }}>
                            {(!chartDisplay) ?
                                <DataTable data={reportData} />
                                :
                                <DataChart data={reportData} />
                            }
                            <MonthCard data={reportData} forecastData={forecastData} budgetData={budgetData} />
                        </Box>
                    </Box>
                </Grid>
            </Box>
        </div>
    )
}

export default DailyFlash
