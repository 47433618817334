import * as React from 'react';
import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import { Grid } from '@mui/material';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import ArrowDropUpIcon from '@mui/icons-material/ArrowDropUp';
import './dailyflash.css'
import SmallLoader from './SmallLoader';
import moment from 'moment';
import { useSelector } from 'react-redux';
import { dataFormatMonthlyCard, removeSpecials } from './functions';
import { removeSpecialChar, removeSpecialCharWithOutDecimal } from '../../app/util';



export default function MonthCard(props) {
    const { app_state, ui_state } = useSelector(
        (state) => state.app.current_state
    );

    const [monthlyData, setMonthlyData] = React.useState(props.data);
    let currentMonth = moment(app_state.as_of_date).format("MMMM YYYY");
    let result;
    let primary = { Occ: null, ADR: null, RevPAR: null, Revenue: null }
    let sdly = { Occ: null, ADR: null, RevPAR: null, Revenue: null }

    let primaryNext = { Occ: null, ADR: null, RevPAR: null, Revenue: null }
    let sdlyNext = { Occ: null, ADR: null, RevPAR: null, Revenue: null } 

    let primaryFar = { Occ: null, ADR: null, RevPAR: null, Revenue: null }
    let sdlyFar = { Occ: null, ADR: null, RevPAR: null, Revenue: null }

    let forecast = { Occ: null, ADR: null, RevPAR: null, Revenue: null }
    let forecastNext = { Occ: null, ADR: null, RevPAR: null, Revenue: null }
    let forecastFar = { Occ: null, ADR: null, RevPAR: null, Revenue: null }

     console.log("props.budgetData =>",props.budgetData);
    if(props.forecastData && props.forecastData.length > 0){
        // debugger;
        forecast = {
            ...forecast,
            Occ: props.forecastData[0].Occ,
            ADR: props.forecastData[0].ADR,
            RevPAR: props.forecastData[0].RevPAR,
            Revenue: props.forecastData[0].Revenue,
        }
        forecastNext = {
            ...forecastNext,
            Occ: props.forecastData[1].Occ,
            ADR: props.forecastData[1].ADR,
            RevPAR: props.forecastData[1].RevPAR,
            Revenue: props.forecastData[1].Revenue,
        }
        forecastFar = {
            ...forecastFar,
            Occ: props.forecastData[2].Occ,
            ADR: props.forecastData[2].ADR,
            RevPAR: props.forecastData[2].RevPAR,
            Revenue: props.forecastData[2].Revenue,
        }
    }
    if (props.data && props.data.length > 0) {
        primary = {
            ...primary,
            Occ: props.data[0].primary.Occ,
            ADR: props.data[0].primary.ADR,
            RevPAR: props.data[0].primary.RevPAR,
            Revenue: props.data[0].primary.Revenue,
        }
        sdly = {
            ...sdly,
            Occ: props.data[0].sdly.Occ,
            ADR: props.data[0].sdly.ADR,
            RevPAR: props.data[0].sdly.RevPAR,
            Revenue: props.data[0].sdly.Revenue,
        }
        primaryNext = {
            ...primaryNext,
            Occ: props.data[1].primary.Occ,
            ADR: props.data[1].primary.ADR,
            RevPAR: props.data[1].primary.RevPAR,
            Revenue: props.data[1].primary.Revenue,
        }
        sdlyNext = {
            ...sdlyNext,
            Occ: props.data[1].sdly.Occ,
            ADR: props.data[1].sdly.ADR,
            RevPAR: props.data[1].sdly.RevPAR,
            Revenue: props.data[1].sdly.Revenue,
        }
        primaryFar = {
            ...primaryFar,
            Occ: props.data[2].primary.Occ,
            ADR: props.data[2].primary.ADR,
            RevPAR: props.data[2].primary.RevPAR,
            Revenue: props.data[2].primary.Revenue,
        }
        sdlyFar = {
            ...sdlyFar,
            Occ: props.data[2].sdly.Occ,
            ADR: props.data[2].sdly.ADR,
            RevPAR: props.data[2].sdly.RevPAR,
            Revenue: props.data[2].sdly.Revenue,
        }

    }
    return (
        <Grid container justifyContent="space-between" spacing={1}>
           <Grid item xs={4}>
           <Card className='card-container'>
                <CardContent>
                    <Typography className='card-date-title'>
                        {currentMonth}  test
                    </Typography>
                    <Box className='card-content-box'>
                        <Grid container rowSpacing={1} columnSpacing={{ xs: 2 }} sx={{ mt: 1, pl: 1.9 }}>
                            <Grid item xs={6}>
                                <span className='card-data-main-title'>OCCUPANCY</span>
                            </Grid>
                            <Grid item xs={6}>
                                <span className='card-data-main-title'>AVG DAILY RATE</span>
                            </Grid>
                            <Grid item xs={6}>
                                <span className='card-data-main-text'>
                                    {(primary.Occ) ? primary.Occ : <SmallLoader />}
                                </span>
                            </Grid>
                            <Grid item xs={6}>
                                <span className='card-data-main-text'>
                                    {(primary.ADR) ? primary.ADR : <SmallLoader />}

                                </span>
                            </Grid>

                            <Grid item xs={6}>
                                <span style={{ position: 'relative' }}>
                                    <span className='card-data-text'>Sdly</span>
                                    <span className='card-data-text'> {(sdly.Occ) ? sdly.Occ : <SmallLoader />}</span>
                                    <span className='card-data-text-arrow' style={{ position: 'absolute', top: -5, right: 50 }}>
                                    {
                                       (sdly.Occ && primary.Occ && primary.Occ > sdly.Occ) ? 
                                        <ArrowDropUpIcon fontSize='large' color='green' />
                                        :
                                        <ArrowDropDownIcon fontSize='large' color='red' />
                                     } 
                                    </span>
                                </span> 
                              
                                <span style={{ position: 'relative' }}>
                                    <span className='card-data-text'>Forecast</span>
                                    <span className='card-data-text'>{(forecast.Occ) ? forecast.Occ : <SmallLoader />}</span>
                                    <span className='card-data-text-arrow' style={{ position: 'absolute', top: 15, right: 50 }}>
                                        {/* <ArrowDropDownIcon fontSize='large' color='red' /> */}
                                    {
                                       (forecast.Occ && primary.Occ && primary.Occ > forecast.Occ) ? 
                                        <ArrowDropUpIcon fontSize='large' color='green' />
                                        :
                                        <ArrowDropDownIcon fontSize='large' color='red' />
                                     } 
                                    </span>
                                </span> 
                                <span>
                                    <span className='card-data-text'>Budget</span>
                                    <span className='card-data-text'>No Data</span>
                                </span>

                            </Grid>
                            <Grid item xs={6}>
                                <span style={{ position: 'relative' }}>
                                    <span className='card-data-text'>Sdly</span>
                                    <span className='card-data-text'>
                                        {(sdly.ADR) ? sdly.ADR : <SmallLoader />}
                                    </span>
                                    <span className='card-data-text-arrow' style={{ position: 'absolute', top: -5, right: 50 }}>
                                    {
                                       (sdly.ADR && primary.ADR && primary.ADR > sdly.ADR) ? 
                                        <ArrowDropUpIcon fontSize='large' color='green' />
                                        :
                                        <ArrowDropDownIcon fontSize='large' color='red' />
                                     } 
                                        
                                    </span>
                                </span>
                                <span style={{ position: 'relative' }}>
                                    <span className='card-data-text'>Forecast</span>
                                    <span className='card-data-text'>{(forecast.ADR) ? forecast.ADR : <SmallLoader />}</span>
                                    <span className='card-data-text-arrow' style={{ position: 'absolute', top: 15, right: 50 }}>
                                    {
                                       (forecast.ADR && primary.ADR && primary.ADR > forecast.ADR) ? 
                                        <ArrowDropUpIcon fontSize='large' color='green' />
                                        :
                                        <ArrowDropDownIcon fontSize='large' color='red' />
                                     } 
                                    </span>
                                </span><span>
                                    <span className='card-data-text'>Budget</span>
                                    <span className='card-data-text'>No Data</span>
                                </span>
                            </Grid>

                        </Grid>

                        <Grid container rowSpacing={1} columnSpacing={{ xs: 2 }} sx={{ mt: 2, pl: 1.9 }}>
                            <Grid item xs={6}>
                                <span className='card-data-main-title'>Rev Par</span>
                            </Grid>
                            <Grid item xs={6}>
                                <span className='card-data-main-title'>Revenue</span>
                            </Grid>
                            <Grid item xs={6}>
                                <span className='card-data-main-text'>
                                    {(primary.RevPAR) ? primary.RevPAR : <SmallLoader />}
                                </span>
                            </Grid>
                            <Grid item xs={6}>
                                <span className='card-data-main-text'>
                                    {/* { removeSpecialChar(primary.Revenue)} */}
                                    {(primary.Revenue) ? dataFormatMonthlyCard("Revenue",removeSpecialChar(primary.Revenue),ui_state) : <SmallLoader />}
                                    </span>
                            </Grid>

                            <Grid item xs={6}>
                                <span style={{ position: 'relative' }}>
                                    <span className='card-data-text'>Sdly</span>
                                    <span className='card-data-text'>
                                        {(sdly.RevPAR) ? sdly.RevPAR : <SmallLoader />}</span>
                                    <span className='card-data-text-arrow' style={{ position: 'absolute', top: -5, right: 50 }}>
                                    {
                                       (sdly.RevPAR && primary.RevPAR && primary.RevPAR > sdly.RevPAR) ? 
                                        <ArrowDropUpIcon fontSize='large' color='green' />
                                        :
                                        <ArrowDropDownIcon fontSize='large' color='red' />
                                     } 
                                    </span>
                                </span>
                                <span style={{ position: 'relative' }}>
                                    <span className='card-data-text'>Forecast</span>
                                    <span className='card-data-text'>{(forecast.RevPAR) ? forecast.RevPAR : <SmallLoader />}</span>
                                    <span className='card-data-text-arrow' style={{ position: 'absolute', top: 15, right: 50 }}>
                                    {
                                       (forecast.RevPAR && primary.RevPAR && primary.RevPAR > forecast.RevPAR) ? 
                                        <ArrowDropUpIcon fontSize='large' color='green' />
                                        :
                                        <ArrowDropDownIcon fontSize='large' color='red' />
                                     } 
                                    </span>
                                </span>
                                <span>
                                    <span className='card-data-text'>Budget</span>
                                    <span className='card-data-text'>No Data</span>
                                </span>

                            </Grid>
                            <Grid item xs={6}>
                                <span style={{ position: 'relative' }}>
                                    <span className='card-data-text'>Sdly</span>
                                    <span className='card-data-text'>{(sdly.Revenue) ? dataFormatMonthlyCard("Revenue",removeSpecialChar(sdly.Revenue) ,ui_state) : <SmallLoader />}</span>
                                    <span className='card-data-text-arrow' style={{ position: 'absolute', top: -5, right: 50 }}>
                                    {
                                       (sdly.Revenue && primary.Revenue && primary.Revenue > sdly.Revenue) ? 
                                        <ArrowDropUpIcon fontSize='large' color='green' />
                                        :
                                        <ArrowDropDownIcon fontSize='large' color='red' />
                                     } 
                                    </span>
                                </span> <span style={{ position: 'relative' }}>
                                    <span className='card-data-text'>Forecast</span>
                                    <span className='card-data-text'>{(forecast.Revenue) ? forecast.Revenue : <SmallLoader />}</span>
                                    <span className='card-data-text-arrow' style={{ position: 'absolute', top: 15, right: 50 }}>
                                    {
                                       (forecast.Revenue && primary.Revenue && primary.Revenue > forecast.Revenue) ? 
                                        <ArrowDropUpIcon fontSize='large' color='green' />
                                        :
                                        <ArrowDropDownIcon fontSize='large' color='red' />
                                     } 
                                    </span>
                                </span> <span>
                                    <span className='card-data-text'>Budget</span>
                                    <span className='card-data-text'>No Data</span>
                                </span>
                            </Grid>

                        </Grid>

                    </Box>
                </CardContent>

            </Card>
         </Grid>
           
           <Grid item xs={4}>
            <Card className='card-container'>
            <CardContent>
                    <Typography className='card-date-title'>
                        {moment(currentMonth).add(1,"month").format("MMMM YYYY")}
                    </Typography>
                    <Box className='card-content-box'>
                        <Grid container rowSpacing={1} columnSpacing={{ xs: 2 }} sx={{ mt: 1, pl: 1.9 }}>
                            <Grid item xs={6}>
                                <span className='card-data-main-title'>OCCUPANCY</span>
                            </Grid>
                            <Grid item xs={6}>
                                <span className='card-data-main-title'>AVG DAILY RATE</span>
                            </Grid>
                            <Grid item xs={6}>
                                <span className='card-data-main-text'>
                                    {(primaryNext.Occ) ? primaryNext.Occ : <SmallLoader />}
                                </span>
                            </Grid>
                            <Grid item xs={6}>
                                <span className='card-data-main-text'>
                                    {(primaryNext.ADR) ? primaryNext.ADR : <SmallLoader />}

                                </span>
                            </Grid>

                            <Grid item xs={6}>
                            <span style={{ position: 'relative' }}>
                                    <span className='card-data-text'>Sdly</span>
                                    <span className='card-data-text'> 
                                    {(sdlyNext.Occ) ? sdlyNext.Occ : <SmallLoader />}</span>
                                    <span className='card-data-text-arrow' style={{ position: 'absolute', top: -5, right: 50 }}>
                                    {
                                       (sdlyNext.Occ && primaryNext.Occ && primaryNext.Occ > sdlyNext.Occ) ? 
                                        <ArrowDropUpIcon fontSize='large' color='green' />
                                        :
                                        <ArrowDropDownIcon fontSize='large' color='red' />
                                     } 
                                    </span>
                                </span> 
                                <span style={{ position: 'relative' }}>
                                    <span className='card-data-text'>Forecast</span>
                                    <span className='card-data-text'>
                                    {(forecastNext.Occ) ? forecastNext.Occ : <SmallLoader />}</span>
                                    <span className='card-data-text-arrow' style={{ position: 'absolute', top: 15, right: 50 }}>
                                    {
                                       (forecastNext.Occ && primaryNext.Occ && primaryNext.Occ > forecastNext.Occ) ? 
                                        <ArrowDropUpIcon fontSize='large' color='green' />
                                        :
                                        <ArrowDropDownIcon fontSize='large' color='red' />
                                     } 
                                    </span>
                                </span> <span style={{ position: 'relative' }}>
                                    <span className='card-data-text'>Budget</span>
                                    <span className='card-data-text'>No Data</span>
                                    
                                </span>

                            </Grid>
                            <Grid item xs={6}>
                                <span style={{ position: 'relative' }}>
                                    <span className='card-data-text'>Sdly</span>
                                    <span className='card-data-text'>
                                        {(sdlyNext.ADR) ? sdlyNext.ADR : <SmallLoader />}
                                    </span>
                                    <span className='card-data-text-arrow' style={{ position: 'absolute', top: -5, right: 50 }}>
                                    {
                                       (sdlyNext.ADR && primaryNext.ADR && primaryNext.ADR > sdlyNext.ADR) ? 
                                        <ArrowDropUpIcon fontSize='large' color='green' />
                                        :
                                        <ArrowDropDownIcon fontSize='large' color='red' />
                                     } 
                                    </span>
                                </span>
                                <span style={{ position: 'relative' }}>
                                    <span className='card-data-text'>Forecast</span>
                                    <span className='card-data-text'> {(forecastNext.ADR) ? forecastNext.ADR : <SmallLoader />}</span>
                                    <span className='card-data-text-arrow' style={{ position: 'absolute', top: 15, right: 50 }}>
                                    {
                                       (forecastNext.ADR && primaryNext.ADR && primaryNext.ADR > forecastNext.ADR) ? 
                                        <ArrowDropUpIcon fontSize='large' color='green' />
                                        :
                                        <ArrowDropDownIcon fontSize='large' color='red' />
                                     } 
                                    </span>
                                </span><span>
                                    <span className='card-data-text'>Budget</span>
                                    <span className='card-data-text'>No Data</span>
                                </span>
                            </Grid>

                        </Grid>

                        <Grid container rowSpacing={1} columnSpacing={{ xs: 2 }} sx={{ mt: 2, pl: 1.9 }}>
                            <Grid item xs={6}>
                                <span className='card-data-main-title'>Rev Par</span>
                            </Grid>
                            <Grid item xs={6}>
                                <span className='card-data-main-title'>Revenue</span>
                            </Grid>
                            <Grid item xs={6}>
                                <span className='card-data-main-text'>
                                    {(primaryNext.RevPAR) ? primaryNext.RevPAR : <SmallLoader />}
                                </span>
                            </Grid>
                            <Grid item xs={6}>
                                <span className='card-data-main-text'>
                                    {(primaryNext.Revenue) ? dataFormatMonthlyCard("Revenue",removeSpecialChar(primaryNext.Revenue),ui_state) : <SmallLoader />}</span>
                            </Grid>

                            <Grid item xs={6}>
                                <span style={{ position: 'relative' }}>
                                    <span className='card-data-text'>sdly </span>
                                    <span className='card-data-text'>
                                        {(sdlyNext.RevPAR) ? sdlyNext.RevPAR : <SmallLoader />}</span>
                                    <span className='card-data-text-arrow' style={{ position: 'absolute', top: -5, right: 50 }}>
                                    {
                                       (sdlyNext.RevPAR && primaryNext.RevPAR && parseInt(removeSpecialChar(primaryNext.RevPAR)) > parseInt(removeSpecialChar(sdlyNext.RevPAR))) ? 
                                        <ArrowDropUpIcon fontSize='large' color='green' />
                                        :
                                        <ArrowDropDownIcon fontSize='large' color='red' />
                                     } 
                                    </span>
                                </span>
                                <span style={{ position: 'relative' }}>
                                    <span className='card-data-text'>Forecast</span>
                                    <span className='card-data-text'> {(forecastNext.RevPAR) ? forecastNext.RevPAR : <SmallLoader />}</span>
                                    <span className='card-data-text-arrow' style={{ position: 'absolute', top: 15, right: 50 }}>
                                    {
                                       (forecastNext.RevPAR && primaryNext.RevPAR && parseInt(removeSpecialChar(primaryNext.RevPAR)) > parseInt(removeSpecialChar(forecastNext.RevPAR))) ? 
                                        <ArrowDropUpIcon fontSize='large' color='green' />
                                        :
                                        <ArrowDropDownIcon fontSize='large' color='red' />
                                     } 
                                    </span>
                                </span>
                                <span>
                                    <span className='card-data-text'>Budget</span>
                                    <span className='card-data-text'>No Data</span>
                                </span>

                            </Grid>
                            <Grid item xs={6}>
                            <span style={{ position: 'relative' }}>

                                    <span className='card-data-text'>sdly</span>
                                    <span className='card-data-text'>{(sdlyNext.Revenue) ? dataFormatMonthlyCard("Revenue",removeSpecialChar(sdlyNext.Revenue) ,ui_state) : <SmallLoader />}</span>
                                    <span className='card-data-text-arrow' style={{ position: 'absolute', top: -5, right: 40 }}>
                                    {
                                       (sdlyNext.Revenue && primaryNext.Revenue && parseInt(removeSpecialChar(primaryNext.Revenue)) > parseInt(removeSpecialChar(sdlyNext.Revenue))) ? 
                                        <ArrowDropUpIcon fontSize='large' color='green' />
                                        :
                                        <ArrowDropDownIcon fontSize='large' color='red' />
                                     } 
                                    </span>
                                </span> <span style={{ position: 'relative' }}>
                                    <span className='card-data-text'>Forecast</span>
                                    <span className='card-data-text'> {(forecastNext.Revenue) ? forecastNext.Revenue : <SmallLoader />}</span>
                                    <span className='card-data-text-arrow' style={{ position: 'absolute', top: 15, right: 40 }}>
                                    {
                                       (forecastNext.Revenue && primaryNext.Revenue && removeSpecialCharWithOutDecimal(primaryNext.Revenue) > removeSpecialCharWithOutDecimal(forecastNext.Revenue)) ? 
                                        <ArrowDropUpIcon fontSize='large' color='green' />
                                        :
                                        <ArrowDropDownIcon fontSize='large' color='red' />
                                     } 
                                    </span>
                                </span> <span>
                                    <span className='card-data-text'>Budget</span>
                                    <span className='card-data-text'>No Data</span>
                                </span>
                            </Grid>

                        </Grid>

                    </Box>
                </CardContent>

            </Card>
           </Grid>
            <Grid item xs={4}>
            <Card className='card-container'>
                 <CardContent>
                    <Typography className='card-date-title'>
                    {moment(currentMonth).add(2,"month").format("MMMM YYYY")}
                    </Typography>
                    <Box className='card-content-box'>
                        <Grid container rowSpacing={1} columnSpacing={{ xs: 2 }} sx={{ mt: 1, pl: 1.9 }}>
                            <Grid item xs={6}>
                                <span className='card-data-main-title'>OCCUPANCY</span>
                            </Grid>
                            <Grid item xs={6}>
                                <span className='card-data-main-title'>AVG DAILY RATE</span>
                            </Grid>
                            <Grid item xs={6}>
                                <span className='card-data-main-text'>
                                    {(primaryFar.Occ) ? primaryFar.Occ : <SmallLoader />}
                                </span>
                            </Grid>
                            <Grid item xs={6}>
                                <span className='card-data-main-text'>
                                    {(primaryFar.ADR) ? primaryFar.ADR : <SmallLoader />}

                                </span>
                            </Grid>

                            <Grid item xs={6}>
                                <span style={{ position: 'relative' }}>
                                    <span className='card-data-text'>Sdly</span>
                                    <span className='card-data-text'> {(sdlyFar.Occ) ? sdlyFar.Occ : <SmallLoader />}</span>
                                    <span className='card-data-text-arrow' style={{ position: 'absolute', top: -5, right: 40 }}>
                                    {
                                       (sdlyFar.Occ && primaryFar.Occ && parseInt(removeSpecialChar(primaryFar.Occ)) > parseInt(removeSpecialChar(sdlyFar.Occ))) ? 
                                        <ArrowDropUpIcon fontSize='large' color='green' />
                                        :
                                        <ArrowDropDownIcon fontSize='large' color='red' />
                                     } 
                                    </span>
                                </span> <span style={{ position: 'relative' }}>
                                    <span className='card-data-text'>Forecast</span>
                                    <span className='card-data-text'> {(forecastFar.Occ) ? forecastFar.Occ : <SmallLoader />}</span>
                                    <span className='card-data-text-arrow' style={{ position: 'absolute', top: 15, right: 40 }}>
                                    {
                                       (forecastFar.Occ && primaryFar.Occ && removeSpecialCharWithOutDecimal(primaryFar.Occ) > removeSpecialCharWithOutDecimal(forecastFar.Occ)) ? 
                                        <ArrowDropUpIcon fontSize='large' color='green' />
                                        :
                                        <ArrowDropDownIcon fontSize='large' color='red' />
                                     } 
                                    </span>
                                </span> <span>
                                    <span className='card-data-text'>Budget</span>
                                    <span className='card-data-text'>No Data</span>
                                </span>

                            </Grid>
                            <Grid item xs={6}>
                                <span style={{ position: 'relative' }}>
                                    <span className='card-data-text'>Sdly</span>
                                    <span className='card-data-text'>
                                        {(sdlyFar.ADR) ? sdlyFar.ADR : <SmallLoader />}
                                    </span>
                                    <span className='card-data-text-arrow' style={{ position: 'absolute', top: -5, right: 50 }}>
                                    {
                                       (sdlyFar.ADR && primaryFar.ADR && removeSpecialCharWithOutDecimal(primaryFar.ADR) > removeSpecialCharWithOutDecimal(sdlyFar.ADR)) ? 
                                        <ArrowDropUpIcon fontSize='large' color='green' />
                                        :
                                        <ArrowDropDownIcon fontSize='large' color='red' />
                                     } 
                                    </span>
                                </span>
                                <span style={{ position: 'relative' }}>
                                    <span className='card-data-text'>Forecast</span>
                                    <span className='card-data-text'>{(forecastFar.ADR) ? forecastFar.ADR : <SmallLoader />}</span>
                                    <span className='card-data-text-arrow' style={{ position: 'absolute', top: 15, right: 50 }}>
                                    {
                                       (forecastFar.ADR && primaryFar.ADR && removeSpecialCharWithOutDecimal(primaryFar.ADR) > removeSpecialCharWithOutDecimal(forecastFar.ADR)) ? 
                                        <ArrowDropUpIcon fontSize='large' color='green' />
                                        :
                                        <ArrowDropDownIcon fontSize='large' color='red' />
                                     } 
                                    </span>
                                </span><span>
                                    <span className='card-data-text'>Budget</span>
                                    <span className='card-data-text'>No Data</span>
                                </span>
                            </Grid>

                        </Grid>

                        <Grid container rowSpacing={1} columnSpacing={{ xs: 2 }} sx={{ mt: 2, pl: 1.9 }}>
                            <Grid item xs={6}>
                                <span className='card-data-main-title'>Rev Par</span>
                            </Grid>
                            <Grid item xs={6}>
                                <span className='card-data-main-title'>Revenue</span>
                            </Grid>
                            <Grid item xs={6}>
                                <span className='card-data-main-text'>
                                    {(primaryFar.RevPAR) ? primaryFar.RevPAR : <SmallLoader />}
                                </span>
                            </Grid>
                            <Grid item xs={6}>
                                <span className='card-data-main-text'>
                                    {(primaryFar.Revenue) ? dataFormatMonthlyCard("Revenue",removeSpecialChar(primaryFar.Revenue),ui_state)  : <SmallLoader />}</span>
                            </Grid>

                            <Grid item xs={6}>
                                <span style={{ position: 'relative' }}>
                                    <span className='card-data-text'>sdly</span>
                                    <span className='card-data-text'>
                                        {(sdlyFar.RevPAR) ? sdlyFar.RevPAR : <SmallLoader />}</span>
                                    <span className='card-data-text-arrow' style={{ position: 'absolute', top: -5, right: 50 }}>
                                    {
                                       (sdlyFar.RevPAR && primaryFar.RevPAR && removeSpecialCharWithOutDecimal(primaryFar.RevPAR) > removeSpecialCharWithOutDecimal(sdlyFar.RevPAR)) ? 
                                        <ArrowDropUpIcon fontSize='large' color='green' />
                                        :
                                        <ArrowDropDownIcon fontSize='large' color='red' />
                                     } 
                                    </span>
                                </span>
                                <span style={{ position: 'relative' }}>
                                    <span className='card-data-text'>Forecast </span>
                                    <span className='card-data-text'>{(forecastFar.RevPAR) ? forecastFar.RevPAR : <SmallLoader />}</span>
                                    <span className='card-data-text-arrow' style={{ position: 'absolute', top: 15, right: 50 }}>
                                    {
                                       ( parseFloat(removeSpecialCharWithOutDecimal(primaryFar.RevPAR)) > parseFloat(removeSpecialCharWithOutDecimal(forecastFar.RevPAR)) ) ? 
                                        <ArrowDropUpIcon fontSize='large' color='green' />
                                        :
                                        <ArrowDropDownIcon fontSize='large' color='red' />
                                     } 
                                    </span>
                                </span>
                                <span>
                                    <span className='card-data-text'>Budget</span>
                                    <span className='card-data-text'>No Data</span>
                                </span>

                            </Grid>
                            <Grid item xs={6}>
                                <span style={{ position: 'relative' }}>
                                    <span className='card-data-text'>sdly</span>
                                    <span className='card-data-text'>{(sdlyFar.Revenue) ? dataFormatMonthlyCard("Revenue",removeSpecialChar(sdlyFar.Revenue) ,ui_state) : <SmallLoader />}</span>
                                    <span className='card-data-text-arrow' style={{ position: 'absolute', top: -5, right:40 }}>
                                    {
                                       ( removeSpecialCharWithOutDecimal(primaryFar.Revenue) > removeSpecialCharWithOutDecimal(sdlyFar.Revenue) ) ? 
                                        <ArrowDropUpIcon fontSize='large' color='green' />
                                        :
                                        <ArrowDropDownIcon fontSize='large' color='red' />
                                     } 
                                    </span>
                                </span> 
                                <span style={{ position: 'relative' }}>
                                    <span className='card-data-text'>Forecast</span>
                                    <span className='card-data-text'>{(forecastFar.Revenue) ? forecastFar.Revenue : <SmallLoader />}</span>
                                    <span className='card-data-text-arrow' style={{ position: 'absolute', top: 15, right:40 }}>
                                    {
                                       ( removeSpecialCharWithOutDecimal(primaryFar.Revenue) > removeSpecialCharWithOutDecimal(forecastFar.Revenue) ) ? 
                                        <ArrowDropUpIcon fontSize='large' color='green' />
                                        :
                                        <ArrowDropDownIcon fontSize='large' color='red' />
                                     } 
                                    </span>
                                </span> <span>
                                    <span className='card-data-text'>Budget</span>
                                    <span className='card-data-text'>No Data</span>
                                </span>
                            </Grid>

                        </Grid>

                    </Box>
                </CardContent>

            </Card>
           </Grid>

        </Grid>
    );
}