import zIndex from "@mui/material/styles/zIndex";
import React from "react";

function Spinner() {
  return (
    <div
      style={{
        textAlign: "center",
        width: "100%",
        height: "80%",
        position: "relative",
      }}
    >
      <div
        style={{
          textAlign: "center",
          position: "absolute",
          zIndex: 999,
          width: "100px",
          top: "30%",
          left: "47%",
          fontSize: 14,
        }}
      >
        <img
          src={`../../assets/img/chartLoading.svg`}
          alt={`Loading...`}
          loading="lazy"
          style={{ width: 70 }}
        />
        <div style={{ position: "relative", top: -20, color: "#afafaf" }}>
          Loading...
        </div>
      </div>
    </div>
  );
}

export default Spinner;
