import moment from "moment";
import { convertToPercent, deepCopy, getAppData, getCorrectValue, getDatesInRange, getFromState, getObjectFilter, getTransformedSegments, round, setUpDate } from "../../../app/util";
import { useDataSummary } from "../../Tile/kpiService";
import dataService from "../../../services/data.service";
import { useSelector } from "react-redux";
import { transformSegmentData } from "../segmentedData";
import { getSegmentedCode } from "../processDataReports";

export function getReportData_hotel(columns, auth, app_state) {
    let data = (app_state.workspace_controls.segmented_view) ? [] : deepCopy(dataService.getSeriesById("OSRSegment").data.data);
    let marketData = (dataService.getSeriesById("MarketARISegmented").data) ? deepCopy(dataService.getSeriesById("MarketARISegmented").data.data) : [];
    let setup_data = [];
    if (data) {
        let dataKey = "HotelTotal";
        data.map((item, index) => {
            let data_columns = [];
            columns.map(col => {
                let dataObj = {};
                if (col.type === 'dateColumn') {
                    dataObj = setUpDate(dataObj, item.index.date);
                    if (app_state.workspace_controls.segmented_view) {
                        dataObj["segment"] = item.index.segment;
                    }
                }

                if (col.type === 'dataColumn') {
                    let { mainKey } = col;
                    col.subcolumns.map(key => {
                        if (mainKey === "difference_sdly") {
                            if (key.value === "Occ") {
                                dataObj[key.value] = convertToPercent(item[dataKey]["primary"][key.value] - item[dataKey]["sdly"][key.value])
                            } else {
                                dataObj[key.value] = round(item[dataKey]["primary"][key.value] - item[dataKey]["sdly"][key.value])
                            }
                        } else if (mainKey === "sdly_pickup_diff") {
                            if (key.value == "Occ") {
                                dataObj[key.value] = convertToPercent(item[dataKey]["sdly_actual"][key.value] - item[dataKey]["sdly"][key.value]);
                            } else {
                                dataObj[key.value] = round(item[dataKey]["sdly_actual"][key.value] - item[dataKey]["sdly"][key.value])

                            }
                        } else if (mainKey === "marketprimary") {
                            if (key.value == "Occ") {
                                dataObj[key.value] = (marketData[index]) ? convertToPercent(marketData[index]["MarketTotal"]["primary"][key.value]) : 0.00;
                            } else {
                                dataObj[key.value] = (marketData[index]) ? round(marketData[index]["MarketTotal"]["primary"][key.value]) : 0.00;
                            }
                        } else if (mainKey === "marketsdly") {
                            if (key.value == "Occ") {
                                dataObj[key.value] = (marketData[index]) ? convertToPercent(marketData[index]["MarketTotal"]["sdly"][key.value]) : 0.00;
                            } else {
                                dataObj[key.value] = (marketData[index]) ? round(marketData[index]["MarketTotal"]["sdly"][key.value]) : 0.00;
                            }
                        } else if (mainKey === "market_sdly_diff") {
                            if (key.value == "Occ") {
                                dataObj[key.value] = (marketData[index]) ? round(convertToPercent(marketData[index]["MarketTotal"]["primary"][key.value]) - convertToPercent(marketData[index]["MarketTotal"]["sdly"][key.value])) : 0.00;
                            } else {
                                dataObj[key.value] = (marketData[index]) ? round(marketData[index]["MarketTotal"]["primary"][key.value] - marketData[index]["MarketTotal"]["sdly"][key.value]) : 0.00;
                            }
                        } else {
                            if (key.value == "Occ") {
                                dataObj[key.value] = (marketData[index]) ? convertToPercent(item[dataKey][mainKey][key.value]) : 0.00;
                            } else {
                                dataObj[key.value] = (marketData[index]) ? round(item[dataKey][mainKey][key.value]) : 0.00;
                            }
                        }
                    })
                }
                dataObj.id = col.id;
                data_columns.push(dataObj);
            })

            data_columns.map((itmArr, idx) => {
                if (itmArr['date'] == "" || itmArr['segment'] === "Total") {
                    data_columns[idx]["ignore_sort"] = true;
                }
            })
            setup_data.push(data_columns);
        })
    }
    return setup_data;

}


export function getTopSegments(auth, app_state, category) {
    let system_settings = getAppData(auth, app_state)
    let market_segment_category_mapping = system_settings.market_segment_category_mapping;
    const lvl1_mseg_grouping = JSON.parse(
        JSON.stringify(system_settings.lvl1_mseg_grouping)
    );
    const topSegments = {};
    lvl1_mseg_grouping.filter((item) => {
        if (item.Transient) topSegments.Transient = item.Transient;
        if (item.Group) topSegments.Group = item.Group;
        if (item.Contract) topSegments.Contract = item.Contract;
    });
    let segmentCodes = [];
    let topSegmentsList = topSegments[category];
    if (topSegmentsList) {
        topSegmentsList.map((item) => {
            // debugger;
            if (market_segment_category_mapping[item]) {
                segmentCodes = [...segmentCodes, ...market_segment_category_mapping[item]];
            }
        })
        segmentCodes = [...new Set(segmentCodes)];
    }
    return segmentCodes;
}


export function getReportSegmentedData_hotel(columns, auth, app_state, ui_state) {
    let data = deepCopy(dataService.getSeriesById("OSRSegment").data.data);
    console.log("data => ", data);
    let marketData = deepCopy(dataService.getSeriesById("MarketARISegmented").data.data);
    let setup_data = [];
    let { date_from, date_until } = app_state.primary_stay_range;

    let dates = getDatesInRange(date_from, date_until);
    let categoryList = ["Transient", "Group", "Contract", "Total"];

    /////// final Total /////

    let blankObj = { Rooms: 0, Occ: 0, ADR: 0, RevPAR: 0, SellRate: 0, Revenue: 0 };
    let primaryTotal = { Rooms: 0, Occ: 0, ADR: 0, RevPAR: 0, SellRate: 0, Revenue: 0 };
    let sdlyTotal = { Rooms: 0, Occ: 0, ADR: 0, RevPAR: 0, SellRate: 0, Revenue: 0 };
    let sdly_actualTotal = { Rooms: 0, Occ: 0, ADR: 0, RevPAR: 0, SellRate: 0, Revenue: 0 };

    let allTotal = {
        "Transient": {
            "primary": blankObj,
            "sdly": blankObj,
            "sdly_actual": blankObj
        },
        "Group": {
            "primary": blankObj,
            "sdly": blankObj,
            "sdly_actual": blankObj
        },
        "Contract": {
            "primary": blankObj,
            "sdly": blankObj,
            "sdly_actual": blankObj
        },

    }

    ///// end final total //// 


    if (dates && dates.length > 0) {

        dates.map((date, dateIndex) => {

            categoryList.map((category, categoryIndex) => {
                let data_columns = [];
                let segmentCodes = getTopSegments(auth, app_state, category);
                columns.map(col => {
                    let dataObj = {};
                    dataObj.id = col.id;
                    if (col.type === 'dateColumn') {
                        dataObj = setUpDate(dataObj, date);
                        if (app_state.workspace_controls.segmented_view) {
                            dataObj["segment"] = category;
                            // dataObj["segment"] = (categoryIndex == (categoryList.length - 1)) ? "Total" : category ;
                        }
                    }
                    if (col.type === 'dataColumn') {
                        let { mainKey } = col;
                        col.subcolumns.map(key => {
                            if (mainKey === "difference_sdly") {
                                let keyPrimaryTotalValue = getSegmentKeyTotal(data[dateIndex], key.value, segmentCodes, "primary", "osr", category);
                                let keySdlyTotalValue = getSegmentKeyTotal(data[dateIndex], key.value, segmentCodes, "sdly", "osr", category);

                                if (key.value === "Occ") {
                                    dataObj[key.value] = convertToPercent(keyPrimaryTotalValue - keySdlyTotalValue)
                                } else {
                                    dataObj[key.value] = round(keyPrimaryTotalValue - keySdlyTotalValue)
                                }
                            } else if (mainKey === "sdly_pickup_diff") {
                                let keySdlyActualTotalValue = getSegmentKeyTotal(data[dateIndex], key.value, segmentCodes, "sdly_actual", "osr", category);
                                let keySdlyTotalValue = getSegmentKeyTotal(data[dateIndex], key.value, segmentCodes, "sdly", "osr", category);
                                if (key.value === "Occ") {
                                    dataObj[key.value] = convertToPercent(keySdlyActualTotalValue - keySdlyTotalValue)
                                } else {
                                    dataObj[key.value] = round(keySdlyActualTotalValue - keySdlyTotalValue)
                                }
                            }
                            else if (mainKey === "marketprimary") {
                                let keyTotalValue_market = 0;//getSegmentKeyTotal(marketData[index], key.value, segmentCodes, "primary","market");
                                if (key.value == "Occ") {
                                    dataObj[key.value] = convertToPercent(keyTotalValue_market);
                                } else {
                                    dataObj[key.value] = round(keyTotalValue_market);
                                }
                            } else if (mainKey === "marketsdly") {
                                let keyTotalValueSdly_market = 0;// getSegmentKeyTotal(marketData[index], key.value, segmentCodes, "primary","market");

                                if (key.value == "Occ") {
                                    dataObj[key.value] = convertToPercent(keyTotalValueSdly_market);
                                } else {
                                    dataObj[key.value] = round(keyTotalValueSdly_market);
                                }
                            } else if (mainKey === "market_sdly_diff") {
                                let keyTotalValue_market = 0;//getSegmentKeyTotal(marketData[index], key.value, segmentCodes, "primary","market");
                                let keyTotalValueSdly_market = 0;//getSegmentKeyTotal(marketData[index], key.value, segmentCodes, "primary","market");
                                dataObj[key.value] = round(keyTotalValue_market - keyTotalValueSdly_market)
                            }
                            else {
                                let keyTotalValue = getSegmentKeyTotal(data[dateIndex], key.value, segmentCodes, mainKey, "osr", category);
                                if (key.value == "Occ") {
                                    dataObj[key.value] = convertToPercent(keyTotalValue);

                                } else {
                                    dataObj[key.value] = round(keyTotalValue);
                                    if (category !== "Total" && mainKey === "primary") {
                                        console.log(allTotal["Transient"][mainKey][key.value], " keyTotalValue ", keyTotalValue);
                                        // allTotal[category][mainKey][key.value] += round(keyTotalValue);
                                        let fieldArr = Object.keys(primaryTotal);
                                        fieldArr.map((k) => {
                                            allTotal[category][mainKey][k] += round(keyTotalValue);
                                        })

                                    }
                                    // debugger; will start from here
                                }
                            }
                        })
                    }
                    data_columns.push(dataObj);
                })
                setup_data.push(data_columns);
            })

        })
    }

    console.log("Final total will appear here ", allTotal);

    return setup_data;
}

// function to calculate segmented data for individual category segment.

export function getReportSegmentedData_topSegment(columns, auth, app_state, segmentCodes) {
    let data = deepCopy(dataService.getSeriesById("OSRSegment").data.data);
    let marketData = deepCopy(dataService.getSeriesById("MarketARISegmented").data.data);
    let setup_data = [];
    let { date_from, date_until } = app_state.primary_stay_range;
    let dates = getDatesInRange(date_from, date_until);
    let blankObj = { Rooms: 0, Occ: 0, ADR: 0, RevPAR: 0, SellRate: 0, Revenue: 0 };
    if (dates && dates.length > 0) {
        dates.map((date, dateIndex) => {
            let dataRecord = data.filter((item) => moment(item.index.date).format("YYYY-MM-DD") === moment(date).format("YYYY-MM-DD"));
            let totalAll = {
                primary: { "Occ": 0, "Rooms": 0, "ADR": 0, "RevPAR": 0, "Revenue": 0, "Sellrate": 0 },
                sdly: { "Occ": 0, "Rooms": 0, "ADR": 0, "RevPAR": 0, "Revenue": 0, "Sellrate": 0 },
                difference_sdly: { "Occ": 0, "Rooms": 0, "ADR": 0, "RevPAR": 0, "Revenue": 0, "Sellrate": 0 },
                sdly_actual: { "Occ": 0, "Rooms": 0, "ADR": 0, "RevPAR": 0, "Revenue": 0, "Sellrate": 0 },
                sdly_pickup_diff: { "Occ": 0, "Rooms": 0, "ADR": 0, "RevPAR": 0, "Revenue": 0, "Sellrate": 0 },
                marketprimary: { "Occ": 0, "Rooms": 0, "ADR": 0, "RevPAR": 0, "Revenue": 0, "Sellrate": 0 },
                marketsdly: { "Occ": 0, "Rooms": 0, "ADR": 0, "RevPAR": 0, "Revenue": 0, "Sellrate": 0 },
                market_sdly_diff: { "Occ": 0, "Rooms": 0, "ADR": 0, "RevPAR": 0, "Revenue": 0, "Sellrate": 0 },
            };
            segmentCodes.map((segmentCode, segmentIndex) => {
                let data_columns = [];
                let segmentName = segmentCode;
                let segmentKey = "Segment_" + segmentCode;
                columns.map(col => {
                    let dataObj = {};
                    dataObj.id = col.id;
                    if (col.type === 'dateColumn') {
                        dataObj = setUpDate(dataObj, date);
                        if (app_state.workspace_controls.segmented_view) {
                            dataObj["segment"] = segmentName;
                        }
                    }
                    if (col.type === 'dataColumn') {
                        let { mainKey } = col;
                        col.subcolumns.map(key => {

                            if (mainKey === "difference_sdly") {
                                if (key.value === "Occ") {
                                    dataObj[key.value] = convertToPercent(dataRecord[0][segmentKey]["primary"][key.value] - dataRecord[0][segmentKey]["sdly"][key.value])
                                    totalAll[mainKey][key.value] += convertToPercent(dataRecord[0][segmentKey]["primary"][key.value] - dataRecord[0][segmentKey]["sdly"][key.value])

                                } else {
                                    dataObj[key.value] = round(dataRecord[0][segmentKey]["primary"][key.value] - dataRecord[0][segmentKey]["sdly"][key.value])
                                    totalAll[mainKey][key.value] += round(dataRecord[0][segmentKey]["primary"][key.value] - dataRecord[0][segmentKey]["sdly"][key.value]);
                                }
                            } else if (mainKey === "sdly_pickup_diff") {
                                if (key.value === "Occ") {
                                    dataObj[key.value] = convertToPercent(dataRecord[0][segmentKey]["sdly_actual"][key.value] - dataRecord[0][segmentKey]["sdly"][key.value])
                                    totalAll[mainKey][key.value] += convertToPercent(dataRecord[0][segmentKey]["sdly_actual"][key.value] - dataRecord[0][segmentKey]["sdly"][key.value])
                                } else {
                                    dataObj[key.value] = round(dataRecord[0][segmentKey]["sdly_actual"][key.value] - dataRecord[0][segmentKey]["sdly"][key.value])
                                    totalAll[mainKey][key.value] += round(dataRecord[0][segmentKey]["sdly_actual"][key.value] - dataRecord[0][segmentKey]["sdly"][key.value])
                                }
                            } else if (mainKey === "marketprimary") {
                                if (key.value == "Occ") {
                                    dataObj[key.value] = 0;//convertToPercent(marketData[index]["MarketTotal"]["primary"][key.value]);
                                    totalAll[mainKey][key.value] += 0;
                                } else {
                                    dataObj[key.value] = 0;//round(marketData[index]["MarketTotal"]["primary"][key.value]);
                                    totalAll[mainKey][key.value] += 0;
                                }
                            } else if (mainKey === "marketsdly") {
                                if (key.value == "Occ") {
                                    dataObj[key.value] = 0;//convertToPercent(marketData[index]["MarketTotal"]["sdly"][key.value]);
                                    totalAll[mainKey][key.value] += 0;
                                } else {
                                    dataObj[key.value] = 0;//round(marketData[index]["MarketTotal"]["sdly"][key.value]);
                                    totalAll[mainKey][key.value] += 0;
                                }
                            } else if (mainKey === "market_sdly_diff") {
                                dataObj[key.value] = 0;//round(marketData[index]["MarketTotal"]["primary"][key.value] - marketData[index]["MarketTotal"]["sdly"][key.value])
                                totalAll[mainKey][key.value] += 0;
                            } else {
                                if (key.value == "Occ") {
                                    dataObj[key.value] = convertToPercent(dataRecord[0][segmentKey][mainKey][key.value]);
                                    totalAll[mainKey][key.value] += convertToPercent(dataRecord[0][segmentKey][mainKey][key.value]);
                                } else {
                                    if (mainKey === "primary") {
                                        totalAll[mainKey][key.value] += round(dataRecord[0][segmentKey][mainKey][key.value]);
                                    }
                                    if (mainKey === "sdly") {
                                        totalAll[mainKey][key.value] += round(dataRecord[0][segmentKey][mainKey][key.value]);
                                    }
                                    dataObj[key.value] = round(dataRecord[0][segmentKey][mainKey][key.value]);
                                }
                            }
                            // console.log(dataRecord[0], " ", segmentKey, " ", mainKey, " ", key.value);
                            // dataObj[key.value] = dataRecord[0][segmentKey][mainKey][key.value]
                        })
                    }
                    data_columns.push(dataObj);

                })
                setup_data.push(data_columns);
            })

            //////////
            console.log("Total All = ", totalAll);
            let dateData = setUpDate({}, date)
            let innerArr = [{
                date: dateData['date'],
                dow: dateData['dow'],
                id: "staydates",
                segment: "Total"
            },
            {
                "id": "otb_primary_report",
                "Occ": totalAll.primary.Occ,
                "Rooms": totalAll.primary.Rooms,
                "ADR": getCorrectValue(totalAll.primary.Revenue / totalAll.primary.Rooms),
                "RevPAR": totalAll.primary.RevPAR,
                "Revenue": totalAll.primary.Revenue,
                "SellRate": totalAll.primary.SellRate,
            },
            {
                "id": "sdly",
                "Occ": totalAll.sdly.Occ,
                "Rooms": totalAll.sdly.Rooms,
                "ADR": getCorrectValue(totalAll.sdly.Revenue / totalAll.sdly.Rooms),
                "RevPAR": totalAll.sdly.RevPAR,
                "Revenue": totalAll.sdly.Revenue,
                "SellRate": totalAll.sdly.SellRate,
            },
            {
                "id": "difference_sdly",
                "Occ": totalAll.difference_sdly.Occ,
                "Rooms": totalAll.difference_sdly.Rooms,
                "ADR": getCorrectValue(totalAll.difference_sdly.Revenue / totalAll.difference_sdly.Rooms),
                "RevPAR": totalAll.difference_sdly.RevPAR,
                "Revenue": totalAll.difference_sdly.Revenue,
                "SellRate": totalAll.difference_sdly.SellRate,
            }
                ,
            {
                "id": "sdly_actual",
                "Occ": totalAll.sdly_actual.Occ,
                "Rooms": totalAll.sdly_actual.Rooms,
                "ADR": getCorrectValue(totalAll.sdly_actual.Revenue / totalAll.sdly_actual.Rooms),
                "RevPAR": totalAll.sdly_actual.RevPAR,
                "Revenue": totalAll.sdly_actual.Revenue,
                "SellRate": totalAll.sdly_actual.SellRate,
            },
            {
                "id": "sdly_pickup_diff",
                "Occ": totalAll.sdly_pickup_diff.Occ,
                "Rooms": totalAll.sdly_pickup_diff.Rooms,
                "ADR": getCorrectValue(totalAll.sdly_pickup_diff.Revenue / totalAll.sdly_pickup_diff.Rooms),
                "RevPAR": totalAll.sdly_pickup_diff.RevPAR,
                "Revenue": totalAll.sdly_pickup_diff.Revenue,
                "SellRate": totalAll.sdly_pickup_diff.SellRate,
            },
            {
                "id": "marketprimary",
                "Occ": totalAll.marketprimary.Occ,
                "Rooms": totalAll.marketprimary.Rooms,
                "ADR": getCorrectValue(totalAll.marketprimary.Revenue / totalAll.marketprimary.Rooms),
                "RevPAR": totalAll.marketprimary.RevPAR,
                "Revenue": totalAll.marketprimary.Revenue,
                "SellRate": totalAll.marketprimary.SellRate,
            },
            {
                "id": "marketsdly",
                "Occ": totalAll.marketsdly.Occ,
                "Rooms": totalAll.marketsdly.Rooms,
                "ADR": getCorrectValue(totalAll.marketsdly.Revenue / totalAll.marketsdly.Rooms),
                "RevPAR": totalAll.marketsdly.RevPAR,
                "Revenue": totalAll.marketsdly.Revenue,
                "SellRate": totalAll.marketsdly.SellRate,
            }
                ,
            {
                "id": "market_sdly_diff",
                "Occ": totalAll.market_sdly_diff.Occ,
                "Rooms": totalAll.market_sdly_diff.Rooms,
                "ADR": getCorrectValue(totalAll.market_sdly_diff.Revenue / totalAll.market_sdly_diff.Rooms),
                "RevPAR": totalAll.market_sdly_diff.RevPAR,
                "Revenue": totalAll.market_sdly_diff.Revenue,
                "SellRate": totalAll.market_sdly_diff.SellRate,
            }
            ]
            setup_data.push(innerArr);
            // console.log(totalAll,date," == ",setup_data);
        })
    }

    return setup_data;
}


export function getReportData_topSegment(columns, auth, app_state, segmentCodes) {
    let data = (app_state.workspace_controls.segmented_view) ? [] : deepCopy(dataService.getSeriesById("OSRSegment").data.data);
    let marketData = (dataService.getSeriesById("MarketARISegmented").data) ? deepCopy(dataService.getSeriesById("MarketARISegmented").data.data) : [];
    let setup_data = [];
    if (data) {
        let dataKey = "HotelTotal";
        data.map((item, index) => {
            let data_columns = [];
            columns.map(col => {
                let dataObj = {};
                if (col.type === 'dateColumn') {
                    dataObj = setUpDate(dataObj, item.index.date);
                    if (app_state.workspace_controls.segmented_view) {
                        dataObj["segment"] = item.index.segment;
                    }
                }

                if (col.type === 'dataColumn') {
                    let { mainKey } = col;
                    col.subcolumns.map(key => {
                        if (mainKey === "difference_sdly") {
                            let keyPrimaryTotalValue = getSegmentKeyTotal(item, key.value, segmentCodes, "primary", "osr");
                            let keySdlyTotalValue = getSegmentKeyTotal(item, key.value, segmentCodes, "sdly", "osr");

                            if (key.value === "Occ") {
                                dataObj[key.value] = convertToPercent(keyPrimaryTotalValue - keySdlyTotalValue)
                            } else {
                                dataObj[key.value] = round(keyPrimaryTotalValue - keySdlyTotalValue)
                            }
                        } else if (mainKey === "sdly_pickup_diff") {
                            let keySdlyActualTotalValue = getSegmentKeyTotal(item, key.value, segmentCodes, "sdly_actual", "osr");
                            let keySdlyTotalValue = getSegmentKeyTotal(item, key.value, segmentCodes, "sdly", "osr");
                            if (key.value == "Occ") {
                                dataObj[key.value] = convertToPercent(keySdlyActualTotalValue - keySdlyTotalValue)
                            } else {
                                dataObj[key.value] = round(keySdlyActualTotalValue - keySdlyTotalValue)
                            }
                        }
                        else if (mainKey === "marketprimary") {
                            let keyTotalValue_market = 0;//getSegmentKeyTotal(marketData[index], key.value, segmentCodes, "primary","market");
                            if (key.value == "Occ") {
                                dataObj[key.value] = convertToPercent(keyTotalValue_market);
                            } else {
                                dataObj[key.value] = round(keyTotalValue_market);
                            }
                        } else if (mainKey === "marketsdly") {
                            let keyTotalValueSdly_market = 0;// getSegmentKeyTotal(marketData[index], key.value, segmentCodes, "primary","market");

                            if (key.value == "Occ") {
                                dataObj[key.value] = convertToPercent(keyTotalValueSdly_market);
                            } else {
                                dataObj[key.value] = round(keyTotalValueSdly_market);
                            }
                        } else if (mainKey === "market_sdly_diff") {
                            let keyTotalValue_market = 0;//getSegmentKeyTotal(marketData[index], key.value, segmentCodes, "primary","market");
                            let keyTotalValueSdly_market = 0;//getSegmentKeyTotal(marketData[index], key.value, segmentCodes, "primary","market");
                            dataObj[key.value] = round(keyTotalValue_market - keyTotalValueSdly_market)
                        }
                        else {
                            let keyTotalValue = getSegmentKeyTotal(item, key.value, segmentCodes, mainKey, "osr");
                            if (key.value == "Occ") {
                                dataObj[key.value] = convertToPercent(keyTotalValue);
                            } else {
                                dataObj[key.value] = round(keyTotalValue);
                            }
                        }
                    })
                }
                dataObj.id = col.id;
                data_columns.push(dataObj);
            })

            data_columns.map((itmArr, idx) => {
                if (itmArr['date'] == "" || itmArr['segment'] === "Total") {
                    data_columns[idx]["ignore_sort"] = true;
                }
            })
            setup_data.push(data_columns);
        })
    }
    return setup_data;
}

// function is used to calculate the total of all passed key basis on the the segement
export const getSegmentKeyTotal = (item, key, segmentCodes, mainKey, segmentType = "osr", segmentTotalRow = "") => {
    let keyTotal = 0;
    let revenueTotal = 0;
    let roomsTotal = 0

    if (segmentTotalRow && segmentTotalRow === "Total") {
        if (key !== "SellRate") {
            keyTotal = item["HotelTotal"][mainKey][key];
        } else {
            keyTotal = 0;
        }
        return keyTotal;
    } else {

        if (segmentCodes && segmentCodes.length > 0) {
            segmentCodes.map((code, index) => {
                let segmentCode = (segmentType === "market") ? "MarketSegment_" + code : "Segment_" + code;
                if (key === "ADR") {
                    revenueTotal += (item[segmentCode][mainKey]["Revenue"]) ? item[segmentCode][mainKey]["Revenue"] : 0;
                    roomsTotal += (item[segmentCode][mainKey]["Rooms"]) ? item[segmentCode][mainKey]["Rooms"] : 0;
                } else {
                    keyTotal += (item[segmentCode][mainKey][key]) ? item[segmentCode][mainKey][key] : 0;
                }
            })
            if (key === "ADR") {
                keyTotal = revenueTotal / roomsTotal;
            }
            return keyTotal;
        }

    }

}