export function settingsReducers(state, actions) {
    const type = actions.type;
    switch(type){
      case "onDataSuccess": {
        state.ui.request.status = "loaded";
        state.ui.request.resType = "";
        state.ui.request.message = "";
        //state.dataOriginal = JSON.parse(JSON.stringify(state.data));
      //  state.data.list = actions.list;
       // state.data.folder = actions.folder;
       // console.log(JSON.stringify(state.data.list)+"testing=");
        // console.log("folder = "+JSON.stringify(state.data.folder));
        return {...state};
      }
      case "onFileUpload": {
        state.ui.request.resType = "success";
        state.ui.request.status = "loaded";
        state.ui.request.message = "File has been added successfully!";
       // const id = "id_" + actions.index;
       //state.data.folder[0].folders.splice(actions.index, 1);
       // console.log("test = "+JSON.stringify(state.data.folder));
        return { ...state };
      }
      case "onFolderCreate": {
        state.ui.request.resType = "success";
        state.ui.request.status = "loaded";
        state.ui.request.message = "Folder has been created successfully!";
       // const id = "id_" + actions.index;
       //state.data.folder[0].folders.splice(actions.index, 1);
       // console.log("test = "+JSON.stringify(state.data.folder));
        return { ...state };
      }
      case "onDeleteFolder": {
        state.ui.request.resType = "success";
        state.ui.request.status = "loaded";
        state.ui.request.message = "Folder has been deleted successfully!";
        // const id = "id_" + actions.index;
        // state.data.folders.splice(actions.index, 1);
        // console.log("test = "+JSON.stringify(state.data.folder));
        return { ...state };
      }
      case "onDeleteDocument": {
        state.ui.request.resType = "success";
        state.ui.request.status = "loaded";
        state.ui.request.message = "Document deleted successfully!";
        state.data.files =state.data.files.filter(item=>{
          if(item.document_uid!==actions.documentUid){
            return item;
          }
        })
       
        return { ...state };
      }
      case "onDeleteFail": {
        state.ui.request.resType = "error";
        state.ui.request.status = "failed";
        // state.ui.request.message = "Document deleted successfully!";
        //state.data.files.splice(actions.index, 1);
        return { ...state };
      }
      case "onHideAlert": {
        state.ui.request.resType = "";
        state.ui.request.status = "";
        state.ui.request.message = "";
        return { ...state };
      }
      case "onResponseError": {
        state.ui.request.resType = "error";
        state.ui.request.status = "failed";
        state.ui.request.message = "Oops! Something went wrong.";
        return { ...state };
      }      
      case "wait": {
        state.ui.request.resType = "waiting";
        state.ui.request.status = "wait";
        state.ui.request.message = "Please wait...";
        return { ...state };
      }      
      default: {
        throw Error("Error occour in document listing.");
      }
      
     
    }
   

}